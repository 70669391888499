import AG from "elements/AG"
import * as PagesDef from './PagesDef'
import * as AppUrls from 'constants/AppUrls';
import { useState, useRef, useContext } from "react";
import * as XLSX from 'xlsx'
import backArrow from 'img/back-arrow.svg';
import DispatchContext from 'context/DispatchContext';
import { useMutation } from "react-query";
import { handleImport, ValidateDataM } from './ImportForm.services'
import { notificationMessage } from "global/helpers";
import { Link } from "react-router-dom";

const ImportForm = ({ match }) => {

    const appDispatch = useContext(DispatchContext);

    const [isImported, setIsImported] = useState<boolean>(false)
    const [isValidated, setIsValidated] = useState<boolean>(false)
    const [verifiedData, setVerifiedData] = useState([])
    const [gridData, setGridData] = useState([])
    const inputRef = useRef<HTMLInputElement>(null)

    let PageDef = null

    const currentURL = match.path

    if (currentURL === AppUrls.import_customers) {
        PageDef = PagesDef.customers_pages_def
    }

    const exportTemplate = () => {
        const worksheet = XLSX.utils.json_to_sheet(PageDef?.template);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "template.xlsx");
    }

    const OpenFileSelection = () => {
        inputRef.current.click();
    }

    const areEqual = (array1, array2) => {
        if (array1.length === array2.length) {
            return array1.every((element, index) => {
                if (element === array2[index]) {
                    return true;
                }
                return false;
            });
        }
        return false;
    }

    const OpenFileSelected = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (event) => {
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, { type: "binary" })
            const workSheet = workBook.Sheets[workBook.SheetNames[0]]
            const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
            let keys = Object.keys(PageDef?.template[0]);
            let headers = fileData[0]
            if (!areEqual(keys, headers)) {
                let notification = {
                    variant: "danger",
                    msg: "Table columns mismatch, make sure to match the template's columns."
                };
                appDispatch({ type: "notification", value: notification });
            } else {
                fileData.splice(0, 1)
                let rows = []
                fileData.forEach(row => {
                    let rowData = {}
                    // @ts-ignore
                    row.forEach((element, index) => {
                        rowData[Object.keys(PageDef?.template[0])[index]] = element
                    });
                    rows.push(rowData)
                })

                if (rows.length > 0) {
                    setGridData(rows)
                    setIsImported(true)
                    let notification = {
                        variant: "success",
                        msg: "You can now validate the data."
                    };
                    appDispatch({ type: "notification", value: notification });
                } else {
                    let notification = {
                        variant: "danger",
                        msg: "You can not submit an empty file."
                    };
                    appDispatch({ type: "notification", value: notification });
                }
            }
        }
        reader.readAsBinaryString(file)
    }

    const ValidateDataMethod = useMutation(ValidateDataM, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Data validated, you can now Import`
            };
            appDispatch({ type: "notification", value: notification })
            setIsValidated(true)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem validating data')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const handleImportMethod = useMutation(handleImport, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Data Imported`
            };
            appDispatch({ type: "notification", value: notification })
            setIsValidated(true)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem importing information')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const ValidateData = () => {
        let data = []
        let gridDataValues = []
        gridData.forEach(i => {
            let obj = {}
            let gridDataKeys = Object.keys(i)
            gridDataValues = Object.values(i)
            Object.keys(PageDef?.template[0]).forEach(k => {
                if (gridDataKeys.includes(k)) {
                    let index = gridDataKeys.indexOf(k)
                    obj[k.trim().replace(/ /g, '')] = gridDataValues[index].toString()
                } else {
                    obj[k.trim().replace(/ /g, '')] = ""
                }
            })
            data.push(obj)
        })
        setVerifiedData(data)
        ValidateDataMethod.mutate({ url: PageDef?.url, data: data })
    }

    const ImportData = () => {
        handleImportMethod.mutate({ url: PageDef?.urlI, data: verifiedData })
    }

    const cancelImport = () => {
        setIsImported(false)
        setIsImported(false)
        setIsValidated(false)
        setGridData([])
    }

    return (
        <div className="grid-page">
            <div className="page-title page-title-editable">
                <Link to={PageDef?.header?.prevPage} className="back-btn">
                    <img src={backArrow} alt="back arrow" />
                    {PageDef?.header?.title}
                </Link>
                <div>
                    {!isImported ? (
                        <>
                            <button className="btn btn-outline-primary" onClick={OpenFileSelection}>
                                {PageDef?.header?.actions?.fileSelect}
                            </button>
                            <input
                                type="file"
                                className="d-none"
                                ref={inputRef}
                                onChange={OpenFileSelected}
                                onClick={e => {
                                    // @ts-ignore
                                    e.target.value = null
                                }}
                            />
                        </>
                    ) : (
                        <button className="btn btn-outline-primary no-border" onClick={cancelImport}>
                            Cancel
                        </button>
                    )}
                    <button className="btn btn-primary" onClick={ValidateData} disabled={!isImported}>
                        {PageDef?.header?.actions?.check}
                    </button>
                    <button className="btn btn-success ms-3" onClick={ImportData} disabled={!isValidated}>
                        {PageDef?.header?.actions?.confirmation}
                    </button>
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="grid-container has-columns-def">
                    <div className="columns-def text-end pb-3">
                        <button className="btn btn-outline-primary" onClick={exportTemplate}>
                            Export Template
                        </button>
                    </div>
                    <div className="grid">
                        <AG
                            columns={Object.keys(PageDef?.template[0]).map(e => ({ field: e, headerName: e }))}
                            data={gridData}
                            pagination={true}
                            autoColumns={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImportForm