import { useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { FormProvider, useForm } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import AG from "elements/AG";
import SalesOrderModel from "./SalesOrderModel/SalesOrderModel";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import DispatchContext from "context/DispatchContext";
import {
  dateFormmaterNoTime,
  formatInput,
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import { TrackingInfo, SalesOrder } from "../SalesOrder.model";
import {
  applyTrackingNumber,
  getSalesOrder,
  searchSalesOrders,
} from "./SalesOrderServices/SalesOrderServices";
import Delete from "img/delete.svg";
import "./SalesOrders.scss";
import Lookup from "elements/Lookup";
import { getBaseCurrency } from "components/currency";

const ApplyTrackingInfo = () => {
  const appDispatch = useContext(DispatchContext);
  const [, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty },

    reset,
    register,
    getValues,
    setValue,
    handleSubmit,
  } = methods;

  const [showSalesOrderModel, setShowSalesOrderModel] =
    useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showShippingmModal, setShowShippingModal] = useState<boolean>(false);
  const [salesOrder, setSalesOrder] = useState<SalesOrder>(null);
  const [trackingList, setTrackingList] = useState<TrackingInfo[]>([]);
  const [modalType, setModalType] = useState<string>("");
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const [trackingNumberErr, setTrackingNumberErr] = useState<boolean>(false);

  const tracking_info_columns: any[] = [
    {
      field: "trackingNumber",
      headerName: "Tracking No.",
      editable: false,
      filter: false,
    },
    {
      field: "orderNumber",
      headerName: "SO No",
      editable: false,
      filter: false,
    },
    {
      field: "accountId",
      headerName: "Cust. No",
      editable: false,
      filter: false,
    },
    {
      field: "addedDate",
      headerName: "Add Date",
      editable: false,
      filter: false,
      cellRenderer: (params) =>
        params.value ? new Date(params.value).toLocaleDateString() : "",
    },
    {
      field: "addedBy",
      headerName: "Add User",
      editable: false,
      filter: false,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filter: false,
      onCellClicked: (params) => {
        setModalType("delete");
        setTrackingNumber(params.data.trackingNumber);
        setShowConfirmModal(true);
      },
      cellRenderer: () => {
        return `
                    <div class="btn text-danger">
                        <img src=${Delete} alt="Delete Icon" />
                        Delete
                    </div>
              `;
      },
    },
  ];

  const getSalesOrders = useMutation(searchSalesOrders);

  const getSalesOrderItem = useMutation(getSalesOrder, {
    async onSuccess(response) {
      if (response.data?.status === 1 || response.data?.status === 6) {
        let notification = {
          variant: "danger",
          msg: "Open and void sales order can not be selected",
        };
        appDispatch({ type: "notification", value: notification });
      } else {
        setSalesOrder(response.data);
      }
    },
  });

  useEffect(() => {
    if (salesOrder) {
      setValue("orderNo", salesOrder?.orderNumber, { shouldDirty: true });
      setTrackingList(salesOrder.trackingList);
    }
  }, [salesOrder]);

  useEffect(() => {
    getSalesOrders.mutate("?states=0");
  }, []);

  const onAddTrackingNumber = () => {
    setTrackingNumberErr(false);
    if (getValues("trackingNumber") && getValues("trackingNumber").length > 0) {
      let trackingNumberList = [
        ...trackingList,
        {
          trackingNumber: getValues("trackingNumber"),
          orderNumber: salesOrder?.orderNumber,
          accountId: salesOrder?.accountNumber,
          addedDate: dateFormmaterNoTime(new Date()),
          addedBy: "Current User",
        },
      ];
      setTrackingList(trackingNumberList);
      setValue("trackingNumber", "");
    }
  };

  const checkMatching = (value1, value2) => {
    return value1 === value2;
  };

  const getNewAmountBalance = () => {
    if (salesOrder) {
      return (
        salesOrder?.customerBalance +
        salesOrder?.total +
        (getValues("totalShippingCost")
          ? parseFloat(
              getValues("totalShippingCost")?.toString().replace(/,/g, "")
            )
          : 0)
      );
    }
    return 0.0;
  };

  const getNewOrderAmount = () => {
    if (salesOrder) {
      return (
        salesOrder?.total +
        (getValues("totalShippingCost")
          ? parseFloat(
              getValues("totalShippingCost")?.toString().replace(/,/g, "")
            )
          : 0)
      );
    }
    return 0.0;
  };

  const onSubmit = () => {
    if (Object.keys(errors).length == 0) {
      setModalType("apply");
      setShowConfirmModal(true);
    }
  };

  const handleApply = useMutation(applyTrackingNumber, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Changes saved successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to save changes"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: handleApply.isLoading });
  }, [handleApply.isLoading]);

  const applyTrackingNumberData = () => {
    if (getValues("totalShippingCost"))
      handleApply.mutate({
        soId: salesOrder?.salesOrderId,
        shipCost: parseFloat(
          getValues("totalShippingCost")?.toString().replace(/,/g, "")
        ),
        trackingList: trackingList.map((i) => i.trackingNumber),
      });
    else setShowShippingModal(true);
    setShowConfirmModal(false);
  };

  const handleSubmitConfirmation = () => {
    if (modalType === "apply") {
      applyTrackingNumberData();
    } else if (modalType === "delete") {
      setTrackingList(
        trackingList.filter((item) => item.trackingNumber !== trackingNumber)
      );
    }
    setShowConfirmModal(false);
  };

  const handleSubmitShipping = () => {
    handleApply.mutate({
      soId: salesOrder?.salesOrderId,
      shipCost: 0,
      trackingList: trackingList.map((i) => i.trackingNumber),
    });
    setShowShippingModal(false);
  };

  const onCancel = () => {
    reset();
    setSalesOrder(null);
    setValue("orderNo", null);
    setTrackingList([]);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn ">Apply Tracking Info</div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                onClick={onCancel}
                disabled={!isDirty}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!getValues("orderNo")}
              >
                Apply Tracking No.
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row d-flex justify-content-between mt-2">
                <div className="col-8">
                  <div className="row justify-content-between mb-2">
                    <div className="col-lg-4 col-md-8 col-sm-8">
                      <div className="form-group">
                        <label>
                          Order No. <span className="text-danger">*</span>
                        </label>
                        <Lookup
                          onButtonClicked={() => setShowSalesOrderModel(true)}
                          inputName="orderNo"
                          isRequired={true}
                          initialData={getSalesOrders?.data?.data?.data?.map(
                            (item) => ({
                              ...item,
                              id: item.salesOrderId,
                              name: item.orderNumber,
                            })
                          )}
                          onSelection={(e) =>
                            getSalesOrderItem.mutate(e.salesOrderId)
                          }
                          inputValue={getValues("orderNo")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-8 col-sm-8">
                      <label>Account</label>
                      <div className={`${!salesOrder && "text-muted"}`}>
                        {salesOrder?.accountNumber}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-8 col-sm-8">
                      <label>Date</label>
                      <div className={`${!salesOrder && "text-muted"}`}>
                        {salesOrder?.orderDate?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-lg-4 col-md-8 col-sm-8">
                      <label className="label mb-2">Company Name</label>
                      <div className={`${!salesOrder && "text-muted"}`}>
                        {salesOrder?.billingInfo.company}
                      </div>
                      <div className={`${!salesOrder && "text-muted"}`}>
                        {salesOrder?.billingInfo.address?.address}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-8 col-sm-8">
                      <div className="row">
                        <div className="col">
                          <label className="label mt-0">Shipping Method</label>
                        </div>
                        <div className="col">
                          <div
                            className={`${
                              !salesOrder && "text-muted"
                            } text-end ms-auto`}
                          >
                            {salesOrder?.shippingMethod?.name}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label className="label mt-0">Item Count</label>
                        </div>
                        <div className="col">
                          {" "}
                          <div
                            className={`${
                              !salesOrder && "text-muted"
                            } text-end ms-auto`}
                          >
                            {salesOrder ? salesOrder?.itemsQuantity : 0}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label className="label mt-0">Total QTY</label>
                        </div>
                        <div className="col">
                          {" "}
                          <div
                            className={`${
                              !salesOrder && "text-muted"
                            } text-end ms-auto`}
                          >
                            {salesOrder ? salesOrder?.totalItems : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-8 col-sm-8"></div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="col-lg-12">
                    <label>Order Notes</label>
                    <div className={`${!salesOrder && "text-muted"} w-100`}>
                      {salesOrder?.remarks === null ? "" : salesOrder?.remarks}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section ms-1 mt-2 py-3 row">
                <div className="col-8 d-flex align-items-center">
                  <label>
                    Tracking Number <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-3 me-3">
                    <Form.Control
                      {...register("trackingNumber")}
                      className="form-control ms-4"
                      disabled={!getValues("orderNo")}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onAddTrackingNumber()}
                    disabled={!getValues("orderNo")}
                  >
                    Add
                  </button>
                  <div className="text-danger mt-0 ms-3">
                    {trackingNumberErr && `This field is required`}
                  </div>
                </div>
                <div className="col-4"></div>
              </div>
              <div className="row d-flex justify-content-between mt-4">
                <div className="col-8">
                  <AG
                    data={trackingList}
                    columns={tracking_info_columns}
                    pagination={true}
                  />
                </div>
                <div className="col-4">
                  <div className="section py-2">
                    <div className="row g-1 my-3">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Total Shipping Cost</label>
                          <div className="custom-input position-relative">
                            <span className="dollar-sign-placeholder">$</span>
                            <input
                              {...register("totalShippingCost", {
                                validate: {
                                  matching: (val) =>
                                    checkMatching(
                                      val,
                                      getValues("verifyTotalShippingCost")
                                    ) || "The values does not match.",
                                },
                              })}
                              className="form-control ps-5 border-0"
                              disabled={!getValues("orderNo")}
                              onBlur={(e) =>
                                setValue(
                                  "totalShippingCost",
                                  formatInput(e.target.value)
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Verify Total Shipping Cost</label>
                          <div className="custom-input position-relative">
                            <span className="dollar-sign-placeholder">$</span>
                            <input
                              {...register("verifyTotalShippingCost", {
                                validate: {
                                  matching: (val) =>
                                    checkMatching(
                                      val,
                                      getValues("totalShippingCost")
                                    ) || "The values does not match.",
                                },
                              })}
                              className="form-control ps-5 border-0"
                              disabled={!getValues("orderNo")}
                              onBlur={(e) =>
                                setValue(
                                  "verifyTotalShippingCost",
                                  formatInput(e.target.value)
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-danger">
                      {errors.verifyTotalShippingCost?.message}
                    </div>
                    <div className="my-3">
                      <InputGroup className="d-flex justify-content-between">
                        <label className="label mt-0">Customer Balance</label>
                        <div>
                          {formatNumber(
                            salesOrder?.customerBalance ?? 0,
                            baseCurrency && baseCurrency
                          )}
                        </div>
                      </InputGroup>
                      <InputGroup className="d-flex justify-content-between">
                        <label className="label mt-0">Order Amount</label>
                        <div>
                          {formatNumber(
                            salesOrder?.total ?? 0,
                            baseCurrency && baseCurrency
                          )}
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="new_ammount-div">
                    <InputGroup className="d-flex justify-content-between">
                      <div>New Amount Balance</div>
                      <div className="value-green">
                        {formatNumber(getNewAmountBalance(), baseCurrency)}
                      </div>
                    </InputGroup>
                    <InputGroup className="d-flex justify-content-between">
                      <div>New Order Amount</div>
                      <div className="value-green">
                        {formatNumber(getNewOrderAmount(), baseCurrency)}
                      </div>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SalesOrderModel
        isOpen={showSalesOrderModel}
        setIsOpen={setShowSalesOrderModel}
        title="Sales Order Listing/Search"
        setSalesOrder={setSalesOrder}
        componentTitle="Apply Tracking Info"
        disallowPickup={true}
        // RemoveOpenAndVoided={true}
        showOnlyFulfilled={true}
      />
      <ConfirmationModal
        title={
          modalType === "apply"
            ? "Saving Changes"
            : modalType === "delete"
            ? "Delete Tracking Number"
            : ""
        }
        message="Are you sure?"
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => handleSubmitConfirmation()}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Yes"
        type={
          modalType === "apply"
            ? "confirmation-saving"
            : modalType === "delete"
            ? "confirmation-danger"
            : ""
        }
      />
      <ConfirmationModal
        title="Sales Order Shipment"
        message="You did not enter a cost for this shipment. Are you sure you want to continue?"
        showModal={showShippingmModal}
        onClose={() => setShowShippingModal(false)}
        onConfirm={() => handleSubmitShipping()}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-saving"
      />
    </>
  );
};

export default ApplyTrackingInfo;
