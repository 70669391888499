import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import { Controller, useForm } from "react-hook-form";
import { useContext } from "react";
import { useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import { postAttribute } from "./InventorySetup.services";
import Select from "react-select";

type Props = {
  showModal: boolean;
  onExit: () => void;
  formValues;
};

const options = [
  { value: 1, label: "Text" },
  { value: 2, label: "Numeric" },
  { value: 3, label: "Date" },
  { value: 4, label: "Boolean" },
];

const AttributeModal = (props: Props) => {
  const appDispatch = useContext(DispatchContext);

  const addMutation = useMutation(postAttribute, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Attribute added successfully",
      };
      appDispatch({ type: "notification", value: notification });
      closeAndReset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding attribute"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: null,
      categoryType: null,
    },
  });

  const closeAndReset = () => {
    reset();
    props.onExit();
  };

  const onSubmit = (data) => {
    addMutation.mutate({ ...data, categoryId: props.formValues.value });
  };

  return (
    <Modal show={props.showModal} centered size="lg">
      <Modal.Header>
        <Modal.Title>Add Attribute</Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={closeAndReset}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-11 offset-sm-1">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="h-100 d-flex flex-column"
            >
              <div className="row mb-4">
                <div className="col-sm-5">
                  <div className="form-group">
                    <label>Category</label>
                    <input
                      readOnly={true}
                      className={"form-control"}
                      value={props.formValues?.label}
                    />
                  </div>
                </div>
              </div>
              <div className="row gy-4 mb-5">
                <div className="col-sm-5">
                  <div className="form-group">
                    <label>
                      Name<span className="text-danger">*</span>
                    </label>
                    <input {...register("name")} className={"form-control"} />
                  </div>
                  {errors["name"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-sm-5">
                  <div className="form-group">
                    <label>
                      Type<span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="categoryType"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={(selectedOption: any) => {
                            onChange(selectedOption?.value);
                          }}
                          isClearable
                          value={
                            options?.find((opt) => opt?.value === value) || null
                          }
                          options={options}
                        />
                      )}
                    />
                    {errors["categoryType"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-auto">
                <button type="submit" className="btn btn-primary ms-0">
                  Add Attribute
                </button>
                <button
                  type="button"
                  onClick={closeAndReset}
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AttributeModal;
