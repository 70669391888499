import { useState, useEffect, useContext } from "react";
import DispatchContext from "context/DispatchContext";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import {
  getShippingList,
  createShipping,
  editShipping,
} from "../SystemSetupServices/SystemSetupServices";
import {
  notificationMessage,
  checkIfAllowed,
  formatInput,
} from "global/helpers";
import { useForm, FormProvider, Controller } from "react-hook-form";
import StateContext from "context/StateContext";
import { ShippingMethod } from "../SystemSetup.models";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";

const NewShipping = ({ match }) => {
  const history = useHistory();

  const [selectedId, setSelectedId] = useState<number>(null);
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const { id } = useParams();

  const dataMutation = useMutation("data", getShippingList);
  const [shippingMethodDetails, setShippingMethodDetails] =
    useState<ShippingMethod>(null);
  useEffect(() => {
    if (id !== "new") {
      dataMutation.mutate(id, {
        onSuccess(result) {
          let idNum = Number(id);
          let data = result.data.find(
            (item) => item.shippingMethodId === idNum
          );
          setShippingMethodDetails(data);
          setSelectedId(idNum);
          setValue("name", data.name);
          setValue("isActive", data.isActive, {
            shouldValidate: true,
            shouldTouch: true,
          });
          setValue("cost", data.cost, {
            shouldValidate: true,
            shouldTouch: true,
          });
        },
      });
    }
  }, [id]);

  const methods = useForm<ShippingMethod>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: shippingMethodDetails?.isActive || true },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty },
  } = methods;

  const addShipping = useMutation(createShipping, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Shipping Method ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      resetForm();
      history.push(AppUrls.shipping_methods);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding Shipping Method"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateShipping = useMutation(editShipping, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.shipping_methods);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing Shipping Method"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addShipping?.isLoading || updateShipping?.isLoading,
    });
  }, [addShipping?.isLoading, updateShipping?.isLoading, appDispatch]);

  const onSubmit = (data: ShippingMethod) => {
    let cost = data.cost ? data.cost : 0;
    let newData = {
      ...data,
      cost: parseFloat(cost.toString().replace(/,/g, "")),
    };
    if (!selectedId) {
      addShipping.mutate(newData);
    } else {
      updateShipping.mutate({ ...newData, shippingMethodId: selectedId });
    }
  };

  const resetForm = () => {
    reset({ name: "", cost: NaN, isActive: true });
  };

  return (
    <div className="static-page">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="d-flex flex-row">
              <div className="back-btn">
                <img
                  src={backArrow}
                  alt="back arrow"
                  onClick={() => history.push(AppUrls.shipping_methods)}
                />
              </div>
              <div>
                <p className="">Shipping Methods</p>
              </div>
            </div>
            <div className="d-flex" style={{ marginLeft: "-15px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  resetForm();
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                className="btn btn-primary"
              >
                {!selectedId
                  ? "Add Shipping Method"
                  : "Save Changes" || "Submit"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div>
              {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
                <div className="">
                  <div className="row ">
                    <div className="col-4">
                      <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <>
                            <label>
                              Shipping Method Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              {...field}
                              name="name"
                              className={`form-control ${
                                errors["name"] && "required_field"
                              }`}
                              type="text"
                              required
                            />
                          </>
                        )}
                      />
                      {errors["name"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-1">
                      <label>Active</label>
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="isActive"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="activeCheckbox"
                                  name="isActive"
                                  onChange={() => onChange(!value)}
                                  checked={value}
                                />
                                <span className="slider round"></span>
                              </label>
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <Controller
                        control={control}
                        name="cost"
                        render={({ field }) => (
                          <div>
                            <label>Shipping Cost</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className={`input-group-text dollar-icon bg-white`}
                                >
                                  $
                                </span>
                              </div>

                              <input
                                {...field}
                                min={0}
                                step="any"
                                type="number"
                                className="form-control border-start-0"
                                onBlur={(e) =>
                                  field.onChange(formatInput(e.target.value))
                                }
                              />
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewShipping;
