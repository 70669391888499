import { useState, useContext, useEffect } from "react";
import DispatchContext from "context/DispatchContext";
import {
  getCurrenciesListFromExchangeRateList,
  notificationMessage,
} from "global/helpers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Lookup from "elements/Lookup";
import { HubInfo, InvoiceItem } from "pages/Invoices/Invoices.model";
import { ErrorResponse } from "components/data/Errors.model";
import {
  getInventoryHubsList,
  manualReceipt,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useMutation, useQuery } from "react-query";
import { Hub } from "../Inventory.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumns, QueryResult } from "components/Common.models";
import { getVendors } from "pages/Vendors/Vendors.services";
import { VendorModelDTO } from "pages/Vendors/Vendors.models";
import { addedSerials } from "../../Invoices/Invoices.model";
import InvoiceItems from "pages/Invoices/Invoice Tabs/InvoiceItems";
import Select from "react-select";
import {
  getExchangeRate,
  getLatestRate,
} from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";

const ManualReceipt = () => {
  const appDispatch = useContext(DispatchContext);

  const [openSelectionVendors, setOpenSelectionVendors] =
    useState<boolean>(false);
  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);

  const [selectedVendor, setSelectedVendor] = useState<VendorModelDTO>(null);
  const [selectedHub, setSelectedHub] = useState<HubInfo>(null);

  const [addedItems, setAddedItems] = useState<Array<InvoiceItem>>([]);
  const [addedSerials, setAddedSerials] = useState<addedSerials[]>([]);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const accountNumberColumns: GridColumns[] = [
    {
      field: "vendorNumber",
      headerName: "Vendor No",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "vendorTypeName",
      headerName: "Type",
    },
    {
      field: "creditLimit",
      headerName: "Credit Limit",
    },
  ];

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const { isLoading: isLoadingInventory, data: inventoryHubs } = useQuery<
    QueryResult<Hub>,
    ErrorResponse
  >("inventoryHubsList", getInventoryHubsList);

  const { data: currencies } = useQuery("currencies", getExchangeRate, {
    select(data) {
      const currencyCodes = data?.map((currency) => ({
        currencyCode: currency?.currencyCode,
      }));
      var currencies = getCurrenciesListFromExchangeRateList(currencyCodes);
      return currencies;
    },
  });

  const getRate = useMutation(getLatestRate, {
    async onSuccess(data) {
      if (data) setValue("currencyExchangeRate", data);
      else setValue("currencyExchangeRate", null);
    },
  });

  useEffect(() => {
    if (inventoryHubs && inventoryHubs?.data?.length === 1) {
      setSelectedHub(inventoryHubs?.data[0]);
    }
  }, [inventoryHubs]);

  const { isLoading: isLoadingVendors, data: vendorsList } = useQuery(
    "vendorsList",
    getVendors
  );

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingVendors || isLoadingInventory,
    });
  }, [isLoadingVendors, isLoadingInventory]);

  useEffect(() => {
    if (selectedVendor) {
      setValue("targetId", selectedVendor.vendorId, { shouldDirty: true });
      setOpenSelectionVendors(false);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (selectedHub) {
      setValue("hubId", selectedHub.hubId, { shouldDirty: true });
      setOpenSelectionHubs(false);
    }
  }, [selectedHub]);

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setValue,
    reset,
    control,
  } = methods;

  const onCancel = () => {
    reset();
    setSelectedVendor(null);
    setSelectedHub(null);
    setAddedItems([]);
    setAddedSerials([]);
  };

  const manualReceiptSubmit = useMutation(manualReceipt, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Success`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem with Submission"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (values) => {
    let data = {
      receivingHubId: selectedHub.locationId,
      vendorId: values.targetId,
      remarks: values.remarks,
      items: addedItems.map((item) => {
        let serialsToSend = [];
        addedSerials
          ?.filter((s) => s.itemId === item.itemId)
          ?.map((serial) => {
            serialsToSend.push(serial.serial);
          });
        return {
          itemId: item.itemId,
          quantity: Number(item.invoiceQty),
          cost: Number(item.invoicePrice),
          serials: serialsToSend,
        };
      }),
    };
    manualReceiptSubmit.mutate(data);
  };

  const onCurrencyChange = (e) => {
    if (e) getRate.mutate(e);
    else setValue("currencyExchangeRate", null);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>Inventory Receipt</div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary no-border ${
                  !isDirty && "text-muted"
                }`}
                disabled={!isDirty}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="left-side-mid">
                  <div className="form-group w-50">
                    <label>
                      Receiving Hub <span className="text-danger">*</span>
                    </label>
                    <Lookup
                      onButtonClicked={() => setOpenSelectionHubs(true)}
                      inputName="hubId"
                      isRequired={true}
                      initialData={inventoryHubs?.data?.map((item) => ({
                        ...item,
                        id: item.locationId,
                        name: item.hubId,
                      }))}
                      onSelection={setSelectedHub}
                      inputValue={selectedHub?.hubId}
                      isDisabled={false}
                    />
                  </div>
                  <div className="form-group w-75">
                    <label>Remarks</label>
                    <textarea
                      {...register("remarks")}
                      className="form-control"
                      placeholder="Write your remarks"
                    />
                  </div>
                </div>
                <div className="right-side-mid">
                  <div className="form-group w-50">
                    <label>
                      Vendor No. <span className="text-danger">*</span>
                    </label>
                    <Lookup
                      onButtonClicked={() => setOpenSelectionVendors(true)}
                      inputName="targetId"
                      isRequired={true}
                      initialData={vendorsList?.data?.map((item) => ({
                        ...item,
                        id: item.vendorId,
                        name: item.vendorNumber,
                      }))}
                      onSelection={setSelectedVendor}
                      inputValue={selectedVendor?.vendorNumber}
                      isDisabled={false}
                    />
                    {errors && errors["targetId"] && (
                      <p className="text-danger">Vendor is Required</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-12">
                  <div className="form-group">
                    <label>Currency</label>
                    <Controller
                      control={control}
                      name="currencyId"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          isSearchable={true}
                          menuPlacement="auto"
                          onChange={(selectedOption: any) => {
                            onChange(selectedOption?.value);
                            onCurrencyChange(selectedOption?.value);
                          }}
                          value={
                            currencies?.find((opt) => opt?.value === value) ||
                            null
                          }
                          options={currencies}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="form-group">
                    <label>Exchange Rate</label>
                    <input
                      className="form-control w-50"
                      {...register("currencyExchangeRate")}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <InvoiceItems
                  isEditing={true}
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  hubId={selectedHub?.locationId}
                  isCreating={true}
                  customer={selectedVendor}
                  addedSerials={addedSerials}
                  setAddedSerials={setAddedSerials}
                  isDisabled={!!selectedHub?.locationId}
                  isManualReceipt={true}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Hub"}
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={inventoryHubs?.data}
        columns={hubsColumns}
        setRowClicked={setSelectedHub}
      />
      <SelectionModal
        modal={"Vendor"}
        showModal={openSelectionVendors}
        setShowModal={setOpenSelectionVendors}
        data={vendorsList?.data}
        columns={accountNumberColumns}
        setRowClicked={setSelectedVendor}
      />
    </>
  );
};

export default ManualReceipt;
