import { useEffect, useRef, useState, useContext } from "react";
import {
  getProfitAndLoss,
  getProfitAndLossGrouped,
} from "./AccountingServices";
import "./Ledgers.scss";

import { useReactToPrint } from "react-to-print";

import Accordion from "react-bootstrap/Accordion";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
  formatNumberLossProfit,
  getCurrencyByCode,
} from "global/helpers";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import Lookup from "elements/Lookup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  getCustomizedReportByIdAPI,
  getReportInit,
  SaveCustomizedReportsAPI,
} from "../../reports/services/ReportServices";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { Modal } from "react-bootstrap";
import close from "img/close.svg";
import DispatchContext from "context/DispatchContext";
import { getBaseCurrency } from "components/currency";

const ProfitAndLossPercent = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const appDispatch = useContext(DispatchContext);
  let d = new Date();
  d.setFullYear(d.getFullYear() - 1);

  const history = useHistory();
  const { data: initData } = useQuery("initData", getReportInit);
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { control, getValues, register, reset } = methods;
  const { data: inventoryHubs } = useQuery(
    "inventoryHubsList",
    getInventoryHubsList
  );
  const [startDate, setStartDate] = useState<Date>(d);
  const [endDate, setEndDate] = useState<Date>(null);
  const [showAccounts, setShowAccounts] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState(null);
  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;
  const onAccountSelected = (x) => {
    setSelectedAccount(x);
    setShowAccounts(false);
  };
  const onHubSelected = (hub) => {
    setSelectedHub(hub);
    setOpenSelectionHubs(false);
  };
  const [data, setData] = useState(null);
  const [reportGenerated, setReportGenerated] = useState<boolean>(false);

  const [isGroupByGenerated, setIsGroupByGenerated] = useState<boolean>(false);

  const ProfitAndLoss = useMutation(getProfitAndLoss, {
    onSuccess(data) {
      setIsGroupByGenerated(false);
      setReportGenerated(true);
      setData(([data[0], data[1], data[2]] = [data[0], data[2], data[1]]));
    },
  });

  const ProfitAndLossGrouped = useMutation(getProfitAndLossGrouped, {
    onSuccess(dataReceived) {
      setIsGroupByGenerated(true);
      setReportGenerated(false);
      setData({
        ...dataReceived,
        categories: ([
          dataReceived.categories[0],
          dataReceived.categories[1],
          dataReceived.categories[2],
        ] = [
          dataReceived.categories[0],
          dataReceived.categories[2],
          dataReceived.categories[1],
        ]),
      });
    },
  });

  const generateReport = () => {
    setData(null);
    if (endDate === null) setEndDate(new Date());
    ProfitAndLoss.mutate({
      startDate: fixTimezoneOffset(startDate),
      endDate: fixTimezoneOffset(endDate ?? new Date()),
      customerId: selectedAccount?.customerAccountId ?? null,
      hubId: selectedHub?.locationId ?? null,
      percent: getValues("percent"),
    });
  };

  const generateReportGrouped = () => {
    setData(null);
    if (endDate === null) setEndDate(new Date());
    ProfitAndLossGrouped.mutate({
      startDate: fixTimezoneOffset(startDate),
      endDate: fixTimezoneOffset(endDate ?? new Date()),
      groupingElement: selectedGroupingElement ?? getValues("groupingElement"),
      percent: getValues("percent"),
    });
  };

  const accordionDiv = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => accordionDiv.current,
  });

  const PrintReport = () => {
    handlePrint();
  };

  const hubsColumns = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const customers_columns = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
    },
  ];

  let withPercent = getValues("percent");

  let classname = withPercent ? "border p-2 text-center flex-grow-1" : "";

  const displayAccordion = (category) => {
    if (category?.children?.length === 0 && category?.ledgers?.length === 0)
      return;

    let children =
      category?.children?.length > 0
        ? category?.children
        : category?.ledgers?.map((ledge) => ({
            parentId: ledge.ledgerId,
            categoryId: ledge.ledgerId,
            balance: ledge.balance,
            name: ledge.accountName,
            children: [],
            percentage: ledge.percentage,
          }));

    return (
      <>
        <Accordion
          defaultActiveKey={data?.length > 0 && data[0].categoryId}
          flush
        >
          <Accordion.Item eventKey={category.categoryId}>
            {children?.length > 0 ? (
              <>
                <Accordion.Header>
                  <div className="w-100 d-flex justify-content-between ps-3 pe-3">
                    <p>
                      <b>{category.name}</b>
                    </p>
                    <div className="d-flex">
                      <div className={`balance ${classname}`}>
                        {formatNumberLossProfit(
                          category.balance,
                          currencySymbol
                        )}
                      </div>
                      {withPercent && (
                        <div className={`balance ${classname}`}>
                          %{category.percentage?.toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {children.map((categoryChild) =>
                    displayAccordion(categoryChild)
                  )}
                  {children.length > 0 && (
                    <div className="w-100 d-flex justify-content-between pe-4 ps-3 pt-2 border-top">
                      <p>Total</p>
                      <div className="d-flex">
                        <div className={`${classname}`}>
                          <b>
                            {formatNumberLossProfit(
                              category.balance,
                              currencySymbol
                            )}
                          </b>
                        </div>
                        {withPercent && (
                          <div className={`${classname}`}>
                            <b>%{category.percentage?.toFixed(2)}</b>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Accordion.Body>
              </>
            ) : (
              <>
                <Accordion.Header className="custom-header">
                  <div className="w-100 d-flex justify-content-between ps-3 pe-4">
                    <p>
                      {category.parentId ? (
                        category.number === "05-01" ? (
                          <b>{category.name}</b>
                        ) : (
                          category.name
                        )
                      ) : (
                        <b>{category.name}</b>
                      )}
                    </p>
                    {category.parentId && (
                      <div className="d-flex">
                        <div className={`balance ${classname}`}>
                          {formatNumberLossProfit(
                            category.balance.currencySymbol
                          )}
                        </div>
                        {withPercent && (
                          <div className={`balance ${classname}`}>
                            %{category.percentage?.toFixed(2)}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Accordion.Header>
              </>
            )}
          </Accordion.Item>
        </Accordion>
        {category.number === "05-01" && (
          <>
            <Accordion.Header className="custom-header  ps-3 border-top">
              <div className="w-100 d-flex justify-content-between pe-4">
                <p style={{ color: "blue" }}>GROSS PROFIT</p>
                <div className="d-flex">
                  <div className={`${classname}`} style={{ color: "blue" }}>
                    <b>
                      {formatNumberLossProfit(
                        data[0]?.balance - data[1]?.balance,
                        currencySymbol
                      )}
                    </b>
                  </div>
                  {withPercent && (
                    <div className={`${classname}`} style={{ color: "blue" }}>
                      <b>
                        %
                        {data[0]?.percentage?.toFixed(2) -
                          data[1]?.percentage?.toFixed(2)}
                      </b>
                    </div>
                  )}
                </div>
              </div>
            </Accordion.Header>
          </>
        )}
      </>
    );
  };
  const displayAccordionGrouped = (category) => {
    if (category?.children?.length === 0 && category?.ledgers?.length === 0)
      return;

    let children =
      category?.children?.length > 0
        ? category?.children
        : category?.ledgers?.map((ledge) => ({
            parentId: ledge.ledgerId,
            categoryId: ledge.ledgerId,
            balances: ledge.balances,
            name: ledge.accountName,
            children: [],
            percentages: ledge.percentages,
          }));

    return (
      <>
        <Accordion
          defaultActiveKey={data?.length > 0 && data[0].categoryId}
          flush
        >
          <Accordion.Item eventKey={category.categoryId}>
            {children?.length > 0 ? (
              <>
                <Accordion.Header className="normal-header">
                  <div
                    className="w-100"
                    style={{
                      display: "grid",
                      gridTemplateColumns: `${
                        withPercent ? "1fr" : "2fr"
                      } repeat(${category?.balances?.length}, 1fr)`,
                    }}
                  >
                    <p>{category.name}</p>
                    {category?.balances?.map((b, i) => {
                      return (
                        <div className="d-flex">
                          <div className={`balance ${classname}`}>
                            {formatNumberLossProfit(b, currencySymbol)}
                          </div>
                          {withPercent && (
                            <div className={`balance ${classname}`}>
                              %{category?.percentages[i]?.toFixed(2)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {children.map((categoryChild) =>
                    displayAccordionGrouped(categoryChild)
                  )}
                  {children.length > 0 && (
                    <div
                      className="w-100 border-top pt-2"
                      style={{
                        display: "grid",
                        gridTemplateColumns: `${
                          withPercent ? "1fr" : "2fr"
                        } repeat(${category?.balances?.length}, 1fr)`,
                      }}
                    >
                      <p>
                        <b>Total {category.name}</b>
                      </p>
                      {category?.balances?.map((b, i) => {
                        return (
                          <div className="d-flex">
                            <div className={`balance ${classname}`}>
                              <b>{formatNumberLossProfit(b, currencySymbol)}</b>
                            </div>
                            {withPercent && (
                              <div className={`balance ${classname}`}>
                                <b>%{category?.percentages[i]?.toFixed(2)}</b>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Accordion.Body>
              </>
            ) : (
              <>
                <Accordion.Header className="custom-header">
                  <div
                    className="w-100"
                    style={{
                      display: "grid",
                      gridTemplateColumns: `${
                        withPercent ? "1fr" : "2fr"
                      } repeat(${category?.balances?.length}, 1fr)`,
                    }}
                  >
                    <p>
                      {category.parentId ? (
                        category.number === "05-01" ? (
                          <b>{category.name}</b>
                        ) : (
                          category.name
                        )
                      ) : (
                        <b>{category.name}</b>
                      )}
                    </p>
                    {category?.balances?.map((b, i) => {
                      return (
                        <div className="d-flex">
                          <div className={classname}>
                            {formatNumberLossProfit(b, currencySymbol)}
                          </div>
                          {withPercent && (
                            <div className={`${classname}`}>
                              %{category?.percentages[i]?.toFixed(2)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Header>
              </>
            )}
          </Accordion.Item>
        </Accordion>
        {category.number === "05-01" && (
          <>
            <Accordion.Header className="custom-header border-top">
              <div
                className="w-100"
                style={{
                  display: "grid",
                  gridTemplateColumns: `${withPercent ? "1fr" : "2fr"} repeat(${
                    category?.balances?.length
                  }, 1fr)`,
                }}
              >
                <p style={{ color: "blue" }}>GROSS PROFIT</p>
                {data?.categories[0]?.balances?.map((_b, i) => {
                  return (
                    <div className="d-flex">
                      <div className={`${classname}`}>
                        {formatNumberLossProfit(
                          data?.categories[0]?.balances[i] -
                            data?.categories[1]?.balances[i],
                          currencySymbol
                        )}
                      </div>
                      {withPercent && (
                        <div className={`${classname}`}>
                          %
                          {data?.categories[0]?.percentages[i]?.toFixed(2) -
                            data?.categories[1]?.percentages[i]?.toFixed(2)}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Accordion.Header>
          </>
        )}
      </>
    );
  };

  const SaveCustomizedReport = useMutation(SaveCustomizedReportsAPI, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: `Report saved as ${getValues(
          "newReportTitle"
        )} under Customized Reports`,
      };
      appDispatch({ type: "notification", value: notification });
      setShowConfirmation(false);
    },
  });

  const onCancelClick = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedAccount(null);
    setSelectedHub(null);
  };
  const onBackClick = () => {
    history.push("/reports");
  };

  const confirmCustomization = () => {
    setShowConfirmation(true);
  };

  useQuery([customizedComponentId], getCustomizedReportByIdAPI, {
    enabled: isCustomizedReport,
    onSuccess: (res) => {
      let params = JSON.parse(res.params).filters;
      reset(params);
      if (params.groupingElement) {
        setSelectedGroupingElement(params.groupingElement);
        setTimeout(() => {
          generateReportGrouped();
        });
      } else {
        if (params.hubId)
          setSelectedHub(
            inventoryHubs?.data?.find((hub) => hub.locationId === params.hubId)
          );
        if (params.customerId)
          setSelectedHub(
            initData?.customers?.find(
              (customer) => customer.customerAccountId === params.customerId
            )
          );
        setTimeout(() => {
          generateReport();
        });
      }
    },
  });

  const saveFiltersAsCustomizedReport = () => {
    const newReportTitle = getValues("newReportTitle");

    const query = search.substr(1).split("?");
    query[0] = query[0].split("=")[1];

    const currentComponentId = query[0];
    let values = getValues();
    let filters = { ...values };
    if (startDate) filters["startDate"] = startDate;
    if (endDate) filters["endDate"] = endDate;
    if (selectedAccount)
      filters["customerId"] = selectedAccount?.customerAccountId;
    if (selectedHub) filters["hubId"] = selectedHub?.locationId;
    filters["groupingElement"] = selectedGroupingElement;
    let params = {
      filters: filters,
    };

    let obj = {
      componentId: currentComponentId,
      name: newReportTitle,
      params: JSON.stringify(params),
    };
    SaveCustomizedReport.mutate(obj);
  };

  const [selectedGroupingElement, setSelectedGroupingElement] = useState(null);
  const [isGroupBy, setIsGroupBy] = useState<boolean>(false);

  useEffect(() => {
    if (selectedGroupingElement) {
      setIsGroupBy(true);
    } else {
      setIsGroupBy(false);
    }
  }, [selectedGroupingElement]);

  const options = [
    {
      value: 1,
      label: "Customer",
    },
    {
      value: 2,
      label: "Hub",
    },
    {
      value: 3,
      label: "Supplier",
    },
    {
      value: 4,
      label: "Weekly",
    },
    {
      value: 5,
      label: "Monthly",
    },
    {
      value: 6,
      label: "Yearly",
    },
    {
      value: 7,
      label: "Quarter",
    },
  ];

  useEffect(() => {
    if (!isCustomizedReport) generateReport();
  }, [isCustomizedReport]);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          Profit and Loss
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <FormProvider {...methods}>
            <Accordion
              defaultActiveKey="1"
              className="mb-4 reports-card reports-filters-card"
            >
              <Accordion.Item eventKey={"1"}>
                <Accordion.Header>
                  <b>Filters</b>
                </Accordion.Header>
                <Accordion.Body>
                  {/* {!isCustomizedReport && (
                    <div className="mb-2 text-end">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={confirmCustomization}
                      >
                        Save customisation
                      </button>
                    </div>
                  )} */}
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="me-2">Grouping Element</label>
                      <Controller
                        control={control}
                        name="groupingElement"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            isClearable={true}
                            options={options}
                            isSearchable={true}
                            value={options?.find(
                              (opt) => opt?.value === selectedGroupingElement
                            )}
                            onChange={(val) => {
                              setSelectedGroupingElement(val?.value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-sm-3">
                      <label className="me-2">Start Date</label>
                      <DatePicker
                        onChange={(date) => setStartDate(date)}
                        selected={startDate}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                      />
                    </div>
                    <div className="col-sm-3">
                      <label className="me-2">End Date</label>
                      <DatePicker
                        onChange={(date) => setEndDate(date)}
                        selected={endDate}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                      />
                    </div>
                  </div>

                  <div className="row mt-3 justify-content-start">
                    {!isGroupBy && (
                      <>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Hub ID</label>
                            <Lookup
                              onButtonClicked={() => setOpenSelectionHubs(true)}
                              inputName="hubId"
                              initialData={inventoryHubs?.data?.map((item) => ({
                                ...item,
                                id: item.locationId,
                                name: item.hubId,
                              }))}
                              onSelection={onHubSelected}
                              inputValue={selectedHub?.hubId}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group mb-4">
                            <label>Customer No</label>
                            <Lookup
                              onButtonClicked={() => setShowAccounts(true)}
                              inputName="vendorId"
                              initialData={initData?.customers?.map((x) => ({
                                ...x,
                                id: x.customerAccountId,
                                name: x.accountNumber,
                              }))}
                              onSelection={onAccountSelected}
                              inputValue={selectedAccount?.accountNumber}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className="col-sm-6 d-flex align-items-center"
                      style={{ height: "85px" }}
                    >
                      <label className="checkbox-inline ms-2">
                        <Controller
                          control={control}
                          name="percent"
                          render={({ field: { onChange, value } }) => (
                            <input
                              id="percent"
                              type="checkbox"
                              checked={value}
                              onChange={(val) => onChange(val.target.checked)}
                            />
                          )}
                        />
                        <label className="text-black ms-2" htmlFor="percent">
                          Show Percentage
                        </label>
                      </label>
                      <div className="col-sm">
                        <button
                          type="button"
                          className="btn btn-outline-primary no-border me-3"
                          onClick={onCancelClick}
                        >
                          Clear filters
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={
                            isGroupBy ? generateReportGrouped : generateReport
                          }
                          disabled={!startDate}
                        >
                          Generate Report
                        </button>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </FormProvider>

          {isGroupByGenerated && (
            <>
              {/* <div className="container report-filters-div text-end mb-3">
                                <button type='button'
                                    className="btn btn-success ms-0"
                                    onClick={() => PrintReport()}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer me-3" viewBox="0 0 16 16">
                                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                    </svg>
                                    Print report
                                </button>
                            </div> */}
              <div className="report-page overflow-auto">
                <div
                  className="pt-5"
                  style={{ width: "fit-content", minWidth: "100%" }}
                  ref={accordionDiv}
                >
                  <div className="text-center">
                    <h3 className="mt-0 mb-4">
                      Profit and Loss <br /> as of{" "}
                      {dateFormmaterNoTime(endDate ?? new Date())}
                    </h3>
                  </div>
                  <div className="custom-header">
                    <div
                      className="border-bottom px-0"
                      style={{
                        display: "grid",
                        gridTemplateColumns: `${
                          withPercent ? "1fr" : "2fr"
                        } repeat(${
                          data?.categories[0]?.balances?.length
                        }, 1fr)`,
                        width: "max-content",
                        minWidth: "100%",
                      }}
                    >
                      <p></p>
                      {data?.groupingData?.map((d) => {
                        return (
                          <div>
                            <div className={classname}>
                              <b>{d}</b>
                            </div>
                            <div className="d-flex">
                              <b
                                style={{ width: "110px" }}
                                className={` ${classname}`}
                              >
                                TOTAL
                              </b>
                              {withPercent && (
                                <b className={` ${classname}`}>% OF INCOME</b>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {data?.categories?.map((category) => {
                    return displayAccordionGrouped(category);
                  })}
                  <div className="custom-header pt-2 border-top">
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: `${
                          withPercent ? "1fr" : "2fr"
                        } repeat(${
                          data?.categories[0]?.balances?.length
                        }, 1fr)`,
                      }}
                    >
                      <p>
                        <b>NET EARNINGS</b>
                      </p>
                      {data?.categories[0]?.balances?.map((_b, i) => {
                        return (
                          <div className="d-flex">
                            <div className={`${classname}`}>
                              <b>
                                {formatNumberLossProfit(
                                  data?.categories[0]?.balances[i] -
                                    data?.categories[1]?.balances[i] -
                                    data?.categories[2]?.balances[i],
                                  currencySymbol
                                )}
                              </b>
                            </div>
                            {withPercent && (
                              <div className={`${classname}`}>
                                <b>
                                  %
                                  {data?.categories[0]?.percentages[i]?.toFixed(
                                    2
                                  ) -
                                    data?.categories[1]?.percentages[
                                      i
                                    ]?.toFixed(2) -
                                    data?.categories[2]?.percentages[
                                      i
                                    ]?.toFixed(2)}
                                </b>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {reportGenerated && (
            <>
              {/* <div className="container report-filters-div text-end mb-3">
                                <button type='button'
                                    className="btn btn-success ms-0"
                                    onClick={() => PrintReport()}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer me-3" viewBox="0 0 16 16">
                                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                    </svg>
                                    Print report
                                </button>
                            </div> */}
              <div className="container w-75 report-paper custom-report">
                <div className="container w-100 pt-5" ref={accordionDiv}>
                  <div className="text-center">
                    <h3 className="mt-0 mb-4">
                      Profit and Loss <br /> as of{" "}
                      {dateFormmaterNoTime(endDate ?? new Date())}
                    </h3>
                  </div>
                  <div className="w-100 d-flex justify-content-end border-bottom p-2 pb-0">
                    <div className="p-2">
                      <b style={{ width: "110px" }} className={`${classname}`}>
                        TOTAL
                      </b>
                      {withPercent && (
                        <b className={`${classname}`}>% OF INCOME</b>
                      )}
                    </div>
                  </div>
                  {data?.map((category) => {
                    return displayAccordion(category);
                  })}
                </div>
                <div
                  className={`w-100 d-flex justify-content-between px-3 ${
                    data?.length > 0 &&
                    data[0]?.balance - data[1]?.balance - data[2]?.balance < 0
                      ? "text-danger"
                      : "text-success"
                  } border-top pt-3`}
                >
                  <p>
                    <b>NET EARNINGS</b>
                  </p>
                  <div className="d-flex">
                    <div className={`${classname}`}>
                      <b>
                        {data?.length > 0 &&
                          formatNumberLossProfit(
                            data[0]?.balance -
                              data[1]?.balance -
                              data[2]?.balance,
                            currencySymbol
                          )}
                      </b>
                    </div>
                    {withPercent && (
                      <div className={`${classname}`}>
                        <b>
                          {data?.length > 0 &&
                            `%${
                              data[0]?.percentage?.toFixed(2) -
                              data[1]?.percentage?.toFixed(2) -
                              data[2]?.percentage?.toFixed(2)
                            }`}
                        </b>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <SelectionModal
        modal={"Hub"}
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={inventoryHubs?.data}
        columns={hubsColumns}
        setRowClicked={onHubSelected}
      />
      <SelectionModal
        modal={"Customers"}
        showModal={showAccounts}
        setShowModal={setShowAccounts}
        data={initData?.customers}
        columns={customers_columns}
        setRowClicked={onAccountSelected}
      />
      <Modal
        className="confirmation-modal confirmation-primary"
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered
      >
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <div className="confirmation-modal-title">
            Save filters configuration
          </div>
          <img
            src={close}
            alt=""
            className="confirmation-modal-close"
            onClick={() => setShowConfirmation(false)}
          />
        </div>
        <div className="mx-4">
          <Modal.Body className="px-0 py-0">
            <div className="form-group">
              <label className="text-primary">New Report Title</label>
              <input
                {...register("newReportTitle")}
                className="form-control"
                type="text"
              />
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn btn-outline-primary ms-0"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                onClick={saveFiltersAsCustomizedReport}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ProfitAndLossPercent;
