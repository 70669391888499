import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getBanks } from "./AccountingServices";
import { checkIfAllowed, getCurrencyByCode } from "global/helpers";
import StateContext from "context/StateContext";
import * as AppUrls from "constants/AppUrls";
import * as colDefs from "../../constants/colDefs";

import { useHistory } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import { getBaseCurrency } from "components/currency";

const BanksList = ({ match }) => {
  const history = useHistory();
  const appState = useContext(StateContext);
  const [filter, setFilter] = useState({
    params: "",
  });
  const [dataGrid, setDataGrid] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataMutation = useMutation("data", getBanks);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "accountName",
      headerName: "Account Name",
      resizable: true,
    },
    {
      field: "bankAccountNumber",
      headerName: "Bank Account Number",
      maxWidth: colDefs.bankNumber,
      resizable: true,
    },
    {
      field: "type",
      headerName: "Type",
      resizable: true,
      maxWidth: colDefs.booleanWidth,
    },
    {
      field: "routingNumber",
      headerName: "Routing Number",
      maxWidth: colDefs.m_lgWidth,

      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      maxWidth: colDefs.balanceWidth,

      resizable: true,
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const onRowClicked = (e) => {
    history.push(`${AppUrls.banks}/${e.bankId}`);
  };

  const onAddExpense = () => {
    history.push(AppUrls.add_bank);
  };

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Banks</p>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <button className="btn btn-primary" onClick={onAddExpense}>
              Add Bank
            </button>
          )}
        </div>
        <div className="page-content-wrapper">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid-container">
            <AgGrid
              columns={columns}
              filters={filter}
              fetchMethod={dataMutation}
              setrowClicked={onRowClicked}
              data={dataGrid}
              setDataGrid={setDataGrid}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BanksList;
