import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import AG from "elements/AG";
import { approveRma, completeRma, getRmaDetails, receiveRma, rejectRma, replaceRma } from "./Services/adminServices";
import { reasonEnum, returnEnum, RMAStatus } from "./StatusEnum";
import { formatNumber, notificationMessage } from "global/helpers";
import DispatchContext from "context/DispatchContext";
import { useContext, useRef, useState } from "react";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import { getSerialNumbersList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import ListItem from "components/SelectionListModal/ListSelection.models";

const RmaDetails = () => {

    const history = useHistory()
    const { id } = useParams();
    const appDispatch = useContext(DispatchContext)
    const selectedItem = useRef(null)
    const [showSerialSelection, setShowSerialSelection] = useState<boolean>(false)
    const [selectedSerials, setSelectedSerials] = useState<number[]>(null)
    const [replacedItems, setReplacedItems] = useState([])

    const onBackClick = () => {
        history.push(AppUrls.ecommerce_rma)
    }

    const { data } = useQuery('order', () => getRmaDetails(id),
        {
            enabled: !!id,
            onSuccess(data) {
                setReplacedItems(data.items.map(item => ({ ...item, serials: [] })))
            }
        }
    )

    const serialsMutation = useMutation(getSerialNumbersList);

    const rejectMutation = useMutation(rejectRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: 'RMA rejected successfully'
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.ecommerce_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem rejecting rma')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const approveMutation = useMutation(approveRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: 'RMA approved successfully'
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.ecommerce_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem approving rma')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const receiveMutation = useMutation(receiveRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: 'RMA received successfully'
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.ecommerce_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem receiving rma')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const replaceMutation = useMutation(replaceRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: 'RMA replaced successfully'
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.ecommerce_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem replacing rma')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const completeMutation = useMutation(completeRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: 'RMA completed successfully'
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.ecommerce_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem completing rma')
            };
            appDispatch({ type: "notification", value: notification })
        }
    })

    const columns = [
        {
            field: "itemNumber",
            headerName: "Item",
        },
        {
            field: "description",
            headerName: "Item Description",
        },
        {
            field: "quantity",
            headerName: "QTY",
        },
        {
            field: "price",
            headerName: "Price",
            valueGetter: (params) => { return `$${params.data?.price?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "subtotal",
            headerName: "Subtotal",
            cellRendererFramework: (params) => formatNumber(params.data.quantity * params.data.price),
        },
        {
            field: "serials",
            headerName: "Serial Number",
            cellRenderer: (params) => {
                if (Array.isArray(params.value) && params.value.length > 0) {
                    let label = params.value[0]
                    if (params.value.length > 1)
                        label += ` +${params.value.length - 1}`
                    return label
                }
                return "-"
            }
        },
        {
            field: "reason",
            headerName: "Reason",
            cellRenderer: (params) => params.value !==3 ? reasonEnum[params.value] : `${reasonEnum[params.value]} - ${params.data?.otherReason}`,
        },
        {
            field: "action",
            headerName: "Action",
            hide: !(data?.status === 2 && data?.rmaProcessStatus === 1 && data?.returnMethod === 1),
            cellRendererFramework: (params) => {
                return (
                    params?.data && params?.data?.serials.length > 0
                        ?
                        <button type='button' className='btn btn-link text-decoration-none ms-0 ps-0' onClick={() => addSerial(params.data)}>Add Serial</button>
                        :
                        "-"
                )
            },
        }
    ];

    const addSerial = (item) => {
        selectedItem.current = item
        setShowSerialSelection(true)
        serialsMutation.mutate({ itemNumber: item.itemNumber, hubId: data?.hubId })
    }

    const onRejectClick = () => {
        rejectMutation.mutate(id)
    }

    const onApproveClick = () => {
        approveMutation.mutate(id)
    }

    const onReceiveClick = () => {
        receiveMutation.mutate(id)
    }

    const onCompleteClick = () => {
        completeMutation.mutate(id)
    }

    const onReplaceClick = () => {
        let serialsTotal = 0
        let replacedTotal = 0
        for (let item of data.items)
            serialsTotal += item.serials.length
        for (let item of replacedItems)
            replacedTotal += item.serials.length
        if (replacedTotal !== serialsTotal) {
            let notification = {
                variant: "danger",
                msg: "Please assign new serial numbers to the replaced serialized items"
            };
            appDispatch({ type: "notification", value: notification })
        } else {
            let newData = {
                id,
                date: data?.date,
                orderDate: data?.date,
                shippingInfoId: data?.shippingInfo?.shippingInfoId,
                shippingMethodId: data?.shippingMethod?.shippingMethodId,
                items: replacedItems
            }
            replaceMutation.mutate(newData)
        }

    }

    const selectSerial = (items: ListItem[]) => {
        setSelectedSerials(items.map(i => i.id));
        setReplacedItems(data.items.map(item => {
            if (item.itemOrderId === selectedItem.current.itemOrderId)
                return { ...item, serials: items.map(i => i.id), serialReplaced: true }
            else if (item.serialReplaced)
                return { ...item }
            return { ...item, serials: [] }
        }))
        setShowSerialSelection(false);
    }

    return (
        <>
            <div className="static-page">
                <div className="page-title page-title-editable">
                    <div className="back-btn " onClick={onBackClick}>
                        <img src={backArrow} alt="back arrow" />
                        {data?.rmaNumber}
                    </div>
                    <div className="d-flex justify-content-between ">
                        {(data?.status === 2 && data?.returnMethod === 2 || data?.rmaProcessStatus === 1 && data?.returnMethod === 3) &&
                            <button className="btn btn-outline-primary">
                                CM issued for validation
                            </button>
                        }
                        {data?.status === 2 && (data?.returnMethod === 2 || (data?.returnMethod === 1 && data?.rmaProcessStatus === 2) || (data?.returnMethod === 3 && data?.rmaProcessStatus === 1)) &&
                            <button className="btn btn-primary" onClick={onCompleteClick}>
                                Complete
                            </button>
                        }
                        {data?.status === 1 &&
                            <button className="btn btn-success" onClick={onApproveClick}>
                                Approve
                            </button>
                        }
                        {data?.status === 1 &&
                            <button
                                type="button"
                                className={`btn btn-danger`}
                                onClick={onRejectClick}
                            >
                                Reject
                            </button>
                        }
                        {data?.status === 2 && data?.returnMethod !== 2 && !data?.rmaProcessStatus &&
                            <button className="btn btn-primary" onClick={onReceiveClick}>
                                Receive
                            </button>
                        }
                        {data?.status === 2 && data?.rmaProcessStatus === 1 && data?.returnMethod === 1 &&
                            <button className="btn btn-primary" onClick={onReplaceClick}>
                                Replace
                            </button>
                        }
                    </div>
                </div>
                <div className="page-content-wrapper">
                    <div className="page-content">
                        <div className="row  gx-5">
                            <div className="col-lg-8 col-sm-12">
                                <div className="row gx-5 gy-4">
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Order No.</label>
                                        <div>{data?.orderNumber}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>RMA No.</label>
                                        <div>{data?.rmaNumber}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>RMA Date</label>
                                        <div>{data?.date && new Date(data?.date).toLocaleString()}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Account</label>
                                        <div>{data?.accountNumber}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Hub ID</label>
                                        <div>{data?.hubKey}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>Status</label>
                                        <div>{RMAStatus[data?.status]}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>Company</label>
                                        <div className="fw-bold mt-3">{data?.billingInfo?.company}</div>
                                        <div className="mt-3">
                                            {data?.billingInfo?.address?.contact && data?.billingInfo?.address?.contact.length > 0 ? data?.billingInfo?.address?.contact : <span className="text-muted fst-italic">Contact</span>}
                                            <br />
                                            {data?.billingInfo?.address?.address && data?.billingInfo?.address?.address.length > 0 ? data?.billingInfo?.address?.address : <span className="text-muted fst-italic">Address</span>}
                                            <br />
                                            {data?.billingInfo?.address?.city && data?.billingInfo?.address?.city.length > 0 ? data?.billingInfo?.address?.city : <span className="text-muted fst-italic">City</span>} {data?.billingInfo?.address?.state && data?.billingInfo?.address?.state.length > 0 ? data?.billingInfo?.address?.state : <span className="text-muted fst-italic">State</span>} {data?.billingInfo?.address?.zipCode && data?.billingInfo?.address?.zipCode.length > 0 ? data?.billingInfo?.address?.zipCode : <span className="text-muted fst-italic">Zip Code</span>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-3">
                                        <label>Ship To</label>
                                        <div className="fw-bold mt-3">{data?.shippingInfo?.company}</div>
                                        <div className="mt-3">
                                            {data?.shippingInfo?.address?.contact && data?.shippingInfo?.address?.contact.length > 0 ? data?.shippingInfo?.address?.contact : <span className="text-muted fst-italic">Contact</span>}
                                            <br />
                                            {data?.shippingInfo?.address?.address && data?.shippingInfo?.address?.address.length > 0 ? data?.shippingInfo?.address?.address : <span className="text-muted fst-italic">Address</span>}
                                            <br />
                                            {data?.shippingInfo?.address?.city && data?.shippingInfo?.address?.city.length > 0 ? data?.shippingInfo?.address?.city : <span className="text-muted fst-italic">City</span>} {data?.shippingInfo?.address?.state && data?.shippingInfo?.address?.state.length > 0 ? data?.shippingInfo?.address?.state : <span className="text-muted fst-italic">State</span>} {data?.shippingInfo?.address?.zipCode && data?.shippingInfo?.address?.zipCode.length > 0 ? data?.shippingInfo?.address?.zipCode : <span className="text-muted fst-italic">Zip Code</span>}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="col-lg-6 col-sm-12 mt-0">
                                        <label>Invoice No.</label>
                                        <div>{data?.invoiceNumber}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mt-0">
                                        <label>Ship Via</label>
                                        <div>{data?.shippingMethod?.name ?? <span className="text-muted fst-italic">Ship via</span>}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>Invoice Date</label>
                                        <div>{data?.invoiceDate ? new Date(data.invoiceDate).toLocaleDateString() : <span className="text-muted fst-italic">Invoice Date</span>}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-4">
                                        <label>Ship by</label>
                                        <div>{data?.representativeName ?? <span className="text-muted fst-italic">Ship by</span>}</div>
                                    </div>
                                    <hr className="my-4" />
                                    <div className="col-lg-6 col-sm-12">
                                        <label>Cust Po</label>
                                        <div>{data?.customerPO}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-4">
                                        <label>Project No.</label>
                                        <div>{data?.projectNumber ?? <span className="text-muted fst-italic">Project No.</span>}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className='row mt-4'>
                                    <div className='col-lg-6 col-sm-12 px-4'>
                                        <div className='d-flex'>
                                            <div className='text-secondary'>TOTAL QTY</div>
                                            <div className='flex-grow-1 text-center'>{data?.totalQuantity}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-12'>
                                        <div className='d-flex'>
                                            <div className='text-secondary'>TOTAL ITEMS to return</div>
                                            <div className='flex-grow-1 text-center'>{data?.totalItems}</div>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <hr />
                                    </div>
                                    <div className='col-sm-12 d-flex justify-content-between px-4'>
                                        <div className='text-secondary'>TAX AMOUNT</div>
                                        <div><span>{formatNumber(data?.tax)}</span></div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className='section mt-3 p-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='fs-5'>ORDER TOTAL</div>
                                                <div className='text-success fs-4'>{formatNumber(data?.subtotal)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='section p-4 pt-0'>
                                    <div className='row gx-5 gy-4'>
                                        <div className='col-lg-6 col-sm-12'>
                                            <label>Return Method </label>
                                            <div>{returnEnum[data?.returnMethod]}</div>
                                        </div>
                                        <div className='col-lg-6 col-sm-12'>
                                            <label>Needs Cm </label>
                                            <div>{data?.needsCM ? "Yes" : "No"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='section p-4 pt-0'>
                                    <div className='row gx-5 gy-4 pt-4'>
                                        <label>Order Remark</label>
                                        <div>
                                            {data?.remarks}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-450 mt-4">
                            <AG data={data?.items} columns={columns} autoColumns={true} />
                        </div>
                    </div>
                </div>
            </div>
            <ListSelectionModal
                showModal={showSerialSelection}
                title="Serials"
                data={serialsMutation.data ? serialsMutation.data?.data?.map(serial => { return { title: serial.serialNo, id: serial.serialId } }) : []}
                selectedValues={selectedSerials ?? []}
                onSelectData={selectSerial}
                onExit={() => setShowSerialSelection(false)}
                selectValue={selectedItem?.current?.serials?.length}
                isInvoice={true}
                maxValue={selectedItem?.current?.serials?.length ?? 0}
            />
        </>
    )
}

export default RmaDetails