import { useEffect, useState, useContext } from "react";
import DispatchContext from "context/DispatchContext";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zipCode from "global/regExp/zipcode";
import { states } from "components/data/States";
import { getUserProfile, updateUserProfile } from "./userServices";
import { notificationMessage } from "global/helpers";
import { UPDATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import SectionTitle from "elements/SectionTitle";
import ChangePassword from "./ChangePassword/ChangePassword";
import StateContext from "context/StateContext";
import {
  CheckIn,
  CheckOut,
} from "pages/Payroll/PayrollServices/payrollServices";
import { Modal } from "react-bootstrap";
import Close from "img/close.svg";

const Profile = () => {
  const appState = useContext(StateContext);

  const [isEditUserProfile, setIsEditUserProfile] = useState<boolean>(false);
  const appDispatch = useContext(DispatchContext);
  const [savedChanges, setSavedChanges] = useState<boolean>(false);
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    reset,
    register,
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    isLoading: isLoadingUserProfile,
    error: errorLoadingUserProfile,
    data: userProfile,
  } = useQuery("userProfile", getUserProfile, {
    onSuccess(data) {
      setUsername(data?.data.email);
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingUserProfile });
  }, [isLoadingUserProfile, appDispatch]);

  useEffect(() => {
    if (errorLoadingUserProfile) {
      return;
    }
    if (userProfile !== undefined) {
      reset({
        address: userProfile?.data.address,
        address1: userProfile?.data.address1,
        city: userProfile?.data.city,
        dateOfBirth: userProfile.data.dateOfBirth
          ? new Date(userProfile.data.dateOfBirth)
          : null,
        firstName: userProfile?.data.firstName,
        lastName: userProfile?.data.lastName,
        middleName: userProfile?.data.middleName,
        mobileNumber: userProfile?.data.mobileNumber,
        homeNumber: userProfile?.data.homeNumber,
        email: userProfile?.data.email,
        role: userProfile?.data.roles[0].roleKey,
        state: userProfile?.data.state && {
          label:
            states.filter(
              (obj) =>
                // eslint-disable-next-line
                obj.value == userProfile?.data.state
            ).length > 0
              ? states.filter(
                  (obj) =>
                    // eslint-disable-next-line
                    obj.value == userProfile?.data.state
                )[0].label
              : "",
          value: userProfile?.data.state ? userProfile?.data.state : "",
        },
        zipCode: userProfile?.data.zipCode,
      });
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [isLoadingUserProfile, errorLoadingUserProfile, userProfile, states]);

  const userFirstLastName = () => {
    return getValues("firstName") !== undefined &&
      getValues("lastName") !== undefined
      ? getValues("firstName") + " " + getValues("lastName")
      : "";
  };

  const editUserProfile = useMutation(updateUserProfile, {
    async onSuccess() {
      setSavedChanges(true);
      let notification = {
        variant: "success",
        msg: `Profile ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsEditUserProfile(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing profile"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitEditUserProfile = (data) => {
    editUserProfile.mutate({ profileData: data });
  };

  const onSubmit = (data) => {
    if (errors?.length > 0) {
      setIsEditUserProfile(true);
    } else {
      submitEditUserProfile(data);
    }
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: editUserProfile.isLoading });
  }, [editUserProfile.isLoading, appDispatch]);

  const CheckInAPI = useMutation(CheckIn, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: `Checked-in`,
      };
      appDispatch({ type: "notification", value: notification });
      setShowCheck(0);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem checking in"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const CheckOutAPI = useMutation(CheckOut, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: `Checked-out`,
      };
      appDispatch({ type: "notification", value: notification });
      setShowCheck(0);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem checking out"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const [showCheck, setShowCheck] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submitCheck = () => {
    if (showCheck === 1) {
      CheckInAPI.mutate({ email: username, password: password });
    } else {
      CheckOutAPI.mutate({ email: username, password: password });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div className="back-btn ">
            {isEditUserProfile ? "Editing" : userFirstLastName()}
          </div>
          <div>
            {isEditUserProfile ? (
              <>
                <button
                  role="button"
                  className="btn btn-outline-primary no-border"
                  onClick={(e) => {
                    e.preventDefault();
                    if (isDirty === true && savedChanges === false) {
                      reset();
                      setIsEditUserProfile(false);
                    } else {
                      setIsEditUserProfile(false);
                    }
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Save Changes
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowCheck(1)}
                >
                  Check In
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => setShowCheck(2)}
                >
                  Check out
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => setIsChangePassword(true)}
                >
                  Change Password
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditUserProfile(true);
                  }}
                >
                  Edit
                </button>
              </>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row   gx-5 employee-details">
              <div className="col-lg-7 col-md-12 col-sm-12">
                {appState?.userData?.forcePasswordChange && (
                  <div className="alert alert-danger">
                    Please change your password before proceeding.
                  </div>
                )}
                <SectionTitle editable={false} title="User Info" />
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          {...register("firstName", { required: true })}
                          name="firstName"
                          type="text"
                          className="form-control"
                          disabled={isEditUserProfile === true ? false : true}
                        />
                        {errors["firstName"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>Middle Name</label>
                        <input
                          {...register("middleName")}
                          name="middleName"
                          type="text"
                          className="form-control"
                          disabled={isEditUserProfile === true ? false : true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          {...register("lastName", { required: true })}
                          name="lastName"
                          type="text"
                          className="form-control"
                          disabled={isEditUserProfile === true ? false : true}
                        />
                        {errors["lastName"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>Date of Birth</label>
                        <Controller
                          control={control}
                          name="dateOfBirth"
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              onChange={onChange}
                              selected={value ? value : null}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={15}
                              scrollableYearDropdown
                              disabled={
                                isEditUserProfile === true ? false : true
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          {...register("email")}
                          type="text"
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>Role</label>
                        <input
                          {...register("role")}
                          type="text"
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 contact-details">
                <h4>Contact Info</h4>
                <div className="">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Cell No.</label>
                      <Controller
                        control={control}
                        name="mobileNumber"
                        render={({ field: { onChange, value } }) => (
                          <PhoneInput
                            value={value}
                            onChange={onChange}
                            country={"us"}
                            disabled={isEditUserProfile === true ? false : true}
                            inputStyle={{ width: "100%" }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Home No.</label>
                      <Controller
                        control={control}
                        name="homeNumber"
                        render={({ field: { onChange, value } }) => (
                          <PhoneInput
                            value={value}
                            onChange={onChange}
                            country={"us"}
                            disabled={isEditUserProfile === true ? false : true}
                            inputStyle={{ width: "100%" }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <h4 className="mt-5">Address</h4>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Address</label>
                      <input
                        {...register("address")}
                        name="address"
                        type="text"
                        className="form-control"
                        disabled={isEditUserProfile === true ? false : true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Address 1</label>
                      <input
                        {...register("address1")}
                        name="address1"
                        type="text"
                        className="form-control"
                        disabled={isEditUserProfile === true ? false : true}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          {...register("city")}
                          name="city"
                          type="text"
                          className="form-control"
                          disabled={isEditUserProfile === true ? false : true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <div className="form-group">
                        <label>State</label>
                        <Controller
                          control={control}
                          name="state"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={states}
                              isSearchable={true}
                              value={value}
                              onChange={onChange}
                              menuPlacement="auto"
                              isClearable
                              placeholder="Choose"
                              isDisabled={
                                isEditUserProfile === true ? false : true
                              }
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4">
                      <div className="form-group">
                        <label>Zip Code</label>
                        <input
                          {...register("zipCode", {
                            pattern: {
                              value: zipCode,
                              message:
                                "Entered value does not match zip code format",
                            },
                          })}
                          name="zipCode"
                          type="text"
                          className="form-control"
                          disabled={isEditUserProfile === true ? false : true}
                        />
                        {errors["zipCode"] && (
                          <span className="text-danger">
                            {errors.zipCode.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ChangePassword
        isChangePassword={isChangePassword}
        setIsChangePassword={setIsChangePassword}
      />
      {showCheck && (
        <Modal show={showCheck} onHide={setShowCheck} centered size="sm">
          <Modal.Header className="d-flex justify-content-end border-bottom-0">
            <img
              src={Close}
              alt="close icon"
              className=""
              role="button"
              onClick={() => {
                setShowCheck(false);
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <label>Username</label>
                <input
                  className="form-control"
                  type="text"
                  value={username}
                  autoComplete="off"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12  ">
                <label>Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="p-2 mt-3 text-end">
              <button
                className={`btn btn-${showCheck === 1 ? "success" : "danger"}`}
                type="button"
                onClick={submitCheck}
              >
                {showCheck === 1 ? "Check In" : "Check Out"}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Profile;
