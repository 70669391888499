import AgGrid from 'elements/AgGrid';
import { getBanks } from 'pages/Accounting/AccountingServices';
import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { applyPayment, getPayment } from './PayrollServices/payrollServices';
import { getPaymentMethods } from 'pages/Invoices/InvoicesServices/InvoicesServices';
import { fixTimezoneOffset, setFriday, setToMonday } from 'global/helpers';
import DispatchContext from 'context/DispatchContext';
import { getUserProfile } from 'pages/User/userServices';

const Payment = () => {

    const firstSet = useRef(true)
    const appDispatch = useContext(DispatchContext);

    const { data: paymentMethods } = useQuery("paymentMethods", getPaymentMethods);

    const { data: banks } = useQuery("banks", getBanks)

    const { data: userProfile } = useQuery("userProfile", getUserProfile);

    const banksOpt = banks?.data?.map(e => { return { value: e.bankId, label: e.accountName } })

    const [selectedBank, setSelectedBank] = useState(banksOpt?.length > 0 ? banksOpt[0] : null)
    const [startDate, setStartDate] = useState<Date>(setToMonday());
    const [endDate, setEndDate] = useState<Date>(setFriday());
    const [issueDate, setIssueDate] = useState<Date>(new Date());
    const [gridRowChecked, setGridRowChecked] = useState([])

    const columns = [
        {
            headerName: "",
            field: "RowSelect",
            checkboxSelection: true,
            floatingFilter: false,
            filter: false,
            width: "45"
        },
        {
            field: "employeeId",
            headerName: "Employee Id",
        },
        {
            field: "employeeName",
            headerName: "Employee Name",
        },
        {
            field: "role",
            headerName: "Role",
            valueGetter: (params) => { return params?.data?.role[0] }
        },
        {
            field: "billingRate",
            headerName: "Billing Rate/Hour",
        },
        {
            field: "employeePaymentMethod",
            headerName: "Payment Method",
            valueGetter: (params) => { return paymentMethods?.find(e => e.paymentMethodId === params?.data?.employeePaymentMethod)?.name }
        },
        {
            field: "totalHours",
            headerName: "Total Hours",
            valueGetter: (params) => { return Math.floor(params?.data?.totalHours) }
        },
        {
            field: "totalPay",
            headerName: "Total Pay",
        },
        {
            field: "totalTax",
            headerName: "Total Taxes",
        },
        {
            field: "netPay",
            headerName: "NET Pay",
        },
    ];

    const fetchAPI = useMutation(getPayment, {
        onSuccess(data) {
            if (firstSet.current) {
                let start = data?.data?.startDate
                setStartDate(start)
                let end = data?.data?.endDate
                setEndDate(end)
                firstSet.current = false
            }
        }
    })

    useEffect(() => {
        if (startDate && endDate) {
            fetchAPI.mutate({ startDate: startDate, endDate: endDate })
        }
    }, [startDate, endDate])


    const applyAPI = useMutation(applyPayment, {
        onSuccess() {
            let notification = {
                variant: "success",
                msg: "Payment Applied successfully"
            };
            appDispatch({ type: "notification", value: notification });
        }
    })

    const issuePayroll = () => {
        const obj = {
            issueDate: fixTimezoneOffset(issueDate),
            payPeriodEnd: fixTimezoneOffset(endDate),
            payPeriodStart: fixTimezoneOffset(startDate),
            runPayrollEntries: gridRowChecked.map(e => {
                return {
                    userId: e.userId,
                    billingRate: e.billingRate,
                    employeePaymentMethod: e.employeePaymentMethod,
                    totalHours: e.totalHours,
                    totalTax: e.totalTax
                }
            }),
            bankId: selectedBank?.value
        }
        applyAPI.mutate(obj)
    }

    return (
        <div className="grid-page">
            <div className="page-title page-title-editable">
                <p>Payment</p>
                {userProfile?.data?.isAdmin &&
                    <div>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={issuePayroll}
                            disabled={!startDate || !endDate || !issueDate || gridRowChecked.length === 0 || !selectedBank}
                        >
                            Issue Payroll
                        </button>
                    </div>
                }
            </div>
            <div className="page-content-wrapper">
                <div className='grid-container has-filters'>
                    <div className='section d-flex align-items-end'>
                        <div className="form-group">
                            <label className="me-2">Bank <span className='text-danger'>*</span></label>
                            <Select
                                options={banksOpt}
                                isSearchable={true}
                                value={selectedBank}
                                onChange={(e) => setSelectedBank(e)}
                                className="w-200"
                            />
                        </div>
                        <div className="form-group ms-3 w-200">
                            <label className="me-2">Start Date <span className='text-danger'>*</span></label>
                            <DatePicker
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                onChange={(e) => { setStartDate(e) }}
                                selected={startDate}
                                maxDate={endDate}
                            />
                        </div>
                        <div className="form-group ms-3 w-200">
                            <label className="me-2">End Date <span className='text-danger'>*</span></label>
                            <DatePicker
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                onChange={(e) => { setEndDate(e) }}
                                selected={endDate}
                                minDate={startDate}
                            />
                        </div>
                        <div className="form-group ms-3 w-200">
                            <label className="me-2">Issue Date <span className='text-danger'>*</span></label>
                            <DatePicker
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                onChange={(e) => { setIssueDate(e) }}
                                selected={issueDate}
                            />
                        </div>
                    </div>
                    <AgGrid
                        data={fetchAPI.data?.data?.entries}
                        columns={columns}
                        setRowChecked={setGridRowChecked}
                        multipleSelection={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Payment