import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import { RepresentativeModelDTO } from "./Representative.models";

const representativesUrl = "representatives";
/**
 * Get all representatives
 * @returns representative array
 */
export const getRepresentatives = async (params) => {
  let extraParams = "";
  if (params.PageSize) {
    extraParams += `&PageSize=${params.PageSize}`;
    extraParams += `&PageStart=${
      isNaN(params.PageStart) ? 0 : params.PageStart
    }`;
  }
  if (params?.searchQuery) extraParams += `&searchQuery=${params?.searchQuery}`;

  const response = await api.get<QueryResult<RepresentativeModelDTO>>(
    `${representativesUrl}?${extraParams}`
  );
  return response.data;
};
/**
 * Get a single representative by id
 * @param repId representative id
 * @returns represnetative entity
 */
export const getRepresentativeById = async (repId: number) => {
  const response = await api.get<RepresentativeModelDTO>(
    `${representativesUrl}/${repId}`
  );
  return response.data;
};
/**
 * Adds a new representative
 * @param data represnetative
 * @returns represnetative entity
 */
export const createRepresentative = async (data: RepresentativeModelDTO) => {
  const response = await api.post<RepresentativeModelDTO>(
    representativesUrl,
    data
  );
  return response.data;
};
/**
 * Edit representative data
 * @param data represnetative
 * @returns represnetative entity
 */
export const editRepresentative = async (data: RepresentativeModelDTO) => {
  const response = await api.put<RepresentativeModelDTO>(
    `${representativesUrl}/${data.repId}`,
    data
  );
  return response.data;
};
/**
 * Delete representative by id
 * @param repId represnetative id
 * @returns true/false
 */
export const deleteRepresentative = async (repId: number) => {
  return await api.delete<boolean>(`${representativesUrl}/${repId}`);
};
