import DateGrid from "components/DateFilter/DateGrid";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AgGrid from "elements/AgGrid";
import {
  checkIfAllowed,
  dateFormmaterNoTime,
  formatInput,
  getCurrencyByCode,
} from "global/helpers";
import { ReactComponent as CloseLogo } from "img/close.svg";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as colDefs from "../../constants/colDefs";
import { getLedgers, getOperations } from "./AccountingServices";
import LedgerSelection from "./LedgerSelection";
import OperationsModal from "./OperationsModal";
export enum OrderTypeEnum {
  "Invoice" = 1,
  "Credit Memo" = 2,
  "Customer Payment" = 3,
  "Purchase Order" = 4,
  "Purchase Order Return" = 5,
  "Vendor Payment" = 6,
  "Credit Memo Refund" = 7,
  "Expense" = 8,
  "Cheque" = 9,
  "Fixed Asset" = 10,
  "Bill" = 11,
  "Sales Order" = 12,
  "Manual Inventory Receive" = 13,
  "Manual Inventory Issue" = 14,
  "RMA" = 15,
  "Vendor RMA" = 16,
  "Reverse CustomerPayment" = 17,
  "Bank Fee Operation" = 18,
  "Fraud" = 19,
  "Issued Check Cleared" = 20,
  "Interest" = 21,
  "Deposit Check Cleared" = 22,
  "Deposit In Transit" = 23,
  "Issued Check In Transit" = 24,
  "Reverse Vendor Payment" = 25,
  "Starting Balance" = 26,
  "Payroll" = 27,
}

enum OperationNameEnum {
  CustPayment = 1,
  InvoiceOp = 2,
  POApproval = 3,
  GoodsIn = 4,
  BillPaymentCreation = 5,
  CreditMemoCreation = 6,
  CustomerInitialBalance = 7,
  VendorInitialBalance = 8,
  ItemStartingQty = 9,
  AutoInvoice = 10,
  ImmediateBillCreation = 11,
  CustomerRMAReceive = 12,
  CustomerRMARefund = 13,
  CustomerRmaCredit = 14,
  VendorRMACredit = 15,
  VendorRMARefund = 16,
  Manual = 17,
  Expense = 18,
  FixedAssetCreation = 19,
  ChequeCreation = 20,
  VoidVendorPayment = 21,
  AccountStartingBalance = 22,
  SOShipment = 23,
  ManualTransfer = 24,
  VendorPayment = 25,
  VoidCustomerPayment = 26,
  IssuedCheckInTransit = 27,
  DepositInTransit = 28,
  DepositCheckCleared = 29,
  Interrest = 30,
  IssuedCheckCleared = 31,
  BankFee = 32,
  ForceReconcile = 33,
  Fraud = 34,
  Payroll = 35,
}

const Operations = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [previousOps, setPreviousOps] = useState<number[]>([]);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    ledgerId: null,
    minValue: null,
    maxValue: null,
    searchQuery: null,
  });
  const [minValRef, setMinValRef] = useState<string>("");
  const [maxValRef, setMaxValRef] = useState<string>("");
  const [dateCategory, setDateCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataMutation = useMutation("data", getOperations, {
    onSuccess(data) {
      setPreviousOps(
        data?.data
          ?.map((op) => op.operationId)
          .sort(function (a, b) {
            return a - b;
          })
      );
    },
  });
  const { data: ledgers } = useQuery("ledgers", getLedgers);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "operationNumber",
      headerName: "Operation #",
      resizable: true,
      maxWidth: colDefs.mWidth,
    },
    {
      field: "operationName",
      headerName: "Operation Name",
      resizable: true,
      // maxWidth: colDefs.m_lgWidth,
      valueGetter: (params) => OperationNameEnum[params.data.operationName],
    },
    {
      field: "operationDate",
      headerName: "Date",
      resizable: true,
      maxWidth: colDefs.dateWidth,

      valueGetter: (params) => dateFormmaterNoTime(params.data.operationDate),
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "orderType",
      headerName: "Order Type",
      resizable: true,
      maxWidth: colDefs.mWidth,

      valueGetter: (params) => OrderTypeEnum[params.data.orderType],
    },
    {
      field: "orderCode",
      headerName: "Order Code",
      resizable: true,
      maxWidth: colDefs.mWidth,
    },
    {
      field: "isPosted",
      headerName: "Posted",
      maxWidth: colDefs.booleanWidth,
      resizable: true,
      valueGetter: (params) => (params.data.isPosted ? "Yes" : "No"),
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "total",
      headerName: "Total",
      resizable: true,
      maxWidth: colDefs.balanceWidth,

      valueGetter: (params) => {
        return currencySymbol + `${params.data?.total?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "category",
      headerName: "Category",
      resizable: true,
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      resizable: true,
    },
  ];

  const displayFilter = () => {
    setFilters((filters) => ({
      ...filters,
      endDate: endDate ? endDate.toISOString() : null,
      startDate: startDate ? startDate.toISOString() : null,
      ledgerId: selectedAccount ? selectedAccount.ledgerId : null,
      minValue: minValRef ?? null,
      maxValue: maxValRef ?? null,
      searchQuery: searchTerm ?? null,
    }));
  };

  const clearFilters = () => {
    setMaxValRef("");
    setMinValRef("");
    setSelectedAccount(null);
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
    setSearchTerm("");
    setFilters({
      startDate: null,
      endDate: null,
      ledgerId: null,
      minValue: null,
      maxValue: null,
      searchQuery: null,
    });
  };

  const onAccountSelection = (account) => {
    setShowAccountModal(false);
    setSelectedAccount(account);
  };

  const onMinValueChange = (e) => {
    setMinValRef(e.target.value);
  };

  const onMaxValueChange = (e) => {
    setMaxValRef(e.target.value);
  };

  const resetAccount = () => {
    setSelectedAccount(null);
  };

  const onRowClicked = (e) => {
    setSelectedId(e.operationId);
    setShowAddModal(true);
  };

  const onAddOperation = () => {
    setSelectedId(null);
    setShowAddModal(true);
  };

  const onModalClose = () => {
    setShowAddModal(false);
    setRefreshCounter((prev) => prev + 1);
    setPreviousOps([]);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Operations</p>
          <div>
            <button
              className="import-btn"
              type="button"
              onClick={exportToExcel}
              disabled={disabledExportBtn || dataGrid?.length === 0}
            >
              <img src={exportIcon} className="me-2" alt="" />
              Export to Excel
            </button>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <button className="btn btn-primary" onClick={onAddOperation}>
                Add Operation
              </button>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container has-filters">
            <div className="filters">
              <div style={{ display: "flex" }}>
                <div className="col-lg-2" style={{ marginRight: "20px" }}>
                  <div className="page-search">
                    <label className="label me-3">Search</label>
                    <div className="input-group">
                      <span className="page-search-icon">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        className="page-search-input ps-5"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <DateGrid
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateCategory={dateCategory}
                    setDateCategory={setDateCategory}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-2">
                  <label className="me-2">Account</label>
                  <div className="position-relative">
                    <LedgerSelection
                      initialData={ledgers?.data
                        .filter((account) => account.accountNumber !== null)
                        .map((ledger) => ({
                          ...ledger,
                          id: ledger.ledgerId,
                          name: ledger.accountNumber,
                        }))}
                      inputValue={selectedAccount?.accountName}
                      onSelection={onAccountSelection}
                      onButtonClicked={() => setShowAccountModal(true)}
                    />
                    {selectedAccount && (
                      <button
                        className="btn btn-outline-primary no-border lookup-close"
                        onClick={resetAccount}
                      >
                        <CloseLogo />
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-2">
                  <label className="me-2">Minimum Amount</label>
                  <input
                    className="form-control"
                    onChange={onMinValueChange}
                    value={minValRef}
                    onBlur={(e) => setMinValRef(formatInput(e.target.value))}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="me-2">Maximum Amount</label>
                  <input
                    className="form-control"
                    onChange={onMaxValueChange}
                    value={maxValRef}
                    onBlur={(e) => setMaxValRef(formatInput(e.target.value))}
                  />
                </div>
                <div className="col-lg-2 d-flex align-items-end mb-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="grid">
              <AgGrid
                columns={columns}
                fetchMethod={dataMutation}
                data={dataGrid}
                setDataGrid={setDataGrid}
                filters={filters}
                setrowClicked={onRowClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                refreshCounter={refreshCounter}
                fileName="Operations List Report"
              />
            </div>
          </div>
        </div>
      </div>
      <OperationsModal
        showModal={showAddModal}
        onExit={onModalClose}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        previousOps={previousOps}
        setPreviousOps={setPreviousOps}
      />
      <SelectionModal
        modal="account"
        showModal={showAccountModal}
        setShowModal={setShowAccountModal}
        data={ledgers?.data.filter((account) => account.accountNumber !== null)}
        columns={ledgersColumns}
        setRowClicked={onAccountSelection}
      />
    </>
  );
};

export default Operations;
