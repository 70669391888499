import React, { useState, useContext } from "react";
import { ReactComponent as SerializedIcon } from "img/serialized.svg";
import { ReactComponent as TangibleIcon } from "img/tangible.svg";
import { ReactComponent as NonTangibleIcon } from "img/non-tangible.svg";
import { useHistory } from "react-router";
import { Modal } from "react-bootstrap";
import Close from "../../../img/close.svg";
import AG from "elements/AG";
import * as AppUrls from "constants/AppUrls";
import "./Inventory.scss";
import DispatchContext from "context/DispatchContext";
import { Hub } from "../Inventory.models";
import { GridColumn } from "elements/AG.models";

type Props = {
  category?: string[];
  showModal: boolean;
  onExit: () => void;
  hubs: Hub[];
};

const CreateItemModal = (props: Props) => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);

  const [selectedType, setSelectedType] = useState<string>();
  const [selectedRows, setSelectedRows] = useState<Hub[]>([]);

  const columns: GridColumn[] = [
    {
      headerName: "Select",
      field: "RowSelect",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      floatingFilter: false,
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "description",
      headerName: "Location Description",
    },
    {
      field: "isDefault",
      headerName: "Default?",
      cellRendererFramework: (params) => (params.value == true ? "Yes" : "No"),
    },
    {
      field: "isActive",
      headerName: "Status",
      cellRendererFramework: (params) =>
        params.value == true ? "Active" : "Disabled",
    },
  ];

  const onItemTypeChanged = (e) => {
    setSelectedType(e.target.value);
  };
  const createItem = () => {
    appDispatch({
      type: "hubs",
      value: selectedRows,
    });
    const state = {
      category: props.category,
    };

    if (selectedType === "1")
      history.push(AppUrls.new_inventory_serialized, state);
    else if (selectedType === "2")
      history.push(AppUrls.new_inventory_nonserialized, state);
    else {
      history.push(AppUrls.non_tangible_item);
    }
  };

  return (
    <Modal
      show={props.showModal}
      centered
      size="lg"
      dialogClassName="modal-80w"
    >
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title className="">Select Type and Hubs</Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={props.onExit}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-4 d-flex align-items-center">
            <input
              name="type"
              type="radio"
              id="serialized"
              className="me-2 order-2"
              value={1}
              checked={selectedType === "1"}
              onChange={onItemTypeChanged}
            />
            <label htmlFor="serialized" className="option-button order-1">
              <SerializedIcon />
            </label>
            <label htmlFor="serialized" className="option-label order-3">
              Serialized Item
            </label>
          </div>
          <div className="col-lg-4 d-flex align-items-center">
            <input
              name="type"
              type="radio"
              id="tangible"
              className="me-2 order-2"
              value={2}
              checked={selectedType === "2"}
              onChange={onItemTypeChanged}
            />
            <label htmlFor="tangible" className="option-button order-1">
              <TangibleIcon />
            </label>
            <label htmlFor="tangible" className="option-label order-3">
              Tangible / Part / Accessory
            </label>
          </div>
          <div className="col-lg-4 d-flex align-items-center">
            <input
              name="type"
              type="radio"
              id="non-tangible"
              className="me-2 order-2"
              value={3}
              checked={selectedType === "3"}
              onChange={onItemTypeChanged}
            />
            <label htmlFor="non-tangible" className="option-button order-1">
              <NonTangibleIcon />
            </label>
            <label htmlFor="non-tangible" className="option-label order-3">
              Service / Non-Tangible
            </label>
          </div>
          <div className="modal-table">
            <AG
              data={props.hubs}
              columns={columns}
              rowSelection="multiple"
              setRowChecked={setSelectedRows}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-top-0">
        <div className="d-flex align-items-center">
          <span className="selected-count me-4">
            Selected({selectedRows.length})
          </span>
          <div className="selected-hubs">
            {selectedRows.map((hub) => (
              <span className="me-1" key={hub.locationId}>
                {hub.hubId}
              </span>
            ))}
          </div>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-outline-primary custom-outline me-4"
            onClick={props.onExit}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={createItem}
            disabled={!selectedType || selectedRows.length === 0}
          >
            Select
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateItemModal;
