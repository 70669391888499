import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { TrailBalanceAPI } from "./AccountingServices";
import "./Ledgers.scss";

import { useReactToPrint } from "react-to-print";

import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";

import { useHistory, useLocation } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import DispatchContext from "context/DispatchContext";
import { FormProvider, useForm } from "react-hook-form";
import Filter from "reports/components/Filter";
import { Accordion } from "react-bootstrap";
import { getBaseCurrency } from "components/currency";

const options = [
  { value: 1, label: "None" },
  { value: 2, label: "Month" },
];

const TrialBalance = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const appDispatch = useContext(DispatchContext);

  const history = useHistory();

  const [data, setData] = useState(null);

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];

  const isCustomizedReport = customizedComponentId != null;

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues, setValue } = methods;

  const MutationMethod = useMutation(TrailBalanceAPI, {
    onSuccess(data) {
      setData(data);
    },
  });

  const onSubmit = () => {
    const values = getValues();
    MutationMethod.mutate({
      groupBy: values.groupingElement?.value,
      startDate: fixTimezoneOffset(values.startDate ?? new Date()),
      endDate: fixTimezoneOffset(values.endDate ?? new Date()),
    });
  };

  const accordionDiv = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => accordionDiv.current,
  });

  const PrintReport = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      // handlePrint()
      var divToPrint = document.getElementById("printTable");
      const newWin = window.open("");
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const onBackClick = () => {
    history.push("/reports");
  };

  useEffect(() => {
    if (!isCustomizedReport) {
      setValue("groupingElement", { value: 1, label: "None" });
      MutationMethod.mutate({
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      });
    }
  }, [isCustomizedReport]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="static-page">
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Trial Balance Report
            </div>
            <div>
              <button
                type="button"
                className="btn btn-success ms-0"
                onClick={() => PrintReport()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-printer me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                </svg>
                Print report to PDF
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <Filter
                filters={[0, 1]}
                onSubmit={onSubmit}
                noColumns={true}
                groupingElements={options}
                initialDate={!isCustomizedReport}
              />
              <hr />
              <>
                <div className="report-page custom-print" id="printTable">
                  <div className="report-header">
                    <div>
                      <h3>
                        <b>Trial Balance Report</b>
                      </h3>
                      <p>
                        <b>Report Date: </b> {dateFormmaterNoTime(new Date())}
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    {getValues("groupingElement")?.value === 1 ? (
                      <table
                        className="table table-bordered"
                        //@ts-ignore
                        border="1"
                        style={{ borderCollapse: "collapse" }}
                        cellpadding="1"
                      >
                        <thead>
                          <tr>
                            <th>Account Name</th>
                            <th>Debit</th>
                            <th>Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.trialBalances?.map((trial) => {
                            return (
                              <>
                                {trial?.trialBalanceEntries?.map(
                                  (transaction) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{transaction?.accountName}</td>
                                          <td>
                                            {transaction?.isDebit &&
                                              formatNumber(
                                                transaction?.amount,
                                                currencySymbol
                                              )}
                                          </td>
                                          <td>
                                            {!transaction?.isDebit &&
                                              formatNumber(
                                                transaction?.amount,
                                                currencySymbol
                                              )}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    {formatNumber(
                                      trial?.debitTotal,
                                      currencySymbol
                                    )}
                                  </td>
                                  <td>
                                    {formatNumber(
                                      trial?.creditTotal,
                                      currencySymbol
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      data?.groupingData?.map((grouping, i) => {
                        return (
                          <>
                            <Accordion flush>
                              <Accordion.Item eventKey={i}>
                                <Accordion.Header className="border">
                                  <div className="w-100 d-flex justify-content-between px-4 align-items-center">
                                    <div className="fw-bold">{grouping}</div>
                                    <div>
                                      <div className="mb-2">
                                        Credit Total: $
                                        {data?.trialBalances[i]?.creditTotal}
                                      </div>
                                      <div>
                                        {" "}
                                        Debit Total: $
                                        {data?.trialBalances[i]?.debitTotal}
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {data?.trialBalances[i]?.trialBalanceEntries
                                    .length > 0 ? (
                                    <table
                                      className="table table-bordered"
                                      //@ts-ignore
                                      border="1"
                                      style={{ borderCollapse: "collapse" }}
                                      cellpadding="1"
                                    >
                                      <thead>
                                        <tr>
                                          <th>Account Name</th>
                                          <th>Debit</th>
                                          <th>Credit</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data?.trialBalances[
                                          i
                                        ]?.trialBalanceEntries?.map(
                                          (transaction) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>
                                                    {transaction?.accountName}
                                                  </td>
                                                  <td>
                                                    {transaction?.isDebit &&
                                                      formatNumber(
                                                        transaction?.amount,
                                                        currencySymbol
                                                      )}
                                                  </td>
                                                  <td>
                                                    {!transaction?.isDebit &&
                                                      formatNumber(
                                                        transaction?.amount,
                                                        currencySymbol
                                                      )}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <div>No Entries Found</div>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        );
                      })
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default TrialBalance;
