import DateGrid from "components/DateFilter/DateGrid";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import {
  dateFormmaterNoTime,
  formatInput,
  getCurrencyByCode,
} from "global/helpers";
import { ReactComponent as CloseLogo } from "img/close.svg";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as colDefs from "../../constants/colDefs";
import { getJournals, getLedgers } from "./AccountingServices";
import LedgerSelection from "./LedgerSelection";

export enum OrderTypeEnum {
  "Invoice" = 1,
  "Credit Memo" = 2,
  "Customer Payment" = 3,
  "Purchase Order" = 4,
  "Purchase Order Return" = 5,
  "Vendor Payment" = 6,
  "Credit Memo Refund" = 7,
  "Expense" = 8,
  "Cheque" = 9,
}

const Journals = () => {
  const appDispatch = useContext(DispatchContext);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [minVal, setMinVal] = useState<string>("");
  const [maxVal, setMaxVal] = useState<string>("");
  const [dateCategory, setDateCategory] = useState(null);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    ledgerId: null,
    minValue: null,
    maxValue: null,
  });

  const dataMutation = useMutation("data", getJournals);
  const { data: ledgers } = useQuery("ledgers", getLedgers);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "journalNumber",
      headerName: "Journal #",
      maxWidth: colDefs.sm_mWidth,
    },
    {
      field: "accountName",
      headerName: "Account Name",
      resizable: true,
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      resizable: true,
      maxWidth: colDefs.m_lgWidth,
    },
    {
      field: "date",
      headerName: "Date",
      resizable: true,
      maxWidth: colDefs.dateWidth,
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data?.date);
      },
    },
    {
      field: "isDebit",
      headerName: "Debit",
      maxWidth: colDefs.booleanWidth,
      resizable: true,
      valueGetter: (params) => (params.data.isDebit ? "Yes" : "No"),
    },
    {
      field: "amount",
      headerName: "Amount",
      resizable: true,
      maxWidth: colDefs.balanceWidth,

      valueGetter: (params) => {
        return currencySymbol + `${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      valueGetter: (params) => (params.data.isDebit ? "Yes" : "No"),
    },
    {
      field: "category",
      headerName: "Category",
      resizable: true,
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      resizable: true,
    },
  ];

  const displayFilter = () => {
    setFilters((filters) => ({
      ...filters,
      endDate: endDate ? endDate.toISOString() : null,
      startDate: startDate ? startDate.toISOString() : null,
      ledgerId: selectedAccount ? selectedAccount.ledgerId : null,
      minValue: minVal ?? null,
      maxValue: maxVal ?? null,
    }));
  };

  const clearFilters = () => {
    setMaxVal("");
    setMinVal("");
    setSelectedAccount(null);
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
    setFilters({
      startDate: null,
      endDate: null,
      ledgerId: null,
      minValue: null,
      maxValue: null,
    });
  };

  const onAccountSelection = (account) => {
    setShowAccountModal(false);
    setSelectedAccount(account);
  };

  const onMinValueChange = (e) => {
    setMinVal(e.target.value);
  };

  const onMaxValueChange = (e) => {
    setMaxVal(e.target.value);
  };

  const resetAccount = () => {
    setSelectedAccount(null);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Journal Entries</p>
          <div>
            <button
              className="import-btn"
              type="button"
              onClick={exportToExcel}
              disabled={disabledExportBtn || dataGrid?.length === 0}
            >
              <img src={exportIcon} className="me-2" alt="" />
              Export to Excel
            </button>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container has-filters">
            <div className="filters">
              <DateGrid
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateCategory={dateCategory}
                setDateCategory={setDateCategory}
              />
              <div className="row mb-4">
                <div className="col-lg-2">
                  <label className="me-2">Account</label>
                  <div className="position-relative">
                    <LedgerSelection
                      initialData={ledgers?.data
                        .filter((account) => account.accountNumber !== null)
                        .map((ledger) => ({
                          ...ledger,
                          id: ledger.ledgerId,
                          name: ledger.accountNumber,
                        }))}
                      inputValue={selectedAccount?.accountName}
                      onSelection={onAccountSelection}
                      onButtonClicked={() => setShowAccountModal(true)}
                    />
                    {selectedAccount && (
                      <button
                        className="btn btn-outline-primary no-border lookup-close"
                        onClick={resetAccount}
                      >
                        <CloseLogo />
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-2">
                  <label className="me-2">Minimum Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={onMinValueChange}
                    value={minVal}
                    onBlur={(e) => setMinVal(formatInput(e.target.value))}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="me-2">Maximum Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={onMaxValueChange}
                    value={maxVal}
                    onBlur={(e) => setMaxVal(formatInput(e.target.value))}
                  />
                </div>
                <div className="col-lg-2 d-flex align-items-end mb-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="grid">
              <AgGrid
                columns={columns}
                fetchMethod={dataMutation}
                data={dataGrid}
                setDataGrid={setDataGrid}
                filters={filters}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                fileName="Journals List Report"
              />
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal="account"
        showModal={showAccountModal}
        setShowModal={setShowAccountModal}
        data={ledgers?.data.filter((account) => account.accountNumber !== null)}
        columns={ledgersColumns}
        setRowClicked={onAccountSelection}
      />
    </>
  );
};

export default Journals;
