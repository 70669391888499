import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import StateContext from "../context/StateContext";
import * as AppUrls from "../constants/AppUrls";

export function ProtectedRoute({ isLoggedIn, allowedUrls, ...rest }) {
  const appState = useContext(StateContext);

  const { path } = rest;

  const checkIfAllowed = () => {
    if (path === AppUrls.user_profile) return true;

    if (path === AppUrls.new_payment_receipt) return true;

    for (let allowed of allowedUrls) {
      if (path.includes(allowed.url)) {
        if (path === allowed.url) {
          return true;
        } else {
          if (path.includes(":id")) {
            for (let action of allowed.actions)
              if (
                action.actionKey === "view" ||
                action.actionKey === "edit" ||
                action.actionKey === "delete"
              )
                return true;
          } else if (
            path.includes("import") ||
            path.includes("new") ||
            path.includes("serialized") ||
            path.includes("non-tangible-item")
          ) {
            for (let action of allowed.actions)
              if (action.actionKey === "add") return true;
          } else if (path.includes("report")) {
            for (let action of allowed.actions)
              if (action.actionKey === "view") return true;
          }
        }
      }
    }
    return false;
  };

  return (
    <>
      {isLoggedIn &&
      (path === AppUrls.user_profile
        ? true
        : !appState?.userData?.forcePasswordChange) ? (
        allowedUrls.length > 0 ? (
          checkIfAllowed() ? (
            <Route {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: AppUrls.access_denied,
              }}
            />
          )
        ) : (
          <></>
        )
      ) : (
        <Redirect
          to={{
            pathname: AppUrls.login,
          }}
        />
      )}
    </>
  );
}

export function LoggedOutRoute({ isLoggedIn, ...rest }) {
  return (
    <>
      {isLoggedIn ? (
        <Redirect
          to={{
            pathname: AppUrls.user_profile,
          }}
        />
      ) : (
        <Route {...rest} />
      )}
    </>
  );
}
