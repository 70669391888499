import SectionTitle from "elements/SectionTitle";
import { useState } from "react";
import { Form } from "react-bootstrap";

const AddNote = ({ onNoteAdd, isDisabled }) => {
  const [newNote, setNewNote] = useState({ subject: "", noteBody: "" });
  const [submitted, setSubmitted] = useState<boolean>(false);

  const onSubjectChanged = (e) => {
    if (e.target.value.length > 0) {
      setNewNote((preSubject) => ({
        ...preSubject,
        subject: e ? e.target.value : "",
      }));
    } else {
      setNewNote((preSubject) => ({
        ...preSubject,
        subject: "",
      }));
    }
  };

  const onNoteBodyChanged = (e) => {
    if (e.target.value.length > 0) {
      setNewNote((preNewNote) => ({
        ...preNewNote,
        noteBody: e ? e.target.value : "",
      }));
    } else {
      setNewNote((preNewNote) => ({ ...preNewNote, noteBody: "" }));
    }
  };

  const onClearClicked = () => {
    setNewNote({ subject: "", noteBody: "" });
    setSubmitted(false);
  };

  const onSubmitNewNote = () => {
    setSubmitted(true);
    if (newNote.subject !== "" && newNote.noteBody !== "") {
      onNoteAdd(newNote);
      setNewNote({ subject: "", noteBody: "" });
      setSubmitted(false);
    }
  };

  return (
    <div>
      <SectionTitle title="New Note" active={false} editable={false} />
      <div>
        <div className="col-lg-11 mx-auto mb-5">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <label className="label mb-1">Subject</label>
            <Form.Control
              name="subject"
              disabled={isDisabled}
              onChange={(e) => onSubjectChanged(e)}
              value={newNote.subject || ""}
              className={`${
                newNote.subject === "" && submitted === true && "border-red"
              }`}
            />
            {newNote.subject === "" && submitted === true && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <label className="label mb-1">New Note</label>
            <Form.Control
              as="textarea"
              name="noteBody"
              disabled={isDisabled}
              placeholder="Add your note"
              className={`note-input ${
                newNote.noteBody === "" && submitted === true && "border-red"
              }`}
              onChange={(e) => onNoteBodyChanged(e)}
              value={newNote.noteBody || ""}
            />
            {newNote.noteBody === "" && submitted === true && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <button
            disabled={isDisabled}
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={onClearClicked}
          >
            Reset
          </button>
          <button
            disabled={isDisabled}
            type="button"
            className="btn btn-primary "
            onClick={() => {
              onSubmitNewNote();
            }}
          >
            Add Note
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
