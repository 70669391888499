import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import { CreateOrder, SalesOrder } from "pages/SalesOrder/SalesOrder.model";

const salesOrderUrl: string = "sales-order";
const trackingNumbersUrl: string = "sales-order/tracking-numbers";

export const getSalesOrders = async (params) => {
  let extraParams = "";
  if (params.PageSize) extraParams += `&PageSize=${params.PageSize}`;
  if (params.PageStart) extraParams += `&PageStart=${params.PageStart}`;
  if (params.startDate) extraParams += `&start=${params.startDate}`;
  if (params.endDate) extraParams += `&end=${params.endDate}`;
  if (params.contactName) extraParams += `&contactName=${params.contactName}`;
  if (params.trackingNumber)
    extraParams += `&trackingNumber=${params.trackingNumber}`;
  if (params.origin) extraParams += `&origin=${params.origin}`;
  const response = await api.get<QueryResult<SalesOrder>>(
    `${salesOrderUrl}?states=0${extraParams}`
  );
  return response.data;
};
export const filterSalesOrders = async (params) => {
  let extraParams = `?states=${params.states}`;
  if (params.PageSize) extraParams += `&PageSize=${params.PageSize}`;
  if (params.PageStart) extraParams += `&PageStart=${params.PageStart}`;
  if (params.startDate) extraParams += `&start=${params.startDate}`;
  if (params.endDate) extraParams += `&end=${params.endDate}`;
  if (params.contactName) extraParams += `&contactName=${params.contactName}`;
  if (params.trackingNumber)
    extraParams += `&trackingNumber=${params.trackingNumber}`;
  if (params.origin) extraParams += `&origin=${params.origin}`;
  if (params.searchQuery) extraParams += `&searchQuery=${params.searchQuery}`;

  const response = await api.get<QueryResult<SalesOrder>>(
    `${salesOrderUrl}${extraParams}`
  );
  return response.data;
};
export const getSalesOrdersForInvoice = async (params) => {
  const states = params.queryKey[0].states;
  const response = await api.get<QueryResult<SalesOrder>>(
    `${salesOrderUrl}?${states.map((state) => `states=${state}&`).join("")}`
  );
  return response.data;
};
export const createSalesOrder = async (data: CreateOrder) => {
  const response = await api.post<CreateOrder>(salesOrderUrl, data);
  return response.data;
};

export const editSalesOrder = async (data: CreateOrder) => {
  return await api.put<CreateOrder>(`${salesOrderUrl}/${data.id}`, data);
};

export const getSalesOrder = async (id) => {
  return await api.get(`${salesOrderUrl}/${id}`);
};

export const voidSalesOrder = async (id) => {
  return await api.delete(`${salesOrderUrl}/${id}`);
};

export const scanSerializedItem = async (data) => {
  return await api.post(
    `${salesOrderUrl}/scan-items/${data.itemOrderId}`,
    data.serials
  );
};

export const scanNonSerializedItem = async (data) => {
  return await api.get(
    `${salesOrderUrl}/${data.soId}/check-item/${data.itemHubId}/${data.quantity}`
  );
};

export const fulfillSalesOrder = async (data) => {
  const response = await api.post(
    `${salesOrderUrl}/${data.soId}/fulfill`,
    data.items
  );
  return response.data;
};

export const GetTrackingNumbersList = async (data) => {
  const response = await api.get<any>(
    `${trackingNumbersUrl}?${
      data.shippingId != undefined ? `&shippingId=${data.shippingId}` : ""
    }${data.accountId != undefined ? `&accountId=${data.accountId}` : ""}${
      data.from != null ? `&from=${data.from}` : ""
    }${data.to != null ? `&to=${data.to}` : ""}${data?.searchQuery !== undefined}&searchQuery=${data?.searchQuery}`
  );
  return response.data.data;
};

export const getFilteredSalesOrders = async (data) => {
  const response = await api.get<QueryResult<SalesOrder>>(
    `${salesOrderUrl}?states=${data}`
  );
  return response.data.data;
};

export const searchSalesOrders = async (params) => {
  return await api.get<QueryResult<SalesOrder>>(`${salesOrderUrl}${params}`);
};

export const shipSalesOrder = async (id) => {
  return await api.get(`${salesOrderUrl}/${id}/ready-to-ship`);
};

export const applyTrackingNumber = async (data) => {
  const response = await api.post(
    `/sales-order/${data.soId}/ship/${data.shipCost}`,
    data.trackingList
  );
  return response.data;
};

export const pickUpSalesOrder = async (data) => {
  return await api.get(`/sales-order/${data.soId}/pick-up`);
};
