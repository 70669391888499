import { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { ReactComponent as AlertIcon } from 'img/alert.svg';
import { useFormContext } from "react-hook-form";

import { Tabs, Tab } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import DispatchContext from 'context/DispatchContext';
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getCustomerById, getCustomers } from "pages/SalesMarketing/Customers/Customers.services";
import { GridColumns } from "components/Common.models";
import { formatNumber } from "global/helpers";
import { CustomerInfoDTO } from "pages/SalesMarketing/Customers/Customers.models";
import Lookup from "elements/Lookup";
import ShippingMethodModal from "pages/SystemSetup/ShippingMethodModal";
import { CustomerAccount } from "pages/Invoices/Invoices.model";
import InvoiceInfo from "pages/Invoices/Invoice Tabs/InvoiceInfo";
import InvoiceItems from "pages/Invoices/Invoice Tabs/InvoiceItems";

const TransactionInvoice = ({ addedItems, setAddedItems, hubId, setHubId, addedSerials, setAddedSerials }) => {

    const appDispatch = useContext(DispatchContext)

    const [openSelection, setOpenSelection] = useState<boolean>(false)
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerAccount>()
    const [customerDetails, setCustomerDetails] = useState<CustomerInfoDTO>()

    const [showShippingModal, setShowShippingModal] = useState<boolean>(false);
    const [methodsCounter, setMethodsCounter] = useState<number>(0);

    const accountNumberColumns: GridColumns[] = [
        {
            field: "accountNumber",
            headerName: "Customer No.",
        },
        {
            field: "company",
            headerName: "Company Name",
        },
        {
            field: "contact",
            headerName: "Contact",
        },
        {
            field: "hubId",
            headerName: "Hub ID",
        },
        {
            field: "state",
            headerName: "Location",
        },
        {
            field: "accountType",
            headerName: "Customer Type",
        },
        {
            field: "balance",
            headerName: "Balance",
            valueGetter: (params) => { return `$${params.data?.balance?.toFixed(2) ?? '0.00'}` }
        }
    ];


    const { formState: { isDirty }, getValues, setValue } = useFormContext();

    const {
        isLoading: isLoadingCustomers,
        data: customers,
    } = useQuery("customersList", getCustomers);

    const getDetails = useMutation(getCustomerById, {
        async onSuccess(response) {
            setCustomerDetails(response);
            setHubId(response.defaultHubId);
        }
    });

    useEffect(() => {
        appDispatch({ type: "loading", value: isLoadingCustomers || getDetails.isLoading })
    }, [isLoadingCustomers, getDetails.isLoading])

    useEffect(() => {
        if (selectedCustomer) {
            setValue('accountNumber', selectedCustomer['accountNumber'], { shouldDirty: true })
            setValue('targetId', selectedCustomer['customerAccountId']);
            getDetails.mutate(selectedCustomer.customerAccountId);
            setOpenSelection(false)
            setSelectedCustomer(null)
            setValue('shipTo', "")
            setValue('shippingInfoId', "")
        }
    }, [selectedCustomer])

    const onTypeCreated = (method) => {
        setValue("shippingMethodId", method, { shouldDirty: true })
        setShowShippingModal(false)
        setMethodsCounter(counter => counter + 1)
    }

    return (
        <>


            <div className="row">
                <div className="col-sm-3">
                    <label>Customer No. <span className="text-danger">*</span></label>
                    <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="targetId"
                        isRequired={true}
                        initialData={customers?.data?.map(item => ({ ...item, id: item.customerAccountId, name: item.accountNumber }))}
                        onSelection={setSelectedCustomer}
                        inputValue={getValues("accountNumber")}
                    />
                </div>
                <div className="col-sm-3">
                    <label>Invoice No.</label>
                    <div className="fst-italic text-muted">Invoice No.</div>
                </div>
                <div className="col-sm-3">
                    <label>Invoice Date</label>
                    <div>{new Date().toLocaleDateString()}</div>
                </div>
                {customerDetails && customerDetails['balance'] < 0 &&
                    <div className="col-lg-6 col-sm-12 d-flex align-items-center justify-content-end">
                        <div className="d-flex align-items-center ">
                            <AlertIcon className='me-3' />
                            <div className='text-danger'>
                                Customer has unaplied credits on account
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="row my-5">
                <div className="col">
                    <div className={`border p-3 ${!isDirty && 'bg-silver-light text-secondary'}`}>
                        <div>
                            Balance
                        </div>
                        <div>
                            {customerDetails && customerDetails['ledger']['balance'] ?
                                <span className={`${customerDetails['ledger']['balance'] < 0 ? 'text-danger' : 'text-success'}`}>
                                    {formatNumber(customerDetails['ledger']['balance'])}
                                </span>
                                :
                                <span className={`${isDirty && 'text-success'}`}>
                                    $ 0.00
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className={`border p-3 ${!isDirty && 'bg-silver-light text-secondary'}`}>
                        <div>
                            Credit Limit
                        </div>
                        <div>
                            {customerDetails && Number(customerDetails['creditLimit']) ?
                                <span className={`${Number(customerDetails['creditLimit']) < 0 ? 'text-danger' : 'text-success'}`}>
                                    {formatNumber(Number(customerDetails['creditLimit']))}
                                </span>
                                :
                                <span className={`${isDirty && 'text-success'}`}>
                                    $ 0.00
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className={`border p-3 ${!isDirty && 'bg-silver-light text-secondary'}`}>
                        <div>
                            On Order
                        </div>
                        <div>
                            {customerDetails && customerDetails['onSO'] ?
                                <span className={`${customerDetails['onSO'] < 0 ? 'text-danger' : 'text-success'}`}>
                                    {formatNumber(customerDetails['onSO'])}
                                </span>
                                :
                                <span className={`${isDirty && 'text-success'}`}>
                                    $ 0.00
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className={`border p-3 ${!isDirty && 'bg-silver-light text-secondary'}`}>
                        <div>
                            Available
                        </div>
                        <div>
                            {customerDetails && customerDetails['balance'] ?
                                <span className={`${customerDetails['balance'] < 0 ? 'text-danger' : 'text-success'}`}>
                                    {formatNumber(customerDetails['balance'])}
                                </span>
                                :
                                <span className={`${isDirty && 'text-success'}`}>
                                    $ 0.00
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Tabs defaultActiveKey="InvoiceInfo" >
                <Tab eventKey="InvoiceInfo" title={<div className="tab-inner-title">Invoice Info</div>}>
                    <InvoiceInfo isEditing={false} isCreating={true} customerDetails={customerDetails} setHubId={setHubId} addedItems={addedItems} setAddedItems={setAddedItems}
                        methodsCounter={methodsCounter}
                        setShowShippingModal={setShowShippingModal}
                    />
                </Tab>
                <Tab eventKey="InvoiceItems"
                    title={
                        <div className="tab-inner-title">
                            Invoice Items
                            <Badge pill className="text-secondary ms-2">{addedItems.length}</Badge>
                        </div>
                    }
                >
                    <InvoiceItems isEditing={false} isCreating={true} hubId={hubId} addedItems={addedItems} setAddedItems={setAddedItems} customer={customerDetails} addedSerials={addedSerials} setAddedSerials={setAddedSerials} />
                </Tab>
            </Tabs>
            <ShippingMethodModal
                showModal={showShippingModal}
                onClose={() => setShowShippingModal(false)}
                onConfirm={onTypeCreated}
            />
            <SelectionModal
                modal={'Account'}
                showModal={openSelection}
                setShowModal={setOpenSelection}
                data={customers?.data}
                columns={accountNumberColumns}
                setRowClicked={setSelectedCustomer}
            />
        </>
    )
}

export default TransactionInvoice