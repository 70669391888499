import { Modal } from 'react-bootstrap';
import Close from 'img/close.svg';
import { useQuery } from 'react-query';
import Lookup from 'elements/Lookup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SelectionModal from 'components/SelectionModal/SelectionModal';
import { getLedgers } from 'pages/Accounting/AccountingServices';

type Props = {
    showModal: boolean,
    onExit: () => void,
    onCreation: (id: number) => void,
    isDeposit?: boolean
}

const DepositModal = (props: Props) => {
    const [openSelection, setOpenSelection] = useState(false)
    const [selectedLedger, setSelectedLedger] = useState(null)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const {
        data: ledgers,
    } = useQuery("ledgers", () => getLedgers({ ledgerType: 1 }));

    const onLedgerSelection = (e) => {
        setSelectedLedger(e)
        setOpenSelection(false)
    }

    const ledgersColumns = [
        {
            field: "accountNumber",
            headerName: "Account",
            resizable: true
        },
        {
            field: "accountName",
            headerName: "Account name",
            resizable: true
        },
        {
            field: "balance",
            headerName: "Balance",
            resizable: true,
            valueGetter: (params) => { return `$${params.data?.balance?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "isDebit",
            headerName: "Debit",
            resizable: true,
            cellRenderer: (params) => params.value ? 'Yes' : 'No',
        }
    ];

    const onConfirm = () => {
        if (selectedLedger)
            props.onCreation(selectedLedger.ledgerId)
    }

    return (
        <>
            <Modal show={props.showModal} centered>
                <Modal.Header>
                    <Modal.Title>
                        <div className='mb-4'>
                            Choose {props.isDeposit ? "Deposit" : "Withdrawal"} Account
                        </div>
                    </Modal.Title>
                    <img
                        src={Close}
                        alt="close icon"
                        className=""
                        role="button"
                        onClick={props.onExit}
                    />
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <label>{props.isDeposit ? "Deposit" : "Withdrawal"} Account <span className="text-danger">*</span></label>
                        <Lookup
                            onButtonClicked={() => setOpenSelection(true)}
                            inputName="paymentAccountId"
                            isRequired={true}
                            initialData={ledgers?.data?.map(item => ({ ...item, id: item.ledgerId, name: item.accountName }))}
                            onSelection={onLedgerSelection}
                            inputValue={selectedLedger?.accountName}
                        />
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer className="d-flex ms-auto border-top-0">
                    <button type="button" className="btn btn-outline-primary custom-outline" onClick={props.onExit}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={onConfirm} >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
            <SelectionModal
                modal="account"
                showModal={openSelection}
                setShowModal={setOpenSelection}
                data={ledgers?.data}
                columns={ledgersColumns}
                setRowClicked={onLedgerSelection}
            />
        </>
    );
};

export default DepositModal;