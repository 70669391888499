export const statusEnum = {
    1: "Open",
    2: "Received",
    3: "Partially Received",
    4: "Voided",
    5: "All",
    6: "Approved",
    7: "Invoiced",
    8: "Fulfilled",
    9: "Partially Fulfilled"
}

export enum typeEnum {
    All = 1,
    Normal = 2,
    Return = 3
}

export const typesEnum = {
    1 : "All",
    2 : "Normal",
    3 : "Return"
}

export enum statusesEnum {
    'Open' = 1,
    'Received' = 2,
    'Partially Received' = 3,
    'Voided' = 4,
    'All' = 5,
    'Approved' = 6,
    'Invoiced' = 7,
    'Fulfilled' = 8,
    'Partially Fulfilled' = 9
}