import { useState, useContext, useRef } from "react";
import {
  dateFormmaterNoTime,
  fixTimezoneOffset,
  formatTime,
  notificationMessage,
} from "global/helpers";
import { useMutation, useQuery } from "react-query";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { getUserAccounts } from "pages/UserManagement/UserManagement.services";
import { FormProvider, useForm } from "react-hook-form";
import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import DatePicker from "react-datepicker";
import {
  editTimesheet,
  getCheckInOut,
} from "./PayrollServices/payrollServices";
import * as colDefs from "../../constants/colDefs";
import { getUserProfile } from "pages/User/userServices";

const CheckInOut = () => {
  const [filters, setFilters] = useState<any>({});
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const firstSet = useRef(true);
  const gridRef = useRef(null);

  const { data: userAccounts } = useQuery("user_accounts", getUserAccounts);
  const appDispatch = useContext(DispatchContext);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { data: userProfile } = useQuery("userProfile", getUserProfile);

  const { data } = useQuery(
    ["checkinout", filters],
    () => getCheckInOut(filters),
    {
      onSuccess(data) {
        if (firstSet.current) {
          let start = data?.data?.startDate;
          onStartDateChange(start);
          let end = data?.data?.endDate;
          onEndDateChange(end);
          firstSet.current = false;
        }
      },
    }
  );

  const editMutation = useMutation(editTimesheet, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Timesheet edited successfully`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing timesheet"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const userColumns = [
    {
      headerName: "Employee Id",
      field: "employeeId",
      minWidth: 150,
    },
    {
      maxWidth: colDefs.mWidth,
      headerName: "Employee Name",
      field: "employeeId",
      valueGetter: (params) => {
        return params.data.firstName + " " + params.data.lastName;
      },
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 250,
    },
    {
      maxWidth: colDefs.sm_mWidth,
      headerName: "Role",
      field: "roles",
      cellRendererFramework: (params) =>
        params.value.length > 1 ? "Multiple" : params.value,
    },
    // {
    //     headerName: "Cell No",
    //     field: "mobileNumber",
    //     valueGetter: (params) => { return params.data.mobileNumber ? params.data.mobileNumber.toString().replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, "$1 $2 $3") : "" },
    // },
    {
      maxWidth: colDefs.sm_mWidth,
      headerName: "Hub",
      field: "hubs",
      valueGetter: (params) => {
        return params.data.roles[0] === "Admin"
          ? "All"
          : params.data.hubs.length > 1
          ? params.data.hubs?.map((hub) => {
              return hub + " ";
            })
          : params.data.hubs;
      },
    },
    {
      maxWidth: colDefs.booleanWidth,
      headerName: "Status",
      field: "isActive",
      cellRendererFramework: (params) =>
        params.data.isActive ? (
          <div className="active-status">active</div>
        ) : (
          <div className="disabled-status">disabled</div>
        ),
    },
    {
      headerName: "Date Added",
      field: "dateAdded",
      cellRendererFramework: (params) =>
        dateFormmaterNoTime(params.data.dateAdded),
    },
  ];

  const columns = [
    {
      headerName: "Employee Id",
      field: "employeeId",
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
    },
    {
      field: "date",
      headerName: "Date",
      maxWidth: colDefs.dateWidth,
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "checkInDate",
      headerName: "Check In",
      maxWidth: colDefs.checkIn,
    },
    {
      field: "checkOutDate",
      headerName: "Check Out",
    },
    {
      field: "hours",
      headerName: "Hours",
      editable: allowEdit,
      cellRenderer: (params) => formatTime(params.value),
      valueGetter: (params) => Number(params.data.hours)?.toFixed(2),
    },
    {
      headerName: "Paid",
      field: "processed",
      cellRendererFramework: (params) =>
        params?.data?.processed ? "Yes" : "No",
    },
  ];

  const clearForm = () => {
    setSelectedUser(null);
    setFilters(null);
    setEndDate(null);
    setStartDate(null);
  };

  const onUserSelection = (e) => {
    setSelectedUser(e);
    setOpenSelection(false);
    setFilters((prev) => ({ ...prev, userId: e.userId }));
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
    setFilters((prev) => ({ ...prev, startDate: fixTimezoneOffset(date) }));
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
    setFilters((prev) => ({ ...prev, endDate: fixTimezoneOffset(date) }));
  };

  const onSaveChanges = () => {
    if (gridRef.current)
      editMutation.mutate(
        gridRef
          .current()[0]
          .gridOptionsWrapper.gridOptions.rowData.map((row) => ({
            id: row.weekleyTimesheetId,
            hours: row.hours,
          }))
      );
    setAllowEdit(false);
  };

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Check In & Out</p>
          {allowEdit ? (
            <button
              type="button"
              className={`btn btn-success`}
              onClick={onSaveChanges}
            >
              Save Changes
            </button>
          ) : (
            userProfile?.data?.isAdmin && (
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={() => setAllowEdit(true)}
              >
                Edit Hours
              </button>
            )
          )}
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container has-filters">
            <div className="filters">
              <div className="d-flex mb-4">
                {userProfile?.data?.isAdmin && (
                  <div className="align-items-center d-flex">
                    <div className="form-group me-3">
                      <label>Employee</label>
                      <FormProvider {...methods}>
                        <Lookup
                          onButtonClicked={() => setOpenSelection(true)}
                          inputName="userId"
                          initialData={userAccounts?.data?.map((item) => ({
                            ...item,
                            id: item.userId,
                            name: item.employeeId,
                          }))}
                          onSelection={onUserSelection}
                          inputValue={selectedUser?.employeeId}
                        />
                      </FormProvider>
                    </div>
                  </div>
                )}
                <div>
                  <label className="me-2">Start Date</label> <br />
                  <DatePicker
                    onChange={(date) => onStartDateChange(date)}
                    selected={startDate}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                  />
                </div>
                <div className="mx-3">
                  <label className="me-3">End Date</label> <br />
                  <DatePicker
                    onChange={(date) => onEndDateChange(date)}
                    selected={endDate}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="btn btn-outline-primary custom-outline"
                    onClick={clearForm}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="grid">
              <AgGrid
                columns={columns}
                data={data?.data?.checkInandOutTimeSheetEntries}
                ref={gridRef}
              />
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal={"Employee"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={userAccounts?.data}
        columns={userColumns}
        setRowClicked={onUserSelection}
      />
    </>
  );
};

export default CheckInOut;
