import { useState, useContext, useEffect } from "react";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import { FormProvider, useForm } from "react-hook-form";
import Lookup from "elements/Lookup";
import { HubInfo, InvoiceItem } from "pages/Invoices/Invoices.model";
import { ErrorResponse } from "components/data/Errors.model";
import {
  getInventoryHubsList,
  InterLocationAPI,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useMutation, useQuery } from "react-query";
import { Hub } from "../Inventory.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumns, QueryResult } from "components/Common.models";
import { addedSerials } from "../../Invoices/Invoices.model";
import InvoiceItems from "pages/Invoices/Invoice Tabs/InvoiceItems";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InterLocation = () => {
  const appDispatch = useContext(DispatchContext);

  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);
  const [openSelectionHubsTo, setOpenSelectionHubsTo] =
    useState<boolean>(false);

  const [selectedHub, setSelectedHub] = useState<HubInfo>(null);
  const [selectedHubTo, setSelectedHubTo] = useState<HubInfo>(null);

  const [addedItems, setAddedItems] = useState<Array<InvoiceItem>>([]);
  const [addedSerials, setAddedSerials] = useState<addedSerials[]>([]);

  const [orderDate, setOrderDate] = useState<Date>(null);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const { isLoading: isLoadingInventory, data: inventoryHubs } = useQuery<
    QueryResult<Hub>,
    ErrorResponse
  >("inventoryHubsList", getInventoryHubsList);

  useEffect(() => {
    if (inventoryHubs && inventoryHubs?.data?.length === 1) {
      setSelectedHub(inventoryHubs?.data[0]);
      setSelectedHubTo(inventoryHubs?.data[0]);
    }
  }, [inventoryHubs]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingInventory });
  }, [isLoadingInventory]);

  useEffect(() => {
    if (selectedHub) {
      setValue("hubId", selectedHub.hubId, { shouldDirty: true });
      setOpenSelectionHubs(false);
    }
  }, [selectedHub]);

  useEffect(() => {
    if (selectedHubTo) {
      setValue("hubIdTo", selectedHubTo.hubId, { shouldDirty: true });
      setOpenSelectionHubsTo(false);
    }
  }, [selectedHubTo]);

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    setValue,
    reset,
  } = methods;

  const onCancel = () => {
    reset();
    setSelectedHub(null);
    setSelectedHubTo(null);
    setAddedItems([]);
    setAddedSerials([]);
    setOrderDate(null);
  };

  const InterLocationSubmit = useMutation(InterLocationAPI, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Transfer success`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem with Submission"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (values) => {
    let data = {
      fromHubId: selectedHub.locationId,
      toHubId: selectedHubTo.locationId,
      remarks: values.remarks,
      orderDate: orderDate ?? new Date(),
      items: addedItems.map((item) => {
        let serialsToSend = [];
        addedSerials
          ?.filter((s) => s.itemId === item.itemId)
          ?.map((serial) => {
            serialsToSend.push({ serialId: serial.serial });
          });
        return {
          itemId: item.itemId,
          quantity: Number(item.invoiceQty),
          cost: Number(item.invoicePrice),
          serials: serialsToSend,
        };
      }),
    };
    InterLocationSubmit.mutate(data);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>Inter Location Transfer</div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary no-border ${
                  !isDirty && "text-muted"
                }`}
                disabled={!isDirty}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="left-side-mid">
                  <div className="form-group w-50">
                    <label>
                      From Hub <span className="text-danger">*</span>
                    </label>
                    <Lookup
                      onButtonClicked={() => setOpenSelectionHubs(true)}
                      inputName="hubId"
                      isRequired={true}
                      initialData={inventoryHubs?.data
                        ?.filter(
                          (h) => h.locationId !== selectedHubTo?.locationId
                        )
                        .map((item) => ({
                          ...item,
                          id: item.locationId,
                          name: item.hubId,
                        }))}
                      onSelection={setSelectedHub}
                      inputValue={selectedHub?.hubId}
                      isDisabled={false}
                    />
                  </div>
                  <div className="form-group w-75">
                    <label>Remarks</label>
                    <textarea
                      {...register("remarks")}
                      className="form-control"
                      placeholder="Write your remarks"
                    />
                  </div>
                </div>
                <div className="right-side-mid">
                  <div className="form-group w-50">
                    <label>
                      To Hub <span className="text-danger">*</span>
                    </label>
                    <Lookup
                      onButtonClicked={() => setOpenSelectionHubsTo(true)}
                      inputName="hubIdTo"
                      isRequired={true}
                      initialData={inventoryHubs?.data
                        ?.filter(
                          (h) => h.locationId !== selectedHub?.locationId
                        )
                        .map((item) => ({
                          ...item,
                          id: item.locationId,
                          name: item.hubId,
                        }))}
                      onSelection={setSelectedHubTo}
                      inputValue={selectedHubTo?.hubId}
                      isDisabled={false}
                    />
                  </div>
                  <div className="form-group w-75">
                    <label>Order Date</label> <br />
                    <DatePicker
                      onChange={(date) => setOrderDate(date)}
                      selected={orderDate}
                      className="form-control"
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <InvoiceItems
                  isEditing={true}
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  hubId={selectedHub?.locationId}
                  isCreating={true}
                  customer={null}
                  addedSerials={addedSerials}
                  setAddedSerials={setAddedSerials}
                  isDisabled={!!selectedHub?.locationId}
                  withoutNonTang={true}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"From Hub"}
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={inventoryHubs?.data?.filter(
          (h) => h.locationId !== selectedHubTo?.locationId
        )}
        columns={hubsColumns}
        setRowClicked={setSelectedHub}
      />
      <SelectionModal
        modal={"To Hub"}
        showModal={openSelectionHubsTo}
        setShowModal={setOpenSelectionHubsTo}
        data={inventoryHubs?.data?.filter(
          (h) => h.locationId !== selectedHub?.locationId
        )}
        columns={hubsColumns}
        setRowClicked={setSelectedHubTo}
      />
    </>
  );
};

export default InterLocation;
