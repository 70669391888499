import { useContext, useEffect, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { SupplierBalanceReportAPI } from "./services/ReportServices";
import "./style/common.scss";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import backArrow from "img/back-arrow.svg";

import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import AgGrid from "elements/AgGrid";
import { useReactToPrint } from "react-to-print";
import DispatchContext from "context/DispatchContext";

import { useHistory, useLocation } from "react-router-dom";

import exportIcon from "img/export.svg";

import Filter from "./components/Filter";
import { getBaseCurrency } from "components/currency";

export default function SupplierBalanceReport() {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const [currentElement, setCurrentElement] = useState(1);
  const [, setRefreshCounter] = useState(0);
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];

  const isCustomizedReport = customizedComponentId != null;

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, control, getValues, setValue } = methods;

  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(SupplierBalanceReportAPI, {
    async onSuccess(data) {
      let currentData = { ...data };
      setReportData(currentData);
      setCurrentElement(getValues("groupingElement")?.value ?? 1);
    },
  });

  const onSubmit = () => {
    const values = getValues();
    let obj = {
      groupingElement: values.groupingElement?.value,
      customerId: values.CustomerId?.value,
      hubId: values.HubId?.value,
      marketId: values.MarketId?.value,
      repId: values.RepresentativeId?.value,
      regionId: values.RegionId?.value,
      invoiceHub: values.IsInvoiceHub?.value,
      period: values.groupingPeriod?.value,
    };
    generateReport.mutate(obj);
  };

  const [isSummary, setIsSummary] = useState(true);

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    setForPrinting(true);
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setForPrinting(false);
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [, setForPrinting] = useState(false);
  const reportPage = useRef(null);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const groupingElementsOpt = [
    {
      value: 1,
      label: "None",
    },
    {
      value: 2,
      label: "Vendors",
    },
    {
      value: 3,
      label: "Period Of Aging",
    },
  ];

  const isSummaryOpt = [
    {
      value: true,
      label: "Summary",
    },
    {
      value: false,
      label: "Detailed",
    },
  ];

  useEffect(() => {
    setReportData(null);
    onSubmit();
  }, [isSummary]);

  useEffect(() => {
    let params = {
      invoiceHub: true,
      groupingElement: 1,
    };
    if (!isCustomizedReport) {
      setValue("groupingElement", { value: 1, label: "None" });
      generateReport.mutate(params);
    }
  }, [isCustomizedReport]);

  const headers = ["No Grouping", "Customers", "Header 3"];

  const columns = [
    [
      {
        field: "elementName",
        headerName: reportData?.groupTitle ?? "",
        cellRenderer: "agGroupCellRenderer",
        pinned: "left",
        lockPostion: true,
        suppressMenu: true,
        floatingFilter: false,
        filter: false,
        suppressMovable: true,
      },
      {
        field: "total_Amount",
        headerName: "Total Amount",
        valueGetter: (params) => {
          return formatNumber(params?.data?.total_Amount, currencySymbol);
        },
      },
      {
        field: "total_OpenBalance",
        headerName: "Open Balance",
        valueGetter: (params) => {
          return formatNumber(params?.data?.total_OpenBalance, currencySymbol);
        },
      },
      {
        field: "total_PaidAmount",
        headerName: "Paid Amount",
        valueGetter: (params) => {
          return formatNumber(params?.data?.total_PaidAmount, currencySymbol);
        },
      },
    ],
    [
      {
        field: "vendorNumber",
        headerName: "Vendor Number",
      },
      {
        field: "vendorBalance",
        headerName: "Vendor Balance",
        valueGetter: (params) => {
          return formatNumber(params.data.vendorBalance, currencySymbol);
        },
      },
      {
        field: "age",
        headerName: "Age",
      },
      {
        field: "billNumber",
        headerName: "Bill Number",
      },
      {
        field: "billAmount",
        headerName: "Bill Amount",
        valueGetter: (params) => {
          return formatNumber(params.data.billAmount, currencySymbol);
        },
      },
      {
        field: "billDate",
        headerName: "Bill Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params.data.billDate);
        },
      },
      {
        field: "openBalance",
        headerName: "Open Balance",
        valueGetter: (params) => {
          return formatNumber(params.data.openBalance, currencySymbol);
        },
      },
      {
        field: "paidAmount",
        headerName: "Paid Amount",
        valueGetter: (params) => {
          return formatNumber(params.data.paidAmount, currencySymbol);
        },
      },
      {
        field: "lastPayment",
        headerName: "Last Payment",
        valueGetter: (params) => {
          return formatNumber(params.data.lastPayment, currencySymbol);
        },
      },
      {
        field: "lastPayDate",
        headerName: "Last Pay Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params.data.lastPayDate);
        },
      },
    ],
  ];

  const detailCellRenderer2 = ({ data }) => {
    return (
      <div className="py-4">
        {data.bills.length > 0 ? (
          <AgGrid
            data={data?.bills}
            columns={gridColumns[1]}
            pagination={false}
            autoHight={true}
            ref={childFnRef1}
            autoSize={true}
          />
        ) : (
          <div className="text-center">No Bills Found</div>
        )}
      </div>
    );
  };

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const [reportName, setReportName] = useState("Supplier Balance");

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className={`page-content ${isSummary ? "hide-arrow" : ""}`}>
              <Filter
                filters={[0, 1, 25, 17, 36, 37]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                groupingElements={groupingElementsOpt}
                isSummary={isSummary}
                setIsSummary={setIsSummary}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                setRefreshCounter={setRefreshCounter}
              />
              <div className="container report-filters-div mb-3">
                <div className="d-flex justify-content-between">
                  <div className="col-2">
                    {currentElement !== 1 && (
                      <>
                        <Controller
                          control={control}
                          name="isSummary"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={isSummaryOpt}
                              isSearchable={true}
                              value={isSummaryOpt?.find(
                                (opt) => opt?.value === isSummary
                              )}
                              onChange={(val) => setIsSummary(val?.value)}
                            />
                          )}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    {currentElement === 1 && (
                      <button
                        className="import-btn"
                        type="button"
                        onClick={exportToExcel}
                        disabled={
                          disabledExportBtn || reportData?.hubs?.length === 0
                        }
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export grid to Excel
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-success ms-5"
                      onClick={printDocument}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-printer me-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                      </svg>
                      Print report to PDF
                    </button>
                  </div>
                </div>
              </div>
              <div className="report-page" ref={reportPage}>
                <div className="report-header d-flex justify-content-between">
                  <div>
                    <h3>
                      <b>
                        {reportName}{" "}
                        {reportData?.groupTitle === "Nothing"
                          ? ""
                          : `grouped by ${reportData?.groupTitle}`}
                      </b>
                    </h3>{" "}
                    <br />
                    <p>
                      <b>Report Date: </b>
                      {dateFormmaterNoTime(reportData?.reportDate)}
                    </p>
                  </div>
                  <div>
                    <img
                      alt=""
                      src={reportData?.imageLogo ?? BlueSeedLogo}
                      className="report-logo"
                    ></img>
                  </div>
                </div>
                <div className="grid-wrapper">
                  <>
                    {gridColumns.length > 0 && (
                      <AgGrid
                        data={
                          currentElement === 1
                            ? reportData?.groups[0]?.bills
                            : reportData?.groups
                        }
                        columns={
                          currentElement === 1 ? gridColumns[1] : gridColumns[0]
                        }
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        fileName={reportName}
                        ref={childFnRef}
                        isReport={isCustomizedReport}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer2}
                        subTable={gridColumns[1]}
                        subTableObj={"bills"}
                        autoSize={true}
                      />
                    )}
                    <div className="mt-3 pb-3">
                      <div className="d-flex justify-content-between">
                        <div className="label">Total</div>
                        <div className="value">
                          Amount:{" "}
                          {formatNumber(
                            reportData?.total_Amount,
                            currencySymbol
                          )}
                        </div>
                        <div className="value">
                          Paid:{" "}
                          {formatNumber(
                            reportData?.total_PaidAmount,
                            currencySymbol
                          )}
                        </div>
                        <div className="value">
                          Balance:{" "}
                          {formatNumber(
                            reportData?.total_OpenBalance,
                            currencySymbol
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
