import { useContext, useEffect, useState } from "react";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useParams } from "react-router-dom";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Region } from "./SystemSetup.models";
import {
  createRegion,
  editRegion,
  getRegionsList,
} from "./SystemSetupServices/SystemSetupServices";

const NewRegionSetup = ({ match }) => {
  const history = useHistory();
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const dataMutation = useMutation("data", getRegionsList);

  const [regiondetails, setregiondetails] = useState<Region>(null);

  const { id } = useParams();

  useEffect(() => {
    if (id !== "new") {
      dataMutation.mutate(null, {
        onSuccess(result) {
          let idNum = Number(id);
          let data = result.data.find((item) => item.regionId === idNum);
          setregiondetails(data);
          setValue("name", data?.name);
          setSelectedId(idNum);
          setValue("isActive", data?.isActive, {
            shouldValidate: true,
            shouldTouch: true,
          });
        },
      });
    }
  }, [id]);

  const methods = useForm<Region>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: regiondetails?.isActive || true },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    if (dataMutation.error) {
      let notification = {
        variant: "danger",
        msg: dataMutation.error,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [dataMutation.error]);

  const addRegion = useMutation(createRegion, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Region ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.region_setup);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding region"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateRegion = useMutation(editRegion, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.region_setup);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing region"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: updateRegion?.isLoading || addRegion?.isLoading,
    });
  }, [updateRegion?.isLoading, addRegion?.isLoading, appDispatch]);

  const onSubmit = (data: Region) => {
    if (!selectedId) {
      addRegion.mutate(data);
    } else {
      updateRegion.mutate({ ...data, regionId: selectedId });
    }
  };

  const resetForm = () => {
    reset({ name: "", isActive: true });
  };

  //   const exportToExcel = () => {
  //     dataMutation.mutate(null, {
  //       onSuccess(result) {
  //         setDataGrid(result.data);
  //       },
  //     });
  //   };

  return (
    <div className="static-page">
      {" "}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="  page-title page-title-editable">
            <div className="d-flex flex-row">
              <div className="back-btn">
                <img
                  src={backArrow}
                  alt="back arrow"
                  onClick={() => history.push(AppUrls.region_setup)}
                />
              </div>
              <div>
                <p className="">Region Setup</p>
              </div>
            </div>
            <div className="d-flex" style={{ marginLeft: "-15px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={resetForm}
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                className="btn btn-primary"
              >
                {!selectedId ? "Add Region" : "Save Changes" || "Submit"}
              </button>
            </div>
          </div>

          <div className="page-content-wrapper">
            <div>
              {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
                <div className="">
                  <div className="row ">
                    <div className="col-4">
                      <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <>
                            <label>
                              Region Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              {...field}
                              name="name"
                              className={`form-control ${
                                errors["name"] && "required_field"
                              }`}
                              type="text"
                              required
                            />
                          </>
                        )}
                      />
                      {errors["name"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-1">
                      <label>Active</label>
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="isActive"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="activeCheckbox"
                                  name="isActive"
                                  onChange={() => onChange(!value)}
                                  checked={value}
                                />
                                <span className="slider round"></span>
                              </label>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewRegionSetup;
