import { useContext, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  dateFormmaterNoTime,
  formatNumber,
  notificationMessage,
} from "global/helpers";

import {
  approveVendorRma,
  creditVendorRma,
  getVendorRmaDetails,
  getVendorRmaList,
  refundVendorRma,
  rejectRma,
} from "./RmaServices/RmaServices";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { FormProvider, useForm } from "react-hook-form";
import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import DepositModal from "./DepositModal";

enum RMAStatus {
  Open = 1,
  Voided = 2,
  "Partially Shipped" = 3,
  Shipped = 4,
  Credited = 5,
  Rejected = 6,
  Approved = 7,
  Refunded = 8,
}

const ApproveVendorRma = ({ match }) => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRma, setSelectedRma] = useState(null);
  const appDispatch = useContext(DispatchContext);
  const rmaDetails = useRef(null);
  const history = useHistory();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const isApprove: boolean = match.path === AppUrls.approve_vendor_rma;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data: rmaList, refetch } = useQuery(["vendor-rma", filter], () =>
    getVendorRmaList({ status: isApprove ? 1 : 4, params: filter.params ?? "" })
  );

  const { mutate } = useMutation(getVendorRmaDetails, {
    onSuccess(data) {
      rmaDetails.current = data;
    },
  });

  const approveMutation = useMutation(approveVendorRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA approved successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      rmaDetails.current = null;
      setSelectedRma(null);
      refetch();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem appproving rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const rejectMutation = useMutation(rejectRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA rejected successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      rmaDetails.current = null;
      setSelectedRma(null);
      refetch();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem rejecting rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const refundMutation = useMutation(refundVendorRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA refunded successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem refunding rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const creditMutation = useMutation(creditVendorRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA credited successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem crediting rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const columns = [
    {
      field: "itemNumber",
      headerName: "Itembata Number",
      with: 20,
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: "quantity",
      headerName: "RMA QTY",
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      cellRendererFramework: (params) =>
        formatNumber(params.data.quantity * params.data.cost),
    },
  ];

  const rmaColumns = [
    {
      field: "vendorRmaNumber",
      headerName: "RMA No.",
    },
    {
      field: "vendorNumber",
      headerName: "Vendor No.",
    },
    {
      field: "purchaseOrderNumber",
      headerName: "Po No.",
    },
    {
      field: "billNumber",
      headerName: "Bill No.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => RMAStatus[params.value],
    },
    {
      field: "vendorRMADate",
      headerName: "RMA Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "RMA Amount",
      cellRenderer: (params) => formatNumber(params.value),
    },
  ];

  const onRmaSelected = (rma) => {
    setSelectedRma(rma);
    mutate({ queryKey: [rma.vendorRMAId] });
    setOpenSelection(false);
  };

  const onApprove = () => {
    approveMutation.mutate(selectedRma.vendorRMAId);
  };

  const onReject = () => {
    rejectMutation.mutate(selectedRma.vendorRMAId);
  };

  const onRefund = () => {
    setShowModal(true);
  };

  const onRefundConfirm = (id: number) => {
    setShowModal(false);
    refundMutation.mutate({ rmaId: selectedRma.vendorRMAId, refundTo: id });
  };

  const onCredit = () => {
    creditMutation.mutate(selectedRma.vendorRMAId);
  };

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <div>
            {isApprove ? "Approve Vendor RMA" : "Refund/Credit Vendor RMA"}
          </div>
          {isApprove ? (
            <div>
              <button
                className="btn btn-danger"
                onClick={onReject}
                disabled={!selectedRma}
              >
                Reject
              </button>
              <button
                className="btn btn-success"
                onClick={onApprove}
                disabled={!selectedRma}
              >
                Approve
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-primary"
                onClick={onRefund}
                disabled={!selectedRma}
              >
                Refund
              </button>
              <button
                className="btn btn-primary"
                onClick={onCredit}
                disabled={!selectedRma}
              >
                Credit
              </button>
            </div>
          )}
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row  gx-5">
              <div className="col-lg-8 col-sm-12">
                <div className="row gx-5 gy-4">
                  <div className="col-lg-6 col-sm-12">
                    <label>
                      RMA No. <span className="text-danger">*</span>
                    </label>
                    <FormProvider {...methods}>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="vendorRmaId"
                        initialData={rmaList?.data?.map((item) => ({
                          ...item,
                          id: item.vendorRMAId,
                          name: item.vendorRmaNumber,
                        }))}
                        onSelection={onRmaSelected}
                        inputValue={selectedRma?.vendorRmaNumber}
                      />
                    </FormProvider>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label>RMA Date</label>
                    <div>
                      {rmaDetails.current?.vendorRMADate &&
                        new Date(
                          rmaDetails.current?.vendorRMADate
                        ).toLocaleString()}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Purchase Order No.</label>
                    <div>{rmaDetails.current?.purchaseOrderNumber}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Bill No.</label>
                    <div>{rmaDetails.current?.billNumber}</div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label>Hub ID</label>
                    <div>{rmaDetails.current?.hubKey}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Vendor No.</label>
                    <div>{rmaDetails.current?.vendorNumber}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Company</label>
                    <div>{rmaDetails.current?.shippingInfo?.company}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Status</label>
                    <div>{RMAStatus[rmaDetails.current?.status]}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-450 mt-5">
              <AgGrid
                data={rmaDetails.current?.items ?? []}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal="RMA"
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={rmaList?.data}
        columns={rmaColumns}
        setRowClicked={onRmaSelected}
        searchProp={true}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
      />
      <DepositModal
        showModal={showModal}
        onExit={() => setShowModal(false)}
        onCreation={onRefundConfirm}
        isDeposit={true}
      />
    </>
  );
};

export default ApproveVendorRma;
