import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  getReportInit,
  getReportInventoryItems,
} from "reports/services/ReportServices";

import Select from "react-select";
import DateGrid from "components/DateFilter/DateGrid";
import { Controller, useForm } from "react-hook-form";
import { addSettings, getSettings, getYOYS } from "./DashboardServices";

import { Chart } from "react-google-charts";

import Accordion from "react-bootstrap/Accordion";

const YOYS = () => {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [dateCategory, setDateCategory] = useState(null);

  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);

  const { data: initData } = useQuery("initData", getReportInit);
  const { data: inventoryItems } = useQuery(
    [{ hubId: -1, itemType: 1 }],
    getReportInventoryItems
  );

  let ItemsOpt = inventoryItems?.data?.map((data) => {
    return { value: data.itemId, label: data.itemNumber };
  });
  let CategoryOpt = initData?.categories?.map((data) => {
    return { value: data.itemCategoryId, label: data.category };
  });
  let HubIdOpt = initData?.hubs?.map((data) => {
    return { value: data.locationId, label: data.hubId };
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { control } = methods;

  const [apiDATA, setApiDATA] = useState(null);

  const mutate = useMutation(getYOYS, {
    onSuccess(data) {
      setApiDATA(data);
    },
  });

  useEffect(() => {
    mutate.mutate({});
  }, []);

  useEffect(() => {
    mutate.mutate({
      startDate: startDate,
      endDate: endDate,
      hubId: selectedHub?.value,
      itemId: selectedItem?.value,
      categoryId: selectedCat?.value,
    });
  }, [startDate, endDate, selectedHub, selectedItem, selectedCat]);

  let data = [
    ["Label", "Value"],
    ["", apiDATA === "" ? 0 : apiDATA],
  ];

  const mutateSettings = useMutation(getSettings, {
    onSuccess(data) {
      setOptions({
        min: data?.minimum,
        max: data?.maximum,
        greenFrom: data?.greenFrom,
        greenTo: data?.greenTo,
        yellowFrom: data?.yellowFrom,
        yellowTo: data?.yellowTo,
        redFrom: data?.redFrom,
        redTo: data?.redTo,
      });
    },
  });

  useEffect(() => {
    mutateSettings.mutate(1);
  }, []);

  const [options, setOptions] = useState({
    min: 0,
    max: 0,
    greenFrom: 0,
    greenTo: 0,
    yellowFrom: 0,
    yellowTo: 0,
    redFrom: 0,
    redTo: 0,
  });

  const settingsM = useMutation(addSettings, {
    onSuccess() {
      mutateSettings.mutate(1);
    },
  });

  const saveConfig = () => {
    let obj = {
      ...options,
      dashboardComponent: 1,
      minimum: options?.min,
      maximum: options?.max,
    };
    settingsM.mutate(obj);
  };

  return (
    <>
      <h3 className="m-2">Year over Year Sales</h3>
      <Accordion>
        <Accordion.Header className="border">
          <h5>
            <b>Settings</b>
          </h5>
        </Accordion.Header>
        <Accordion.Body className="border">
          <div className="row w-100">
            <DateGrid
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              dateCategory={dateCategory}
              setDateCategory={setDateCategory}
              isFullWidth={true}
            />
            <div className="col-4">
              <label>Hub</label>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={HubIdOpt}
                    value={selectedHub}
                    onChange={(e) => setSelectedHub(e)}
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              />
            </div>
            <div className="col-4">
              <label>Item</label>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={ItemsOpt}
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e)}
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              />
            </div>
            <div className="col-4">
              <label>Category</label>
              <Controller
                control={control}
                name="type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={CategoryOpt}
                    value={selectedCat}
                    onChange={(e) => setSelectedCat(e)}
                    isSearchable={true}
                    isClearable={true}
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-3">
            <div className="row">
              <div className="col-6">
                <label>Min</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.min || ""}
                  onChange={(e) =>
                    setOptions({ ...options, min: Number(e.target.value) })
                  }
                />
              </div>
              <div className="col-6">
                <label>Max</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.max || ""}
                  onChange={(e) =>
                    setOptions({ ...options, max: Number(e.target.value) })
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>Green From</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.greenFrom || ""}
                  onChange={(e) =>
                    setOptions({
                      ...options,
                      greenFrom: Number(e.target.value),
                    })
                  }
                />
              </div>
              <div className="col-6">
                <label>Green To</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.greenTo || ""}
                  onChange={(e) =>
                    setOptions({ ...options, greenTo: Number(e.target.value) })
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>Yellow From</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.yellowFrom || ""}
                  onChange={(e) =>
                    setOptions({
                      ...options,
                      yellowFrom: Number(e.target.value),
                    })
                  }
                />
              </div>
              <div className="col-6">
                <label>Yellow To</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.yellowTo || ""}
                  onChange={(e) =>
                    setOptions({ ...options, yellowTo: Number(e.target.value) })
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>Red From</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.redFrom || ""}
                  onChange={(e) =>
                    setOptions({ ...options, redFrom: Number(e.target.value) })
                  }
                />
              </div>
              <div className="col-6">
                <label>Red To</label>
                <input
                  type="number"
                  className="form-control"
                  value={options?.redTo || ""}
                  onChange={(e) =>
                    setOptions({ ...options, redTo: Number(e.target.value) })
                  }
                />
              </div>
            </div>
          </div>
          <div className="p-2 mt-3 text-end">
            <button
              type="button"
              className="btn btn-success"
              onClick={saveConfig}
            >
              Save Settings
            </button>
          </div>
        </Accordion.Body>
      </Accordion>
      <div className="mt-4">
        <Chart
          chartType="Gauge"
          width="100%"
          height="350px"
          data={data}
          //ts-ignore
          options={options}
        />
      </div>
    </>
  );
};

export default YOYS;
