import SelectionModal from "components/SelectionModal/SelectionModal";
import StateContext from "context/StateContext";
import Lookup from "elements/Lookup";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

const StartingBalance = ({ setValue, startingBalance, setStartingBalance }) => {

    const appState = useContext(StateContext);

    const [openSelectionLedger, setOpenSelectionLedger] = useState<boolean>(false)
    const [selectedLedger, setSelectedLedger] = useState(null)

    const {
        data: paymentAccounts,
    } = useQuery("affectedAccount", () => getLedgers({ ledgerType: LedgerTypeEnum.Equity }));

    useEffect(() => {
        if (appState.selectedHubs.length > 0)
            setStartingBalance(appState.selectedHubs.map(hub => ({ hubId: hub.locationId, quantity: '', itemCost: '', title: hub.hubId })))
    }, [appState.selectedHubs])

    const ledgersColumns = [
        {
            field: "accountNumber",
            headerName: "Account",
            resizable: true
        },
        {
            field: "accountName",
            headerName: "Account name",
            resizable: true
        },
        {
            field: "balance",
            headerName: "Balance",
            resizable: true
        },
        {
            field: "isDebit",
            headerName: "Debit",
            resizable: true,
            cellRenderer: (params) => params.value ? 'Yes' : 'No',
        }
    ];

    const handleInputChange = (index, event) => {
        const values = [...startingBalance];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;

        setStartingBalance(values);
    };

    const onLedgerSelection = (e) => {
        setSelectedLedger(e)
        setOpenSelectionLedger(false)
        setValue("balancingAccountId", e.ledgerId, { shouldValidate: true })
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-3">
                    <div className="form-group">
                        <label>Balancing affected account</label>
                        <Lookup
                            onButtonClicked={() => setOpenSelectionLedger(true)}
                            inputName="balancingAccountId"
                            initialData={paymentAccounts?.data?.map(item => ({ ...item, id: item.ledgerId, name: item.accountName }))}
                            onSelection={onLedgerSelection}
                            inputValue={selectedLedger?.accountName}
                        />
                    </div>
                </div>
            </div>
            {startingBalance.map((hub, index) => {
                return (
                    <div className="row my-2" key={hub.hubId}>
                        <hr />
                        <h5 className="mb-3">{hub.title}</h5>
                        <div className="col-sm-2">
                            <div className="form-group">
                                <label>Quantity</label>
                                <input className='form-control' type="number" name="quantity" value={hub.quantity} onChange={(event) => handleInputChange(index, event)} disabled={!selectedLedger} />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-group">
                                <label>Cost</label>
                                <input className='form-control' type="number" name="itemCost" value={hub.itemCost} onChange={(event) => handleInputChange(index, event)} disabled={!selectedLedger} />
                            </div>
                        </div>
                    </div>
                )
            })}
            <SelectionModal
                modal={'Ledger'}
                showModal={openSelectionLedger}
                setShowModal={setOpenSelectionLedger}
                data={paymentAccounts?.data}
                columns={ledgersColumns}
                setRowClicked={onLedgerSelection}
            />
        </>
    );
};

export default StartingBalance;