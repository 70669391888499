import { useState, useEffect } from 'react';
import AG from "elements/AG";
import Select from "react-select";
import { DropDownOption, GridColumns } from 'components/Common.models';
import { SalesOrder } from '../../../SalesOrder/SalesOrder.model';
import { statusEnum } from '../../../SalesOrder/StatusEnum';

type Props = {
    salesOrdersColumns: GridColumns[],
    customerId: number,
    customerSalesOrders: SalesOrder[],
    setCustomerSalesOrder: Function,
    initialSalesOrder: SalesOrder[],
};

const SalesOrders = (props: Props) => {
    const [selectedOrderStatus, setSelectedOrderStatus] = useState<DropDownOption>({ label: "All", value: 0 });

    const onOrderStatusChange = (e) => {
        setSelectedOrderStatus(e)
    }

    useEffect(() => {
        if (selectedOrderStatus) {
            if (selectedOrderStatus.value === '0') {
                props.setCustomerSalesOrder(
                    props.initialSalesOrder
                )
            } else {
                props.setCustomerSalesOrder(
                    props.initialSalesOrder.filter(val => val.status === Number(selectedOrderStatus.value))
                )
            }
        }
    }, [selectedOrderStatus])

    return (
        <>
            <div className="min-h-350">
                <div className="row mb-3">
                    <div className='col-lg-3'>
                        <Select
                            options={Object.entries(statusEnum).map(([k, v]) => ({ label: v, value: k }))}
                            isClearable={false}
                            isSearchable={true}
                            className="flex-grow-1"
                            value={selectedOrderStatus}
                            onChange={onOrderStatusChange}
                        />
                    </div>
                </div>
                <div className="h-100">
                    <AG
                        data={props.customerSalesOrders}
                        columns={props.salesOrdersColumns}
                    />
                </div>
            </div>
        </>
    )
}

export default SalesOrders
