import {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  forwardRef,
} from "react";
import DispatchContext from "context/DispatchContext";
import { AgGridReact } from "ag-grid-react";
import { IServerSideDatasource } from "ag-grid-community";
import { IServerSideGetRowsParams } from "ag-grid-community/dist/lib/interfaces/iServerSideDatasource";
import { GridReadyEvent } from "ag-grid-community/dist/lib/events";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./AgGrid.scss";
import CheckboxRenderer from "pages/Accounting/CheckboxRenderer";

type Props = {
  fetchMethod?: any;
  data?: any[];
  columns?: any;
  filters?: any;
  setrowClicked?: any;
  refreshCounter?: number;
  exportToExcel?: boolean;
  setExportToExcelClicked?: any;
  setDisabledExportBtn?: any;
  setDataGrid?: any;
  setIsLoadingGridExport?: any;
  autoSize?: boolean;
  pagination?: boolean;
  forPrinting?: boolean;
  autoHight?: boolean;
  fileName?: string;
  masterDetail?: boolean;
  detailCellRenderer?: any;
  defaultExportParams?: any;
  subTable?: any;
  subTableObj?: string;
  isReport?: boolean;
  multipleSelection?: boolean;
  setRowChecked?: any;
  singleClickEdit?: boolean;
  displayIsRefreshing?: boolean;
};

const AgGrid = forwardRef((props: Props, ref?: any) => {
  const appDispatch = useContext(DispatchContext);
  const defaultPageSize = 15;
  const [paginationPageSize, setPaginationSize] = useState(defaultPageSize);
  const [gridApi, setGridApi] = useState(null);
  const [gridParams, setGridParams] = useState<any>(null);
  const gridRef = useRef<any>();

  useEffect(() => {
    if (!props.data) {
      if (gridParams) {
        const dataSource: IServerSideDatasource = {
          getRows(params: IServerSideGetRowsParams): void {
            const { startRow } = params.request;
            let PageStart = Number(startRow);
            props.fetchMethod.mutate(
              {
                ...props.filters,
                PageStart: PageStart,
                PageSize: paginationPageSize ?? props.data?.length,
              },
              {
                onSuccess: (data) => {
                  params.success({
                    rowData: data.data,
                    rowCount: data.count,
                  });
                },
              }
            );
          },
        };
        gridParams.api.setServerSideDatasource(dataSource);
      }
    }
  }, [gridParams, props.filters, props.data]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    if (props.fetchMethod) {
      setGridParams(params);
    }
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    if (props.data) {
      if (props.autoHight) gridRef.current.api.setDomLayout("autoHeight");
      if (!props.autoSize) return;
      else gridRef.current.api.sizeColumnsToFit();
    }
  }, [props.data, props.autoSize, props.autoHight, gridRef]);

  useEffect(() => {
    gridRef?.current?.api?.redrawRows();
  }, [props.masterDetail, gridRef]);

  useEffect(() => {
    if (props.forPrinting) {
      gridRef.current.api.setDomLayout("print");
    } else gridRef.current.api.setDomLayout();
  }, [gridRef, props.forPrinting]);

  useEffect(() => {
    if (gridApi && props.refreshCounter > 0) {
      gridApi?.refreshServerSideStore();
      if (
        props?.displayIsRefreshing ||
        props?.displayIsRefreshing === undefined
      ) {
        let notification = {
          variant: "success",
          msg: "Table is refreshing...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [gridApi, props.refreshCounter]);

  const onRowDoubleClicked = () => {
    let selectedRows = gridApi?.getSelectedRows();
    props.setrowClicked !== undefined &&
      props.setrowClicked !== null &&
      selectedRows &&
      props.setrowClicked(selectedRows[0]);
  };

  const onCheckboxSelectionChange = (event) => {
    if (props.setRowChecked) props.setRowChecked(event.api.getSelectedRows());
  };

  const excelStyles = useMemo(() => {
    return [
      {
        id: "coverHeading",
        font: {
          size: 18,
          bold: true,
        },
      },
      {
        id: "header",
        alignment: {
          vertical: "Center",
          horizental: "Center",
        },
        interior: {
          color: "#99c5ff",
          pattern: "Solid",
          patternColor: undefined,
        },
        borders: {
          borderBottom: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          boorderTop: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderLeft: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderRight: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
        },
        padding: 15,
        font: {
          bold: true,
        },
      },
      {
        id: "subheader",
        alignment: {
          vertical: "Center",
          horizental: "Center",
        },
        interior: {
          color: "#e6f1ff",
          pattern: "Solid",
          patternColor: undefined,
        },
        borders: {
          borderBottom: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          boorderTop: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderLeft: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderRight: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
        },
        padding: 15,
        font: {
          bold: true,
        },
      },
      {
        id: "cell",
        alignment: {
          vertical: "Center",
          horizental: "Center",
        },
        padding: 15,
        borders: {
          borderBottom: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          boorderTop: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderLeft: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
          borderRight: {
            color: "#babfc7",
            lineStyle: "Continuous",
            weight: 2,
          },
        },
        dataType: "String",
      },
      {
        id: "twoDecimalPlaces",
        alignment: {
          horizental: "Center",
        },
      },
    ];
  }, []);

  const myColumnWidthCallback = (params) => {
    var originalWidth = params?.column?.getActualWidth();
    return originalWidth ?? 200;
  };

  const getPrePendContent = () => [
    [],
    [
      {
        data: { value: `${props.fileName}`, type: "String" },
        mergeAcross: 3,
        styleId: "coverHeading",
      },
    ],
    [],
  ];

  const getParams = () => ({
    prependContent: getPrePendContent(),
    columnWidth: myColumnWidthCallback,
    fileName: props.fileName,
  });

  const getColumsWidth = () => {
    if (gridRef.current) return gridRef.current.columnApi.getAllColumns();
  };
  if (ref) ref.current = getColumsWidth;

  useEffect(() => {
    if (props.exportToExcel) {
      setTimeout(
        function () {
          gridRef.current.api.exportDataAsExcel(getParams());
          props.setExportToExcelClicked(false);
          props.setDisabledExportBtn(false);
          props.setDataGrid && props.setDataGrid(null);
          props.setIsLoadingGridExport && props.setIsLoadingGridExport(false);
          let notification = {
            variant: "success",
            msg: "Exported successfully",
          };
          appDispatch({ type: "notification", value: notification });
        },
        props.data.length > 100 ? 5000 : 3000
      );
    }
  }, [props.exportToExcel, gridApi]);

  const cell = (text, styleId) => {
    return {
      styleId: styleId,
      data: {
        type: "String",
        value: String(text),
      },
    };
  };

  const subHeaders = [];
  props.subTable?.forEach(function (col) {
    subHeaders.push(cell(col.headerName, "subheader"));
  });

  const onFirstDataRendered = useCallback((params) => {
    if (props.autoHight) {
      params.api.setDomLayout("autoHeight");
      params.api.expandAll();
    }
  }, []);
  const popupParent = useMemo(() => {
    return document.querySelector("body");
  }, []);

  return (
    <div className="ag-grid-container ag-theme-material">
      <AgGridReact
        defaultExportParams={
          props.subTable && {
            getCustomContentBelowRow: function (params) {
              if (params.node?.data?.[props.subTableObj])
                return [subHeaders].concat(
                  params?.node?.data?.[props.subTableObj]?.map(
                    function (record) {
                      let keys = Object.keys(record);
                      let res = [];
                      for (let key of keys) {
                        if (
                          typeof record[key] !== "object" ||
                          record[key] instanceof Date
                        )
                          res.push(cell(record[key] ?? "", "cell"));
                      }
                      return res;
                    }
                  ),
                  [[]]
                );
              return [subHeaders];
            },
            columnWidth: 120,
          }
        }
        ref={gridRef}
        defaultColDef={{
          flex: 1,
          editable: false,
          resizable: true,
          sortable: false,
          wrapText: true,
          filter: "agTextColumnFilter",
          suppressFiltersToolPanel: true,
          columnsMenuParams: {
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
          cellStyle: {
            "min-height": "50px",
            height: "100%",
            display: "flex",
            "align-items": "center",
          },
          autoHeight: true,
          suppressMenu: true,
        }}
        serverSideStoreType="partial"
        popupParent={popupParent}
        rowSelection={props.multipleSelection ? "multiple" : "single"}
        onRowDoubleClicked={onRowDoubleClicked}
        onSelectionChanged={onCheckboxSelectionChange}
        suppressRowClickSelection={props.multipleSelection}
        animateRows={true}
        rowData={props.data}
        columnDefs={props.columns}
        onGridReady={onGridReady}
        rowModelType={props.fetchMethod ? "serverSide" : "clientSide"}
        pagination={props.pagination ?? true}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        maxBlocksInCache={paginationPageSize}
        enableCellTextSelection={true}
        stopEditingWhenCellsLoseFocus={true}
        masterDetail={props.masterDetail ?? false}
        detailCellRenderer={props.masterDetail ? "detailCellRenderer" : null}
        onFirstDataRendered={onFirstDataRendered}
        detailRowAutoHeight={true}
        singleClickEdit={props.singleClickEdit ?? false}
        frameworkComponents={{
          detailCellRenderer: props.detailCellRenderer,
          checkboxRenderer: CheckboxRenderer,
        }}
        //@ts-ignore
        excelStyles={excelStyles}
      />
    </div>
  );
});

export default AgGrid;
