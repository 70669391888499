import backArrow from "img/back-arrow.svg";
import DispatchContext from "context/DispatchContext";
import exportIcon from "img/export.svg";
import { useHistory, useParams } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import { useMutation } from "react-query";
import {
  getPaymentDetails,
  voidPaymentReceipt,
} from "./InvoicesServices/InvoicesServices";
import { useEffect, useState, useContext } from "react";
import { dateFormmaterNoTime } from "global/helpers";
import AG from "elements/AG";
import SectionTitle from "elements/SectionTitle";

const PaymentReceiptDetails = () => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const { id } = useParams();

  const [data, setData] = useState(null);

  const getPaymentDetailsM = useMutation(getPaymentDetails, {
    async onSuccess(result) {
      if (result) {
        setData(result);
      }
    },
  });

  useEffect(() => {
    if (id) getPaymentDetailsM.mutate(id);
  }, [id]);

  const onBackClick = () => {
    history.push({ pathname: AppUrls.payment_receipt, state: "filter" });
  };

  const voidM = useMutation(voidPaymentReceipt, {
    async onSuccess() {
      let notification = {
        variant: "danger",
        msg: "Payment voided successfully.",
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.payment_receipt);
    },
  });

  const voidClicked = () => {
    voidM.mutate(id);
  };

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
    },
    {
      field: "invoiceBalance",
      headerName: "Invoice Balance",
    },
    {
      field: "invoiceAmount",
      headerName: "Invoice Amount",
    },
    {
      field: "invoicePaidAmount",
      headerName: "Invoice Paid Amount",
    },
    {
      field: "invoicePaidDate",
      headerName: "Invoice Paid Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
  ];

  const reportClicked = () => {
    history.push(`/invoices/payment-receipt/${id}/report`);
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          Receipt No. {data?.receiptNumber}
        </div>
        <div>
          <button className="import-btn" type="button" onClick={reportClicked}>
            <img src={exportIcon} className="me-2" alt="" />
            View Report
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={voidClicked}
            disabled={data?.status === 3}
          >
            Void
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="right-side-mid">
              <div className="row">
                <div className="col">
                  <div>
                    <label>Account No</label>
                    <p>
                      {data?.accountNumber ?? (
                        <span className="text-muted fst-italic">
                          Account No
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <label>Company</label>
                    <p>
                      {data?.company ?? (
                        <span className="text-muted fst-italic">Company</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 border d-flex justify-content-between p-3 me-2">
              <span
                className={data?.balance <= 0 ? "text-danger" : "text-success"}
              >
                Current Balance
              </span>
              <span
                className={data?.balance <= 0 ? "text-danger" : "text-success"}
              >
                {`$ ${data?.balance ?? "0.00"}`}
              </span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="left-side-mid">
              <div className="row">
                <div className="col border d-flex justify-content-between p-3 me-2">
                  <span
                    className={
                      data?.payment <= 0 ? "text-danger" : "text-success"
                    }
                  >
                    Payment
                  </span>
                  <span
                    className={
                      data?.payment <= 0 ? "text-danger" : "text-success"
                    }
                  >
                    {`$ ${data?.payment ?? "0.00"}`}
                  </span>
                </div>
                <div className="col border d-flex justify-content-between p-3 me-2">
                  <span
                    className={
                      data?.unapplied <= 0 ? "text-danger" : "text-success"
                    }
                  >
                    Un-Appiled
                  </span>
                  <span
                    className={
                      data?.unapplied <= 0 ? "text-danger" : "text-success"
                    }
                  >
                    {`$ ${data?.unapplied ?? "0.00"}`}
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <label>Remark</label>
                  <p>
                    {data?.remark ?? (
                      <span className="text-muted fst-italic">Remark</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side-mid">
              <div className="row">
                <div className="col">
                  <div>
                    <label>Check No</label>
                    <p>
                      {data?.checkNumber ?? (
                        <span className="text-muted fst-italic">Check No</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <label>Ref No</label>
                    <p>
                      {data?.refNumber ?? (
                        <span className="text-muted fst-italic">Ref No</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div>
                    <label>Check Date</label>
                    <p>
                      {dateFormmaterNoTime(data?.date) ?? (
                        <span className="text-muted fst-italic">
                          Check Date
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <SectionTitle title="Invoices" editable={false} />
              <div className="h-450">
                <AG
                  data={data?.paymentReceiptInvoices}
                  columns={columns}
                  autoColumns={true}
                  pagination={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentReceiptDetails;
