import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { GridColumn } from "elements/AG.models";
import AgGrid from "elements/AgGrid";
import { checkIfAllowed } from "global/helpers";
import exportIcon from "img/export.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import addIcon from "../../img/add-icon.svg";
import { getVendorTypes, getVendors } from "./Vendors.services";
const VendorsList = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [activeOnly, setIsActiveOnly] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const { data: userTypes } = useQuery("get_user_types", getVendorTypes);

  const dataMutation = useMutation("data", getVendors, {
    async onSuccess(response) {
      setDataGrid(response?.data);
    },
  });

  const onRowDoubleClick = (e) => {
    const url = `${AppUrls.vendors}/${e.vendorId}`;
    history.push(url);
  };

  const columns: GridColumn[] = [
    {
      field: "vendorNumber",
      headerName: "Vendor No",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "accountId",
      headerName: "Account w/Vendor",
    },
    {
      field: "vendorTypeName",
      headerName: "Type",
    },
    {
      field: "isPreferred",
      headerName: "Preferred?",
      valueGetter: (params) => {
        return params.data.isPreferred ? "Yes" : "No";
      },
    },
    {
      field: "isApproved",
      headerName: "Approved?",
      valueGetter: (params) => {
        return params.data.isApproved ? "Yes" : "No";
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      sortable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
              <div class="${status ? "active-status" : "disabled-status"}">
                ${status ? "Active" : "Disabled"}
              </div>
            `;
      },
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);
  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const [filters, setFilters] = useState({
    vendorTypeId: null,
    activeOnly: true,
    pageParam: true,
  });
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const onAccountTypeChange = (e) => {
    setSelectedAccountType(e);
    setFilters((filters) => ({ ...filters, vendorTypeId: e?.value ?? null }));
    setDataGrid(null);
  };
  const clearFilters = () => {
    setSelectedAccountType(null);
    setIsActiveOnly(true);
    setFilters({ vendorTypeId: null, activeOnly: true, pageParam: true });
    setDataGrid(null);
  };

  const onRadioChange = (e) => {
    const value = e.target.checked;
    setIsActiveOnly(!value);
    if (value === true)
      setFilters((filters) => ({ ...filters, activeOnly: null }));
    else setFilters((filters) => ({ ...filters, activeOnly: !value }));
    setDataGrid(null);
  };
  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ ...filters, searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Vendors</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0 || !dataGrid}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_vendor} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Vendor
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="">
            <div className="row mb-4 align-items-end">
              <div className="col-lg-3">
                <div className="page-search">
                  <label className="me-2">Search</label>
                  <div className="input-group">
                    <span className="page-search-icon">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      className="page-search-input ps-5"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => onSearchTermChange(e)}
                      value={searchTerm}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <label className="me-2">Vendor Type</label>
                <Select
                  options={userTypes?.map((type) => ({
                    value: type.vendorTypeId,
                    label: type.name,
                  }))}
                  isSearchable={true}
                  value={selectedAccountType}
                  onChange={onAccountTypeChange}
                  className="me-4 w-100"
                  isClearable
                />
              </div>
              <div className="col-lg-2">
                <button
                  type="button"
                  onClick={clearFilters}
                  className="btn btn-outline-primary custom-outline"
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="form-group">
              <label className="checkbox-inline">
                <input
                  id="showDisabled"
                  type="checkbox"
                  onChange={onRadioChange}
                  checked={!activeOnly}
                />
                <label className="text-black ms-2" htmlFor="showDisabled">
                  Show Disabled
                </label>
              </label>
            </div>
          </div>
          <AgGrid
            columns={columns}
            fetchMethod={dataMutation}
            data={dataGrid}
            filters={filters}
            setDataGrid={setDataGrid}
            setrowClicked={onRowDoubleClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            refreshCounter={refreshCounter}
            fileName="Vendors List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default VendorsList;
