import { useContext, useEffect, useState } from "react";

import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import addIcon from "../../img/add-icon.svg";
import { Region } from "./SystemSetup.models";
import {
  createRegion,
  editRegion,
  getRegionsList,
} from "./SystemSetupServices/SystemSetupServices";

import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

const RegionSetup = ({ match }) => {
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataMutation = useMutation("data", getRegionsList);
  const { isLoading } = dataMutation;
  const methods = useForm<Region>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: true },
  });
  const { reset } = methods;

  useEffect(() => {
    if (dataMutation.error) {
      let notification = {
        variant: "danger",
        msg: dataMutation.error,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [dataMutation.error]);

  const addRegion = useMutation(createRegion, {
    async onSuccess() {
      setRefreshCounter((prev) => prev + 1);
      let notification = {
        variant: "success",
        msg: `Region ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding region"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateRegion = useMutation(editRegion, {
    async onSuccess() {
      setRefreshCounter((prev) => prev + 1);
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing region"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: updateRegion?.isLoading || addRegion?.isLoading,
    });
  }, [updateRegion?.isLoading, addRegion?.isLoading, appDispatch]);

  const columns = [
    {
      field: "name",
      headerName: "Region",
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
  ];

  const history = useHistory();

  const onRowDoubleClicked = (e: Region) => {
    const url = `${AppUrls.region_setup}/${e.regionId}`;
    history.push(url);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Regions</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || isLoading || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_region_setup} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Region
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row h-100">
          <div className=" grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                fetchMethod={dataMutation}
                data={dataGrid}
                setDataGrid={setDataGrid}
                filters={filter}
                setrowClicked={onRowDoubleClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                refreshCounter={refreshCounter}
                fileName="Regions List Report"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionSetup;
