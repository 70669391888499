import { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { LOAD_FAIL_MSG } from "constants/NotificationMsgs";
import { useQuery } from "react-query";
import { getPurchaseOrder } from "./PurchasingServices/PurchaseServices";
import DispatchContext from "context/DispatchContext";
import { states } from "components/data/States";
import { statusEnum, typesEnum } from "./StatusEnum";
import { dateFormmaterNoTime, formatNumber } from "global/helpers";
import AG from "elements/AG";
import { Tabs, Tab } from "react-bootstrap";
import { PurchaseOrderDetails } from "./PurchasingOrder.model";
import exportIcon from "img/export.svg";

const ViewPurchaseOrder = ({ match }) => {
  const { id } = useParams();
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const location = useLocation();

  const isOpen = location.status === "isOpen";
  const isReturn = match.path.includes(AppUrls.purchase_order_return_list);

  const [data, setData] = useState<PurchaseOrderDetails>(null);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item",
    },
    {
      field: "description",
      headerName: "Item Description",
    },
    {
      field: "quantityOrdered",
      headerName: "Order QTY",
    },
    {
      field: "quantityReceived",
      headerName: "Receive QTY",
    },
    {
      field: "taxable",
      headerName: "Taxable",
      cellRendererFramework: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "itemHubId",
      headerName: "Hub Key",
    },
  ];

  const serialsColumns = [
    {
      field: "serialNo",
      headerName: "Serial Number",
    },
    {
      field: "item",
      headerName: "Item Number",
    },
    {
      field: "itemDesc",
      headerName: "Item Description",
    },
    {
      field: "scanDate",
      headerName: "Scan Date",
    },
    {
      field: "",
      headerName: "Approved",
      cellRenderer: (params) => {
        let isAvailable = params.data.isAvailable;
        let salesOrderId = params.data.salesOrderId;
        let res = isAvailable || (salesOrderId ?? false) ? "Yes" : "No";
        return res;
      },
    },
  ];

  const expenseColumns = [
    {
      field: "expenseItemId",
      headerName: "Expense Item ID",
    },
    {
      field: "expenseName",
      headerName: "Expense Name",
    },
    {
      field: "amount",
      headerName: "Amount",
      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const {
    isLoading: isLoadingPO,
    isSuccess: isSuccessPO,
    error: errorLoadingPO,
    data: currentPO,
  } = useQuery([id], getPurchaseOrder, {
    enabled: id !== undefined ? true : false,
  });

  useEffect(() => {
    if (errorLoadingPO) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} Purchase Order\'s data.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(
        isOpen
          ? AppUrls.purchase_order_return_list
          : AppUrls.purchase_order_list
      );
    }
  }, [errorLoadingPO]);

  useEffect(() => {
    if (currentPO) setData(currentPO.data);
  }, [isSuccessPO, currentPO]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingPO });
  }, [isLoadingPO, appDispatch]);

  const onBackClick = () => {
    history.push({
      pathname: isOpen
        ? AppUrls.purchase_order_return_list
        : AppUrls.purchase_order_list,
      state: "filter",
    });
  };

  const onEditClick = () => {
    let url = `${AppUrls.purchase_order_list}/${id}`;
    history.push(url);
  };

  const [serialsItems, setSerialsItems] = useState<any>(null);

  useEffect(() => {
    let array = [];
    if (currentPO) {
      currentPO?.data?.purchaseOrderItems.forEach((element) => {
        if (element.serials.length > 0) {
          element.serials.forEach((s) => {
            array.push({
              serialNo: s.serialNo,
              scanDate: dateFormmaterNoTime(s.scanDate),
              item: element.itemNumber,
              itemDesc: element.description,
              isAvailable: s.isAvailable,
              salesOrderId: s.salesOrderId,
            });
          });
        }
      });
      setSerialsItems(array);
    }
  }, [currentPO]);

  const reportClicked = () => {
    history.push(`/purchasing/purchase-orders/${id}/report`);
  };

  const goToFulFill = () => {
    history.push({
      pathname: AppUrls.receive_purchase_order,
      state: { poId: id },
    });
  };

  const goToApprove = () => {
    history.push({
      pathname: AppUrls.approve_po_receipt,
      state: { poId: id },
    });
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          {data?.purchaseOrderNumber}
        </div>
        <div>
          <button className="import-btn" type="button" onClick={reportClicked}>
            <img src={exportIcon} className="me-2" alt="" />
            View Report
          </button>
          {data &&
            (data.poState === 1 ||
              data.poState === 2 ||
              data.poState === 8) && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={onEditClick}
              >
                Edit
              </button>
            )}
          {data && (data.poState === 1 || data.poState === 3) && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={goToFulFill}
            >
              Receive Purchase Order
            </button>
          )}
          {data && data.poState === 2 && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={goToApprove}
            >
              Approve Purchase Order
            </button>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="left-side">
              <div className="row">
                <div className="left-side">
                  <div className="row">
                    <div className="left-side-mid">
                      <div className="form-group">
                        <label>Vendor</label>
                        <p>
                          {data?.vendorNumber ?? (
                            <span className="text-muted fst-italic">
                              Vendor
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <div className="form-group">
                        <label>Hub ID</label>
                        <p>
                          {data?.hubKey ?? (
                            <span className="text-muted fst-italic">
                              Hub ID
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="row">
                    <div className="left-side-mid">
                      <div className="form-group">
                        <label>Status</label>
                        <p>
                          {statusEnum[data?.poState] ?? (
                            <span className="text-muted fst-italic">
                              Status
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <div className="form-group">
                        <label>Type</label>
                        <p>
                          {typesEnum[data?.poType] ?? (
                            <span className="text-muted fst-italic">Type</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="left-side">
                  <div className="row">
                    <div className="left-side-mid">
                      <div className="form-group">
                        <label>Request {isReturn && "Return"} Date</label>
                        <p>
                          {dateFormmaterNoTime(data?.requestDate) ?? (
                            <span className="text-muted fst-italic">
                              Request Date
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <div className="form-group">
                        <label>PO {isReturn && "Return"} Date</label>
                        <p>
                          {dateFormmaterNoTime(data?.poDate) ?? (
                            <span className="text-muted fst-italic">
                              PO Date
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="left-side">
                  <div className="row">
                    <div className="left-side-mid">
                      <div className="form-group">
                        <label>Expected Ship Date</label>
                        <p>
                          {dateFormmaterNoTime(data?.expShipDate) ?? (
                            <span className="text-muted fst-italic">
                              Expected Ship Date
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-3">
                <div className="left-side">
                  <div className="row">
                    <div className="left-side-mid">
                      <div className="form-group">
                        <label>Vendor Invoice/PO</label>
                        <p>{data?.vendorInvoicePO}</p>
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <div className="form-group">
                        <label>Term</label>
                        <p>
                          {data?.terms !== "" ? (
                            data?.terms
                          ) : (
                            <span className="text-muted fst-italic">Term</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="form-group">
                    <label>Shipping Method</label>
                    <p>
                      {data?.shippingMethodName ?? (
                        <span className="text-muted fst-italic">
                          Shipping Method
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="left-side">
                  <div className="row">
                    <div className="left-side-mid">
                      <div className="form-group">
                        <label>Discount</label>
                        <p>{formatNumber(data?.discount)}</p>
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <div className="form-group">
                        <label>Shipping Cost</label>
                        <p>{formatNumber(data?.shippingCost)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="form-group">
                    <label>Market Code</label>
                    <p>
                      {data?.marketCode !== "" ? (
                        data?.marketCode
                      ) : (
                        <span className="text-muted fst-italic">
                          Market Code
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              {data?.currency && (
                <div className="row mt-3">
                  <div className="left-side">
                    <div className="row">
                      <div className="left-side-mid">
                        <div className="form-group">
                          <label>Currency</label>
                          <p>
                            {data?.currency?.name} {data?.currency?.symbol}
                          </p>
                        </div>
                      </div>
                      <div className="right-side-mid">
                        <div className="form-group">
                          <label>Exchange Rate</label>
                          <p>{data?.rate}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <hr />
              <div className="row mt-3">
                <div className="form-group">
                  <label>Remarks</label>
                  <p>
                    {data?.remarks !== "" ? (
                      data?.remarks
                    ) : (
                      <span className="text-muted fst-italic">Remarks</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side pb-0">
              <div className="section py-2 ">
                <div className="form-group mb-2">
                  <label>Company</label>
                  <p>
                    {data?.company ?? (
                      <span className="text-muted fst-italic">Company</span>
                    )}
                  </p>
                </div>
                <div className="form-group mb-2">
                  <label>Contact</label>
                  <p>
                    {data?.contact ?? (
                      <span className="text-muted fst-italic">Contact</span>
                    )}
                  </p>
                </div>
                <div className="form-group mb-2">
                  <label>Address</label>
                  <p>
                    {data?.address ?? (
                      <span className="text-muted fst-italic">Address</span>
                    )}
                  </p>
                </div>
                <div className="form-group mb-2">
                  <label>Address 1</label>
                  <p>
                    {data?.address1 ?? (
                      <span className="text-muted fst-italic">Address 1</span>
                    )}
                  </p>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="left-side-mid ps-0">
                      <label>Country</label>
                      <p className="mb-0">
                        {data?.country ?? (
                          <span className="text-muted fst-italic">Country</span>
                        )}
                      </p>
                    </div>
                    <div className="right-side-mid">
                      <label>Zip Code</label>
                      <p className="mb-0">
                        {data?.zipCode ?? (
                          <span className="text-muted fst-italic">
                            Zip Code
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <div className="row">
                    <div className="left-side-mid ps-0">
                      <label>City</label>
                      <p>
                        {data?.city ?? (
                          <span className="text-muted fst-italic">City</span>
                        )}
                      </p>
                    </div>
                    <div className="right-side-mid">
                      <label>State</label>
                      <p>
                        {states.filter(
                          (value) => value.value === data?.state
                        )[0]?.label ?? (
                          <span className="text-muted fst-italic">State</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label>Work No.</label>
                  <p>
                    {data?.workNumber ?? (
                      <span className="text-muted fst-italic">Work No.</span>
                    )}
                  </p>
                </div>
                <div className="form-group mb-2">
                  <label>Email</label>
                  <p>
                    {data?.email ?? (
                      <span className="text-muted fst-italic">Email</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <Tabs defaultActiveKey={"Detail"}>
              <Tab
                eventKey="Detail"
                title={
                  <div className="tab-inner-title">Purchase Order Details</div>
                }
              >
                <div className="h-450 mt-3">
                  <AG data={data?.purchaseOrderItems} columns={columns} />
                </div>
              </Tab>
              <Tab
                eventKey="serials"
                title={<div className="tab-inner-title">Serials</div>}
              >
                <div className="h-450 mt-3">
                  <AG data={serialsItems} columns={serialsColumns} />
                </div>
              </Tab>
              <Tab
                eventKey="expenses"
                title={<div className="tab-inner-title">Expenses</div>}
              >
                <div className="h-450 mt-3">
                  <AG
                    data={data?.purchaseOrderExpenses}
                    columns={expenseColumns}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
