import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { getSalesReport } from "./services/ReportServices";
import "./style/common.scss";
import backArrow from "img/back-arrow.svg";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import DispatchContext from "context/DispatchContext";
import "./style/report.scss";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { useHistory, useLocation } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import { useReactToPrint } from "react-to-print";
import exportIcon from "img/export.svg";
import Filter from "./components/Filter";
import { getBaseCurrency } from "components/currency";

export default function SalesReport() {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const reportPage = useRef(null);

  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const [reportName, setReportName] = useState("Sales Report");
  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const [, setRefreshCounter] = useState(0);

  const [reportData, setReportData] = useState(null);
  const [isSummary, setIsSummary] = useState(true);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      groupingElement: { value: 1, label: "None" },
      HubId: null,
      CategoryId: null,
      startDate: null,
      endDate: null,
      ItemId: null,
      CustomerId: null,
      incomeAccount: null,
    },
  });

  const { handleSubmit, getValues } = methods;

  const generateReport = useMutation(getSalesReport, {
    async onSuccess(data) {
      let currentData = { ...data };
      setReportData(currentData);
    },
  });

  const columns = [
    [
      {
        field: "itemNumber",
        headerName: "Item No",
      },
      {
        field: "serialNo",
        headerName: "Serial No",
      },
      {
        field: "itemDescription",
        headerName: "Item Description",
      },
      {
        field: "invoiceDate",
        headerName: "Invoice Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params?.data?.invoiceDate);
        },
      },
      {
        field: "customerNumber",
        headerName: "Customer No",
      },
      {
        field: "balance",
        headerName: "Balance",
        valueGetter: (params) => {
          return formatNumber(params?.data?.balance, currencySymbol);
        },
      },
      {
        field: "quantity",
        headerName: "QTY",
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return formatNumber(params.data.totalCost, currencySymbol);
        },
      },
      {
        field: "totalPrice",
        headerName: "Total Price",
        valueGetter: (params) => {
          return formatNumber(params.data.totalPrice, currencySymbol);
        },
      },
    ],
    [
      {
        field: "elementName",
        headerName: reportData?.groupTitle ?? "",
        cellRenderer: "agGroupCellRenderer",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "quantity",
        headerName: "Total QTY",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return formatNumber(params?.data?.totalCost, currencySymbol);
        },
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "totalPrice",
        headerName: "Total Price",
        valueGetter: (params) => {
          return formatNumber(params?.data?.totalPrice, currencySymbol);
        },
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const detailCellRenderer = ({ data }) => {
    return (
      <div className={`py-4`}>
        <AgGrid
          data={data?.items}
          columns={isCustomizedReport ? gridColumns[1] : gridColumns[0]}
          pagination={false}
          autoHight={true}
          forPrinting={true}
          ref={childFnRef1}
          autoSize={true}
        />
      </div>
    );
  };

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSummaryChange = (val) => {
    setIsSummary(val?.value);
    onSubmit();
  };

  const onSubmit = () => {
    let values = getValues();
    setReportData(null);
    let params = {
      groupingElement: 1,
      startDate: null,
      endDate: null,
      customerId: null,
      hubId: null,
      itemId: null,
      categoryId: null,
      incomeAccountId: null,
    };

    if (values.startDate)
      params.startDate = fixTimezoneOffset(values.startDate);
    if (values.endDate) params.endDate = fixTimezoneOffset(values.endDate);
    if (values.groupingElement)
      params.groupingElement = values.groupingElement?.value;
    if (values.HubId) params.hubId = values.HubId?.value;
    if (values.CategoryId) params.categoryId = values.CategoryId?.value;
    if (values.ItemId) params.itemId = values.ItemId?.value;
    if (values.CustomerId) params.customerId = values.CustomerId?.value;
    if (values.incomeAccount)
      params.incomeAccountId = values.incomeAccount?.value;

    generateReport.mutate(params);
  };

  const options = [
    {
      value: 1,
      label: "None",
    },
    {
      value: 2,
      label: "Hubs",
    },
    {
      value: 3,
      label: "Items",
    },
    {
      value: 4,
      label: "Category",
    },
    {
      value: 5,
      label: "Customer",
    },
    {
      value: 6,
      label: "Income Account",
    },
    {
      value: 7,
      label: "Day",
    },
    {
      value: 8,
      label: "Week",
    },
    {
      value: 9,
      label: "Month",
    },
    {
      value: 10,
      label: "Quarter",
    },
    {
      value: 11,
      label: "Year",
    },
  ];

  const isSummaryOpt = [
    {
      value: true,
      label: "Summary",
    },
    {
      value: false,
      label: "Detailed",
    },
  ];

  useEffect(() => {
    if (!isCustomizedReport) generateReport.mutate({ groupingElement: 1 });
  }, [isCustomizedReport]);

  const getColumns =
    getValues("groupingElement")?.value === 1
      ? gridColumns[0]
      : isCustomizedReport
        ? gridColumns[0]
        : gridColumns[1];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className={`page-content ${isSummary ? "hide-arrow" : ""}`}>
              <Filter
                filters={[0, 1, 2, 4, 8, 13, 31]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                setRefreshCounter={setRefreshCounter}
                groupingElements={options}
                isSummary={isSummary}
                setIsSummary={setIsSummary}
              />
              <>
                <div className="container report-filters-div mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="col-2">
                      {getValues("groupingElement")?.value !== 1 && (
                        <Select
                          options={isSummaryOpt}
                          isSearchable={true}
                          value={isSummaryOpt?.find(
                            (opt) => opt?.value === isSummary
                          )}
                          onChange={(val) => onSummaryChange(val)}
                        />
                      )}
                    </div>
                    <div>
                      <button
                        className="import-btn"
                        type="button"
                        onClick={exportToExcel}
                        disabled={
                          disabledExportBtn || reportData?.hubs?.length === 0
                        }
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export grid to Excel
                      </button>
                      <button
                        type="button"
                        className="btn btn-success ms-5"
                        onClick={printDocument}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-printer me-3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                          <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>
                        Print report to PDF
                      </button>
                    </div>
                  </div>
                </div>
                <div className="report-page" ref={reportPage}>
                  <div className="report-header d-flex justify-content-between">
                    <div>
                      <h3>
                        <b>
                          {reportName}{" "}
                          {reportData?.groupTitle === "Nothing"
                            ? ""
                            : `grouped by ${reportData?.groupTitle}`}
                        </b>
                      </h3>{" "}
                      <br />
                      <p>
                        <b>Report Date: </b>
                        {dateFormmaterNoTime(reportData?.reportDate)}
                      </p>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={reportData?.imageLogo ?? BlueSeedLogo}
                        className="report-logo"
                      ></img>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    {gridColumns.length > 0 && (
                      <AgGrid
                        data={
                          getValues("groupingElement")?.value === 1
                            ? reportData?.groups[0]?.items
                            : reportData?.groups
                        }
                        columns={getColumns}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        subTable={gridColumns[0]}
                        subTableObj={"items"}
                        fileName="Sales Report"
                        ref={childFnRef}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
