import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import {
  VendorModelDTO,
  VendorTypeModel,
  VendorPurchaseOrder,
  VendorInvoiceBill,
} from "./Vendors.models";

export const getVendorTypes = async (params): Promise<VendorTypeModel[]> => {
  let response;
  if (params.queryKey.length > 1) {
    response = await api.get<VendorTypeModel[]>(
      `vendors/types?PageSize=${params.PageSize}&PageStart=${
        isNaN(params.PageStart) ? 0 : params.PageStart
      }${
        params && params.queryKey[1].params && params.queryKey[1].params !== ""
          ? `&${params.queryKey[1].params}`
          : ""
      }`
    );
  } else {
    response = await api.get<VendorTypeModel[]>(`vendors/types`);
  }
  return response.data;
};

export const createVendorType = async (
  data: VendorTypeModel
): Promise<VendorTypeModel> => {
  const response = await api.post<VendorTypeModel>("vendors/types", data);
  return response.data;
};

export const updateVendorType = async (
  data: VendorTypeModel
): Promise<VendorTypeModel> => {
  const response = await api.put<VendorTypeModel>(
    `vendors/types/${data.vendorTypeId}`,
    data
  );
  return response.data;
};

export const getVendors = async (
  params
): Promise<QueryResult<VendorModelDTO>> => {
  let extraParams = "";
  if (params.activeOnly !== null && params.activeOnly !== undefined)
    extraParams += `&activeOnly=${params.activeOnly}`;
  if (params?.searchQuery) extraParams += `&searchQuery=${params.searchQuery}`;
  if (params.vendorTypeId) {
    if (params.pageParam) {
      const response = await api.get<QueryResult<VendorModelDTO>>(
        `vendors?vendorTypeId=${params.vendorTypeId}&PageSize=${
          params.PageSize
        }&PageStart=${
          isNaN(params.PageStart) ? 0 : params.PageStart
        }${extraParams}`
      );
      return response.data;
    } else {
      const response = await api.get<QueryResult<VendorModelDTO>>(
        `vendors?vendorTypeId=${params.vendorTypeId}${extraParams}`
      );
      return response.data;
    }
  } else {
    if (params.pageParam) {
      const response = await api.get<QueryResult<VendorModelDTO>>(
        `vendors?PageSize=${params.PageSize}&PageStart=${
          isNaN(params.PageStart) ? 0 : params.PageStart
        }${extraParams}`
      );
      return response.data;
    } else {
      const response = await api.get<QueryResult<VendorModelDTO>>(
        `vendors?${extraParams}`
      );
      return response.data;
    }
  }
};

export const getVendorById = async (
  vendorID: number
): Promise<VendorModelDTO> => {
  const response = await api.get<VendorModelDTO>(`vendors/${vendorID}`);
  return response.data;
};

export const createVendor = async (
  vendorData: VendorModelDTO
): Promise<VendorModelDTO> => {
  const response = await api.post<VendorModelDTO>(`vendors`, vendorData);
  return response.data;
};

export const updateVendor = async (
  vendorData: VendorModelDTO
): Promise<VendorModelDTO> => {
  const response = await api.put<VendorModelDTO>(
    `vendors/${vendorData.vendorId}`,
    vendorData
  );
  return response.data;
};

export const getVendorPurchaseOrders = async (
  data
): Promise<QueryResult<VendorPurchaseOrder>> => {
  const response = await api.get<QueryResult<VendorPurchaseOrder>>(
    `/purchase-orders?vendorId=${data.vendorId}&poStatus=${data.poStatus}&poType=${data.poType}`
  );
  return response.data;
};

export const getVendorInvoiceBills = async (
  data
): Promise<QueryResult<VendorInvoiceBill>> => {
  const response = await api.get<QueryResult<VendorInvoiceBill>>(
    `/invoices/receive?vendorId=${data.vendorId}&receiveInvoiceFilters=${data.billsTypeId}`
  );
  return response.data;
};

export const getReceiveInvoice = async () => {
  const response = await api.get(`invoices/receive-payment`);
  return response.data;
};

export const getReceiveInvoiceById = async (id) => {
  const response = await api.get(`invoices/receive-payment/${id}`);
  return response.data;
};

export const getVendorDebit = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`vendors/debit-credit/${queryId}`);
  return response.data;
};

export const getVendorCm = async (params) => {
  const response = await api.get(
    `vendors/credit-memo${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};

export const getVendorCmDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`vendors/credit-memo/${queryId}`);
  return response.data;
};
