import { useState, useEffect, useContext } from "react";
import AG from "elements/AG";
import Delete from "img/delete.svg";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  getSalesOrder,
  scanSerializedItem,
  scanNonSerializedItem,
  fulfillSalesOrder,
  shipSalesOrder,
  searchSalesOrders,
} from "./SalesOrderServices/SalesOrderServices";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import DispatchContext from "context/DispatchContext";
import { ReactComponent as AlertIcon } from "img/alert.svg";
import {
  dateFormmaterNoTime,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import { SalesOrderItem } from "pages/SalesOrder/SalesOrder.model";
import SalesOrdersModal from "./SalesOrderModal";
import CSVReaderInput from "components/FileImporter/CSVReaderInput";
import Lookup from "elements/Lookup";
import { getBaseCurrency } from "components/currency";

const FulfillSalesOrder = () => {
  const location = useLocation();
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [openShipment, setOpenShipment] = useState<boolean>(false);
  const [modalRowClicked, setModalRowClicked] = useState<any>();
  const [orderDetails, setOrderDetails] = useState(null);
  const [fulfilledItems, setFulfilledItems] = useState<Array<SalesOrderItem>>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedRow, setSelectedRow] = useState([]);
  const [deSelectItems, setDeselectItems] = useState<number>(0);
  const [importData, setImportData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>();
  const [importError, setImportError] = useState<string>(null);
  const [customIsDirty, setCustomIsDirty] = useState<boolean>(null);
  const [isButtonRdyShipOff, setisButtonRdyShipOff] = useState(false);

  const appDispatch = useContext(DispatchContext);

  const typeEnum = {
    1: "Serialized",
    2: "Non Serialized",
    3: "Non Tangible",
  };

  const itemsColumns = [
    {
      headerName: "Select",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemType",
      headerName: "Item Type",
      cellRenderer: (params) => (params.value ? typeEnum[params.value] : ""),
    },
    {
      field: "quantity",
      headerName: "Qty",
    },
    {
      field: "scanQty",
      headerName: "Scan Qty",
      cellRenderer: (params) => getScannedByItem(params.data),
    },
    {
      field: "price",
      headerName: "Price",
      valueGetter: (params) => {
        return `${currencySymbol} ${params.data?.price?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const serialColumns = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "fulfillQty",
      headerName: "Qty",
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
    },
    {
      field: "action",
      headerName: "Action",
      onCellClicked: (params) => {
        setCustomIsDirty(true);
        setFulfilledItems((prev) =>
          prev.filter((item) => item.index !== params.data.index)
        );
      },
      cellRenderer: () => {
        return `
                  <div class="btn text-danger">
                    <img src=${Delete} alt="Delete Icon" />
                    Delete 
                  </div>
            `;
      },
    },
  ];

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, reset, register, setValue, getValues, setFocus } =
    methods;

  const getOrderDetails = useMutation(getSalesOrder, {
    async onSuccess(response) {
      setOrderDetails(response.data);
    },
  });

  const confirmShipment = useMutation(shipSalesOrder);

  const getSalesOrders = useMutation(searchSalesOrders);

  const scanSerialized = useMutation(scanSerializedItem, {
    async onSuccess(response) {
      if (response.data && selectedRow.length > 0) {
        let newItem = {
          ...selectedRow[0],
          fulfillQty: 1,
          serialNumber: getValues("itemSerial"),
          index:
            fulfilledItems.length > 0
              ? Math.max(...fulfilledItems.map((o) => o.index)) + 1
              : 0,
        };
        setFulfilledItems((prevState) => [...prevState, { ...newItem }]);
        setFocus("itemSerial");
        setValue("itemSerial", "");
        setCustomIsDirty(true);
      } else {
        setErrorMessage(
          "Item number/serial number was not found. Please make sure it's valid and try again."
        );
      }
    },
  });

  const scanBulkSerialized = useMutation(scanSerializedItem, {
    async onSuccess(response) {
      if (response.data && selectedRow.length > 0) {
        setValue("itemSerial", "");
        setValue("quantity", null);
        let scannedItems = [...fulfilledItems];
        let index = Math.max(...fulfilledItems.map((o) => o.index)) + 1;
        for (let imported of importData) {
          scannedItems.push({
            ...selectedRow[0],
            fulfillQty: 1,
            serialNumber: imported.serial,
            index: index,
          });
          index++;
        }
        setSelectedRow(null);
        setDeselectItems((prev) => prev + 1);
        setFulfilledItems(scannedItems);
        setShowModal(false);
        setCustomIsDirty(true);
      } else {
        setImportError(
          "Item number/serial number was not found. Please make sure it's valid and try again."
        );
      }
    },
  });

  const scanNonSerialized = useMutation(scanNonSerializedItem, {
    async onSuccess(response) {
      if (response.data) {
        let itemFound = fulfilledItems.find(
          (i) => i.itemOrderId === selectedRow[0].itemOrderId
        );
        if (itemFound) {
          let fullfilledItem = fulfilledItems.map((element) => {
            if (element.itemOrderId === itemFound.itemOrderId)
              return {
                ...element,
                fulfillQty: (
                  Number(getValues("quantity")) + Number(itemFound.fulfillQty)
                ).toString(),
              };
            else return element;
          });
          setFulfilledItems(fullfilledItem);
          setCustomIsDirty(true);
        } else {
          let newItem = {
            ...selectedRow[0],
            fulfillQty: Number(getValues("quantity")),
            index: fulfilledItems.length + 1,
          };
          if (
            newItem.quantity - newItem.fulfilledQty <
            Number(getValues("quantity"))
          )
            setErrorMessage(
              `Quantity requested must be less than  ${
                newItem.quantity - newItem.fulfilledQty + 1
              }
  `
            );
          else if (
            Number(newItem.fulfilledQty) + Number(getValues("quantity")) >
            Number(newItem.quantity)
          ) {
            setErrorMessage("Quantity requested is not available");
          } else {
            setFulfilledItems((prevState) => [...prevState, { ...newItem }]);
            setCustomIsDirty(true);
            setErrorMessage(null);
            setSelectedRow(null);
            setDeselectItems((prev) => prev + 1);
          }
        }
      } else {
        let newItem = {
          ...selectedRow[0],
        };
        if (
          newItem.quantity - newItem.fulfilledQty <
          Number(getValues("quantity"))
        )
          setErrorMessage(
            `Quantity requested must be less than  ${
              newItem.quantity - newItem.fulfilledQty + 1
            }
`
          );
        else setErrorMessage("Quantity requested is not available");
      }
    },
    onError() {
      let newItem = {
        ...selectedRow[0],
        fulfillQty: Number(getValues("quantity")),
        index:
          fulfilledItems.length > 0
            ? Math.max(...fulfilledItems.map((o) => o.index)) + 1
            : 0,
      };

      if (Number(newItem.quantity) === 1) {
        setErrorMessage("Please input item Quantity.");
      } else {
        setErrorMessage(
          "Item number/serial number was not found. Please make sure it's right and try again."
        );
      }
    },
  });
  useEffect(() => {
    if (!fulfilledItems || fulfilledItems.length === 0) setCustomIsDirty(false);
  }, [fulfilledItems]);

  const submitFulfill = useMutation(fulfillSalesOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `SO. ${getValues("orderNumber")} fulfilled successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      if (orderDetails["totalItems"] > getScanned()) setOpenShipment(true);
      else onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem fulfilling sales order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: submitFulfill.isLoading });
  }, [submitFulfill.isLoading]);

  useEffect(() => {
    if (openShipment === false) onCancel();
  }, [openShipment]);

  useEffect(() => {
    appDispatch({ type: "loading", value: getOrderDetails.isLoading });
  }, [getOrderDetails.isLoading]);

  useEffect(() => {
    if (modalRowClicked) {
      if (modalRowClicked.status === 1 || modalRowClicked.status === 3) {
        if (modalRowClicked.onHold) {
          let notification = {
            variant: "danger",
            msg: "This sales order is on hold and cannot be fulfilled",
          };
          appDispatch({ type: "notification", value: notification });
        } else {
          setOpenSelection(false);
          setValue("orderNumber", modalRowClicked["orderNumber"]);
          setValue("orderId", modalRowClicked["salesOrderId"]);
          getOrderDetails.mutate(modalRowClicked["salesOrderId"]);
          setFulfilledItems([]);
          setCustomIsDirty(false);
          appDispatch({ type: "notification", value: null });
          setDeselectItems((prev) => prev + 1);
        }
      } else {
        let notification = {
          variant: "danger",
          msg: "Only Open or Partially Fulfilled orders can be selected",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [modalRowClicked]);

  useEffect(() => {
    getSalesOrders.mutate("?states=0");
  }, []);

  useEffect(() => {
    if (selectedRow && selectedRow.length > 0) {
      if (selectedRow[0].itemType === 2) {
        setValue("itemSerial", selectedRow[0]["itemNumber"]);
        setValue("quantity", null);
        // setFocus("itemSerial")
      } else {
        setValue("itemSerial", "");
        setValue("quantity", null);
        // setFocus("itemSerial")
      }
    } else {
      setValue("itemSerial", "");
      setValue("quantity", null);
    }
  }, [selectedRow]);

  const getScannedByItem = (item) => {
    return item.fulfilledQty;
  };

  const addItem = () => {
    let quantity = getValues("quantity");
    if (selectedRow && selectedRow.length > 0) {
      if (selectedRow[0].itemType === 1) {
        if (
          fulfilledItems.find((i) => i.serialNumber === getValues("itemSerial"))
        )
          setErrorMessage(`Serial Number already exists.`);
        // else if (getValues("itemSerial").length != 15)
        //     setErrorMessage(`Serial Number must be 15 characters.`)
        else if (
          fulfilledItems.filter(
            (item) => item.itemOrderId === selectedRow[0].itemOrderId
          ).length === selectedRow[0].quantity
        )
          setErrorMessage(`All the available items have been scanned.`);
        else {
          if (!scanSerialized.isLoading) {
            scanSerialized.mutate({
              itemOrderId: selectedRow[0].itemOrderId,
              serials: [getValues("itemSerial")],
            });
            setErrorMessage(null);
          }
        }
      } else {
        let itemfound = fulfilledItems.find(
          (i) => i.itemOrderId === selectedRow[0].itemOrderId
        );
        if (itemfound) {
          if (
            Number(quantity) >
            Number(itemfound.quantity) -
              (Number(itemfound.fulfilledQty) + Number(itemfound.fulfillQty))
          )
            setErrorMessage(
              `Quantity cannot be bigger than the quantity Ordered`
            );
          else if (
            Number(quantity) + Number(itemfound.fulfillQty) >
            Number(itemfound.quantity)
          )
            setErrorMessage(
              `Quantity cannot be bigger than the quantity Ordered`
            );
          else {
            setErrorMessage("");
            scanNonSerialized.mutate({
              soId: orderDetails["salesOrderId"],
              itemHubId: selectedRow[0].itemHubId,
              quantity: Number(quantity) + Number(itemfound.fulfillQty),
            });
          }
        } else {
          setErrorMessage("");

          scanNonSerialized.mutate({
            soId: orderDetails["salesOrderId"],
            itemHubId: selectedRow[0].itemHubId,
            quantity: quantity,
          });
        }
      }
    } else {
      setErrorMessage("Please select an item from the grid above");
    }
  };
  const getFulfilledQty = () => {
    let total = 0;
    if (orderDetails) {
      for (let i of orderDetails["itemOrders"]) {
        const fulfilledItem = fulfilledItems.find(
          (item) => item.itemOrderId === i.itemOrderId
        );
        if (fulfilledItem)
          total += i.fulfilledQty + parseInt(fulfilledItem.fulfillQty);
        else total += i.fulfilledQty;
      }
      return total;
    }
  };

  const onCancel = () => {
    reset();
    setModalRowClicked(null);
    setSelectedRow(null);
    setFulfilledItems([]);
    setOrderDetails(null);
    setErrorMessage("");
    setCustomIsDirty(false);
  };

  const onSubmit = () => {
    if (orderDetails["totalItems"] > getScanned()) setOpenConfirmation(true);
    else fullFillOrder();
    setCustomIsDirty(true);
  };
  const getScanned = () => {
    let total = 0;
    for (let item of fulfilledItems) {
      total += parseInt(item.fulfillQty) + parseInt(item.fulfilledQty);
    }
    return total;
  };

  const fullFillOrder = () => {
    setOpenConfirmation(false);
    let IdsList = [];
    let fulfilled = fulfilledItems.map((item) => {
      if (item.itemType === 1 && !IdsList.includes(item.itemOrderId)) {
        IdsList.push(item.itemOrderId);
        let serials = [...fulfilledItems]
          .filter((element) => element.itemHubId === item.itemHubId)
          .map((value) => value.serialNumber);
        return {
          itemOrderId: item.itemOrderId,
          quantity: serials.length,
          serials: serials,
        };
      } else if (item.itemType === 2) {
        return {
          itemOrderId: item.itemOrderId,
          quantity: fulfilledItems.find(
            (element) => element.itemHubId === item.itemHubId
          )
            ? fulfilledItems.find(
                (element) => element.itemHubId === item.itemHubId
              ).fulfillQty
            : 0,
        };
      } else {
        return {
          itemOrderId: item.itemOrderId,
          quantity: fulfilledItems.find(
            (element) => element.itemHubId === item.itemHubId
          )
            ? fulfilledItems.find(
                (element) => element.itemHubId === item.itemHubId
              ).fulfillQty
            : 0,
        };
      }
    });
    submitFulfill.mutate({
      soId: orderDetails["salesOrderId"],
      items: fulfilled.filter((i) => i != undefined),
    });
  };

  const shipOrder = () => {
    setisButtonRdyShipOff(true);
    setOpenShipment(false);
    confirmShipment.mutate(orderDetails["salesOrderId"]);
    let notification = {
      variant: "success",
      msg: `Shipped successfully`,
    };

    appDispatch({ type: "notification", value: notification });
  };

  const applyImportedItems = () => {
    const intersection = fulfilledItems.filter((item1) =>
      importData.some((item2) => item1.serialNumber == item2.serial)
    );
    if (intersection.length > 0)
      setImportError(`Duplicate Serial found please check imported serials`);
    else {
      scanBulkSerialized.mutate({
        itemOrderId: selectedRow[0].itemOrderId,
        serials: importData.map((serial) => Number(serial.serial)),
      });
    }
  };

  const validateImportedItems = (items) => {
    let fetchedItems = items.data.filter((item) => item[0] != "");

    if (
      fetchedItems[0][0].toLowerCase() === "serial number" ||
      fetchedItems[0][0].toLowerCase() === "serial"
    ) {
      if (
        selectedRow[0].quantity -
          fulfilledItems.filter(
            (item) => item.itemOrderId === selectedRow[0].itemOrderId
          ).length -
          (fetchedItems.length - 1) <
        0
      )
        setImportError(
          "The Imported quantity is bigger than the avilable quantity."
        );
      else {
        let dataToSend = [];

        let valid = true;

        for (let i = 1; i < fetchedItems.length; i++) {
          let element = fetchedItems[i];
          if (element[0].length < 15 || isNaN(element[0])) {
            setImportError("Invalid serial numbers");
            valid = false;
            break;
          } else {
            valid = true;
            dataToSend.push({ serial: element[0] });
          }
        }

        if (valid) {
          let finalDataToSend = dataToSend.map((e) => Object.values(e)[0]);
          if (new Set(finalDataToSend).size !== dataToSend.length)
            setImportError("Duplicate serial numbers found");
          else {
            setImportError(null);
            setImportData(dataToSend);
          }
        }
      }
    } else {
      setImportError("Column names mismatch");
    }
  };
  useEffect(() => {
    if (location.state) {
      setModalRowClicked(
        getSalesOrders?.data?.data?.data?.find(
          (item) => item.salesOrderId == location.state.soId
        )
      );
    }
  }, [location, getSalesOrders?.data?.data?.data]);
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(addItem)}>
          <div className="page-title page-title-editable">
            <div>Fulfill Sales Order</div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                onClick={onCancel}
                disabled={!customIsDirty}
              >
                Reset
              </button>

              {orderDetails && (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={shipOrder}
                  disabled={
                    orderDetails.readyToShip ||
                    isButtonRdyShipOff ||
                    parseInt(orderDetails.fulfillTotal) <= 0
                  }
                >
                  Ready for shipping
                </button>
              )}
              <button
                type="button"
                onClick={onSubmit}
                className="btn btn-primary"
                disabled={!customIsDirty}
              >
                FulFill
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row gx-5">
                <div className="col-lg-3 col-sm-12">
                  <label>
                    Order No. <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelection(true)}
                    inputName="orderId"
                    isRequired={true}
                    initialData={getSalesOrders?.data?.data?.data?.map(
                      (item) => ({
                        ...item,
                        id: item.salesOrderId,
                        name: item.orderNumber,
                      })
                    )}
                    onSelection={setModalRowClicked}
                    inputValue={getValues("orderNumber")}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Order Date</label>
                  <div>
                    {orderDetails ? (
                      dateFormmaterNoTime(orderDetails["orderDate"])
                    ) : (
                      <span className="fst-italic text-muted">Order Date</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row gx-5 mt-3 ">
                <div className="col-lg-2 col-sm-12 py-4">
                  <div className="row section py-4">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Account</label>
                        <p>
                          {orderDetails ? (
                            orderDetails["accountNumber"]
                          ) : (
                            <span className="text-muted fst-italic">
                              Account
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="form-group">
                        <label>Hub ID</label>
                        <p>
                          {orderDetails ? (
                            orderDetails["hubKey"]
                          ) : (
                            <span className="text-muted fst-italic">
                              Hub ID
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 py-4">
                  <div className="row section py-4">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Comapny</label>
                        <p>
                          {orderDetails &&
                          orderDetails["billingInfo"]["company"] ? (
                            orderDetails["billingInfo"]["company"]
                          ) : (
                            <span className="text-muted fst-italic">
                              Company
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Contact Name</label>
                        <p>
                          {orderDetails &&
                          orderDetails["billingInfo"]?.contact ? (
                            orderDetails["billingInfo"]?.contact
                          ) : (
                            <span className="text-muted fst-italic">
                              Contact
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Address</label>
                        <p>
                          {orderDetails &&
                          orderDetails["billingInfo"]?.address ? (
                            orderDetails["billingInfo"]?.address
                          ) : (
                            <span className="text-muted fst-italic">
                              Address
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label>City</label>
                            <p>
                              {orderDetails &&
                              orderDetails["billingInfo"]?.city ? (
                                orderDetails["billingInfo"]?.city
                              ) : (
                                <span className="text-muted fst-italic">
                                  City
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label>State</label>
                            <p>
                              {orderDetails &&
                              orderDetails["billingInfo"]?.state ? (
                                orderDetails["billingInfo"]?.state
                              ) : (
                                <span className="text-muted fst-italic">
                                  State
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <p>
                              {orderDetails &&
                              orderDetails["billingInfo"]?.zipCode ? (
                                orderDetails["billingInfo"]?.zipCode
                              ) : (
                                <span className="text-muted fst-italic">
                                  Zip code
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 py-4">
                  <div className="row  section py-4">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Ship To</label>
                        <p>
                          {orderDetails &&
                          orderDetails.shippingInfo?.company ? (
                            orderDetails["shippingInfo"]["company"]
                          ) : (
                            <span className="text-muted fst-italic">
                              Company
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Contact Name</label>
                        <p>
                          {orderDetails &&
                          orderDetails.shippingInfo?.contact ? (
                            orderDetails["shippingInfo"]["contact"]
                          ) : (
                            <span className="text-muted fst-italic">
                              Contact
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Address</label>
                        <p>
                          {orderDetails &&
                          orderDetails.shippingInfo?.address ? (
                            orderDetails["shippingInfo"]["address"]
                          ) : (
                            <span className="text-muted fst-italic">
                              Address
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label>City</label>
                            <p>
                              {orderDetails &&
                              orderDetails.shippingInfo?.city ? (
                                orderDetails["shippingInfo"]["city"]
                              ) : (
                                <span className="text-muted fst-italic">
                                  City
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label>State</label>
                            <p>
                              {orderDetails &&
                              orderDetails.shippingInfo?.state ? (
                                orderDetails["shippingInfo"]["city"]
                              ) : (
                                <span className="text-muted fst-italic">
                                  State
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <p>
                              {orderDetails &&
                              orderDetails.shippingInfo?.zipCode ? (
                                orderDetails["shippingInfo"]["zipCode"]
                              ) : (
                                <span className="text-muted fst-italic">
                                  Zip code
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mt-4">
                  <div className="section pt-2 pb-3">
                    <label>Order Notes</label>
                    <div>
                      {orderDetails ? (
                        orderDetails["remarks"] ? (
                          orderDetails["remarks"]
                        ) : (
                          <span className="text-muted fst-italic">Remarks</span>
                        )
                      ) : (
                        <span className="text-muted fst-italic">Remarks</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 h-250">
                <AG
                  data={orderDetails ? orderDetails["itemOrders"] : []}
                  columns={itemsColumns}
                  setRowChecked={setSelectedRow}
                  deSelectItems={deSelectItems}
                />
              </div>
              <div className="row gx-5 my-4">
                <div className="col">
                  <div className="border p-3">
                    <div>Total Items</div>
                    <div className="text-success">
                      {orderDetails ? orderDetails["itemsQuantity"] : 0}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="border p-3">
                    <div>Total Item Qty</div>
                    <div className="text-success">
                      {orderDetails ? orderDetails["totalItems"] : 0}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="border p-3">
                    <div>Open Qty </div>
                    <div className="text-success">
                      {orderDetails ? (
                        <>{orderDetails["totalItems"] - getFulfilledQty()}</>
                      ) : (
                        0
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="border p-3 section">
                    <div>Scanned</div>
                    <div className="text-success">{getFulfilledQty()}</div>
                  </div>
                </div>
              </div>
              <div className="section pt-4">
                {errorMessage && (
                  <div className="d-flex align-items-center">
                    <AlertIcon className="me-3" />
                    <div className="text-danger">{errorMessage}</div>
                  </div>
                )}
              </div>
              <div className="row section py-3 ps-2">
                {selectedRow && selectedRow.length !== 0 && (
                  <>
                    <div className="col-7 d-flex align-items-center">
                      {selectedRow[0].itemType === 1 ? (
                        <>
                          <label>
                            Serial &nbsp;&nbsp;{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control ms-4 w-50"
                            {...register("itemSerial")}
                            autoComplete="off"
                          />
                        </>
                      ) : (
                        <>
                          <label>
                            Quantity <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control ms-4 w-25"
                            type="number"
                            {...register("quantity")}
                            min={1}
                          />
                        </>
                      )}
                    </div>
                    <div className="col-5 d-flex align-items-center justify-content-end">
                      {selectedRow[0].itemType === 1 && (
                        <CSVReaderInput
                          onDataFetched={validateImportedItems}
                          IsDisabled={false}
                          title="Import items list from a CSV file"
                          data={importData}
                          error={importError}
                          showModal={showModal}
                          onClose={() => setShowModal(false)}
                          onOpen={() => setShowModal(true)}
                          onSelection={applyImportedItems}
                        />
                      )}
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="h-250">
                <AG data={fulfilledItems} columns={serialColumns} />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SalesOrdersModal
        showModal={openSelection}
        setShowModal={setOpenSelection}
        setRowClicked={setModalRowClicked}
        showOnlyOpenAndPF={true}
      />
      <ConfirmationModal
        title="Sales Order Fulfillment"
        message="There are items that are not scanned/added. Are you sure you want to have a partial fulfillment? Sales order will remain open for partial fulfilled items."
        showModal={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => {
          fullFillOrder();
        }}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
      <ConfirmationModal
        title="Sales Order Shipment"
        message="Do you want to mark this sales order as ready to ship?"
        showModal={openShipment}
        onClose={() => setOpenShipment(false)}
        onConfirm={() => {
          shipOrder();
        }}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
    </>
  );
};

export default FulfillSalesOrder;
