import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { InventoryAgingReportAPI } from "./services/ReportServices";
import backArrow from "img/back-arrow.svg";

import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { useHistory, useLocation } from "react-router-dom";

import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { useReactToPrint } from "react-to-print";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";

import Filter from "./components/Filter";
import { getBaseCurrency } from "components/currency";

export default function InventoryAgingReport() {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc2", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const reportPage = useRef(null);

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const [reportName, setReportName] = useState("Inventory Aging Report");
  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const [, setRefreshCounter] = useState(0);
  const [reportData, setReportData] = useState(null);
  const [isSummary, setIsSummary] = useState(true);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const generateReport = useMutation(
    "InventoryAgingReportAPI",
    InventoryAgingReportAPI,
    {
      async onSuccess(data) {
        let currentData = { ...data };
        setReportData(currentData);
      },
    }
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      groupingElement: { value: 1, label: "None" },
      HubId: null,
      ClassId: null,
      SubClassId: null,
      ProductLineId: null,
      CategoryId: null,
      ManufacturerId: null,
      IsSerialized: null,
      itemSubClass: null,
    },
  });

  const { handleSubmit, getValues } = methods;

  const onSubmit = () => {
    let values = getValues();
    let params = {
      groupingElement: 1,
      hubId: null,
      itemClassId: null,
      subClassId: null,
      productLineId: null,
      categoryId: null,
      manufacturerId: null,
      isSerialized: null,
    };

    if (values.groupingElement)
      params.groupingElement = values.groupingElement?.value;
    if (values.HubId) params.hubId = values.HubId?.value;
    if (values.ClassId) params.itemClassId = values.ClassId?.value;
    if (values.SubClassId) params.subClassId = values.SubClassId?.value;
    if (values.ProductLineId)
      params.productLineId = values.ProductLineId?.value;
    if (values.CategoryId) params.categoryId = values.CategoryId?.value;
    if (values.ManufacturerId)
      params.manufacturerId = values.ManufacturerId?.value;
    if (values.IsSerialized) params.isSerialized = values.IsSerialized?.value;

    generateReport.mutate(params);
  };

  const options = [
    {
      value: 1,
      label: "None",
    },
    {
      value: 2,
      label: "Hubs",
    },
    {
      value: 3,
      label: "Items",
    },
    {
      value: 4,
      label: "Category",
    },
  ];

  const columns = [
    [
      {
        field: "elementName",
        headerName: reportData?.groupTitle ?? "",
        cellRenderer: "agGroupCellRenderer",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },

      {
        field: "cost_From0To30",
        headerName: "From 0 to 30",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From0To30, currencySymbol) + "<br />"
          );
        },
      },
      {
        field: "cost_From31To60",
        headerName: "From 31 to 60",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From31To60, currencySymbol) +
            "<br />" +
            params.data.qty_From31To60
          );
        },
      },
      {
        field: "cost_From61To90",
        headerName: "From 61 to 90",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From61To90, currencySymbol) +
            "<br />" +
            params.data.qty_From61To90
          );
        },
      },
      {
        field: "cost_From91To120",
        headerName: "From 91 to 120",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From91To120, currencySymbol) +
            "<br />" +
            params.data.qty_From91To120
          );
        },
      },
      {
        field: "cost_Over120",
        headerName: "> 120",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_Over120, currencySymbol) +
            "<br />" +
            params.data.qty_Over120
          );
        },
      },
    ],
    [
      {
        field: "itemNumber",
        headerName: "Item No",
      },
      {
        field: "hubKey",
        headerName: "Hub Name",
      },
      {
        field: "age",
        headerName: "Age/QTY",
        cellRenderer: (params) => {
          return (
            "Age: " + params.data.age + "<br /> QTY: " + params.data.quantity
          );
        },
      },
      {
        field: "cost_From0To30",
        headerName: "From 0 to 30",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From0To30, currencySymbol) +
            "<br />" +
            params.data.qty_From0To30
          );
        },
      },
      {
        field: "cost_From31To60",
        headerName: "From 31 to 60",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From31To60, currencySymbol) +
            "<br />" +
            params.data.qty_From31To60
          );
        },
      },
      {
        field: "cost_From61To90",
        headerName: "From 61 to 90",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From61To90, currencySymbol) +
            "<br />" +
            params.data.qty_From61To90
          );
        },
      },
      {
        field: "cost_From91To120",
        headerName: "From 91 to 120",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_From91To120, currencySymbol) +
            "<br />" +
            params.data.qty_From91To120
          );
        },
      },
      {
        field: "cost_Over120",
        headerName: "> 120",
        cellRenderer: (params) => {
          return (
            formatNumber(params.data.cost_Over120, currencySymbol) +
            "<br />" +
            params.data.qty_Over120
          );
        },
      },
    ],
  ];

  const detailCellRenderer = ({ data }) => {
    return (
      <>
        <div className="py-4">
          <AgGrid
            data={data?.items}
            columns={gridColumns[1]}
            pagination={false}
            autoHight={true}
            autoSize={true}
            forPrinting={true}
            ref={childFnRef1}
          />
        </div>
      </>
    );
  };

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  // const exportToExcel = () => {
  //     setIsLoadingGridExport(true)
  //     setDisabledExportBtn(true)
  //     setExportToExcelClicked(true)
  //     let notification = {
  //         variant: "success",
  //         msg: "Preparing grid for export...",
  //     };
  //     appDispatch({ type: "notification", value: notification })
  // }

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  useEffect(() => {
    if (!isCustomizedReport) generateReport.mutate({ groupingElement: 1 });
  }, [isCustomizedReport]);

  const isSummaryOpt = [
    {
      value: true,
      label: "Summary",
    },
    {
      value: false,
      label: "Detailed",
    },
  ];

  const onSummaryChange = (val) => {
    setIsSummary(val?.value);
  };

  const getColumns =
    getValues("groupingElement")?.value === 1
      ? isCustomizedReport
        ? gridColumns[0]
        : gridColumns[1]
      : gridColumns[0];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className={`page-content ${isSummary ? "hide-arrow" : ""}`}>
              <Filter
                filters={[0, 2, 9, 10, 11, 12, 13, 15]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                setRefreshCounter={setRefreshCounter}
                groupingElements={options}
                isSummary={isSummary}
                setIsSummary={setIsSummary}
              />
              <>
                <div className="container report-filters-div mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="col-2">
                      {getValues("groupingElement")?.value !== 1 && (
                        <Select
                          options={isSummaryOpt}
                          isSearchable={true}
                          value={isSummaryOpt?.find(
                            (opt) => opt?.value === isSummary
                          )}
                          onChange={(val) => onSummaryChange(val)}
                        />
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success ms-5"
                        onClick={printDocument}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-printer me-3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                          <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 0-1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>
                        Print report to PDF
                      </button>
                    </div>
                  </div>
                  {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn || reportData?.serials?.length === 0}>
                                                <img src={exportIcon} className="me-2" alt="" />
                                                Export grid to Excel
                                            </button> */}
                </div>
                <div className="report-page" ref={reportPage}>
                  <div className="report-header d-flex justify-content-between">
                    <div>
                      <h3>
                        <b>
                          {reportName}{" "}
                          {reportData?.groupTitle === "Nothing"
                            ? ""
                            : `grouped by ${reportData?.groupTitle}`}
                        </b>
                      </h3>{" "}
                      <br />
                      <p>
                        <b>Report Date: </b>
                        {dateFormmaterNoTime(reportData?.reportDate)}
                      </p>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={reportData?.imageLogo ?? BlueSeedLogo}
                        className="report-logo"
                      ></img>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    {gridColumns.length > 0 && (
                      <AgGrid
                        data={
                          getValues("groupingElement")?.value === 1
                            ? reportData?.groups[0]?.items
                            : reportData?.groups
                        }
                        columns={getColumns}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        subTable={gridColumns[1]}
                        subTableObj={"items"}
                        fileName="Inventory Aging Report"
                        ref={childFnRef}
                      />
                    )}
                    <div className="mt-3 pb-3">
                      <div className="d-flex justify-content-between">
                        <div className="label">Total QTY</div>
                        <div className="value">
                          From 0 to 30: {reportData?.qty_From0To30}
                        </div>
                        <div className="value">
                          From 31 to 60: {reportData?.qty_From31To60}
                        </div>
                        <div className="value">
                          From 61 to 90: {reportData?.qty_From61To90}
                        </div>
                        <div className="value">
                          From 91 to 120: {reportData?.qty_From91To120}
                        </div>
                        <div className="value">
                          Over 120: {reportData?.qty_Over120}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 pb-3">
                      <div className="d-flex justify-content-between">
                        <div className="label">Total Cost</div>
                        <div className="value">
                          From 0 to 30:{" "}
                          {formatNumber(
                            reportData?.cost_From0To30,
                            currencySymbol
                          )}
                        </div>
                        <div className="value">
                          From 31 to 60:{" "}
                          {formatNumber(
                            reportData?.cost_From31To60,
                            currencySymbol
                          )}
                        </div>
                        <div className="value">
                          From 61 to 90:{" "}
                          {formatNumber(
                            reportData?.cost_From61To90,
                            currencySymbol
                          )}
                        </div>
                        <div className="value">
                          From 91 to 120:{" "}
                          {formatNumber(
                            reportData?.cost_From91To120,
                            currencySymbol
                          )}
                        </div>
                        <div className="value">
                          Over 120:{" "}
                          {formatNumber(
                            reportData?.cost_Over120,
                            currencySymbol
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
