import { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Close from '../../img/close.svg';
import ListItem from './ListSelection.models';
import './ListSelection.module.scss';
import DispatchContext from "context/DispatchContext";

type Props = {
    showModal: boolean,
    title: string,
    data: ListItem[],
    selectedValues: number[],
    onSelectData: (data: ListItem[]) => void,
    onExit: () => void,
    selectValue?: number,
    isInvoice?: boolean,
    maxValue?: number
}

const ListSelectionModal = (props: Props) => {

    const appDispatch = useContext(DispatchContext);

    const [selectedData, setSelectedData] = useState<ListItem[]>(props.data?.filter(item => props.selectedValues?.find(id => id === item.id)));

    useEffect(() => {
        setSelectedData(props.data?.filter(item => props.selectedValues?.find(id => id === item.id)))
    }, [props.selectedValues, props.data])

    // const [unselectedData, setUnselectedData] = useState<ListItem[]>(props.data?.filter(item => (!props.selectedValues || !props.selectedValues?.find(id => id === item.id))));
    const [filterText, setFilterText] = useState<string>('');

    // useEffect(() => {
    //     setUnselectedData(props.data?.filter(item => (!selectedData?.find(si => si.id === item.id))));
    // }, [selectedData, props.data]);


    const onCheckboxChange = (e) => {
        if (e.target.checked) {
            let selected = props.data?.filter(state => state.id === Number(e.target.name));
            setSelectedData(state => [...state, ...selected]);
        }
        else {
            setSelectedData(state => state.filter(element => element.id !== Number(e.target.name)));
        }
    }

    const onAddSelected = () => {
        if (props.maxValue) {
            if (props.maxValue < selectedData.length) {
                let notification = {
                    variant: "danger",
                    msg: `You cannot select more than ${props.maxValue} serials.`
                };
                appDispatch({ type: "notification", value: notification });
            } else {
                props.onSelectData(selectedData);
                props.onExit();
            }
        } else {
            props.onSelectData(selectedData);
            props.onExit();
        }
    }

    const onExit = () => {
        props.onExit();
    }

    return (
        <>
            <Modal show={props.showModal} centered>
                <Modal.Header className="d-flex justify-content-between border-bottom-0">
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                    <img
                        src={Close}
                        alt="close icon"
                        className="ms-auto"
                        role="button"
                        onClick={onExit}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div className='row gx-5'>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <input type="text" placeholder='Find' className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} />
                        </div>
                        {/* <div className='col-lg-6 col-md-12 col-sm-12'>
                            {props.selectValue && `Qty: ${props.selectValue}` } <span className='me-3 ms-3'>|</span>
                            Selected({selectedData?.length})
                        </div> */}
                    </div>
                    <div className='row gx-5 mt-3'>
                        <div className='col-lg-12 col-md-12 col-sm-12 modal-column'>
                            <div className='column-container'>
                                {
                                    props.data?.filter(unselected => unselected.title && unselected.title.toLowerCase().includes(filterText?.toLowerCase()))
                                        .map((element) => {
                                            return (
                                                <div key={element.id}>
                                                    <input type="checkbox" id={element.id.toString()} name={element.id.toString()} onChange={onCheckboxChange}
                                                        checked={selectedData?.some(selected => selected.id === element.id)}
                                                    />
                                                    <label htmlFor={element.id.toString()}>{element.title}</label>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div>

                        {/* <div className="col-lg-6 col-md-12 col-sm-12 modal-column">
                            <div className='column-container'>
                                {
                                    selectedData?.filter(selected => selected.title && selected.title.includes(filterText))
                                        .map((element) => {
                                            return (
                                                <div key={element.id}>
                                                    <input type="checkbox" id={element.id.toString()} name={element.id.toString()} defaultChecked={true} onChange={onCheckboxChange} />
                                                    <label htmlFor={element.id.toString()}>{element.title}</label>
                                                </div>
                                            )
                                        })
                                }
                            </div>
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex ms-auto border-top-0">
                    <button type="button" className="btn btn-outline-primary custom-outline" onClick={onExit}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={onAddSelected} >
                        {props.isInvoice ? "Confirm" : "Add"} Selected
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ListSelectionModal;