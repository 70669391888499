import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";
import { DropDownOption } from "components/Common.models";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { ItemSubClass } from "./InventorySetup.model";
import {
  createSubClass,
  deleteSubClass,
  editSubClass,
  getItemClasses,
  getItemSubClasses,
} from "./InventorySetup.services";

const NewItemSubClass = ({ match }) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const [selectedClassId, setSelectedClassId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const [itemClassOptions, setItemClassOptions] =
    useState<DropDownOption[]>(null);
  const { error: errorLoadingData } = useQuery<any, ErrorResponse>(
    "get_subClasses",
    getItemSubClasses
  );

  const location = useLocation();
  const history = useHistory();
  const onSubmit = (values) => {
    const data: ItemSubClass = {
      itemClassId: values.classId,
      itemSubClassId: selectedId ? selectedId : 0,
      subClass: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addSubClass.mutate(data);
    } else {
      updateSubClass.mutate(data);
    }
  };
  useEffect(() => {
    if (location?.state) {
      const { id, name, description, itemClass } = location.state;
      setSelectedId(id);
      setSelectedClassId(parseInt(itemClass));
      setValue("name", name);
      setValue("description", description);
      setValue("classId", itemClass);
    }
  }, [location?.state]);

  const { error: errorLoadingDataClasses } = useQuery<any, ErrorResponse>(
    "get_classes",
    getItemClasses,
    {
      onSuccess(data) {
        const dataOptions = data?.data?.map((item) => {
          return { label: item.className, value: item.itemClassId };
        });

        setItemClassOptions(dataOptions);
      },
    }
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    register,
    control,
  } = methods;

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  useEffect(() => {
    if (errorLoadingDataClasses?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingDataClasses.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingDataClasses, appDispatch]);

  const addSubClass = useMutation(createSubClass, {
    async onSuccess() {
      queryClient.invalidateQueries("get_subClasses");
      let notification = {
        variant: "success",
        msg: `Item sub class ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
      history.push(AppUrls.item_sub_class);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item sub class"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateSubClass = useMutation(editSubClass, {
    async onSuccess() {
      queryClient.invalidateQueries("get_subClasses");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSelectedId(null);
      reset();
      history.push(AppUrls.item_sub_class);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing item sub class"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeItem = useMutation(deleteSubClass, {
    async onSuccess() {
      queryClient.invalidateQueries("get_subClasses");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing item sub class."),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const resetForm = () => {
    reset();
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        addSubClass.isLoading ||
        updateSubClass.isLoading ||
        removeItem.isLoading,
    });
  }, [
    addSubClass.isLoading,
    updateSubClass.isLoading,
    removeItem.isLoading,
    appDispatch,
  ]);

  return (
    <>
      <div className="static-page">
        <form id="new-method-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="  page-title page-title-editable">
            <div className="d-flex flex-row">
              <div className="back-btn">
                <img
                  src={backArrow}
                  alt="back arrow"
                  onClick={() => history.push(AppUrls.item_sub_class)}
                />
              </div>
              <div>
                <p className="">Item Sub-Class</p>
              </div>
            </div>
            <div className="d-flex ">
              <button
                type="button"
                className="btn btn-outline-primary custom-outline custom-outline"
                onClick={resetForm}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isDirty}
              >
                {!selectedId ? "Add Sub Class" : "Save Changes"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="row h-100">
              {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                <div className="">
                  <div className="form-group">
                    <label className="label">
                      Item Class <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="classId"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          menuPlacement="auto"
                          options={itemClassOptions}
                          isSearchable={true}
                          value={
                            (selectedId
                              ? itemClassOptions?.find(
                                  (i) => i.value === selectedClassId
                                )
                              : itemClassOptions?.find(
                                  (opt) => opt?.value === value
                                )) || null
                          }
                          onChange={(val: DropDownOption) => {
                            onChange(val?.value);
                            setSelectedClassId(val?.value);
                          }}
                          isClearable={true}
                        />
                      )}
                    />
                    {errors.classId && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      {!selectedId ? "New Sub Class" : "Sub class"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("name", { required: true, maxLength: 15 })}
                      className={
                        errors["name"]
                          ? "form-control required_field"
                          : "form-control"
                      }
                      required={true}
                    />
                    {errors.name && errors.name.type === "maxLength" && (
                      <p className="text-danger">
                        Max length for name value must not exceed 15 characters.
                      </p>
                    )}
                    {errors.name && errors.name.type === "required" && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Description <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("description", { required: true })}
                      className={
                        errors["description"]
                          ? "form-control required_field"
                          : "form-control"
                      }
                      required={true}
                    />
                    {errors.description &&
                      errors.description.type === "required" && (
                        <p className="text-danger">This field is required</p>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>{" "}
        </form>
      </div>
    </>
  );
};

export default NewItemSubClass;
