import { Modal } from 'react-bootstrap';
import close from 'img/close.svg';
import CheckboxTree from 'react-checkbox-tree';
import { getItemCategories } from '../InventorySetup/InventorySetup.services';
import { useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
} from "react-icons/md";

type Props = {
    onConfirm: (category: { label: string, value: number }) => void,
    onClose: () => void,
    showModal: boolean,
    selectedCategory: number
};

const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: (
        <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
    ),
    expandClose: (
        <MdChevronRight className="rct-icon rct-icon-expand-close" />
    ),
    expandOpen: (
        <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
    ),
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: (
        <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
    ),
    parentClose: <span className='d-none'></span>,
    parentOpen: <span className='d-none'></span>,
    leaf: <span className='d-none'></span>
};

const CategoryModal = (props: Props) => {


    const [expanded, setExpanded] = useState<string[]>([]);
    const [checked, setChecked] = useState<string[]>([]);
    const categoriesFlat = useRef(null);

    useEffect(() => {
        if (props.selectedCategory)
            setChecked([props.selectedCategory.toString()])

    }, [props.selectedCategory])

    const {
        data: categories,
    } = useQuery("item-categories", getItemCategories,
        {
            onSuccess(data) {
                let flatCategories = [];
                const transformCategories = (data) => {
                    if (data && data.length > 0) {
                        for (let category of data) {
                            if (category.isLeaf) flatCategories.push(category);
                            if (category.children?.length > 0) {
                                transformCategories(category.children);
                            }
                        }
                    }
                };
                transformCategories(data);
                categoriesFlat.current = flatCategories
            },
        }
    );

    const onCloseClicked = () => {
        props.onClose();
    }

    const onCheck = (_nodes, target) => {
        setChecked([target.value])
    };

    const onClick = (target) => {
        if (target.isLeaf)
            setChecked([target.value])
    }

    const onAddCategory = () => {
        let category = categoriesFlat.current.find(category => category.value === checked[0])
        if (category)
            props.onConfirm(category)
    }

    return (
        <Modal className="confirmation-modal" show={props.showModal} onHide={() => props.onClose()} centered>
            <div className="d-flex justify-content-between align-items-center mx-4 my-3">
                <div className='confirmation-modal-title'>Add Category</div>
                <img
                    src={close}
                    alt=''
                    className='confirmation-modal-close'
                    onClick={onCloseClicked}
                />
            </div>
            <div className="mx-4">
                <Modal.Body className='px-0 py-0'>
                    <CheckboxTree
                        nodes={categories}
                        expanded={expanded}
                        onExpand={(nodes) => setExpanded(nodes)}
                        icons={icons}
                        expandOnClick={true}
                        onlyLeafCheckboxes={true}
                        checked={checked}
                        onCheck={onCheck}
                        onClick={onClick}
                    />
                    <div className='d-flex justify-content-between mt-5'>
                        <button className='btn btn-outline-primary ms-0' type='button' onClick={() => props.onClose()}>
                            Cancel
                        </button>
                        <button
                            className={`btn btn-primary`}
                            disabled={checked.length === 0}
                            onClick={onAddCategory}
                        >
                            Add
                        </button>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default CategoryModal;