import { dateFormmaterNoTime, formatNumber } from "global/helpers";
import { useQuery } from "react-query";
import { useParams, useHistory } from 'react-router-dom'
import { getLogDetails } from "./AccountingServices";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";

enum ReconciliationLogTypeEnum {
    OutstandingChecks = 1,
    DepositsInTransit = 2,
    NSFChecks = 3,
    BankServiceFees = 4,
    InterestIncome = 5,
    DifferentCheckAmount = 6,
    DifferentDepositAmount = 7,
    NotRecordedCheck = 8,
    NotRecordedDeposit = 9,
    DuplicateCheck = 10
}

const StatementDetails = () => {

    const { id } = useParams();
    const history = useHistory()

    const { data } = useQuery([id], getLogDetails);

    const getEntries = (type) => {
        if (data?.reconciliationLogEntries?.filter(entry => entry.reconciliationLogType === type).length > 0)
            return data?.reconciliationLogEntries.filter(entry => entry.reconciliationLogType === type)
        return [{ amount: 0 }]
    }

    const onBackClick = () => {
        history.push(AppUrls.bank_reconciliation)
    }

    return (
        <div className="static-page">
            <div className="page-title page-title-editable">
                <div className="back-btn " onClick={onBackClick}>
                    <img src={backArrow} alt="back arrow" />
                    Bank Reconciliation
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="report-page fs-5">
                        <div className="d-flex mb-5">
                            <label className="me-4">Reconciliation Date</label>
                            <span>{dateFormmaterNoTime(data?.reconciliationDate)}</span>
                        </div>
                        <div className="mb-4">
                            {getEntries(ReconciliationLogTypeEnum["DepositsInTransit"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Add: Deposit in Transit
                                        </div>
                                        <div className="col-sm-4">
                                            {formatNumber(entry.amount)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <hr />
                        <div className="mb-4">
                            {getEntries(ReconciliationLogTypeEnum["OutstandingChecks"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Less: Outstanding Checks
                                        </div>
                                        <div className="col-sm-4 offset-sm-4">
                                            {formatNumber(Math.abs(entry.amount))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <hr />
                        <div>
                            {getEntries(ReconciliationLogTypeEnum["InterestIncome"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Add: Interest Income
                                        </div>
                                        <div className="col-sm-4">
                                            {formatNumber(entry.amount)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="mb-4">
                            {getEntries(ReconciliationLogTypeEnum["NotRecordedDeposit"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Add: Understated Deposits
                                        </div>
                                        <div className="col-sm-4">
                                            {formatNumber(entry.amount)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <hr />
                        <div>
                            {getEntries(ReconciliationLogTypeEnum["NSFChecks"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Less: NFS Check
                                        </div>
                                        <div className="col-sm-4 offset-sm-4">
                                            {formatNumber(Math.abs(entry.amount))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            {getEntries(ReconciliationLogTypeEnum["BankServiceFees"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Less: Bank Service Fee
                                        </div>
                                        <div className="col-sm-4 offset-sm-4">
                                            {formatNumber(Math.abs(entry.amount))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            {getEntries(ReconciliationLogTypeEnum["NotRecordedCheck"]).map((entry, index) => {
                                return (
                                    <div key={index} className="row">
                                        <div className="col-sm-4">
                                            Less: Bank Overdraft Fee
                                        </div>
                                        <div className="col-sm-4 offset-sm-4">
                                            {formatNumber(Math.abs(entry.amount))}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatementDetails