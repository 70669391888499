import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import {
  ItemHubsInitData,
  InventoryItemHub,
  Hub,
  ItemHistory,
  inventoryItemInit,
  serializedItem,
  nonSerializedItem,
  nonTangibleDTO,
  nonTangibleInit,
  serialNumbers,
} from "pages/Inventory/Inventory.models";

const inventoryItemsUrl = "inventory/items";
const serializedItemUrl = "inventory/items/serialized";
const nonserializedItemUrl = "inventory/items/non-serialized";

export const getInventoryItems = async (data) => {
  let response;
  if (data.pageStart && data.pageSize) {
    response = await api.get(
      `${inventoryItemsUrl}/hub/${data.hubId}/type/${data.itemType}?PageSize=${
        data.PageSize
      }&PageStart=${data.PageStart}${data.params ?? ""}`
    );
  } else {
    response = await api.get(
      `${inventoryItemsUrl}/hub/${data.hubId}/type/${data.itemType}?${
        data.params ?? ""
      }`
    );
  }
  return response?.data;
};

export const getSerializedInit = async () => {
  const response = await api.get<inventoryItemInit>(
    `${serializedItemUrl}/init`
  );
  return response.data;
};

export const createSerializedItem = async (data) => {
  return await api.post(serializedItemUrl, data);
};

export const editSerializedItem = async (data) => {
  return await api.put(`${serializedItemUrl}/${data.serializedItemId}`, data);
};

export const getSerializedItem = async (id) => {
  let queryId = id.queryKey[0];
  if (queryId) {
    const response = await api.get<serializedItem>(
      `${serializedItemUrl}/${queryId}`
    );
    return response.data;
  }
};

export const getItemPopUps = async (id) => {
  const response = await api.get(`inventory/items/item-lookup?itemId=${id}`);
  return response.data;
};

export const uploadPortalImage = async (data) => {
  return await api.post(`inventory/items/${data.itemId}/image`, data.file);
};

export const manualReceipt = async (data) => {
  return await api.post("inventory-transfer/manual-receipt", data);
};

export const manualIssue = async (data) => {
  return await api.post("inventory-transfer/manual-issue", data);
};

export const QuickLocationAPI = async (data) => {
  return await api.post("inventory-transfer/quick-location", data);
};

export const InterLocationAPI = async (data) => {
  return await api.post("inventory-transfer/inter-location", data);
};

export const TransferAPI = async () => {
  const result = await api.get("inventory-transfer");
  return result.data.data;
};

export const SerialTransferAPI = async (data) => {
  return await api.post("inventory-transfer/serial-transfer", data);
};

export const ProcessTransferAPI = async (transferId) => {
  return await api.post(`inventory-transfer/${transferId}/process`);
};

export const VoidTransferAPI = async (transferId) => {
  return await api.delete(`inventory-transfer/${transferId}/void `);
};

export const getNonTangibleSelections = async () => {
  const response = await api.get<nonTangibleInit>(
    `inventory/items/non-tangible/init`
  );
  return response.data;
};

export const GetSerialsByItemId = async (id) => {
  let queryId = id.queryKey[0][0];
  if (queryId) {
    const result = await api.get(`inventory/items/serials?id=${queryId}`);
    return result?.data;
  }
};

export const createNonTangibleItem = async (data) => {
  return await api.post(`inventory/items/non-tangible`, data);
};

export const updateNonTangibleItem = async (data) => {
  return await api.put(
    `inventory/items/non-tangible/${data.itemId}`,
    data.body
  );
};

export const getNonTangibleItemById = async (id) => {
  id = id.queryKey[0];
  const response = await api.get<nonTangibleDTO>(
    `inventory/items/non-tangible/${id}`
  );
  return response.data;
};

export const deleteNonTangibleItem = async (id) => {
  return await api.delete(`inventory/items/non-tangible/${id}`);
};

export const deleteSerializedItem = async (id) => {
  return await api.delete(`${serializedItemUrl}/${id}`);
};

export const getNonSerializedInit = async () => {
  const response = await api.get<inventoryItemInit>(
    `${nonserializedItemUrl}/init`
  );
  return response.data;
};

export const createNonSerializedItem = async (data) => {
  return await api.post(nonserializedItemUrl, data);
};

export const editNonSerializedItem = async (data) => {
  return await api.put(
    `${nonserializedItemUrl}/${data.nonSerializedItemId}`,
    data
  );
};

export const getNonSerializedItem = async (id) => {
  let queryId = id.queryKey[0];
  if (queryId) {
    const response = await api.get<nonSerializedItem>(
      `${nonserializedItemUrl}/${queryId}`
    );
    return response.data;
  }
};

export const deleteNonSerializedItem = async (id) => {
  return await api.delete(`${nonserializedItemUrl}/${id}`);
};

export const createItemNote = async (data) => {
  return await api.post(`inventory/items/${data.id}/notes`, {
    subject: data.newNoteData.subject,
    noteBody: data.newNoteData.noteBody,
  });
};

export const editItemNote = async (data) => {
  let body = {};
  body = {
    subject: data.noteData.subjectTxt,
    noteBody: data.noteData.noteBody,
    noteId: data.noteData.noteId,
  };
  return await api.put(`inventory/items/notes/${data.noteId}`, body);
};

export const deleteItemNote = async (id) => {
  return await api.delete(`inventory/items/notes/${id}`);
};

export const getItemHubsInitData = async (id): Promise<ItemHubsInitData> => {
  const response = await api.get<ItemHubsInitData>(
    `inventory/items/hubs/init/${id}`
  );
  return response.data;
};

export const getHubPricing = async (data): Promise<InventoryItemHub> => {
  let itemId = data.itemId;
  let hubId = data.hubId;
  if (itemId && hubId) {
    const response = await api.get<InventoryItemHub>(
      `inventory/items/${itemId}/hubs/${hubId}`
    );
    return response.data;
  }
};

export const updateHubPricing = async (data: any): Promise<any> => {
  const response = await api.post<any>(
    `inventory/items/${data.itemId}/hubs`,
    data
  );
  return response.data;
};

export const getInventoryHubsList = async (
  params
): Promise<QueryResult<Hub>> => {
  const response = await api.get<QueryResult<Hub>>(`hubs?${params.params}`);
  return response.data;
};

export const getItemHistory = async (data) => {
  return await api.get<ItemHistory>(
    `${inventoryItemsUrl}/${data.itemId}/history?${
      data.queryParams !== "" ? data.queryParams : ""
    }`
  );
};

export const getInvoiceHist = async (data) => {
  if (data.customerId)
    return await api.get<any>(
      `invoices/history/item/${data.itemId}?customerId=${data.customerId}`
    );
  else return await api.get<any>(`invoices/history/item/${data.itemId}`);
};

export const createHub = async (data: Hub): Promise<Hub> => {
  const response = await api.post<Hub>(`hubs`, data);
  return response.data;
};

export const updateHub = async (data: Hub): Promise<Hub> => {
  const response = await api.put<Hub>(`hubs/${data.id}`, data);
  return response.data;
};

export const getHubById = async (id): Promise<Hub> => {
  let queryId = id.queryKey[0];
  if (queryId) {
    const response = await api.get<Hub>(`hubs/${queryId}`);
    return response.data;
  }
};

export const deleteHub = async (id: number) => {
  const response = await api.delete<boolean>(`hubs/${id}`);
  return response.data;
};

export const copyHub = async (data) => {
  let id: number = data.id;
  let hubId: string = data.hubId;
  const response = await api.put(`hubs/${id}/copy-items?hubId=${hubId}`);
  return response.data;
};

export const getItemModelsList = async () => {
  return await api.get("item-models");
};

export const createItemModel = async (data) => {
  return await api.post(`item-models`, {
    model: data.itemModelData.itemModelName,
    description: data.itemModelData.itemModelDescription,
    itemManufacturerId: data.itemModelData.itemManufacturerId,
  });
};

export const updateItemModel = async (data) => {
  let body = {};
  body = {
    model: data.itemModelData.itemModelName,
    description: data.itemModelData.itemModelDescription,
    itemManufacturerId: data.itemModelData.itemManufacturerId,
  };
  return await api.put(`item-models/${data.itemModelId}`, body);
};

export const deleteItemModel = async (id) => {
  return await api.delete(`item-models/${id}`);
};

export const getSerialNumbersList = async (data) => {
  if (data.PageSize && data.PageStart) {
    const response = await api.get(
      `serials?PageSize=${data.PageSize}&PageStart=${data.PageStart}&serialNo=${
        data.serialNumber != undefined ? data.serialNumber : ""
      }&itemNumber=${
        data.itemNumber != undefined ? data.itemNumber : ""
      }&hubId=${data.hubId != undefined ? data.hubId : ""}`
    );
    return response.data;
  } else {
    const response = await api.get(
      `serials?serialNo=${
        data.serialNumber != undefined ? data.serialNumber : ""
      }&itemNumber=${
        data.itemNumber != undefined ? data.itemNumber : ""
      }&hubId=${data.hubId != undefined ? data.hubId : ""}`
    );
    return response.data;
  }
};

export const getSerialNumberById = async (id): Promise<serialNumbers> => {
  let queryId = id.queryKey[0];
  if (queryId) {
    const response = await api.get<serialNumbers>(`/serials/${queryId}`);
    return response.data;
  }
};
