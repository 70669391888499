import { useState, useEffect } from "react";
import Close from "img/close.svg";
import { Modal } from "react-bootstrap";
import AG from "elements/AG";
import { itemsColumns } from 'components/ItemOrderModal/modalColumns';
import Select from "react-select";
import { useQuery, useMutation } from 'react-query';
import {
    getInventoryHubsList,
    getInventoryItems
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { DropDownOption } from "components/Common.models";

type Props = {
    showModal: boolean,
    setShowModal: any,
    setRowClicked: (e: any) => void,
    selectedHub: number,
    searchHubs?: boolean,
    onlySerialized?: boolean,
    isPo?: boolean
}

const ItemsModal = (props: Props) => {

    const {
        showModal,
        setShowModal,
        setRowClicked,
        selectedHub,
        searchHubs = false,
        isPo = false
    } = props;

    const [chosenHub, setChosenHub] = useState<number>(selectedHub)
    const [selectedUnitType, setSelectedUnitType] = useState<DropDownOption>({ label: "All", value: 1 })
    const [hubsOptions, setHubsOptions] = useState<DropDownOption[]>([])
    const [gridData, setGridData] = useState<Array<Object>>()

    const onRowDoubleClick = (item) => {
        setRowClicked(item)
    }

    const unitTypeOptions = [
        {
            label: "All",
            value: 1
        },
        {
            label: "Serialized",
            value: 2
        },
        {
            label: "Non Serialized",
            value: 3
        },
        {
            label: "Non Tangible",
            value: 4
        }
    ]

    const unitTypeOptionsWithoutNonTangible = [
        {
            label: "All",
            value: 1
        },
        {
            label: "Serialized",
            value: 2
        },
        {
            label: "Non Serialized",
            value: 3
        }
    ]

    const {
        isSuccess: isSuccessHubs,
        data: hubs,
    } = useQuery("hubsList", getInventoryHubsList, { enabled: searchHubs });

    const getItems = useMutation(getInventoryItems, {
        async onSuccess(response) {
            if(props.onlySerialized) {
                setGridData(response.data.filter(item => item.isActive === true && item.isSerialized === true).map(element => ({ ...element, itemHubId: chosenHub })))
            } else {
                if(props.isPo) {
                    setGridData(response.data.filter(item => item.isActive === true && item.nonTangible === false).map(element => ({ ...element, itemHubId: chosenHub })))
                } else {
                    setGridData(response.data.filter(item => item.isActive === true).map(element => ({ ...element, itemHubId: chosenHub })))
                }
            }
        }
    });

    const onHubChange = (e) => {
        setChosenHub(e.value)
    }

    const onUnitTypeChange = (e) => {
        setSelectedUnitType(e)
    }

    useEffect(() => {
        if (isSuccessHubs) {
            let modifiedHubs =
                hubs?.data?.map(element => {
                    return { label: element.hubId, value: element.locationId }
                })
            modifiedHubs.unshift({ label: "All", value: -1 })
            setHubsOptions(modifiedHubs)
            if (modifiedHubs.length > 0)
                setChosenHub(modifiedHubs[0].value)
        }
    }, [isSuccessHubs, hubs])

    useEffect(() => {
        if (selectedHub)
            setChosenHub(selectedHub)
    }, [selectedHub])

    useEffect(() => {
        if (chosenHub && selectedUnitType && showModal)
            getItems.mutate({ hubId: chosenHub, itemType: selectedUnitType['value'] })
    }, [chosenHub, selectedUnitType, showModal])

    return (
        <Modal show={showModal} onHide={setShowModal} centered size="lg" dialogClassName="modal-80w">
            <Modal.Header className="d-flex justify-content-between border-bottom-0">
                <Modal.Title className="">
                    Double click on an item to select it
                </Modal.Title>
                <img
                    src={Close}
                    alt="close icon"
                    className=""
                    role="button"
                    onClick={() => {
                        setShowModal(false);
                    }}
                />
            </Modal.Header>
            <Modal.Body className="px-0">
                <div className='row mb-5 px-3'>
                    {searchHubs && (
                        <div className='col-lg-5 d-flex align-items-center'>
                            <label className="label me-3 fw-bold">Hubs</label>
                            <Select
                                options={hubsOptions}
                                isSearchable={true}
                                value={hubsOptions.find(val => val.value === chosenHub)}
                                onChange={onHubChange}
                                className="flex-grow-1"
                            />
                        </div>
                    )}
                    {!props.onlySerialized && (
                        <div className='col-lg-5 d-flex align-items-center'>
                            <label className="label me-3 fw-bold">Unit Type</label>
                            <Select
                                options={props.isPo ? unitTypeOptionsWithoutNonTangible : unitTypeOptions}
                                isSearchable={true}
                                value={selectedUnitType}
                                onChange={onUnitTypeChange}
                                className="flex-grow-1"
                            />
                        </div>
                    )}
                </div>
                <div className='modal-table'>
                    <AG
                        data={gridData}
                        columns={itemsColumns}
                        setrowClicked={onRowDoubleClick}
                        autoColumns={true}
                        pagination={false}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex  border-top-0">
                <button
                    type="button"
                    className="btn btn-outline-primary custom-outline"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ItemsModal