import { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import SearchIcon from "img/search-group.png";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { DropDownOption, GridColumns } from "components/Common.models";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { getShippingList } from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { ShippingDetails } from "pages/SalesOrder/SalesOrder.model";
import { HubInfo, InvoiceFormDTO } from "../Invoices.model";
import { ShippingMethodRequest } from "pages/SystemSetup/SystemSetup.models";
import CreatableSelect from "react-select/creatable";

const InvoiceInfo = ({
  isEditing,
  isCreating,
  customerDetails = null,
  setHubId = null,
  addedItems = [],
  setAddedItems = null,
  isCredit = false,
  invoiceData = null,
  methodsCounter = 0,
  setShowShippingModal = null,
}) => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [modalRowClicked, setModalRowClicked] = useState();
  const [shippingOptions, setShippingOptions] = useState<DropDownOption[]>([]);
  const [shippingDetails, setShippingDetails] = useState<ShippingDetails>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [changedHub, setChangedHub] = useState<HubInfo>();

  const {
    formState: { errors },
    register,
    control,
    setValue,
  } = useFormContext<InvoiceFormDTO>();

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No",
      editable: false,
    },
  ];

  const shipColumns = [
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "address.workNumber",
      headerName: "Work No.",
    },
    {
      field: "address.address",
      headerName: "Address",
    },
    {
      field: "address.city",
      headerName: "City",
    },
    {
      field: "address.state",
      headerName: "State",
    },
    {
      field: "address.country",
      headerName: "Country",
    },
    {
      field: "address.zipCode",
      headerName: "Zip Code",
    },
  ];

  const { data: hubsList } = useQuery("hubsList", getInventoryHubsList);

  const { data: shippingMethods, refetch } = useQuery<ShippingMethodRequest>(
    "shippingList",
    getShippingList
  );

  useEffect(() => {
    if (methodsCounter > 0) refetch();
  }, [methodsCounter]);

  useEffect(() => {
    if (customerDetails && hubsList) {
      setValue(
        "defaultHub",
        hubsList?.data?.find(
          (hub) => hub?.locationId === customerDetails?.defaultHubId
        )?.hubId
      );
    }
  }, [customerDetails, hubsList]);
  useEffect(() => {
    if (shippingMethods?.data) {
      let activeMethods = shippingMethods?.data?.filter(
        (method) => method.isActive === true
      );
      setShippingOptions(
        activeMethods.map((method) => ({
          value: method.shippingMethodId,
          label: method.name,
        }))
      );
    }
  }, [shippingMethods?.data]);

  useEffect(() => {
    if (modalRowClicked) {
      if (modalType === "ship") {
        setValue("shipTo", modalRowClicked["company"], {
          shouldValidate: true,
        });
        setValue("shippingInfoId", modalRowClicked["shippingInfoId"]);
        setShippingDetails(modalRowClicked);
      } else {
        if (addedItems.length === 0) {
          setValue("defaultHub", modalRowClicked["hubId"], {
            shouldValidate: true,
          });
          setHubId(modalRowClicked["locationId"]);
        } else {
          setShowConfirmation(true);
          setChangedHub(modalRowClicked);
        }
      }
    }
    setModalRowClicked(null);
    setOpenSelection(false);
  }, [modalRowClicked]);

  useEffect(() => {
    if (!customerDetails) setShippingDetails(null);
    else getShippingInfo();
  }, [customerDetails]);

  const changeHubs = () => {
    if (changedHub) {
      setValue("defaultHub", changedHub["hubId"]);
      setHubId(changedHub["locationId"]);
      setAddedItems([]);
    }
    setShowConfirmation(false);
  };

  const getShippingInfo = () => {
    if (isCredit) {
      if (customerDetails && customerDetails?.shippingInfos.length > 0) {
        setValue(
          "shippingInfoId",
          customerDetails.shippingInfos[0].shippingInfoId
        );
        return customerDetails.shippingInfos[0];
      } else {
        return null;
      }
    } else {
      return shippingDetails ? shippingDetails : null;
    }
  };

  useEffect(() => {
    if (!isCredit && customerDetails) {
      if (customerDetails && customerDetails?.shippingInfos.length > 0) {
        setValue(
          "shippingInfoId",
          customerDetails.shippingInfos[0].shippingInfoId
        );
        setValue("shipTo", customerDetails.shippingInfos[0].company);
        setShippingDetails(customerDetails.shippingInfos[0]);
        getShippingInfo();
      }
    }
  }, [isCredit, customerDetails]);

  const createLabel = () => <label>Add New</label>;

  const onCreateType = () => {
    if (setShowShippingModal) setShowShippingModal(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-6">
          <div className="row">
            {!isCredit && (
              <div className="col-sm-5">
                <label>
                  Ship to {isCreating && <span className="text-danger">*</span>}
                </label>
                {isCreating ? (
                  <InputGroup>
                    <input
                      className={`form-control ${
                        (isEditing || customerDetails) && "bg-white"
                      }`}
                      placeholder="Choose"
                      readOnly
                      {...register("shipTo")}
                    />
                    <input className="d-none" {...register("shippingInfoId")} />
                    <Button
                      variant="primary"
                      className="input-group-prepend"
                      onClick={() => {
                        setOpenSelection(true);
                        setModalType("ship");
                      }}
                      disabled={!customerDetails}
                    >
                      <img src={SearchIcon} alt="" />
                    </Button>
                  </InputGroup>
                ) : (
                  <div>{invoiceData?.shippingInfo?.company}</div>
                )}
              </div>
            )}
            <div className={`col-sm-12 ${!isCredit && "mt-5"}`}>
              <label>Company Name</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["company"]
                    ) : (
                      <span className="text-muted fst-italic">Company</span>
                    )}
                  </>
                ) : (
                  invoiceData?.shippingInfo?.company
                )}
              </div>
            </div>
            <div className="col-sm-4 mt-5">
              <label>Work No.</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["address"]["workNumber"] ?? (
                        <span className="text-muted fst-italic">Work No.</span>
                      )
                    ) : (
                      <span className="text-muted fst-italic">Work No.</span>
                    )}
                  </>
                ) : (
                  invoiceData?.shippingInfo?.address?.workNumber ?? (
                    <span className="text-muted fst-italic">Work No.</span>
                  )
                )}
              </div>
            </div>
            <div className="col-sm-12 mt-5">
              <label>Address</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["address"]["address"] !== "" ? (
                        getShippingInfo()["address"]["address"]
                      ) : (
                        <span className="text-muted fst-italic">Address</span>
                      )
                    ) : (
                      <span className="text-muted fst-italic">Address</span>
                    )}
                  </>
                ) : invoiceData?.shippingInfo?.address?.address !== "" ? (
                  invoiceData?.shippingInfo?.address?.address
                ) : (
                  <span className="text-muted fst-italic">Address</span>
                )}
              </div>
            </div>
            <div className="col-sm-12 mt-5">
              <label>Address1</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["address"]["address1"] !== "" ? (
                        getShippingInfo()["address"]["address1"]
                      ) : (
                        <span className="text-muted fst-italic">Address 1</span>
                      )
                    ) : (
                      <span className="text-muted fst-italic">Address 1</span>
                    )}
                  </>
                ) : invoiceData?.shippingInfo?.address?.address1 !== "" ? (
                  invoiceData?.shippingInfo?.address?.address1
                ) : (
                  <span className="text-muted fst-italic">Address 1</span>
                )}
              </div>
            </div>
            <div className="col-sm-2 mt-5">
              <label>City</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["address"]["city"] !== "" ? (
                        getShippingInfo()["address"]["city"]
                      ) : (
                        <span className="text-muted fst-italic">City</span>
                      )
                    ) : (
                      <span className="text-muted fst-italic">City</span>
                    )}
                  </>
                ) : invoiceData?.shippingInfo?.address?.city !== "" ? (
                  invoiceData?.shippingInfo?.address?.city
                ) : (
                  <span className="text-muted fst-italic">City</span>
                )}
              </div>
            </div>
            <div className="col-sm-2 mt-5">
              <label>State</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["address"]["state"] ?? (
                        <span className="text-muted fst-italic">State</span>
                      )
                    ) : (
                      <span className="text-muted fst-italic">State</span>
                    )}
                  </>
                ) : (
                  invoiceData?.shippingInfo?.address?.state ?? (
                    <span className="text-muted fst-italic">State</span>
                  )
                )}
              </div>
            </div>
            <div className="col-sm-2 mt-5">
              <label>Zip Code</label>
              <div>
                {isCreating ? (
                  <>
                    {getShippingInfo() ? (
                      getShippingInfo()["address"]["zipCode"] !== "" ? (
                        getShippingInfo()["address"]["zipCode"]
                      ) : (
                        <span className="text-muted fst-italic">Zip Code</span>
                      )
                    ) : (
                      <span className="text-muted fst-italic">Zip Code</span>
                    )}
                  </>
                ) : invoiceData?.shippingInfo?.address?.zipCode !== "" ? (
                  invoiceData?.shippingInfo?.address?.zipCode
                ) : (
                  <span className="text-muted fst-italic">Zip Code</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="section pb-5">
            <div className="row gy-5 mt-0">
              <div className="col-sm-6">
                <label>
                  Default Hub{" "}
                  {isCreating && <span className="text-danger">*</span>}
                </label>
                {isCreating ? (
                  <InputGroup>
                    <input
                      className={`form-control ${
                        (isEditing || customerDetails) && "bg-white"
                      }`}
                      placeholder="Choose"
                      readOnly
                      {...register("defaultHub", { required: true })}
                    />
                    <Button
                      variant="primary"
                      className="input-group-prepend"
                      onClick={() => {
                        setOpenSelection(true);
                        setModalType("hub");
                      }}
                      disabled={!customerDetails}
                    >
                      <img src={SearchIcon} alt="" />
                    </Button>
                  </InputGroup>
                ) : (
                  <div>{invoiceData?.hubKey}</div>
                )}
                {errors["defaultHub"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="col-sm-6">
                {!isCredit && (
                  <>
                    <label>Ship Via</label>
                    {isCreating ? (
                      <Controller
                        control={control}
                        name="shippingMethodId"
                        render={({ field: { onChange, value } }) => (
                          <CreatableSelect
                            isSearchable={true}
                            menuPlacement="auto"
                            onChange={onChange}
                            value={value || null}
                            isDisabled={!customerDetails}
                            options={shippingOptions}
                            isValidNewOption={() => true}
                            formatCreateLabel={createLabel}
                            createOptionPosition="first"
                            onCreateOption={onCreateType}
                          />
                        )}
                      />
                    ) : (
                      <div>{invoiceData?.shippingMethod}</div>
                    )}
                  </>
                )}
              </div>
              {/* {!isCredit && (
                <div className="col-sm-6">
                  <label>Customer PO</label>
                  <input
                    className="form-control"
                    disabled={!isEditing && !customerDetails}
                    {...register("customerPo")}
                  />
                </div>
              )} */}
              <div className="col-sm-12">
                <label>Remarks</label>
                <textarea
                  {...register("remarks")}
                  className="form-control min-h-200"
                  disabled={!isEditing && !customerDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal={modalType === "hub" ? "Hub" : "Shipping"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={
          modalType === "hub"
            ? hubsList?.data
            : customerDetails && customerDetails["shippingInfos"]
        }
        columns={modalType === "hub" ? hubsColumns : shipColumns}
        setRowClicked={setModalRowClicked}
      />
      <ConfirmationModal
        title="Changing Hubs"
        message="Are you sure you want to change hubs? Your selected items will be cleared"
        showModal={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={changeHubs}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default InvoiceInfo;
