import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

import {
  getReportInit,
  getReportInventoryItems,
} from "reports/services/ReportServices";

import Select from "react-select";
import DateGrid from "components/DateFilter/DateGrid";
import { Controller, useForm } from "react-hook-form";
import { getGMRoid } from "./DashboardServices";

import { Chart } from "react-google-charts";

import Accordion from "react-bootstrap/Accordion";
import { left } from "@popperjs/core";

const GrossMargins = () => {
  let types = [
    {
      value: 1,
      label: "Hub",
    },
    {
      value: 2,
      label: "Date",
    },
    {
      value: 3,
      label: "Item",
    },
    {
      value: 4,
      label: "Category",
    },
  ];

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [dateCategory, setDateCategory] = useState(null);

  const [selectedHub, setSelectedHub] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedType, setSelectedType] = useState(types[2]);

  const { data: initData } = useQuery("initData", getReportInit);
  const { data: inventoryItems } = useQuery(
    [{ hubId: -1, itemType: 1 }],
    getReportInventoryItems
  );
  let ItemsOpt = inventoryItems?.data?.map((data) => {
    return { value: data.itemId, label: data.itemNumber };
  });
  let CategoryOpt = initData?.categories?.map((data) => {
    return { value: data.itemCategoryId, label: data.category };
  });
  let HubIdOpt = initData?.hubs?.map((data) => {
    return { value: data.locationId, label: data.hubId };
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { control } = methods;

  const [apiDATA, setApiDATA] = useState<any>([{ variable: "N/A", value: 0 }]);

  const mutate = useMutation(getGMRoid, {
    onSuccess(data) {
      data?.length > 0 && setApiDATA(data);
    },
  });

  useEffect(() => {
    mutate.mutate({
      type: selectedType?.value,
      startDate: startDate,
      endDate: endDate,
      hubId: selectedHub?.value,
      itemId: selectedItem?.value,
      categoryId: selectedCat?.value,
    });
  }, [
    selectedType,
    startDate,
    endDate,
    selectedHub,
    selectedItem,
    selectedCat,
  ]);

  let data = [["Variable", "Amount"]];

  apiDATA?.forEach((e) => {
    data.push([e.variable ?? "N/A", e.value]);
  });

  return (
    <>
      <div
        className="shadow p-3 mb-5 bg-white rounded  "
        style={{ width: "85%", minHeight: "550px", marginLeft: "50px" }}
      >
        <h3 className="m-1 m-2">Gross Margins</h3>
        <Accordion>
          <Accordion.Header className="border p-2">
            <h5>
              <b>Filters</b>
            </h5>
          </Accordion.Header>
          <Accordion.Body className="border">
            <div className="row w-100">
              <DateGrid
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateCategory={dateCategory}
                setDateCategory={setDateCategory}
                isFullWidth={true}
              />
              <div className="col-4">
                <label>Type</label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={types}
                      value={selectedType}
                      onChange={(e) => setSelectedType(e)}
                      isSearchable={true}
                      isClearable={false}
                    />
                  )}
                />
              </div>
              <div className="col-4">
                <label>Hub</label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={HubIdOpt}
                      value={selectedHub}
                      onChange={(e) => setSelectedHub(e)}
                      isSearchable={true}
                      isClearable={true}
                    />
                  )}
                />
              </div>
              <div className="col-4">
                <label>Item</label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={ItemsOpt}
                      value={selectedItem}
                      onChange={(e) => setSelectedItem(e)}
                      isSearchable={true}
                      isClearable={true}
                    />
                  )}
                />
              </div>
              <div className="col-4 mt-3">
                <label>Category</label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={CategoryOpt}
                      value={selectedCat}
                      onChange={(e) => setSelectedCat(e)}
                      isSearchable={true}
                      isClearable={true}
                    />
                  )}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion>
        <div className="mt-4">
          <Chart
            chartType="Bar"
            width="100%"
            height="350px"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{}}
          />
        </div>
      </div>
    </>
  );
};

export default GrossMargins;
