import SectionTitle from "elements/SectionTitle";
import { formatInput } from "global/helpers";
import { Form } from "react-bootstrap";
import "./../../../src/styles/toggle.css";

import { Controller, useFormContext } from "react-hook-form";

type Props = {
  title?: string;
  btn: string;
  resetData: () => void;
  active?: boolean;
  withCost?: boolean;
  name: string;
};

const SetupForm = (props: Props) => {
  const {
    formState: { errors, isDirty },

    control,
    register,
    setValue,
  } = useFormContext();

  const clearForm = () => {
    props.resetData();
  };
  const isActive = useFormContext().watch("isActive");

  return (
    <>
      <div className=" cntrlBtns position-absolute top-0 end-0 "></div>
      <div className="formCreate">
        {props?.title && (
          <SectionTitle
            active={props.active}
            editable={false}
            title={props.title}
          />
        )}
        <div className="row ">
          <div className="col-4">
            <label>
              {props.name} <span className="text-danger">*</span>
            </label>
            <Form.Control
              {...register("name", { required: true })}
              className={`form-control ${errors["name"] && "required_field"}`}
              type="string"
            />
            {errors["name"] && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="col-1">
            <label>{isActive ? "Active" : "Disabled"}</label>
            <div className="form-group">
              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, value } }) => (
                  <>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="activeCheckbox"
                        name="isActive"
                        onChange={() => onChange(!value)}
                        checked={value}
                      />
                      <span className="slider round"></span>
                    </label>
                  </>
                )}
              />
            </div>
          </div>
          {props.withCost && (
            <div className="col-4">
              <label>Shipping Cost</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className={`input-group-text dollar-icon bg-white`}>
                    $
                  </span>
                </div>
                <input
                  {...register("cost")}
                  min={0}
                  step="any"
                  type="number"
                  className="form-control border-start-0"
                  onBlur={(e) => setValue("cost", formatInput(e.target.value))}
                />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex" style={{ marginLeft: "-15px" }}>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={clearForm}
          >
            Reset
          </button>
          <button type="submit" disabled={!isDirty} className="btn btn-primary">
            {props?.btn || "Submit"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SetupForm;
