import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const SortableItem = SortableElement(({ value }) => (
  <div
    style={{
      opacity: 1,
      visibility: "visible",
      zIndex: 100000,
      cursor: "move",
    }}
  >
    <span>&#x2637;</span>
    {value}
  </div>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div className="re-order-columns-container">
      {items.map((value, index) => (
        <div className="re-order-element">
          <SortableItem
            key={`item-${value.headerName}`}
            index={index}
            value={value.headerName}
          />
        </div>
      ))}
    </div>
  );
});

const ColumnCustomizer = ({
  gridColumns,
  setGridColumns,
  showModal,
  setShowModal,
  headers,
}) => {
  const onSortEnd = ({ e: { oldIndex, newIndex }, index }) => {
    setGridColumns((prev) =>
      prev.map((column, i) => {
        if (i === index)
          return arrayMoveImmutable(gridColumns[i], oldIndex, newIndex);
        return column;
      })
    );
  };

  const onCheckBoxChange = (e, key, index) => {
    let obj = gridColumns[index].map((column) => {
      if (column.field === key) return { ...column, hide: !e.target.checked };
      return { ...column };
    });
    setGridColumns((prev) =>
      prev.map((column, i) => {
        if (i == index) return obj;
        return column;
      })
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      centered
      size="lg"
      dialogClassName={`customize-columns-popup ${
        gridColumns.length > 1 ? "modal-80w" : ""
      }`}
    >
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title>Customize Columns</Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className="ms-auto"
          role="button"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {gridColumns?.map((gridColumn, index) => {
            return (
              <div
                className={` ${
                  gridColumns.length === 1
                    ? "col-12"
                    : gridColumns.length === 2
                    ? "col-6"
                    : "col-4"
                }`}
              >
                <h5>
                  <b>{headers[index]}</b>
                </h5>
                <div className="px-2 my-2 py-2 border-top border-bottom">
                  <b>Show/Hide Columns</b>
                </div>
                {gridColumn.map((column) => {
                  return (
                    <div key={column.field} className="d-flex">
                      <input
                        type="checkbox"
                        className="me-3"
                        checked={!column.hide}
                        onChange={(e) =>
                          onCheckBoxChange(e, column.field, index)
                        }
                      />
                      <div>{column.headerName}</div>
                    </div>
                  );
                })}
                <div className="px-2 my-2 py-2 border-top border-bottom">
                  <b>Re-Order Columns</b>
                </div>
                <SortableList
                  items={gridColumn.filter((column) => !column.hide)}
                  onSortEnd={(e) => onSortEnd({ e: e, index: index })}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex ms-auto border-top-0">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ColumnCustomizer;
