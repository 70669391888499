import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Tabs, Tab, Modal, Badge } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import GeneralInfo from "./sections/GeneralInfo";
import AccountInfo from "./sections/AccountInfo";
import AdditionalInfo from "./sections/AdditionalInfo";
import ShippingInfo from "./sections/ShippingInfo";
import SalesOrders from "./sections/SalesOrders";
import Payments from "./sections/Payments";
import Notes from "components/note/Notes";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import {
  notificationMessage,
  checkIfAllowed,
  cloneObj,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { getCustomerSalesOrders } from "../SalesMarketingServices/salesMarketingServices";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { statusEnum } from "../../SalesOrder/StatusEnum";
import {
  getCustomerById,
  getCustomersInit,
  createCustomer,
  editCustomer,
  getCustomers,
  getCustomerReceipts,
} from "./Customers.services";
import {
  CustomerAccount,
  CustomerInfoDTO,
  CustomerInitData,
  CustomerShippingInfo,
} from "./Customers.models";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  LOAD_FAIL_MSG,
  REQUIRED_FIELDS_MSG,
} from "constants/NotificationMsgs";
import { CustomerPayment } from "./Customers.models";
import { Note } from "components/note/note.model";
import DebitCredits from "./sections/DebitCredits";
import { getInvoicesForCustomer } from "pages/Invoices/InvoicesServices/InvoicesServices";
import { InvoiceEntry } from "pages/Invoices/Invoices.model";
import { QueryResult } from "components/Common.models";
import CustomerModal from "./sections/CustomerModal";
import HubModal from "./sections/HubModal";
import { getBaseCurrency } from "components/currency";

const CustomerDetails = ({ match }) => {
  const [currencySymbol, setCurrencySymbol] = useState("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const queryClient = useQueryClient();

  /** ****************************************************************
   * States - global
   * ****************************************************************/
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [showTypeModal, setShowTypeModal] = useState<boolean>(false);
  const [showHubModal, setShowHubModal] = useState<boolean>(false);
  // const [initData, setInitData] = useState<CustomerInitData>(null);
  const [customersList, setCustomersList] = useState<CustomerAccount[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("GeneralInfo");
  const [shippingData, setShippingData] = useState<CustomerShippingInfo[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [initialNotes, setInitialNotes] = useState<Note[]>([]);

  /** ****************************************************************
   * Used to fetch action (create/edit customer)
   * ****************************************************************/

  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsDisabled(true);
    }
  }, [id]);

  const methods = useForm<CustomerInfoDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isActive: true,
      creditScore: undefined,
      shippingInfos: [],
      notes: [],
      defaultShippingInfo: { isDefault: true },
    },
  });

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = methods;

  /** ****************************************************************
   * Populate init state
   * ****************************************************************/

  const {
    isSuccess: isSuccessInit,
    data: initData,
    refetch,
  } = useQuery<CustomerInitData>("get_initData", getCustomersInit);

  const [customerDetails, setCUstomerDetails] = useState<CustomerInfoDTO>(null);

  const getCustomerDetails = useMutation(getCustomerById, {
    async onSuccess(result) {
      if (result) {
        setCUstomerDetails(result);
        result.defaultShippingInfo = result.shippingInfos.find(
          (item) => item.isDefault
        );
        result.shippingInfos = result.shippingInfos.filter(
          (item) => !item.isDefault
        );
        result.shippingInfos.forEach((item, index) => {
          item.index = index;
        });
        setNotes(result.notes);
        let shipData = cloneObj(result.shippingInfos) as CustomerShippingInfo[];
        setShippingData(shipData);
        reset(result);
      }
    },
    async onError(err) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} customer\'s data.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.customers);
    },
  });

  const [customerDebitCredits, setCustomerDebitCredits] =
    useState<InvoiceEntry[]>(null);

  const [customerReceipts, setCustomerReceipts] =
    useState<CustomerPayment[]>(null);

  const getCustomerReceiptsList = useMutation(getCustomerReceipts, {
    async onSuccess(result) {
      if (result) {
        setCustomerReceipts(result?.data);
      }
    },
    async onError(err) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} customer\'s receipts.`,
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const getDebitsCredits = useMutation(getInvoicesForCustomer, {
    async onSuccess(result) {
      if (result) {
        setCustomerDebitCredits(result.data);
      }
    },
    async onError(err) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} customer\'s receipts.`,
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    if (id && initData) {
      getCustomerDetails.mutate(id);
    }
    if (!id && initData) {
      if (initData?.hubs?.length === 1)
        setValue("defaultHubId", initData.hubs[0].locationId);
    }
  }, [id, initData]);

  useEffect(() => {
    if (id && isSuccessInit) {
      getCustomerReceiptsList.mutate(id);
      getDebitsCredits.mutate(id);
    }
  }, [id, isSuccessInit]);

  /** ****************************************************************
   * Populate data state
   * ****************************************************************/
  useQuery<QueryResult<CustomerAccount>, ErrorResponse>(
    "get_customers",
    getCustomers,
    {
      onSuccess: (res) => {
        setCustomersList(res.data);
      },
    }
  );

  /** ****************************************************************
   * Form buttons
   * ****************************************************************/

  const onBackClick = () => {
    if (isDirty) setOpenConfirmModal(true);
    else history.push(AppUrls.customers);
  };

  const cancelForm = () => {
    setNotes(initialNotes);
    reset();
    setActiveTab("GeneralInfo");
    const shipData = cloneObj(getValues("shippingInfos"));
    setShippingData(shipData);
    if (id) {
      setIsDisabled(true);
    }
  };

  const onEditClick = () => {
    setIsDisabled(false);
  };

  /** ****************************************************************
   * Create / Edit form submit
   * ****************************************************************/

  const addCustomer = useMutation(createCustomer, {
    async onSuccess(res) {
      let notification = {
        variant: "success",
        msg: `Customer ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(`${AppUrls.customers}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding customers"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateCustomer = useMutation(editCustomer, {
    async onSuccess(result) {
      let notification = {
        variant: "success",
        msg: `Customer ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsDisabled(true);
      reset(result);
      queryClient.invalidateQueries();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem editing customers"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = async (values: CustomerInfoDTO) => {
    if (values.closedDate && values.closedDate < values.openedDate) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(
          { response: { data: "" } },
          "Closed date cannot be less then opened date."
        ),
      };
      appDispatch({ type: "notification", value: notification });
      return;
    }
    let shippingInfoesData: CustomerShippingInfo[] = values.shippingInfos
      ? cloneObj(values.shippingInfos)
      : [];
    //let defaultShippingInfo = values.shippingInfos.find(item => item.isDefault);

    shippingInfoesData.push(values.defaultShippingInfo);

    if (!id) {
      addCustomer.mutate({
        ...values,
        shippingInfos: shippingInfoesData,
        startingBalance: values.startingBalance
          ? parseFloat(
              values.startingBalance?.toString().replace(/,/g, "")
            ).toString()
          : "0",
      });
    } else {
      updateCustomer.mutate({
        ...values,
        shippingInfos: shippingInfoesData,
        customerAccountId: id,
        notes,
        creditLimit: values.creditLimit
          ? parseFloat(
              values.creditLimit?.toString().replace(/,/g, "")
            ).toString()
          : null,
      });
    }
  };

  const onNoteAdd = (data) => {
    setInitialNotes(cloneObj(notes));
    let allNotes = [...notes];
    allNotes.push({
      ...data,
      createdDate: new Date(),
      id:
        allNotes.length > 0
          ? Math.max(...allNotes.map((o) => o.noteId || o.id)) + 1
          : 0,
    });
    setNotes(allNotes);
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onNoteUpdate = (data) => {
    let allNotes = [...notes];
    allNotes = allNotes.map((note) => {
      if ((note.id || note.noteId) === (data.id || data.noteId))
        return { ...data };
      return { ...note };
    });
    setNotes(allNotes);
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onNoteDelete = (data) => {
    let allNotes = [...notes];
    allNotes = allNotes.filter(
      (note) => (note.id || note.noteId) !== (data.id || data.noteId)
    );
    setNotes(allNotes);
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const [customerSalesOrder, setCustomerSalesOrder] = useState([]);

  const sales_orders__columns = [
    {
      field: "orderNumber",
      headerName: "Sales order",
    },
    {
      field: "shipVia",
      headerName: "Ship. method",
    },
    {
      field: "hubKey",
      headerName: "Location",
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
    },
    {
      field: "orderAmount",
      headerName: "SO Amount",
      valueGetter: (params) => {
        return (
          baseCurrency +
          " " +
          `${params.data?.orderAmount?.toFixed(2) ?? "0.00"}`
        );
      },
    },
    {
      field: "taxAmount",
      headerName: "Tax Amount",
    },
    {
      field: "shipAmount",
      headerName: "Ship Amount",
      valueGetter: (params) => {
        return (
          currencySymbol + `${params.data?.shipAmount?.toFixed(2) ?? "0.00"}`
        );
      },
    },
    {
      field: "printed",
      headerName: "Printed?",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => (params.value ? statusEnum[params.value] : ""),
    },
    {
      field: "origin",
      headerName: "Origin",
    },
  ];

  const getSalesOrders = useMutation(getCustomerSalesOrders, {
    async onSuccess(response) {
      setCustomerSalesOrder(response);
    },
  });

  useEffect(() => {
    if (id) getSalesOrders.mutate(id);
  }, [id]);

  const getCustomerSalesOrdersLength = () => {
    return customerSalesOrder ? customerSalesOrder?.length : "";
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        getCustomerReceiptsList.isLoading ||
        getCustomerDetails.isLoading ||
        addCustomer.isLoading ||
        updateCustomer.isLoading,
    });
  }, [
    appDispatch,
    getCustomerReceiptsList.isLoading,
    getCustomerDetails.isLoading,
    addCustomer.isLoading,
    updateCustomer.isLoading,
  ]);

  const onTypeCreated = (id) => {
    setValue("accountTypeId", id, { shouldDirty: true });
    refetch();
    setShowTypeModal(false);
  };

  const onHubCreated = (id) => {
    setValue("defaultHubId", id, { shouldDirty: true });
    refetch();
    setShowHubModal(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              {id
                ? `Customer ${
                    getValues("accountNumber")
                      ? getValues("accountNumber")
                      : "ID"
                  }`
                : "Create Customer"}
            </div>
            <div>
              {checkIfAllowed(appState.allowedUrls, "edit", match.path) &&
                id &&
                isDisabled && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onEditClick}
                  >
                    Edit
                  </button>
                )}
              {(!id || (id && !isDisabled)) && (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-primary no-border ${
                      !isDirty && !id && "text-muted"
                    }`}
                    disabled={!isDirty && !id}
                    onClick={cancelForm}
                  >
                    Reset{" "}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={!isDirty}
                  >
                    {id ? "Save Changes" : "Create"}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <AccountInfo
                id={id}
                isDisabled={isDisabled}
                accTypes={initData?.accountTypes}
                priceLevels={initData?.priceLevels}
                showTypeModal={setShowTypeModal}
              />
              <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e)}>
                <Tab
                  eventKey="GeneralInfo"
                  title={<div className="tab-inner-title">General Info</div>}
                >
                  <GeneralInfo
                    id={id}
                    customerDetails={customerDetails}
                    isDisabled={isDisabled}
                    representatives={initData?.representatives}
                    markets={initData?.markets}
                    regions={initData?.regions}
                    shippingMethods={initData?.shippingMethods}
                    hubsList={initData?.hubs}
                    showHubModal={setShowHubModal}
                  />
                </Tab>
                <Tab
                  eventKey="AdditionalInformation"
                  title={
                    <div className="tab-inner-title">
                      Additional Information
                    </div>
                  }
                >
                  <AdditionalInfo
                    id={id}
                    customerDetails={customerDetails}
                    isDisabled={isDisabled}
                    representatives={initData?.representatives}
                    creditTerms={initData?.creditTerms}
                    languages={initData?.languages}
                    customers={customersList}
                  />
                </Tab>
                <Tab
                  eventKey="ShippingInfoes"
                  title={<div className="tab-inner-title">Shipping Info</div>}
                >
                  <ShippingInfo
                    isDisabled={isDisabled}
                    shippingData={shippingData}
                    onDataUpdated={(data: CustomerShippingInfo[]) =>
                      setShippingData(data)
                    }
                  />
                </Tab>
                {id && (
                  <Tab
                    eventKey="salesOrders"
                    title={
                      <div className="tab-inner-title">
                        Sales Orders
                        <Badge pill className="text-secondary ms-2">
                          {getCustomerSalesOrdersLength()}
                        </Badge>
                      </div>
                    }
                    disabled={!id}
                  >
                    <SalesOrders
                      salesOrdersColumns={sales_orders__columns}
                      customerSalesOrders={customerSalesOrder}
                      setCustomerSalesOrder={setCustomerSalesOrder}
                      initialSalesOrder={customerSalesOrder}
                      customerId={id}
                    />
                  </Tab>
                )}
                {id && (
                  <Tab
                    eventKey="debitcredits"
                    title={
                      <div className="tab-inner-title">
                        Debit/Credit
                        <Badge pill className="text-secondary ms-2">
                          {customerDebitCredits?.length}
                        </Badge>
                      </div>
                    }
                  >
                    <DebitCredits data={customerDebitCredits} id={id} />
                  </Tab>
                )}
                {id && (
                  <Tab
                    eventKey="payments"
                    title={
                      <div className="tab-inner-title">
                        Payments
                        <Badge pill className="text-secondary ms-2">
                          {customerReceipts?.length}
                        </Badge>
                      </div>
                    }
                  >
                    <Payments
                      customerReceipts={
                        customerReceipts?.length > 0 ? customerReceipts : []
                      }
                    />
                  </Tab>
                )}
                {id && (
                  <Tab
                    eventKey="customerNotes"
                    title={
                      <div className="tab-inner-title">
                        Notes
                        <Badge pill className="text-secondary ms-2">
                          {notes.length}
                        </Badge>
                      </div>
                    }
                  >
                    <Notes
                      notes={notes}
                      onNoteDelete={onNoteDelete}
                      onNoteUpdate={onNoteUpdate}
                      onNoteAdd={onNoteAdd}
                      isDisabled={isDisabled}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <CustomerModal
        showModal={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        onConfirm={onTypeCreated}
      />
      <HubModal
        showModal={showHubModal}
        onClose={() => setShowHubModal(false)}
        onConfirm={onHubCreated}
      />
      <Modal
        show={openConfirmModal}
        onHide={setOpenConfirmModal}
        centered
        className="discard-modal"
      >
        <Modal.Header>
          <Modal.Title>Discarding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to leave?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            className="btn btn-outline-primary"
            onClick={() => setOpenConfirmModal(false)}
          >
            No, stay
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              history.push(AppUrls.customers);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerDetails;
