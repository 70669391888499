import Accordion from 'react-bootstrap/Accordion';
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DeleteCustomizedReportsAPI, getListOfCustomizedReportsAPI, getListOfReportsAPI, ModifyReportFavsAPI } from './services/ReportServices';
import DispatchContext from "context/DispatchContext";

import './style/common.scss'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

const ReportsTab = ({ data, ListOfFavReportsM, dataF, setIsReportDeleted, reportsDisc, isCustom }) => {

    const history = useHistory()

    const appDispatch = useContext(DispatchContext);
    const inputRef = useRef(null)
    const reportId = useRef(null)
    const [searchResults, setSearchResults] = useState([])
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const redirectToReport = (reportItem) => {
        let componentId = reportItem.componentId
        let customizedComponentId = reportItem.customizedReportId
        let params = `?id=${componentId}`
        if (customizedComponentId != 0) params += `?custom=${customizedComponentId}`
        history.push(`${reportItem?.url}${params}`)
    }

    const ListOfReportsM = useMutation(getListOfReportsAPI, {
        onSuccess(data) {
            let results = []
            data.forEach(element => {
                element.reports.forEach(report => {
                    results.push({ ...report, url: reportsDisc[report.key] })
                });
            });
            setSearchResults(results)
        },
    })

    const ListOfCustReportsM = useMutation(getListOfCustomizedReportsAPI, {
        onSuccess(data) {
            let results = []
            data.forEach(element => {
                element.reports.forEach(report => {
                    results.push({ ...report, url: reportsDisc[report.key] })
                });
            });
            setSearchResults(results)
        }
    })

    const changeRoute = (component) => {
        if (isCustom)
            history.push(`${component.url}?id=${component.componentId}&custom=${component.customizedReportId}`)
        else
            history.push(`${component.url}?id=${component.componentId}`)
    }

    const ModifyReportFavsM = useMutation(ModifyReportFavsAPI, {
        onSuccess() {
            ListOfFavReportsM.mutate()
        }
    })

    const ModifyFavReport = (id) => {
        ModifyReportFavsM.mutate(id)
    }

    const DeleteReportM = useMutation(DeleteCustomizedReportsAPI, {
        onSuccess() {
            let notification = {
                variant: "success",
                msg: "Report deleted successfully"
            };
            appDispatch({ type: "notification", value: notification });
            setIsReportDeleted(true)
        }
    })

    const DeleteReport = () => {
        setShowConfirmModal(false)
        DeleteReportM.mutate(reportId.current)
    }

    const onDelete = (id) => {
        reportId.current = id
        setShowConfirmModal(true)
    }

    let timeout
    const onSearch = (e) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            if (isCustom)
                ListOfCustReportsM.mutate(e.target.value)
            else
                ListOfReportsM.mutate(e.target.value)
        }, 1000);
    }

    useEffect(() => {
        appDispatch({ type: "loading", value: ModifyReportFavsM.isLoading })
    }, [ModifyReportFavsM.isLoading])

    return (
        <>
            <div className="row mb-4">
                <div className="col-md-3">
                    <div className="input-group" style={{ zIndex: 1000 }}>
                        <span className="report-search-icon">
                            <i className="fa fa-search"></i>
                        </span>
                        <input
                            className="form-control ps-5"
                            type="search"
                            placeholder="Search"
                            onChange={onSearch}
                            ref={inputRef}
                        />
                        {inputRef.current?.value.length > 0 &&
                            <div className='position-absolute top-100 ms-0 mt-1 bg-white w-100 p-1 border'>
                                {searchResults.length > 0 ?
                                    searchResults.map(component => (
                                        <div key={component.componentId}>
                                            <button className='btn w-100 bg-white text-start route-btn ms-0' onClick={() => changeRoute(component)}>{component.name}</button>
                                        </div>
                                    ))
                                    :
                                    <>
                                        {!ListOfReportsM.isLoading && !ListOfCustReportsM.isLoading &&
                                            <div className='p-2'>No Results Found </div>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Accordion defaultActiveKey="1" className="mb-4 reports-card">
                <Accordion.Item eventKey={"1"}>
                    <Accordion.Header>
                        <b>Favorite</b>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className='row'>
                            {data?.map((cat, key) => {
                                return (
                                    <>
                                        {cat?.reports?.filter(rep => dataF?.includes(rep.reportCategoryId)).map(reportItem => {
                                            return (
                                                <div key={key} className="col-6">
                                                    <div className='p-3' key={reportItem.reportCategoryId}>
                                                        <div className='d-flex justify-content-between pe-5 me-5'>
                                                            <div className='report-link' onClick={() => redirectToReport(reportItem)}>
                                                                {reportItem.name}
                                                            </div>
                                                            <div className='d-flex justify-content-between pe-5 me-5' onClick={() => ModifyFavReport(reportItem.reportCategoryId)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={dataF?.find(f => f === reportItem.reportCategoryId) ? "#2CA01C" : "black"} className="bi bi-star-fill is-favorite report-link me-5" viewBox="0 0 16 16">
                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {data?.map((cat, key) => {
                return (
                    <Accordion defaultActiveKey={key.toString()} key={key} className="mb-4 reports-card">
                        <Accordion.Item eventKey={key.toString()}>
                            <Accordion.Header>
                                <b>{cat.category}</b>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='row'>
                                    {cat?.reports?.map(reportItem => {
                                        return (
                                            <div className='col-6' key={reportItem.reportCategoryId}>
                                                <div className='p-3'>
                                                    <div className='d-flex justify-content-between pe-5 me-5'>
                                                        <div className='report-link' onClick={() => redirectToReport(reportItem)}>
                                                            {reportItem.name}
                                                        </div>
                                                        <div className='d-flex justify-content-between pe-5 me-5'>
                                                            <div onClick={() => ModifyFavReport(reportItem.reportCategoryId)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={dataF?.find(f => f === reportItem.reportCategoryId) ? "#2CA01C" : "black"} className={`bi bi-star-fill report-link is-not-fav me-5 ${dataF?.find(f => f === reportItem.reportCategoryId) && 'is-favorite'}`} style={{ color: "red" }} viewBox="0 0 16 16">
                                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                                                </svg>
                                                            </div>
                                                            {reportItem.customizedReportId ? (
                                                                <div onClick={() => onDelete(reportItem.customizedReportId)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" className="bi bi-trash report-link" viewBox="0 0 16 16">
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                    </svg>
                                                                </div>
                                                            ) : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )
            })}
            <ConfirmationModal
                title="Delete Report"
                message="Are you sure you want to delete this report?"
                showModal={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={DeleteReport}
                cancelBtnTitle="No"
                confirmBtnTitle="Yes"
                type="confirmation-danger"
            />
        </>
    )
}

export default ReportsTab