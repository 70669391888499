import { useEffect, useState, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { ReactComponent as AlertIcon } from "img/alert.svg";
import { useForm, FormProvider, Controller } from "react-hook-form";
import InvoiceInfo from "./Invoice Tabs/InvoiceInfo";
import InvoiceItems from "./Invoice Tabs/InvoiceItems";
import { Tabs, Tab } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import DispatchContext from "context/DispatchContext";
import SelectionModal from "components/SelectionModal/SelectionModal";
import {
  getCustomerById,
  getCustomers,
} from "pages/SalesMarketing/Customers/Customers.services";
import { GridColumns } from "components/Common.models";
import { createInvoice } from "./InvoicesServices/InvoicesServices";
import {
  formatNumber,
  getCurrenciesListFromExchangeRateList,
  notificationMessage,
} from "global/helpers";
import {
  CustomerAccount,
  InvoiceItem,
  CreateInvoiceI,
  itemI,
} from "./Invoices.model";
import { CustomerInfoDTO } from "pages/SalesMarketing/Customers/Customers.models";
import {
  CREATE_SUCCESS_MSG,
  REQUIRED_FIELDS_MSG,
} from "constants/NotificationMsgs";
import { addedSerials } from "./Invoices.model";
import Lookup from "elements/Lookup";
import ShippingMethodModal from "pages/SystemSetup/ShippingMethodModal";
import Select from "react-select";
import {
  getCurrency,
  getExchangeRate,
  getLatestRate,
} from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";

const CreateInvoice = () => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);

  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerAccount>();
  const [customerDetails, setCustomerDetails] = useState<CustomerInfoDTO>();
  const [hubId, setHubId] = useState<number>();
  const [addedItems, setAddedItems] = useState<InvoiceItem[]>([]);
  const [addedSerials, setAddedSerials] = useState<addedSerials[]>([]);
  const [showShippingModal, setShowShippingModal] = useState<boolean>(false);
  const [methodsCounter, setMethodsCounter] = useState<number>(0);

  const accountNumberColumns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Customer No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "accountType",
      headerName: "Customer Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty },
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    register,
  } = methods;

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    "customersList",
    getCustomers
  );

  const { data: currencies } = useQuery("currencies", getExchangeRate, {
    select(data) {
      const currencyCodes = data?.map((currency) => ({
        currencyCode: currency?.currencyCode,
      }));
      var currencies = getCurrenciesListFromExchangeRateList(currencyCodes);
      return currencies;
    },
  });

  const getRate = useMutation(getLatestRate, {
    async onSuccess(data) {
      if (data) setValue("currencyExchangeRate", data);
      else setValue("currencyExchangeRate", null);
    },
  });

  const getDetails = useMutation(getCustomerById, {
    async onSuccess(response) {
      setCustomerDetails(response);
      setHubId(response.defaultHubId);
    },
  });

  const addInvoice = useMutation(createInvoice, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `Invoice ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push({
        pathname: `${AppUrls.invoices_list}/${response.data.invoiceId}`,
        state: { isNew: true },
      });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingCustomers || getDetails.isLoading,
    });
  }, [isLoadingCustomers, getDetails.isLoading]);

  useEffect(() => {
    if (selectedCustomer) {
      setValue("accountNumber", selectedCustomer["accountNumber"], {
        shouldDirty: true,
      });
      setValue("targetId", selectedCustomer["customerAccountId"]);
      getDetails.mutate(selectedCustomer.customerAccountId);
      setOpenSelection(false);
      setSelectedCustomer(null);
      setValue("shipTo", "");
      setValue("shippingInfoId", "");
    }
  }, [selectedCustomer]);

  const onBackClick = () => {
    history.push(AppUrls.invoices_list);
  };

  const onCancel = () => {
    reset();
    setCustomerDetails(null);
    setAddedItems([]);
    setHubId(null);
  };

  const onSubmit = (values) => {
    if (addedItems.length > 0) {
      let canSubmit: boolean = true;
      let itemsToAdd = [];
      for (let i of addedItems) {
        let serialsToAdd = addedSerials
          ?.filter((j) => j.itemId === i.itemId)
          ?.map((k) => k.serial);
        if (i.isSerialized) {
          itemsToAdd.push({
            itemId: i.itemId,
            quantity: Number(i.invoiceQty),
            price: Number(i.invoicePrice),
            description: i.itemDescription,
            serials: serialsToAdd,
          });
        } else {
          itemsToAdd.push({
            itemId: i.itemId,
            quantity: Number(i.invoiceQty),
            price: Number(i.invoicePrice),
            description: i.itemDescription,
            serials: [],
          });
        }
        if (i.isSerialized && serialsToAdd?.length !== Number(i.invoiceQty)) {
          canSubmit = false;
          break;
        }
      }
      if (canSubmit) {
        let items: itemI[] = itemsToAdd;
        let newData: CreateInvoiceI = {
          targetId: values.targetId,
          invoiceType: 1,
          hubId: hubId,
          orderDate: new Date(),
          shippingInfoId: values.shippingInfoId ?? null,
          shippingMethodId: values.shippingMethodId?.value ?? null,
          items: items,
          remarks: values.remarks !== "undefined" ? values.remarks : null,
          currencyId: values.currencyId,
          currencyExchangeRate: values.currencyExchangeRate,
        };
        addInvoice.mutate(newData);
      } else {
        let notification = {
          variant: "danger",
          msg: "The number of added serials should match the selected quantity",
        };
        appDispatch({ type: "notification", value: notification });
      }
    } else {
      let notification = {
        variant: "danger",
        msg: "Please add items to create invoice",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const onTypeCreated = (method) => {
    setValue("shippingMethodId", method, { shouldDirty: true });
    setShowShippingModal(false);
    setMethodsCounter((counter) => counter + 1);
  };

  const onCurrencyChange = (e) => {
    if (e) getRate.mutate(e);
    else setValue("currencyExchangeRate", null);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Create Immediate Invoice
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={addedItems.length === 0}
              >
                Create
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="row gx-5">
                    <div className="col-sm-6">
                      <label>
                        Customer No. <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="targetId"
                        isRequired={true}
                        initialData={customers?.data?.map((item) => ({
                          ...item,
                          id: item.customerAccountId,
                          name: item.accountNumber,
                        }))}
                        onSelection={setSelectedCustomer}
                        inputValue={getValues("accountNumber")}
                      />
                    </div>
                    <div className="col-sm-3">
                      <label>Invoice No.</label>
                      <span className="fst-italic text-muted">Invoice No.</span>
                    </div>
                    <div className="col-sm-3">
                      <label>Invoice Date</label>
                      <div>{new Date().toLocaleDateString()}</div>
                    </div>
                  </div>
                </div>
                {customerDetails && customerDetails["balance"] < 0 && (
                  <div className="col-lg-6 col-sm-12 d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center ">
                      <AlertIcon className="me-3" />
                      <div className="text-danger">
                        Customer has unaplied credits on account
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-12">
                  <div className="row mt-5 px-2">
                    <div className="col-lg-4 col-sm-12">
                      <div className="form-group">
                        <label>Currency</label>
                        <Controller
                          control={control}
                          name="currencyId"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isSearchable={true}
                              menuPlacement="auto"
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption?.value);
                                onCurrencyChange(selectedOption?.value);
                              }}
                              value={
                                currencies?.find(
                                  (opt) => opt?.value === value
                                ) || null
                              }
                              options={currencies}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                      <div className="form-group">
                        <label>Exchange Rate</label>
                        <input
                          className="form-control"
                          {...register("currencyExchangeRate")}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="row my-5">
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>Balance</div>
                        <div>
                          {customerDetails &&
                          customerDetails["ledger"]["balance"] ? (
                            <span
                              className={`${
                                customerDetails["ledger"]["balance"] < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(
                                customerDetails["ledger"]["balance"]
                              )}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $ 0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>Credit Limit</div>
                        <div>
                          {customerDetails &&
                          Number(customerDetails["creditLimit"]) ? (
                            <span
                              className={`${
                                Number(customerDetails["creditLimit"]) < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(
                                Number(customerDetails["creditLimit"])
                              )}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $ 0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>On Order</div>
                        <div>
                          {customerDetails && customerDetails["onSO"] ? (
                            <span
                              className={`${
                                customerDetails["onSO"] < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(customerDetails["onSO"])}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $ 0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>Available</div>
                        <div>
                          {customerDetails && customerDetails["balance"] ? (
                            <span
                              className={`${
                                customerDetails["balance"] < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(customerDetails["balance"])}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $ 0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs defaultActiveKey="InvoiceInfo">
                <Tab
                  eventKey="InvoiceInfo"
                  title={<div className="tab-inner-title">Invoice Info</div>}
                >
                  <InvoiceInfo
                    isEditing={false}
                    isCreating={true}
                    customerDetails={customerDetails}
                    setHubId={setHubId}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    methodsCounter={methodsCounter}
                    setShowShippingModal={setShowShippingModal}
                  />
                </Tab>
                <Tab
                  eventKey="InvoiceItems"
                  title={
                    <div className="tab-inner-title">
                      Invoice Items
                      <Badge pill className="text-secondary ms-2">
                        {addedItems.length}
                      </Badge>
                    </div>
                  }
                >
                  <InvoiceItems
                    isEditing={false}
                    isCreating={true}
                    hubId={hubId}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    customer={customerDetails}
                    addedSerials={addedSerials}
                    setAddedSerials={setAddedSerials}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <ShippingMethodModal
        showModal={showShippingModal}
        onClose={() => setShowShippingModal(false)}
        onConfirm={onTypeCreated}
      />
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={customers?.data}
        columns={accountNumberColumns}
        setRowClicked={setSelectedCustomer}
      />
    </>
  );
};

export default CreateInvoice;
