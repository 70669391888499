import backArrow from "img/back-arrow.svg";
import { useHistory, useParams } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import { useQuery } from "react-query";
import { dateFormmaterNoTime } from "global/helpers";
import AG from "elements/AG";
import SectionTitle from "elements/SectionTitle";
import { getReceiveInvoiceById } from "./Vendors.services";

const ReceiptInvoiceDetails = () => {

    const history = useHistory()

    const { id } = useParams();

    const { data } = useQuery("allEod", () => getReceiveInvoiceById(id));

    const onBackClick = () => {
        history.push({ pathname: AppUrls.receipt_invoice_payment })
    }

    const columns = [
        {
            field: "invoiceNumber",
            headerName: "Invoice Number"
        },
        {
            field: "invoiceBalance",
            headerName: "Invoice Balance",
            cellRenderer: (params) => `$${params.data?.total - params.data?.paidAmount}`,
        },
        {
            field: "paidAmount",
            headerName: "Invoice Paid Amount",
            cellRenderer: (params) => `$${params.value}`,

        },
        {
            field: "orderDate",
            headerName: "Invoice Paid Date",
            cellRenderer: (params) => dateFormmaterNoTime(params.value),
        },
    ]

    return (
        <div className="static-page">
            <div className="page-title page-title-editable">
                <div className="back-btn " onClick={onBackClick}>
                    <img src={backArrow} alt="back arrow" />
                    Receipt No. {data?.receiptNumber}
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="right-side-mid">
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Vendor No</label>
                                        <p>{data?.receiveInvoice?.target?.vendorNumber ?? <span className="text-muted fst-italic">Vendor No</span>}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Company</label>
                                        <p>{data?.receiveInvoice?.target?.company ?? <span className="text-muted fst-italic">Company</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 border d-flex justify-content-between p-3 me-2">
                            <span
                                className={
                                    data?.receiveInvoice?.target?.balance <= 0
                                        ? "text-danger"
                                        : "text-success"
                                }
                            >
                                Current Balance
                            </span>
                            <span
                                className={
                                    data?.receiveInvoice?.target?.balance <= 0
                                        ? "text-danger"
                                        : "text-success"
                                }
                            >
                                {`$ ${data?.receiveInvoice?.target?.balance ?? "0.00"}`}
                            </span>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="left-side-mid">
                            <div className="row">
                                <div className="col border d-flex justify-content-between p-3 me-2">
                                    <span
                                        className={
                                            data?.paymentAmount <= 0
                                                ? "text-danger"
                                                : "text-success"
                                        }
                                    >
                                        Payment
                                    </span>
                                    <span
                                        className={
                                            data?.paymentAmount  <= 0
                                                ? "text-danger"
                                                : "text-success"
                                        }
                                    >
                                        {`$ ${data?.paymentAmount ?? "0.00"}`}
                                    </span>
                                </div>
                                <div className="col border invisible d-flex justify-content-between p-3 me-2">
                                    <span
                                        className={
                                            data?.unapplied <= 0
                                                ? "text-danger"
                                                : "text-success"
                                        }
                                    >
                                        Un-Appiled
                                    </span>
                                    <span
                                        className={
                                            data?.unapplied <= 0
                                                ? "text-danger"
                                                : "text-success"
                                        }
                                    >
                                        {`$ ${data?.unapplied ?? "0.00"}`}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div>
                                    <label>Remark</label>
                                    <p>{data?.receiveInvoice?.remarks ?? <span className="text-muted fst-italic">Remark</span>}</p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-mid">
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Check No</label>
                                        <p>{data?.checkNumber ?? <span className="text-muted fst-italic">Check No</span>}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Payment Date</label>
                                        <p>{dateFormmaterNoTime(data?.paymentDate) ?? <span className="text-muted fst-italic">Payment Date</span>}</p>
                                    </div>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <SectionTitle title="Received Invoices" editable={false} />
                            <div className="h-250">
                                <AG
                                    data={[data?.receiveInvoice]}
                                    columns={columns}
                                    autoColumns={true}
                                    pagination={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReceiptInvoiceDetails
