import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import "./SideAccordion.scss";
import { ReactComponent as SideIcon } from "img/sideicon.svg";
import { ReactComponent as CaretUp } from "img/caretup.svg";

const SideAccordion = ({ entries }) => {
  const [activeKey, setActiveKey] = useState<string>();
  const [activeSubKey, setActiveSubKey] = useState<string>();
  const [activePage, setActivePage] = useState<string>();
  const [activeSubPage, setActiveSubPage] = useState<string>();

  const location = useLocation();

  useEffect(() => {
    const getCurrentPage = () => {
      for (let subMenuItem of entries)
        for (let sublink of subMenuItem.children)
          if (location.pathname.includes(sublink.to)) return subMenuItem;
      return null;
    };
    setActiveKey(getCurrentPage()?.to);
    setActivePage(getCurrentPage()?.to);
    let sub = "/" + location.pathname.split("/")[2];
    if (sub === "/operations") sub = "/accounting/operations";
    if (sub && sub != "undefined") {
      setActiveSubPage(sub);
      setActiveSubKey(sub);
    }
  }, [location, entries]);

  const changeActiveKey = (active) => {
    if (active !== activeKey) setActiveKey(active);
    else setActiveKey("0");
  };

  const changeActiveSubKey = (active) => {
    if (active !== activeSubKey) setActiveSubKey(active);
    else setActiveSubKey("0");
  };

  return (
    <Accordion
      defaultActiveKey="1"
      activeKey={activeKey}
      className="custom-accordion"
    >
      {entries.map((entry) => {
        return (
          <Accordion.Item eventKey={entry.to} key={entry.key}>
            {entry.children.filter(
              (c) => c.isMenuItem === true && c.isGranted === true
            ).length > 0 ? (
              <>
                <Accordion.Header
                  onClick={() => changeActiveKey(entry.to)}
                  className={`${
                    activeKey !== entry.to &&
                    activePage === entry.to &&
                    "collapsed-active"
                  }`}
                >
                  <SideIcon className="side-icon" />
                  <span className="side-menu-value py-1">{entry.value}</span>
                  <CaretUp className="ms-auto accordion-arrow" />
                </Accordion.Header>
              </>
            ) : (
              <div className="single-menu-entry">
                <NavLink to={entry.to}>
                  <SideIcon className="side-icon" />
                  <span className="side-menu-value">{entry.value}</span>
                </NavLink>
              </div>
            )}
            {entry.children.filter(
              (c) => c.isMenuItem === true && c.isGranted === true
            ).length > 0 && (
              <>
                <Accordion.Body>
                  {entry.children.map((sublink) => {
                    return (
                      <div
                        key={sublink.key + "_" + sublink.order}
                        className="link-container"
                      >
                        {sublink.to != "" && sublink.to != undefined && (
                          <>
                            {sublink.children.length === 0 ? (
                              <>
                                <NavLink
                                  className={`dropdown-item sub-item`}
                                  to={sublink.to ?? ""}
                                >
                                  <span className="inner-sub-item">
                                    {sublink.value}
                                  </span>
                                </NavLink>
                              </>
                            ) : (
                              <>
                                <Accordion
                                  defaultActiveKey="1"
                                  activeKey={activeSubKey}
                                  className="custom-accordion custom-accordion-inner"
                                >
                                  <Accordion.Item
                                    eventKey={sublink.to}
                                    key={sublink.key}
                                  >
                                    <Accordion.Header
                                      onClick={() =>
                                        changeActiveSubKey(sublink.to)
                                      }
                                      className={`${
                                        activeSubKey !== sublink.to &&
                                        activeSubPage === sublink.to &&
                                        "collapsed-active"
                                      }`}
                                    >
                                      <span className="side-menu-value">
                                        {sublink.value}
                                      </span>
                                      <CaretUp className="ms-auto accordion-arrow" />
                                    </Accordion.Header>
                                    {sublink.children.filter(
                                      (c) =>
                                        c.isMenuItem === true &&
                                        c.isGranted === true
                                    ).length > 0 && (
                                      <>
                                        <Accordion.Body>
                                          {sublink.children
                                            .filter((c) => c.isGranted === true)
                                            .sort((a, b) => a.order - b.order)
                                            .map((sublink2) => {
                                              return (
                                                <NavLink
                                                  key={sublink2.to ?? ""}
                                                  className={`dropdown-item sub-item`}
                                                  to={sublink2.to ?? ""}
                                                >
                                                  <span className="inner-sub-item">
                                                    {sublink2.value}
                                                  </span>
                                                </NavLink>
                                              );
                                            })}
                                        </Accordion.Body>
                                      </>
                                    )}
                                  </Accordion.Item>
                                </Accordion>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </Accordion.Body>
              </>
            )}
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default SideAccordion;
