import { Modal } from 'react-bootstrap';
import Close from 'img/close.svg';
import { fixTimezoneOffset, formatInput } from 'global/helpers';
import { useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Lookup from 'elements/Lookup';
import SelectionModal from 'components/SelectionModal/SelectionModal';
import { useMutation, useQuery } from 'react-query';
import { AddfixedAssetsAPI, getJournalId, getLeafs, getLedgers } from './AccountingServices';
import { LedgerTypeEnum } from './enum/AccountEnum';
import Select from "react-select";
import { Form } from 'react-bootstrap';
import DispatchContext from 'context/DispatchContext';

type Props = {
    showModal: boolean,
    onExit: () => void,
    onCreation: (n: number) => void,
    payment: number,
}

const FixedAssetModal = (props: Props) => {

    const appDispatch = useContext(DispatchContext);

    const [openExpenseAccountId, setOpenExpenseAccountId] = useState<boolean>(false)
    const [selectedExpenseAccountId, setSelectedExpenseAccountId] = useState(null)

    const {
        data: ledgers,
    } = useQuery("ledgers", getLedgers);

    const intervalOptions = [
        { label: "Daily", value: 1 },
        { label: "Weekly", value: 2 },
        { label: "Monthly", value: 3 },
        { label: "Yearly", value: 4 },
    ]

    const onExpenseAccountIdSelection = (e) => {
        setSelectedExpenseAccountId(e)
        setOpenExpenseAccountId(false)
        setValue("expenseAccountId", e.ledgerId, { shouldValidate: true })
    }

    const {
        data: paymentAccountsEpense,
    } = useQuery("expenses", () => getLedgers({ ledgerType: LedgerTypeEnum.Expenses }));

    const { data: leafs } = useQuery("leafs", () => getLeafs({ ledgerType: LedgerTypeEnum.AccountsPayable }));

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });

    const { formState: { isDirty, errors }, register, handleSubmit, reset, setValue, control } = methods

    const getIdAPI = useMutation(getJournalId, {
        onSuccess(data) {
            let notification = {
                variant: "success",
                msg: "Fixed Asset Added successfully"
            };
            appDispatch({ type: "notification", value: notification });
            props.onCreation(data)
        }
    })

    const submitAPI = useMutation(AddfixedAssetsAPI, {
        onSuccess(data) {
            let notification = {
                variant: "success",
                msg: "Fixed Asset Added successfully"
            };
            appDispatch({ type: "notification", value: notification });
            getIdAPI.mutate(data.fixedAssetId)
        }
    })

    useEffect(() => {
        if (props.payment)
            setValue("price", props.payment)
    }, [props.payment])

    const ledgersColumns = [
        {
            field: "accountNumber",
            headerName: "Account",
            resizable: true
        },
        {
            field: "accountName",
            headerName: "Account name",
            resizable: true
        },
        {
            field: "balance",
            headerName: "Balance",
            resizable: true
        },
        {
            field: "isDebit",
            headerName: "Debit",
            resizable: true,
            cellRenderer: (params) => params.value ? 'Yes' : 'No',
        }
    ];

    const onSubmit = (values) => {
        let obj = {
            description: values?.description,
            purchaseDate: fixTimezoneOffset(values?.purchaseDate),
            price: values?.price,
            depreciationPeriod: values?.depreciationPeriod?.value,
            accountingCategoryId: values?.accountingCategoryId?.value,
            depreciationPercent: values?.depreciationPercent,
            assetName: values?.assetName,
            buyingLedgerId: values?.ledgerId?.value,
            depreciationExpenseAccountId: values?.expenseAccountId
        }
        submitAPI.mutate(obj)
    }

    const onCancel = () => {
        reset()
        setSelectedExpenseAccountId(null)
    }

    return (
        <>
            <Modal show={props.showModal} centered size="lg" dialogClassName="modal-80w">
                <Modal.Header>
                    <Modal.Title>
                        <div className='mb-4'>
                            Add Fixed Asset
                        </div>
                    </Modal.Title>
                    <img
                        src={Close}
                        alt="close icon"
                        className=""
                        role="button"
                        onClick={props.onExit}
                    />
                </Modal.Header>
                <Modal.Body>
                    <>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                                <div className='d-flex justify-content-end'>
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary no-border"
                                        onClick={onCancel}
                                        disabled={!isDirty}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        disabled={!isDirty}
                                    >
                                        Create
                                    </button>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-group mb-4">
                                            <label>Asset Name <span className="text-danger">*</span></label>
                                            <input
                                                {...register("assetName", { required: true })}
                                                className={`form-control ${errors["assetName"] && 'required_field'}`}
                                            />
                                            {errors["assetName"] && <p className="text-danger">This field is required</p>}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Purchase Date<span className="text-danger">*</span></label>
                                            <Controller
                                                control={control}
                                                name="purchaseDate"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <DatePicker
                                                        showYearDropdown
                                                        dateFormatCalendar="MMMM"
                                                        yearDropdownItemNumber={15}
                                                        scrollableYearDropdown
                                                        onChange={onChange}
                                                        selected={value ? value : null}
                                                        minDate={new Date()}
                                                    />
                                                )}
                                            />
                                            {errors["purchaseDate"] && <p className="text-danger">This field is required</p>}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Price <span className="text-danger">*</span></label>
                                            <input
                                                {...register("price", { required: true })}
                                                className={`form-control ${errors["price"] && 'required_field'}`}
                                                disabled={true}
                                                onBlur={(e) => setValue("price", formatInput(e.target.value))}
                                            />
                                            {errors["price"] && <p className="text-danger">This field is required</p>}
                                        </div>

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Description <span className="text-danger">*</span></label>
                                            <input
                                                {...register("description", { required: true })}
                                                className={`form-control ${errors["description"] && 'required_field'}`}
                                            />
                                            {errors["description"] && <p className="text-danger">This field is required</p>}
                                        </div>

                                    </div>
                                    <div className="col-4">
                                        <div className='form-group'>
                                            <label>Expense Account <span className="text-danger">*</span></label>
                                            <Lookup
                                                onButtonClicked={() => setOpenExpenseAccountId(true)}
                                                inputName="expenseAccountId"
                                                isRequired={true}
                                                initialData={paymentAccountsEpense?.data?.map(item => ({ ...item, id: item.ledgerId, name: item.accountName }))}
                                                onSelection={onExpenseAccountIdSelection}
                                                inputValue={selectedExpenseAccountId?.accountName}
                                                hasError={errors['expenseAccountId'] ? true : false}
                                            />
                                            {errors && errors['expenseAccountId'] && <p className="text-danger">This field is Required</p>}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Depreciation Period <span className="text-danger">*</span></label>
                                            <Controller
                                                control={control}
                                                name="depreciationPeriod"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        onChange={onChange}
                                                        isClearable
                                                        value={value}
                                                        options={intervalOptions}
                                                        className={errors.depreciationPeriod && "required_field"}
                                                    />
                                                )}
                                            />
                                            {errors["depreciationPeriod"] && (
                                                <p className="text-danger">This field is required</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Depreciation % <span className="text-danger">*</span></label>
                                            <Form.Control
                                                {...register("depreciationPercent", {
                                                    required: {
                                                        value: true,
                                                        message: "This field is required"
                                                    },
                                                    valueAsNumber: true,
                                                    min: {
                                                        value: 0,
                                                        message: "The value must be between 0 and 100"
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "The value must be between 0 and 100"
                                                    }
                                                })}
                                                className={
                                                    errors["depreciationPercent"]
                                                        ? "form-control required_field"
                                                        : "form-control"
                                                }
                                                type="string"
                                            />
                                            {errors["depreciationPercent"] && (
                                                <p className="text-danger">
                                                    {errors["depreciationPercent"].message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className='form-group'>
                                            <label>Account Category <span className="text-danger">*</span></label>
                                            <Controller
                                                control={control}
                                                name="accountingCategoryId"
                                                rules={{ required: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        onChange={onChange}
                                                        isClearable
                                                        value={value}
                                                        options={leafs?.map(item => { return { label: item.name, value: item.categoryId } })}
                                                        className={errors.accountingCategoryId && "required_field"}
                                                    />
                                                )}
                                            />
                                            {errors['accountingCategoryId'] && <span className="text-danger">This field is required</span>}
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group">
                                            <label>Ledger <span className="text-danger">*</span></label>
                                            <Controller
                                                control={control}
                                                name="ledgerId"
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <Select
                                                        options={ledgers?.data.map(ledger => ({ ...ledger, label: ledger.accountName }))}
                                                        isSearchable={true}
                                                        value={value}
                                                        onChange={onChange}
                                                        isClearable={true}
                                                        className={`${errors["ledgerId"] && "required_field"}`}
                                                    />
                                                )}
                                            />
                                            {errors["ledgerId"] && (
                                                <p className="text-danger">This field is required</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormProvider>
                    </>
                </Modal.Body>
            </Modal>
            <SelectionModal
                modal="account"
                showModal={openExpenseAccountId}
                setShowModal={setOpenExpenseAccountId}
                data={paymentAccountsEpense?.data}
                columns={ledgersColumns}
                setRowClicked={onExpenseAccountIdSelection}
            />
        </>
    );
};

export default FixedAssetModal;