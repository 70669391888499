import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import backArrow from "img/back-arrow.svg";
import DispatchContext from "context/DispatchContext";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { useHistory, useLocation } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import { useReactToPrint } from "react-to-print";
import exportIcon from "img/export.svg";
import { dateFormmaterNoTime, fetchReportParamsQuery } from "global/helpers";
import { BillPaymentsReportAPI } from "./services/ReportServices";
import Filter from "./components/Filter";
import Select from "react-select";

const options = [
  { value: 1, label: "None" },
  { value: 2, label: "Hub" },
  { value: 3, label: "Account" },
  { value: 4, label: "Vendor" },
  { value: 5, label: "Day" },
  { value: 6, label: "Week" },
  { value: 7, label: "Month" },
  { value: 8, label: "Quarter" },
  { value: 9, label: "Year" },
  { value: 10, label: "Payment Method" },
];

const isSummaryOpt = [
  {
    value: true,
    label: "Summary",
  },
  {
    value: false,
    label: "Detailed",
  },
];

export default function BillPaymentReport() {
  const history = useHistory();

  const reportPage = useRef(null);

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const [reportName, setReportName] = useState("Bill Payments Report");
  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const [, setRefreshCounter] = useState(0);
  const [isSummary, setIsSummary] = useState(true);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const appDispatch = useContext(DispatchContext);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const columns = [
    [
      {
        field: "groupingKey",
        headerName: options.find(
          (option) => option.value === getValues("groupingElement")
        )?.label,
        cellRenderer: "agGroupCellRenderer",
      },
      {
        field: "totalAmountPaid",
        headerName: "Total",
        valueGetter: (params) => {
          return `$${params.data?.totalAmountPaid?.toFixed(2) ?? "0.00"}`;
        },
      },
    ],
    [
      {
        field: "hubKey",
        headerName: "Hub",
      },
      {
        field: "paymentAccount",
        headerName: "Account",
      },
      {
        field: "paymentAmount",
        headerName: "Amount",
        valueGetter: (params) => {
          return `$${params.data?.paymentAmount?.toFixed(2) ?? "0.00"}`;
        },
      },
      {
        field: "paymentDate",
        headerName: "Payment Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params.data?.paymentDate);
        },
      },
      {
        field: "paymentMethod",
        headerName: "Payment Method",
      },
      {
        field: "receiptNumber",
        headerName: "Receipt No",
      },
      {
        field: "receiveInvoice",
        headerName: "Receive Invoice",
      },
      {
        field: "vendorNumber",
        headerName: "Vendor No",
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const { data, isLoading, mutate } = useMutation(BillPaymentsReportAPI);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoading });
  }, [appDispatch, isLoading]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const onSummaryChange = (val) => {
    setIsSummary(val);
    onSubmit();
  };

  const onSubmit = () => {
    const values = getValues();
    if (values.startDate === null || values.endDate === null) {
      let notification = {
        variant: "danger",
        msg: "Please add start and end date",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      let params = {} as {
        startDate?: string;
        endDate?: string;
        vendorId?: number;
        accountId?: number;
        hubId?: number;
      };
      if (values.startDate) params.startDate = values.startDate?.toISOString();
      if (values.endDate) params.endDate = values.endDate?.toISOString();
      if (values.VendorId) params.vendorId = values.VendorId?.value;
      if (values.CustomerId) params.accountId = values.CustomerId?.value;
      if (values.HubId) params.hubId = values.HubId?.value;
      mutate({
        groupingElement: values.groupingElement?.value,
        extraParams: params,
      });
    }
  };

  const detailCellRenderer = ({ data }) => {
    return (
      <div className={`py-4`}>
        <AgGrid
          data={data?.purchasingEntries}
          columns={gridColumns[1]}
          pagination={false}
          autoHight={true}
          forPrinting={true}
          ref={childFnRef1}
        />
      </div>
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className={`page-content ${isSummary ? "hide-arrow" : ""}`}>
              <Filter
                filters={[0, 1, 2, 17, 35]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                setRefreshCounter={setRefreshCounter}
                groupingElements={options}
                isSummary={isSummary}
                setIsSummary={setIsSummary}
                initialDate={!isCustomizedReport}
              />
              <>
                <div className="container report-filters-div mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="col-2">
                      {getValues("groupingElement")?.value !== 1 && (
                        <Select
                          options={isSummaryOpt}
                          isSearchable={true}
                          value={isSummaryOpt?.find(
                            (opt) => opt?.value === isSummary
                          )}
                          onChange={(val) => onSummaryChange(val?.value)}
                        />
                      )}
                    </div>
                    <div>
                      <button
                        className="import-btn"
                        type="button"
                        onClick={exportToExcel}
                        disabled={disabledExportBtn}
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export grid to Excel
                      </button>
                      <button
                        type="button"
                        className="btn btn-success ms-5"
                        onClick={printDocument}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-printer me-3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                          <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                        </svg>
                        Print report to PDF
                      </button>
                    </div>
                  </div>
                </div>
                <div className="report-page" ref={reportPage}>
                  <div className="report-header d-flex justify-content-between">
                    <div>
                      <h3>
                        <b>{reportName}</b>
                      </h3>{" "}
                      <br />
                    </div>
                    <div>
                      <img
                        alt=""
                        src={data?.imageLogo ?? BlueSeedLogo}
                        className="report-logo"
                      ></img>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    {gridColumns.length > 0 && (
                      <AgGrid
                        data={
                          getValues("groupingElement")?.value === 1
                            ? data?.billPaymentEntries[0].purchasingEntries
                            : data?.billPaymentEntries
                        }
                        columns={
                          getValues("groupingElement")?.value === 1
                            ? gridColumns[1]
                            : gridColumns[0]
                        }
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        subTable={gridColumns[1]}
                        subTableObj={"purchasingEntries"}
                        fileName="Bill Payments Report"
                        ref={childFnRef}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
