import AG from "elements/AG";
import { useContext, useRef, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useMutation, useQuery } from "react-query";
import {
  deleteAttribute,
  deleteSubCategory,
  getItemCategories,
} from "./InventorySetup.services";
import { MdChevronRight, MdKeyboardArrowDown } from "react-icons/md";
import CategoryModal from "./CategoryModal";
import { Tab, Tabs } from "react-bootstrap";
import AttributeModal from "./AttributeModal";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import DispatchContext from "context/DispatchContext";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import CreateItemModal from "../Inventory/CreateItemModal";
import StateContext from "context/StateContext";
import { useRouteMatch } from "react-router-dom";
import { QueryResult } from "components/Common.models";
import { Hub } from "../Inventory.models";
import { getInventoryHubsList } from "../Inventory/InventoryServices/Inventory.services";
import addIcon from "img/add-icon.svg";

const icons = {
  check: <div className="open-circle"></div>,
  uncheck: <div className="closed-circle"></div>,
  expandClose: <MdChevronRight className="rct-icon rct-icon-expand-close" />,
  expandOpen: <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />,
  parentClose: <div className="closed-circle"></div>,
  parentOpen: <div className="open-circle"></div>,
  leaf: <span className="d-none"></span>,
};

enum CategoryTypeEnum {
  Text = 1,
  Numeric = 2,
  Date = 3,
  Boolean = 4,
}

const ItemCategories = () => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const appState = useContext(StateContext);

  const [gridData, setGridData] = useState([]);
  const [expanded, setExpanded] = useState<string[]>();
  const [checked, setChecked] = useState<string[]>(["0"]);
  const [selectedcat, setSelectedCat] = useState<string[]>([]);
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [showEmptyCategoryModal, setShowEmptyCategoryModal] =
    useState<boolean>(false);
  const [showAttributeModal, setShowAttributeModal] = useState<boolean>(false);
  const categoryParent = useRef(null);
  const categoriesFlat = useRef(null);
  const match = useRouteMatch();
  const { data: hubs } = useQuery<QueryResult<Hub>>(
    "hubsList",
    getInventoryHubsList
  );

  const { data: categories, refetch } = useQuery(
    "item-categories",
    getItemCategories,
    {
      onSuccess(data) {
        let flatCategories = [];
        const transformCategories = (data) => {
          if (data && data.length > 0) {
            for (let category of data) {
              if (category.children?.length === 0) category.children = null;
              if (category.children?.length > 0) {
                transformCategories(category.children);
              }
              flatCategories.push(category);
            }
            categoriesFlat.current = flatCategories;
          }
        };
        transformCategories(data);
        if (categoryParent.current) onTreeClick(categoryParent.current);
      },
    }
  );

  const attributeColumns = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "categoryType",
      headerName: "Type",
      cellRenderer: (params) => CategoryTypeEnum[params.value],
    },
    {
      field: "itemCategoryAttributeId",
      headerName: "Action",
      cellRendererFramework: () => (
        <button className="btn btn-link text-decoration-none text-danger ms-0">
          Delete
        </button>
      ),
      onCellClicked: (params) => {
        attributeMutation.mutate(params.value);
      },
    },
  ];

  const itemColumns = [
    {
      field: "itemNumber",
      headerName: "Item Number ",
    },
    {
      field: "suggestedSellingPrice",
      headerName: "suggested Selling Price",
    },
    {
      field: "itemDescription",
      headerName: "Description",
    },
    {
      field: "isSerialized",
      headerName: "Serialized Item",
    },
  ];

  const attributeMutation = useMutation(deleteAttribute, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Attribute deleted successfully",
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem deleting attribute"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const categoryMutation = useMutation(deleteSubCategory, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Category deleted successfully",
      };
      refetch();
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem deleting category"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onTreeClick = (target) => {
    setChecked([target.value]);
    setSelectedCat([target]);
    let found = categoriesFlat.current.find(
      (category) => category.value === target.value
    );
    if (found) {
      categoryParent.current = found;
      setGridData(getItems(found, []));
    }
  };

  const getItems = (found, array) => {
    if (found?.items) array.push(...found.items);
    if (found?.children)
      for (let element of found.children)
        if (element.children === null) {
          if (element.items.length > 0) array.push(...element.items);
        } else {
          for (let child of element.children) getItems(child, array);
        }
    return array;
  };

  const onModalClose = () => {
    setShowAttributeModal(false);
    setShowCategoryModal(false);
    setShowEmptyCategoryModal(false);
    refetch();
  };

  const deleteSub = () => {
    categoryMutation.mutate(categoryParent.current?.value);
  };

  const onRowClick = (item) => {
    if (item.nonTangible) {
      history.push(`${AppUrls.non_tangible_item}/${item.itemId}`);
    } else {
      if (item.isSerialized)
        history.push(`${AppUrls.new_inventory_serialized}/${item.itemId}`);
      else
        history.push(`${AppUrls.new_inventory_nonserialized}/${item.itemId}`);
    }
  };

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <p>Categories</p>
          <div>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <button
                onClick={() => setIsCreating(true)}
                className="btn btn-primary"
              >
                <img src={addIcon} className="me-2" alt="" />
                Add New Item
              </button>
            )}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowEmptyCategoryModal(true)}
            >
              Add Category
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowCategoryModal(true)}
              disabled={!!!categoryParent.current}
            >
              Add Sub Category
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteSub}
              disabled={
                !!!categoryParent.current ||
                !!!categoryParent.current.parentCategoryId ||
                categoryParent.current.items.length > 0
              }
            >
              Delete Sub Category
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowAttributeModal(true)}
              disabled={!!!categoryParent.current}
            >
              Add Attribute
            </button>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row mb-5">
              <div className="col-sm-3 ledgers-tree">
                {categories &&
                  Array.isArray(categories) &&
                  categories.length > 0 && (
                    <CheckboxTree
                      nodes={categories}
                      expanded={expanded}
                      onExpand={(nodes) => setExpanded(nodes)}
                      onClick={onTreeClick}
                      icons={icons}
                      expandOnClick={true}
                      onlyLeafCheckboxes={true}
                      checked={checked}
                    />
                  )}
              </div>
              <div className="col-sm-9">
                <Tabs defaultActiveKey="Items">
                  <Tab
                    eventKey="Items"
                    title={<div className="tab-inner-title">Items</div>}
                  >
                    <div className="h-350">
                      <AG
                        data={gridData}
                        columns={itemColumns}
                        setrowClicked={onRowClick}
                      />
                    </div>
                  </Tab>
                  <Tab
                    eventKey="Attributes"
                    title={<div className="tab-inner-title">Attributes</div>}
                  >
                    <div className="h-350">
                      <AG
                        data={categoryParent.current?.attributes}
                        columns={attributeColumns}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoryModal
        showModal={showCategoryModal}
        onExit={onModalClose}
        formValues={categoryParent.current}
      />
      <CategoryModal
        showModal={showEmptyCategoryModal}
        onExit={onModalClose}
        formValues={null}
      />
      <AttributeModal
        showModal={showAttributeModal}
        onExit={onModalClose}
        formValues={categoryParent.current}
      />
      <CreateItemModal
        category={selectedcat}
        showModal={isCreating}
        onExit={() => setIsCreating(false)}
        hubs={hubs?.data}
      />
    </>
  );
};

export default ItemCategories;
