import { GridColumns, QueryResult } from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { ErrorResponse } from "components/data/Errors.model";
import Lookup from "elements/Lookup";
import { Hub } from "pages/Inventory/Inventory.models";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { CustomerAccount, HubInfo } from "pages/Invoices/Invoices.model";
import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { getOpenOrdersSummaryReportAPI } from "./services/ReportServices";

import { getCustomers } from "pages/SalesMarketing/Customers/Customers.services";

import { fixTimezoneOffset } from "global/helpers";
import backArrow from "img/back-arrow.svg";

import { useHistory } from "react-router-dom";

const origins = [
  { value: 1, label: "System" },
  { value: 2, label: "Web" },
];

export default function OpenOrderSummaryReport() {
  const history = useHistory();
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);
  const [openSelectionCustomers, setOpenSelectionCustomers] =
    useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState<HubInfo>(null);
  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerAccount>(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const CustPO = useRef<HTMLInputElement>(null);
  const ProjectNo = useRef<HTMLInputElement>(null);

  const customers_columns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
    },
  ];

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const { data: inventoryHubs } = useQuery<QueryResult<Hub>, ErrorResponse>(
    "inventoryHubsList",
    getInventoryHubsList
  );

  const { data: customers } = useQuery("customersList", getCustomers);

  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(getOpenOrdersSummaryReportAPI, {
    async onSuccess(data) {
      let currentData = { ...data };
      if (CustPO.current?.value)
        currentData = { ...currentData, customerPO: CustPO.current.value };
      if (ProjectNo.current?.value)
        currentData = { ...currentData, projectNumber: CustPO.current.value };
      setShowReport(true);
      setReportData(currentData);
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const onHubSelected = (hub) => {
    setSelectedHub(hub);
    setOpenSelectionHubs(false);
  };

  const onCustomerSelected = (customer) => {
    setSelectedCustomer(customer);
    setOpenSelectionCustomers(false);
  };

  const onOriginChange = (origin) => {
    setSelectedOrigin(origin);
  };

  const onSubmit = () => {
    let params = {
      startDate: null,
      endDate: null,
      hubIds: [],
      customerId: null,
      origin: null,
      projectNo: null,
      CustomerPO: null,
    };
    if (startDate) params.startDate = fixTimezoneOffset(startDate);
    if (endDate) params.endDate = fixTimezoneOffset(endDate);
    if (selectedHub) params.hubIds[0] = selectedHub.locationId;
    if (selectedCustomer)
      params.customerId = selectedCustomer.customerAccountId;
    if (selectedOrigin) params.origin = selectedOrigin.value;
    if (ProjectNo.current && ProjectNo.current.value.length != 0)
      params.projectNo = ProjectNo.current.value;
    if (CustPO.current && ProjectNo.current.value.length != 0)
      params.CustomerPO = CustPO.current.value;
    generateReport.mutate(params);
  };

  const pageTitle = "Open Orders Summary Report";

  const onCancelClick = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedCustomer(null);
    setSelectedHub(null);
    ProjectNo.current.value = null;
    CustPO.current.value = null;
    setSelectedOrigin(null);
  };

  const tableHeaders = [
    "Order No",
    "Customer",
    "Company",
    "Hub",
    "Order Amount",
    "Ship Amount",
    "Date",
  ];

  const onBackClick = () => {
    setShowReport(false);
  };

  const onBackToReportsClick = () => {
    history.push("/reports");
  };
  return (
    <>
      {showReport ? (
        <form>
          <div className="page-title  page-title-editable">
            <div className="w-auto">
              <div className="back-btn " onClick={onBackClick}>
                <img src={backArrow} alt="back arrow" />
                {pageTitle}
              </div>
            </div>
          </div>
          <div className="static-page">
            <div className="page-content-wrapper p-0">
              <div className="grid-container"></div>
            </div>
          </div>
        </form>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="page-title page-title-editable">
              <div className="back-btn " onClick={onBackToReportsClick}>
                <img src={backArrow} alt="back arrow" />
                {pageTitle}
              </div>
              <div>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border`}
                  onClick={onCancelClick}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Create
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="page-content">
                <div className="row">
                  <div className="col-sm-3">
                    <label>Start Date</label>
                    <DatePicker
                      onChange={(date) => setStartDate(date)}
                      selected={startDate}
                      className="form-control"
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                  <div className="col-sm-3">
                    <label>End Date</label>
                    <DatePicker
                      onChange={(date) => setEndDate(date)}
                      selected={endDate}
                      className="form-control"
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group mb-4">
                      <label>Customer ID</label>
                      <Lookup
                        onButtonClicked={() => setOpenSelectionCustomers(true)}
                        inputName="accountId"
                        initialData={customers?.data?.map((c) => ({
                          ...c,
                          id: c.customerAccountId,
                          name: c.accountNumber,
                        }))}
                        onSelection={onCustomerSelected}
                        inputValue={selectedCustomer?.accountNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-4">
                  <div className="col-sm-6">
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group mb-4">
                      <label>Hub ID</label>
                      <Lookup
                        onButtonClicked={() => setOpenSelectionHubs(true)}
                        inputName="hubId"
                        initialData={inventoryHubs?.data?.map((item) => ({
                          ...item,
                          id: item.locationId,
                          name: item.hubId,
                        }))}
                        onSelection={onHubSelected}
                        inputValue={selectedHub?.hubId}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-4">
                  <div className="col-sm-6">
                    <hr />
                  </div>
                </div>
                <div className="row mt-5 mb-4">
                  <div className="col-sm-3">
                    <label className="label">Project No</label>
                    <input ref={ProjectNo} className="form-control" />
                  </div>
                  <div className="col-sm-3">
                    <label className="label">Origin</label>
                    <Select
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={onOriginChange}
                      value={selectedOrigin}
                      options={origins}
                      isClearable
                    />
                  </div>
                  <div className="col-sm-3">
                    <label className="label">Cust No</label>
                    <input ref={CustPO} className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
      <SelectionModal
        modal={"Customers"}
        showModal={openSelectionCustomers}
        setShowModal={setSelectedCustomer}
        data={customers?.data}
        columns={customers_columns}
        setRowClicked={onCustomerSelected}
      />
      <SelectionModal
        modal={"Hub"}
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={inventoryHubs?.data}
        columns={hubsColumns}
        setRowClicked={onHubSelected}
      />
    </>
  );
}
