import { Controller, FormProvider, useForm } from "react-hook-form"
import backArrow from "img/back-arrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useContext, useRef, useState } from "react";
import { InvoiceItem, addedSerials, itemI, CreateInvoiceI } from "pages/Invoices/Invoices.model";
import TransactionExpense from "./TransactionExpense";
import DispatchContext from "context/DispatchContext";
import TransactionOperation from "./TransactionOperation";
import TransactionInvoice from "./TransactionInvoice";
import queryString from 'query-string'
import { useMutation } from "react-query";
import { addTransaction } from "./AccountingServices";
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { notificationMessage } from "global/helpers";

const templateOptions = [
    { label: "Scheduled", value: 1 },
    { label: "Reminder", value: 2 }
]

const endOptions = [
    { label: "None", value: 1 },
    { label: "By", value: 2 }
]

const intervalOptions = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "Monthly", value: 3 },
    { label: "Yearly", value: 4 },
]

const daysOptions = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
]

const monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
]

const AddTransaction = () => {

    const history = useHistory()
    const gridRef = useRef(null);
    const appDispatch = useContext(DispatchContext);
    const [interval, setInterval] = useState(0)
    const [end, setEnd] = useState(1)
    const [addedItems, setAddedItems] = useState<InvoiceItem[]>([])
    const [hubId, setHubId] = useState<number>()
    const [addedSerials, setAddedSerials] = useState<addedSerials[]>([])


    const { search } = useLocation()
    const { type } = queryString.parse(search)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const { formState: { isDirty, errors }, handleSubmit, reset, register, control, setValue } = methods


    const addMutation = useMutation(addTransaction, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Transaction ${CREATE_SUCCESS_MSG}`
            };
            appDispatch({ type: "notification", value: notification });
            history.push(`${AppUrls.recurrent_transactions}`);
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, "problem adding transaction"),
            };
            appDispatch({ type: "notification", value: notification });
        },
    });

    const onBackClick = () => {
        history.push(AppUrls.recurrent_transactions)
    }

    const cancelClicked = () => {
        setInterval(0)
        setEnd(1)
        reset()
        gridRef.current.api.setRowData([]);
    }

    const onIntervalChange = (e) => {
        setInterval(e)
        setValue("dayOfMonth", null)
        setValue("everyDay", null)
        setValue("everyWeek", null)
        setValue("onDayOfWeek", null)
        setValue("everyMonth", null)
        setValue("month", null)
        setValue("occurrences", null)
    }

    const onEndChange = (e) => {
        setEnd(e.value)
    }

    const onSubmit = (values) => {
        const rowData = [];
        gridRef.current?.api.forEachNode(function (node) {
            rowData.push(node.data);
        });

        if (type === '1')
            addExpense(values, rowData)
        else if (type === '2')
            addInvoice(values)
        else if (type === '3')
            addOperation(values, rowData)
    }

    const addExpense = (values, rowData) => {

        let paymentDetailEntries = []
        paymentDetailEntries = rowData.filter(data => data.accountName !== null && data.amount !== null)
            .map(row => {
                return {
                    "ledgerId": row.categoryId,
                    "description": row.description,
                    "amount": Number(row.amount)
                }
            })
        if (paymentDetailEntries.length === 0) {
            let notification = {
                variant: "danger",
                msg: "Please add expenses in the table"
            };
            appDispatch({ type: "notification", value: notification });
        } else if (paymentDetailEntries.some(entry => (isNaN(entry.amount) || entry.amount === 0))) {
            let notification = {
                variant: "danger",
                msg: "Please enter a valid amount"
            };
            appDispatch({ type: "notification", value: notification });
        } else {
            let data = { ...values, transactionType: type, expense: { ...values.expense, paymentDetailEntries: paymentDetailEntries } }
            addMutation.mutate(data)
        }

    }

    const addOperation = (values, rowData) => {

        let debitTotal = 0
        let creditTotal = 0
        let isDataValid = true
        let journals = []
        for (let row of rowData) {
            if (row.accountNumber === null) {
                isDataValid = false
                break
            }
            if (row.isDebit)
                debitTotal += !isNaN(row.amount) ? Number(row.amount) : 0
            else
                creditTotal += !isNaN(row.amount) ? Number(row.amount) : 0
        }
        if (isDataValid) {
            if (debitTotal !== 0 && debitTotal === creditTotal) {
                journals = rowData.map(journal => ({ ...journal, ledgerId: journal.accountName.ledgerId }))
                let data = { ...values, transactionType: type, operation: { ...values.operation, journals: journals } }
                addMutation.mutate(data)
            } else {
                let notification = {
                    variant: "danger",
                    msg: "Debit and Credit amount should be equal"
                };
                appDispatch({ type: "notification", value: notification });
            }
        } else {
            let notification = {
                variant: "danger",
                msg: "Please enter account number"
            };
            appDispatch({ type: "notification", value: notification });
        }
    }

    const addInvoice = (values) => {
        if (addedItems.length > 0) {
            let canSubmit: boolean = true
            let itemsToAdd = []
            for (let i of addedItems) {
                let serialsToAdd = addedSerials?.filter(j => j.itemId === i.itemId)?.map(k => k.serial)
                if (i.isSerialized) {
                    itemsToAdd.push({
                        itemId: i.itemId,
                        quantity: Number(i.invoiceQty),
                        price: Number(i.invoicePrice),
                        description: i.itemDescription,
                        serials: serialsToAdd
                    })
                } else {
                    itemsToAdd.push({
                        itemId: i.itemId,
                        quantity: Number(i.invoiceQty),
                        price: Number(i.invoicePrice),
                        description: i.itemDescription,
                        serials: []
                    })
                }
                if (i.isSerialized && (serialsToAdd?.length !== Number(i.invoiceQty))) {
                    canSubmit = false
                    break;
                }
            }
            if (canSubmit) {
                let items: itemI[] = itemsToAdd
                let newData: CreateInvoiceI = {
                    targetId: values.targetId,
                    invoiceType: 1,
                    hubId: hubId,
                    orderDate: new Date(),
                    shippingInfoId: values.shippingInfoId ?? null,
                    shippingMethodId: values.shippingMethodId?.value ?? null,
                    items: items,
                    remarks: values.remarks !== "undefined" ? values.remarks : null,
                }
                let data = { ...values, transactionType: type, invoice: { ...newData } }
                addMutation.mutate(data)
            } else {
                let notification = {
                    variant: "danger",
                    msg: "The number of added serials should match the selected quantity",
                };
                appDispatch({ type: "notification", value: notification })
            }
        } else {
            let notification = {
                variant: "danger",
                msg: "Please add items to create invoice",
            };
            appDispatch({ type: "notification", value: notification })
        }
    }

    const ordinalSuffix = (i) => {
        let j = i % 10;
        let k = i % 100;
        if (j == 1 && k != 11) return i + "st";
        if (j == 2 && k != 12) return i + "nd";
        if (j == 3 && k != 13) return i + "rd";
        return i + "th";
    }

    const numberOptions = [...Array(31).keys()].map(i => ({ value: i + 1, label: ordinalSuffix(i + 1) }))

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="page-title page-title-editable">
                        <div className="back-btn " onClick={onBackClick}>
                            <img src={backArrow} alt="back arrow" />
                            Recurrent Transaction
                        </div>
                        <div>
                            <button
                                type="button"
                                className={`btn btn-outline-primary no-border ${!isDirty && 'text-muted'}`}
                                onClick={cancelClicked}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-success" disabled={!isDirty}>
                                Create
                            </button>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="page-content">
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label>Template Name<span className="text-danger">*</span></label>
                                        <input {...register("templateName", { required: true })} className="form-control" />
                                        {errors["templateName"] && (
                                            <p className="text-danger">This field is required</p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label>Type<span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="templateType"
                                            rules={{ required: true }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    onChange={(selectedOption: any) => onChange(selectedOption?.value)}
                                                    isClearable
                                                    value={templateOptions?.find(opt => opt?.value === value) || null}
                                                    options={templateOptions}
                                                />
                                            )}
                                        />
                                        {errors["templateType"] && (
                                            <p className="text-danger">This field is required</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label>Interval<span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="intervalEnum"
                                            rules={{ required: true }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    onChange={(selectedOption: any) => { onChange(selectedOption?.value); onIntervalChange(selectedOption?.value) }}
                                                    isClearable
                                                    value={intervalOptions?.find(opt => opt?.value === value) || null}
                                                    options={intervalOptions}
                                                />
                                            )}
                                        />
                                        {errors["intervalEnum"] && (
                                            <p className="text-danger">This field is required</p>
                                        )}
                                    </div>
                                </div>
                                {interval > 0 &&
                                    <>
                                        <div className="col-sm-4">
                                            <div className="d-flex align-items-center h-100">
                                                {interval === 3 &&
                                                    <>
                                                        <span>on<span className="text-danger">*</span></span>
                                                        <Controller
                                                            control={control}
                                                            name="dayOfMonth"
                                                            rules={{ required: true }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <Select
                                                                    onChange={(selectedOption: any) => onChange(selectedOption?.value)}
                                                                    isClearable
                                                                    value={numberOptions?.find(opt => opt?.value === value) || null}
                                                                    options={numberOptions}
                                                                    className="mx-3"
                                                                />
                                                            )}
                                                        />
                                                        <span className="me-2">of</span>
                                                    </>
                                                }
                                                <span>every</span>
                                                {interval === 1 &&
                                                    <>
                                                        <input type="number" {...register("everyDay", { required: true })} min={1} className="form-control mx-3" style={{ width: "80px" }} />
                                                        <span>day(s)<span className="text-danger">*</span></span>
                                                    </>
                                                }
                                                {interval === 2 &&
                                                    <>
                                                        <input type="number" {...register("everyWeek", { required: true })} min={1} className="form-control mx-3" style={{ width: "80px" }} />
                                                        <span className="text-nowrap">week(s)<span className="text-danger">*</span> on<span className="text-danger">*</span></span>
                                                        <Controller
                                                            control={control}
                                                            name="onDayOfWeek"
                                                            rules={{ required: true }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <Select
                                                                    onChange={(selectedOption: any) => onChange(selectedOption?.value)}
                                                                    isClearable
                                                                    value={daysOptions?.find(opt => opt?.value === value) || null}
                                                                    options={daysOptions}
                                                                    className="mx-3"
                                                                />
                                                            )}
                                                        />
                                                    </>
                                                }
                                                {interval === 3 &&
                                                    <>
                                                        <input type="number" {...register("everyMonth", { required: true })} min={1} className="form-control mx-3" style={{ width: "80px" }} />
                                                        <span className="text-nowrap">month(s)<span className="text-danger">*</span></span>
                                                    </>
                                                }
                                                {interval === 4 &&
                                                    <>
                                                        <Controller
                                                            control={control}
                                                            name="month"
                                                            rules={{ required: true }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <Select
                                                                    onChange={(selectedOption: any) => onChange(selectedOption?.value)}
                                                                    isClearable
                                                                    value={monthOptions?.find(opt => opt?.value === value) || null}
                                                                    options={monthOptions}
                                                                    className="mx-3"
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={control}
                                                            name="dayOfMonth"
                                                            rules={{ required: true }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <Select
                                                                    onChange={(selectedOption: any) => onChange(selectedOption?.value)}
                                                                    isClearable
                                                                    value={numberOptions?.find(opt => opt?.value === value) || null}
                                                                    options={numberOptions}
                                                                    className="mx-3"
                                                                />
                                                            )}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-1">
                                            <div className="form-group">
                                                <label>Start Date<span className="text-danger">*</span></label>
                                                <Controller
                                                    control={control}
                                                    name="startDate"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <DatePicker
                                                            showYearDropdown
                                                            dateFormatCalendar="MMMM"
                                                            yearDropdownItemNumber={15}
                                                            scrollableYearDropdown
                                                            onChange={onChange}
                                                            selected={value ? value : null}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-1">
                                            <div className="form-group">
                                                <label>End</label>
                                                <Select
                                                    onChange={onEndChange}
                                                    value={endOptions?.find(opt => opt?.value === end) || null}
                                                    options={endOptions}
                                                />
                                            </div>
                                        </div>
                                        {end === 2 &&
                                            <div className="col-sm-1">
                                                <div className="form-group">
                                                    <label>End Date</label>
                                                    <Controller
                                                        control={control}
                                                        name="endDate"
                                                        render={({ field: { onChange, value } }) => (
                                                            <DatePicker
                                                                showYearDropdown
                                                                dateFormatCalendar="MMMM"
                                                                yearDropdownItemNumber={15}
                                                                scrollableYearDropdown
                                                                onChange={onChange}
                                                                selected={value ? value : null}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {interval === 4 &&
                                            <div className="col d-flex align-items-center">
                                                <input type="number" {...register("occurrences", { required: true })} min={1} className="form-control mx-3" style={{ width: "80px" }} />
                                                <span className="text-nowrap">occurences<span className="text-danger">*</span></span>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    {Object.keys(errors).length > 0 &&
                                        <span className="text-danger">These fields are required</span>
                                    }
                                </div>
                            </div>
                            {type === '1' &&
                                <TransactionExpense gridRef={gridRef} />
                            }
                            {type === '3' &&
                                <TransactionOperation gridRef={gridRef} />
                            }
                            {type === '2' &&
                                <TransactionInvoice
                                    addedItems={addedItems}
                                    setAddedItems={setAddedItems}
                                    hubId={hubId}
                                    setHubId={setHubId}
                                    addedSerials={addedSerials}
                                    setAddedSerials={setAddedSerials}
                                />
                            }
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default AddTransaction