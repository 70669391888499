import { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { getPurchaseOrders } from "./PurchasingServices/PurchaseServices";
import addIcon from "img/add-icon.svg";
import Select from "react-select";
import * as AppUrls from "constants/AppUrls";
import {
  checkIfAllowed,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { dateFormmaterNoTime } from "global/helpers";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import StateContext from "context/StateContext";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { getVendors } from "../Vendors/Vendors.services";
import { DropDownOption } from "components/Common.models";
import { statusesEnum, typeEnum, statusEnum } from "./StatusEnum";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import exportIcon from "img/export.svg";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { getBaseCurrency } from "components/currency";

const PurchaseOrder = ({ match }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const { data: inventoryHubsList } = useQuery(
    "inventoryHubsList",
    getInventoryHubsList
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: vendorsList } = useQuery("vendorsList", getVendors);
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { reset, control } = methods;

  const [PoStatus, setPoStatus] = useState<DropDownOption>({
    value: statusesEnum.All,
    label: "All",
  });
  const [PoType, setPoType] = useState<DropDownOption>({
    value: typeEnum.All,
    label: "All",
  });
  const [vendor, setVendor] = useState<DropDownOption>(null);
  const [hub, setHub] = useState<DropDownOption>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);

  let history = useHistory();
  const location = useLocation();

  const isReturn = match.path === AppUrls.purchase_order_return_list;

  const cancelForm = () => {
    reset();
    setHub(null);
    setVendor(null);
    setPoStatus({ value: statusesEnum.All, label: "All" });
    setPoType({ value: typeEnum.All, label: "All" });
  };

  const PurchaseOrderStates = [
    {
      value: 1,
      label: "Open",
    },
    {
      value: 2,
      label: "Received",
    },
    {
      value: 3,
      label: "Partial Received",
    },
    {
      value: 4,
      label: "Voided",
    },
    {
      value: 5,
      label: "All",
    },
    {
      value: 6,
      label: "Approved",
    },
    {
      value: 7,
      label: "Invoiced",
    },
  ];

  const poReturnStates = [
    ...PurchaseOrderStates,
    {
      value: 8,
      label: "Fulfilled",
    },
    {
      value: 9,
      label: "Partially Fulfilled",
    },
  ];

  const [filters, setFilters] = useState<any>({});

  const dataMutation = useMutation(getPurchaseOrders);

  useEffect(() => {
    setFilters({
      vendorId: null,
      hubId: null,
      poStatus: statusesEnum.All,
      poType: isReturn ? typeEnum.Return : typeEnum.Normal,
    });
  }, [match.path]);

  useEffect(() => {
    setFilters({
      vendorId: vendor?.value,
      hubId: hub?.value,
      poStatus: PoStatus.value,
      poType: isReturn ? typeEnum.Return : typeEnum.Normal,
      searchQuery: searchTerm,
    });
  }, [PoStatus, PoType, vendor, hub, searchTerm]);

  useEffect(() => {
    if (location.state && appState.gridFilters) {
      if (appState.gridFilters.vendorId) {
        let found = vendorsList?.data?.find(
          (vendor) => vendor.vendorId === appState.gridFilters.vendorId
        );
        setVendor({ value: found.vendorId, label: found.vendorNumber });
      }
      if (appState.gridFilters.hubId) {
        let found = inventoryHubsList?.data?.find(
          (hub) => hub.locationId === appState.gridFilters.hubId
        );
        setHub({ value: found.locationId, label: found.hubId });
      }
      if (appState.gridFilters.poStatus) {
        let found = PurchaseOrderStates?.find(
          (po) => po.value === appState.gridFilters.poStatus
        );
        setPoStatus(found);
      }
    } else {
      cancelForm();
    }
  }, [location, appState.gridFilters]);

  const onRowClick = (e) => {
    let url = {
      pathname: isReturn
        ? `${AppUrls.purchase_order_return_list}/view/${e.purchaseOrderId}`
        : `${AppUrls.purchase_order_list}/view/${e.purchaseOrderId}`,
      status: isReturn && "isOpen",
    };
    history.push(url);
  };

  const columns = [
    {
      field: "purchaseOrderNumber",
      headerName: "PO No.",
      maxWidth: 130,
    },
    {
      field: "vendorNo",
      headerName: "Vendor No.",
      maxWidth: 150,
    },
    {
      field: "company",
      headerName: "Company",
      maxWidth: 150,
    },
    {
      field: "hubId",
      headerName: "Hub ID",
      maxWidth: 120,
    },
    {
      field: "poDate",
      headerName: "PO Date",
      maxWidth: 120,

      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.poDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.poDate === null
            ? ""
            : dateFormmaterNoTime(params.data.poDate);

        return `
        <div>
          ${date}
        <div>
      `;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      maxWidth: 130,

      valueGetter: (params) => {
        return params.data.amount + " " + baseCurrency;
      },
      // cellRenderer: (params) => {
      //   let amount = params.data.amount;

      //   return `
      //   ${formatNumber(amount)}
      // `;
      // },
    },
    {
      field: "received",
      headerName: isReturn ? "Returned" : "Received",
      maxWidth: 130,

      valueGetter: (params) => {
        return "$ " + params.data.received;
      },
      cellRenderer: (params) => {
        let received = params.data.received;
        return `
        ${formatNumber(received)}
      `;
      },
    },
    {
      field: "openAmount",
      headerName: "Open Amount",
      maxWidth: 155,

      valueGetter: (params) => {
        return baseCurrency + params.data.openAmount;
      },
      cellRenderer: (params) => {
        let openAmount = params.data.amount - params.data.received;
        return `
        ${baseCurrency} ${openAmount}
      `;
      },
    },
    {
      field: "poState",
      headerName: "Status",
      maxWidth: 130,

      valueGetter: (params) => {
        return statusEnum[params.data.poState];
      },
      cellRenderer: (params) => {
        let poState = params.data.poState;
        const found = statusEnum[poState];
        return `
        ${found}
      `;
      },
    },
    {
      field: "recDate",
      maxWidth: 130,

      headerName: "Rec. Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.recDate);
      },
      cellRenderer: (params) => {
        let recDate =
          params.data.recDate === null
            ? ""
            : dateFormmaterNoTime(params.data.recDate);
        return `
        <div>
          ${recDate}
        <div>
      `;
      },
    },
    {
      field: "marketCode",
      headerName: "Market Code",
      cellRenderer: (params) => {
        let marketCode =
          params.data.marketCode === null ? "" : params.data.marketCode;
        return `
        <div>
          ${marketCode}receive-invoice-details
        <div>
      `;
      },
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(filters, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>
          {match.path === AppUrls.purchase_order_return_list
            ? "Purchase Order Return List"
            : "Purchase Order List"}
        </p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {match.path === AppUrls.purchase_order_return_list
            ? checkIfAllowed(appState.allowedUrls, "add", match.path) && (
                <button
                  type="button"
                  onClick={() =>
                    history.push({
                      pathname: AppUrls.new_purchase_order_return,
                      status: "isOpen",
                    })
                  }
                  className="btn btn-primary"
                >
                  <img src={addIcon} className="me-2" alt="" /> New PO return
                </button>
              )
            : checkIfAllowed(appState.allowedUrls, "add", match.path) && (
                <Link
                  to={AppUrls.new_purchase_order}
                  className="btn btn-primary"
                >
                  <img src={addIcon} className="me-2" alt="" /> New Purchase
                  Order
                </Link>
              )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <form>
              <div className="form-group">
                <label className="me-2">Search</label>
                <div className="page-search">
                  <div className="input-group">
                    <span className="page-search-icon">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      className="page-search-input ps-5 me-4 w-200"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col">
                  <label className="label me-3">Vendor</label>
                  <Controller
                    control={control}
                    name="vendorId"
                    render={() => (
                      <Select
                        options={vendorsList?.data?.map((item) => ({
                          value: item.vendorId,
                          label: item.vendorNumber,
                        }))}
                        isSearchable={true}
                        menuPlacement="auto"
                        className="flex-grow-1"
                        value={vendor}
                        onChange={(value) => setVendor(value)}
                        isClearable
                      />
                    )}
                  />
                </div>
                <div className="col">
                  <label className="label me-3">Hub</label>
                  <Controller
                    control={control}
                    name="hubId"
                    render={() => (
                      <Select
                        options={inventoryHubsList?.data?.map((item) => ({
                          value: item.locationId,
                          label: item.hubId,
                        }))}
                        isSearchable={true}
                        menuPlacement="auto"
                        className="flex-grow-1"
                        value={hub}
                        onChange={(value) => setHub(value)}
                        isClearable
                      />
                    )}
                  />
                </div>
                <div className="col">
                  <label className="label me-3">Status</label>
                  <Controller
                    control={control}
                    name="poStatus"
                    render={() => (
                      <Select
                        options={
                          isReturn ? poReturnStates : PurchaseOrderStates
                        }
                        isSearchable={true}
                        menuPlacement="auto"
                        className="flex-grow-1"
                        value={PoStatus}
                        onChange={(value) => setPoStatus(value)}
                      />
                    )}
                  />
                </div>
                <div className="col pt-4 mt-1">
                  <button
                    type="button"
                    className="btn btn-outline-primary custom-outline"
                    onClick={cancelForm}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              fetchMethod={dataMutation}
              data={dataGrid}
              setDataGrid={setDataGrid}
              filters={filters}
              setrowClicked={onRowClick}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              refreshCounter={refreshCounter}
              fileName="Purchase Orders List Report"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
