import api from "components/data/AxiosClient";

const userUrl = "users"
const userTypeUrl = "users/user-types";
const userRolesUrl = "roles";
const userPermissionsUrl = "roles/permissions";
const userComponentsUrl = "roles/components"

// User Type
export const getUserTypes = async () => {
    return await api.get(userTypeUrl);
};

export const createUserType = async (data) => {
    return await api.post(userTypeUrl, data);
};

export const editUserType = async (data) => {
    return await api.put(`${userTypeUrl}/${data.userTypeId}`, data);
};

// User Roles

export const getUserRoles = async (params) => {
    const res = await api.get(`${userRolesUrl}${params.queryKey[1].params && params.queryKey[1].params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
        }`);
    return res.data;
};

export const getUserPermissions = async () => {
    return await api.get(userPermissionsUrl);
};


export const getRoleMembers = async (id) => {
    return await api.get(`${userRolesUrl}/${id}/members`);
};

export const createUserRole = async (data) => {
    return await api.post(userRolesUrl, data);
};

export const editUserRole = async (data) => {
    return await api.put(`${userRolesUrl}/${data.roleId}`, data);
};

export const getUserRole = async (id) => {
    let queryId = id.queryKey[0];
    if (queryId)
        return await api.get(`${userRolesUrl}/${queryId}`);
};

export const deleteUserRole = async (id) => {
    return await api.delete(`${userRolesUrl}/${id}`);
};

// Employee

export const getUsers = async () => {
    return await api.get(userUrl);
};

export const createUser = async (data) => {
    return await api.post(userUrl, data);
};


export const getUser = async (id) => {
    let queryId = id.queryKey[0];
    if (queryId)
        return await api.get(`${userUrl}/${queryId}`);
};

export const editUser = async (data) => {
    return await api.put(`${userUrl}/${data.userId}`, data);
};

// Components

export const getUserComponents = async () => {
    return await api.get(userComponentsUrl);
};