import { useState, useContext } from "react";

import { Link, useHistory } from "react-router-dom";

import { useMutation } from "react-query";
import addIcon from "img/add-icon.svg";
import Select from "react-select";
import * as AppUrls from "constants/AppUrls";
import { checkIfAllowed, formatNumber } from "global/helpers";
import { dateFormmaterNoTime } from "global/helpers";
import StateContext from "context/StateContext";
import { DropDownOption } from "components/Common.models";
import AgGrid from "elements/AgGrid";
import { getVendorRmaList } from "./RmaServices/RmaServices";

const statusOptions = [
  { value: 0, label: "All" },
  { value: 1, label: "Open" },
  { value: 2, label: "Voided" },
  { value: 3, label: "Partially Shipped" },
  { value: 4, label: "Shipped" },
  { value: 5, label: "Credited" },
  { value: 6, label: "Rejected" },
  { value: 7, label: "Approved" },
  { value: 8, label: "Refunded" },
];

enum RMAStatus {
  Open = 1,
  Voided = 2,
  "Partially Shipped" = 3,
  Shipped = 4,
  Credited = 5,
  Rejected = 6,
  Approved = 7,
  Refunded = 8,
}

const VendorRma = ({ match }) => {
  const appState = useContext(StateContext);

  const [status, setStatus] = useState<DropDownOption>({
    value: 0,
    label: "All",
  });
  const [dataGrid, setDataGrid] = useState(null);
  const [filters, setFilters] = useState({
    searchQuery: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  let history = useHistory();

  const dataMutation = useMutation(getVendorRmaList, {
    async onSuccess(response) {
      setDataGrid(response?.data);
    },
  });

  const onRowClick = (e) => {
    let url = `${AppUrls.vendor_rma}/${e.vendorRMAId}`;
    history.push(url);
  };

  const columns = [
    {
      field: "vendorRmaNumber",
      headerName: "RMA No.",
    },
    {
      field: "vendorNumber",
      headerName: "Vendor No.",
    },
    {
      field: "purchaseOrderNumber",
      headerName: "Po No.",
    },
    {
      field: "billNumber",
      headerName: "Bill No.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => RMAStatus[params.value],
    },
    {
      field: "vendorRMADate",
      headerName: "RMA Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "Amount",
      cellRenderer: (params) => formatNumber(params.value),
    },
  ];

  const onStatusChange = (e) => {
    setStatus(e);
    if (e?.value && e.value > 0)
      setFilters((filters) => ({ ...filters, status: e?.value }));
    else setFilters((filters) => ({ ...filters, status: null }));
    setDataGrid(null);
  };

  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ ...filters, searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>List of Vendor RMA</p>
        <div>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.create_vendor_rma} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Vendor RMA
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <div className="d-flex align-items-start">
              <div className="form-group">
                <label className="me-2">Search</label>
                <div className="page-search">
                  <div className="input-group">
                    <span className="page-search-icon">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      className="page-search-input ps-5 me-4 w-200"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => onSearchTermChange(e)}
                      value={searchTerm}
                    />
                  </div>
                </div>
              </div>

              <form>
                <div className="row mb-4" style={{ width: "1000px" }}>
                  <div className="col-sm-2">
                    <label className="label me-3">Status</label>
                    <Select
                      options={statusOptions}
                      isSearchable={true}
                      menuPlacement="auto"
                      value={status}
                      onChange={onStatusChange}
                      isClearable
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              fetchMethod={dataMutation}
              filters={filters}
              setrowClicked={onRowClick}
              setDataGrid={setDataGrid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorRma;
