import { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import "../CustomerDetails.scss";
import { RepresentativeModelDTO } from "pages/SalesMarketing/Representative/Representative.models";
import {
  BankAccountType,
  CreditTerm,
  CustomerAccount,
  CustomerInfoDTO,
  LanguageModel,
  Representative,
  WarrantyLevel,
} from "../Customers.models";
import { DropDownOption } from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumn } from "elements/AG.models";
import { states } from "components/data/States";
import Lookup from "elements/Lookup";
import { formatInput, formatNumber } from "global/helpers";
import { getBaseCurrency } from "components/currency";
import { useQuery } from "react-query";

type Props = {
  customerDetails: CustomerInfoDTO;
  id: number;
  isDisabled: boolean;
  representatives: RepresentativeModelDTO[];
  languages: LanguageModel[];
  creditTerms: CreditTerm[];
  customers: CustomerAccount[];
};

const AdditionalInfo = (props: Props) => {
  const {
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useFormContext<CustomerInfoDTO>();
  const [showParentAccountModal, setShowParentAccountModal] =
    useState<boolean>(false);
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const [showInsideRepModal, setShowInsideRepModal] = useState<boolean>(false);
  const [showTrainedByModal, setShowTrainedByModal] = useState<boolean>(false);
  const openBalance = getValues("openedBalance");
  const onOrder = getValues("onSO");

  const languageItems: DropDownOption[] = props.languages?.map((l) => {
    const opt: DropDownOption = { label: l.languageName, value: l.languageId };
    return opt;
  });
  const creditTermItems: DropDownOption[] = props.creditTerms?.map((c) => {
    const opt: DropDownOption = {
      label: `${c.term}: ${c.description}`,
      value: c.creditTermId,
    };
    return opt;
  });

  const warrantyLevels: DropDownOption[] = [
    { value: WarrantyLevel.Standard, label: "Standard" },
    { value: WarrantyLevel.Warranty2, label: "Warranty 2" },
    { value: WarrantyLevel.Warranty3, label: "Warranty 3" },
  ];

  const customerColumns: GridColumn[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      cellRenderer: (params) => {
        let city = params.data.city === null ? "" : params.data.city;
        let state =
          params.data.state === null
            ? ""
            : states.filter((value) => value.value === params.data.state)[0]
                .label;
        return `
        <div>
          ${city}
          <br />
          <span class="text-muted">${state}</spam>
        <div>
      `;
      },
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
  ];

  const rep_columns: GridColumn[] = [
    {
      field: "repName",
      headerName: "Representative Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "workNumber",
      headerName: "Work No.",
    },
    {
      field: "mobileNumber",
      headerName: "Cell No.",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "isActive",
      headerName: "Status",
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
            ${status ? "Active" : "Disabled"}
        </div>
        `;
      },
    },
  ];

  const [selectedParent, setSelectedParent] = useState<CustomerAccount>(null);
  const [selectedInsideRep, setSelectedInsideRep] =
    useState<Representative>(null);
  const [selectedTrainedBy, setSelectedTrainedBy] =
    useState<Representative>(null);

  const onSelectedLookupItem = (id: number, field: any) => {
    if (id) {
      if (field.toString() === "parentAccountId")
        setSelectedParent(
          props.customers?.find((cust) => cust.customerAccountId === id)
        );
      else if (field.toString() === "insideRepId")
        setSelectedInsideRep(
          props.representatives?.find((rep) => rep.repId === id)
        );
      else if (field.toString() === "trainedById")
        setSelectedTrainedBy(
          props.representatives?.find((rep) => rep.repId === id)
        );
      setValue(field, id, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (props.id) {
      setSelectedParent(
        props.customers?.find(
          (cust) =>
            cust.customerAccountId === props.customerDetails?.customerAccountId
        )
      );
      setSelectedInsideRep(
        props.representatives?.find(
          (rep) => rep.repId === props.customerDetails?.insideRepId
        )
      );
      setSelectedTrainedBy(
        props.representatives?.find(
          (rep) => rep.repId === props.customerDetails?.trainedById
        )
      );
    }
  }, [props.id, props.customerDetails]);

  useEffect(() => {
    setValue("dirtyFlag", "1", { shouldDirty: true });
  }, [selectedParent, selectedInsideRep, selectedTrainedBy]);

  return (
    <>
      <div className="form-group mb-4">
        <label className="checkbox-inline">
          <input
            {...register("isMasterDealer")}
            id="isMasterDealerE"
            disabled={props.isDisabled}
            type="checkbox"
          />
          <label className="text-black ms-2" htmlFor="isMasterDealerE">
            Master Dealer Account
          </label>
        </label>
      </div>
      <div className="row pb-4">
        <div className="left-side-mid border-end">
          <div className="row">
            <div className="left-side-mid">
              <div className="form-group mb-4">
                <div className="custom-field">
                  <label>Parent Account</label>
                  <Lookup
                    onButtonClicked={() => setShowParentAccountModal(true)}
                    inputName="parentAccountId"
                    isRequired={false}
                    initialData={props.customers
                      ?.filter((cust) => !!cust.isActive)
                      .map((cust) => ({
                        ...cust,
                        id: cust.customerAccountId,
                        name: cust.accountNumber,
                      }))}
                    onSelection={setSelectedParent}
                    inputValue={selectedParent?.accountNumber}
                    isDisabled={props.isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side-mid">
          <div className="row">
            <div className="left-side-mid">
              <div className="form-group mb-4">
                <div className="custom-field">
                  <label>Inside Rep ID</label>
                  <Lookup
                    onButtonClicked={() => setShowInsideRepModal(true)}
                    inputName="insideRepId"
                    isRequired={false}
                    initialData={props.representatives
                      ?.filter((rep) => !!rep.isActive)
                      .map((rep) => ({
                        ...rep,
                        id: rep.repId,
                        name: rep.repName,
                      }))}
                    onSelection={setSelectedInsideRep}
                    inputValue={selectedInsideRep?.repName}
                    isDisabled={props.isDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group mb-4">
                <div className="custom-field">
                  <label> Trained By</label>
                  <Lookup
                    onButtonClicked={() => setShowTrainedByModal(true)}
                    inputName="trainedById"
                    isRequired={false}
                    initialData={props.representatives
                      ?.filter((rep) => !!rep.isActive)
                      .map((rep) => ({
                        ...rep,
                        id: rep.repId,
                        name: rep.repName,
                      }))}
                    onSelection={setSelectedTrainedBy}
                    inputValue={selectedTrainedBy?.repName}
                    isDisabled={props.isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row justify-content-start">
        <div className="col-3">
          <div className="custom-field">
            <label>Warranty Level</label>
            <Controller
              name="warrantyLevel"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  isDisabled={props.isDisabled}
                  options={warrantyLevels}
                  isClearable
                  isSearchable={true}
                  menuPlacement="auto"
                  value={
                    warrantyLevels?.find((opt) => opt?.value === value) || null
                  }
                  onChange={(val: DropDownOption) => onChange(val?.value)}
                />
              )}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="custom-field">
            <label>Default Language</label>
            <Controller
              name="languageId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  isDisabled={props.isDisabled}
                  options={languageItems}
                  isClearable
                  isSearchable={true}
                  menuPlacement="auto"
                  value={
                    languageItems?.find((opt) => opt?.value === value) || null
                  }
                  onChange={(val: DropDownOption) => onChange(val?.value)}
                />
              )}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group mb-4">
            <label>DOB</label>
            <br />
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  onChange={onChange}
                  selected={value ? value : null}
                  className="form-control"
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  disabled={props.isDisabled}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row section p-2 pt-3">
        <h5 className="fw-bold">Credit Info</h5>
        <div className="row justify-content-start">
          <div className="col-3">
            <div className="form-group mb-4">
              <label>Credit date</label>
              <Controller
                name="creditDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    selected={value ? value : null}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    disabled={props.isDisabled}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group mb-4">
              <label>Credit Score</label>
              <input
                {...register("creditScore", {
                  pattern: {
                    value: /^[a-zA-Z0-9]{0,4}$/,
                    message:
                      "The field CreditScore must be a string or array type with a maximum length of '4'",
                  },
                })}
                className="form-control"
                disabled={props.isDisabled}
              />
              {errors.creditScore && (
                <p className="text-danger">{errors.creditScore?.message}</p>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="form-group mb-4">
              <label>Credit Ref. No.</label>
              <input
                {...register("creditRefNum")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
          </div>
        </div>
        <h5 className="fw-bold">Balance</h5>
        <div className="row justify-content-start">
          <div className="col-2">
            <div
              className={
                !props.id
                  ? "col-12 border d-flex flex-column p-3 disabled-balance"
                  : "col-12 border d-flex flex-column p-3"
              }
            >
              <span
                className={
                  props.id && openBalance <= 0 ? "text-danger" : "text-success"
                }
              >
                Open Balance
              </span>
              <span
                className={
                  props.id && openBalance <= 0 ? "text-danger" : "text-success"
                }
              >
                {!props.id
                  ? "$ 0.00"
                  : `${
                      props.customerDetails &&
                      props.customerDetails.primaryCurrencyCode
                        ? props.customerDetails.primaryCurrencyCode
                        : baseCurrency
                    } ${openBalance ? openBalance : 0}`}
              </span>
            </div>
          </div>
          <div className="col-2">
            <div
              className={
                !props.id
                  ? "col-12 border d-flex flex-column p-3 disabled-balance"
                  : "col-12 border d-flex flex-column p-3"
              }
            >
              <span
                className={
                  !!props.id && onOrder <= 0 ? "text-danger" : "text-success"
                }
              >
                On Order
              </span>
              <span
                className={
                  props.id && onOrder <= 0 ? "text-danger" : "text-success"
                }
              >
                {!props.id
                  ? "$ 0.00"
                  : formatNumber(onOrder !== null ? onOrder : 0, baseCurrency)}
              </span>
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label>Credit Limit</label>
              <input
                {...register("creditLimit")}
                className="form-control"
                disabled={props.isDisabled}
                onBlur={(e) =>
                  setValue("creditLimit", formatInput(e.target.value))
                }
              />
            </div>
          </div>
          <div className="col-5">
            <div className="custom-field">
              <label>Term</label>
              <Controller
                name="creditTermId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="creditTermId"
                    isDisabled={props.isDisabled}
                    options={creditTermItems}
                    isClearable
                    isSearchable={true}
                    menuPlacement="auto"
                    value={
                      creditTermItems?.find((opt) => opt?.value === value) ||
                      null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row ps-0 mt-4">
        <div className="left-side-mid ms-0 ps-0">
          <div className="section p-3">
            <h5 className="fw-bold">Banking Information</h5>
            <div className="form-group mt-4 mb-4">
              <label>Bank Account No.</label>
              <input
                {...register("bankAccNum")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
            <div className="form-group mb-4">
              <label>Bank Routing No.</label>
              <input
                {...register("bankRoutingNum")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
            <div className="row">
              <div className="col">
                <Controller
                  control={control}
                  name="bankAccType"
                  render={({ field: { onChange, value } }) => (
                    <div className="form-group mb-4">
                      <label>Account Type.</label>
                      <br />
                      <label className="checkbox-inline">
                        <input
                          id="SavingE"
                          disabled={props.isDisabled}
                          type="radio"
                          onChange={() => onChange(BankAccountType.Saving)}
                          checked={value === BankAccountType.Saving}
                        />
                        <label className="text-black ms-2" htmlFor="SavingE">
                          Saving
                        </label>
                      </label>
                      <label className="checkbox-inline ms-5">
                        <input
                          id="CheckingE"
                          disabled={props.isDisabled}
                          type="radio"
                          onChange={() => onChange(BankAccountType.Checking)}
                          checked={value === BankAccountType.Checking}
                        />
                        <label className="text-black ms-2" htmlFor="CheckingE">
                          Checking
                        </label>
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <label>ACH Term</label>
                  <input
                    {...register("achTerm")}
                    className="form-control"
                    disabled={props.isDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-4">
            <div className="form-group mb-4">
              <label>Masrer Dealer Code</label>
              <input
                {...register("masterDealer")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
            <div className="form-group mb-4">
              <label>QPay Branch ID</label>
              <input
                {...register("qPayBranchId")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="right-side-mid pt-2">
          <div className="container pt-5">
            <div className="row mb-4">
              <div className="col">
                <label className="checkbox-inline">
                  <Controller
                    control={control}
                    name="ccShop"
                    render={({ field: { onChange, value } }) => (
                      <input
                        id="ccShopE"
                        disabled={props.isDisabled}
                        type="checkbox"
                        checked={value}
                        onChange={(val) => onChange(val.target.checked)}
                      />
                    )}
                  />
                  <label className="text-black ms-2" htmlFor="ccShopE">
                    Can Ship by Credit Card
                  </label>
                </label>
              </div>
              <div className="col">
                <label className="checkbox-inline ms-5">
                  <Controller
                    control={control}
                    name="otherShop"
                    render={({ field: { onChange, value } }) => (
                      <input
                        id="otherShopE"
                        disabled={props.isDisabled}
                        type="checkbox"
                        checked={value}
                        onChange={(val) => onChange(val.target.checked)}
                      />
                    )}
                  />
                  <label className="text-black ms-2" htmlFor="otherShopE">
                    Can Ship by Other
                  </label>
                </label>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <label className="checkbox-inline">
                  <Controller
                    control={control}
                    name="codShop"
                    render={({ field: { onChange, value } }) => (
                      <input
                        id="codShopE"
                        disabled={props.isDisabled}
                        type="checkbox"
                        checked={value}
                        onChange={(val) => onChange(val.target.checked)}
                      />
                    )}
                  />
                  <label className="text-black ms-2" htmlFor="codShopE">
                    Can Shop by C.O.D
                  </label>
                </label>
              </div>
              <div className="col">
                <label className="checkbox-inline ms-5">
                  <Controller
                    control={control}
                    name="achShop"
                    render={({ field: { onChange, value } }) => (
                      <input
                        id="achShopE"
                        disabled={props.isDisabled}
                        type="checkbox"
                        checked={value}
                        onChange={(val) => onChange(val.target.checked)}
                      />
                    )}
                  />
                  <label className="text-black ms-2" htmlFor="achShopE">
                    Can Shop by ACH
                  </label>
                </label>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <label className="checkbox-inline">
                  <Controller
                    control={control}
                    name="paypalShop"
                    render={({ field: { onChange, value } }) => (
                      <input
                        id="paypalShopE"
                        disabled={props.isDisabled}
                        type="checkbox"
                        checked={value}
                        onChange={(val) => onChange(val.target.checked)}
                      />
                    )}
                  />
                  <label className="text-black ms-2" htmlFor="paypalShopE">
                    Can shop by PayPal
                  </label>
                </label>
              </div>
              <div className="col">
                <label className="checkbox-inline ms-5">
                  <Controller
                    control={control}
                    name="checkShop"
                    render={({ field: { onChange, value } }) => (
                      <input
                        id="checkShopE"
                        disabled={props.isDisabled}
                        type="checkbox"
                        checked={value}
                        onChange={(val) => onChange(val.target.checked)}
                      />
                    )}
                  />
                  <label className="text-black ms-2" htmlFor="checkShopE">
                    Can Shop by Check
                  </label>
                </label>
              </div>
            </div>
            <div className="form-group mb-4">
              <label>PayPal Email</label>
              <input
                {...register("payPalEmail", {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format.",
                  },
                })}
                className={`form-control ${
                  errors.payPalEmail && "required_field"
                }`}
                disabled={props.isDisabled}
              />
              {errors.payPalEmail && (
                <p className="text-danger">{errors.payPalEmail?.message}</p>
              )}
            </div>
            <div className="form-group mb-4">
              <label>Multiple Dealer Codes Seperated by comma (,)</label>
              <textarea
                {...register("dealerCodes")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal="account"
        showModal={showParentAccountModal}
        setShowModal={setShowParentAccountModal}
        data={props.customers}
        columns={customerColumns}
        setRowClicked={(item: CustomerAccount) => {
          onSelectedLookupItem(item.customerAccountId, "parentAccountId");
          setShowParentAccountModal(false);
        }}
      />
      <SelectionModal
        modal="representative"
        showModal={showInsideRepModal}
        setShowModal={setShowInsideRepModal}
        data={props.representatives}
        columns={rep_columns}
        setRowClicked={(item: RepresentativeModelDTO) => {
          onSelectedLookupItem(item.repId, "insideRepId");
          setShowInsideRepModal(false);
        }}
      />
      <SelectionModal
        modal="representative"
        showModal={showTrainedByModal}
        setShowModal={setShowTrainedByModal}
        data={props.representatives}
        columns={rep_columns}
        setRowClicked={(item: RepresentativeModelDTO) => {
          onSelectedLookupItem(item.repId, "trainedById");
          setShowTrainedByModal(false);
        }}
      />
    </>
  );
};

export default AdditionalInfo;
