import { useState, useRef, useContext } from "react";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import DatePicker from "react-datepicker";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { getSalesOrder } from "pages/SalesOrder/SalesOrders/SalesOrderServices/SalesOrderServices";
import DispatchContext from "context/DispatchContext";
import { formatNumber, notificationMessage } from "global/helpers";
import AG from "elements/AG";
import { ackEstimate, estimateSo } from "./Services/adminServices";

const OrderDetails = () => {

    const history = useHistory()
    const { id } = useParams();
    const [selectedMethod, setSelectedMethod] = useState<number>(0)
    const [discountPercentage, setDiscountPercentage] = useState<string>("")
    const [discountAmount, setDiscountAmount] = useState<string>("")
    const [expiryDate, setExpiryDate] = useState<Date>(null);
    const [allowEdit, setAllowEdit] = useState<boolean>(false);
    const discountType = useRef(null)
    const [itemOrders, setItemOrders] = useState([])
    const appDispatch = useContext(DispatchContext)

    const onBackClick = () => {
        history.push(AppUrls.ecommerce_so)
    }

    const { data, refetch } = useQuery('order', () => getSalesOrder(id),
        {
            enabled: !!id,
            onSuccess(data) {
                setItemOrders(data?.data?.itemOrders)
            },
        }
    )

    const columns = [
        {
            field: "itemNumber",
            headerName: "Item",
        },
        {
            field: "description",
            headerName: "Item Description",
        },
        {
            field: "quantity",
            headerName: "Order QTY",
            editable: allowEdit,
            onCellValueChanged: (params) => onQuantityChange(params)
        },
        {
            field: "price",
            headerName: "Price",
            cellRenderer: (params) => formatNumber(params.value)
        },
        {
            field: "subtotal",
            headerName: "Subtotal",
            valueGetter: (params) => formatNumber(params.getValue("quantity") * params.getValue("price")),
        }
    ];

    const updateSalesOrder = useMutation(estimateSo, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: "Changes saved successfully",
            };
            appDispatch({ type: "notification", value: notification })
            refetch()
            setAllowEdit(false)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem editing sales order')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const acceptEstimate = useMutation(ackEstimate, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: "Changes saved successfully",
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.ecommerce_so)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem editing sales order')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const onRadioChange = (e) => {
        if (e.target.checked)
            setSelectedMethod(parseInt(e.target.value))
        else {
            setSelectedMethod(0)
            setDiscountAmount("")
            setDiscountPercentage("")
        }
    }

    const onAmountChange = (e) => {
        if (e.target.value === "")
            setDiscountAmount("")
        else if (!isNaN(e.target.value) && Number(e.target.value) > 0)
            setDiscountAmount(e.target.value)
    }

    const onPercentageChange = (e) => {
        if (e.target.value === "")
            setDiscountPercentage("")
        else if (!isNaN(e.target.value) && Number(e.target.value) > 0 && Number(e.target.value) <= 100)
            setDiscountPercentage(e.target.value)
    }

    const applyDiscount = (type) => {
        discountType.current = type
        setSelectedMethod(0)
        if (type === 1)
            setDiscountAmount("")
        else if (type === 2)
            setDiscountPercentage("")
    }

    const onCancel = () => {
        setAllowEdit(false)
        setSelectedMethod(0)
        discountType.current = null
        setDiscountAmount("")
        setDiscountPercentage("")
    }

    const onQuantityChange = (params) => {
        setItemOrders(items => items.map(item => {
            if (item.itemOrderId === params.data.itemOrderId)
                return { ...item, quantity: Number(params.data.quantity) }
            return { ...item }
        }))
    }

    const onAcknowledge = () => {
        acceptEstimate.mutate(id)
    }

    const onSubmit = () => {
        let notification = {
            variant: "danger",
            msg: ''
        };
        if (discountType.current === null)
            appDispatch({ type: "notification", value: { ...notification, msg: "Please choose either discount by amount or by percentage" } })
        else if (expiryDate === null)
            appDispatch({ type: "notification", value: { ...notification, msg: "Please enter discount expiry date" } })
        else {
            let newData = {}
            newData = { expiryDate: expiryDate.toISOString(), id, itemOrders }
            if (discountType.current === 1)
                newData = { ...newData, discountPercentage }
            else if (discountType.current === 2)
                newData = { ...newData, discountAmount }
            updateSalesOrder.mutate(newData)
        }
    }

    return (
        <div className="static-page">
            <div className="page-title page-title-editable">
                <div className="back-btn " onClick={onBackClick}>
                    <img src={backArrow} alt="back arrow" />
                    {data?.data?.orderNumber}
                </div>
                <div className="d-flex justify-content-between ">
                    {allowEdit ?
                        <>
                            <button className="btn btn-outline-primary no-border" onClick={onCancel}>
                                Cancel
                            </button>
                            <button className="btn btn-success" onClick={onSubmit}>
                                Save Changes
                            </button>
                        </>
                        :
                        <>
                            <button className="btn btn-success" onClick={onAcknowledge}>
                                Reply Estimate
                            </button>
                            <button className="btn btn-primary" onClick={() => setAllowEdit(true)}>
                                Edit
                            </button>
                        </>
                    }
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="row  gx-5">
                        <div className="col-lg-8 col-sm-12">
                            <div className="row gx-5 gy-4">
                                <div className="col-lg-6 col-sm-12">
                                    <label>Order No.</label>
                                    <div>{data?.data?.orderNumber}</div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <label>Order Date</label>
                                    <div>{data?.data?.orderDate && new Date(data?.data?.orderDate).toLocaleString()}</div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <label>Account</label>
                                    <div>{data?.data?.accountNumber}</div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <label>Hub ID</label>
                                    <div>{data?.data?.hubKey}</div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <label>Origin</label>
                                    <div>{data?.data?.origin === 1 ? "Sytem" : "Web"}</div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <label>Status</label>
                                    <div>Estimate</div>
                                </div>
                                <div className='section p-4 pt-0'>

                                    <div className="d-flex align-items-center">
                                        <div className="me-5 fw-bold">Discount</div>
                                        <div className="row">
                                            <div className="col-lg-6 col-sm-12">
                                                <label className="checkbox-inline">
                                                    <input
                                                        id="percentage"
                                                        type="checkbox"
                                                        value={1}
                                                        className="me-2"
                                                        onChange={onRadioChange}
                                                        checked={selectedMethod === 1}
                                                        disabled={!allowEdit}
                                                    />
                                                    <label htmlFor="percentage">Percentage</label>
                                                </label>
                                                {selectedMethod === 1
                                                    ?
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className='input-group w-50'>
                                                            <div className="input-group-prepend">
                                                                <span className={`input-group-text dollar-icon bg-white`}>%</span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                className='form-control border-start-0'
                                                                onChange={onPercentageChange}
                                                                value={discountPercentage}
                                                            />
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary no-border `}
                                                            onClick={() => applyDiscount(1)}
                                                            disabled={discountPercentage === ""}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="mb-3">Discount {discountType.current === 1 ? discountPercentage : 0}%</div>
                                                }
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <label className="checkbox-inline">
                                                    <input
                                                        id="amount"
                                                        type="checkbox"
                                                        value={2}
                                                        className="me-2"
                                                        onChange={onRadioChange}
                                                        checked={selectedMethod === 2}
                                                        disabled={!allowEdit}
                                                    />
                                                    <label htmlFor="amount">Amount</label>
                                                </label>
                                                {selectedMethod === 2
                                                    ?
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className='input-group w-50'>
                                                            <div className="input-group-prepend">
                                                                <span className={`input-group-text dollar-icon bg-white`}>$</span>
                                                            </div>
                                                            <input
                                                                type="number"
                                                                className='form-control border-start-0'
                                                                onChange={onAmountChange}
                                                                value={discountAmount}
                                                            />
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className={`btn btn-outline-primary no-border `}
                                                            onClick={() => applyDiscount(2)}
                                                            disabled={discountAmount === ""}
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="mb-3">Discount {discountType.current === 2 ? discountAmount : 0}$</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="d-flex justify-content-end">
                                        <div className="w-25 d-flex align-items-center">
                                            <label className="flex-shrink-0 me-2">Expiry Date</label>
                                            <DatePicker
                                                onChange={(date) => setExpiryDate(date)}
                                                selected={expiryDate}
                                                className="form-control"
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={15}
                                                scrollableYearDropdown
                                                disabled={!discountType.current || !allowEdit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <label>Company</label>
                                    <div className="fw-bold mt-3">{data?.data?.billingInfo?.company}</div>
                                    <div className="mt-3">
                                        {data?.data?.billingInfo?.address?.contact && data?.data?.billingInfo?.address?.contact.length > 0 ? data?.data?.billingInfo?.address?.contact : <span className="text-muted fst-italic">Contact</span>}
                                        <br />
                                        {data?.data?.billingInfo?.address?.address && data?.data?.billingInfo?.address?.address.length > 0 ? data?.data?.billingInfo?.address?.address : <span className="text-muted fst-italic">Address</span>}
                                        <br />
                                        {data?.data?.billingInfo?.address?.city && data?.data?.billingInfo?.address?.city.length > 0 ? data?.data?.billingInfo?.address?.city : <span className="text-muted fst-italic">City</span>} {data?.data?.billingInfo?.address?.state && data?.data?.billingInfo?.address?.state.length > 0 ? data?.data?.billingInfo?.address?.state : <span className="text-muted fst-italic">State</span>} {data?.data?.billingInfo?.address?.zipCode && data?.data?.billingInfo?.address?.zipCode.length > 0 ? data?.data?.billingInfo?.address?.zipCode : <span className="text-muted fst-italic">Zip Code</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-3">
                                    <label>Ship To</label>
                                    <div className="fw-bold mt-3">{data?.data?.shippingInfo?.company}</div>
                                    <div className="mt-3">
                                        {data?.data?.shippingInfo?.address?.contact && data?.data?.shippingInfo?.address?.contact.length > 0 ? data?.data?.shippingInfo?.address?.contact : <span className="text-muted fst-italic">Contact</span>}
                                        <br />
                                        {data?.data?.shippingInfo?.address?.address && data?.data?.shippingInfo?.address?.address.length > 0 ? data?.data?.shippingInfo?.address?.address : <span className="text-muted fst-italic">Address</span>}
                                        <br />
                                        {data?.data?.shippingInfo?.address?.city && data?.data?.shippingInfo?.address?.city.length > 0 ? data?.data?.shippingInfo?.address?.city : <span className="text-muted fst-italic">City</span>} {data?.data?.shippingInfo?.address?.state && data?.data?.shippingInfo?.address?.state.length > 0 ? data?.data?.shippingInfo?.address?.state : <span className="text-muted fst-italic">State</span>} {data?.data?.shippingInfo?.address?.zipCode && data?.data?.shippingInfo?.address?.zipCode.length > 0 ? data?.data?.shippingInfo?.address?.zipCode : <span className="text-muted fst-italic">Zip Code</span>}
                                    </div>
                                </div>
                                <hr />
                                <div className="col-lg-6 col-sm-12 mt-0">
                                    <label>Ship Via</label>
                                    <div>{data?.data?.shipVia ?? <span className="text-muted fst-italic">Ship via</span>}</div>
                                </div>
                                <div className="col-lg-6 col-sm-12 mb-4">
                                    <label>Ship by</label>
                                    <div>{data?.data?.representativeName ?? <span className="text-muted fst-italic">Ship by</span>}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className='row mt-4'>
                                <div className='col-lg-6 col-sm-12 px-4'>
                                    <div className='d-flex'>
                                        <div className='text-secondary'>TOTAL QTY</div>
                                        <div className='flex-grow-1 text-center'>{data?.data?.totalItems}</div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-12'>
                                    <div className='d-flex'>
                                        <div className='text-secondary'>TOTAL ITEMS</div>
                                        <div className='flex-grow-1 text-center'>{data?.data?.itemsQuantity}</div>
                                    </div>
                                </div>
                                <div className='px-4'>
                                    <hr />
                                </div>
                                <div className='col-sm-12 d-flex justify-content-between px-4'>
                                    <div className='text-secondary'>SUBTOTAL</div>
                                    <div>{formatNumber(data?.data.subTotal)}</div>
                                </div>
                                <div className='col-sm-12 d-flex justify-content-between px-4'>
                                    <div className='text-secondary'>TAX AMOUNT</div>
                                    <div>{formatNumber(data?.data.taxTotal)}</div>
                                </div>
                                <div className='col-sm-12 d-flex justify-content-between px-4'>
                                    <div className='text-secondary'>SHIPPING COST</div>
                                    <div>{formatNumber(data?.data.shipCost)}</div>
                                </div>
                                <div className='col-sm-12 d-flex justify-content-between px-4'>
                                    <div className='text-secondary'>DISCOUNT</div>
                                    <div>{formatNumber(data?.data.discount)}</div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className='section mt-3 p-3'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='fs-5'>ORDER TOTAL</div>
                                            <div className='text-success fs-4'>{formatNumber(data?.data?.total)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='section p-4 pt-0'>
                                <div className='row gx-5 gy-4'>
                                    <div className='col-lg-6 col-sm-12'>
                                        <label>Date Entered</label>
                                        <div>{data?.data?.orderDate && new Date(data?.data?.orderDate).toLocaleString()}</div>
                                    </div>
                                    <div className='col-lg-6 col-sm-12'>
                                        <label>SO Inst: </label>
                                        <span className="text-muted fst-italic"> SO Inst</span>
                                    </div>
                                    <div className='col-lg-6 col-sm-12'>
                                        <label>Printed On: </label>
                                        <span className="text-muted fst-italic"> Printed On</span>
                                    </div>
                                    <div className='col-lg-6 col-sm-12'>
                                        <label>Printed By: </label>
                                        <span className="text-muted fst-italic"> Printed By</span>
                                    </div>
                                </div>
                            </div>
                            <div className='section p-4 pt-0'>
                                <div className='row gx-5 gy-4 pt-4'>
                                    <label>Order Remark</label>
                                    <div>
                                        {data?.data?.remarks}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {allowEdit && <div className="section fw-bold p-3">Double click on order quantity to edit it</div>}
                    <div className="h-450">
                        <AG data={data?.data?.itemOrders} columns={columns} autoColumns={false} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails