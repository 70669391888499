import { Chart } from "react-google-charts";
import { useMutation } from "react-query";
import {
  getFormattedDateForDashboard,
  getOneMonthAgoDate,
} from "global/helpers";
import DateGrid from "components/DateFilter/DateGrid";
import { useEffect, useState } from "react";
import { getSales } from "./DashboardServices";

const Sales = () => {
  const [startDate, setStartDate] = useState<Date>(getOneMonthAgoDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dateCategory, setDateCategory] = useState(null);

  const [sales, setSales] = useState<any>([
    { date: getFormattedDateForDashboard(), amount: 0.0 },
  ]);
  const mutate = useMutation(getSales, {
    onSuccess(data) {
      setSales(data);
    },
  });

  useEffect(() => {
    if (startDate || endDate) {
      mutate.mutate({ startDate: startDate, endDate: endDate });
    }
  }, [startDate, endDate]);

  let data = [["Year", "Amount in $"]];

  sales?.forEach((e) => {
    data.push([e.date, e.amount]);
  });

  const options = {
    chart: {
      title: "Sales",
    },
  };

  return (
    <div className="row w-100">
      <DateGrid
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        dateCategory={dateCategory}
        setDateCategory={setDateCategory}
        isFullWidth={true}
        isForExpenses={true}
      />

      {data && (
        <Chart
          chartType="Bar"
          width="700px"
          height="350px"
          loader={<div>Loading Chart</div>}
          data={data}
          options={options}
        />
      )}
    </div>
  );
};

export default Sales;
