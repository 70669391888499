import React, { useEffect, useRef } from "react";

import * as AppUrls from "constants/AppUrls";
import { useParams, useHistory } from "react-router-dom";
import backArrow from "img/back-arrow.svg";

import {
  CreditMemoDetailAPI,
  InvoiceDetailAPI,
  SalesOrderDetailReportAPI,
} from "./services/ReportServices";

import { useQuery } from "react-query";

import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
const CreditMemoReport = ({ match }) => {
  const history = useHistory();

  const { id } = useParams();
  const pageTitle = "Credit Memo Detail Report";
  const backUrl = `/invoices/credit-memo/view/${id}`;

  const isCredit = match.path === AppUrls.view_credit_memo;

  const { data, isLoading, isSuccess } = useQuery(
    id,
    isCredit ? CreditMemoDetailAPI : InvoiceDetailAPI
  );

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="static-page">
        <div className="page-content-wrapper p-0">
          <div className="grid-container">
            <div></div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreditMemoReport;
