import api from "components/data/AxiosClient";

const rma = "rma";
const vendorRma = "vendor-rma";

export const getRmaList = async (params) => {
  let query = "?";
  if (params.PageStart) query += `pageStart=${params.PageStart}`;
  if (params.PageSize) query += `&pageSize=${params.PageSize}`;
  if (params.origin) query += `&origin=${params.origin}`;
  if (params.status) query += `&status=${params.status}`;
  if (params.params) query += `&${params.params}`;
  const response = await api.get(`${rma}${query}`);
  return response.data;
};

export const createRma = async (data) => {
  return await api.post(rma, data);
};

export const getRmaDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`${rma}/${queryId}`);
  return response.data;
};

export const approveRma = async (id) => {
  return await api.post(`${rma}/${id}/approve`);
};
export const rejectCustomerRma = async (id) => {
  return await api.post(`${rma}/${id}/reject`);
};

export const rejectRma = async (id) => {
  return await api.delete(`vendor-rma/${id}`);
};

export const voidRma = async (id) => {
  return await api.post(`${rma}/${id}/void`);
};

export const refundRma = async (data) => {
  return await api.post(`${rma}/${data.rmaId}/refund/${data.refundFrom}`);
};

export const creditRma = async (id) => {
  return await api.post(`${rma}/${id}/credit`);
};

export const addInvoiceRma = async (params) => {
  return await api.post(`${rma}/${params.rmaId}/add-invoice`, params);
};

export const receiveRma = async (data) => {
  return await api.post(`${rma}/receive`, data);
};

export const getVendorRmaList = async (params) => {
  let query = "?";
  if (params.PageStart) query += `pageStart=${params.PageStart}`;
  if (params.PageSize) query += `&pageSize=${params.PageSize}`;
  if (params.status) query += `&status=${params.status}`;
  if (params.forShipping)
    query += `&forShipping=${params.forShipping ? "true" : "false"}`;
  if (params.params) query += `&${params.params}`;
  const response = await api.get(`${vendorRma}${query}`);
  return response.data;
};

export const createVendorRma = async (data) => {
  return await api.post(vendorRma, data);
};

export const getVendorRmaDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`${vendorRma}/${queryId}`);
  return response.data;
};

export const approveVendorRma = async (id) => {
  return await api.post(`${vendorRma}/${id}/approve`);
};

export const addBillRma = async (params) => {
  return await api.post(`${vendorRma}/${params.rmaId}/add-bill`, params);
};

export const refundVendorRma = async (data) => {
  return await api.post(`${vendorRma}/${data.rmaId}/refund/${data.refundTo}`);
};

export const creditVendorRma = async (id) => {
  return await api.post(`${vendorRma}/${id}/credit`);
};

export const shipRma = async (data) => {
  return await api.post(`${vendorRma}/ship`, data);
};
