import { GridColumns } from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import Lookup from "elements/Lookup";
import { getInventoryItems } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { PaymentMethod } from "pages/Invoices/Invoices.model";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import {
  CashReceiptRegisterReportAPI,
  getReportInit,
} from "./services/ReportServices";
//@ts-ignore

import backArrow from "img/back-arrow.svg";
import "./style/common.scss";

import { fixTimezoneOffset } from "global/helpers";
import { getPaymentMethods } from "pages/Invoices/InvoicesServices/InvoicesServices";

import { useHistory } from "react-router-dom";
export default function DataReceiptRegisterReport() {
  const history = useHistory();
  const getItems = useMutation(getInventoryItems);

  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );

  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  useEffect(() => {
    if (paymentMethods) {
      setPaymentMethodOptions(
        paymentMethods.map((method) => ({
          label: method.name,
          value: method.paymentMethodId,
        }))
      );
    }
  }, [paymentMethods]);

  const { data: initData } = useQuery("initData", getReportInit);

  const [showReport, setShowReport] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const { control, setValue } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [showHub, setShowHub] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState(null);

  const onHubSelection = (hub) => {
    setSelectedHub(hub);
    setShowHub(false);
  };

  const [showAccounts, setShowAccounts] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const onAccountSelected = (x) => {
    setSelectedAccount(x);
    setShowAccounts(false);
  };

  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(CashReceiptRegisterReportAPI, {
    async onSuccess(data) {
      let currentData = { ...data };
      setShowReport(true);
      setReportData(currentData);
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const [chosenType, setChosenType] = useState(1);

  const onTypeChanged = (e) => {
    setChosenType(e.value);
  };

  const onSubmit = () => {
    let params = {
      startDate: null,
      endDate: null,
      customerId: null,
      hubId: null,
      paymentMethodId: null,
    };
    if (startDate) params.startDate = fixTimezoneOffset(startDate);
    if (endDate) params.endDate = fixTimezoneOffset(endDate);
    else {
      setEndDate(new Date());
      params.endDate = fixTimezoneOffset(new Date());
    }
    if (selectedHub) params.hubId = selectedHub?.locationId;
    if (selectedAccount) params.customerId = selectedAccount?.customerAccountId;
    if (selectedAccount) params.paymentMethodId = getValues("paymentMethodId");

    generateReport.mutate(params);
  };

  const pageTitle = "Cash Register Report";

  const onBackClick = () => {
    setShowReport(false);
  };

  const options = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Credut Memo",
    },
  ];

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const customers_columns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
    },
  ];

  const onCancelClick = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedHub(false);
    setSelectedAccount(null);
  };

  const tableHeaders = [
    "Receipt No",
    "Account",
    "Ref",
    "Batch No",
    "Check No",
    "Payment method",
    "Payment",
  ];

  const onBackToReportsClick = () => {
    history.push("/reports");
  };
  return (
    <>
      {showReport ? (
        <form>
          <div className="page-title  page-title-editable">
            <div className="w-auto">
              <div className="back-btn " onClick={onBackClick}>
                <img src={backArrow} alt="back arrow" />
                {pageTitle}
              </div>
            </div>
          </div>
          <div className="static-page">
            <div className="page-content-wrapper p-0">
              <div className="grid-container"></div>
            </div>
          </div>
        </form>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="page-title page-title-editable">
              <div className="back-btn " onClick={onBackToReportsClick}>
                <img src={backArrow} alt="back arrow" />
                {pageTitle}
              </div>
              <div>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border`}
                  onClick={onCancelClick}
                >
                  Cancel
                </button>
                {/*  */}
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!startDate}
                >
                  Create
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="page-content">
                <div className="row">
                  <div className="col-sm-3">
                    <label>Start Date</label>
                    <DatePicker
                      onChange={(date) => setStartDate(date)}
                      selected={startDate}
                      className="form-control"
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                  <div className="col-sm-3">
                    <label>End Date</label>
                    <DatePicker
                      onChange={(date) => setEndDate(date)}
                      selected={endDate}
                      className="form-control"
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </div>
                </div>
                <div className="row mt-5 mb-4">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Hub Id</label>
                      <Lookup
                        onButtonClicked={() => setShowHub(true)}
                        inputName="hubId"
                        initialData={initData?.hubs?.map((item) => ({
                          ...item,
                          id: item.locationId,
                          name: item.hubId,
                        }))}
                        onSelection={onHubSelection}
                        inputValue={selectedHub?.hubId}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group mb-4">
                      <label>Customer</label>
                      <Lookup
                        onButtonClicked={() => setShowAccounts(true)}
                        inputName="vendorId"
                        initialData={initData?.customers?.map((x) => ({
                          ...x,
                          id: x.customerAccountId,
                          name: x.accountNumber,
                        }))}
                        onSelection={onAccountSelected}
                        inputValue={selectedAccount?.accountNumber}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group mb-4">
                      <label>Invoice Type</label>
                      <Select
                        options={options}
                        isSearchable={true}
                        value={options.find((val) => val.value === chosenType)}
                        onChange={onTypeChanged}
                        className="flex-grow-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-4">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Payment Method</label>
                      <Controller
                        control={control}
                        name="paymentMethodId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={options}
                            isClearable
                            isSearchable={true}
                            value={
                              options.find((opt) => opt?.value === value) ||
                              null
                            }
                            onChange={(selectedOption: any) =>
                              onChange(selectedOption?.value)
                            }
                            placeholder="Choose"
                            menuPlacement="auto"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      )}
      <SelectionModal
        modal={"Hub"}
        showModal={showHub}
        setShowModal={setShowHub}
        data={initData?.hubs}
        columns={hubsColumns}
        setRowClicked={onHubSelection}
      />
      <SelectionModal
        modal={"Customers"}
        showModal={showAccounts}
        setShowModal={setShowAccounts}
        data={initData?.customers}
        columns={customers_columns}
        setRowClicked={onAccountSelected}
      />
    </>
  );
}
