import { useState } from 'react'
import CheckboxTree from "react-checkbox-tree";
import SelectImg from 'img/carbon_select-window.png'
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AG from "elements/AG";
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdKeyboardArrowDown,
  MdAddBox,
  MdIndeterminateCheckBox,
  MdFolder,
  MdFolderOpen,
  MdInsertDriveFile
} from "react-icons/md";

import "./UserRoleDetails.scss"

import { Controller, useForm } from "react-hook-form";
import { cloneObj } from 'global/helpers';

const Permissions = ({ checked, setChecked, treeOptions, disabled, permissionsListing, setPermissionsListing, isException = false, exceptList = [], setExceptList = null }) => {

  const {
    control, getValues
} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
        isActive: true,
        isTaxable: true
    }
})

  const [expanded, setExpanded] = useState([]);
  const [clickedActions, setClickedActions] = useState([]);
  const [clickedActionsTitle, setClickedActionsTitle] = useState("");

  const onCheck = (checkedOptions) => {

    let newChecked = getAllChecked(checkedOptions)
    
    let updatedPermissions = permissionsListing.slice()
    for (let permission of updatedPermissions)
      if (newChecked.includes(permission.value.toString())) {
        if (!permission.actions.some(e => e.isGranted === true))
          for (let action of permission.actions) {
            action.isGranted = true
          }
      } else {
        for (let action of permission.actions)
          action.isGranted = false
      }
    setPermissionsListing(updatedPermissions)
  };


  const getAllChecked = (checkedOptions) => {
    let alreadyChecked = [...new Set(checked)]
    let newChecked = checkedOptions
    // get difference between what has already been checked and what is recently checked
    let difference = alreadyChecked
      .filter(x => !checkedOptions.includes(x))
      .concat(checkedOptions.filter(x => !alreadyChecked.includes(x)));

    // find the element in the array from above difference
    let differenceElement = permissionsListing.find(permission => permission.value.toString() === difference[0])
    if (differenceElement)
      // if element has children
      if (differenceElement.children) {
        //if element has been checked
        if (checkedOptions.length > alreadyChecked.length) {
          for (let element of permissionsListing) {
            // add element parent to the array
            if (element.parentComponentId && element.parentComponentId.toString() === difference[0])
              newChecked = [...newChecked, element.value.toString()]
          }
          // after updating array add the children of every element in it if in multi levels
          for (let element of permissionsListing) {
            let parentElement = permissionsListing.find(permission => permission.value === element.parentComponentId)
            if (element.parentComponentId && newChecked.includes(element.parentComponentId.toString()) && newChecked.indexOf(element.value.toString() === -1)
              && parentElement && parentElement.parentComponentId && parentElement.children) {
              newChecked = [...newChecked, element.value.toString()]
            }
          }
          //check if the difference element is both a parent and a child to add his parent
          if (differenceElement.parentComponentId) {
            newChecked = [...newChecked, differenceElement.parentComponentId.toString()]
          }
          // if element has been unchecked
        } else {
          for (let element of permissionsListing) {
            // remove element parent from the array
            if (element.parentComponentId && element.parentComponentId.toString() === difference[0])
              newChecked = newChecked.filter(value => value != element.value.toString())
          }
          // after updating array remove the children if element is not included in it
          for (let element of permissionsListing) {
            if (element.parentComponentId && !newChecked.includes(element.parentComponentId.toString()))
              newChecked = newChecked.filter(value => value != element.value.toString())
          }
          //check if the difference element is both a parent and a child to remove his parent
          if (differenceElement.parentComponentId) {
            newChecked = newChecked.filter(value => value != differenceElement.parentComponentId.toString())
          }
        }
        //if element has no children
      } else {
        // if element has been checked
        if (checkedOptions.length > alreadyChecked.length) {
          // add parent
          if (differenceElement.parentComponentId)
            newChecked = [...newChecked, differenceElement.parentComponentId.toString()]
          // after updating the array add the parents of newly added elements
          for (let element of permissionsListing) {
            if (element.parentComponentId && newChecked.includes(element.value.toString()) && newChecked.indexOf(element.parentComponentId.toString() === -1))
              newChecked = [...newChecked, element.parentComponentId.toString()]
          }
          // if element has been unchecked
        } else {
          let counter = 0
          //check if other children of the same level are checked
          for (let element of permissionsListing) {
            if (element.parentComponentId === differenceElement.parentComponentId && checkedOptions.includes(element.value.toString()))
              counter++
          }
          //if none of the children are checked remove parent from array
          if (counter === 0)
            newChecked = newChecked.filter(value => value != differenceElement.parentComponentId.toString())
          //for parent nodes with multi levels check if none of their children is still checked to remove them
          for (let element of permissionsListing) {
            let counter2 = 0
            if (!element.parentComponentId && element.children) {
              for (let child of element.children) {
                if (newChecked.includes(child.value.toString()))
                  counter2++
              }
              if (counter2 == 0)
                newChecked = newChecked.filter(value => value != element.value)
            }
          }
        }
      }
    if (differenceElement) {
      setClickedActions(differenceElement.actions)
      setClickedActionsTitle(differenceElement.label)
    }
    setChecked(newChecked);
    return newChecked
  }

  const onExpand = (expanded) => {
    setExpanded(expanded);
  };

  const onClick = (clicked) => {
    let actions = []
    let chosen = permissionsListing.find(option => option.value === clicked.value)
    actions = chosen.actions
    for (let action of actions)
      action.componentId = chosen.value
    setClickedActions(actions);
    setClickedActionsTitle(chosen.label)
  };

  const onCheckboxChange = (e) => {
    let updatedTree = []
    if (e.target.checked) {
      let checkedOptions = [...checked]
      if (checkedOptions.indexOf(e.target.value))
        checkedOptions = [...checkedOptions, e.target.value]
      getAllChecked([...new Set(checkedOptions)])
      updatedTree = permissionsListing.slice()
      for (let element of updatedTree) {
        for (let action of element.actions)
          if (action.actionId == e.target.name)
            action.isGranted = true
      }
      let updateClicked = clickedActions?.map(action => {
        if (action.actionId.toString() === e.target.name)
          return { ...action, isGranted: true }
        return action
      })
      setClickedActions(updateClicked)
    } else {
      let checkedOptions = [...checked]
      for (let permission of permissionsListing) {
        if (permission.value.toString() === e.target.value) {
          let isGranted = permission.actions.filter(e => e.isGranted === true)
          if (isGranted.length <= 1)
            checkedOptions = checkedOptions.filter(option => option != e.target.value)
        }
      }
      getAllChecked([...new Set(checkedOptions)])
      updatedTree = permissionsListing.slice()
      for (let element of updatedTree) {
        for (let action of element.actions)
          if (action.actionId == e.target.name)
            action.isGranted = false
      }
      let updateClicked = clickedActions?.map(action => {
        if (action.actionId.toString() === e.target.name)
          return { ...action, isGranted: false }
        return action
      })
      setClickedActions(updateClicked)
    }
    setPermissionsListing(updatedTree)
  }

  const onCheckboxChangeGrant = (e) => {
    let oldValues = cloneObj(exceptList)
    if(oldValues.find(i => i.componentActionId === Number(e.target.name))) {
      oldValues.find(i => i.componentActionId === Number(e.target.name)).isGranted = getValues(e.target.name)
    } else {
      oldValues.push({componentActionId: Number(e.target.name), isGranted: getValues(e.target.name)})
    }
    setExceptList(oldValues)
  }

  const icons = {
    check: <MdCheckBox className="rct-icon rct-icon-check" />,
    uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" />,
    halfCheck: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" />
    ),
    expandClose: (
      <MdChevronRight className="rct-icon rct-icon-expand-close" />
    ),
    expandOpen: (
      <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
    ),
    expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" />,
    collapseAll: (
      <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" />
    ),
    parentClose: <MdFolder className="rct-icon rct-icon-parent-close" />,
    parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" />,
    leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" />
  };
  const columns = [
    {
      headerName: "Function Description",
      field: "actionName",
    },
    {
      headerName: isException ? "Except" : "Allow",
      field: "allowed",
      cellRendererFramework: (params) => <input type="checkbox" disabled={disabled} name={params.data?.actionId} value={params.data?.componentId} checked={params.data?.isGranted} onChange={onCheckboxChange} />,
      floatingFilter: false,
    },
    {
      headerName: "Action",
      field: "action",
      //@ts-ignore
      cellRendererFramework: (params) => 
      <>
          <div className="pt-3">
            <Controller
              control={control}
              name={params.data?.actionId.toString()}
              render={({ field: { onChange, value } }) => (
                <>
                  <label className="checkbox-inline">
                    <input
                      onChange={(e) => {onChange(true); onCheckboxChangeGrant(e);}}
                      checked={value || exceptList.find(i => i.componentActionId == params.data.actionId)?.isGranted}
                      id="Grant"
                      name={params.data?.actionId}
                      type="radio"
                      disabled={!params.data.isGranted}
                    />
                    <label htmlFor="Grant" className="text-black ms-2">Grant</label>
                  </label>
                  <label className="checkbox-inline ms-3"></label>
                  <label className="checkbox-inline">
                    <input
                      onChange={(e) => {onChange(false); onCheckboxChangeGrant(e);}}
                      checked={!value || !exceptList.find(i => i.componentActionId == params.data.actionId)?.isGranted}
                      id="Prohibit"
                      name={params.data?.actionId}
                      type="radio"
                      disabled={!params.data.isGranted}
                    />
                    <label htmlFor="Prohibit" className="text-black ms-2">Prohibit</label>
                  </label>
                </>
              )}
            />
          </div>
      </>,
      floatingFilter: false,
      hide: !isException
    },
  ];

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-4 user-roles">
        {treeOptions && treeOptions.length > 0 &&
          <CheckboxTree
            nodes={treeOptions}
            checked={checked}
            expanded={expanded}
            onCheck={onCheck}
            onExpand={onExpand}
            icons={icons}
            onClick={onClick}
            disabled={disabled}
            checkModel='all'
            noCascade={true}
          />
        }
      </div>
      <div className="col-sm-12 col-lg-8 module-wrapper">
        {clickedActions.length > 0 ?
          <>
            <span className='mb-1 align-self-start'>{clickedActionsTitle}</span>
            <AG columns={columns} data={clickedActions} />
          </>
          :
          <>
            <img src={SelectImg} alt="Select" />
            <p>Select a module</p>
          </>
        }
      </div>
    </div>
  );
};
export default Permissions;