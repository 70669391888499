import AG from "elements/AG";
import Close from "img/close.svg";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { DropDownOption, GridColumns } from "components/Common.models";
import { useForm } from "react-hook-form";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { getVendors } from "../Vendors/Vendors.services";
import DispatchContext from "context/DispatchContext";
import { getPurchaseOrdersWithCustomStuff } from "./PurchasingServices/PurchaseServices";
import { statusEnum } from "./StatusEnum";

type Props = {
  showModal: boolean;
  onExit: any;
  onSelectedItem: any;
  preDefinedType?: DropDownOption;
  ApprovedAndVoidedRemoved?: boolean;
  ShowOnlyReceivedAndPartiallyReceived?: boolean;
};

const PurchaseSelectionModal = ({
  showModal,
  onExit,
  onSelectedItem,
  preDefinedType,
  ApprovedAndVoidedRemoved,
  ShowOnlyReceivedAndPartiallyReceived,
}: Props) => {
  const appDispatch = useContext(DispatchContext);

  const [data, setData] = useState([]);

  const dataMuation = useMutation(getPurchaseOrdersWithCustomStuff, {
    onSuccess(res) {
      setData(res.data);
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { setValue, reset, control } = methods;

  const PurchaseOrderStates = [
    {
      value: 1,
      label: "Open",
    },
    {
      value: 2,
      label: "Received",
    },
    {
      value: 3,
      label: "Partial Received",
    },
    {
      value: 4,
      label: "Voided",
    },
    {
      value: 5,
      label: "Any",
    },
  ];

  const POTypes = [
    {
      value: 1,
      label: "All",
    },
    {
      value: 2,
      label: "Normal",
    },
    {
      value: 3,
      label: "Return",
    },
  ];

  const { isLoading: isLoadingInventoryHubs, data: inventoryHubsList } =
    useQuery("inventoryHubsList", getInventoryHubsList);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingInventoryHubs });
  }, [isLoadingInventoryHubs, appDispatch]);

  const { isLoading: isLoadingDataVendors, data: vendorsList } = useQuery(
    "vendorsList",
    getVendors
  );

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingDataVendors });
  }, [isLoadingDataVendors, appDispatch]);

  useEffect(() => {
    if (showModal) {
      reset();
      setValue("vendorId", "");
      setValue("hubId", "");
      setPoStatus(PurchaseOrderStates[4]);
      setPoType(preDefinedType ? preDefinedType : POTypes[0]);
      // getPOs.mutate({ vendorId: null, hubId: null, poStatus: 5, poType: preDefinedType ? preDefinedType.value : 1 })
    }
  }, [showModal, setValue]);

  const [PoStatus, setPoStatus] = useState<DropDownOption>(
    PurchaseOrderStates[4]
  );
  const [PoType, setPoType] = useState<DropDownOption>(
    preDefinedType ? preDefinedType : POTypes[0]
  );
  const [vendor, setVendor] = useState<DropDownOption>(null);
  const [hub, setHub] = useState<DropDownOption>(null);

  const cancelForm = () => {
    reset();
    setHub(null);
    setVendor(null);
    setPoStatus(PurchaseOrderStates[4]);
    setPoType(preDefinedType ? preDefinedType : POTypes[0]);
  };

  useEffect(() => {
    dataMuation.mutate({
      vendorId: vendor?.value,
      hubId: hub?.value,
      poStatus: PoStatus.value,
      poType: PoType.value,
    });
  }, [PoStatus, PoType, vendor, hub]);

  const purchaseColumns: GridColumns[] = [
    {
      field: "purchaseOrderNumber",
      headerName: "PO No.",
    },
    {
      field: "vendorNo",
      headerName: "Vendor No.",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "vendorPO",
      headerName: "Vendor PO",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "Amount",
      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "received",
      headerName: "Received",
      valueGetter: (params) => {
        return `$${params.data?.received?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "openAmount",
      headerName: "Open Amount",
      valueGetter: (params) => {
        return `$${params.data?.openAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "poState",
      headerName: "Status",
      cellRenderer: (params) => (params.value ? statusEnum[params.value] : ""),
    },
  ];

  return (
    <Modal
      show={showModal}
      onHide={onExit}
      centered
      size="lg"
      dialogClassName="modal-80w"
    >
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title className="copy-items-title ">
          Double click on a purchase order to select it
        </Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={() => {
            onExit(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="px-0">
        <form>
          <div className="row ps-3 mb-4">
            <div className="col">
              <label className="label me-3">Vendor</label>
              <Controller
                control={control}
                name="vendorId"
                render={() => (
                  <Select
                    options={vendorsList?.data?.map((item) => ({
                      value: item.vendorId,
                      label: item.vendorNumber,
                    }))}
                    isSearchable={true}
                    menuPlacement="auto"
                    className="flex-grow-1"
                    value={vendor}
                    onChange={(value) => setVendor(value)}
                    isClearable
                  />
                )}
              />
            </div>
            <div className="col">
              <label className="label me-3">Hub</label>
              <Controller
                control={control}
                name="hubId"
                render={() => (
                  <Select
                    options={inventoryHubsList?.data?.map((item) => ({
                      value: item.locationId,
                      label: item.hubId,
                    }))}
                    isSearchable={true}
                    menuPlacement="auto"
                    className="flex-grow-1"
                    value={hub}
                    onChange={(value) => setHub(value)}
                    isClearable
                  />
                )}
              />
            </div>
            {!preDefinedType && (
              <div className="col">
                <label className="label me-3">Type</label>
                <Controller
                  control={control}
                  name="poType"
                  render={() => (
                    <Select
                      options={POTypes}
                      isSearchable={true}
                      menuPlacement="auto"
                      className="flex-grow-1"
                      value={PoType || null}
                      onChange={(value) => setPoType(value)}
                    />
                  )}
                />
              </div>
            )}
            {!ApprovedAndVoidedRemoved ||
              (ShowOnlyReceivedAndPartiallyReceived && (
                <div className="col">
                  <label className="label me-3">State</label>
                  <Controller
                    control={control}
                    name="poStatus"
                    render={() => (
                      <Select
                        options={PurchaseOrderStates}
                        defaultValue={PurchaseOrderStates[0]}
                        isSearchable={true}
                        menuPlacement="auto"
                        className="flex-grow-1"
                        value={PoStatus || null}
                        onChange={(value) => setPoStatus(value)}
                      />
                    )}
                  />
                </div>
              ))}
            <div className="col pt-4">
              <button
                type="button"
                className="btn btn-outline-primary custom-outline"
                onClick={cancelForm}
              >
                Reset
              </button>
            </div>
          </div>
        </form>
        <div className="h-450">
          <AG
            data={
              ApprovedAndVoidedRemoved
                ? data.filter(
                    (d) => d.poState != 6 && d.poState != 4 && d.poState != 7
                  )
                : ShowOnlyReceivedAndPartiallyReceived
                ? data.filter((d) => d.poState === 2 || d.poState === 3)
                : data.filter((d) => d.poState === 6 || d.poState === 7)
            }
            columns={purchaseColumns}
            autoColumns={true}
            setrowClicked={onSelectedItem}
            pagination={true}
            filters={{}}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex  border-top-0">
        <button
          type="button"
          className="btn btn-outline-primary custom-outline"
          onClick={() => {
            onExit(false);
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PurchaseSelectionModal;
