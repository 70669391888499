import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import { PortalUser } from "../SalesMarketing.models";

const representativesUrl = "representatives";
const portalUrl = "portal-users";
const accountTypesUrl = "customers/account-types";

// Account Types
export const getAccountTypesList = async () => {
  return await api.get(accountTypesUrl);
};

export const createAccountType = async (data) => {
  return await api.post(accountTypesUrl, data);
};

export const editAccountType = async (data) => {
  return await api.put(`${accountTypesUrl}/${data.id}`, data);
};

// Representatives
export const getRepresentativesList = async () => {
  return await api.get(representativesUrl);
};

export const getRepresentative = async (id) => {
  let repId = id.queryKey[0];
  if (repId) return await api.get(`${representativesUrl}/${repId}`);
};

export const editRepresentative = async (data) => {
  return await api.put(`${representativesUrl}/${data.repId}`, data);
};

export const createRepresentative = async (data) => {
  return await api.post(representativesUrl, data);
};

export const deleteRepresentative = async (id) => {
  return await api.delete(`${representativesUrl}/${id}`);
};

// Portal Users
export const getPortalsList = async (
  params
): Promise<QueryResult<PortalUser>> => {
  let extraParams = "";
  if (params.PageSize) {
    extraParams += `&PageSize=${params.PageSize}`;
    extraParams += `&PageStart=${
      isNaN(params.PageStart) ? 0 : params.PageStart
    }`;
  }
  if (params?.searchQuery) extraParams += `&searchQuery=${params?.searchQuery}`;

  const response = await api.get<QueryResult<PortalUser>>(
    `${portalUrl}?${extraParams}`
  );
  return response.data;
};

export const createPortal = async (data) => {
  return await api.post(portalUrl, data);
};

export const editPortal = async (data) => {
  return await api.put(`${portalUrl}/${data.portalUserId}`, data);
};

export const getPortal = async (id): Promise<PortalUser> => {
  let queryId = id.queryKey[0];
  if (queryId) {
    const response = await api.get<PortalUser>(`${portalUrl}/${queryId}`);
    return response.data;
  }
};

export const getSameCompanyList = async (id): Promise<PortalUser[]> => {
  let queryId = id.queryKey[1];
  if (queryId) {
    const response = await api.get<PortalUser[]>(
      `${portalUrl}/others/${queryId}`
    );
    return response.data;
  }
};

// Customers

export const getCustomersInit = async () => {
  return await api.get("customers/init");
};

export const createShippingInfo = async (data) => {
  return await api.post(`customers/${data.customerId}/shipping-info`, data);
};

export const editShippingInfo = async (data) => {
  return await api.put(`customers/shipping-info/${data.shippingInfoId}`, data);
};

export const createCustomerNote = async (data) => {
  return await api.post(`customers/${data.id}/notes`, {
    subject: data.newNoteData.subject,
    noteBody: data.newNoteData.noteBody,
  });
};

export const editCustomerNote = async (data) => {
  let body = {};
  body = {
    subject: data.noteData.subjectTxt,
    noteBody: data.noteData.noteBody,
    noteId: Number(data.noteData.noteId),
  };
  return await api.put(`customers/notes/${data.noteId}`, body);
};

export const deleteCustomerNote = async (noteId) => {
  return await api.delete(`customers/notes/${noteId}`);
};

export const getCustomerSalesOrders = async (customerId) => {
  const response = await api.get(
    `sales-order?accountId=${customerId}&states=0`
  );
  return response.data.data;
};
