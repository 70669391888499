
import Expenses from './Expenses';
import Sales from './Sales';


import { useState } from "react";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import CR from "./CR";
import DayesSalesOutstanding from "./DayesSalesOutstanding";
import DaysInvOutstanding from "./DaysInvOutstanding";
import GrossMargins from "./GrossMargins";
import InvTurnOver from "./InvTurnOver";
import InvoiceNbres from "./InvoiceNbres";
import NetProfitMargin from "./NetProfitMargin";
import Payments from "./Payments";
import Profit from "./Profit";
import QR from "./QR";
import YOYS from "./YOYS";

const Dashboard = () => {

  const [activeKey, setActiveKey] = useState(0)

  return (
    <div className="static-page">
      <div className="page-title">
        <div>
          Dashboard
        </div>
      </div>
      <div className='page-content-wrapper'>
        <div className="page-content">
          <ul className="dashboard-tab">
            <li onClick={() => setActiveKey(0)} className={activeKey === 0 ? 'active' : ''}>Ordinary</li>
            <li onClick={() => setActiveKey(1)} className={activeKey === 1 ? 'active' : ''}>KPIs</li>
          </ul>
            {activeKey === 0 && (
              <div className="row w-100">
                <div className="col-4">
                    <InvoiceNbres />
                </div>
                <div className="col-6">
                    <Expenses />
                </div>
                <div className="col-6">
                  <Sales />
                </div>
                <div className="col-6">
                  <Profit />
                </div>
                <div className="col-6">
                  <Payments />
                </div>
              </div>  
            )}
            {activeKey === 1 && (
              <div className="row w-100">
                <div className="col-6">
                  <GrossMargins />
                </div>
                <div className="col-6">
                  <InvTurnOver />
                </div>
                <div className="col-5">
                  <YOYS />
                </div>
                <div className="col-5">
                  <CR />
                </div>
                <div className="col-5">
                  <QR />
                </div>
                {/* <div className="col-4">
                  <YOYE />
                </div> */}
                <div className="col-5">
                  <DayesSalesOutstanding />
                </div>
                <div className="col-5">
                  <NetProfitMargin />
                </div>
                <div className="col-5">
                  <DaysInvOutstanding />
                </div>
              </div> 
            )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard