import React, { useContext, useEffect, useState } from "react";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useParams, Link } from "react-router-dom";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { FormProvider, useForm } from "react-hook-form";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { useMutation, useQuery } from "react-query";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import Lookup from "elements/Lookup";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  addItemExpense,
  deleteItemExpense,
  editExpenses,
  editItemExpense,
  getItemExpenseDetails,
  getLedgers,
} from "./AccountingServices";
import { LedgerTypeEnum } from "./enum/AccountEnum";
import { Form } from "react-bootstrap"; // Import Form from react-bootstrap
import SelectionModal from "components/SelectionModal/SelectionModal";

const AddEditItemExpense = ({ match }) => {
  let history = useHistory();
  const { id } = useParams();
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const [isEditData, setIsEditData] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [openInvAssetAccount, setOpenInvAssetAccount] =
    useState<boolean>(false);
  const [selectedInvAssetAccount, setSelectedInvAssetAccount] = useState(null);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      accountNumber: "",
      name: "",
      invAssetAccount: null,
    },
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    register,
    setValue,
  } = methods;

  const { isLoading: isLoadingExpenseInfo, data: expenseInfo } = useQuery(
    [id],
    getItemExpenseDetails,
    {
      enabled: id !== undefined,
    }
  );

  const { data: paymentAccounts } = useQuery("expenses", () => getLedgers({}));

  const ledgersColumns = [
    { field: "accountNumber", headerName: "Account", resizable: true },
    { field: "accountName", headerName: "Account name", resizable: true },
    { field: "balance", headerName: "Balance", resizable: true },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    if (id) {
      setIsEditData(false);
      if (expenseInfo) {
        setValue("accountNumber", expenseInfo.accountNumber);
        setValue("name", expenseInfo.name);
        setValue("invAssetAccount", expenseInfo.expenseAccountId); // Assuming this is what you want to set
      }
    } else {
      setIsEditData(true);
    }
  }, [id, expenseInfo, setValue]);

  const clearNonTangibleFrm = () => {
    reset();
  };

  const onInvAssetAccountSelection = (e) => {
    setSelectedInvAssetAccount(e);
    setOpenInvAssetAccount(false);
    setValue("invAssetAccount", e.ledgerId, { shouldValidate: true });
  };

  const addItem = useMutation(addItemExpense, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `Item ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      clearNonTangibleFrm();
      history.push(`${AppUrls.item_expenses}/${response.expenseItemId}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to create item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editItem = useMutation(editItemExpense, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Item ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsEditData(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to updated item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeExpense = useMutation(deleteItemExpense, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Item Deleted",
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      clearNonTangibleFrm();
      history.push(AppUrls.item_expenses);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to delete item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onItemDelete = () => {
    setShowDelete(false);
    if (id) {
      removeExpense.mutate(id);
    }
  };

  const onSubmit = (data) => {
    const { invAssetAccount, ...rest } = data;
    const newData = {
      ...rest,
      ExpenseAccountId: invAssetAccount,
      AccruedExpenseAccountId: invAssetAccount,
    };

    if (id) {
      editItem.mutate({ body: newData, id: id });
    } else {
      addItem.mutate({
        ...newData,
      });
    }
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        isLoadingExpenseInfo ||
        editItem.isLoading ||
        addItem.isLoading ||
        removeExpense.isLoading,
    });
  }, [
    isLoadingExpenseInfo,
    editItem.isLoading,
    addItem.isLoading,
    removeExpense.isLoading,
    appDispatch,
  ]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <Link to={AppUrls.item_expenses} className="back-btn ">
            <img src={backArrow} alt="back arrow" />
            {!id && "Adding Item Expense"}
            {id && isEditData && "Editing Non-Tangible Item"}
            {id && !isEditData && expenseInfo?.accountNumber}
          </Link>
          <div>
            {id ? (
              isEditData === false ? (
                <>
                  {checkIfAllowed(
                    appState.allowedUrls,
                    "delete",
                    match.path
                  ) && (
                    <button
                      type="button"
                      className="btn btn-danger ms-0"
                      onClick={() => {
                        setShowDelete(true);
                      }}
                    >
                      Delete
                    </button>
                  )}
                  {checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditData(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary no-border"
                    onClick={() => {
                      reset();
                      setIsEditData(false);
                    }}
                  >
                    Reset
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </>
              )
            ) : (
              <>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border${!isDirty && " text-muted"}`}
                  onClick={() => clearNonTangibleFrm()}
                  disabled={!isDirty}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </>
            )}
          </div>
        </div>
        <FormProvider {...methods}>
          <div className="page-content-wrapper">
            <div className="page-content" style={{ height: "600px" }}>
              <div className="mt-4">
                <div className="col-lg-3 col-md-2 col-sm-4 col-4 me-4 mb-5">
                  <label className="label mb-1">
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    {...register("accountNumber", { required: true })}
                    name="accountNumber"
                    className={`${errors.accountNumber && "required_field"}`}
                    disabled={id} // Disable if in edit mode
                  />
                  {errors["accountNumber"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-lg-3 col-md-4 col-sm-8 col-8 me-5 mb-5">
                  <label className="label mb-1">
                    Name <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    {...register("name", { required: true })}
                    name="name"
                    className={`${errors.name && "required_field"}`}
                    disabled={isEditData === true ? false : true}
                  />
                  {errors["name"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                {!id && (
                  <div className="col-lg-3 col-md-4 col-sm-8 col-8 me-5 mb-5">
                    <label>
                      Income account <span className="text-danger">*</span>
                    </label>
                    <>
                      <Lookup
                        onButtonClicked={() => setOpenInvAssetAccount(true)}
                        inputName="invAssetAccount"
                        isRequired={true}
                        initialData={paymentAccounts?.data?.map((item) => ({
                          ...item,
                          id: item.ledgerId,
                          name: item.accountName,
                        }))}
                        onSelection={onInvAssetAccountSelection}
                        inputValue={selectedInvAssetAccount?.accountName}
                        hasError={errors["invAssetAccount"] ? true : false}
                      />
                      {errors && errors["invAssetAccount"] && (
                        <p className="text-danger">This field is Required</p>
                      )}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>
        </FormProvider>
      </form>
      <ConfirmationModal
        title="Delete Expense"
        message="Are you sure? It will be permanently deleted."
        showModal={showDelete}
        onClose={() => setShowDelete(false)}
        onConfirm={onItemDelete}
        cancelBtnTitle="No, Keep"
        confirmBtnTitle="Yes, Delete"
        type="confirmation-danger"
      />
      <SelectionModal
        modal="account"
        showModal={openInvAssetAccount}
        setShowModal={setOpenInvAssetAccount}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onInvAssetAccountSelection}
      />
    </div>
  );
};

export default AddEditItemExpense;
