import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { getTransactionReport } from "./services/ReportServices";
import backArrow from "img/back-arrow.svg";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
} from "global/helpers";
import DispatchContext from "context/DispatchContext";
import "./style/report.scss";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import Filter from "./components/Filter";

export default function TransactionalMovement() {
  const [reportName, setReportName] = useState("Transactional Movement");

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];

  const isCustomizedReport = customizedComponentId != null;

  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const childFnRef = useRef(null);

  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(getTransactionReport, {
    async onSuccess(data) {
      let currentData = { ...data };
      if (inputRef.current?.value)
        currentData = { ...currentData, refNo: inputRef.current.value };
      setReportData(currentData);
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const onSubmit = () => {
    const values = getValues();
    let obj = {
      startDate: fixTimezoneOffset(values.startDate),
      endDate: fixTimezoneOffset(values.endDate),
      hubId: values.HubId?.value,
      operationType: values.OperationType?.value,
    };
    generateReport.mutate(obj);
  };

  const headers = ["Transactions"];

  const columns = [
    [
      {
        field: "itemNumber",
        headerName: "Item No",
        pinned: "left",
      },
      {
        field: "itemDescription",
        headerName: "Description",
      },
      {
        field: "hubKey",
        headerName: "Hub",
      },
      {
        field: "isAdding",
        headerName: "Is Adding",
        valueGetter: (params) => {
          return params.data.isAdding ? "Yes" : "No";
        },
      },
      {
        field: "quantity",
        headerName: "QTY",
      },
      {
        field: "operationType",
        headerName: "Operation",
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    columns.map((column) => {
      return column.map((c) => ({ ...c, hide: false }));
    })
  );

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const reportPage = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  useEffect(() => {
    if (!isCustomizedReport) generateReport.mutate({});
  }, [isCustomizedReport]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <Filter
                filters={[1, 2, 22, 24]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
              />

              <div className="container report-filters-div text-end mb-3">
                <button
                  className="import-btn"
                  type="button"
                  onClick={exportToExcel}
                  disabled={disabledExportBtn}
                >
                  <img src={exportIcon} className="me-2" alt="" />
                  Export grid to Excel
                </button>
                <button
                  type="button"
                  className="btn btn-success ms-5"
                  onClick={printDocument}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-printer me-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                  </svg>
                  Print report to PDF
                </button>
              </div>
              <div className="report-page" ref={reportPage}>
                <div className="report-header d-flex justify-content-between">
                  <div>
                    <h3>
                      <b>{reportName}</b>
                    </h3>{" "}
                    <br />
                    <p>
                      <b>Report Date: </b>
                      {dateFormmaterNoTime(reportData?.reportDate)}
                    </p>
                  </div>
                  <div className="text-end">
                    <img
                      alt=""
                      src={reportData?.imageLogo ?? BlueSeedLogo}
                      className="report-logo"
                    ></img>
                  </div>
                </div>
                <div className="grid-wrapper">
                  <AgGrid
                    columns={gridColumns[0]}
                    data={reportData?.transactionInfo}
                    pagination={false}
                    forPrinting={true}
                    autoHight={true}
                    exportToExcel={exportToExcelClicked}
                    setExportToExcelClicked={setExportToExcelClicked}
                    setDisabledExportBtn={setDisabledExportBtn}
                    setIsLoadingGridExport={setIsLoadingGridExport}
                    fileName={reportName}
                    ref={childFnRef}
                    isReport={isCustomizedReport}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
