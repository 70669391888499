import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { dateFormmaterNoTime } from "global/helpers";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { getSalesOrders } from "./Services/adminServices";

export default function OrderManagement() {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const [filters, setFilters] = useState({
    searchQuery: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const dataMutation = useMutation("data", getSalesOrders);

  const columns = [
    {
      field: "orderNumber",
      headerName: "Order No.",
      resizable: true,
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      resizable: true,
    },
    {
      field: "company",
      headerName: "Company",
      resizable: true,
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: () => "Estimate",
      resizable: true,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      cellRenderer: (params) =>
        params.value ? `${dateFormmaterNoTime(params.value)}` : "",
      resizable: true,
    },
    {
      field: "orderAmount",
      headerName: "Amount",
      resizable: true,
      valueGetter: (params) => {
        return `$${params.data?.orderAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "shipAmount",
      headerName: "Ship Amount",
      resizable: true,
      valueGetter: (params) => {
        return `$${params.data?.shipAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const onRowClick = (e) => {
    let url = `${AppUrls.ecommerce_so}/${e.salesOrderId}`;
    history.push(url);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ ...filters, searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Orders Management</p>
        <button
          className="import-btn"
          type="button"
          onClick={exportToExcel}
          disabled={disabledExportBtn || dataGrid?.length === 0}
        >
          <img src={exportIcon} className="me-2" alt="" />
          Export to Excel
        </button>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="form-group">
            <label className="me-2">Search</label>
            <div className="page-search">
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5 me-4 w-200"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => onSearchTermChange(e)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            fetchMethod={dataMutation}
            data={dataGrid}
            filters={filters}
            setDataGrid={setDataGrid}
            setrowClicked={onRowClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Orders List Report"
          />
        </div>
      </div>
    </div>
  );
}
