import { useContext, useEffect, useState } from "react";

import * as AppUrls from "constants/AppUrls";
import { Link, useHistory } from "react-router-dom";
import addIcon from "../../img/add-icon.svg";
import exportIcon from "img/export.svg";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import SetupForm from "pages/SystemSetup/SetupForm";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserTypeModel } from "./UserManagement.models";
import {
  createUserType,
  editUserType,
  getUserTypes,
} from "./UserManagement.services";

import AgGrid from "elements/AgGrid";

const UserTypeSetup = () => {
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: userTypes } = useQuery<UserTypeModel[], ErrorResponse>(
    ["get_user_types", filter],
    getUserTypes
  );
  const [dataGrid, setDataGrid] = useState(null);
  const methods = useForm<UserTypeModel>({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset, setValue } = methods;

  const addUserType = useMutation(createUserType, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `User Type ${CREATE_SUCCESS_MSG}`,
      };
      queryClient.invalidateQueries("get_user_types");
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding user type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateUserType = useMutation(editUserType, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      queryClient.invalidateQueries("get_user_types");
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing user type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: addUserType.isLoading });
  }, [addUserType.isLoading]);

  const columns = [
    {
      field: "name",
      headerName: "Type",
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
  ];

  const history = useHistory();

  const onRowDoubleClicked = (e) => {
    const url = `${AppUrls.users_type}/${e.userTypeId}`;
    history.push(url);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>User Types</p>
        <div>
          {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn}>
                  <img src={exportIcon} className="me-2" alt="" /> 
                  Export to Excel
              </button> */}
        </div>
        <div>
          <Link to={AppUrls.new_users_type} className="btn btn-primary">
            <img src={addIcon} className="me-2" alt="" /> New User Type
          </Link>
        </div>
      </div>

      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row h-100">
          <div className=" grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                data={userTypes}
                setDataGrid={setDataGrid}
                filters={filter}
                setrowClicked={onRowDoubleClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTypeSetup;
