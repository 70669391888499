import { GridColumns } from "components/Common.models";
import { useHistory } from "react-router";
import { typeEnum, InvoiceState } from "../../../Invoices/InvoiceEnums";
import exportIcon from "img/export.svg";
import { dateFormmaterNoTime, getCurrencyByCode } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { useQuery } from "react-query";
import { getBaseCurrency } from "components/currency";
const DebitCredits = ({ data = [], id = null }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const columns: GridColumns[] = [
    {
      field: "invoiceType",
      headerName: "Type",
      valueGetter: (params) => typeEnum[params.data.invoiceType],
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => InvoiceState[params.data.status],
    },
    {
      field: "date",
      headerName: "Inv. Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.date);
      },
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      valueGetter: (params) => {
        return `${currencySymbol}${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "taxAmount",
      headerName: "Tax Amount",
      valueGetter: (params) => {
        return `${currencySymbol}${
          params.data?.taxAmount?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      valueGetter: (params) => {
        return `${currencySymbol}${
          params.data?.paidAmount?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "invoiceBalance",
      headerName: "Inv. Balance",
      valueGetter: (params) => {
        return `${currencySymbol}${
          params.data?.invoiceBalance?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "salesOrder",
      headerName: "Sales Order",
    },
    {
      field: "refNumber",
      headerName: "Refno",
    },
    {
      field: "aging",
      headerName: "Aging",
    },
    {
      field: "batchNumber",
      headerName: "Batch No",
    },
  ];

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const reportClicked = () => {
    history.push(`/sales-marketing/customers/account-statement/${id}/report`);
  };

  return (
    <div className="h-350">
      <div className="d-flex justify-content-end mb-3">
        <button className="import-btn" type="button" onClick={reportClicked}>
          <img src={exportIcon} className="me-2" alt="" />
          Account Statement report
        </button>
        <button
          className="import-btn"
          type="button"
          onClick={exportToExcel}
          disabled={disabledExportBtn || data?.length === 0}
        >
          <img src={exportIcon} className="me-2" alt="" />
          Export to Excel
        </button>
      </div>
      <AgGrid
        columns={columns}
        data={data}
        exportToExcel={exportToExcelClicked}
        setExportToExcelClicked={setExportToExcelClicked}
        setDisabledExportBtn={setDisabledExportBtn}
        setIsLoadingGridExport={setIsLoadingGridExport}
        fileName="Account Debit/Credit List Report"
        autoSize={true}
      />
    </div>
  );
};

export default DebitCredits;
