import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { FormProvider, useForm } from "react-hook-form";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import {
  REQUIRED_FIELDS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { GridColumns } from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getBaseCurrency } from "components/currency";
import AG from "elements/AG";
import Lookup from "elements/Lookup";
import exportIcon from "img/export.svg";
import { getPurchaseOrdersListByVendor } from "pages/Purchasing/PurchasingServices/PurchaseServices";
import { VendorModelDTO } from "pages/Vendors/Vendors.models";
import { getVendors } from "pages/Vendors/Vendors.services";
import { useMutation, useQuery } from "react-query";
import { InvoiceState } from "./InvoiceEnums";
import {
  createReceiveInvoice,
  editBillAPI,
  editReceiveInvoice,
  getReceiveInvoiceById,
} from "./InvoicesServices/InvoicesServices";
import ReceiveInvoiceItemsModal from "./ItemsModel/ReceiveInvoiceItemsModel";

const CreateReceiveInvoice = ({ match }) => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    register,
    setValue,
    reset,
  } = methods;
  const editBillMutation = useMutation(editBillAPI);

  const { id } = useParams();

  const onEditBill = (values) => {
    let obj = {
      orderDate: new Date(),
      remarks: values.remarks,
      targetId: selectedVendor?.vendorId,
      purchaseOrders: selectedPosIds,
      items: addedItems.map((item) => {
        return {
          itemId: item.itemHubId,
          quantity: item.quantityReceived,
          price: item.cost,
          description: item.description,
          taxable: item.taxable ?? false,
          taxAmount: null,
          serials: item.serials.map((s) => s.serialId),
        };
      }),
      dueDate: new Date(),
      taxAmount,
    };
    if (id) {
      let newobj = { ...obj, id: id };
      editBillMutation.mutate(newobj);
      // editReceiveInvoiceM.mutate(newobj);
    } else {
      createReceiveInvoiceM.mutate(obj);
    }
  };
  //}

  const isEdit = match.path === AppUrls.edit_receive_invoice;

  const { data: invoiceData } = useQuery([id], getReceiveInvoiceById, {
    enabled: id !== undefined,
    onSuccess(data) {
      if (data.taxAmount) setTaxAmount(data.taxAmount);
    },
  });

  const { data: vendorsList } = useQuery("vendorsList", getVendors);

  const [openSelectionVendor, setOpenSelectionVendor] =
    useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<VendorModelDTO>(null);

  const [poList, setPoList] = useState([]);
  const [taxAmount, setTaxAmount] = useState("");

  const [addedItems, setAddedItems] = useState([]);

  const [selectedPos, setSelectedPos] = useState([]);
  const [selectedPosIds, setSelectedPosIds] = useState([]);

  useEffect(() => {
    if (selectedPos.length > 0) {
      setSelectedPosIds(selectedPos?.map((po) => po?.purchaseOrderId));
    }
  }, [selectedPos]);

  const purchaseOrdersMethod = useMutation(getPurchaseOrdersListByVendor, {
    onSuccess(response) {
      if (id) {
        setPoList(
          response.filter(
            (p) =>
              p.poState == 6 ||
              invoiceData.purchaseOrders.includes(p.purchaseOrderId)
          )
        );
      } else setPoList(response);
    },
  });

  useEffect(() => {
    if (invoiceData) {
      setSelectedVendor(
        vendorsList?.data?.find((v) => v.vendorId === invoiceData.targetId)
      );
      setAddedItems(
        invoiceData.items.map((item) => {
          return {
            itemHubId: item.itemHubId,
            itemNumber: item.itemNumber,
            description: item.description,
            serials: item.serials,
            cost: item.price,
            quantityReceived: item.quantity,
          };
        })
      );
    }
  }, [invoiceData]);

  useEffect(() => {
    if (selectedVendor) {
      setValue("targetId", selectedVendor.vendorId, { shouldDirty: true });
      setOpenSelectionVendor(false);
      if (id)
        purchaseOrdersMethod.mutate({
          id: selectedVendor?.vendorId,
          edit: false,
        });
      else
        purchaseOrdersMethod.mutate({
          id: selectedVendor?.vendorId,
          edit: true,
        });
    }
  }, [id, selectedVendor]);

  const createReceiveInvoiceM = useMutation(createReceiveInvoice, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: `Bill ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
  });
  const editReceiveInvoiceM = useMutation(editReceiveInvoice, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: `Bi;; ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
  });

  const onSubmit = (values) => {
    let obj = {
      orderDate: new Date(),
      remarks: values.remarks,
      targetId: selectedVendor?.vendorId,
      purchaseOrders: selectedPosIds,
      items: addedItems.map((item) => {
        return {
          itemId: item.itemHubId,
          quantity: item.quantityReceived,
          price: item.cost,
          description: item.description,
          taxable: item.taxable ?? false,
          taxAmount: null,
          serials: item.serials.map((s) => s.serialId),
        };
      }),
      dueDate: new Date(),
      taxAmount,
    };
    if (id) {
      let newobj = { ...obj, id: id };
      editReceiveInvoiceM.mutate(newobj);
    } else {
      createReceiveInvoiceM.mutate(obj);
    }
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const onBackClick = () => {
    history.push(AppUrls.receive_invoices_list);
  };

  const onCancel = () => {
    if (id) {
      onBackClick();
    }
    reset();
    setSelectedVendor(null);
    setSelectedPosIds([]);
    setSelectedPos([]);
    setPoList([]);
    setTaxAmount("");
  };

  const vendorColumns: GridColumns[] = [
    {
      field: "vendorNumber",
      headerName: "Vendor No",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "vendorTypeName",
      headerName: "Type",
    },
    {
      field: "creditLimit",
      headerName: "Credit Limit",
    },
  ];

  const poColumns = [
    {
      headerName: "",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
      width: "45",
      hide: id ? true : false,
    },
    {
      field: "purchaseOrderNumber",
      headerName: "PO No.",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "poDate",
      headerName: "PO Date",
      cellRenderer: (params) => {
        let date =
          params.data.poDate === null
            ? ""
            : dateFormmaterNoTime(params.data.poDate);
        return `
            <div>
              ${date}
            <div>
          `;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      editable: true,

      cellRenderer: (params) =>
        formatNumber(params.data?.amount, currencySymbol),
    },

    {
      field: "received",
      headerName: "Received",
      valueGetter: (params) => {
        return `${currencySymbol} ${
          params.data?.received?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "openAmount",
      headerName: "Open Amount",
      cellRenderer: (params) => {
        let openAmount = params.data.amount - params.data.received;
        return `
            ${formatNumber(openAmount, currencySymbol)}
          `;
      },
    },
    {
      field: "recDate",
      headerName: "Rec. Date",
      cellRenderer: (params) => {
        let recDate =
          params.data.recDate === null
            ? ""
            : dateFormmaterNoTime(params.data.recDate);
        return `
            <div>
            ${recDate}
            <div>
          `;
      },
    },
    {
      field: "marketCode",
      headerName: "Market Code",
      cellRenderer: (params) => {
        let marketCode =
          params.data.marketCode === null ? "" : params.data.marketCode;
        return `
            <div>
              ${marketCode}
            <div>
          `;
      },
    },
  ];

  const reportClicked = () => {
    history.push(`/vendors/receive-invoice-list/report/${id}`);
  };

  const onEdit = () => {
    history.push(`/vendors/receive-invoice-list/immediate/${id}`);
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div
              className="back-btn"
              onClick={() => history.push(AppUrls.receive_invoices_list)}
            >
              <img src={backArrow} alt="back arrow" />
              {id ? `Bill ${invoiceData?.invoiceNumber ?? ""}` : ` Create Bill`}
            </div>
            <div>
              {id && invoiceData && (
                <>
                  <button
                    className="import-btn"
                    type="button"
                    onClick={reportClicked}
                  >
                    <img src={exportIcon} className="me-2" alt="" />
                    View Report
                  </button>
                  {invoiceData.immediate ? (
                    <button className="btn btn-primary" onClick={onEdit}>
                      Edit
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={onEditBill}>
                      Save changes
                    </button>
                  )}
                </>
              )}

              {!id ? (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-primary no-border ${
                      !isDirty && !id && "text-muted"
                    }`}
                    disabled={!isDirty && !id}
                    onClick={onCancel}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={!isDirty || selectedPos.length === 0}
                  >
                    Create
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group w-75">
                        <label>
                          Vendor No. <span className="text-danger">*</span>
                        </label>
                        <Lookup
                          onButtonClicked={() => setOpenSelectionVendor(true)}
                          inputName="targetId"
                          isRequired={true}
                          initialData={vendorsList?.data?.map((item) => ({
                            ...item,
                            id: item.vendorId,
                            name: item.vendorNumber,
                          }))}
                          onSelection={setSelectedVendor}
                          inputValue={selectedVendor?.vendorNumber}
                          isDisabled={id}
                        />
                        {errors["targetId"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group mt-2 w-100">
                        <label>Bill Date</label>
                        <p>{dateFormmaterNoTime(new Date())}</p>
                      </div>
                    </div>
                    {id && (
                      <div className="col-6">
                        <div className="form-group mt-2 w-100">
                          <label>Status</label>
                          <p>{InvoiceState[invoiceData?.invoiceState]}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-8">
                  {id ? (
                    <>
                      <div className="form-group">
                        <label>Remarks</label>
                        <p>{invoiceData?.remarks}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group mw-100">
                        <label>Remarks</label>
                        <textarea
                          {...register("remarks")}
                          className="form-control"
                          rows={3}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-12">
                  <div className="form-group mt-2 w-100">
                    <label>Tax Amount</label>
                    <input
                      type="number"
                      value={taxAmount}
                      onChange={(e) => setTaxAmount(e.target.value)}
                      className="form-control"
                      disabled={id}
                    />
                  </div>
                </div>
                {invoiceData?.immediate && invoiceData?.currency && (
                  <div className="col-lg-2 col-sm-12">
                    <div className="form-group mt-2 w-100">
                      <label>Currency</label>
                      <div>
                        {invoiceData?.currency?.name}{" "}
                        {invoiceData?.currency?.symbol}
                      </div>
                    </div>
                  </div>
                )}
                {invoiceData?.immediate && invoiceData?.currency && (
                  <div className="col-lg-2 col-sm-12">
                    <div className="form-group mt-2 w-100">
                      <label>Exchange Rate</label>
                      <div>{invoiceData?.rate}</div>
                    </div>
                  </div>
                )}
              </div>
              {!invoiceData?.immediate && (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="mt-3">
                      <div className="row section px-0 ps-2 py-3 pb-0 ">
                        <div className="ps-0">
                          <h4>
                            <b>Purchase Order List</b>
                          </h4>
                        </div>
                      </div>
                      <div className="h-350">
                        <AG
                          data={poList}
                          columns={poColumns}
                          autoColumns={true}
                          setRowChecked={setSelectedPos}
                          rowSelection="multiple"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-12">
                  <div className="h-350 mt-4">
                    <ReceiveInvoiceItemsModal
                      addedItems={addedItems}
                      setAddedItems={setAddedItems}
                      selectedPos={selectedPosIds}
                      isEdit={isEdit}
                      tax={Number(taxAmount)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Vendor"}
        showModal={openSelectionVendor}
        setShowModal={setOpenSelectionVendor}
        data={vendorsList?.data}
        columns={vendorColumns}
        setRowClicked={setSelectedVendor}
      />
    </>
  );
};

export default CreateReceiveInvoice;
