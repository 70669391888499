import { useContext, useState } from "react";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { formatNumber, notificationMessage } from "global/helpers";

import { addInvoiceRma, approveRma, creditRma, getRmaDetails, refundRma, rejectRma, voidRma } from "./RmaServices/RmaServices";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { getInvoices } from "pages/Invoices/InvoicesServices/InvoicesServices";
import DepositModal from "./DepositModal";

enum RMAStatus {
    Pending = 1,
    Approved = 2,
    Rejected = 3,
    Voided = 4,
    Closed = 5,
    Received = 6,
    Credited = 7,
    Refunded = 8,
    "Partially Received" = 9,
}

const ViewRma = () => {

    const history = useHistory()
    const { id } = useParams();
    const appDispatch = useContext(DispatchContext)
    const [showModal, setShowModal] = useState<boolean>(false)

    const onBackClick = () => {
        history.push(AppUrls.customer_rma)
    }

    const { data } = useQuery([id], getRmaDetails);

    const dataMutation = useMutation(getInvoices, {
        onSuccess(res) {
            let found = res.data?.find(invoice => invoice.salesOrder === data?.orderNumber)
            if (found)
                invoiceMutation.mutate({ rmaId: data?.rmaId, invoiceId: found?.invoiceId })
            else {
                let notification = {
                    variant: "danger",
                    msg: "No invoice found for this RMA"
                };
                appDispatch({ type: "notification", value: notification })
            }
        },
    });


    const approveMutation = useMutation(approveRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `RMA approved successfully`
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.customer_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'Problem appproving rma')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const invoiceMutation = useMutation(addInvoiceRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Invoice added successfully`
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.customer_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'Problem adding invoice')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const rejectMutation = useMutation(rejectRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `RMA rejected successfully`
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.customer_rma)

        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'Problem rejecting rma')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const voidMutation = useMutation(voidRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `RMA voided successfully`
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.customer_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'Problem voiding rma')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const refundMutation = useMutation(refundRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `RMA refunded successfully`
            };
            appDispatch({ type: "notification", value: notification })
            history.push(AppUrls.customer_rma)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'Problem refunding rma')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const creditMutation = useMutation(creditRma, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `RMA crredited successfully`
            };
            appDispatch({ type: "notification", value: notification })
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'Problem crediting rma')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });


    const columns = [
        {
            field: "itemNumber",
            headerName: "Item Number",
        },
        {
            field: "description",
            headerName: "Item Description",
        },
        {
            field: "quantity",
            headerName: "RMA QTY",
        },
        {
            field: "price",
            headerName: "Price",
            valueGetter: (params) => { return `$${params.data?.price?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "subtotal",
            headerName: "Subtotal",
            cellRendererFramework: (params) => formatNumber(params.data.quantity * params.data.price),
        },
        {
            field: "receivedQuantity",
            headerName: "Received QTY",
        },
        {
            field: "writeOffQuantity",
            headerName: "Write Off QTY",
        },
        {
            field: "serials",
            headerName: "Serials",
            cellRendererFramework: (params) => params.value?.map(serial => serial.serialNo),
        },
    ];


    const onApprove = () => {
        approveMutation.mutate(id)
    }

    const onReject = () => {
        rejectMutation.mutate(id)
    }

    const onVoid = () => {
        voidMutation.mutate(id)
    }

    const onRefund = () => {
        setShowModal(true)
    }

    const onRefundConfirm = (refundId: number) => {
        setShowModal(false)
        refundMutation.mutate({ rmaId: id, refundFrom: refundId })
    }

    const onCredit = () => {
        creditMutation.mutate(id)
        onReceiveRma()
    }

    const onAddInvoice = () => {
        dataMutation.mutate({})
    }

    const onReceiveRma = () => {
        history.push({
            pathname: AppUrls.receive_customer_rma,
            search: `?rmaId=${id}`,
        });
    }

    return (
        <>
            <div className="static-page">
                <div className="page-title page-title-editable">
                    <div className="back-btn " onClick={onBackClick}>
                        <img src={backArrow} alt="back arrow" />
                        {data?.rmaNumber}
                    </div>
                    <div>
                        {data?.status === 1 &&
                            <>
                                <button className="btn btn-danger" onClick={onReject}>Reject</button>
                                <button className="btn btn-success" onClick={onApprove}>Approve</button>
                            </>
                        }
                        {((data?.status === 6) || (data?.status === 9)) &&
                            <button className="btn btn-danger" onClick={onVoid}>Void</button>
                        }
                        {data?.status === 2 &&
                            <button className="btn btn-success" onClick={onReceiveRma}>Start Receiving</button>
                        }
                        {data?.status === 6 &&
                            (
                                data?.invoiceNumber === null ?
                                    <button className="btn btn-primary" onClick={onAddInvoice}>Add Invoice</button>
                                    :
                                    <>
                                        <button className="btn btn-primary" onClick={onRefund}>Refund</button>
                                        <button className="btn btn-primary" onClick={onCredit}>Credit</button>
                                    </>
                            )
                        }
                    </div>
                </div>
                <div className="page-content-wrapper">
                    <div className="page-content">
                        <div className="row  gx-5">
                            <div className="col-lg-8 col-sm-12">
                                <div className="row gx-5 gy-4">
                                    <div className="col-lg-6 col-sm-12">
                                        <label>RMA No.</label>
                                        <div>{data?.rmaNumber}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>RMA Date</label>
                                        <div>{data?.date && new Date(data?.date).toLocaleString()}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Sales Order No.</label>
                                        <div>{data?.orderNumber}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Invoice No.</label>
                                        <div>{data?.invoiceNumber}</div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <label>Hub ID</label>
                                        <div>{data?.hubKey}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Customer No.</label>
                                        <div>{data?.accountNumber}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Company</label>
                                        <div>{data?.shippingInfo?.company}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Origin</label>
                                        <div>{data?.origin === 1 ? "Sytem" : "Web"}</div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12">
                                        <label>Status</label>
                                        <div>{RMAStatus[data?.status]}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <div className='row mt-4'>
                                    <div className='col-lg-6 col-sm-12 px-4'>
                                        <div className='d-flex'>
                                            <div className='text-secondary'>TOTAL QTY</div>
                                            <div className='flex-grow-1 text-center'>{data?.totalQuantity}</div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-sm-12'>
                                        <div className='d-flex'>
                                            <div className='text-secondary'>TOTAL ITEMS</div>
                                            <div className='flex-grow-1 text-center'>{data?.totalItems}</div>
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <hr />
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className='section mt-3 p-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='fs-5'>ORDER TOTAL</div>
                                                <div className='text-success fs-4'>{formatNumber(data?.subtotal)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-450 mt-5">
                            <AgGrid data={data?.items} columns={columns} />
                        </div>
                    </div>
                </div>
            </div>
            <DepositModal
                showModal={showModal}
                onExit={() => setShowModal(false)}
                onCreation={onRefundConfirm}
            />
        </>
    )
}

export default ViewRma