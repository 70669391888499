export const PurchaseOrderStates = {
    1: 'Open',
    2: 'Received',
    3: 'Partial Received',
    4: 'Voided',
    5: 'All',
    6: 'Approved',
    7: 'Invoiced',
    8: 'Fulfilled',
    9: 'PartiallyFulfilled'
}

export const POTypes = {
    1: 'All',
    2: 'Normal',
    3: 'Return',
}
