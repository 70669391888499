import { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router";
import Close from "img/close.svg";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import SearchIcon from "img/search-group.png";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Tabs, Tab } from "react-bootstrap";
import ItemInformation from "./ItemInformation";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { useQuery, useMutation } from "react-query";
import {
  createSerializedItem,
  getSerializedItem,
  getSerializedInit,
  editSerializedItem,
  getNonSerializedInit,
  createNonSerializedItem,
  getNonSerializedItem,
  editNonSerializedItem,
  deleteSerializedItem,
  deleteNonSerializedItem,
  GetSerialsByItemId,
  uploadPortalImage,
  getItemPopUps,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { Modal } from "react-bootstrap";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  notificationMessage,
  checkIfAllowed,
  dateFormmaterNoTime,
  formatNumber,
} from "global/helpers";
import PortalSettings from "./PortalSettings";
// import BOMPartList from './BOMPartList';
// import StoreItemFilter from './StoreItemFilter';
// import ContabilityList from './ContabilityList';
// import ItemShelves from './ItemShelves';
// import ItemSKUs from './ItemSKUs';
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import {
  inventoryItemForm,
  inventoryItemInit,
  nonSerializedItem,
  serializedItem,
} from "../Inventory.models";
import AG from "elements/AG";
import { getLedgers } from "pages/Accounting/AccountingServices";
import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import StartingBalance from "./StartingBalance";
import ClassesModal from "./ClassesModal";
import ItemAttributes from "./ItemAttributes";
import { getItemCategories } from "../InventorySetup/InventorySetup.services";
import { useLocation } from "react-router-dom";

const InventoryItem = ({ match }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [initList, setInitList] = useState<inventoryItemInit>(null);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [startingBalance, setStartingBalance] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const modalType = useRef(null);

  // const [bomPartsList, setBomPartsList] = useState([]);
  // const [storeFilterIdsList, setStoreFilterIdsList] = useState([]);
  // const [itemSKUsList, setItemSKUsList] = useState([]);

  const [customIsDirty, setCustomIsDirty] = useState<boolean>(false);
  const location = useLocation();
  const appDispatch = useContext(DispatchContext);

  useEffect(() => {
    if (location?.state?.category) {
      const { category } = location.state;

      if (category && category[0] && category[0].value) {
        const filteredCategory = categories.filter(
          (cat) => cat.value === category[0].value
        );
        setSelectedCategory(filteredCategory[0]);
      }
    }
  }, [location.state]);

  const appState = useContext(StateContext);
  const history = useHistory();
  const { id } = useParams();

  const serialsColumns = [
    {
      field: "serialNo",
      headerName: "Serial Number",
    },
    {
      field: "purchaseOrder.purchaseOrderNumber",
      headerName: "Purchase Order",
    },
    {
      field: "scanDate",
      headerName: "Scan Date",
      cellRenderer: (params) =>
        params.value ? `${dateFormmaterNoTime(params.value)}` : "",
    },
  ];

  const isSerialized =
    match.path === AppUrls.new_inventory_serialized ||
    match.path === AppUrls.inventory_serialized_details;

  const { data: paymentAccountsInv } = useQuery(
    "inventory",
    () => getLedgers({ ledgerType: LedgerTypeEnum.Inventory }),
    {
      onSuccess(response) {
        let { data } = response;
        if (!id && data.length > 0) onInvAssetAccountSelection(data[0]);
      },
    }
  );

  const { data: paymentAccountsIncome } = useQuery(
    "income",
    () => getLedgers({ ledgerType: LedgerTypeEnum.Sales }),
    {
      onSuccess(response) {
        let { data } = response;
        if (!id && data.length > 0) onIncomeAccountIdSelection(data[0]);
      },
    }
  );

  const { data: paymentAccountsEpense } = useQuery(
    "expenses",
    () => getLedgers({ ledgerType: LedgerTypeEnum.CostOfSales }),
    {
      onSuccess(response) {
        let { data } = response;
        if (!id && data.length > 0) onExpenseAccountIdSelection(data[0]);
      },
    }
  );

  const { data: serialsList } = useQuery([[id]], GetSerialsByItemId, {
    enabled: isSerialized,
  });

  const {
    isSuccess: isSuccessSerializedInit,
    data: serializedInitList,
    refetch: refetchSerialized,
  } = useQuery<inventoryItemInit>("init", getSerializedInit, {
    enabled: isSerialized,
  });

  const {
    isSuccess: isSuccessNonSerializedInit,
    data: nonSerializedInitList,
    refetch: refetchNonSerialized,
  } = useQuery<inventoryItemInit>("init", getNonSerializedInit, {
    enabled: !isSerialized,
  });

  const {
    isSuccess: isSuccessSerialized,
    data: currentSerialized,
    isLoading: isLoadingCurrentSerialized,
  } = useQuery<serializedItem>([id], getSerializedItem, {
    enabled: isSerialized && id !== undefined ? true : false,
  });

  const {
    isSuccess: isSuccessNonSerialized,
    data: currentNonSerialized,
    isLoading: isLoadingCurrentNonSerialized,
  } = useQuery<nonSerializedItem>([id], getNonSerializedItem, {
    enabled: !isSerialized && id !== undefined ? true : false,
  });

  const { data: categories } = useQuery("item-categories", getItemCategories, {
    select(data) {
      let flatCategories = [];
      const transformCategories = (data) => {
        if (data && data.length > 0) {
          for (let category of data) {
            if (category.isLeaf) flatCategories.push(category);
            if (category.children?.length > 0) {
              transformCategories(category.children);
            }
          }
        }
      };
      transformCategories(data);
      return flatCategories;
    },
  });

  const methods = useForm<inventoryItemForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      item: {
        isActive: true,
        isRetail: false,
        sellingQuantity: null,
      },
    },
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    handleSubmit,
    reset,
    register,
    getValues,
    setValue,
    control,
  } = methods;

  useEffect(() => {
    if (isDirty) setCustomIsDirty(true);
  }, [isDirty]);

  const onBackClick = () => {
    if (isDirty && !submitted) setOpenConfirmModal(true);
    else history.push({ pathname: AppUrls.inventory_list, state: "filter" });
  };

  const onEditClick = (e) => {
    e.preventDefault();
    setIsDisabled(false);
    setSubmitted(false);
  };

  const cancelForm = () => {
    reset();
    setImgThumbnail(null);
    if (id) {
      setIsDisabled(true);
      // if (isSerialized) {
      //     setBomPartsList(currentSerialized.bomParts)
      //     setStoreFilterIdsList(currentSerialized.storeFilters)
      // } else {
      //     setStoreFilterIdsList(currentNonSerialized.storeFilters)
      //     setItemSKUsList(currentNonSerialized.itemSKUs ? currentNonSerialized.itemSKUs : [])
      // }
    }
  };

  const handleDeleteItem = () => {
    setShowDelete(false);
    if (isSerialized) {
      removeSerializedItem.mutate(currentSerialized.serializedItemId);
    } else
      removeNonSerializedItem.mutate(currentNonSerialized.nonSerializedItemId);
  };

  useEffect(() => {
    if (isSerialized && isSuccessSerialized && categories) {
      // reset({
      //     // onlineDescription: currentSerialized.portalSettings?.onlineDescription,
      // })
      reset(currentSerialized);
      setAttributes(currentSerialized?.item?.attributeValues);
      let category = categories.find(
        (category) =>
          category.value === currentNonSerialized.item.itemCategoryId
      );
      if (category) setSelectedCategory(category);
      setValue(
        "invAssetAccount",
        currentSerialized?.item?.inventoryAssetAccountId
      );
      setValue("incomeAccountId", currentSerialized?.item?.incomeAccountId);
      setValue("expenseAccountId", currentSerialized?.item?.expenseAccountId);
      setSelectedExpenseAccountId(
        paymentAccountsEpense?.data?.find(
          (e) => e.ledgerId === currentSerialized?.item?.expenseAccountId
        )
      );
      setSelectedIncomeAccountId(
        paymentAccountsIncome?.data?.find(
          (e) => e.ledgerId === currentSerialized?.item?.incomeAccountId
        )
      );
      setSelectedInvAssetAccount(
        paymentAccountsInv?.data?.find(
          (e) => e.ledgerId === currentSerialized?.item?.inventoryAssetAccountId
        )
      );
      if (currentSerialized?.item?.serialLength) {
        let splitted = currentSerialized?.item?.serialLength
          .toString()
          .split("-");
        setValue(
          "serialLength1",
          isNaN(parseInt(splitted[0])) ? null : parseInt(splitted[0])
        );
        setValue(
          "serialLength2",
          isNaN(parseInt(splitted[1])) ? null : parseInt(splitted[1])
        );
        setValue(
          "serialLength3",
          isNaN(parseInt(splitted[2])) ? null : parseInt(splitted[2])
        );
        // setValue("serialLength4", isNaN(parseInt(splitted[3])) ? null : parseInt(splitted[3]))
      }
      // if (currentSerialized.bomParts) {
      //     setBomPartsList(currentSerialized.bomParts)
      // }
      // if (currentSerialized.storeFilters) {
      //     setStoreFilterIdsList(currentSerialized.storeFilters)
      // }
    }
  }, [
    isSuccessSerialized,
    currentSerialized,
    isSerialized,
    paymentAccountsInv,
    paymentAccountsIncome,
    paymentAccountsEpense,
    categories,
  ]);

  useEffect(() => {
    if (!isSerialized && isSuccessNonSerialized && categories) {
      // reset({
      //     onlineDescription: currentSerialized.portalSettings?.onlineDescription,
      // })
      reset({ ...currentNonSerialized, ...currentNonSerialized.item });
      setAttributes(currentNonSerialized?.item?.attributeValues);
      let category = categories.find(
        (category) =>
          category.value === currentNonSerialized.item.itemCategoryId
      );
      if (category) setSelectedCategory(category);
      setValue(
        "invAssetAccount",
        currentNonSerialized?.item?.inventoryAssetAccountId
      );
      setValue("incomeAccountId", currentNonSerialized?.item?.incomeAccountId);
      setValue(
        "expenseAccountId",
        currentNonSerialized?.item?.expenseAccountId
      );
      setSelectedExpenseAccountId(
        paymentAccountsEpense?.data?.find(
          (e) => e.ledgerId === currentNonSerialized?.item?.expenseAccountId
        )
      );
      setSelectedIncomeAccountId(
        paymentAccountsIncome?.data?.find(
          (e) => e.ledgerId === currentNonSerialized?.item?.incomeAccountId
        )
      );
      setSelectedInvAssetAccount(
        paymentAccountsInv?.data?.find(
          (e) =>
            e.ledgerId === currentNonSerialized?.item?.inventoryAssetAccountId
        )
      );
      // if (currentNonSerialized.storeFilters) {
      //     setStoreFilterIdsList(currentNonSerialized.storeFilters)
      // }
      // if (currentNonSerialized.itemSKUs) {
      //     setItemSKUsList(currentNonSerialized.itemSKUs ? currentNonSerialized.itemSKUs : [])
      // }
    }
  }, [
    isSuccessNonSerialized,
    currentNonSerialized,
    isSerialized,
    paymentAccountsInv,
    paymentAccountsIncome,
    paymentAccountsEpense,
    categories,
  ]);
  useEffect(() => {
    if (id) {
      setIsDisabled(true);
    }
  }, [id]);

  useEffect(() => {
    if (isSuccessSerializedInit) setInitList(serializedInitList);
  }, [isSuccessSerializedInit, serializedInitList]);

  useEffect(() => {
    if (isSuccessNonSerializedInit) setInitList(nonSerializedInitList);
  }, [isSuccessNonSerializedInit, nonSerializedInitList]);

  const [imgThumbnail, setImgThumbnail] = useState(null);

  const uploadImage = useMutation(uploadPortalImage);

  const addSerializedItem = useMutation(createSerializedItem, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `Item ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      setSubmitted(true);
      if (response?.data?.serializedItemId) {
        if (imgThumbnail) {
          uploadImage.mutate({
            itemId: response.data.serializedItemId,
            file: imgThumbnail,
          });
          history.push(
            `${AppUrls.new_inventory_serialized}/${response.data.serializedItemId}`
          );
        } else {
          history.push(
            `${AppUrls.new_inventory_serialized}/${response.data.serializedItemId}`
          );
        }
      }
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const addNonSerializedItem = useMutation(createNonSerializedItem, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `Item ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      setSubmitted(true);
      if (response?.data?.nonSerializedItemId) {
        if (imgThumbnail) {
          uploadImage.mutate({
            itemId: response.data.serializedItemId,
            file: imgThumbnail,
          });
          history.push(
            `${AppUrls.new_inventory_nonserialized}/${response.data.nonSerializedItemId}`
          );
        } else {
          history.push(
            `${AppUrls.new_inventory_nonserialized}/${response.data.nonSerializedItemId}`
          );
        }
      }
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateSerializedItem = useMutation(editSerializedItem, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Item ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSubmitted(true);
      setIsDisabled(true);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing Item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateNonSerializedItem = useMutation(editNonSerializedItem, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Item ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSubmitted(true);
      setIsDisabled(true);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing Item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeSerializedItem = useMutation(deleteSerializedItem, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      setTimeout(() => {
        history.push(AppUrls.inventory_list);
      }, 1000);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Sorry something went wrong."),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeNonSerializedItem = useMutation(deleteNonSerializedItem, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      setTimeout(() => {
        history.push(AppUrls.inventory_list);
      }, 1000);
    },
  });

  function getSerialLength(_value1, _value2, _value3, _value4) {
    let serials = [];
    for (let serial of arguments) if (serial) serials.push(serial);
    return serials?.join("-");
  }

  // const getItemNotesLength = () => {
  //     return isSerialized ? currentSerialized?.item.notes.length : currentNonSerialized?.item.notes.length
  // }

  // const getItemId = () => {
  //     return isSerialized ? currentSerialized?.item.itemId : currentNonSerialized?.item.itemId
  // }

  // const getItemNotes = () => {
  //     return isSerialized ? currentSerialized?.item.notes : currentNonSerialized?.item.notes
  // }

  const [openInvAssetAccount, setOpenInvAssetAccount] =
    useState<boolean>(false);
  const [selectedInvAssetAccount, setSelectedInvAssetAccount] = useState(null);
  const [openIncomeAccountId, setOpenIncomeAccountId] =
    useState<boolean>(false);
  const [selectedIncomeAccountId, setSelectedIncomeAccountId] = useState(null);
  const [openExpenseAccountId, setOpenExpenseAccountId] =
    useState<boolean>(false);
  const [selectedExpenseAccountId, setSelectedExpenseAccountId] =
    useState(null);

  const onInvAssetAccountSelection = (e) => {
    setSelectedInvAssetAccount(e);
    setOpenInvAssetAccount(false);
    setValue("invAssetAccount", e.ledgerId, { shouldValidate: true });
  };
  const onIncomeAccountIdSelection = (e) => {
    setSelectedIncomeAccountId(e);
    setOpenIncomeAccountId(false);
    setValue("incomeAccountId", e.ledgerId, { shouldValidate: true });
  };
  const onExpenseAccountIdSelection = (e) => {
    setSelectedExpenseAccountId(e);
    setOpenExpenseAccountId(false);
    setValue("expenseAccountId", e.ledgerId, { shouldValidate: true });
  };

  const onSubmit = (values) => {
    let newData = {
      ...values,
      warrantyDays1: values.warrantyDays1 !== "" ? values.warrantyDays1 : null,
      warrantyDays2: values.warrantyDays2 !== "" ? values.warrantyDays2 : null,
      warrantyDays3: values.warrantyDays3 !== "" ? values.warrantyDays3 : null,
      item: {
        ...values.item,
        sellingQuantity: values.item.sellingQuantity ?? null,
        weight: values.item.weight ?? null,
        isTaxable: values.item.isTaxable,
        isRetail: values.item.isRetail,
        inventoryAssetAccountId: selectedInvAssetAccount.ledgerId,
        incomeAccountId: selectedIncomeAccountId.ledgerId,
        expenseAccountId: selectedExpenseAccountId.ledgerId,
        attributeValues: attributes
          .filter((attribute) => attribute.value)
          .map((attribute) => ({
            attributeId: attribute.itemCategoryAttributeId,
            value: attribute.value,
          })),
      },
      portalSettings: {
        ...values.portalSettings,
        hotItem:
          values.portalSettings.hotItem === null
            ? false
            : values.portalSettings.hotItem,
        newArrival:
          values.portalSettings.newArrival === null
            ? false
            : values.portalSettings.newArrival,
        clearance:
          values.portalSettings.clearance === null
            ? false
            : values.portalSettings.clearance,
      },
      bomParts: [],
      compatibleModelsIds: [],
      storeFilterIds: [],
    };
    if (!id) {
      let balances = [];
      if (values.balancingAccountId)
        balances = startingBalance.filter(
          (balance) => balance.itemCost !== "" && balance.quantity !== ""
        );
      newData = {
        ...newData,
        item: {
          ...newData.item,
          hubsIds: appState.selectedHubs.map((hub) => hub.locationId),
          hubStartingBalances: balances,
          balancingAccountId: values.balancingAccountId,
        },
      };
    }
    if (isSerialized) {
      newData = {
        ...newData,
        item: {
          ...newData.item,
          serialLength: getSerialLength(
            values.serialLength1,
            values.serialLength2,
            values.serialLength3,
            values.serialLength4
          ),
        },
        // bomParts: bomPartsList.map(item => {
        //     return {
        //         "bomPartItemId": item.itemId || item.bomPartItemId,
        //         "usage": item.usage,
        //         "maxFill": item.maxFill,
        //     }
        // }),
        // storeFilterIds: storeFilterIdsList.map(item => {
        //     return Number(item.customerAccountId)
        // }),
      };
    }
    // newData = {
    //     ...newData,
    //     compatibleModelsIds: dataSelected.map(item => item.itemModelId)
    // }
    if (id) {
      // newData.item.storeFilterIds = storeFilterIdsList;
      if (imgThumbnail) {
        uploadImage.mutate({
          itemId: currentSerialized.item.itemId,
          file: imgThumbnail,
        });
      }
      if (isSerialized) {
        // let isValid = true;
        // for (let bomPart of bomPartsList) {
        //     if (isNaN(bomPart.usage) || isNaN(bomPart.maxFill)) {
        //         isValid = false;
        //         break;
        //     }
        // }
        // if (isValid) {
        //     newData.item.bomParts = bomPartsList;
        // } else {
        //     let notification = {
        //         variant: "danger",
        //         msg: 'Max Fill and Usage accept numbers only'
        //     };
        //     appDispatch({ type: "notification", value: notification })
        // }
        newData["serializedItemId"] = currentSerialized.serializedItemId;
        newData.item.itemId = currentSerialized.item.itemId;
        updateSerializedItem.mutate(newData);
      } else {
        newData["nonSerializedItemId"] =
          currentNonSerialized.nonSerializedItemId;
        newData.item.itemId = currentNonSerialized.item.itemId;
        updateNonSerializedItem.mutate(newData);
      }
    } else {
      if (isSerialized) addSerializedItem.mutate(newData);
      else addNonSerializedItem.mutate(newData);
    }
  };

  // useEffect(() => {
  //     if (bomPartsList) {
  //         const res1 = bomPartsList.filter((item1) =>
  //             !currentSerialized.bomParts.find(item2 => item1.itemId === item2.itemId)
  //         )
  //         if (res1.length > 0) {
  //             setCustomIsDirty(true);
  //         }
  //     }
  //     if (storeFilterIdsList) {
  //         let res2 = [];
  //         if (isSerialized) {
  //             res2 = storeFilterIdsList.filter((stored1) =>
  //                 !currentSerialized.storeFilterIds.find(stored2 => stored1.accountNumber === stored2.accountNumber))
  //         } else {
  //             res2 = storeFilterIdsList.filter((stored1) =>
  //                 !currentNonSerialized.storeFilterIds.find(stored2 => stored1.accountNumber === stored2.accountNumber))
  //         }
  //         if (res2.length > 0) {
  //             setCustomIsDirty(true);
  //         }
  //     }
  //     if (itemSKUsList) {
  //         const res3 = itemSKUsList.filter((sku1) =>
  //             !currentNonSerialized.itemSKUs.find(sku2 => sku1.accountNumber === sku2.accountNumber)
  //         )
  //         if (res3.length > 0) {
  //             setCustomIsDirty(true);
  //         }
  //     }
  // }, [bomPartsList, storeFilterIdsList, itemSKUsList])

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        isLoadingCurrentSerialized ||
        isLoadingCurrentNonSerialized ||
        addSerializedItem.isLoading ||
        addNonSerializedItem.isLoading ||
        updateSerializedItem.isLoading ||
        updateNonSerializedItem.isLoading ||
        removeSerializedItem.isLoading ||
        removeNonSerializedItem.isLoading,
    });
  }, [
    isLoadingCurrentSerialized,
    isLoadingCurrentNonSerialized,
    addSerializedItem.isLoading,
    addNonSerializedItem.isLoading,
    updateSerializedItem.isLoading,
    updateNonSerializedItem.isLoading,
    removeSerializedItem.isLoading,
    removeNonSerializedItem.isLoading,
    appDispatch,
  ]);

  const hubInvColumns = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: "hubKey",
      headerName: "Hub Id",
    },
    {
      field: "hubDescription",
      headerName: "Hub Description",
    },
    {
      field: "itemPrice",
      headerName: "Item Price",
    },
    {
      field: "onHand",
      headerName: "On Hand",
    },
    {
      field: "onPO",
      headerName: "On PO",
    },
    {
      field: "onSO",
      headerName: "On SO",
    },
  ];

  const pendingPOsColumns = [
    {
      field: "purcahseOrderNumber",
      headerName: "PO Number",
    },
    {
      field: "hubKey",
      headerName: "Hub Id",
    },
    {
      field: "quantityOpen",
      headerName: "Qty Open",
    },
    {
      field: "quantityOrdered",
      headerName: "Qty Ordered",
    },
    {
      field: "quantityReceived",
      headerName: "Qty Received",
    },
    {
      field: "vendor",
      headerName: "Vendor",
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value) ?? "",
    },
  ];

  const pendingSOsColumns = [
    {
      field: "accountNumber",
      headerName: "Account Number",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "hubKey",
      headerName: "Hub Id",
    },
    {
      field: "quantityOpen",
      headerName: "Qty Open",
    },
    {
      field: "quantityOrdered",
      headerName: "Qty Ordered",
    },
    {
      field: "quantityShipped",
      headerName: "Qty Shipped",
    },
    {
      field: "vendor",
      headerName: "Vendor",
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value) ?? "",
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const [popUpData, setPopUpData] = useState(null);

  const FetchPopUpsData = useMutation(getItemPopUps, {
    async onSuccess(response) {
      setPopUpData(response);
    },
  });

  useEffect(() => {
    if (id) FetchPopUpsData.mutate(id);
  }, [id]);

  const [showOnHandPopUp, setShowOnHandPopUp] = useState<boolean>(false);
  const [showOnPOPopUp, setShowOnPOPopUp] = useState<boolean>(false);
  const [showOnSOPopUp, setShowOnSOPopUp] = useState<boolean>(false);

  const onTypeCreated = (id) => {
    if (modalType.current === 1)
      setValue("item.itemClassId", id, { shouldDirty: true });
    else if (modalType.current === 3)
      setValue("item.categoryId", id, { shouldDirty: true });
    if (isSerialized) refetchSerialized();
    else refetchNonSerialized();
    setShowCreationModal(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              {isSerialized &&
                (isDisabled
                  ? getValues("item.itemNumber")
                  : id
                  ? "Editing Serialized Item"
                  : "Adding Serialized Item")}
              {!isSerialized &&
                (isDisabled
                  ? getValues("item.itemNumber")
                  : id
                  ? "Editing Non-Serialized Item"
                  : "Adding Non-Serialized Item")}
            </div>
            <div className="d-flex justify-content-between ">
              {isDisabled ? (
                <>
                  {/* {checkIfAllowed(appState.allowedUrls, "delete", match.path) &&
                                        <button type='button' className='btn fw-normal fs-6 d-flex align-items-center text-danger' onClick={() => setShowDelete(true)}>
                                            <img src={deleteIcon} className='me-2' alt="delete" />
                                            Delete
                                        </button>
                                    } */}
                  {checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                    <button
                      type="button"
                      className="btn btn-outline-primary custom-outline"
                      onClick={() => {
                        if (isSerialized)
                          history.push({
                            pathname: `${AppUrls.new_inventory_serialized}/${id}/item-hubs`,
                            state: {
                              itemNumber: getValues("item.itemNumber"),
                            },
                          });
                        else
                          history.push({
                            pathname: `${AppUrls.new_inventory_nonserialized}/${id}/item-hubs`,
                            state: {
                              itemNumber: getValues("item.itemNumber"),
                            },
                          });
                      }}
                    >
                      Hubs
                    </button>
                  )}
                  {checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onEditClick}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-primary no-border ${
                      !isDirty && !id && "text-muted"
                    }`}
                    onClick={cancelForm}
                    disabled={!isDirty && !id}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!customIsDirty || isSubmitSuccessful}
                  >
                    {id ? "Save Changes" : "Create"}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row  gx-5 inventory-item">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row gx-5">
                    <div className="col-lg-6">
                      <label>
                        Item Number <span className="text-danger">*</span>
                      </label>
                      <input
                        name="itemNumber"
                        type="text"
                        className={`form-control ${
                          errors["item"] &&
                          errors["item"]["itemNumber"] &&
                          "required_field"
                        }`}
                        {...register("item.itemNumber", { required: true })}
                        disabled={isDisabled}
                      />
                      {errors["item"] && errors["item"]["itemNumber"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label className="label mb-2">Status</label>
                      {isDisabled ? (
                        getValues("item.isActive") === true ? (
                          <div className="active-status">active</div>
                        ) : (
                          <div className="disabled-status">disabled</div>
                        )
                      ) : (
                        <Controller
                          control={control}
                          name="item.isActive"
                          render={({ field: { onChange, value } }) => (
                            <div className="form-group mt-2">
                              <label className="checkbox-inline">
                                <input
                                  id="activeE"
                                  type="radio"
                                  onChange={() => onChange(true)}
                                  checked={value}
                                />
                                <label
                                  className="text-black ms-2"
                                  htmlFor="activeE"
                                >
                                  Active
                                </label>
                              </label>
                              <label className="checkbox-inline ms-3">
                                <input
                                  id="disabledE"
                                  type="radio"
                                  onChange={() => onChange(false)}
                                  checked={!value}
                                />
                                <label
                                  className="text-black ms-2"
                                  htmlFor="disabledE"
                                >
                                  Disabled
                                </label>
                              </label>
                            </div>
                          )}
                        />
                      )}
                    </div>
                    <div className="col-lg-10 col-sm-12 mt-5">
                      <label>
                        Item Description <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors["item"] &&
                          errors["item"].itemDescription &&
                          "required_field"
                        }`}
                        {...register("item.itemDescription", {
                          required: true,
                        })}
                        disabled={isDisabled}
                      />
                      {errors["item"] && errors["item"].itemDescription && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-lg-6 mt-5">
                      <label>
                        Suggested Selling Price{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className={`form-control ${
                          errors["item"] &&
                          errors["item"]["suggestedSellingPrice"] &&
                          "required_field"
                        }`}
                        {...register("item.suggestedSellingPrice", {
                          required: true,
                        })}
                        disabled={isDisabled}
                      />
                      {errors["item"] &&
                        errors["item"]["suggestedSellingPrice"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                    </div>
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6 mt-5">
                      <label className="label mb-2">Retail Item?</label>
                      <Controller
                        control={control}
                        name="item.isRetail"
                        render={({ field: { onChange, value } }) => (
                          <div className="form-group mt-2">
                            <label className="checkbox-inline">
                              <input
                                id="retailYes"
                                type="radio"
                                onChange={() => onChange(true)}
                                checked={value}
                                disabled={isDisabled}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="retailYes"
                              >
                                Yes
                              </label>
                            </label>
                            <label className="checkbox-inline ms-3">
                              <input
                                id="retailNo"
                                type="radio"
                                onChange={() => onChange(false)}
                                checked={!value}
                                disabled={isDisabled}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="retailNo"
                              >
                                No
                              </label>
                            </label>
                          </div>
                        )}
                      />
                    </div>
                    {!isSerialized && (
                      <div className="col-lg-6 mt-5">
                        <label className="label mb-2">Taxable?</label>
                        <Controller
                          control={control}
                          name="item.isTaxable"
                          render={({ field: { onChange, value } }) => (
                            <div className="form-group mt-2">
                              <label className="checkbox-inline">
                                <input
                                  id="taxableYes"
                                  type="radio"
                                  onChange={() => onChange(true)}
                                  checked={value}
                                  disabled={isDisabled}
                                />
                                <label
                                  className="text-black ms-2"
                                  htmlFor="taxableYes"
                                >
                                  Yes
                                </label>
                              </label>
                              <label className="checkbox-inline ms-3">
                                <input
                                  id="taxableNo"
                                  type="radio"
                                  onChange={() => onChange(false)}
                                  checked={!value}
                                  disabled={isDisabled}
                                />
                                <label
                                  className="text-black ms-2"
                                  htmlFor="taxableNo"
                                >
                                  No
                                </label>
                              </label>
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label>
                        Inventory Asset Account{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenInvAssetAccount(true)}
                        inputName="invAssetAccount"
                        isRequired={true}
                        initialData={paymentAccountsInv?.data?.map((item) => ({
                          ...item,
                          id: item.ledgerId,
                          name: item.accountName,
                        }))}
                        onSelection={onInvAssetAccountSelection}
                        inputValue={selectedInvAssetAccount?.accountName}
                        isDisabled={isDisabled}
                        hasError={errors["invAssetAccount"] ? true : false}
                      />
                      {errors && errors["invAssetAccount"] && (
                        <p className="text-danger">This field is Required</p>
                      )}
                    </div>
                    <div className="col-6">
                      <label>
                        Income Account <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenIncomeAccountId(true)}
                        inputName="incomeAccountId"
                        isRequired={true}
                        initialData={paymentAccountsIncome?.data?.map(
                          (item) => ({
                            ...item,
                            id: item.ledgerId,
                            name: item.accountName,
                          })
                        )}
                        onSelection={onIncomeAccountIdSelection}
                        inputValue={selectedIncomeAccountId?.accountName}
                        isDisabled={isDisabled}
                        hasError={errors["incomeAccountId"] ? true : false}
                      />
                      {errors && errors["incomeAccountId"] && (
                        <p className="text-danger">This field is Required</p>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <label>
                        Expense Account <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenExpenseAccountId(true)}
                        inputName="expenseAccountId"
                        isRequired={true}
                        initialData={paymentAccountsEpense?.data?.map(
                          (item) => ({
                            ...item,
                            id: item.ledgerId,
                            name: item.accountName,
                          })
                        )}
                        onSelection={onExpenseAccountIdSelection}
                        inputValue={selectedExpenseAccountId?.accountName}
                        isDisabled={isDisabled}
                        hasError={errors["expenseAccountId"] ? true : false}
                      />
                      {errors && errors["expenseAccountId"] && (
                        <p className="text-danger">This field is Required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="row p-4 blue-section">
                    <div className="col-lg-6">
                      <InputGroup className="align-items-center">
                        <label className="label mt-0">Qty on Hand</label>
                        <input
                          className="form-control border-0 bg-transparent text-end px-3"
                          name="qty"
                          disabled
                          value={popUpData?.onHand ?? 0}
                        />
                        <Button
                          variant="primary"
                          onClick={() => setShowOnHandPopUp(true)}
                        >
                          <img src={SearchIcon} alt="" />
                        </Button>
                      </InputGroup>
                    </div>
                    <div className="col-lg-6">
                      <InputGroup className="align-items-center">
                        <label className="label mt-0">On Sales Orders</label>
                        <input
                          className="form-control border-0 bg-transparent text-end px-3"
                          name="sales"
                          disabled
                          value={popUpData?.onSO ?? 0}
                        />
                        <Button
                          variant="primary"
                          onClick={() => setShowOnSOPopUp(true)}
                        >
                          <img src={SearchIcon} alt="" />
                        </Button>
                      </InputGroup>
                    </div>
                    <div className="col-lg-6 mt-2">
                      <InputGroup className="align-items-center">
                        <label className="label mt-0">Pending PO</label>
                        <input
                          className="form-control border-0 bg-transparent text-end px-3"
                          name="pending"
                          disabled
                          value={popUpData?.onPO ?? 0}
                        />
                        <Button
                          variant="primary"
                          onClick={() => setShowOnPOPopUp(true)}
                        >
                          <img src={SearchIcon} alt="" />
                        </Button>
                      </InputGroup>
                    </div>
                    <div className="col-lg-6 mt-2">
                      <InputGroup className="align-items-center">
                        <label className="label mt-0">On Fullfil</label>
                        <input
                          className="form-control border-0 bg-transparent text-end px-3"
                          name="pending"
                          disabled
                          value={
                            (currentSerialized?.item?.onFulfill ||
                              currentNonSerialized?.item?.onFulfill) ??
                            0
                          }
                        />
                        <Button
                          variant="primary"
                          onClick={() => setShowOnPOPopUp(true)}
                          className="invisible"
                        >
                          <img src={SearchIcon} alt="" />
                        </Button>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="row p-4">
                    <div className="col-lg-4">
                      <label>Current Average Cost</label>
                      <div className="mt-3">
                        {formatNumber(currentSerialized?.averageCost ?? 0)}
                      </div>
                    </div>
                    <div className="col-lg-4 offset-lg-1">
                      <label>Selling QTY</label>
                      <input
                        type="number"
                        name="selling"
                        className="form-control"
                        {...register("item.sellingQuantity")}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className="col-lg-4 mt-4">
                      <label>Last PO No.</label>
                      <div className="mt-2">
                        {currentSerialized?.lastPONumber ?? (
                          <span className="text-muted fst-italic">
                            Last Po No.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 mt-4 offset-lg-1">
                      <label>Last Received Date</label>
                      <div className="mt-2">
                        {currentSerialized?.lastReceivedDate ? (
                          dateFormmaterNoTime(
                            currentSerialized?.lastReceivedDate
                          )
                        ) : (
                          <span className="text-muted fst-italic">
                            Last Received Date
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs defaultActiveKey="itemInformation" className="mt-5">
                <Tab
                  eventKey="itemInformation"
                  title={
                    <div className="tab-inner-title">Item Information</div>
                  }
                >
                  <ItemInformation
                    initList={initList}
                    isDisabled={isDisabled}
                    isSerialized={isSerialized}
                    classId={
                      currentSerialized?.item?.itemClassId ||
                      currentNonSerialized?.item?.itemClassId
                    }
                    showModal={setShowCreationModal}
                    modalType={modalType}
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                  />
                </Tab>
                <Tab
                  eventKey="portalSettings"
                  title={<div className="tab-inner-title">Portal Settings</div>}
                >
                  <PortalSettings
                    isDisabled={isDisabled}
                    item={currentSerialized || currentNonSerialized}
                    setImgThumbnail={setImgThumbnail}
                    setCustomIsDirty={setCustomIsDirty}
                  />
                </Tab>
                {!id && !isSerialized && (
                  <Tab
                    eventKey="startingBalance"
                    title={
                      <div className="tab-inner-title">Starting Balance</div>
                    }
                  >
                    <StartingBalance
                      setValue={setValue}
                      startingBalance={startingBalance}
                      setStartingBalance={setStartingBalance}
                    />
                  </Tab>
                )}
                {isSerialized && id && (
                  <Tab
                    eventKey="serials"
                    title={<div className="tab-inner-title">Serials</div>}
                  >
                    <div className="h-450">
                      <AG columns={serialsColumns} data={serialsList} />
                    </div>
                  </Tab>
                )}
                <Tab
                  eventKey="attributes"
                  title={<div className="tab-inner-title">Attributes</div>}
                >
                  <ItemAttributes
                    attributes={attributes}
                    setAttributes={setAttributes}
                    categoryId={selectedCategory?.value}
                    isDisabled={isDisabled}
                    setCustomIsDirty={setCustomIsDirty}
                  />
                </Tab>
                {/* {isSerialized && id && (
                                    <Tab
                                        eventKey="bomPartList"
                                        title={<div className="tab-inner-title">BOM Part List</div>}
                                    >
                                        <BOMPartList
                                            isDisabled={isDisabled}
                                            bomPartsList={bomPartsList}
                                            setBomPartsList={setBomPartsList}
                                        />
                                    </Tab>
                                )}
                                {(id && !isSerialized) && (
                                    <Tab
                                        eventKey="compatabilityList"
                                        title={<div className="tab-inner-title">Contability List</div>}
                                    >
                                        <ContabilityList
                                            data={currentNonSerialized?.data?.compatibleModelsIds}
                                            isDisabled={isDisabled}
                                            dataSelected={dataSelected}
                                            setDataSelected={setDataSelected}
                                        />
                                    </Tab>
                                )}
                                {id && (
                                    <Tab
                                        eventKey="storeItemFilter"
                                        title={<div className="tab-inner-title">Store Item Filter</div>}
                                    >
                                        <StoreItemFilter
                                            isDisabled={isDisabled}
                                            storeFilterIdsList={storeFilterIdsList}
                                            setStoreFilterIdsList={setStoreFilterIdsList}
                                        />
                                    </Tab>
                                )}
                                {!isSerialized && id && (
                                    <Tab
                                        eventKey="itemSKUs"
                                        title={<div className="tab-inner-title">SKUs</div>}
                                    >
                                        <ItemSKUs
                                            isDisabled={isDisabled}
                                            itemSKUsList={itemSKUsList}
                                            setItemSKUsList={setItemSKUsList}
                                        />
                                    </Tab>
                                )}
                                {(id && !isSerialized) && (
                                    <Tab
                                        eventKey="shelves"
                                        title={<div className="tab-inner-title">Shelves</div>}
                                    >
                                        <ItemShelves
                                            data={currentNonSerialized?.data}
                                        />
                                    </Tab>
                                )} */}
                {/* {id && (
                                    <Tab
                                        eventKey="notes"
                                        title={
                                            <div className="tab-inner-title">
                                                Item Notes
                                                <Badge pill className="text-secondary ms-2">{getItemNotesLength()}</Badge>
                                            </div>
                                        }
                                    >
                                        <NotesList
                                            targetId={getItemId()}
                                            notes={getItemNotes()}
                                            onAddNote={addNewItemNote}
                                            onUpdateNote={updateItemNote}
                                            onDeleteNote={removeItemNote}
                                        />
                                    </Tab>
                                )} */}
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <ClassesModal
        showModal={showCreationModal}
        onClose={() => setShowCreationModal(false)}
        onConfirm={onTypeCreated}
        modalType={modalType.current}
        itemClassOptions={initList?.classes?.map((item) => {
          return { label: item.className, value: item.itemClassId };
        })}
      />
      <Modal
        show={openConfirmModal}
        onHide={setOpenConfirmModal}
        centered
        className="discard-modal"
      >
        <Modal.Header>
          <Modal.Title>Discarding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to leave?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <button
            className="btn btn-outline-primary"
            onClick={() => setOpenConfirmModal(false)}
          >
            No, stay
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              history.push(AppUrls.inventory_list);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showOnHandPopUp}
        onHide={() => setShowOnHandPopUp(false)}
        centered
        size="lg"
        dialogClassName="modal-80w"
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>On Hand Items</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className=""
            role="button"
            onClick={() => setShowOnHandPopUp(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="h-450">
            <AG
              data={popUpData?.hubInventory}
              columns={hubInvColumns}
              pagination={true}
              autoColumns={true}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showOnPOPopUp}
        onHide={() => setShowOnPOPopUp(false)}
        centered
        size="lg"
        dialogClassName="modal-80w"
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>Pending PO</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className=""
            role="button"
            onClick={() => setShowOnPOPopUp(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="h-450">
            <AG
              data={popUpData?.pendingPOs}
              columns={pendingPOsColumns}
              pagination={true}
              autoColumns={true}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showOnSOPopUp}
        onHide={() => setShowOnSOPopUp(false)}
        centered
        size="lg"
        dialogClassName="modal-80w"
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>Pending SO</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className=""
            role="button"
            onClick={() => setShowOnSOPopUp(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="h-450">
            <AG
              data={popUpData?.pendingSOs}
              columns={pendingSOsColumns}
              pagination={true}
              autoColumns={true}
            />
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        title="Delete Item"
        message="Are you sure you want to delete the item?"
        showModal={showDelete}
        onClose={() => setShowDelete(false)}
        onConfirm={() => handleDeleteItem()}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
      <SelectionModal
        modal="account"
        showModal={openInvAssetAccount}
        setShowModal={setOpenInvAssetAccount}
        data={paymentAccountsInv?.data}
        columns={ledgersColumns}
        setRowClicked={onInvAssetAccountSelection}
      />
      <SelectionModal
        modal="account"
        showModal={openIncomeAccountId}
        setShowModal={setOpenIncomeAccountId}
        data={paymentAccountsIncome?.data}
        columns={ledgersColumns}
        setRowClicked={onIncomeAccountIdSelection}
      />
      <SelectionModal
        modal="account"
        showModal={openExpenseAccountId}
        setShowModal={setOpenExpenseAccountId}
        data={paymentAccountsEpense?.data}
        columns={ledgersColumns}
        setRowClicked={onExpenseAccountIdSelection}
      />
    </>
  );
};

export default InventoryItem;
