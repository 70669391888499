import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import addIcon from "img/add-icon.svg";
import * as AppUrls from "constants/AppUrls";
import { useMutation } from "react-query";
import { checkIfAllowed } from "global/helpers";
import StateContext from "context/StateContext";
import { getRepresentatives } from "./Representative.services";
import { GridColumn } from "elements/AG.models";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import DispatchContext from "context/DispatchContext";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";

const Representatives = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  let history = useHistory();
  const [refreshCounter, setRefreshCounter] = useState(0);

  const appState = useContext(StateContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [dataGrid, setDataGrid] = useState(null);
  const [filters, setFilters] = useState({
    searchQuery: "",
  });

  const dataMutation = useMutation("get_reps", getRepresentatives, {
    async onSuccess(response) {
      setDataGrid(response?.data);
    },
  });

  const onRowDoubleClick = (e) => {
    let url = `${AppUrls.representatives}/${e.repId}`;
    history.push(url);
  };

  const columns: GridColumn[] = [
    {
      field: "repName",
      headerName: "Representative Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      valueGetter: (params) => {
        return params.data.workNumber
          ? params.data?.workNumber
              ?.toString()
              .replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, "$1 $2 $3")
          : "";
      },
    },
    {
      field: "mobileNumber",
      headerName: "Cell No.",
      valueGetter: (params) => {
        return params.data.workNumber
          ? params.data?.mobileNumber
              ?.toString()
              .replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, "$1 $2 $3")
          : "";
      },
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "isActive",
      headerName: "Status",
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
                    <div class="${
                      status ? "active-status" : "disabled-status"
                    }">
                        ${status ? "Active" : "Disabled"}
                    </div>
                    `;
      },
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Representatives</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length == 0 || !dataGrid}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_representatives} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Representative
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="row mb-4">
          <div className="col-lg-3">
            <label className="label me-3">Search</label>
            <div className="page-search">
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => onSearchTermChange(e)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            data={dataGrid}
            filters={filters}
            setDataGrid={setDataGrid}
            fetchMethod={dataMutation}
            setrowClicked={onRowDoubleClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            refreshCounter={refreshCounter}
            fileName="Representatives List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default Representatives;
