import { useState, useContext, useEffect, useRef } from "react";
import Lookup from "elements/Lookup";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getInvoices,
  getPaymentMethods,
  payInvoice,
} from "./InvoicesServices/InvoicesServices";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumns, QueryResult } from "components/Common.models";
import { InvoiceEntry, PayInvoiceDTO, PaymentMethod } from "./Invoices.model";
import DispatchContext from "context/DispatchContext";
import { formatInput, formatNumber, notificationMessage } from "global/helpers";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import * as colDefs from "../../constants/colDefs";

const PayInvoice = () => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceEntry>();
  const selectedType = useRef(LedgerTypeEnum.Asset);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();
  const location = useLocation();
  const [ledgerData, setLedgerData] = useState(null);
  const columns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
      minWidth: colDefs.mWidth,
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      minWidth: colDefs.mWidth,
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: colDefs.mWidth,
    },
    {
      field: "date",
      headerName: "Inv. Date",
      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
      minWidth: colDefs.m_lgWidth,
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      minWidth: colDefs.mWidth,
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      minWidth: colDefs.mWidth,
    },
    {
      field: "aging",
      headerName: "Aging",
      minWidth: colDefs.smWidth,
    },
    {
      field: "batchNumber",
      headerName: "Batch No",
      minWidth: colDefs.smWidth,
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const { refetch } = useQuery(
    "expenses",
    () => getLedgers({ ledgerType: selectedType.current }),
    {
      onSuccess(data) {
        if (data.count === 0)
          if (selectedType.current === LedgerTypeEnum.Cash)
            setValue("paymentAccountId", "0");
          else if (selectedType.current === LedgerTypeEnum.Bank)
            setValue("paymentAccountId", "-1");
      },
    }
  );

  const ApiCall = useMutation(getLedgers, {
    onSuccess(res) {
      setLedgerData(res?.data);
    },
  });

  useEffect(() => {
    if (selectedPaymentMethod) {
      if (selectedPaymentMethod === 1) {
        ApiCall.mutate({ ledgerType: 13 });
      } else if (selectedPaymentMethod === 2 || selectedPaymentMethod === 3) {
        ApiCall.mutate({ ledgerType: 14 });
      }
    } else {
      ApiCall.mutate({ ledgerType: 1 });
    }
  }, [selectedPaymentMethod]);

  const methods = useForm<PayInvoiceDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty },

    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
  } = methods;

  const { data: invoices } = useQuery<QueryResult<InvoiceEntry>>(
    "openInvoices",
    getInvoices,
    {
      select(response) {
        let result = {
          ...response,
          data: response.data?.filter(
            (invoice) => invoice.status !== 3 && invoice.status !== 4
          ),
        };
        return result;
      },
    }
  );

  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );

  const submitPayment = useMutation(payInvoice, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Invoice paid successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("openInvoices");
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem paying invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  useEffect(() => {
    if (location.state?.invoiceId && invoices?.data) {
      setSelectedInvoice(
        invoices.data.find(
          (invoice) => invoice.invoiceId === Number(location.state?.invoiceId)
        )
      );
      setValue("invoiceId", Number(location.state?.invoiceId), {
        shouldDirty: true,
      });
    }
  }, [location, invoices]);

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("paymentAccountId", e.ledgerId);
  };

  const onInvoiceSelected = (invoice) => {
    setValue("invoiceId", invoice.invoiceId, { shouldDirty: true });
    setSelectedInvoice(invoice);
    setOpenSelection(false);
  };

  const getChangeAmount = () => {
    if (
      parseFloat(getValues("amountPaid")?.toString().replace(/,/g, "")) >
      selectedInvoice.amount - selectedInvoice.paidAmount
    )
      return (
        parseFloat(getValues("amountPaid")?.toString().replace(/,/g, "")) -
        (selectedInvoice.amount - selectedInvoice.paidAmount)
      );
    return 0;
  };

  const onCancel = () => {
    reset();
    setSelectedInvoice(null);
    setSelectedLedger(null);
  };

  const onSubmit = () => {
    setOpenConfirmation(true);
  };

  const confirmPayment = () => {
    setOpenConfirmation(false);
    let data = getValues();
    data.depositTo = selectedLedger.ledgerId;
    submitPayment.mutate({
      ...data,
      amountPaid: parseFloat(
        getValues("amountPaid")?.toString().replace(/,/g, "")
      ).toString(),
    });
  };

  const onMethodChange = (e) => {
    setSelectedLedger(null);
    let deposit = LedgerTypeEnum.Asset;
    if (e === "Cash") deposit = LedgerTypeEnum.Cash;
    else if (e === "Credit Card" || e === "Check")
      deposit = LedgerTypeEnum.Bank;
    selectedType.current = deposit;
    refetch();
  };

  let options = [];
  if (paymentMethods)
    options = paymentMethods.map((method) => ({
      label: method.name,
      value: method.paymentMethodId,
    }));

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>Pay Single Invoice</div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                disabled={!isDirty}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                Save Changes
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-5">
                  <div className="row">
                    <div className="col-sm-12">
                      <label>
                        Invoice No. <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="invoiceId"
                        isRequired={true}
                        initialData={invoices?.data?.map((item) => ({
                          ...item,
                          id: item.invoiceId,
                          name: item.invoiceNumber,
                        }))}
                        onSelection={onInvoiceSelected}
                        inputValue={selectedInvoice?.invoiceNumber}
                      />
                    </div>
                    <div className="col-sm-12 mt-4">
                      <label>
                        Payment Method <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="paymentMethodId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={options}
                            isClearable
                            isSearchable={true}
                            value={
                              options.find((opt) => opt?.value === value) ||
                              null
                            }
                            onChange={(selectedOption: any) => {
                              onChange(selectedOption?.value);
                              onMethodChange(selectedOption?.label);
                              setSelectedPaymentMethod(selectedOption?.value);
                            }}
                            placeholder="Choose"
                            menuPlacement="auto"
                            isDisabled={!isDirty}
                            className={`${
                              errors["paymentMethodId"] && "required_field"
                            }`}
                          />
                        )}
                      />
                      {errors.paymentMethodId && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-sm-12 mt-4">
                      <label>
                        Deposit to <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelectionLedger(true)}
                        inputName="paymentAccountId"
                        isRequired={true}
                        initialData={ledgerData?.map((item) => ({
                          ...item,
                          id: item.ledgerId,
                          name: item.accountName,
                        }))}
                        onSelection={onLedgerSelection}
                        inputValue={selectedLedger?.accountName}
                        isDisabled={
                          !getValues("paymentMethodId") ||
                          getValues("paymentAccountId") === "-1" ||
                          getValues("paymentAccountId") === "0"
                        }
                        hasError={errors["paymentAccountId"] ? true : false}
                      />
                      {errors["paymentAccountId"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-sm-7 mt-4">
                      <label>
                        Amount Paid <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("amountPaid", { required: true, min: 1 })}
                        className={`form-control ${
                          errors["amountPaid"] && "required_field"
                        }`}
                        disabled={!isDirty}
                        onBlur={(e) =>
                          setValue("amountPaid", formatInput(e.target.value))
                        }
                      />
                      {errors.amountPaid && (
                        <p className="text-danger">
                          Amount Paid should be a positive number
                        </p>
                      )}
                    </div>
                    {selectedPaymentMethod != 1 && (
                      <div className="col-sm-5 mt-4">
                        <label>Check No.</label>
                        <input
                          type="text"
                          {...register("checkNumber")}
                          className="form-control"
                          disabled={!isDirty}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="section row py-4">
                    <div className="col-sm-12">
                      <label>Amount Due</label>
                      <div>
                        {selectedInvoice ? (
                          formatNumber(
                            selectedInvoice.amount - selectedInvoice.paidAmount
                          )
                        ) : (
                          <span className="fst-italic text-muted">
                            Amount due
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 mt-5">
                      <label>Invoice Date</label>
                      <div>
                        {selectedInvoice ? (
                          new Date(selectedInvoice.date).toLocaleDateString() +
                          " " +
                          new Date(selectedInvoice.date).toLocaleTimeString()
                        ) : (
                          <span className="fst-italic text-muted">
                            Invoice Date
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 mt-5">
                      <label>Customer No.</label>
                      <div>
                        {selectedInvoice ? (
                          selectedInvoice.accountNumber
                        ) : (
                          <span className="fst-italic text-muted">
                            Account No.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 mt-5">
                      <label>Company</label>
                      <div>
                        {selectedInvoice ? (
                          selectedInvoice.company
                        ) : (
                          <span className="fst-italic text-muted">Company</span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-7 mt-4">
                      <label>Change Amount</label>
                      <div>
                        {selectedInvoice ? (
                          formatNumber(getChangeAmount())
                        ) : (
                          <span className="fst-italic text-muted">
                            Change Amount
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal="Invoice"
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={invoices?.data}
        columns={columns}
        setRowClicked={onInvoiceSelected}
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={ledgerData}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
      <ConfirmationModal
        title="Invoice Payment"
        message={
          <>
            <div>
              You are about to pay invoice number{" "}
              {selectedInvoice?.invoiceNumber}
            </div>
            <div>
              Invoice Balance:{" "}
              {formatNumber(
                selectedInvoice?.amount - selectedInvoice?.paidAmount
              )}
            </div>
            <div>Payment Received: {formatNumber(getValues("amountPaid"))}</div>
            <div className="mt-2">Do you wish to continue?</div>
          </>
        }
        showModal={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => {
          confirmPayment();
        }}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
    </>
  );
};

export default PayInvoice;
