import React, { useEffect, useState } from "react";
import AG from "elements/AG";
import { useMutation } from "react-query";
import { getRoleMembers } from "../UserManagementServices/UserManagementServices";

const Members = ({ id }) => {

const [membersList, setMembersList] = useState([])

  const getMembers = useMutation(getRoleMembers, {
    async onSuccess(response) {
      setMembersList(response.data)
    }
  });

  useEffect(() => {
    if (id)
      getMembers.mutate(id);
  }, [id])

  const columns = [
    {
      headerName: "Employee Id",
      field: "employeeId",
    },

    {
      headerName: "Role Id",
      field: "roles",
      cellRendererFramework: (params) => params.value.length > 1 ? "Multiple" : params.value[0],
    },
    {
      headerName: "Cell No",
      field: "cellNumber",
    },
    {
      headerName: "Hub Id",
      field: "hubs",
      cellRendererFramework: (params) => params.value.length > 1 ? "Multiple" : params.value.length > 0 ? params.value[0] : null,
    },
    {
      headerName: "Status",
      field: "isActive",
      cellRendererFramework: (params) => params.value == true ? <div className="active-status">active</div> : <div className="disabled-status">disabled</div>,
    },
  ];

  return (
    <div className="members-grid">
      <AG columns={columns} data={membersList} />
    </div>
  );
};
export default Members;