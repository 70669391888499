import { DropDownOption } from "components/Common.models";
import { useEffect, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import SearchIcon from "img/search-group.png";
import CategoryModal from "./CategoryModal";

const ItemInformation = ({
  initList,
  isDisabled,
  isSerialized,
  classId,
  showModal,
  modalType,
  setSelectedCategory,
  selectedCategory,
}) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const itemClassOptions: DropDownOption[] = initList?.classes?.map((item) => {
    return { label: item.className, value: item.itemClassId };
  });

  const [selectedClass, setSelectedClass] = useState(classId);
  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const [itemSubClassOptions, setItemSubClassOptions] = useState<
    DropDownOption[]
  >([]);

  useEffect(() => {
    if (initList && selectedClass) {
      const itemSubClassOptions: DropDownOption[] = initList?.subClasses
        ?.filter((i) => i.itemClassId === selectedClass?.value)
        ?.map((item) => {
          return { label: item.subClass, value: item.itemSubClassId };
        });
      setItemSubClassOptions(itemSubClassOptions);
    }
  }, [initList, selectedClass, classId]);

  useEffect(() => {
    if (initList && classId) {
      const itemSubClassOptions: DropDownOption[] = initList?.subClasses
        ?.filter((i) => i.itemClassId === classId)
        ?.map((item) => {
          return { label: item.subClass, value: item.itemSubClassId };
        });
      setItemSubClassOptions(itemSubClassOptions);
    }
  }, [initList, classId]);

  const manufacturerOptions: DropDownOption[] = initList?.manufacturers?.map(
    (item) => {
      return { label: item.name, value: item.manufacturerId };
    }
  );
  const productLineOptions: DropDownOption[] = initList?.productLines?.map(
    (item) => {
      return { label: item.productLine, value: item.itemProductLineId };
    }
  );
  const conditionOptions: DropDownOption[] = initList?.conditions?.map(
    (item) => {
      return { label: item.condition, value: item.itemConditionId };
    }
  );
  const modelOptions: DropDownOption[] =
    initList?.models &&
    initList?.models?.map((item) => {
      return { label: item.model, value: item.itemModelId };
    });
  const colorOptions: DropDownOption[] = initList?.colors?.map((item) => {
    return { label: item.description, value: item.itemColorId };
  });
  const deviceOSOptions: DropDownOption[] =
    initList?.deviceOS &&
    initList?.deviceOS.map((item) => {
      return { label: item.name, value: item.itemOSId };
    });

  const createLabel = () => <label>Add New</label>;

  const onCreateClass = () => {
    showModal(true);
    modalType.current = 1;
  };

  const onCreateSubClass = () => {
    showModal(true);
    modalType.current = 2;
  };

  const onChooseCategory = () => {
    setShowCategoryModal(true);
  };
  useEffect(() => {
    if (selectedCategory && selectedCategory !== null) {
      setValue("item.itemCategoryId", selectedCategory.value, {
        shouldDirty: true,
      });
    }
  }, [selectedCategory]);

  const onSelectCategory = (category) => {
    setShowCategoryModal(false);
    setSelectedCategory(category);
    setValue("item.itemCategoryId", category.value, { shouldDirty: true });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            {/* {!isSerialized &&
                        <div className='col-sm-12'>
                            <Controller
                                control={control}
                                name="autoSerialBOMParts"
                                render={({ field: { onChange, value } }) => (
                                    <div className="form-check mb-5 mt-3">
                                        <input
                                            className={`form-check-input ${isDisabled && 'disabled-checkbox'}`}
                                            type="checkbox"
                                            id="bomparts"
                                            onChange={() => onChange(!value)}
                                            checked={value}
                                        />
                                        <label className={`form-check-label text-black ${isDisabled && 'disabled-label'}`} htmlFor="bomparts">
                                            Auto General Serial Number for BOM Parts
                                        </label>
                                    </div>
                                )}
                            />
                        </div>
                    } */}
            <div className="col-lg-4">
              <label>Class ID</label>
              <Controller
                control={control}
                name="item.itemClassId"
                render={({ field: { onChange, value } }) => (
                  <CreatableSelect
                    options={itemClassOptions}
                    isSearchable={true}
                    isDisabled={isDisabled}
                    value={
                      selectedClass ||
                      itemClassOptions?.find((opt) => opt?.value === value) ||
                      itemClassOptions?.find((opt) => opt?.value === classId) ||
                      null
                    }
                    onChange={(val: DropDownOption) => {
                      setSelectedClass(val);
                      onChange(val?.value);
                    }}
                    isClearable={true}
                    isValidNewOption={() => true}
                    formatCreateLabel={createLabel}
                    createOptionPosition="first"
                    onCreateOption={onCreateClass}
                  />
                )}
              />
            </div>
            <div className="col-lg-4">
              <label>Sub Class ID</label>
              <Controller
                control={control}
                name="item.subClassId"
                render={({ field: { onChange, value } }) => (
                  <CreatableSelect
                    options={itemSubClassOptions}
                    isSearchable={true}
                    value={
                      itemSubClassOptions?.find(
                        (opt) => opt?.value === value
                      ) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                    isDisabled={isDisabled}
                    isClearable={true}
                    isValidNewOption={() => true}
                    formatCreateLabel={createLabel}
                    createOptionPosition="first"
                    onCreateOption={onCreateSubClass}
                  />
                )}
              />
            </div>
            <div className="col-lg-4">
              <label>Category</label>
              <InputGroup className={`input-lookup`}>
                <input
                  className={`form-control ${isDisabled ? "" : "bg-white"}`}
                  placeholder="Choose"
                  disabled={isDisabled}
                  readOnly
                  defaultValue={selectedCategory?.label ?? ""}
                />
                <input
                  className="d-none"
                  {...register("item.itemCategoryId")}
                />
                <Button
                  variant="primary"
                  className="input-group-prepend"
                  onClick={onChooseCategory}
                  disabled={isDisabled}
                >
                  <img src={SearchIcon} alt="" />
                </Button>
              </InputGroup>
            </div>
          </div>
          <hr className="my-5" />
          <div className="row gx-5">
            <div className="col-lg-4">
              <label>Manufacturer</label>
              <Controller
                control={control}
                name="item.manufacturerId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={manufacturerOptions}
                    isSearchable={true}
                    value={
                      manufacturerOptions?.find(
                        (opt) => opt?.value === value
                      ) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                    isDisabled={isDisabled}
                    isClearable={true}
                  />
                )}
              />
            </div>
            <div className="col-lg-4">
              <label>Man. Part No.</label>
              <input
                type="text"
                name="manPart"
                className="form-control"
                {...register("item.manPartNumber")}
                disabled={isDisabled}
              />
            </div>
            <div className="col-lg-4">
              <label>Vend. Part No.</label>
              <input
                type="text"
                name="vendPart"
                className="form-control"
                {...register("item.venPartNumber")}
                disabled={isDisabled}
              />
            </div>
          </div>
          <hr className="my-5" />
          {isSerialized && (
            <div className="row gx-5">
              <div className="col-lg-4">
                <label>Product Line</label>
                <Controller
                  control={control}
                  name="item.productLineId"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={productLineOptions}
                      isSearchable={true}
                      value={
                        productLineOptions?.find(
                          (opt) => opt?.value === value
                        ) || null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      isDisabled={isDisabled}
                      isClearable={true}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="row gx-5 mt-4">
            {!isSerialized && (
              <div className="col-lg-4">
                <label>Product Line</label>
                <Controller
                  control={control}
                  name="item.productLineId"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={productLineOptions}
                      isSearchable={true}
                      value={
                        productLineOptions?.find(
                          (opt) => opt?.value === value
                        ) || null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      isDisabled={isDisabled}
                      isClearable={true}
                    />
                  )}
                />
              </div>
            )}
            <div className="col-lg-4">
              <label>Condition</label>
              <Controller
                control={control}
                name="item.conditionId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={conditionOptions}
                    isSearchable={true}
                    value={
                      conditionOptions?.find((opt) => opt?.value === value) ||
                      null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                    isDisabled={isDisabled}
                    isClearable={true}
                  />
                )}
              />
            </div>
            {isSerialized && (
              <div className="col-lg-4">
                <label>Model</label>
                <Controller
                  control={control}
                  name="modelId"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={modelOptions}
                      isSearchable={true}
                      value={
                        modelOptions?.find((opt) => opt?.value === value) ||
                        null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      isDisabled={isDisabled}
                      isClearable={true}
                    />
                  )}
                />
              </div>
            )}
            <div className="col-lg-4">
              <label>Color</label>
              <Controller
                control={control}
                name="item.colorId"
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={colorOptions}
                    isSearchable={true}
                    value={
                      colorOptions?.find((opt) => opt?.value === value) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                    isDisabled={isDisabled}
                    isClearable={true}
                  />
                )}
              />
            </div>
          </div>
          <hr className="my-5" />
          <div className={`row gx-5 ${!isSerialized && "mb-5"}`}>
            <div className="col-lg-8">
              <label>Misc Info</label>
              <input
                type="text"
                name="misc"
                className="form-control"
                {...register("item.miscInfo")}
                disabled={isDisabled}
              />
            </div>
          </div>
          {isSerialized && (
            <div className="row gx-5 my-5">
              <div className="col-lg-8 p-0">
                <div className="row gx-5">
                  <div className="col-lg-4">
                    <label>Warranty Days</label>
                    <input
                      type="number"
                      name="warranty"
                      className="form-control"
                      min="0"
                      {...register("warrantyDays1")}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Warranty Days 2</label>
                    <input
                      type="number"
                      name="warranty2"
                      className="form-control"
                      min="0"
                      {...register("warrantyDays2")}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Warranty Days 3</label>
                    <input
                      type="number"
                      name="warranty3"
                      className="form-control"
                      min="0"
                      {...register("warrantyDays3")}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-4">
          <div className="row p-4 blue-section mb-4">
            <div className="col-sm-12 mb-4">
              <label>Item Barcode</label>
              <input
                type="text"
                name="barcode"
                className="form-control"
                {...register("item.barcode")}
                disabled={isDisabled}
              />
            </div>
            <div className="col-lg-6">
              <label>Item Weight (lbs)</label>
              <input
                type="number"
                name="weight"
                className="form-control"
                {...register("item.weight")}
                disabled={isDisabled}
              />
            </div>
            <div className="col-lg-6">
              <label>Quickbook Item</label>
              <input
                type="text"
                name="quickbook"
                className="form-control"
                {...register("item.quickBook")}
                disabled={isDisabled}
              />
            </div>
          </div>
          {isSerialized ? (
            <div className="row p-4 blue-section">
              <div className="col-lg-6">
                <label>Device OS</label>
                <Controller
                  control={control}
                  name="deviceOSId"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={deviceOSOptions}
                      isSearchable={true}
                      value={
                        deviceOSOptions?.find((opt) => opt?.value === value) ||
                        null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      isDisabled={isDisabled}
                      isClearable={true}
                    />
                  )}
                />
              </div>
              <div className="col-lg-6">
                <label>Device SW</label>
                <input
                  type="text"
                  name="sw"
                  className="form-control"
                  {...register("deviceSW")}
                  disabled={isDisabled}
                />
              </div>
              <hr className="my-5" />
              <div className="col-lg-12 mb-4">
                <div className="row">
                  <div className="col-sm-12">
                    <label>
                      Serial Length <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-lg-3">
                    <input
                      type="text"
                      className="form-control text-center"
                      {...register("serialLength1", { required: true })}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-lg-1">-</div>
                  <div className="col-lg-3">
                    <input
                      type="text"
                      className="form-control text-center"
                      {...register("serialLength2")}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-lg-1">-</div>
                  <div className="col-lg-3">
                    <input
                      type="text"
                      className="form-control text-center"
                      {...register("serialLength3")}
                      disabled={isDisabled}
                    />
                  </div>
                  {/* <div className='col-lg-1'>-</div>
                                <div className="col-lg-2">
                                    <input type="text" maxLength={1} className="form-control text-center" {...register('serialLength4')} disabled={isDisabled} />
                                </div> */}
                </div>
                {errors["serialLength1"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          ) : (
            <div className="row p-4 blue-section">
              <div className="col-sm-12">
                <label className="label mb-2">OEM Item?</label>
                <Controller
                  control={control}
                  name="isOEMItem"
                  render={({ field: { onChange, value } }) => (
                    <div className="form-group mt-2">
                      <label className="checkbox-inline">
                        <input
                          id="oemYes"
                          type="radio"
                          onChange={() => onChange(true)}
                          checked={value}
                          disabled={isDisabled}
                        />
                        <label className="text-black ms-2" htmlFor="oemYes">
                          Yes
                        </label>
                      </label>
                      <label className="checkbox-inline ms-3">
                        <input
                          id="oemNo"
                          type="radio"
                          onChange={() => onChange(false)}
                          checked={!value}
                          disabled={isDisabled}
                        />
                        <label className="text-black ms-2" htmlFor="oemNo">
                          No
                        </label>
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className="col-sm-12 my-4">
                <label>OEM Part Desc</label>
                <input
                  type="text"
                  name="oemPartDesc"
                  className="form-control"
                  {...register("oemPartDesc")}
                  disabled={isDisabled}
                />
              </div>
              <div className="col-sm-12 mb-4">
                <label>Traveler Desc</label>
                <input
                  type="text"
                  name="travelerDesc"
                  className="form-control"
                  {...register("travelerDesc")}
                  disabled={isDisabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CategoryModal
        showModal={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
        onConfirm={onSelectCategory}
        selectedCategory={selectedCategory?.value}
      />
    </>
  );
};

export default ItemInformation;
