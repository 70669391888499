import { useEffect, useRef, useState } from "react";
import { getCompProfitAndLoss } from "./AccountingServices";
import "./Ledgers.scss";

import { useReactToPrint } from "react-to-print";

import Accordion from "react-bootstrap/Accordion";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
  getCurrencyByCode,
} from "global/helpers";

import { useMutation, useQuery } from "react-query";

import { useHistory, useLocation } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import { FormProvider, useForm } from "react-hook-form";
import Filter from "reports/components/Filter";
import { getBaseCurrency } from "components/currency";

const ComparativeProfitAndLossReport = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const history = useHistory();
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];

  const isCustomizedReport = customizedComponentId != null;

  const { handleSubmit, getValues } = methods;

  const [data, setData] = useState([]);

  const ProfitAndLoss = useMutation(getCompProfitAndLoss, {
    onSuccess(data) {
      setData(([data[0], data[1], data[2]] = [data[0], data[2], data[1]]));
    },
  });

  const onSubmit = () => {
    const values = getValues();
    ProfitAndLoss.mutate({
      startDate: fixTimezoneOffset(values.startDate ?? new Date()),
      endDate: fixTimezoneOffset(values.endDate ?? new Date()),
    });
  };

  const accordionDiv = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => accordionDiv.current,
  });

  const PrintReport = () => {
    handlePrint();
  };

  useEffect(() => {
    if (!isCustomizedReport)
      ProfitAndLoss.mutate({
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
      });
  }, [isCustomizedReport]);

  const displayAccordion = (category) => {
    if (category === null) return;

    let children =
      category.children.length > 0
        ? category.children
        : category?.ledgers?.map((ledge) => ({
            parentId: ledge.ledgerId,
            categoryId: ledge.ledgerId,
            balance: ledge.balance,
            name: ledge.accountName,
            children: [],
          }));

    return (
      <>
        <Accordion
          defaultActiveKey={data?.length > 0 && data[0].categoryId}
          flush
        >
          <Accordion.Item eventKey={category.categoryId}>
            {children?.length > 0 ? (
              <>
                <Accordion.Header>
                  <table className="w-100 px-5">
                    <tbody>
                      <tr>
                        <td
                          style={{ width: "60%" }}
                          className="text-start p-1 ps-3"
                        >
                          {category.name}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.balance} {currencySymbol}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.priorBalance} {currencySymbol}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="w-100 d-flex justify-content-between ps-3 pe-3">
                                        <p><b>{category.name}</b></p>
                                        <p className="balance">{category.balance} $</p>
                                    </div> */}
                </Accordion.Header>
                <Accordion.Body>
                  {children.map((categoryChild) =>
                    displayAccordion(categoryChild)
                  )}
                  {children.length > 0 && (
                    <div className=" border-top fw-bold">
                      <table className="w-100 px-5">
                        <tbody>
                          <tr>
                            <td
                              style={{ width: "60%" }}
                              className="text-start p-1 ps-3"
                            >
                              Total {category.name}
                            </td>
                            <td
                              style={{ width: "20%" }}
                              className="text-end p-1"
                            >
                              {category.balance} {currencySymbol}
                            </td>
                            <td
                              style={{ width: "20%" }}
                              className="text-end p-1"
                            >
                              {category.priorBalance} {currencySymbol}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    // <div className="w-100 d-flex justify-content-between pe-4 ps-3 pt-2 border-top">
                    //     <p>Total</p>
                    //     <p><b>{category.balance} $</b></p>
                    // </div>
                  )}
                </Accordion.Body>
              </>
            ) : (
              <>
                <Accordion.Header className="custom-header">
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td style={{ width: "60%" }} className="text-start p-1">
                          {category.name}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.balance} {currencySymbol}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.priorBalance} {currencySymbol}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="w-100 d-flex justify-content-between ps-3 pe-4">
                                        <p>{category.parentId ? category.number === "05-01" ? <b>{category.name}</b> : category.name : <b>{category.name}</b>}</p>
                                        <p>{category.parentId && `${category.balance} $`}</p>
                                    </div> */}
                </Accordion.Header>
              </>
            )}
          </Accordion.Item>
        </Accordion>
        {category.number === "05-01" && (
          <>
            <Accordion.Header className="custom-header border-top">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td style={{ width: "60%" }} className="text-start p-1">
                      <p style={{ color: "blue" }}>GROSS PROFIT</p>
                    </td>
                    <td style={{ width: "20%" }} className="text-end p-1">
                      <p style={{ color: "blue" }}>
                        <b>
                          {data[0]?.balance - data[1]?.balance} {currencySymbol}
                        </b>
                      </p>
                    </td>
                    <td style={{ width: "20%" }} className="text-end p-1">
                      <p style={{ color: "blue" }}>
                        <b>
                          {data[0]?.priorBalance - data[1]?.priorBalance}{" "}
                          {currencySymbol}
                        </b>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Accordion.Header>
          </>
        )}
      </>
    );
  };

  const onBackClick = () => {
    history.push("/reports");
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          Comparative Profit and Loss
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Filter
                filters={[1]}
                onSubmit={onSubmit}
                noColumns={true}
                initialDate={!isCustomizedReport}
              />
            </form>
          </FormProvider>
          <div className="container report-filters-div text-end mb-3">
            <button
              type="button"
              className="btn btn-success ms-0"
              onClick={() => PrintReport()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-printer me-3"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>
              Print report to PDF
            </button>
          </div>
          <div className="container w-75 report-paper custom-report">
            <div className="container w-100 pt-5" ref={accordionDiv}>
              <div className="text-center">
                <h3 className="mt-0">Comparative Profit and Loss</h3>
              </div>
              <table className="w-100 px-5 border-bottom">
                <tbody>
                  <tr>
                    <td style={{ width: "60%" }} className="text-start"></td>
                    <td style={{ width: "20%" }} className="text-end">
                      <b>
                        TOTAL as of <br />{" "}
                        {dateFormmaterNoTime(getValues("endDate"))}
                      </b>
                    </td>
                    <td style={{ width: "20%" }} className="text-end">
                      <b>
                        TOTAL as of <br />{" "}
                        {getValues("endDate")?.getMonth() +
                          1 +
                          "/" +
                          getValues("endDate")?.getDate() +
                          "/" +
                          (getValues("endDate")?.getFullYear() - 1)}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              {data?.map((category) => {
                return displayAccordion(category);
              })}
            </div>
            <div className="px-3 border-top mt-3 fw-bold">
              <table className="w-100">
                <tbody>
                  <tr>
                    <td style={{ width: "60%" }} className="text-start">
                      NET EARNINGS
                    </td>
                    <td style={{ width: "20%" }} className="text-end">
                      <b>
                        {data[0]?.balance - data[1]?.balance - data[2]?.balance}{" "}
                        {currencySymbol}
                      </b>
                    </td>
                    <td style={{ width: "20%" }} className="text-end">
                      <b>
                        {data[0]?.priorBalance -
                          data[1]?.priorBalance -
                          data[2]?.priorBalance}{" "}
                        {currencySymbol}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className={`w-100 d-flex justify-content-between px-3 ${(data[0]?.balance - data[1]?.balance) < 0 ? 'text-danger' : 'text-success'} border-top pt-3`}>
                                    <p><b></b></p>
                                    <p></p>
                                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparativeProfitAndLossReport;
