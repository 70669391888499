import { DropDownOption, GridColumns } from "components/Common.models";
import AG from "elements/AG";
import { dateFormmater } from "global/helpers";
import Select from "react-select";
import { VendorInvoiceBill } from "../Vendors.models";

type Props = {
  vendorId: number;
  billsType: DropDownOption;
  setBillsType: Function;
  vendorBills: VendorInvoiceBill[];
};

const InvoiceBills = (props: Props) => {
  const BillsTypes: DropDownOption[] = [
    {
      value: 1,
      label: "All Bills",
    },
    {
      value: 2,
      label: "Past Due Bills",
    },
    {
      value: 3,
      label: "Due This Month",
    },
    {
      value: 4,
      label: "Paid Bills",
    },
  ];

  const columns: GridColumns[] = [
    {
      field: "receiveInvoiceId",
      headerName: "V. Invoice No.",
    },
    {
      field: "orderDate",
      headerName: "V Invoice Date",
      cellRenderer: (params) => dateFormmater(params.data.orderDate),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      cellRenderer: (params) => dateFormmater(params.data.dueDate),
    },
    {
      field: "aging",
      headerName: "Days",
    },
    {
      field: "total",
      headerName: "Amount",
      valueGetter: (params) => {
        return `$${params.data?.total?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      valueGetter: (params) => {
        return `$${params.data?.paidAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidDate",
      headerName: "List Dt. Paid",
      cellRenderer: (params) => dateFormmater(params.data.paidDate),
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "createdBy",
      headerName: "Add User",
    },
    {
      field: "createdDate",
      headerName: "Add Date",
      cellRenderer: (params) => dateFormmater(params.data.createdDate),
    },
    {
      field: "purchaseOrderNumber",
      headerName: "PO No.",
    },
  ];

  return (
    <>
      <div className="col-sm-12">
        <div className="row mb-4">
          <div className="col-lg-3 mt-4 ps-0">
            <Select
              isSearchable={true}
              menuPlacement="auto"
              className="flex-grow-1"
              isClearable
              options={BillsTypes}
              value={props?.billsType}
              onChange={(value) => props?.setBillsType(value)}
            />
          </div>
        </div>
        <div className="h-450 mt-4 px-0">
          <AG data={props?.vendorBills} columns={columns} autoColumns={true} />
        </div>
      </div>
    </>
  );
};

export default InvoiceBills;
