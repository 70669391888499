import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import {
  createBankType,
  editBankType,
  getBanksTypes,
} from "./AccountingServices";
import StateContext from "context/StateContext";
import SectionTitle from "elements/SectionTitle";
import { FormProvider, useForm } from "react-hook-form";
import DispatchContext from "context/DispatchContext";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";

import AgGrid from "elements/AgGrid";

const BankTypes = ({ match }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();

  const [selectedId, setSelectedId] = useState<number>(null);

  const frmMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    handleSubmit,
    reset,
    setValue,
    register,
    formState: { errors },
  } = frmMethods;

  const { data } = useQuery("bankTypes", getBanksTypes);

  const columns = [
    {
      field: "type",
      headerName: "Type",
    },
  ];

  const addOp = useMutation(createBankType, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `type ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("bankTypes");
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateOp = useMutation(editBankType, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `type ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("bankTypes");
      setSelectedId(null);
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onRowDoubleClicked = (e) => {
    setSelectedId(e.bankTypeId);
    setValue("type", e.type);
  };

  const resetForm = () => {
    setSelectedId(null);
    reset();
  };

  const onSubmit = (values) => {
    if (!selectedId) {
      addOp.mutate({ ...values });
    } else {
      updateOp.mutate({ ...values, bankTypeId: selectedId });
    }
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Bank Types</p>
      </div>
      <div className="page-content-wrapper">
        <div className="row h-100">
          <div className="left-side-mid grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                data={data}
                setrowClicked={onRowDoubleClicked}
              />
            </div>
          </div>
          {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
            checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
            <div className="right-side-mid">
              <SectionTitle
                active={!!selectedId}
                editable={false}
                title="Bank Type"
              />
              <div className="">
                <FormProvider {...frmMethods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>
                            Type <span className="text-danger">*</span>
                          </label>
                          <input
                            {...register("type", { required: true })}
                            className={`form-control ${
                              errors["type"] && "required_field"
                            }`}
                          />
                        </div>
                        {errors["type"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between">
                      <button
                        type="button"
                        className="btn btn-outline-primary custom-outline custom-outline"
                        onClick={resetForm}
                      >
                        Reset{" "}
                      </button>
                      <button type="submit" className="btn btn-primary ">
                        {selectedId ? "Save Changes" : "Add Type"}
                      </button>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BankTypes;
