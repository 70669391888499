import { useQuery } from "react-query";
import { getAttributes } from "../InventorySetup/InventorySetup.services";
import DatePicker from "react-datepicker";

const ItemAttributes = ({ attributes, setAttributes, categoryId, isDisabled, setCustomIsDirty = null }) => {

    useQuery([categoryId], getAttributes, {
        enabled: !!categoryId,
        onSuccess(data) {
            if (attributes.length === 0) {
                setAttributes(data)
            } else {
                setAttributes(
                    data.map(attribute => {
                        let common = attributes.find(element => element.attributeId === attribute.itemCategoryAttributeId)
                        if (common)
                            return { ...attribute, value: common.value }
                        else
                            return { ...attribute }
                    })
                )
            }
        },
    });


    const handleInputChange = (index, event) => {
        if (setCustomIsDirty)
            setCustomIsDirty(true);
        const values = [...attributes];
        values[index].value = event.target.value;

        setAttributes(values);
    };


    if (!categoryId)
        return (
            <div className="h-350 mt-4">Please Choose Item Category to set attributes</div>
        )

    if (attributes?.length === 0)
        return (
            <div className="h-350 mt-4">This category has no attributes</div>
        )

    return (
        <div className={`row ${setCustomIsDirty ? 'h-350' : ''}`}>
            {attributes?.map((attribute, index) => {
                return (
                    <div className="col-sm-3" key={index}>
                        <div className="form-group">
                            <label>{attribute.name}</label>
                            {attribute.categoryType < 3 &&
                                <input className='form-control'
                                    value={attribute.value ?? ''}
                                    type={attribute.categoryType === 1 ? 'text' : 'number'}
                                    onChange={(event) => handleInputChange(index, event)}
                                    disabled={isDisabled}
                                />
                            }
                            {attribute.category === 3 &&
                                <DatePicker
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    onChange={(e) => { handleInputChange(index, e) }}
                                    selected={attribute.value ?? null}
                                    isDisabled={isDisabled}
                                />
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default ItemAttributes;