import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { ItemProductLine } from "./InventorySetup.model";

import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import SectionTitle from "elements/SectionTitle";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  createProductLine,
  deleteProductLine,
  editProductLine,
  getProductLines,
} from "./InventorySetup.services";
import { useLocation } from "react-router-dom";

const NewProductLines = ({ match }, props) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const location = useLocation();
  useEffect(() => {
    if (location?.state) {
      const { id, name, description } = location?.state;
      setSelectedId(id);
      setValue("name", name);
      setValue("description", description);
    }
  }, [location.state]);

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty },
  } = formMethods;

  const addProductLine = useMutation(createProductLine, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Product Line ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.product_line);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding product line"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateProductLine = useMutation(editProductLine, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.product_line);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing product line"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();

  const onSubmit = (values) => {
    const data: ItemProductLine = {
      itemProductLineId: selectedId ? selectedId : 0,
      productLine: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addProductLine.mutate(data);
    } else {
      updateProductLine.mutate(data);
    }
  };

  const resetForm = () => {
    reset({ name: "", description: "" });
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addProductLine.isLoading || updateProductLine.isLoading,
    });
  }, [addProductLine.isLoading, updateProductLine.isLoading, appDispatch]);

  return (
    <>
      <div className="static-page">
        <FormProvider {...formMethods}>
          <form id="new-method-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="  page-title page-title-editable">
              <div className="d-flex flex-row">
                <div className="back-btn">
                  <img
                    src={backArrow}
                    alt="back arrow"
                    onClick={() => history.push(AppUrls.product_line)}
                  />
                </div>
                <div>
                  <p className="">Product Line</p>
                </div>
              </div>
              <div className="d-flex" style={{ marginLeft: "10px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline custom-outline"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  {!selectedId ? "Add Product Line" : "Save Changes"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div>
                {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                  checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                  <div className="">
                    <div className="">
                      <div className="row m-3">
                        <div className="col-4">
                          <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                              <>
                                <label>
                                  Product Line{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  {...field}
                                  type={props.isColorInput ? "color" : "text"}
                                  className={`form-control ${
                                    errors.name ? "required_field" : ""
                                  } ${props.isColorInput ? "w-25" : ""}`}
                                  required={true}
                                />
                              </>
                            )}
                          />
                          {errors.name && errors.name.type === "maxLength" && (
                            <p className="text-danger">
                              Max length for name value must not exceed 15
                              characters.
                            </p>
                          )}
                          {errors.name && errors.name.type === "required" && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                        <div className="col-4">
                          <label>
                            Description <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="description"
                            render={({ field }) => (
                              <>
                                <input
                                  {...field}
                                  className={
                                    errors.description
                                      ? "form-control required_field"
                                      : "form-control"
                                  }
                                  required={true}
                                />
                                {errors.description &&
                                  errors.description.type === "required" && (
                                    <p className="text-danger">
                                      This field is required
                                    </p>
                                  )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default NewProductLines;
