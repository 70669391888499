import dotenv from "dotenv";
import axios, { AxiosError, AxiosResponse } from "axios";
import { handleDates } from "global/helpers";

dotenv.config();

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    Pragma: "no-cache",
    "Cache-control": "no-cache",
  },
});

const isExpiredToken = (creationDate: Date, expiresIn: number): boolean => {
  const expiresInMinusOneMinute = (expiresIn - 60) * 1000;
  const expirationTime = new Date(
    creationDate.getTime() + expiresInMinusOneMinute
  );
  const currentTime = new Date();
  return currentTime > expirationTime;
};
api.interceptors.request.use(async function (config) {
  const { accessToken, idToken, refreshToken, creationDate, expiresIn } =
    JSON.parse(localStorage.getItem("blueseedTokens"));
  const company = localStorage.getItem("company");
  const companyId = JSON.parse(localStorage.getItem("companyId"));
  const creationDateValue = new Date(creationDate);
  const isExpired = isExpiredToken(creationDateValue, expiresIn);

  if (isExpired && !!refreshToken) {
    await axios
      .get(`${baseURL}/users/refresh-token?token=${refreshToken}`, {
        headers: {
          companyUniqueAlias: company ?? "",
          companyId: companyId ?? null,
        },
      })
      .then(function (response) {
        let tokens = {
          accessToken: response.data.accessToken,
          idToken: response.data.idToken,
          refreshToken,
          creationDate: new Date(),
          expiresIn: response.data.expiresIn,
        };
        localStorage.setItem("blueseedTokens", JSON.stringify(tokens));
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        config.headers.companyUniqueAlias = company ?? "";
        config.headers.companyId = companyId ?? null;
        config.headers["x-id-token"] = response.data.idToken;
      })
      .catch(function (error: AxiosError) {
        const shouldRedirectToLogin = error.response?.status === 401;
        if (shouldRedirectToLogin) {
          localStorage.removeItem("blueseedTokens");
          localStorage.removeItem("companyId");
          window.location.href = `/login/${localStorage.getItem("company")}`;
        }
      });
  } else if (localStorage.getItem("blueseedTokens")) {
    const { accessToken, idToken, refreshToken, creationDate } = JSON.parse(
      localStorage.getItem("blueseedTokens")
    );
    const company = localStorage.getItem("company");
    const companyId = JSON.parse(localStorage.getItem("companyId"));
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
    config.headers.companyUniqueAlias = company ?? "";
    config.headers.companyId = companyId ?? null;
    config.headers["x-id-token"] = idToken ? idToken : "";
  }
  return config;
});

api.interceptors.response.use(
  function (response: AxiosResponse) {
    handleDates(response?.data);
    return response;
  },
  function (error: AxiosError) {
    const shouldRedirectToLogin = error?.response?.status === 401;
    if (shouldRedirectToLogin) {
      localStorage.removeItem("blueseedTokens");
      localStorage.removeItem("companyId");
      window.location.href = `/login/${localStorage.getItem("company")}`;
    }
    return Promise.reject(error);
  }
);

export default api;
