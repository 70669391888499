import { GridColumns, QueryResult } from "components/Common.models";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import SelectionModal from "components/SelectionModal/SelectionModal";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import Lookup from "elements/Lookup";
import { formatNumber, notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { InvoiceStateArray } from "./InvoiceEnums";
import {
  PaymentMethod,
  SendInvoice,
  SendInvoiceDTO,
  SendInvoiceDetails,
} from "./Invoices.model";
import {
  getPaymentMethods,
  getReceiveInvoiceById,
  getReceiveInvoices,
  payReceiveInvoice,
} from "./InvoicesServices/InvoicesServices";
const SendReceivePayment = () => {
  const history = useHistory();
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<SendInvoiceDetails>();
  const [ledgerData, setLedgerData] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();

  const columns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "Bill No.",
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) =>
        params.value ? InvoiceStateArray[params.value - 1] : "",
    },
    {
      field: "hubKey",
      headerName: "Hub",
    },
    {
      field: "vendorNumber",
      headerName: "Vendor",
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "total",
      headerName: "Total",
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const methods = useForm<SendInvoiceDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty },

    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
  } = methods;

  // const {
  //     data: paymentAccounts,
  // } = useQuery("expenses", () => getLedgers({ ledgerType: LedgerTypeEnum.Asset }));

  const ApiCall = useMutation(getLedgers, {
    onSuccess(res) {
      setLedgerData(res?.data);
    },
  });

  useEffect(() => {
    if (selectedPaymentMethod) {
      if (selectedPaymentMethod === 1) {
        ApiCall.mutate({ ledgerType: 13 });
      } else if (selectedPaymentMethod === 2 || selectedPaymentMethod === 3) {
        ApiCall.mutate({ ledgerType: 14 });
      }
    } else {
      ApiCall.mutate({ ledgerType: 2 });
    }
  }, [selectedPaymentMethod]);

  const { data: invoices } = useQuery<QueryResult<SendInvoice>>(
    "receiveInvoices",
    getReceiveInvoices
  );

  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );

  const getInvoiceDetails = useMutation(getReceiveInvoiceById, {
    async onSuccess(response) {
      setSelectedInvoice(response);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem selecting invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitPayment = useMutation(payReceiveInvoice, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Invoice paid successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("receiveInvoices");
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem paying invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onInvoiceSelected = (invoice) => {
    setValue("receiveInvoiceId", invoice.receiveInvoiceId, {
      shouldDirty: true,
    });
    getInvoiceDetails.mutate(invoice.receiveInvoiceId);
    setOpenSelection(false);
    setSelectedInvoice(invoice);
  };

  const getChangeAmount = () => {
    if (
      getValues("amountPaid") >
      selectedInvoice.total - selectedInvoice.paidAmount
    )
      return formatNumber(
        getValues("amountPaid") -
          (selectedInvoice.total - selectedInvoice.paidAmount)
      );
    return "//";
  };

  const onCancel = () => {
    reset();
    setSelectedInvoice(null);
    setSelectedLedger(null);
  };

  const onSubmit = () => {
    setOpenConfirmation(true);
  };

  const confirmPayment = () => {
    setOpenConfirmation(false);
    let data = getValues();
    data.depositTo = selectedLedger.ledgerId;
    submitPayment.mutate(data);
  };

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("paymentAccountId", e.ledgerId);
  };

  let options = [];
  if (paymentMethods)
    options = paymentMethods.map((method) => ({
      label: method.name,
      value: method.paymentMethodId,
    }));

  const onBackClick = () => {
    history.push(AppUrls.receive_invoice_payment_list);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Send Bill Payment
            </div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                disabled={!isDirty}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                Save Changes
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-5">
                  <div className="row">
                    <div className="col-sm-12">
                      <label>
                        Bill No. <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="receiveInvoiceId"
                        isRequired={true}
                        initialData={invoices?.data
                          ?.filter((s) => s.invoiceState != 4)
                          ?.map((item) => ({
                            ...item,
                            id: item.receiveInvoiceId,
                            name: item.invoiceNumber,
                          }))}
                        onSelection={onInvoiceSelected}
                        inputValue={selectedInvoice?.invoiceNumber}
                        hasError={errors.receiveInvoiceId ? true : false}
                      />
                      {errors.receiveInvoiceId && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-sm-12 mt-4">
                      <label>
                        Payment Method <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="paymentMethodId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={options}
                            isClearable
                            isSearchable={true}
                            value={
                              options.find((opt) => opt?.value === value) ||
                              null
                            }
                            onChange={(selectedOption: any) => {
                              onChange(selectedOption?.value);
                              setSelectedPaymentMethod(selectedOption?.value);
                            }}
                            className={
                              errors["paymentMethodId"] && "required_field"
                            }
                            placeholder="Choose"
                            menuPlacement="auto"
                            isDisabled={!isDirty}
                          />
                        )}
                      />
                      {errors.paymentMethodId && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-sm-12 mt-4">
                      <label>
                        Deposit from <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelectionLedger(true)}
                        inputName="paymentAccountId"
                        isRequired={true}
                        initialData={ledgerData?.map((item) => ({
                          ...item,
                          id: item.ledgerId,
                          name: item.accountName,
                        }))}
                        onSelection={onLedgerSelection}
                        inputValue={selectedLedger?.accountName}
                        hasError={errors.paymentAccountId ? true : false}
                      />
                      {errors["paymentAccountId"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-sm-7 mt-4">
                      <label>
                        Amount Paid <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        {...register("amountPaid", {
                          required: true,
                          min: 1,
                          valueAsNumber: true,
                        })}
                        className={`form-control ${
                          errors["amountPaid"] && "required_field"
                        }`}
                        disabled={!isDirty}
                      />
                      {errors.amountPaid && (
                        <p className="text-danger">
                          Amount Paid should be a positive number
                        </p>
                      )}
                    </div>
                    {selectedPaymentMethod != 1 && (
                      <div className="col-sm-5 mt-4">
                        <label>Check No.</label>
                        <input
                          type="text"
                          {...register("checkNumber")}
                          className="form-control"
                          disabled={!isDirty}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="section row py-4">
                    <div className="col-sm-12">
                      <label>Vendor No.</label>
                      <div>
                        {selectedInvoice ? (
                          selectedInvoice.vendorNumber
                        ) : (
                          <span className="text-muted fst-italic">
                            Vendor No.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      <label>Invoice Date</label>
                      <div>
                        {selectedInvoice ? (
                          new Date(
                            selectedInvoice.orderDate
                          ).toLocaleDateString() +
                          " " +
                          new Date(
                            selectedInvoice.orderDate
                          ).toLocaleTimeString()
                        ) : (
                          <span className="text-muted fst-italic">
                            Invoice Date
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      <label>Amount Due</label>
                      <div>
                        {selectedInvoice ? (
                          formatNumber(
                            selectedInvoice.total - selectedInvoice.paidAmount
                          )
                        ) : (
                          <span className="text-muted fst-italic">
                            Amount Due
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-7 mt-4">
                      <label>Change Amount</label>
                      <div>
                        {selectedInvoice ? (
                          getChangeAmount()
                        ) : (
                          <span className="text-muted fst-italic">
                            Change Amount
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal="Bill"
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={invoices?.data?.filter((s) => s.invoiceState != 4)}
        columns={columns}
        setRowClicked={onInvoiceSelected}
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={ledgerData}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
      <ConfirmationModal
        title="Invoice Payment"
        message={
          <>
            <div>
              You are about to pay invoice number{" "}
              {selectedInvoice?.invoiceNumber}
            </div>
            <div>
              Invoice Balance: $
              {selectedInvoice?.total - selectedInvoice?.paidAmount}
            </div>
            <div>Payment Received: ${getValues("amountPaid")}</div>
            <div className="mt-2">Do you wish to continue?</div>
          </>
        }
        showModal={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => {
          confirmPayment();
        }}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
    </>
  );
};

export default SendReceivePayment;
