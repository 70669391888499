import { statusEnum } from "pages/SalesOrder/StatusEnum";

export const accountNumberColumns = [
    {
        field: "accountNumber",
        headerName: "Customer No.",
    },
    {
        field: "company",
        headerName: "Company Name",
    },
    {
        field: "contact",
        headerName: "Contact",
    },
    {
        field: "hubId",
        headerName: "Hub ID",
    },
    {
        field: "state",
        headerName: "Location",
    },
    {
        field: "accountType",
        headerName: "Customer Type",
    },
    {
        field: "balance",
        headerName: "Balance",
    }
];

export const hubsColumns = [
    {
        field: "hubId",
        headerName: "Hub ID",
        editable: false,
    },
    {
        field: "description",
        headerName: "Location",
        editable: false,
    },
    {
        field: "contactPerson",
        headerName: "Contact",
        editable: false,
    },
    {
        field: "workNumber",
        headerName: "Work No",
        editable: false,
    },
    {
        field: "faxNumber",
        headerName: "Fax No",
        editable: false,
    }
];

export const repsColumns = [
    {
        field: "repName",
        headerName: "Representative Name",
    },
    {
        field: "contact",
        headerName: "Contact",
    },
    {
        field: "workNumber",
        headerName: "Work No.",
    },
    {
        field: "mobileNumber",
        headerName: "Cell No.",
    },
    {
        field: "email",
        headerName: "Email",
    },
];

export const shipColumns = [
    {
        field: "company",
        headerName: "Company Name",
    },
    {
        field: "address.workNumber",
        headerName: "Work No.",
    },
    {
        field: "address.faxNumber",
        headerName: "Fax No.",
    },
    {
        field: "address.address",
        headerName: "Address",
    },
    {
        field: "address.city",
        headerName: "City",
    },
    {
        field: "address.state",
        headerName: "State",
    },
    {
        field: "address.country",
        headerName: "Country",
    },
    {
        field: "address.zipCode",
        headerName: "Zip Code",
    },
]

export const itemsColumns = [
    {
        field: "itemNumber",
        headerName: "Item Number",
    },
    {
        field: "itemDescription",
        headerName: "Item Description",
    },
    {
        field: "quantityAtHand",
        headerName: "Qty/Hand",
    },
    {
        field: "quantityAvailable",
        headerName: "Qty Avail.",
    },
    {
        field: "price1",
        headerName: "Price 1",
    },
    {
        field: "price2",
        headerName: "Price 2",
    },
    {
        field: "price3",
        headerName: "Price 3",
    },
    {
        field: "productLine",
        headerName: "Product Line",
    },
    {
        field: "itemClass",
        headerName: "Item Class",
    },
    {
        field: "itemSubClass",
        headerName: "Sub-Class",
    },
    {
        field: "category",
        headerName: "Category",
    },
    {
        field: "manufacturer",
        headerName: "Manufacturer",
    },
    {
        field: "condition",
        headerName: "Condition",
    },
    {
        field: "color",
        headerName: "Color",
    },
    {
        field: "miscInfo",
        headerName: "Misc Info",
    },
];

export const orderColumns = [
    {
        field: "orderNumber",
        headerName: "Order No.",
    },
    {
        field: "accountNumber",
        headerName: "Account No.",
    },
    {
        field: "company",
        headerName: "Company",
    },
    {
        field: "projectNumber",
        headerName: "Project No.",
    },
    {
        field: "customerPO",
        headerName: "Customer PO#",
    },
    {
        field: "hubId",
        headerName: "Hub ID",
    },
    {
        field: "orderDate",
        headerName: "Order Date",
        cellRenderer: (params) => params.value ? (`${new Date(params.value).toLocaleDateString()} - ${new Date(params.value).toLocaleTimeString()}`) : '',
    },
    {
        field: "orderAmount",
        headerName: "Order Amount",
    },
    {
        field: "shipAmount",
        headerName: "Ship Amount",
    },
    {
        field: "status",
        headerName: "Status",
        cellRenderer: (params) => params.value ? statusEnum[params.value] : '',
    },
];

export const vendorsColumns = [
    {
        field: "vendorNumber",
        headerName: "Vendor No",
    },
    {
        field: "company",
        headerName: "Company",
    },
    {
        field: "contact",
        headerName: "Contact",
    },
    {
        field: "accountId",
        headerName: "Account w/Vendor",
    },
    {
        field: "vendorTypeName",
        headerName: "Type",
    },
    {
        field: "isPreferred",
        headerName: "Preferred?",
    },
    {
        field: "isApproved",
        headerName: "Approved?",
    },
]