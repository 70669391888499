import React, { useState } from "react";
import { useQuery } from "react-query";
import { getInvoiceNbres } from "./DashboardServices";
import { getBaseCurrency } from "components/currency";
import { getCurrencyByCode } from "global/helpers";

const InvoiceNbres = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const { data: invNbre } = useQuery("in", getInvoiceNbres);

  return (
    <div className="h-100">
      <h3>Total Invoices</h3>
      <div className="h-75 mt-5">
        <div className="row">
          <div className="col-1 bg-primary" style={{ width: "25px" }} />
          <div className="col-11">
            <p className="mb-0 mt-3">
              <b>
                {invNbre?.openInvoice} {currencySymbol}
              </b>
            </p>
            <p className="fst-italic">Open Invoice</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1 bg-success" style={{ width: "25px" }} />
          <div className="col-11">
            <p className="mb-0 mt-3">
              <b>
                {invNbre?.overDueIvoices} {currencySymbol}
              </b>
            </p>
            <p className="fst-italic">Over-due Invoice</p>
          </div>
        </div>
        <div className="row">
          <div className="col-1 bg-info" style={{ width: "25px" }} />
          <div className="col-11">
            <p className="mb-0 mt-3">
              <b>
                {invNbre?.paid} {currencySymbol}
              </b>
            </p>
            <p className="fst-italic">Paid</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceNbres;
