import { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom'
import DispatchContext from 'context/DispatchContext';
import PurchaseSelectionModal from "./PurchaseSelectionModal";
import DatePicker from "react-datepicker";
import AG from "elements/AG";
import { typeEnum, statusesEnum } from './StatusEnum';
import { approvePurchaseOrder, getPurchaseOrder, getPurchaseOrders, removeAllSerials, removeSerial } from './PurchasingServices/PurchaseServices';
import { PurchaseOrder, PurchaseOrderDetails } from './PurchasingOrder.model';
import * as AppUrls from "constants/AppUrls";
import { dateFormmaterNoTime, notificationMessage } from 'global/helpers';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Lookup from 'elements/Lookup';
import { FormProvider, useForm } from 'react-hook-form';

const ApprovePurchaseOrder = ({ match }) => {

    const isNormalPo = match.path === AppUrls.approve_po_receipt
    const location = useLocation();
    const appDispatch = useContext(DispatchContext)

    const [openSelection, setOpenSelection] = useState<boolean>(false)
    const [modalRowClicked, setModalRowClicked] = useState<PurchaseOrder>()
    const [selectedPo, setSelectedPo] = useState<PurchaseOrder>()
    const [gridRowChecked, setGridRowChecked] = useState<PurchaseOrder[]>()
    const [orderDetails, setOrderDetails] = useState<PurchaseOrderDetails>()
    const [receivedDate, setReceivedDate] = useState<Date>(new Date());
    const [confirmRemove, setConfirmRemove] = useState<boolean>(false)
    const [confirmRemoveAll, setConfirmRemoveAll] = useState<boolean>(false)
    const [defaultSelected, setDefaultSelected] = useState(null)
    const [openConfirmationClose, setOpenConfirmationClose] = useState<boolean>(false)

    const itemsColumns = [
        {
            headerName: "",
            field: "RowSelect",
            checkboxSelection: true,
            floatingFilter: false,
            filter: false,
            width: "45"
        },
        {
            field: "itemNumber",
            headerName: "Item Number",
        },
        {
            field: "vendorPart",
            headerName: "Vendor Part No",
        },
        {
            field: "description",
            headerName: "Item Description",
        },
        {
            field: "warranty",
            headerName: "Warranty",
        },
        {
            field: "quantityOrdered",
            headerName: "Ordered",
        },
        {
            field: "quantityReceived",
            headerName: "Received/Approved",
        },
        {
            field: "open",
            headerName: "Open",
            cellRendererFramework: (params) => params.data.quantityOrdered - params.data.quantityReceived,
        },
        {
            field: "quantityScanned",
            headerName: "Scanned",
        }
    ]

    const serialsColumns = [
        {
            headerName: "",
            field: "RowSelect",
            checkboxSelection: true,
            floatingFilter: false,
            filter: false,
            width: "45"
        },
        {
            field: "serialNo",
            headerName: "Serial Number",
        },
        {
            field: "itemNumber",
            headerName: "Item Number",
        },
        {
            field: "description",
            headerName: "Item Description",
        },
        {
            field: "scanDate",
            headerName: "Scan Date",
        },
        {
            field: "quantityOrdered",
            headerName: "Quantity",
            cellRendererFramework: () => `1`,
        }
    ]

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const getPOs = useMutation(getPurchaseOrders);

    const getOrderDetails = useMutation(getPurchaseOrder, {
        async onSuccess(response) {
            setModalRowClicked(null)
            setOrderDetails(response.data)

            let serialsArray = []

            response.data?.purchaseOrderItems?.filter(serial => !serial.isAvailable).forEach(e => {
                e.serials.forEach(s => {
                    let serialsObj = {
                        serialId: s.serialId,
                        serialNo: s.serialNo,
                        itemNumber: e.itemNumber,
                        description: e.description,
                        scanDate: dateFormmaterNoTime(s.scanDate),
                        quantityOrdered: e.quantityOrdered
                    }
                    serialsArray.push(serialsObj)
                })
            })
            setSerials(serialsArray)

            let serializedItem = response.data.purchaseOrderItems.find(item => item.itemType === 1)
            if (serializedItem)
                setDefaultSelected(serializedItem)
        }
    });

    const approvePo = useMutation(approvePurchaseOrder, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: "Purchase Order approved successfully",
            };
            appDispatch({ type: "notification", value: notification })
            setModalRowClicked(null)
            setOrderDetails(null)
            setReceivedDate(new Date())
            
            setOpenConfirmationClose(false)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem approving purchase order')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    useEffect(() => {
        appDispatch({ type: "loading", value: approvePo.isLoading })
    }, [approvePo.isLoading, appDispatch]);

    const removePoSerial = useMutation(removeSerial, {
        async onSuccess() {
            setSerials(prevState => prevState.filter(serial => serial.serialId !== gridRowChecked[0].serialId))
            let newOrderDetails = { ...orderDetails }
            let serials = newOrderDetails.purchaseOrderItems.find(item => item.purchaseOrderItemId === gridRowChecked[0].purchaseOrderItemId)
                .serials.filter(serial => serial.serialId !== gridRowChecked[0].serialId)
            let itemFound = newOrderDetails.purchaseOrderItems.find(item => item.purchaseOrderItemId === gridRowChecked[0].purchaseOrderItemId)
            itemFound.serials = serials
            itemFound.quantityScanned = itemFound.quantityScanned - 1
            setOrderDetails(newOrderDetails)
            setGridRowChecked([])

        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem removing serial')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });


    const removeAllPoSerials = useMutation(removeAllSerials, {
        async onSuccess() {
            
            let newOrderDetails = { ...orderDetails }
            let itemFound = newOrderDetails.purchaseOrderItems.find(item => item.purchaseOrderItemId === rowClicked.purchaseOrderItemId)
            itemFound.serials = []
            itemFound.quantityScanned = 0
            setOrderDetails(newOrderDetails)
            setGridRowChecked([])
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem removing serials')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    useEffect(() => {
        if (location) {
            setReceivedDate(new Date())
            setOrderDetails(null)
            
            setSelectedPo(null)
        }
    }, [location])

    useEffect(() => {
        getPOs.mutate({ vendorId: null, hubId: null, poStatus: 5, poType: isNormalPo ? 2 : 3 })
    }, [isNormalPo])

    useEffect(() => {
        if (modalRowClicked) {
            if (statusesEnum[modalRowClicked.poState] === 'Received' || statusesEnum[modalRowClicked.poState] === 'Partially Received' || statusesEnum[modalRowClicked.poState] === 'Partially Fulfilled' || statusesEnum[modalRowClicked.poState] === 'Fulfilled') {
                setOpenSelection(false)
                setGridRowChecked([])
                
                getOrderDetails.mutate({ queryKey: [modalRowClicked['purchaseOrderId']] })
                setSelectedPo(modalRowClicked)
            } else {
                let notification = {
                    variant: "danger",
                    msg: "Only Received or Partially Received orders can be selected"
                };
                appDispatch({ type: "notification", value: notification })
            }
        }
    }, [modalRowClicked])

    const [serials, setSerials] = useState<any>([])
    const [rowClicked, setrowClicked] = useState<any>([])

    const onItemChecked = (item) => {
        setrowClicked(item[0])
        let gridRow = item[0]
        let objArray = []
        gridRow?.serials?.filter(serial => !serial.isAvailable).forEach(e => {
            let obj = {
                purchaseOrderItemId: gridRow.purchaseOrderItemId,
                serialId: e.serialId,
                serialNo: e.serialNo,
                itemNumber: gridRow.itemNumber,
                description: gridRow.description,
                cartonNumber: gridRow.cartonNumber,
                scanDate: gridRow.scanDate,
                quantityOrdered: gridRow.quantityOrdered
            }
            objArray.push(obj)
        })
    }

    const getPoType = () => {
        if (isNormalPo)
            return { value: typeEnum['Normal'], label: 'Normal' }
        return { value: typeEnum['Return'], label: 'Return' }
    }

    const onSubmit = () => {
        if (receivedDate) {
            if(orderDetails.poState === 3) {
                setOpenConfirmationClose(true)
            } else {
                approvePo.mutate({ poId: orderDetails.purchaseOrderId, receiveDate: receivedDate.toUTCString(), close: false })
            }
        } else {
            let notification = {
                variant: "danger",
                msg: 'Please Select Approved Date'
            };
            appDispatch({ type: "notification", value: notification })
        }
    }

    const removeLine = () => {
        setConfirmRemove(false)
        removePoSerial.mutate({ poId: orderDetails.purchaseOrderId, serialId: gridRowChecked[0].serialId })
    }

    const removeAllLines = () => {
        setConfirmRemoveAll(false)
        removeAllPoSerials.mutate({ poItemId: rowClicked.purchaseOrderItemId })
    }

    const closePO = (status) => {
        if(status) {
            approvePo.mutate({ poId: orderDetails.purchaseOrderId, receiveDate: receivedDate.toUTCString(), close: true })
        } else {
            approvePo.mutate({ poId: orderDetails.purchaseOrderId, receiveDate: receivedDate.toUTCString(), close: false })
        }
    }
    useEffect(() => {
        if (location.state) {
            setModalRowClicked(getPOs?.data?.data?.find(i => i.purchaseOrderId == location.state.poId))
        }
    }, [location, getPOs?.data?.data]);
    return (
        <div className="grid-page">
            <FormProvider {...methods}>
                <div className="page-title page-title-editable">
                    <p>Purchase Order Receipt Approval</p>
                    <div>
                        <button className="btn btn-success" disabled={!orderDetails} onClick={onSubmit}>
                            Approve
                        </button>
                    </div>
                </div>
                <div className="page-content-wrapper">
                    <div className="page-content">
                        <div className="grid-container has-filters">
                            <div className="filters row">
                                <div className="col-lg-3 col-sm-12">
                                    <label>PO No. <span className="text-danger">*</span></label>
                                    <Lookup
                                        onButtonClicked={() => setOpenSelection(true)}
                                        inputName="purchaseOrderNumber"
                                        isRequired={true}
                                        initialData={isNormalPo ? getPOs?.data?.data?.filter(p => p.poState === 2 || p.poState === 3 ).map(item => ({ ...item, id: item.purchaseOrderId, name: item.purchaseOrderNumber })) : getPOs?.data?.data?.map(item => ({ ...item, id: item.purchaseOrderId, name: item.purchaseOrderNumber }))}
                                        onSelection={setModalRowClicked}
                                        inputValue={selectedPo?.purchaseOrderNumber}
                                    />
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <label>Approved Date <span className="text-danger">*</span></label>
                                    <DatePicker
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={15}
                                        scrollableYearDropdown
                                        onChange={(date: Date) => setReceivedDate(date)}
                                        selected={receivedDate}
                                        className="w-50"
                                    />
                                </div>
                            </div>
                            <div className="grid mt-4">
                                <div className='row section px-0 ps-2 py-3 pb-0 '>
                                    <div className='ps-0'>
                                        <h4><b>Ordered Items</b></h4>
                                    </div>
                                </div>
                                <div className='h-50'>
                                    <AG
                                        data={orderDetails ? orderDetails.purchaseOrderItems : []}
                                        columns={itemsColumns}
                                        setRowChecked={onItemChecked}
                                        selectItem={defaultSelected}
                                    />
                                </div>
                                <div className='row section mt-4 px-0 ps-2 py-3 pb-0'>
                                    <div className='ps-0 d-flex justify-content-between align-items-center'>
                                        <h4><b>Received serials</b></h4>
                                        <div className='pb-3'>
                                            <button
                                                className='btn btn-danger'
                                                disabled={serials.length !== 0 && gridRowChecked ? gridRowChecked.length === 0 : true}
                                                onClick={() => setConfirmRemove(true)}
                                            >
                                                Remove Selected Line
                                        </button>
                                            <button
                                                className='btn btn-danger'
                                                disabled={serials.length === 0}
                                                onClick={() => setConfirmRemoveAll(true)}
                                            >
                                                Remove All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='h-50 mb-5 '>    
                                    <AG
                                        data={serials}
                                        columns={serialsColumns}
                                        setRowChecked={setGridRowChecked}
                                    />
                                </div>
                                <div className="py-1    "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormProvider>
            <PurchaseSelectionModal
                showModal={openSelection}
                onExit={setOpenSelection}
                onSelectedItem={setModalRowClicked}
                preDefinedType={getPoType()}
                ShowOnlyReceivedAndPartiallyReceived={true}
            />
            <ConfirmationModal
                title="Remove Selected Serial"
                message="This will delete the selected serial from the purchase order. Are you sure you want to proceed?"
                onClose={() => setConfirmRemove(false)}
                onConfirm={() => removeLine()}
                cancelBtnTitle="Keep"
                confirmBtnTitle="Delete"
                type="confirmation-danger"
                showModal={confirmRemove}
                onCancel={() => setConfirmRemove(false)}
            />
            <ConfirmationModal
                title="Remove All Serials"
                message="This will delete all the serials from the purchase order. Are you sure you want to proceed?"
                onClose={() => setConfirmRemoveAll(false)}
                onConfirm={() => removeAllLines()}
                cancelBtnTitle="Keep"
                confirmBtnTitle="Delete"
                type="confirmation-danger"
                showModal={confirmRemoveAll}
                onCancel={() => setConfirmRemoveAll(false)}
            />
            <ConfirmationModal
                title="Approve Purchase Order"
                message={`Do you wish to finalize this purchase order now?`}
                showModal={openConfirmationClose}
                onClose={() => {closePO(false)}}
                onConfirm={() => {closePO(true)}}
                cancelBtnTitle="No"
                confirmBtnTitle="Yes"
                type="confirmation-primary"
                onCancel={() => setOpenConfirmationClose(false)}
            />
        </div>
    )
}

export default ApprovePurchaseOrder