import {
  DropDownOption,
  GridColumns,
  QueryResult,
} from "components/Common.models";
import DateGrid from "components/DateFilter/DateGrid";
import SelectionModal from "components/SelectionModal/SelectionModal";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AgGrid from "elements/AgGrid";
import Lookup from "elements/Lookup";
import { dateFormmater } from "global/helpers";
import addIcon from "img/add-icon.svg";
import exportIcon from "img/export.svg";
import { CustomerAccount } from "pages/SalesMarketing/Customers/Customers.models";
import { getCustomers } from "pages/SalesMarketing/Customers/Customers.services";
import { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { PaymentMethod, Receipt } from "./Invoices.model";
import {
  getPaymentMethods,
  getReceipts,
} from "./InvoicesServices/InvoicesServices";
const PaymentReceipt = () => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerAccount>();
  const [gridData, setGridData] = useState<Receipt[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<DropDownOption>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const location = useLocation();
  const preFiltered = useRef<boolean>(false);
  const [dateCategory, setDateCategory] = useState(null);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { isLoading: isLoadingCustomers, data: customers } = useQuery<
    QueryResult<CustomerAccount>
  >("customersList", getCustomers);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingCustomers });
  }, [isLoadingCustomers]);

  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );

  const onCustomerSelected = (customer) => {
    setSelectedCustomer(customer);
    setOpenSelection(false);
  };

  const accountNumberColumns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const paymentsReceiptColumns: GridColumns[] = [
    {
      field: "checkNumber",
      headerName: "Check Number",
      width: "150px",

      editable: false,
    },
    {
      field: "receiptNumber",
      headerName: "Receipt No",
      width: "130px",

      editable: false,
    },
    {
      field: "accountNumber",
      headerName: "Customer No",
      width: "140px",

      editable: false,
    },
    {
      field: "date",
      headerName: "Paid Date",
      editable: false,
      valueGetter: (params) => {
        return dateFormmater(params?.data?.date);
      },
    },
    {
      field: "payment",
      headerName: "Paid Amount",
      width: "170px",

      valueGetter: (params) => {
        return `$${params.data?.payment?.toFixed(2) ?? "0.00"}`;
      },
      editable: false,
    },
    {
      field: "paymentMethod",
      headerName: "Pay Method",
      width: "130px",

      editable: false,
    },
    {
      field: "unapplied",
      headerName: "Un-applied",
      editable: false,
      valueGetter: (params) => {
        return `$${params.data?.unapplied?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: "150px",

      editable: false,
      valueGetter: (params) => {
        return `$${
          (params.data?.unapplied + params.data?.payment).toFixed(2) ?? "0.00"
        }`;
      },
    },
  ];

  const getReceiptsList = useMutation(getReceipts, {
    async onSuccess(response) {
      setGridData(response?.data);
      preFiltered.current = false;
    },
  });

  const onMethodChange = (e) => {
    setSelectedMethod(e);
  };

  useEffect(() => {
    if (location.state && appState.gridFilters) {
      preFiltered.current = true;
      if (appState.gridFilters.selectedCustomer)
        setSelectedCustomer(appState.gridFilters.selectedCustomer);
      if (appState.gridFilters.selectedMethod)
        setSelectedMethod(appState.gridFilters.selectedMethod);
      if (appState.gridFilters.startDate)
        setStartDate(appState.gridFilters.startDate);
      if (appState.gridFilters.endDate)
        setEndDate(appState.gridFilters.endDate);
    }
  }, [location, appState.gridFilters]);

  const displayFilter = () => {
    let queryParams = "?";
    if (startDate)
      queryParams += `&from=${new Date(startDate).toLocaleDateString()}`;
    if (endDate) queryParams += `&to=${new Date(endDate).toLocaleDateString()}`;
    if (selectedMethod)
      queryParams += `&paymentMethodId=${selectedMethod.value}`;
    if (selectedCustomer)
      queryParams += `&customerId=${selectedCustomer.customerAccountId}`;
    if (
      preFiltered.current === false ||
      (preFiltered.current === true && queryParams !== "?")
    )
      getReceiptsList.mutate(queryParams);
  };

  const clearFilters = () => {
    setSelectedCustomer(null);
    setSelectedMethod(null);
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
    getReceiptsList.mutate("");
  };

  useEffect(() => {
    getReceiptsList.mutate("");
  }, []);

  let history = useHistory();

  const onRowDoubleClicked = (e) => {
    let url = `${AppUrls.payment_receipt}/${e.paymentReceiptId}`;
    if (Boolean(selectedCustomer || selectedMethod || startDate || endDate))
      appDispatch({
        type: "filter",
        value: { selectedCustomer, selectedMethod, startDate, endDate },
      });
    else appDispatch({ type: "filter", value: null });
    history.push(url);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <div className="grid-page">
            <div className="page-title page-title-editable">
              <div>Payment Receipt</div>
              <div>
                <button
                  className="import-btn"
                  type="button"
                  onClick={exportToExcel}
                  disabled={disabledExportBtn || gridData?.length === 0}
                >
                  <img src={exportIcon} className="me-2" alt="" />
                  Export to Excel
                </button>
                <Link
                  to={AppUrls.new_payment_receipt}
                  className="btn btn-primary"
                >
                  <img src={addIcon} className="me-2" alt="" /> Add New Customer
                  Payment
                </Link>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="grid-container has-filters">
                <div className="filters">
                  <DateGrid
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateCategory={dateCategory}
                    setDateCategory={setDateCategory}
                  />
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label className="label">Customer No.</label>
                        <Lookup
                          onButtonClicked={() => setOpenSelection(true)}
                          inputName="customerAccountId"
                          initialData={customers?.data?.map((item) => ({
                            ...item,
                            id: item.customerAccountId,
                            name: item.accountNumber,
                          }))}
                          onSelection={onCustomerSelected}
                          inputValue={selectedCustomer?.accountNumber}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <label className="label">Payment Method</label>
                      <Select
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={onMethodChange}
                        value={selectedMethod}
                        options={paymentMethods?.map((item) => ({
                          value: item.paymentMethodId,
                          label: item.name,
                        }))}
                        isClearable
                      />
                    </div>
                    <div className="col-sm-2 d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={displayFilter}
                      >
                        Display
                      </button>
                      <button
                        type="button"
                        onClick={clearFilters}
                        className="btn btn-outline-primary custom-outline"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <AgGrid
                    columns={paymentsReceiptColumns}
                    data={gridData}
                    setrowClicked={onRowDoubleClicked}
                    exportToExcel={exportToExcelClicked}
                    setExportToExcelClicked={setExportToExcelClicked}
                    setDisabledExportBtn={setDisabledExportBtn}
                    setIsLoadingGridExport={setIsLoadingGridExport}
                    fileName="Payment receipt List Report"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={customers?.data}
        columns={accountNumberColumns}
        setRowClicked={onCustomerSelected}
      />
    </>
  );
};

export default PaymentReceipt;
