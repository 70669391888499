import AG from "elements/AG";
import { dateFormmaterNoTime } from "global/helpers";
import { GridColumns } from "components/Common.models";

type Props = {
    vendorPayment
};

const BillPayment = (props: Props) => {

    const columns: GridColumns[] = [
        {
            field: "receiptNumber",
            headerName: "Receipt No",
        },
        {
            field: "receiveInvoice.invoiceNumber",
            headerName: "Bill No.",
        },
        {
            field: "paymentAmount",
            headerName: "Payment Amount",
            valueGetter: (params) => { return `$${params.data?.paymentAmount?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "paymentDate",
            headerName: "Payment Date",
            valueGetter: (params) => { return dateFormmaterNoTime(params?.data?.paymentDate) }
        },
    ]

    return (
        <>
            <div className='col-sm-12'>
                <div className="h-450 mt-4 px-0">
                    <AG
                        data={props?.vendorPayment}
                        columns={columns}
                        autoColumns={true}
                    />
                </div>
            </div>
        </>
    )
}

export default BillPayment;
