import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import addIcon from "../../img/add-icon.svg";
import { getTaxRates } from "./SystemSetupServices/SystemSetupServices";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

const TaxSetup = () => {
  const appDispatch = useContext(DispatchContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data } = useQuery(["taxes", filter], getTaxRates);

  const columns = [
    {
      field: "state",
      headerName: "State",
    },
    {
      field: "stateTaxRate",
      headerName: "Tax Rate",
    },
  ];

  const history = useHistory();
  const onRowDoubleClicked = (e) => {
    const url = `${AppUrls.new_tax_setup}`;
    history.push({
      pathname: url,
      state: {
        id: e.taxId,
        name: e.state,
        rate: e.stateTaxRate,
      },
    });
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Tax Rates</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || data?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <Link to={AppUrls.new_tax_setup} className="btn btn-primary">
            <img src={addIcon} className="me-2" alt="" /> New Tax Rates
          </Link>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="row h-100">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                data={data}
                setrowClicked={onRowDoubleClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                fileName="Tax Rates List Report"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxSetup;
