import * as AppUrls from "constants/AppUrls";
import { dateFormmaterNoTime, getCurrencyByCode } from "global/helpers";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { GridColumns } from "components/Common.models";
import { InvoiceState } from "pages/Invoices/InvoiceEnums";
import DispatchContext from "context/DispatchContext";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";
import { useContext, useEffect, useState } from "react";
import { getAdminCreditMemos } from "./Services/adminServices";
import { getBaseCurrency } from "components/currency";

const CmManagement = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const [filters, setFilters] = useState({
    searchQuery: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const columns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "CM.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => InvoiceState[params.value],
    },
    {
      field: "date",
      headerName: "CM Date",
      cellRenderer: (params) =>
        params.value ? `${dateFormmaterNoTime(params.value)}` : "",
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "salesOrder",
      headerName: "Sales Order",
    },
  ];

  const dataMutation = useMutation("data", getAdminCreditMemos);

  const onRowDoubleClicked = (e) => {
    let url = `${AppUrls.ecommerce_cm}/${e.invoiceId}`;
    history.push(url);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ ...filters, searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>CM Management</p>
        <button
          className="import-btn"
          type="button"
          onClick={exportToExcel}
          disabled={disabledExportBtn || dataGrid?.length === 0}
        >
          <img src={exportIcon} className="me-2" alt="" />
          Export to Excel
        </button>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="form-group">
            <label className="me-2">Search</label>
            <div className="page-search">
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5 me-4 w-200"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => onSearchTermChange(e)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            fetchMethod={dataMutation}
            data={dataGrid}
            filters={filters}
            setDataGrid={setDataGrid}
            setrowClicked={onRowDoubleClicked}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="CM Management List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default CmManagement;
