import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useParams } from "react-router-dom";

import { getInventoryItemDetailAPI } from "./services/ReportServices";

import { useQuery } from "react-query";

const InventoryItemDetailReport = () => {
  const history = useHistory();

  const { id } = useParams();
  const pageTitle = "Inventory Item Detail Report";
  const backUrl = AppUrls.inventory_list;

  // const { data, isLoading, isSuccess } = useQuery(
  //   id,
  //   getInventoryItemDetailAPI
  // );

  const tableHeaders = ["Item Number", "Description", "Active", "Average Cost"];
  const tableHeaders2 = [
    "On Hand",
    "On PO",
    "On SO",
    "Selling QTY",
    "Retail",
    "Taxable",
  ];

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="static-page">
        <div className="page-content-wrapper p-0">
          <div className="grid-container"></div>
        </div>
      </div>
    </form>
  );
};

export default InventoryItemDetailReport;
