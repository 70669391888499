import AG from "elements/AG";
import { dateFormmater, formatNumber } from "global/helpers";
import { GridColumns } from "components/Common.models";

type Props = {
    vendorDebit
};

const DebitCredit = (props: Props) => {

    const columns: GridColumns[] = [
        {
            field: "accountNumber",
            headerName: "Account No."
        },
        {
            field: "type",
            headerName: "Type"
        },
        {
            field: "company",
            headerName: "Company"
        },
        {
            field: "status",
            headerName: "Status"
        },
        {
            field: "date",
            headerName: "Date",
            cellRenderer: (params) => dateFormmater(params.data.date),
        },
        {
            field: "amount",
            headerName: "Amount",
            cellRenderer: (params) => formatNumber(params.value)
        },
        {
            field: "balance",
            headerName: "Balance",
            cellRenderer: (params) => formatNumber(params.value)
        },
        {
            field: "paidAmount",
            headerName: "Paid Amount",
            cellRenderer: (params) => formatNumber(params.value)
        },
    ];

    return (
        <>
            <div className='col-sm-12'>
                <div className="h-450 mt-4 px-0">
                    <AG
                        data={props?.vendorDebit}
                        columns={columns}
                        autoColumns={true}
                    />
                </div>
            </div>
        </>
    )
}

export default DebitCredit;
