import Close from "img/close.svg";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
// import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import SelectionModal from "components/SelectionModal/SelectionModal";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import { formatNumber, notificationMessage } from "global/helpers";
import { ReactComponent as CaretUp } from "img/caretup.svg";
import Delete from "img/delete.svg";
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  addOperation,
  editOperation,
  getLedgers,
  getOperationDetails,
  postOp,
  unpostOp,
} from "./AccountingServices";
import LedgerSelection from "./LedgerSelection";
import { OrderTypeEnum } from "./Operations";

type Props = {
  showModal: boolean;
  onExit: () => void;
  selectedId: number | null;
  setSelectedId: Dispatch<SetStateAction<number>>;
  previousOps: number[];
  setPreviousOps: Dispatch<SetStateAction<number[]>>;
};

const orderUrls = {
  1: AppUrls.invoices_list,
  2: `${AppUrls.credits_memo_list}/view`,
  3: AppUrls.payment_receipt,
  4: `${AppUrls.purchase_order_list}/view`,
  5: `${AppUrls.purchase_order_return_list}/view`,
  6: AppUrls.payment_receipt,
  7: AppUrls.refund_credit_memo,
  8: AppUrls.expenses,
  9: AppUrls.cheque,
  10: AppUrls.fixed_assets,
  11: `${AppUrls.receive_invoices_list}/view`,
  12: AppUrls.sales_order_list,
  13: AppUrls.manual_inventory_receipt,
  14: AppUrls.manual_inventory_issue,
  15: AppUrls.customer_rma,
  16: AppUrls.vendor_rma,
  17: AppUrls.bank_reconciliation,
  18: AppUrls.bank_reconciliation,
  19: AppUrls.bank_reconciliation,
  20: AppUrls.bank_reconciliation,
  21: AppUrls.bank_reconciliation,
  22: AppUrls.bank_reconciliation,
  23: AppUrls.bank_reconciliation,
  24: AppUrls.bank_reconciliation,
  25: AppUrls.bank_reconciliation,
  26: AppUrls.banks,
  27: AppUrls.payroll_list,
};

const OperationsModal = (props: Props) => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const gridRef = useRef<any>();
  const rowRef = useRef<number>();
  const postAfterSubmit = useRef<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [postedState, setPostedState] = useState<boolean>(false);
  const [, setRefresh] = useState<number>(0);

  // const { data: costCenters } = useQuery("costCenters", getCostCenters);
  const { data: ledgers } = useQuery("ledgers", getLedgers);
  const { refetch, data: opInfo } = useQuery(
    [[props.selectedId]],
    getOperationDetails,
    {
      enabled: !!props.selectedId,
      onSuccess(data) {
        setValue("isPosted", data.isPosted);
        setPostedState(data.isPosted);
        setValue("operationDate", data.operationDate);
        setValue("operationNumber", data.operationNumber);
        setValue("orderCode", data.orderCode);
        setTimeout(() => {
          if (getRowData().length === 0)
            gridRef?.current?.api.applyTransaction({
              add: data.journals.map((journal) => ({
                ...journal,
                accountName: {
                  accountName: journal.accountName,
                  ledgerId: journal.ledgerId,
                },
                costCenter: {
                  costCenterId: journal.costCenterId,
                  name: journal.costCenter,
                },
              })),
            });
          setRefresh((prev) => prev + 1);
        }, 500);
      },
    }
  );

  const viewOnly = props.selectedId
    ? OrderTypeEnum[opInfo?.orderType]
      ? true
      : false
    : false;

  const addMutation = useMutation(addOperation, {
    async onSuccess(data) {
      let notification = {
        variant: "success",
        msg: "Operation added successfully",
      };
      props.setPreviousOps((prev) => [...prev, data.operationId]);
      appDispatch({ type: "notification", value: notification });
      if (postAfterSubmit.current === true) {
        props.setSelectedId(data.operationId);
        postMutation.mutate(data.operationId);
        postAfterSubmit.current = false;
      } else {
        gridRef.current.api.setRowData([]);
        addRow();
      }
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding operation"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editMutation = useMutation(editOperation, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Operation edited successfully",
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing operation"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const postMutation = useMutation(postOp, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Operation posted successfully",
      };
      appDispatch({ type: "notification", value: notification });
      refetch();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem posting operation"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const unpostMutation = useMutation(unpostOp, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Operation unposted successfully",
      };
      appDispatch({ type: "notification", value: notification });
      refetch();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem unposting operation"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const columns = [
    {
      field: "journalNumber",
      headerName: "Journal #",
      width: 120,
    },
    {
      field: "accountNumber",
      headerName: "Account #",
      cellRendererFramework: (params) => {
        return (
          <LedgerSelection
            initialData={ledgers?.data
              .filter((account) => account.accountNumber !== null)
              .map((ledger) => ({
                ...ledger,
                id: ledger.ledgerId,
                name: `${ledger.accountNumber}-${ledger.accountName}`,
              }))}
            inputValue={params.value}
            onSelection={(data) => onLedgerSelection(data, params?.rowIndex)}
            onButtonClicked={() => {
              setShowAccountModal(true);
              rowRef.current = params.rowIndex;
            }}
            isDisabled={viewOnly || (!!props.selectedId ? postedState : false)}
          />
        );
      },
      cellClass: "overflow-visible",
      width: 250,
    },
    {
      field: "accountName",
      headerName: "Account Name",
      cellRenderer: (params) => params.value?.accountName,
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      editable: !viewOnly && (!!props.selectedId ? !postedState : true),
      flex: 1,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      cellRenderer: function (params) {
        var input = document.createElement("input");
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = viewOnly || (!!props.selectedId ? postedState : false);
        input.addEventListener("click", function () {
          params.value = !params.value;
          params.node.data.isDebit = params.value;
          setRefresh((prev) => prev + 1);
        });
        return input;
      },
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      editable: !viewOnly && (!!props.selectedId ? !postedState : true),
      width: 120,
      cellRenderer: (params) =>
        isNaN(params.value) ? null : formatNumber(params.value),
      onCellValueChanged: () => setRefresh((prev) => prev + 1),
    },
    // {
    //     field: "costCenter",
    //     headerName: "Cost Center",
    //     editable: !viewOnly && (!!props.selectedId ? !postedState : true),
    //     cellEditor: 'agRichSelectCellEditor',
    //     cellEditorParams: {
    //         cellHeight: 50,
    //         values: costCenters,
    //         formatValue: value => value?.name,
    //     },
    //     cellRenderer: (params) => params.value?.name,
    //     width: 150
    // },
    {
      field: "",
      headerName: "Action",
      width: 150,
      cellRendererFramework: () => {
        return (
          <button
            type="button"
            className="btn text-danger"
            disabled={props.selectedId && postedState}
          >
            <img src={Delete} alt="Delete Icon" />
            Delete
          </button>
        );
      },
      onCellClicked: (params) => {
        if ((props.selectedId && !postedState) || !!!props.selectedId)
          gridRef?.current?.api.applyTransaction({
            remove: [params.data],
          });
      },
      hide: props.selectedId
        ? OrderTypeEnum[opInfo?.orderType]
          ? true
          : false
        : false,
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "category",
      headerName: "Category",
      resizable: true,
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      resizable: true,
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      isPosted: false,
      operationDate: null,
      operationNumber: null,
      orderCode: null,
      orderType: null,
    },
  });

  const addRow = () => {
    const newItems = [
      {
        journalNumber: getRowData().length + 1,
        accountNumber: null,
        accountName: null,
        description: null,
        isDebit: false,
        amount: null,
        costCenter: null,
      },
    ];
    gridRef?.current?.api.applyTransaction({
      add: newItems,
    });
  };

  const getRowData = () => {
    const rowData = [];
    gridRef?.current?.api.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  };

  const onLedgerSelection = (nodeData, rowIndex) => {
    setShowAccountModal(false);
    const itemsToUpdate = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      if (index != rowIndex) {
        return;
      }
      const data = rowNode.data;
      data.accountName = nodeData;
      data.accountNumber = nodeData.accountNumber;
      itemsToUpdate.push(data);
    });
    gridRef.current.api.applyTransaction({ update: itemsToUpdate });
  };

  const resetForm = () => {
    reset();
    props.setSelectedId(null);
    gridRef.current.api.setRowData([]);
  };

  const closeAndReset = () => {
    resetForm();
    props.onExit();
  };

  const showSource = () => {
    if (opInfo?.orderId)
      history.push(`${orderUrls[opInfo?.orderType]}/${opInfo?.orderId}`);
    else history.push(`${orderUrls[opInfo?.orderType]}`);
  };

  const onPostClick = () => {
    postMutation.mutate(props.selectedId);
  };

  const onUnpostClick = () => {
    unpostMutation.mutate(props.selectedId);
  };

  const onPreviousClick = () => {
    resetForm();
    let prev;
    if (props.selectedId === null)
      prev = props.previousOps[props.previousOps.length - 1];
    else
      prev = props.previousOps[props.previousOps.indexOf(props.selectedId) - 1];
    props.setSelectedId(prev);
  };

  const onNextClick = () => {
    resetForm();
    let index = props.previousOps.indexOf(props.selectedId);
    if (index + 1 === props.previousOps.length) props.setSelectedId(null);
    else props.setSelectedId(props.previousOps[index + 1]);
  };

  const onSubmit = (data) => {
    let rowData = getRowData();
    let debitTotal = 0;
    let creditTotal = 0;
    let isDataValid = true;
    for (let row of rowData) {
      if (row.accountNumber === null) {
        isDataValid = false;
        break;
      }
      if (row.isDebit)
        debitTotal += !isNaN(row.amount) ? Number(row.amount) : 0;
      else creditTotal += !isNaN(row.amount) ? Number(row.amount) : 0;
    }
    if (isDataValid) {
      if (debitTotal !== 0 && debitTotal === creditTotal) {
        let newData = {
          ...data,
          journals: rowData.map((journal) => ({
            ...journal,
            ledgerId: journal.accountName.ledgerId,
            costCenterId: journal?.costCenter?.costCenterId,
          })),
        };
        if (props.selectedId) {
          editMutation.mutate({ ...newData, id: props.selectedId });
        } else {
          addMutation.mutate(newData);
        }
      } else {
        let notification = {
          variant: "danger",
          msg: "Debit and Credit amount should be equal",
        };
        appDispatch({ type: "notification", value: notification });
      }
    } else {
      let notification = {
        variant: "danger",
        msg: "Please enter account number",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const getTotal = () => {
    return getRowData().reduce(
      (previousValue, currentValue) =>
        previousValue +
        (currentValue.isDebit ? 0 : Number(currentValue.amount)),
      0
    );
  };

  return (
    <>
      <Modal
        show={props.showModal}
        centered
        size="lg"
        dialogClassName="modal-80w"
      >
        <div className={`modal-mask ${!showAccountModal && "d-none"}`}></div>
        <Modal.Header>
          <Modal.Title>
            <div className="mb-4">Add Operation</div>
            <button
              onClick={onPreviousClick}
              disabled={
                props.previousOps.length === 0 ||
                props.selectedId === props.previousOps[0]
              }
              className="btn btn-outline-primary"
            >
              <CaretUp
                style={{
                  transform: "rotate(-90deg)translateX(2px)",
                  marginRight: "5px",
                }}
              />
              Previous
            </button>
            <button
              onClick={onNextClick}
              disabled={
                props.previousOps.length === 0 || props.selectedId === null
              }
              className="btn btn-outline-primary"
            >
              Next
              <CaretUp
                style={{
                  transform: "rotate(90deg)translateX(-2px)",
                  marginLeft: "5px",
                }}
              />
            </button>
          </Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className=""
            role="button"
            onClick={closeAndReset}
          />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row gy-4">
              <div className="col-sm-4">
                <div className="form-group">
                  <label>
                    Date<span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="operationDate"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        onChange={onChange}
                        selected={value ? value : null}
                        disabled={viewOnly}
                      />
                    )}
                  />
                </div>
                {errors["operationDate"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Operation #</label>
                  <input
                    {...register("operationNumber")}
                    className={"form-control"}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Order Code</label>
                  <input
                    {...register("orderCode")}
                    className={"form-control"}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Order Type</label>
                  {/* <Controller
                                        control={control}
                                        name="orderType"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Select
                                                options={[]}
                                                isSearchable={true}
                                                value={[]?.find(opt => opt?.value === value) || null}
                                                onChange={(val) => onChange(val?.value)}
                                                isClearable={true}
                                                isDisabled={true}
                                            />
                                        )}
                                    /> */}
                  <div>
                    {props.selectedId
                      ? OrderTypeEnum[opInfo?.orderType] ?? "Manual"
                      : "Manual"}
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <Controller
                  control={control}
                  name="isPosted"
                  render={({ field: { onChange, value } }) => (
                    <div className="form-check mb-5 mt-3">
                      <input
                        className={`form-check-input`}
                        type="checkbox"
                        id="postState"
                        onChange={() => onChange(!value)}
                        checked={value}
                        disabled
                      />
                      <label
                        className={`form-check-label text-black`}
                        htmlFor="postState"
                      >
                        Posted state
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
            <hr className="mt-3 mb-5" />
            <div className="row">
              <div className="col-sm-12 border p-4">
                <div className="ag-theme-alpine h-350 operations-grid">
                  <AgGridReact
                    ref={gridRef}
                    rowData={[]}
                    columnDefs={columns}
                    animateRows={true}
                    stopEditingWhenCellsLoseFocus={true}
                  ></AgGridReact>
                </div>
                <div className="my-3 text-end">
                  <b>Total: {formatNumber(getTotal())}</b>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    onClick={addRow}
                    type="button"
                    disabled={!!props.selectedId ? postedState : false}
                    className="btn btn-outline-primary"
                  >
                    Add new
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5 d-flex justify-content-between">
              <div>
                <button
                  type="button"
                  onClick={onPostClick}
                  disabled={!!!props.selectedId || postedState}
                  className="btn btn-primary ms-0"
                >
                  Post
                </button>
                <button
                  type="button"
                  onClick={onUnpostClick}
                  disabled={!!!props.selectedId || !postedState}
                  className="btn btn-outline-primary"
                >
                  Unpost
                </button>
                {!props.selectedId && (
                  <button
                    type="submit"
                    onClick={() => (postAfterSubmit.current = true)}
                    className="btn btn-outline-primary"
                  >
                    Save & Post
                  </button>
                )}
                <button
                  type="submit"
                  disabled={
                    viewOnly || (props.selectedId ? postedState : false)
                  }
                  className="btn btn-outline-primary"
                >
                  {props.selectedId ? "Save Changes" : "Save & New"}
                </button>
                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-outline-primary"
                >
                  Cancel
                </button>
              </div>
              <button
                type="button"
                disabled={!!!props.selectedId || opInfo?.orderType === null}
                className="btn btn-outline-primary"
                onClick={showSource}
              >
                Show source
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <SelectionModal
        modal="account"
        withPagination={false}
        showModal={showAccountModal}
        setShowModal={setShowAccountModal}
        data={ledgers?.data.filter((account) => account.accountNumber !== null)}
        columns={ledgersColumns}
        setRowClicked={(data) => onLedgerSelection(data, rowRef.current)}
      />
    </>
  );
};

export default OperationsModal;
