import { useContext, useEffect, useRef, useState } from "react";
import { getJournalsReport } from "./AccountingServices";
import "./Ledgers.scss";

import DispatchContext from "context/DispatchContext";
import { useReactToPrint } from "react-to-print";

import { useMutation, useQuery } from "react-query";

import { useHistory, useLocation } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { FormProvider, useForm } from "react-hook-form";
import BlueSeedLogo from "img/BlueSeed-logo-blue.png";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";
import Filter from "reports/components/Filter";
import { getBaseCurrency } from "components/currency";

const JournalsReport = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const [reportName, setReportName] = useState("Journals");
  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const [, setRefreshCounter] = useState(0);

  const [reportData, setData] = useState(null);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const generateReport = useMutation(getJournalsReport, {
    onSuccess(data) {
      let currentData = { ...data };
      setData(currentData);
    },
  });

  const onSubmit = () => {
    const values = getValues();
    let params = {
      startDate: null,
      endDate: null,
      customerId: null,
      accountTypeId: null,
      type: null,
      hubId: null,
      itemId: null,
      itemClassId: null,
      subClassId: null,
      productLineId: null,
      supplierId: null,
      categoryId: null,
      manufacturerId: null,
      isSerialized: null,
    };
    if (values.startDate)
      params.startDate = fixTimezoneOffset(values.startDate);
    if (values.endDate) params.endDate = fixTimezoneOffset(values.endDate);
    else {
      params.endDate = fixTimezoneOffset(new Date());
    }

    generateReport.mutate(params);
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    setForPrinting(true);
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setForPrinting(false);
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const onBackClick = () => {
    history.push("/reports");
  };

  const [forPrinting, setForPrinting] = useState(false);
  const reportPage = useRef(null);

  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const columns = [
    [
      {
        field: "operationNumber",
        headerName: "Operation No",
        cellRenderer: "agGroupCellRenderer",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "orderCode",
        headerName: "Order Code",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "description",
        headerName: "Description",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "operationDate",
        headerName: "Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params?.data?.operationDate);
        },
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
    ],
    [
      {
        field: "accountName",
        headerName: "Account Name",
      },
      {
        field: "debit",
        headerName: "Debit",
        valueGetter: (params) => {
          return formatNumber(params?.data?.debit, currencySymbol);
        },
      },
      {
        field: "credit",
        headerName: "Credit",
        valueGetter: (params) => {
          return formatNumber(params?.data?.credit, currencySymbol);
        },
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const detailCellRenderer = ({ data }) => {
    return (
      <div className="py-4">
        <AgGrid
          data={data?.accounts}
          columns={gridColumns[1]}
          pagination={false}
          autoHight={true}
          forPrinting={true}
          ref={childFnRef1}
        />
      </div>
    );
  };

  useEffect(() => {
    if (!isCustomizedReport) generateReport.mutate({});
  }, [isCustomizedReport]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div className="back-btn" onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            Journals
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <Filter
              filters={[1]}
              onSubmit={onSubmit}
              gridColumns={gridColumns}
              setGridColumns={setGridColumns}
              setReportName={setReportName}
              headers={headers}
              childFnRef={childFnRef.current}
              childFnRef1={childFnRef1.current}
              setRefreshCounter={setRefreshCounter}
            />
            <>
              <div className="container report-filters-div text-end mb-3">
                <button
                  className="import-btn"
                  type="button"
                  onClick={exportToExcel}
                  disabled={disabledExportBtn || reportData?.hubs?.length === 0}
                >
                  <img src={exportIcon} className="me-2" alt="" />
                  Export grid to Excel
                </button>
                <button
                  type="button"
                  className="btn btn-success ms-5"
                  onClick={printDocument}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-printer me-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                  </svg>
                  Print report to PDF
                </button>
              </div>
              <div className="report-page" ref={reportPage}>
                <div className="report-header d-flex justify-content-between">
                  <div>
                    <h3>
                      <b>{reportName}</b>
                    </h3>{" "}
                    <br />
                    <p>
                      <b>Report Date: </b>
                      {dateFormmaterNoTime(reportData?.reportDate)}
                    </p>
                  </div>
                  <div>
                    <img
                      alt=""
                      src={reportData?.imageLogo ?? BlueSeedLogo}
                      className="report-logo"
                    ></img>
                  </div>
                </div>
                <div className="grid-wrapper">
                  {gridColumns.length > 0 && (
                    <AgGrid
                      data={reportData?.journals}
                      columns={gridColumns[0]}
                      pagination={false}
                      forPrinting={true}
                      autoHight={true}
                      masterDetail={true}
                      detailCellRenderer={detailCellRenderer}
                      exportToExcel={exportToExcelClicked}
                      setExportToExcelClicked={setExportToExcelClicked}
                      setDisabledExportBtn={setDisabledExportBtn}
                      setIsLoadingGridExport={setIsLoadingGridExport}
                      subTable={gridColumns[1]}
                      subTableObj={"accounts"}
                      fileName="Journals Report"
                      ref={childFnRef}
                    />
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default JournalsReport;
