import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import {
  checkIfAllowed,
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import InvoiceInfo from "./Invoice Tabs/InvoiceInfo";
import InvoiceItems from "./Invoice Tabs/InvoiceItems";
import InvoiceExpenses from "./InvoiceExpenses";
import { getBaseCurrency } from "components/currency";
import {
  UPDATE_SUCCESS_MSG,
  VOID_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import exportIcon from "img/export.svg";
import { FormProvider, useForm } from "react-hook-form";
import { InvoiceStateArray2 } from "./InvoiceEnums";
import {
  editInvoice,
  getInvoice,
  voidInvoice,
} from "./InvoicesServices/InvoicesServices";

const InvoiceDetails = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const { id } = useParams();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showVoid, setShowVoid] = useState<boolean>(false);
  const [showApplyPayment, setShowApplyPayment] = useState<boolean>(false);
  const [addedItems, setAddedItems] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState<number>(0);

  const isCredit = match.path === AppUrls.view_credit_memo;

  const updateInvoice = useMutation(editInvoice, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Invoice ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsEditing(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const { isLoading: isLoadingInvoice, data: invoiceData } = useQuery(
    [id],
    getInvoice,
    {
      onSuccess(data) {
        setAddedItems(
          data?.data.items.map((item) => ({
            ...item,
            invoicePrice: item.price,
            invoiceQty: item.quantity,
            itemDescription: item.description,
          }))
        );
        setTotalExpenses(
          data?.data.expenses.reduce((acc, expense) => acc + expense.amount, 0)
        );
      },
    }
  );

  useEffect(() => {
    if (location.state?.isNew)
      setTimeout(() => {
        setShowApplyPayment(true);
      }, 1000);
  }, [location]);

  useEffect(() => {
    if (invoiceData) {
      setValue("customerPo", invoiceData.data?.customerPo);
      setValue("remarks", invoiceData.data?.remarks);
    }
  }, [invoiceData]);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    setValue,
  } = methods;

  const onSubmit = (values) => {
    updateInvoice.mutate({
      ...values,
      invoiceId: id,
      invoiceType: invoiceData?.data?.invoiceState,
      hubId: invoiceData?.data?.hubId,
      items: addedItems.map((item) => ({
        ...item,
        price: Number(item.invoicePrice),
      })),
      shippingInfoId: invoiceData?.data?.shippingInfoId,
      shippingMethodId: invoiceData?.data?.shippingMethodId,
    });
  };

  const onBackClick = () => {
    if (isCredit) history.push(AppUrls.credit_memo);
    else history.push(AppUrls.invoices_list);
  };

  const onCancel = () => {
    reset();
    setIsEditing(false);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: updateInvoice.isLoading || isLoadingInvoice,
    });
  }, [updateInvoice.isLoading, appDispatch, isLoadingInvoice]);

  const voidInvoiceClicked = () => {
    setShowVoid(true);
  };

  const voidMethod = useMutation(voidInvoice, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Invoice ${VOID_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.invoices_list);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem voiding invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const payInvoice = () => {
    history.push({
      pathname: AppUrls.pay_invoice,
      state: { invoiceId: id },
    });
  };

  const voidConfirmed = () => {
    voidMethod.mutate(id);
    setShowVoid(false);
  };

  const reportClicked = () => {
    isCredit
      ? history.push(`/invoices/credit-memo/view/${id}/report`)
      : history.push(`/invoices/invoice-list/${id}/report`);
  };

  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              {invoiceData?.data?.invoiceNumber}
            </div>
            <div className="d-flex justify-content-between ">
              {isEditing ? (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary no-border"
                    onClick={onCancel}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={!isDirty}
                  >
                    Save Changes
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="import-btn"
                    type="button"
                    onClick={reportClicked}
                  >
                    <img src={exportIcon} className="me-2" alt="" />
                    View Report
                  </button>
                  {checkIfAllowed(appState.allowedUrls, "delete", match.path) &&
                    invoiceData?.data?.invoiceState != 3 && (
                      <button
                        type="button"
                        className={`btn btn-danger`}
                        onClick={voidInvoiceClicked}
                      >
                        Void
                      </button>
                    )}
                  {checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                    <button
                      type="button"
                      className="btn btn-primary ms-3"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="row g-5">
                    <div className="col-sm-4">
                      <label>Account No.</label>
                      <div>{invoiceData?.data?.customerNumber}</div>
                    </div>
                    <div className="col-sm-4">
                      <label>Invoice No.</label>
                      <div>{invoiceData?.data?.invoiceNumber}</div>
                    </div>
                    <div className="col-sm-4">
                      <label>Invoice Date</label>
                      <div>
                        {invoiceData &&
                          new Date(
                            invoiceData?.data?.invoiceDate
                          ).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label>Status</label>
                      <div>
                        {
                          InvoiceStateArray2[
                            invoiceData?.data?.invoiceState - 1
                          ]
                        }
                      </div>
                    </div>
                    {invoiceData?.data?.currency &&
                      invoiceData?.data?.rate !== 0 && (
                        <div className="col-sm-4">
                          <label>Currency</label>
                          <div>
                            {invoiceData?.data?.currency?.name}{" "}
                            {invoiceData?.data?.currency?.symbol}
                          </div>
                        </div>
                      )}
                    {invoiceData?.data?.currency &&
                      invoiceData?.data?.rate !== 0 && (
                        <div className="col-sm-4">
                          <label>Exchange Rate</label>
                          <div>{invoiceData?.data?.rate} </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between">
                        <div>Invoice amount</div>
                        <div>
                          {formatNumber(
                            invoiceData?.data?.subTotal,
                            currencySymbol
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>Tax amount</div>
                        <div>
                          {formatNumber(
                            invoiceData?.data?.taxTotal,
                            currencySymbol
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>Shipping cost</div>
                        <div>
                          {formatNumber(
                            invoiceData?.data?.shipCost,
                            currencySymbol
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>Expenses</div>
                        <div>{formatNumber(totalExpenses, currencySymbol)}</div>
                      </div>
                      {!isCredit && (
                        <div className="d-flex justify-content-between mt-2">
                          <div>Discount</div>
                          <div>
                            {formatNumber(
                              invoiceData?.data?.discount,
                              currencySymbol
                            )}
                          </div>
                        </div>
                      )}
                      <hr className="mt-3" />
                      <div className="d-flex justify-content-between mt-2">
                        <div>Invoice Total</div>
                        <div>
                          {formatNumber(
                            invoiceData?.data?.total,
                            currencySymbol
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>Paid amount</div>
                        <div>
                          {formatNumber(
                            invoiceData?.data?.paidAmount,
                            currencySymbol
                          )}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>Invoice Balance</div>
                        <div>
                          {formatNumber(
                            invoiceData?.data?.total -
                              invoiceData?.data?.paidAmount,
                            currencySymbol
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col offset-1">
                      <div className="d-flex justify-content-between">
                        <div>Order no.</div>
                        {invoiceData?.data?.salesOrderNumber ?? (
                          <span className="text-muted fst-italic">
                            Order No.
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>Days old</div>
                        <div>{invoiceData?.data?.daysOld}</div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div>EMP ID</div>
                        <div>ERP</div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3 ms-3 me-2" />
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between">
                        <div>QB Invoice</div>
                        {invoiceData?.data?.invoiceNumber ?? (
                          <span className="text-muted fst-italic">
                            QB Invoice
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col offset-1">
                      {/* <div className="d-flex justify-content-between">
                                                <div>QB Date</div>
                                                {dateFormmaterNoTime(invoiceData?.data?.paidDate) ?? <span className="text-muted fst-italic">QB Date</span>}
                                            </div> */}
                      {/* <div className="d-flex justify-content-between mt-2">
                                                <div>Add User</div>
                                                {invoiceData?.data?.salesOrderNumber ?? <span className="text-muted fst-italic">Add User</span>}
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <Tabs defaultActiveKey="InvoiceInfo">
                <Tab
                  eventKey="InvoiceInfo"
                  title={<div className="tab-inner-title">Invoice Info</div>}
                >
                  <InvoiceInfo
                    isCredit={isCredit}
                    isEditing={isEditing}
                    isCreating={false}
                    invoiceData={invoiceData?.data}
                  />
                </Tab>
                <Tab
                  eventKey="InvoiceItems"
                  title={
                    <div className="tab-inner-title">
                      Invoice Items
                      <Badge pill className="text-secondary ms-2">
                        {invoiceData?.data?.items.length}
                      </Badge>
                    </div>
                  }
                >
                  <InvoiceItems
                    isCreating={false}
                    isEditing={false}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    isInInvoice={true}
                  />
                </Tab>
                <Tab
                  eventKey="InvoiceExpenses"
                  title={
                    <div className="tab-inner-title">
                      Expenses
                      <Badge pill className="text-secondary ms-2">
                        {invoiceData?.data?.expenses.length}
                      </Badge>
                    </div>
                  }
                >
                  <InvoiceExpenses
                    expenses={invoiceData?.data?.expenses}
                    currencySymbol={currencySymbol}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <ConfirmationModal
        title="Void Invoice"
        message={`Void invoice ${invoiceData?.data?.invoiceNumber}? Once you void an Invoice, you cannot unvoid it.`}
        showModal={showVoid}
        onClose={() => setShowVoid(false)}
        onConfirm={voidConfirmed}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
      <ConfirmationModal
        title="Apply Payment"
        message="Would you like to immediately apply payment toward this invoice?"
        showModal={showApplyPayment}
        onClose={() => setShowApplyPayment(false)}
        onConfirm={payInvoice}
        onCancel={() => setShowApplyPayment(false)}
        cancelBtnTitle="No"
        confirmBtnTitle="Apply"
        type="confirmation-saving"
      />
    </>
  );
};

export default InvoiceDetails;
