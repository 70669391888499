import api from "components/data/AxiosClient";
import {
  CreateInvoiceI,
  GetInvoiceDTO,
  InvoiceDTO,
  InvoiceEntry,
  PayInvoiceDTO,
  PaymentForm,
  PaymentMethod,
  Receipt,
  SendInvoice,
  SendInvoiceDetails,
  SendInvoiceDTO,
} from "../Invoices.model";
import { CustomerPayment } from "pages/SalesMarketing/Customers/Customers.models";
import { QueryResult } from "components/Common.models";

const invoices = "invoices";

export const getInvoices = async (params) => {
  let extraParams = "";
  if (params.startDate) extraParams += `&startDate=${params.startDate}`;
  if (params.endDate) extraParams += `&endDate=${params.endDate}`;
  if (params.immediate) extraParams += `&immediate=${params.immediate}`;
  if (params.invoiceState)
    extraParams += `&invoiceState=${params.invoiceState}`;
  if (params.searchQuery) extraParams += `&searchQuery=${params.searchQuery}`;

  if (params.PageSize && params.PageStart) {
    const response = await api.get<QueryResult<InvoiceEntry>>(
      `${invoices}?PageSize=${params.PageSize}&PageStart=${isNaN(params.PageStart) ? 0 : params.PageStart}&invType=1${extraParams}`
    );
    return response.data;
  } else {
    const response = await api.get<QueryResult<InvoiceEntry>>(
      `${invoices}?invType=1${extraParams}`
    );
    return response.data;
  }
};

export const getInvoicesForCustomer = async (customerId) => {
  const response = await api.get<QueryResult<InvoiceEntry>>(
    `${invoices}?customerId=${customerId}`
  );
  return response.data;
};

export const getItemsByPos = async (POIds) => {
  const response = await api.post("invoices/receive/purchase-items", POIds);
  return response.data;
};

export const createImmediateBillAPI = async (data) => {
  const response = await api.post("invoices/receive/immediate", data);
  return response.data;
};

export const editImmediateBillAPI = async (data) => {
  const response = await api.put(`invoices/receive/immediate/${data.id}`, data);
  return response.data;
};
export const editBillAPI = async (data) => {
  const response = await api.put(`invoices/receive/${data.id}`, data);
  return response.data;
};
export const getCreditMemos = async (params) => {
  let extraParams = "";
  if (params.startDate) extraParams += `&startDate=${params.startDate}`;
  if (params.endDate) extraParams += `&endDate=${params.endDate}`;
  if (params.immediate) extraParams += `&immediate=${params.immediate}`;
  if (params.searchQuery) extraParams += `&searchQuery=${params.searchQuery}`;
  if (params.invoiceState)
    extraParams += `&invoiceState=${params.invoiceState}`;
  if (params.pageParam) {
    const response = await api.get<QueryResult<InvoiceEntry>>(
      `${invoices}?PageSize=${params.PageSize}&PageStart=${isNaN(params.PageStart) ? 0 : params.PageStart}&invType=2`
    );
    return response.data;
  } else {
    const response = await api.get<QueryResult<InvoiceEntry>>(
      `${invoices}?invType=2${extraParams}`
    );
    return response.data;
  }
};

export const getReceiveInvoices = async (params) => {
  let extraParams = "";
  if (params.immediate) extraParams += `?immediate=${params.immediate}`;
  const response = await api.get<QueryResult<SendInvoice>>(
    `${invoices}/receive${extraParams}`
  );
  return response.data;
};

export const getBillPaymentsList = async (params) => {
  let extraParams = "?";
  if (params.startDate) extraParams += `&startDate=${params.startDate}`;
  if (params.endDate) extraParams += `&endDate=${params.endDate}`;
  if (params.vendorId) extraParams += `&vendorId=${params.vendorId}`;
  const response = await api.get<QueryResult<SendInvoice>>(
    `invoices/receive-payment${extraParams}`
  );
  return response.data;
};

export const getBillPaymentById = async (id) => {
  let billId = id.queryKey[0];
  const response = await api.get(`invoices/receive-payment/${billId}`);
  return response.data;
};

export const voidBillPayment = async (id) => {
  const response = await api.delete(`invoices/receive/${id}/void`);
  return response.data;
};

export const getReceiveInvoiceById = async (id) => {
  let invoiceId = id.queryKey[0];
  const response = await api.get<SendInvoiceDetails>(
    `${invoices}/receive/${invoiceId}`
  );
  return response.data;
};

export const payReceiveInvoice = async (data: SendInvoiceDTO) => {
  const response = await api.post<SendInvoice>(
    `${invoices}/receive/${data.receiveInvoiceId}/pay`,
    data
  );
  return response.data;
};

export const getPaymentDetails = async (receiptId) => {
  const response = await api.get(`invoices/receipt/${receiptId}`);
  return response.data;
};

export const createInvoice = async (data) => {
  return await api.post<InvoiceDTO>(invoices, data);
};

export const createReceiveInvoice = async (data) => {
  return await api.post<InvoiceDTO>(`${invoices}/receive`, data);
};

export const editReceiveInvoice = async (data) => {
  return await api.put<InvoiceDTO>(
    `${invoices}/receive/${Number(data.invoideId)}`,
    data
  );
};

export const voidPaymentReceipt = async (receiptId) => {
  return await api.post(`invoices/receipt/${receiptId}/void`, {});
};

export const getInvoice = async (id) => {
  let invoiceId = id.queryKey[0];
  return await api.get<GetInvoiceDTO>(`${invoices}/${invoiceId}`);
};

export const voidInvoice = async (id) => {
  return await api.get<boolean>(`${invoices}/${id}/void`);
};

export const editInvoice = async (data: CreateInvoiceI) => {
  return await api.put<InvoiceDTO>(`${invoices}/${data.invoiceId}`, data);
};

export const getOpenInvoices = async () => {
  const response = await api.get<QueryResult<InvoiceEntry>>(
    `${invoices}?invType=1&balanceState=2`
  );
  return response.data;
};

export const getInvoicesByCustomer = async (id: number) => {
  const response = await api.get<QueryResult<InvoiceEntry>>(
    `${invoices}?customerId=${id}&balanceState=2`
  );
  return response.data;
};

export const autoInvoice = async (data) => {
  return await api.post<boolean>(
    `${invoices}/auto-invoice?invoiceDate=${data.invoiceDate}`,
    data.orders
  );
};

export const invoiceSo = async (data) => {
  return await api.post(`${invoices}/invoice-sales-order`, data);
};

export const getPaymentMethods = async () => {
  const response = await api.get<PaymentMethod[]>(
    `${invoices}/payment-methods`
  );
  return response.data;
};

export const payInvoice = async (data: PayInvoiceDTO) => {
  return await api.post<boolean>(`${invoices}/${data.invoiceId}/pay`, data);
};

export const payInvoiceDirect = async (data: PaymentForm) => {
  return await api.post<boolean>(`${invoices}/${data.invoiceId}/pay`, data);
};

export const manualApplyCredits = async (data) => {
  return await api.post<boolean>(`${invoices}/${data.creditId}/manual-apply`, {
    creditMemoApplicationModels: data.credits,
    remarks: data.remark,
  });
};

export const autoApplyCredits = async (data) => {
  return await api.post(`${invoices}/${data.id}/auto-apply`, {
    remark: data.remark,
  });
};

export const applyPayments = async (data) => {
  return await api.post<boolean>(`${invoices}/apply-payment`, data);
};

export const getReceipts = async (
  queryParams: string
): Promise<QueryResult<Receipt>> => {
  const response = await api.get<QueryResult<Receipt>>(
    `invoices/receipts${queryParams}`
  );
  return response.data;
};

export const getUnappliedPayments = async (customerId: number) => {
  const response = await api.get<QueryResult<CustomerPayment>>(
    `invoices/receipts?customerId=${customerId}&isUnapplied=true`
  );
  return response.data;
};

export const applyUnappliedPayments = async (data) => {
  return await api.post<boolean>(
    `${invoices}/receipt/${data.receiptId}/apply?paidDate=${data.paidDate}`,
    data.payments
  );
};

export const CustomerPaymentAPI = async (data) => {
  return await api.post(`${invoices}/customer-payment`, data);
};
