import { useContext, useEffect, useState } from "react";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { Hub } from "pages/Inventory/Inventory.models";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useMutation, useQuery } from "react-query";
import Lookup from "elements/Lookup";
import DatePicker from "react-datepicker";
import { FormProvider, useForm, Controller } from "react-hook-form";
import AG from "elements/AG";
import { SalesOrder } from "pages/SalesOrder/SalesOrder.model";
import DispatchContext from "context/DispatchContext";
import { searchSalesOrders } from "pages/SalesOrder/SalesOrders/SalesOrderServices/SalesOrderServices";
import { formatNumber, notificationMessage } from "global/helpers";
import { autoInvoice } from "./InvoicesServices/InvoicesServices";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { LOAD_FAIL_MSG } from "constants/NotificationMsgs";
import { AutoInvoiceForm } from "./Invoices.model";
import { GridColumns, QueryResult } from "components/Common.models";
import DateGrid from "components/DateFilter/DateGrid";

const AutoInvoice = () => {
  const appDispatch = useContext(DispatchContext);

  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState<Hub>();
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [selectedRows, setSelectedRows] = useState<SalesOrder[]>();
  const [salesOrders, setSalesOrders] = useState<SalesOrder[]>();
  const [dateCategory, setDateCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: hubs } = useQuery<QueryResult<Hub>>(
    "hubs",
    getInventoryHubsList,
    {
      onSuccess(data) {
        if (data?.data?.length === 1) setSelectedHub(data?.data[0]);
      },
    }
  );

  const getOrders = useMutation(searchSalesOrders, {
    async onSuccess(response) {
      setSalesOrders(response.data.data);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, `${LOAD_FAIL_MSG} orders`),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitOrders = useMutation(autoInvoice, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Orders invoiced successfully",
      };
      appDispatch({ type: "notification", value: notification });
      getOrders.mutate("?state=4&state=5");
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem invoicing orders"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const methods = useForm<AutoInvoiceForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      invoiceDate: new Date(),
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
  } = methods;

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No",
      editable: false,
    },
  ];

  const ordersColumns: GridColumns[] = [
    {
      headerName: "",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
      width: "45",
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "orderNumber",
      headerName: "Order No.",
    },
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "shippingMethod",
      headerName: "Ship Method",
    },
    {
      field: "orderAmount",
      headerName: "Order Amount",
      valueGetter: (params) => {
        return `$${params.data?.orderAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "shipAmount",
      headerName: "Shipped Amount",
      valueGetter: (params) => {
        return `$${params.data?.shipAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "openAmount",
      headerName: "Open Amount",
      valueGetter: (params) => {
        return `$${params.data?.openAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "contact",
      headerName: "Contact Name",
    },
  ];

  const displayFilter = () => {
    let queryParams = "?states=4&states=5";
    if (startDate)
      queryParams += `&start=${new Date(startDate).toLocaleString()}`;
    if (endDate) queryParams += `&end=${new Date(endDate).toLocaleString()}`;
    if (selectedHub) queryParams += `&hubId=${selectedHub.locationId}`;
    if (searchTerm) queryParams += `&searchQuery=${searchTerm}`;
    getOrders.mutate(queryParams);
  };

  useEffect(() => {
    getOrders.mutate("?states=4&states=5");
  }, []);

  const onHubSelected = (hub) => {
    setSelectedHub(hub);
    setOpenSelection(false);
  };

  const onSubmit = () => {
    if (!selectedRows || selectedRows.length === 0) {
      let notification = {
        variant: "danger",
        msg: `Please select orders to invoice`,
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      setShowConfirmation(true);
    }
  };

  const clearFilters = () => {
    setSelectedHub(null);
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
    getOrders.mutate("?states=4&states=5");
  };

  const onConfirmSubmit = () => {
    setShowConfirmation(false);
    submitOrders.mutate({
      invoiceDate: new Date(getValues("invoiceDate")).toLocaleDateString(),
      orders: selectedRows.map((order) => order.salesOrderId),
    });
  };

  const getOpenAmount = () => {
    let openAmount = 0;
    if (salesOrders)
      for (let order of salesOrders) openAmount += order.orderAmount;
    return openAmount;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>Auto Invoice Sales Orders</div>
            <div className="d-flex justify-content-between ">
              <button
                type="submit"
                className="btn btn-success"
                disabled={selectedRows?.length === 0}
              >
                Invoice
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="form-group">
                  <label className="me-2">Search</label>
                  <div className="page-search">
                    <div className="input-group">
                      <span className="page-search-icon">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        className="page-search-input ps-5 me-4 w-200"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2" style={{ marginLeft: "-80px" }}>
                  <div className="form-group">
                    <label>Hub Id</label>
                    <Lookup
                      onButtonClicked={() => setOpenSelection(true)}
                      inputName="hubId"
                      isRequired={false}
                      initialData={hubs?.data?.map((item) => ({
                        ...item,
                        id: item.locationId,
                        name: item.hubId,
                      }))}
                      onSelection={onHubSelected}
                      inputValue={selectedHub?.hubId}
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <label>Invoice Date</label>
                  <Controller
                    name="invoiceDate"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        selected={value ? value : null}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                      />
                    )}
                  />
                  {errors.invoiceDate && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <DateGrid
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateCategory={dateCategory}
                setDateCategory={setDateCategory}
              >
                <div className="col-lg-2 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </DateGrid>
              <div className="row mt-3">
                <div className="col-sm-2">
                  <label>Order Count</label>
                  <div>{salesOrders?.length}</div>
                </div>
                <div className="col-sm-2">
                  <label>Store Count</label>
                  <div></div>
                </div>
                <div className="col-sm-2">
                  <label>Open Amount</label>
                  <div>{formatNumber(getOpenAmount())}</div>
                </div>
              </div>
              <div className="h-350 mt-4">
                <AG
                  data={salesOrders}
                  columns={ordersColumns}
                  autoColumns={true}
                  isLoading={getOrders.isLoading}
                  rowSelection="multiple"
                  setRowChecked={setSelectedRows}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={hubs?.data}
        columns={hubsColumns}
        setRowClicked={onHubSelected}
      />
      <ConfirmationModal
        title="Auto Invoice Sales Orders"
        message={`Are you sure you want to invoice selected open sales orders in the list? Total selected orders: ${selectedRows?.length}`}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => onConfirmSubmit()}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showConfirmation}
      />
    </>
  );
};

export default AutoInvoice;
