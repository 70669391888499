import { useContext, useEffect } from "react";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { dateFormmaterNoTime, formatNumber } from "global/helpers";
import { useQuery } from "react-query";
import DispatchContext from 'context/DispatchContext';
import { useHistory, useParams } from "react-router-dom";
import { getInvoice } from "pages/Invoices/InvoicesServices/InvoicesServices";
import { InvoiceState } from "pages/Invoices/InvoiceEnums";
import AG from "elements/AG";

const CmDetails = () => {

    const history = useHistory()
    const appDispatch = useContext(DispatchContext)
    const { id } = useParams();


    const {
        isLoading: isLoadingInvoice,
        data: invoiceData,
    } = useQuery([id], getInvoice);



    const onBackClick = () => {
        history.push(AppUrls.ecommerce_cm)
    }

    useEffect(() => {
        appDispatch({ type: "loading", value: isLoadingInvoice })
    }, [appDispatch, isLoadingInvoice])


    const columns = [
        {
            field: "itemNumber",
            headerName: "Item Number",
        },
        {
            field: "itemDescription",
            headerName: "Item Description",
        },
        {
            field: "quantity",
            headerName: "QTY",
            editable: true,
        },
        {
            field: "price",
            headerName: "Item Price",
            valueGetter: (params) => { return `$${params.data?.price?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "taxable",
            headerName: "Taxable",
        },
        {
            field: "subtotal",
            headerName: "Sub Total",
            valueGetter: (params) => formatNumber(params.getValue("quantity") * params.getValue("price")), 
            filter: false,
        },
    ]

    return (
        <div className="static-page">
            <div className="page-title page-title-editable">
                <div className="back-btn " onClick={onBackClick}>
                    <img src={backArrow} alt="back arrow" />
                    {invoiceData?.data?.invoiceNumber}
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Account No.</label>
                                    <div>{invoiceData?.data?.customerNumber}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>CM No.</label>
                                    <div>{invoiceData?.data?.invoiceNumber}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>CM Date</label>
                                    <div>{invoiceData && new Date(invoiceData?.data?.invoiceDate).toLocaleDateString()}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Status</label>
                                    <div>{InvoiceState[invoiceData?.data?.invoiceState]}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Order Number</label>
                                    <div>{invoiceData?.data?.salesOrderNumber}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-between">
                                        <div>CM amount</div>
                                        <label>{formatNumber(invoiceData?.data?.total)}</label>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>Paid amount</div>
                                        <label>{formatNumber(invoiceData?.data?.paidAmount)}</label>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>CM Balance</div>
                                        <label>{formatNumber(invoiceData?.data?.total - invoiceData?.data?.paidAmount)}</label>
                                    </div>
                                </div>
                                <div className="col offset-1">
                                    <div className="d-flex justify-content-between">
                                        <div>Order no.</div>
                                        {invoiceData?.data?.salesOrderNumber ?? <span className="text-muted fst-italic">Order No.</span>}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>Days old</div>
                                        <div>{invoiceData?.data?.daysOld}</div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>EMP ID</div>
                                        <div>ERP</div>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-3 ms-3 me-2" />
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-between">
                                        <div>QB Invoice</div>
                                        {invoiceData?.data?.invoiceNumber ?? <span className="text-muted fst-italic">QB Invoice</span>}
                                    </div>
                                </div>
                                <div className="col offset-1">
                                    <div className="d-flex justify-content-between">
                                        <div>QB Date</div>
                                        {dateFormmaterNoTime(invoiceData?.data?.paidDate) ?? <span className="text-muted fst-italic">QB Date</span>}
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>Add User</div>
                                        {invoiceData?.data?.salesOrderNumber ?? <span className="text-muted fst-italic">Add User</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-450 mt-4">
                        <AG data={invoiceData?.data?.items} columns={columns} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmDetails;