import { DropDownOption, GridColumns } from "components/Common.models";
import * as AppUrls from "constants/AppUrls";
import StateContext from "context/StateContext";
import {
  checkIfAllowed,
  dateFormmaterNoTime,
  fixTimezoneOffset,
} from "global/helpers";
import addIcon from "img/add-icon.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { InvoiceStateArray } from "./InvoiceEnums";
import { getInvoices } from "./InvoicesServices/InvoicesServices";
// import Select from "react-select";
// import FilterPopup from "components/FilterPopup/FilterPopup";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
// import { ReactComponent as FilterIcon } from 'img/filter.svg';
// import { ReactComponent as CaretUp } from 'img/caretup.svg';
import DateGrid from "components/DateFilter/DateGrid";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";
const InvoiceList = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [selectedOrigin, setSelectedOrigin] = useState<number>(0);
  // const [showFilter, setShowFilter] = useState<boolean>(false);
  // const [selectedAging, setSelectedAging] = useState(null)
  // const [selectedBalance, setSelectedBalance] = useState(null)
  const [dateCategory, setDateCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    balanceState: null,
    immediate: null,
    invoiceState: 0,
    searchQuery: searchTerm,
  });
  const [selectedStatus, setSelectedStatus] = useState<DropDownOption>({
    label: "All",
    value: 0,
  });

  const columns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
      width: "130px",
    },
    {
      field: "immediate",
      headerName: "Is Immediate",
      width: "135px",
      valueGetter: (params) => {
        return params.data.immediate ? "Yes" : "No";
      },
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      width: "145px",
    },
    {
      field: "company",
      headerName: "Company",
      width: "170px",
    },
    {
      field: "status",
      headerName: "Status",
      width: "115px",
      valueGetter: (params) => {
        return InvoiceStateArray[params.data.status - 1];
      },
    },
    {
      field: "date",
      headerName: "Inv. Date",
      width: "130px",

      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.date);
      },
      cellRenderer: (params) =>
        params.value ? `${dateFormmaterNoTime(params.value)}` : "",
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      width: "135px",

      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      width: "135px",

      valueGetter: (params) => {
        return `$${params.data?.paidAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "invoiceBalance",
      headerName: "Inv. Balance",
      width: "135px",
      valueGetter: (params) => {
        return `$${
          (params.data.amount - params.data.paidAmount)?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "salesOrder",
      headerName: "Sales Order",
      width: "132px",
    },
    {
      field: "aging",
      headerName: "Aging",
      width: "100px",
    },
    {
      field: "batchNumber",
      headerName: "Batch No",
      width: "120px",
    },
  ];

  const dataMutation = useMutation("data", getInvoices);

  const displayFilter = () => {
    let newFilters = { ...filters };
    if (endDate)
      newFilters = { ...newFilters, endDate: fixTimezoneOffset(endDate) };
    else newFilters = { ...newFilters, endDate: null };
    if (startDate)
      newFilters = { ...newFilters, startDate: fixTimezoneOffset(startDate) };
    else newFilters = { ...newFilters, startDate: null };
    if (selectedOrigin)
      newFilters = { ...newFilters, immediate: selectedOrigin === 1 };
    else newFilters = { ...newFilters, immediate: null };
    if (selectedStatus?.value === 0)
      newFilters = { ...newFilters, invoiceState: null };
    else newFilters = { ...newFilters, invoiceState: selectedStatus?.value };
    if (searchTerm) newFilters = { ...newFilters, searchQuery: searchTerm };
    setFilters(newFilters);
  };

  const onRowDoubleClicked = (e) => {
    let url = `${AppUrls.invoices_list}/${e.invoiceId}`;
    history.push(url);
  };

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  // const togglePopup = () => {
  //     setShowFilter(prev => !prev)
  // }

  // const filterResults = () => {
  //     setFilters(filters => ({ ...filters, balanceState: selectedBalance ?? null }))
  //     setShowFilter(false)
  // }

  // const onAgingChange = (e) => {
  //     setSelectedAging(e)
  // }

  // const onBalanceChange = (e) => {
  //     setSelectedBalance(e)
  // }

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onRadioChange = (e) => {
    setSelectedOrigin(parseInt(e.target.value));
  };

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
    setSelectedStatus({ label: "All", value: 0 });
    setFilters({
      startDate: null,
      endDate: null,
      balanceState: null,
      immediate: selectedOrigin === 1,
      invoiceState: 0,
      searchQuery: "",
    });
  };

  const options = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Open",
      value: 1,
    },
    {
      label: "Applied",
      value: 2,
    },
    {
      label: "Voided",
      value: 3,
    },
    {
      label: "Paid",
      value: 4,
    },
    {
      label: "PartiallyPaid",
      value: 5,
    },
  ];

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Invoices</p>
          <div>
            <button
              className="import-btn"
              type="button"
              onClick={exportToExcel}
              disabled={disabledExportBtn || dataGrid?.length === 0}
            >
              <img src={exportIcon} className="me-2" alt="" />
              Export to Excel
            </button>
            <button onClick={onRefresh} className="import-btn import-btn-blue">
              <RefreshIcon className="me-2" />
              Refresh
            </button>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <Link to={AppUrls.new_invoice_normal} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> New Invoice
              </Link>
            )}
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <Link to={AppUrls.new_invoice} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> New Immediate
                Invoice
              </Link>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container has-filters">
            <div className="filters row">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <div className="form-group">
                  <label className="me-2">Search</label>
                  <div className="page-search">
                    <div className="input-group">
                      <span className="page-search-icon">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        className="page-search-input ps-5 me-4 w-200"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", marginRight: "-50%" }}>
                  <DateGrid
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateCategory={dateCategory}
                    setDateCategory={setDateCategory}
                  />
                </div>
                <div
                  className="form-group col-sm-2 d-flex align-items-center"
                  style={{ marginTop: "26px" }}
                >
                  <label className="checkbox-inline ms-3 mb-0">
                    <input
                      id="systemOption1"
                      type="radio"
                      value={0}
                      onChange={onRadioChange}
                      checked={selectedOrigin === 0}
                    />
                    <label
                      className={"text-black ms-2"}
                      htmlFor="systemOption1"
                    >
                      All
                    </label>
                  </label>
                  <label className="checkbox-inline ms-3 mb-0">
                    <input
                      id="systemOption2"
                      type="radio"
                      value={1}
                      onChange={onRadioChange}
                      checked={selectedOrigin === 1}
                    />
                    <label
                      className={"text-black ms-2"}
                      htmlFor="systemOption2"
                    >
                      Immediate
                    </label>
                  </label>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-2">
                  <label>Status</label>
                  <Select
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    options={options}
                  />
                </div>
                <div className="col-sm-2 d-flex align-items-end">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="grid">
              <AgGrid
                columns={columns}
                fetchMethod={dataMutation}
                // data={dataGrid}
                // setDataGrid={setDataGrid}
                filters={filters}
                setrowClicked={onRowDoubleClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                refreshCounter={refreshCounter}
                fileName="Invoice List Report"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <FilterPopup show={showFilter} setShow={setShowFilter}>
                <div className="d-flex align-items-center">
                    <label className="me-2">Aging</label>
                    <Select
                        options={[]}
                        isSearchable={true}
                        value={selectedAging}
                        onChange={onAgingChange}
                        className="me-4 w-200"
                    />
                    <label className="me-2">Balance</label>
                    <Select
                        options={[]}
                        isSearchable={true}
                        value={selectedBalance}
                        onChange={onBalanceChange}
                        className="w-200"
                    />
                    <button className="btn btn-outline-primary" onClick={filterResults}>Filter</button>
                </div>
            </FilterPopup> */}
    </>
  );
};

export default InvoiceList;
