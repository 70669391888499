export const dashboard = "/dashboard";

/**
 * System Setup
 */

export const purging = "/system-setup/purging";
export const company_setup = "/system-setup/company";
export const shipping_methods = "/system-setup/shipping-methods";
export const new_shipping_methods = "/system-setup/shipping-methods/new";
export const edit_shipping_methods = "/system-setup/shipping-methods/:id";
export const region_setup = "/system-setup/regions";
export const new_region_setup = "/system-setup/regions/new";
export const edit_region_setup = "/system-setup/regions/:id";
export const market_setup = "/system-setup/markets";
export const new_market_setup = "/system-setup/markets/new";
export const edit_market_setup = "/system-setup/markets/:id";
export const tax_setup = "/system-setup/tax-rates";
export const new_tax_setup = "/system-setup/tax-rates/new";
export const qpay_credentials = "/system-setup/qpay-credentials";
export const create_qpay_credentials = "/system-setup/qpay-credentials/new";
export const get_qpay_credentials = "/system-setup/qpay-credentials/:id";
export const email_settings = "/system-setup/email-settings";
export const account_types = "/system-setup/account-type";
export const new_account_types = "/system-setup/account-type/new";
export const edit_account_types = "/system-setup/account-type/:id";
export const vendor_types = "/system-setup/vendors-types";
export const new_vendor_types = "/system-setup/vendors-types/new";
export const edit_vendor_types = "/system-setup/vendors-types/:id";
export const exchange_rate = "/system-setup/currency/exchange-rate";
export const new_exchange_rate = "/system-setup/currency/exchange-rate/new";
export const edit_exchange_rate = "/system-setup/currency/exchange-rate/:id";

/**
 * User Management
 */
export const employees = "/system-setup/user-management/employees";
export const new_employee = "/system-setup/user-management/employees/new";
export const get_employee_details =
  "/system-setup/user-management/employees/:id";
export const users_roles = "/system-setup/user-management/users-roles";
export const users_type = "/system-setup/user-management/users-types";
export const new_users_type = "/system-setup/user-management/users-types/new";
export const edit_users_type = "/system-setup/user-management/users-types/:id";
export const new_user_role = "/system-setup/user-management/users-roles/new";
export const get_user_role_details =
  "/system-setup/user-management/users-roles/:id";

/**
 * Sales & Marketing
 */

export const customers = "/sales-marketing/customers";
export const customers_report = "/reports/account-statement/report";
export const create_customers = "/sales-marketing/customers/new";
export const import_customers = "/sales-marketing/customers/import";
export const get_customer = "/sales-marketing/customers/:id";
export const customer_acc_statement_report =
  "/reports/account-statement/:id/report";

export const portal_users = "/sales-marketing/portal-users";
export const create_new_portal_user = "/sales-marketing/portal-users/new";
export const get_portal_details = "/sales-marketing/portal-users/:id";
export const edit_portal_user = "/sales-marketing/portal-users/:id/edit";

export const representatives = "/sales-marketing/representatives";
export const new_representatives = "/sales-marketing/representatives/new";
export const get_representatives_details =
  "/sales-marketing/representatives/:id";
export const customer_rma = "/sales-marketing/customer-rma/customer-rma";
export const create_customer_rma =
  "/sales-marketing/customer-rma/customer-rma/new";
export const view_customer_rma =
  "/sales-marketing/customer-rma/customer-rma/:id";
export const receive_customer_rma = "/sales-marketing/customer-rma/receive-rma";
export const approve_customer_rma = "/sales-marketing/customer-rma/approve-rma";
export const refund_customer_rma = "/sales-marketing/customer-rma/refund-rma";

/**
 * Vendors
 */

export const vendors = "/vendors/setup";
export const new_vendor = "/vendors/setup/new";
export const vendor_details = "/vendors/setup/:id";
export const receive_invoices_list = "/vendors/receive-invoice-list";
export const receive_invoices_list_report =
  "/vendors/receive-invoice-list/report";
export const create_receive_invoice = "/vendors/receive-invoice-list/new";
export const create_receive_invoice_immediate =
  "/vendors/receive-invoice-list/immediate/new";
export const edit_receive_invoice = "/vendors/receive-invoice-list/view/:id";
export const receive_invoice_detail_report =
  "/vendors/receive-invoice-list/report/:id";
export const receive_invoice_payment_list = "/vendors/receive-invoice-payment";
export const receive_invoice_payment = "/vendors/receive-invoice-payment/new";
export const receive_invoice_payment_detail =
  "/vendors/receive-invoice-payment/:id";
export const receipt_invoice_payment = "/vendors/receipt-invoice-payment";
export const receipt_invoice_details = "/vendors/receipt-invoice-payment/:id";
export const vendor_rma = "/vendors/vendor-rma/vendor-rma";
export const create_vendor_rma = "/vendors/vendor-rma/vendor-rma/new";
export const view_vendor_rma = "/vendors/vendor-rma/vendor-rma/:id";
export const ship_vendor_rma = "/vendors/vendor-rma/ship-rma";
export const approve_vendor_rma = "/vendors/vendor-rma/approve-rma";
export const edit_bill = "/vendors/receive-invoice-list/immediate/:id";
export const refund_vendor_rma = "/vendors/vendor-rma/refund-rma";
export const vendor_credit_memo = "/vendors/credit-memo";
export const view_vendor_credit_memo = "/vendors/credit-memo/:id";

/**
 * Inventories
 */

export const inventories_list = "/system-setup/inventory-setup/hubs";
export const create_new_inventory = "/system-setup/inventory-setup/hubs/new";
export const get_inventory_details = "/system-setup/inventory-setup/hubs/:id";
export const product_line = "/system-setup/inventory-setup/product-line";

export const new_product_line =
  "/system-setup/inventory-setup/product-line/new";
export const edit_product_line =
  "/system-setup/inventory-setup/product-line/:id";
export const item_class = "/system-setup/inventory-setup/item-class";
export const new_item_class = "/system-setup/inventory-setup/item-class/new";
export const edit_item_class = "/system-setup/inventory-setup/item-class/:id";
export const item_sub_class = "/system-setup/inventory-setup/item-sub-class";
export const new_item_sub_class =
  "/system-setup/inventory-setup/item-sub-class/new";
export const edit_item_sub_class =
  "/system-setup/inventory-setup/item-sub-class/:id";
export const item_manufacturer =
  "/system-setup/inventory-setup/item-manufacturer";
export const new_item_manufacturer =
  "/system-setup/inventory-setup/item-manufacturer/new";
export const edit_item_manufacturer =
  "/system-setup/inventory-setup/item-manufacturer/:id";
export const item_shelves = "/system-setup/inventory-setup/item-shelves";
export const new_item_shelves =
  "/system-setup/inventory-setup/item-shelves/new";
export const edit_item_shelves =
  "/system-setup/inventory-setup/item-shelves/:id";
export const item_condition = "/system-setup/inventory-setup/item-condition";
export const new_item_condition =
  "/system-setup/inventory-setup/item-condition/new";
export const edit_item_condition =
  "/system-setup/inventory-setup/item-condition/:id";
export const item_models = "/system-setup/inventory-setup/item-models";
export const new_item_models = "/system-setup/inventory-setup/item-models/new";
export const edit_item_models = "/system-setup/inventory-setup/item-models/:id";
export const item_category = "/inventory/inventories/item-category";
export const new_item_category = "/inventory/inventories/item-category/new";
export const item_color = "/system-setup/inventory-setup/item-color";
export const new_item_color = "/system-setup/inventory-setup/item-color/new";
export const edit_item_color = "/system-setup/inventory-setup/item-color/:id";
export const item_bins = "/system-setup/inventory-setup/item-bins";
export const new_item_bins = "/system-setup/inventory-setup/item-bins/new";
export const edit_item_bins = "/system-setup/inventory-setup/item-bins/:id";

export const non_tangible_item =
  "/inventory/inventories/items/non-tangible-item";
export const non_tangible_item_info =
  "/inventory/inventories/items/non-tangible-item/:id";
export const inventory_list = "/inventory/inventories/items";
export const inventory_list_report = "/inventory/items/report";
export const serial_numbers = "/inventory/inventories/serials";
export const serial_numbers_report = "/inventory/inventories/serials/report";
export const serial_numbers_details = "/inventory/inventories/serials/:id";
export const manual_inventory_receipt = "/inventory/inventories/manual-receipt";
export const manual_inventory_issue = "/inventory/inventories/inventory-issue";
export const inter_location_transfer =
  "/inventory/inventories/inter-location-transfer";
export const quick_location_transfer =
  "/inventory/inventories/quick-location-tansfer";
export const inventory_transfers = "/inventory/inventories/transfer";
export const item_serial_transfer = "/inventory/inventories/serial-transfer";
export const new_inventory_serialized =
  "/inventory/inventories/items/serialized";
export const new_inventory_nonserialized =
  "/inventory/inventories/items/non-serialized";
export const inventory_serialized_details =
  "/inventory/inventories/items/serialized/:id";
export const inventory_nonserialized_details =
  "/inventory/inventories/items/non-serialized/:id";
export const inventory_item_detail_report =
  "/inventory/inventories/items/report/:id";
export const inventory_serialized_hubs =
  "/inventory/inventories/items/serialized/:id/item-hubs";
export const inventory_nonserialized_hubs =
  "/inventory/inventories/items/non-serialized/:id/item-hubs";
export const item_history = "/inventory/history/item-history";

/**
 * Login
 */

export const login = "/login";
export const access_denied = "/access-denied";

/**
 * Sales Order
 */

export const sales_order_list = "/sales-order/sales-orders";
export const sale_order_transaction_details_report =
  "/sales-order/sales-orders/report";
export const credits_memo_list = "/sales-order/credits-memo";
export const new_sales_order = "/sales-order/sales-orders/new";
export const fulfill_sales_order = "/sales-order/fullfill";
export const view_sales_order = "/sales-order/sales-orders/:id";
export const view_sales_order_report = "/sales-order/sales-orders/:id/report";
export const view_sales_order_report_packing_slip =
  "/sales-order/sales-orders/:id/packing-slip/report";
export const change_sales_order = "/sales-order/change-sales-order";
export const apply_tracking_info = "/sales-order/apply-tracking-info";
export const so_tracking_numbers_list = "/sales-order/tracking-numbers-list";
export const sales_order_pickup = "/sales-order/pickup";

/**
 * Invoice
 */

export const invoices_list = "/invoices/invoice-list";
export const new_invoice = "/invoices/invoice-list/new";
export const new_invoice_normal = "/invoices/invoice-list/normal/new";
export const view_invoice = "/invoices/invoice-list/:id";
export const view_invoice_report = "/invoices/invoice-list/:id/report";
export const credit_memo = "/invoices/credit-memo";
export const create_credit_memo = "/invoices/credit-memo/new";
export const view_credit_memo = "/invoices/credit-memo/view/:id";
export const view_credit_memo_report = "/invoices/credit-memo/view/:id/report";
export const pay_invoice = "/invoices/payments/pay-invoice";
export const apply_credit_memo = "/invoices/payments/apply-credit-memo";
export const auto_invoice = "/invoices/auto-invoice";
export const payment_receipt = "/invoices/payments/payment-receipt";
export const new_payment_receipt = "/invoices/payments/payment-receipt/new";
export const payment_receipt_report =
  "/invoices/payments/payment-receipt/:id/report";
export const view_payment_receipt = "/invoices/payments/payment-receipt/:id";
export const refund_credit_memo = "/invoices/refund-credit-memo";
export const eod_cashout = "/invoices/eod-cashout";

/**
 * Purchasing
 */

export const purchase_order_list = "/purchasing/purchase-orders";
export const purchase_order_listing_report =
  "/purchasing/purchase-orders/report";
export const view_purchase_order_details =
  "/purchasing/purchase-orders/view/:id";
export const view_purchase_order = "/purchasing/purchase-orders/:id";
export const view_purchase_order_report =
  "/purchasing/purchase-orders/:id/report";
export const new_purchase_order = "/purchasing/purchase-orders/new";
export const receive_purchase_order = "/purchasing/receive-purchase-order";
export const approve_po_receipt = "/purchasing/approve-po-receipt";
export const purchase_order_return_list = "/purchasing/po-return-list";
export const view_purchase_order_details_return =
  "/purchasing/po-return-list/view/:id";
export const new_purchase_order_return = "/purchasing/po-return-list/new";
export const approve_po_return = "/purchasing/approve-po-return";
export const fulfill_po_return = "/purchasing/fulfill-po-return";

/**
 * User
 */

export const user_profile = "/profile";

/**
 * Audit
 */

export const audit_trail = "/reports/audit-trail";

/**
 * Reports
 */

export const transactional_movement = "/reports/transational-movement";
export const sales_order_summary_report = "/reports/sales-order-summary";
export const serial_transfer_report = "/reports/serial-transfer";
export const open_order_summary_report = "/reports/open-order-summary";
export const open_po_summary_report = "/reports/open-po-summary";
export const account_sales_details_report =
  "/reports/account-sales-details-report";
export const inventory_on_hand_report = "/reports/inventory-on-hand-report";
export const inventory_reorder_report = "/reports/inventory-reorder-report";
export const sales_profit_report = "/reports/sales-profit";
export const inventory_aging_report = "/reports/inventory-aging";
export const inventory_issue_report = "/reports/inventory-issue";
export const inventory_valuation_report = "/reports/inventory-valuation";
export const inventory_valuation_summary_report =
  "/reports/inventory-valuation-summary";
export const invoice_application_detail = "/reports/invoice-application-detail";
export const invoice_application_summary =
  "/reports/invoice-application-summary";
export const cash_receipt_register_report = "/reports/cash-receipt-register";
export const ar_aging_as_of_date = "/reports/ar-aging-as-of-date";
export const past_due_invoices = "/reports/past-due-invoices";
export const serial_detail_as_of_date = "/reports/serial-detail-as-of-date";
export const balance_sheet_report = "/reports/balance-sheet";
export const profit_and_loss = "/reports/profit-and-loss";
export const comparative_profit_and_loss =
  "/reports/comparative-profit-and-loss";
export const journals_report = "/reports/journals";
export const comparative_balance_sheet = "/reports/comparative-balance";
export const trial_balance_report = "/reports/trial-balance";
export const sales_report = "/reports/sales-report";
export const purchase_order_report = "/reports/purchase-order";
export const expense_report = "/reports/expense-report";
export const purchasing_report = "/reports/purchasing-report";
export const bill_payment_report = "/reports/bill-payment-report";
export const chart_of_accounts_report = "/reports/chart-of-accounts-report";
export const supplier_balance_report = "/reports/supplier-balance-report";

/**
 * Ecommerce
 */

export const ecommerce_rma = "/portal/rma";
export const ecommerce_rma_id = "/portal/rma/:id";
export const ecommerce_cm = "/portal/cm-management";
export const ecommerce_cm_id = "/portal/cm-management/:id";
export const ecommerce_so = "/portal/order-management";
export const ecommerce_so_id = "/portal/order-management/:id";
export const ecommerce_updates = "/portal/updates";
export const ecommerce_reasons = "/portal/reasons";

export const Content_Management_System = "/portal/content-management-system";
export const Content_Management_System_ss_new =
  "/portal/content-management-system/slideshow/new";
export const Content_Management_System_so_new =
  "/portal/content-management-system/standOut/new";

/**
 * Accounting
 */

export const ledgers = "/accounting/ledgers";
export const operations = "/accounting/operations";
export const expenses = "/accounting/expenses";
export const add_expenses = "/accounting/expenses/new";
export const view_expenses = "/accounting/expenses/:id";
export const add_cheque = "/accounting/cheques/new";
export const view_cheque = "/accounting/cheques/:id";
export const cheque = "/accounting/cheques";
export const op_config = "/accounting/operations-configuration";
export const journals_list = "/accounting/journals";
export const bank_reconciliation = "/accounting/bank-reconciliation";
export const add_bank_reconciliation = "/accounting/bank-reconciliation/new";
export const view_bank_reconciliation = "/accounting/bank-reconciliation/:id";
export const banks = "/accounting/banks";
export const add_bank = "/accounting/banks/new";
export const bank_details = "/accounting/banks/:id";
export const bank_types = "/accounting/bank-types";
export const recurrent_transactions = "/accounting/recurrent-transactions";
export const add_transactions = "/accounting/recurrent-transactions/new";
export const fixed_assets = "/accounting/fixed-assets";
export const view_fixed_assets = "/accounting/fixed-assets/view/{id}";
export const add_fixed_assets = "/accounting/fixed-assets/new";
export const item_expenses = "/accounting/item-expenses";
export const add_item_expense = "/accounting/item-expenses/new";
export const view_item_expense = "/accounting/item-expenses/:id";
/**
 * `Payroll`
 */

export const check_in_out = "/payroll/check-in-out";
export const timesheet = "/payroll/timesheet";
export const tax_settings = "/payroll/tax-settings";
export const add_tax_settings = "/payroll/tax-settings/new";
export const payment = "/payroll/payment";
export const payroll_list = "/payroll/list";

export const entriesUrls = {
  Dashboard: dashboard,
  SystemSetup: "/system-setup",
  Purging: purging,
  CompanySetup: company_setup,
  ShippingMethod: shipping_methods,
  RegionSetup: region_setup,
  MarketSetup: market_setup,
  QPayCredentials: qpay_credentials,
  SystemEmailSettings: email_settings,
  UserManagement: "/user-management",
  EmployeeSetup: employees,
  RoleSetup: users_roles,
  TaxRates: tax_setup,
  UserTypeSetup: users_type,
  SalesMarketing: "/sales-marketing",
  Customers: customers,
  AccountType: account_types,
  PortalUsers: portal_users,
  Representative: representatives,
  Vendors: "/vendors",
  VendorsSetup: vendors,
  VendorTypes: vendor_types,
  ReceiveInvoicePayments: receipt_invoice_payment,
  SalesOrderMain: "/sales-order",
  SalesOrders: sales_order_list,
  ChangeVoidSalesOrders: change_sales_order,
  FulfillSalesOrder: fulfill_sales_order,
  ApplyTracking: apply_tracking_info,
  TrackingNumbersList: so_tracking_numbers_list,
  Purchasing: "/purchasing",
  PickUpSalesOrder: sales_order_pickup,
  ApprovePO: approve_po_receipt,
  PurchaseOrderReturn: purchase_order_return_list,
  PurchaseOrders: purchase_order_list,
  ReceivePO: receive_purchase_order,
  FulfillPOReturns: fulfill_po_return,
  ApprovePOReturns: approve_po_return,
  Inventories: "/inventory-setup",
  InventorySetup: "/inventory-setup",
  InventoryHubs: inventories_list,
  ProductLine: product_line,
  ItemClass: item_class,
  ItemSubClass: item_sub_class,
  ItemCategory: item_category,
  ItemManufacturer: item_manufacturer,
  ItemCondition: item_condition,
  ItemColor: item_color,
  ItemShelves: item_shelves,
  ItemBins: item_bins,
  ItemModel: item_models,
  InventoryItems: "/inventories",
  InventoryList: inventory_list,
  SerialNumbers: serial_numbers,
  ManualInventoryReceipt: manual_inventory_receipt,
  ManualInventoryIssue: manual_inventory_issue,
  InterLocationTransfer: inter_location_transfer,
  QuickLocationTransfer: quick_location_transfer,
  ItemSerialTransfer: item_serial_transfer,
  InventoryTransfers: inventory_transfers,
  Invoices: "/invoices",
  InvoiceList: invoices_list,
  PayInvoice: pay_invoice,
  Bills: receive_invoices_list,
  PayBill: receive_invoice_payment_list,
  BillPayments: receive_invoice_payment_list,
  CreditMemo: credit_memo,
  ApplyPayments: apply_credit_memo,
  RefundCreditMemo: refund_credit_memo,
  AutoInvoiceSO: auto_invoice,
  ItemHistoryList: item_history,
  PaymentReceipt: payment_receipt,
  EODCashOut: eod_cashout,
  ItemHistory: "/history",
  AuditTrail: audit_trail,
  Reports: "/reports",
  MovementTransaction: transactional_movement,
  SalesTransactionSummaryRe: sales_order_summary_report,
  SerialTransferTransaction: serial_transfer_report,
  OpenOrderTransactionSumma: open_order_summary_report,
  OpenPOSummary: open_po_summary_report,
  AccountSalesDetailsReport: account_sales_details_report,
  InventoryOnHandReport: inventory_on_hand_report,
  InventoryReorderReport: inventory_reorder_report,
  SalesProfitReport: sales_profit_report,
  InventoryAgingReport: inventory_aging_report,
  InvoiceApplicationDetails: invoice_application_detail,
  InvoiceApplicationSummary: invoice_application_summary,
  InventoryIssueReport: inventory_issue_report,
  CashReceiptRegisterReport: cash_receipt_register_report,
  ArAgingAsOfDateReport: ar_aging_as_of_date,
  PastDueInvoiceReport: past_due_invoices,
  SerialNoDetailAsOfDateRep: serial_detail_as_of_date,
  Portal: "/portal",
  OrderManagement: ecommerce_so,
  RMA: ecommerce_rma,
  CMManagement: ecommerce_cm,
  NewUpdates: ecommerce_updates,
  RMAReturnReasons: ecommerce_reasons,
  Accounting: "/accounting",
  Ledgers: ledgers,
  Operations: operations,
  Expenses: expenses,
  Cheque: cheque,
  Journals: journals_list,
  BankReconciliation: bank_reconciliation,
  Banks: banks,
  BankTypes: bank_types,
  OperationsConfiguration: op_config,
  BalanceSheet: balance_sheet_report,
  ProfitAndLoss: profit_and_loss,
  JournalsReport: journals_report,
  ComparativeBalanceSheet: comparative_balance_sheet,
  TrialBalanceReport: trial_balance_report,
  InventoryValuationReport: inventory_valuation_report,
  InventoryValuationSummaryReport: inventory_valuation_summary_report,
  SalesReport: sales_report,
  ComparativeProfitLossReport: comparative_profit_and_loss,
  PurchaseOrderReport: purchase_order_report,
  ExpenseReport: expense_report,
  PurchasingReport: purchasing_report,
  BillPaymentsReport: bill_payment_report,
  ChartOfAccountsReport: chart_of_accounts_report,
  SupplierBalanceReport: supplier_balance_report,
  RecurrentTranasctions: recurrent_transactions,
  CreateCustomerRMA: customer_rma,
  ReceiveCustomerRMA: receive_customer_rma,
  CustomerRMA: "/customer-rma",
  VendorRMA: "/vendor-rma",
  CreateVendorRMA: vendor_rma,
  ShipVendorRMA: ship_vendor_rma,
  FixedAssets: fixed_assets,
  ApproveVendorRMA: approve_vendor_rma,
  RefundCreditVendorRMA: refund_vendor_rma,
  CheckInAndOut: check_in_out,
  TimeSheet: timesheet,
  Payroll: "/payroll",
  TaxSettings: tax_settings,
  Payment: payment,
  ApproveCustomerRMA: approve_customer_rma,
  RefundCreditCustomerRMA: refund_customer_rma,
  PayrollList: payroll_list,
  Payments: "/payments",
  VendorCreditMemo: vendor_credit_memo,
  Currency: "/currency",
  ExchangeRate: exchange_rate,
  ContentManagementSystem: Content_Management_System,
  inventory: "/inventory",
  ItemExpenses: item_expenses,
};
