import { QueryClient, QueryClientProvider, QueryCache } from "react-query";

const queryCache = new QueryCache({
  onError: (error) => {},
});

const queryClient = new QueryClient({
  queryCache,
});

export const QueryClientContext = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
