import { useMutation } from "react-query";
import { getAudit } from "./AuditTrailServices";

import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";

import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { dateFormmaterNoTime, fixTimezoneOffset } from "global/helpers";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Filter from "reports/components/Filter";

export default function AuditTrail() {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    userId: null,
    actionType: null,
  });

  const dataMutation = useMutation("data", getAudit);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const columns = [
    [
      {
        field: "actionName",
        headerName: "Action",
        resizable: true,
      },
      {
        field: "description",
        headerName: "Description",
        resizable: true,
        width: 800,
      },
      {
        field: "createdBy",
        headerName: "Created By",
        resizable: true,
        width: 300,
      },
      {
        field: "createdDate",
        headerName: "Created Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params.data?.createdDate);
        },
        resizable: true,
      },
    ],
  ];

  const onBackClick = () => {
    history.push("/reports");
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSubmit = () => {
    const { startDate, endDate, userId, actionType } = getValues();
    let newFilters = {
      startDate: startDate ? fixTimezoneOffset(startDate) : null,
      endDate: endDate ? fixTimezoneOffset(endDate) : null,
      userId: userId?.value ?? null,
      actionType: actionType?.value ?? null,
    };
    setFilters(newFilters);
  };

  const [gridColumns, setGridColumns] = useState(
    columns.map((column) => {
      return column.map((c) => ({ ...c }));
    })
  );

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <div className="back-btn" onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          Audit Trail
        </div>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormProvider {...methods}>
                <Filter
                  filters={[1, 40, 41]}
                  onSubmit={onSubmit}
                  gridColumns={gridColumns}
                  setGridColumns={setGridColumns}
                />
              </FormProvider>
            </form>
          </div>
          <div></div>

          <AgGrid
            columns={gridColumns[0]}
            fetchMethod={dataMutation}
            data={dataGrid}
            setDataGrid={setDataGrid}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Audit Trail List Report"
            filters={filters}
          />
        </div>
      </div>
    </div>
  );
}
