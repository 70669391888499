import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useParams } from "react-router-dom";

import { InventoryPurchaseValueAPI } from "./services/ReportServices";

import { useQuery } from "react-query";

const InventoryValueReport = () => {
  const history = useHistory();

  const { id } = useParams();
  const pageTitle = "Inventory List Report";
  const backUrl = AppUrls.inventory_list;

  const { data, isLoading, isSuccess } = useQuery(
    "InventoryDataa",
    InventoryPurchaseValueAPI,
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const tableHeaders = [
    "Item No",
    "Description",
    "Prod Line",
    "Class",
    "Sub-Class",
    "Cost",
    "On Hand",
    "Price 1",
    "Price 2",
    "Price 3",
  ];

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="static-page">
        <div className="page-content-wrapper p-0">
          <div className="grid-container"></div>
        </div>
      </div>
    </form>
  );
};

export default InventoryValueReport;
