import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { dateFormmaterNoTime, getCurrencyByCode } from "global/helpers";
import exportIcon from "img/export.svg";
import { getUserAccounts } from "pages/UserManagement/UserManagement.services";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import * as colDefs from "../../constants/colDefs";
import { getPayRollList } from "./PayrollServices/payrollServices";

const PayrollList = () => {
  const appDispatch = useContext(DispatchContext);

  const [dataGrid, setGridData] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const { data: userAccounts } = useQuery("user_accounts", getUserAccounts);

  const userAccountsOpt = userAccounts?.data?.map((e) => {
    return { value: e.userId, label: e.employeeId };
  });
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "employeeId",
      headerName: "Employee Id",
    },
    {
      field: "employeeName",
      headerName: "Employee Name",
      maxWidth: colDefs.employeeName,
    },
    {
      field: "payPeriodStart",
      headerName: "Period Start",
      maxWidth: colDefs.detailedDateWidth,
      valueGetter: (params) => {
        return dateFormmaterNoTime(params?.data?.payPeriodStart);
      },
    },
    {
      field: "payPeriodEnd",
      headerName: "Period End",
      maxWidth: colDefs.detailedDateWidth,

      valueGetter: (params) => {
        return dateFormmaterNoTime(params?.data?.payPeriodEnd);
      },
    },
    {
      field: "totalHours",
      headerName: "Total Hours",
      maxWidth: colDefs.totalHrs,
      valueGetter: (params) => {
        return (params?.data?.totalHours).toFixed(2);
      },
    },
    {
      field: "billingRate",
      headerName: "Billing Rate",
      maxWidth: colDefs.billingRate,
      valueGetter: (params) => {
        return currencySymbol + (params?.data?.billingRate).toFixed(2);
      },
    },
    {
      field: "totalTax",
      headerName: "Total Tax",
      maxWidth: colDefs.totalTax,

      valueGetter: (params) => {
        return currencySymbol + (params?.data?.totalTax).toFixed(2);
      },
    },
    {
      field: "totalPaid",
      headerName: "Total Paid",
      maxWidth: colDefs.totalTax,

      valueGetter: (params) => {
        return currencySymbol + (params?.data?.totalPaid).toFixed(2);
      },
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params?.data?.issueDate);
      },
    },
  ];

  const fetchAPI = useMutation(getPayRollList, {
    onSuccess(data) {
      setGridData(data?.data);
    },
  });

  useEffect(() => {
    fetchAPI.mutate({
      userId: selectedAcc?.value,
      startDate: startDate,
      endDate: endDate,
    });
  }, [selectedAcc, startDate, endDate]);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Payroll List</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="d-flex justify-content-between align-items-end">
            <div className="d-flex align-items-end">
              <div className="form-group">
                <label className="me-2">Account</label>
                <Select
                  options={userAccountsOpt}
                  isSearchable={true}
                  value={selectedAcc}
                  onChange={(e) => setSelectedAcc(e)}
                  className="w-200"
                  isClearable={true}
                />
              </div>
              <div className="form-group ms-3 w-200">
                <label className="me-2">
                  Start Date <span className="text-danger">*</span>
                </label>
                <DatePicker
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                  selected={startDate}
                  maxDate={endDate}
                />
              </div>
              <div className="form-group ms-3 w-200">
                <label className="me-2">
                  End Date <span className="text-danger">*</span>
                </label>
                <DatePicker
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                  selected={endDate}
                  minDate={startDate}
                />
              </div>
            </div>
          </div>
          <AgGrid
            data={dataGrid}
            columns={columns}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Payroll"
          />
        </div>
      </div>
    </div>
  );
};

export default PayrollList;
