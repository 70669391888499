import AG from "elements/AG";
import { GridColumns } from "components/Common.models";
import { CustomerPayment } from '../Customers.models';
import { dateFormmaterNoTime, formatNumber } from "global/helpers";

type Props = {
    customerReceipts: CustomerPayment[],
};

enum CheckPaymentStatusEnum {
    InTransit = 1,
    Confirmed = 2,
    Declined = 3
}

const Payments = (props: Props) => {

    const columns: GridColumns[] = [
        {
            field: "receiptNumber",
            headerName: "Receipt No."
        },
        {
            field: "checkNumber",
            headerName: "Check No."
        },
        {
            field: "refNumber",
            headerName: "Ref No."
        },
        {
            field: "remark",
            headerName: "Remark"
        },
        {
            field: "paymentMethod",
            headerName: "Pay. type"
        },
        {
            field: "status",
            headerName: "Status",
            cellRenderer: (params) => CheckPaymentStatusEnum[params.value],
        },
        {
            field: "date",
            headerName: "Paid Date",
            cellRenderer: (params) => dateFormmaterNoTime(params.data.date),
        },
        {
            field: "payment",
            headerName: "Payment Amount",
            valueGetter: (params) => { return `$${params.data?.payment?.toFixed(2) ?? '0.00'}` }
        },
        // {
        //     field: "",
        //     headerName: "Applied Amount",
        //     valueGetter: (params) => {
        //         return (
        //             `${formatNumber(params.getValue("payment") - params.getValue("unapplied"))}`
        //         )
        //     },
        // },
        {
            field: "unapplied",
            headerName: "Unapplied Amount",
            valueGetter: (params) => { return `$${params.data?.unapplied?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "accountNumber",
            headerName: "Employee id",
        },
    ];

    const getPaymentAmountTotal = () => {
        let total = 0;
        for (let i = 0; i < props?.customerReceipts?.length; i++) {
            if (!isNaN(props?.customerReceipts[i].payment)) {
                total += props?.customerReceipts[i].payment;
            }
        }
        return total;
    }

    const getUnappliedAmountTotal = () => {
        let total = 0;
        for (let i = 0; i < props?.customerReceipts?.length; i++) {
            if (!isNaN(props?.customerReceipts[i].unapplied)) {
                total += props?.customerReceipts[i].unapplied;
            }
        }
        return total;
    }

    const getAppliedAmountTotal = () => {
        return getPaymentAmountTotal() - getUnappliedAmountTotal()
    };

    return (
        <div className='col-sm-12'>
            <div className='section'>
                <div className="row">
                    <div className="col my-4">
                        <div className='border p-3 bg-white'>
                            <div>
                                No of Payments
                            </div>
                            <div>
                                <span className='text-success'>{props?.customerReceipts?.length}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col my-4">
                        <div className='border p-3 bg-white'>
                            <div>
                                Payment Amount Total
                            </div>
                            <div>
                                <span className='text-success'>
                                    {formatNumber(getPaymentAmountTotal())}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col my-4">
                        <div className='border p-3 bg-white'>
                            <div>
                                Applied Amount Total
                            </div>
                            <div>
                                <span className='text-success'>
                                    {formatNumber(getAppliedAmountTotal())}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col my-4">
                        <div className='border p-3 bg-white'>
                            <div>
                                Unapplied Amount Total
                            </div>
                            <div>
                                <span className='text-success'>
                                    {formatNumber(getUnappliedAmountTotal())}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col my-4">

                    </div>
                </div>
            </div>
            <div className="h-450 mt-4 px-0">
                <AG
                    data={props?.customerReceipts}
                    columns={columns}
                    autoColumns={true}
                />
            </div>
        </div>
    )
}

export default Payments;
