import * as AppUrls from "constants/AppUrls";
import addIcon from "img/add-icon.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { filterSalesOrders } from "./SalesOrderServices/SalesOrderServices";
import { DropDownOption } from "components/Common.models";
import DateGrid from "components/DateFilter/DateGrid";
import FilterPopup from "components/FilterPopup/FilterPopup";
import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AgGrid from "elements/AgGrid";
import {
  checkIfAllowed,
  dateFormmaterNoTime,
  getCurrencyByCode,
} from "global/helpers";
import exportIcon from "img/export.svg";
import Select from "react-select";
import { statusEnum } from "../StatusEnum";

let selectOriginOptions = [
  { label: "All", value: 0 },
  { label: "System", value: 1 },
  { label: "Web", value: 2 },
];
const SalesOrders = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedOrigin, setSelectedOrigin] = useState<number>(0);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    origin: 0,
    states: 0,
    searchQuery: "",
  });
  const [dateCategory, setDateCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState<DropDownOption>({
    label: "All",
    value: 0,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const contactRef = useRef<HTMLInputElement>(null);
  const trackingRef = useRef<HTMLInputElement>(null);

  const onRowClick = (e) => {
    let url = `${AppUrls.sales_order_list}/${e.salesOrderId}`;
    history.push(url);
  };

  const dataMutation = useMutation("data", filterSalesOrders, {
    async onSuccess(response) {
      setDataGrid(response?.data);
    },
  });

  const displayFilter = () => {
    let newFilters = { ...filters, states: selectedStatus?.value };
    if (endDate) newFilters = { ...newFilters, endDate: endDate.toISOString() };
    else newFilters = { ...newFilters, endDate: null };
    if (startDate)
      newFilters = { ...newFilters, startDate: startDate.toISOString() };
    else newFilters = { ...newFilters, startDate: null };
    if (selectedOrigin) newFilters = { ...newFilters, origin: selectedOrigin };
    else newFilters = { ...newFilters, origin: null };
    if (searchTerm) newFilters = { ...newFilters, searchQuery: searchTerm };
    else newFilters = { ...newFilters, searchQuery: "" };
    setFilters(newFilters);
    setDataGrid(null);
  };
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "orderNumber",
      headerName: "Order No.",
      resizable: true,
      maxWidth: 130,
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      maxWidth: 150,
      resizable: true,
    },
    {
      field: "company",
      headerName: "Company",
      maxWidth: 180,
      resizable: true,
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 130,
      valueGetter: (params) =>
        params.data.status ? statusEnum[params.data.status] : "",
      resizable: true,
    },
    {
      field: "origin",
      headerName: "Origin",
      maxWidth: 100,

      valueGetter: (params) => (params.data.origin === 1 ? "System" : "Web"),
      resizable: true,
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
      maxWidth: 130,
      resizable: true,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      maxWidth: 130,

      valueGetter: (params) =>
        params.data.orderDate
          ? `${dateFormmaterNoTime(params.data.orderDate)}`
          : "",
      resizable: true,
    },
    {
      field: "orderAmount",
      headerName: "Order Amount",
      maxWidth: 150,

      resizable: true,
      valueGetter: (params) => {
        return `${currencySymbol} ${
          params.data?.orderAmount?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "shipAmount",
      headerName: "Ship Amount",
      maxWidth: 150,
      resizable: true,
      valueGetter: (params) => {
        return `${currencySymbol} ${
          params.data?.shipAmount?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "projectNumber",
      headerName: "Project No.",
      maxWidth: 130,
      resizable: true,
    },
    {
      field: "customerPO",
      headerName: "Customer PO#",

      resizable: true,
    },
    {
      field: "shipVia",
      headerName: "Ship Via",
      maxWidth: 130,

      resizable: true,
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const filterResults = () => {
    setFilters((filters) => ({
      ...filters,
      contactName: contactRef.current.value ?? null,
      trackingNumber: trackingRef.current.value ?? null,
    }));
    setShowFilter(false);
  };

  const onSelectChange = (e) => {
    setSelectedOrigin(parseInt(e.value));
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
    setSelectedOrigin(0);
    setSelectedStatus({ label: "All", value: 0 });
    setSearchTerm("");
    setFilters({
      startDate: null,
      endDate: null,
      origin: null,
      states: 0,
      searchQuery: "",
    });
    setDataGrid(null);
  };

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Sales Orders</p>
          <div className="d-flex justify-content-between">
            <button
              className="import-btn"
              type="button"
              onClick={exportToExcel}
              disabled={
                disabledExportBtn || dataGrid?.length === 0 || !dataGrid
              }
            >
              <img src={exportIcon} className="me-2" alt="" />
              Export to Excel
            </button>
            {/*<button className="import-btn import-btn-blue">
                        <ExcelIcon className='me-2' /> Import Serialized Items
                    </button>
                    <button className="import-btn import-btn-blue">
                        <ExcelIcon className='me-2' /> Import Tangible Items
                    </button>*/}
            <button onClick={onRefresh} className="import-btn import-btn-blue">
              <RefreshIcon className="me-2" />
              Refresh
            </button>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <Link to={AppUrls.new_sales_order} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> New Sales Order
              </Link>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container has-filters">
            <div className="filters">
              <div className="row mb-3 d-flex  align-items-center">
                <div className="col-sm-2">
                  <div className="page-search">
                    <label>Search</label>
                    <div className="input-group">
                      <span className="page-search-icon">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        className="page-search-input ps-5"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">
                  <label>Origin</label>
                  <Select
                    value={selectOriginOptions.find(
                      (option) => option.value === selectedOrigin
                    )}
                    onChange={onSelectChange}
                    options={selectOriginOptions}
                  />
                </div>
                <div className="col-sm-2">
                  <label>Status</label>
                  <Select
                    value={selectedStatus}
                    onChange={setSelectedStatus}
                    options={Object.entries(statusEnum).map(([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </div>
                <div
                  className="col-sm-2 d-flex align-items-end"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </div>
              <DateGrid
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateCategory={dateCategory}
                setDateCategory={setDateCategory}
              />
              <div className="row mb-3"></div>
            </div>
            <div className="grid">
              <AgGrid
                columns={columns}
                fetchMethod={dataMutation}
                data={dataGrid}
                setDataGrid={setDataGrid}
                setrowClicked={onRowClick}
                filters={filters}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                refreshCounter={refreshCounter}
                fileName="Sales Orders List Report"
              />
            </div>
          </div>
        </div>
      </div>
      <FilterPopup show={showFilter} setShow={setShowFilter}>
        <div className="d-flex align-items-center">
          <label className="me-2">Contact Name</label>
          <input className="form-control me-4" ref={contactRef} />
          <label className="me-2">Enter tracking No</label>
          <input className="form-control" ref={trackingRef} />
          <button className="btn btn-outline-primary" onClick={filterResults}>
            Filter
          </button>
        </div>
      </FilterPopup>
    </>
  );
};

export default SalesOrders;
