import React, { useState } from 'react';
import { Card, Dropdown } from "react-bootstrap";
import { ThreeDots } from 'react-bootstrap-icons';
import { dateFormmater } from 'global/helpers';
// import UpdateNote from './UpdateNote';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import Edit from 'img/edit.svg';
import Delete from 'img/delete.svg';
import NoteUpdate from './NoteUpdate';

const NoteItem = ({ note, onNoteDelete, onNoteUpdate, isDisabled }) => {

    const [showUpdateNote, setShowUpdateNote] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);

    const onConfirmDelete = () => {
        setShowDelete(false)
        onNoteDelete(note)
    }

    return (
        <>
            <Card className="note-card">
                <Card.Header>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="card-title">{note.subject}</div>
                        <Dropdown className='ml-2'>
                            <Dropdown.Toggle
                                variant='light'
                                disabled={isDisabled}
                            >
                                <ThreeDots className='three-dots' />
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ border: '1px solid #7CB1FF' }}>
                                <Dropdown.Item
                                    className="mb-1"
                                    onClick={() => setShowUpdateNote(true)}
                                >
                                    <span>
                                        <img src={Edit} alt='edit' className='dp-icon' />
                                    </span>
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="mb-1"
                                    onClick={() => setShowDelete(true)}
                                >
                                    <span>
                                        <img src={Delete} alt='delete' className='dp-icon' />
                                    </span>
                                    Delete
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card.Header>
                <Card.Body>
                    <p>{note.noteBody}</p>
                </Card.Body>
                <Card.Footer className="note-footer">
                    <div className="row">
                        <div className="left-side-mid">
                            <span className='note-label'>Date Added</span>
                            <span className="ms-3">{dateFormmater(note.createdDate)}</span>
                        </div>
                        <div className="right-side-mid">
                            <span className='note-label'>Added by</span>
                            <span className="ms-3">{note.createdBy}</span>
                        </div>
                    </div>
                </Card.Footer>
            </Card>
            <ConfirmationModal
                title="Delete Note"
                message="Are you sure? It will be permanently deleted."
                showModal={showDelete}
                onClose={() => setShowDelete(false)}
                onConfirm={() => onConfirmDelete()}
                cancelBtnTitle="No, Keep"
                confirmBtnTitle="Yes, Delete"
                type="confirmation-danger"
            />
            <NoteUpdate
                isUpdate={showUpdateNote}
                setIsUpdate={setShowUpdateNote}
                note={note}
                onNoteUpdate={onNoteUpdate}
            />
        </>
    );
}

export default NoteItem;
