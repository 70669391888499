import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { ValidationRule, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createBannerImg, createSOImg } from "../Portal.services";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";

type FormData = {
  [key: string]: string | boolean;
};

type Props = {
  section: string;
  imageUrl?: string;
  suggestion?: string;
  title?: string;
  btn?: string;
  deleteBtn?: string;
  resetData?: () => void;
  sbmtData: (data: FormData) => void;
  deleteData: () => void;
  active?: boolean;
  inputs: {
    maxLength?: ValidationRule<number>;
    name: string;
    type: string;
    label: string;
    default?: string;
    required?: boolean;
  }[];
  checkboxes?: {
    name: string;
    label: string;
  }[];
};

export default function EcomSetupForm(props: Props) {
  const {
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loadedImg, setLoadedImg] = useState(false);
  const [imgValoidation, setImgValoidation] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const imgRef = useRef(null);
  const onSubmit = (data: FormData) => {
    if (!isSubmitting && loadedImg) {
      setIsSubmitting(true);
      data.imageUrl = imgRef.current;
      props.sbmtData(data);
    } else setImgValoidation(true);
  };
  const history = useHistory();

  const uploadImgSS = useMutation(createBannerImg, {
    onSuccess: (img) => {
      imgRef.current = img;
      setLoadedImg(true);
      setLoadingImg(false);
    },
    onError: (error) => {},
  });
  const uploadImgSO = useMutation(createSOImg, {
    onSuccess: (img) => {
      imgRef.current = img;
      setLoadedImg(true);
      setLoadingImg(false);
    },
    onError: (error) => {},
  });
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      setLoadingImg(true);
      if (props.section == "SS") uploadImgSS.mutate(file);
      else uploadImgSO.mutate(file);
    }
  };
  const onDelete = () => {
    props.deleteData();
  };

  useEffect(() => {
    props.inputs.forEach((input) => {
      if (input.default) {
        setValue(input.name, input.default);
      }
    });
  }, [props.inputs]);

  return (
    <div className="static-page">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div>
            {" "}
            <img
              src={backArrow}
              alt="back arrow"
              onClick={() => history.push(AppUrls.Content_Management_System)}
            />
            <span className="mx-2">{props.title}</span>
          </div>

          <div>
            <button
              className="btn btn-outline-primary "
              style={{ minWidth: "100px" }}
              disabled={!isDirty}
              onClick={() => {
                reset();
              }}
            >
              Reset
            </button>

            {props.deleteBtn ? (
              <button
                type="button"
                className="btn btn-danger "
                style={{ minWidth: "100px" }}
                onClick={onDelete}
              >
                {props.deleteBtn || "Submit"}
              </button>
            ) : null}
            <button
              type="submit"
              className="btn-primary btn "
              style={{ minWidth: "100px" }}
            >
              {props.btn || "Submit"}
            </button>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div>
            <div className=" ">
              <div className="form-group my-auto">
                {props.checkboxes &&
                  props.checkboxes.map((checkbox) => (
                    <div key={checkbox.name}>
                      <input
                        type="checkbox"
                        id={checkbox.name}
                        className="form-control"
                        {...register(checkbox.name)}
                      />
                      <label htmlFor={checkbox.name}>{checkbox.label}</label>
                    </div>
                  ))}
              </div>
              <div className="">
                {props.inputs.map((input, index) => (
                  <div className="" key={input.name}>
                    <div className="">
                      <div className="">
                        <div className="">
                          <label htmlFor={input.name}>
                            {input.label}{" "}
                            <span className="text-danger mx-2"> *</span>{" "}
                            {errors[input.name] &&
                            (errors[input.name].type === "required" ||
                              errors[input.name].type === "maxLength") ? (
                              <span className="text-danger">
                                {errors[input.name].type === "required"
                                  ? "This field is required"
                                  : "Max length exceeded"}
                              </span>
                            ) : null}
                          </label>{" "}
                        </div>
                      </div>
                      <input
                        type={input.type}
                        id={input.name}
                        className="form-control mb-3"
                        {...register(input.name, {
                          required: true,
                          maxLength: input.maxLength,
                        })}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <label className="">
                Image
                <span className="text-danger mx-2"> *</span>
              </label>{" "}
              {loadingImg ? (
                <div
                  className="spinner-border text-primary m-3 "
                  role="status"
                  style={{ float: "right" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <></>
              )}
              <Dropzone
                onChangeStatus={handleChangeStatus}
                accept="image/*"
                {...register("dropzone")}
              />
              {imgValoidation && (
                <span className="text-danger">This field is required</span>
              )}
              {props.suggestion && (
                <p className="text-muted">{props.suggestion}</p>
              )}
            </div>
          </div>
        </div>{" "}
      </form>
    </div>
  );
}
