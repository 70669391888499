import { useState, useContext, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import { ItemColor } from "./InventorySetup.model";
import { ErrorResponse } from "components/data/Errors.model";
import {
  getItemColors,
  createItemColor,
  editItemColor,
} from "./InventorySetup.services";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { useLocation } from "react-router-dom";
const NewItemColor = ({ match }) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const { error: errorLoadingData } = useQuery<any, ErrorResponse>(
    "get_colors",
    getItemColors
  );

  const formMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { isDirty, errors },
  } = formMethods;

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { id, name, description } = location.state;
      setSelectedId(id);
      setValue("name", name);
      setValue("description", description);
    }
  }, [location.state]);

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const addColor = useMutation(createItemColor, {
    async onSuccess() {
      queryClient.invalidateQueries("get_colors");
      let notification = {
        variant: "success",
        msg: `Color ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_color);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding color"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateColor = useMutation(editItemColor, {
    async onSuccess() {
      queryClient.invalidateQueries("get_colors");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_color);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing color"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();

  const onSubmit = (values) => {
    const data: ItemColor = {
      itemColorId: selectedId ? selectedId : 0,
      color: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addColor.mutate(data);
    } else {
      updateColor.mutate(data);
    }
  };

  const resetForm = () => {
    reset({ name: "#00000", description: "" });
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addColor.isLoading || updateColor.isLoading,
    });
  }, [addColor.isLoading, updateColor.isLoading, appDispatch]);

  return (
    <>
      <div className="static-page">
        {" "}
        <FormProvider {...formMethods}>
          <form id="new-method-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="  page-title page-title-editable">
              <div className="d-flex flex-row">
                <div className="back-btn">
                  <img
                    src={backArrow}
                    alt="back arrow"
                    onClick={() => history.push(AppUrls.item_color)}
                  />
                </div>
                <div>
                  <p className="">Item Color</p>
                </div>
              </div>
              <div className="d-flex" style={{ marginLeft: "10px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline custom-outline"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  {!selectedId ? "Add Item Color" : "Save Changes"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="row h-100">
                {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                  checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                  <div className="row m-3">
                    <div className="col-4">
                      <Controller
                        control={control}
                        name="name"
                        rules={{ required: true, maxLength: 15 }}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <label>
                              Item Color <span className="text-danger">*</span>
                            </label>
                            <input
                              value={value}
                              onChange={onChange}
                              type={"color"}
                              className={`form-control ${
                                errors["name"] && "required_field"
                              } w-25`}
                            />
                          </>
                        )}
                      />
                      {errors.name && errors.name.type === "maxLength" && (
                        <p className="text-danger">
                          Max length for name value must not exceed 15
                          characters.
                        </p>
                      )}
                      {errors.name && errors.name.type === "required" && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-4">
                      <label>
                        Description <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="description"
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={
                                errors.description
                                  ? "form-control required_field"
                                  : "form-control"
                              }
                              required={true}
                            />
                            {errors.description &&
                              errors.description.type === "required" && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>{" "}
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default NewItemColor;
