import api from "components/data/AxiosClient";
import { fixTimezoneOffset } from "global/helpers";

const dashboard = "dashboard";

export const getSettings = async (id) => {
  const response = await api.get(`${dashboard}/dashboard-component-settings/${id}`);
  return response.data;
}

export const addSettings = async (data) => {
  const response = await api.post(`${dashboard}/dashboard-component-settings`, data);
  return response.data;
}

export const getSales = async (params)  => {
  let extra = ''
  if(params && params?.startDate)
    extra += `?startDate=${fixTimezoneOffset(params.startDate)}`
  if(params && params?.endDate)
    extra += `&endDate=${fixTimezoneOffset(params.endDate)}`
  const response = await api.get(`${dashboard}/sales${extra}`);
  return response.data;
};

export const getExpenses = async (params)  => {
  let extra = ''
  if(params && params?.startDate)
    extra += `?startDate=${fixTimezoneOffset(params.startDate)}`
  if(params && params?.endDate)
    extra += `&endDate=${fixTimezoneOffset(params.endDate)}`
  const response = await api.get(`${dashboard}/expense/date${extra}`);
  return response.data;
};

export const getExpensesTypes = async ()  => {
  const response = await api.get(`${dashboard}/expense/type`);
  return response.data;
};

export const getProfit = async (params)  => {
  let extra = ''
  if(params && params?.startDate)
    extra += `?startDate=${fixTimezoneOffset(params.startDate)}`
  if(params && params?.endDate)
    extra += `&endDate=${fixTimezoneOffset(params.endDate)}`
  const response = await api.get(`${dashboard}/profit${extra}`);
  return response.data;
};


export const getPayments = async (params)  => {
  let extra = ''
  if(params && params?.startDate)
    extra += `?startDate=${fixTimezoneOffset(params.startDate)}`
  if(params && params?.endDate)
    extra += `&endDate=${fixTimezoneOffset(params.endDate)}`
  const response = await api.get(`${dashboard}/payments${extra}`);
  return response.data;
};

export const getYoYE = async ()  => {
  const response = await api.get(`${dashboard}/year-over-year-expenses`);
  return response.data;
};

export const getQR = async ()  => {
  const response = await api.get(`${dashboard}/quick-ratio`);
  return response.data;
};


export const getCR = async ()  => {
  const response = await api.get(`${dashboard}/current-ratio`);
  return response.data;
};

export const getDSO = async ()  => {
  const response = await api.get(`${dashboard}/days-sales-outstanding?days=365`);
  return response.data;
};

export const getNPM = async ()  => {
  const response = await api.get(`${dashboard}/net-profit-margin`);
  return response.data;
};

export const getDIO = async ()  => {
  const response = await api.get(`${dashboard}/days-inventory-outstanding`);
  return response.data;
};

export const getInvoiceNbres = async ()  => {
  const response = await api.get(`${dashboard}/invoices-number`);
  return response.data;
};

export const getYOYS = async (params)  => {
  let extra = {}
  if(params && params?.startDate)
    extra['startDate'] = fixTimezoneOffset(params.startDate)
  if(params && params?.endDate)
    extra['endDate'] = fixTimezoneOffset(params.endDate)
  if(params && params?.hubId)
    extra['hubId'] = params.hubId
  if(params && params?.itemId)
    extra['itemId'] = params.itemId
  if(params && params?.categoryId)
    extra['categoryId'] = params.categoryId
  const response = await api.post(`${dashboard}/year-over-year-sales`, extra);
  return response.data;
};

export const getGMRoid = async (params)  => {
  let extra = {}
  if(params && params?.startDate)
    extra['startDate'] = fixTimezoneOffset(params.startDate)
  if(params && params?.endDate)
    extra['endDate'] = fixTimezoneOffset(params.endDate)
  if(params && params?.hubId)
    extra['hubId'] = params.hubId
  if(params && params?.itemId)
    extra['itemId'] = params.itemId
  if(params && params?.categoryId)
    extra['categoryId'] = params.categoryId
  const response = await api.post(`${dashboard}/gmroid/${params?.type}`, extra);
  return response.data;
};

export const getInvTurnOver = async (params)  => {
  let extra = {}
  if(params && params?.startDate)
    extra['startDate'] = fixTimezoneOffset(params.startDate)
  if(params && params?.endDate)
    extra['endDate'] = fixTimezoneOffset(params.endDate)
  if(params && params?.hubId)
    extra['hubId'] = params.hubId
  if(params && params?.itemId)
    extra['itemId'] = params.itemId
  if(params && params?.categoryId)
    extra['categoryId'] = params.categoryId
  const response = await api.post(`${dashboard}/inventory-turnover/${params?.type}`, extra);
  return response.data;
};
