import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import {
  Company,
  EmailSettings,
  Market,
  Region,
  ShippingMethod,
} from "../SystemSetup.models";

const marketsUrl = "system-setup/markets";
const qpayUrl = "system-setup/qpay-credentials";
const regionsUrl = "system-setup/regions";
const shippingUrl = "system-setup/shipping-methods";
const emailSettingsUrl = "system-setup/email-settings";
const companyUrl = "system-setup/company";
const hubsUrl = "system-setup/hubs";

// Markets

export const getMarkets = async (params): Promise<QueryResult<Market>> => {
  const response = await api.get<QueryResult<Market>>(
    `${marketsUrl}${
      params.params && params.params !== "" ? `?${params.params}` : ""
    }`
  );
  return response.data;
};
export const getMarketById = async (id): Promise<QueryResult<Market>> => {
  const response = await api.get<QueryResult<Market>>(`${marketsUrl}/${id}`);
  return response.data;
};
export const createMarket = async (data: Market) => {
  return await api.post(marketsUrl, data);
};

export const editMarket = async (data: Market) => {
  return await api.put<Market>(marketsUrl, data);
};

// Qpay
export const getQpayList = async (params) => {
  const response = await api.get(
    `${qpayUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};

export const getQpay = async (id) => {
  let queryId = id.queryKey[0];
  if (queryId) return await api.get(`${qpayUrl}/${queryId}`);
};

export const createQpay = async (data) => {
  return await api.post(qpayUrl, data);
};

export const editQpay = async (data) => {
  return await api.put(qpayUrl, data);
};

export const deleteQpay = async (id) => {
  return await api.delete(`${qpayUrl}/${id}`);
};

// Regions

export const getRegionsList = async (params): Promise<QueryResult<Region>> => {
  if (params) {
    const response = await api.get<QueryResult<Region>>(
      `${regionsUrl}?PageSize=${params.PageSize}&PageStart=${
        isNaN(params.PageStart) ? 0 : params.PageStart
      }${params.params && params.params !== "" ? `&${params.params}` : ""}`
    );
    return response.data;
  } else {
    const response = await api.get<QueryResult<Region>>(`${regionsUrl}`);
    return response.data;
  }
};

export const createRegion = async (data: Region) => {
  return await api.post<Region>(regionsUrl, data);
};

export const editRegion = async (data: Region) => {
  return await api.put<Region>(regionsUrl, data);
};

// Shipping Methods

export const getShippingList = async (
  params
): Promise<QueryResult<ShippingMethod>> => {
  const response = await api.get<QueryResult<ShippingMethod>>(
    `${shippingUrl}${
      params && params.params && params.params !== "" ? `?${params.params}` : ""
    }`
  );
  return response.data;
};

export const createShipping = async (newShippingMethod: ShippingMethod) => {
  return await api.post<ShippingMethod>(shippingUrl, newShippingMethod);
};

export const editShipping = async (data: ShippingMethod) => {
  return await api.put<ShippingMethod>(shippingUrl, data);
};

// System Email Settings

export const getEmailSettings = async (): Promise<EmailSettings> => {
  const result = await api.get<EmailSettings>(emailSettingsUrl);
  return result.data;
};

export const editEmailSettings = async (data: EmailSettings) => {
  return await api.put<EmailSettings>(emailSettingsUrl, data);
};

// Company

export const getCompanyInfo = async (): Promise<Company> => {
  const result = await api.get<Company>(companyUrl);
  return result.data;
};

export const editCompanyInfo = async (data: Company): Promise<Company> => {
  const result = await api.put<Company>(companyUrl, data);
  return result.data;
};

export const ImageUploadLogo = async (data) => {
  return await api.post("system-setup/company/logo", data);
};

export const ImageUploadPortalSetting = async (data) => {
  return await api.post("system-setup/company/portal-img", data);
};

// Hubs

export const getHubs = async () => {
  const result = await api.get(hubsUrl);
  return result.data;
};

// Tax

export const getTaxRates = async (params) => {
  const result = await api.get(
    `system-setup/tax-rates${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return result.data;
};

export const addTax = async (data) => {
  const result = await api.post("system-setup/tax-rates", data);
  return result.data;
};

export const editTax = async (data) => {
  const result = await api.put(`system-setup/tax-rates/${data.id}`, data);
  return result.data;
};

// Purging

export const PurgeEntitiesAPI = async (data) => {
  return await api.post("purging", data);
};

export const getCompanies = async () => {
  return await api.get("users/companies");
};

export const postCompany = async (data) => {
  return await api.post("users/companies", data);
};

export const assignCompany = async (data) => {
  return await api.post(`users/select-company/${data.id}`, {
    email: data.username,
    password: data.password,
  });
};

// Currency

export const getCurrency = async () => {
  const result = await api.get("system-setup/currency");
  return result.data;
};
export const addExchangeRate = async (data) => {
  const result = await api.post("system-setup/exchange-rate", data);
  return result.data;
};

export const editExchangeRate = async (data) => {
  const result = await api.put("system-setup/exchange-rate", data);
  return result.data;
};

export const getExchangeRateById = async (id: number) => {
  const result = await api.get(`system-setup/exchange-rate/${id}`);
  return result.data;
};

export const getExchangeRate = async (params) => {
  const result = await api.get(
    `system-setup/exchange-rate${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return result.data;
};

export const getLatestRate = async (code) => {
  const result = await api.get(`system-setup/exchange-rate-latest/${code}`);
  return result.data;
};
