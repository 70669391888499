import { useState, useEffect, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, Form, InputGroup, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  getNonTangibleSelections,
  createNonTangibleItem,
  getNonTangibleItemById,
  updateNonTangibleItem,
  deleteNonTangibleItem,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import "./Inventory.scss";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import StateContext from "context/StateContext";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { nonTangibleDTO, nonTangibleInit } from "../Inventory.models";
import { DropDownOption } from "components/Common.models";
import Lookup from "elements/Lookup";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import SelectionModal from "components/SelectionModal/SelectionModal";
import SearchIcon from "img/search-group.png";
import CategoryModal from "./CategoryModal";
import ItemAttributes from "./ItemAttributes";
import { getItemCategories } from "../InventorySetup/InventorySetup.services";

const NonTangibleItem = ({ match }) => {
  let history = useHistory();
  const { id } = useParams();
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const [isEditNonTangibleData, setIsEditNonTangibleData] =
    useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [attributes, setAttributes] = useState([]);

  const methods = useForm<nonTangibleDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isActive: true,
      isTaxable: true,
    },
  });

  const {
    formState: { errors, isDirty },

    control,
    getValues,
    handleSubmit,
    reset,
    register,
    setValue,
  } = methods;

  const { data: categories } = useQuery("item-categories", getItemCategories, {
    select(data) {
      let flatCategories = [];
      const transformCategories = (data) => {
        if (data && data.length > 0) {
          for (let category of data) {
            if (category.isLeaf) flatCategories.push(category);
            if (category.children?.length > 0) {
              transformCategories(category.children);
            }
          }
        }
      };
      transformCategories(data);
      return flatCategories;
    },
  });

  const { isLoading: isLoadingSelections, data: selections } =
    useQuery<nonTangibleInit>("selectionsLists", getNonTangibleSelections);

  const [selectedClass, setSelectedClass] = useState(null);
  const [itemSubClassOptions, setItemSubClassOptions] = useState<
    DropDownOption[]
  >([]);

  const itemClassOptions: DropDownOption[] = selections?.classes.map((item) => {
    return { label: item.className, value: item.itemClassId };
  });

  useEffect(() => {
    const itemSubClassOptions: DropDownOption[] = selections?.subClasses
      .filter((i) => i.itemClassId === selectedClass?.value)
      .map((item) => {
        return { label: item.subClass, value: item.itemSubClassId };
      });
    setItemSubClassOptions(itemSubClassOptions);
  }, [selectedClass]);

  const productLineOptions: DropDownOption[] = selections?.productLines.map(
    (item) => {
      return { label: item.productLine, value: item.itemProductLineId };
    }
  );

  const { isLoading: isLoadingNonTangibleInfo, data: nonTangibleInfo } =
    useQuery<nonTangibleDTO>([id], getNonTangibleItemById, {
      enabled: id !== undefined ? true : false,
    });

  useEffect(() => {
    if (id) {
      setIsEditNonTangibleData(false);
    } else {
      setIsEditNonTangibleData(true);
    }
  }, [id]);

  useEffect(() => {
    if (nonTangibleInfo && categories) {
      setAttributes(nonTangibleInfo.attributeValues);
      reset(nonTangibleInfo);
      let category = categories.find(
        (category) => category.value === nonTangibleInfo.itemCategoryId
      );
      if (category) setSelectedCategory(category);
    }
  }, [nonTangibleInfo, categories]);

  const clearNonTangibleFrm = () => {
    reset();
  };

  const addItem = useMutation(createNonTangibleItem, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `Item ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      clearNonTangibleFrm();
      history.push(`${AppUrls.non_tangible_item}/${response.data.itemId}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to create item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editItem = useMutation(updateNonTangibleItem, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Item ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsEditNonTangibleData(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to updated item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeNonTangibleItem = useMutation(deleteNonTangibleItem, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Item Deleted",
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      clearNonTangibleFrm();
      history.push(AppUrls.inventory_list);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to delete item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onItemDelete = () => {
    setShowDelete(false);
    if (id) {
      removeNonTangibleItem.mutate(id);
    }
  };

  const onSubmit = (data) => {
    if (id) {
      let newData = {
        ...data,
        attributeValues: attributes
          .filter((attribute) => attribute.value)
          .map((attribute) => ({
            attributeId: attribute.itemCategoryAttributeId,
            value: attribute.value,
          })),
      };
      editItem.mutate({ body: newData, itemId: id });
    } else {
      let newData = {
        ...data,
        hubsIds: appState.selectedHubs.map((hub) => hub.locationId),
        incomeAccountId: selectedInvAssetAccount?.ledgerId,
        attributeValues: attributes
          .filter((attribute) => attribute.value)
          .map((attribute) => ({
            attributeId: attribute.itemCategoryAttributeId,
            value: attribute.value,
          })),
      };
      addItem.mutate(newData);
    }
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        isLoadingNonTangibleInfo ||
        editItem.isLoading ||
        addItem.isLoading ||
        removeNonTangibleItem.isLoading ||
        isLoadingSelections,
    });
  }, [
    isLoadingNonTangibleInfo,
    editItem.isLoading,
    addItem.isLoading,
    removeNonTangibleItem.isLoading,
    isLoadingSelections,
    appDispatch,
  ]);

  const { data: paymentAccountsInv } = useQuery("inventory", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Income })
  );

  const [openInvAssetAccount, setOpenInvAssetAccount] =
    useState<boolean>(false);
  const [selectedInvAssetAccount, setSelectedInvAssetAccount] = useState(null);

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const onInvAssetAccountSelection = (e) => {
    setSelectedInvAssetAccount(e);
    setOpenInvAssetAccount(false);
    setValue("invAssetAccount", e.ledgerId, { shouldValidate: true });
  };

  const onChooseCategory = () => {
    setShowCategoryModal(true);
  };

  const onSelectCategory = (category) => {
    setShowCategoryModal(false);
    setSelectedCategory(category);
    setValue("itemCategoryId", category?.value, { shouldDirty: true });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <Link to={AppUrls.inventory_list} className="back-btn ">
            <img src={backArrow} alt="back arrow" />
            {!id && "Adding Non-Tangible Item"}
            {id && isEditNonTangibleData && "Editing Non-Tangible Item"}
            {id && !isEditNonTangibleData && nonTangibleInfo?.itemNumber}
          </Link>
          <div>
            {id ? (
              isEditNonTangibleData === false ? (
                <>
                  {/* {checkIfAllowed(appState.allowedUrls, "delete", match.path) &&
                                        <button
                                            type='button'
                                            className="btn btn-outline-primary no-border text-danger m3-4"
                                            onClick={() => setShowDelete(true)}
                                        >
                                            <img src={Delete} alt="Delete Icon" className="me-2" />
                                            Delete
                                        </button>
                                    } */}
                  {checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditNonTangibleData(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary no-border"
                    onClick={() => {
                      reset();
                      setIsEditNonTangibleData(false);
                    }}
                  >
                    Reset
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </>
              )
            ) : (
              <>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border${
                    !isDirty && "text-muted"
                  }`}
                  onClick={() => clearNonTangibleFrm()}
                  disabled={!isDirty}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </>
            )}
          </div>
        </div>
        <FormProvider {...methods}>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row mt-4">
                <div className="col-lg-3 col-md-2 col-sm-4 col-4  me-4 mb-5">
                  <label className="label mb-1">
                    Item Number <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    {...register("itemNumber", { required: true })}
                    name="itemNumber"
                    className={`${errors.itemNumber && "required_field"}`}
                    disabled={isEditNonTangibleData === true ? false : true}
                  />
                  {errors["itemNumber"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-lg-3 col-md-4 col-sm-8 col-8 me-5 mb-5">
                  <label className="label mb-1">
                    Item Description <span className="text-danger">*</span>
                  </label>
                  <Form.Control
                    {...register("itemDescription", { required: true })}
                    name="itemDescription"
                    className={`${errors.itemDescription && "required_field"}`}
                    disabled={isEditNonTangibleData === true ? false : true}
                  />
                  {errors["itemDescription"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                {!id && (
                  <div className="col-lg-3 col-md-4 col-sm-8 col-8 me-5 mb-5">
                    <label>
                      Income account <span className="text-danger">*</span>
                    </label>
                    <>
                      <Lookup
                        onButtonClicked={() => setOpenInvAssetAccount(true)}
                        inputName="invAssetAccount"
                        isRequired={true}
                        initialData={paymentAccountsInv?.data?.map((item) => ({
                          ...item,
                          id: item.ledgerId,
                          name: item.accountName,
                        }))}
                        onSelection={onInvAssetAccountSelection}
                        inputValue={selectedInvAssetAccount?.accountName}
                        hasError={errors["invAssetAccount"] ? true : false}
                      />
                      {errors && errors["invAssetAccount"] && (
                        <p className="text-danger">This field is Required</p>
                      )}
                    </>
                  </div>
                )}
                <div className="col-lg-2 col-md-2 col-sm-4  col-4 mb-5">
                  <div className="row">
                    <label className="label mb-1">Status</label>
                  </div>
                  {isEditNonTangibleData === false ? (
                    <span
                      className={
                        getValues("isActive") === true
                          ? "active-status"
                          : "disabled-status"
                      }
                    >
                      {getValues("isActive") === true ? "Active" : "Disabled"}
                    </span>
                  ) : (
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, value } }) => (
                        <div className="form-group mt-2">
                          <label className="checkbox-inline">
                            <input
                              id="isActiveYes"
                              type="radio"
                              onChange={() => onChange(true)}
                              checked={value}
                              disabled={!isEditNonTangibleData}
                            />
                            <label
                              className="text-black ms-2"
                              htmlFor="isActiveYes"
                            >
                              Active
                            </label>
                          </label>
                          <label className="checkbox-inline ms-3">
                            <input
                              id="isActiveNo"
                              type="radio"
                              onChange={() => onChange(false)}
                              checked={!value}
                              disabled={!isEditNonTangibleData}
                            />
                            <label
                              className="text-black ms-2"
                              htmlFor="isActiveNo"
                            >
                              Disabled
                            </label>
                          </label>
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="row mx-auto mt-4 custom-border-bottom">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4  me-5 mb-5">
                  <label className="label mb-1">Class ID</label>
                  <Controller
                    control={control}
                    name="classId"
                    render={({ field: { onChange } }) => (
                      <Select
                        menuPlacement="auto"
                        options={itemClassOptions}
                        isSearchable={true}
                        isDisabled={!isEditNonTangibleData}
                        value={selectedClass}
                        onChange={(val: DropDownOption) =>
                          setSelectedClass(val)
                        }
                        isClearable={true}
                      />
                    )}
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-4  me-5 mb-5">
                  <label className="label mb-1">Sub Class ID</label>
                  <Controller
                    control={control}
                    name="subClassId"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={itemSubClassOptions}
                        isSearchable={true}
                        value={
                          itemSubClassOptions?.find(
                            (opt) => opt?.value === value
                          ) || null
                        }
                        onChange={(val: DropDownOption) => onChange(val?.value)}
                        menuPlacement="auto"
                        isDisabled={!isEditNonTangibleData}
                        isClearable={true}
                      />
                    )}
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-4  me-5 mb-5">
                  {/* <label className="label mb-1">Category ID</label>
                                    <Controller
                                        control={control}
                                        name="categoryId"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Select
                                                options={categoryOptions}
                                                isSearchable={true}
                                                value={categoryOptions?.find(opt => opt?.value === value) || null}
                                                onChange={(val: DropDownOption) => onChange(val?.value)}
                                                menuPlacement="auto"
                                                isDisabled={!isEditNonTangibleData}
                                                isClearable={true}
                                            />
                                        )}
                                    /> */}
                  <label>Category</label>
                  <InputGroup className={`input-lookup`}>
                    <input
                      className={`form-control ${
                        !isEditNonTangibleData ? "" : "bg-white"
                      }`}
                      placeholder="Choose"
                      disabled={!isEditNonTangibleData}
                      readOnly
                      defaultValue={selectedCategory?.label ?? ""}
                    />
                    <input className="d-none" {...register("itemCategoryId")} />
                    <Button
                      variant="primary"
                      className="input-group-prepend"
                      onClick={onChooseCategory}
                      disabled={!isEditNonTangibleData}
                    >
                      <img src={SearchIcon} alt="" />
                    </Button>
                  </InputGroup>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-4  me-4 mb-5">
                  <div className="row">
                    <label className="label mb-1">Taxable?</label>
                  </div>
                  <Controller
                    control={control}
                    name="isTaxable"
                    render={({ field: { onChange, value } }) => (
                      <div className="form-group mt-2">
                        <label className="checkbox-inline">
                          <input
                            id="isTaxableYes"
                            type="radio"
                            onChange={() => onChange(true)}
                            checked={value}
                            disabled={!isEditNonTangibleData}
                          />
                          <label
                            className="text-black ms-2"
                            htmlFor="isTaxableYes"
                          >
                            Yes
                          </label>
                        </label>
                        <label className="checkbox-inline ms-3">
                          <input
                            id="isTaxableNo"
                            type="radio"
                            onChange={() => onChange(false)}
                            checked={!value}
                            disabled={!isEditNonTangibleData}
                          />
                          <label
                            className="text-black ms-2"
                            htmlFor="isTaxableNo"
                          >
                            No
                          </label>
                        </label>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="row mx-auto mt-4">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4  me-5">
                  <label className="label mb-1">Product Line</label>
                  <Controller
                    control={control}
                    name="productLineId"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={productLineOptions}
                        isSearchable={true}
                        value={
                          productLineOptions?.find(
                            (opt) => opt?.value === value
                          ) || null
                        }
                        onChange={(val: DropDownOption) => onChange(val?.value)}
                        menuPlacement="auto"
                        isDisabled={!isEditNonTangibleData}
                        isClearable={true}
                      />
                    )}
                  />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-10 col-10  me-5">
                  <label className="label mb-1">Misc Info</label>
                  <Form.Control
                    {...register("miscInfo")}
                    name="miscInfo"
                    disabled={isEditNonTangibleData === true ? false : true}
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                  <label className="label mb-1">Quickbook Item</label>
                  <Form.Control
                    {...register("quickBook")}
                    name="quickBook"
                    disabled={isEditNonTangibleData === true ? false : true}
                  />
                </div>
              </div>
              <Tabs defaultActiveKey="attributes" className="mt-5">
                <Tab
                  eventKey="attributes"
                  title={<div className="tab-inner-title">Attributes</div>}
                >
                  <ItemAttributes
                    attributes={attributes}
                    setAttributes={setAttributes}
                    categoryId={selectedCategory?.value}
                    isDisabled={!isEditNonTangibleData}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </FormProvider>
      </form>
      <SelectionModal
        modal="account"
        showModal={openInvAssetAccount}
        setShowModal={setOpenInvAssetAccount}
        data={paymentAccountsInv?.data}
        columns={ledgersColumns}
        setRowClicked={onInvAssetAccountSelection}
      />
      <ConfirmationModal
        title="Delete Item"
        message="Are you sure? It will be permanently deleted."
        showModal={showDelete}
        onClose={() => setShowDelete(false)}
        onConfirm={onItemDelete}
        cancelBtnTitle="No, Keep"
        confirmBtnTitle="Yes, Delete"
        type="confirmation-danger"
      />
      <CategoryModal
        showModal={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
        onConfirm={onSelectCategory}
        selectedCategory={selectedCategory?.value}
      />
    </>
  );
};

export default NonTangibleItem;
