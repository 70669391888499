import { Modal } from "react-bootstrap";
import close from "img/close.svg";
import { useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { useContext } from "react";
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { formatInput, notificationMessage } from "global/helpers";
import { useForm } from "react-hook-form";
import { createShipping } from "./SystemSetupServices/SystemSetupServices";

type Props = {
  onConfirm: (method) => void;
  onClose: () => void;
  showModal: boolean;
};

const ShippingMethodModal = (props: Props) => {
  const appDispatch = useContext(DispatchContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onCloseClicked = () => {
    props.onClose();
  };

  const addItem = useMutation(createShipping, {
    async onSuccess(data) {
      let notification = {
        variant: "success",
        msg: `Shipping method ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
      props.onConfirm({
        value: data.data.shippingMethodId,
        label: data.data.name,
      });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding shipping method"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (data) => {
    let cost = data.cost ? data.cost : 0;
    addItem.mutate({
      ...data,
      cost: parseFloat(cost.toString().replace(/,/g, "")),
      isActive: true,
    });
  };

  return (
    <Modal
      className="confirmation-modal"
      show={props.showModal}
      onHide={() => props.onClose()}
      centered
    >
      <div className="d-flex justify-content-between align-items-center mx-4 my-3">
        <div className="confirmation-modal-title">Shipping Method</div>
        <img
          src={close}
          alt=""
          className="confirmation-modal-close"
          onClick={onCloseClicked}
        />
      </div>
      <div className="mx-4">
        <Modal.Body className="px-0 py-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
              <label>Shipping Method</label>
              <input
                className="form-control"
                {...register("name", { required: true })}
              />
            </div>
            {errors["name"] && (
              <p className="text-danger">This field is required</p>
            )}
            <div className="form-group">
              <label>Shipping Cost</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className={`input-group-text dollar-icon bg-white`}>
                    $
                  </span>
                </div>
                <input
                  {...register("cost")}
                  className="form-control border-start-0"
                  onBlur={(e) => setValue("cost", formatInput(e.target.value))}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn btn-outline-primary ms-0"
                type="button"
                onClick={() => props.onClose()}
              >
                Cancel
              </button>
              <button className={`btn btn-primary`} type="submit">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ShippingMethodModal;
