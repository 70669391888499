import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { states, StateModel } from "components/data/States";
import "../CustomerDetails.scss";
import {
  CustomerInfoDTO,
  CustomerShippingInfo,
  InventoryHubDTO,
  Representative,
} from "../Customers.models";
import { RepresentativeModelDTO } from "pages/SalesMarketing/Representative/Representative.models";
import {
  Market,
  Region,
  ShippingMethod,
} from "pages/SystemSetup/SystemSetup.models";
import { DropDownOption } from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { zipCodeRegEx } from "global/regExp/regularExpressions";
import SectionTitle from "elements/SectionTitle";
import Lookup from "elements/Lookup";
import { HubInfo } from "pages/Invoices/Invoices.model";
import CreatableSelect from "react-select/creatable";
import CountrySelect from "components/CountrySelect/CountrySelect";

type Props = {
  id: number;
  customerDetails: CustomerInfoDTO;
  isDisabled: boolean;
  representatives: RepresentativeModelDTO[];
  markets: Market[];
  regions: Region[];
  shippingMethods: ShippingMethod[];
  hubsList: InventoryHubDTO[];
  showHubModal: Dispatch<SetStateAction<boolean>>;
};

const GeneralInfo = (props: Props) => {
  const {
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useFormContext<CustomerInfoDTO>();

  const hubOptions: DropDownOption[] = props.hubsList?.map((item) => {
    return { label: item.hubId, value: item.locationId };
  });

  const [btnChecked, setBtnChecked] = useState<boolean>(false);
  const [showRepModal, setShowRepModal] = useState<boolean>(false);
  // const [showHubModal, setShowHubModal] = useState<boolean>(false);
  const [selectedBillingCountry, setSelectedBillingCountry] = useState("");
  const [selectedShippingCountry, setSelectedShippingCountry] = useState("");

  const rep_columns = [
    {
      field: "repName",
      headerName: "Representative Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "workNumber",
      headerName: "Work No.",
    },
    {
      field: "mobileNumber",
      headerName: "Cell No.",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "isActive",
      headerName: "Status",
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
            ${status ? "Active" : "Disabled"}
        </div>
        `;
      },
    },
  ];

  const marketItems: DropDownOption[] = props.markets
    ?.filter((m) => m.isActive)
    .map((m) => {
      const opt: DropDownOption = { label: m.name, value: m.marketId };
      return opt;
    });
  const regionItems: DropDownOption[] = props.regions
    ?.filter((r) => r.isActive)
    .map((r) => {
      const opt: DropDownOption = { label: r.name, value: r.regionId };
      return opt;
    });
  const shippingItems: DropDownOption[] = props.shippingMethods
    ?.filter((s) => s.isActive)
    .map((s) => {
      const opt: DropDownOption = { label: s.name, value: s.shippingMethodId };
      return opt;
    });

  const statesOptions: StateModel[] = states;

  const onSelectedLookupItem = (id: number, field: any) => {
    if (id) {
      field.toString() === "repId"
        ? setSelectedRep(props.representatives.find((i) => i.repId === id))
        : setSelectedHub(props.hubsList.find((i) => i.locationId === id));
      setValue(field, id, { shouldValidate: true });
    }
  };

  const onSameInfoChecked = (e) => {
    setBtnChecked(e.target.checked);
    if (e.target.checked) {
      copyBillingInfo(true);
    }
  };

  const copyBillingInfo = (copy: boolean) => {
    if (copy) {
      const defaultShippingInfo: CustomerShippingInfo = getValues(
        "defaultShippingInfo"
      );
      defaultShippingInfo.address = {
        address: getValues("billingInfo.address.address"),
        address1: getValues("billingInfo.address.address1"),
        city: getValues("billingInfo.address.city"),
        state: getValues("billingInfo.address.state"),
        country: getValues("billingInfo.address.country"),
        zipCode: getValues("billingInfo.address.zipCode"),
        contact: getValues("billingInfo.address.contact"),
        mobileNumber: getValues("billingInfo.address.mobileNumber"),
        workNumber: getValues("billingInfo.address.workNumber"),
        workNumExt: getValues("billingInfo.address.workNumExt"),
        email: getValues("billingInfo.address.email"),
      };

      defaultShippingInfo.company = getValues("billingInfo.company");

      setValue("defaultShippingInfo", defaultShippingInfo, {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue(
        "defaultShippingInfo.address.mobileNumber",
        defaultShippingInfo.address.mobileNumber,
        { shouldValidate: true, shouldTouch: true }
      );
      setValue(
        "defaultShippingInfo.address.workNumber",
        defaultShippingInfo.address.workNumber,
        { shouldValidate: true, shouldTouch: true }
      );
      setValue(
        "defaultShippingInfo.address.state",
        defaultShippingInfo.address.state,
        { shouldValidate: true, shouldTouch: true }
      );
    }
  };

  const [selectedRep, setSelectedRep] = useState<Representative>(null);
  const [selectedHub, setSelectedHub] = useState<HubInfo>(null);

  useEffect(() => {
    if (props.id) {
      setSelectedRep(
        props.representatives?.find(
          (rep) => rep.repId === props.customerDetails?.repId
        )
      );
      setSelectedHub(
        props.hubsList?.find(
          (item) => item.locationId === props.customerDetails?.defaultHubId
        )
      );
    }
  }, [props.id, props.customerDetails, props.hubsList]);

  useEffect(() => {
    setValue("dirtyFlag", "1", { shouldDirty: true });
  }, [selectedRep, selectedHub]);

  const createLabel = () => <label>Add New</label>;

  const onCreateHub = () => {
    props.showHubModal(true);
  };

  return (
    <>
      <input {...register("dirtyFlag")} type="hidden" />
      <div className="section pb-4">
        <div className="row">
          <div className="col">
            <div className="form-group mb-4">
              <label>Date Signed</label>
              <Controller
                name="signedDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    selected={value}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    disabled={props.isDisabled}
                  />
                )}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group mb-4">
              <label>Date Opened</label>
              <Controller
                name="openedDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    selected={value}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    disabled={props.isDisabled}
                  />
                )}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group mb-4">
              <label>Date Closed</label>
              <Controller
                name="closedDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    selected={value}
                    className="form-control"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    scrollableYearDropdown
                    disabled={props.isDisabled}
                  />
                )}
              />
            </div>
          </div>
          <div className="col"></div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col">
            <div className="custom-field">
              <label>Rep ID</label>
              <Lookup
                onButtonClicked={() => setShowRepModal(true)}
                inputName="repId"
                initialData={props.representatives
                  ?.filter((rep) => !!rep.isActive)
                  .map((rep) => ({ ...rep, id: rep.repId, name: rep.repName }))}
                onSelection={setSelectedRep}
                inputValue={selectedRep?.repName}
                isDisabled={props.isDisabled}
              />
            </div>
          </div>
          <div className="col">
            <div className="custom-field">
              <label>Market</label>
              <Controller
                name="marketId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled={props.isDisabled}
                    isClearable
                    options={marketItems}
                    isSearchable={true}
                    menuPlacement="auto"
                    value={
                      marketItems?.find((opt) => opt?.value === value) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="col">
            <div className="custom-field">
              <label>Region</label>
              <Controller
                name="regionId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled={props.isDisabled}
                    isClearable
                    options={regionItems}
                    isSearchable={true}
                    menuPlacement="auto"
                    value={
                      regionItems?.find((opt) => opt?.value === value) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="col">
            <div className="custom-field">
              <label>
                Default Hub <span className="text-danger">*</span>
              </label>
              {/* <Lookup
                  onButtonClicked={() => setShowHubModal(true)}
                  inputName="defaultHubId"
                  initialData={props.hubsList?.filter(hub => !!hub.isActive).map(hub => ({ ...hub, id: hub.locationId, name: hub.hubId }))}
                  onSelection={setSelectedHub}
                  inputValue={selectedHub?.hubId}
                  isDisabled={props.isDisabled}
                  isRequired={true}
                /> */}
              <Controller
                name="defaultHubId"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CreatableSelect
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isDisabled={props.isDisabled}
                    options={hubOptions}
                    isClearable
                    isSearchable={true}
                    menuPlacement="auto"
                    value={
                      hubOptions?.find((opt) => opt?.value === value) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                    isValidNewOption={() => true}
                    formatCreateLabel={createLabel}
                    createOptionPosition="first"
                    onCreateOption={onCreateHub}
                    className={`${errors.defaultHubId && "required_field"}`}
                  />
                )}
              />
              {errors.defaultHubId && (
                <p className="text-danger">Default hub is required</p>
              )}
            </div>
          </div>
          <div className="col">
            <div className="form-group mb-4">
              <label>RTPOS ID</label>
              <input
                {...register("rtposId")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 mt-4">
        <div className="left-side-mid p-0">
          <div className="billing-shipping-sections">
            <SectionTitle editable={false} title="Billing Information" />
            <div className="form-group mt-4 mb-4">
              <label>
                Company Name <span className="text-danger">*</span>
              </label>
              <input
                {...register("billingInfo.company", {
                  required: { value: true, message: "Company is required" },
                })}
                className={`form-control ${
                  errors.billingInfo?.company && "required_field"
                }`}
                disabled={props.isDisabled}
                onBlur={() => {
                  copyBillingInfo(btnChecked);
                }}
              />
              {errors?.billingInfo?.company && (
                <p className="text-danger">
                  {errors?.billingInfo?.company?.message}
                </p>
              )}
            </div>
            <div className="form-group mb-4">
              <label>DBA</label>
              <input
                {...register("billingInfo.dba")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
            <div className="form-group mb-4">
              <label>Contact</label>
              <input
                {...register("billingInfo.address.contact")}
                className="form-control"
                disabled={props.isDisabled}
                onBlur={() => {
                  copyBillingInfo(btnChecked);
                }}
              />
            </div>
            <div className="form-group mb-4">
              <label>Mobile No.</label>
              <Controller
                name="billingInfo.address.mobileNumber"
                rules={{
                  pattern: {
                    value:
                      /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/,
                    message: "Invalid Phone Input",
                  },
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    country={"us"}
                    disabled={props.isDisabled}
                    onBlur={() => {
                      copyBillingInfo(btnChecked);
                    }}
                  />
                )}
              />
              {errors.billingInfo?.address?.mobileNumber && (
                <p className="text-danger">
                  {errors.billingInfo?.address?.mobileNumber?.message}
                </p>
              )}
            </div>

            <div className="form-group mb-4">
              <label>Email</label>
              <input
                {...register("billingInfo.address.email")}
                type="email"
                className={`form-control`}
                disabled={props.isDisabled}
              />
            </div>

            <div className="row mx-0">
              <div className="col px-0">
                <div className="form-group mb-4">
                  <label>Work No.</label>
                  <Controller
                    name="billingInfo.address.workNumber"
                    rules={{
                      pattern: {
                        value:
                          /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/,
                        message: "Invalid Phone Input",
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        value={value}
                        onChange={onChange}
                        country={"us"}
                        disabled={props.isDisabled}
                        onBlur={() => {
                          copyBillingInfo(btnChecked);
                        }}
                      />
                    )}
                  />
                  {errors.billingInfo?.address?.workNumber && (
                    <p className="text-danger">
                      {errors.billingInfo?.address?.workNumber?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <label>Ext.</label>
                  <input
                    {...register("billingInfo.address.workNumExt", {
                      pattern: {
                        value: /^[0-9]{1,4}\s*$/,
                        message: "Invalid extension",
                      },
                    })}
                    type="number"
                    className={`form-control ${
                      errors.billingInfo?.address?.workNumExt &&
                      "required_field"
                    }`}
                    disabled={props.isDisabled}
                    onBlur={() => {
                      copyBillingInfo(btnChecked);
                    }}
                  />
                  {errors.billingInfo?.address?.workNumExt && (
                    <p className="text-danger">
                      {errors.billingInfo?.address?.workNumExt?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-6 px-0">
                <div className="form-group mb-4">
                  <label>EIN/Tax ID</label>
                  <input
                    {...register("billingInfo.taxId")}
                    className="form-control"
                    disabled={props.isDisabled}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-4">
                  <label>Entity Date</label>
                  <Controller
                    name="billingInfo.entityDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={onChange}
                        selected={value ? value : null}
                        className="form-control"
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        disabled={props.isDisabled}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="section pt-4">
              <div className="form-group mb-4">
                <label>Address 1</label>
                <span className="text-danger"> *</span>
                <input
                  {...register("billingInfo.address.address", {
                    required: "This field is required",
                  })}
                  className="form-control"
                  disabled={props.isDisabled}
                  onBlur={() => {
                    copyBillingInfo(btnChecked);
                  }}
                />
                {errors?.billingInfo?.address?.address && (
                  <p className="text-danger">
                    {errors.billingInfo.address.address.message}
                  </p>
                )}
              </div>
              <div className="form-group mb-4">
                <label>Address 2</label> <span className="text-danger"> *</span>
                <input
                  {...register("billingInfo.address.address1", {
                    required: "This field is required",
                  })}
                  className="form-control"
                  disabled={props.isDisabled}
                  onBlur={() => {
                    copyBillingInfo(btnChecked);
                  }}
                />{" "}
                {errors?.billingInfo?.address?.address1 && (
                  <p className="text-danger">
                    {errors.billingInfo.address.address1.message}
                  </p>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <div
                    className="form-group mb-4"
                    style={{ marginLeft: "-10px" }}
                  >
                    <label>City</label> <span className="text-danger"> *</span>
                    <input
                      {...register("billingInfo.address.city", {
                        required: "This field is required",
                      })}
                      className="form-control"
                      disabled={props.isDisabled}
                      onBlur={() => {
                        copyBillingInfo(btnChecked);
                      }}
                    />{" "}
                    {errors?.billingInfo?.address?.city && (
                      <p className="text-danger">
                        {errors.billingInfo.address.city.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group mb-4">
                    <label>
                      State{" "}
                      {selectedBillingCountry === "US" && (
                        <span className="text-danger">*</span>
                      )}
                    </label>
                    <Controller
                      name="billingInfo.address.state"
                      control={control}
                      rules={{ required: selectedBillingCountry === "US" }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          isDisabled={
                            selectedBillingCountry !== "US" || props.isDisabled
                          }
                          options={statesOptions}
                          isClearable
                          isSearchable={true}
                          menuPlacement="auto"
                          className={
                            errors["billingInfo.address.state"]
                              ? "required_field"
                              : ""
                          }
                          value={
                            selectedBillingCountry === "US"
                              ? statesOptions?.find(
                                  (opt) => opt?.value === value
                                ) || null
                              : null
                          }
                          onChange={(val: any) => {
                            onChange(val?.value);
                            copyBillingInfo(btnChecked);
                          }}
                        />
                      )}
                    />
                    {selectedBillingCountry === "US" &&
                      errors.billingInfo?.address?.state && (
                        <p className="text-danger">This field is required</p>
                      )}
                  </div>
                </div>

                <div className="row">
                  <div className="col" style={{ marginLeft: "-10px" }}>
                    <div className="form-group mb-4">
                      <div style={{ marginLeft: "-10px" }}>
                        <label>Country</label>{" "}
                        <span className="text-danger"> *</span>
                        <Controller
                          name="billingInfo.address.country"
                          control={control}
                          rules={{ required: "This field is required" }}
                          defaultValue=""
                          render={({ field }) => {
                            setSelectedBillingCountry(field.value);
                            return (
                              <CountrySelect
                                isDisabled={props.isDisabled}
                                value={field.value}
                                onChange={(countryCode) => {
                                  field.onChange(countryCode);
                                  setSelectedBillingCountry(countryCode);
                                  copyBillingInfo(btnChecked);
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                      {errors?.billingInfo?.address?.country && (
                        <p className="text-danger">
                          {errors.billingInfo.address.country.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col">
                    <div className="form-group mb-4">
                      <label>Zip Code</label>{" "}
                      <span className="text-danger"> *</span>
                      <Form.Control
                        {...register("billingInfo.address.zipCode", {
                          required: "This field is required",
                          pattern: {
                            value: zipCodeRegEx,
                            message: "Invalid Zip Code",
                          },
                        })}
                        className={`form-control ${
                          errors.billingInfo?.address?.zipCode &&
                          "required_field"
                        }`}
                        disabled={props.isDisabled}
                        onBlur={() => {
                          copyBillingInfo(btnChecked);
                        }}
                      />
                      {errors.billingInfo?.address?.zipCode && (
                        <p className="text-danger">
                          {errors.billingInfo?.address?.zipCode.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side-mid p-0">
          <div className="billing-shipping-sections ms-auto">
            <div className={`section-title d-flex justify-content-between`}>
              <span>Shipping Information</span>
              <div className="d-flex justify-content-between">
                <Form.Label className="me-2 pt-2 fw-normal text-secondary">
                  Same as billing
                </Form.Label>
                <Form.Check
                  name="asBillingInfo"
                  type="checkbox"
                  className="pt-2"
                  disabled={props.isDisabled}
                  onChange={onSameInfoChecked}
                  checked={btnChecked}
                />
              </div>
            </div>
            <div className="form-group mt-4 mb-4">
              <label>
                Company Name <span className="text-danger">*</span>
              </label>
              <input
                {...register("defaultShippingInfo.company", {
                  required: { value: true, message: "Company is required" },
                })}
                className={`form-control ${
                  errors.defaultShippingInfo?.company && "required_field"
                }`}
                disabled={props.isDisabled || btnChecked}
              />
              {errors?.defaultShippingInfo?.company && (
                <p className="text-danger">
                  {errors?.defaultShippingInfo?.company?.message}
                </p>
              )}
            </div>
            <div className="form-group mt-4 mb-4">
              <label>Ship Method</label>
              <Controller
                name="shippingMethodId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled={props.isDisabled}
                    options={shippingItems}
                    isClearable
                    isSearchable={true}
                    menuPlacement="auto"
                    value={
                      shippingItems?.find((opt) => opt?.value === value) || null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                  />
                )}
              />
            </div>
            <div className="form-group mt-4 mb-4">
              <label>Contact</label>
              <input
                {...register("defaultShippingInfo.address.contact")}
                className="form-control"
                disabled={props.isDisabled || btnChecked}
              />
            </div>
            <div className="form-group mb-4">
              <label>Mobile No.</label>
              <Controller
                name="defaultShippingInfo.address.mobileNumber"
                rules={{
                  pattern: {
                    value:
                      /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/,
                    message: "Invalid Phone Input",
                  },
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    country={"us"}
                    disabled={props.isDisabled || btnChecked}
                  />
                )}
              />
              {errors.defaultShippingInfo?.address?.mobileNumber && (
                <p className="text-danger">
                  {errors.defaultShippingInfo?.address?.mobileNumber.message}
                </p>
              )}
            </div>
            <div className="row mx-0">
              <div className="col px-0">
                <div className="form-group mb-4">
                  <label>Work No.</label>
                  <Controller
                    name="defaultShippingInfo.address.workNumber"
                    rules={{
                      pattern: {
                        value:
                          /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/,
                        message: "Invalid Phone Input",
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        value={value}
                        onChange={onChange}
                        country={"us"}
                        disabled={props.isDisabled || btnChecked}
                      />
                    )}
                  />
                  {errors.defaultShippingInfo?.address?.workNumber && (
                    <p className="text-danger">
                      {errors.defaultShippingInfo?.address?.workNumber?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-4">
                  <label>Ext.</label>
                  <input
                    {...register("defaultShippingInfo.address.workNumExt", {
                      pattern: {
                        value: /^[0-9]{1,4}\s*$/,
                        message: "Invalid extension",
                      },
                    })}
                    type="number"
                    className={`form-control ${
                      errors.defaultShippingInfo?.address?.workNumExt &&
                      "required_field"
                    }`}
                    disabled={props.isDisabled || btnChecked}
                  />
                  {errors.defaultShippingInfo?.address?.workNumExt && (
                    <p className="text-danger">
                      {errors.defaultShippingInfo?.address?.workNumExt?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group mb-4">
              <label>FedEx Account</label>
              <input
                {...register("fedExAccount")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
            <div className="form-group mb-4">
              <label>UPS Account</label>
              <input
                {...register("upsAccount")}
                className="form-control"
                disabled={props.isDisabled}
              />
            </div>
            <div className="section pt-4">
              <div className="form-group mb-4">
                <label>Address 1</label> <span className="text-danger"> *</span>
                <input
                  {...register("defaultShippingInfo.address.address", {
                    required: "This field is required",
                  })}
                  className="form-control"
                  disabled={props.isDisabled || btnChecked}
                />{" "}
                {errors?.defaultShippingInfo?.address?.address && (
                  <p className="text-danger">
                    {errors.defaultShippingInfo.address.address.message}
                  </p>
                )}
              </div>
              <div className="form-group mb-4">
                <label>Address 2</label> <span className="text-danger"> *</span>
                <input
                  {...register("defaultShippingInfo.address.address1", {
                    required: "This field is required",
                  })}
                  className="form-control"
                  disabled={props.isDisabled || btnChecked}
                />{" "}
                {errors?.defaultShippingInfo?.address?.address1 && (
                  <p className="text-danger">
                    {errors.defaultShippingInfo.address.address1.message}
                  </p>
                )}
              </div>
              <div className="row">
                <div className="col" style={{ marginLeft: "-10px" }}>
                  <div className="form-group mb-4">
                    <label>City</label> <span className="text-danger"> *</span>
                    <input
                      {...register("defaultShippingInfo.address.city", {
                        required: "This field is required",
                      })}
                      className="form-control"
                      disabled={props.isDisabled || btnChecked}
                    />{" "}
                    {errors?.defaultShippingInfo?.address?.city && (
                      <p className="text-danger">
                        {errors.defaultShippingInfo.address.city.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group mb-4">
                    <label>
                      State{" "}
                      {selectedShippingCountry === "US" && (
                        <span className="text-danger">*</span>
                      )}
                    </label>
                    <Controller
                      name="defaultShippingInfo.address.state"
                      control={control}
                      rules={{ required: selectedShippingCountry === "US" }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          isDisabled={
                            props.isDisabled ||
                            btnChecked ||
                            selectedShippingCountry !== "US"
                          }
                          options={statesOptions}
                          isSearchable={true}
                          menuPlacement="auto"
                          isClearable
                          className={
                            errors["defaultShippingInfo.address.state"]
                              ? "required_field"
                              : ""
                          }
                          value={
                            selectedShippingCountry === "US"
                              ? statesOptions?.find(
                                  (opt) => opt?.value === value
                                ) || null
                              : null
                          }
                          onChange={(val: any) => onChange(val?.value)}
                        />
                      )}
                    />
                    {errors.defaultShippingInfo?.address?.state &&
                      selectedShippingCountry === "US" && (
                        <p className="text-danger">This field is required</p>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col" style={{ marginLeft: "-10px" }}>
                  <div className="form-group mb-4">
                    <label>Country</label>{" "}
                    <span className="text-danger"> *</span>
                    <Controller
                      name="defaultShippingInfo.address.country"
                      control={control}
                      rules={{ required: "This field is required" }}
                      defaultValue=""
                      render={({ field }) => {
                        setSelectedShippingCountry(field.value);
                        return (
                          <CountrySelect
                            isDisabled={props.isDisabled || btnChecked}
                            value={field.value}
                            onChange={(countryCode) => {
                              field.onChange(countryCode);
                              setSelectedShippingCountry(countryCode);
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.defaultShippingInfo?.address?.country && (
                      <p className="text-danger">
                        {errors.defaultShippingInfo.address.country.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group mb-4">
                    <label>Zip Code</label>
                    <span className="text-danger"> *</span>
                    <Form.Control
                      {...register("defaultShippingInfo.address.zipCode", {
                        pattern: {
                          value: zipCodeRegEx,
                          message: "Invalid Zip Code",
                        },
                      })}
                      className={`form-control ${
                        errors.defaultShippingInfo?.address?.zipCode &&
                        "required_field"
                      }`}
                      disabled={props.isDisabled || btnChecked}
                    />
                    {errors.defaultShippingInfo?.address?.zipCode && (
                      <p className="text-danger">
                        {errors.defaultShippingInfo?.address?.zipCode.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <SelectionModal
        modal="Hub"
        showModal={showHubModal}
        setShowModal={setShowHubModal}
        data={props.hubsList}
        columns={hub_columns}
        setRowClicked={(item: InventoryHubDTO) => { onSelectedLookupItem(item.locationId, "defaultHubId"); setShowHubModal(false); }}
      /> */}
      <SelectionModal
        modal="Representative"
        showModal={showRepModal}
        setShowModal={setShowRepModal}
        data={props.representatives}
        columns={rep_columns}
        setRowClicked={(item: RepresentativeModelDTO) => {
          onSelectedLookupItem(item.repId, "repId");
          setShowRepModal(false);
        }}
      />
    </>
  );
};

export default GeneralInfo;
