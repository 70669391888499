import { Modal } from "react-bootstrap";
import close from "img/close.svg";
import "./ConfirmationModal.scss";

type Props = {
  title: string;
  message: any;
  cancelBtnTitle: string;
  confirmBtnTitle: string;
  onConfirm: () => void;
  onClose: () => void;
  onCancel?: () => void;
  showModal: boolean;
  type: string;
};

const ConfirmationModal = (props: Props) => {
  const onCloseClicked = () => {
    props.onClose();
  };

  const onCancelClicked = () => {
    props.onCancel();
  };

  return (
    <Modal
      className={"confirmation-modal " + props.type}
      show={props.showModal}
      onHide={() => props.onClose()}
      centered
    >
      <div className="d-flex justify-content-between align-items-center mx-4 my-3">
        <div className="confirmation-modal-title">{props.title}</div>
        <img
          src={close}
          alt=""
          className="confirmation-modal-close"
          onClick={() =>
            props.onCancel ? onCancelClicked() : onCloseClicked()
          }
        />
      </div>
      <div className="mx-4">
        <Modal.Body className="px-0 py-0">
          <div>{props.message}</div>
          <div className="d-flex justify-content-between mt-3">
            <button
              className="btn btn-outline-primary ms-0"
              onClick={() => props.onClose()}
            >
              {props.cancelBtnTitle}
            </button>
            <button
              className={`btn ${
                props.type === "confirmation-danger"
                  ? "btn-danger"
                  : props.type === "confirmation-primary"
                  ? "btn-primary"
                  : props.type === "confirmation-saving"
                  ? "btn-primary"
                  : ""
              }`}
              onClick={() => props.onConfirm()}
            >
              {props.confirmBtnTitle}
            </button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
