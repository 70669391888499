import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import addIcon from "img/add-icon.svg";
import { useMutation } from "react-query";
import * as AppUrls from "constants/AppUrls";
import { checkIfAllowed, dateFormmaterNoTime } from "global/helpers";
import StateContext from "context/StateContext";
import { GridColumn } from "elements/AG.models";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { fixedAssetsAPI } from "./AccountingServices";
import * as colDefs from "../../constants/colDefs";

const FixedAssets = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const dataMutation = useMutation("data", fixedAssetsAPI);
  const { isLoading } = dataMutation;
  let history = useHistory();

  const columns: GridColumn[] = [
    {
      field: "assetName",
      headerName: "Asset Name",
      maxWidth: colDefs.mWidth,
    },
    {
      field: "description",
      headerName: "Desscription",
      minWidth: colDefs.textWidth,
    },
    {
      field: "purchaseDate",
      headerName: "Purchase Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data?.purchaseDate);
      },
    },
    {
      field: "relatedCOAAccount",
      headerName: "Related COA Account",
    },
    {
      field: "price",
      headerName: "Price",

      maxWidth: colDefs.smWidth,
    },
    {
      field: "depreciationPercent",
      headerName: "Depreciation %",
    },
    {
      field: "remainingValue",
      headerName: "Remaning Value",
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const exportToExcel = () => {
    dataMutation.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Fixed Assets</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || isLoading || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <>
              <Link to={AppUrls.add_fixed_assets} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> Add Fixed Asset
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            fetchMethod={dataMutation}
            data={dataGrid}
            columns={columns}
            filters={filter}
            refreshCounter={refreshCounter}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setDataGrid={setDataGrid}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="FIxed Assets List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default FixedAssets;
