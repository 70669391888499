import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import SectionTitle from "elements/SectionTitle";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import Delete from "img/delete.svg";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createReason,
  deleteReason,
  editReason,
  getReasons,
} from "./Services/adminServices";
import AgGrid from "elements/AgGrid";

const ReasonsList = ({ match }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();

  const { data } = useQuery("reasons", getReasons);

  const frmMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    handleSubmit,
    reset,
    setValue,
    register,
    formState: { errors },
  } = frmMethods;

  const columns = [
    {
      field: "reason",
      headerName: "Reason",
    },
    {
      field: "action",
      headerName: "Action",
      floatingFilter: false,
      filter: false,
      sortable: false,
      hide: !checkIfAllowed(appState.allowedUrls, "delete", match.path),
      onCellClicked: (params) => {
        onDeleteItemClicked(params.data);
      },
      cellRenderer: () => {
        return `
            <div class="btn text-danger">
              <img src=${Delete} alt="Delete Icon" />
              Delete 
            </div>
      `;
      },
    },
  ];

  const addReason = useMutation(createReason, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Reason ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("reasons");
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding reason"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateReason = useMutation(editReason, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Reason ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("reasons");
      setSelectedId(null);
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing reason"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeReason = useMutation(deleteReason, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Reason ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("reasons");
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing reason"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onRowDoubleClicked = (e) => {
    setSelectedId(e.id);
    setValue("name", e.reason);
  };

  const handleDeleteItem = () => {
    removeReason.mutate(selectedId);
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const onDeleteItemClicked = (data) => {
    setSelectedId(data.id);
    setShowDeleteModal(true);
  };

  const onSubmit = (values) => {
    const data = {
      id: selectedId ? selectedId : 0,
      reason: values.name,
    };
    if (!selectedId) {
      addReason.mutate(data);
    } else {
      updateReason.mutate(data);
    }
  };

  const resetForm = () => {
    setSelectedId(null);
    reset();
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        addReason.isLoading || updateReason.isLoading || removeReason.isLoading,
    });
  }, [
    addReason.isLoading,
    updateReason.isLoading,
    removeReason.isLoading,
    appDispatch,
  ]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <p>RMA return reasons</p>
          <div>
            {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn}>
                        <img src={exportIcon} className="me-2" alt="" /> 
                        Export to Excel
                    </button> */}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="row h-100">
            <div className="left-side-mid grid-container">
              <div className="grid">
                <AgGrid
                  columns={columns}
                  data={data}
                  setrowClicked={onRowDoubleClicked}
                  exportToExcel={exportToExcelClicked}
                  setExportToExcelClicked={setExportToExcelClicked}
                  setIsLoadingGridExport={setIsLoadingGridExport}
                />
              </div>
            </div>
            {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
              checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
              <div className="right-side-mid">
                <SectionTitle
                  active={!!selectedId}
                  editable={false}
                  title="Reason"
                />
                <div className="">
                  <FormProvider {...frmMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label>{selectedId ? "New Reason" : "Reason"}</label>
                        <input
                          {...register("name", { required: true })}
                          type={"text"}
                          className={`form-control ${
                            errors["name"] && "required_field"
                          }`}
                        />
                        {errors.name && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          type="button"
                          className="btn btn-outline-primary custom-outline custom-outline"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button type="submit" className="btn btn-primary ">
                          {selectedId ? "Save Changes" : "Add Reason"}
                        </button>
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Reason"
        message="Are you sure you want to delete the item?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteItem}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
    </>
  );
};

export default ReasonsList;
