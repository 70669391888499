import { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Close from 'img/close.svg';
import { useContext, useEffect } from 'react';
import DispatchContext from 'context/DispatchContext';
import ListSelectionModal from 'components/SelectionListModal/ListSelectionModal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import SearchIcon from "img/search-group.png";
import { ReactComponent as DeleteIcon } from 'img/delete.svg';
import { splitCategories } from './AccountingServices';
import { cloneObj, notificationMessage } from 'global/helpers';
import { useMutation } from 'react-query';

type Props = {
    showModal: boolean,
    onExit: () => void,
    category
}

const SplitModal = (props: Props) => {

    const appDispatch = useContext(DispatchContext);
    const [subCategories, setSubCategories] = useState([])
    const [showSelectionModal, setShowSelectionModal] = useState(false)
    const indexRef = useRef(null)

    const splitMutation = useMutation(splitCategories, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Categories split successfully`
            };
            appDispatch({ type: "notification", value: notification });
            props.onExit()
        },
        onError(error) {
            let notification = { variant: "danger", msg: notificationMessage(error, "problem splitting categories") };
            appDispatch({ type: "notification", value: notification });
        }
    });

    useEffect(() => {
        if (props.category)
            setSubCategories([{ name: "", ledgers: [], id: 1 }, { name: "", ledgers: [], id: 2 }])
    }, [props.category])

    const onLedgerSelection = (e) => {
        setSubCategories(prev => prev.map((sub) => {
            if (sub.id === indexRef.current) {
                return {
                    ...sub,
                    ledgers: e
                }
            }
            else return sub;
        }))
    }

    const onNameChange = (e, id) => {
        setSubCategories(prev => prev.map((sub, i) => {
            if (sub.id === id) {
                return {
                    ...sub,
                    name: e.target.value
                }
            }
            else return sub;
        }))
    }

    const getRemainingLedgers = () => {
        let remaining = []
        let selected = []
        for (let sub of subCategories)
            if (sub.ledgers.length > 0)
                selected = [...selected, ...sub.ledgers]
        if (props.category?.ledgers.length > 0)
            for (let ledger of props.category?.ledgers)
                if (!selected.find(element => element.id === ledger.ledgerId))
                    remaining.push(ledger)
        return remaining.map(ledger => ({ id: ledger.ledgerId, title: ledger.accountName }))
    }

    const getSelectValues = () => {
        if (indexRef.current !== null) {
            return (subCategories.find(e => e.id === indexRef.current)?.ledgers.map(ledger => (ledger.id)))
        }
        return []
    }

    const getSelectionData = () => {
        if (indexRef.current !== null)
            return ([...getRemainingLedgers(), ...subCategories.find(e => e.id === indexRef.current)?.ledgers ?? []])
        return []
    }

    const addSub = () => {
        setSubCategories(prev => [...prev, { name: "", ledgers: [], id: Math.max(...prev.map(o => o.id)) + 1 }])
    }

    const removeSub = (id) => {
        setSubCategories(prev => prev.filter((element) => element.id !== id))
    }

    const onSubmit = () => {
        if (getRemainingLedgers().length > 0) {
            let notification = { variant: "danger", msg: "Please assign all remaining ledgers" };
            appDispatch({ type: "notification", value: notification });
        } else if (subCategories.find(sub => sub.name.trim() === '')) {
            let notification = { variant: "danger", msg: "Please add a name to the subcategories" };
            appDispatch({ type: "notification", value: notification });
        } else {
            let subs = cloneObj(subCategories)
            for (let sub of subs)
                sub.ledgers = sub.ledgers.map(ledger => (ledger.id))
            let data = {
                categoryId: props.category?.categoryId,
                subCategories: subs
            }
            splitMutation.mutate(data)
        }
    }

    return (
        <>
            <Modal show={props.showModal} centered size="lg" dialogClassName="modal-50w">
                <Modal.Header>
                    <Modal.Title>
                        Split Sub Category
                    </Modal.Title>
                    <img
                        src={Close}
                        alt="close icon"
                        className=""
                        role="button"
                        onClick={props.onExit}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-4'>
                        <div className='col-sm-11 offset-sm-1'>
                            {subCategories.map((sub, index) => {
                                return (
                                    <div className='row mb-4' key={sub.id}>
                                        <div className='col-sm-2'>
                                            <div className='form-group'>
                                                <label className='mb-2'>Category Name</label>
                                                <input value={sub.name} className="form-control" onChange={(e) => onNameChange(e, sub.id)} style={{ height: "38px" }} />
                                            </div>
                                        </div>
                                        <div className='col-sm-8'>
                                            <div className='form-group'>
                                                <label className='mb-2'>Ledgers</label>
                                                <InputGroup>
                                                    <div className={`selected-container`}>
                                                        {
                                                            sub.ledgers.map(ledger => {
                                                                return <span key={ledger.id} className="selected-item">{ledger.title}</span>
                                                            })
                                                        }
                                                    </div>
                                                    <Button variant="primary"
                                                        onClick={() => { setShowSelectionModal(true); indexRef.current = sub.id }} className="input-group-prepend">
                                                        <img src={SearchIcon} alt="" />
                                                    </Button>
                                                </InputGroup>
                                            </div>
                                        </div>
                                        {index !== 0 && subCategories.length > 2 &&
                                            <div className='col-sm-1'>
                                                <div className='d-flex align-items-end h-100'>
                                                    <button className='btn btn-outline-primary no-border' onClick={() => removeSub(sub.id)}>
                                                        <DeleteIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className='col-sm-12 offset-sm-1'>
                            <button className='btn btn-outline-primary ms-0' onClick={addSub}>Add</button>
                        </div>
                        <div className='d-flex align-items-end flex-column'>
                            <div>Total Ledgers: {props.category?.ledgers?.length}</div>
                            <div>
                                Assigned Ledgers: {props.category?.ledgers?.length - getRemainingLedgers().length}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={props.onExit}
                    >
                        Cancel
                    </button>
                    <button className='btn btn-primary'
                        onClick={onSubmit}
                    >
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
            <ListSelectionModal
                showModal={showSelectionModal}
                title="Ledgers"
                data={getSelectionData()}
                selectedValues={getSelectValues()}
                onSelectData={onLedgerSelection}
                onExit={() => setShowSelectionModal(false)}
            />
        </>
    );
};

export default SplitModal;