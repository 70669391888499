import { useContext, useEffect, useState } from "react";
import * as AppUrls from "constants/AppUrls";
import addIcon from "img/add-icon.svg";
import {
  checkIfAllowed,
  dateFormmaterNoTime,
  getCurrencyByCode,
} from "global/helpers";
import StateContext from "context/StateContext";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { GridColumns } from "components/Common.models";
import { getReceiveInvoices } from "./InvoicesServices/InvoicesServices";
import { InvoiceStateArray } from "./InvoiceEnums";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { getBaseCurrency } from "components/currency";

const ReceiveInvoiceList = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedOrigin, setSelectedOrigin] = useState<number>(0);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "Bill No.",
    },
    {
      field: "immediate",
      headerName: "Is Immediate",
      valueGetter: (params) => {
        return params.data.immediate ? "Yes" : "No";
      },
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => {
        return InvoiceStateArray[params.data.status - 1];
      },
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.orderDate);
      },
      cellRenderer: (params) =>
        params.value ? dateFormmaterNoTime(params.value) : "",
    },
    {
      field: "paidAmount",
      headerName: "Remaining Amount",
      valueGetter: (params) => {
        return `${currencySymbol}${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.paidDate);
      },
      cellRenderer: (params) =>
        params.value ? dateFormmaterNoTime(params.value) : "",
    },
    {
      field: "total",
      headerName: "Total",
      valueGetter: (params) => {
        return `${currencySymbol} ${params.data?.total?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "vendorNumber",
      headerName: "Vendor",
    },
  ];

  const receiveInvoices = useMutation(getReceiveInvoices);

  const onRowDoubleClicked = (e) => {
    let url = `${AppUrls.receive_invoices_list}/view/${e.receiveInvoiceId}`;
    history.push(url);
  };

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };
  const [filters, setFilters] = useState({ immediate: false });
  useEffect(() => {
    if (selectedOrigin)
      setFilters((filters) => ({
        ...filters,
        immediate: selectedOrigin === 1,
      }));
    else setFilters((filters) => ({ ...filters, immediate: false }));
  }, [selectedOrigin]);

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    receiveInvoices.mutate(filters, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (receiveInvoices.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, receiveInvoices.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onRadioChange = (e) => {
    setSelectedOrigin(parseInt(e.target.value));
  };

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Bills list</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link
              to={AppUrls.create_receive_invoice}
              className="btn btn-primary"
            >
              <img src={addIcon} className="me-2" alt="" /> New Bill
            </Link>
          )}
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link
              to={AppUrls.create_receive_invoice_immediate}
              className="btn btn-primary"
            >
              <img src={addIcon} className="me-2" alt="" /> New Immediate Bill
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters d-flex justify-content-between">
            <div className="form-group">
              <label className="checkbox-inline ms-3">
                <input
                  id="systemOption1"
                  type="radio"
                  value={0}
                  onChange={onRadioChange}
                  checked={selectedOrigin === 0}
                />
                <label className={"text-black ms-2"} htmlFor="systemOption1">
                  All
                </label>
              </label>
              <label className="checkbox-inline ms-3">
                <input
                  id="systemOption2"
                  type="radio"
                  value={1}
                  onChange={onRadioChange}
                  checked={selectedOrigin === 1}
                />
                <label className={"text-black ms-2"} htmlFor="systemOption2">
                  Immediate
                </label>
              </label>
            </div>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              fetchMethod={receiveInvoices}
              data={dataGrid}
              setDataGrid={setDataGrid}
              filters={filters}
              setrowClicked={onRowDoubleClicked}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              refreshCounter={refreshCounter}
              fileName="Receive Invoice List Report"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveInvoiceList;
