import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import {
  ItemBin,
  ItemCategory,
  ItemClass,
  ItemColor,
  ItemCondition,
  ItemManufacturer,
  ItemModel,
  ItemProductLine,
  ItemShelve,
  ItemSubClass,
} from "./InventorySetup.model";

const productLineUrl: string = "product-lines";
const classUrl: string = "item-classes";
const shelveUrl: string = "shelves";
const subClassUrl: string = "subclasses";
const categoryUrl: string = "item-categories";
const binUrl: string = "item-bins";
const itemModelUrl: string = "item-models";
const itemConditionUrl: string = "item-conditions";
const manufacturersUrl: string = "manufacturers";
const itemColorsUrl: string = "item-colors";

// Product line
export const getProductLines = async (params) => {
  const response = await api.get<QueryResult<ItemProductLine>>(
    `${productLineUrl}?PageSize=${params.PageSize}&PageStart=${isNaN(params.PageStart) ? 0 : params.PageStart}&${params.params}`
  );
  return response.data;
};

export const createProductLine = async (data: ItemProductLine) => {
  const response = await api.post<ItemProductLine>(productLineUrl, data);
  return response.data;
};
export const editProductLine = async (data: ItemProductLine) => {
  const response = await api.put<ItemProductLine>(
    `${productLineUrl}/${data.itemProductLineId}`,
    data
  );
  return response.data;
};
export const deleteProductLine = async (id: number) => {
  const response = await api.delete<boolean>(`${productLineUrl}/${id}`);
  return response.data;
};

// Item Class
export const getItemClasses = async (params) => {
  const response = await api.get<ItemClass[]>(
    `${classUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createItemClass = async (data: ItemClass) => {
  const response = await api.post<ItemClass>(classUrl, data);
  return response.data;
};
export const editItemClass = async (data: ItemClass) => {
  const response = await api.put<ItemClass>(
    `${classUrl}/${data.itemClassId}`,
    data
  );
  return response.data;
};
export const deleteItemClass = async (id: number) => {
  const response = await api.delete<boolean>(`${classUrl}/${id}`);
  return response.data;
};

// Shelve
export const getShelves = async (params) => {
  const response = await api.get<ItemShelve[]>(
    `${shelveUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createShelve = async (data: ItemShelve) => {
  const response = await api.post<ItemShelve>(shelveUrl, data);
  return response.data;
};
export const editShelve = async (data: ItemShelve) => {
  const response = await api.put<ItemShelve>(
    `${shelveUrl}/${data.itemShelveId}`,
    data
  );
  return response.data;
};
export const deleteShelve = async (id: number) => {
  const response = await api.delete<boolean>(`${shelveUrl}/${id}`);
  return response.data;
};

// SubClass
export const getItemSubClasses = async (params) => {
  const response = await api.get<ItemSubClass[]>(
    `${subClassUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createSubClass = async (data: ItemSubClass) => {
  const response = await api.post<ItemSubClass>(subClassUrl, data);
  return response.data;
};
export const editSubClass = async (data: ItemSubClass) => {
  const response = await api.put<ItemSubClass>(
    `${subClassUrl}/${data.itemSubClassId}`,
    data
  );
  return response.data;
};
export const deleteSubClass = async (id: number) => {
  const response = await api.delete<boolean>(`${subClassUrl}/${id}`);
  return response.data;
};

// Category
export const getCategories = async () => {
  const response = await api.get<QueryResult<ItemCategory>>(categoryUrl);
  return response.data;
};
export const createCategory = async (data: ItemCategory) => {
  const response = await api.post<ItemCategory>(categoryUrl, data);
  return response.data;
};
export const editCategory = async (data: ItemCategory) => {
  const response = await api.put<ItemCategory>(
    `${categoryUrl}/${data.itemCategoryId}`,
    data
  );
  return response.data;
};
export const deleteCategory = async (id: number) => {
  const response = await api.delete<boolean>(`${categoryUrl}/${id}`);
  return response.data;
};

// Bins
export const getBins = async (params) => {
  const response = await api.get<ItemBin[]>(
    `${binUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createBin = async (data: ItemBin) => {
  const response = await api.post<ItemBin>(binUrl, data);
  return response.data;
};
export const editBin = async (data: ItemBin) => {
  const response = await api.put<ItemBin>(`${binUrl}/${data.itemBinId}`, data);
  return response.data;
};
export const deleteBin = async (id: number) => {
  const response = await api.delete<boolean>(`${binUrl}/${id}`);
  return response.data;
};

// Item Models
export const getItemModels = async (params) => {
  const response = await api.get<ItemModel[]>(
    `${itemModelUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createItemModel = async (data: ItemModel) => {
  const response = await api.post<ItemModel>(itemModelUrl, data);
  return response.data;
};
export const editItemModel = async (data: ItemModel) => {
  const response = await api.put<ItemModel>(
    `${itemModelUrl}/${data.itemModelId}`,
    data
  );
  return response.data;
};
export const deleteItemModel = async (id: number) => {
  const response = await api.delete<boolean>(`${itemModelUrl}/${id}`);
  return response.data;
};

// Item Condition
export const getItemConditions = async (params) => {
  const response = await api.get<ItemCondition[]>(
    `${itemConditionUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createItemCondition = async (data: ItemCondition) => {
  const response = await api.post<ItemCondition>(itemConditionUrl, data);
  return response.data;
};
export const editItemCondition = async (data: ItemCondition) => {
  const response = await api.put<ItemCondition>(
    `${itemConditionUrl}/${data.itemConditionId}`,
    data
  );
  return response.data;
};
export const deleteItemCondition = async (id: number) => {
  const response = await api.delete<boolean>(`${itemConditionUrl}/${id}`);
  return response.data;
};

// Item Manufacturers
export const getManufacturers = async (params) => {
  const response = await api.get<ItemManufacturer[]>(
    `${manufacturersUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createManufacturer = async (data: ItemManufacturer) => {
  const response = await api.post<ItemManufacturer>(manufacturersUrl, data);
  return response.data;
};
export const editManufacturer = async (data: ItemManufacturer) => {
  const response = await api.put<ItemManufacturer>(
    `${manufacturersUrl}/${data.manufacturerId}`,
    data
  );
  return response.data;
};
export const uploadImage = async (data) => {
  return await api.post(
    `manufacturers/${data.manufacturerId}/image`,
    data.file
  );
};
export const deleteManufacturer = async (id: number) => {
  const response = await api.delete<boolean>(`${manufacturersUrl}/${id}`);
  return response.data;
};

// Item Colors
export const getItemColors = async (params) => {
  const response = await api.get<ItemColor[]>(
    `${itemColorsUrl}${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
export const createItemColor = async (data: ItemColor) => {
  const response = await api.post<ItemColor>(itemColorsUrl, data);
  return response.data;
};
export const editItemColor = async (data: ItemColor) => {
  const response = await api.put<ItemColor>(
    `${itemColorsUrl}/${data.itemColorId}`,
    data
  );
  return response.data;
};
export const deleteItemColor = async (id: number) => {
  const response = await api.delete<boolean>(`${itemColorsUrl}/${id}`);
  return response.data;
};

export const getItemCategories = async () => {
  const response = await api.get(`inventory/items/item-category`);
  return response.data;
};

export const postItemCategory = async (data) => {
  const response = await api.post(`inventory/items/item-category`, data);
  return response.data;
};

export const postAttribute = async (data) => {
  const response = await api.post(
    `inventory/items/item-category-attribute`,
    data
  );
  return response.data;
};

export const getAttributes = async (id) => {
  let categoryId = id.queryKey[0];
  const response = await api.get(
    `inventory/items/item-category-attribute/${categoryId}`
  );
  return response.data;
};

export const deleteAttribute = async (id) => {
  const response = await api.delete(
    `inventory/items/item-category-attribute/${id}`
  );
  return response.data;
};

export const deleteSubCategory = async (id) => {
  const response = await api.delete(`inventory/items/item-category/${id}`);
  return response.data;
};
