import { useState, useContext, useEffect } from "react";
import "./Login.scss";
import { ReactComponent as LoginLogo } from "img/loginLogo.svg";
// import { ReactComponent as MainLogo } from "img/seedLogo.svg";
import { ReactComponent as LoginBackground } from "img/loginBackground.svg";
import password_hide from "img/password-hide.svg";
import password_show from "img/password-show.svg";
import DispatchContext from "context/DispatchContext";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  checkLogin,
  sendCode,
  submitChangeEmail,
  checkUserIfExists,
} from "./LoginServices";
import { notificationMessage } from "global/helpers";
import { Form, Modal } from "react-bootstrap";
import close from "img/close.svg";
import { getCompanies } from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import { logoutApi } from "pages/SalesMarketing/SalesMarketing.services";
import Select from "react-select";

export default function Login() {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const { company } = useParams();
  const [showCompaniesModal, setShowCompaniesModal] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  const logInMutation = useMutation(checkLogin, {
    async onSuccess(response) {
      const {
        accessToken,
        idToken,
        refreshToken,
        companyId,
        companyUniqueAlias,
        expiresIn,
      } = response.data;
      let tokens = {
        accessToken,
        idToken,
        refreshToken,
        creationDate: new Date(),
        expiresIn,
      };
      if (!companyId) {
        localStorage.setItem("blueseedTokens", JSON.stringify(tokens));
        appDispatch({ type: "loading", value: false });
        companiesMutation.mutate();
      } else {
        localStorage.setItem("blueseedTokens", JSON.stringify(tokens));
        localStorage.setItem("company", companyUniqueAlias);
        localStorage.setItem("companyId", companyId || null);
        appDispatch({ type: "login" });
        history.push("/");
      }
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem logging in"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const logoutMutation = useMutation(logoutApi, {
    async onSuccess() {
      localStorage.clear();
    },
  });

  const companiesMutation = useMutation(getCompanies, {
    async onSuccess(response) {
      setShowCompaniesModal(true);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem getting companies"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  useEffect(() => {}, [getValues()]);

  useEffect(() => {
    appDispatch({ type: "loading", value: logInMutation.isLoading });
  }, [logInMutation.isLoading, appDispatch]);

  const onSubmit = (values) => {
    logInMutation.mutate(values);
  };

  const [passwordType, setPasswordType] = useState("password");

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === "input" ? "password" : "input");
  };

  const [showError, setShowError] = useState<boolean>(false);
  const [showErrorPassword, setShowErrorPassword] = useState<boolean>(false);
  const [showModalForgotPassword, setShowModalForgotPassword] =
    useState<boolean>(false);
  const [showForgotPasswordCode, setShowForgetPasswordCode] =
    useState<boolean>(false);
  const [forGotPasswordEmail, setForGotPasswordEmail] = useState<string>("");
  const [userCode, setUserCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState(null);

  const ForgotPasswordClicked = () => {
    setShowForgetPasswordCode(false);
    setShowModalForgotPassword(true);
  };

  const getCode = useMutation(sendCode, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "You will receive a verification code by email shortly",
      };
      appDispatch({ type: "notification", value: notification });
      setShowForgetPasswordCode(true);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem sending code."),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const userExists = useMutation(checkUserIfExists, {
    async onSuccess(data) {
      if (data?.data) {
        getCode.mutate(forGotPasswordEmail);
      } else {
        let notification = {
          variant: "danger",
          msg: "User Doesn't Exist",
        };
        appDispatch({ type: "notification", value: notification });
      }
    },
    onError(error) {},
  });

  const changePasswordConfirmed = async () => {
    if (forGotPasswordEmail) {
      userExists.mutate(forGotPasswordEmail);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (forGotPasswordEmail?.length > 0) setShowError(false);
    else setShowError(true);
    if (!forGotPasswordEmail) setShowError(false);
  }, [forGotPasswordEmail]);

  useEffect(() => {
    if (company) setValue("company", company);
  }, [company]);

  const confirmCode = useMutation(submitChangeEmail, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: "Your password has been changed successfully",
      };
      appDispatch({ type: "notification", value: notification });
      setShowModalForgotPassword(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Invalid Password."),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const changePasswordSubmit = () => {
    if (newPassword === newPasswordConfirm) {
      if (
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          newPassword
        )
      ) {
        let obj = {
          username: forGotPasswordEmail,
          newPassword: newPassword,
          confirmationCode: userCode,
        };
        confirmCode.mutate(obj);
        setShowErrorPassword(false);
      } else {
        let notification = {
          variant: "danger",
          msg: "Invalid password format",
        };
        appDispatch({ type: "notification", value: notification });
      }
    } else {
      setShowErrorPassword(true);
    }
  };

  const onCompanyChange = (e) => {
    setSelectedCompany(e);
  };

  const applyCompany = () => {
    const selectedCompanyAttr = companiesMutation.data?.data?.filter(
      (c) => c?.companyId === selectedCompany?.value
    );
    localStorage.setItem("company", selectedCompanyAttr[0]?.uniqueAlias);
    localStorage.setItem("companyId", selectedCompanyAttr[0]?.companyId);
    appDispatch({ type: "login" });
    history.push("/");
  };

  return (
    <div className="container-fluid login-container">
      <div className="row">
        <div className="col-sm login-column d-none d-lg-flex">
          <LoginLogo />
        </div>
        <div className="col-sm login-column login-column--colored">
          <h1>Welcome to BlueSeed</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                {...register("username", {
                  required: true,
                  // pattern: {
                  //   value: /\S+@\S+\.\S+/,
                  //   message: "Entered value does not match email format"
                  // }
                })}
                className={"form-control"}
                // type="email"
                type="text"
              />
              {errors.username && (
                <span role="alert" className="text-danger">
                  {errors.username.message
                    ? errors.username.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <div className="form-group mb-3">
              <label>Password</label>
              <div className="input-group">
                <Form.Control
                  id="password"
                  {...register("password", {
                    required: true,
                    minLength: {
                      value: 5,
                      message: "min length is 5",
                    },
                  })}
                  type={passwordType}
                />
                <button
                  type="button"
                  className="input-reveal-password border border-1"
                  onClick={showHidePassword}
                >
                  {passwordType === "input" ? (
                    <img src={password_show} alt="show password" />
                  ) : (
                    <img src={password_hide} alt="hide password" />
                  )}
                </button>
              </div>
              {errors.password && (
                <span role="alert" className="text-danger">
                  {errors.password.message
                    ? errors.password.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <button type="submit">Log in</button>
            <a onClick={ForgotPasswordClicked} className="forgot-password-btn">
              Forgot Password
            </a>
          </form>
          <LoginBackground className="login-background" />
        </div>
      </div>
      {showModalForgotPassword && (
        <>
          <Modal
            className=""
            show={showModalForgotPassword}
            onHide={() => setShowModalForgotPassword(false)}
            centered
          >
            <div className="d-flex justify-content-between align-items-center mx-4 my-3">
              <h3>Forgot password</h3>
              <img
                src={close}
                alt=""
                className="confirmation-modal-close"
                onClick={() => setShowModalForgotPassword(false)}
              />
            </div>
            <div className="mx-4 mb-4">
              <Modal.Body className="px-0 py-0 forgot-password-modal">
                {!showForgotPasswordCode ? (
                  <div className="form-group my-3">
                    <p>
                      Having a problem with your account? Enter your email, and
                      we will send you a verification code which you can use to
                      change your password.
                    </p>
                    <label className="label">Email Address</label>
                    <input
                      type="text"
                      className="form-control"
                      value={forGotPasswordEmail}
                      onChange={(value) =>
                        setForGotPasswordEmail(value.target.value)
                      }
                    />
                    <p>
                      {showError && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </p>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <b>Password must contain</b>
                      <ul>
                        <li>At least 8 characters</li>
                        <li>At least 1 special character</li>
                        <li>At least 1 upper case letter</li>
                        <li>At least 1 lower case letter</li>
                        <li>At least 1 numeric character</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group my-3">
                        <label className="label">Verification Code</label>
                        <input
                          type="text"
                          name="verificationCode"
                          className="form-control w-50"
                          onChange={(value) => setUserCode(value.target.value)}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">New Password</label>
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control"
                          onChange={(value) =>
                            setNewPassword(value.target.value)
                          }
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group my-3">
                        <label className="label">Confirm Password</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          onChange={(value) =>
                            setNewPasswordConfirm(value.target.value)
                          }
                          autoComplete="off"
                        />
                      </div>
                      <p>
                        {showErrorPassword && (
                          <span className="text-danger">
                            Passwords should match
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between mt-3">
                  <button
                    className="btn btn-outline-primary ms-0"
                    onClick={() => setShowModalForgotPassword(false)}
                  >
                    Reset
                  </button>
                  <div>
                    {showForgotPasswordCode && (
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => setShowForgetPasswordCode(false)}
                      >
                        Back
                      </button>
                    )}
                    {!showForgotPasswordCode ? (
                      <button
                        className="btn btn-success"
                        onClick={changePasswordConfirmed}
                      >
                        Contiune
                      </button>
                    ) : (
                      <button
                        className="btn btn-success"
                        onClick={changePasswordSubmit}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </>
      )}
      <Modal
        show={showCompaniesModal}
        onHide={() => {
          logoutMutation.mutate();
          setShowCompaniesModal(false);
        }}
        centered
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title className="">
            Select the company you want to login with
          </Modal.Title>
          <img
            src={close}
            alt="close icon"
            className=""
            role="button"
            onClick={() => {
              logoutMutation.mutate();
              setShowCompaniesModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className="px-3">
            <label>Companies</label>
            <Select
              options={companiesMutation.data?.data?.map((company) => ({
                value: company.companyId,
                label: company.name,
              }))}
              isSearchable={true}
              value={selectedCompany}
              onChange={onCompanyChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex  border-top-0">
          <button
            className="btn btn-primary"
            onClick={applyCompany}
            disabled={!!!selectedCompany}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => {
              setShowCompaniesModal(false);
              logoutMutation.mutate();
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
