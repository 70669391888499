import { useState, useEffect, useContext } from "react";
import DispatchContext from "context/DispatchContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import StateContext from "context/StateContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { VendorTypeModel } from "../Vendors.models";
import { ErrorResponse } from "components/data/Errors.model";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import {
  createVendorType,
  getVendorTypes,
  updateVendorType,
} from "../Vendors.services";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";

const NewVendorTypes = ({ match }) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const [vendorTypeData, setVendorTypeData] = useState<VendorTypeModel>(null);
  const { error: errorLoadingData, data: items } = useQuery<
    VendorTypeModel[],
    ErrorResponse
  >("get_vendorTypes", getVendorTypes);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setSelectedId(id);
      let idNum = Number(id);
      let data = items?.find((item) => item.vendorTypeId === idNum);
      setVendorTypeData(data);
      setSelectedId(idNum);
      setValue("name", data?.name);
      setValue("isActive", data?.isActive);
    }
  }, [id]);

  const formMethods = useForm<VendorTypeModel>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: vendorTypeData?.isActive || true },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
    control,
  } = formMethods;

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const history = useHistory();

  const addItem = useMutation(createVendorType, {
    onSuccess() {
      queryClient.invalidateQueries("get_vendorTypes");
      let notification = {
        variant: "success",
        msg: `Vendor type ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_types);
    },
    onError(error: any) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding vendor type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateItem = useMutation(updateVendorType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_vendorTypes");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_types);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing vendor type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addItem.isLoading || updateItem.isLoading,
    });
  }, [addItem.isLoading, updateItem.isLoading]);

  const onSubmit = (values) => {
    const vendorTypeData: VendorTypeModel = {
      vendorTypeId: selectedId ? selectedId : 0,
      name: values.name,
      isActive: values.isActive,
    };
    if (!selectedId) {
      addItem.mutate(vendorTypeData);
    } else {
      updateItem.mutate(vendorTypeData);
    }
  };

  const resetForm = () => {
    reset({ name: "", isActive: true });
  };

  return (
    <div className="static-page">
      <FormProvider {...formMethods}>
        <form id="new-method-form " onSubmit={handleSubmit(onSubmit)}>
          <div className="  page-title page-title-editable">
            <div className="d-flex flex-row">
              <div className="back-btn">
                <img
                  src={backArrow}
                  alt="back arrow"
                  onClick={() => history.push(AppUrls.vendor_types)}
                />
              </div>
              <div>
                <p className="">Vendor Types</p>
              </div>
            </div>
            <div className="d-flex" style={{ marginLeft: "-15px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  resetForm();
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                className="btn btn-primary"
              >
                {!selectedId ? "Add Vendor Type" : "Save Changes" || "Submit"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div>
              {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                <div className="">
                  <div className="row ">
                    <div className="col-4">
                      <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <>
                            <label>
                              Vendor Type
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              {...field}
                              required={true}
                              className={
                                errors.name
                                  ? "form-control required_field"
                                  : "form-control"
                              }
                            />
                          </>
                        )}
                      />
                      {errors["name"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-1">
                      <label>Active</label>
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="isActive"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="activeCheckbox"
                                  name="isActive"
                                  onChange={() => onChange(!value)}
                                  checked={value}
                                />
                                <span className="slider round"></span>
                              </label>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>{" "}
        </form>
      </FormProvider>
    </div>
  );
};

export default NewVendorTypes;
