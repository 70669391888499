import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Close from "img/close.svg";

const NoteUpdate = ({ isUpdate, setIsUpdate, note, onNoteUpdate }) => {
  const [noteData, setNoteData] = useState({ ...note });

  const onSubjectChanged = (e) => {
    setNoteData((preSubjectTxt) => ({
      ...preSubjectTxt,
      subject: e ? e.target.value : "",
    }));
  };

  const onNoteBodyChanged = (e) => {
    setNoteData((preNoteTxt) => ({
      ...preNoteTxt,
      noteBody: e ? e.target.value : "",
    }));
  };

  const cancelClicked = () => {
    setNoteData({
      ...note,
    });
  };

  const onModelClosed = () => {
    setNoteData({ ...note });
    setIsUpdate(false);
  };

  const onSaveUpdate = () => {
    if (noteData.subject !== "" && noteData.noteBody !== "") {
      onNoteUpdate(noteData);
      setIsUpdate(false);
    }
  };

  return (
    <Modal show={isUpdate} onHide={setIsUpdate}  centered>
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title className="update-note-title ">Editing Note</Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={onModelClosed}
        />
      </Modal.Header>
      <Modal.Body className="update-note-body">
        <div className="col-lg-12 mb-4">
          <label className="label mb-1">Subject</label>
          <Form.Control
            name="subjectTxt"
            onChange={(e) => onSubjectChanged(e)}
            value={noteData.subject || ""}
            className={`${noteData.subject === "" && "border-red"}`}
          />
          {noteData.subject === "" && (
            <p className="text-danger">This field is required</p>
          )}
        </div>
        <div className="col-lg-12 mb-4">
          <label className="label mb-1">Note</label>
          <Form.Control
            as="textarea"
            name="noteTxt"
            placeholder="Add your note"
            onChange={(e) => onNoteBodyChanged(e)}
            value={noteData.noteBody || ""}
            className={`note-input ${noteData.noteBody === "" && "border-red"}`}
          />
          {noteData.noteBody === "" && (
            <p className="text-danger">This field is required</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="update-note-footer d-flex">
        <>
          <button
            type="button"
            className="btn btn-outline-primary no-border"
            onClick={cancelClicked}
          >
            {" "}
            Reset
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              onSaveUpdate();
            }}
          >
            Save Changes
          </button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default NoteUpdate;
