import { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Form } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import backArrow from "img/back-arrow.svg";
import {
  formatInput,
  getCurrenciesListFromExchangeRateList,
} from "global/helpers";
import DispatchContext from "context/DispatchContext";
import * as AppUrls from "constants/AppUrls";
import { notificationMessage } from "global/helpers";
import PhoneInput from "react-phone-input-2";
import { zipCodeRegEx } from "global/regExp/regularExpressions";
import SectionTitle from "elements/SectionTitle";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { states, StateModel } from "components/data/States";
import {
  addBank,
  editBank,
  getBankDetails,
  getBanksTypes,
  getLeafs,
  getLedgers,
} from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";

import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getCurrency } from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import CountrySelect from "components/CountrySelect/CountrySelect";

const BankRecord = () => {
  const history = useHistory();

  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [isAccountDisabled, setIsAccountDisabled] = useState<boolean>(true);
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const appDispatch = useContext(DispatchContext);

  const statesOptions: StateModel[] = states;
  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
    if (countryCode !== "US") {
      setValue("address.state", null);
    }
  };
  const { data: leafs } = useQuery("leafs", () =>
    getLeafs({ ledgerType: LedgerTypeEnum.Bank })
  );

  const { data: bankTypes } = useQuery("bankTypes", getBanksTypes, {
    select(data) {
      return data.map((e) => ({ value: e.bankTypeId, label: e.type }));
    },
  });

  const { data: currencies } = useQuery("currencies", getCurrency, {
    select(data) {
      return getCurrenciesListFromExchangeRateList(data);
    },
  });

  useQuery([id], getBankDetails, {
    enabled: !!id,
    onSuccess(data) {
      reset(data);
    },
  });

  const { data: paymentAccounts } = useQuery("expenses", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Equity })
  );

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const methods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    control,
    handleSubmit,
    reset,
    register,
    setValue,
  } = methods;

  const addMutation = useMutation(addBank, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Bank ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`${AppUrls.banks}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding bank"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editMutation = useMutation(editBank, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`${AppUrls.banks}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing bank"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (data) => {
    if (!id) {
      addMutation.mutate({
        ...data,
        startingBalance: data.startingBalance
          ? parseFloat(
              data.startingBalance?.toString().replace(/,/g, "")
            ).toString()
          : "0",
      });
    } else {
      editMutation.mutate(data);
    }
  };

  const onBackClicked = () => {
    if (isDirty) {
      setShowConfirmModal(true);
    } else {
      history.push(AppUrls.banks);
    }
  };

  const cancelForm = () => {
    reset();
  };

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("balancingAffectedAccountId", e.ledgerId, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div role="button" className="back-btn " onClick={onBackClicked}>
              <img src={backArrow} alt="back arrow" />
              {!id && "New "} Bank
            </div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary no-border ${
                  !isDirty && !id && "text-muted"
                }`}
                onClick={cancelForm}
                disabled={!isDirty && !id}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty || isSubmitSuccessful}
              >
                {id ? "Save Changes" : "Create"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="left-side-mid pe-5 ps-5 section bg-white">
                  <div className="row mb-4">
                    <div className="form-group col">
                      <label>
                        Bank Account No. <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        {...register("bankAccountNumber", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "The Bank Account No cannot exceed 30 characters",
                          },
                        })}
                        name="bankAccountNumber"
                        className={
                          errors.bankAccountNumber
                            ? "form-control required_field"
                            : "form-control"
                        }
                      />
                      {errors["bankAccountNumber"] && (
                        <span className="text-danger">
                          {errors.bankAccountNumber.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group col">
                      <label>
                        Account Name <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        {...register("accountName", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "The Account Name cannot exceed 30 characters",
                          },
                        })}
                        name="accountName"
                        className={
                          errors.accountName
                            ? "form-control required_field"
                            : "form-control"
                        }
                      />
                      {errors["accountName"] && (
                        <span className="text-danger">
                          {errors.accountName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <label>
                        Account Category <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="accountingCategoryId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(selectedOption: any) =>
                              onChange(selectedOption?.value)
                            }
                            isClearable
                            value={
                              leafs?.find((opt) => opt?.categoryId === value) ||
                              null
                            }
                            options={leafs?.map((item) => {
                              return {
                                label: item.name,
                                value: item.categoryId,
                              };
                            })}
                            className={
                              errors.accountingCategoryId && "required_field"
                            }
                          />
                        )}
                      />
                      {errors["accountingCategoryId"] && (
                        <span className="text-danger">
                          {errors.accountingCategoryId.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-sm-6">
                      <label>
                        Bank Type <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="bankTypeId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(selectedOption: any) =>
                              onChange(selectedOption?.value)
                            }
                            isClearable
                            value={
                              bankTypes?.find((opt) => opt?.value === value) ||
                              null
                            }
                            options={bankTypes}
                            className={errors.bankTypeId && "required_field"}
                          />
                        )}
                      />
                      {errors["bankTypeId"] && (
                        <span className="text-danger">
                          {errors.bankTypeId.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-sm-6">
                      <label>Primary Currency</label>
                      <span className="text-danger"> *</span>
                      <Controller
                        control={control}
                        name="primaryCurrencyCode"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(selectedOption: any) =>
                              onChange(selectedOption?.value)
                            }
                            isClearable
                            value={
                              currencies?.find((opt) => opt?.value === value) ||
                              null
                            }
                            options={currencies}
                            className={
                              errors.primaryCurrencyCode && "required_field"
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Routing Number</label>
                        <input
                          {...register("routingNumber")}
                          className={"form-control"}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Serial</label>
                        <input
                          {...register("serial")}
                          className={"form-control"}
                        />
                      </div>
                    </div>
                  </div>
                  {!id && (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Starting balance</label>
                          <input
                            {...register("startingBalance")}
                            className={"form-control"}
                            onChange={(e) =>
                              Number(e.target.value) > 0
                                ? setIsAccountDisabled(false)
                                : setIsAccountDisabled(true)
                            }
                            onBlur={(e) =>
                              setValue(
                                "startingBalance",
                                formatInput(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Balancing affected account</label>
                          <Lookup
                            onButtonClicked={() => setOpenSelectionLedger(true)}
                            inputName="balancingAffectedAccountId"
                            initialData={paymentAccounts?.data?.map((item) => ({
                              ...item,
                              id: item.ledgerId,
                              name: item.accountName,
                            }))}
                            onSelection={onLedgerSelection}
                            inputValue={selectedLedger?.accountName}
                            isDisabled={isAccountDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="right-side-mid section ">
                  <SectionTitle editable={false} title="Address" />
                  <div className="form-group mt-4 mb-4">
                    <label>
                      Address 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("address.address", { required: true })}
                      className={`form-control ${
                        errors.address?.address && "required_field"
                      }`}
                    />
                    {errors?.address?.address && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>

                  <div className="form-group mb-4">
                    <label>Address 2</label>
                    <span className="text-danger">*</span>
                    <input
                      {...register("address.address1", { required: true })}
                      className={`form-control ${
                        errors.address?.address1 && "required_field"
                      }`}
                    />{" "}
                    {errors?.address?.address1 && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <label>Contact</label>
                    <input
                      {...register("address.contact")}
                      className="form-control"
                    />
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>City</label>{" "}
                        <span className="text-danger">*</span>
                        <input
                          {...register("address.city", { required: true })}
                          className={`form-control ${
                            errors.address?.city && "required_field"
                          }`}
                        />
                        {errors?.address?.address1 && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>State</label>
                        <span className="text-danger">*</span>
                        <Controller
                          name="address.state"
                          control={control}
                          rules={{ required: selectedCountry === "US" }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={statesOptions}
                              isClearable
                              isSearchable={true}
                              menuPlacement="auto"
                              value={
                                statesOptions?.find(
                                  (opt) => opt?.value === value
                                ) || null
                              }
                              onChange={(val: any) => {
                                onChange(val?.value);
                              }}
                              isDisabled={selectedCountry !== "US"}
                            />
                          )}
                        />
                        {selectedCountry === "US" && errors?.address?.state && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>Country</label>{" "}
                        <span className="text-danger">*</span>
                        <Controller
                          name="address.country"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => {
                            setSelectedCountry(value);
                            return (
                              <CountrySelect
                                value={value}
                                onChange={(countryCode) => {
                                  handleCountryChange(countryCode);
                                  onChange(countryCode);
                                }}
                              />
                            );
                          }}
                        />
                        {errors?.address?.country && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>Zip Code</label>
                        <Form.Control
                          {...register("address.zipCode", {
                            pattern: {
                              value: zipCodeRegEx,
                              message: "Invalid Zip Code",
                            },
                          })}
                          className={`form-control ${
                            errors.address?.zipCode && "required_field"
                          }`}
                        />
                        {errors.address?.zipCode && (
                          <p className="text-danger">
                            {errors.address?.zipCode.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>Mobile No.</label>
                        <Controller
                          name="address.mobileNumber"
                          rules={{
                            pattern: {
                              value:
                                /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/,
                              message: "Invalid Phone Input",
                            },
                          }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              country={"us"}
                            />
                          )}
                        />
                        {errors.address?.mobileNumber && (
                          <p className="text-danger">
                            {errors.address?.mobileNumber?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>Email</label>
                        <Controller
                          name="address.email"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <input
                              className={
                                errors.company
                                  ? "form-control required_field"
                                  : "form-control"
                              }
                              type={"email"}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        {errors.address?.email && (
                          <p className="text-danger">
                            {errors.address?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>Work No.</label>
                        <Controller
                          name="address.workNumber"
                          rules={{
                            pattern: {
                              value:
                                /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/,
                              message: "Invalid Phone Input",
                            },
                          }}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              onChange={onChange}
                              country={"us"}
                            />
                          )}
                        />
                        {errors.address?.workNumber && (
                          <p className="text-danger">
                            {errors.address?.workNumber?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group mb-4">
                        <label>Ext.</label>
                        <input
                          {...register("address.workNumExt", {
                            pattern: {
                              value: /^[0-9]{1,4}\s*$/,
                              message: "Invalid extension",
                            },
                          })}
                          type="number"
                          className={`form-control ${
                            errors.address?.workNumExt && "required_field"
                          }`}
                        />
                        {errors.address?.workNumExt && (
                          <p className="text-danger">
                            {errors.address?.workNumExt?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          history.push(AppUrls.banks);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
    </>
  );
};

export default BankRecord;
