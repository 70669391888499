import { Note } from "components/note/note.model"
import { Market, Region, ShippingMethod } from "pages/SystemSetup/SystemSetup.models"
import { AccountTypeDTO } from "../AccountType/AccountType.models"
import { RepresentativeModelDTO } from "../Representative/Representative.models"
import { AddressInfo } from "../SalesMarketing.models"
    

export interface CustomerAccount {
    accountNumber: string,
    accountType: string,
    balance: number,
    city: string,
    company: string,
    contact: string,
    customerAccountId: number,
    hubId: string,
    isActive: boolean,
    state: string,
    primaryCurrencyCode?: string;
}

export interface CustomerAccountRequest {
    count: number,
    data: CustomerAccount[]
}

export interface CustomerInfoDTO {
    startingBalance: string,
    balancingAffectedAccountId: number,
    requestDate: Date,
    customerAccountId: number,
    accountNumber: string,
    accountTypeId: number,
    ledger: { accountingCategoryId: number, balance: number },
    isActive: boolean,
    sellingPriceLevelId: number,
    billingInfo: CustomerBillingInfo,
    defaultShippingInfo: CustomerShippingInfo,
    shippingInfos: CustomerShippingInfo[],
    fedExAccount: string,
    upsAccount: string,
    shippingMethodId?: number,
    email: string,
    repId?: number,
    rep?: Representative,
    marketId?: number,
    regionId?: number,
    signedDate?: Date,
    openedDate?: Date
    closedDate?: Date,
    defaultHubId?: number,
    defaultHub: InventoryHub
    rtposId: string,
    insideRepId?: number,
    trainedById?: number,
    parentAccountId?: number,
    isMasterDealer: boolean,
    warrantyLevel: WarrantyLevel,
    languageId?: number,
    dateOfBirth?: Date,
    creditDate?: Date,
    creditScore: string,
    creditRefNum: string,
    validateSprintDB?: boolean,
    validateBlackListDB?: boolean,
    openedBalance?: number,
    creditLimit?: string,
    onOrder?: number,
    creditTermId?: number,
    bankAccNum: string,
    bankRoutingNum: string,
    bankAccType: BankAccountType,
    achTerm: string,
    ccShop: boolean,
    codShop: boolean,
    checkShop: boolean,
    otherShop: boolean,
    achShop: boolean,
    paypalShop: boolean,
    payPalEmail: string,
    masterDealer: string,
    qPayBranchId: string,
    dealerCodes: string,
    onSO: number,
    balance: number,
    notes: Note[],
    createdDate: Date,
    createdBy: string,
    //used for front end only
    shippingInfoData: CustomerShippingInfo
    openCredits?: number,
    dirtyFlag?: string,
    shippingInfoState?: string,
    billingInfoState?: string,
    primaryCurrencyCode: string
}

export interface InventoryHub {
    hubId: number
    hubKey: string
}

export interface Representative {
    repId: number
    repName: string
}

export interface CustomerShippingInfo {
    shippingInfoId?: number,
    company?: string,
    address?: AddressInfo,
    isDefault?: boolean,
    index?: number
}

export interface CustomerBillingInfo {
    billingInfoId: number,
    address: AddressInfo,
    company: string,
    dba: string,
    entityDate?: Date,
    taxId: string
}

export enum WarrantyLevel {
    Standard = 1,
    Warranty2 = 2,
    Warranty3 = 3
}

export enum BankAccountType {
    Checking = 1,
    Saving = 2
}

export interface InventoryHubDTO {
    locationId: number,
    hubId: string,
    description: string,
    workNumber: string,
    faxNumber: string,
    contactPerson: string,
    isDefault: boolean,
    isActive: boolean,
    address: string,
    address1: string,
    city: string,
    state: string,
    zipCode: string,
}

export interface PriceLevel {
    priceLevelId: number,
    title: string
}
export interface CreditTerm {
    creditTermId: number,
    term: string,
    description: string
}
export interface LanguageModel {
    languageId: number,
    languageName: string
}
export interface CustomerInitData {
    accountTypes: AccountTypeDTO[],
    representatives: RepresentativeModelDTO[],
    regions: Region[],
    markets: Market[],
    shippingMethods: ShippingMethod[],
    creditTerms: CreditTerm[],
    languages: LanguageModel[],
    hubs: InventoryHubDTO[],
    priceLevels: PriceLevel[]
}
export interface CustomerCreditMemo {
    accountNumber: string,
    aging: number,
    amount: number,
    batchNumber: number,
    company: string,
    customerPo: string,
    date: Date,
    hubId: number,
    invoiceId: number,
    invoiceNumber: string,
    invoiceType: number,
    paidAmount: number,
    paidDate: Date
    remarks: string,
    salesOrder: string,
    shippingInfoId: number,
    shippingMethodId: number,
    refundAmount: number
}
export interface CustomerPayment {
    paymentReceiptId: number,
    receiptNumber: string,
    accountNumber: string,
    date: Date,
    payment: number,
    refNumber: string,
    remark: string,
    status: number,
    createdBy: string,
    checkNumber: string,
    batchNumber: number,
    paymentMethod: string,
    unapplied: number
}
