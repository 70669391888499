import * as AppUrls from "constants/AppUrls";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import DatePicker from "react-datepicker";
import Select from "react-select";

import DateFilter from "components/DateFilter/DateFilter";
import DispatchContext from "context/DispatchContext";

import { fixTimezoneOffset } from "global/helpers";
import close from "img/close.svg";
import { ReactComponent as FilterIcon } from "img/filter.svg";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import { getPaymentMethods } from "pages/Invoices/InvoicesServices/InvoicesServices";
import { getUserAccounts } from "pages/UserManagement/UserManagement.services";
import { Modal } from "react-bootstrap";
import {
  getCustomizedReportByIdAPI,
  getReportInit,
  getReportInventoryItems,
  getReportMarkets,
  getReportRegions,
  getReportVendors,
  SaveCustomizedReportsAPI,
} from "../services/ReportServices";
import ColumnCustomizer from "./Customizer";

const Filter = (props) => {
  const statuses = [
    {
      value: 1,
      label: "Open",
    },
    {
      value: 2,
      label: "Received",
    },
    {
      value: 3,
      label: "Partial Received",
    },
    {
      value: 4,
      label: "Voided",
    },
    {
      value: 5,
      label: "All",
    },
  ];

  const Dateoptions = [
    { value: 5, label: "This Year" },
    { value: 7, label: "This Month" },
    { value: 8, label: "This Week" },
    { value: 9, label: "Year To Date" },
    { value: 1, label: "Last Year" },
    { value: 2, label: "Last Quarter" },
    { value: 3, label: "Last Month" },
    { value: 4, label: "Last Week" },
    // { value: 6, label: "This Quarter" },

    { value: 10, label: "Custom" },
  ];
  const [dateCategory, setDateCategory] = useState(null);

  const { control, register, reset, setValue, getValues } = useFormContext();

  const { search, pathname } = useLocation();

  const queryParams = search.substr(1).split("?");
  queryParams[0] = queryParams[0].split("=")[1];
  if (queryParams[1]) queryParams[1] = queryParams[1].split("=")[1];

  const currentComponentId = queryParams[0];
  const customizedComponentId = queryParams[1];

  const isCustomizedReport = customizedComponentId != null;

  const appDispatch = useContext(DispatchContext);
  const [showCustomizer, setShowCustomizer] = useState<boolean>(false);

  const { data: initData } = useQuery("initData", getReportInit);
  const { data: inventoryItems } = useQuery(
    ["getReportInventoryItems2", { hubId: -1, itemType: 1 }],
    getReportInventoryItems
  );
  const { data: vendors } = useQuery("vendorsList", getReportVendors);
  const { data: markets } = useQuery("markets", getReportMarkets);
  const { data: regions } = useQuery("regions", getReportRegions);
  const { data: paymentAccountsIncome } = useQuery("income", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Income })
  );
  const { data: paymentAccounts } = useQuery("paymentAccounts", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Asset })
  );

  const { data: paymentMethods } = useQuery(
    "paymentMethods",
    getPaymentMethods
  );

  const { data: userAccounts } = useQuery("user_accounts", getUserAccounts);

  const origins = [
    { value: 1, label: "System" },
    { value: 2, label: "Web" },
  ];

  let incomeOpt = paymentAccountsIncome?.data?.map((item) => {
    return { value: item.ledgerId, label: item.accountName };
  });

  let paymentAccountMethods = paymentAccounts?.data?.map((item) => ({
    value: item.ledgerId,
    label: item.accountName,
  }));

  let methodsOpt = paymentMethods?.map((method) => ({
    label: method.name,
    value: method.paymentMethodId,
  }));

  let userAccountsOpt = userAccounts?.data?.map((data) => {
    return { value: data.employeeId, label: data.email };
  });

  let HubIdOpt = initData?.hubs?.map((data) => {
    return { value: data.locationId, label: data.hubId };
  });

  let CustomersOpt = initData?.customers?.map((data) => {
    return { value: data.customerAccountId, label: data.accountNumber };
  });

  let CustomersTypeOpt = initData?.accountTypes?.map((data) => {
    return { value: data.accTypeId, label: data.name };
  });

  let RepresentativesOpt = initData?.representatives?.map((data) => {
    return { value: data.repId, label: data.repName };
  });

  let ItemsOpt = inventoryItems?.map((data) => {
    return { value: data.itemId, label: data.itemNumber };
  });
  let ClassesOpt = inventoryItems
    ?.filter((data) => data.itemClass !== null)
    .map((data) => {
      return { value: data.itemId, label: data.itemClass };
    });

  let SubClassesOpt = inventoryItems
    ?.filter((data) => data.itemSubClass !== null)
    .map((data) => {
      return { value: data.itemId, label: data.itemSubClass };
    });

  let ManufacturerOpt = inventoryItems
    ?.filter((data) => data.manufacturer !== null)
    .map((data) => {
      return { value: data.accTypeId, label: data.manufacturer };
    });

  let ProductLineOpt = initData?.productLines?.map((data) => {
    return { value: data.itemProductLineId, label: data.productLine };
  });

  let CategoryOpt = initData?.categories?.map((data) => {
    return { value: data.itemCategoryId, label: data.category };
  });

  let SupplierOpt = initData?.suppliers?.map((data) => {
    return { value: data.vendorId, label: data.vendorNumber };
  });

  let VendorsOpt = vendors?.map((data) => {
    return { value: data.vendorId, label: data.vendorNumber };
  });

  let MarketOpt = markets?.map((data) => {
    return { value: data.marketId, label: data.name };
  });

  let RegionOpt = regions?.map((data) => {
    return { value: data.regionId, label: data.name };
  });

  let InvoiceTypeOpt = [
    {
      value: 1,
      label: "Invoice",
    },
    {
      value: 2,
      label: "Credit Memo",
    },
  ];

  let YesNoOpt = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const operationTypes = [
    { value: 1, label: "Sales Order" },
    { value: 2, label: "Purchase Order" },
    { value: 3, label: "Invoice" },
    { value: 4, label: "Transfers" },
    { value: 5, label: "Biills" },
  ];

  const PeriodOpt = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  const actionTypes = [
    { value: 1, label: "View" },
    { value: 2, label: "Add" },
    { value: 3, label: "Edit" },
    { value: 4, label: "Delete" },
  ];

  const filterElements = [
    {
      key: 0,
      isAdvanced: false,
      registerValue: "groupingElement",
      label: "Grouping Element",
      defaultOption: null,
      options: props.groupingElements,
    },
    {
      // Date Filter (imported from DateFilter component)
      key: 1,
      registerValue: "DatePicker",
      isAdvanced: false,
    },
    {
      key: 2,
      isAdvanced: false,
      registerValue: "HubId",
      label: "Hub ID",
      defaultOption: null,
      options: HubIdOpt,
    },
    {
      key: 3,
      isAdvanced: false,
      registerValue: "HubsId",
      label: "Hubs ID",
      defaultOption: null,
      options: HubIdOpt,
    },
    {
      key: 4,
      isAdvanced: true,
      registerValue: "CustomerId",
      label: "Customer",
      defaultOption: null,
      options: CustomersOpt,
    },
    {
      key: 5,
      isAdvanced: true,
      registerValue: "CustomerTypeId",
      label: "Customer Type",
      defaultOption: null,
      options: CustomersTypeOpt,
    },
    {
      key: 6,
      isAdvanced: true,
      registerValue: "RepresentativeId",
      label: "Representative",
      defaultOption: null,
      options: RepresentativesOpt,
    },
    {
      key: 7,
      isAdvanced: true,
      registerValue: "IsInsideRep",
      label: "is inside Rep",
      defaultOption: null,
      options: YesNoOpt,
    },
    {
      key: 8,
      isAdvanced: true,
      registerValue: "ItemId",
      label: "Item",
      defaultOption: null,
      options: ItemsOpt,
    },
    {
      key: 9,
      isAdvanced: true,
      registerValue: "ClassId",
      label: "Class",
      defaultOption: null,
      options: ClassesOpt,
    },
    {
      key: 10,
      isAdvanced: true,
      registerValue: "SubClassId",
      label: "Sub Class",
      defaultOption: null,
      options: SubClassesOpt,
    },
    {
      key: 11,
      isAdvanced: true,
      registerValue: "ManufacturerId",
      label: "Manufacturer",
      defaultOption: null,
      options: ManufacturerOpt,
    },
    {
      key: 12,
      isAdvanced: true,
      registerValue: "ProductLineId",
      label: "Product Line",
      defaultOption: null,
      options: ProductLineOpt,
    },
    {
      key: 13,
      isAdvanced: true,
      registerValue: "CategoryId",
      label: "Category",
      defaultOption: null,
      options: CategoryOpt,
    },
    {
      key: 14,
      isAdvanced: true,
      registerValue: "SupplierId",
      label: "Supplier",
      defaultOption: null,
      options: SupplierOpt,
    },
    {
      key: 15,
      isAdvanced: true,
      registerValue: "IsSerialized",
      label: "is serialized",
      defaultOption: null,
      options: YesNoOpt,
    },
    {
      key: 16,
      isAdvanced: true,
      registerValue: "IsRetail",
      label: "is retail",
      defaultOption: null,
      options: YesNoOpt,
    },
    {
      key: 17,
      isAdvanced: true,
      registerValue: "VendorId",
      label: "Vendor",
      defaultOption: null,
      options: VendorsOpt,
    },
    {
      key: 18,
      isAdvanced: true,
      registerValue: "invoiceType",
      label: "Invoice Type",
      defaultOption: null,
      options: InvoiceTypeOpt,
    },
    {
      key: 19,
      isAdvanced: true,
      registerValue: "MarketId",
      label: "Market",
      defaultOption: null,
      options: MarketOpt,
    },
    {
      key: 20,
      isAdvanced: true,
      registerValue: "RegionId",
      label: "Region",
      defaultOption: null,
      options: RegionOpt,
    },
    {
      key: 21,
      isAdvanced: true,
      registerValue: "ProjectNo",
      label: "Project No",
      defaultOption: null,
    },
    {
      key: 22,
      isAdvanced: true,
      registerValue: "RefNo",
      label: "Ref No",
      defaultOption: null,
    },
    {
      key: 23,
      isAdvanced: true,
      registerValue: "CustPo",
      label: "Cust PO",
      defaultOption: null,
    },
    {
      key: 24,
      isAdvanced: true,
      registerValue: "OperationType",
      label: "Operation Type",
      defaultOption: null,
      options: operationTypes,
    },
    {
      key: 25,
      isAdvanced: false,
      registerValue: "groupingPeriod",
      label: "Grouping Period",
      defaultOption: null,
      options: PeriodOpt,
    },
    {
      key: 26,
      isAdvanced: true,
      registerValue: "IsInvoiceHub",
      label: "is invoice hub",
      defaultOption: { label: "yes", value: true },
      options: YesNoOpt,
    },
    {
      key: 27,
      isAdvanced: true,
      registerValue: "transferedBy",
      label: "Transfered By",
      defaultOption: null,
    },
    {
      key: 28,
      isAdvanced: true,
      registerValue: "PoNo",
      label: "Po No",
      defaultOption: null,
    },
    {
      key: 29,
      isAdvanced: true,
      registerValue: "IsOnline",
      label: "Is Online",
      defaultOption: null,
      options: YesNoOpt,
    },
    {
      key: 30,
      isAdvanced: true,
      registerValue: "origin",
      label: "Origin",
      defaultOption: null,
      options: origins,
    },
    {
      key: 31,
      isAdvanced: true,
      registerValue: "incomeAccount",
      label: "Income Account",
      defaultOption: null,
      options: incomeOpt,
    },
    {
      key: 32,
      isAdvanced: true,
      registerValue: "status",
      label: "Status",
      defaultOption: null,
      options: statuses,
    },
    {
      key: 33,
      isAdvanced: true,
      registerValue: "payee",
      label: "Payee",
      defaultOption: null,
      options: VendorsOpt,
    },
    {
      key: 34,
      isAdvanced: true,
      registerValue: "paymentMethod",
      label: "Payment Method",
      defaultOption: null,
      options: methodsOpt,
    },
    {
      key: 35,
      isAdvanced: true,
      registerValue: "paymentAccount",
      label: "Payment Account",
      defaultOption: null,
      options: paymentAccountMethods,
    },
    {
      key: 36,
      isAdvanced: true,
      registerValue: "isSorted",
      label: "Sorted",
      defaultOption: null,
      options: YesNoOpt,
    },
    {
      key: 37,
      isAdvanced: true,
      registerValue: "isPastDue",
      label: "Past Due",
      defaultOption: null,
      options: YesNoOpt,
    },
    {
      key: 38,
      isAdvanced: true,
      registerValue: "minAge",
      label: "Min Age",
      defaultOption: null,
    },
    {
      key: 39,
      isAdvanced: false,
      registerValue: "asOfDate",
      label: "As of Date",
    },
    {
      key: 40,
      isAdvanced: false,
      registerValue: "userId",
      label: "User",
      options: userAccountsOpt,
    },
    {
      key: 41,
      isAdvanced: false,
      registerValue: "actionType",
      label: "Action Type",
      options: actionTypes,
    },
  ];

  useQuery([customizedComponentId], getCustomizedReportByIdAPI, {
    enabled: isCustomizedReport,
    onSuccess: (res) => {
      let resetObj = {};
      let columns = JSON.parse(res.params).columns;
      let params = JSON.parse(res.params).filters;
      let name = res.name;
      let isSummary = JSON.parse(res.params).isSummary;
      Object.keys(params).forEach((key) => {
        if (key === "dateCategory") {
          setDateCategory(
            Dateoptions.find((option) => option.value === params[key])
          );
        } else if (
          key === "startDate" ||
          key === "endDate" ||
          key === "asOfDate"
        ) {
          resetObj[key] = new Date(params[key]);
        } else {
          resetObj[key] = params[key];
        }
      });
      if (props.setGridColumns) props.setGridColumns(columns);
      reset(resetObj);
      props.onSubmit();
      if (props.setReportName) props.setReportName(name);
      if (props.setIsSummary) props.setIsSummary(isSummary ? true : false);
    },
  });

  useEffect(() => {
    if (props.initialDate) {
      setDateCategory({ value: 5, label: "This Year" });
      const currentYear = new Date().getFullYear();
      let startDate = new Date(currentYear, 0, 1);
      let endDate = new Date(currentYear, 11, 31);
      setValue("invoiceType", { value: 1, label: "Invoice" });
      setValue("groupingElement", { value: 1, label: "None" });
      setValue("startDate", startDate);
      setValue("endDate", endDate);
      setTimeout(() => {
        props.onSubmit();
      });
    }
  }, [props.initialDate]);

  const onCancelClick = () => {
    let defaultValues = {};
    filterElements.forEach((filterElement) => {
      let key = filterElement.registerValue;
      defaultValues[key] = "";
    });
    reset(defaultValues);
  };

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const DisplayHideAdvanced = () => {
    setShowAdvancedFilters((prev) => !prev);
  };

  const [
    showConfirmSaveFiltersAsCustomizedReport,
    setShowConfirmSaveFiltersAsCustomizedReport,
  ] = useState(false);

  const cancelSaveFilterAsCustomizedReport = () => {
    setShowConfirmSaveFiltersAsCustomizedReport(false);
    setValue("newReportTitle", null);
  };

  const SaveCustomizedReport = useMutation(SaveCustomizedReportsAPI, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: `Report saved as ${getValues(
          "newReportTitle"
        )} under Customized Reports`,
      };
      appDispatch({ type: "notification", value: notification });
      cancelSaveFilterAsCustomizedReport();
    },
  });

  const mapColumns = (initial) => {
    return initial
      ?.sort((a, b) => a.left - b.left)
      .map((column) => {
        let { colDef } = column;
        let { field, headerName, valueGetter, cellRenderer } = colDef;
        return {
          field,
          headerName,
          valueGetter,
          hide: !column.visible,
          width: column.actualWidth,
          cellRenderer,
        };
      });
  };

  const confirmCustomization = () => {
    setShowConfirmSaveFiltersAsCustomizedReport(true);
    if (props.setRefreshCounter) props.setRefreshCounter((prev) => prev + 1);
  };

  const saveFiltersAsCustomizedReport = () => {
    const newReportTitle = getValues("newReportTitle");
    let columns = [];

    if (props.noColumns !== true) {
      if (props.childFnRef && props.childFnRef()?.length > 0)
        columns.push(mapColumns(props.childFnRef()));
      else if (props.gridColumns.length > 0) columns.push(props.gridColumns[0]);

      if (props.childFnRef1 && props.childFnRef1()?.length > 0)
        columns.push(mapColumns(props.childFnRef1()));
      else if (props.gridColumns.length > 1) columns.push(props.gridColumns[1]);

      if (props.childFnRef2 && props.childFnRef2()?.length > 0)
        columns.push(mapColumns(props.childFnRef2()));
      else if (props.gridColumns.length > 2) columns.push(props.gridColumns[2]);
    }

    let values = getValues();
    let filters = {};
    if (dateCategory) filters["dateCategory"] = dateCategory?.value;
    if (values.startDate)
      filters["startDate"] = fixTimezoneOffset(getValues("startDate"));
    if (values.endDate)
      filters["endDate"] = fixTimezoneOffset(getValues("endDate"));
    filterElements.forEach((filterElement) => {
      let key = filterElement.registerValue;
      let value = getValues(key);
      filters[key] = value;
    });
    let params = {
      filters: filters,
      columns: columns,
    };
    if (props.isSummary) params["isSummary"] = props.isSummary;
    let obj = {
      componentId: currentComponentId,
      name: newReportTitle,
      params: JSON.stringify(params),
    };
    SaveCustomizedReport.mutate(obj);
  };

  let groupingElementSelected = useWatch({
    control,
    name: "groupingElement",
  });

  return (
    <>
      <Accordion
        defaultActiveKey="1"
        className="mb-4 reports-card reports-filters-card"
      >
        <Accordion.Item eventKey={"1"}>
          <Accordion.Header>
            <b>Filters</b>
          </Accordion.Header>
          <Accordion.Body className="p-3">
            <div className="row">
              {filterElements
                .filter(
                  (filterElement) =>
                    props.filters.includes(filterElement.key) &&
                    !filterElement.isAdvanced
                )
                .map((filterElement) => {
                  return (
                    <>
                      {filterElement.key === 1 ? (
                        <div
                          className={`${
                            pathname === AppUrls.audit_trail ? "" : ""
                          } w-100`}
                        >
                          <DateFilter
                            dateCategory={dateCategory}
                            setDateCategory={setDateCategory}
                            options={Dateoptions}
                          />
                        </div>
                      ) : (
                        <>
                          {filterElement.key === 3 ? (
                            <>
                              <div className="form-group">
                                <label>{filterElement.label}</label>
                                <Controller
                                  control={control}
                                  name={filterElement.registerValue}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      isMulti
                                      options={filterElement.options}
                                      value={value}
                                      onChange={onChange}
                                      isSearchable={true}
                                      isClearable={true}
                                    />
                                  )}
                                />
                              </div>
                            </>
                          ) : filterElement.key === 39 ? (
                            <div className="form-group col-sm-3">
                              <label>{filterElement.label}</label>
                              <Controller
                                control={control}
                                name={filterElement.registerValue}
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    onChange={onChange}
                                    selected={value ? value : null}
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <>
                              <div
                                className={`form-group col-sm-3 ${
                                  filterElement.key === 25
                                    ? groupingElementSelected?.label ===
                                      "Period Of Aging"
                                      ? "d-block"
                                      : "d-none"
                                    : "d-block"
                                }`}
                              >
                                <div
                                  className={`${
                                    filterElement.key === 41 ? "mx-2 " : ""
                                  }`}
                                  style={{marginLeft: "10px"}}
                                >
                                  <label>{filterElement.label}</label>
                                  <Controller
                                    control={control}
                                    name={filterElement.registerValue}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Select
                                        options={filterElement.options}
                                        value={value}
                                        onChange={onChange}
                                        isSearchable={true}
                                        isClearable={filterElement.key != 0}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              {filterElements.some(
                (filterElement) =>
                  props.filters.includes(filterElement.key) &&
                  filterElement.isAdvanced
              ) && (
                <div className="col-sm-6" style={{marginBottom: "40px"}}>
                  {groupingElementSelected ? (
                    groupingElementSelected.label === "None" ? (
                      <button
                        type="button"
                        className="btn btn-outline-primary custom-filter-btn mt-4"
                        onClick={DisplayHideAdvanced}
                      >
                        <FilterIcon className="me-2" />
                        Advanced
                      </button>
                    ) : (
                      <></>
                    )
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-primary custom-filter-btn mt-4"
                      onClick={DisplayHideAdvanced}
                    >
                      <FilterIcon className="me-2" />
                      Advanced
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-outline-primary no-border mt-4"
                    onClick={onCancelClick}
                  >
                    Clear filters
                  </button>
                </div>
              )}
            </div>
            <div
              className={`report-advanced-filters ${
                showAdvancedFilters ? "d-block w-75" : "d-none"
              } mt-4`}
            >
              <div className="row">
                {filterElements
                  .filter(
                    (filterElement) =>
                      props.filters.includes(filterElement.key) &&
                      filterElement.isAdvanced
                  )
                  .map((filterElement) => {
                    return (
                      <>
                        {filterElement.key === 1 ? (
                          <>
                            <DateFilter
                              dateCategory={dateCategory}
                              setDateCategory={setDateCategory}
                              options={Dateoptions}
                            />
                          </>
                        ) : (
                          <>
                            {filterElement.key === 21 ||
                            filterElement.key === 22 ||
                            filterElement.key === 23 ||
                            filterElement.key === 27 ||
                            filterElement.key === 28 ||
                            filterElement.key === 38 ? (
                              <>
                                <div className="col-sm-3">
                                  <label className="mt-1">
                                    {filterElement.label}
                                  </label>
                                  <input
                                    type={
                                      filterElement.key === 38
                                        ? "number"
                                        : "text"
                                    }
                                    {...register(filterElement.registerValue)}
                                    className="form-control"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className=" form-group col-sm-3">
                                  <label>{filterElement.label}</label>
                                  <Controller
                                    control={control}
                                    name={filterElement.registerValue}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Select
                                        options={filterElement.options}
                                        value={value}
                                        onChange={onChange}
                                        isSearchable={true}
                                        isClearable={filterElement.key != 26}
                                      />
                                    )}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
            {/* <div className="text-end"> */}
            <div className="sbmtfrm  row  " style={{alignItems: "center", justifyContent: "space-between"}}>
              <div className=" col-auto">
                {" "}
                <button type="submit" className="btn btn-outline-primary">
                  Generate Report
                </button>
              </div>
              <div className="col-auto">
                {" "}
                {!isCustomizedReport && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={confirmCustomization}
                  >
                    Save customisation
                  </button>
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal
        className="confirmation-modal confirmation-primary"
        show={showConfirmSaveFiltersAsCustomizedReport}
        onHide={cancelSaveFilterAsCustomizedReport}
        centered
      >
        <div className="d-flex justify-content-between align-items-center mx-4 my-3">
          <div className="confirmation-modal-title">
            Save filters configuration
          </div>
          <img
            src={close}
            alt=""
            className="confirmation-modal-close"
            onClick={cancelSaveFilterAsCustomizedReport}
          />
        </div>
        <div className="mx-4">
          <Modal.Body className="px-0 py-0">
            <div className="form-group">
              <label className="text-primary">New Report Title</label>
              <input
                {...register("newReportTitle")}
                className="form-control"
                type="text"
              />
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn btn-outline-primary ms-0"
                onClick={cancelSaveFilterAsCustomizedReport}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                onClick={saveFiltersAsCustomizedReport}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <ColumnCustomizer
        showModal={showCustomizer}
        setShowModal={setShowCustomizer}
        // gridColumns={props.gridColumns}
        gridColumns={[]}
        setGridColumns={props.setGridColumns}
        headers={props.headers}
      />
    </>
  );
};

export default Filter;
