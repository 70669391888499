import { GridColumns, QueryResult } from "components/Common.models";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { ErrorResponse } from "components/data/Errors.model";
import { StateModel, states } from "components/data/States";
import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import SectionTitle from "elements/SectionTitle";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import zipCode from "global/regExp/zipcode";
import backArrow from "img/back-arrow.svg";
import Copy from "img/copy.svg";
import Edit from "img/edit.svg";
import {
  copyHub,
  createHub,
  deleteHub,
  getHubById,
  getInventoryHubsList,
  updateHub,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { ThreeDots } from "react-bootstrap-icons";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { Hub } from "../Inventory.models";

const InventoryDetails = (props) => {
  const { match } = props;

  let history = useHistory();
  const { id } = useParams();
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const [isEditInventory, setIsEditInventory] = useState<boolean>(false);
  const [showDeleteInventory, setShowDeleteInventory] =
    useState<boolean>(false);
  const [showSubmitCopyItem, setShowSubmitCopyItem] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showHubsModal, setShowHubsModal] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState<Hub>();
  const [attached, setAttached] = useState<boolean>(false);

  const firstRender = useRef(true);
  const statesOptions: StateModel[] = states;

  const {
    isLoading: isLoadingItemHubInfo,
    error: errorLoadingHubInfo,
    data: hubInfo,
  } = useQuery<Hub, ErrorResponse>([id], getHubById, {
    enabled: id !== undefined ? true : false,
  });

  useEffect(() => {
    if (id) {
      setIsEditInventory(false);
    } else {
      setIsEditInventory(true);
    }
  }, [id]);

  useEffect(() => {
    if (errorLoadingHubInfo?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingHubInfo.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingHubInfo, appDispatch]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingItemHubInfo });
  }, [isLoadingItemHubInfo, appDispatch]);

  useEffect(() => {
    if (hubInfo !== undefined) {
      reset(hubInfo);
    }
  }, [hubInfo]);

  const addHub = useMutation(createHub, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `Hub ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      clearHubFrm();
      history.push(`${AppUrls.inventories_list}/${response.hubId}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to create hub"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editHub = useMutation(updateHub, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Hub ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsEditInventory(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing hub"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitCreateHub = (data) => {
    addHub.mutate(data);
  };

  const submitEditHub = (data) => {
    editHub.mutate({ ...data, id: id });
  };

  const removeHub = useMutation(deleteHub, {
    async onSuccess() {
      setShowDeleteInventory(false);
      let notification = {
        variant: "success",
        msg: `Hub ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(AppUrls.inventories_list);
    },
    onError() {
      setAttached(true);
    },
  });

  const inventoryHubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const {
    isLoading: isLoadingInventoryHubs,
    error: errorLoadingInventoryHubs,
    data: inventoryHubs,
  } = useQuery<QueryResult<Hub>, ErrorResponse>(
    "inventoryHubsList",
    getInventoryHubsList
  );

  useEffect(() => {
    if (errorLoadingInventoryHubs?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingInventoryHubs.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingInventoryHubs, appDispatch]);

  const onSelectedHub = (e) => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setSelectedHub(e);
    setShowHubsModal(false);
    setShowSubmitCopyItem(true);
  };

  const copyItemToHub = useMutation(copyHub, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Hub copied successfully",
      };
      appDispatch({ type: "notification", value: notification });
      setShowSubmitCopyItem(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to copy hub"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitCopyHub = () => {
    copyItemToHub.mutate({
      id: hubInfo?.locationId,
      hubId: selectedHub?.locationId,
    });
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        isLoadingItemHubInfo ||
        isLoadingInventoryHubs ||
        addHub.isLoading ||
        editHub.isLoading ||
        removeHub.isLoading ||
        copyItemToHub.isLoading,
    });
  }, [
    isLoadingItemHubInfo,
    isLoadingInventoryHubs,
    addHub.isLoading,
    editHub.isLoading,
    removeHub.isLoading,
    copyItemToHub.isLoading,
    appDispatch,
  ]);

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    getValues,
    handleSubmit,
    reset,
    register,
  } = useForm<Hub>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isActive: true,
      isDefault: true,
    },
  });

  const clearHubFrm = () => {
    reset();
  };

  const onSubmit = (data: Hub) => {
    if (id) {
      submitEditHub(data);
    } else {
      submitCreateHub(data);
    }
  };

  const onBackClicked = () => {
    if (isDirty) {
      setShowConfirmModal(true);
    } else {
      history.push(AppUrls.inventories_list);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div role="button" className="back-btn" onClick={onBackClicked}>
            <img src={backArrow} alt="back arrow" />
            {hubInfo ? hubInfo?.hubId : "Creating new hub"}
          </div>
          <div>
            {isEditInventory === true ? (
              <>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border ${
                    !isDirty && !id && "text-muted"
                  }`}
                  disabled={!isDirty && !id}
                  onClick={() => {
                    if (id) {
                      reset();
                      setIsEditInventory(false);
                    } else {
                      reset();
                    }
                  }}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!isDirty || isSubmitSuccessful}
                >
                  {id ? "Save Changes" : "Create"}
                </button>
              </>
            ) : (
              (checkIfAllowed(appState.allowedUrls, "edit", match.path) ||
                checkIfAllowed(appState.allowedUrls, "delete", match.path)) && (
                <Dropdown>
                  <Dropdown.Toggle variant="primary" className="dropdown-btn">
                    <ThreeDots />
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ border: "1px solid #7CB1FF" }}>
                    {checkIfAllowed(
                      appState.allowedUrls,
                      "edit",
                      match.path
                    ) && (
                      <>
                        <Dropdown.Item
                          className="mb-1"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsEditInventory(true);
                          }}
                        >
                          <span>
                            <img src={Edit} alt="edit" className="me-3" />
                          </span>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowHubsModal(true);
                          }}
                          className="mb-1"
                        >
                          <span>
                            <img src={Copy} alt="copy" className="me-3" />
                          </span>
                          Copy items to this Hub
                        </Dropdown.Item>
                      </>
                    )}
                    {/* {checkIfAllowed(appState.allowedUrls, "delete", match.path) &&
                                            <Dropdown.Item
                                                className="mb-1"
                                                onClick={() => {
                                                    setAttached(false)
                                                    setShowDeleteInventory(true)
                                                }}
                                            >
                                                <span>
                                                    <img src={Delete} alt='delete' className='me-3' />
                                                </span>
                                                Delete
                                            </Dropdown.Item>
                                        } */}
                  </Dropdown.Menu>
                </Dropdown>
              )
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row">
              <div className="left-side">
                <SectionTitle editable={false} title="Hub Info" />
                <div className="col-10">
                  <div className="row">
                    <div className="left-side-mid ps-0">
                      <div className="form-group">
                        <label>
                          Hub ID {!id && <span className="text-danger">*</span>}
                        </label>
                        {id ? (
                          <>
                            <p>{hubInfo?.hubId}</p>
                          </>
                        ) : (
                          <>
                            <Form.Control
                              {...register("hubId", { required: true })}
                              className={`form-control ${
                                errors.hubId && "required_field"
                              }`}
                              name="hubId"
                              disabled={isEditInventory === true ? false : true}
                            />

                            {errors["hubId"] && (
                              <p className="text-danger">
                                This field is required
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <label>Status</label>
                      {(!id || (id && isEditInventory)) && (
                        <>
                          <div className="form-group">
                            <Controller
                              control={control}
                              name="isActive"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  {value ? (
                                    <label className="row p-2 ">Active</label>
                                  ) : (
                                    <label className="row p-2"> Disabled</label>
                                  )}
                                  <label
                                    className="switch"
                                    htmlFor="activeCheckbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id="activeCheckbox"
                                      name="active"
                                      onChange={() => onChange(!value)}
                                      checked={value}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </>
                              )}
                            />
                            {errors["isActive"] && (
                              <p className="text-danger">
                                This status is required
                              </p>
                            )}
                          </div>
                        </>
                      )}
                      {id && !isEditInventory && (
                        <div
                          className={`${
                            getValues("isActive") === true
                              ? "active-status"
                              : "disabled-status"
                          } ms-0`}
                        >
                          {getValues("isActive") === true
                            ? "Active"
                            : "Disabled"}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="left-side-mid ps-0">
                      <div className="form-group">
                        <label>
                          Location Description{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          {...register("description", { required: true })}
                          name="description"
                          className={`form-control ${
                            errors.description && "required_field"
                          }`}
                          disabled={isEditInventory === true ? false : true}
                        />
                        {errors["description"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                    <div className="right-side-mid">
                      <div className="right-side-mid">
                        <label>Default Hub</label>
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="isDefault"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id="activeCheckbox"
                                    name="replay"
                                    onChange={() => onChange(!value)}
                                    checked={value}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </>
                            )}
                          />
                          {errors["isDefault"] && (
                            <p className="text-danger">
                              This status is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <Form.Control
                      {...register("address")}
                      className="form-control"
                      name="address"
                      disabled={isEditInventory === true ? false : true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Address 1</label>
                    <Form.Control
                      {...register("address1")}
                      className="form-control"
                      name="address1"
                      disabled={isEditInventory === true ? false : true}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-group col-sm-3">
                      <label>City</label>
                      <Form.Control
                        {...register("city")}
                        className="form-control"
                        name="city"
                        disabled={isEditInventory === true ? false : true}
                      />
                    </div>
                    <div className="form-group col-sm-3">
                      <label>State</label>
                      <Controller
                        control={control}
                        name="state"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(selectedOption: any) => {
                              onChange(selectedOption?.value);
                            }}
                            value={
                              states.find((opt) => opt?.value === value) || null
                            }
                            isDisabled={isEditInventory === true ? false : true}
                            options={statesOptions}
                            menuPlacement="auto"
                            isSearchable={true}
                            isClearable
                            placeholder="Choose"
                          />
                        )}
                      />
                    </div>
                    <div className="form-group col-sm-3">
                      <label>Zip Code</label>
                      <Form.Control
                        {...register("zipCode", {
                          pattern: {
                            value: zipCode,
                            message:
                              "Entered value does not match zip code format",
                          },
                        })}
                        className="form-control"
                        name="zipCode"
                        style={{
                          borderColor: !errors.zipCode ? "" : "red",
                        }}
                        disabled={isEditInventory === true ? false : true}
                      />
                      {errors["zipCode"] && (
                        <span className="text-danger">
                          {errors.zipCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side ">
                <div className="section pb-3">
                  <SectionTitle title="Contact Info" editable={false} />
                  <div className="form-group">
                    <label>Contact</label>
                    <Form.Control
                      {...register("contactPerson")}
                      className="form-control"
                      name="contactPerson"
                      disabled={isEditInventory === true ? false : true}
                    />
                  </div>
                  <div className="form-group">
                    <label>Work No.</label>
                    <Controller
                      control={control}
                      name="workNumber"
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          value={value}
                          onChange={onChange}
                          country={"us" || 1}
                          disabled={isEditInventory === true ? false : true}
                          inputStyle={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label>Fax No.</label>
                    <Controller
                      control={control}
                      name="faxNumber"
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          value={value}
                          onChange={onChange}
                          country={"us" || 1}
                          disabled={isEditInventory === true ? false : true}
                          inputStyle={{ width: "100%" }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <SelectionModal
        modal={"Hub ID"}
        showModal={showHubsModal}
        setShowModal={setShowHubsModal}
        data={inventoryHubs?.data.filter(
          (hub) => hub.locationId !== Number(id)
        )}
        columns={inventoryHubsColumns}
        setRowClicked={(e) => onSelectedHub(e)}
      />
      <ConfirmationModal
        title="Copy items to this Hub"
        message={
          <>
            <div className="mb-4">
              All inventory items will be copied to new Hub.
            </div>
            <div>
              <div className="mb-2">
                From Hub ID: <strong>{selectedHub?.hubId}</strong>
              </div>
              <div>
                To Hub ID: <strong>{hubInfo?.hubId}</strong>
              </div>
            </div>
            <div className="mt-4">Do you wish to continue?</div>
          </>
        }
        showModal={showSubmitCopyItem}
        onClose={() => setShowSubmitCopyItem(false)}
        onConfirm={() => submitCopyHub()}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
      <ConfirmationModal
        title={attached ? "Cannot delete" : "Deleting Inventory Hub"}
        message={
          attached
            ? "This Hub ID is attached to Inventory records or Account records."
            : `Are you sure you want to delete ${hubInfo?.hubId} ?`
        }
        showModal={showDeleteInventory}
        onClose={() => setShowDeleteInventory(false)}
        onConfirm={
          attached
            ? () => setShowDeleteInventory(false)
            : () => removeHub.mutate(hubInfo?.locationId)
        }
        cancelBtnTitle={attached ? "Exit" : "No, Keep"}
        confirmBtnTitle={attached ? "Okay" : "Yes, Delete"}
        type={attached ? "confirmation-primary" : "confirmation-danger"}
      />
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          history.push(AppUrls.inventories_list);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default InventoryDetails;
