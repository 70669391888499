import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { FormProvider, useForm } from "react-hook-form";
import BaseInvSetupItem from "./BaseInvSetupItem";
import { ItemShelve } from "./InventorySetup.model";
import {
  createShelve,
  editShelve,
  getShelves,
} from "./InventorySetup.services";

const NewItemShelf = ({ match }) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { id, name, description } = location.state;
      setSelectedId(id);
      setValue("name", name);
      setValue("description", description);
    }
  }, [location.state]);

  const { error: errorLoadingData } = useQuery<any, ErrorResponse>(
    "get_shelves",
    getShelves
  );

  const formMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const addShelve = useMutation(createShelve, {
    async onSuccess() {
      queryClient.invalidateQueries("get_shelves");
      let notification = {
        variant: "success",
        msg: `Item shelve ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_shelves);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item shelve"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateShelve = useMutation(editShelve, {
    async onSuccess() {
      queryClient.invalidateQueries("get_shelves");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_shelves);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing item shelve"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();

  const onSubmit = (values) => {
    const data: ItemShelve = {
      itemShelveId: selectedId ? selectedId : 0,
      shelve: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addShelve.mutate(data);
    } else {
      updateShelve.mutate(data);
    }
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addShelve.isLoading || updateShelve.isLoading,
    });
  }, [addShelve.isLoading, updateShelve.isLoading, appDispatch]);

  return (
    <>
      <div className="static-page">
        <FormProvider {...formMethods}>
          <form id="new-method-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="  page-title page-title-editable">
              <div className="d-flex flex-row">
                <div className="back-btn">
                  <img
                    src={backArrow}
                    alt="back arrow"
                    onClick={() => history.push(AppUrls.item_shelves)}
                  />
                </div>
                <div>
                  <p className="">Item Shelves</p>
                </div>
              </div>
              <div className="d-flex" style={{ marginLeft: "10px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline custom-outline"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  {!selectedId ? "Add Shelf" : "Save Changes"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="row h-100">
                {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                  checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                  <div className="">
                    <div className="">
                      <BaseInvSetupItem
                        nameTitle={!selectedId ? "Shelf Name" : "Shelf Name"}
                        btnTitle={!selectedId ? "Add Shelf" : "Save Changes"}
                        cancelBtnTitle="Cancel"
                        resetData={resetForm}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>{" "}
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default NewItemShelf;
