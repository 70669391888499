import { useContext, useEffect, useState } from "react";
import addIcon from "img/add-icon.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import * as AppUrls from "constants/AppUrls";
import { Link, useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getUserAccounts } from "../UserManagement.services";
import "./EmployeeDetails.scss";
import { checkIfAllowed, dateFormmaterNoTime } from "global/helpers";
import StateContext from "context/StateContext";
import { UserAccountModel } from "../UserManagement.models";
import { GridColumn } from "elements/AG.models";
import { QueryResult } from "components/Common.models";

import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";
import * as colDefs from "../../../constants/colDefs";

const UserAccounts = ({ match }) => {
  const appDispatch = useContext(DispatchContext);

  const history = useHistory();
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: userAccounts, isLoading } = useQuery<
    QueryResult<UserAccountModel>
  >(["user_accounts", filter], getUserAccounts);

  const [dataGrid, setDataGrid] = useState(null);
  const onRowDoubleClick = (e: UserAccountModel) => {
    let url = `${AppUrls.employees}/${e.userId}`;
    history.push(url);
  };

  const onRefresh = () => {
    queryClient.invalidateQueries(["user_accounts"]);
    let notification = {
      variant: "success",
      msg: "Table is refreshing...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const columns: GridColumn[] = [
    {
      maxWidth: colDefs.lgWidth,

      headerName: "Employee Id",
      field: "employeeId",
    },

    {
      maxWidth: colDefs.mWidth,

      headerName: "Role",
      field: "roles",
      valueGetter: (params) => {
        return params.data.roles.length > 1 ? "Multiple" : params.data.roles;
      },
      cellRendererFramework: (params) =>
        params.value.length > 1 ? "Multiple" : params.value,
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      maxWidth: colDefs.sm_mWidth,
      headerName: "Hub",
      field: "hubs",
      valueGetter: (params) => {
        return params.data.roles[0] === "Admin"
          ? "All"
          : params.data.hubs.length > 1
            ? params.data.hubs?.map((hub) => {
                return hub + " ";
              })
            : params.data.hubs;
      },
    },
    {
      headerName: "Status",
      field: "isActive",
      maxWidth: colDefs.booleanWidth,

      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRendererFramework: (params) =>
        params.data.isActive ? (
          <div className="active-status">active</div>
        ) : (
          <div className="disabled-status">disabled</div>
        ),
    },
    {
      maxWidth: colDefs.dateWidth,

      headerName: "Date Added",
      field: "dateAdded",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.dateAdded);
      },
      cellRendererFramework: (params) =>
        dateFormmaterNoTime(params.data.dateAdded),
    },
    {
      headerName: "Added By",
      field: "addedBy",
    },
  ];

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Employees</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={
              disabledExportBtn || isLoading || userAccounts?.data?.length === 0
            }
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_employee} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Employee
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            data={userAccounts?.data}
            setDataGrid={setDataGrid}
            filters={filter}
            setrowClicked={onRowDoubleClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Employees List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default UserAccounts;
