import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Dropdown, InputGroup, Button, Modal } from "react-bootstrap";
import { ThreeDots } from "react-bootstrap-icons";
import { useMutation, useQuery } from "react-query";
import {
  dateFormmater,
  notificationMessage,
  checkIfAllowed,
} from "global/helpers";
import AG from "elements/AG";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import {
  getHubs,
  getQpay,
  createQpay,
  editQpay,
} from "../SystemSetupServices/SystemSetupServices";
import * as AppUrls from "constants/AppUrls";
import Edit from "img/edit.svg";
import Close from "img/close.svg";
import backArrow from "img/back-arrow.svg";
import Delete from "img/delete.svg";
import SearchIcon from "img/search.svg";
import password_hide from "img/password-hide.svg";
import password_show from "img/password-show.svg";
import DeleteQpay from "./DeleteQpay";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  LOAD_FAIL_MSG,
} from "constants/NotificationMsgs";
import SectionTitle from "elements/SectionTitle";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

const QpayDetails = ({ match }) => {
  /** ****************************************************************
   * States - global
   * ****************************************************************/

  const [data, setData] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [passwordType, setPasswordType] = useState("password");

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === "input" ? "password" : "input");
  };

  /** ****************************************************************
   * Used to fetch action (create/edit qpay)
   * ****************************************************************/

  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const history = useHistory();
  const { id } = useParams();

  /** ****************************************************************
   * Modal
   * ****************************************************************/

  const [modalHubsData, setModalHubsData] = useState(null);
  const [showModalHubs, setShowModalHubs] = useState(false);
  const [hubSelected, setHubSelected] = useState(null);

  const {
    isLoading: isLoadingHubs,
    isSuccess: isSuccessHubs,
    data: hubsList,
  } = useQuery("hubsList", getHubs);

  const hubsMutationMethod = useMutation(getHubs);

  useEffect(() => {
    setModalHubsData(hubsList?.data);
  }, [isSuccessHubs, hubsList]);

  const hub_columns = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
            ${status ? "Active" : "Disabled"}
        </div>
        `;
      },
    },
  ];

  const modal = (
    <Modal
      show={showModalHubs}
      onHide={setShowModalHubs}
      centered
      size="lg"
      dialogClassName="modal-80w"
    >
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title className="copy-items-title ">
          Double click on a Hub to select it
        </Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={() => {
            setShowModalHubs(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="px-0 copy-items-body">
        <div className="h-450">
          <AG
            // data={modalHubsData}
            fetchMethod={hubsMutationMethod}
            columns={hub_columns}
            setrowClicked={setHubSelected}
            pagination={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex  border-top-0">
        <button
          type="button"
          className="btn btn-outline-primary custom-outline"
          onClick={() => {
            setShowModalHubs(false);
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );

  useEffect(() => {
    setShowModalHubs(false);
  }, [hubSelected]);

  /** ****************************************************************
   * Populate data state
   * ****************************************************************/

  const {
    isLoading: isLoadingQpay,
    isSuccess: isSuccessQpay,
    error: errorLoadingQpay,
    data: currentQpay,
  } = useQuery([id], getQpay, {
    enabled: id !== undefined ? true : false,
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingQpay });
  }, [isLoadingQpay, appDispatch]);

  useEffect(() => {
    if (errorLoadingQpay) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} qpay's data.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.qpay_credentials);
    }
  }, [errorLoadingQpay]);

  useEffect(() => {
    if (currentQpay) setData(currentQpay.data);
  }, [isSuccessQpay, currentQpay]);

  /** ****************************************************************
   * Form
   * ****************************************************************/

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty },

    handleSubmit,
    reset,
    register,
    setValue,
  } = methods;

  /** ****************************************************************
   * Populate form with qpay data
   * ****************************************************************/

  useEffect(() => {
    if (isSuccessQpay && data) {
      if (data) {
        reset({
          username: data.username,
          password: data.password,
          branchId: data.branchId,
        });
        if (modalHubsData) {
          let res = modalHubsData.filter((value) => {
            return value.locationId == data.hubId;
          });
          setHubSelected(res[0]);
        }
      }
    }
  }, [isSuccessQpay, data]);

  useEffect(() => {
    if (modalHubsData && data) {
      let res = modalHubsData.filter((value) => {
        return value.locationId == data.hubId;
      });
      setHubSelected(res[0]);
    }
  }, [modalHubsData]);

  /** ****************************************************************
   * Form buttons
   * ****************************************************************/

  const onBackClick = () => {
    if (isDirty && !submitted) setOpenConfirmModal(true);
    else history.push(AppUrls.qpay_credentials);
  };

  const cancelForm = () => {
    reset();
    if (id) {
      setIsDisabled(true);
      setPasswordType("password");
    } else {
      setHubSelected(null);
    }
  };

  const onEditClick = () => {
    setIsDisabled(false);
    setSubmitted(false);
  };

  /** ****************************************************************
   * Create / Edit form submit
   * ****************************************************************/

  const addQpay = useMutation(createQpay, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `QPay credential ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`${AppUrls.qpay_credentials}/${response.data.qPayId}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding credential"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateQpay = useMutation(editQpay, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSubmitted(true);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing credential"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingHubs || updateQpay?.isLoading,
    });
  }, [isLoadingHubs, updateQpay?.isLoading, appDispatch]);

  const onSubmit = async (values) => {
    if (id) {
      let new_data = {
        ...data,
        username: values.username,
        branchId: values.branchId,
        password: values.password,
      };
      updateQpay.mutate(new_data);
    } else {
      let new_data = {
        hubId: hubSelected.locationId,
        branchId: values.branchId,
        username: values.username,
        password: values.password,
      };
      addQpay.mutate(new_data);
    }
  };

  useEffect(() => {
    if (hubSelected) {
      setValue("hubId", hubSelected?.locationId, {
        shouldDirty: !id ? true : false,
      });
      if (!id) errors["hubId"] = "";
    }
  }, [hubSelected]);

  return (
    <>
      {modal}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {id ? `${data?.hub?.hubKey}` : "Create Credentials"}
          </div>
          <div className="d-flex justify-content-between ">
            {(checkIfAllowed(appState.allowedUrls, "edit", match.path) ||
              checkIfAllowed(appState.allowedUrls, "delete", match.path)) &&
              id &&
              isDisabled && (
                <Dropdown>
                  <Dropdown.Toggle variant="primary" className="dropdown-btn">
                    <ThreeDots />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {checkIfAllowed(
                      appState.allowedUrls,
                      "edit",
                      match.path
                    ) && (
                      <Dropdown.Item className="mb-1" onClick={onEditClick}>
                        <span>
                          <img src={Edit} alt="edit" className="dp-icon me-3" />
                        </span>
                        Edit
                      </Dropdown.Item>
                    )}
                    {checkIfAllowed(
                      appState.allowedUrls,
                      "delete",
                      match.path
                    ) && (
                      <Dropdown.Item
                        className="mb-1"
                        onClick={() => setShowDelete(true)}
                      >
                        <span>
                          <img
                            src={Delete}
                            alt="delete"
                            className="dp-icon me-3"
                          />
                        </span>
                        Delete
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            {(!id || (id && !isDisabled)) && (
              <>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border ${
                    !isDirty && !id && "text-muted"
                  }`}
                  disabled={!isDirty && !id}
                  onClick={cancelForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!isDirty}
                >
                  {id ? "Save Changes" : "Create"}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            {showDelete ? (
              <DeleteQpay
                isdelete={showDelete}
                setIsDelete={setShowDelete}
                qpayId={data.qPayId}
              />
            ) : (
              <></>
            )}
            <div className="row">
              <div className="left-side ">
                <SectionTitle
                  editable={false}
                  title={!id ? `New Credentials` : `Qpay Credentials`}
                />
                <div className="row mt-3">
                  <div className="left-side-mid ">
                    <div className="custom-field">
                      <label>
                        Hub ID <span className="text-danger">*</span>
                      </label>
                      {!id ? (
                        <>
                          <InputGroup>
                            <input
                              className="d-none"
                              id="hubId"
                              {...register("hubId", { required: true })}
                              name="hubId"
                            />
                            <div
                              className={`selected-container ${
                                id && !isDisabled && "disabled"
                              } ${errors["hubId"] && "required_field"} `}
                            >
                              {hubSelected?.hubId ||
                                modalHubsData?.filter(
                                  (item) => item.value === data?.hubId
                                )[0]?.label}
                            </div>
                            <Button
                              variant="primary"
                              onClick={() =>
                                !id || isDisabled
                                  ? setShowModalHubs(true)
                                  : null
                              }
                            >
                              <img src={SearchIcon} alt="" />
                            </Button>
                          </InputGroup>
                        </>
                      ) : (
                        <p>{data?.hub?.hubKey}</p>
                      )}
                      <p
                        className={`text-danger ${
                          errors["hubId"] ? "" : "invisible"
                        }`}
                      >
                        This field is required
                      </p>
                    </div>
                    <div className="form-group mt-4">
                      <label>
                        Username <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("username", { required: true })}
                        style={{ height: "38px" }}
                        className={
                          errors["username"]
                            ? "form-control required_field"
                            : "form-control"
                        }
                        disabled={!id || !isDisabled ? false : true}
                      />
                      {errors["username"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="right-side-mid ">
                    <div className="form-group mb-1">
                      <label>
                        Branch ID <span className="text-danger">*</span>
                      </label>
                      <input
                        style={{ height: "38px" }}
                        {...register("branchId", { required: true })}
                        className={
                          errors["branchId"]
                            ? "form-control required_field"
                            : "form-control"
                        }
                        disabled={!id || !isDisabled ? false : true}
                      />
                      <p
                        className={`text-danger ${
                          errors["branchId"] ? "" : "invisible"
                        }`}
                      >
                        This field is required
                      </p>
                    </div>
                    <div className="custom-field mt-4">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <Form.Control
                          {...register("password", { required: true })}
                          className={errors["password"] ? "required_field" : ""}
                          style={{ height: "38px" }}
                          disabled={!id || !isDisabled ? false : true}
                          type={passwordType}
                        />
                        <button
                          disabled={!id || !isDisabled ? false : true}
                          type="button"
                          id="password-reveal"
                          className={` d-flex align-items-center ${
                            !id || !isDisabled
                              ? "input-reveal-password"
                              : "input-reveal-password bg-white"
                          } ${
                            errors["password"]
                              ? "required_field required_field"
                              : ""
                          }`}
                          onClick={!id || !isDisabled ? showHidePassword : null}
                        >
                          {passwordType === "input" ? (
                            <img src={password_show} alt="password show" />
                          ) : (
                            <img src={password_hide} alt="password hide" />
                          )}
                        </button>
                      </div>
                      {errors["password"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
                {id && isDisabled ? (
                  <>
                    <hr className="mt-4" />
                    <div className="row">
                      <div className="left-side-mid">
                        <div className="form-group">
                          <label>Added By</label> {data?.createdBy}
                        </div>
                        <div className="form-group mt-3">
                          <label>Added Date</label>{" "}
                          {dateFormmater(data?.createdDate)}
                        </div>
                      </div>
                      <div className="right-side-mid">
                        <div className="form-group">
                          <label>Last Updated by</label> {data?.modifiedBy}
                        </div>
                        <div className="form-group mt-3">
                          <label>Last Updated on</label>{" "}
                          {dateFormmater(data?.modifiedDate)}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="right-side ">
                <div className="section text-muted pt-1 info-description">
                  <h5>
                    <i>Hub ID</i>
                  </h5>
                  <p>
                    <i>Description</i>
                  </p>
                  <div className="bg-white info-description-inner">
                    <div className="info-description-inner-elements mt-3">
                      <div>Work No.</div>
                      <div className="info-description-inner-elements-value">
                        {hubSelected !== null ? hubSelected?.workNumber : "_ _"}
                      </div>
                    </div>
                    <div className="info-description-inner-elements mt-3">
                      <div>Fax No.</div>
                      <div className="info-description-inner-elements-value">
                        {hubSelected !== null ? hubSelected?.faxNumber : "_ _"}
                      </div>
                    </div>
                    <div className="info-description-inner-elements mt-3">
                      <div>Contact Person</div>
                      <div className="info-description-inner-elements-value">
                        {hubSelected !== null
                          ? hubSelected?.contactPerson
                          : "_ _"}
                      </div>
                    </div>
                    <div className="info-description-inner-elements mt-3">
                      <div>Default?</div>
                      <div className="info-description-inner-elements-value">
                        {hubSelected !== null
                          ? hubSelected?.isDefault
                            ? "Yes"
                            : "No"
                          : "_ _"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={() => {
          setOpenConfirmModal(false);
          history.push(AppUrls.qpay_credentials);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default QpayDetails;
