// import Lookup from "elements/Lookup";
import { useContext, useRef, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useMutation, useQuery } from "react-query";
import {
  addIncome,
  getBanks,
  getPartialReconciliation,
  getReconciliation,
  partialReconciliation,
  postReconciliation,
} from "./AccountingServices";
import DispatchContext from "context/DispatchContext";
import {
  dateFormmaterNoTime,
  formatInput,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import ExpensesModal from "./ExpenseModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import backArrow from "img/back-arrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import AgGrid from "elements/AgGrid";
import FraudModal from "./FraudModal";
import queryString from "query-string";
import FixedAssetModal from "./FixedAssetModal";

export enum LogTypeEnum {
  "Issued check not cleared" = 1,
  "Deposit not cleared" = 2,
  "Invalid check" = 3,
  "Bank Fee" = 4,
  "Interest Income" = 5,
  "Payments do not match" = 6,
  "DifferentDepositAmount" = 7,
  "Unknown withdrawn money" = 8,
  "Unknown deposited money" = 9,
  "Duplicate Check" = 10,
  "Issued Check Cleared" = 11,
  "Deposit In Transit Cleared" = 12,
  "Fraud" = 13,
  "InvalidCheck" = 14,
}

const dictionnary = {
  "Unknown deposited money": ["Deposit In Transit Cleared", "Interest Income"],
  "Unknown withdrawn money": [
    "Expenses",
    "Fixed Asset",
    "Bank Fee",
    "Issued Check Cleared",
    "Fraud",
  ], //Income
  "Deposit not cleared": ["Deposit in transit", "Invalid check", "Fraud"],
  "Issued check not cleared": ["Outstanding check", "Invalid check"],
  "Payments do not match": ["Fraud"],
  "Duplicate Check": ["Fraud"],
};

const BankReconcilation = () => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const { search, state: importedData } = useLocation();
  const { bank, resumeId } = queryString.parse(search);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showForce, setShowForce] = useState<boolean>(false);
  const [showExpenseModal, setShowExpenseModal] = useState<boolean>(false);
  const [showFixedAssetModal, setShowFixedAssetModal] =
    useState<boolean>(false);
  const [showFraudModal, setShowFraudModal] = useState<boolean>(false);
  const [, setCounter] = useState<number>(0);
  // const [selectedAccount, setSelectedAccount] = useState(null)
  const [gridData, setGridData] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const expenseIndex = useRef(null);
  const expensePayment = useRef(null);
  const expenseDate = useRef(null);
  const expenseType = useRef(null);

  useQuery([resumeId], getPartialReconciliation, {
    enabled: !!resumeId,
    onSuccess(data) {
      setValue("endingBalance", data.endingBalance?.toString(), {
        shouldDirty: true,
      });
      setValue("endingDate", data.endingDate, { shouldDirty: true });
      setGridData(
        data.partialReconcilationEntries.map((entry) => ({
          ...entry,
          refNo: entry.refNumber,
          category: LogTypeEnum[entry.reconciliationLogType],
          reason: LogTypeEnum[entry.reconciliationReason],
          payment: !entry.isDeposit ? entry.amount : null,
          deposit: entry.isDeposit ? entry.amount : null,
        }))
      );
      setShowGrid(true);
    },
  });

  const getReceiptsList = useMutation(getReconciliation, {
    async onSuccess(response) {
      setShowGrid(true);
      let result = [];
      let responseClone = response.reconcilationEntries.map((a) => ({ ...a }));
      let importedClone = importedData.map((a) => ({
        ...a,
        refNo: a["Reference"],
        date: a["Post Date"],
        accountName: a["Account Name"],
        deposit: a["Amount"] > 0 ? a["Amount"] : null,
        payment: a["Amount"] < 0 ? Math.abs(a["Amount"]) : null,
      }));
      //Check if imported data matches (same ref, same ref different amount, no match) and remove matched entries
      for (let entry of importedClone) {
        let found = responseClone.find(
          (element) => element.refNo === entry["Reference"]?.toString()
        );
        if (found) {
          if (found.amount === Math.abs(entry["Amount"])) {
            result.push({ ...found, matching: true, reconciled: true });
            responseClone = responseClone.filter(
              (journal) => journal.journalId !== found.journalId
            );
          } else {
            result.push({
              ...found,
              reason: "Payments do not match",
              matching: false,
              reconciled: false,
            });
            responseClone = responseClone.filter(
              (journal) => journal.journalId !== found.journalId
            );
          }
        } else {
          let notFound = { ...entry, matching: false, reconciled: false };
          if (entry["Amount"] > 0)
            notFound = { ...notFound, reason: "Unknown deposited money" };
          else notFound = { ...notFound, reason: "Unknown withdrawn money" };
          result.push(notFound);
        }
      }
      //Add remaining entries
      for (let element of responseClone)
        if (element.deposit)
          result.push({ ...element, reason: "Deposit not cleared" });
        else result.push({ ...element, reason: "Issued check not cleared" });

      setGridData(result);
    },
  });

  const postMutation = useMutation(postReconciliation, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Reconciliation done successfully",
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.bank_reconciliation);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding reconciliation"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const resumeMutation = useMutation(partialReconciliation, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Reconciliation saved successfully",
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.bank_reconciliation);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem saving reconciliation"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const incomeMutation = useMutation(addIncome, {
    async onSuccess(data) {
      let notification = {
        variant: "success",
        msg: "Income added successfully",
      };
      onExpenseCreation(data?.journalEntries[0]?.journalId);
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding income"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const paymentsReceiptColumns = [
    {
      field: "date",
      headerName: "Date",
      cellRenderer: (params) => {
        return dateFormmaterNoTime(params?.value);
      },
    },
    // {
    //     field: "clearedDate",
    //     headerName: "Cleared Date",
    //     cellRenderer: (params) => { return dateFormmaterNoTime(params?.value) },
    // },
    {
      field: "refNo",
      headerName: "Ref Number",
    },
    {
      field: "accountName",
      headerName: "Account",
    },
    {
      field: "payment",
      headerName: "Payment",
      cellRenderer: (params) =>
        params.value ? formatNumber(params.value) : "",
    },
    {
      field: "deposit",
      headerName: "Deposit",
      cellRenderer: (params) =>
        params.value ? formatNumber(params.value) : "",
    },
    {
      field: "matching",
      headerName: "Matching",
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
      width: 120,
    },
    {
      field: "reason",
      headerName: "Reason",
    },
    {
      field: "category",
      headerName: "Category",
      editable: true,
      cellRendererFramework: (params) => {
        if (params.value) return params.value;
        return (
          <select
            style={{
              position: "absolute",
              width: "90%",
              pointerEvents: "none",
              left: "5px",
              top: "15px",
            }}
          ></select>
        );
      },
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      cellEditorSelector: (params) => {
        return {
          component: "agRichSelectCellEditor",
          params: { values: dictionnary[params.data?.reason] ?? [] },
        };
      },
      onCellValueChanged: (params) => {
        if (params?.newValue === "Expenses") {
          expenseIndex.current = params?.node?.rowIndex;
          expensePayment.current = params?.data?.payment;
          expenseDate.current = params?.data?.date;
          expenseType.current = 1;
          setShowExpenseModal(true);
        } else if (params?.newValue === "Fixed Asset") {
          expenseIndex.current = params?.node?.rowIndex;
          expensePayment.current = params?.data?.payment;
          expenseType.current = 2;
          setShowFixedAssetModal(true);
        } else if (params?.newValue === "Income") {
          expenseIndex.current = params?.node?.rowIndex;
          expensePayment.current = params?.data?.payment;
          setShowConfirmation(true);
        } else {
          setGridData((data) =>
            data.map((e, i) => {
              if (i === params?.node?.rowIndex)
                return { ...e, reconciled: true };
              return e;
            })
          );
        }
        // } else if (params?.newValue === 'Fraud') {
        //     expenseIndex.current = params?.node?.rowIndex
        //     setShowFraudModal(true)
        // }
      },
    },
    {
      headerName: "",
      field: "reconciled",
      cellRenderer: "checkboxRenderer",
      onCellClicked: () => {
        setCounter((c) => c + 1);
      },
    },
  ];

  const { data: paymentAccounts } = useQuery("accounts", getBanks);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    getValues,
  } = methods;

  // const onAccountSelection = (e) => {
  //     setSelectedAccount(e)
  //     setOpenSelectionAccount(false)
  //     setValue("bankId", e.bankId)
  // }

  const onExpenseCreation = (id: number) => {
    setShowExpenseModal(false);
    setShowFixedAssetModal(false);
    setGridData((data) =>
      data.map((e, i) => {
        if (i === expenseIndex.current)
          return { ...e, journalId: id, reconciled: true };
        return e;
      })
    );
  };

  const onFraudCreation = (id: number) => {
    setShowFraudModal(false);
    setGridData((data) =>
      data.map((e, i) => {
        if (i === expenseIndex.current)
          return { ...e, fraudAccountId: id, reconciled: true };
        return e;
      })
    );
  };

  const onAddIncome = () => {
    setShowConfirmation(false);
    incomeMutation.mutate({
      operationDate: new Date(),
      amount: expensePayment.current,
      bankId: bank,
    });
  };

  const getClearedBalance = () => {
    let total = 0;
    let reconciled = gridData.filter((data) => data.reconciled === true);
    for (let data of reconciled) total = total + data.payment + data.deposit;
    return total;
  };

  const onCancel = () => {
    reset();
    // setSelectedAccount(null)
    setShowGrid(false);
    setGridData([]);
  };

  const onSubmit = (values) => {
    getReceiptsList.mutate({
      bankId: bank,
      endingDate: values.endingDate.toISOString(),
    });
  };

  const onSave = () => {
    if (
      parseFloat(getValues("endingBalance")?.replace(/,/g, "")) -
        getClearedBalance() ===
      0
    )
      saveChanges();
    else setShowForce(true);
  };

  const saveChanges = () => {
    setShowForce(false);
    if (gridData.find((data) => !data.reconciled)) {
      let notification = {
        variant: "danger",
        msg: "Please specify the categories of the remaining entries before force reconciling",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      let reconciled = gridData
        .filter((data) => data.reconciled === true && data.journalId)
        .map((data) => data.journalId);
      let logs = gridData
        .filter((data) => data.matching !== true)
        .map((data) => ({
          ...data,
          reconciliationLogType:
            LogTypeEnum[data.category] ?? LogTypeEnum[data.reason],
          isDeposit: data.amount > 0,
        }));
      let apiData = {
        reconcilationEntries: reconciled,
        bankId: bank,
        reconciliationDate: new Date(),
        reconciliationLog: {
          reconciliationLogEntries: logs,
        },
        reconciliationDifference:
          parseFloat(getValues("endingBalance")?.replace(/,/g, "")) -
          getClearedBalance(),
      };
      postMutation.mutate(apiData);
    }
  };

  const resumeReconciliation = () => {
    setShowForce(false);
    let apiData = {
      ...getValues(),
      bankId: bank,
      partialReconciliationDate: new Date(),
      reconciliationEntries: gridData.map((data) => ({
        ...data,
        reconciliationLogType: LogTypeEnum[data.category],
        isDeposit: data.amount > 0,
        reconciliationReason: LogTypeEnum[data.reason],
        amount: Math.abs(data.payment || data.deposit),
        refNumber: data.refNo,
      })),
    };
    resumeMutation.mutate(apiData);
  };

  const onBackClick = () => {
    history.push(AppUrls.bank_reconciliation);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Reconcile
            </div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary`}
                onClick={onCancel}
                disabled={!isDirty}
              >
                Cancel
              </button>
              {!resumeId && (
                <button
                  type="submit"
                  className={`btn btn-primary`}
                  disabled={!isDirty || showGrid || importedData?.length === 0}
                >
                  Start Reconciling
                </button>
              )}
              <button
                type="button"
                className={`btn btn-success`}
                onClick={onSave}
                disabled={!showGrid}
              >
                Save Changes
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              {/* <div className="row">
                                <div className="col-sm-3">
                                    <label>Account <span className="text-danger">*</span></label>
                                    <Lookup
                                        onButtonClicked={() => setOpenSelectionAccount(true)}
                                        inputName="bankId"
                                        isRequired={true}
                                        initialData={paymentAccounts?.data?.map(item => ({ ...item, id: item.ledgerId, name: item.accountName }))}
                                        onSelection={onAccountSelection}
                                        inputValue={selectedAccount?.accountName}
                                        isDisabled={showGrid}
                                        hasError={errors["bankId"] ? true : false}
                                    />
                                    {errors["bankId"] && (
                                        <p className="text-danger">This field is required</p>
                                    )}
                                </div>
                                <div className="col-sm-3">
                                    <div className="position-relative h-100 d-flex align-items-end">
                                        <label htmlFor="import-excel" className="import-btn">
                                            <img src={ExcelIcon} className="me-2" alt="" /> Import
                                        </label>
                                        <input ref={inputFile} type="file" id="import-excel" className="invisible position-absolute start-0" onChange={importExcel} />
                                    </div>
                                </div>
                            </div> */}
              <div className="row mt-4">
                <div className="col-sm-3">
                  <label>Beginning Balance</label>
                  <div>
                    {formatNumber(
                      paymentAccounts?.data?.find(
                        (element) => element.bankId === Number(bank)
                      )?.balance ?? 0
                    )}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label>
                      Ending Balance <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("endingBalance", { required: true })}
                      className={`form-control ${
                        errors["endingBalance"] && "required_field"
                      }`}
                      onBlur={(e) =>
                        setValue("endingBalance", formatInput(e.target.value))
                      }
                      disabled={showGrid}
                    />
                    {errors["endingBalance"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
                <div className="col-sm-3 offset-sm-1">
                  <div className="form-group">
                    <label>
                      Ending Date <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="endingDate"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          onChange={onChange}
                          selected={value ? value : null}
                          disabled={showGrid}
                          className={`${
                            errors["endingDate"] && "required_field"
                          }`}
                        />
                      )}
                    />
                  </div>
                  {errors["endingDate"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              {showGrid && (
                <>
                  <div className="row my-5">
                    <div className="col-sm-4">
                      <label className="fs-3">Statement Ending Balance</label>
                      <div className="fs-3">
                        {formatNumber(
                          parseFloat(
                            getValues("endingBalance")?.replace(/,/g, "")
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label className="fs-3">Cleared Balance</label>
                      <div className="fs-3">
                        {formatNumber(getClearedBalance())}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <label className="fs-3">Difference</label>
                      <div className="fs-3">
                        {formatNumber(
                          parseFloat(
                            getValues("endingBalance")?.replace(/,/g, "")
                          ) - getClearedBalance()
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row reconcilation">
                    <AgGrid
                      columns={paymentsReceiptColumns}
                      data={gridData}
                      autoHight={true}
                      autoSize={true}
                      singleClickEdit={true}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      {/* <SelectionModal
                modal={'Account'}
                showModal={openSelectionAccount}
                setShowModal={setOpenSelectionAccount}
                data={paymentAccounts?.data}
                columns={accountsColumns}
                setRowClicked={onAccountSelection}
            /> */}
      <ExpensesModal
        showModal={showExpenseModal}
        onExit={() => setShowExpenseModal(false)}
        onCreation={onExpenseCreation}
        bankId={bank}
        payment={expensePayment.current}
        expenseType={expenseType.current}
        expenseDate={expenseDate.current}
      />
      <FixedAssetModal
        showModal={showFixedAssetModal}
        onExit={() => setShowFixedAssetModal(false)}
        onCreation={onExpenseCreation}
        payment={expensePayment.current}
      />
      <ConfirmationModal
        title="Force Reconcile"
        message={`The cleared balance is different than the statement ending balance. Do you want to force reconcile or resume reconcilation later?`}
        onClose={resumeReconciliation}
        onConfirm={saveChanges}
        cancelBtnTitle="Resume Later"
        confirmBtnTitle="Force Reconcile"
        type="confirmation-primary"
        showModal={showForce}
      />
      <ConfirmationModal
        title="Add Income"
        message={`An income will be added with ${formatNumber(
          expensePayment.current
        )}, do you wish to continue?`}
        onClose={() => setShowConfirmation(false)}
        onConfirm={onAddIncome}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showConfirmation}
      />
      <FraudModal
        showModal={showFraudModal}
        onExit={() => setShowFraudModal(false)}
        onCreation={onFraudCreation}
      />
    </>
  );
};

export default BankReconcilation;
