import api from "components/data/AxiosClient";

export const getAllEod = async () => {
    const response = await api.get(`invoices/eod-cashout`);
    return response.data;
};

export const getEodById = async (id) => {
    const response = await api.get(`invoices/eod-cashout/${id}`);
    return response.data;
};

export const generateEod = async (params) => {
    const response = await api.post(`invoices/generate-eod-cashout`, params);
    return response.data;
};

export const eodCashOut = async (params) => {
    const response = await api.post(`invoices/eod-cashout`, params);
    return response.data;
};

export const getEodReport = async (id) => {
    const response = await api.get(`Report/invoice/eod-cash?eodId=${id}`,);
    return response.data;
};