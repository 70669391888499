import React from "react";
import Select, { ActionMeta } from "react-select";

import { AREA_CODES } from "global/helpers";

type OptionType = {
  value: string;
  label: string;
  width?: number;
};

type CountrySelectProps = {
  onChange: (value: string | null) => void;
  value: string | null;
  isDisabled?: boolean;
  width?: number;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  onChange,
  value,
  isDisabled,
  width,
}) => {
  const options: OptionType[] = AREA_CODES.map((country) => ({
    value: country.countryCode,
    label: country.countryName,
  }));

  const handleChange = (selectedOption: any, actionMeta: any) => {
    if (actionMeta.action === "select-option") {
      onChange((selectedOption as OptionType).value);
    } else if (actionMeta.action === "clear") {
      onChange(null);
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: width ? width : 220,
    }),
  };

  return (
    <Select
      options={options}
      isClearable
      isSearchable={true}
      menuPlacement="auto"
      value={options.find((opt) => opt.value === value)}
      onChange={handleChange}
      styles={customStyles}
      isDisabled={isDisabled}
    />
  );
};

export default CountrySelect;
