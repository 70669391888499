import SelectionModal from "components/SelectionModal/SelectionModal";
import * as AppUrls from "constants/AppUrls";
import { REQUIRED_FIELDS_MSG } from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import Lookup from "elements/Lookup";
import { fixTimezoneOffset, formatInput } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { getCustomers } from "pages/SalesMarketing/Customers/Customers.services";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  CustomerPaymentAPI,
  getPaymentMethods,
} from "./InvoicesServices/InvoicesServices";

const NewPaymentReceipt = () => {
  const appDispatch = useContext(DispatchContext);

  const history = useHistory();

  const accountNumberColumns = [
    {
      field: "accountNumber",
      headerName: "Customer No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "accountType",
      headerName: "Customer Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];
  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    register,
  } = methods;

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openSelectedCustomer, setOpenSelectedCustomer] =
    useState<boolean>(false);

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    "customersList",
    getCustomers
  );

  const { data: paymentMethods } = useQuery(
    "paymentMethods",
    getPaymentMethods
  );

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };
  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("paymentAccountId", e.ledgerId);
  };

  const onCancel = () => {
    reset();
    setOpenSelectedCustomer(null);
    setOpenSelectionLedger(null);
  };

  const ApplyAPICall = useMutation(CustomerPaymentAPI, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: "Payment applied successfully",
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.payment_receipt);
    },
    onError() {
      let notification = {
        variant: "danger",
        msg: "Sorry something went wrong, please try again.",
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const [ledgerData, setLedgerData] = useState(null);
  const onSubmit = async (values) => {
    let obj = {
      paymentMethodId: values.paymentMethodId,
      checkNumber: values.checkNo,
      amountPaid: values.paymentAmount,
      paidDate: fixTimezoneOffset(values.paymentDate),
      depositTo: selectedLedger.ledgerId,
      customerId: selectedCustomer?.customerAccountId,
    };
    ApplyAPICall.mutate(obj);
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingCustomers });
  }, [isLoadingCustomers]);

  useEffect(() => {
    if (selectedCustomer) {
      setValue("customerNo", selectedCustomer["accountNumber"], {
        shouldDirty: true,
      });
      setOpenSelectedCustomer(false);
    }
  }, [selectedCustomer]);

  const ApiCall = useMutation(getLedgers, {
    onSuccess(res) {
      setLedgerData(res?.data);
    },
  });

  useEffect(() => {
    if (selectedPaymentMethod === 1) {
      ApiCall.mutate({ ledgerType: 13 });
    } else {
      ApiCall.mutate({ ledgerType: 14 });
    }
  }, [selectedPaymentMethod]);

  const onBackClick = () => {
    history.push(AppUrls.payment_receipt);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="grid-page">
            <div className="page-title page-title-editable">
              <div className="back-btn " onClick={onBackClick}>
                <img src={backArrow} alt="back arrow" />
                New Payment Receipt
              </div>
              <div>
                <button
                  type="button"
                  onClick={onCancel}
                  className={`btn btn-outline-primary no-border ${
                    !isDirty && "text-muted"
                  }`}
                  disabled={!isDirty}
                >
                  Reset
                </button>
                <button
                  className="btn btn-success"
                  disabled={!isDirty || isSubmitSuccessful}
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="page-content">
                <div className="row w-75">
                  <div className="left-side-mid">
                    <div className="form-group">
                      <label>
                        Customer No. <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelectedCustomer(true)}
                        inputName="customerNo"
                        isRequired={true}
                        initialData={customers?.data?.map((item) => ({
                          ...item,
                          id: item.customerAccountId,
                          name: item.accountNumber,
                        }))}
                        onSelection={setSelectedCustomer}
                        inputValue={getValues("customerNo")}
                        hasError={errors["customerNo"] ? true : false}
                      />
                      {errors["customerNo"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="right-side-mid">
                    {selectedPaymentMethod && (
                      <div className="form-group">
                        <label>
                          Deposit From Account{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Lookup
                          onButtonClicked={() => setOpenSelectionLedger(true)}
                          inputName="paymentAccountId"
                          isRequired={true}
                          initialData={ledgerData?.map((item) => ({
                            ...item,
                            id: item.ledgerId,
                            name: item.accountName,
                          }))}
                          onSelection={onLedgerSelection}
                          inputValue={selectedLedger?.accountName}
                          hasError={errors["paymentAccountId"] ? true : false}
                        />
                        {errors["paymentAccountId"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row w-75 mt-3">
                  <div className="left-side-mid">
                    <div className="form-group">
                      <label>
                        Payment Date <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="paymentDate"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            onChange={onChange}
                            selected={value ? value : null}
                            minDate={new Date()}
                            className={`form-control ${
                              errors["paymentDate"] && "required_field"
                            }`}
                          />
                        )}
                      />
                      {errors["paymentDate"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="right-side-mid">
                    <div className="form-group">
                      <label>
                        Payment Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("paymentAmount", { required: true })}
                        type="number"
                        className={`form-control ${
                          errors["paymentAmount"] && "required_field"
                        }`}
                        onBlur={(e) =>
                          setValue("paymentAmount", formatInput(e.target.value))
                        }
                      />
                      {errors["paymentAmount"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row w-75 mt-3">
                  <div className="left-side-mid">
                    <div className="form-group">
                      <label>Payment Method</label>
                      <Controller
                        control={control}
                        name="paymentMethodId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={paymentMethods?.map((method) => ({
                              label: method.name,
                              value: method.paymentMethodId,
                            }))}
                            isSearchable={true}
                            value={
                              paymentMethods
                                ?.map((method) => ({
                                  label: method.name,
                                  value: method.paymentMethodId,
                                }))
                                ?.find((opt) => opt?.value === value) || null
                            }
                            onChange={(val) => {
                              onChange(val?.value);
                              setSelectedPaymentMethod(val?.value);
                            }}
                            isClearable={true}
                          />
                        )}
                      />
                    </div>
                    {errors["paymentMethodId"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  {selectedPaymentMethod != 1 && (
                    <div className="right-side-mid">
                      <div className="form-group">
                        <label>
                          Check No. <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("checkNo", { required: true })}
                          className={`form-control ${
                            errors["checkNo"] && "required_field"
                          }`}
                        />
                        {errors["checkNo"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelectedCustomer}
        setShowModal={setOpenSelectedCustomer}
        data={customers?.data}
        columns={accountNumberColumns}
        setRowClicked={setSelectedCustomer}
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={ledgerData}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
    </>
  );
};

export default NewPaymentReceipt;
