import { useEffect, useState, useContext } from "react";
import AG from "elements/AG";
import HistoryModal from "./HistoryModal";
import ItemsModal from "./ItemsModal";
import DispatchContext from "context/DispatchContext";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { SalesOrderItem } from "pages/SalesOrder/SalesOrder.model";
import { useMutation } from "react-query";
import { getInventoryItems } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import Lookup from "elements/Lookup";
import { cloneObj, formatInput, formatNumber } from "global/helpers";
import { getItemExpenses } from "pages/Accounting/AccountingServices";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import { Tabs, Tab } from "react-bootstrap";

type Expense = {
  expenseItemId: string;
  name: string;
  amount: number;
};

type ExpensesResponse = { data: Expense[] };

const ItemOrder = ({
  addedItems,
  setAddedItems,
  hubId,
  canEdit,
  dirtyForm = null,
  isSo = false,
  isPo = false,
  customerDetails,
  addedExpenses,
  setAddedExpenses,
}) => {
  const { control } = useFormContext();
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showDuplicateConfirmation, setShowDuplicateConfirmation] =
    useState<boolean>(false);
  const [showQtyConfirmation, setShowQtyConfirmation] =
    useState<boolean>(false);
  const [showChangeQtyConfirmation, setShowChangeQtyConfirmation] =
    useState<boolean>(false);
  const [showPriceConfirmation, setShowPriceConfirmation] =
    useState<boolean>(false);
  const [selectedItemHistory, setSelectedItemHistory] =
    useState<SalesOrderItem>();
  const [cloneObject, setClonedObject] = useState<any>(null);
  const [tempObj, setTempObj] = useState<any>(null);
  const [expenses, setExpenses] = useState<ExpensesResponse>();
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [expenseAmount, setExpenseAmount] = useState("");
  const appDispatch = useContext(DispatchContext);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item Number",
      floatingFilter: false,
      filter: false,
      resizable: true,
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
      floatingFilter: false,
      filter: false,
      resizable: true,
    },
    {
      field: "orderQty",
      headerName: "Order QTY",
      floatingFilter: false,
      filter: false,
      resizable: true,
      editable: canEdit,
      onCellValueChanged: (params) => subTotalChanged(params),
    },
    {
      field: "orderPrice",
      headerName: "Price",
      cellRenderer: (params) =>
        formatNumber(parseFloat(params.value?.toString().replace(/,/g, ""))),
      floatingFilter: false,
      filter: false,
      resizable: true,
      editable: canEdit,
      onCellValueChanged: (params) => subTotalChanged(params),
    },
    {
      field: "isTaxable",
      headerName: "Tax?",
      cellRendererFramework: (params) => (params.value ? "Yes" : "No"),
      floatingFilter: false,
      filter: false,
      resizable: true,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      valueGetter: (params) => {
        return formatNumber(
          parseFloat(
            params.getValue("orderPrice")?.toString().replace(/,/g, "")
          ) * params.getValue("orderQty")
        );
      },
      floatingFilter: false,
      filter: false,
      resizable: true,
    },
    {
      field: "orderWarranty",
      headerName: "Warranty",
      valueSetter: (params) => {
        if (params.newValue < 0) {
          let notification = {
            variant: "danger",
            msg: `Warranty days cannot be a negative value.`,
          };
          appDispatch({ type: "notification", value: notification });
          return false;
        } else {
          params.data.orderWarranty = params.newValue;
          return true;
        }
      },
      floatingFilter: false,
      filter: false,
      resizable: true,
      editable: canEdit,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-link text-decoration-none ms-0"
              disabled={!canEdit}
              onClick={() => {
                setOpenHistory(true);
                setSelectedItemHistory(params.data);
              }}
            >
              History
            </button>
            <button
              type="button"
              className="btn btn-link text-decoration-none ms-0 text-danger"
              disabled={!canEdit}
              onClick={() => removeItem(params.data)}
            >
              Remove
            </button>
          </>
        );
      },
      floatingFilter: false,
      filter: false,
    },
  ];

  const expenseColumns = [
    {
      field: "expenseItemId",
      headerName: "Expense ID",
    },
    {
      field: "expenseName",
      headerName: "Expense Name",
    },
    {
      field: "amount",
      headerName: "Amount",
      valueGetter: (params) => {
        return formatNumber(params.data.amount);
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      cellRendererFramework: (params) => {
        return (
          <button
            type="button"
            className="btn btn-link text-decoration-none ms-0 text-danger"
            disabled={!canEdit}
            onClick={() => removeExpense(params.data)}
          >
            Remove
          </button>
        );
      },
    },
  ];

  const getItems = useMutation(getInventoryItems);
  const getExpensesQuery = useMutation(getItemExpenses, {
    onSuccess: (data) => setExpenses(data),
  });

  const subTotalChanged = (params) => {
    if (!isNaN(params.data.orderQty) && !isNaN(params.data.orderPrice)) {
      if (isSo && Number(params.data.orderQty) > params.data.quantityAtHand) {
        setTempObj({
          id: params.data.inventoyItemHub,
          qty: params.data.orderQty,
          price: params.data.orderPrice,
        });
        setShowChangeQtyConfirmation(true);
      } else {
        let temp = [...addedItems];
        temp.filter(
          (i) => i.inventoyItemHub === params.data.inventoyItemHub
        )[0].orderQty = params.data.orderQty;
        temp.filter(
          (i) => i.inventoyItemHub === params.data.inventoyItemHub
        )[0].orderPrice = params.data.orderPrice;
        setAddedItems(temp);
      }
    } else {
      setAddedItems(cloneObj(cloneObject));
    }
  };

  useEffect(() => {
    if (!hubId) {
      clearItem();
    } else {
      getItems.mutate({ hubId: hubId, itemType: 1 });
      getExpensesQuery.mutate({});
    }
  }, [hubId]);

  const addItem = () => {
    setIsSubmitted(true);
    if (
      isSo &&
      selectedItem["sellingQty"] &&
      Number(selectedItem["orderQty"]) % selectedItem["sellingQty"] !== 0
    ) {
      let notification = {
        variant: "danger",
        msg: "The order qty should be a multiple of item's selling qty.",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      if (
        isSo &&
        selectedItem["orderQty"] > selectedItem["quantityAvailable"]
      ) {
        setShowQtyConfirmation(true);
      } else {
        if (
          selectedItem["orderPrice"] !== "" &&
          selectedItem["orderQty"] !== "" &&
          parseFloat(selectedItem["orderPrice"]?.toString().replace(/,/g, "")) >
            0 &&
          parseInt(selectedItem["orderQty"]) > 0
        ) {
          if (isSo && selectedItem["quantityAvailable"] <= 0) {
            setShowQtyConfirmation(true);
          } else {
            if (
              isSo &&
              Number(selectedItem["orderQty"]) >
                Number(selectedItem["quantityAtHand"])
            ) {
              let notification = {
                variant: "danger",
                msg: "You do not have sufficient quantity for this item.",
              };
              appDispatch({ type: "notification", value: notification });
            } else {
              let totalQtyOrdered =
                (Number(
                  addedItems.find(
                    (item) => item.itemId === selectedItem["itemId"]
                  )?.orderQty
                ) ?? 0) + selectedItem["quantityAvailable"];
              if (
                isSo &&
                (selectedItem["orderQty"] > selectedItem["quantityAvailable"] ||
                  totalQtyOrdered > selectedItem["quantityAvailable"])
              ) {
                setShowQtyConfirmation(true);
              } else if (
                isSo &&
                parseFloat(
                  selectedItem["orderPrice"]?.toString().replace(/,/g, "")
                ) < selectedItem["cost"]
              ) {
                setShowPriceConfirmation(true);
              } else {
                if (
                  isPo &&
                  addedItems.find(
                    (item) => item.itemId === selectedItem["itemId"]
                  ) &&
                  !showDuplicateConfirmation
                ) {
                  setShowDuplicateConfirmation(true);
                } else {
                  let index =
                    addedItems.length > 0
                      ? Math.max(...addedItems.map((o) => o.index)) + 1
                      : 0;
                  let itemToAdd = { ...selectedItem, index: index };

                  let clone = cloneObj(addedItems);
                  setAddedItems([...clone, itemToAdd]);
                  setClonedObject(cloneObj([...clone, itemToAdd]));
                  dirtyForm();
                  clearItem();
                }
              }
            }
          }
        } else {
          let notification = {
            variant: "danger",
            msg: "Both the Price and Quantity are required.",
          };
          appDispatch({ type: "notification", value: notification });
        }
      }
    }
  };

  const addExpense = () => {
    if (selectedExpense && expenseAmount) {
      let newExpense = {
        itemId: addedItems.length + 1,
        expenseItemId: selectedExpense.value,
        expenseName: selectedExpense.label,
        amount: parseFloat(expenseAmount),
      };
      setAddedExpenses((prev) => [...prev, newExpense]);
      setSelectedExpense(null);
      setExpenseAmount("");
    }
  };

  const addQtyItem = () => {
    let index =
      addedItems?.length > 0
        ? Math.max(...addedItems.map((o) => o.index)) + 1
        : 0;
    let itemToAdd = { ...selectedItem, index: index };
    let clone = cloneObj(addedItems);
    setAddedItems([...clone, itemToAdd]);
    setClonedObject(cloneObj([...clone, itemToAdd]));
    clearItem();
    setShowQtyConfirmation(false);
    setShowPriceConfirmation(false);
  };

  const ChangeQtyItem = (decision) => {
    if (!decision) {
      let temp = [...addedItems];
      temp.filter((i) => i.itemId === tempObj.id)[0].orderQty = tempObj.qty;
      temp.filter((i) => i.itemId === tempObj.id)[0].orderPrice = tempObj.price;
      setAddedItems(temp);
    }
    setShowChangeQtyConfirmation(false);
  };

  const removeItem = (item) => {
    let updatedItems = addedItems.filter((added) => {
      return added.index !== item.index;
    });
    setClonedObject(updatedItems);
    setAddedItems(updatedItems);
    dirtyForm();
  };

  const removeExpense = (expense) => {
    let updatedExpenses = addedExpenses.filter(
      (e) => e.expenseItemId !== expense.expenseItemId
    );
    setAddedExpenses(updatedExpenses);
    dirtyForm();
  };

  const removeAllItems = () => {
    setAddedItems([]);
    setClonedObject([]);
  };

  const removeAllExpenses = () => {
    setAddedExpenses([]);
    dirtyForm();
  };

  const clearItem = () => {
    setSelectedItem({});
    setIsSubmitted(false);
    setIsDisabled(true);
  };

  const getQty = () => {
    let total = 0;
    if (addedItems && addedItems.length > 0)
      for (let item of addedItems) total += parseInt(item.orderQty);
    return total;
  };

  const getSubTotal = () => {
    let subTotal = 0;
    if (addedItems && addedItems.length > 0)
      for (let item of addedItems)
        subTotal +=
          parseInt(item.orderQty) *
          parseFloat(item.orderPrice?.toString().replace(/,/g, ""));
    return subTotal;
  };

  const getTotalExpenseAmount = () => {
    let totalExpense = 0;
    if (addedExpenses && addedExpenses.length > 0)
      for (let expense of addedExpenses)
        totalExpense += parseFloat(expense.amount);
    return totalExpense;
  };

  const getOrderTotal = () => {
    const subTotal = getSubTotal();
    const totalExpense = getTotalExpenseAmount();
    const taxAmount = 0; // Assuming tax amount is 0 for now
    return subTotal + totalExpense + taxAmount;
  };

  const onOpenSelection = () => {
    if (hubId) {
      setOpenSelection(true);
    } else {
      let notification = {
        variant: "danger",
        msg: "You need to choose a hub to select items",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const onRowDoubleClicked = (item) => {
    let itemObj = {
      ...item,
      orderPrice: formatInput(
        item.cost?.toString() || item.suggestedSellingPrice?.toString()
      ),
      orderWarranty: "",
      orderQty: "",
    };
    setSelectedItem(itemObj);
    setOpenSelection(false);
    setIsDisabled(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="row gx-4">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>
                  Item <span className="text-danger">*</span>
                </label>
                <Lookup
                  onButtonClicked={onOpenSelection}
                  inputName="item"
                  isDisabled={!canEdit}
                  initialData={getItems?.data?.data
                    ?.filter((item) => item.isActive === true)
                    .map((element) => ({
                      ...element,
                      itemHubId: hubId,
                      id: element.itemId,
                      name: element.itemNumber,
                    }))}
                  onSelection={onRowDoubleClicked}
                  inputValue={selectedItem ? selectedItem["itemNumber"] : ""}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="form-group">
                <label>
                  Price <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className={`input-group-text dollar-icon ${
                        !isDisabled
                          ? `bg-white ${((isSubmitted && selectedItem["orderPrice"] === "") || selectedItem["orderPrice"] === null) && "required_field"}`
                          : "bg-disabled"
                      }`}
                    >
                      $
                    </span>
                  </div>
                  <input
                    min={1}
                    onChange={(e) =>
                      setSelectedItem((prevState) => ({
                        ...prevState,
                        orderPrice: e.target.value,
                      }))
                    }
                    value={selectedItem["orderPrice"] || ""}
                    disabled={isDisabled}
                    onBlur={(e) =>
                      setSelectedItem((prevState) => ({
                        ...prevState,
                        orderPrice: formatInput(e.target.value),
                      }))
                    }
                    className={`form-control border-start-0 ${
                      ((isSubmitted && selectedItem["orderPrice"] === "") ||
                        selectedItem["orderPrice"] === null) &&
                      "required_field"
                    }`}
                  />
                </div>
                {isSubmitted &&
                  !selectedItem["nonTangible"] &&
                  (selectedItem["orderPrice"] === "" ||
                    selectedItem["orderPrice"] === null) && (
                    <p className="text-danger text-nowrap">
                      This field is required
                    </p>
                  )}
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="form-group">
                <label>Warranty Days</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0"
                  onChange={(e) =>
                    Number(e.target.value) < 0
                      ? setSelectedItem((prevState) => ({
                          ...prevState,
                          orderWarranty: Number(e.target.value) * -1,
                        }))
                      : setSelectedItem((prevState) => ({
                          ...prevState,
                          orderWarranty: e.target.value,
                        }))
                  }
                  value={selectedItem["orderWarranty"] || ""}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Description</label>
                <input
                  className="form-control"
                  defaultValue={
                    selectedItem ? selectedItem["itemDescription"] : ""
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="row">
                <div className="col-5 px-0">
                  <div className="form-group">
                    <label>
                      Qty <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={`form-control ${isSubmitted && selectedItem["orderQty"] === "" && "required_field"}`}
                      placeholder="0"
                      min={1}
                      onChange={(e) =>
                        setSelectedItem((prevState) => ({
                          ...prevState,
                          orderQty: e.target.value,
                        }))
                      }
                      value={selectedItem["orderQty"] || ""}
                      disabled={isDisabled}
                    />
                    {isSubmitted && selectedItem["orderQty"] === "" && (
                      <p className="text-danger text-nowrap">
                        This field is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-7 m-auto mt-4">
                  <div className="btn-group">
                    <div>
                      <button
                        type="button"
                        className="btn btn-success"
                        disabled={Object.keys(selectedItem).length < 4}
                        onClick={addItem}
                      >
                        Add
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-primary"
                        type="button"
                        onClick={clearItem}
                        disabled={isDisabled}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              {isSubmitted &&
                (parseInt(selectedItem["orderQty"]) < 1 ||
                  parseFloat(
                    selectedItem["orderPrice"]?.toString().replace(/,/g, "")
                  ) < 1) && (
                  <p className="text-danger">
                    Quantity and price must be positive
                  </p>
                )}
            </div>
          </div>
          <div
            className="col-lg-6 col-sm-12"
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <div className="form-group" style={{ width: "45%" }}>
              <label>Expense</label>
              <Controller
                control={control}
                name="selectedExpense"
                render={({ field: { onChange, value } }) => (
                  <Select
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(selectedOption: any) => {
                      onChange(selectedOption);
                      setSelectedExpense(selectedOption);
                    }}
                    value={selectedExpense}
                    options={expenses?.data?.map((expense) => ({
                      value: expense.expenseItemId,
                      label: expense.name,
                    }))}
                    isDisabled={!canEdit}
                  />
                )}
              />
            </div>
            {selectedExpense && (
              <div
                className="form-group"
                style={{ marginLeft: "3%", width: "40%" }}
              >
                <label>Expense Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={expenseAmount}
                  onChange={(e) => setExpenseAmount(e.target.value)}
                  disabled={!canEdit}
                />
                {isSubmitted && !expenseAmount && (
                  <p className="text-danger text-nowrap">
                    This field is required
                  </p>
                )}
              </div>
            )}
            <div className="row mt-2">
              <div className="col-lg-6 col-sm-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addExpense}
                  disabled={!selectedExpense || !expenseAmount}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 offset-lg-1 col-sm-12">
          <div className="row">
            <div className="col-lg-6 col-sm-12 px-4">
              <div className="d-flex">
                <div className="text-secondary">TOTAL QTY</div>
                <div className="flex-grow-1 text-center">{getQty()}</div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="d-flex">
                <div className="text-secondary">TOTAL ITEMS</div>
                <div className="flex-grow-1 text-center">
                  {addedItems?.length}
                </div>
              </div>
            </div>
            <div className="px-4 mt-2 mb-3">
              <hr />
            </div>
            <div className="col-sm-12 d-flex justify-content-between px-4">
              <div className="text-secondary">SUB-TOTAL</div>
              <div>{formatNumber(getSubTotal())}</div>
            </div>
            <div className="col-sm-12 d-flex justify-content-between px-4">
              <div className="text-secondary">TOTAL EXPENSES</div>
              <div>{formatNumber(getTotalExpenseAmount())}</div>
            </div>
            <div className="col-sm-12 d-flex justify-content-between mt-3 px-4">
              <div className="text-secondary">TAX AMOUNT</div>
              <div>{formatNumber(0)}</div>
            </div>
            <div className="col-sm-12">
              <div className="section mt-3 p-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fs-5">ORDER TOTAL</div>
                  <div className="text-success fs-4">
                    {formatNumber(getOrderTotal())}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <Tabs defaultActiveKey="items" className=" mt-3">
            <Tab eventKey="items" title="Items">
              <button
                style={{
                  marginTop: "-100px",
                  marginLeft: "530px",
                }}
                type="button"
                className={`btn btn-link link-danger text-decoration-none ${(!isDisabled || addedItems?.length === 0) && "d-none"}`}
                onClick={removeAllItems}
              >
                Clear table
              </button>

              <div className="ag-container">
                <AG
                  columns={columns}
                  data={addedItems}
                  autoHeight={true}
                  autoColumns={true}
                  onCellValueChanged={dirtyForm}
                />
              </div>
            </Tab>
            <Tab eventKey="expenses" title="Expenses">
              <button
                style={{
                  marginTop: "-100px",
                  marginLeft: "530px",
                }}
                type="button"
                className={`btn btn-link link-danger text-decoration-none ${(!isDisabled || addedExpenses?.length === 0) && "d-none"}`}
                onClick={removeAllExpenses}
              >
                Clear table
              </button>

              <div className="ag-container">
                <AG
                  columns={expenseColumns}
                  data={addedExpenses}
                  autoHeight={true}
                  autoColumns={true}
                  onCellValueChanged={dirtyForm}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <ItemsModal
        showModal={openSelection}
        setShowModal={setOpenSelection}
        setRowClicked={onRowDoubleClicked}
        selectedHub={hubId}
        isPo={isPo}
      />
      <HistoryModal
        showModal={openHistory}
        setShowModal={setOpenHistory}
        selectedItem={selectedItemHistory}
        customerDetails={customerDetails}
        isSoHistory={isSo}
      />
      <ConfirmationModal
        title="Add Duplicate Item"
        message="You have already previously added this item, do you wish to continue?"
        onClose={() => setShowDuplicateConfirmation(false)}
        onConfirm={() => {
          addItem();
          setShowDuplicateConfirmation(false);
        }}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showDuplicateConfirmation}
      />
      <ConfirmationModal
        title="Add Item"
        message="You do not have the sufficient quantity, would like to add it to the order anyway?"
        onClose={() => setShowQtyConfirmation(false)}
        onConfirm={() => addQtyItem()}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showQtyConfirmation}
      />
      <ConfirmationModal
        title="Change Quantity"
        message="You do not have the sufficient quantity, would like to confirm your change?"
        onClose={() => ChangeQtyItem(false)}
        onConfirm={() => ChangeQtyItem(true)}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showChangeQtyConfirmation}
      />
      <ConfirmationModal
        title="Add Item"
        message="Item price is less than item cost, would like to add it to the order anyway?"
        onClose={() => setShowPriceConfirmation(false)}
        onConfirm={() => addQtyItem()}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showPriceConfirmation}
      />
    </>
  );
};

export default ItemOrder;
