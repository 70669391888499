import { useState, useEffect, useContext } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import password_hide from "img/password-hide.svg";
import password_show from "img/password-show.svg";
import {
  getEmailSettings,
  editEmailSettings,
} from "./SystemSetupServices/SystemSetupServices";
import { useQuery, useMutation } from "react-query";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import { EmailSettings } from "./SystemSetup.models";
import { ErrorResponse } from "components/data/Errors.model";
import { Form } from "react-bootstrap";
import { UPDATE_SUCCESS_MSG } from "constants/NotificationMsgs";

const SystemEmailSettings = ({ match }) => {
  const [data, setData] = useState<EmailSettings>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [passwordType, setPasswordType] = useState<string>("password");

  const methods = useForm<EmailSettings>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: data,
  });
  const {
    formState: { errors, isDirty },

    control,
    handleSubmit,
    reset,
    register,
  } = methods;

  const {
    isLoading: isLoadingEmailSettings,
    error: errorLoadingData,
    data: emailSettings,
  } = useQuery<EmailSettings, ErrorResponse>("settingsList", getEmailSettings, {
    onSuccess(data) {
      if (!data) setCanEdit(false);
    },
  });

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingEmailSettings });
  }, [isLoadingEmailSettings, appDispatch]);

  useEffect(() => {
    setData(emailSettings);
  }, [emailSettings]);

  useEffect(() => {
    if (data)
      reset({
        server: data.server,
        email: data.email,
        fromEmail: data.fromEmail,
        password: data.password,
        port: data.port,
      });
  }, [data]);

  const onCancel = () => {
    reset();
    setCanEdit(!canEdit);
    setPasswordType("password");
  };
  const changeeditableActive = () => {
    setCanEdit(!canEdit);
  };

  const updateSettings = useMutation(editEmailSettings, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing market"),
      };
      appDispatch({ type: "notification", value: notification });
    },
    onSettled: () => {
      setCanEdit(!canEdit);
    },
  });

  const onSubmit = async (new_data: EmailSettings) => {
    updateSettings.mutate(new_data);
  };

  const showHidePassword = (e) => {
    e.preventDefault();
    setPasswordType(passwordType === "input" ? "password" : "input");
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingEmailSettings || updateSettings?.isLoading,
    });
  }, [isLoadingEmailSettings, updateSettings?.isLoading, appDispatch]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="row"
          autoComplete="off"
        >
          <div className="page-title page-title-editable">
            <div>System Email Settings</div>
            <div>
              {canEdit ? (
                checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={changeeditableActive}
                  >
                    Edit
                  </button>
                )
              ) : (
                <>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-primary no-border"
                      onClick={onCancel}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!isDirty}
                    >
                      Save Changes
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row w-50">
                <div className="left-side">
                  <div className="form-group">
                    <label>
                      SMTP Server <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      {...register("server", { required: true })}
                      className={errors["server"] ? "required_field" : ""}
                      name="server"
                      defaultValue={data?.server}
                      disabled={canEdit}
                      type="string"
                    />

                    {errors["server"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      User Email <span className="text-danger">*</span>
                    </label>

                    <Form.Control
                      {...register("email", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format.",
                        },
                      })}
                      className={`${errors["email"] && "required_field"}`}
                      name="email"
                      defaultValue={data?.email}
                      disabled={canEdit}
                      type="string"
                    />
                    {errors["email"] && (
                      <p className="text-danger">{errors["email"].message}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      From Email <span className="text-danger">*</span>
                    </label>

                    <Form.Control
                      {...register("fromEmail", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format.",
                        },
                      })}
                      className={`${errors["fromEmail"] && "required_field"}`}
                      name="fromEmail"
                      defaultValue={data?.fromEmail}
                      disabled={canEdit}
                      type="string"
                      autoComplete="off"
                    />
                    {errors["fromEmail"] && (
                      <p className="text-danger">
                        {errors["fromEmail"].message}
                      </p>
                    )}
                  </div>
                  <div className="custom-field">
                    <label>
                      Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <Form.Control
                        {...register("password", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                        className={`border border-right-0 ${
                          errors["password"] && "required_field"
                        }`}
                        name="password"
                        defaultValue={data?.fromEmail}
                        disabled={canEdit}
                        type={passwordType}
                        autoComplete="new-password"
                      />

                      <button
                        type="button"
                        disabled={canEdit}
                        className={
                          canEdit
                            ? "input-reveal-password"
                            : "input-reveal-password bg-white"
                        }
                        onClick={!canEdit ? showHidePassword : null}
                      >
                        {passwordType === "input" ? (
                          <img src={password_show} alt="password show" />
                        ) : (
                          <img src={password_hide} alt="password hide" />
                        )}
                      </button>
                      {errors["password"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-side ">
                  <div className="form-group">
                    <label>
                      SMTP Port <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      {...register("port", { required: true })}
                      className={errors["port"] ? "required_field" : ""}
                      name="port"
                      defaultValue={data?.fromEmail}
                      disabled={canEdit}
                      type="number"
                    />
                    {errors["port"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="form-group mt-4 pt-2">
                    <label>
                      SMTP Replay : <span className="text-danger">*</span>
                    </label>
                    <div className="form-group mt-2">
                      <Controller
                        control={control}
                        name="relay"
                        render={({ field: { onChange, value } }) => (
                          <>
                            {value ? (
                              <label className="row p-3 ">Yes</label>
                            ) : (
                              <label className="row p-3"> No</label>
                            )}
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="activeCheckbox"
                                name="replay"
                                onChange={() => onChange(!value)}
                                checked={value}
                              />
                              <span className="slider round"></span>
                            </label>
                          </>
                        )}
                      />

                      {errors["relay"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default SystemEmailSettings;
