import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { dateFormmaterNoTime } from "global/helpers";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { getNotifications, notificationSeen } from "./Services/adminServices";

export default function NewUpdates() {
  const appDispatch = useContext(DispatchContext);
  let seenNotification = useRef(null);
  const history = useHistory();

  const dataMutation = useMutation("data", getNotifications);

  const seenMutation = useMutation(notificationSeen, {
    onSuccess() {
      notificationNavigation();
    },
  });

  const columns = [
    {
      field: "message",
      headerName: "Notification",
      flex: 1,
      cellRendererFramework: (params) => {
        return (
          <div
            className={`d-flex justify-content-between w-100 ${
              params.data?.isSeen ? "fw-noraml" : "fw-bold"
            }`}
          >
            <div className="d-flex align-items-center">
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  borderRadius: "50%",
                  background: params.data.isSeen ? "transparent" : "#EB1E4B",
                }}
                className="me-2"
              ></div>
              <div>
                {params.value.split(" ").map((t, index) => {
                  if (t.startsWith("#"))
                    return (
                      <span key={index} style={{ color: "#52CBFF" }}>
                        {t}{" "}
                      </span>
                    );
                  return <span key={index}>{t} </span>;
                })}
              </div>
            </div>
            <div className="pe-3">
              {dateFormmaterNoTime(params.data.createdDate)}
            </div>
          </div>
        );
      },
    },
  ];

  const onRowClick = (e) => {
    seenNotification.current = e;
    if (e.isSeen) notificationNavigation();
    else seenMutation.mutate([e.ecommerceNotificationId]);
  };

  const notificationNavigation = () => {
    let route =
      seenNotification?.current?.referenceType === 1
        ? `${AppUrls.sales_order_list}`
        : `${AppUrls.ecommerce_rma}`;
    history.push(`${route}/${seenNotification.current?.referenceId}`);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>New Updates</p>
        <button
          className="import-btn"
          type="button"
          onClick={exportToExcel}
          disabled={disabledExportBtn || dataGrid?.length === 0}
        >
          <img src={exportIcon} className="me-2" alt="" />
          Export to Excel
        </button>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container full-width-row">
          <AgGrid
            columns={columns}
            fetchMethod={dataMutation}
            data={dataGrid}
            setDataGrid={setDataGrid}
            setrowClicked={onRowClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Notifications List Report"
          />
        </div>
      </div>
    </div>
  );
}
