import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import placeholderimg from "img/image-placeholder.png";
import "./ImageUpload.scss";

const ImageUploadComponent = ({
  active = false,
  initialImg = null,
  setCustomIsDirty,
  setImageData,
}) => {
  const handleChangeStatus = (acceptedFile, status) => {
    const formData = new FormData();
    formData.append("file", acceptedFile.file);
    setCustomIsDirty(true);
    setImageData(formData);
  };
  return (
    <>
      {!active ? (
        <div className="img-container">
          <img
            src={initialImg ?? placeholderimg}
            className={initialImg ? "preview-img" : "placeholderimg"}
            alt="unable-to-load"
          />
        </div>
      ) : (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/*,video/*"
          inputContent={(files, extra) =>
            extra.reject ? "Image and video files only" : "Drag or Select"
          }
          styles={{
            dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
            inputLabel: (files, extra) =>
              extra.reject ? { color: "red" } : {},
          }}
          multiple={false}
          maxFiles={1}
          //@ts-ignore
          init={() => {
            //@ts-ignore
            this.on("maxfilesexceeded", function (file) {
              this.removeAllFiles();
              this.addFile(file);
            });
          }}
        />
      )}
    </>
  );
};

export default ImageUploadComponent;
