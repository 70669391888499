export enum LedgerTypeEnum {
    Asset = 1,
    CashAndCashEquivelant = 2,
    Inventory = 3,
    AccountsReceivable = 4,
    Liabilities = 5,
    AccountsPayable = 6,
    Equity = 7,
    Income = 8,
    Sales = 9,
    Expenses = 10,
    CostOfSales = 11,
    Insurance = 12,
    Cash = 13,
    Bank = 14
}