import AG from "elements/AG";
import { useContext, useEffect, useRef, useState } from "react";
import exportIcon from "img/export.svg";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  checkIfAllowed,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import StateContext from "context/StateContext";
import {
  editLedger,
  getCategories,
  importCategories,
  removeCategory,
} from "./AccountingServices";
import CheckboxTree from "react-checkbox-tree";
import { MdChevronRight, MdKeyboardArrowDown } from "react-icons/md";
import "./Ledgers.scss";
import LedgersModal from "./LedgersModal";
import CategoryModal from "./CategoryModal";
import DispatchContext from "context/DispatchContext";
import SplitModal from "./SplitModal";
import { getBaseCurrency } from "components/currency";

const icons = {
  check: <div className="open-circle"></div>,
  uncheck: <div className="closed-circle"></div>,
  expandClose: <MdChevronRight className="rct-icon rct-icon-expand-close" />,
  expandOpen: <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />,
  parentClose: <div className="closed-circle"></div>,
  parentOpen: <div className="open-circle"></div>,
  leaf: <span className="d-none"></span>,
};

const Ledgers = ({ match }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const [expanded, setExpanded] = useState<string[]>();
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState<string[]>();
  const [gridData, setGridData] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [showSplitModal, setShowSplitModal] = useState<boolean>(false);
  const [showContext, setShowContext] = useState<boolean>(false);
  const [ledgerToEdit, setLedgerToEdit] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [timer, setTimer] = useState(null);
  const [showSearchError, setShowSearchError] = useState(false);
  const categoriesFlat = useRef([]);
  const categoryId = useRef();
  const formValues = useRef(null);
  const totalValue = useRef(0);
  const currentExpanded = useRef([]);
  const categoryParent = useRef(null);
  const rightClickTarget = useRef(null);
  const coordinates = useRef({ top: null, left: null });
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const currency = baseCurrency;

  const { refetch } = useQuery("categories", getCategories, {
    onSuccess(data) {
      let flatCategories = [];
      const transformCategories = (data) => {
        if (data && data.length > 0) {
          for (let category of data) {
            if (category.children?.length === 0) category.children = null;
            if (category.children?.length > 0) {
              transformCategories(category.children);
            }
            flatCategories.push(category);
          }
          setCategories(data);
          categoriesFlat.current = flatCategories;
        }
      };
      transformCategories(data);
      if (formValues?.current?.target) onClick(formValues?.current?.target);
    },
  });

  const columns = [
    {
      field: "accountNumber",
      headerName: "Account",
      minWidth: 100,

      resizable: true,
      valueGetter: (params) => {
        return params.data.accountNumber;
      },
    },
    {
      field: "accountName",
      headerName: "Account name",
      minWidth: 170,

      resizable: true,
      editable: ({ data: { ledgerId } }) => ledgerId === ledgerToEdit,
    },
    {
      field: "isActive",
      headerName: "Status",
      resizable: true,
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
                <div class="${status ? "active-status" : "disabled-status"}">
                  ${status ? "Active" : "Disabled"}
                </div>
              `;
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      cellRenderer: (params) => formatNumber(params.value, currency),
      // valueGetter: (params) => { return `$${params.data?.balance?.toFixed(2) ?? '0.00'}` }
    },
    {
      field: "",
      headerName: "Action",
      resizable: true,
      cellRendererFramework: (params) => {
        return (
          <>
            {ledgerToEdit === params.data.ledgerId ? (
              <>
                <button
                  type="button"
                  className="btn btn-primary p-0 px-2 ms-0"
                  onClick={() => editAccountName(params?.data)}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="btn btn-link text-decoration-none ms-0"
                  onClick={() => setLedgerToEdit(null)}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-link text-decoration-none ms-0"
                onClick={() => editRow(params?.data)}
              >
                Edit
              </button>
            )}
          </>
        );
      },
    },
    {
      field: "",
      headerName: "",
      resizable: true,
      width: 100,
      cellRendererFramework: (params) => {
        return (
          <>
            <button
              type="button"
              className={`btn btn-link text-decoration-none ms-0 ${
                params.data.isActive ? "text-danger" : "text-success"
              }`}
              onClick={() => editAccountStatus(params?.data)}
            >
              {params.data.isActive ? "Disable" : "Activate"}
            </button>
          </>
        );
      },
    },
    {
      field: "",
      headerName: "",
      resizable: true,
      width: 220,
      cellRendererFramework: (params) => {
        return (
          <>
            <button
              type="button"
              className={`btn btn-link text-decoration-none ms-0`}
              onClick={() => viewAccountStatement(params?.data?.ledgerId)}
            >
              View Account Statement
            </button>
          </>
        );
      },
    },
  ];

  const editMutation = useMutation(editLedger, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Account edited successfully",
      };
      refetch();
      setLedgerToEdit(null);
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing account"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const deleteMutation = useMutation(removeCategory, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Category deleted successfully",
      };
      refetch();
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem deleting category"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const importMutation = useMutation(importCategories, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Imported successfully",
      };
      refetch();
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem importing file"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.isSameNode(rightClickTarget.current)) setShowContext(false);
    };
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  const editRow = (e) => {
    setLedgerToEdit(e.ledgerId);
  };

  const viewAccountStatement = (id) => {
    history.push({
      pathname: `/reports/account-statement/${id}/report`,
      state: { isLedger: true },
    });
  };

  const editAccountName = (data) => {
    if (data)
      editMutation.mutate({
        ledgerName: data?.accountName,
        ledgerId: data?.ledgerId,
        isActive: data.isActive,
      });
  };

  const editAccountStatus = (data) => {
    if (data)
      editMutation.mutate({
        ledgerName: data?.accountName,
        ledgerId: data?.ledgerId,
        isActive: !data.isActive,
      });
  };

  const onCheck = (_nodes, target) => {
    let found = categoriesFlat.current.find(
      (category) => category.categoryId === target.value
    );
    if (found) totalValue.current = found.balance;
    if (getLedgers(found, []).length > 0) setGridData(getLedgers(found, []));
    else setGridData([]);
    if (found && found.isLeaf) {
      categoryId.current = found?.categoryId;
      setChecked([target.value]);
      setFormValues(target);
    } else {
      categoryId.current = null;
      setChecked([]);
    }
  };

  const onClick = (target) => {
    let found = categoriesFlat.current.find(
      (category) => category.categoryId === target.value
    );
    if (found) {
      totalValue.current = found.balance;
      categoryParent.current = found;
    }
    if (getLedgers(found, []).length > 0) setGridData(getLedgers(found, []));
    else setGridData([]);
    if (found && found.isLeaf) {
      categoryId.current = found?.categoryId;
      setChecked([target.value]);
      setFormValues(target);
    } else {
      categoryId.current = null;
      setChecked([]);
    }
  };

  const getLedgers = (found, array) => {
    if (found?.ledgers) array.push(...found.ledgers);
    if (found?.children)
      for (let element of found.children)
        if (element.children === null) {
          if (element.ledgers.length > 0) array.push(...element.ledgers);
        } else {
          for (let child of element.children) getLedgers(child, array);
        }
    return array;
  };

  const setFormValues = (target) => {
    let found = categoriesFlat.current.find(
      (category) => category.categoryId === target.value
    );
    let parentNumber = target.label?.toString().replace(/[^\d.-]/g, "");
    let serial = "0001";
    if (found?.ledgers) {
      serial = "";
      let length = found.ledgers.length.toString().length;
      for (let i = length; i < 4; i++) serial += "0";
      serial += found.ledgers.length + 1;
    }
    formValues.current = {
      target: target,
      parent: target?.label,
      accountNumber: parentNumber + serial,
      serial: serial,
      categoryId: categoryId.current,
    };
  };

  const findParents = (category, array) => {
    if (!category.parentId) return array;
    let parent = categoriesFlat.current.find(
      (cat) => cat.value === category.parentId
    );
    return findParents(parent, [...array, parent.value]);
  };

  const inputChanged = (e) => {
    setFilterText(e.target.value);
    clearTimeout(timer);
    setShowSearchError(false);

    const newTimer = setTimeout(() => {
      if (e.target.value) {
        let found = categoriesFlat.current.find((cat) =>
          cat.label.toLowerCase().includes(e.target.value.toLowerCase())
        );
        if (found) {
          appDispatch({ type: "notification", value: null });
          setExpanded(findParents(found, [found.value]));
          onClick(found);
        } else {
          setShowSearchError(true);
        }
      }
    }, 500);

    setTimer(newTimer);
  };

  const onModalClose = () => {
    setShowModal(false);
    setShowCategoryModal(false);
    setShowSplitModal(false);
    refetch();
  };

  const onContextMenu = (e) => {
    e.preventDefault();
    coordinates.current = { top: e.pageY, left: e.pageX };
    currentExpanded.current = expanded;
    rightClickTarget.current = e.target;
    if (e.target) e.target.click();
    setExpanded(currentExpanded.current);
    if (categoryParent.current) setShowContext(true);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToJson = (e) => {
    e.preventDefault();
    downloadFile({
      data: JSON.stringify(categories),
      fileName: "chart-of-accounts.json",
      fileType: "text/json",
    });
  };

  const handleFileChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      let result = e.target.result as string;
      importMutation.mutate(JSON.parse(result));
    };
  };

  const onImportClick = () => {
    let input = document.getElementById("import-json");
    if (input) input.click();
  };

  const onCategoryDelete = () => {
    deleteMutation.mutate(categoryParent.current.categoryId);
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Chart of Accounts</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToJson}
            disabled={!!!categories}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export
          </button>
          <label className="position-relative me-4" htmlFor="import-json">
            <button
              className="btn btn-success"
              type="button"
              onClick={onImportClick}
              disabled={!!categories}
            >
              Import
            </button>
            <input
              type="file"
              className="position-absolute start-0 fs-6 h-100 w-100 d-none"
              id="import-json"
              accept="application/JSON"
              onChange={handleFileChange}
            />
          </label>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <button
              type="button"
              className="btn btn-primary ms-0"
              disabled={!categoryId.current}
              onClick={() => setShowModal(true)}
            >
              Add Account
            </button>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="row">
            <div className="col-sm-2 mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={filterText}
                onChange={inputChanged}
              />
              {showSearchError && (
                <span className="text-danger">Ledger Not Found</span>
              )}
            </div>
          </div>
          <div className="row mb-5">
            <div
              className="col-sm-3 ledgers-tree"
              onContextMenu={onContextMenu}
            >
              {categories &&
                Array.isArray(categories) &&
                categories.length > 0 && (
                  <CheckboxTree
                    nodes={categories}
                    expanded={expanded}
                    onExpand={(nodes) => setExpanded(nodes)}
                    checked={checked}
                    onCheck={onCheck}
                    icons={icons}
                    expandOnClick={true}
                    onlyLeafCheckboxes={true}
                    onClick={onClick}
                  />
                )}
            </div>
            <div className="col-sm-9">
              <div className="h-350">
                <AG data={gridData} columns={columns} />
              </div>
              <div className="w-100 text-end border-bottom mb-2 p-2">
                <b>TOTAL: {formatNumber(totalValue?.current, currency)}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showContext && (
        <div
          className="context-menu"
          style={{
            top: coordinates.current.top,
            left: coordinates.current.left,
          }}
        >
          {categoryParent.current?.ledgers.length === 0 ? (
            <div className="d-flex flex-column align-items-center">
              <button
                className="btn btn-primary ms-0"
                onClick={() => setShowCategoryModal(true)}
              >
                Add Sub Category
              </button>
              {categoryParent.current?.isLeaf && (
                <button
                  className="btn btn-danger ms-0 mt-2"
                  onClick={onCategoryDelete}
                >
                  Delete Category
                </button>
              )}
            </div>
          ) : (
            <>
              {categoryParent.current?.ledgers?.some((e) => e.balance !== 0) ? (
                <div className="alert alert-info mb-0">
                  Can not split on accounts that have transactions
                </div>
              ) : (
                <button
                  className="btn btn-primary ms-0"
                  onClick={() => setShowSplitModal(true)}
                >
                  Split Sub Category
                </button>
              )}
            </>
          )}
        </div>
      )}
      <SplitModal
        showModal={showSplitModal}
        onExit={onModalClose}
        category={categoryParent.current}
      />
      <LedgersModal
        showModal={showModal}
        onExit={onModalClose}
        formValues={formValues.current}
      />
      <CategoryModal
        showModal={showCategoryModal}
        onExit={onModalClose}
        formValues={categoryParent.current}
      />
    </div>
  );
};

export default Ledgers;
