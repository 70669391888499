import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import { dateFormmaterNoTime } from "global/helpers";
import {
  ApprovePurchaseData,
  PurchaseOrderInit,
  ReceivePurchaseData,
} from "../PurchasingOrder.model";

const purchaseOrders = "purchase-orders";

// export const getPurchaseOrders = async () => {
//   return await api.get(purchaseOrders);
// };

export const getPurchaseOrders = async (data) => {
  if (data.PageSize !== undefined && data.PageStart !== undefined) {
    const response = await api.get<any>(
      `${purchaseOrders}?PageSize=${data.PageSize === undefined ? null : data.PageSize}&PageStart=${data.PageStart === undefined || isNaN(data.PageStart) ? 0 : data.PageStart}${data.vendorId != undefined ? `&vendorId=${data.vendorId}` : ""}${data.hubId != undefined ? `&hubId=${data.hubId}` : ""}${data.poStatus != undefined ? `&poStatus=${data.poStatus}` : ""}${data.poType != undefined ? `&poType=${data.poType}` : ""}${data.searchQuery != undefined ? `&searchQuery=${data.searchQuery}` : ""}`
    );
    return response.data;
  } else {
    const response = await api.get<any>(
      `${purchaseOrders}?${data.vendorId != undefined ? `?vendorId=${data.vendorId}` : ""}${data.hubId != undefined ? `&hubId=${data.hubId}` : ""}${data.poStatus != undefined ? `&poStatus=${data.poStatus}` : ""}${data.poType != undefined ? `&poType=${data.poType}` : ""}${data.searchQuery != undefined ? `&searchQuery=${data.searchQuery}` : ""}`
    );
    return response.data;
  }
};

export const getPurchaseOrdersWithCustomStuff = async (data) => {
  if (data.PageSize !== undefined && data.PageStart !== undefined) {
    const response = await api.get<any>(
      `${purchaseOrders}?PageSize=${data.PageSize === undefined ? null : data.PageSize}&PageStart=${data.PageStart === undefined || isNaN(data.PageStart) ? 0 : data.PageStart}${data.vendorId != undefined ? `&vendorId=${data.vendorId}` : ""}${data.hubId != undefined ? `&hubId=${data.hubId}` : ""}${data.poStatus != undefined ? `&poStatus=${data.poStatus}` : ""}${data.poType != undefined ? `&poType=${data.poType}` : ""}`
    );
    return response.data;
  } else {
    const response = await api.get<any>(
      `${purchaseOrders}?${data.vendorId != undefined ? `vendorId=${data.vendorId}` : ""}${data.hubId != undefined ? `&hubId=${data.hubId}` : ""}${data.poStatus != undefined ? `&poStatus=${data.poStatus}` : ""}${data.poType != undefined ? `&poType=${data.poType}` : ""}`
    );
    return response.data;
  }
};

export const getPurchaseOrdersListByVendor = async (data) => {
  if (data.edit) {
    const response = await api.get<any>(
      `${purchaseOrders}?vendorId=${data.id}&poType=2&poStatus=6`
    );
    return response.data.data;
  } else {
    const response = await api.get<any>(
      `${purchaseOrders}?vendorId=${data.id}&poType=2`
    );
    return response.data.data;
  }
};

export const getPurchaseOrderInit = async () => {
  const response = await api.get<PurchaseOrderInit>(`${purchaseOrders}/init`);
  return response.data;
};

export const getPurchaseOrderInv = async (id) => {
  const response = await api.get(`${purchaseOrders}/${id}`);
  return response.data;
};

export const getPurchaseOrder = async (id) => {
  let POId = id.queryKey[0];
  return await api.get(`${purchaseOrders}/${POId}`);
};

export const createPurchaseOrder = async (data: any) => {
  return await api.post(purchaseOrders, data);
};

export const editPurchaseOrders = async (data) => {
  return await api.put(`${purchaseOrders}/${data.purchaseOrderId}`, data);
};

export const receivePurchaseOrder = async (data: ReceivePurchaseData) => {
  return await api.post<boolean>(
    `${purchaseOrders}/${data.poId}/receive`,
    data.items
  );
};

export const closePurchaseOrder = async (id: number) => {
  return await api.get<boolean>(
    `${purchaseOrders}/${id}/approve?approveDate=${dateFormmaterNoTime(new Date())}&close=true`
  );
};

export const voidPurchaseOrder = async (id: number) => {
  return await api.get<boolean>(`${purchaseOrders}/${id}/void`);
};

export const checkSerialValidity = async (data) => {
  return await api.post<boolean>(
    `${purchaseOrders}/serial/check-validity/${data.hub}`,
    data.serials
  );
};

export const checkSerialAvailability = async (data: any[]) => {
  return await api.post<boolean>(
    `${purchaseOrders}/serial/check-availabilty`,
    data
  );
};

export const approvePurchaseOrder = async (data: ApprovePurchaseData) => {
  return await api.get<boolean>(
    `${purchaseOrders}/${data.poId}/approve?approveDate=${data.receiveDate}&close=${data.close}`
  );
};

export const removeSerial = async (data) => {
  return await api.post<boolean>(
    `${purchaseOrders}/${data.poId}/remove-serial/${data.serialId}`
  );
};

export const removeAllSerials = async (data) => {
  return await api.post<boolean>(
    `${purchaseOrders}/remove-serials/${data.poItemId}`
  );
};
