import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { getPurchaseOrderReport } from "./services/ReportServices";
import "./style/common.scss";
import backArrow from "img/back-arrow.svg";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
  formatNumber,
} from "global/helpers";
import DispatchContext from "context/DispatchContext";
import "./style/report.scss";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { useHistory, useLocation } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import { useReactToPrint } from "react-to-print";
import exportIcon from "img/export.svg";
import Filter from "./components/Filter";

export default function PurchaseOrderReport() {
  const history = useHistory();

  const appDispatch = useContext(DispatchContext);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues, setValue } = methods;

  const [reportData, setReportData] = useState(null);

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const [reportName, setReportName] = useState("Purchase Orders Report");
  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const childFnRef2 = useRef(null);
  const [, setRefreshCounter] = useState(0);

  const generateReport = useMutation(getPurchaseOrderReport, {
    async onSuccess(data) {
      let currentData = { ...data };
      setReportData(currentData);
    },
  });

  const columns = [
    [
      {
        field: "elementName",
        headerName: reportData?.groupTitle ?? "",
        cellRenderer: "agGroupCellRenderer",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "quantity",
        headerName: "Total QTY",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return formatNumber(params?.data?.totalCost);
        },
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "totalPrice",
        headerName: "Total Price",
        valueGetter: (params) => {
          return formatNumber(params?.data?.totalPrice);
        },
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
    ],
    [
      {
        field: "purchaseOrderNumber",
        headerName: "PO No",
        cellRenderer: "agGroupCellRenderer",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "quantity",
        headerName: "QTY",
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return formatNumber(params?.data?.totalCost);
        },
      },
    ],
    [
      {
        field: "itemNumber",
        headerName: "Item No",
      },
      {
        field: "itemDescription",
        headerName: "Item Description",
      },
      {
        field: "orderDate",
        headerName: "Order Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params?.data?.orderDate);
        },
      },
      {
        field: "hubKey",
        headerName: "Hub",
      },
      {
        field: "balance",
        headerName: "Balance",
        valueGetter: (params) => {
          return formatNumber(params?.data?.balance);
        },
      },
      {
        field: "quantity",
        headerName: "QTY",
      },
      {
        field: "cost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return formatNumber(params.data.cost);
        },
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const detailCellRenderer = ({ data }) => {
    return (
      <div className={`py-4`}>
        <AgGrid
          data={data?.orders}
          columns={gridColumns[1]}
          pagination={false}
          autoHight={true}
          forPrinting={true}
          masterDetail={true}
          detailCellRenderer={detailCellRenderer2}
          subTable={gridColumns[2]}
          subTableObj={"items"}
          ref={childFnRef1}
        />
      </div>
    );
  };

  const detailCellRenderer2 = ({ data }) => {
    return (
      <div className="py-4">
        <AgGrid
          data={data?.items}
          columns={gridColumns[2]}
          pagination={false}
          autoHight={true}
          forPrinting={true}
          ref={childFnRef2}
        />
      </div>
    );
  };

  const reportPage = useRef(null);

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };
  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSubmit = () => {
    const values = getValues();
    generateReport.mutate({
      groupingElement: values.groupingElement?.value,
      startDate: fixTimezoneOffset(values.startDate),
      endDate: fixTimezoneOffset(values.endDate),
    });
  };

  const options = [
    {
      value: 1,
      label: "Hubs",
    },
    {
      value: 2,
      label: "Items",
    },
    {
      value: 3,
      label: "Categories",
    },
    {
      value: 4,
      label: "Customers",
    },
    {
      value: 5,
      label: "Day",
    },
    {
      value: 6,
      label: "Week",
    },
    {
      value: 7,
      label: "Month",
    },
    {
      value: 8,
      label: "Quarter",
    },
    {
      value: 9,
      label: "Year",
    },
  ];

  useEffect(() => {
    if (!isCustomizedReport) {
      setValue("groupingElement", { value: 1, label: "Hubs" });
      generateReport.mutate({
        groupingElement: 1,
        startDate: fixTimezoneOffset(new Date()),
        endDate: fixTimezoneOffset(new Date()),
      });
    }
  }, [isCustomizedReport]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <Filter
                filters={[0, 1]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                childFnRef2={childFnRef2.current}
                setRefreshCounter={setRefreshCounter}
                groupingElements={options}
              />
              <>
                <div className="container report-filters-div mb-3">
                  <div className="text-end">
                    <button
                      className="import-btn"
                      type="button"
                      onClick={exportToExcel}
                      disabled={
                        disabledExportBtn || reportData?.hubs?.length === 0
                      }
                    >
                      <img src={exportIcon} className="me-2" alt="" />
                      Export grid to Excel
                    </button>
                    <button
                      type="button"
                      className="btn btn-success ms-5"
                      onClick={printDocument}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-printer me-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                      </svg>
                      Print report to PDF
                    </button>
                  </div>
                </div>
                <div className="report-page" ref={reportPage}>
                  <div className="report-header d-flex justify-content-between">
                    <div>
                      <h3>
                        <b>
                          {reportName} grouped by {reportData?.groupTitle}
                        </b>
                      </h3>{" "}
                      <br />
                      <p>
                        <b>Report Date: </b>
                        {dateFormmaterNoTime(reportData?.reportDate)}
                      </p>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={reportData?.imageLogo ?? BlueSeedLogo}
                        className="report-logo"
                      ></img>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    {gridColumns.length > 0 && (
                      <AgGrid
                        data={reportData?.groups}
                        columns={gridColumns[0]}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        subTable={gridColumns[1]}
                        subTableObj={"items"}
                        fileName="Purchase Order Report"
                        ref={childFnRef}
                      />
                    )}
                  </div>
                  <div className="mt-3 pb-3">
                    <div className="d-flex justify-content-between">
                      <div className="label">Total</div>
                      <div className="value">QTY: {reportData?.quantity}</div>
                      <div className="value">
                        Cost: {formatNumber(reportData?.totalCost)}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
