import { useState, useContext, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import DispatchContext from "context/DispatchContext";
import * as AppUrls from "constants/AppUrls";
import { useForm, FormProvider } from "react-hook-form";
import backArrow from "img/back-arrow.svg";
import { useMutation, useQuery } from "react-query";
import { Tabs, Tab } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";

import { SalesOrderItem } from "../SalesOrder/SalesOrder.model";

import { formatInput, notificationMessage } from "global/helpers";

import {
  getPurchaseOrder,
  getPurchaseOrderInit,
  createPurchaseOrder,
  editPurchaseOrders,
  voidPurchaseOrder,
} from "./PurchasingServices/PurchaseServices";

import PoInfo from "./PurchaseOrderTabs/PoInfo";

import ItemOrder from "components/ItemOrderModal/ItemOrder";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

import { VendorModelDTO } from "pages/Vendors/Vendors.models";
import { typeEnum } from "./StatusEnum";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  LOAD_FAIL_MSG,
  REQUIRED_FIELDS_MSG,
} from "constants/NotificationMsgs";
import ShippingMethodModal from "pages/SystemSetup/ShippingMethodModal";

const PurchaseOrderDetail = ({ match }) => {
  const location = useLocation();
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const { id } = useParams();
  const isEdit: boolean = match.path === AppUrls.view_purchase_order;
  const isOpen: boolean = location.status === "isOpen";

  const [data, setData] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [showDiscard, setShowDiscard] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [addedItems, setAddedItems] = useState<Array<SalesOrderItem>>([]);
  const [vendorSelected, setVendorSelected] = useState<VendorModelDTO>(null);
  const [hubSelected, setHubSelected] = useState<any>(null);
  const [showShippingModal, setShowShippingModal] = useState<boolean>(false);
  const [addedExpenses, setAddedExpenses] = useState([]);

  const {
    isSuccess: isSuccessPO,
    error: errorLoadingPO,
    data: currentPO,
  } = useQuery([id], getPurchaseOrder, {
    enabled: id !== undefined ? true : false,
  });

  useEffect(() => {
    if (errorLoadingPO) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} Purchase Order's data.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(
        isOpen
          ? AppUrls.purchase_order_return_list
          : AppUrls.purchase_order_list
      );
    }
  }, [errorLoadingPO]);

  useEffect(() => {
    if (currentPO) {
      setData(currentPO.data);
      setAddedExpenses(currentPO.data.purchaseOrderExpenses || []);
    }
  }, [isSuccessPO, currentPO]);

  const {
    isLoading: isLoadingPurchaseOrderInit,
    error: errorLoadingPurchaseOrderInit,
    data: purchaseOrderInit,
    refetch,
  } = useQuery("purchaseOrderInit", getPurchaseOrderInit);

  useEffect(() => {
    if (errorLoadingPurchaseOrderInit) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} Purchase Order's init data.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(
        isOpen
          ? AppUrls.purchase_order_return_list
          : AppUrls.purchase_order_list
      );
    }
  }, [errorLoadingPurchaseOrderInit]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingPurchaseOrderInit });
  }, [isLoadingPurchaseOrderInit, appDispatch]);

  const onBackClick = () => {
    if (isDirty && !isDisabled) setShowDiscard(true);
    else
      history.push(
        isOpen
          ? AppUrls.purchase_order_return_list
          : AppUrls.purchase_order_list
      );
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty, isSubmitSuccessful },
    setValue,
    register,
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const [currentActive, setCurrentActive] = useState<string>("PoInfo");

  const tabChanged = (e) => {
    setCurrentActive(e);
  };

  const cancelClicked = () => {
    setIsDisabled(true);
    if (id) {
      let url = `${AppUrls.purchase_order_list}/view/${id}`;
      history.push(url);
    } else onCancel();
  };

  const onCancel = () => {
    setAddedItems([]);
    reset();
    setVendorSelected(null);
    setHubSelected(null);
    setCurrentActive("PoInfo");
  };

  useEffect(() => {
    if (data) {
      reset({
        requestDate: data.requestDate ? new Date(data.requestDate) : null,
        targetId: data.targetId,
        hubId: data.hubId,
        termId: data.termId,
        ShippingMethod: {
          value: data.shippingMethodId,
          label: data.shippingMethodName,
        },
        vendorPO: data.vendorInvoicePO,
        poDate: data.poDate ? new Date(data.poDate) : null,
        expShipDate: data.expShipDate ? new Date(data.expShipDate) : null,
        shippingCost: formatInput(data.shippingCost),
        discount: formatInput(data.discount),
        remarks: data.remarks,
        marketCode: data.marketCode,
        currencyCode: data.currencyCode,
        currencyExchangeRate: data.rate,
      });

      let purchaseArray = [];
      data.purchaseOrderItems.forEach((poItem) => {
        purchaseArray.push({
          inventoyItemHub: poItem.itemHubId,
          itemId: poItem.itemId,
          itemNumber: poItem.itemNumber,
          itemDescription: poItem.description,
          orderQty: poItem.quantityOrdered,
          shipQuantity: null,
          openQuantity: null,
          orderPrice: poItem.cost ? formatInput(poItem.cost) : 0,
          tax: poItem.isTaxable,
          orderWarranty: poItem.warranty,
        });
      });

      setAddedItems(purchaseArray);
      setHubSelected({ locationId: data.hubId });
      setAddedExpenses(data.purchaseOrderExpenses || []);
    }
  }, [data]);

  const voidPOFct = useMutation(voidPurchaseOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Purchase Order voided successfully",
      };
      appDispatch({ type: "notification", value: notification });
      history.push(
        `${
          isOpen
            ? AppUrls.purchase_order_return_list
            : AppUrls.purchase_order_list
        }`
      );
    },
  });

  const voidPO = () => {
    voidPOFct.mutate(id);
  };

  const addPurchaseOrder = useMutation(createPurchaseOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Purchase Order ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(
        isOpen
          ? AppUrls.purchase_order_return_list
          : AppUrls.purchase_order_list
      );
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding purchase order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updatePurchaseOrder = useMutation(editPurchaseOrders, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Purchase Order ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`/purchasing/purchase-orders/view/${id}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem editing purchase order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitConfirmed = () => {
    let values = getValues();
    let addedItemsForHadisDTO = addedItems.map((item) => ({
      itemHubId: item.inventoyItemHub,
      price: parseFloat(item.orderPrice?.toString().replace(/,/g, "")),
      warrantyDays:
        item.orderWarranty?.length == 0 ? null : Number(item.orderWarranty),
      description: item.itemDescription,
      orderQuantity: Number(item.orderQty),
    }));

    let addedExpensesForDto = addedExpenses.map((expense) => ({
      expenseItemId: expense.expenseItemId,
      amount: expense.amount,
    }));

    if (addedItemsForHadisDTO.length > 0) {
      let newData = {
        requestDate: values.requestDate,
        targetId: values.targetId,
        hubId: values.hubId,
        poType: isOpen ? typeEnum.Return : typeEnum.Normal,
        termId: values.creditTermId,
        vendorInvoicePO: values.vendorPO,
        poDate: values.poDate,
        expShipDate: values.expShipDate,
        shippingMethodId: values.ShippingMethod?.value,
        shippingCost: values.shippingCost
          ? parseFloat(values.shippingCost?.toString().replace(/,/g, ""))
          : null,
        discount: values.discount
          ? parseFloat(values.discount?.toString().replace(/,/g, ""))
          : null,
        remarks: values.remarks,
        purchaseOrderItems: addedItemsForHadisDTO,
        purchaseOrderExpenses: addedExpensesForDto,
        marketCode: values.marketCode,
        date: new Date(),
        currencyCode: values.currencyCode,
        currencyExchangeRate: values.currencyExchangeRate,
      };

      if (id) {
        let newDataEdit = {
          ...newData,
          purchaseOrderId: data?.purchaseOrderId,
        };
        updatePurchaseOrder.mutate(newDataEdit);
      } else {
        addPurchaseOrder.mutate(newData);
      }
    } else {
      let notification = {
        variant: "danger",
        msg: "Please add Items to create a purchase order.",
      };
      appDispatch({ type: "notification", value: notification });
    }
    setShowWarning(false);
  };

  const onSubmit = () => {
    let price = 0;
    addedItems.forEach(
      (item) =>
        (price +=
          parseFloat(item.orderPrice?.toString().replace(/,/g, "")) *
          Number(item.orderQty))
    );
    if (vendorSelected?.creditLimit && !isOpen) {
      if (
        vendorSelected?.onPO + vendorSelected?.balance + price >
        Number(vendorSelected?.creditLimit)
      ) {
        setShowWarning(true);
      } else {
        submitConfirmed();
      }
    } else {
      submitConfirmed();
    }
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const dirtyForm = () => {
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onTypeCreated = (method) => {
    setValue("ShippingMethod", method, { shouldDirty: true });
    setShowShippingModal(false);
    refetch();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              {isEdit
                ? data?.purchaseOrderNumber
                : `Create ${isOpen ? "PO return" : "Purchase Order"}`}
            </div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary no-border ${
                  !isDirty && !id && "text-muted"
                }`}
                disabled={!isDirty && !id}
                onClick={cancelClicked}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                {isEdit ? "Save Changes" : "Create"}
              </button>
              {isEdit && isDisabled && data && data.poState === 1 && (
                <button
                  type="button"
                  onClick={voidPO}
                  className="btn btn-danger ms-4"
                >
                  Void
                </button>
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <input {...register("dirtyFlag")} type="hidden" />
              <Tabs
                defaultActiveKey="PoInfo"
                activeKey={currentActive}
                onSelect={tabChanged}
              >
                <Tab
                  eventKey="PoInfo"
                  title={<div className="tab-inner-title">PO Info</div>}
                >
                  <PoInfo
                    data={data}
                    purchaseOrderInit={purchaseOrderInit}
                    isDisabled={isDisabled}
                    vendorSelected={vendorSelected}
                    setVendorSelected={setVendorSelected}
                    hubSelected={hubSelected}
                    setHubSelected={setHubSelected}
                    setShowShippingModal={setShowShippingModal}
                  />
                </Tab>
                <Tab
                  eventKey="PoItems"
                  title={
                    <div className="tab-inner-title">
                      PO items
                      <Badge pill className="text-secondary ms-2">
                        {addedItems.length}
                      </Badge>
                    </div>
                  }
                >
                  <ItemOrder
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    hubId={hubSelected?.locationId || getValues("hubId")}
                    canEdit={true}
                    dirtyForm={dirtyForm}
                    customerDetails={vendorSelected}
                    isPo={!isOpen}
                    addedExpenses={addedExpenses}
                    setAddedExpenses={setAddedExpenses}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <ShippingMethodModal
        showModal={showShippingModal}
        onClose={() => setShowShippingModal(false)}
        onConfirm={onTypeCreated}
      />
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={showDiscard}
        onClose={() => setShowDiscard(false)}
        onConfirm={() => {
          setShowDiscard(false);
          history.push(
            isOpen
              ? AppUrls.purchase_order_return_list
              : AppUrls.purchase_order_list
          );
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
      <ConfirmationModal
        title="Credit Limit Exceeded"
        message="Order total exceeds the vendor's credit limit, do you wish to proceed?"
        showModal={showWarning}
        onClose={() => setShowWarning(false)}
        onConfirm={() => {
          submitConfirmed();
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
    </>
  );
};

export default PurchaseOrderDetail;
