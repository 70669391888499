export const initialState = {
  loggedIn: Boolean(localStorage.getItem("blueseedTokens")),
  isLoading: false,
  notification: null,
  selectedHubs: [],
  allowedUrls: [],
  userData: { firstName: "", lastName: "" },
  gridFilters: null,
};

export function ourReducer(state, action) {
  switch (action.type) {
    case "login":
      return { ...state, loggedIn: true };
    case "logout":
      return { ...state, loggedIn: false };
    case "loading":
      return { ...state, isLoading: action.value };
    case "notification":
      return { ...state, notification: action.value };
    case "hubs":
      return { ...state, selectedHubs: action.value };
    case "urls":
      return { ...state, allowedUrls: action.value };
    case "user":
      return { ...state, userData: action.value };
    case "filter":
      return { ...state, gridFilters: action.value };
    default:
      return;
  }
}
