import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import backArrow from 'img/back-arrow.svg';
import { Tabs, Tab } from 'react-bootstrap';
import AG from 'elements/AG';
import { GridColumns } from 'components/Common.models';
import { ErrorResponse } from "components/data/Errors.model";
import { serialNumbers } from "pages/Inventory/Inventory.models";
import { dateFormmater, formatNumber } from "global/helpers";
import DispatchContext from 'context/DispatchContext'
import {
    getSerialNumberById
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import * as AppUrls from "constants/AppUrls";

const SerialNumberDetails = () => {
    const history = useHistory();
    const { id } = useParams();
    const appDispatch = useContext(DispatchContext);
    const [key, setKey] = useState<string>("invoices");

    const {
        isLoading: isLoadingSerialNumber,
        error: errorLoadingSerialNumber,
        data: serialNumber,
    } = useQuery<
        serialNumbers,
        ErrorResponse
    >([id], getSerialNumberById, {
        enabled: id !== undefined ? true : false
    });

    useEffect(() => {
        if (errorLoadingSerialNumber?.errorMessage) {
            let notification = {
                variant: "danger",
                msg: errorLoadingSerialNumber.errorMessage,
            };
            appDispatch({ type: "notification", value: notification });
        }
    }, [errorLoadingSerialNumber, appDispatch]);

    useEffect(() => {
        appDispatch({ type: "loading", value: isLoadingSerialNumber })
    }, [isLoadingSerialNumber, appDispatch])

    const invoices_columns: GridColumns[] = [
        {
            field: "accountNo",
            headerName: "Account No.",
        },
        {
            field: "companyName",
            headerName: "Company Name",
        },
        {
            field: "quantity",
            headerName: "Quantity",
        },
        {
            field: "price",
            headerName: "Price",
            valueGetter: (params) => { return `$${params.data?.balance?.toFixed(2) ?? '0.00'}` }
        },
        {
            field: "invoiceNo",
            headerName: "Invoice No",
        },
        {
            field: "invoiceDate",
            headerName: "Invoice Date",
        },
        {
            field: "stat",
            headerName: "Stat",
        },
    ]

    const so_fullfillment_columns: GridColumns[] = [
        {
            field: "orderNo",
            headerName: "Order No.",
        },
        {
            field: "addDate",
            headerName: "Add Date",
        },
        {
            field: "addUser",
            headerName: "Add User",
        },
        {
            field: "quantity",
            headerName: "Quantity",
        },
        {
            field: "status",
            headerName: "Status",
        },
        {
            field: "invoiceNo",
            headerName: "Invoice No.",
        },
        {
            field: "itemNo",
            headerName: "Item No",
        },
    ]

    const po_history_columns: GridColumns[] = [
        {
            field: "poNumber",
            headerName: "PO Number",
        },
        {
            field: "poDate",
            headerName: "PO Date",
        },
        {
            field: "vendorNo",
            headerName: "Vendor No",
        },
        {
            field: "vendorName",
            headerName: "Vendor Name",
        },
        {
            field: "addUser",
            headerName: "Add User",
        },
        {
            field: "type",
            headerName: "Type",
        },
    ]

    const onBackClicked = () => {
        history.push({ pathname: AppUrls.serial_numbers, state: "filter" });
    };

    return (
        <div className="static-page">
            <div className="page-title page-title-editable">
                <div role="button" className="back-btn" onClick={onBackClicked}>
                    <img src={backArrow} alt="back arrow" />
                    {serialNumber?.serialNo}
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col px-0">
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Item Number</label>
                                <div className="col-lg-8">{serialNumber?.itemNumber}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Description</label>
                                <div className="col-lg-8">{serialNumber?.description}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Received Date</label>
                                <div className="col-lg-8">{dateFormmater(serialNumber?.receiveDate)}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">PO Number</label>
                                <div className="col-lg-8">{serialNumber?.poNumber}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Vendor Name</label>
                                <div className="col-lg-8">{serialNumber?.vendorName}</div>
                            </div>
                        </div>
                        <div className="col px-0">
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Status</label>
                                <div
                                    className={`${serialNumber?.isAvailable ? 'active-status' : 'disabled-status'} col-lg-8`}
                                >
                                    {serialNumber?.isAvailable ? "Inventory" : "Issued"}
                                </div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Warranty</label>
                                <div className="col-lg-8">{serialNumber?.warranty}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Market Code</label>
                                <div className="col-lg-8">{serialNumber?.marketCode}</div>
                            </div>
                        </div>
                        <div className="col px-0">
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Cost</label>
                                <div className="col-lg-8">{formatNumber(serialNumber?.itemCost)}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Hub ID</label>
                                <div className="col-lg-8">{serialNumber?.hubkey}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Issued Date</label>
                                <div className="col-lg-8">{dateFormmater(serialNumber?.createdDate)}</div>
                            </div>
                            <div className="d-flex mb-2">
                                <label className="col-lg-4 pe-3">Vendor PO</label>
                                <div className="col-lg-8">{serialNumber?.vendorPO}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab
                                eventKey="invoices"
                                title={<div className="tab-inner-title">Invoices</div>}
                            >
                                <div className='h-250 px-0'>
                                    <AG
                                        data={[]}
                                        columns={invoices_columns}
                                    />
                                </div>
                            </Tab>
                            <Tab
                                eventKey="sofullfillment"
                                title={<div className="tab-inner-title">SO Fullfillment</div>}
                            >
                                <div className='h-250 px-0'>
                                    <AG
                                        data={[]}
                                        columns={so_fullfillment_columns}
                                    />
                                </div>
                            </Tab>
                            <Tab
                                eventKey="poHistory"
                                title={<div className="tab-inner-title">PO History</div>}
                            >
                                <div className='h-250 px-0'>
                                    <AG
                                        data={[]}
                                        columns={po_history_columns}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SerialNumberDetails;
