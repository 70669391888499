import { useContext, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import * as AppUrls from "../constants/AppUrls";
import { ProtectedRoute, LoggedOutRoute } from "global/routes";
import ShippingMethods from "pages/SystemSetup/ShippingMethods";
import RegionSetup from "pages/SystemSetup/RegionSetup";
import MarketSetup from "pages/SystemSetup/MarketSetup";
import QpayCredentials from "pages/SystemSetup/QpayCredentials";
import SystemEmailSettings from "pages/SystemSetup/SystemEmailSettings";
import EmployeeUserSetup from "pages/UserManagement/EmployeeUserSetup/UserAccounts";
import UserRoleSetup from "pages/UserManagement/userRoleSetup/UserRoleSetup";
import UserRoleDetails from "pages/UserManagement/userRoleSetup/UserRoleDetails";
import UserTypeSetup from "pages/UserManagement/UserTypeSetup";
import Customers from "pages/SalesMarketing/Customers/Customers";
import AccountTypes from "pages/SalesMarketing/AccountType/AccountTypes";
import PortalUsers from "pages/SalesMarketing/PortalUsers/PortalUsers";
import PortalUserInfo from "pages/SalesMarketing/PortalUsers/PortalUserInfo";
import PortalUserDetails from "pages/SalesMarketing/PortalUsers/PortalUserDetails";
import Representatives from "pages/SalesMarketing/Representative/Representatives";
import RepresentativeDetails from "pages/SalesMarketing/Representative/RepresentativeDetails";
import VendorsList from "pages/Vendors/VendorsList";
import VendorDetails from "pages/Vendors/Vendor/VendorDetails";
import InventoryHubs from "pages/Inventory/InventoryHubs/InventoryHubs";
import InventoryDetails from "pages/Inventory/InventoryDetails/InventoryDetails";
import ItemShelves from "pages/Inventory/InventorySetup/ItemShelves";
import InventoryList from "pages/Inventory/Inventory/InventoryList";
import SerialNumbers from "pages/Inventory/Inventory/SerialNumbers";
import SerialNumberDetails from "pages/Inventory/Inventory/SerialNumberDetails";
import NonTangibleItem from "pages/Inventory/Inventory/NonTangibleItem";
import InventoryItem from "pages/Inventory/Inventory/InventoryItem";
import ItemSubClasses from "pages/Inventory/InventorySetup/ItemSubClasses";
import ItemModels from "pages/Inventory/InventorySetup/ItemModels";
import ItemConditions from "pages/Inventory/InventorySetup/ItemConditions";
import ItemManufacturers from "pages/Inventory/InventorySetup/ItemManufacturers";
import ItemColors from "pages/Inventory/InventorySetup/ItemColors";
import QpayDetails from "pages/SystemSetup/QpayDetails/QpayDetails";
import ProductLine from "pages/Inventory/InventorySetup/ProductLines";
import ItemClass from "pages/Inventory/InventorySetup/ItemClasses";
import ItemCategories from "pages/Inventory/InventorySetup/ItemCategories";
import ItemBins from "pages/Inventory/InventorySetup/ItemBins";
import Login from "pages/Login/Login";
import StateContext from "../context/StateContext";
import CustomersDetails from "pages/SalesMarketing/Customers/CustomerDetails";
import SalesOrders from "pages/SalesOrder/SalesOrders/SalesOrders";
import CreateSalesOrder from "pages/SalesOrder/SalesOrders/CreateSalesOrder";
import FulfillSalesOrder from "pages/SalesOrder/SalesOrders/FulfillSalesOrder";
import ApplyTrackingInfo from "pages/SalesOrder/SalesOrders/ApplyTrackingInfo";
import ViewSalesOrder from "pages/SalesOrder/SalesOrders/ViewSalesOrder";
import Profile from "pages/User/Profile";
import AccessDenied from "pages/AccessDenied/AccessDenied";
import ItemHubs from "pages/Inventory/Inventory/ItemHubs";
import SoTrackingNumbersList from "pages/SalesOrder/SoTrackingNumbersList";
import PurchaseOrder from "pages/Purchasing/PurchaseOrder";
import PurchaseOrderDetail from "pages/Purchasing/PurchaseOrderDetail";
import InvoiceList from "pages/Invoices/InvoiceList";
import InvoiceDetails from "pages/Invoices/InvoiceDetails";
import CreateInvoice from "pages/Invoices/CreateInvoice";
import RefundCreditMemo from "pages/Invoices/RefundCreditMemo";
import SalesOrderPickup from "pages/SalesOrder/SalesOrders/SalesOrderPickup";
import VendorTypesList from "pages/Vendors/VendorTypes/VendorTypesList";
import ReceivePurchaseOrder from "pages/Purchasing/ReceivePurchaseOrder";
import CreditMemo from "pages/Invoices/CreditMemo";
import ItemHistory from "pages/Inventory/History/ItemHistory";
import ApprovePurchaseOrder from "pages/Purchasing/ApprovePurchaseOrder";
import PayInvoice from "pages/Invoices/PayInvoice";
import ApplyCreditMemo from "pages/Invoices/ApplyCreditMemo";
import AutoInvoice from "pages/Invoices/AutoInvoice";
import ViewPurchaseOrder from "pages/Purchasing/ViewPurchaseOrder";
import PaymentReceipt from "pages/Invoices/PaymentReceipt";
import SendReceivePayment from "pages/Invoices/SendReceivePayment";
import ReceiveInvoiceList from "pages/Invoices/ReceiveInvoiceList";
import CreateReceiveInvoice from "pages/Invoices/CreateReceieveInvoice";
import ManualIssue from "pages/Inventory/Inventory/ManualIssue";
import ManualReceipt from "pages/Inventory/Inventory/ManualReceipt";
import InterLocation from "pages/Inventory/Inventory/InterLocation";
import QuickLocation from "pages/Inventory/Inventory/QuickLocation";
import AuditTrail from "pages/AuditTrail/AuditTrail";
import Transfer from "pages/Inventory/Inventory/Transfer";
import ImportForm from "./FileImporter/ImportForm";
import SerialTransfer from "pages/Inventory/Inventory/SerialTransfer";
import CreateCreditMemo from "pages/Invoices/CreateCreditMemo";
import PaymentReceiptDetails from "pages/Invoices/PaymentReceiptDetails";
import PaymentReceiptReport from "reports/PaymentReceiptReport";
import SerialNumberListReport from "reports/SerialNumberListReport";
import InventoryValueReport from "reports/InventoryValueReport";
import PurchaseOrderDetailReport from "reports/PurchaseOrderDetailReport";
import EodCash from "pages/Eod/EodCash";
import TransactionalMovement from "reports/TransactionalMovement";
import SalesOrderTransactionReport from "reports/SalesOrderTransactionReport";
import SalesOrderSummaryReport from "reports/SalesOrderSummaryReport";
import SerialTransferReport from "reports/SerialTransferReport";
import OpenOrderSummaryReport from "reports/OpenOrderSummaryReport";
import OpenPOSummaryReport from "reports/OpenPOSummaryReport";
import AccountSalesDetailReport from "reports/AccountSalesDetailReport";
import InventoryOnHandReport from "reports/InventoryOnHandReport";
import InventoryReorderReport from "reports/InventoryReorderReport";
import ReceiptInvoicePayment from "pages/Vendors/ReceiptInvoicePayment";
import ReceiptInvoiceDetails from "pages/Vendors/ReceiptInvoiceDetails";
import OrderManagement from "pages/EcommerceAdmin/OrderManagement";
import OrderDetails from "pages/EcommerceAdmin/OrderDetails";
import RmaList from "pages/EcommerceAdmin/Rma";
import RmaDetails from "pages/EcommerceAdmin/RmaDetails";
import CmManagement from "pages/EcommerceAdmin/CmManagement";
import CmDetails from "pages/EcommerceAdmin/CmDetails";
import NewUpdates from "pages/EcommerceAdmin/NewUpdates";
import Operations from "pages/Accounting/Operations";
import Ledgers from "pages/Accounting/Ledgers";
import TaxSetup from "pages/SystemSetup/TaxSetup";
import AccountStatementsReport from "reports/AccountStatementsReport";
import InventoryItemDetailReport from "reports/InventoryItemDetailReport";
import PurchaseOrderListingReport from "reports/PurchaseOrderListingReport";
import ReceiveInvoiceDetailReport from "reports/ReceiveInvoiceDetailReport";
import InventoryAgingReport from "reports/InventoryAgingReport";
import InvoiceApplicationDetail from "reports/InvoiceApplicationDetail";
import SalesProfitReport from "reports/SalesProfitReport";
import ReceiveInvoiceListReport from "reports/ReceiveInvoiceListReport";
import InvoiceApplicationSummaryReport from "reports/InvoiceApplicationSummaryReport";
import SingleSalesOrderDetailReport from "reports/SingleSalesOrderDetailReport";
import InventoryIssuedReport from "reports/InventoryIssueReport";
import DataReceiptRegisterReport from "reports/DataReceiptRegisterReport";
import ARAgingAsOfDate from "reports/ARAgingAsOfDateReport";
import CreditMemoReport from "reports/CreditMemoReport";
import PastDueInvoicesReport from "reports/PastDueInvoicesReport";
import SerialDetailAsOfDateReport from "reports/SerialDetailAsOfDateReport";
import SOPackingSlipReport from "reports/SOPackingSlipReport";
import Expenses from "pages/Accounting/Expenses";
import ReasonsList from "pages/EcommerceAdmin/ReasonsList";
import OpConfiguration from "pages/Accounting/OpConfiguration";
import BalanceSheetReport from "pages/Accounting/BalanceSheetReport";
import ExpensesList from "pages/Accounting/ExpensesList";
import ViewExpense from "pages/Accounting/ViewExpense";
import JournalsReport from "pages/Accounting/JournalsReport";
import ReportsList from "reports/ReportsList";
import ChequesList from "pages/Accounting/ChequesList";
import Journals from "pages/Accounting/Journals";
import ReceivePaymentList from "pages/Invoices/ReceivePaymentList";
import ReceivePaymentDetail from "pages/Invoices/ReceivePaymentDetail";
import CreateImmediateBill from "pages/Invoices/CreateImmediateBill";
import BankReconcilation from "pages/Accounting/BankReconcilation";
import ComparativeBalanceSheet from "pages/Accounting/ComparativeBalanceSheetReport";
import TrialBalance from "pages/Accounting/TrialBalanceReport";
import BankTypes from "pages/Accounting/BankTypes";
import BanksList from "pages/Accounting/BanksList";
import BankRecord from "pages/Accounting/BankRecord";
import InventoryValuation from "reports/InventoryValuation";
import InventoryValuationSummary from "reports/InventoryValuationSummary";
import SalesReport from "reports/SalesReport";
import ComparativeProfitAndLossReport from "pages/Accounting/ComparativeProfitAndLossReport";
import PurchaseOrderReport from "reports/PurchaseOrderReport";
import StatementList from "pages/Accounting/StatementList";
import StatementDetails from "pages/Accounting/StatementDetails";
import ExpensesReport from "reports/ExpensesReport";
import PurchasingReport from "reports/PurchasingReport";
import BillPaymentReport from "reports/BillPaymentReport";
import ProfitAndLossPercent from "pages/Accounting/ProfitLossPercent";
import ChartOfAccountsReport from "reports/ChartOfAccountsReport";
import SupplierBalanceReport from "reports/SupplierBalanceReport";
import CreateNormalInvoice from "pages/Invoices/CreateNormalInvoice";
import RecurrentTransactions from "pages/Accounting/RecurrentTransactions";
import AddTransaction from "pages/Accounting/AddTransaction";
import CustomerRma from "pages/RMA/CustomerRma";
import CreateRma from "pages/RMA/CreateRma";
import ViewRma from "pages/RMA/ViewRma";
import ReceiveRma from "pages/RMA/ReceiveRma";
import Purging from "pages/SystemSetup/Purging";
import VendorRma from "pages/RMA/VendorRma";
import CreateVRma from "pages/RMA/CreateVRMA";
import ViewVendorRma from "pages/RMA/ViewVendorRma";
import ShipRma from "pages/RMA/ShipRma";
import NewPaymentReceipt from "pages/Invoices/NewPaymentReceipt";
import FixedAssets from "pages/Accounting/FixedAssets";
import FixedAssetsDetails from "pages/Accounting/FixedAssetsDetails";
import ApproveVendorRma from "pages/RMA/ApproveVendorRma";
import CheckInOut from "pages/Payroll/CheckInOut";
import Timesheet from "pages/Payroll/Timesheet";
import TaxSettings from "pages/Payroll/TaxSettings";
import AddTaxSettings from "pages/Payroll/AddTaxSettings";
import Payment from "pages/Payroll/Payment";
import Dashboard from "pages/Dashboard/Dashboard";
import ApproveCustomerRma from "pages/RMA/ApproveCustomerRma";
import PayrollList from "pages/Payroll/PayrollList";
import NewShipping from "pages/SystemSetup/Setup New/NewShipping";
import NewRegionSetup from "pages/SystemSetup/NewRegionSetup";
import NewMarketSetup from "pages/SystemSetup/NewMarketSetup";
import NewAccountTypes from "pages/SalesMarketing/AccountType/NewAccountTypes";
import NewVendorTypes from "pages/Vendors/VendorTypes/NewVendorTypes";
import NewProductLines from "pages/Inventory/InventorySetup/NewProductLines";
import EcomDetails from "pages/SalesMarketing/PortalUsers/ecomsetup/EcomDetails";
import NewItemClasses from "pages/Inventory/InventorySetup/NewItemClasses";
import NewItemSubClass from "pages/Inventory/InventorySetup/NewItemSubClass";
import NewItemCategory from "pages/Inventory/InventorySetup/NewItemCategory";
import NewItemManufacturer from "pages/Inventory/InventorySetup/NewItemManufacturer";
import NewItemCondition from "pages/Inventory/InventorySetup/NewItemCondition";
import NewItemColor from "pages/Inventory/InventorySetup/NewItemColor";
import NewItemShelf from "pages/Inventory/InventorySetup/NewItemShelf";
import NewItemBin from "pages/Inventory/InventorySetup/NewItemBin";
import NewItemModel from "pages/Inventory/InventorySetup/NewItemModel";
import NewTaxSetup from "pages/SystemSetup/NewTaxSetup";
import VendorCM from "pages/Vendors/Vendor/VendorCM";
import VendorCmDetails from "pages/Vendors/Vendor/VendorCmDetails";
import ExchangeRate from "pages/SystemSetup/ExchangeRate";
import EditExchangeRate from "pages/SystemSetup/EditExchangeRate";
import EcomSLideShow from "pages/SalesMarketing/PortalUsers/ecomsetup/EcomSlideShow";
import EcomStandOut from "pages/SalesMarketing/PortalUsers/ecomsetup/EcomStandOut";
import NewUserTypeSetup from "pages/UserManagement/NewUserTypeSetup";
import EmployeeDetails from "pages/UserManagement/EmployeeUserSetup/EmployeeDetails";
import ItemExpenses from "pages/Accounting/ItemExpenses";
import AddEditItemExpense from "pages/Accounting/AddEditItemExpense";

const WebsiteContent = () => {
  const appState = useContext(StateContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (appState.allowedUrls.length > 0 && location.state?.from === "login") {
      history.push(appState.allowedUrls[0].url);
    }
  }, [appState.allowedUrls, history, location]);

  return (
    <>
      <Switch>
        <LoggedOutRoute
          path={AppUrls.login}
          isLoggedIn={appState.loggedIn}
          exact
          component={Login}
        />
        <LoggedOutRoute
          path={`${AppUrls.login}/:company`}
          isLoggedIn={appState.loggedIn}
          exact
          component={Login}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.purging}
          exact
          component={Purging}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.dashboard}
          exact
          component={Dashboard}
        />
        {/**
         *
         * System Setup
         *
         */}
        {/* <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.company_setup}
          exact
          component={CompanySetup}
        /> */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.shipping_methods}
          exact
          component={ShippingMethods}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_shipping_methods}
          exact
          component={NewShipping}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_shipping_methods}
          exact
          component={NewShipping}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.region_setup}
          exact
          component={RegionSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_region_setup}
          exact
          component={NewRegionSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_region_setup}
          exact
          component={NewRegionSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.market_setup}
          exact
          component={MarketSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_market_setup}
          exact
          component={NewMarketSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_market_setup}
          exact
          component={NewMarketSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.tax_setup}
          exact
          component={TaxSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_tax_setup}
          exact
          component={NewTaxSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.qpay_credentials}
          exact
          component={QpayCredentials}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_qpay_credentials}
          exact
          component={QpayDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_qpay_credentials}
          exact
          component={QpayDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.email_settings}
          exact
          component={SystemEmailSettings}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.exchange_rate}
          exact
          component={ExchangeRate}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_exchange_rate}
          exact
          component={EditExchangeRate}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_exchange_rate}
          exact
          component={EditExchangeRate}
        />
        {/**
         *
         * User Management
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.employees}
          exact
          component={EmployeeUserSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_employee}
          exact
          component={EmployeeDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_employee_details}
          exact
          component={EmployeeDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.users_roles}
          exact
          component={UserRoleSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_user_role}
          exact
          component={UserRoleDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_user_role_details}
          exact
          component={UserRoleDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.users_type}
          exact
          component={UserTypeSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_users_type}
          exact
          component={NewUserTypeSetup}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_users_type}
          exact
          component={NewUserTypeSetup}
        />
        {/**
         *
         * Sales Order
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.sales_order_list}
          exact
          component={SalesOrders}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.sale_order_transaction_details_report}
          exact
          component={SalesOrderTransactionReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_sales_order}
          exact
          component={CreateSalesOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_sales_order}
          exact
          component={ViewSalesOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.change_sales_order}
          exact
          component={CreateSalesOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.fulfill_sales_order}
          exact
          component={FulfillSalesOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.apply_tracking_info}
          exact
          component={ApplyTrackingInfo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.so_tracking_numbers_list}
          exact
          component={SoTrackingNumbersList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.sales_order_pickup}
          exact
          component={SalesOrderPickup}
        />
        {/**
         *
         * Purchasing
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.purchase_order_list}
          exact
          component={PurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.purchase_order_listing_report}
          exact
          component={PurchaseOrderListingReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.purchase_order_return_list}
          exact
          component={PurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_purchase_order}
          exact
          component={ReceivePurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_purchase_order}
          exact
          component={PurchaseOrderDetail}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_purchase_order_return}
          exact
          component={PurchaseOrderDetail}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_purchase_order_report}
          exact
          component={PurchaseOrderDetailReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_purchase_order}
          exact
          component={PurchaseOrderDetail}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_purchase_order_details_return}
          exact
          component={ViewPurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_purchase_order_details}
          exact
          component={ViewPurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.approve_po_receipt}
          exact
          component={ApprovePurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.approve_po_return}
          exact
          component={ApprovePurchaseOrder}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.fulfill_po_return}
          exact
          component={ReceivePurchaseOrder}
        />
        {/**
         *
         * Sales & Marketing
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_customers}
          exact
          component={CustomersDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.customer_acc_statement_report}
          exact
          component={AccountStatementsReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.import_customers}
          exact
          component={ImportForm}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_customer}
          exact
          component={CustomersDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.customers}
          exact
          component={Customers}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.customers_report}
          exact
          component={AccountStatementsReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.account_types}
          exact
          component={AccountTypes}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_account_types}
          exact
          component={NewAccountTypes}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_account_types}
          exact
          component={NewAccountTypes}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.portal_users}
          exact
          component={PortalUsers}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_new_portal_user}
          exact
          component={PortalUserInfo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_portal_details}
          exact
          component={PortalUserDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_portal_user}
          exact
          component={PortalUserInfo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.representatives}
          exact
          component={Representatives}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_representatives_details}
          exact
          component={RepresentativeDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_representatives}
          exact
          component={RepresentativeDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.customer_rma}
          exact
          component={CustomerRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_customer_rma}
          exact
          component={CreateRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_customer_rma}
          exact
          component={ViewRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_customer_rma}
          exact
          component={ReceiveRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.approve_customer_rma}
          exact
          component={ApproveCustomerRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.refund_customer_rma}
          exact
          component={ApproveCustomerRma}
        />
        {/**
         *
         * Invoices
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.invoices_list}
          exact
          component={InvoiceList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_invoice}
          exact
          component={CreateInvoice}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_invoice_normal}
          exact
          component={CreateNormalInvoice}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_invoice}
          exact
          component={InvoiceDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_credit_memo}
          exact
          component={InvoiceDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.credit_memo}
          exact
          component={CreditMemo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_credit_memo}
          exact
          component={CreateCreditMemo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.pay_invoice}
          exact
          component={PayInvoice}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.apply_credit_memo}
          exact
          component={ApplyCreditMemo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.payment_receipt}
          exact
          component={PaymentReceipt}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_payment_receipt}
          exact
          component={NewPaymentReceipt}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.payment_receipt_report}
          exact
          component={PaymentReceiptReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_payment_receipt}
          exact
          component={PaymentReceiptDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.auto_invoice}
          exact
          component={AutoInvoice}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.refund_credit_memo}
          exact
          component={RefundCreditMemo}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_invoices_list}
          exact
          component={ReceiveInvoiceList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_invoices_list_report}
          exact
          component={ReceiveInvoiceListReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_invoice_detail_report}
          exact
          component={ReceiveInvoiceDetailReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_receive_invoice_immediate}
          exact
          component={CreateImmediateBill}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_bill}
          exact
          component={CreateImmediateBill}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_receive_invoice}
          exact
          component={CreateReceiveInvoice}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_receive_invoice}
          exact
          component={CreateReceiveInvoice}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_invoice_payment_list}
          exact
          component={ReceivePaymentList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_invoice_payment}
          exact
          component={SendReceivePayment}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receive_invoice_payment_detail}
          exact
          component={ReceivePaymentDetail}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.eod_cashout}
          exact
          component={EodCash}
        />
        {/**
         *
         * Vendors
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.vendors}
          exact
          component={VendorsList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.vendor_types}
          exact
          component={VendorTypesList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_vendor_types}
          exact
          component={NewVendorTypes}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_vendor_types}
          exact
          component={NewVendorTypes}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_vendor}
          exact
          component={VendorDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.vendor_details}
          exact
          component={VendorDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.vendor_credit_memo}
          exact
          component={VendorCM}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_vendor_credit_memo}
          exact
          component={VendorCmDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receipt_invoice_payment}
          exact
          component={ReceiptInvoicePayment}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.receipt_invoice_details}
          exact
          component={ReceiptInvoiceDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.vendor_rma}
          exact
          component={VendorRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.approve_vendor_rma}
          exact
          component={ApproveVendorRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.refund_vendor_rma}
          exact
          component={ApproveVendorRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_vendor_rma}
          exact
          component={CreateVRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_vendor_rma}
          exact
          component={ViewVendorRma}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ship_vendor_rma}
          exact
          component={ShipRma}
        />
        {/**
         *
         * Inventory
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_list}
          exact
          component={InventoryList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_list_report}
          exact
          component={InventoryValueReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.serial_numbers}
          exact
          component={SerialNumbers}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.serial_numbers_report}
          exact
          component={SerialNumberListReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.serial_numbers_details}
          exact
          component={SerialNumberDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.manual_inventory_issue}
          exact
          component={ManualIssue}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.manual_inventory_receipt}
          exact
          component={ManualReceipt}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inter_location_transfer}
          exact
          component={InterLocation}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.quick_location_transfer}
          exact
          component={QuickLocation}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_transfers}
          exact
          component={Transfer}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_serial_transfer}
          exact
          component={SerialTransfer}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.non_tangible_item}
          exact
          component={NonTangibleItem}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.non_tangible_item_info}
          exact
          component={NonTangibleItem}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_inventory_serialized}
          exact
          component={InventoryItem}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_serialized_details}
          exact
          component={InventoryItem}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_item_detail_report}
          exact
          component={InventoryItemDetailReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_inventory_nonserialized}
          exact
          component={InventoryItem}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_nonserialized_details}
          exact
          component={InventoryItem}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventories_list}
          exact
          component={InventoryHubs}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.create_new_inventory}
          exact
          component={InventoryDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.product_line}
          exact
          component={ProductLine}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_product_line}
          exact
          component={NewProductLines}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_product_line}
          exact
          component={NewProductLines}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_class}
          exact
          component={ItemClass}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_class}
          exact
          component={NewItemClasses}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_class}
          exact
          component={NewItemClasses}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.get_inventory_details}
          exact
          component={InventoryDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_shelves}
          exact
          component={ItemShelves}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_shelves}
          exact
          component={NewItemShelf}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_shelves}
          exact
          component={NewItemShelf}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_models}
          exact
          component={ItemModels}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_models}
          exact
          component={NewItemModel}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_models}
          exact
          component={NewItemModel}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_condition}
          exact
          component={ItemConditions}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_condition}
          exact
          component={NewItemCondition}
        />{" "}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_condition}
          exact
          component={NewItemCondition}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_sub_class}
          exact
          component={ItemSubClasses}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_sub_class}
          exact
          component={NewItemSubClass}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_sub_class}
          exact
          component={NewItemSubClass}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_sub_class}
          exact
          component={NewItemSubClass}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_manufacturer}
          exact
          component={ItemManufacturers}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_manufacturer}
          exact
          component={NewItemManufacturer}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_manufacturer}
          exact
          component={NewItemManufacturer}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_category}
          exact
          component={ItemCategories}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_category}
          exact
          component={NewItemCategory}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_color}
          exact
          component={ItemColors}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_color}
          exact
          component={NewItemColor}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_color}
          exact
          component={NewItemColor}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_bins}
          exact
          component={ItemBins}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.new_item_bins}
          exact
          component={NewItemBin}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.edit_item_bins}
          exact
          component={NewItemBin}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.user_profile}
          exact
          component={Profile}
        />
        <Route path={AppUrls.access_denied} exact component={AccessDenied} />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_serialized_hubs}
          exact
          component={ItemHubs}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_nonserialized_hubs}
          exact
          component={ItemHubs}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_history}
          exact
          component={ItemHistory}
        />
        {/**
         *
         * Audit
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.audit_trail}
          exact
          component={AuditTrail}
        />
        {/**
         *
         * Accounting
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ledgers}
          exact
          component={Ledgers}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.bank_types}
          exact
          component={BankTypes}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.banks}
          exact
          component={BanksList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_bank}
          exact
          component={BankRecord}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.bank_details}
          exact
          component={BankRecord}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.bank_reconciliation}
          exact
          component={StatementList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_bank_reconciliation}
          exact
          component={BankReconcilation}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_bank_reconciliation}
          exact
          component={StatementDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.operations}
          exact
          component={Operations}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.journals_list}
          exact
          component={Journals}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.recurrent_transactions}
          exact
          component={RecurrentTransactions}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_transactions}
          exact
          component={AddTransaction}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.expenses}
          exact
          component={ExpensesList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_expenses}
          exact
          component={Expenses}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_expenses}
          exact
          component={ViewExpense}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.cheque}
          exact
          component={ChequesList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_cheque}
          exact
          component={Expenses}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_cheque}
          exact
          component={ViewExpense}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.op_config}
          exact
          component={OpConfiguration}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.item_expenses}
          exact
          component={ItemExpenses}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_item_expense}
          exact
          component={AddEditItemExpense}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_item_expense}
          exact
          component={AddEditItemExpense}
        />
        {/**
         *
         * Payroll
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.check_in_out}
          exact
          component={CheckInOut}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.timesheet}
          exact
          component={Timesheet}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.tax_settings}
          exact
          component={TaxSettings}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.payment}
          exact
          component={Payment}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.payroll_list}
          exact
          component={PayrollList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_tax_settings}
          exact
          component={AddTaxSettings}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.comparative_balance_sheet}
          exact
          component={ComparativeBalanceSheet}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.purchase_order_report}
          exact
          component={PurchaseOrderReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.trial_balance_report}
          exact
          component={TrialBalance}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.balance_sheet_report}
          exact
          component={BalanceSheetReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.profit_and_loss}
          exact
          component={ProfitAndLossPercent}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.comparative_profit_and_loss}
          exact
          component={ComparativeProfitAndLossReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.journals_report}
          exact
          component={JournalsReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.fixed_assets}
          exact
          component={FixedAssets}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_fixed_assets}
          exact
          component={FixedAssetsDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.add_fixed_assets}
          exact
          component={FixedAssetsDetails}
        />
        {/**
         *
         * Ecommerce
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.Content_Management_System}
          exact
          component={EcomDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.Content_Management_System_ss_new}
          exact
          component={EcomSLideShow}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.Content_Management_System_so_new}
          exact
          component={EcomStandOut}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_so}
          exact
          component={OrderManagement}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_so_id}
          exact
          component={OrderDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_rma}
          exact
          component={RmaList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_rma_id}
          exact
          component={RmaDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_cm}
          exact
          component={CmManagement}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_cm_id}
          exact
          component={CmDetails}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_updates}
          exact
          component={NewUpdates}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ecommerce_reasons}
          exact
          component={ReasonsList}
        />
        {/**
         *
         * Reports
         *
         */}
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={"/reports"}
          exact
          component={ReportsList}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.transactional_movement}
          exact
          component={TransactionalMovement}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.sales_order_summary_report}
          exact
          component={SalesOrderSummaryReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.serial_transfer_report}
          exact
          component={SerialTransferReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.open_order_summary_report}
          exact
          component={OpenOrderSummaryReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.open_po_summary_report}
          exact
          component={OpenPOSummaryReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.account_sales_details_report}
          exact
          component={AccountSalesDetailReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_on_hand_report}
          exact
          component={InventoryOnHandReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_valuation_report}
          exact
          component={InventoryValuation}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.chart_of_accounts_report}
          exact
          component={ChartOfAccountsReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.supplier_balance_report}
          exact
          component={SupplierBalanceReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_valuation_summary_report}
          exact
          component={InventoryValuationSummary}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_reorder_report}
          exact
          component={InventoryReorderReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.sales_profit_report}
          exact
          component={SalesProfitReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_aging_report}
          exact
          component={InventoryAgingReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.inventory_issue_report}
          exact
          component={InventoryIssuedReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.invoice_application_detail}
          exact
          component={InvoiceApplicationDetail}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.invoice_application_summary}
          exact
          component={InvoiceApplicationSummaryReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_sales_order_report}
          exact
          component={SingleSalesOrderDetailReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_sales_order_report_packing_slip}
          exact
          component={SOPackingSlipReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.cash_receipt_register_report}
          exact
          component={DataReceiptRegisterReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.ar_aging_as_of_date}
          exact
          component={ARAgingAsOfDate}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_credit_memo_report}
          exact
          component={CreditMemoReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.view_invoice_report}
          exact
          component={CreditMemoReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.past_due_invoices}
          exact
          component={PastDueInvoicesReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.serial_detail_as_of_date}
          exact
          component={SerialDetailAsOfDateReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.sales_report}
          exact
          component={SalesReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.expense_report}
          exact
          component={ExpensesReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.purchasing_report}
          exact
          component={PurchasingReport}
        />
        <ProtectedRoute
          isLoggedIn={appState.loggedIn}
          allowedUrls={appState.allowedUrls}
          path={AppUrls.bill_payment_report}
          exact
          component={BillPaymentReport}
        />
        <Route path="*">
          <Redirect
            to={{
              pathname:
                appState.allowedUrls.length > 0
                  ? appState.allowedUrls[0].url
                  : AppUrls.user_profile,
              state: { from: "login" },
            }}
          />
        </Route>
      </Switch>
    </>
  );
};

export default WebsiteContent;
