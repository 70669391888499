import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { CompBalanceSheetAPI } from "./AccountingServices";
import "./Ledgers.scss";

import { useReactToPrint } from "react-to-print";

import Accordion from "react-bootstrap/Accordion";
import {
  dateFormmaterNoTime,
  fixTimezoneOffset,
  getCurrencyByCode,
} from "global/helpers";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import { getBaseCurrency } from "components/currency";

const ComparativeBalanceSheet = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const history = useHistory();
  const [endDate, setEndDate] = useState<Date>(null);
  const [data, setData] = useState([]);

  const MutationMethod = useMutation(CompBalanceSheetAPI, {
    onSuccess(data) {
      setData(data);
    },
  });

  const accordionDiv = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => accordionDiv.current,
  });

  const PrintReport = () => {
    handlePrint();
  };

  const displayAccordion = (category) => {
    if (category.children === null) return;
    return (
      <>
        <Accordion
          defaultActiveKey={data?.length > 0 && data[0].categoryId}
          flush
        >
          <Accordion.Item eventKey={category.categoryId}>
            {category.children.length > 0 ? (
              <>
                <Accordion.Header>
                  <table className="w-100 px-5">
                    <tbody>
                      <tr>
                        <td
                          style={{ width: "60%" }}
                          className="text-start p-1 ps-3"
                        >
                          {category.name}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.balance} {currencySymbol}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.priorBalance} {currencySymbol}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="w-100 d-flex justify-content-between pe-3 ps-4">
                                        <p>{category.name}</p>
                                        <p className="balance"> {category.priorBalance} $</p>
                                        <p className="balance"> {category.balance} $</p>
                                    </div> */}
                </Accordion.Header>
                <Accordion.Body>
                  {category.children.map((categoryChild) =>
                    displayAccordion(categoryChild)
                  )}
                  {category.children.length > 0 && (
                    <table className="w-100 px-5 border-top">
                      <tbody>
                        <tr>
                          <td
                            style={{ width: "60%" }}
                            className="text-start p-1 ps-3"
                          >
                            <b>Total {category.name}</b>
                          </td>
                          <td style={{ width: "20%" }} className="text-end p-1">
                            <b>
                              {category.balance} {currencySymbol}
                            </b>
                          </td>
                          <td style={{ width: "20%" }} className="text-end p-1">
                            <b>
                              {category.priorBalance} {currencySymbol}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    // <div className="w-100 d-flex justify-content-between pe-4 ps-4   pt-2 border-top">
                    //     <p><b>Total {category.name}</b></p>
                    //     <p><b>{category.priorBalance} $</b></p>
                    //     <p><b>{category.balance} $</b></p>
                    // </div>
                  )}
                </Accordion.Body>
              </>
            ) : (
              <>
                <Accordion.Header className="custom-header">
                  {/* <div className="w-100 d-flex justify-content-between pe-3 ps-4">
                                        <p>{category.name}</p>
                                        <p>{category.parentId && `${category.priorBalance} $`}</p>
                                        <p>{category.parentId && `${category.balance} $`}</p>
                                    </div> */}
                  <table className="w-100 px-5">
                    <tbody>
                      <tr>
                        <td
                          style={{ width: "60%" }}
                          className="text-start p-1 ps-3"
                        >
                          {category.name}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.balance} {currencySymbol}
                        </td>
                        <td style={{ width: "20%" }} className="text-end p-1">
                          {category.priorBalance} {currencySymbol}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Header>
              </>
            )}
          </Accordion.Item>
        </Accordion>
      </>
    );
  };

  const [showReport, setShowReport] = useState<boolean>(false);
  const onBackClick = () => {
    history.push("/reports");
  };

  const reportGenerate = () => {
    if (!endDate) {
      setEndDate(new Date());
      setShowReport(true);
    } else {
      MutationMethod.mutate({ endDate: fixTimezoneOffset(endDate) });
      setShowReport(true);
    }
  };

  useEffect(() => {
    reportGenerate();
  }, []);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          Comparative Balance Sheet Report
        </div>
        <div>
          <button
            type="button"
            className="btn btn-success ms-0"
            onClick={() => PrintReport()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-printer me-3"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            Print report to PDF
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="row w-50">
            {/* <div className="col-4">
                            <div className="form-group">
                                <label>Start Date</label>
                                <DatePicker
                                    onChange={(date) => setStartDate(date)}
                                    selected={startDate}
                                    minDate={minStartDate}
                                    maxDate={endDate}
                                    className="form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                />
                            </div>
                        </div> */}
            <div className="col-4">
              <div className="form-group">
                <label>As Of Date</label>
                <DatePicker
                  onChange={(date) => setEndDate(date)}
                  selected={endDate}
                  className="form-control"
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <br />
                <button
                  type="submit"
                  className="btn btn-outline-primary mt-1"
                  disabled={!endDate}
                  onClick={reportGenerate}
                >
                  Generate Report
                </button>
              </div>
            </div>
          </div>
          {showReport && (
            <>
              <div className="container w-75 report-paper custom-report">
                <div className="container w-100 pt-5" ref={accordionDiv}>
                  <div className="text-center">
                    <h3 className="mt-0">Comparative Balance Sheet</h3>
                  </div>
                  <table className="w-100 px-5 border-bottom">
                    <tbody>
                      <tr>
                        <td
                          style={{ width: "60%" }}
                          className="text-start"
                        ></td>
                        <td style={{ width: "20%" }} className="text-end">
                          <b>
                            Balance as of <br /> {dateFormmaterNoTime(endDate)}
                          </b>
                        </td>
                        <td style={{ width: "20%" }} className="text-end">
                          <b>
                            Balance as of <br />{" "}
                            {endDate?.getMonth() +
                              1 +
                              "/" +
                              endDate?.getDate() +
                              "/" +
                              (endDate?.getFullYear() - 1)}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {data?.map((category) => {
                    return displayAccordion(category);
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComparativeBalanceSheet;
