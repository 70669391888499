import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { FormProvider, useForm } from "react-hook-form";
import DispatchContext from "../../context/DispatchContext";
import Delete from "img/delete.svg";
import AG from "elements/AG";
import * as AppUrls from "constants/AppUrls";
import {
  dateFormmaterNoTime,
  formatInput,
  notificationMessage,
} from "global/helpers";
import { GridColumns } from "components/Common.models";
import {
  getPurchaseOrder,
  receivePurchaseOrder,
  checkSerialValidity,
  checkSerialAvailability,
} from "./PurchasingServices/PurchaseServices";
import { ReactComponent as AlertIcon } from "img/alert.svg";
import {
  PurchaseOrderDetails,
  POItem,
  PurchaseOrder,
} from "./PurchasingOrder.model";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import PurchaseSelectionModal from "./PurchaseSelectionModal";
import { typeEnum } from "./StatusEnum";
import CSVReaderInput from "components/FileImporter/CSVReaderInput";
import Lookup from "elements/Lookup";
import { getPurchaseOrders } from "./PurchasingServices/PurchaseServices";

const ReceivePurchaseOrder = ({ match }) => {
  const isFulfill = match.path === AppUrls.fulfill_po_return;

  const location = useLocation();

  const appDispatch = useContext(DispatchContext);

  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [orderDetails, setOrderDetails] = useState<PurchaseOrderDetails>(null);
  const [poItems, setPoItems] = useState<POItem[]>([]);
  const [selectedRow, setSelectedRow] = useState<POItem[]>();
  const [fulfilledItems, setFulfilledItems] = useState<POItem[]>([]);
  const [deSelectItems, setDeselectItems] = useState<number>(0);

  const [importData, setImportData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>();
  const [importError, setImportError] = useState<string>(null);

  const [allData, setAllData] = useState([]);

  const [lookupData, setLookupData] = useState<PurchaseOrder[]>(null);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [poSelected, setPoSelected] = useState<PurchaseOrderDetails>(null);
  const [customIsDirty, setCustomIsDirty] = useState<boolean>(false);

  const getPOs = useMutation(getPurchaseOrders, {
    async onSuccess(response) {
      setPoSelected(null);
      setLookupData(null);
      let dataLookUp = [];
      setAllData(response?.data);
      if (isFulfill)
        dataLookUp = response?.data?.filter((res) => res.type === 3);
      else dataLookUp = response?.data?.filter((res) => res.type !== 3);
      setLookupData(dataLookUp);
    },
  });

  useEffect(() => {
    getPOs.mutate({ vendorId: null, hubId: null, poStatus: 5, poType: 1 });
  }, [isFulfill]);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = methods;

  const getOrderDetails = useMutation(getPurchaseOrder, {
    async onSuccess(response) {
      setValue("purchaseOrderNumber", response.data.purchaseOrderNumber, {
        shouldDirty: true,
      });
      setValue("purchaseOrderId", response.data.purchaseOrderId);
      setValue("vendorPO", response.data.vendorInvoicePO);
      setValue("shipCost", formatInput(response.data.shippingCost));
      setValue("vendorDiscount", formatInput(response.data.discount));
      setOrderDetails(response.data);
      setPoItems(response.data.purchaseOrderItems);
      let scanned = [];
      for (let item of response.data.purchaseOrderItems) {
        if (item.itemType === 1) {
          for (let scannedItem of item.serials) {
            if (!scannedItem.isAvailable)
              scanned.push({
                ...item,
                serialNumber: scannedItem.serialNo,
                qty: 1,
                index: scanned.length + 1,
              });
          }
        } else {
          if (item.quantityScanned > 0)
            scanned.push({
              ...item,
              qty: item.quantityScanned,
              index: scanned.length + 1,
            });
        }
      }
      setFulfilledItems(scanned);
    },
  });

  const submitReceive = useMutation(receivePurchaseOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `PO. ${getValues("purchaseOrderNumber")} ${
          isFulfill ? "fulfilled" : "received"
        } successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem receiving purchase order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const scanSerialized = useMutation(checkSerialValidity, {
    async onSuccess(response) {
      if (response.data) {
        if (selectedRow.length > 0) {
          let newItem = {
            ...selectedRow[0],
            qty: 1,
            scanDate: new Date().toLocaleString(),
            serialNumber: getValues("itemSerial"),
            index:
              fulfilledItems.length > 0
                ? Math.max(...fulfilledItems.map((o) => o.index)) + 1
                : 0,
          };
          // if (newItem.serialNumber.length != 15)
          //     setErrorMessage(`Serial Number must be 15 characters.`)
          // else {
          setFulfilledItems((prevState) => [...prevState, { ...newItem }]);
          setFocus("itemSerial");
          setValue("itemSerial", "");
          setCustomIsDirty(true);
        }
        // }
      } else setErrorMessage("Invalid Serial Number Or Serial already exists");
    },
    onError() {
      setErrorMessage("Invalid Serial Number Or Serial already exist");
    },
  });
  const scanSerializedBulk = useMutation(checkSerialValidity, {
    async onSuccess(response) {
      if (response.data) {
        if (selectedRow.length > 0) {
          let newFulfilled = [...fulfilledItems];
          importData.forEach((element) => {
            let newItem = {
              ...selectedRow[0],
              qty: 1,
              serialNumber: element.serial,
              index:
                fulfilledItems.length > 0
                  ? Math.max(...newFulfilled.map((o) => o.index)) + 1
                  : 0,
            };
            newFulfilled.push(newItem);
          });
          setImportError(null);
        }
      } else setImportError("Invalid Serial Number Or Serial already exists");
    },
  });
  const checkAvailability = useMutation(checkSerialAvailability, {
    async onSuccess(response) {
      if (response.data) {
        if (selectedRow.length > 0) {
          let newItem = {
            ...selectedRow[0],
            qty: 1,
            serialNumber: getValues("itemSerial"),
            index:
              fulfilledItems.length > 0
                ? Math.max(...fulfilledItems.map((o) => o.index)) + 1
                : 0,
          };
          setFulfilledItems((prevState) => [...prevState, { ...newItem }]);
          setCustomIsDirty(true);
          setValue("itemSerial", "");
          setFocus("itemSerial");
        }
      } else setErrorMessage("Invalid Serial Number");
    },
    onError() {
      setErrorMessage("Invalid Serial Number");
    },
  });

  const checkAvailabilityBulk = useMutation(checkSerialAvailability, {
    async onSuccess(response) {
      if (response.data) {
        if (selectedRow.length > 0) {
          let newFulfilled = [...fulfilledItems];
          importData.forEach((element) => {
            let newItem = {
              ...selectedRow[0],
              qty: 1,
              serialNumber: element.serial,
              index:
                newFulfilled.length > 0
                  ? Math.max(...newFulfilled.map((o) => o.index)) + 1
                  : 0,
            };
            newFulfilled.push(newItem);
          });
          setSelectedRow(null);
          setDeselectItems((prev) => prev + 1);
          setImportError(null);
        }
      } else setImportError("Invalid Serial Number");
    },
    onError() {
      setImportError("Invalid Serial Number");
    },
  });

  useEffect(() => {
    if (selectedRow && selectedRow.length > 0) {
      setErrorMessage(null);
      if (selectedRow[0].itemType === 1) {
        setValue("itemSerial", "", { shouldTouch: true });
        setFocus("itemSerial");
      } else {
        setValue("itemSerial", "", { shouldTouch: true });
        setFocus("quantity");
      }
    } else {
      setValue("itemSerial", null);
      setValue("quantity", null);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (location) {
      onCancel();
    }
  }, [location]);

  const itemsColumns: GridColumns[] = [
    {
      headerName: "Select",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "venPartNumber",
      headerName: "Vendor Part No",
    },
    {
      field: "description",
      headerName: "Item Description",
    },
    {
      field: "itemType",
      headerName: "Item Type",
      cellRenderer: (params) =>
        params.value === 1 ? "Serialized" : "Non Serialized",
    },
    {
      field: "quantityOrdered",
      headerName: "Ordered",
    },
    {
      field: "quantityReceived",
      headerName: `${isFulfill ? "Fulfilled" : "Received"}`,
    },
    {
      field: "open",
      headerName: "Open",
      cellRenderer: (params) =>
        params.data.quantityOrdered - params.data.quantityReceived,
    },
    {
      field: "quantityScanned",
      headerName: "Scanned",
      cellRenderer: (params) => getScannedByItem(params.data),
    },
    {
      field: "remaining",
      headerName: "Remaining",
      cellRenderer: (params) =>
        params.data.quantityOrdered -
        params.data.quantityReceived -
        getScannedByItem(params.data),
    },
  ];

  const fulfilledColumns: GridColumns[] = [
    {
      field: "serialNumber",
      headerName: "Serial Number",
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "qty",
      headerName: "Qty Ent",
    },
    {
      field: "scanDate",
      headerName: "Scan Date",
      cellRenderer: (value) => {
        return dateFormmaterNoTime(value.data);
      },
    },
    {
      field: "action",
      headerName: "Action",
      onCellClicked: (params) => {
        if (params.itemType === 1) {
          setFulfilledItems((prev) =>
            prev.filter(
              (item) => item.serialNumber !== params.data.serialNumber
            )
          );
          setCustomIsDirty(true);
        } else {
          setFulfilledItems((prev) =>
            prev.filter((item) => item.index !== params.data.index)
          );
          setCustomIsDirty(true);
          if (params.data.qty === params.data.quantityScanned)
            setPoItems((items) =>
              items.map((item) => {
                if (
                  item.purchaseOrderItemId === params.data.purchaseOrderItemId
                )
                  return { ...item, quantityScanned: 0 };
                return { ...item };
              })
            );
        }
      },
      cellRenderer: () => {
        return `
                  <div class="btn text-danger">
                    <img src=${Delete} alt="Delete Icon" />
                    Delete 
                  </div>
            `;
      },
    },
  ];

  const onPurchaseOrderSelected = (po) => {
    if ([1, 2, 3, 8, 9].includes(po?.poState)) {
      setPoSelected(po);
      setIsDisabled(false);
      selectPO(po);
    } else {
      setValue("purchaseOrderNumber", null);
      setIsDisabled(true);
      onSelectionError(
        "Appproved, invoiced and voided purchase orders cannot be selected"
      );
    }
  };

  const selectPO = (po) => {
    getOrderDetails.mutate({ queryKey: [po["purchaseOrderId"]] });
    setOpenSelection(false);
    setFulfilledItems([]);
    setSelectedRow(null);
    setDeselectItems((prev) => prev + 1);
    setCustomIsDirty(false);
  };

  const onSelectionError = (message) => {
    let notification = {
      variant: "danger",
      msg: message,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const addItem = () => {
    let quantity = getValues("quantity");
    if (selectedRow && selectedRow.length > 0) {
      if (selectedRow[0].itemType === 1) {
        // if (getValues("itemSerial").length != 15)
        //     setErrorMessage(`Serial Number must be 15 characters.`)
        // else {
        if (
          selectedRow[0].quantityOrdered -
            selectedRow[0].quantityReceived -
            getScannedByItem(selectedRow[0]) <=
          0
        )
          setErrorMessage(`All the available items have been scanned.`);
        else {
          if (
            fulfilledItems.find(
              (i) => i.serialNumber == getValues("itemSerial")
            )
          )
            setErrorMessage(`Serial Number already exists.`);
          else {
            if (!scanSerialized.isLoading) {
              if (isFulfill) {
                checkAvailability.mutate([getValues("itemSerial")]);
                setErrorMessage(null);
              } else {
                scanSerialized.mutate({
                  hub: selectedRow[0].itemHubId,
                  serials: [getValues("itemSerial")],
                });
                setErrorMessage(null);
              }
            }
          }
        }
        // }
      } else {
        if (quantity < 1) setErrorMessage(`Quantity cannot be less than 1`);
        else {
          if (
            Number(quantity) >
            Number(selectedRow[0].quantityOrdered) -
              Number(selectedRow[0].quantityReceived) -
              Number(selectedRow[0].quantityScanned)
          )
            setErrorMessage(
              `The quantity cannot be greater than the quantity ordered`
            );
          else {
            let itemfound = fulfilledItems.find(
              (i) =>
                i.purchaseOrderItemId === selectedRow[0].purchaseOrderItemId
            );
            if (itemfound) {
              if (
                Number(quantity) + Number(itemfound.qty) >
                Number(itemfound.quantityOrdered) -
                  Number(selectedRow[0].quantityReceived)
              )
                setErrorMessage(
                  `The quantity cannot be greater than the quantity ordered`
                );
              else {
                let fullfilledItem = fulfilledItems.map((element) => {
                  if (
                    element.purchaseOrderItemId ===
                    itemfound.purchaseOrderItemId
                  )
                    return {
                      ...element,
                      qty: Number(quantity) + Number(itemfound.qty),
                      scanDate: new Date().toLocaleString(),
                    };
                  else return element;
                });
                setFulfilledItems(fullfilledItem);
                setErrorMessage(null);
                setSelectedRow(null);
                setDeselectItems((prev) => prev + 1);
                setCustomIsDirty(true);
              }
            } else {
              let newItem = {
                ...selectedRow[0],
                scanDate: new Date().toLocaleString(),
                serialNumber: getValues("itemSerial"),
                qty: Number(getValues("quantity")),
                index:
                  fulfilledItems.length > 0
                    ? Math.max(...fulfilledItems.map((o) => o.index)) + 1
                    : 0,
              };
              setFulfilledItems((prevState) => [...prevState, { ...newItem }]);
              setErrorMessage(null);
              setSelectedRow(null);
              setDeselectItems((prev) => prev + 1);
              setCustomIsDirty(true);
            }
          }
        }
      }
    } else {
      setErrorMessage("Please select an item from the grid above");
    }
  };

  const getScanned = () => {
    let total = 0;
    for (let item of fulfilledItems) total += item.qty;
    return total;
  };

  const getPoType = () => {
    if (isFulfill) return { value: typeEnum["Return"], label: "Return" };
    return { value: typeEnum["Normal"], label: "Normal" };
  };

  const getTotalQty = () => {
    let total = 0;
    for (let item of orderDetails["purchaseOrderItems"])
      total += item.quantityOrdered;
    return total;
  };

  const getScannedByItem = (item) => {
    if (item.itemType === 2) {
      if (
        fulfilledItems.find(
          (element) => element.purchaseOrderItemId === item.purchaseOrderItemId
        )
      )
        return fulfilledItems.find(
          (element) => element.purchaseOrderItemId === item.purchaseOrderItemId
        ).qty;
      return item.quantityScanned;
    } else {
      return fulfilledItems.filter(
        (element) => element.purchaseOrderItemId === item.purchaseOrderItemId
      ).length;
    }
  };

  const onCancel = () => {
    reset();
    setOrderDetails(null);
    setPoItems([]);
    setFulfilledItems([]);
    setErrorMessage(null);
    setSelectedRow(null);
    setPoSelected(null);
    setIsDisabled(true);
    setCustomIsDirty(false);
  };

  const onSubmit = () => {
    if (getTotalQty() > getScanned()) setOpenConfirmation(true);
    else receiveOrder();
  };

  const receiveOrder = () => {
    let fulfilled = orderDetails.purchaseOrderItems.map((item) => {
      if (item.itemType === 1) {
        let serials = [...fulfilledItems]
          .filter((element) => element.itemId === item.itemId)
          .map((value) => value.serialNumber);
        return {
          itemOrderId: item.purchaseOrderItemId,
          quantity: serials.length,
          serials: serials,
        };
      } else {
        return {
          itemOrderId: item.purchaseOrderItemId,
          quantity: fulfilledItems.find(
            (element) => element.itemId === item.itemId
          )
            ? fulfilledItems.find((element) => element.itemId === item.itemId)
                .qty
            : 0,
        };
      }
    });
    let data = {
      items: {
        shipCost: getValues("shipCost")
          ? parseFloat(
              getValues("shipCost")?.toString().replace(/,/g, "")
            ).toString()
          : "0",
        vendorDiscount: getValues("vendorDiscount")
          ? parseFloat(
              getValues("vendorDiscount")?.toString().replace(/,/g, "")
            ).toString()
          : "0",
        vendPo: getValues("vendorPO"),
        receiveData: fulfilled,
      },
      poId: getValues("purchaseOrderId"),
    };
    submitReceive.mutate(data);
  };

  useEffect(() => {
    if (showModal) {
      setImportData(null);
      setImportError(null);
    }
  }, [showModal]);

  const validateImportedItems = (items) => {
    let fetchedItems = items.data.filter((item) => item[0] != "");

    if (
      fetchedItems[0][0].toLowerCase() === "serial number" ||
      fetchedItems[0][0].toLowerCase() === "serial"
    ) {
      if (
        selectedRow[0].quantityOrdered -
          selectedRow[0].quantityReceived -
          getScannedByItem(selectedRow[0]) -
          (fetchedItems.length - 1) <
        0
      )
        setImportError(
          "The Imported quantity is bigger than the avilable quantity."
        );
      else {
        let dataToSend = [];

        let valid = true;

        for (let i = 1; i < fetchedItems.length; i++) {
          let element = fetchedItems[i];
          if (element[0].length < 15 || isNaN(element[0])) {
            setImportError("Invalid serial numbers");
            valid = false;
            break;
          } else {
            valid = true;
            dataToSend.push({ serial: element[0] });
          }
        }

        if (valid) {
          let finalDataToSend = dataToSend.map((e) => Object.values(e)[0]);
          if (new Set(finalDataToSend).size !== dataToSend.length)
            setImportError("Duplicate serial numbers found");
          else {
            if (isFulfill) {
              setImportData(dataToSend);
              checkAvailabilityBulk.mutate(finalDataToSend);
            } else {
              setImportError(null);
              setImportData(dataToSend);
              scanSerializedBulk.mutate({
                hub: selectedRow[0].itemHubId,
                serials: finalDataToSend,
              });
            }
          }
        } else {
          setImportError("Column names mismatch");
        }
      }
    }
  };

  const applyImportedItems = () => {
    const intersection = fulfilledItems.filter((item1) =>
      importData.some((item2) => item1.serialNumber == item2.serial)
    );
    if (intersection.length > 0)
      setImportError(`Duplicate Serial found please check imported serials`);
    else {
      let scannedItems = [...fulfilledItems];
      let index =
        scannedItems.length > 0
          ? Math.max(...scannedItems.map((o) => o.index)) + 1
          : 0;
      for (let imported of importData) {
        scannedItems.push({
          ...selectedRow[0],
          qty: 1,
          serialNumber: imported.serial,
          index: index,
        });
        index++;
      }
      setFulfilledItems(scannedItems);
      setSelectedRow(null);
      setDeselectItems((prev) => prev + 1);
      setShowModal(false);
      setCustomIsDirty(true);
    }
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: submitReceive.isLoading });
  }, [submitReceive.isLoading, appDispatch]);

  useEffect(() => {
    if (poSelected) {
      onPurchaseOrderSelected(poSelected);
    }
  }, [poSelected]);
  useEffect(() => {
    if (location.state) {
      setPoSelected(
        allData?.find((i) => i.purchaseOrderId == location.state.poId)
      );
    }
  }, [location, allData]);
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(() => addItem())}>
          <div className="page-title page-title-editable">
            <div>
              {isFulfill ? "Fulfill PO Returns" : "Receive Purchase Order"}
            </div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary no-border`}
                onClick={onCancel}
                disabled={!poSelected}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-success"
                disabled={
                  !customIsDirty ||
                  (customIsDirty && Object.keys(errors).length !== 0) ||
                  getScanned() === 0
                }
                onClick={onSubmit}
              >
                {isFulfill ? "Fulfill" : "Receive"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row gx-5">
                <div className="col-lg-3 col-sm-12">
                  <label>
                    PO No. <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelection(true)}
                    inputName="purchaseOrderNumber"
                    isRequired={true}
                    initialData={lookupData?.map((item) => ({
                      ...item,
                      id: item.purchaseOrderId,
                      name: item.purchaseOrderNumber,
                    }))}
                    onSelection={setPoSelected}
                    inputValue={poSelected?.purchaseOrderNumber}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>PO Date</label>
                  <div>
                    {orderDetails && orderDetails.poDate ? (
                      dateFormmaterNoTime(new Date(orderDetails.poDate))
                    ) : (
                      <span className="text-muted fst-italic">PO Date</span>
                    )}
                  </div>
                </div>
                {!isFulfill && (
                  <>
                    <div className="col-lg-3 col-sm-12">
                      <label>Shipping/Handling Charge</label>
                      <div className="input-group w-50">
                        <div className="input-group-prepend">
                          <span
                            className={`input-group-text dollar-icon ${
                              !isDisabled ? "bg-white" : "bg-disabled"
                            }`}
                          >
                            $
                          </span>
                        </div>
                        <input
                          className="form-control border-start-0"
                          {...register("shipCost", {
                            min: {
                              value: 0,
                              message: "You cannot put a negative value",
                            },
                          })}
                          disabled={isDisabled}
                          onBlur={(e) =>
                            setValue("shipCost", formatInput(e.target.value))
                          }
                        />
                      </div>
                      {errors["shipCost"] && (
                        <p className="text-danger">
                          {errors["shipCost"].message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Vendor Discount</label>
                      <div className="input-group w-50">
                        <div className="input-group-prepend">
                          <span
                            className={`input-group-text dollar-icon ${
                              !isDisabled ? "bg-white" : "bg-disabled"
                            }`}
                          >
                            $
                          </span>
                        </div>
                        <input
                          className="form-control border-start-0"
                          {...register("vendorDiscount", {
                            min: {
                              value: 0,
                              message: "You cannot put a negative value",
                            },
                          })}
                          disabled={isDisabled}
                          onBlur={(e) =>
                            setValue(
                              "vendorDiscount",
                              formatInput(e.target.value)
                            )
                          }
                        />
                      </div>
                      {errors["vendorDiscount"] && (
                        <p className="text-danger">
                          {errors["vendorDiscount"].message}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="row gx-5 mt-3 ">
                <div className="col-lg-3 col-sm-12">
                  <div>
                    <label>Vend Invoice / PO</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("vendorPO")}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="mt-2">
                    <label>Hub ID</label>
                    <div>
                      {orderDetails ? (
                        orderDetails.hubKey
                      ) : (
                        <span className="text-muted fst-italic">Hub ID</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div>
                    <label>Req Date</label>
                    <div>
                      {orderDetails && orderDetails.requestDate ? (
                        dateFormmaterNoTime(new Date(orderDetails.requestDate))
                      ) : (
                        <span className="text-muted fst-italic">Req Date</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div>
                    <label>Exp Ship Date</label>
                    <div>
                      {orderDetails && orderDetails.expShipDate ? (
                        dateFormmaterNoTime(new Date(orderDetails.expShipDate))
                      ) : (
                        <span className="text-muted fst-italic">
                          Exp. Ship. Date
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-2">
                    <label>Ship Via</label>
                    <div>
                      {orderDetails ? (
                        orderDetails.shippingMethodName
                      ) : (
                        <span className="text-muted fst-italic">
                          Shipping method
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-250 mt-4" style={{ height: 10 }}>
                <AG
                  data={poItems}
                  columns={itemsColumns}
                  setRowChecked={setSelectedRow}
                  deSelectItems={deSelectItems}
                />
              </div>
              <div className="row my-4">
                <div className="col ps-0">
                  <div className="border p-3">
                    <div>Total Items</div>
                    <div className="text-success">
                      {orderDetails
                        ? orderDetails["purchaseOrderItems"].length
                        : 0}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="border p-3">
                    <div>Total Item Qty</div>
                    <div className="text-success">
                      {orderDetails ? getTotalQty() : 0}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="border p-3">
                    <div>Open Qty</div>
                    <div className="text-success">
                      {orderDetails ? getTotalQty() - getScanned() : 0}
                    </div>
                  </div>
                </div>
                <div className="col pe-0">
                  <div className="border p-3">
                    <div>Scanned</div>
                    <div className="text-success">{getScanned()}</div>
                  </div>
                </div>
              </div>
              <div className="row section py-3 ps-2">
                {selectedRow && selectedRow.length !== 0 && (
                  <>
                    <div className="col-3 d-flex align-items-center">
                      {selectedRow[0].itemType === 1 ? (
                        <>
                          <label>
                            Serial <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control ms-4"
                            {...register("itemSerial")}
                            autoComplete="off"
                          />
                        </>
                      ) : (
                        <>
                          <label>
                            Quantity <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control ms-4 w-50"
                            type="number"
                            {...register("quantity")}
                            min={1}
                          />
                        </>
                      )}
                    </div>
                    <div className="col-5 d-flex align-items-center">
                      {errorMessage && (
                        <div className="d-flex align-items-center">
                          <AlertIcon className="me-3" />
                          <div className="text-danger">{errorMessage}</div>
                        </div>
                      )}
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                      {selectedRow[0].itemType === 1 && (
                        <CSVReaderInput
                          onDataFetched={validateImportedItems}
                          IsDisabled={false}
                          title="Import items list from a CSV file"
                          data={importData}
                          error={importError}
                          showModal={showModal}
                          onClose={() => setShowModal(false)}
                          onOpen={() => setShowModal(true)}
                          onSelection={applyImportedItems}
                        />
                      )}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isDisabled && !scanSerialized.isLoading}
                      >
                        Add
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="h-350">
                <AG data={fulfilledItems} columns={fulfilledColumns} />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <PurchaseSelectionModal
        showModal={openSelection}
        onExit={setOpenSelection}
        onSelectedItem={onPurchaseOrderSelected}
        preDefinedType={getPoType()}
        ApprovedAndVoidedRemoved={true}
      />
      <ConfirmationModal
        title="Receive Purchase Order"
        message={`There are items that are not ${
          isFulfill ? "fulfilled" : "received"
        }/scanned. Are you sure you want to have a partial ${
          isFulfill ? "fulfillment" : "receive"
        }? Purchase order will remain open for partial ${
          isFulfill ? "fulfilled" : "received"
        } items.`}
        showModal={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onConfirm={() => {
          setOpenConfirmation(false);
          receiveOrder();
        }}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
      />
    </>
  );
};

export default ReceivePurchaseOrder;
