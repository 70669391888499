import api from "components/data/AxiosClient";
import { AccountTypeDTO } from "./AccountType/AccountType.models";

const customersURL = "customers";
/**
 * Get all account types
 * @returns representative array
 */
export const getAccountTypes = async (params): Promise<AccountTypeDTO[]> => {
  const response = await api.get<AccountTypeDTO[]>(
    `${customersURL}/account-types${
      params.queryKey.length > 1 &&
      params.queryKey[1].params &&
      params.queryKey[1].params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
  return response.data;
};
/**
 * Adds a new account type
 * @param data account type
 * @returns account type entity
 */
export const createAccountType = async (
  data: AccountTypeDTO
): Promise<AccountTypeDTO> => {
  const response = await api.post<AccountTypeDTO>(
    `${customersURL}/account-types`,
    data
  );
  return response.data;
};
/**
 * Edit account type data
 * @param data account type
 * @returns account type entity
 */
export const editAccountType = async (
  data: AccountTypeDTO
): Promise<AccountTypeDTO> => {
  const response = await api.put<AccountTypeDTO>(
    `${customersURL}/account-types/${data.accTypeId}`,
    data
  );
  return response.data;
};
/**
 * Delete account type by id
 * @param repId account type id
 * @returns true/false
 */
export const deleteAccountType = async (typeId: number): Promise<boolean> => {
  const response = await api.delete<boolean>(
    `${customersURL}/account-types/${typeId}`
  );
  return response.data;
};

export const logoutApi = async () => {
  return await api.get("users/logout");
};
