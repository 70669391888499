import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { GridColumn } from "elements/AG.models";
import AgGrid from "elements/AgGrid";
import { checkIfAllowed } from "global/helpers";
import addIcon from "img/add-icon.svg";
import exportIcon from "img/export.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import * as colDefs from "../../../constants/colDefs";
import { getPortalsList } from "../SalesMarketingServices/salesMarketingServices";

const PortalUsers = (props) => {
  const appDispatch = useContext(DispatchContext);
  const { match } = props;
  let history = useHistory();
  const appState = useContext(StateContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [dataGrid, setDataGrid] = useState(null);
  const [filters, setFilters] = useState({
    searchQuery: "",
  });

  const dataMutation = useMutation("portalUsersData", getPortalsList, {
    async onSuccess(response) {
      setDataGrid(response?.data);
    },
  });

  const onRowDoubleClick = (e) => {
    const id = e.portalUserId;
    history.push(`${AppUrls.portal_users}/${id}`);
  };

  const statusCellRenderer = (params) => {
    let style = params.value === "Active" ? "active-status" : "disabled-status";
    return `<div class=${style}>${
      params.value === "Active" ? "Active" : "Disabled"
    }</div>`;
  };

  const columns: GridColumn[] = [
    {
      field: "username",
      headerName: "Online User ID",
    },
    {
      field: "fullname",
      headerName: "Full Name",
    },
    {
      field: "userLevel",
      headerName: "Level",
      maxWidth: colDefs.smWidth,

      valueGetter: (params) => {
        return params.data.userLevel === "0" ? "" : params.data.userLevel;
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      maxWidth: colDefs.booleanWidth,

      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: statusCellRenderer,
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "accountNum",
      headerName: "Customers No.",
      maxWidth: colDefs.mWidth,
    },
    {
      field: "companyName",
      headerName: "Company Name",
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };
  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Portal Users</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length == 0 || !dataGrid}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link
              to={AppUrls.create_new_portal_user}
              className="btn btn-primary"
            >
              <img src={addIcon} className="me-2" alt="" /> New User
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="row mb-4">
          <div className="col-lg-3">
            <label className="label me-3">Search</label>

            <div className="page-search">
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => onSearchTermChange(e)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            data={dataGrid}
            setDataGrid={setDataGrid}
            fetchMethod={dataMutation}
            filters={filters}
            setrowClicked={onRowDoubleClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Portal Users List Report"
            refreshCounter={refreshCounter}
          />
        </div>
      </div>
    </div>
  );
};

export default PortalUsers;
