import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { getInventoryOnHandReport } from "./services/ReportServices";
import "./style/common.scss";
import backArrow from "img/back-arrow.svg";
import { dateFormmaterNoTime, fetchReportParamsQuery } from "global/helpers";
import DispatchContext from "context/DispatchContext";
import "./style/report.scss";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { useHistory, useLocation } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import { useReactToPrint } from "react-to-print";
import exportIcon from "img/export.svg";
import Filter from "./components/Filter";
import Select from "react-select";

const isSummaryOpt = [
  {
    value: true,
    label: "Summary",
  },
  {
    value: false,
    label: "Detailed",
  },
];

export default function InventoryOnHandReport() {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const [reportName, setReportName] = useState("Invetory OnHand Report");
  const [, setRefreshCounter] = useState(0);
  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const [isSummary, setIsSummary] = useState(true);

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];

  const isCustomizedReport = customizedComponentId != null;

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(
    "getInventoryOnHandReport",
    getInventoryOnHandReport,
    {
      async onSuccess(data) {
        let currentData = { ...data };
        setReportData(currentData);
      },
    }
  );

  const onSummaryChange = (val) => {
    setIsSummary(val);
    onSubmit();
  };

  const onSubmit = () => {
    const values = getValues();
    let params = {
      hubIds: [],
      itemClassId: null,
      subClassId: null,
      productLineId: null,
      supplierId: null,
      categoryId: null,
      manufacturerId: null,
      isSerialized: null,
    };
    if (values.HubsId) params.hubIds = values.HubsId.map((e) => e.value);
    if (values.ClassId) params.itemClassId = values.ClassId?.value;
    if (values.SubClassId) params.subClassId = values.SubClassId?.value;
    if (values.ProductLineId)
      params.productLineId = values.ProductLineId?.value;
    if (values.SupplierId) params.supplierId = values.SupplierId?.vendorId;
    if (values.CategoryId) params.categoryId = values.CategoryId?.value;
    if (values.ManufacturerId)
      params.manufacturerId = values.ManufacturerId?.value;
    if (values.IsSerialized) params.isSerialized = values.IsSerialized?.value;

    generateReport.mutate(params);
  };

  const columns = [
    [
      {
        field: "hubKey",
        headerName: "Hub Name",
        cellRenderer: "agGroupCellRenderer",
        filter: false,
        floatingFilter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMenu: true,
      },
      {
        field: "totalAvailableQuantity",
        headerName: "Total Available Quantity",
      },
      {
        field: "totalOnHand",
        headerName: "Total On Hand",
      },
      {
        field: "totalOnPO",
        headerName: "Total On PO",
      },
      {
        field: "totalOnSO",
        headerName: "Total On SO",
      },
    ],
    [
      {
        field: "itemNumber",
        headerName: "Item No",
      },
      {
        field: "itemDescription",
        headerName: "Description",
      },
      {
        field: "manPartNumber",
        headerName: "Man Part Number",
      },
      {
        field: "availableQuantity",
        headerName: "Available QTY",
      },
      {
        field: "onHand",
        headerName: "on Hand",
      },
      {
        field: "onPO",
        headerName: "on PO",
      },
      {
        field: "onSO",
        headerName: "on SO",
      },
    ],
  ];

  const detailCellRenderer = ({ data, ...rest }) => {
    return (
      <div className="py-4">
        <AgGrid
          data={data?.items}
          columns={gridColumns[1]}
          pagination={false}
          autoHight={true}
          autoSize={true}
          forPrinting={true}
          ref={childFnRef1}
        />
      </div>
    );
  };

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const reportPage = useRef(null);

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };
  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  useEffect(() => {
    if (!isCustomizedReport) generateReport.mutate({});
  }, [isCustomizedReport]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className={`page-content ${isSummary ? "hide-arrow" : ""}`}>
              <Filter
                filters={[3, 9, 10, 11, 12, 13, 14, 15]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                setRefreshCounter={setRefreshCounter}
                isSummary={isSummary}
                setIsSummary={setIsSummary}
              />
              <div className="container report-filters-div mb-3">
                <div className="d-flex justify-content-between">
                  <div className="col-2">
                    {getValues("groupingElement")?.value !== 1 && (
                      <Select
                        options={isSummaryOpt}
                        isSearchable={true}
                        value={isSummaryOpt?.find(
                          (opt) => opt?.value === isSummary
                        )}
                        onChange={(val) => onSummaryChange(val?.value)}
                      />
                    )}
                  </div>
                  <div>
                    <button
                      className="import-btn"
                      type="button"
                      onClick={exportToExcel}
                      disabled={
                        disabledExportBtn || reportData?.hubs?.length === 0
                      }
                    >
                      <img src={exportIcon} className="me-2" alt="" />
                      Export grid to Excel
                    </button>
                    <button
                      type="button"
                      className="btn btn-success ms-5"
                      onClick={printDocument}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-printer me-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                      </svg>
                      Print report to PDF
                    </button>
                  </div>
                </div>
              </div>
              <div className="report-page" ref={reportPage}>
                <div className="report-header d-flex justify-content-between">
                  <div>
                    <h3>
                      <b>{reportName}</b>
                    </h3>{" "}
                    <br />
                    <p>
                      <b>Report Date: </b>
                      {dateFormmaterNoTime(reportData?.reportDate)}
                    </p>
                  </div>
                  <div>
                    <img
                      alt=""
                      src={reportData?.imageLogo ?? BlueSeedLogo}
                      className="report-logo"
                    ></img>
                  </div>
                </div>
                <div className="grid-wrapper">
                  {gridColumns.length > 0 && (
                    <AgGrid
                      data={reportData?.hubs}
                      columns={gridColumns[0]}
                      pagination={false}
                      forPrinting={true}
                      autoHight={true}
                      masterDetail={true}
                      detailCellRenderer={detailCellRenderer}
                      exportToExcel={exportToExcelClicked}
                      setExportToExcelClicked={setExportToExcelClicked}
                      setDisabledExportBtn={setDisabledExportBtn}
                      setIsLoadingGridExport={setIsLoadingGridExport}
                      subTable={gridColumns[1]}
                      subTableObj={"items"}
                      fileName="Inventory On Hand Report"
                      ref={childFnRef}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
