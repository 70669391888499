import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { createStandOut, deleteStandOut } from "../Portal.services";
import EcomSetupForm from "./EcomSetupForm";

export default function EcomStandOut() {
  const appDispatch = useContext(DispatchContext);

  const [defaultdata, setDefaultData] = useState({
    id: "",
    title: "",

    imageUrl: "",
  });
  const location = useLocation();
  const id = location.state && location.state.id;
  const title = location.state && location.state.title;
  const imageUrl = location.state && location.state.imageUrl;
  useEffect(() => {
    setDefaultData({
      id: id,
      title: title,
      imageUrl: imageUrl,
    });
  }, [id, title, imageUrl]);
  const createStandOutMutation = useMutation(createStandOut, {
    onSuccess: (data) => {
      let notification = {
        variant: "success",
        msg: `${CREATE_SUCCESS_MSG}`,
      };
      const url = `${AppUrls.Content_Management_System}`;

      history.push({
        pathname: url,
      });
      appDispatch({ type: "notification", value: notification });
    },
    onError: (error) => {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding Section"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();

  const handleFormSubmit = (data) => {
    createStandOutMutation.mutate(data);
  };

  const deleteStandOutMutation = useMutation(deleteStandOut, {
    onSuccess: (dataID) => {
      let notification = {
        variant: "success",
        msg: `${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      const url = `${AppUrls.Content_Management_System}`;

      history.push({
        pathname: url,
      });
    },
    onError: (error) => {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem deleting section"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const handleFormDelete = () => {
    deleteStandOutMutation.mutate(Number(defaultdata.id));
  };

  const inputs = [
    {
      name: "title",
      type: "text",
      label: "Title",
      required: true,
      maxLength: 50,
      default: defaultdata.title,
    },
  ];

  //

  return (
    <>
      <div>
        <div>
          <EcomSetupForm
            title="Stand Out"
            section={"SS"}
            imageUrl={defaultdata.imageUrl}
            inputs={inputs}
            sbmtData={handleFormSubmit}
            deleteData={handleFormDelete}
            suggestion="Suggested resolution 200 x 200"
            btn="Save"
            {...(defaultdata.id ? { deleteBtn: "Delete" } : {})}
          />
        </div>
      </div>
    </>
  );
}
