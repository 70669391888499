import api from "components/data/AxiosClient";
import { fixTimezoneOffset } from "global/helpers";

const ReportUrl = '/Report'

export const getListOfReportsAPI = async (search?: string) => {
    const res = await api.get(`${ReportUrl}/category${search ? `?reportName=${search}` : ''}`)
    return res.data
}

export const getListOfCustomizedReportsAPI = async (search?: string) => {
    const res = await api.get(`${ReportUrl}/customized${search ? `?reportName=${search}` : ''}`)
    return res.data
}

export const getCustomizedReportByIdAPI = async (id) => {
    let ReportId = id.queryKey[0]
    const res = await api.get(`${ReportUrl}/customized/${ReportId}`)
    return res.data
}

export const SaveCustomizedReportsAPI = async (data) => {
    const res = await api.post(`${ReportUrl}/customized`, data)
    return res.data
}

export const DeleteCustomizedReportsAPI = async (id) => {
    const res = await api.delete(`${ReportUrl}/customized/${id}`)
    return res.data
}

export const getListOfFavReportsAPI = async () => {
    const res = await api.get(`${ReportUrl}/favourite`)
    return res.data
}

export const ModifyReportFavsAPI = async (id) => {
    const res = await api.get(`${ReportUrl}/favourite/modify?reportCategoryId=${id}`)
    return res.data
}

export const PaymentReceiptReportAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/account/print-receipt?PaymentReceiptId=${id.queryKey[0]}`)
    return response.data
}

export const AccountStatementReportAPI = async (params) => {
    let extra = ''
    if(params && params?.startDate)
      extra += `?startDate=${fixTimezoneOffset(params.startDate)}`
    if(params && params?.endDate)
      extra += `&endDate=${fixTimezoneOffset(params.endDate)}`
    const response = await api.get(`Accounting/account-statement/${params.id}/${extra}`);
    return response.data;

}

export const SerialListReportAPI = async () => {
    const response = await api.post(`${ReportUrl}/inventory/serial-detail`, {})
    return response.data
}

export const InventoryPurchaseValueAPI = async () => {
    const response = await api.get(`${ReportUrl}/inventory/listing`, {})
    return response.data
}

export const AccountBalanaceReportAPI = async (id) => {
    const response = await api.post(`${ReportUrl}/account/balance-statement`, {customerId: id.queryKey[0]})
    return response.data
}

export const PurchaseOrderDetailReportAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/purchase-order/details?PurchaseOrderId=${id.queryKey[0]}`)
    return response.data
}

export const getTransactionReport = async (params) => {
    const response = await api.post(`${ReportUrl}/transactions`, params)
    return response.data
}

export const SalesOrderTransactionDetailReportAPI = async () => {
    const response = await api.post(`${ReportUrl}/sales-order/transaction-report`, {})
    return response.data
}

export const getSerialTransferReport = async (params) => {
    const response = await api.post(`${ReportUrl}/inventory/serial-transfer`, params)
    return response.data
}

export const getSalesOrderSummaryReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/sales-order/summary-report`, params)
    return response.data
}

export const getOpenOrdersSummaryReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/open-sales-order/summary-report`, params)
    return response.data
}

export const getPurchaseOrdersListingReportAPI = async () => {
    const response = await api.get(`${ReportUrl}/purchase-order/listing`)
    return response.data
}

export const getReceiveInvoiceDetailReportAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/receive-invoice-details/${id.queryKey[0]}`)
    return response.data
}

export const getOpenPurchaseOrderSummaryReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/purchase-order/orders-summary`, params)
    return response.data
}

export const getSalesDetailReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/account/sales-details-report`, params)
    return response.data
}

export const getInventoryReorderReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/inventory/reorder`, params)
    return response.data
}

export const getReportInit = async () => {
    const response = await api.get(`${ReportUrl}/init`)
    return response.data
}

export const getReportInventoryItems = async (queryId) => {
    let queryObj = queryId.queryKey[0]
    const response = await api.get(`inventory/items/hub/${queryObj.hubId}/type/${queryObj.itemType}`);
    return response.data
};

export const getReportVendors = async () => {
    const response = await api.get('vendors');
    return response.data.data;  
};

export const getReportMarkets = async () => {
    const response = await api.get('system-setup/markets');
    return response.data.data;  
};

export const getReportRegions = async () => {
    const response = await api.get('system-setup/regions');
    return response.data.data;  
};

export const getInventoryOnHandReport = async (params) => {
    const response = await api.post(`${ReportUrl}/inventory/onhand`, params)
    return response.data
}

export const getInventoryValuation = async () => {
    const response = await api.get(`${ReportUrl}/inventory-valuation`)
    return response.data
}

export const getSalesReport = async (params) => {
    const response = await api.post(`${ReportUrl}/sales-order/with-grouping`, params)
    return response.data
}

export const getPurchaseOrderReport = async (params) => {
    const response = await api.get(`${ReportUrl}/purchase-order/with-grouping?groupingElement=${params.groupingElement}`)
    return response.data
}

export const getInventoryValuationSummary = async () => {
    const response = await api.get(`${ReportUrl}/inventory-valuation-summary`)
    return response.data
}

export const getInventoryItemDetailAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/inventory/item-detail?itemId=${id.queryKey[0]}`)
    return response.data
}

export const SalesProfitReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/invoice/sales-profit`, params)
    return response.data
}

export const InventoryAgingReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/inventory/aging`, params)
    return response.data
}

export const InventoryIssueReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/inventory/issued`, params)
    return response.data
}

export const InvoiceApplicationDetailAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/invoice/application-Detail`, params)
    return response.data
}

export const InvoiceApplicationSummaryAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/invoice/application-summary`, params)
    return response.data
}

export const SalesOrderDetailReportAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/sales-order/order-detail?id=${id.queryKey[0]}`)
    return response.data
}

export const SOPackingSlipReportAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/sales-order/packing-slip?salesOrderId=${id.queryKey[0]}`)
    return response.data
}

export const CashReceiptRegisterReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/account/cash-receipt`, params)
    return response.data
}

export const ARAgingAsOfDateReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/ar/aging`, params)
    return response.data
}

export const SupplierBalanceReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/ap/aging`, params)
    return response.data
}

export const CreditMemoDetailAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/credit-memo/${id.queryKey[0]}`)
    return response.data
}

export const InvoiceDetailAPI = async (id) => {
    const response = await api.get(`${ReportUrl}/invoice-details/${id.queryKey[0]}`)
    return response.data
}

export const InvoicePastDueReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/invoice/past-due`, params)
    return response.data
}

export const SerialDetailAsOfDateReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/serial/as-of-date`, params)
    return response.data
}

export const PurchasingReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/purchasing/${params?.groupingElement}`, params?.extraParams)
    return response.data
}

export const BillPaymentsReportAPI = async (params) => {
    const response = await api.post(`${ReportUrl}/bills-grouped/${params?.groupingElement}`, params?.extraParams)
    return response.data
}

export const ChartOfAccountsReportAPI = async () => {
    const response = await api.get(`${ReportUrl}/accounting/chart-of-accounts`)
    return response.data
}