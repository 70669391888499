import { useContext, useEffect, useState } from "react";
import SectionTitle from "elements/SectionTitle";
import { useFormContext, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { StateModel, states } from "components/data/States";
import "../CustomerDetails.scss";
import { CustomerInfoDTO, CustomerShippingInfo } from "../Customers.models";
import Delete from "img/delete.svg";
import { Form } from "react-bootstrap";
import DispatchContext from "context/DispatchContext";
import BluesAgGrid from "elements/BluesAgGrid";
import { cloneObj } from "global/helpers";
import { zipCodeRegEx } from "global/regExp/regularExpressions";
import CountrySelect from "components/CountrySelect/CountrySelect";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

type Props = {
  isDisabled: boolean;
  shippingData: CustomerShippingInfo[];
  onDataUpdated: (data: CustomerShippingInfo[]) => void;
};
// Global unique index counter
let nextIndex = 0;
const ShippingInfo = ({ isDisabled, shippingData, onDataUpdated }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(null);
  const {
    formState: { errors },
    control,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useFormContext<CustomerInfoDTO>();
  const appDispatch = useContext(DispatchContext);

  const resetForm = () => {
    resetShippingInfo();
    setSelectedIndex(null);
  };
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [toBeRemoved, setToBeRemoved] = useState();
  const onRowDoubleClicked = (item: CustomerShippingInfo) => {
    setSelectedIndex(item.index);
    populateFormFields(item);
  };

  useEffect(() => {
    if (selectedIndex !== null) {
      const item = shippingData.find((data) => data.index === selectedIndex);
      if (item) {
        populateFormFields(item);
      }
    }
  }, [selectedIndex, shippingData]);

  const populateFormFields = (item) => {
    setValue("shippingInfoData.address.address", item.address.address, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.address1", item.address.address1, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.city", item.address.city, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.state", item.address.state, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.country", item.address.country, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.zipCode", item.address.zipCode, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue(
      "shippingInfoData.address.mobileNumber",
      item.address.mobileNumber,
      { shouldValidate: true, shouldTouch: true }
    );
    setValue("shippingInfoData.address.workNumber", item.address.workNumber, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.contact", item.address.contact, {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.company", item.company, {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onRemoveItemClicked = (item: CustomerShippingInfo) => {
    const shippingList = getValues("shippingInfos").filter(
      (d) => d.index !== item.index
    );
    setValue("shippingInfos", shippingList, { shouldDirty: true });

    onDataUpdated(cloneObj(shippingList));
    setShowDeleteModal(false);
  };

  // Function to generate the next unique index
  const generateIndex = () => nextIndex++;

  // Corrected updateGrid function
  const updateGrid = () => {
    // Extract the current shipping info form values
    const contact = getValues("shippingInfoData.address.contact");
    const company = getValues("shippingInfoData.company");
    const shippingData = getValues("shippingInfoData");
    const shippingList = getValues("shippingInfos");

    // Check for validation errors
    if (
      !contact ||
      !company ||
      errors.shippingInfoData?.address?.zipCode ||
      errors.shippingInfoData?.address?.email
    ) {
      // Set errors for required fields
      if (!contact) {
        setError("shippingInfoData.address.contact", {
          type: "value",
          message: "This field is required",
        });
      }
      if (!company) {
        setError("shippingInfoData.company", {
          type: "value",
          message: "This field is required",
        });
      }
      if (errors.shippingInfoData?.address?.email) {
        setError("shippingInfoData.address.email", {
          type: "value",
          message: "Invalid email format",
        });
      }
      const notification = {
        variant: "danger",
        msg: "Please fill in the required shipping fields",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      if (selectedIndex !== null) {
        // Update existing shipping info with the current index
        const updatedList = shippingList.map((si) => {
          if (selectedIndex === si.index) {
            return {
              ...shippingData,
              index: selectedIndex,
              address: { ...shippingData.address },
            };
          }
          return si;
        });
        setValue("shippingInfos", updatedList, { shouldDirty: true });
        onDataUpdated(cloneObj(updatedList));
      } else {
        // Add new shipping info with a unique index
        shippingData.index = generateIndex();
        const newList = [
          ...shippingList,
          { ...shippingData, address: { ...shippingData.address } },
        ];
        setValue("shippingInfos", newList, {
          shouldValidate: true,
          shouldTouch: true,
        });
        onDataUpdated(cloneObj(newList));
      }
      resetShippingInfo();
      setSelectedIndex(null);
    }
  };

  const resetShippingInfo = () => {
    setValue("shippingInfoData.company", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.contact", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.workNumber", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.faxNumber", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.address", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.address1", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.city", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.state", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.country", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.zipCode", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
    setValue("shippingInfoData.address.email", "", {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const columns = [
    {
      field: "company",
      headerName: "Company",
      sortable: false,
      cellRenderer: (params) => {
        let value = params.data.company ? params.data.company : "";
        return `${value}`;
      },
    },
    {
      field: "address",
      headerName: "Location",
      sortable: false,
      cellRenderer: (params) => {
        let city = params.data?.address?.city ? params.data?.address?.city : "";
        let state = params.data?.address?.state
          ? states.filter(
              (value) => value.value === params.data.address.state
            )[0]?.label
          : "";
        return `
        <div>
          ${city}
          <br />
          <span class="text-muted">${state}</spam>
        <div>
      `;
      },
    },
    {
      field: "workNumber",
      headerName: "Work",
      editable: false,
      cellRenderer: (params) => {
        let value = params.data?.address?.workNumber
          ? params.data?.address?.workNumber
          : "";
        return `${value}`;
      },
    },
    {
      field: "action",
      headerName: "Action",
      floatingFilter: false,
      filter: false,
      sortable: false,
      hide: isDisabled,
      onCellClicked: (params) => {
        setToBeRemoved(params.data);
        setShowDeleteModal(true);
      },
      cellRenderer: () => {
        return `
            <div class="btn text-danger">
              <img class="d-none" src=${Delete} alt="Delete Icon" />
              Remove 
            </div>
      `;
      },
    },
  ];

  return (
    <div className="row">
      <div className="col-lg-7 col-md-7 col-sm-12 items-page">
        <div className="h-100 w-100">
          <BluesAgGrid
            data={shippingData}
            columns={columns}
            onRowDoubleClicked={onRowDoubleClicked}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className="col-lg-5 col-md-5 col-sm-12">
        <SectionTitle
          editable={false}
          title={
            selectedIndex !== null
              ? "Edit Shipping Info"
              : "New Shipping Information"
          }
        />
        <div className="form-group mt-4 mb-4">
          <label>Contact</label>
          <input
            {...register("shippingInfoData.address.contact")}
            className={`form-control ${
              errors.shippingInfoData?.address?.contact && "required_field"
            }`}
            onInput={() => clearErrors("shippingInfoData.address.contact")}
            disabled={isDisabled}
          />
          {errors.shippingInfoData?.address?.contact && (
            <p className="text-danger">
              {errors.shippingInfoData?.address?.contact?.message}
            </p>
          )}
        </div>
        <div className="form-group mb-4">
          <label>Work No.</label>
          <Controller
            name="shippingInfoData.address.workNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                value={value}
                onChange={onChange}
                country={"us"}
                disabled={isDisabled}
              />
            )}
          />
        </div>
        <div className="form-group mb-4">
          <label>Email</label>
          <Controller
            name="shippingInfoData.address.email"
            control={control}
            rules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <input
                className={`form-control ${error ? "is-invalid" : ""}`}
                type="email"
                {...field}
                disabled={isDisabled}
              />
            )}
          />

          {errors.shippingInfoData?.address?.email && (
            <p className="text-danger">
              {errors.shippingInfoData?.address?.email.message}
            </p>
          )}
        </div>
        <div className="form-group mt-4 mb-4">
          <label>Company</label>
          <input
            {...register("shippingInfoData.company")}
            className={`form-control ${
              errors.shippingInfoData?.company && "required_field"
            }`}
            onInput={() => clearErrors("shippingInfoData.company")}
            disabled={isDisabled}
          />
          {errors.shippingInfoData?.company && (
            <p className="text-danger">
              {errors.shippingInfoData?.company?.message}
            </p>
          )}
        </div>
        <div className="section pt-4">
          <div className="form-group mb-4">
            <label>Address</label>
            <input
              {...register("shippingInfoData.address.address")}
              className="form-control"
              disabled={isDisabled}
            />
          </div>
          <div className="form-group mb-4">
            <label>Address 1</label>
            <input
              {...register("shippingInfoData.address.address1")}
              className="form-control"
              disabled={isDisabled}
            />
          </div>
          <div className="row" style={{ marginLeft: "-10px" }}>
            <div className="col">
              <div className="form-group mb-4">
                <label>City</label>
                <input
                  {...register("shippingInfoData.address.city")}
                  className="form-control"
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-4">
                <label>State</label>
                <Controller
                  name="shippingInfoData.address.state"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={isDisabled || selectedCountry !== "US"}
                      isClearable
                      options={states}
                      isSearchable={true}
                      menuPlacement="auto"
                      value={
                        selectedCountry === "US"
                          ? states.find((opt) => opt?.value === value) || null
                          : null
                      }
                      onChange={(val: StateModel) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: "-10px" }}>
            <div className="col">
              <div className="form-group mb-4">
                <label>Country</label>
                <Controller
                  name="shippingInfoData.address.country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <CountrySelect
                        isDisabled={isDisabled}
                        value={field.value}
                        onChange={(countryCode) => {
                          field.onChange(countryCode);
                          setSelectedCountry(countryCode);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-4">
                <label>Zip Code</label>
                <Form.Control
                  {...register("shippingInfoData.address.zipCode", {
                    pattern: {
                      value: zipCodeRegEx,
                      message: "Invalid Zip Code",
                    },
                  })}
                  className={`form-control ${
                    errors.shippingInfoData?.address?.zipCode &&
                    "required_field"
                  }`}
                  disabled={isDisabled}
                />
                {errors.shippingInfoData?.address?.zipCode && (
                  <p className="text-danger">
                    {errors.shippingInfoData?.address?.zipCode.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={resetForm}
            disabled={isDisabled}
          >
            {selectedIndex === null ? "Reset" : "Cancel"}
          </button>
          <button
            type="button"
            className="btn btn-primary "
            onClick={updateGrid}
            disabled={isDisabled}
          >
            {selectedIndex === null ? "Add Ship. info" : "Save Changes"}
          </button>
        </div>
      </div>
      <ConfirmationModal
        title="Remove Shipping Address"
        message="Are you sure you want to remove this shipping address?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => onRemoveItemClicked(toBeRemoved)}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Remove"
        type="confirmation-danger"
      />
    </div>
  );
};

export default ShippingInfo;
