import { useEffect, useState, useContext, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useForm, FormProvider } from "react-hook-form";
import { Tabs, Tab } from "react-bootstrap";
import DispatchContext from "context/DispatchContext";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { invoiceSo } from "./InvoicesServices/InvoicesServices";
import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import { REQUIRED_FIELDS_MSG } from "constants/NotificationMsgs";
import Lookup from "elements/Lookup";
import {
  getSalesOrder,
  getSalesOrdersForInvoice,
} from "pages/SalesOrder/SalesOrders/SalesOrderServices/SalesOrderServices";
import { statusEnum } from "pages/SalesOrder/StatusEnum";
import AgGrid from "elements/AgGrid";
import { getBaseCurrency } from "components/currency";

const CreateNormalInvoice = () => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [selectedSO, setSelectedSO] = useState();
  const [orderDetails, setOrderDetails] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const gridRef = useRef(null);

  const accountNumberColumns = [
    {
      field: "orderNumber",
      headerName: "Order No.",
      resizable: true,
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      resizable: true,
    },
    {
      field: "company",
      headerName: "Company",
      resizable: true,
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) =>
        params.data.status ? statusEnum[params.data.status] : "",
      resizable: true,
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
      resizable: true,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      valueGetter: (params) =>
        params.data.orderDate
          ? `${dateFormmaterNoTime(params.data.orderDate)}`
          : "",
      resizable: true,
    },
  ];

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues, setValue, reset } = methods;

  const getOrderDetails = useMutation(getSalesOrder, {
    async onSuccess(response) {
      setOrderDetails(response.data);
      gridRef.current = response.data.itemOrders;
      setExpenses(response.data.salesOrderExpenses);
    },
  });

  const { isLoading: isLoadingSo, data: salesOrders } = useQuery(
    [{ states: [4, 5] }],
    getSalesOrdersForInvoice
  );

  // getOrders.mutate('?state=4&state=5')
  const addInvoice = useMutation(invoiceSo, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Order invoiced successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem invoicing orders"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingSo });
  }, [isLoadingSo]);

  useEffect(() => {
    if (selectedSO) {
      setValue("orderNumber", selectedSO["orderNumber"], { shouldDirty: true });
      setValue("targetId", selectedSO["salesOrderId"]);
      getOrderDetails.mutate(selectedSO["salesOrderId"]);
      setOpenSelection(false);
      setSelectedSO(null);
    }
  }, [selectedSO]);

  const onBackClick = () => {
    history.push(AppUrls.invoices_list);
  };

  const onCancel = () => {
    reset();
    setSelectedSO(null);
    setOrderDetails(null);
  };

  const onSubmit = () => {
    let obj = {
      salesOrderId: orderDetails.salesOrderId,
      itemOrders: gridRef.current,
    };
    addInvoice.mutate(obj);
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item",
    },
    {
      field: "description",
      headerName: "Item Description",
    },
    {
      field: "quantity",
      headerName: "Order QTY",
    },
    {
      field: "shipQuantity",
      headerName: "Ship QTY",
    },
    {
      field: "taxable",
      headerName: "Taxable",
      cellRendererFramework: (params) => (params.value == true ? "Yes" : "No"),
    },
    {
      field: "price",
      headerName: "Price",
      cellRenderer: (params) => formatNumber(params.value),
      editable: true,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      valueGetter: (params) => params.data.quantity * params.data.price,
      cellRenderer: (params) => formatNumber(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub Key",
    },
    {
      field: "warrantyDays",
      headerName: "Warranty",
    },
    {
      field: "Non Com",
      headerName: "nonCom",
    },
  ];

  const expenseColumns = [
    {
      field: "expenseItemId",
      headerName: "Expense Item ID",
    },
    {
      field: "expenseName",
      headerName: "Expense Name",
    },
    {
      field: "amount",
      headerName: "Amount",
      cellRenderer: (params) => formatNumber(params.value),
    },
  ];

  const expenseTotal = expenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Create Invoice
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                onClick={onCancel}
              >
                Reset
              </button>
              <button type="submit" className="btn btn-success">
                Create
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="row gx-5">
                    <div className="col-sm-6">
                      <label>Sales Order No</label>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="targetId"
                        isRequired={true}
                        initialData={salesOrders?.data?.map((item) => ({
                          ...item,
                          id: item.salesOrderId,
                          name: item.orderNumber,
                        }))}
                        onSelection={setSelectedSO}
                        inputValue={getValues("orderNumber")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  gx-5">
                <div className="col-lg-8 col-sm-12">
                  <div className="row gx-5 gy-4">
                    <div className="col-lg-6 col-sm-12">
                      <label>Order Date</label>
                      <div>
                        {orderDetails &&
                          new Date(orderDetails["orderDate"]).toLocaleString()}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12"></div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Account</label>
                      <div>{orderDetails && orderDetails["accountNumber"]}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Hub ID</label>
                      <div>{orderDetails && orderDetails["hubKey"]}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Origin</label>
                      <div>{orderDetails?.origin === 1 ? "System" : "Web"}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Status</label>
                      <div>
                        {orderDetails && statusEnum[orderDetails["status"]]}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <label>Company</label>
                      <div className="fw-bold mt-3">
                        {orderDetails && orderDetails["billingInfo"]["company"]}
                      </div>
                      <div className="mt-3">
                        {orderDetails &&
                        orderDetails.billingInfo?.contact &&
                        orderDetails.billingInfo?.contact.length > 0 ? (
                          orderDetails.billingInfo?.contact
                        ) : (
                          <span className="text-muted fst-italic">Contact</span>
                        )}
                        <br />
                        {orderDetails &&
                        orderDetails.billingInfo?.address &&
                        orderDetails.billingInfo?.address.length > 0 ? (
                          orderDetails.billingInfo?.address
                        ) : (
                          <span className="text-muted fst-italic">Address</span>
                        )}
                        <br />
                        {orderDetails &&
                        orderDetails.billingInfo?.city &&
                        orderDetails.billingInfo?.city.length > 0 ? (
                          orderDetails.billingInfo?.city
                        ) : (
                          <span className="text-muted fst-italic">City</span>
                        )}{" "}
                        {orderDetails &&
                        orderDetails.billingInfo?.state &&
                        orderDetails.billingInfo?.state.length > 0 ? (
                          orderDetails.billingInfo?.state
                        ) : (
                          <span className="text-muted fst-italic">State</span>
                        )}{" "}
                        {orderDetails &&
                        orderDetails.billingInfo?.zipCode &&
                        orderDetails.billingInfo?.zipCode.length > 0 ? (
                          orderDetails.billingInfo?.zipCode
                        ) : (
                          <span className="text-muted fst-italic">
                            Zip Code
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3">
                      <label>Ship To</label>
                      <div className="fw-bold mt-3">
                        {orderDetails && orderDetails.shippingInfo?.company}
                      </div>
                      <div className="mt-3">
                        {orderDetails &&
                        orderDetails.shippingInfo?.contact &&
                        orderDetails.shippingInfo?.contact.length > 0 ? (
                          orderDetails.shippingInfo?.contact
                        ) : (
                          <span className="text-muted fst-italic">Contact</span>
                        )}
                        <br />
                        {orderDetails &&
                        orderDetails.shippingInfo?.address &&
                        orderDetails.shippingInfo?.address.length > 0 ? (
                          orderDetails.shippingInfo?.address
                        ) : (
                          <span className="text-muted fst-italic">Address</span>
                        )}
                        <br />
                        {orderDetails &&
                        orderDetails.shippingInfo?.city &&
                        orderDetails.shippingInfo?.city.length > 0 ? (
                          orderDetails.shippingInfo?.city
                        ) : (
                          <span className="text-muted fst-italic">City</span>
                        )}{" "}
                        {orderDetails &&
                        orderDetails.shippingInfo?.state &&
                        orderDetails.shippingInfo?.state.length > 0 ? (
                          orderDetails.shippingInfo?.state
                        ) : (
                          <span className="text-muted fst-italic">State</span>
                        )}{" "}
                        {orderDetails &&
                        orderDetails.shippingInfo?.zipCode &&
                        orderDetails.shippingInfo?.zipCode.length > 0 ? (
                          orderDetails.shippingInfo?.zipCode
                        ) : (
                          <span className="text-muted fst-italic">
                            Zip Code
                          </span>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="col-lg-6 col-sm-12 my-2">
                      <label>Ship Via</label>
                      <div>
                        {orderDetails && orderDetails["shippingMethod"] ? (
                          orderDetails["shippingMethod"]["name"]
                        ) : (
                          <span className="text-muted fst-italic">
                            Ship via
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 my-2">
                      <label>Ship by</label>
                      <div>
                        {orderDetails && orderDetails["representativeName"] ? (
                          orderDetails["representativeName"]
                        ) : (
                          <span className="text-muted fst-italic">Ship by</span>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="col-lg-6 col-sm-12 mt-0">
                      <label>Cust Po</label>
                      <div>
                        {orderDetails && orderDetails["customerPO"] ? (
                          orderDetails["customerPO"]
                        ) : (
                          <span className="text-muted fst-italic">Cust PO</span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-0">
                      <label>Project No.</label>
                      <div>
                        {orderDetails && orderDetails["projectNumber"] ? (
                          orderDetails["projectNumber"]
                        ) : (
                          <span className="text-muted fst-italic">
                            Project No.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row mt-4">
                    <div className="col-lg-6 col-sm-12 px-4">
                      <div className="d-flex">
                        <div className="text-secondary">TOTAL QTY</div>
                        <div className="flex-grow-1 text-center">
                          {orderDetails && orderDetails["totalItems"]}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="d-flex">
                        <div className="text-secondary">TOTAL ITEMS</div>
                        <div className="flex-grow-1 text-center">
                          {orderDetails && orderDetails["itemsQuantity"]}
                        </div>
                      </div>
                    </div>
                    <div className="px-4">
                      <hr />
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between px-4">
                      <div className="text-secondary">SUBTOTAL</div>
                      <div>
                        {orderDetails &&
                          formatNumber(
                            orderDetails["subTotal"] * orderDetails["rate"],
                            baseCurrency &&
                              getCurrencyByCode(
                                orderDetails?.currencyCode
                                  ? orderDetails.currencyCode
                                  : baseCurrency
                              ).code
                          )}
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between px-4">
                      <div className="text-secondary">TAX AMOUNT</div>
                      <div>
                        {orderDetails &&
                          formatNumber(
                            orderDetails &&
                              orderDetails["taxTotal"] * orderDetails["rate"],
                            baseCurrency &&
                              getCurrencyByCode(
                                orderDetails?.currencyCode
                                  ? orderDetails.currencyCode
                                  : baseCurrency
                              ).code
                          )}
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between px-4">
                      <div className="text-secondary">SHIPPING COST</div>
                      <div>
                        {orderDetails &&
                          formatNumber(
                            orderDetails &&
                              orderDetails["shipCost"] * orderDetails["rate"],
                            baseCurrency &&
                              getCurrencyByCode(
                                orderDetails?.currencyCode
                                  ? orderDetails.currencyCode
                                  : baseCurrency
                              ).code
                          )}
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between px-4">
                      <div className="text-secondary">DISCOUNT</div>
                      <div>
                        {orderDetails &&
                          formatNumber(
                            orderDetails &&
                              orderDetails["discount"] * orderDetails["rate"],
                            baseCurrency &&
                              getCurrencyByCode(
                                orderDetails?.currencyCode
                                  ? orderDetails.currencyCode
                                  : baseCurrency
                              ).code
                          )}
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between px-4">
                      <div className="text-secondary">TOTAL EXPENSES</div>
                      <div>
                        {formatNumber(
                          expenseTotal,
                          baseCurrency &&
                            getCurrencyByCode(
                              orderDetails?.currencyCode
                                ? orderDetails.currencyCode
                                : baseCurrency
                            ).code
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="section mt-3 p-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fs-5">ORDER TOTAL</div>
                          <div className="text-success fs-4">
                            {formatNumber(
                              orderDetails?.total * orderDetails?.rate,
                              baseCurrency &&
                                getCurrencyByCode(
                                  orderDetails?.currencyCode
                                    ? orderDetails.currencyCode
                                    : baseCurrency
                                ).code
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section p-4 pt-0">
                    <div className="row gx-5 gy-4">
                      <div className="col-lg-12 col-sm-12">
                        <label>Date Entered</label>
                        <div>
                          {orderDetails &&
                            dateFormmaterNoTime(orderDetails["orderDate"])}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section p-4 pt-0">
                    <div className="row gx-5 gy-4 pt-4">
                      <label>Order Remark</label>
                      <div>
                        {(orderDetails && orderDetails["remarks"]) ?? (
                          <span className="text-muted fst-italic">
                            Order Remark
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs defaultActiveKey={"Items"} className="mt-5">
                <Tab
                  eventKey="Items"
                  title={
                    <div className="tab-inner-title">Sales Order Items</div>
                  }
                >
                  <div className="h-450">
                    <AgGrid data={gridRef?.current ?? []} columns={columns} />
                  </div>
                </Tab>
                <Tab
                  eventKey="Expenses"
                  title={<div className="tab-inner-title">Expenses</div>}
                >
                  <div className="h-450">
                    <AgGrid data={expenses} columns={expenseColumns} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={salesOrders?.data}
        columns={accountNumberColumns}
        setRowClicked={setSelectedSO}
      />
    </>
  );
};

export default CreateNormalInvoice;
