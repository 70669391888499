import Lookup from "elements/Lookup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import Dropzone from 'react-dropzone-uploader';
import Delete from "img/delete.svg";
import * as AppUrls from "constants/AppUrls";
import { useMutation, useQuery } from "react-query";
import { getVendors } from "pages/Vendors/Vendors.services";
import { VendorModelDTO } from "pages/Vendors/Vendors.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import {
  addAssets,
  addCheque,
  addExpenses,
  getLedgers,
} from "./AccountingServices";
import { PaymentMethod } from "pages/Invoices/Invoices.model";
import { getPaymentMethods } from "pages/Invoices/InvoicesServices/InvoicesServices";
import DispatchContext from "context/DispatchContext";
import {
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import LedgerSelection from "./LedgerSelection";
import backArrow from "img/back-arrow.svg";
import { LedgerTypeEnum } from "./enum/AccountEnum";
import { getBaseCurrency } from "components/currency";

const Expenses = ({
  match = null,
  hideTitle = false,
  onCreation = null,
  bankId = null,
  payment = null,
  isAsset = false,
  expenseDate = null,
}) => {
  const history = useHistory();
  const gridRef = useRef(null);
  const rowRef = useRef<number>();
  const isDisabled = useRef<boolean>(false);
  const appDispatch = useContext(DispatchContext);
  const location = useLocation();
  const [selectedVendor, setSelectedVendor] = useState<VendorModelDTO>(null);
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionVendor, setOpenSelectionVendor] =
    useState<boolean>(false);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);
  const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
  const [, setAmountChange] = useState(0);
  const isCheque = match?.path === AppUrls.add_cheque;

  const { data: vendorsList } = useQuery("vendorsList", getVendors);

  const { data: ledgers } = useQuery("ledgers", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Expenses })
  );

  const { data: paymentAccounts, refetch } = useQuery("expenses", () =>
    getLedgers({
      ledgerType: isCheque
        ? LedgerTypeEnum.Bank
        : LedgerTypeEnum.CashAndCashEquivelant,
    })
  );

  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );

  const addMutationE = useMutation(addExpenses, {
    async onSuccess(data) {
      let notification = {
        variant: "success",
        msg: "Expenses added successfully",
      };
      if (onCreation) onCreation(data?.operation?.journalEntries[0]?.journalId);
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding expenses"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const addMutationA = useMutation(addAssets, {
    async onSuccess(data) {
      let notification = {
        variant: "success",
        msg: "Asset added successfully",
      };
      if (onCreation) onCreation(data?.operation?.journalEntries[0]?.journalId);
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding asset"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const addMutationC = useMutation(addCheque, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Cheque added successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding cheque"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    reset,
    control,
    register,
    setValue,
  } = methods;
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const vendorColumns = [
    {
      field: "vendorNumber",
      headerName: "Vendor No",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "vendorTypeName",
      headerName: "Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "creditLimit",
      headerName: "Credit Limit",
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const onCellValueChanged = () => {
    let filledRows = 0;
    for (let node of getRowData()) if (node.accountName !== null) filledRows++;
    if (getRowData().length === filledRows) addRow();
  };

  const columns = [
    {
      field: "accountNumber",
      headerName: "#",
      width: 100,
    },
    {
      field: "accountName",
      headerName: "Account",
      cellRendererFramework: (params) => {
        return (
          <LedgerSelection
            initialData={ledgers?.data.map((ledger) => ({
              ...ledger,
              id: ledger.ledgerId,
              name: ledger.accountNumber,
            }))}
            inputValue={params.value}
            onSelection={(data) => onAccountSelection(data, params?.rowIndex)}
            onButtonClicked={() => {
              setShowAccountModal(true);
              rowRef.current = params.rowIndex;
            }}
          />
        );
      },
      cellClass: "overflow-visible",
    },
    {
      field: "description",
      headerName: "Description",
      editable: true,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      editable: true,
      cellRenderer: (params) =>
        isNaN(params.value) ? null : formatNumber(params.value, currencySymbol),
      onCellValueChanged: () => {
        setAmountChange((prev) => prev + 1);
        onCellValueChanged();
      },
      width: 150,
    },
    {
      field: "action",
      headerName: "",
      width: 150,
      cellRenderer: () => {
        return `
                    <div class="btn text-danger">
                      <img src=${Delete} alt="Delete Icon" />
                      Delete 
                    </div>
              `;
      },
      onCellClicked: (params) => {
        gridRef?.current?.api.applyTransaction({
          remove: [params.data],
        });
      },
    },
  ];

  useEffect(() => {
    if (location) {
      onCancel();
      refetch();
      addRow();
    }
  }, [location]);

  useEffect(() => {
    if (bankId && paymentAccounts && paymentMethods) {
      let found = paymentAccounts.data?.find(
        (account) => account.ledgerId === Number(bankId)
      );
      onLedgerSelection(found);
      isDisabled.current = true;
      setValue(
        "paymentMethodId",
        paymentMethods?.find((opt) => opt?.name === "Check")?.paymentMethodId
      );
      setValue("paymentDate", new Date(expenseDate));
    }
  }, [bankId, paymentAccounts, paymentMethods, expenseDate]);

  const onGridReady = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const getRowData = () => {
    const rowData = [];
    gridRef.current?.api.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  };

  const getTotalAmount = () => {
    let amount = 0;
    for (let node of getRowData())
      amount += isNaN(node.amount) ? 0 : Number(node.amount);
    return amount;
  };

  const addRow = () => {
    const newItems = [
      {
        accountNumber: getRowData().length + 1,
        accountName: null,
        description: null,
        amount: payment ?? undefined,
      },
    ];
    gridRef?.current?.api.applyTransaction({
      add: newItems,
    });
  };

  const clearRows = () => {
    gridRef.current.api.setRowData([]);
  };

  const onPayeeSelection = (e) => {
    setSelectedVendor(e);
    setOpenSelectionVendor(false);
    setValue("payeeId", e.vendorId);
  };

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("paymentAccountId", e?.ledgerId);
  };

  const onAccountSelection = (nodeData, rowIndex) => {
    setShowAccountModal(false);
    const itemsToUpdate = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort(
      function (rowNode, index) {
        if (index != rowIndex) {
          return;
        }
        const data = rowNode.data;
        data.accountName = nodeData.accountNumber;
        data.categoryId = nodeData.ledgerId;
        itemsToUpdate.push(data);
      }
    );
    gridRef.current.api.applyTransaction({ update: itemsToUpdate });
  };

  const onBackClick = () => {
    history.push(isCheque ? AppUrls.cheque : AppUrls.expenses);
  };

  const onCancel = () => {
    reset();
    setSelectedLedger(null);
    setSelectedVendor(null);
    clearRows();
    setValue("paymentAccountId", null);
    setValue("payeeId", null);
  };

  const onSubmit = (values) => {
    let paymentDetailEntries = [];
    paymentDetailEntries = getRowData()
      .filter((data) => data.accountName !== null && data.amount !== null)
      .map((row) => {
        return {
          ledgerId: row.categoryId,
          description: row.description,
          amount: Number(row.amount),
        };
      });
    if (paymentDetailEntries.length === 0) {
      let notification = {
        variant: "danger",
        msg: "Please add expenses in the table",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      paymentDetailEntries.some(
        (entry) => isNaN(entry.amount) || entry.amount === 0
      )
    ) {
      let notification = {
        variant: "danger",
        msg: "Please enter a valid amount",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      if (isCheque)
        addMutationC.mutate({
          ...values,
          bankAccountId: values.paymentAccountId,
          chequeNo: values.refNo,
          paymentDetailEntries,
        });
      else {
        if (isAsset) addMutationA.mutate({ ...values, paymentDetailEntries });
        else addMutationE.mutate({ ...values, paymentDetailEntries });
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            {!hideTitle && (
              <div className="back-btn " onClick={onBackClick}>
                <img src={backArrow} alt="back arrow" />
                {isCheque ? "Cheque" : "Expenses"}
              </div>
            )}
            <div className="d-flex justify-content-end ">
              <button
                type="button"
                className={`btn btn-outline-primary`}
                onClick={onCancel}
                disabled={!isDirty}
              >
                Reset
              </button>
              <button
                type="submit"
                className={`btn btn-primary`}
                disabled={!isDirty}
              >
                Save Changes
              </button>
            </div>
          </div>

          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row gy-4">
                <div className="col-sm-3">
                  <label>Payee</label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionVendor(true)}
                    inputName="payeeId"
                    initialData={vendorsList?.data?.map((item) => ({
                      ...item,
                      id: item.vendorId,
                      name: item.vendorNumber,
                    }))}
                    onSelection={onPayeeSelection}
                    inputValue={selectedVendor?.vendorNumber}
                  />
                </div>
                <div className="col-sm-3">
                  <label>
                    {isCheque ? "Bank" : "Payment"} Account{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionLedger(true)}
                    inputName="paymentAccountId"
                    isRequired={true}
                    initialData={paymentAccounts?.data?.map((item) => ({
                      ...item,
                      id: item.ledgerId,
                      name: item.accountName,
                    }))}
                    onSelection={onLedgerSelection}
                    inputValue={selectedLedger?.accountName}
                    isDisabled={isDisabled.current}
                    hasError={errors["paymentAccountId"] ? true : false}
                  />
                  {errors["paymentAccountId"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-sm-3 d-flex align-items-end">
                  <div className="text-primary">
                    Balance {formatNumber(selectedLedger?.balance ?? 0)}
                  </div>
                </div>
                <div className={`col-sm-3 ${hideTitle && "invisible"}`}>
                  <div className="section py-1 text-center text-primary">
                    <div>Amount</div>
                    <div className="fs-3">{formatNumber(getTotalAmount())}</div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label>
                      Payment Date <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="paymentDate"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          onChange={onChange}
                          selected={value ? value : null}
                          className={`${
                            errors["paymentDate"] && "required_field"
                          }`}
                        />
                      )}
                    />
                  </div>
                  {errors["paymentDate"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-sm-3">
                  {isCheque ? (
                    <div className="form-group">
                      <label>Mailing Address</label>
                      <input
                        {...register("mailingAddress")}
                        className="form-control"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="form-group">
                        <label>Payment Method</label>
                        <Controller
                          control={control}
                          name="paymentMethodId"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={paymentMethods?.map((method) => ({
                                label: method.name,
                                value: method.paymentMethodId,
                              }))}
                              isSearchable={true}
                              value={
                                paymentMethods
                                  ?.map((method) => ({
                                    label: method.name,
                                    value: method.paymentMethodId,
                                  }))
                                  ?.find((opt) => opt?.value === value) || null
                              }
                              onChange={(val) => onChange(val?.value)}
                              isClearable={true}
                              isDisabled={isDisabled.current}
                            />
                          )}
                        />
                      </div>
                      {errors["paymentMethodId"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </>
                  )}
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label>{isCheque ? "Cheque" : "Ref"} No</label>
                    <input {...register("refNo")} className="form-control" />
                  </div>
                </div>
                <div className="col-sm-9 border p-4">
                  <div className="ag-theme-alpine h-350 operations-grid">
                    <AgGridReact
                      ref={gridRef}
                      rowData={[]}
                      columnDefs={columns}
                      animateRows={true}
                      onGridReady={onGridReady}
                      stopEditingWhenCellsLoseFocus={true}
                    ></AgGridReact>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <button
                        type="button"
                        onClick={addRow}
                        className="btn btn-outline-primary"
                      >
                        Add new
                      </button>
                      <button
                        type="button"
                        onClick={clearRows}
                        className="btn btn-outline-primary"
                      >
                        Clear all lines
                      </button>
                    </div>
                    <div className="fw-bold">
                      Total {formatNumber(getTotalAmount())}
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group">
                    <label>Memo</label>
                    <textarea {...register("memo")} className="form-control" />
                  </div>
                </div>
                {/* <div className="col-sm-8">
                                    <label>Attachments</label>
                                    <Dropzone />
                                </div> */}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Vendor"}
        showModal={openSelectionVendor}
        setShowModal={setOpenSelectionVendor}
        data={vendorsList?.data}
        columns={vendorColumns}
        setRowClicked={onPayeeSelection}
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
      <SelectionModal
        modal="account"
        showModal={showAccountModal}
        setShowModal={setShowAccountModal}
        data={ledgers?.data}
        columns={ledgersColumns}
        setRowClicked={(data) => onAccountSelection(data, rowRef.current)}
      />
    </>
  );
};

export default Expenses;
