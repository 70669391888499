import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";

import { getPurchaseOrdersListingReportAPI } from "./services/ReportServices";

import { useQuery } from "react-query";

const PurchaseOrderListingReport = () => {
  const history = useHistory();

  const pageTitle = "Purchasr Order Listing Report";
  const backUrl = AppUrls.purchase_order_list;

  const tableHeaders = [
    "Vendor",
    "Hub",
    "Date",
    "Open amount",
    "Req date",
    "PO Date",
    "Shipping Method",
    "Shipping Cost",
    "Received",
    "Discount",
  ];

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="static-page">
        <div className="page-content-wrapper p-0">
          <div className="grid-container"></div>
        </div>
      </div>
    </form>
  );
};

export default PurchaseOrderListingReport;
