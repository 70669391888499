import { useState, useContext } from "react";
import { Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import password_hide from "img/password-hide.svg";
import password_show from "img/password-show.svg";
import "./ChangePassword.scss";
import { useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import { UPDATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { UserPassword } from "../User.models";
import { updatePassword } from "../userServices";

const ChangePassword = (props) => {
  const { isChangePassword, setIsChangePassword } = props;
  const appDispatch = useContext(DispatchContext);
  const [currentPasswordType, setCurrentPasswordType] =
    useState<string>("password");
  const [newPasswordType, setNewPasswordType] = useState<string>("password");
  const [confirmPasswordType, setConfirmPasswordType] =
    useState<string>("password");

  const {
    formState: { errors },
    watch,
    register,
    reset,
    handleSubmit,
  } = useForm<UserPassword>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const showHideCurrentPassword = (e) => {
    e.preventDefault();
    setCurrentPasswordType(
      currentPasswordType === "input" ? "password" : "input"
    );
  };

  const showHideNewPassword = (e) => {
    e.preventDefault();
    setNewPasswordType(newPasswordType === "input" ? "password" : "input");
  };

  const showHideConfirmPassword = (e) => {
    e.preventDefault();
    setConfirmPasswordType(
      confirmPasswordType === "input" ? "password" : "input"
    );
  };

  const onCloseClicked = () => {
    setIsChangePassword(false);
  };

  const editPassword = useMutation(updatePassword, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Password ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsChangePassword(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem changing password."),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (values: UserPassword) => {
    const data = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    editPassword.mutate(data);
  };

  return (
    <Modal
      show={isChangePassword}
      onHide={setIsChangePassword}
      centered
      size="lg"
      dialogClassName="modal-50w"
    >
      <Modal.Body>
        <div className="mx-4">
          <Modal.Body className="px-0 py-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div>
                  <h3 className="h3">Password criteria</h3>
                  <ul>
                    <li>At least 8 characters</li>
                    <li>At least 1 special character</li>
                    <li>At least 1 upper case letter</li>
                    <li>At least 1 lower case letter</li>
                    <li>At least 1 numeric character</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Current Password</label>
                      <div className="input-group">
                        <Form.Control
                          name="currentPassword"
                          type={currentPasswordType}
                          {...register("currentPassword", {
                            required: true,
                            pattern: {
                              value:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-\\]).{8,}$/,
                              message: "Wrong password security criteria",
                            },
                          })}
                          style={{
                            borderColor: !errors.currentPassword ? "" : "red",
                          }}
                        />
                        <button
                          type="button"
                          className="input-reveal-password border border-1 bg-white"
                          onClick={showHideCurrentPassword}
                        >
                          {currentPasswordType === "input" ? (
                            <img src={password_show} alt="password show" />
                          ) : (
                            <img src={password_hide} alt="password hide" />
                          )}
                        </button>
                      </div>
                      {errors["currentPassword"] && (
                        <span className="text-danger">
                          {errors.currentPassword.message ||
                            "This field is required"}
                        </span>
                      )}
                    </div>
                    <div className="form-group my-3">
                      <label>New Password</label>
                      <div className="input-group">
                        <Form.Control
                          name="newPassword"
                          type={newPasswordType}
                          {...register("newPassword", {
                            required: true,
                            pattern: {
                              value:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-\\]).{8,}$/,
                              message: "Wrong password security criteria",
                            },
                          })}
                        />
                        <button
                          type="button"
                          className="input-reveal-password border border-1 bg-white"
                          onClick={showHideNewPassword}
                        >
                          {newPasswordType === "input" ? (
                            <img src={password_show} alt="password show" />
                          ) : (
                            <img src={password_hide} alt="password hide" />
                          )}
                        </button>
                      </div>
                      {errors["newPassword"] && (
                        <span className="text-danger">
                          {errors.newPassword.message ||
                            "This field is required"}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Confirm New Password</label>
                      <div className="input-group">
                        <Form.Control
                          name="confirmNewPassword"
                          type={confirmPasswordType}
                          {...register("confirmNewPassword", {
                            required: true,
                            pattern: {
                              value:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-\\]).{8,}$/,
                              message: "Wrong password security criteria",
                            },
                            validate: (val: string) => {
                              if (watch("newPassword") != val) {
                                return "Your passwords do no match";
                              }
                            },
                          })}
                        />
                        <button
                          type="button"
                          className="input-reveal-password border border-1 bg-white"
                          onClick={showHideConfirmPassword}
                        >
                          {confirmPasswordType === "input" ? (
                            <img src={password_show} alt="password show" />
                          ) : (
                            <img src={password_hide} alt="password hide" />
                          )}
                        </button>
                      </div>
                      {errors["confirmNewPassword"] && (
                        <span className="text-danger">
                          {errors.confirmNewPassword.message ||
                            "This field is required"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="my-3 d-grid gap-2">
                    <button className="btn btn-primary m-0" type="submit">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary custom-outline m-0"
                      onClick={() => {
                        reset();
                        onCloseClicked();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
