export const statusEnum = {
    0: "All",
    1: "Open",
    2: "Fulfilled",
    3: "Partially Fulfilled",
    4: "Shipped",
    5: "Partially Shipped",
    6: "Voided",
    7: "Completed",
    8: "Estimate",
    9: "Estimate Replied"
}

export const statusEnum2 = {
    1: "Open",
    2: "Fulfilled",
    3: "Partially Fulfilled",
    4: "Shipped",
    5: "Partially Shipped",
}