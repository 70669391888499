import { useFormContext, Controller } from "react-hook-form";

import Select from "react-select";

import "../CustomerDetails.scss";

import {
  dateFormmater,
  formatInput,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { CustomerInfoDTO, PriceLevel } from "../Customers.models";
import { AccountTypeDTO } from "pages/SalesMarketing/AccountType/AccountType.models";
import { DropDownOption } from "components/Common.models";
import { useQuery } from "react-query";
import { getLeafs, getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import CreatableSelect from "react-select/creatable";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getCurrency } from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import { getBaseCurrency } from "components/currency";

type Props = {
  id: number;
  accTypes: AccountTypeDTO[];
  isDisabled: boolean;
  priceLevels: PriceLevel[];
  showTypeModal: Dispatch<SetStateAction<boolean>>;
};

const AccountInfo = (props: Props) => {
  const {
    formState: { errors },
    register,
    control,
    getValues,
    setValue,
  } = useFormContext<CustomerInfoDTO>();

  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);

  const accTypeItems: DropDownOption[] = props.accTypes?.map((item) => {
    return { label: item.name, value: item.accTypeId };
  });
  const priceLevelItems: DropDownOption[] = props.priceLevels?.map((item) => {
    return { label: item.title, value: item.priceLevelId };
  });

  const { data: leafs } = useQuery("leafs", () =>
    getLeafs({ ledgerType: LedgerTypeEnum.AccountsReceivable })
  );

  const { data: paymentAccounts } = useQuery("expenses", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Equity })
  );

  const { data: currencies } = useQuery("currencies", getCurrency, {
    select(data) {
      return data.map((currency) => {
        const detailedCurrency = getCurrencyByCode(currency.currencyCode);
        return {
          label: detailedCurrency.name,
          value: currency.currencyCode,
          isBaseCurrency: currency.isBaseCurrency,
        };
      });
    },
  });

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const creditLimit = getValues("creditLimit");
  const onSo: number = getValues("onSO");
  const balance: number = getValues("ledger")?.balance;
  const openCredits: number = getValues("openCredits");
  const createdDate: Date = getValues("createdDate");
  const createdBy: string = getValues("createdBy");

  const createLabel = () => <label>Add New</label>;

  const onCreateType = () => {
    props.showTypeModal(true);
  };

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("balancingAffectedAccountId", e.ledgerId, {
      shouldValidate: true,
    });
  };

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      const baseCurrency = currencies.find(
        (currency) => currency.isBaseCurrency
      );
      if (baseCurrency) {
        setSelectedCurrency(baseCurrency);
        setValue("primaryCurrencyCode", baseCurrency.value); // Programmatically update the form value
      }
    }
  }, [currencies, setValue]);

  useEffect(() => {}, [selectedCurrency]);

  return (
    <>
      <div className="row">
        <div className="left-side-mid ">
          <div className="row mb-4">
            <div className="left-side-mid">
              <div className="form-group mb-4">
                <label>
                  Customer No <span className="text-danger">*</span>
                </label>
                {props.id ? (
                  <>
                    <p>{getValues("accountNumber")}</p>
                  </>
                ) : (
                  <>
                    <input
                      {...register("accountNumber", {
                        required: {
                          value: true,
                          message: "Account No. is required",
                        },
                        maxLength: {
                          value: 10,
                          message: "Account No. must not exceed 10 characters",
                        },
                      })}
                      className={`form-control ${
                        errors.accountNumber && "required_field"
                      }`}
                      disabled={props.isDisabled}
                    />
                    {errors?.accountNumber && (
                      <p className="text-danger">
                        {errors?.accountNumber?.message}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="right-side-mid">
              <div className="mt-3">
                {!props.isDisabled ? (
                  <>
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, value } }) => (
                        <div className="form-group">
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="activeCheckbox"
                              name="isActive"
                              onChange={() => onChange(!value)}
                              checked={value}
                            />
                            <span className="slider round"></span>
                          </label>
                          {value ? (
                            <label className=" ps-3 ms-3 ">Active</label>
                          ) : (
                            <label className=" ps-3 ms-3"> Disabled</label>
                          )}
                        </div>
                      )}
                    />
                  </>
                ) : (
                  <div
                    className={`hub-info-status ${
                      getValues("isActive")
                        ? "active-status"
                        : "disabled-status"
                    }`}
                  >
                    {getValues("isActive") ? "Active" : "Disabled"}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="right-side-mid">
              <div className="custom-field">
                <label>
                  Customer Type <span className="text-danger">*</span>
                </label>
                <Controller
                  name="accountTypeId"
                  rules={{
                    required: {
                      value: true,
                      message: "Account type is required",
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CreatableSelect
                      name="accountTypeId"
                      isDisabled={props.isDisabled}
                      options={accTypeItems}
                      isClearable
                      isSearchable={true}
                      menuPlacement="auto"
                      value={
                        accTypeItems?.find((opt) => opt?.value === value) ||
                        null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      isValidNewOption={() => true}
                      formatCreateLabel={createLabel}
                      createOptionPosition="first"
                      onCreateOption={onCreateType}
                      className={`${errors.accountTypeId && "required_field"}`}
                    />
                  )}
                />
                {errors?.accountTypeId && (
                  <p className="text-danger">
                    {errors?.accountTypeId?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group mb-4">
                <label>
                  Selling Price Level <span className="text-danger">*</span>
                </label>
                <Controller
                  name="sellingPriceLevelId"
                  rules={{
                    required: {
                      value: true,
                      message: "Selling price level is required",
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="sellingPriceLevelId"
                      isDisabled={props.isDisabled}
                      options={priceLevelItems}
                      isClearable
                      isSearchable={true}
                      menuPlacement="auto"
                      value={
                        priceLevelItems?.find((opt) => opt?.value === value) ||
                        null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      className={`${
                        errors.sellingPriceLevelId && "required_field"
                      }`}
                    />
                  )}
                />
                {errors?.sellingPriceLevelId && (
                  <p className="text-danger">
                    {errors?.sellingPriceLevelId?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="right-side-mid mt-3 mb-2">
              <div className="custom-field">
                <label>
                  Account Category <span className="text-danger">*</span>
                </label>
                <Controller
                  name="ledger.accountingCategoryId"
                  rules={{
                    required: {
                      value: true,
                      message: "Account category is required",
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={props.isDisabled}
                      options={leafs?.map((item) => {
                        return { label: item.name, value: item.categoryId };
                      })}
                      isClearable
                      isSearchable={true}
                      menuPlacement="auto"
                      value={
                        leafs?.find((opt) => opt?.categoryId === value) || null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                      className={`${
                        errors?.ledger?.accountingCategoryId && "required_field"
                      }`}
                    />
                  )}
                />
                {errors?.ledger?.accountingCategoryId && (
                  <p className="text-danger">
                    {errors?.ledger?.accountingCategoryId?.message}
                  </p>
                )}
              </div>
            </div>
            {/* <div className="right-side-mid mt-3 mb-2">
              <div className="custom-field">
                <label>Primary Currency</label>{" "}
                <span className="text-danger"> * </span>
                <Controller
                  name="primaryCurrencyCode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 999999,
                        }),
                        menuList: (base) => ({
                          ...base,
                          maxHeight: "260px",
                        }),
                      }}
                      isDisabled={props.isDisabled}
                      options={currencies}
                      isClearable
                      isSearchable={true}
                      menuPlacement="auto"
                      value={
                        currencies?.find((opt) => opt?.value === value) ||
                        selectedCurrency
                      }
                      onChange={(val: DropDownOption) => {
                        setSelectedCurrency(val);
                        onChange(val?.value);
                      }}
                    />
                  )}
                />
              </div>
            </div> */}
          </div>
          {!props.id && (
            <div className="row">
              <div className="right-side-mid mt-5 mb-2">
                <div className="form-group">
                  <label>Starting balance</label>
                  <input
                    {...register("startingBalance")}
                    className={"form-control"}
                    onBlur={(e) =>
                      setValue("startingBalance", formatInput(e.target.value))
                    }
                  />
                </div>
              </div>
              <div className="right-side-mid mt-5 mb-2">
                <div className="form-group">
                  <label>Balancing affected account</label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionLedger(true)}
                    inputName="balancingAffectedAccountId"
                    initialData={paymentAccounts?.data?.map((item) => ({
                      ...item,
                      id: item.ledgerId,
                      name: item.accountName,
                    }))}
                    onSelection={onLedgerSelection}
                    inputValue={selectedLedger?.accountName}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="right-side-mid ps-5 pe-5">
          <div className="row">
            <div
              className={
                props.isDisabled
                  ? "col-6 border d-flex justify-content-between p-3 me-2 disabled-balance"
                  : "col-6 border d-flex justify-content-between p-3 me-2"
              }
            >
              <span className={balance <= 0 ? "text-danger" : "text-success"}>
                Balance
              </span>
              <span className={balance <= 0 ? "text-danger" : "text-success"}>
                {`${formatNumber(balance ?? 0, baseCurrency)}`}
              </span>
            </div>
            <div
              className={
                props.isDisabled
                  ? "col-5 border d-flex justify-content-between p-3 disabled-balance"
                  : "col-5 border d-flex justify-content-between p-3"
              }
            >
              <span className={onSo <= 0 ? "text-danger" : "text-success"}>
                On Order
              </span>
              <span className={onSo <= 0 ? "text-danger" : "text-success"}>
                {`${formatNumber(onSo ?? 0, baseCurrency)}`}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div
              className={
                props.isDisabled
                  ? "col-6 d-flex border justify-content-between p-3 me-2 disabled-balance"
                  : "col-6 d-flex border justify-content-between p-3 me-2"
              }
            >
              <span
                className={openCredits <= 0 ? "text-danger" : "text-success"}
              >
                Open Credits
              </span>
              <span
                className={openCredits <= 0 ? "text-danger" : "text-success"}
              >
                {`${formatNumber(openCredits ?? 0, baseCurrency)}`}
              </span>
            </div>
            <div
              className={
                props.isDisabled
                  ? "col-5 border d-flex justify-content-between p-3 disabled-balance"
                  : "col-5 border d-flex justify-content-between p-3"
              }
            >
              <span
                className={
                  Number(creditLimit) <= 0 ? "text-danger" : "text-success"
                }
              >
                Credit Limit
              </span>
              <span
                className={
                  Number(creditLimit) <= 0 ? "text-danger" : "text-success"
                }
              >
                {`${formatNumber(Number(creditLimit), baseCurrency)}`}
              </span>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <p>
                <label>Date Added</label>
              </p>
              <p className={props.isDisabled ? "text-muted" : ""}>
                {!props.id ? (
                  <span className="text-muted fst-italic">Date Added</span>
                ) : (
                  dateFormmater(createdDate)
                )}
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <p>
                <label>Added by</label>
              </p>
              <p className={props.isDisabled ? "text-muted" : ""}>
                {!props.id ? (
                  <span className="text-muted fst-italic">Added by</span>
                ) : createdBy ? (
                  createdBy
                ) : (
                  <span className="text-muted fst-italic">Added by</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
    </>
  );
};

export default AccountInfo;
