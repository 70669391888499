import { useEffect, useReducer, useRef, useState } from "react";

import SideMenu from "sections/SideMenu";

import Notification from "elements/Notification";
import { Spinner } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import WebsiteContent from "./components/WebsiteContent";
import DispatchContext from "./context/DispatchContext";
import StateContext from "./context/StateContext";
import { initialState, ourReducer } from "./context/context";
import { QueryClientContext } from "./reactquery";
import Header from "./sections/Header";

function App() {
  const [state, dispatch] = useReducer(ourReducer, initialState);
  const [loadingUrl, setLoadingUrl] = useState(false);

  const timer = useRef(0);
  useEffect(() => {
    if (state.notification) {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        dispatch({ type: "notification", value: null });
      }, 5000);
    }
  }, [state.notification]);

  useEffect(() => {
    if (state.loggedIn === true && localStorage.getItem("company")) {
      setLoadingUrl(true);
      setTimeout(() => {
        setLoadingUrl(false);
      }, 1);
    }
  }, [state.loggedIn]);

  return (
    <>
      {loadingUrl ? (
        "...loading"
      ) : (
        <BrowserRouter basename={localStorage.getItem("company")}>
          <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
              <QueryClientContext>
                {state.loggedIn && (
                  <div className="website-header">
                    <Header />
                  </div>
                )}
                {state.isLoading && (
                  <div className="loading-screen-container">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
                {state.notification && (
                  <Notification notification={state.notification} />
                )}
                <div className="website-content-wrapper">
                  {state.loggedIn && (
                    <div className="sidemenu">
                      <SideMenu />
                    </div>
                  )}
                  <div className="website-content">
                    <WebsiteContent />
                  </div>
                </div>
              </QueryClientContext>
            </DispatchContext.Provider>
          </StateContext.Provider>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
