import { useContext } from 'react'
import DispatchContext from 'context/DispatchContext';
import { useCSVReader } from 'react-papaparse';
import { ReactComponent as ExcelIcon } from 'img/excel-icon.svg'
import { Modal } from 'react-bootstrap';
import close from 'img/close.svg';
import { POItemsColumns } from "./CSVReaderInput.modal";
import { ReactComponent as AlertIcon } from 'img/alert.svg';
import './ImportModal.scss';
import AG from 'elements/AG';

type Props = {
  IsDisabled: boolean,
  onDataFetched: (data: any) => void,
  title: string,
  data: any,
  error: string,
  showModal: boolean,
  onClose: () => void,
  onOpen: () => void
  onSelection: () => void
};


const CSVReaderInput = (props: Props) => {

  const appDispatch = useContext(DispatchContext)
  const { CSVReader } = useCSVReader();

  const onCloseClicked = () => {
    props.onClose();
  }

  const onOpenClicked = () => {
    props.onOpen();
  }

  return (
    <CSVReader
      onUploadAccepted={props.onDataFetched}
      onUploadRejected={() => {
        let notification = {
          variant: "danger",
          msg: "Only csv files are accepted.",
        };
        appDispatch({ type: "notification", value: notification })
      }}
    >
      {({
        getRootProps,
      }: any) => (
        <>
          <Modal className={'import-modal'} show={props.showModal} onHide={() => props.onClose()} centered>
            <div className="d-flex justify-content-between align-items-center mx-4 my-3">
              <h5>{props.title}</h5>
              <img
                src={close}
                alt=''
                className='import-modal-close'
                onClick={() => onCloseClicked()}
              />
            </div>
            <div className="mx-4">
              <Modal.Body className='px-0 py-0'>
                <div className=" p-4 mb-1">
                  <ul>
                    <li>Column 1: Serial Number OR Serial of 15 digits</li>
                  </ul>
                  {props.error &&
                    <div className="d-flex align-items-center">
                      <AlertIcon className="me-3" />
                      <div className='text-danger'>
                        {props.error}
                      </div>
                    </div>
                  }
                </div>
                <button type='button' className='btn btn-primary mb-2 ms-0' {...getRootProps()} disabled={props.IsDisabled} >
                  Select File
                </button>
                <div className='h-450 mb-4'>
                  <AG data={props.data} columns={POItemsColumns} />
                </div>
                <div className='text-end'>
                  <button type='button' className='btn btn-success' onClick={props.onSelection} disabled={!props.data || props.data.length === 0 || (props.error && props.error !== "")} >
                    Confirm Selection
                  </button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
          <div>
            <button type='button' className='btn btn-success' onClick={() => onOpenClicked()} disabled={props.IsDisabled} >
              <ExcelIcon className='me-3' />
              Import Items
            </button>
          </div>
        </>
      )}
    </CSVReader>
  );
}

export default CSVReaderInput
