import dotenv from "dotenv";
import axios from "axios";

dotenv.config();

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    Pragma: "no-cache",
    "Cache-control": "no-cache",
  },
  timeout: 5000,
});

const loginUrl = "users/login";

// Login
export const checkLogin = async (data) => {
  const res = await api.post(loginUrl, {
    email: data.username,
    password: data.password,
  });
  return res;
};
export const checkUserIfExists = async (email) => {
  const res = await api.get(`users/user-exists/${email}`);
  return res;
};
export const getCompanyInfo = async () => {
  const res = await api.get("system-setup/company-info");
  return res.data;
};

export const sendCode = async (email) => {
  return await api.post(`users/forgot-password/${email}`);
};

export const submitChangeEmail = async (data) => {
  return await api.post(`users/reset-password`, data);
};
