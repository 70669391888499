import { useState, useEffect, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { IServerSideDatasource } from 'ag-grid-community';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import 'ag-grid-enterprise';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AG.scss";

type Props = {
  fetchMethod?: any,
  data?: any[],
  columns: any[],
  setrowSingleClicked?: any,
  setrowClicked?: any,
  setRowChecked?: any,
  autoColumns?: any,
  deSelectItems?: any,
  autoHeight?: boolean,
  onCellValueChanged?: any,
  selectItem?: any
  isLoading?: boolean,
  pagination?: boolean,
  filters?: any,
  refreshCounter?: number
} & typeof defaultProps;

const defaultProps = {
  rowSelection: "single",
};

const AG = (props: Props) => {

  const paginationPageSize = 10;

  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef<any>();

  useEffect(() => {
    if (gridApi && props.deSelectItems && props.deSelectItems > 0)
      gridApi.deselectAll();
  }, [props.deSelectItems, gridApi])

  useEffect(() => {
    if (gridApi && props.selectItem)
      setTimeout(() => {
        gridApi.forEachNode(function (node) {
          node.setSelected(node.data.purchaseOrderItemId === props.selectItem.purchaseOrderItemId);
        });
      });
  }, [props.selectItem, gridApi])

  useEffect(() => {
    gridApi?.refreshServerSideStore({ purge: true })
  }, [props.filters])

  useEffect(() => {
    if (gridApi && props.refreshCounter > 0) {
      gridApi?.refreshServerSideStore({ purge: true })
    }
  }, [gridApi, props.refreshCounter])

  const [gridParams, setGridParams] = useState<any>(null)

  useEffect(() => {
    if (gridParams) {
      const dataSource: IServerSideDatasource = {
        getRows(params: IServerSideGetRowsParams): void {
          const { startRow } = params.request;
          let PageStart = Number(startRow);
          props.fetchMethod.mutate(
            {
              ...props.filters,
              PageSize: paginationPageSize,
              PageStart: PageStart,
            },
            {
              onSuccess: (data) => {
                params.success({
                  rowData: data.data,
                  rowCount: data.count,
                });
              },
            },
          );
        },
      };
      gridParams.api.setServerSideDatasource(dataSource);
    }
  }, [gridParams, props.filters])

  const onGridReady = useCallback((params: GridReadyEvent) => {
    if (props.fetchMethod) {
      setGridParams(params)
    }
    setGridApi(params.api);
  }, []);

  useEffect(() => {
    if (props.data && props.data.length === 0 && !props.isLoading)
      gridRef.current.api.showNoRowsOverlay();
  }, [props.data, props.isLoading])

  useEffect(() => {
    if (props.isLoading)
      gridRef.current.api.showLoadingOverlay();
  }, [props.isLoading])

  const onGridSizeChanged = (params: any) => {
    if (!props.autoColumns)
      gridRef.current.api.sizeColumnsToFit();
    else {
      params.columnApi.autoSizeAllColumns();
    }
  }

  const onSelectionChanged = () => {
    let selectedRows = gridApi?.getSelectedRows();
    (props.setrowClicked !== undefined && props.setrowClicked !== null && selectedRows) && props.setrowClicked(selectedRows[0]);
  };

  const OnRowClicked = () => {
    let selectedRows = gridApi?.getSelectedRows();
    (typeof props.setrowSingleClicked !== "undefined" && typeof props.setrowSingleClicked !== null && selectedRows) && props.setrowSingleClicked(selectedRows[0]);
  }

  const onCheckboxSelectionChange = (event) => {
    if (props.setRowChecked)
      props.setRowChecked(event.api.getSelectedRows());
  }

  const onFirstDataRendered = useCallback((params) => {
    if (!props.autoColumns)
    gridRef.current.api.sizeColumnsToFit();
  else {
    params.columnApi.autoSizeAllColumns();
  }  }, []);

  return (
    <div className="ag-grid-container ag-theme-alpine">
      <AgGridReact
        defaultColDef={{
          editable: false,
          filter: "agTextColumnFilter",
          floatingFilter: false,
          resizable: true,
          sortable: true,
          suppressMenu: true
        }}
        overlayLoadingTemplate={
          '<span class="ag-overlay-loading-center">Loading...</span>'
        }
        domLayout={props.autoHeight ? 'autoHeight' : 'normal'}
        pagination={props.pagination ?? false}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        maxBlocksInCache={paginationPageSize}
        onGridReady={onGridReady}
        serverSideStoreType={'partial'}
        rowModelType={props.fetchMethod ? 'serverSide' : 'clientSide'}
        columnDefs={props.columns}
        rowHeight={40}
        suppressDragLeaveHidesColumns={true}
        rowSelection={props.rowSelection}
        onGridSizeChanged={onGridSizeChanged}
        onCellDoubleClicked={onSelectionChanged}
        onSelectionChanged={onCheckboxSelectionChange}
        rowData={props.data}
        onRowClicked={OnRowClicked}
        ref={gridRef}
        onFirstDataRendered={onFirstDataRendered}
        onCellValueChanged={props.onCellValueChanged}
        stopEditingWhenCellsLoseFocus={true}
        enableCellTextSelection={true}
        ensureDomOrder={true}
      ></AgGridReact>
    </div>
  );
};
AG.defaultProps = defaultProps;

export default AG;
