import { useContext, useEffect, useState } from "react";

import addIcon from "img/add-icon.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import { useQuery, useQueryClient } from "react-query";
import { getUserRoles } from "../UserManagementServices/UserManagementServices";

import * as AppUrls from "constants/AppUrls";

import { Link, useHistory } from "react-router-dom";

import { checkIfAllowed } from "global/helpers";
import StateContext from "context/StateContext";

import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import * as colDefs from "../../../constants/colDefs";

const UserRoleSetup = ({ match }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const { data: userRoles } = useQuery(["user_roles", filter], getUserRoles);
  const [dataGrid, setDataGrid] = useState(null);
  const columns = [
    {
      field: "roleKey",
      headerName: "Role Id",
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "membersCount",
      headerName: "members",
      maxWidth: colDefs.sm_mWidth,
    },
    {
      field: "isActive",
      headerName: "Status",
      maxWidth: colDefs.booleanWidth,

      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
      <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
      </div>
      `;
      },
    },
    {
      field: "createdDate",
      headerName: "Date Added",
      maxWidth: colDefs.mWidth,

      valueGetter: (params) => {
        return new Date(params.data.createdDate).toLocaleDateString();
      },
      cellRendererFramework: (params) =>
        new Date(params.value).toLocaleDateString(),
    },
  ];

  const onRowClick = (e) => {
    let url = `${AppUrls.users_roles}/${e.roleId}`;
    history.push(url);
  };

  const onRefresh = () => {
    queryClient.invalidateQueries(["user_roles"]);
    let notification = {
      variant: "success",
      msg: "Table is refreshing...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const [exportToExcelClicked, setExportToExcelClicked] = useState(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState(false);
  const [isLoadingGridExport, setIsLoadingGridExport] = useState(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>User Roles</p>
        <div>
          {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn}>
              <img src={exportIcon} className="me-2" alt="" /> 
              Export to Excel
          </button> */}
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_user_role} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New User Role
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            data={userRoles?.data}
            setDataGrid={setDataGrid}
            filters={filter}
            setrowClicked={onRowClick}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
          />
        </div>
      </div>
    </div>
  );
};
export default UserRoleSetup;
