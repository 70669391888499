import Close from "img/close.svg";
import { Modal } from "react-bootstrap";
import AG from "elements/AG";
import { useEffect, useState } from "react";

type FilterType = {
  params: string;
};

type Props = {
  modal: any;
  showModal: boolean;
  setShowModal: any;
  data: any;
  columns: any;
  setRowClicked: any;
  withPagination?: boolean;
  searchProp?: boolean;
  setFilter?: (filter: FilterType) => void;
  searchTerm?: string;
  setSearchTerm?: any;
  filter?: any;
  fetchMethod?: any;
};

const SelectionModal = ({
  modal,
  showModal,
  setShowModal,
  data,
  columns,
  setRowClicked,
  withPagination = true,
  searchProp,
  setFilter,
  searchTerm,
  setSearchTerm,
  filter,
  fetchMethod,
}: Props) => {
  const displayFilter = () => {
    if (typeof setFilter === "function") {
      const params = searchTerm ? `searchQuery=${searchTerm}` : "";
      setFilter({
        params: params,
      });
    }
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      centered
      size="lg"
      dialogClassName="modal-80w"
    >
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title>Double click on {modal} to select it</Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className="ms-auto"
          role="button"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="px-0">
        {searchProp && (
          <div
            className="filters"
            style={{ marginInline: "10px", marginBottom: "10px" }}
          >
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="h-450">
          <AG
            data={data}
            pagination={false}
            columns={columns}
            setrowClicked={setRowClicked}
            filters={filter}
            fetchMethod={fetchMethod}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex ms-auto border-top-0">
        <button
          type="button"
          className="btn btn-outline-primary custom-outline"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectionModal;
