import { useContext, useEffect, useRef, useState } from "react";

import backArrow from "img/back-arrow.svg";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { SalesProfitReportAPI } from "./services/ReportServices";
import "./style/common.scss";

import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
} from "global/helpers";
import { useReactToPrint } from "react-to-print";

import exportIcon from "img/export.svg";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "./components/Filter";

export default function SalesProfitReport() {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const [reportName, setReportName] = useState("Sales Profit report");

  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(SalesProfitReportAPI, {
    async onSuccess(data) {
      let currentData = { ...data };
      setReportData(currentData);
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues } = methods;

  const onSubmit = () => {
    const values = getValues();
    let params = {
      startDate: null,
      endDate: null,
      accountId: null,
      hubId: null,
      itemId: null,
      itemClassId: null,
      subClassId: null,
      productLineId: null,
      supplierId: null,
      categoryId: null,
      manufacturerId: null,
      isSerialized: null,
      isRetail: null,
    };
    if (values.startDate)
      params.startDate = fixTimezoneOffset(values.startDate);
    if (values.endDate) params.endDate = fixTimezoneOffset(values.endDate);
    if (values.CustomerId) params.accountId = values.CustomerId?.value;
    if (values.HubId) params.hubId = values.HubId?.values;
    if (values.ClassId) params.itemClassId = values.ClassId?.value;
    if (values.SubClassId) params.subClassId = values.SubClassId?.value;
    if (values.ProductLineId)
      params.productLineId = values.ProductLineId?.value;
    if (values.SupplierId) params.supplierId = values.SupplierId?.value;
    if (values.CategoryId) params.categoryId = values.CategoryId?.value;
    if (values.ManufacturerId)
      params.manufacturerId = values.ManufacturerId?.value;
    if (values.IsSerialized) params.isSerialized = values.IsSerialized?.value;
    if (values.IsRetail) params.isRetail = values.IsRetail?.value;
    if (values.ItemId) params.itemId = values.ItemId?.value;

    generateReport.mutate(params);
  };

  const columns = [
    [
      {
        field: "itemNumber",
        headerName: "Item No",
      },
      {
        field: "serialNo",
        headerName: "Serial",
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice No",
      },
      {
        field: "quantity",
        headerName: "QTY",
      },
      {
        field: "price",
        headerName: "Price",
        valueGetter: (params) => {
          return "$" + params.data.price?.toFixed(2);
        },
      },
      {
        field: "cost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return "$" + params.data.cost?.toFixed(2);
        },
      },
      {
        field: "subTotal",
        headerName: "Profit/Loss",
        valueGetter: (params) => {
          return "$" + params.data.profitAndLoss.toFixed(2);
        },
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    columns.map((column) => {
      return column.map((c) => ({ ...c }));
    })
  );

  const onBackToReportsClick = () => {
    history.push("/reports");
  };
  const [forPrinting, setForPrinting] = useState(false);
  const reportPage = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    setForPrinting(true);
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setForPrinting(false);
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };
  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <Filter
                filters={[1, 2, 4, 8, 9, 10, 11, 12, 13, 14, 15, 16]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                initialDate={!isCustomizedReport}
              />
              <>
                <div className="container report-filters-div text-end mb-3">
                  <button
                    className="import-btn"
                    type="button"
                    onClick={exportToExcel}
                    disabled={
                      disabledExportBtn || reportData?.items?.length === 0
                    }
                  >
                    <img src={exportIcon} className="me-2" alt="" />
                    Export grid to Excel
                  </button>
                  <button
                    type="button"
                    className="btn btn-success ms-5"
                    onClick={printDocument}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    Print report to PDF
                  </button>
                </div>
                <div className="report-page" ref={reportPage}>
                  <div className="report-header d-flex justify-content-between">
                    <div>
                      <h3>
                        <b>{reportName}</b>
                      </h3>{" "}
                      <br />
                      <p>
                        <b>Report Date: </b>
                        {dateFormmaterNoTime(reportData?.reportDate)}
                      </p>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={reportData?.imageLogo ?? BlueSeedLogo}
                        className="report-logo"
                      ></img>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    <AgGrid
                      data={reportData?.items}
                      columns={gridColumns[0]}
                      pagination={false}
                      forPrinting={true}
                      autoHight={true}
                      masterDetail={true}
                      exportToExcel={exportToExcelClicked}
                      setExportToExcelClicked={setExportToExcelClicked}
                      setDisabledExportBtn={setDisabledExportBtn}
                      setIsLoadingGridExport={setIsLoadingGridExport}
                      fileName="Inventory Issue Report"
                      ref={childFnRef}
                    />
                  </div>
                  <div className="mt-3 pb-3">
                    <div className="d-flex justify-content-between">
                      <div className="label">Total</div>
                      <div className="value">
                        Profit & Loss: ${reportData?.profitAndLoss.toFixed(2)}
                      </div>
                      <div className="value">
                        Sub-Total: ${reportData?.subtotal.toFixed(2)}
                      </div>
                      <div className="value">
                        Cost: ${reportData?.totalCost?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
