import { ErrorResponse } from "components/data/Errors.model";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ItemManufacturer } from "./InventorySetup.model";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { FormProvider, useForm } from "react-hook-form";
import {
  createManufacturer,
  deleteManufacturer,
  editManufacturer,
  getManufacturers,
  uploadImage,
} from "./InventorySetup.services";

import ImageUploadComponent from "components/ImageUpload/ImageUploadComponent";
import "../Inventory/Inventory.scss";

const ItemManufacturers = ({ match }) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();

  const { mutate: dataMutation, error: errorLoadingData } = useMutation<
    any,
    ErrorResponse
  >("dataManufacturers", getManufacturers);

  const { id: idManufacturer } = useParams();
  useEffect(() => {
    if (idManufacturer !== "new") {
      dataMutation(null, {
        onSuccess(result) {
          let idNum = Number(idManufacturer);
          let data = result?.data?.find(
            (item) => item?.manufacturerId === idNum
          );
          setSelectedId(idNum);
          setValue("name", data.name);
          setValue("description", data.description);
          setInitialImg(data?.imageUrl);
        },
      });
    }
  }, [idManufacturer]);

  const formMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    register,
  } = formMethods;

  const [active, setActive] = useState<boolean>(false);

  const [manufImage, setManufImage] = useState(null);
  const [initialImg, setInitialImg] = useState(null);

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const imageUpload = useMutation(uploadImage);

  const addManufacturer = useMutation(createManufacturer, {
    async onSuccess(res) {
      queryClient.invalidateQueries("get_manufacturers");
      let notification = {
        variant: "success",
        msg: `Manufacturer ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      if (manufImage) {
        imageUpload.mutate({
          manufacturerId: res.manufacturerId,
          file: manufImage,
        });
      }
      history.push(AppUrls.item_manufacturer);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding manufacturer"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateManufacturer = useMutation(editManufacturer, {
    async onSuccess() {
      queryClient.invalidateQueries("get_manufacturers");
      if (manufImage) {
        imageUpload.mutate({ manufacturerId: selectedId, file: manufImage });
      }
      setActive(false);
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_manufacturer);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing manufacturer"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeItem = useMutation(deleteManufacturer, {
    async onSuccess() {
      queryClient.invalidateQueries("get_manufacturers");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing item manufacturer"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();

  const onSubmit = (values) => {
    const data: ItemManufacturer = {
      manufacturerId: selectedId ? selectedId : 0,
      name: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addManufacturer.mutate(data);
    } else {
      updateManufacturer.mutate(data);
    }
  };

  const resetForm = () => {
    reset();
    setActive(false);
    setInitialImg(null);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        addManufacturer.isLoading ||
        updateManufacturer.isLoading ||
        removeItem.isLoading,
    });
  }, [
    addManufacturer.isLoading,
    updateManufacturer.isLoading,
    removeItem.isLoading,
    appDispatch,
  ]);

  return (
    <>
      <div className="static-page">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="  page-title page-title-editable">
              <div className="d-flex flex-row">
                <div className="back-btn">
                  <img
                    src={backArrow}
                    alt="back arrow"
                    onClick={() => history.push(AppUrls.item_manufacturer)}
                  />
                </div>
                <div>
                  <p className="">Item Manufacturer</p>
                </div>
              </div>
              <div className="d-flex pb-4">
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline custom-outline"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  {!selectedId ? "Add Item Manufacturer" : "Save Changes"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="row h-100">
                {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                  checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                  <div className="">
                    <div className="">
                      <div className="row mb-2">
                        <div className="col-6">
                          <label>Manufacturer logo</label>
                          {selectedId ? (
                            <>
                              <ImageUploadComponent
                                active={active}
                                initialImg={initialImg}
                                setCustomIsDirty={() => {}}
                                setImageData={setManufImage}
                              />
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-sm mt-1 ms-0"
                                onClick={() => setActive(false)}
                                disabled={!active}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm mt-1 ms-2"
                                onClick={() => setActive(true)}
                                disabled={!selectedId}
                              >
                                Clear{" "}
                              </button>
                            </>
                          ) : (
                            <ImageUploadComponent
                              active={true}
                              initialImg={initialImg}
                              setCustomIsDirty={() => {}}
                              setImageData={setManufImage}
                            />
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Manufacturer Name{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("name", {
                            required: true,
                            maxLength: 15,
                          })}
                          type="text"
                          className={`form-control ${
                            errors["name"] && "required_field"
                          }`}
                        />
                        {errors.name && errors.name.type === "maxLength" && (
                          <p className="text-danger">
                            Max length for name value must not exceed 15
                            characters.
                          </p>
                        )}
                        {errors.name && errors.name.type === "required" && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("description", { required: true })}
                          className={
                            errors["description"]
                              ? "form-control required_field"
                              : "form-control"
                          }
                        />
                        {errors.name &&
                          errors.description.type === "required" && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>{" "}
          </form>
        </FormProvider>
      </div>
      {/* <ConfirmationModal
        title="Delete Manufacturer"
        message="Are you sure you want to delete the item?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleDeleteItem(selectedId)}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      /> */}
    </>
  );
};

export default ItemManufacturers;
