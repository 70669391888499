import { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "react-query";
import addIcon from "img/add-icon.svg";
import * as AppUrls from "constants/AppUrls";
import { checkIfAllowed } from "global/helpers";
import StateContext from "context/StateContext";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { GridColumn } from "elements/AG.models";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";

import DispatchContext from "context/DispatchContext";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";

import * as colDefs from "../../../constants/colDefs";

const InventoryHubs = (props) => {
  const appDispatch = useContext(DispatchContext);

  const { match } = props;
  let history = useHistory();
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataMutation = useMutation("data", getInventoryHubsList);
  const [filter, setFilter] = useState({
    params: "",
  });
  const onRowDoublClicked = (e) => {
    const id = e.locationId;
    history.push(`${AppUrls.inventories_list}/${id}`);
  };

  const columns: GridColumn[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
      minWidth: colDefs.textWidth,
    },
    {
      field: "workNumber",
      headerName: "Work No.",

      editable: false,
      valueGetter: (params) => {
        return params.data.workNumber
          ? params.data.workNumber
              .toString()
              .replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, "$1 $2 $3")
          : "";
      },
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
      valueGetter: (params) => {
        return params.data.faxNumber
          ? params.data.faxNumber
              .toString()
              .replace(/(\d{3})\D?(\d{4})\D?(\d{4})/, "$1 $2 $3")
          : "";
      },
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default",
      editable: false,
      maxWidth: colDefs.booleanWidth,

      valueGetter: (params) => {
        return params.data.isDefault ? "Yes" : "No";
      },
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "isActive",
      headerName: "Status",
      maxWidth: colDefs.booleanWidth,
      editable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Inventory Hubs</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.create_new_inventory} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Hub
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            fetchMethod={dataMutation}
            data={dataGrid}
            filters={filter}
            setDataGrid={setDataGrid}
            setrowClicked={onRowDoublClicked}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            refreshCounter={refreshCounter}
            fileName="Inventory Hubs List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryHubs;
