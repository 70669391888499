import { Controller, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import { AgGridReact } from 'ag-grid-react';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getLedgers } from './AccountingServices';
import { formatNumber } from 'global/helpers';
import LedgerSelection from './LedgerSelection';
import SelectionModal from 'components/SelectionModal/SelectionModal';
import Delete from "img/delete.svg";


const TransactionOperation = ({ gridRef }) => {

    const rowRef = useRef<number>();
    const [showAccountModal, setShowAccountModal] = useState<boolean>(false);
    const [, setRefresh] = useState<number>(0);

    const { data: ledgers } = useQuery("ledgers", getLedgers);

    const columns = [
        {
            field: "journalNumber",
            headerName: "Journal #",
            width: 120
        },
        {
            field: "accountNumber",
            headerName: "Account #",
            cellRendererFramework: (params) => {
                return (
                    <LedgerSelection
                        initialData={ledgers?.data.filter(account => account.accountNumber !== null).map(ledger => ({ ...ledger, id: ledger.ledgerId, name: `${ledger.accountNumber}-${ledger.accountName}` }))}
                        inputValue={params.value}
                        onSelection={(data) => onLedgerSelection(data, params?.rowIndex)}
                        onButtonClicked={() => { setShowAccountModal(true); rowRef.current = params.rowIndex }}
                    />
                )
            },
            cellClass: 'overflow-visible',
            width: 250
        },
        {
            field: "accountName",
            headerName: "Account Name",
            cellRenderer: (params) => params.value?.accountName,
            width: 200
        },
        {
            field: "description",
            headerName: "Description",
            editable: true,
            flex: 1
        },
        {
            field: "isDebit",
            headerName: "Debit",
            cellRenderer: function (params) {
                var input = document.createElement("input");
                input.type = "checkbox";
                input.checked = params.value;
                input.addEventListener("click", function () {
                    params.value = !params.value;
                    params.node.data.isDebit = params.value;
                    setRefresh(prev => prev + 1)
                });
                return input;
            },
            width: 100
        },
        {
            field: "amount",
            headerName: "Amount",
            editable: true,
            width: 120,
            cellRenderer: (params) => isNaN(params.value) ? null : formatNumber(params.value),
            onCellValueChanged: () => setRefresh(prev => prev + 1)
        },

        {
            field: "",
            headerName: "Action",
            width: 150,
            cellRendererFramework: () => {
                return (
                    <button type="button" className="btn text-danger">
                        <img src={Delete} alt="Delete Icon" />
                        Delete
                    </button>
                );
            },
            onCellClicked: (params) => {
                gridRef?.current?.api.applyTransaction({
                    remove: [params.data],
                });
            },

        }
    ];

    const ledgersColumns = [
        {
            field: "accountNumber",
            headerName: "Account",
            resizable: true
        },
        {
            field: "accountName",
            headerName: "Account name",
            resizable: true
        },
        {
            field: "balance",
            headerName: "Balance",
            resizable: true
        },
        {
            field: "isDebit",
            headerName: "Debit",
            resizable: true,
            cellRenderer: (params) => params.value ? 'Yes' : 'No',
        },
        {
            field: "category",
            headerName: "Category",
            resizable: true
        },
        {
            field: "subCategory",
            headerName: "Sub Category",
            resizable: true
        }
    ];


    const { control, formState: { errors } } = useFormContext();


    const addRow = () => {
        const newItems = [
            {
                journalNumber: getRowData().length + 1,
                accountNumber: null,
                accountName: null,
                description: null,
                isDebit: false,
                amount: null,
                costCenter: null,
            }
        ];
        gridRef?.current?.api.applyTransaction({
            add: newItems,
        });
    }

    const getRowData = () => {
        const rowData = [];
        gridRef?.current?.api.forEachNode(function (node) {
            rowData.push(node.data);
        });
        return rowData;
    }

    const onLedgerSelection = (nodeData, rowIndex) => {
        setShowAccountModal(false)
        const itemsToUpdate = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort(function (
            rowNode,
            index
        ) {
            if (index != rowIndex) {
                return;
            }
            const data = rowNode.data;
            data.accountName = nodeData;
            data.accountNumber = nodeData.accountNumber;
            itemsToUpdate.push(data);
        });
        gridRef.current.api.applyTransaction({ update: itemsToUpdate });
    }

    const getTotal = () => {
        return getRowData().reduce(
            (previousValue, currentValue) => previousValue + (currentValue.isDebit ? 0 : Number(currentValue.amount)),
            0
        );
    }

    return (
        <>
            <div className='row gy-4'>
                <div className='col-sm-3'>
                    <div className="form-group">
                        <label>Date</label>
                        <Controller
                            control={control}
                            name="operation.operationDate"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    onChange={onChange}
                                    selected={value ? value : null}
                                />
                            )}
                        />
                    </div>
                    {errors.operation?.operationDate && (
                        <p className="text-danger">This field is required</p>
                    )}
                </div>
                <div className='col-sm-3'>
                    <div className='form-group'>
                        <label>Order Type</label>
                        <div>Manual</div>
                    </div>
                </div>
            </div>
            <hr className='mt-3 mb-5' />
            <div className='row'>
                <div className='col-sm-12 border p-4'>
                    <div className="ag-theme-alpine h-350 operations-grid">
                        <AgGridReact
                            ref={gridRef}
                            rowData={[]}
                            columnDefs={columns}
                            animateRows={true}
                            stopEditingWhenCellsLoseFocus={true}
                        ></AgGridReact>
                    </div>
                    <div className='my-3 text-end'>
                        <b>Total: {formatNumber(getTotal())}</b>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                        <button onClick={addRow} type="button" className="btn btn-outline-primary">Add new</button>
                    </div>
                </div>
            </div>
            <SelectionModal
                modal="account"
                withPagination={false}
                showModal={showAccountModal}
                setShowModal={setShowAccountModal}
                data={ledgers?.data.filter(account => account.accountNumber !== null)}
                columns={ledgersColumns}
                setRowClicked={(data) => onLedgerSelection(data, rowRef.current)}
            />
        </>
    );
};

export default TransactionOperation;