import { useContext, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";

import {
  approveRma,
  creditRma,
  getRmaDetails,
  getRmaList,
  refundRma,
  rejectCustomerRma,
} from "./RmaServices/RmaServices";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { FormProvider, useForm } from "react-hook-form";
import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import DepositModal from "./DepositModal";
import { getBaseCurrency } from "components/currency";

enum RMAStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Voided = 4,
  Closed = 5,
  Received = 6,
  Credited = 7,
  Refunded = 8,
  "Partially Received" = 9,
}

const ApproveCustomerRma = ({ match }) => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRma, setSelectedRma] = useState(null);
  const appDispatch = useContext(DispatchContext);
  const rmaDetails = useRef(null);
  const history = useHistory();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const isApprove: boolean = match.path === AppUrls.approve_customer_rma;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data: rmaList, refetch } = useQuery(["customer-rma", filter], () =>
    getRmaList({ status: isApprove ? 1 : 6, params: filter.params ?? "" })
  );

  const { mutate } = useMutation(getRmaDetails, {
    onSuccess(data) {
      rmaDetails.current = data;
    },
  });

  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const approveMutation = useMutation(approveRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA approved successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      rmaDetails.current = null;
      setSelectedRma(null);
      refetch();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem appproving rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const rejectMutation = useMutation(rejectCustomerRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA rejected successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      rmaDetails.current = null;
      setSelectedRma(null);
      refetch();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem rejecting rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const refundMutation = useMutation(refundRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA refunded successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem refunding rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const creditMutation = useMutation(creditRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA credited successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem crediting rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item Number",
      minWidth: 140,
    },
    {
      field: "description",
      headerName: "Item Description",
      minWidth: 160,
    },
    {
      field: "quantity",
      headerName: "RMA QTY",
      minWidth: 130,
    },
    {
      field: "price",
      headerName: "Price",
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.price?.toFixed(2) ?? "0.00"}`;
      },
      minWidth: 100,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      cellRendererFramework: (params) =>
        formatNumber(params.data.quantity * params.data.price, currencySymbol),
      minWidth: 120,
    },
  ];

  const rmaColumns = [
    {
      field: "rmaNumber",
      headerName: "RMA No.",
    },
    {
      field: "customerNumber",
      headerName: "Customer No.",
    },
    {
      field: "orderNumber",
      headerName: "So No.",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => RMAStatus[params.value],
    },
    {
      field: "rmaDate",
      headerName: "RMA Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "RMA Amount",
      cellRenderer: (params) => formatNumber(params.value, currencySymbol),
    },
  ];

  const onRmaSelected = (rma) => {
    setSelectedRma(rma);
    mutate({ queryKey: [rma.rmaId] });
    setOpenSelection(false);
  };

  const onApprove = () => {
    approveMutation.mutate(selectedRma.rmaId);
  };

  const onReject = () => {
    rejectMutation.mutate(selectedRma.rmaId);
  };

  const onRefund = () => {
    setShowModal(true);
  };

  const onRefundConfirm = (id: number) => {
    setShowModal(false);
    refundMutation.mutate({ rmaId: selectedRma.rmaId, refundFrom: id });
  };

  const onCredit = () => {
    creditMutation.mutate(selectedRma.rmaId);
  };

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <div>
            {isApprove ? "Approve Customer RMA" : "Refund/Credit Vendor RMA"}
          </div>
          {isApprove ? (
            <div>
              <button
                className="btn btn-danger"
                onClick={onReject}
                disabled={!selectedRma}
              >
                Reject
              </button>
              <button
                className="btn btn-success"
                onClick={onApprove}
                disabled={!selectedRma}
              >
                Approve
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-primary"
                onClick={onRefund}
                disabled={!selectedRma}
              >
                Refund
              </button>
              <button
                className="btn btn-primary"
                onClick={onCredit}
                disabled={!selectedRma}
              >
                Credit
              </button>
            </div>
          )}
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row  gx-5">
              <div className="col-lg-8 col-sm-12">
                <div className="row gx-5 gy-4">
                  <div className="col-lg-6 col-sm-12">
                    <label>
                      RMA No. <span className="text-danger">*</span>
                    </label>
                    <FormProvider {...methods}>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="vendorRmaId"
                        initialData={rmaList?.data?.map((item) => ({
                          ...item,
                          id: item.rmaId,
                          name: item.rmaNumber,
                        }))}
                        onSelection={onRmaSelected}
                        inputValue={selectedRma?.rmaNumber}
                      />
                    </FormProvider>
                  </div>
                  <div className="row gx-5 gy-4">
                    <div className="col-lg-3 col-sm-12">
                      <label>Customer No.</label>
                      <div>{rmaDetails?.current?.accountNumber}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Company</label>
                      <div>{rmaDetails?.current?.shippingInfo?.company}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Origin</label>
                      <div>
                        {rmaDetails?.current?.origin === 1 ? "Sytem" : "Web"}
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Status</label>
                      <div>{RMAStatus[rmaDetails?.current?.status]}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="row mt-4">
                  <div className="col-lg-6 col-sm-12 px-4">
                    <div className="d-flex">
                      <div className="text-secondary">TOTAL QTY</div>
                      <div className="flex-grow-1 text-center">
                        {rmaDetails?.current?.totalQuantity}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="d-flex">
                      <div className="text-secondary">TOTAL ITEMS</div>
                      <div className="flex-grow-1 text-center">
                        {rmaDetails?.current?.totalItems}
                      </div>
                    </div>
                  </div>
                  <div className="px-4">
                    <hr />
                  </div>
                  <div className="col-sm-12">
                    <div className="section mt-3 p-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="fs-5">ORDER TOTAL</div>
                        <div className="text-success fs-4">
                          {rmaDetails.current ? (
                            formatNumber(
                              rmaDetails?.current?.subtotal,
                              currencySymbol
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-450 mt-5">
              <AgGrid
                data={rmaDetails.current?.items ?? []}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
      <SelectionModal
        modal="RMA"
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={rmaList?.data}
        columns={rmaColumns}
        setRowClicked={onRmaSelected}
        searchProp={true}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
      />
      <DepositModal
        showModal={showModal}
        onExit={() => setShowModal(false)}
        onCreation={onRefundConfirm}
      />
    </>
  );
};

export default ApproveCustomerRma;
