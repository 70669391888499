import { useState, useContext, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { ItemCategory } from "./InventorySetup.model";
import { ErrorResponse } from "components/data/Errors.model";
import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import {
  getCategories,
  createCategory,
  editCategory,
  deleteCategory,
} from "./InventorySetup.services";
import { FormProvider, useForm } from "react-hook-form";
import SectionTitle from "elements/SectionTitle";
import BaseInvSetupItem from "./BaseInvSetupItem";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { useLocation } from "react-router-dom";

import { QueryResult } from "components/Common.models";

const NewItemCategory = ({ match }) => {
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { id, name, description } = location.state;
      setSelectedId(id);
      setValue("name", name);
      setValue("description", description);
    }
  }, [location.state]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const { error: errorLoadingData } = useQuery<
    QueryResult<ItemCategory>,
    ErrorResponse
  >("get_categories", getCategories);

  const frmMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const { handleSubmit, reset, setValue } = frmMethods;

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const addCategory = useMutation(createCategory, {
    async onSuccess() {
      queryClient.invalidateQueries("get_categories");
      let notification = {
        variant: "success",
        msg: `Item Category ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item Category"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateCategory = useMutation(editCategory, {
    async onSuccess() {
      queryClient.invalidateQueries("get_categories");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSelectedId(null);
      reset();
      history.push(AppUrls.item_category);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing item Category"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeItem = useMutation(deleteCategory, {
    async onSuccess() {
      queryClient.invalidateQueries("get_categories");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_category);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing item Category"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const handleDeleteItem = (itemId) => {
    removeItem.mutate(itemId);
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const history = useHistory();

  const onSubmit = (values) => {
    const categoryData: ItemCategory = {
      itemCategoryId: selectedId ? selectedId : 0,
      category: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addCategory.mutate(categoryData);
    } else {
      updateCategory.mutate(categoryData);
    }
  };

  const resetForm = () => {
    setSelectedId(null);
    reset();
    history.push(AppUrls.item_category);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        addCategory.isLoading ||
        updateCategory.isLoading ||
        removeItem.isLoading,
    });
  }, [
    addCategory.isLoading,
    updateCategory.isLoading,
    removeItem.isLoading,
    appDispatch,
  ]);

  return (
    <>
      <div className="static-page">
        <div className="  page-title page-title-editable">
          <div className="d-flex flex-row">
            <div className="back-btn mt-2 ">
              <img
                src={backArrow}
                alt="back arrow"
                onClick={() => history.push(AppUrls.item_category)}
              />
            </div>
            <div className="p-2">
              <p className="">Item Category</p>
            </div>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div>
            {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
              checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
              <div className="">
                <SectionTitle
                  active={!!selectedId}
                  editable={false}
                  title="Category"
                />
                <div className="">
                  <FormProvider {...frmMethods}>
                    <form
                      id="new-method-form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <BaseInvSetupItem
                        nameTitle={
                          !selectedId ? "New Category" : "Sub Category"
                        }
                        btnTitle={!selectedId ? "Add Category" : "Save Changes"}
                        cancelBtnTitle="Cancel"
                        resetData={resetForm}
                      />
                    </form>
                  </FormProvider>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Category"
        message="Are you sure you want to delete the item?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleDeleteItem(selectedId)}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
    </>
  );
};

export default NewItemCategory;
