import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import backArrow from "img/back-arrow.svg";

import { SerialListReportAPI } from "./services/ReportServices";

import { useQuery } from "react-query";

const SerialNumberListReport = () => {
  const history = useHistory();

  const pageTitle = "Serial Number list report";
  const backUrl = AppUrls.serial_numbers;

  useQuery("serials", SerialListReportAPI, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="static-page">
        <div className="page-content-wrapper p-0">
          <div className="grid-container"></div>
        </div>
      </div>
    </form>
  );
};

export default SerialNumberListReport;
