import Select from "react-select";
import DatePicker from "react-datepicker";

const options = [
  { value: 5, label: "This Year" },
  { value: 7, label: "This Month" },
  { value: 8, label: "This Week" },
  { value: 1, label: "Last Year" },
  { value: 2, label: "Last Quarter" },
  { value: 3, label: "Last Month" },
  { value: 4, label: "Last Week" },
  { value: 9, label: "Year To Date" },
  { value: 10, label: "Custom" },
];

const options2 = [
  { value: 5, label: "This Year" },
  { value: 7, label: "This Month" },
  { value: 8, label: "This Week" },
  { value: 1, label: "Last Year" },
  { value: 2, label: "Last Quarter" },
  { value: 3, label: "Last Month" },
  { value: 4, label: "Last Week" },
  { value: 9, label: "Year To Date" },
  { value: 10, label: "Custom" },
];

const options3 = [
  { value: 3, label: "Last 30 days" },
  { value: 7, label: "This Month" },
  { value: 5, label: "This fiscal Year" },
  { value: 1, label: "Last fiscal Year" },
  { value: 2, label: "Last fiscal Quarter" },
];

const DateGrid = ({
  setEndDate = null,
  endDate = null,
  setStartDate = null,
  startDate = null,
  dateCategory = null,
  setDateCategory = null,
  children = null,
  isFullWidth = false,
  thisFirst = false,
  isForExpenses = false,
}) => {
  const onDateChange = (e) => {
    let startDate = null;
    let endDate = null;

    setDateCategory(e);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    if (e?.value === 1) {
      startDate = new Date(currentYear - 1, 0, 1);
      endDate = new Date(currentYear - 1, 11, 31);
    } else if (e.value === 2) {
      const quarter = getQuarter(currentDate);
      startDate = new Date(currentYear, quarter * 3 - 6, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
    } else if (e.value === 3) {
      startDate = new Date(currentYear, currentMonth - 1, 1);
      endDate = new Date(currentYear, currentMonth, 0);
    } else if (e.value === 4) {
      const lastWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 7
      );
      startDate = getFirstDayOfWeek(lastWeek);
      const lastDay = new Date(startDate);
      endDate = new Date(lastDay.setDate(lastDay.getDate() + 6));
    } else if (e.value === 5) {
      startDate = new Date(currentYear, 0, 1);
      endDate = new Date(currentYear, 11, 31);
    } else if (e.value === 6) {
      const quarter = getQuarter(currentDate);
      startDate = new Date(currentYear, quarter * 3 - 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
    } else if (e.value === 7) {
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(currentYear, currentMonth + 1, 0);
    } else if (e.value === 8) {
      startDate = getFirstDayOfWeek(currentDate);
      const lastDay = new Date(startDate);
      endDate = new Date(lastDay.setDate(lastDay.getDate() + 6));
    } else if (e.value === 9) {
      startDate = new Date(new Date().setFullYear(currentYear - 1));
      endDate = currentDate;
    }

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getFirstDayOfWeek = (d) => {
    const date = new Date(d);
    const day = date.getDay();

    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  };

  const getQuarter = (date) => {
    return Math.floor(date.getMonth() / 3 + 1);
  };

  return (
    <div className="row px-0">
      <div
        className={`${isFullWidth ? "col-sm-4" : "col-sm-2"} form-group ${
          isForExpenses && "col-sm-6"
        }`}
      >
        <label>Date</label>
        <Select
          options={thisFirst ? options2 : isForExpenses ? options3 : options}
          isSearchable={true}
          value={dateCategory}
          onChange={onDateChange}
        />
      </div>
      <div
        className={`${isFullWidth ? "col-sm-4" : "col-sm-2"} form-group ${
          isForExpenses && "d-none"
        }`}
      >
        <label>Start Date</label>
        <DatePicker
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          onChange={(e) => {
            setStartDate(e);
          }}
          selected={startDate}
          disabled={dateCategory?.value !== 10}
          maxDate={endDate}
        />
      </div>
      <div
        className={`${isFullWidth ? "col-sm-4" : "col-sm-2"} form-group ${
          isForExpenses && "d-none"
        }`}
      >
        <label>End Date</label>
        <DatePicker
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          onChange={(e) => {
            setEndDate(e);
          }}
          selected={endDate}
          disabled={dateCategory?.value !== 10}
          minDate={startDate}
        />
      </div>
      {children}
    </div>
  );
};

export default DateGrid;
