import { useContext, useEffect, useState } from "react";

import { useMutation, useQuery } from "react-query";

import { getShippingList } from "../SystemSetup/SystemSetupServices/SystemSetupServices";
import { GetTrackingNumbersList } from "./SalesOrders/SalesOrderServices/SalesOrderServices";

import Select from "react-select";

import { dateFormmater, dateFormmaterNoTime } from "global/helpers";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";

import DispatchContext from "context/DispatchContext";

import { DropDownOption } from "components/Common.models";
import { getCustomers } from "../SalesMarketing/Customers/Customers.services";

import DateGrid from "components/DateFilter/DateGrid";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

const SoTrackingNumbersList = () => {
  const appDispatch = useContext(DispatchContext);

  useEffect(() => {
    getNumbers.mutate({});
  }, []);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { reset, register, control } = methods;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [shippingMethod, setShippingMethod] = useState<DropDownOption>(null);
  const [account, setAccount] = useState<DropDownOption>(null);
  const [fromDate, setFromDate] = useState<Date>(null);
  const [toDate, setToDate] = useState<Date>(null);
  const [dateCategory, setDateCategory] = useState(null);

  const [gridData, setGridData] = useState<Array<JSON>>(null);

  const { isLoading: isLoadingDataCustomers, data: customersList } = useQuery(
    "customersList",
    getCustomers
  );

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingDataCustomers });
  }, [isLoadingDataCustomers, appDispatch]);

  const shippingListData = useMutation("data", getShippingList);

  useEffect(() => {
    shippingListData.mutate(null);
  }, []);

  useEffect(() => {
    appDispatch({ type: "loading", value: shippingListData.isLoading });
  }, [shippingListData.isLoading, appDispatch]);

  const getNumbers = useMutation(GetTrackingNumbersList, {
    async onSuccess(response) {
      setGridData(null);
      setGridData(response);
    },
  });

  const columns = [
    {
      field: "trackingNumber",
      headerName: "Tracking No.",
    },
    {
      field: "orderNumber",
      headerName: "Order No.",
    },
    {
      field: "accountId",
      headerName: "Customer ID",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "shippingMethod",
      headerName: "Shipping Method",
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.orderDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.orderDate === null
            ? ""
            : dateFormmater(params.data.orderDate);
        return `
          <div>
            ${date}
          <div>
        `;
      },
    },
    {
      field: "addedBy",
      headerName: "Added By",
    },
    {
      field: "addedDate",
      headerName: "Added Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.addedDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.addedDate === null
            ? ""
            : dateFormmater(params.data.addedDate);
        return `
          <div>
            ${date}
          <div>
        `;
      },
    },
  ];

  const clearFilters = () => {
    reset();
    setFromDate(null);
    setToDate(null);
    setAccount(null);
    setDateCategory(null);
    setShippingMethod(null);
    getNumbers.mutate({});
  };

  const displayFilter = () => {
    getNumbers.mutate({
      shippingId: shippingMethod?.value,
      accountId: account?.value,
      from: dateFormmaterNoTime(fromDate),
      to: dateFormmaterNoTime(toDate),
      searchQuery: searchTerm,
    });
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Tracking Numbers</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || gridData?.length === 0}
            style={{ marginRight: "15px" }}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <form>
              <div className="d-flex align-items-start">
                <div className="form-group">
                  <label className="me-2">Search</label>
                  <div className="page-search">
                    <div className="input-group">
                      <span className="page-search-icon">
                        <i className="fa fa-search"></i>
                      </span>
                      <input
                        className="page-search-input ps-5 me-4 w-200"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: "1000px" }}>
                  <DateGrid
                    startDate={fromDate}
                    endDate={toDate}
                    setStartDate={setFromDate}
                    setEndDate={setToDate}
                    dateCategory={dateCategory}
                    setDateCategory={setDateCategory}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-sm-2">
                  <label className="label me-3">Shipping Method</label>
                  <Controller
                    {...register("shippingMethod")}
                    control={control}
                    name="shippingMethod"
                    render={() => (
                      <Select
                        options={shippingListData?.data?.data?.map((item) => ({
                          value: item.shippingMethodId,
                          label: item.name,
                        }))}
                        isSearchable={true}
                        menuPlacement="auto"
                        className="flex-grow-1"
                        value={shippingMethod}
                        onChange={(value) => setShippingMethod(value)}
                        isClearable
                      />
                    )}
                  />
                </div>

                <div className="col-sm-2">
                  <label className="label me-3">Account</label>
                  <Controller
                    {...register("accountId")}
                    control={control}
                    name="accountId"
                    render={() => (
                      <Select
                        options={customersList?.data?.map((item) => ({
                          value: item.customerAccountId,
                          label: item.accountNumber,
                        }))}
                        isSearchable={true}
                        menuPlacement="auto"
                        className="flex-grow-1"
                        value={account}
                        onChange={(value) => setAccount(value)}
                        isClearable
                      />
                    )}
                  />
                </div>
                <div className="col pt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              data={gridData}
              setrowClicked={() => {}}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              fileName="SO Tracking Numbers List Report"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoTrackingNumbersList;
