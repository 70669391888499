import * as AppUrls from "../constants/AppUrls";

import DispatchContext from "context/DispatchContext";
import {
  getListOfCustomizedReportsAPI,
  getListOfFavReportsAPI,
  getListOfReportsAPI,
} from "./services/ReportServices";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Tabs, Tab } from "react-bootstrap";

import "./style/common.scss";
import ReportsTab from "./ReportsTab";

const ReportsList = () => {
  const appDispatch = useContext(DispatchContext);
  const [data, setData] = useState(null);
  const [dataCustomized, setDataCuztomized] = useState(null);
  const [dataF, setDataF] = useState(null);

  let reportsDic = {
    AuditTrail: AppUrls.audit_trail,
    MovementTransaction: AppUrls.transactional_movement,
    SalesTransactionSummaryRe: AppUrls.sales_order_summary_report,
    SerialTransferTransaction: AppUrls.serial_transfer_report,
    OpenOrderTransactionSumma: AppUrls.open_order_summary_report,
    OpenPOSummary: AppUrls.open_po_summary_report,
    AccountSalesDetailsReport: AppUrls.account_sales_details_report,
    InventoryOnHandReport: AppUrls.inventory_on_hand_report,
    InventoryReorderReport: AppUrls.inventory_reorder_report,
    SalesProfitReport: AppUrls.sales_profit_report,
    InventoryAgingReport: AppUrls.inventory_aging_report,
    InvoiceApplicationDetails: AppUrls.invoice_application_detail,
    InvoiceApplicationSummary: AppUrls.invoice_application_summary,
    InventoryIssueReport: AppUrls.inventory_issue_report,
    CashReceiptRegisterReport: AppUrls.cash_receipt_register_report,
    ArAgingAsOfDateReport: AppUrls.ar_aging_as_of_date,
    PastDueInvoiceReport: AppUrls.past_due_invoices,
    SerialNoDetailAsOfDateRep: AppUrls.serial_detail_as_of_date,
    BalanceSheet: AppUrls.balance_sheet_report,
    ProfitAndLoss: AppUrls.profit_and_loss,
    JournalsReport: AppUrls.journals_report,
    ComparativeBalanceSheet: AppUrls.comparative_balance_sheet,
    TrialBalanceReport: AppUrls.trial_balance_report,
    InventoryValuationReport: AppUrls.inventory_valuation_report,
    InventoryValuationSummaryReport: AppUrls.inventory_valuation_summary_report,
    SalesReport: AppUrls.sales_report,
    ComparativeProfitLossReport: AppUrls.comparative_profit_and_loss,
    PurchaseOrderReport: AppUrls.purchase_order_report,
    ExpenseReport: AppUrls.expense_report,
    PurchasingReport: AppUrls.purchasing_report,
    BillPaymentsReport: AppUrls.bill_payment_report,
    ChartOfAccountsReport: AppUrls.chart_of_accounts_report,
    SupplierBalanceReport: AppUrls.supplier_balance_report,
  };

  const [reportDeleted, setIsReportDeleted] = useState(false);

  const ListOfReportsM = useMutation(getListOfReportsAPI, {
    onSuccess(res) {
      let resultData = res;
      resultData.map((result) => {
        result.reports.map((cat) => {
          cat.url = reportsDic[cat.key];
        });
      });
      setData(resultData);
    },
  });

  const ListOfCustReportsM = useMutation(getListOfCustomizedReportsAPI, {
    onSuccess(res) {
      let resultData = res;
      resultData.map((result) => {
        result.reports.map((cat) => {
          cat.url = reportsDic[cat.key];
        });
      });
      setDataCuztomized(resultData);
    },
  });

  const ListOfFavReportsM = useMutation(getListOfFavReportsAPI, {
    onSuccess(res) {
      setDataF(res);
    },
  });

  useEffect(() => {
    ListOfReportsM.mutate(null);
    ListOfFavReportsM.mutate();
    ListOfCustReportsM.mutate(null);
  }, []);

  useEffect(() => {
    if (reportDeleted) {
      ListOfReportsM.mutate(null);
      ListOfCustReportsM.mutate(null);
    }
  }, [reportDeleted]);

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: ListOfReportsM.isLoading || ListOfFavReportsM.isLoading,
    });
  }, [ListOfReportsM.isLoading, ListOfFavReportsM.isLoading]);

  return (
    <>
      <div className="static-page">
        <div className="page-title">Reports</div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <Tabs defaultActiveKey="standardReports">
              <Tab
                eventKey="standardReports"
                title={<div className="tab-inner-title">Stanrdard Reports</div>}
              >
                <>
                  <ReportsTab
                    data={data}
                    ListOfFavReportsM={ListOfFavReportsM}
                    dataF={dataF}
                    setIsReportDeleted={null}
                    reportsDisc={reportsDic}
                    isCustom={false}
                  />
                </>
              </Tab>
              <Tab
                eventKey="customizedReports"
                title={
                  <div className="tab-inner-title">Customized Reports</div>
                }
              >
                <>
                  <ReportsTab
                    data={dataCustomized}
                    ListOfFavReportsM={ListOfFavReportsM}
                    dataF={dataF}
                    setIsReportDeleted={setIsReportDeleted}
                    reportsDisc={reportsDic}
                    isCustom={true}
                  />
                </>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsList;
