import { Modal } from "react-bootstrap";
import close from "img/close.svg";
import { useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { useContext } from "react";
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { notificationMessage } from "global/helpers";
import { useForm } from "react-hook-form";
import { createReason } from "pages/EcommerceAdmin/Services/adminServices";

type Props = {
  onConfirm: (name: string) => void;
  onClose: () => void;
  showModal: boolean;
};

const ReasonsModal = (props: Props) => {
  const appDispatch = useContext(DispatchContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onCloseClicked = () => {
    props.onClose();
  };

  const addClass = useMutation(createReason, {
    async onSuccess(data) {
      let notification = {
        variant: "success",
        msg: `Reason ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
      props.onConfirm(data.reason);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding reason"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (data) => {
    addClass.mutate({ ...data });
  };

  return (
    <Modal
      className="confirmation-modal"
      show={props.showModal}
      onHide={() => props.onClose()}
      centered
    >
      <div className="d-flex justify-content-between align-items-center mx-4 my-3">
        <div className="confirmation-modal-title">Add Reason</div>
        <img
          src={close}
          alt=""
          className="confirmation-modal-close"
          onClick={onCloseClicked}
        />
      </div>
      <div className="mx-4">
        <Modal.Body className="px-0 py-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-4">
              <label>Reason</label>
              <input
                className="form-control"
                {...register("reason", { required: true })}
              />
            </div>
            {errors["name"] && (
              <p className="text-danger">This field is required</p>
            )}
            <div className="d-flex justify-content-between mt-3">
              <button
                className="btn btn-outline-primary ms-0"
                type="button"
                onClick={() => props.onClose()}
              >
                Cancel
              </button>
              <button className={`btn btn-primary`} type="submit">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ReasonsModal;
