import { useState, useEffect, useContext } from "react";
import DispatchContext from "context/DispatchContext";
import { Modal } from "react-bootstrap";
import "./DeleteQpay.scss";
import { useMutation } from "react-query";
import { Redirect } from "react-router-dom";
import { deleteQpay } from "../SystemSetupServices/SystemSetupServices";

const DeleteQpay = ({ isdelete, setIsDelete, qpayId }) => {
  const [deleted, setDeleted] = useState(false);
  const appDispatch = useContext(DispatchContext);

  const removeQpay = useMutation(deleteQpay, {
    async onSuccess() {
      setDeleted(true);
    },
  });

  const onDeleteQpay = () => {
    removeQpay.mutate(qpayId);
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: removeQpay.isLoading });
  }, [removeQpay?.isLoading, appDispatch]);

  return (
    <>
      <Modal show={isdelete} onHide={setIsDelete} id="delete-inventory-modal">
        <Modal.Header className="delete-inventory-header">
          <Modal.Title className="delete-inventory-title">
            Deleting qpay credentials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="delete-inventory-body">
          <span>
            Delete qpay credentials of Hub <strong>{qpayId}</strong> ?
          </span>
        </Modal.Body>
        <Modal.Footer className="delete-inventory-footer d-flex justify-content-between">
          <button
            className="btn keep-inventory-btn"
            onClick={() => setIsDelete(false)}
          >
            Cancel
          </button>
          <button className="btn delete-inventory-btn" onClick={onDeleteQpay}>
            Yes, Delete
          </button>
        </Modal.Footer>
      </Modal>
      {deleted ? <Redirect to="/system-setup/qpay-credentials" /> : ""}
    </>
  );
};

export default DeleteQpay;
