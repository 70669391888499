import { useContext, useEffect, useRef, useState } from "react";

import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useParams } from "react-router-dom";

import { PaymentReceiptReportAPI } from "./services/ReportServices";

import { useQuery } from "react-query";

import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { dateFormmaterNoTime } from "global/helpers";
import { useReactToPrint } from "react-to-print";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";

const PaymentReceiptReport = () => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();

  const { id } = useParams();
  const pageTitle = "Customer Payment Receipt";
  const backUrl = `${AppUrls.payment_receipt}/${id}`;

  const { data, isLoading } = useQuery(id, PaymentReceiptReportAPI, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No",
    },
    {
      field: "priorBalance",
      headerName: "Prior Balance",
      valueGetter: (params) => {
        return "$ " + (params.data?.priorBalance?.toFixed(2) ?? "0.00");
      },
    },
    {
      field: "currentBalance",
      headerName: "Current Balance",
      valueGetter: (params) => {
        return "$ " + (params.data?.currentBalance?.toFixed(2) ?? "0.00");
      },
    },
    {
      field: "originalAmount",
      headerName: "Original Amount",
      valueGetter: (params) => {
        return "$ " + (params.data?.originalAmount?.toFixed(2) ?? "0.00");
      },
    },
    {
      field: "invoiceAmount",
      headerName: "Inv Amount",
      valueGetter: (params) => {
        return "$ " + (params.data?.invoiceAmount?.toFixed(2) ?? "0.00");
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      valueGetter: (params) => {
        return "$ " + (params.data?.paidAmount?.toFixed(2) ?? "0.00");
      },
    },
  ];

  const [forPrinting, setForPrinting] = useState(false);
  const reportPage = useRef(null);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoading });
  }, [appDispatch, isLoading]);

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    setForPrinting(true);
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setForPrinting(false);
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  const onBackClick = () => {
    history.push(backUrl);
  };

  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="page-content-wrapper p-0">
        <div className="page-content">
          <div className="grid-container">
            <div className="container report-filters-div text-end mb-3 mt-3">
              <button
                type="button"
                className="btn btn-success ms-5"
                onClick={printDocument}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-printer me-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                </svg>
                Print report to PDF
              </button>
            </div>
            <hr />
            <div className="report-page container" ref={reportPage}>
              <div className="report-header d-flex justify-content-between">
                <div>
                  <h3>
                    <b>{pageTitle}</b>
                  </h3>{" "}
                  <br />
                  <p>
                    <b>Report Date: </b>
                    {dateFormmaterNoTime(data?.reportDate)}
                  </p>
                </div>
                <div>
                  <img
                    alt=""
                    src={data?.imageLogo ?? BlueSeedLogo}
                    className="report-logo"
                  ></img>
                </div>
              </div>
              <div className="container">
                <div className="row my-3">
                  <div className="col-6">
                    <div className="w-50 border p-2 text-start">
                      <p>
                        <b>Customer : </b> {data?.accountNumber}{" "}
                      </p>
                    </div>
                    <div className="w-50 border p-2 text-start">
                      <p>
                        <b>Balance : </b> $ {data?.accountBalance?.toFixed(2)}{" "}
                      </p>
                    </div>
                    <div className="w-50 border p-2 text-start">
                      <p>
                        <b>Selling Price Lvl : </b> {data?.sellingPriceLevel}{" "}
                      </p>
                    </div>
                    <div className="w-50 border p-2 text-start">
                      <p>
                        <b>Payment Method : </b> {data?.paymentMethod}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-wrapper">
                <div className="position-relative">
                  <div
                    className="position-relative"
                    style={{ height: "350px", width: "70%", margin: "auto" }}
                  >
                    {forPrinting ? (
                      <AgGrid
                        columns={columns}
                        data={data?.invoices}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        autoSize={true}
                      />
                    ) : (
                      <AgGrid
                        columns={columns}
                        data={data?.invoices}
                        pagination={false}
                        autoHight={true}
                        autoSize={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PaymentReceiptReport;
