import { useState, useEffect, useContext } from "react";
import DispatchContext from "context/DispatchContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import StateContext from "context/StateContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { VendorTypeModel } from "../Vendors.models";
import { ErrorResponse } from "components/data/Errors.model";
import { Link, useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import addIcon from "../../../img/add-icon.svg";

import {
  createVendorType,
  getVendorTypes,
  updateVendorType,
} from "../Vendors.services";
import { useForm } from "react-hook-form";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import AgGrid from "elements/AgGrid";

const VendorTypesList = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { error: errorLoadingData, data: items } = useQuery<
    VendorTypeModel[],
    ErrorResponse
  >(["get_vendorTypes", filter], getVendorTypes);
  const [dataGrid, setDataGrid] = useState(null);
  const frmMethods = useForm<VendorTypeModel>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: true },
  });
  const { reset } = frmMethods;

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const columns = [
    {
      field: "name",
      headerName: "Vendor Type",
      editable: false,
    },
    // {
    //     field: "description",
    //     headerName: "Description",
    // },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
            <div class="${status ? "active-status" : "disabled-status"}">
            ${status ? "Active" : "Disabled"}
            </div>
        `;
      },
    },
  ];

  const addItem = useMutation(createVendorType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_vendorTypes");
      let notification = {
        variant: "success",
        msg: `Vendor type ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding vendor type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateItem = useMutation(updateVendorType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_vendorTypes");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing vendor type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addItem.isLoading || updateItem.isLoading,
    });
  }, [addItem.isLoading, updateItem.isLoading]);

  const history = useHistory();

  const onRowDoubleClicked = (e: VendorTypeModel) => {
    const url = `${AppUrls.vendor_types}/${e.vendorTypeId}`;
    history.push(url);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Vendor Types</p>
        <div>
          <div>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <Link to={AppUrls.new_vendor_types} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> New Vendor Type
              </Link>
            )}
          </div>
          {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn}>
                        <img src={exportIcon} className="me-2" alt="" /> 
                        Export to Excel
                    </button> */}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row h-100">
          <div className=" grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                data={items}
                setDataGrid={setDataGrid}
                filters={filter}
                setrowClicked={onRowDoubleClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setIsLoadingGridExport={setIsLoadingGridExport}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorTypesList;
