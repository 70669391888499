import * as AppUrls from "constants/AppUrls";
import {
  dateFormmaterNoTime,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import SelectionModal from "components/SelectionModal/SelectionModal";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import Lookup from "elements/Lookup";
import queryString from "query-string";
import { FormProvider, useForm } from "react-hook-form";
import {
  getVendorRmaDetails,
  getVendorRmaList,
  shipRma,
} from "./RmaServices/RmaServices";

enum RMAStatus {
  Open = 1,
  Voided = 2,
  "Partially Shipped" = 3,
  Shipped = 4,
  Credited = 5,
  Rejected = 6,
  Approved = 7,
  Refunded = 8,
}

const ShipRma = () => {
  const history = useHistory();
  const selectedItem = useRef(null);
  const gridItems = useRef([]);
  const serialType = useRef(null);
  const appDispatch = useContext(DispatchContext);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedRma, setSelectedRma] = useState(null);
  const [showSerialSelection, setShowSerialSelection] =
    useState<boolean>(false);
  const [rowChecked, setRowChecked] = useState([]);

  const { search } = useLocation();
  const { rmaId } = queryString.parse(search);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, setValue, getValues } = methods;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data: rmaList } = useQuery(["vendor-rma", filter], () =>
    getVendorRmaList({ forShipping: true, params: filter.params ?? "" })
  );
  const { data: rmaData, mutate } = useMutation(getVendorRmaDetails, {
    onSuccess(res) {
      gridItems.current = res.items;
      if (!selectedRma) setSelectedRma(res);
    },
  });

  useEffect(() => {
    if (rmaId) {
      setValue("vendorRmaId", rmaId, { shouldDirty: true });
      mutate({ queryKey: [rmaId] });
    }
  }, [rmaId]);

  const receiveMutation = useMutation(shipRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA shipped successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem shipping rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onBackClick = () => {
    history.push(AppUrls.vendor_rma);
  };

  const columns = [
    {
      headerName: "",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: "quantity",
      headerName: "RMA QTY",
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      cellRendererFramework: (params) =>
        formatNumber(params.data.quantity * params.data.cost),
    },
    {
      field: "shipped",
      headerName: "Shipped Quantity",
      editable: true,
    },
    {
      field: "returnSerials",
      headerName: "Return Serials",
      cellRendererFramework: (params) => {
        return params?.data && params?.data?.serials.length > 0 ? (
          <button
            type="button"
            className="btn btn-link text-decoration-none ms-0 ps-0"
            onClick={() => addSerial(params.data, 1)}
          >
            Add Serial
          </button>
        ) : (
          "-"
        );
      },
    },
  ];

  const rmaColumns = [
    {
      field: "vendorRmaNumber",
      headerName: "RMA No.",
    },
    {
      field: "vendorNumber",
      headerName: "Vendor No.",
    },
    {
      field: "purchaseOrderNumber",
      headerName: "Po No.",
    },
    {
      field: "billNumber",
      headerName: "Bill No.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => RMAStatus[params.value],
    },
    {
      field: "vendorRMADate",
      headerName: "RMA Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "RMA Amount",
      cellRenderer: (params) => formatNumber(params.value),
    },
  ];

  const onRmaSelected = (rma) => {
    // if (rma.status === 2 || rma.status === 9) {
    setValue("vendorRmaId", rma.vendorRMAId, { shouldDirty: true });
    setSelectedRma(rma);
    mutate({ queryKey: [rma.vendorRMAId] });
    setOpenSelection(false);
    selectedItem.current = null;
    // } else {
    //     let notification = {
    //         variant: "danger",
    //         msg: "Only approved and partially received rmas can be selected"
    //     };
    //     appDispatch({ type: "notification", value: notification })
    // }
  };

  const addSerial = (values, type) => {
    serialType.current = type;
    selectedItem.current = values.vendorRMAItemId;
    setShowSerialSelection(true);
  };

  const selectSerial = (items) => {
    gridItems.current = gridItems.current.map((item) => {
      if (item.vendorRMAItemId === selectedItem.current)
        return { ...item, returnSerials: items.map((i) => i.id) };
      return { ...item };
    });
    setShowSerialSelection(false);
  };

  const onSubmit = (values) => {
    let checkedItems = rowChecked.filter((n) =>
      rowChecked.some((n2) => n.vendorRMAItemId === n2.vendorRMAItemId)
    );
    if (checkedItems.length === 0) {
      let notification = {
        variant: "danger",
        msg: "Please select items to receive from the table",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (checkedItems.some((item) => !item.shipped)) {
      let notification = {
        variant: "danger",
        msg: "Please choose item quantity to return",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      checkedItems.some((item) => Number(item.shipped ?? 0) > item.quantity)
    ) {
      let notification = {
        variant: "danger",
        msg: "Invalid quantity to return entered",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      checkedItems.some(
        (item) =>
          item.serials?.length > 0 &&
          item.serials?.length !== item.returnSerials?.length
      )
    ) {
      let notification = {
        variant: "danger",
        msg: "Please add serials to selected items",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      if (
        checkedItems.some((item) => Number(item.shipped ?? 0) < item.quantity)
      ) {
        setOpenConfirmation(true);
      } else {
        let data = {
          ...values,
          shipmentDate: new Date(),
          close: true,
          items: checkedItems.map((item) => ({
            vendorRMAItemId: item.vendorRMAItemId,
            shippmentQuantity: item.shipped,
            serials: item.returnSerials ?? [],
          })),
        };
        receiveMutation.mutate(data);
      }
    }
  };

  const onConfirmation = (close) => {
    let values = getValues();
    let checkedItems = rowChecked.filter((n) =>
      rowChecked.some((n2) => n.vendorRMAItemId === n2.vendorRMAItemId)
    );
    setOpenConfirmation(false);
    let data = {
      ...values,
      shipmentDate: new Date(),
      close: close,
      items: checkedItems.map((item) => ({
        vendorRMAItemId: item.vendorRMAItemId,
        shippmentQuantity: item.shipped,
        serials: item.returnSerials ?? [],
      })),
    };
    receiveMutation.mutate(data);
  };

  let itemSerials = gridItems.current?.find(
    (item) => item.vendorRMAItemId === selectedItem.current
  );
  const getTotalQty = () => {
    let totalQuantity = 0;

    if (rmaData && rmaData.items) {
      rmaData.items.forEach((item) => {
        totalQuantity += item.quantity;
      });
    }
    return totalQuantity;
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Ship RMA
            </div>
            <div>
              <button className="btn btn-outline-primary ">Close RMA</button>
              <button className="btn btn-success">Ship</button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row  gx-5">
                <div className="col-lg-8 col-sm-12">
                  <div className="row mb-5">
                    <div className="col-sm-6">
                      <label>
                        RMA No. <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="vendorRmaId"
                        initialData={rmaList?.data?.map((item) => ({
                          ...item,
                          id: item.vendorRMAId,
                          name: item.vendorRmaNumber,
                        }))}
                        onSelection={onRmaSelected}
                        inputValue={selectedRma?.vendorRmaNumber}
                      />
                    </div>
                  </div>
                  <div className="row gx-5 gy-4">
                    <div className="col-lg-3 col-sm-12">
                      <label>Vendor No.</label>
                      <div>{rmaData?.vendorNumber}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Company</label>
                      <div>{rmaData?.shippingInfo?.company}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Status</label>
                      <div>{RMAStatus[rmaData?.status]}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row mt-4">
                    <div className="col-lg-6 col-sm-12 px-4">
                      <div className="d-flex">
                        <div className="text-secondary">TOTAL QTY</div>
                        <div className="flex-grow-1 text-center">
                          {rmaData ? getTotalQty() : <></>}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="d-flex">
                        <div className="text-secondary">TOTAL ITEMS</div>
                        <div className="flex-grow-1 text-center">
                          {rmaData ? rmaData.items.length : <></>}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="px-4">
                      <hr />
                    </div>
                    <div className="col-sm-12">
                      <div className="section mt-3 p-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fs-5">ORDER TOTAL</div>
                          <div className="text-success fs-4">
                            {rmaData ? formatNumber(rmaData?.amount) : <></>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-450 mt-5">
                <AgGrid
                  data={gridItems.current}
                  columns={columns}
                  multipleSelection={true}
                  setRowChecked={setRowChecked}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal="RMA"
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={rmaList?.data}
        columns={rmaColumns}
        setRowClicked={onRmaSelected}
        searchProp={true}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
      />
      <ListSelectionModal
        showModal={showSerialSelection}
        title="Serials"
        data={
          selectedItem.current
            ? itemSerials?.serials?.map((serial) => {
                return { title: serial.serialNo, id: serial.serialId };
              })
            : []
        }
        selectedValues={selectedItem.current ? itemSerials?.returnSerials : []}
        onSelectData={selectSerial}
        onExit={() => setShowSerialSelection(false)}
        selectValue={itemSerials?.returnSerials?.length}
        isInvoice={true}
        maxValue={itemSerials?.serials?.length ?? 0}
      />
      <ConfirmationModal
        title="Partially Receiving RMA"
        message="Would you like to close RMA?"
        onClose={() => onConfirmation(false)}
        onConfirm={() => onConfirmation(true)}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={openConfirmation}
      />
    </>
  );
};

export default ShipRma;
