import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { ErrorResponse } from "components/data/Errors.model";
import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AgGrid from "elements/AgGrid";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import Delete from "img/delete.svg";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import addIcon from "../../../img/add-icon.svg";
import { ItemCondition } from "./InventorySetup.model";
import {
  createItemCondition,
  deleteItemCondition,
  editItemCondition,
  getItemConditions,
} from "./InventorySetup.services";
const ItemConditions = ({ match }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { error: errorLoadingData, data: items } = useQuery<any, ErrorResponse>(
    ["get_conditions", filter],
    getItemConditions
  );

  const frmMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const { reset } = frmMethods;

  const columns = [
    {
      field: "condition",
      headerName: "Item Condition",
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "action",
      headerName: "Action",
      floatingFilter: false,
      filter: false,
      sortable: false,
      hide: !checkIfAllowed(appState.allowedUrls, "delete", match.path),
      onCellClicked: (params) => {
        onDeleteItemClicked(params.data);
      },
      cellRenderer: () => {
        return `
            <div class="btn text-danger">
              <img src=${Delete} alt="Delete Icon" />
              Delete 
            </div>
      `;
      },
    },
  ];

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const addCondition = useMutation(createItemCondition, {
    async onSuccess() {
      queryClient.invalidateQueries("get_conditions");
      let notification = {
        variant: "success",
        msg: `Condition ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding condition"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateCondition = useMutation(editItemCondition, {
    async onSuccess() {
      queryClient.invalidateQueries("get_conditions");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSelectedId(null);
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing condition"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeItem = useMutation(deleteItemCondition, {
    async onSuccess() {
      queryClient.invalidateQueries("get_conditions");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing item condition"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();
  const onRowClicked = (e) => {
    const url = `${AppUrls.item_condition}/${e.itemConditionId}`;
    history.push({
      pathname: url,
      state: {
        id: e.itemConditionId,
        name: e.condition,
        description: e.description,
      },
    });
  };

  const handleDeleteItem = (itemId) => {
    removeItem.mutate(itemId);
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const onDeleteItemClicked = (data) => {
    setSelectedId(data.itemConditionId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        addCondition.isLoading ||
        updateCondition.isLoading ||
        removeItem.isLoading,
    });
  }, [
    addCondition.isLoading,
    updateCondition.isLoading,
    removeItem.isLoading,
    appDispatch,
  ]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <p>Item Conditions</p>
          <div>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <Link to={AppUrls.new_item_condition} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> New Item Condition
              </Link>
            )}
            {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn}>
                    <img src={exportIcon} className="me-2" alt="" /> 
                    Export to Excel
                </button> */}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row h-100">
            <div className=" grid-container">
              <div className="grid">
                <AgGrid
                  columns={columns}
                  data={items?.data}
                  filters={filter}
                  setrowClicked={onRowClicked}
                  exportToExcel={exportToExcelClicked}
                  setExportToExcelClicked={setExportToExcelClicked}
                  setIsLoadingGridExport={setIsLoadingGridExport}
                  autoSize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Condition"
        message="Are you sure you want to delete the item?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleDeleteItem(selectedId)}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
    </>
  );
};

export default ItemConditions;
