import { useState } from "react";
import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useContext, useEffect } from "react";
import { addLedger, getLedgers } from "./AccountingServices";
import { useQuery, useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { formatInput, notificationMessage } from "global/helpers";
import Lookup from "elements/Lookup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { LedgerTypeEnum } from "./enum/AccountEnum";

type Props = {
  showModal: boolean;
  onExit: () => void;
  formValues;
};

const LedgersModal = (props: Props) => {
  const appDispatch = useContext(DispatchContext);
  const [, setRefreshCounter] = useState(0);

  useEffect(() => {
    if (props.formValues) {
      const { parent, accountNumber, serial } = props.formValues;
      setValue("parent", parent);
      setValue("accountNumber", accountNumber);
      setValue("serial", serial);
    }
  }, [props.formValues]);
  const addMutation = useMutation(addLedger, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Ledger added successfully",
      };
      appDispatch({ type: "notification", value: notification });
      closeAndReset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding ledger"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isDebit: true,
      parent: null,
      serial: null,
      accountNumber: null,
      accountName: null,
      startingBalance: null,
      balancingAffectedAccountId: null,
      isNonPosting: false,
      isActive: true,
    },
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = methods;

  const closeAndReset = () => {
    reset();
    setSelectedLedger(null);
    props.onExit();
  };

  const { data: paymentAccounts } = useQuery("expenses", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Equity })
  );

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("balancingAffectedAccountId", e.ledgerId, {
      shouldValidate: true,
    });
    setRefreshCounter((prev) => prev + 1);
  };

  const onSubmit = (data) => {
    let dataToSend = {
      ...data,
      accountingCategoryId: props.formValues.categoryId,
      startingBalance: data.startingBalance
        ? parseFloat(data.startingBalance?.toString().replace(/,/g, ""))
        : 0,
      balancingAffectedAccountId: data.balancingAffectedAccountId,
    };
    addMutation.mutate(dataToSend);
  };

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const checkIfIncomeOrExpense = () => {
    if (
      props?.formValues?.parent.substring(0, 2) === "04" ||
      props?.formValues?.parent.substring(0, 2) === "05"
    )
      return true;
    return false;
  };

  return (
    <>
      <Modal
        show={props.showModal}
        centered
        size="lg"
        dialogClassName="modal-50w"
      >
        <Modal.Header>
          <Modal.Title>Add Account</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className=""
            role="button"
            onClick={closeAndReset}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-11 offset-sm-1">
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="h-100 d-flex flex-column"
                >
                  <div className="row gy-4 mb-5">
                    <div className="col-sm-5">
                      <div className="form-group">
                        <label>Parent Acc</label>
                        <input
                          {...register("parent")}
                          className={"form-control"}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-group">
                        <label>Serial</label>
                        <input
                          {...register("serial")}
                          className={"form-control"}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-group">
                        <label>Account #</label>
                        <input
                          {...register("accountNumber")}
                          className={"form-control"}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-group">
                        <label>
                          Account Name<span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("accountName", { required: true })}
                          className={
                            errors.accountName
                              ? "form-control required_field"
                              : "form-control"
                          }
                        />
                      </div>
                      {errors["accountName"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    {!checkIfIncomeOrExpense() && (
                      <>
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label>Starting balance</label>
                            <input
                              {...register("startingBalance")}
                              className={"form-control"}
                              onBlur={(e) => {
                                setValue(
                                  "startingBalance",
                                  formatInput(e.target.value)
                                );
                                setRefreshCounter((prev) => prev + 1);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label>Balancing affected account</label>
                            <Lookup
                              onButtonClicked={() =>
                                setOpenSelectionLedger(true)
                              }
                              inputName="balancingAffectedAccountId"
                              initialData={paymentAccounts?.data?.map(
                                (item) => ({
                                  ...item,
                                  id: item.ledgerId,
                                  name: item.accountName,
                                })
                              )}
                              onSelection={onLedgerSelection}
                              inputValue={selectedLedger?.accountName}
                              isDisabled={!getValues("startingBalance")}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-sm-5">
                      <Controller
                        control={control}
                        name="isActive"
                        render={({ field: { onChange, value } }) => (
                          <div className="form-check">
                            <input
                              className={`form-check-input`}
                              type="checkbox"
                              id="activeState"
                              onChange={() => onChange(!value)}
                              checked={value}
                            />
                            <label
                              className={`form-check-label text-black`}
                              htmlFor="activeState"
                            >
                              Active
                            </label>
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-sm-5">
                      <Controller
                        control={control}
                        name="isNonPosting"
                        render={({ field: { onChange, value } }) => (
                          <div className="form-check">
                            <input
                              className={`form-check-input`}
                              type="checkbox"
                              id="postState"
                              onChange={() => onChange(!value)}
                              checked={value}
                            />
                            <label
                              className={`form-check-label text-black`}
                              htmlFor="postState"
                            >
                              Non Posting
                            </label>
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-sm-5">
                      <Controller
                        control={control}
                        name="isDebit"
                        render={({ field: { onChange, value } }) => (
                          <div className="form-group mt-2">
                            <label className="checkbox-inline">
                              <input
                                id="isDebit"
                                type="radio"
                                onChange={() => onChange(true)}
                                checked={value}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="isDebit"
                              >
                                Debit
                              </label>
                            </label>
                            <label className="checkbox-inline ms-3">
                              <input
                                id="isCredit"
                                type="radio"
                                onChange={() => onChange(false)}
                                checked={!value}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="isCredit"
                              >
                                Credit
                              </label>
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="mt-auto">
                    <button
                      type="submit"
                      className="btn btn-primary ms-0"
                      disabled={
                        getValues("startingBalance") &&
                        !getValues("balancingAffectedAccountId")
                      }
                    >
                      Add Account
                    </button>
                    <button
                      type="button"
                      onClick={closeAndReset}
                      className="btn btn-outline-primary"
                    >
                      {" "}
                      Reset
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
    </>
  );
};

export default LedgersModal;
