import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useMutation, useQuery } from "react-query";
import { getExpenses, getExpensesTypes } from "./DashboardServices";

import DateGrid from "components/DateFilter/DateGrid";
import { getOneMonthAgoDate } from "global/helpers";

const Expenses = () => {
  const [startDate, setStartDate] = useState<Date>(getOneMonthAgoDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dateCategory, setDateCategory] = useState(null);

  const [expenses, setExpenses] = useState(null);

  const mutate = useMutation(getExpenses, {
    onSuccess(data) {
      setExpenses(data);
    },
  });

  useEffect(() => {
    if (startDate || endDate) {
      mutate.mutate({ startDate: startDate, endDate: endDate });
    }
  }, [startDate, endDate]);

  let data = [["Expense", "Value"]];

  expenses?.forEach((e) => {
    data.push([e.type, e.amount]);
  });

  const options = {
    title: "Expenses",
  };

  return (
    <>
      <div className="row w-100">
        <DateGrid
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dateCategory={dateCategory}
          setDateCategory={setDateCategory}
          isFullWidth={true}
          isForExpenses={true}
        />
        {/* <div className='col-6'>
                    <label>Type</label>
                    <Controller
                        control={control}
                        name="type"
                        render={({
                            field: { onChange, value },
                        }) => (
                            <Select
                                options={typesOpt}
                                value={type}
                                onChange={e => setType(e)}
                                isSearchable={true}
                                isClearable={true}
                            />
                        )}
                    />
                </div> */}
      </div>
      <div className="w-100">
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width="700px"
          height="350px"
        />
      </div>
    </>
  );
};

export default Expenses;
