import {
  DropDownOption,
  GridColumns,
  QueryResult,
} from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { ErrorResponse } from "components/data/Errors.model";
import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import Lookup from "elements/Lookup";
import SectionTitle from "elements/SectionTitle";
import { notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { CustomerAccount } from "../Customers/Customers.models";
import { getCustomers } from "../Customers/Customers.services";
import { PortalUser } from "../SalesMarketing.models";
import {
  createPortal,
  editPortal,
  getPortal,
} from "../SalesMarketingServices/salesMarketingServices";

const PortalUserInfo = () => {
  const history = useHistory();
  const { id } = useParams();
  const appDispatch = useContext(DispatchContext);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerAccount>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  let methods = useForm<PortalUser>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isActive: true,
    },
  });
  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    handleSubmit,
    reset,
    register,
    setValue,
  } = methods;

  const { data: currentPortal } = useQuery<PortalUser>(
    ["getCurrentPortal", id],
    getPortal,
    {
      enabled: id !== undefined ? true : false,
    }
  );

  useEffect(() => {
    if (currentPortal) {
      reset(currentPortal);
    }
  }, [currentPortal]);

  useEffect(() => {
    if (selectedCustomer) {
      setValue("accountNum", selectedCustomer.accountNumber, {
        shouldDirty: true,
      });
      setShowModal(false);
    }
  }, [selectedCustomer]);

  const addPortalUser = useMutation(createPortal, {
    async onSuccess() {
      setIsSuccessful(true);

      let notification = {
        variant: "success",
        msg: `Portal User ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(AppUrls.portal_users);
    },
    onError(error) {
      setIsSuccessful(false);
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding Portal User"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updatePortalUser = useMutation(editPortal, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`${AppUrls.portal_users}/${id}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing portal user"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (values: PortalUser) => {
    const final_data = {
      username: values.username,
      fullname: values.fullname,
      email: values.email,
      isActive: values.isActive,
      userLevelId: values.userLevelId,
      customerAccountId: selectedCustomer?.customerAccountId,
      companyName: values.companyName,
    };
    if (id) {
      final_data["portalUserId"] = Number(id);
      updatePortalUser.mutate(final_data);
    } else {
      addPortalUser.mutate(final_data);
    }
  };

  const columns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
      editable: false,
    },
    {
      field: "company",
      headerName: "Company Name",
      editable: false,
    },
    {
      field: "contact",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "hubId",
      headerName: "Hub Id",
      editable: false,
    },
    {
      field: "city",
      headerName: "City Name",
      editable: false,
    },
    {
      field: "state",
      headerName: "State",
      editable: false,
    },
    {
      field: "dba",
      headerName: "Account Type",
      editable: false,
    },
  ];

  const { error: errorLoadingCustomers, data: customers } = useQuery<
    QueryResult<CustomerAccount>,
    ErrorResponse
  >("customersList", getCustomers);

  useEffect(() => {
    if (errorLoadingCustomers?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingCustomers.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingCustomers, appDispatch]);

  const userLevels: DropDownOption[] = [
    {
      label: "Employee",
      value: 1,
    },
    {
      label: "Operator",
      value: 2,
    },
  ];

  const onBackClicked = () => {
    if (id) {
      history.push(`${AppUrls.portal_users}/${id}`);
    } else {
      history.push(AppUrls.portal_users);
    }
  };

  const onCancelClicked = () => {
    reset();
    if (!id) {
      setValue("accountNum", "");
      setSelectedCustomer(null);
    }
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addPortalUser.isLoading || updatePortalUser.isLoading,
    });
  }, [addPortalUser.isLoading, updatePortalUser.isLoading]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div role="button" className="back-button " onClick={onBackClicked}>
            <img src={backArrow} alt="back arrow" />
            {id ? " Editing" : " Creating Portal User"}
          </div>
          <div className="d-flex justify-content-between ">
            <button
              type="button"
              className={`btn btn-outline-primary no-border ${
                !isDirty && !id && "text-muted"
              }`}
              disabled={!isDirty && !id}
              onClick={onCancelClicked}
            >
              Reset
            </button>
            <button
              type="submit"
              className="btn btn-success"
              disabled={!isDirty || (isSubmitSuccessful && isSuccessful)}
            >
              {id ? "Save Changes" : "Create"}
            </button>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <FormProvider {...methods}>
                      <div className="form-group">
                        <label>
                          Account No.<span className="text-danger">*</span>
                        </label>
                        <Lookup
                          onButtonClicked={() => setShowModal(true)}
                          inputName="accountNum"
                          isRequired={true}
                          initialData={customers?.data
                            ?.filter((cust) => !!cust.isActive)
                            .map((cust) => ({
                              ...cust,
                              id: cust.customerAccountId,
                              name: cust.accountNumber,
                            }))}
                          onSelection={setSelectedCustomer}
                          inputValue={selectedCustomer?.accountNumber}
                          isDisabled={false}
                        />
                        {/* <InputGroup>
                                                    <Form.Control
                                                        className="form-control"
                                                        disabled={id ? true : false}
                                                        {...register("accountNum", { required: true })}
                                                        defaultValue={id ? currentPortal?.accountNum : ''}
                                                    />
                                                    <Button
                                                        disabled={id ? true : false}
                                                        onClick={() => setShowModal(true)}
                                                    >
                                                        <img src={SearchIcon} alt="" />
                                                    </Button>
                                                </InputGroup> */}
                        {errors["accountNum"] && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </FormProvider>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("companyName", { required: true })}
                        disabled={id ? true : false}
                        defaultValue={id ? currentPortal?.companyName : ""}
                        className={
                          errors["companyName"]
                            ? "form-control required_field"
                            : "form-control"
                        }
                      />
                      {errors["companyName"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section p-0 my-4 mx-0">
                  <SectionTitle editable={false} title="Account Info" />
                </div>
                <div className="col-lg-10">
                  <div className="my-3">
                    <div className="form-group">
                      <label>
                        User ID <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("username", { required: true })}
                        className={
                          errors["username"]
                            ? "form-control required_field"
                            : "form-control"
                        }
                      />
                      {errors["username"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="section p-0 mb-4 mx-0">
                  <SectionTitle editable={false} title="User Info" />
                </div>
                <div className="row my-3">
                  <div className="col">
                    <div className="form-group">
                      <label>
                        Full Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("fullname", { required: true })}
                        className={
                          errors["fullname"]
                            ? "form-control required_field"
                            : "form-control"
                        }
                      />
                      {errors["fullname"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>
                        User Level <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="userLevelId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(selectedOption: any) => {
                              onChange(selectedOption?.value);
                            }}
                            className={errors.userLevelId && " required_field"}
                            isClearable
                            value={
                              userLevels.find((opt) => opt?.value === value) ||
                              null
                            }
                            options={userLevels}
                          />
                        )}
                      />
                      {errors["userLevelId"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col">
                    <div className="form-group">
                      <label>
                        User Email <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message:
                              "Entered value does not match email format.",
                          },
                        })}
                        className={
                          errors["email"]
                            ? "form-control required_field"
                            : "form-control"
                        }
                      />
                      {errors["email"] && (
                        <p className="text-danger">
                          {errors.email.message || "This field is required"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="row"></div>
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="isActive"
                        render={({ field: { onChange, value } }) => (
                          <>
                            {value ? (
                              <label className="row p-2 ">Active</label>
                            ) : (
                              <label className="row p-2"> Disabled</label>
                            )}
                            <label className="switch" htmlFor="activeCheckbox">
                              <input
                                type="checkbox"
                                id="activeCheckbox"
                                name="active"
                                onChange={() => onChange(!value)}
                                checked={value}
                              />
                              <span className="slider round"></span>
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <SelectionModal
        modal="Account"
        showModal={showModal}
        setShowModal={setShowModal}
        data={customers?.data}
        columns={columns}
        setRowClicked={setSelectedCustomer}
      />
    </>
  );
};

export default PortalUserInfo;
