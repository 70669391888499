import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import Delete from "img/delete.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import * as AppUrls from "constants/AppUrls";
import { Link, useHistory } from "react-router-dom";
import addIcon from "../../../img/add-icon.svg";

import { DropDownOption } from "components/Common.models";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { useForm } from "react-hook-form";
import { ItemSubClass } from "./InventorySetup.model";
import {
  createSubClass,
  deleteSubClass,
  editSubClass,
  getItemClasses,
  getItemSubClasses,
} from "./InventorySetup.services";

import AgGrid from "elements/AgGrid";

const ItemSubClasses = ({ match }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { error: errorLoadingData, data: items } = useQuery<any, ErrorResponse>(
    ["get_subClasses", filter],
    getItemSubClasses
  );
  const { error: errorLoadingDataClasses, data: itemClasses } = useQuery<
    any,
    ErrorResponse
  >("get_classes", getItemClasses);
  const methods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const { reset, setValue } = methods;

  const columns = [
    {
      field: "subClass",
      headerName: "Item Sub Class",
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "itemClassId",
      headerName: "Item Class",
      valueGetter: (params) => {
        return itemClasses?.data?.find(
          (i) => i.itemClassId === params?.data?.itemClassId
        )?.className;
      },
      cellRenderer: (params) => {
        return itemClasses?.data?.find(
          (i) => i.itemClassId === params?.data?.itemClassId
        )?.className;
      },
    },
    {
      field: "action",
      headerName: "Action",
      floatingFilter: false,
      filter: false,
      sortable: false,
      hide: !checkIfAllowed(appState.allowedUrls, "delete", match.path),
      onCellClicked: (params) => {
        onDeleteItemClicked(params.data);
      },
      cellRenderer: () => {
        return `
            <div class="btn text-danger">
              <img src=${Delete} alt="Delete Icon" />
              Delete 
            </div>
      `;
      },
    },
  ];

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  useEffect(() => {
    if (errorLoadingDataClasses?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingDataClasses.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingDataClasses, appDispatch]);

  const addSubClass = useMutation(createSubClass, {
    async onSuccess() {
      queryClient.invalidateQueries("get_subClasses");
      let notification = {
        variant: "success",
        msg: `Item sub class ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item sub class"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateSubClass = useMutation(editSubClass, {
    async onSuccess() {
      queryClient.invalidateQueries("get_subClasses");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setSelectedId(null);
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing item sub class"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeItem = useMutation(deleteSubClass, {
    async onSuccess() {
      queryClient.invalidateQueries("get_subClasses");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing item sub class."),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const history = useHistory();

  const onRowDoubleClicked = (e) => {
    const url = `${AppUrls.item_sub_class}/${e.itemSubClassId}`;
    history.push({
      pathname: url,
      state: {
        id: e.itemSubClassId,
        name: e.subClass,
        description: e.description,
        itemClass: e.itemClassId,
      },
    });
    setSelectedId(e.itemSubClassId);
    setValue("name", e.subClass);
    setValue("description", e.description);
  };

  const handleDeleteItem = (itemId) => {
    removeItem.mutate(itemId);
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const onDeleteItemClicked = (data) => {
    setSelectedId(data.itemSubClassId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        addSubClass.isLoading ||
        updateSubClass.isLoading ||
        removeItem.isLoading,
    });
  }, [
    addSubClass.isLoading,
    updateSubClass.isLoading,
    removeItem.isLoading,
    appDispatch,
  ]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <p>Item Sub-Classes</p>

          <div>
            <Link to={AppUrls.new_item_sub_class} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Item Sub-Class
            </Link>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row h-100">
            <div className=" grid-container">
              <div className="grid">
                <AgGrid
                  columns={columns}
                  data={items?.data}
                  filters={filter}
                  setrowClicked={onRowDoubleClicked}
                  autoSize={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Shelve"
        message="Are you sure you want to delete the item?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => handleDeleteItem(selectedId)}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
    </>
  );
};

export default ItemSubClasses;
