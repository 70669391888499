import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import {
  PermissionModel,
  UserAccountModel,
  UserRole,
  UserRoleMember,
  UserTypeModel,
} from "./UserManagement.models";

const userUrl = "users";
const userTypeUrl = "users/user-types";
const userRolesUrl = "roles";
const userPermissionsUrl = "roles/permissions";
const userComponentsUrl = "roles/components";

/**
 * Get all user types
 * @returns
 */
export const getUserTypes = async (params): Promise<UserTypeModel[]> => {
  let result;
  if (params) {
    const queryParams =
      params.queryKey && params.queryKey[1] && params.queryKey[1].params
        ? params.queryKey[1].params
        : "";
    result = await api.get<UserTypeModel[]>(
      `${userTypeUrl}${queryParams ? `?${queryParams}` : ""}`
    );
  } else {
    result = await api.get<UserTypeModel[]>(`${userTypeUrl}`);
  }
  return result.data;
};

/**
 * Create a new user type
 * @param data
 * @returns
 */
export const createUserType = async (data: UserTypeModel) => {
  return await api.post(userTypeUrl, data);
};
/**
 * Edit a user type
 * @param data
 * @returns
 */
export const editUserType = async (
  data: UserTypeModel
): Promise<UserTypeModel> => {
  const result = await api.put<UserTypeModel>(
    `${userTypeUrl}/${data.userTypeId}`,
    data
  );
  return result.data;
};

/**
 * Get all user roles
 * @returns
 */
export const getUserRoles = async (): Promise<QueryResult<UserRole>> => {
  const result = await api.get<QueryResult<UserRole>>(userRolesUrl);
  return result.data;
};
/**
 * Get user role permissions
 * @returns
 */
export const getUserPermissions = async (): Promise<PermissionModel[]> => {
  const result = await api.get<PermissionModel[]>(userPermissionsUrl);
  return result.data;
};

/**
 * Get user role permissions by ID
 * @param id
 * @returns
 */
export const getUserPermissionsById = async (id) => {
  const result = await api.get(`users/permissions/${id}`);
  return result.data;
};

/**
 * Gets role members by role id
 * @param id
 * @returns
 */
export const getRoleMembers = async (id: number): Promise<UserRoleMember[]> => {
  const result = await api.get<UserRoleMember[]>(
    `${userRolesUrl}/${id}/members`
  );
  return result.data;
};
/**
 * Creates a new user role
 * @param data
 * @returns
 */
export const createUserRole = async (data: UserRole): Promise<UserRole> => {
  const result = await api.post<UserRole>(userRolesUrl, data);
  return result.data;
};
/**
 * Updates a user role
 * @param data
 * @returns
 */
export const editUserRole = async (data: UserRole): Promise<UserRole> => {
  const result = await api.put<UserRole>(
    `${userRolesUrl}/${data.roleId}`,
    data
  );
  return result.data;
};

export const getUserRoleById = async (id: number): Promise<UserRole> => {
  const result = await api.get(`${userRolesUrl}/${id}`);
  return result.data;
};

export const deleteUserRole = async (id: number): Promise<boolean> => {
  const result = await api.delete<boolean>(`${userRolesUrl}/${id}`);
  return result.data;
};

/**
 * Get all user accounts
 * @returns
 */
export const getUserAccounts = async (params) => {
  const result = await api.get(
    `${userUrl}${
      params &&
      params?.queryKey[1]?.params &&
      params?.queryKey[1]?.params !== ""
        ? `?${params?.queryKey[1]?.params}`
        : ""
    }`
  );
  return result.data;
};
/**
 * Create a new user account-
 * @param data
 * @returns
 */
export const createUserAccount = async (data): Promise<UserAccountModel> => {
  const result = await api.post<UserAccountModel>(userUrl, data);
  return result.data;
};
/**
 * Get user account info by id
 * @param id
 * @returns
 */
export const getUserAccountById = async (
  id: number
): Promise<UserAccountModel> => {
  const result = await api.get<UserAccountModel>(`${userUrl}/${id}`);
  return result.data;
};
/**
 * Edit user account info
 * @param data
 * @returns
 */
export const editUserAccount = async (data: UserAccountModel): Promise<any> => {
  const result = await api.put<any>(`${userUrl}/${data.userId}`, data);
  return result.data;
};

/**
 * Delete user account
 * @param accountId
 * @returns true if user deleted successfully
 * */
export const deleteUserAccount = async (
  accountId: number
): Promise<boolean> => {
  const result = await api.delete(`${userUrl}/${accountId}`);
  return result.data;
};

// Components

export const getUserComponents = async () => {
  return await api.get(userComponentsUrl);
};

/**
 * Edit user hubs
 * @param data
 * @returns
 */
export const setHubsForUser = async (data: {
  hubs: number[];
  accountId: number;
}) => {
  const response = await api.put(`${userUrl}`, data);
  return response.data;
};
