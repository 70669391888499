import { useEffect, useState } from "react";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import { useHistory, useParams } from "react-router-dom";
import AG from "elements/AG";
import { Tabs, Tab } from "react-bootstrap";
import { getSalesOrder } from "./SalesOrderServices/SalesOrderServices";
import { useMutation, useQuery } from "react-query";
import { statusEnum } from "../StatusEnum";
import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";

import exportIcon from "img/export.svg";
import { getBaseCurrency } from "components/currency";

const ViewSalesOrder = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");

  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  const history = useHistory();
  const { id } = useParams();
  const [expenses, setExpenses] = useState<any>(null);

  const onBackClick = () => {
    history.push(AppUrls.sales_order_list);
  };

  const getOrderDetails = useMutation(getSalesOrder, {
    async onSuccess(response) {
      const order = response.data;
      setOrderDetails(order);
      setExpenses(order.salesOrderExpenses);
    },
  });

  useEffect(() => {
    if (id) getOrderDetails.mutate(id);
  }, [id]);

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item",
    },
    {
      field: "description",
      headerName: "Item Description",
    },
    {
      field: "quantity",
      headerName: "Order QTY",
    },
    {
      field: "shipQuantity",
      headerName: "Ship QTY",
    },
    {
      field: "taxable",
      headerName: "Taxable",
      cellRendererFramework: (params) => (params.value == true ? "Y" : "N"),
    },
    {
      field: "price",
      headerName: "Price",
      valueGetter: (params) => {
        return `${currencySymbol} ${params.data?.price?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      cellRendererFramework: (params) => {
        return `${currencySymbol}  ${(params.data.quantity * params.data.price).toFixed(2)}`;
      },
    },
    {
      field: "hubKey",
      headerName: "Hub Key",
    },
    {
      field: "warrantyDays",
      headerName: "Warranty",
    },
    {
      field: "Non Com",
      headerName: "nonCom",
    },
  ];

  const serialsColumns = [
    {
      field: "serialNo",
      headerName: "Serial Number",
    },
    {
      field: "item",
      headerName: "Item Number",
    },
    {
      field: "itemDesc",
      headerName: "Item Description",
    },
    {
      field: "scanDate",
      headerName: "Scan Date",
    },
  ];

  const [serialsItems, setSerialsItems] = useState<any>(null);

  const expenseColumns = [
    {
      field: "expenseItemId",
      headerName: "Expense Item ID",
    },
    {
      field: "expenseName",
      headerName: "Expense Name",
    },
    {
      field: "amount",
      headerName: "Amount",
      valueGetter: (params) => {
        return `${currencySymbol} ${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  useEffect(() => {
    let array = [];
    if (orderDetails) {
      orderDetails?.itemOrders?.forEach((element) => {
        if (element?.serials?.length > 0) {
          element.serials.forEach((s) => {
            array.push({
              serialNo: s.serialNo,
              scanDate: dateFormmaterNoTime(s.scanDate),
              item: element.itemNumber,
              itemDesc: element.description,
            });
          });
        }
      });
      setSerialsItems(array);
    }
  }, [orderDetails]);

  const editSalesOrder = () => {
    history.push({
      pathname: AppUrls.change_sales_order,
      state: {
        soId: id,
        allowVoid: statusEnum[orderDetails["status"]] !== "Fulfilled",
      },
    });
  };

  const goToFulFill = () => {
    history.push({
      pathname: AppUrls.fulfill_sales_order,
      state: { soId: id },
    });
  };

  const reportClicked = () => {
    history.push(`/sales-order/sales-orders/${id}/report`);
  };

  const reportClickedSlip = () => {
    history.push(`/sales-order/sales-orders/${id}/packing-slip/report`);
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          {orderDetails && orderDetails["orderNumber"]}
        </div>
        <div>
          <button className="import-btn" type="button" onClick={reportClicked}>
            <img src={exportIcon} className="me-2" alt="" />
            View Report
          </button>
          <button
            className="import-btn"
            type="button"
            onClick={reportClickedSlip}
          >
            <img src={exportIcon} className="me-2" alt="" />
            View SO Slip Report
          </button>
          {orderDetails &&
            (statusEnum[orderDetails["status"]] === "Open" ||
              statusEnum[orderDetails["status"]] === "Fulfilled" ||
              statusEnum[orderDetails["status"]] === "Shipped") && (
              <button className="btn btn-primary" onClick={editSalesOrder}>
                Edit
              </button>
            )}
          {orderDetails &&
            (statusEnum[orderDetails["status"]] === "Open" ||
              statusEnum[orderDetails["status"]] === "Partially Fulfilled") && (
              <button className="btn btn-primary" onClick={goToFulFill}>
                FulFill Sales Order
              </button>
            )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="row  gx-5">
            <div className="col-lg-8 col-sm-12">
              <div className="row gx-5 gy-4">
                <div className="col-lg-6 col-sm-12">
                  <label>Order No.</label>
                  <div>{orderDetails && orderDetails["orderNumber"]}</div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <label>Order Date</label>
                  <div>
                    {orderDetails &&
                      new Date(orderDetails["orderDate"]).toLocaleString()}
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Account</label>
                  <div>{orderDetails && orderDetails["accountNumber"]}</div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Hub ID</label>
                  <div>{orderDetails && orderDetails["hubKey"]}</div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Origin</label>
                  <div>{orderDetails?.origin === 1 ? "System" : "Web"}</div>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <label>Status</label>
                  <div>
                    {orderDetails && statusEnum[orderDetails["status"]]}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <label>Company</label>
                  <div className="fw-bold mt-3">
                    {orderDetails && orderDetails["billingInfo"]["company"]}
                  </div>
                  <div className="mt-3">
                    {orderDetails &&
                    orderDetails.billingInfo?.contact &&
                    orderDetails.billingInfo?.contact.length > 0 ? (
                      orderDetails.billingInfo?.contact
                    ) : (
                      <span className="text-muted fst-italic">Contact</span>
                    )}
                    <br />
                    {orderDetails &&
                    orderDetails.billingInfo?.address &&
                    orderDetails.billingInfo?.address.length > 0 ? (
                      orderDetails.billingInfo?.address
                    ) : (
                      <span className="text-muted fst-italic">Address</span>
                    )}
                    <br />
                    {orderDetails &&
                    orderDetails.billingInfo?.city &&
                    orderDetails.billingInfo?.city.length > 0 ? (
                      orderDetails.billingInfo?.city
                    ) : (
                      <span className="text-muted fst-italic">City</span>
                    )}{" "}
                    {orderDetails &&
                    orderDetails.billingInfo?.state &&
                    orderDetails.billingInfo?.state.length > 0 ? (
                      orderDetails.billingInfo?.state
                    ) : (
                      <span className="text-muted fst-italic">State</span>
                    )}{" "}
                    {orderDetails &&
                    orderDetails.billingInfo?.zipCode &&
                    orderDetails.billingInfo?.zipCode.length > 0 ? (
                      orderDetails.billingInfo?.zipCode
                    ) : (
                      <span className="text-muted fst-italic">Zip Code</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 mb-3">
                  <label>Ship To</label>
                  <div className="fw-bold mt-3">
                    {orderDetails && orderDetails.shippingInfo?.company}
                  </div>
                  <div className="mt-3">
                    {orderDetails &&
                    orderDetails.shippingInfo?.contact &&
                    orderDetails.shippingInfo?.contact.length > 0 ? (
                      orderDetails.shippingInfo?.contact
                    ) : (
                      <span className="text-muted fst-italic">Contact</span>
                    )}
                    <br />
                    {orderDetails &&
                    orderDetails.shippingInfo?.address &&
                    orderDetails.shippingInfo?.address.length > 0 ? (
                      orderDetails.shippingInfo?.address
                    ) : (
                      <span className="text-muted fst-italic">Address</span>
                    )}
                    <br />
                    {orderDetails &&
                    orderDetails.shippingInfo?.city &&
                    orderDetails.shippingInfo?.city.length > 0 ? (
                      orderDetails.shippingInfo?.city
                    ) : (
                      <span className="text-muted fst-italic">City</span>
                    )}{" "}
                    {orderDetails &&
                    orderDetails.shippingInfo?.state &&
                    orderDetails.shippingInfo?.state.length > 0 ? (
                      orderDetails.shippingInfo?.state
                    ) : (
                      <span className="text-muted fst-italic">State</span>
                    )}{" "}
                    {orderDetails &&
                    orderDetails.shippingInfo?.zipCode &&
                    orderDetails.shippingInfo?.zipCode.length > 0 ? (
                      orderDetails.shippingInfo?.zipCode
                    ) : (
                      <span className="text-muted fst-italic">Zip Code</span>
                    )}
                  </div>
                </div>
                <hr />
                <div className="col-lg-6 col-sm-12 my-2">
                  <label>Ship Via</label>
                  <div>
                    {orderDetails && orderDetails["shippingMethod"] ? (
                      orderDetails["shippingMethod"]["name"]
                    ) : (
                      <span className="text-muted fst-italic">Ship via</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 my-2">
                  <label>Ship by</label>
                  <div>
                    {orderDetails && orderDetails["representativeName"] ? (
                      orderDetails["representativeName"]
                    ) : (
                      <span className="text-muted fst-italic">Ship by</span>
                    )}
                  </div>
                </div>
                {orderDetails?.currency && (
                  <div className="col-lg-6 col-sm-12 my-2">
                    <label>Currency</label>
                    <div>
                      {orderDetails && orderDetails["currency"] ? (
                        `${orderDetails?.currency?.name} ${orderDetails?.currency?.symbol}`
                      ) : (
                        <span className="text-muted fst-italic">Currency</span>
                      )}
                    </div>
                  </div>
                )}
                {orderDetails?.currency && (
                  <div className="col-lg-6 col-sm-12 my-2">
                    <label>Exchange Rate</label>
                    <div>
                      {orderDetails && orderDetails["rate"] ? (
                        orderDetails?.rate
                      ) : (
                        <span className="text-muted fst-italic">
                          Exchange Rate
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <hr />
                <div className="col-lg-6 col-sm-12 mt-0">
                  <label>Cust Po</label>
                  <div>
                    {orderDetails && orderDetails["customerPO"] ? (
                      orderDetails["customerPO"]
                    ) : (
                      <span className="text-muted fst-italic">Cust PO</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 mt-0">
                  <label>Project No.</label>
                  <div>
                    {orderDetails && orderDetails["projectNumber"] ? (
                      orderDetails["projectNumber"]
                    ) : (
                      <span className="text-muted fst-italic">Project No.</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="row mt-4">
                <div className="col-lg-6 col-sm-12 px-4">
                  <div className="d-flex">
                    <div className="text-secondary">TOTAL QTY</div>
                    <div className="flex-grow-1 text-center">
                      {orderDetails && orderDetails["totalItems"]}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex">
                    <div className="text-secondary">TOTAL ITEMS</div>
                    <div className="flex-grow-1 text-center">
                      {orderDetails && orderDetails["itemsQuantity"]}
                    </div>
                  </div>
                </div>
                <div className="px-4">
                  <hr />
                </div>
                <div className="col-sm-12 d-flex justify-content-between px-4">
                  <div className="text-secondary">SUBTOTAL</div>
                  <div>
                    {formatNumber(
                      orderDetails &&
                        (
                          orderDetails["subTotal"] * orderDetails["rate"]
                        ).toFixed(2),
                      baseCurrency &&
                        getCurrencyByCode(
                          orderDetails?.currencyCode
                            ? orderDetails.currencyCode
                            : baseCurrency
                        ).code
                    )}
                  </div>
                </div>
                <div className="col-sm-12 d-flex justify-content-between px-4">
                  <div className="text-secondary">TAX AMOUNT</div>
                  <div>
                    {formatNumber(
                      orderDetails &&
                        (
                          orderDetails["taxTotal"] * orderDetails["rate"]
                        ).toFixed(2),
                      baseCurrency &&
                        getCurrencyByCode(
                          orderDetails?.currencyCode
                            ? orderDetails.currencyCode
                            : baseCurrency
                        ).code
                    )}
                  </div>
                </div>
                <div className="col-sm-12 d-flex justify-content-between px-4">
                  <div className="text-secondary">SHIPPING COST</div>
                  <div>
                    {formatNumber(
                      orderDetails &&
                        (
                          orderDetails["shipCost"] * orderDetails["rate"]
                        ).toFixed(2),
                      baseCurrency &&
                        getCurrencyByCode(
                          orderDetails?.currencyCode
                            ? orderDetails.currencyCode
                            : baseCurrency
                        ).code
                    )}
                  </div>
                </div>
                <div className="col-sm-12 d-flex justify-content-between px-4">
                  <div className="text-secondary">DISCOUNT</div>
                  <div>
                    {formatNumber(
                      orderDetails &&
                        (
                          orderDetails["discount"] * orderDetails["rate"]
                        ).toFixed(2),
                      baseCurrency &&
                        getCurrencyByCode(
                          orderDetails?.currencyCode
                            ? orderDetails.currencyCode
                            : baseCurrency
                        ).code
                    )}
                  </div>
                </div>
                <div className="col-sm-12 d-flex justify-content-between px-4">
                  <div className="text-secondary">EXPENSE TOTAL</div>
                  <div>
                    {formatNumber(
                      orderDetails?.salesOrderExpenses.reduce(
                        (total, expense) =>
                          (total + expense.amount) * orderDetails?.rate,
                        0
                      ),
                      baseCurrency &&
                        getCurrencyByCode(
                          orderDetails?.currencyCode
                            ? orderDetails.currencyCode
                            : baseCurrency
                        ).code
                    )}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="section mt-3 p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="fs-5">ORDER TOTAL</div>
                      <div className="text-success fs-4">
                        {formatNumber(
                          orderDetails?.total * orderDetails?.rate,
                          baseCurrency &&
                            getCurrencyByCode(
                              orderDetails?.currencyCode
                                ? orderDetails.currencyCode
                                : baseCurrency
                            ).code
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section p-4 pt-0">
                <div className="row gx-5 gy-4">
                  <div className="col-lg-12 col-sm-12">
                    <label>Date Entered</label>
                    <div>
                      {orderDetails &&
                        dateFormmaterNoTime(orderDetails["orderDate"])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section p-4 pt-0">
                <div className="row gx-5 gy-4 pt-4">
                  <label>Order Remark</label>
                  <div>
                    {(orderDetails && orderDetails["remarks"]) ?? (
                      <span className="text-muted fst-italic">
                        Order Remark
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tabs defaultActiveKey={"Detail"} className="mt-5">
            <Tab
              eventKey="Detail"
              title={<div className="tab-inner-title">Sales Order Details</div>}
            >
              <div className="h-450">
                <AG
                  data={orderDetails && orderDetails["itemOrders"]}
                  columns={columns}
                />
              </div>
            </Tab>
            <Tab
              eventKey="serials"
              title={<div className="tab-inner-title">Serials</div>}
            >
              <div className="h-450">
                <AG data={serialsItems} columns={serialsColumns} />
              </div>
            </Tab>
            <Tab
              eventKey="expenses"
              title={<div className="tab-inner-title">Expenses</div>}
            >
              <div className="h-450">
                <AG data={expenses} columns={expenseColumns} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ViewSalesOrder;
