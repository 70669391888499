import { DropDownOption, QueryResult } from "components/Common.models";
import ListItem from "components/SelectionListModal/ListSelection.models";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import { ErrorResponse } from "components/data/Errors.model";
import { states } from "components/data/States";
import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import SearchIcon from "img/search-group.png";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
  UserAccountModel,
  UserRole,
  UserTypeModel,
} from "../UserManagement.models";
import {
  createUserAccount,
  deleteUserAccount,
  editUserAccount,
  getUserAccountById,
  getUserRoles,
  getUserTypes,
} from "../UserManagement.services";
// import Permissions from "../userRoleSetup/Permissions";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { getCompanies } from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import { getUserProfile } from "pages/User/userServices";
import CountrySelect from "components/CountrySelect/CountrySelect";

const EmployeeDetails = ({ match }) => {
  const [showRoleSelection, setShowRoleSelection] = useState<boolean>(false);
  const [showHubsSelection, setShowHubsSelection] = useState<boolean>(false);
  const [showCompaniesSelection, setShowCompaniesSelection] =
    useState<boolean>(false);
  const [userRolesList, setUserRolesList] = useState<ListItem[]>([]);
  const [hubsList, setHubsList] = useState<ListItem[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [selectedHubs, setSelectedHubs] = useState<number[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [userTypeOptions, setUserTypeOptions] = useState<DropDownOption[]>([]);
  const [userData, setUserData] = useState<UserAccountModel>(null);
  const [activeTab, setActiveTab] = useState<string>("EmployeeInfo");
  const [checked, setChecked] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [allPermissions, setAllPermissions] = useState([])
  // const [permissionsListing, setPermissionsListing] = useState([]);
  // const [exceptList, setExceptList] = useState([]);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const history = useHistory();
  const { id } = useParams();
  const { data: userProfile } = useQuery("userProfile", getUserProfile);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
  } = useForm<UserAccountModel>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: true },
  });

  const {
    isSuccess: isSuccessUser,
    isLoading: isLoadingUser,
    data: getUserData,
  } = useQuery<UserAccountModel, ErrorResponse>(
    [id],
    () => getUserAccountById(id),
    { enabled: id ? true : false }
  );
  const {
    isSuccess: isSuccessUserTypes,
    isLoading: isLoadingUserTypes,
    data: userTypes,
  } = useQuery<UserTypeModel[], ErrorResponse>(["get_user_types"], getUserTypes);
  const {
    isSuccess: isSuccessRoles,
    isLoading: isLoadingRoles,
    data: rolesData,
  } = useQuery<QueryResult<UserRole>, ErrorResponse>("get_roles", getUserRoles);
  const {
    isSuccess: isSuccessHubs,
    isLoading: isLoadingHubs,
    data: hubsData,
  } = useQuery<any, ErrorResponse>("get_hubs", getInventoryHubsList);
  const { data: companies } = useQuery("companiesList", getCompanies, {
    select(data) {
      return data?.data.map((company) => ({
        id: company.companyId,
        title: company.name,
      }));
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        isLoadingUser || isLoadingRoles || isLoadingHubs || isLoadingUserTypes,
    });
  }, [
    isLoadingUser,
    isLoadingRoles,
    isLoadingHubs,
    isLoadingUserTypes,
    appDispatch,
  ]);

  useEffect(() => {
    if (isSuccessUser && userTypeOptions.length > 0) {
      setUserData(getUserData);
      // let exceptListFromAPI = []
      // getUserData?.permissionExceptions?.forEach(i => {
      //     exceptListFromAPI.push({ userId: i.userId, componentActionId: i.componentActionId, isGranted: i.isGranted })
      // })
      // let checkedPermissions = []
      // for (let permission of exceptListFromAPI) {
      //     if (permission.isGranted || !permission.isGranted) {
      //         if (checkedPermissions.indexOf(permission.componentActionId.toString()) === -1)
      //             checkedPermissions.push(permission.componentActionId.toString())
      //     }
      // }
      // setChecked(checkedPermissions)
      // setExceptList(exceptListFromAPI)
      setSelectedRoles(getUserData.roleIds);
      setSelectedHubs(getUserData.hubIds);
      setSelectedCompanies(getUserData.companies);
      reset(getUserData);
    }
  }, [isSuccessUser, getUserData, userTypeOptions, reset]);

  useEffect(() => {
    if (isSuccessUserTypes) {
      let activeUserTypes = userTypes?.filter((ut) => ut.isActive === true);
      let ut_options: DropDownOption[] = activeUserTypes
        ?.filter((t) => t.isActive)
        ?.map((ut) => {
          return { label: ut.name, value: ut.userTypeId };
        });
      setUserTypeOptions(ut_options);
    }
  }, [isSuccessUserTypes, userTypes]);

  useEffect(() => {
    if (isSuccessRoles && rolesData) {
      setUserRolesList(
        rolesData?.data
          ?.filter((t) => t.isActive)
          ?.map((item) => {
            return { id: item.roleId, title: item.roleKey };
          })
      );
    }
  }, [isSuccessRoles, rolesData]);

  useEffect(() => {
    if (isSuccessHubs && hubsData) {
      setHubsList(
        hubsData?.data?.map((item) => {
          return { id: item.locationId, title: item.hubId };
        })
      );
    }
  }, [isSuccessHubs, hubsData]);

  useEffect(() => {
    if (id) {
      setIsDisabled(true);
    }
  }, [id]);

  const addUser = useMutation(createUserAccount, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `User ${CREATE_SUCCESS_MSG}. An email will be sent containing the user id and the password.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`${AppUrls.employees}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding user"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateUser = useMutation(editUserAccount, {
    async onSuccess(result) {
      let notification = {
        variant: "success",
        msg: `User ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsDisabled(true);
      setUserData(result);
      reset(result);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem editing user"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const deleteUser = useMutation("deleteUserAccount", deleteUserAccount, {
    onSuccess: () => {
      let notification = {
        variant: "success",
        msg: `User deleted successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(`${AppUrls.employees}`);
    },

    onError: (error) => {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem deleting user"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const onBackClick = () => {
    if (isDirty) setOpenConfirmModal(true);
    else history.push(AppUrls.employees);
  };

  const cancelForm = () => {
    reset();
    if (id) {
      setIsDisabled(true);
      setSelectedRoles(userData.roleIds);
      setSelectedHubs(userData.hubIds);
      setChecked([]);
      // let exceptListFromAPI = []
      // getUserData?.permissionExceptions?.forEach(i => {
      //     exceptListFromAPI.push({ userId: i.userId, componentActionId: i.componentActionId, isGranted: i.isGranted })
      // })
      // setExceptList(exceptListFromAPI)
      // setPermissionsListing([])
    } else {
      setSelectedRoles([]);
      setSelectedHubs([]);
    }
  };

  const onEditClicked = () => {
    setIsDisabled(false);
  };
  const onSubmit = (values: UserAccountModel) => {
    const dlNumber = values.dlNumber ? values.dlNumber : null;
    if (id) {
      // let exceptArray = []
      // exceptList?.forEach(i => {
      //     exceptArray.push({
      //         userId: id,
      //         componentActionId: i.componentActionId,
      //         isGranted: i.isGranted
      //     })
      // })
      updateUser.mutate({ ...values, dlNumber: dlNumber, userId: id });
    } else {
      addUser.mutate({
        ...values,
        dlNumber: dlNumber,
        companies: [userProfile?.data?.currentCompanyId],
      });
    }
  };

  const onRolesSelection = (items: ListItem[]) => {
    const selectedRoles = items.map((i) => i.id);
    setValue("roleIds", selectedRoles, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSelectedRoles(selectedRoles);
    setShowRoleSelection(false);
  };

  const onHubsSelection = (items: ListItem[]) => {
    const selectedHubs = items.map((i) => i.id);
    setValue("hubIds", selectedHubs, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSelectedHubs(selectedHubs);
    setShowHubsSelection(false);
  };

  const onCompaniesSelection = (items: ListItem[]) => {
    const selecedCompanies = items.map((i) => i.id);
    setValue("companies", selecedCompanies, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setSelectedCompanies(selecedCompanies);
    setShowCompaniesSelection(false);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingUser || updateUser.isLoading,
    });
  }, [isLoadingUser, updateUser.isLoading, appDispatch]);

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
    if (countryCode !== "US") {
      setValue("state", null);
    }
  };
  // const {
  //     data: userPermissions,
  //     isSuccess: isSuccessPermissions
  // } = useQuery("userPermissionsList", getUserPermissions);

  // useEffect(() => {
  //     if (isSuccessPermissions) {
  //         let flatPermissions = []
  //         const changePermmissionsLabels = (permissions) => {
  //             if (permissions && permissions.length > 0)
  //                 for (let permission of permissions) {
  //                     permission.value = permission.componentId
  //                     permission.label = permission.name
  //                     if (permission.subPermissions.length > 0) {
  //                         permission.children = permission.subPermissions
  //                         changePermmissionsLabels(permission.children)
  //                     }
  //                     flatPermissions.push(permission)
  //                 }
  //         }
  //         let modifiedPermissions = userPermissions
  //         changePermmissionsLabels(modifiedPermissions)
  //         setAllPermissions(modifiedPermissions)
  //         setPermissionsListing(flatPermissions)
  //     }
  // }, [isSuccessPermissions])

  // useEffect(() => {
  //     //@ts-ignore
  //     // setValue("exceptFlag", 1, {shouldDirty: true})
  // }, [exceptList])

  return (
    <>
      <ConfirmationModal
        title="Delete Employee"
        message="Are you sure you want to delete this employee?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => deleteUser.mutate(Number(id))}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input name="exceptFlag" type="hidden" />
        <div className="page-title page-title-editable">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {isDisabled
              ? getValues("employeeId")
              : id
              ? `Edit User: ${getValues("employeeId")}`
              : "Create Employee"}
          </div>
          <div className="d-flex justify-content-between ">
            {!!id &&
              checkIfAllowed(appState.allowedUrls, "delete", match.path) && (
                <button
                  type="button"
                  className="btn btn-danger ms-0"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </button>
              )}
            {isDisabled ? (
              checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onEditClicked}
                >
                  Edit
                </button>
              )
            ) : (
              <>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border ${
                    !isDirty && !id && "text-muted"
                  }`}
                  disabled={!isDirty && !id}
                  onClick={cancelForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!isDirty}
                >
                  {id ? "Save Changes" : "Create"}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e)}>
              <Tab
                eventKey="EmployeeInfo"
                title={<div className="tab-inner-title">Employee Info</div>}
              >
                <div className="row gx-5 employee-details">
                  <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="row  ">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            Employee Id{" "}
                            {!id && <span className="text-danger">*</span>}
                          </label>
                          {id ? (
                            <p>{getUserData?.employeeId}</p>
                          ) : (
                            <input
                              {...register("employeeId", { required: true })}
                              name="employeeId"
                              type="text"
                              className={`form-control ${
                                errors.employeeId && "required_field"
                              }`}
                              disabled={isDisabled}
                            />
                          )}
                          {errors["employeeId"] && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            Role Id <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="roleIds"
                            rules={{ required: true }}
                            render={({ field: { value } }) => (
                              <InputGroup>
                                <input
                                  className="form-control d-none"
                                  name="roleId"
                                />
                                <div
                                  className={`selected-container ${
                                    isDisabled && "disabled"
                                  } ${errors.roleIds && "required_field"}`}
                                >
                                  {userRolesList
                                    .filter((role) => value?.includes(role.id))
                                    .map((role) => {
                                      return (
                                        <span
                                          key={role.id}
                                          className="selected-item"
                                        >
                                          {role.title}
                                        </span>
                                      );
                                    })}
                                </div>
                                <Button
                                  variant="primary"
                                  disabled={isDisabled}
                                  onClick={() => setShowRoleSelection(true)}
                                  className="input-group-prepend"
                                >
                                  <img src={SearchIcon} alt="" />
                                </Button>
                              </InputGroup>
                            )}
                          />
                          {errors["roleIds"] && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            Employee Type <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="userTypeId"
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={userTypeOptions}
                                className={
                                  errors.userTypeId && " required_field"
                                }
                                isSearchable={true}
                                value={
                                  userTypeOptions?.find(
                                    (opt) => opt?.value === value
                                  ) || null
                                }
                                onChange={(selectedOption: any) =>
                                  onChange(selectedOption?.value)
                                }
                                isDisabled={isDisabled}
                              />
                            )}
                          />
                          {errors.userTypeId && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            Hub Id <span className="text-danger">*</span>
                          </label>
                          <Controller
                            control={control}
                            name="hubIds"
                            rules={{ required: true }}
                            render={({ field: { value } }) => (
                              <InputGroup>
                                <input
                                  className="form-control d-none"
                                  name="hubId"
                                />
                                <div
                                  className={`selected-container ${
                                    isDisabled && "disabled"
                                  } ${errors.hubIds && "required_field"}`}
                                >
                                  {hubsList
                                    .filter((hub) => value?.includes(hub.id))
                                    .map((hub) => {
                                      return (
                                        <span
                                          key={hub.id}
                                          className="selected-item"
                                        >
                                          {hub.title}
                                        </span>
                                      );
                                    })}
                                </div>
                                <Button
                                  variant="primary"
                                  disabled={isDisabled}
                                  onClick={() => {
                                    setShowHubsSelection(true);
                                  }}
                                  className="input-group-prepend"
                                >
                                  <img src={SearchIcon} alt="" />
                                </Button>
                              </InputGroup>
                            )}
                          />
                          {errors.hubIds && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                          {isDisabled ? (
                            getValues("isActive") ? (
                              <div className="active-status">active</div>
                            ) : (
                              <div className="disabled-status">disabled</div>
                            )
                          ) : (
                            <div className="form-group mt-2">
                              <Controller
                                control={control}
                                name="isActive"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    {value ? (
                                      <label className=" m-2 row ">
                                        Employee Status : Active
                                      </label>
                                    ) : (
                                      <label className=" m-2 row">
                                        Employee Status : Disabled
                                      </label>
                                    )}
                                    <label
                                      className="switch"
                                      htmlFor="activeCheckbox"
                                    >
                                      <input
                                        type="checkbox"
                                        id="activeCheckbox"
                                        name="isActive"
                                        onChange={() => onChange(!value)}
                                        checked={value}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {/*   <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Companies</label>
                          <Controller
                            control={control}
                            name="companies"
                            render={({ field: { value } }) => (
                              <InputGroup>
                                <input
                                  className="form-control d-none"
                                  name="company"
                                />
                                <div
                                  className={`selected-container ${
                                    isDisabled && "disabled"
                                  }`}
                                >
                                  {companies
                                    ?.filter((company) =>
                                      value?.includes(company.id)
                                    )
                                    .map((company) => {
                                      return (
                                        <span
                                          key={company.id}
                                          className="selected-item"
                                        >
                                          {company.title}
                                        </span>
                                      );
                                    })}
                                </div>
                                <Button
                                  variant="primary"
                                  disabled={isDisabled}
                                  onClick={() => {
                                    setShowCompaniesSelection(true);
                                  }}
                                  className="input-group-prepend"
                                >
                                  <img src={SearchIcon} alt="" />
                                </Button>
                              </InputGroup>
                            )}
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            name="firstName"
                            type="text"
                            {...register("firstName", { required: true })}
                            className={`form-control ${
                              errors.firstName && "required_field"
                            }`}
                            disabled={isDisabled}
                          />
                          {errors["firstName"] && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Middle Name </label>
                          <input
                            name="middleName"
                            type="text"
                            className="form-control middle-name"
                            {...register("middleName")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            name="lastName"
                            type="text"
                            className={`form-control ${
                              errors.lastName && "required_field"
                            }`}
                            {...register("lastName", { required: true })}
                            disabled={isDisabled}
                          />
                          {errors["lastName"] && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Date of Birth</label> <br />
                          <Controller
                            control={control}
                            name="dateOfBirth"
                            render={({ field }) => (
                              <DatePicker
                                style={{ width: "300px" }}
                                onChange={(val) => field.onChange(val)}
                                selected={field.value}
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={35}
                                scrollableYearDropdown
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <hr className="my-5" />
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>
                            Billing Rate (/hour){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            name="billing"
                            type="number"
                            className="form-control"
                            {...register("billingRate", { required: true })}
                            disabled={isDisabled}
                            min={0}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="label mb-2">Payment Method</label>
                          <div className="form-group mt-2">
                            <Controller
                              control={control}
                              name="employeePaymentMethod"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <label className="checkbox-inline">
                                    <input
                                      id="checkYes"
                                      type="radio"
                                      disabled={isDisabled}
                                      onChange={() => onChange(1)}
                                      checked={value === 1}
                                    />
                                    <label
                                      htmlFor="checkYes"
                                      className="text-black ms-2"
                                    >
                                      Check
                                    </label>
                                  </label>
                                  <label className="checkbox-inline ms-3">
                                    <input
                                      id="checkNo"
                                      type="radio"
                                      disabled={isDisabled}
                                      onChange={() => onChange(2)}
                                      checked={value === 2}
                                    />
                                    <label
                                      htmlFor="checkNo"
                                      className="text-black ms-2"
                                    >
                                      Direct Deposit
                                    </label>
                                  </label>
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label className="label mb-2">Gender</label>
                          <div className="form-group mt-2">
                            <Controller
                              control={control}
                              name="gender"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <label className="checkbox-inline">
                                    <input
                                      id="genderYes"
                                      name="gender"
                                      type="radio"
                                      disabled={isDisabled}
                                      onChange={() => onChange(1)}
                                      checked={value === 1}
                                    />
                                    <label
                                      htmlFor="genderYes"
                                      className="text-black ms-2"
                                    >
                                      Male
                                    </label>
                                  </label>
                                  <label className="checkbox-inline ms-3">
                                    <input
                                      id="genderNo"
                                      name="gender"
                                      type="radio"
                                      disabled={isDisabled}
                                      onChange={() => onChange(2)}
                                      checked={value === 2}
                                    />
                                    <label
                                      htmlFor="genderNo"
                                      className="text-black ms-2"
                                    >
                                      Female
                                    </label>
                                  </label>
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12"></div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Hire Date</label> <br />
                          <Controller
                            control={control}
                            name="hireDate"
                            render={({ field }) => (
                              <DatePicker
                                onChange={(val) => field.onChange(val)}
                                selected={field.value}
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Release Date</label> <br />
                          <Controller
                            control={control}
                            name="releaseDate"
                            render={({ field }) => (
                              <DatePicker
                                onChange={(val) => field.onChange(val)}
                                selected={field.value}
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Route Number </label>
                          <input
                            name="routeNumber"
                            type="text"
                            className="form-control"
                            {...register("routeNumber")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Account Number </label>
                          <input
                            name="accountNumber"
                            type="text"
                            className="form-control"
                            {...register("accountNumber")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <hr className="my-5" />
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>Last 4 of SSN </label>
                          <input
                            name="ssn"
                            type="text"
                            className="form-control"
                            {...register("lastSSN")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                          <label>DL No.</label>
                          <input
                            name="dlNo"
                            type="number"
                            className="form-control"
                            {...register("dlNumber")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-12 col-sm-12 contact-details">
                    <h4>Contact Info</h4>
                    <div className="row  ">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            name="email"
                            type="email"
                            className={`form-control ${
                              errors.email && "required_field"
                            }`}
                            disabled={id ? true : isDisabled}
                            {...register("email", {
                              required: true,
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message:
                                  "Entered value does not match email format",
                              },
                            })}
                          />
                          {errors["email"] && (
                            <span className="text-danger">
                              {errors.email.message
                                ? errors.email.message
                                : "This field is required"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Cell No.</label>
                          <Controller
                            control={control}
                            name="mobileNumber"
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                value={value}
                                onChange={onChange}
                                country={"us"}
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Home No.</label>
                          <Controller
                            control={control}
                            name="homeNumber"
                            render={({ field: { onChange, value } }) => (
                              <PhoneInput
                                value={value}
                                onChange={onChange}
                                country={"us"}
                                disabled={isDisabled}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row  ">
                      <div className="col-sm-12">
                        <h4>Address</h4>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Address</label>
                          <input
                            name="address"
                            type="text"
                            className="form-control"
                            {...register("address")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Address 1</label>
                          <input
                            name="address1"
                            type="text"
                            className="form-control"
                            {...register("address1")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            name="city"
                            type="text"
                            className="form-control"
                            {...register("city")}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className="form-group">
                          <label>Zip Code</label>
                          <input
                            name="zipCode"
                            type="text"
                            className="form-control"
                            disabled={isDisabled}
                            {...register("zipCode", {
                              pattern: {
                                value: /^\d{5}(?:[-\s]\d{4})?$/,
                                message:
                                  "Entered value does not match zip code format",
                              },
                            })}
                          />
                          {errors["zipCode"] && (
                            <span className="text-danger">
                              {errors.zipCode.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label>Country</label>{" "}
                          <Controller
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              setSelectedCountry(value);
                              return (
                                <CountrySelect
                                  width={248}
                                  isDisabled={isDisabled}
                                  value={value}
                                  onChange={(countryCode) => {
                                    handleCountryChange(countryCode);
                                    onChange(countryCode);
                                  }}
                                />
                              );
                            }}
                            name="country"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-8">
                        <div className="form-group">
                          <label>State</label>
                          <Controller
                            control={control}
                            name="state"
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={states}
                                isSearchable={true}
                                isClearable
                                menuPlacement="auto"
                                isDisabled={
                                  isDisabled || selectedCountry !== "US"
                                }
                                value={
                                  states.find((opt) => opt?.value === value) ||
                                  null
                                }
                                onChange={(opt: any) => onChange(opt?.value)}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              {/* <Tab
                                eventKey="EmployeePermissions"
                                title={<div className="tab-inner-title">Permissions Exceptions</div>}
                                disabled={!id}
                            >
                                <Permissions 
                                    checked={checked}
                                    setChecked={setChecked}
                                    treeOptions={allPermissions}
                                    permissionsListing={permissionsListing}
                                    setPermissionsListing={setPermissionsListing}
                                    disabled={isDisabled}
                                    isException={true}
                                    exceptList={exceptList}
                                    setExceptList={setExceptList}
                                />
                            </Tab> */}
            </Tabs>
          </div>
        </div>
      </form>
      <ListSelectionModal
        showModal={showRoleSelection}
        title="Role ID List"
        data={userRolesList}
        selectedValues={selectedRoles}
        onSelectData={onRolesSelection}
        onExit={() => setShowRoleSelection(false)}
      />
      <ListSelectionModal
        showModal={showHubsSelection}
        title="Hub ID List"
        data={hubsList}
        selectedValues={selectedHubs}
        onSelectData={onHubsSelection}
        onExit={() => setShowHubsSelection(false)}
      />
      <ListSelectionModal
        showModal={showCompaniesSelection}
        title="Companies List"
        data={companies}
        selectedValues={selectedCompanies}
        onSelectData={onCompaniesSelection}
        onExit={() => setShowCompaniesSelection(false)}
      />
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onConfirm={() => {
          setOpenConfirmModal(false);
          history.push(AppUrls.employees);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default EmployeeDetails;
