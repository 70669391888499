import api from "components/data/AxiosClient";

const payrollUrl = "payroll";

export const getCheckInOut = async (params) => {
  let query = "";
  if (params?.userId) query += `&userId=${params.userId}`;
  if (params?.startDate) {
    let sDate = new Date(params.startDate);
    query += `&startDate=${sDate.toISOString()}`;
  }
  if (params?.endDate) {
    let eDate = new Date(params.endDate);
    query += `&endDate=${eDate.toISOString()}`;
  }
  return await api.get(`${payrollUrl}/time-sheet-in-out?${query}`);
};

export const getTimeSheet = async (params) => {
  let query = "";
  if (params?.userId) query += `&userId=${params.userId}`;
  if (params?.startDate) {
    let sDate = new Date(params.startDate);
    query += `&startDate=${sDate.toISOString()}`;
  }
  if (params?.endDate) {
    let eDate = new Date(params.endDate);
    query += `&endDate=${eDate.toISOString()}`;
  }
  return await api.get(`${payrollUrl}/time-sheet?${query}`);
};

export const editTimesheet = async (data) => {
  return await api.put(`${payrollUrl}/time-sheet`, data);
};

export const getTaxSettings = async (params) => {
  return await api.get(
    `${payrollUrl}/tax-settings${
      params && params.queryKey[1]?.params && params.queryKey[1]?.params !== ""
        ? `?${params.queryKey[1].params}`
        : ""
    }`
  );
};

export const postTaxSettings = async (data) => {
  return await api.post(`${payrollUrl}/tax-settings`, data);
};

export const getPayment = async (params) => {
  let query = "";
  if (params?.startDate)
    query += `&startDate=${params.startDate.toISOString()}`;
  if (params?.endDate) query += `&endDate=${params.endDate.toISOString()}`;
  return await api.get(`${payrollUrl}/payment-details?${query}`);
};

export const applyPayment = async (data) => {
  return await api.post(`${payrollUrl}/payroll`, data);
};

export const CheckIn = async (data) => {
  return await api.post(`${payrollUrl}/check-in`, data);
};

export const CheckOut = async (data) => {
  return await api.post(`${payrollUrl}/check-out`, data);
};

export const getPayRollList = async (params) => {
  let query = "";
  if (params?.userId) query += `&userId=${params.userId}`;
  if (params?.startDate)
    query += `&startDate=${params.startDate.toISOString()}`;
  if (params?.endDate) query += `&endDate=${params.endDate.toISOString()}`;
  return await api.get(`${payrollUrl}?${query}`);
};
