import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { getTransactions } from "./AccountingServices";
import { checkIfAllowed, dateFormmaterNoTime } from "global/helpers";
import StateContext from "context/StateContext";
import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import Select from "react-select";

const options = [
  { value: 1, label: "Expense" },
  { value: 2, label: "Invoice" },
  { value: 3, label: "Operation" },
];

enum transactionTypeEnum {
  Expense = 1,
  Invoice = 2,
  Operation = 3,
}

enum TemplateTypeEnum {
  Scheduled = 1,
  Reminder = 2,
}

enum IntervalEnum {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4,
}

const templateOptions = [
  { value: 1, label: "Scheduled" },
  { value: 2, label: "Reminder" },
];

const transactionOptions = [
  { value: 1, label: "Expense" },
  { value: 2, label: "Invoice" },
  { value: 3, label: "Operation" },
];

const RecurrentTransactions = ({ match }) => {
  const history = useHistory();
  const appState = useContext(StateContext);
  const inputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const { data, mutate } = useMutation("rectransactions", getTransactions);

  const columns = [
    {
      field: "templateName",
      headerName: "Template Name",
      resizable: true,
    },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      resizable: true,
      valueGetter: (params) => transactionTypeEnum[params.data.transactionType],
    },
    {
      field: "templateType",
      headerName: "Template Type",
      resizable: true,
      valueGetter: (params) => TemplateTypeEnum[params.data.templateType],
    },
    {
      field: "interval",
      headerName: "Interval",
      resizable: true,
      valueGetter: (params) => IntervalEnum[params.data.interval],
    },
    {
      field: "startDate",
      headerName: "Start Date",
      resizable: true,
      valueGetter: (params) => dateFormmaterNoTime(params.data.startDate),
    },
    {
      field: "nextDate",
      headerName: "Next Date",
      resizable: true,
      valueGetter: (params) => dateFormmaterNoTime(params.data.nextDate),
    },
    {
      field: "ledgerName",
      headerName: "Customer/Supplier",
      resizable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      resizable: true,
      maxWidth: 130,

      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  useEffect(() => {
    mutate("");
  }, []);

  const onTypeChange = (e) => {
    setSelectedType(e.value);
  };

  const onAddTransaction = () => {
    setShowModal(true);
  };

  const selectType = () => {
    if (selectedType) {
      history.push({
        pathname: AppUrls.add_transactions,
        search: `?type=${selectedType}`,
      });
    }
  };

  const displayFilter = () => {
    let params = "?";
    if (inputRef.current.value)
      params += `&templateName=${inputRef.current.value}`;
    if (selectedTemplate) params += `&templateType=${selectedTemplate}`;
    if (selectedTransaction)
      params += `&transactionType=${selectedTransaction}`;

    mutate(params);
  };

  const clearFilters = () => {
    inputRef.current.value = null;
    setSelectedTemplate(null);
    setSelectedTransaction(null);
    mutate("");
  };

  const onTemplateChange = (e) => {
    setSelectedTemplate(e.value);
  };

  const onTransactionChange = (e) => {
    setSelectedTransaction(e.value);
  };

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Recurrent Transactions</p>
          <div>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <button className="btn btn-primary" onClick={onAddTransaction}>
                Add Transaction
              </button>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container has-filters">
            <div className="filters">
              <div className="row">
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Template Name</label>
                    <input
                      type="text"
                      ref={inputRef}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Template Type</label>
                    <Select
                      onChange={onTemplateChange}
                      value={
                        templateOptions?.find(
                          (opt) => opt?.value === selectedTemplate
                        ) || null
                      }
                      options={templateOptions}
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Transaction Type</label>
                    <Select
                      onChange={onTransactionChange}
                      value={
                        transactionOptions?.find(
                          (opt) => opt?.value === selectedTransaction
                        ) || null
                      }
                      options={transactionOptions}
                    />
                  </div>
                </div>
                <div className="col-lg-2 d-flex align-items-end mb-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={displayFilter}
                  >
                    Display
                  </button>
                  <button
                    type="button"
                    onClick={clearFilters}
                    className="btn btn-outline-primary custom-outline"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div className="grid">
              <AgGrid columns={columns} data={data} />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={setShowModal} centered>
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>Select Transaction Type</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className="ms-auto"
            role="button"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
              <div className="form-group">
                <label>
                  Transaction Type <span className="text-danger">*</span>
                </label>
                <Select
                  options={options}
                  isSearchable={true}
                  value={
                    options.find((option) => option.value === selectedType) ||
                    null
                  }
                  onChange={onTypeChange}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ms-auto border-top-0">
          <button
            type="button"
            className="btn btn-primary"
            onClick={selectType}
          >
            Select
          </button>
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RecurrentTransactions;
