import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext, Controller } from "react-hook-form";

import SelectionModal from "components/SelectionModal/SelectionModal";

import {
  vendorsColumns,
  hubsColumns,
} from "components/ItemOrderModal/modalColumns";

import Select from "react-select";
import Lookup from "elements/Lookup";
import { DropDownOption } from "components/Common.models";
import CreatableSelect from "react-select/creatable";
import { useMutation, useQuery } from "react-query";
import {
  getCurrency,
  getExchangeRate,
  getLatestRate,
} from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import { getCurrenciesListFromExchangeRateList } from "global/helpers";

const InvoiceInfo = ({
  init,
  vendorSelected,
  setVendorSelected,
  hubSelected,
  setHubSelected,
  setShowShippingModal,
  hideInfo = false,
}) => {
  const { id } = useParams();

  const [openSelectionVendor, setOpenSelectionVendor] =
    useState<boolean>(false);

  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);

  const creditTermItems: DropDownOption[] = init?.creditTerms?.map((c) => {
    const opt: DropDownOption = {
      label: `${c.term}: ${c.description}`,
      value: c.creditTermId,
    };
    return opt;
  });

  const shippingMethods: DropDownOption[] = init?.shippingMethods?.map((c) => {
    const opt: DropDownOption = { label: c.name, value: c.shippingMethodId };
    return opt;
  });

  const { data: currencies } = useQuery("currencies", getExchangeRate, {
    select(data) {
      const currencyCodes = data?.map((currency) => ({
        currencyCode: currency?.currencyCode,
      }));
      var currencies = getCurrenciesListFromExchangeRateList(currencyCodes);
      return currencies;
    },
  });

  const getRate = useMutation(getLatestRate, {
    async onSuccess(data) {
      if (data) setValue("currencyExchangeRate", data);
      else setValue("currencyExchangeRate", null);
    },
  });

  useEffect(() => {
    if (hubSelected) {
      setOpenSelectionHubs(false);
      setValue(
        "hubId",
        hubSelected.locationId,
        !id && { shouldValidate: true, shouldTouch: true }
      );
    }
  }, [hubSelected]);

  useEffect(() => {
    if (vendorSelected) {
      setOpenSelectionVendor(false);
      setValue("targetId", vendorSelected.vendorId, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [vendorSelected]);

  let modal = <></>;
  if (openSelectionHubs) {
    modal = (
      <SelectionModal
        modal="Hubs"
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={init?.hubs}
        columns={hubsColumns}
        setRowClicked={setHubSelected}
      />
    );
  } else if (openSelectionVendor) {
    modal = (
      <SelectionModal
        modal="Vendor"
        showModal={openSelectionVendor}
        setShowModal={setOpenSelectionVendor}
        data={init?.vendors}
        columns={vendorsColumns}
        setRowClicked={setVendorSelected}
      />
    );
  }

  const {
    formState: { errors },
    register,
    control,
    setValue,
  } = useFormContext();

  const createLabel = () => <label>Add New</label>;

  const onCreateType = () => {
    setShowShippingModal(true);
  };

  const onCurrencyChange = (e) => {
    if (e) getRate.mutate(e);
    else setValue("currencyExchangeRate", null);
  };

  return (
    <>
      <div className="row">
        <div className="">
          <div className="row">
            {!hideInfo && (
              <>
                <div className="col-sm-2">
                  <label className="mb-3">
                    Vendor No <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionVendor(true)}
                    inputName="targetId"
                    isRequired={true}
                    initialData={init?.vendors?.map((item) => ({
                      ...item,
                      id: item.vendorId,
                      name: item.vendorNumber,
                    }))}
                    onSelection={setVendorSelected}
                    inputValue={vendorSelected?.vendorNumber}
                    hasError={errors["targetId"]}
                  />
                  {errors["targetId"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-sm-2">
                  <label className="mb-3">
                    Hub ID <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionHubs(true)}
                    inputName="hubId"
                    isRequired={true}
                    initialData={init?.hubs?.map((item) => ({
                      ...item,
                      id: item.locationId,
                      name: item.hubId,
                    }))}
                    onSelection={setHubSelected}
                    inputValue={hubSelected?.hubId}
                  />
                  {errors["hubId"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </>
            )}{" "}
            <div className="col-sm-5 ">
              {!hideInfo && (
                <div className="left-side-mid">
                  <div className="form-group w-75 m-2">
                    <label>
                      Bill Date <span className="text-danger">*</span>
                    </label>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name="billDate"
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          onChange={onChange}
                          selected={value ? value : null}
                        />
                      )}
                    />
                    {errors["billDate"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm">
              <label>
                Shipping Method <span className="text-danger">*</span>
              </label>
              <Controller
                rules={{ required: true }}
                name="ShippingMethod"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CreatableSelect
                    options={shippingMethods}
                    isClearable
                    isSearchable={true}
                    menuPlacement="auto"
                    value={value}
                    onChange={onChange}
                    isValidNewOption={() => true}
                    formatCreateLabel={createLabel}
                    createOptionPosition="first"
                    onCreateOption={onCreateType}
                    className={errors["ShippingMethod"] && "required_field"}
                  />
                )}
              />
              {errors["ShippingMethod"] && (
                <>
                  <p className="text-danger">This field is required</p>
                </>
              )}
            </div>

            <div className="col-sm">
              <label>Vendor Invoice/PO</label>
              <input {...register("vendorPO")} className="form-control" />
            </div>
            <div className="col-md-5">
              <label>Term</label>
              <Controller
                name="creditTermId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={creditTermItems}
                    isClearable
                    isSearchable={true}
                    menuPlacement="auto"
                    value={
                      creditTermItems?.find((opt) => opt?.value === value) ||
                      null
                    }
                    onChange={(val: DropDownOption) => onChange(val?.value)}
                  />
                )}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="">
              <label>Remarks</label>
              <textarea
                {...register("remarks")}
                className={` ${
                  errors["soDisclaimer"] && "required_field"
                } form-control`}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-3 col-sm-12">
              <div className="form-group">
                <label>Currency</label>
                <Controller
                  control={control}
                  name="currencyId"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption?.value);
                        onCurrencyChange(selectedOption?.value);
                      }}
                      value={
                        currencies?.find((opt) => opt?.value === value) || null
                      }
                      options={currencies}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="form-group">
                <label>Exchange Rate</label>
                <input
                  className="form-control"
                  {...register("currencyExchangeRate")}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal}
    </>
  );
};

export default InvoiceInfo;
