import { useState, useEffect, useContext } from "react";
import Close from "img/close.svg";
import { Modal } from "react-bootstrap";
import AG from "elements/AG";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useQuery, useMutation } from "react-query";
import { getCustomers } from "pages/SalesMarketing/Customers/Customers.services";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { DropDownOption, GridColumns } from "components/Common.models";
import { searchSalesOrders } from "./SalesOrderServices/SalesOrderServices";
import { SalesOrder } from "pages/SalesOrder/SalesOrder.model";
import { statusEnum, statusEnum2 } from "../StatusEnum";
import DispatchContext from "context/DispatchContext";

const SalesOrdersModal = ({
  showModal,
  setShowModal,
  setRowClicked,
  preselectedStatus = null,
  showOnlyOpenAndPF = false,
}) => {
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [selectedAccount, setSelectedAccount] = useState<DropDownOption>(null);
  const [selectedHub, setSelectedHub] = useState<DropDownOption>(null);
  const [selectedStatus, setSelectedStatus] = useState<DropDownOption>({
    label: preselectedStatus ? preselectedStatus.label : "All",
    value: preselectedStatus ? preselectedStatus.value : 0,
  });
  const [ordersList, setOrdersList] = useState<SalesOrder[]>([]);

  const appDispatch = useContext(DispatchContext);

  const orderColumns: GridColumns[] = [
    {
      field: "orderDate",
      headerName: "Order Date",
      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
    },
    {
      field: "orderNumber",
      headerName: "Order No.",
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => (params.value ? statusEnum[params.value] : ""),
    },
  ];

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    "customersList",
    getCustomers,
    { enabled: showModal }
  );

  const { isLoading: isLoadingHubs, data: hubsList } = useQuery(
    "hubsList",
    getInventoryHubsList,
    { enabled: showModal }
  );

  const getSalesOrders = useMutation(searchSalesOrders, {
    async onSuccess(response) {
      setOrdersList(response.data.data);
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: getSalesOrders.isLoading || isLoadingCustomers || isLoadingHubs,
    });
  }, [getSalesOrders.isLoading, isLoadingCustomers, isLoadingHubs]);

  const onSearch = () => {
    let queryParams = "?";
    if (startDate)
      queryParams += `&start=${new Date(startDate).toLocaleString()}`;
    if (endDate) queryParams += `&end=${new Date(endDate).toLocaleString()}`;
    if (selectedAccount) queryParams += `&accountId=${selectedAccount.value}`;
    if (selectedHub) queryParams += `&hubId=${selectedHub.value}`;
    if (selectedStatus) queryParams += `&states=${selectedStatus.value}`;

    getSalesOrders.mutate(queryParams);
  };

  useEffect(() => {
    if (showModal) {
      onSearch();
    }
  }, [showModal]);

  useEffect(() => {
    onSearch();
  }, [startDate, endDate, selectedAccount, selectedHub, selectedStatus]);

  const clearForm = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedAccount(null);
    setSelectedHub(null);
    setSelectedStatus({
      label: preselectedStatus ? preselectedStatus.label : "All",
      value: preselectedStatus ? preselectedStatus.value : 0,
    });
  };

  return (
    <Modal
      show={showModal}
      onHide={setShowModal}
      centered
      size="lg"
      dialogClassName="modal-80w"
    >
      <Modal.Header className="d-flex justify-content-between border-bottom-0">
        <Modal.Title className="copy-items-title ">
          Double click on Sales Order to select it
        </Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="px-0">
        <div className="row ps-5 mb-5">
          <div className="col">
            <label>Start Date</label>
            <DatePicker
              onChange={(date) => setStartDate(date)}
              selected={startDate}
              className="form-control"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
            />
          </div>
          <div className="col">
            <label>End Date</label>
            <DatePicker
              onChange={(date) => setEndDate(date)}
              selected={endDate}
              className="form-control"
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
            />
          </div>
          <div className="col">
            <label>Account</label>
            <Select
              value={selectedAccount}
              isClearable
              onChange={setSelectedAccount}
              options={customers?.data
                ?.filter((customer) => customer.isActive === true)
                .map((element) => ({
                  label: element.accountNumber,
                  value: element.customerAccountId,
                }))}
            />
          </div>
          <div className="col">
            <label>Hub ID</label>
            <Select
              value={selectedHub}
              onChange={setSelectedHub}
              isClearable
              options={hubsList?.data
                ?.filter((hub) => hub.isActive === true)
                .map((element) => ({
                  label: element.hubId,
                  value: element.locationId,
                }))}
            />
          </div>
          {!showOnlyOpenAndPF && (
            <div className="col">
              <label>Status</label>
              <Select
                value={selectedStatus}
                onChange={setSelectedStatus}
                options={Object.entries(statusEnum2).map(([k, v]) => ({
                  label: v,
                  value: k,
                }))}
              />
            </div>
          )}
          <div className="col mt-4">
            <button className="btn btn-outline-primary" onClick={clearForm}>
              Reset
            </button>
          </div>
        </div>
        <div style={{ height: 335 }}>
          <AG
            data={
              showOnlyOpenAndPF
                ? ordersList.filter((s) => s.status === 1 || s.status === 3)
                : ordersList
            }
            columns={orderColumns}
            setrowClicked={setRowClicked}
            pagination={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex  border-top-0">
        <button
          type="button"
          className="btn btn-outline-primary custom-outline"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesOrdersModal;
