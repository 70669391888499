import { useMutation, useQuery } from 'react-query'
import { getNPM, addSettings, getSettings } from './DashboardServices'

import { Chart } from "react-google-charts";
import { useEffect, useState } from 'react';

import Accordion from 'react-bootstrap/Accordion';

const NetProfitMargin = () => {
    let { data: npm } = useQuery("npm", getNPM, { staleTime: Infinity })

    let data = [
        ["Label", "Value"],
        ["", npm === "" ? 0 : Math.floor(npm)]
    ]

    const mutateSettings = useMutation(getSettings, {
        onSuccess(data) {
            setOptions({
                min: data?.minimum,
                max: data?.maximum,
                greenFrom: data?.greenFrom,
                greenTo: data?.greenTo,
                yellowFrom: data?.yellowFrom,
                yellowTo: data?.yellowTo,
                redFrom: data?.redFrom,
                redTo: data?.redTo
            })
        },
    })

    useEffect(() => {
        mutateSettings.mutate(6)
    }, [])

    const [options, setOptions] = useState({
        min: 0,
        max: 0,
        greenFrom: 0,
        greenTo: 0,
        yellowFrom: 0,
        yellowTo: 0,
        redFrom: 0,
        redTo: 0
    })

    const settingsM = useMutation(addSettings, {
        onSuccess() {
            mutateSettings.mutate(6)
        }
    })

    const saveConfig = () => {
        let obj = {
            ...options,
            dashboardComponent: 6,
            minimum: options?.min,
            maximum: options?.max,
        }
        settingsM.mutate(obj)
    }

    return (
        <>
            <h3 className='m-2'>Net Profit Margin</h3>
            <Accordion>
                <Accordion.Header className='border'>
                    <h5><b>Settings</b></h5>
                </Accordion.Header>
                <Accordion.Body className='border'>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-6'>
                                <label>Min</label>
                                <input type="number" className='form-control' value={options?.min || ""} onChange={e => setOptions({ ...options, min: Number(e.target.value) })} />
                            </div>
                            <div className='col-6'>
                                <label>Max</label>
                                <input type="number" className='form-control' value={options?.max || ""} onChange={e => setOptions({ ...options, max: Number(e.target.value) })} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'>
                                <label>Green From</label>
                                <input type="number" className='form-control' value={options?.greenFrom || ""} onChange={e => setOptions({ ...options, greenFrom: Number(e.target.value) })} />
                            </div>
                            <div className='col-6'>
                                <label>Green To</label>
                                <input type="number" className='form-control' value={options?.greenTo || ""} onChange={e => setOptions({ ...options, greenTo: Number(e.target.value) })} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'>
                                <label>Yellow From</label>
                                <input type="number" className='form-control' value={options?.yellowFrom || ""} onChange={e => setOptions({ ...options, yellowFrom: Number(e.target.value) })} />
                            </div>
                            <div className='col-6'>
                                <label>Yellow To</label>
                                <input type="number" className='form-control' value={options?.yellowTo || ""} onChange={e => setOptions({ ...options, yellowTo: Number(e.target.value) })} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6'>
                                <label>Red From</label>
                                <input type="number" className='form-control' value={options?.redFrom || ""} onChange={e => setOptions({ ...options, redFrom: Number(e.target.value) })} />
                            </div>
                            <div className='col-6'>
                                <label>Red To</label>
                                <input type="number" className='form-control' value={options?.redTo || ""} onChange={e => setOptions({ ...options, redTo: Number(e.target.value) })} />
                            </div>
                        </div>
                    </div>
                    <div className='p-2 mt-3 text-end'>
                        <button type='button' className='btn btn-success' onClick={saveConfig}>Save Settings</button>
                    </div>
                </Accordion.Body>
            </Accordion>
            <Chart
                chartType="Gauge"
                width="100%"
                height="350px"
                data={data}
                //ts-ignore
                options={options}
            />
        </>
    )
}

export default NetProfitMargin