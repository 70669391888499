import { useEffect, useState, useContext } from "react";
import AG from "elements/AG";
import ItemsModal from "components/ItemOrderModal/ItemsModal";
import { GridColumns } from "components/Common.models";
import { SalesOrderItem } from "pages/SalesOrder/SalesOrder.model";
import HistoryModal from "components/ItemOrderModal/HistoryModal";
import {
  getInventoryItems,
  getSerialNumbersList,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useMutation, useQuery } from "react-query";
import { item } from "pages/Inventory/Inventory.models";
import Lookup from "elements/Lookup";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import ListItem from "components/SelectionListModal/ListSelection.models";
import { IInventoryItem } from "../Invoices.model";
import { useFormContext } from "react-hook-form";
import DispatchContext from "context/DispatchContext";
import {
  cloneObj,
  formatInput,
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import { checkSerialValidity } from "pages/Purchasing/PurchasingServices/PurchaseServices";
import { ReactComponent as AlertIcon } from "img/alert.svg";
import Delete from "img/delete.svg";
import { getBaseCurrency } from "components/currency";

const InvoiceItems = ({
  isCreating,
  isEditing,
  hubId = null,
  addedItems = [],
  setAddedItems = null,
  isCredit = false,
  customer = null,
  addedSerials = [],
  setAddedSerials = null,
  isDisabled = false,
  isTrans = false,
  qtyReq = false,
  withoutNonTang = false,
  isInInvoice = false,
  isManualReceipt = false,
  showUnavailable = false,
}) => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [modalRowClicked, setModalRowClicked] = useState<IInventoryItem>();
  const [selectedItem, setSelectedItem] = useState<IInventoryItem>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [selectedItemHistory, setSelectedItemHistory] =
    useState<SalesOrderItem>();
  const [cloneObject, setClonedObject] = useState<any>(null);

  const appDispatch = useContext(DispatchContext);

  const {
    formState: { errors },
    register,
    getValues,
    setValue,
  } = useFormContext();

  const columns: GridColumns[] = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: "invoiceQty",
      headerName: "QTY",
      editable: !isInInvoice,
      onCellValueChanged: (params) => subTotalChanged(params),
    },
    {
      field: "invoicePrice",
      headerName: isTrans ? "Added Cost" : "Item Price",
      editable: true,
      onCellValueChanged: (params) => subTotalChanged(params),
      cellRenderer: (params) => formatNumber(params.value, currencySymbol),
    },
    {
      field: "isTaxable",
      headerName: "Taxable",
      cellRendererFramework: (params) =>
        params.data.taxable ? "Yes" : params.data.isTaxable ? "Yes" : "No",
    },
    {
      field: "subtotal",
      headerName: "Sub Total",
      valueGetter: (params) =>
        formatNumber(
          params.getValue("invoiceQty") * params.getValue("invoicePrice"),
          currencySymbol
        ),
      filter: false,
    },
    {
      field: "action",
      headerName: "Action",
      hide: !isCreating,
      cellRendererFramework: (params) => {
        return (
          <>
            {params.data?.isSerialized && (
              <button
                type="button"
                className="btn btn-link text-decoration-none ms-0 ps-0"
                onClick={() => {
                  setShowSerialSelection(true);
                  setSelectedRow(params.data);
                }}
              >
                Add Serial
              </button>
            )}
            <button
              type="button"
              className="btn btn-link text-decoration-none ms-0 ps-0"
              onClick={() => {
                setOpenHistory(true);
                setSelectedItemHistory(params.data);
              }}
            >
              History
            </button>
            <button
              type="button"
              className="btn btn-link text-decoration-none text-danger ms-0 ps-0"
              onClick={() => removeItem(params.data)}
            >
              Remove
            </button>
          </>
        );
      },
      width: "200",
      cellStyle: { "justify-content": "flex-end" },
    },
  ];
  const serialsColumns: GridColumns[] = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: isManualReceipt ? "serial" : "serialLbl",
      headerName: "Serial",
    },
    {
      field: "action",
      headerName: "Action",
      hide: !isCreating,
      cellRendererFramework: (params) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-link text-decoration-none text-danger"
              onClick={() => removeSerial(params.data)}
            >
              Remove
            </button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (hubId) {
      clearItem();
      setValue("itemQty", "");
      setValue("itemPrice", "");
      setAddedSerials && setAddedSerials(null);
      setSelectedSerials([]);
    }
  }, [hubId]);

  useEffect(() => {
    if (modalRowClicked) {
      let item = {
        ...modalRowClicked,
        invoicePrice: "",
        invoiceQty: "",
      };
      setValue(
        "itemPrice",
        formatInput(modalRowClicked?.suggestedSellingPrice?.toString())
      );
      setSelectedItem(item);
      setModalRowClicked(null);
      setOpenSelection(false);
      setValue("itemQty", "");
    }
  }, [modalRowClicked]);

  const subTotalChanged = (params) => {
    if (!isNaN(params.data.invoiceQty) && !isNaN(params.data.invoicePrice)) {
      if (Number(params.data.invoiceQty) > params.data.quantityAvailable) {
        let notification = {
          variant: "danger",
          msg: "The quantity cannot be bigger than the quantity at hand.",
        };
        appDispatch({ type: "notification", value: notification });
        setAddedItems(cloneObj(cloneObject));
      } else {
        let temp = [...addedItems];
        temp.filter((i) => i.itemId === params.data.itemId)[0].invoiceQty =
          params.data.invoiceQty;
        temp.filter((i) => i.itemId === params.data.itemId)[0].invoicePrice =
          params.data.invoicePrice;
        setAddedItems && setAddedItems(temp);
        setValue("dirtyFlag", 1, { shouldDirty: true });
      }
    } else {
      setAddedItems(cloneObj(cloneObject));
    }
  };

  const clearItem = () => {
    setSelectedItem(null);
    setValue("itemPrice", "");
    setValue("itemQty", "");
    setIsSubmitted(false);
  };
  const addItem = () => {
    setIsSubmitted(true);
    if (getValues("itemQty") == "" || getValues("itemPrice") == "") {
      let notification = {
        variant: "danger",
        msg: "The item quantity and price are required.",
      };

      appDispatch({ type: "notification", value: notification });
    } else if (Number(getValues("itemQty")) > 1000000) {
      let notification = {
        variant: "danger",
        msg: "Qty is too high !",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      if (
        Number(getValues("itemQty")) > 0 &&
        parseFloat(getValues("itemPrice")?.toString().replace(/,/g, "")) > 0
      ) {
        if (
          Number(selectedItem["sellingQty"]) &&
          Number(getValues("itemQty") % Number(selectedItem["sellingQty"])) != 0
        ) {
          let notification = {
            variant: "danger",
            msg: "The order qty should be a multiple of item's selling qty",
          };
          appDispatch({ type: "notification", value: notification });
        } else {
          if (!qtyReq) {
            let qty = getValues("itemQty");
            let price = parseFloat(
              getValues("itemPrice")?.toString().replace(/,/g, "")
            );
            let itemToAdd = {};
            let index =
              addedItems.length > 0
                ? Math.max(...addedItems.map((o) => o.index)) + 1
                : 0;
            itemToAdd = {
              ...selectedItem,
              index: index,
              invoicePrice: price,
              invoiceQty: qty,
            };
            let clone = cloneObj(addedItems);
            setAddedItems([...clone, itemToAdd]);
            setClonedObject(cloneObj([...clone, itemToAdd]));
            clearItem();
            setValue("itemPrice", "");
            setValue("itemQty", "");
          } else {
            if (
              selectedItem["nonTangible"] ||
              Number(getValues("itemQty")) <= selectedItem["quantityAtHand"]
            ) {
              let qty = getValues("itemQty");
              let price = parseFloat(
                getValues("itemPrice")?.toString().replace(/,/g, "")
              );
              let itemToAdd = {};
              let index =
                addedItems.length > 0
                  ? Math.max(...addedItems.map((o) => o.index)) + 1
                  : 0;
              itemToAdd = {
                ...selectedItem,
                index: index,
                invoicePrice: price,
                invoiceQty: qty,
              };
              let clone = cloneObj(addedItems);
              setAddedItems([...clone, itemToAdd]);
              setClonedObject(cloneObj([...clone, itemToAdd]));
              clearItem();
              setValue("itemPrice", "");
              setValue("itemQty", "");
            } else {
              let notification = {
                variant: "danger",
                msg: "You do not have sufficient quantity for this item.",
              };
              appDispatch({ type: "notification", value: notification });
            }
          }
        }
      } else {
        let notification = {
          variant: "danger",
          msg: "The item quantity and price must be positive numbers.",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  };

  const addSerial = (serialValue) => {
    let array = addedSerials ? [...addedSerials] : [];
    array = addedSerials
      ? addedSerials?.filter((i) => i.itemId !== selectedRow?.itemId)
      : [];
    serialValue.forEach((val) => {
      let obj = {
        itemId: selectedRow?.itemId,
        itemNumber: selectedRow?.itemNumber,
        itemDescription: selectedRow?.itemDescription,
        serial: val.id,
        serialLbl: val.title,
      };
      array.push(obj);
    });
    setAddedSerials && setAddedSerials(array);
  };

  const removeItem = (item) => {
    let updatedItems = addedItems?.filter((added) => {
      return added.index !== item.index;
    });
    let updatedSerials = addedSerials?.filter((added) => {
      return added.itemId !== item.itemId;
    });
    setAddedItems(updatedItems);
    setClonedObject(updatedItems);
    setAddedSerials && setAddedSerials(updatedSerials);
  };

  const removeSerial = (serial) => {
    let updatedSerials = addedSerials?.filter((added) => {
      return added.serial !== serial.serial;
    });
    setAddedSerials(updatedSerials);
    let updatedSerialsSelected = updatedSerials.map((i) => i.serial);
    setAddedSerials && setSelectedSerials(updatedSerialsSelected);
  };

  const getQty = () => {
    let total = 0;
    for (let item of addedItems) total += parseInt(item.invoiceQty);
    return total;
  };

  const getSubTotal = () => {
    let subTotal = 0;
    for (let item of addedItems)
      subTotal += parseInt(item.invoiceQty) * parseInt(item.invoicePrice);
    return subTotal;
  };

  const getTaxTotal = () => {
    let taxTotal = 0;
    let taxRate = customer?.billingInfo?.stateTaxRate?.stateTaxRate ?? 0;
    for (let item of addedItems)
      if (item.isTaxable)
        taxTotal +=
          (parseInt(item.invoiceQty) * parseInt(item.invoicePrice) * taxRate) /
          100;
    return taxTotal;
  };

  const [serials, setSerials] = useState<any>(null);
  const [serialsOptions, setSerialsOptions] = useState<ListItem[]>(null);

  const getSerialNumbers = useMutation(getSerialNumbersList, {
    async onSuccess(response) {
      setSerials(response);
    },
  });

  const [lookupData, setLookupData] = useState<item[]>(null);

  const getItems = useMutation(getInventoryItems, {
    async onSuccess(response) {
      setLookupData(response.data.filter((item) => item.isActive === true));
    },
  });

  useEffect(() => {
    if (hubId) getItems.mutate({ hubId: hubId, itemType: 1 });
  }, [hubId]);

  const [showSerialSelection, setShowSerialSelection] =
    useState<boolean>(false);
  const [selectedSerials, setSelectedSerials] = useState<number[]>(null);
  const [selectedSerialsForTrans, setSelectedSerialsForTrans] =
    useState<number[]>(null);

  useEffect(() => {
    if (serials) {
      if (isTrans) {
        const serialList: ListItem[] = serials.data
          .filter(
            (i) =>
              i.isAvailable === !showUnavailable &&
              !selectedSerialsForTrans?.some((s) => s === i.serialId)
          )
          .map((s) => {
            const opt: ListItem = { title: s.serialNo, id: s.serialId };
            return opt;
          });
        setSerialsOptions(serialList);
      } else {
        const serialList: ListItem[] = serials.data
          .filter((i) => i.isAvailable === !showUnavailable)
          .map((s) => {
            const opt: ListItem = { title: s.serialNo, id: s.serialId };
            return opt;
          });
        setSerialsOptions(serialList);
      }
    }
  }, [serials, selectedSerialsForTrans]);

  const selectSerial = (items: ListItem[]) => {
    const selected = items;
    addSerial(items);
    setSelectedSerials(selected.map((i) => i.id));
    if (isTrans) {
      let old = selectedSerialsForTrans ?? [];
      selected.map((i) => {
        return old.push(i.id);
      });
      setSelectedSerialsForTrans(old);
    }
    setShowSerialSelection(false);
  };

  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    if (selectedRow && selectedRow?.isSerialized) {
      if (isTrans) getSerialNumbers.mutate({});
      else
        getSerialNumbers.mutate({
          itemNumber: selectedRow.itemNumber,
          hubId: hubId,
        });
      let toSet = addedSerials?.filter((i) => i.itemId === selectedRow?.itemId);
      setSelectedSerials(toSet?.map((i) => i.serial));
    }
  }, [selectedRow]);

  useEffect(() => {
    if (selectedItem?.itemId) {
      if (!isManualReceipt) {
        if (customer?.sellingPriceLevelId) {
          let pricelvl = `price${customer?.sellingPriceLevelId}`;
          if (selectedItem[pricelvl]) {
            setValue(
              "itemPrice",
              selectedItem[pricelvl]
                ? formatInput(selectedItem[pricelvl].toString())
                : formatInput(selectedItem["suggestedSellingPrice"]?.toString())
            );
          } else
            setValue(
              "itemPrice",
              selectedItem["defaultPrice"]
                ? formatInput(selectedItem["defaultPrice"].toString())
                : formatInput(selectedItem["suggestedSellingPrice"]?.toString())
            );
        } else
          setValue(
            "itemPrice",
            selectedItem["defaultPrice"]
              ? formatInput(selectedItem["defaultPrice"].toString())
              : formatInput(selectedItem["suggestedSellingPrice"]?.toString())
          );
      }
      setValue("invoiceQty", selectedItem["invoiceQty"]);
    }
  }, [selectedItem?.itemId]);

  const [errorMsg, setErrorMsg] = useState("");

  const checkAvailability = useMutation(checkSerialValidity, {
    async onSuccess(response) {
      if (response.data) {
        setErrorMsg("");
        let obj = cloneObj(addedSerials ?? []);
        let serial = {
          itemId: selectedRow?.itemId,
          itemNumber: selectedRow?.itemNumber,
          itemDescription: selectedRow?.itemDescription,
          serial: getValues("itemSerial"),
        };
        obj.push(serial);
        setValue("itemSerial", null);
        setAddedSerials && setAddedSerials(obj);
      } else {
        setErrorMsg("Invalid Serial");
      }
    },
    onError(error) {
      //@ts-ignore
      setErrorMsg(notificationMessage(error));
    },
  });
  const addSerialToItem = () => {
    if (
      addedSerials &&
      addedSerials.length > 0 &&
      addedSerials.find((i) => i.serial === getValues("itemSerial"))
    )
      setErrorMsg("Duplicate Serial detected");
    else {
      checkAvailability.mutate({
        hub: selectedRow.inventoyItemHub,
        serials: [getValues("itemSerial")],
      });
    }
  };

  const removeSerialFromList = (serial) => {
    setAddedSerials(addedSerials?.filter((i) => i.serial != serial));
  };

  return (
    <>
      <input {...register("dirtyFlag")} type="hidden" />
      {isCreating && (
        <div className="row mb-4">
          <div className="left-side">
            <div className="row">
              <div className="left-side-mid">
                <div className="form-group">
                  <label>
                    Item <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelection(true)}
                    inputName="item"
                    isRequired={false}
                    initialData={lookupData?.map((item) => ({
                      ...item,
                      id: item.itemId,
                      name: item.itemNumber,
                    }))}
                    onSelection={setModalRowClicked}
                    inputValue={selectedItem?.itemNumber}
                    isDisabled={!hubId}
                  />
                </div>
              </div>
              <div className="right-side-mid"></div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-12">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    className="form-control"
                    disabled={!hubId}
                    defaultValue={
                      selectedItem ? selectedItem["itemDescription"] : ""
                    }
                  />
                </div>
              </div>
              <div className="col-lg-2 col-sm-12">
                <div className="form-group">
                  <label>
                    Qty <span className="text-danger">*</span>
                  </label>
                  <input
                    {...register("itemQty")}
                    type="number"
                    className="form-control"
                    disabled={!hubId}
                  />
                  {isSubmitted && errors["itemQty"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <div className="form-group">
                  <label>
                    {isTrans ? "Add Cost" : "Price"}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className={`input-group-text dollar-icon ${
                          !hubId ? "bg-disabled" : "bg-white"
                        }`}
                      >
                        {currencySymbol}
                      </span>
                    </div>
                    <input
                      {...register("itemPrice")}
                      className={`form-control border-start-0 ${
                        errors["itemPrice"] && "required_field"
                      }`}
                      disabled={!hubId}
                      onBlur={(e) =>
                        setValue("itemPrice", formatInput(e.target.value))
                      }
                    />
                  </div>
                  {isSubmitted && errors["itemPrice"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 ps-0">
                <div className="form-group text-end mt-4">
                  <button
                    className="btn btn-outline-primary ms-0"
                    type="button"
                    disabled={!hubId}
                    onClick={clearItem}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="btn btn-success text-nowrap"
                    disabled={
                      (!isEditing && !hubId) ||
                      (selectedItem && Object.keys(selectedItem).length < 4)
                    }
                    onClick={addItem}
                  >
                    Add Item
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="row">
              <div className="col-lg-6 col-sm-12 px-4">
                <div className="d-flex">
                  <div>TOTAL QTY</div>
                  <div className="flex-grow-1 text-end">{getQty()}</div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex">
                  <div className="text-secondary">SUB-total</div>
                  <div className="flex-grow-1 text-end">
                    {(formatNumber(getSubTotal()), currencySymbol)}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 px-4">
                <div className="d-flex">
                  <div>Total items</div>
                  <div className="flex-grow-1 text-end">
                    {addedItems.length}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex">
                  <div className="text-secondary">Tax amount</div>
                  <div className="flex-grow-1 text-end">
                    {formatNumber(getTaxTotal(), currencySymbol)}
                  </div>
                </div>
              </div>
              <div className="px-4 mt-">
                <hr className="mt-3" />
              </div>
              <div className="col-sm-6 offset-6">
                <div className="d-flex">
                  <div>{isCredit ? "Credit Memo Total" : "ORDER TOTAL"}</div>
                  <div className="flex-grow-1 text-end">
                    {formatNumber(
                      getSubTotal() + getTaxTotal(),
                      currencySymbol
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid mt-3">
        <div className="row section px-0 ps-2 py-3 pb-0 ">
          <div className="ps-0">
            <h4>
              <b>Items list</b>
            </h4>
          </div>
        </div>
        <div className="h-350">
          <AG columns={columns} data={addedItems} autoColumns={true} />
        </div>
      </div>
      {isCreating && (
        <div className="grid mt-3">
          <div className="row section px-0 ps-2 py-3 pb-0 ">
            <div className="ps-0">
              <h4>
                <b>Serials list</b>
              </h4>
            </div>
          </div>
          <div className="h-350">
            <AG
              columns={serialsColumns}
              data={addedSerials}
              autoColumns={true}
            />
          </div>
        </div>
      )}
      <ListSelectionModal
        showModal={!isManualReceipt && showSerialSelection}
        title="Serials"
        data={serialsOptions ?? []}
        selectedValues={selectedSerials ?? []}
        onSelectData={selectSerial}
        onExit={() => setShowSerialSelection(false)}
        selectValue={Number(selectedRow?.invoiceQty)}
        isInvoice={true}
        maxValue={Number(selectedRow?.invoiceQty ?? 0)}
      />
      <Modal show={isManualReceipt && showSerialSelection} centered>
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>Add serials</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className="ms-auto"
            role="button"
            onClick={() => setShowSerialSelection(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {errorMsg && (
              <div className="d-flex align-items-center mb-2">
                <AlertIcon className="me-3" />
                <div className="text-danger">{errorMsg}</div>
              </div>
            )}
            <div className="d-flex justify-content-between">
              <input
                className="form-control"
                {...register("itemSerial")}
                autoComplete="off"
              />
              <button
                type="button"
                onClick={addSerialToItem}
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <div className="container mt-3">
            <div className="modal-column">
              <div className="column-container">
                {addedSerials?.map((element) => {
                  return (
                    <div
                      key={element.serial}
                      className="w-100 d-flex justify-content-between"
                    >
                      <label>{element.serial}</label>
                      <button
                        type="button"
                        className="btn outline-danger text-danger"
                        onClick={() => removeSerialFromList(element.serial)}
                      >
                        <img src={Delete} alt="delete" className="me-2" />
                        Remove
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ms-auto border-top-0">
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => setShowSerialSelection(false)}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={isManualReceipt && showSerialSelection} centered>
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>Add serials</Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className="ms-auto"
            role="button"
            onClick={() => setShowSerialSelection(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {errorMsg && (
              <div className="d-flex align-items-center mb-2">
                <AlertIcon className="me-3" />
                <div className="text-danger">{errorMsg}</div>
              </div>
            )}
            <div className="d-flex justify-content-between">
              <input
                className="form-control"
                {...register("itemSerial")}
                autoComplete="off"
              />
              <button
                type="button"
                onClick={addSerialToItem}
                className="btn btn-primary"
              >
                Add
              </button>
            </div>
          </div>
          <div className="container mt-3">
            <div className="modal-column">
              <div className="column-container">
                {addedSerials?.map((element) => {
                  return (
                    <div
                      key={element.serial}
                      className="w-100 d-flex justify-content-between"
                    >
                      <label>{element.serial}</label>
                      <button
                        type="button"
                        className="btn outline-danger text-danger"
                        onClick={() => removeSerialFromList(element.serial)}
                      >
                        <img src={Delete} alt="delete" className="me-2" />
                        Remove
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ms-auto border-top-0">
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => setShowSerialSelection(false)}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <ItemsModal
        showModal={openSelection}
        setShowModal={setOpenSelection}
        setRowClicked={setModalRowClicked}
        selectedHub={hubId}
        onlySerialized={isTrans}
        isPo={withoutNonTang}
      />
      <HistoryModal
        showModal={openHistory}
        setShowModal={setOpenHistory}
        selectedItem={selectedItemHistory}
        customerDetails={customer}
        isInvoice={true}
      />
    </>
  );
};

export default InvoiceItems;
