import { FormProvider, useForm } from "react-hook-form";

import * as AppUrls from "constants/AppUrls";

import backArrow from "img/back-arrow.svg";

import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DispatchContext from "context/DispatchContext";
import { useMutation, useQuery } from "react-query";
import {
  createImmediateBillAPI,
  editImmediateBillAPI,
  getReceiveInvoiceById,
} from "./InvoicesServices/InvoicesServices";
import { VendorModelDTO } from "pages/Vendors/Vendors.models";
import { getPurchaseOrderInit } from "pages/Purchasing/PurchasingServices/PurchaseServices";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { Badge, Tab, Tabs } from "react-bootstrap";
import InvoiceInfo from "./InvoiceInfo";
import InvoiceItems from "./Invoice Tabs/InvoiceItems";
import { addedSerials } from "./Invoices.model";
import ShippingMethodModal from "pages/SystemSetup/ShippingMethodModal";

const CreateImmediateBill = () => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const { id } = useParams();

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [addedItems, setAddedItems] = useState([]);
  const [vendorSelected, setVendorSelected] = useState<VendorModelDTO>(null);
  const [hubSelected, setHubSelected] = useState<any>(null);
  const [showShippingModal, setShowShippingModal] = useState<boolean>(false);
  const [addedSerials, setAddedSerials] = useState<addedSerials[]>([]);

  useQuery([id], getReceiveInvoiceById, {
    enabled: !!id,
    onSuccess(data) {
      setValue("vendorPO", data.vendorInvoicePO);
      setValue("creditTermId", data.termId);
      setValue("ShippingMethod", {
        label: data.shippingMethod.name,
        value: data.shippingMethodId,
      });
      setValue("currencyId", data.currency?.currencyId);
      setValue("currencyExchangeRate", data.rate);
      setValue("remarks", data.remarks);
      setHubSelected({ locationId: data.hubId });
      setAddedItems(
        data.items.map((item, index) => ({
          ...item,
          index,
          inventoyItemHub: item.itemHubId,
          itemDescription: item.description,
          invoiceQty: item.quantity,
          invoicePrice: item.price,
          isTaxable: item.taxable,
          isSerialized: item.serials?.length > 0,
        }))
      );
      let serials = [];
      for (let item of data.items) {
        let serial = item.serials.map((x) => ({
          itemDescription: item.description,
          itemId: item.itemId,
          itemNumber: item.itemNumber,
          serial: x.serialNo,
        }));
        serials = [...serials, ...serial];
      }
      setTimeout(() => {
        setAddedSerials(serials);
      });
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty, errors },
    setValue,
    register,
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const onBackClick = () => {
    history.push(AppUrls.receive_invoices_list);
  };

  const cancelClicked = () => {
    onCancel();
  };

  const onCancel = () => {
    setAddedItems([]);
    reset();
    setVendorSelected(null);
    setHubSelected(null);
  };

  const [currentActive, setCurrentActive] = useState<string>("PoInfo");

  const tabChanged = (e) => {
    setCurrentActive(e);
  };

  const onSubmit = () => {
    let price = 0;
    addedItems.forEach(
      (item) =>
        (price +=
          parseFloat(item.orderPrice?.toString().replace(/,/g, "")) *
          Number(item.orderQty))
    );
    if (vendorSelected?.creditLimit) {
      if (
        vendorSelected?.onPO + vendorSelected?.balance + price >
        Number(vendorSelected?.creditLimit)
      ) {
        setShowWarning(true);
      } else {
        submitConfirmed();
      }
    } else {
      submitConfirmed();
    }
  };

  const methodToSubmit = useMutation(createImmediateBillAPI, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: "Bill created",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
  });

  const editSubmit = useMutation(editImmediateBillAPI, {
    onSuccess() {
      let notification = {
        variant: "success",
        msg: "Bill ediited",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
  });

  const submitConfirmed = () => {
    let canContinue = true;
    addedItems
      .filter((item) => item.isSerialized)
      .forEach((item) => {
        if (
          addedSerials?.filter((serial) => serial.itemId === item.itemId)
            .length != Number(item.invoiceQty)
        ) {
          canContinue = false;
        }
      });
    if (canContinue) {
      let addedItemsForHadisDTO = addedItems.map((item) => ({
        itemHubId: item.inventoyItemHub,
        price: parseFloat(item.invoicePrice),
        description: item.itemDescription,
        orderQuantity: Number(item.invoiceQty),
        serials: addedSerials
          ?.filter((s) => s.itemId === item.itemId)
          .map((s) => s.serial),
      }));
      let newObj = {
        date: getValues("billDate"),
        targetId: getValues("targetId"),
        hubId: getValues("hubId"),
        termId: getValues("creditTermId") ?? null,
        vendorInvoicePO: getValues("vendorPO"),
        shippingMethodId: getValues("ShippingMethod.value"),
        remarks: getValues("remarks"),
        marketCode: getValues("marketCode"),
        currencyId: getValues("currencyId"),
        currencyExchangeRate: getValues("currencyExchangeRate"),
        purchaseOrderItems: addedItemsForHadisDTO,
        id: id ?? null,
      };
      if (!!id) editSubmit.mutate(newObj);
      else methodToSubmit.mutate(newObj);
    } else {
      let notification = {
        variant: "danger",
        msg: "The number of serials must match the quantity ordered",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const onTypeCreated = (method) => {
    setValue("ShippingMethod", method, { shouldDirty: true });
    setShowShippingModal(false);
    refetch();
  };

  const { data: init, refetch } = useQuery("init", getPurchaseOrderInit, {
    onSuccess(data) {
      if (data?.hubs?.length === 1 && !id) setHubSelected(data.hubs[0]);
    },
  });
  useEffect(() => {
    if (
      errors["ShippingMethod"] ||
      errors["targetId"] ||
      errors["hubId"] ||
      errors["billDate"]
    ) {
      setCurrentActive("PoInfo");
    }
  }, [
    errors["ShippingMethod"] ||
      errors["targetId"] ||
      errors["hubId"] ||
      errors["billDate"],
  ]);

  return (
    <>
      <>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="page-title page-title-editable">
              <div className="back-btn " onClick={onBackClick}>
                <img src={backArrow} alt="back arrow" />
                {!!id ? "Edit" : "Create"} Immediate Bill
              </div>
              <div>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border ${
                    !isDirty && "text-muted"
                  }`}
                  onClick={cancelClicked}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={addedItems.length === 0}
                >
                  {!!id ? "Save Changes" : "Create"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="page-content">
                <input {...register("dirtyFlag")} type="hidden" />
                <Tabs
                  defaultActiveKey="PoInfo"
                  activeKey={currentActive}
                  onSelect={tabChanged}
                >
                  <Tab
                    eventKey="PoInfo"
                    title={<div className="tab-inner-title">PO Info</div>}
                  >
                    <InvoiceInfo
                      vendorSelected={vendorSelected}
                      init={init}
                      setVendorSelected={setVendorSelected}
                      hubSelected={hubSelected}
                      setHubSelected={setHubSelected}
                      setShowShippingModal={setShowShippingModal}
                      hideInfo={!!id}
                    />
                  </Tab>
                  <Tab
                    eventKey="PoItems"
                    title={
                      <div className="tab-inner-title">
                        items
                        <Badge pill className="text-secondary ms-2">
                          {addedItems.length}
                        </Badge>
                      </div>
                    }
                  >
                    <InvoiceItems
                      isEditing={true}
                      addedItems={addedItems}
                      setAddedItems={setAddedItems}
                      hubId={hubSelected?.locationId}
                      isCreating={true}
                      customer={vendorSelected}
                      addedSerials={addedSerials}
                      setAddedSerials={setAddedSerials}
                      isDisabled={!!hubSelected?.locationId}
                      isManualReceipt={true}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </form>
        </FormProvider>
        <ShippingMethodModal
          showModal={showShippingModal}
          onClose={() => setShowShippingModal(false)}
          onConfirm={onTypeCreated}
        />
        <ConfirmationModal
          title="Credit Limit Exceeded"
          message="Order total exceeds the vendor's credit limit, do you wish to proceed?"
          showModal={showWarning}
          onClose={() => setShowWarning(false)}
          onConfirm={() => {
            submitConfirmed();
          }}
          cancelBtnTitle="No, stay"
          confirmBtnTitle="Yes"
          type="confirmation-primary"
        />
      </>
    </>
  );
};

export default CreateImmediateBill;
