import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import addIcon from "img/add-icon.svg";
import { useMutation, useQuery } from "react-query";
import * as AppUrls from "constants/AppUrls";
import ExcelIcon from "img/excel_icon.png";
import { getCustomers } from "./Customers.services";
import { checkIfAllowed, getCurrencyByCode } from "global/helpers";
import StateContext from "context/StateContext";
import { states } from "components/data/States";
import { GridColumn } from "elements/AG.models";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import Select from "react-select";
import { QueryResult } from "components/Common.models";
import { Hub } from "pages/Inventory/Inventory.models";
import { ErrorResponse } from "components/data/Errors.model";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { getAccountTypes } from "../SalesMarketing.services";
import * as colDefs from "../../../constants/colDefs";
import { getBaseCurrency } from "components/currency";

let selectOptions = [
  { label: "All", value: 0 },
  { label: "Active", value: 1 },
  { label: "Disabled", value: 2 },
];

const Customers = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedHubId, setSelectedHubId] = useState(null);
  const [filters, setFilters] = useState({
    accountTypeId: null,
    hubId: null,
    activeOnly: null,
    searchQuery: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);
  const [activeOnly, setIsActiveOnly] = useState<number>(0);

  const dataMutation = useMutation("data", getCustomers, {
    async onSuccess(response) {
      setDataGrid(response?.data);
    },
  });

  const { data: inventoryHubs } = useQuery<QueryResult<Hub>, ErrorResponse>(
    "inventoryHubsList",
    getInventoryHubsList
  );
  const { data: userTypes } = useQuery("get_user_types", getAccountTypes);

  let history = useHistory();

  const onRowDoubleClicked = (e) => {
    let url = `${AppUrls.customers}/${e.customerAccountId}`;
    history.push(url);
  };
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const columns: GridColumn[] = [
    {
      field: "accountNumber",
      headerName: "Customer No",
      maxWidth: colDefs.mWidth,
    },
    {
      field: "company",
      headerName: "Company Name",
      maxWidth: 150,
    },
    {
      field: "contact",
      headerName: "Contact",
      maxWidth: 150,
    },
    {
      field: "hubId",
      headerName: "Hub ID",
      maxWidth: colDefs.mWidth,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      maxWidth: 300,
      valueGetter: (params) => {
        return `${params?.data?.city ?? ""} ${params?.data?.state ?? ""}`;
      },
      cellRenderer: (params) => {
        let city = params?.data?.city === null ? "" : params?.data?.city;
        let state =
          params?.data?.state === null
            ? ""
            : states.filter((value) => value.value === params?.data?.state)[0]
                ?.label;
        return `
        <div>
          ${city}
          <br />
          <span class="text-muted">${state ?? ""}</span>
        <div>
      `;
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      maxWidth: colDefs.balanceWidth,

      valueGetter: (params) => {
        return `${getCurrencyByCode(baseCurrency).symbol} ${
          params.data?.balance?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: colDefs.booleanWidth,

      sortable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
  ];

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const onAccountTypeChange = (e) => {
    setSelectedAccountType(e);
    setFilters((filters) => ({ ...filters, accountTypeId: e?.value ?? null }));
    setDataGrid(null);
  };

  const onHubChange = (e) => {
    setSelectedHubId(e);
    setFilters((filters) => ({ ...filters, hubId: e?.value ?? null }));
    setDataGrid(null);
  };

  const clearFilters = () => {
    setSelectedAccountType(null);
    setSelectedHubId(null);
    setIsActiveOnly(0);
    setSearchTerm("");
    setFilters({
      hubId: null,
      accountTypeId: null,
      activeOnly: null,
      searchQuery: "",
    });
    setDataGrid(null);
  };

  // const reportClicked = () => {
  //   history.push(AppUrls.customers_report)
  // }

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const onRadioChange = (e) => {
    const value = Number(e?.value);
    setIsActiveOnly(Number(e?.value));
    if (value === 0)
      setFilters((filters) => ({ ...filters, activeOnly: null }));
    else if (value === 1)
      setFilters((filters) => ({ ...filters, activeOnly: true }));
    else if (value === 2)
      setFilters((filters) => ({ ...filters, activeOnly: false }));
    setDataGrid(null);
  };

  const onSearchTermChange = (e) => {
    const value = e?.target?.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newDebounceTimeout = setTimeout(() => {
      setFilters((filters) => ({ ...filters, searchQuery: value }));
      setDataGrid(null);
    }, 1000);

    setDebounceTimeout(newDebounceTimeout);
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Customers</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0 || !dataGrid}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <>
              <Link to={AppUrls.import_customers} className="import-btn">
                <img src={ExcelIcon} className="me-2" alt="" /> Import Customers
              </Link>
              <Link to={AppUrls.create_customers} className="btn btn-primary">
                <img src={addIcon} className="me-2" alt="" /> New Customer
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          {/* <div className="d-flex justify-content-between align-items-end"> */}
          <div className="d-flex align-items-end">
            <div className="form-group">
              <label className="me-2">Search</label>
              <div className="page-search">
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5 me-4 w-200"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => onSearchTermChange(e)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="me-2">Customer Type</label>
              <Select
                options={userTypes?.map((type) => ({
                  value: type.accTypeId,
                  label: type.name,
                }))}
                isSearchable={true}
                value={selectedAccountType}
                onChange={onAccountTypeChange}
                className="me-4 w-200"
                isClearable
              />
            </div>
            <div className="form-group">
              <label className="me-2">Hub ID</label>
              <Select
                options={inventoryHubs?.data?.map((hub) => ({
                  value: hub.locationId,
                  label: hub.hubId,
                }))}
                isSearchable={true}
                value={selectedHubId}
                onChange={onHubChange}
                className=" me-4 w-200"
                isClearable
              />
            </div>
            <div className="form-group">
              <label className="me-2">Show Disabled</label>
              <Select
                options={selectOptions}
                isSearchable={true}
                value={selectOptions.find(
                  (option) => option.value === activeOnly
                )}
                onChange={onRadioChange}
                className="w-200"
              />
            </div>
            <button
              type="button"
              onClick={clearFilters}
              className="btn btn-outline-primary custom-outline mb-3"
            >
              Reset
            </button>
          </div>
          {/* <div className="form-group">
              <label className="checkbox-inline">
                <input
                  id="showDisabled"
                  type="checkbox"
                  onChange={onRadioChange}
                  checked={!activeOnly}
                />
                <label className="text-black ms-2" htmlFor="showDisabled">
                  Show Disabled?
                </label>
              </label>
            </div>
          </div> */}
          <AgGrid
            fetchMethod={dataMutation}
            data={dataGrid}
            columns={columns}
            refreshCounter={refreshCounter}
            setrowClicked={onRowDoubleClicked}
            filters={filters}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setDataGrid={setDataGrid}
            setIsLoadingGridExport={setIsLoadingGridExport}
            fileName="Customers List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default Customers;
