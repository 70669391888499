import api from "components/data/AxiosClient";
import { UserPassword } from "./User.models";

export const getUserProfile = async () => {
    return await api.get('users/profile?withPermissions=true');
};

export const updateUserProfile = async (data) => {
    let body = {};
    body = {
        address: data.profileData.address,
        address1: data.profileData.address1,
        city: data.profileData.city,
        dateOfBirth: data.profileData.dateOfBirth,
        firstName: data.profileData.firstName,
        lastName: data.profileData.lastName,
        middleName: data.profileData.middleName,
        mobileNumber: data.profileData.mobileNumber,
        homeNumber: data.profileData.homeNumber,
        state: data.profileData.state ? data.profileData.state.value : null,
        zipCode: data.profileData.zipCode,
    };
    return await api.put(`users/profile`, body);
};

export const updatePassword = async (data: UserPassword): Promise<UserPassword> => {
    const response = await api.post<UserPassword>('/users/change-password', data);
    return response.data;
};
