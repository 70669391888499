import { ErrorResponse } from "components/data/Errors.model";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import DispatchContext from "context/DispatchContext";
import {
  ProcessTransferAPI,
  TransferAPI,
  VoidTransferAPI,
} from "./InventoryServices/Inventory.services";
import { FormProvider, useForm } from "react-hook-form";
import Lookup from "elements/Lookup";
import { TransferI, TransferItemI } from "pages/Invoices/Invoices.model";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumn } from "elements/AG.models";
import {
  dateFormmaterNoTime,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import Close from "img/close.svg";
import { Modal } from "react-bootstrap";
import AG from "elements/AG";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

const Transfer = () => {
  const appDispatch = useContext(DispatchContext);

  const { isLoading: isLoadingInventory, data: inventoryTransfer } = useQuery<
    TransferI[],
    ErrorResponse
  >("inventoryHubsList", TransferAPI);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingInventory });
  }, [isLoadingInventory]);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {} = methods;

  const status = {
    1: "Pending",
    2: "Completed",
  };

  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [showSerialsModal, setShowSerialsModal] = useState<boolean>(false);
  const [selectedTrans, setSelectedTrans] = useState<TransferI>(null);
  const [selectedItem, setSelectedItem] = useState<TransferItemI>(null);
  const [serialsList, setSerialsList] = useState<{ serial: string }[]>(null);

  const onCancel = () => {
    setSelectedTrans(null);
    setSelectedItem(null);
    setSerialsList(null);
  };

  useEffect(() => {
    if (selectedTrans) setOpenSelection(false);
  }, [selectedTrans]);

  useEffect(() => {
    if (selectedItem?.serials?.length > 0) {
      let objA = [];
      selectedItem.serials?.forEach((i) => objA.push({ serial: i }));
      setSerialsList(objA);
    }
  }, [selectedItem]);

  const serialsColumns: GridColumn[] = [
    {
      field: "serial",
      headerName: "Serial Number",
    },
  ];

  const itemsColumns: GridColumn[] = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: "serials",
      headerName: "Serials",
      cellRendererFramework: (params) => {
        let serials = inventoryTransfer
          ?.find(
            (i) => i.inventoryTransferId === selectedTrans?.inventoryTransferId
          )
          ?.items?.find(
            (i) => i.itemNumber === params.data.itemNumber
          )?.serials;
        return (
          <>
            {serials?.length > 0 ? (
              <span
                className="btn btn-link text-decoration-none ms-0 ps-0"
                onClick={() => {
                  setShowSerialsModal(true);
                  setSelectedItem(
                    inventoryTransfer
                      ?.find(
                        (i) =>
                          i.inventoryTransferId ===
                          selectedTrans.inventoryTransferId
                      )
                      .items.find(
                        (i) => i.itemNumber === params.data.itemNumber
                      )
                  );
                }}
              >
                Serials
              </span>
            ) : (
              <span className="text-muted fst-italic">Serials</span>
            )}
          </>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Qty",
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "subTotal",
      headerName: "Sub Total",
    },
    {
      field: "category",
      headerName: "Category",
    },
    {
      field: "productLine",
      headerName: "Product Line",
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
    },
    {
      field: "manufPartNumber",
      headerName: "Man. Part. No",
    },
  ];

  const inventoryTransferColumns: GridColumn[] = [
    {
      field: "inventoryTransferId",
      headerName: "Transfer Id",
    },
    {
      field: "totalAmount",
      headerName: "Total amount",
    },
    {
      field: "transferDate",
      headerName: "Transfer Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.transferDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.createdDate === null
            ? ""
            : dateFormmaterNoTime(params.data.transferDate) ?? "";
        return `
                  <div>
                    ${date}
                  <div>
                `;
      },
    },
    {
      field: "fromHub",
      headerName: "From Hub",
    },
    {
      field: "newHub",
      headerName: "New Hub",
    },
    {
      field: "createdBy",
      headerName: "Created By",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.createdDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.createdDate === null
            ? ""
            : dateFormmaterNoTime(params.data.createdDate);
        return `
                  <div>
                    ${date}
                  <div>
                `;
      },
    },
  ];

  const processM = useMutation(ProcessTransferAPI, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Transfer processed successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const voidM = useMutation(VoidTransferAPI, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Transfer voided successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem voiding transfer"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onVoid = () => {
    voidM.mutate(selectedTrans.inventoryTransferId);
  };

  const onProcess = () => {
    processM.mutate(selectedTrans.inventoryTransferId);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <div className="page-title page-title-editable">
            <div>Inventory Transfer</div>
            <div>
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                disabled={!selectedTrans}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-danger"
                disabled={!selectedTrans}
                onClick={onVoid}
              >
                Void
              </button>
              <button
                type="button"
                className="btn btn-success"
                disabled={!selectedTrans}
                onClick={onProcess}
              >
                Process
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="left-side-mid">
                  <div className="form-group w-50">
                    <label>
                      Transfer Id <span className="text-danger">*</span>
                    </label>
                    <Lookup
                      onButtonClicked={() => setOpenSelection(true)}
                      inputName="inventoryTransferId"
                      initialData={
                        inventoryTransfer?.length > 0 &&
                        inventoryTransfer?.map((trans) => ({
                          ...trans,
                          id: trans.inventoryTransferId,
                          name: trans.transferNumber,
                        }))
                      }
                      onSelection={setSelectedTrans}
                      inputValue={selectedTrans?.transferNumber}
                      isDisabled={false}
                    />
                  </div>
                </div>
                <div className="right-side-mid">
                  <div className="row">
                    <div className="col">
                      <div>
                        <label>From Hub</label>
                        <p>
                          {selectedTrans?.fromHub ?? (
                            <span className="text-muted fst-italic">
                              From Hub
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <label>New Hub</label>
                        <p>
                          {selectedTrans?.newHub ?? (
                            <span className="text-muted fst-italic">
                              New Hub
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <label>Status</label>
                        <p>
                          {status[selectedTrans?.status] ?? (
                            <span className="text-muted fst-italic">
                              Status
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div>
                        <label>Transfer Date</label>
                        <p>
                          {dateFormmaterNoTime(selectedTrans?.transferDate) ?? (
                            <span className="text-muted fst-italic">
                              Transfer Date
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <label>Created By</label>
                        <p>
                          {selectedTrans?.createdBy != "" ? (
                            selectedTrans?.createdBy
                          ) : (
                            <span className="text-muted fst-italic">
                              Created By
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <label>Remarks</label>
                        <p>
                          {selectedTrans?.remarks != "" ? (
                            selectedTrans?.remarks
                          ) : (
                            <span className="text-muted fst-italic">
                              Remarks
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 px-2">
                <div className="col border d-flex justify-content-between p-3 me-2">
                  <span
                    className={
                      selectedTrans?.totalAmount <= 0
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    Total Amount
                  </span>
                  <span
                    className={
                      selectedTrans?.totalAmount <= 0
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {formatNumber(selectedTrans?.totalAmount ?? 0)}
                  </span>
                </div>
                <div className="col border d-flex justify-content-between p-3 me-2">
                  <span
                    className={
                      selectedTrans?.totalItems <= 0
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    Total Items
                  </span>
                  <span
                    className={
                      selectedTrans?.totalItems <= 0
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {`${selectedTrans?.totalItems ?? "0"}`}
                  </span>
                </div>
                <div className="col border d-flex justify-content-between p-3 me-2">
                  <span
                    className={
                      selectedTrans?.totalItems <= 0
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    Total Quantity
                  </span>
                  <span
                    className={
                      selectedTrans?.totalQuantity <= 0
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {`${selectedTrans?.totalQuantity ?? "0"}`}
                  </span>
                </div>
              </div>
              <div className="text-end pt-3">
                <button
                  className="import-btn"
                  type="button"
                  onClick={exportToExcel}
                  disabled={
                    disabledExportBtn || selectedTrans?.items?.length === 0
                  }
                >
                  <img src={exportIcon} className="me-2" alt="" />
                  Export to Excel
                </button>
              </div>
              <div className="row mt-4 h-450">
                <AgGrid
                  columns={itemsColumns}
                  data={selectedTrans?.items ?? []}
                  exportToExcel={exportToExcelClicked}
                  setExportToExcelClicked={setExportToExcelClicked}
                  setDisabledExportBtn={setDisabledExportBtn}
                  setIsLoadingGridExport={setIsLoadingGridExport}
                  fileName="Inventory Transfer List Report"
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Transfer Selection Modal"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={inventoryTransfer}
        columns={inventoryTransferColumns}
        setRowClicked={setSelectedTrans}
      />
      <Modal
        show={showSerialsModal}
        onHide={setShowSerialsModal}
        centered
        size="lg"
        dialogClassName="modal"
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>
            Serials List for Item {selectedItem?.itemNumber}
          </Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className="ms-auto"
            role="button"
            onClick={() => {
              setShowSerialsModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className="h-250">
            <AG data={serialsList} columns={serialsColumns} />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ms-auto border-top-0">
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => {
              setShowSerialsModal(false);
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Transfer;
