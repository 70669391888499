import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import "./DeleteRepresentative.scss";
import { deleteRepresentativeProps } from "./Representative.models";
import { deleteRepresentative } from "./Representative.services";
import * as AppUrls from "constants/AppUrls";

const DeleteRepresentative = (props: deleteRepresentativeProps) => {
  let history = useHistory();

  const removeRep = useMutation(deleteRepresentative, {
    async onSuccess() {
      history.push(`${AppUrls.representatives}`);
    },
  });

  const onDeleteRep = () => {
    removeRep.mutate(props.repId);
  };

  return (
    <>
      <Modal
        show={props.showDelete}
        onHide={props.onExit}
        id="delete-inventory-modal"
      >
        <Modal.Header className="delete-inventory-header">
          <Modal.Title
            className="delete-inventory-title"
            style={{ color: props.attached ? "#212529" : "#FA7070" }}
          >
            {props.attached ? "Cannot delete" : "Deleting Representatives"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="delete-inventory-body">
          <p className="delete-inventory-txt">
            {props.attached === true ? (
              <span>
                This representative is representing customer(s). Make sure they
                are not,and then delete.
              </span>
            ) : (
              <span>Are you sure? They will be permanently deleted.</span>
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="delete-inventory-footer d-flex justify-content-between">
          {props.attached === true ? (
            <button className="btn btn-primary okay-btn" onClick={props.onExit}>
              Okay
            </button>
          ) : (
            <>
              <button className="btn keep-inventory-btn" onClick={props.onExit}>
                No, Keep
              </button>
              <button
                className="btn delete-inventory-btn"
                onClick={onDeleteRep}
              >
                Yes, Delete
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteRepresentative;
