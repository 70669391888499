import ReactDOM from "react-dom";
import "./styles/main.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import dotenv from "dotenv";
import App from "./App";

dotenv.config();
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_ENVIRONMENT;
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 50,
  debug: true,
  environment: environment,
});

const app = <App />;

ReactDOM.render(app, document.getElementById("root"));
