import * as AppUrls from "constants/AppUrls";
import { dateFormmaterNoTime } from "global/helpers";
import { useHistory } from "react-router-dom";
import { GridColumns } from "components/Common.models";
import AgGrid from "elements/AgGrid";
import { useQuery } from "react-query";
import { getVendorCm } from "../Vendors.services";
import { useEffect, useState } from "react";

const VendorCM = () => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data } = useQuery(["vendor-cm", filter], getVendorCm);

  const columns: GridColumns[] = [
    {
      field: "number",
      headerName: "Invoice No.",
      width: "130px",
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      width: "145px",
    },
    {
      field: "company",
      headerName: "Company",
      width: "170px",
    },
    {
      field: "date",
      headerName: "Inv. Date",
      width: "130px",

      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.date);
      },
      cellRenderer: (params) =>
        params.value ? `${dateFormmaterNoTime(params.value)}` : "",
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      width: "140px",

      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      width: "140px",

      valueGetter: (params) => {
        return `$${params.data?.paidAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "invoiceBalance",
      headerName: "Inv. Balance",
      width: "135px",
      valueGetter: (params) => {
        return `$${(params.data.amount - params.data.paidAmount)?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const onRowDoubleClicked = (e) => {
    let url = `${AppUrls.vendor_credit_memo}/${e.id}`;
    history.push(url);
  };

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Vendor Credit Memo</p>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="d-flex align-items-start">
            <div className="form-group">
              <label className="me-2">Search</label>
              <div className="page-search">
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5 me-4 w-200"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            data={data}
            setrowClicked={onRowDoubleClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default VendorCM;
