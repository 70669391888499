export enum typeEnum {
    'Invoice' = 1,
    'Credit Memo' = 2,
    'All' = 3
}

export enum stateEnum {
    'All' = 1,
    'Open' = 2,
    'Closed' = 3
}

export const InvoiceStateArray =
[
    "Open",
    "Applied",
    "Voided",
    "Paid",
    "PartiallyPaid"
]

export const InvoiceStateArray2 =
[
    "Open",
    "Applied",
    "Voided",
    "Paid",
    "Partially Paid"
]

export enum InvoiceState
{
    Open = 1,
    Applied = 2,
    Voided = 3,
    Paid = 4,
    PartiallyPaid = 5
}