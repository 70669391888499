import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { notificationMessage } from "global/helpers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { AccountTypeDTO } from "./AccountType.models";
import { ErrorResponse } from "components/data/Errors.model";
import { useHistory, useParams } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import {
  getAccountTypes,
  createAccountType,
  editAccountType,
  deleteAccountType,
} from "../SalesMarketing.services";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
} from "constants/NotificationMsgs";

import DispatchContext from "context/DispatchContext";

const NewAccountTypes = ({ match }) => {
  const history = useHistory();

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<AccountTypeDTO>(null);
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();
  const [customerTypeData, setCustomerTypeData] =
    useState<AccountTypeDTO>(null);
  const formMethods = useForm<AccountTypeDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: customerTypeData?.isActive || true },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { isDirty, errors },
  } = formMethods;

  const { data: accountTypes } = useQuery<AccountTypeDTO[], ErrorResponse>(
    "get_account_types",
    getAccountTypes
  );
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setSelectedItem(id);
      let idNum = Number(id);
      let data = accountTypes?.find((item) => item.accTypeId === idNum);
      setCustomerTypeData(data);
      setValue("name", data?.name);
      setValue("isActive", data?.isActive);
    }
  }, [accountTypes, id]);

  const addAccType = useMutation(createAccountType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_account_types");
      let notification = {
        variant: "success",
        msg: `Customer type ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.account_types);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding customer type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateAccType = useMutation(editAccountType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_account_types");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.account_types);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing customer type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeAccType = useMutation(deleteAccountType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_account_types");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const handleDeleteItem = (itemId) => {
    setShowDelete(false);
    removeAccType.mutate(itemId);
    setSelectedItem(null);
  };

  const onSubmit = (data: AccountTypeDTO) => {
    if (!selectedItem) {
      addAccType.mutate(data);
    } else {
      updateAccType.mutate({ ...data, accTypeId: id });
    }
  };

  const resetForm = () => {
    reset({ isActive: true, name: "" });
  };

  return (
    <div className="static-page">
      {" "}
      <FormProvider {...formMethods}>
        <form
          id="new-method-form"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="  page-title page-title-editable">
            <div className="d-flex flex-row">
              <div className="back-btn">
                <img
                  src={backArrow}
                  alt="back arrow"
                  onClick={() => history.push(AppUrls.account_types)}
                />
              </div>
              <div>
                <p className="">Customer Type</p>
              </div>
            </div>
            <div className="d-flex ">
              <button
                className="btn btn-outline-primary custom-outline custom-outline"
                type="button"
                onClick={resetForm}
              >
                Reset{" "}
              </button>
              <button
                className="btn btn-primary "
                form="new-method-form"
                type="submit"
                disabled={!isDirty}
              >
                {!selectedItem ? "Add Customer Type" : "Save Changes"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div>
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group mt-3">
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <>
                          <label>
                            Customer Type <span className="text-danger">*</span>
                          </label>
                          <input
                            {...field}
                            required={true}
                            className={`form-control ${
                              errors.name && "required_field"
                            }`}
                            placeholder={"Customer Type"}
                          />
                        </>
                      )}
                    />
                    {errors["name"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
                <div className="col-2 ">
                  <div className="form-group mt-3">
                    <div className="form-group mt-2">
                      <Controller
                        control={control}
                        name="isActive"
                        render={({ field: { onChange, value } }) => (
                          <>
                            {value ? (
                              <label className="row m-1 ">Active</label>
                            ) : (
                              <label className=" row m-1"> Disabled</label>
                            )}
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="activeCheckbox"
                                name="isActive"
                                onChange={() => onChange(!value)}
                                checked={value}
                              />
                              <span className="slider round"></span>
                            </label>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            title="Delete Account Type"
            message={`Are you sure you want to delete the account type "${selectedItem?.name}"?`}
            showModal={showDelete}
            onClose={() => setShowDelete(false)}
            onConfirm={() => {
              handleDeleteItem(selectedItem.accTypeId);
            }}
            cancelBtnTitle="Keep"
            confirmBtnTitle="Delete"
            type="confirmation-danger"
          />{" "}
        </form>
      </FormProvider>
    </div>
  );
};

export default NewAccountTypes;
