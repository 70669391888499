import * as AppUrls from "constants/AppUrls";

/* Customers */

const customersUrl = '/customers/import/validate'

const customersUrlI = '/customers/import'

const customers_columns_template = [
    {
        "Account No" : "",
        "Account Type" : "",
        "Company" : "",
        "Rep Id" : "",
        "Default Hub" : "",
        "Address" : "",
        "City" : "",
        "State" : "",
        "Zip Code" : "",
        "Work No" : "",
        "Contact" : "",
        "Price Level" : "",
        "Credit Term" : "",
        "Email" : "",
    }
  ];

    const customers_page_header = {
        prevPage: AppUrls.customers,
        title: "Import Customers",
        actions: {
            check: "Validate Data",
            fileSelect: "Select File",
            confirmation: "Import"
        }
    };

export const customers_pages_def = {header: customers_page_header, template: customers_columns_template, url: customersUrl, urlI: customersUrlI}


