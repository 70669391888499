import { GridColumns } from "components/Common.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { UPDATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import Lookup from "elements/Lookup";
import { formatNumber, notificationMessage } from "global/helpers";
import exportIcon from "img/export.svg";
import { getLedgers } from "pages/Accounting/AccountingServices";
import {
  CustomerCreditMemo,
  CustomerInfoDTO,
} from "pages/SalesMarketing/Customers/Customers.models";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  getCustomerById,
  getCustomerCreditMemos,
  getCustomers,
  updateCustomerRefundAmount,
} from "../SalesMarketing/Customers/Customers.services";
import { CustomerAccount } from "./Invoices.model";
const RefundCreditMemo = () => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openLedgerSelection, setOpenLedgerSelection] =
    useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerAccount>();
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [customerDetails, setCustomerDetails] = useState<CustomerInfoDTO>();
  const [gridData, setGridData] = useState<CustomerCreditMemo[]>([]);
  const appDispatch = useContext(DispatchContext);

  const { data: ledgers } = useQuery("ledgers", () =>
    getLedgers({ ledgerType: 2 })
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty, errors },
    reset,
    setValue,
    handleSubmit,
  } = methods;

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const accountNumberColumns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Customer No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "accountType",
      headerName: "Customer Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const creditMemoColumns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "CR Memo",
      width: "130px",
    },
    {
      field: "date",
      headerName: "CR Date",
      width: "120px",

      cellRendererFramework: (params) =>
        params.value ? new Date(params.value).toLocaleDateString() : null,
    },
    {
      field: "amount",
      headerName: "CR Amount",
      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      valueGetter: (params) => {
        return `$${params.data?.paidAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
      width: "165px",

      cellRendererFramework: (params) =>
        params.value ? new Date(params.value).toLocaleDateString() : null,
    },
    {
      field: "crBalance",
      headerName: "CR Balance",
      cellRenderer: (params) =>
        formatNumber(params.data?.amount - params.data?.paidAmount),
    },
    {
      field: "aging",
      headerName: "Days Old",
      width: "130px",
    },
    {
      field: "refundAmount",
      headerName: "Refund Amount",
      cellRenderer: (params) =>
        formatNumber(
          params.data?.refundAmount === undefined
            ? 0
            : params.getValue("refundAmount")
        ),
      valueSetter: (params) => {
        if (
          parseInt(params.newValue) >
          params.data?.amount - params.data?.paidAmount
        ) {
          let notification = {
            variant: "danger",
            msg: `Cannot make payment that is larger than invoice balance.`,
          };
          appDispatch({ type: "notification", value: notification });
          return false;
        } else {
          dirtyForm();
          params.data.refundAmount = params.newValue;
          return true;
        }
      },
      editable: true,
    },
    {
      field: "newBalance",
      headerName: "New Balance",
      valueGetter: (params) => {
        return formatNumber(
          params.getValue("refundAmount") === undefined
            ? params.data?.amount - params.data?.paidAmount - 0
            : params.data?.amount -
                params.data?.paidAmount -
                params.getValue("refundAmount")
        );
      },
    },
  ];

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    "customersList",
    getCustomers
  );

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingCustomers });
  }, [isLoadingCustomers]);

  const getCustomerDetails = useMutation(getCustomerById, {
    async onSuccess(response) {
      setCustomerDetails(response);
    },
  });

  const onCustomerSelected = (customer) => {
    getCustomerDetails.mutate(customer["customerAccountId"]);
    setSelectedCustomer(customer);
    setOpenSelection(false);
  };

  const getCreditMemos = useMutation(getCustomerCreditMemos, {
    async onSuccess(response) {
      setGridData(response?.data);
    },
  });

  useEffect(() => {
    if (selectedCustomer) {
      getCreditMemos.mutate(selectedCustomer?.customerAccountId);
    }
  }, [selectedCustomer]);

  const dirtyForm = () => {
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const editRefundAmount = useMutation(updateCustomerRefundAmount, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Refund Amount ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      getCreditMemos.mutate(selectedCustomer?.customerAccountId);
      getCustomerDetails.mutate(selectedCustomer.customerAccountId);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to edit refund amount"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onRefundAmountValueChanged = (e) => {
    dirtyForm();
    if (isNaN(e.data.refundAmount) || e.data.refundAmount === "") {
      let notification = {
        variant: "danger",
        msg: "Invalid value. Please enter a number",
      };
      appDispatch({ type: "notification", value: notification });
      let updatedRefundAmount = gridData.map((refundAmt) => {
        if (refundAmt.invoiceId === e.data.invoiceId)
          return { ...refundAmt, refundAmount: 0 };
        return { ...refundAmt };
      });
      setGridData(updatedRefundAmount);
    } else {
      let updatedRefundAmount = gridData.map((refundAmt) => {
        if (refundAmt.invoiceId === e.data.invoiceId) {
          return { ...refundAmt, refundAmount: parseInt(e.data.refundAmount) };
        }
        return { ...refundAmt };
      });
      setGridData(updatedRefundAmount);
    }
  };

  const getRefundAmountValueTotal = () => {
    let total = 0;
    for (let i = 0; i < gridData.length; i++) {
      if (!isNaN(gridData[i].refundAmount)) {
        total += gridData[i].refundAmount;
      }
    }
    return total;
  };

  const onCancel = () => {
    reset();
    setSelectedCustomer(null);
    setCustomerDetails(null);
    setSelectedLedger(null);
    setValue("paymentAccountId", null);
    setGridData([]);
  };

  const onSubmit = (values) => {
    let data = gridData.map((refundAmt) => {
      return {
        creditId: refundAmt.invoiceId,
        refundAmount: refundAmt.refundAmount,
        depositTo: values.paymentAccountId,
      };
    });
    editRefundAmount.mutate({
      customerId: selectedCustomer?.customerAccountId,
      refundAmount: data,
    });
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setValue("paymentAccountId", e.ledgerId);
    setOpenLedgerSelection(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid-page">
            <div className="page-title page-title-editable">
              <div>Refund Credit Memo</div>
              <div className="d-flex justify-content-between ">
                <button
                  className="import-btn"
                  type="button"
                  onClick={exportToExcel}
                  disabled={disabledExportBtn || gridData?.length === 0}
                >
                  <img src={exportIcon} className="me-2" alt="" />
                  Export to Excel
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary no-border"
                  onClick={onCancel}
                  disabled={!isDirty}
                >
                  Reset{" "}
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={!isDirty}
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="grid-container has-filters">
                <div className="filters">
                  <div className="row align-items-baseline">
                    <div className="col-lg-4 col-md-8 col-sm-8">
                      <div className="d-flex align-items-center form-group">
                        <label className="me-3">Customer No.</label>
                        <Lookup
                          onButtonClicked={() => setOpenSelection(true)}
                          inputName="customerAccountId"
                          initialData={customers?.data?.map((item) => ({
                            ...item,
                            id: item.customerAccountId,
                            name: item.accountNumber,
                          }))}
                          onSelection={onCustomerSelected}
                          inputValue={selectedCustomer?.accountNumber}
                        />
                      </div>
                    </div>
                    <div className="col px-0">
                      <label className="label me-3">Company</label>
                      <span>{customerDetails?.billingInfo.company}</span>
                    </div>
                    <div className="col px-0">
                      <label className="label me-3">Balance</label>
                      <span>
                        {formatNumber(customerDetails?.ledger?.balance)}
                      </span>
                    </div>
                    <div className="col px-0">
                      <label className="label me-3">Open Credit</label>
                      <span>{formatNumber(customerDetails?.openCredits)}</span>
                    </div>
                    <div className="col px-0">
                      <label className="label me-3">Total Credit Refund</label>
                      <span>{formatNumber(getRefundAmountValueTotal())}</span>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col-lg-3">
                      <label>
                        Deposit Account <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenLedgerSelection(true)}
                        inputName="paymentAccountId"
                        isRequired={true}
                        initialData={ledgers?.data?.map((item) => ({
                          ...item,
                          id: item.ledgerId,
                          name: item.accountName,
                        }))}
                        onSelection={onLedgerSelection}
                        inputValue={selectedLedger?.accountName}
                      />
                      {errors.paymentAccountId && (
                        <span className="text-danger">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <AgGrid
                    columns={creditMemoColumns}
                    data={gridData}
                    exportToExcel={exportToExcelClicked}
                    setExportToExcelClicked={setExportToExcelClicked}
                    setDisabledExportBtn={setDisabledExportBtn}
                    setIsLoadingGridExport={setIsLoadingGridExport}
                    fileName="Refund Credit Memo List Report"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={customers?.data}
        columns={accountNumberColumns}
        setRowClicked={onCustomerSelected}
      />
      <SelectionModal
        modal="account"
        showModal={openLedgerSelection}
        setShowModal={setOpenLedgerSelection}
        data={ledgers?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
    </>
  );
};

export default RefundCreditMemo;
