import { QueryResult } from "components/Common.models";
import api from "components/data/AxiosClient";
import {
  CustomerAccount,
  CustomerInfoDTO,
  CustomerInitData,
  CustomerCreditMemo,
  CustomerPayment,
} from "./Customers.models";

const customersURL = "customers";

export const getCustomersInit = async (): Promise<CustomerInitData> => {
  const response = await api.get<CustomerInitData>("customers/init");
  return response.data;
};

export const getCustomers = async (
  params
): Promise<QueryResult<CustomerAccount>> => {
  let extraParams = "";
  if (params.PageSize) {
    extraParams += `&PageSize=${params.PageSize}`;
    extraParams += `&PageStart=${
      isNaN(params.PageStart) ? 0 : params.PageStart
    }`;
  }
  if (params.hubId) extraParams += `&hubId=${params.hubId}`;
  if (params.accountTypeId)
    extraParams += `&accountTypeId=${params.accountTypeId}`;
  if (params.activeOnly !== null && params.activeOnly !== undefined)
    extraParams += `&activeOnly=${params.activeOnly}`;
  if (params?.searchQuery) extraParams += `&searchQuery=${params?.searchQuery}`;
  const response = await api.get<QueryResult<CustomerAccount>>(
    `${customersURL}?${extraParams}`
  );
  return response.data;
};

export const createCustomer = async (
  data: CustomerInfoDTO
): Promise<CustomerInfoDTO> => {
  const response = await api.post<CustomerInfoDTO>(customersURL, data);
  return response.data;
};

export const editCustomer = async (
  data: CustomerInfoDTO
): Promise<CustomerInfoDTO> => {
  const response = await api.put<CustomerInfoDTO>(
    `${customersURL}/${data.customerAccountId}`,
    data
  );
  return response.data;
};

export const getCustomerById = async (id: number) => {
  const response = await api.get<CustomerInfoDTO>(`${customersURL}/${id}`);
  return response.data;
};

export const deleteCustomer = async (id): Promise<boolean> => {
  const response = await api.delete<boolean>(`${customersURL}/${id}`);
  return response.data;
};

export const getCustomerCreditMemos = async (
  customerId: number
): Promise<QueryResult<CustomerCreditMemo>> => {
  const response = await api.get<QueryResult<CustomerCreditMemo>>(
    `/invoices?invType=2&balanceState=2&customerId=${customerId}`
  );
  return response.data;
};

export const updateCustomerRefundAmount = async (
  data
): Promise<CustomerCreditMemo> => {
  const response = await api.post<CustomerCreditMemo>(
    `/invoices/credit-memo/refund/${data.customerId}`,
    data.refundAmount
  );
  return response.data;
};

export const getCustomerReceipts = async (
  id: number
): Promise<QueryResult<CustomerPayment>> => {
  const response = await api.get<QueryResult<CustomerPayment>>(
    `/invoices/receipts?customerId=${id}`
  );
  return response.data;
};
