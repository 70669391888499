import { useQuery } from "react-query";
import AgGrid from "elements/AgGrid";
import { getTaxSettings } from "./PayrollServices/payrollServices";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import { useEffect, useState } from "react";

const TaxSettings = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data } = useQuery(["taxsettings", filter], getTaxSettings);

  const columns = [
    {
      field: "fromIncome",
      headerName: "From Income",
    },
    {
      field: "toIncome",
      headerName: "To Income",
    },
    {
      field: "taxRate",
      headerName: "Tax Rate",
      cellRenderer: (params) => params.value + "%",
    },
  ];

  const onEdit = () => {
    history.push(AppUrls.add_tax_settings);
  };

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Tax Settings</p>
          <div>
            <button className="btn btn-primary" type="button" onClick={onEdit}>
              Edit
            </button>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid-container">
            <div className="grid">
              <AgGrid columns={columns} data={data?.data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaxSettings;
