import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import BaseInvSetupItem from "./BaseInvSetupItem";
import { ItemBin } from "./InventorySetup.model";
import { createBin, editBin, getBins } from "./InventorySetup.services";

const NewItemBin = ({ match }) => {
  const history = useHistory();

  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const { error: errorLoadingData } = useQuery<any, ErrorResponse>(
    "get_bins",
    getBins
  );

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { id, name, description } = location.state;
      setSelectedId(id);
      setValue("name", name);
      setValue("description", description);
    }
  }, [location.state]);

  const formMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (errorLoadingData?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingData.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingData, appDispatch]);

  const addBin = useMutation(createBin, {
    async onSuccess() {
      queryClient.invalidateQueries("get_bins");
      let notification = {
        variant: "success",
        msg: `Item bin ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_bins);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item bin"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateBin = useMutation(editBin, {
    async onSuccess() {
      queryClient.invalidateQueries("get_bins");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_bins);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing item bin"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (values) => {
    const binData: ItemBin = {
      itemBinId: selectedId ? selectedId : 0,
      binId: values.name,
      description: values.description,
    };
    if (!selectedId) {
      addBin.mutate(binData);
    } else {
      updateBin.mutate(binData);
    }
  };

  const resetForm = () => {
    reset();
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addBin.isLoading || updateBin.isLoading,
    });
  }, [addBin.isLoading, updateBin.isLoading, appDispatch]);

  return (
    <>
      <div className="static-page">
        {" "}
        <FormProvider {...formMethods}>
          <form id="new-method-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="  page-title page-title-editable">
              <div className="d-flex flex-row">
                <div className="back-btn">
                  <img
                    src={backArrow}
                    alt="back arrow"
                    onClick={() => history.push(AppUrls.item_bins)}
                  />
                </div>
                <div>
                  <p className="">Item Bin</p>
                </div>
              </div>
              <div className="d-flex" style={{ marginLeft: "10px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline custom-outline"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  {!selectedId ? "Add Bin" : "Save Changes"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="row h-100">
                {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                  checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                  <div className="">
                    <BaseInvSetupItem
                      nameTitle={!selectedId ? "Bin Name" : "Bin Name"}
                      btnTitle={!selectedId ? "Add Bin" : "Save Changes"}
                      cancelBtnTitle="Cancel"
                      resetData={resetForm}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default NewItemBin;
