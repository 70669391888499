import { Modal } from 'react-bootstrap';
import close from 'img/close.svg';
import { useMutation } from 'react-query';
import DispatchContext from 'context/DispatchContext'
import { useContext } from 'react';
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { notificationMessage } from 'global/helpers';
import { useForm } from 'react-hook-form';
import { createHub } from 'pages/Inventory/Inventory/InventoryServices/Inventory.services';

type Props = {
    onConfirm: (id: number) => void,
    onClose: () => void,
    showModal: boolean,
};

const HubModal = (props: Props) => {

    const appDispatch = useContext(DispatchContext);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onCloseClicked = () => {
        props.onClose();
    }

    const addItem = useMutation(createHub, {
        async onSuccess(data) {
            let notification = {
                variant: "success",
                msg: `Hub ${CREATE_SUCCESS_MSG}`
            };
            appDispatch({ type: "notification", value: notification });
            reset()
            props.onConfirm(Number(data.hubId))
        },
        onError(error) {
            let notification = { variant: "danger", msg: notificationMessage(error, "problem adding hub") };
            appDispatch({ type: "notification", value: notification });
        }
    });

    const onSubmit = (data) => {
        addItem.mutate({ ...data, isActive: true })
    }

    return (
        <Modal className="confirmation-modal" show={props.showModal} onHide={() => props.onClose()} centered>
            <div className="d-flex justify-content-between align-items-center mx-4 my-3">
                <div className='confirmation-modal-title'>Add Hub</div>
                <img
                    src={close}
                    alt=''
                    className='confirmation-modal-close'
                    onClick={onCloseClicked}
                />
            </div>
            <div className="mx-4">
                <Modal.Body className='px-0 py-0'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label>
                                Hub Id
                            </label>
                            <input className='form-control' {...register("hubId", { required: true })} />
                        </div>
                        {errors["hubId"] && (
                            <p className="text-danger">This field is required</p>
                        )}
                        <div className="form-group mt-3">
                            <label>
                                Location Description
                            </label>
                            <input className='form-control' {...register("description", { required: true })} />
                        </div>
                        {errors["description"] && (
                            <p className="text-danger">This field is required</p>
                        )}
                        <div className='d-flex justify-content-between mt-3'>
                            <button className='btn btn-outline-primary ms-0' type='button' onClick={() => props.onClose()}>
                                Cancel
                            </button>
                            <button
                                className={`btn btn-primary`}
                                type="submit"
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default HubModal;