import React, { useState, useContext } from "react";
import AgGrid from "elements/AgGrid";
import addIcon from "../../../../img/add-icon.svg";
import { checkIfAllowed } from "global/helpers";
import { Link, useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import { Tabs, Tab } from "react-bootstrap";
import StateContext from "context/StateContext";
import { useQuery } from "react-query";
import { getAllBanners, getAllStandOut } from "../Portal.services";

export default function EcomDetails({ match }) {
  const appState = useContext(StateContext);

  const [activeTab, setActiveTab] = useState<string>("carousel");
  const { data: bannersData } = useQuery("ecomBanners", getAllBanners);
  const { data: soData } = useQuery("ecomStandout", getAllStandOut);

  const columns = [
    { headerName: "Title", field: "title" },
    { headerName: "Description", field: "subTitle" },
    { headerName: "Image", field: "imageUrl" },
    { headerName: "Button Link", field: "actionButtonUrl" },
    { headerName: "Button Label", field: "actionButtonLabel" },
  ];

  const BannersData =
    bannersData?.map((banner) => ({
      id: banner.id,
      title: banner.title,
      subTitle: banner.subTitle,
      imageUrl: banner.imageUrl,
      actionButtonUrl: banner.actionButtonUrl,
      actionButtonLabel: banner.actionButtonLabel,
    })) || [];
  const standOutData =
    soData?.map((data) => ({
      id: data.id,
      title: data.title,
      img: data.imageUrl,
    })) || [];

  const columns1 = [
    { headerName: "Title", field: "title" },
    { headerName: "Icon Image", field: "img" },
  ];

  const history = useHistory();
  const onRowDoubleClickedSo = (e) => {
    const url = `${AppUrls.Content_Management_System_so_new}`;

    history.push({
      pathname: url,
      state: {
        id: e.id,
        title: e.title,
        imageUrl: e.img,
      },
    });
  };
  const onRowDoubleClickedSs = (e) => {
    const url = `${AppUrls.Content_Management_System_ss_new}`;
    history.push({
      pathname: url,
      state: {
        id: e.id,
        subTitle: e.subTitle,
        title: e.title,
        imageUrl: e.imageUrl,
        actionButtonUrl: e.actionButtonUrl,
        actionButtonLabel: e.actionButtonLabel,
      },
    });
  };
  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <p>Ecom Setup</p>
          <div>
            {" "}
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <Link
                to={
                  activeTab === "carousel"
                    ? AppUrls.Content_Management_System_ss_new
                    : activeTab === "standOut"
                    ? AppUrls.Content_Management_System_so_new
                    : ""
                }
                className="btn btn-primary"
              >
                <img src={addIcon} className="me-2" alt="" />
                Add New
              </Link>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <Tabs activeKey={activeTab} onSelect={(e) => setActiveTab(e)}>
              <Tab
                eventKey="carousel"
                title={<div className="tab-inner-title">Slide Show</div>}
              >
                <div className="h-full">
                  <AgGrid
                    columns={columns}
                    data={BannersData}
                    setrowClicked={onRowDoubleClickedSs}
                    autoSize={true}
                  />
                </div>
              </Tab>
              <Tab
                eventKey="standOut"
                title={<div className="tab-inner-title">Stand Out</div>}
              >
                <div className="h-full">
                  <AgGrid
                    columns={columns1}
                    data={standOutData}
                    setrowClicked={onRowDoubleClickedSo}
                    autoSize={true}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
