import * as AppUrls from "constants/AppUrls";
import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import Lookup from "elements/Lookup";
import queryString from "query-string";
import { FormProvider, useForm } from "react-hook-form";
import {
  getRmaDetails,
  getRmaList,
  receiveRma,
} from "./RmaServices/RmaServices";

enum RMAStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Voided = 4,
  Closed = 5,
  Received = 6,
  Credited = 7,
  Refunded = 8,
  "Partially Received" = 9,
}

const ReceiveRma = () => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const history = useHistory();
  const selectedItem = useRef(null);
  const gridItems = useRef([]);
  const serialType = useRef(null);
  const appDispatch = useContext(DispatchContext);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedRma, setSelectedRma] = useState(null);
  const [showSerialSelection, setShowSerialSelection] =
    useState<boolean>(false);
  const [rowChecked, setRowChecked] = useState([]);

  const { search } = useLocation();
  const { rmaId } = queryString.parse(search);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = methods;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data: rmaList } = useQuery(["rma", filter], () =>
    getRmaList({ params: filter.params ?? "" })
  );

  const { data: rmaData, mutate } = useMutation(getRmaDetails, {
    onSuccess(res) {
      gridItems.current = res.items;
      if (!selectedRma) setSelectedRma(res);
    },
  });

  useEffect(() => {
    if (rmaId) {
      setValue("rmaId", rmaId, { shouldDirty: true });
      mutate({ queryKey: [rmaId] });
    }
  }, [rmaId]);

  const receiveMutation = useMutation(receiveRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA received successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.customer_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem receiving rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onBackClick = () => {
    history.push(AppUrls.customer_rma);
  };

  const columns = [
    {
      headerName: "",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
      minWidth: 40,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
      minWidth: 140,
    },
    {
      field: "description",
      headerName: "Item Description",
      minWidth: 160,
    },
    {
      field: "quantity",
      headerName: "RMA QTY",
      minWidth: 130,
    },
    {
      field: "price",
      headerName: "Price",
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.price?.toFixed(2) ?? "0.00"}`;
      },
      minWidth: 100,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      cellRendererFramework: (params) =>
        formatNumber(params.data.quantity * params.data.price, currencySymbol),
      minWidth: 120,
    },
    {
      field: "receivedQuantity",
      headerName: "Previously Received QTY",
      minWidth: 150,
    },
    {
      field: "returnInv",
      headerName: "Return to Inv.",
      editable: true,
      minWidth: 140,
    },
    {
      field: "returnSerials",
      headerName: "Return Serials",
      cellRendererFramework: (params) => {
        return params?.data && params?.data?.serials.length > 0 ? (
          <button
            type="button"
            className="btn btn-link text-decoration-none ms-0 ps-0"
            onClick={() => addSerial(params.data, 1)}
          >
            Add Serial
          </button>
        ) : (
          "-"
        );
      },
      minWidth: 150,
    },
    {
      field: "writeOff",
      headerName: "Write Off",
      editable: true,
      minWidth: 120,
    },
    {
      field: "writeSerials",
      headerName: "Write Off Serials",
      cellRendererFramework: (params) => {
        return params?.data && params?.data?.serials.length > 0 ? (
          <button
            type="button"
            className="btn btn-link text-decoration-none ms-0 ps-0"
            onClick={() => addSerial(params.data, 2)}
          >
            Add Serial
          </button>
        ) : (
          "-"
        );
      },
      minWidth: 160,
    },
  ];

  const rmaColumns = [
    {
      field: "rmaNumber",
      headerName: "RMA No.",
    },
    {
      field: "customerNumber",
      headerName: "Customer No.",
    },
    {
      field: "orderNumber",
      headerName: "So No.",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => RMAStatus[params.value],
    },
    {
      field: "rmaDate",
      headerName: "RMA Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "RMA Amount",
      cellRenderer: (params) => formatNumber(params.value, currencySymbol),
    },
  ];

  const onRmaSelected = (rma) => {
    if (rma.status === 2 || rma.status === 9) {
      setValue("rmaId", rma.rmaId, { shouldDirty: true });
      setSelectedRma(rma);
      mutate({ queryKey: [rma.rmaId] });
      setOpenSelection(false);
      selectedItem.current = null;
    } else {
      let notification = {
        variant: "danger",
        msg: "Only approved and partially received rmas can be selected",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const addSerial = (values, type) => {
    serialType.current = type;
    selectedItem.current = values.rmaItemId;
    setShowSerialSelection(true);
  };

  const selectSerial = (items) => {
    gridItems.current = gridItems.current.map((item) => {
      if (item.rmaItemId === selectedItem.current)
        return { ...item, returnSerials: items.map((i) => i.id) };
      return { ...item };
    });
    setShowSerialSelection(false);
  };

  const onSubmit = (values) => {
    let checkedItems = rowChecked.filter((n) =>
      rowChecked.some((n2) => n.rmaItemId === n2.rmaItemId)
    );
    if (checkedItems.length === 0) {
      let notification = {
        variant: "danger",
        msg: "Please select items to receive from the table",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (checkedItems.some((item) => !item.returnInv && !item.writeOff)) {
      let notification = {
        variant: "danger",
        msg: "Please choose item quantity to return",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      checkedItems.some(
        (item) =>
          Number(item.returnInv ?? 0) + Number(item.writeOff ?? 0) >
          item.quantity
      )
    ) {
      let notification = {
        variant: "danger",
        msg: "Invalid quantity to return entered",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      checkedItems.some(
        (item) =>
          item.serials?.length > 0 &&
          item.serials?.length !== item.returnSerials?.length
      )
    ) {
      let notification = {
        variant: "danger",
        msg: "Please add serials to selected items",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      if (
        checkedItems.some(
          (item) =>
            Number(item.returnInv ?? 0) + Number(item.writeOff ?? 0) <
            item.quantity
        )
      ) {
        setOpenConfirmation(true);
      } else {
        let data = {
          ...values,
          receiveDate: new Date(),
          close: true,
          rmaItemsReceiveEntries: checkedItems.map((item) => ({
            rmaItemId: item.rmaItemId,
            returnQuantity: item.returnInv ?? 0,
            writeOffQuantity: item.writeOff ?? 0,
            returnSerials: item.returnSerials ?? [],
          })),
        };
        receiveMutation.mutate(data);
      }
    }
  };

  const onConfirmation = (close) => {
    let values = getValues();
    let checkedItems = rowChecked.filter((n) =>
      rowChecked.some((n2) => n.rmaItemId === n2.rmaItemId)
    );
    setOpenConfirmation(false);
    let data = {
      ...values,
      receiveDate: new Date(),
      close: close,
      rmaItemsReceiveEntries: checkedItems.map((item) => ({
        rmaItemId: item.rmaItemId,
        returnQuantity: item.returnInv ?? 0,
        writeOffQuantity: item.writeOff ?? 0,
        returnSerials: item.returnSerials ?? [],
      })),
    };
    receiveMutation.mutate(data);
  };

  let itemSerials = gridItems.current?.find(
    (item) => item.rmaItemId === selectedItem.current
  );

  const onCancel = () => {
    reset();
    setSelectedRma(null);
    gridItems.current = [];
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Receive RMA
            </div>
            <div>
              <button
                className="btn btn-outline-primary no-border"
                type="button"
                onClick={onCancel}
                disabled={!isDirty}
              >
                Reset
              </button>
              <button className="btn btn-success" disabled={!isDirty}>
                Receive
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row  gx-5">
                <div className="col-lg-8 col-sm-12">
                  <div className="row mb-5">
                    <div className="col-sm-3">
                      <label>
                        RMA No. <span className="text-danger">*</span>
                      </label>
                      <Lookup
                        onButtonClicked={() => setOpenSelection(true)}
                        inputName="rmaId"
                        initialData={rmaList?.data?.map((item) => ({
                          ...item,
                          id: item.rmaId,
                          name: item.rmaNumber,
                        }))}
                        onSelection={onRmaSelected}
                        inputValue={selectedRma?.rmaNumber}
                      />
                    </div>
                  </div>
                  <div className="row gx-5 gy-4">
                    <div className="col-lg-3 col-sm-12">
                      <label>Customer No.</label>
                      <div>{rmaData?.accountNumber}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Company</label>
                      <div>{rmaData?.shippingInfo?.company}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Origin</label>
                      <div>{rmaData?.origin === 1 ? "Sytem" : "Web"}</div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                      <label>Status</label>
                      <div>{RMAStatus[rmaData?.status]}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row mt-4">
                    <div className="col-lg-6 col-sm-12 px-4">
                      <div className="d-flex">
                        <div className="text-secondary">TOTAL QTY</div>
                        <div className="flex-grow-1 text-center">
                          {rmaData?.totalQuantity}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="d-flex">
                        <div className="text-secondary">TOTAL ITEMS</div>
                        <div className="flex-grow-1 text-center">
                          {rmaData?.totalItems}
                        </div>
                      </div>
                    </div>
                    <div className="px-4">
                      <hr />
                    </div>
                    <div className="col-sm-12">
                      <div className="section mt-3 p-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="fs-5">ORDER TOTAL</div>
                          <div className="text-success fs-4">
                            {rmaData ? (
                              <span>
                                {formatNumber(rmaData.subtotal, currencySymbol)}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-450 mt-5">
                <AgGrid
                  data={gridItems.current}
                  columns={columns}
                  multipleSelection={true}
                  setRowChecked={setRowChecked}
                  autoSize={true}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal="RMA"
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={rmaList?.data.filter((e) => e.status === 2 || e.status === 9)}
        columns={rmaColumns}
        setRowClicked={onRmaSelected}
        searchProp={true}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
      />
      <ListSelectionModal
        showModal={showSerialSelection}
        title="Serials"
        data={
          selectedItem.current
            ? itemSerials?.serials?.map((serial) => {
                return { title: serial.serialNo, id: serial.serialId };
              })
            : []
        }
        selectedValues={selectedItem.current ? itemSerials?.returnSerials : []}
        onSelectData={selectSerial}
        onExit={() => setShowSerialSelection(false)}
        selectValue={itemSerials?.returnSerials?.length}
        isInvoice={true}
        maxValue={itemSerials?.serials?.length ?? 0}
      />
      <ConfirmationModal
        title="Partially Receiving RMA"
        message="Would you like to close RMA?"
        onClose={() => onConfirmation(false)}
        onConfirm={() => onConfirmation(true)}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={openConfirmation}
      />
    </>
  );
};

export default ReceiveRma;
