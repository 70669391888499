import { getBaseCurrency } from "components/currency";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AgGrid from "elements/AgGrid";
import {
  dateFormmater,
  dateFormmaterNoTime,
  getCurrencyByCode,
} from "global/helpers";
import exportIcon from "img/export.svg";
import { getSerialNumbersList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";

const SerialNumbers = () => {
  const history = useHistory();
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [itemNumber, setItemNumber] = useState<string>("");

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const location = useLocation();

  const dataMutation = useMutation(getSerialNumbersList);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "serialNo",
      headerName: "Serial Number",
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "hubkey",
      headerName: "Hub ID",
    },
    {
      field: "isAvailable",
      headerName: "Is Available",
      valueGetter: (params) => {
        return params.data.isActive ? "Inventory" : "Issued";
      },
      cellRenderer: (params) => {
        let status = params.data.isAvailable;
        return `
              <div class="${status ? "active-status" : "disabled-status"}">
                ${status ? "Inventory" : "Issued"}
              </div>
            `;
      },
    },
    {
      field: "receiveDate",
      headerName: "Receive Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.receiveDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.receiveDate === null
            ? ""
            : dateFormmater(params.data.receiveDate);
        return `
                  <div>
                    ${date}
                  <div>
                `;
      },
    },
    {
      field: "soldDate",
      headerName: "Sold Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.soldDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.soldDate === null
            ? ""
            : dateFormmater(params.data.soldDate);
        return `
                  <div>
                    ${date}
                  <div>
                `;
      },
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.createdDate);
      },
      cellRenderer: (params) => {
        let date =
          params.data.createdDate === null
            ? ""
            : dateFormmater(params.data.createdDate);
        return `
                  <div>
                    ${date}
                  <div>
                `;
      },
    },
    {
      field: "itemCost",
      headerName: "Item Cost",
      valueGetter: (params) => {
        return `${currencySymbol} ${
          params.data?.itemCost?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "remark",
      headerName: "Remark",
    },
    {
      field: "createdBy",
      headerName: "Created By",
    },
    {
      field: "warranty",
      headerName: "Warranty",
    },
  ];

  const [filters, setFilters] = useState<any>({});

  const filterSerialNumbers = () => {
    if (serialNumber || itemNumber) {
      setFilters({
        serialNumber: serialNumber,
        itemNumber: itemNumber,
      });
    } else {
      setFilters({});
    }
  };

  useEffect(() => {
    if (location.state && appState.gridFilters) {
      if (appState.gridFilters.serialNumber)
        setSerialNumber(appState.gridFilters.serialNumber);
      if (appState.gridFilters.itemNumber)
        setItemNumber(appState.gridFilters.itemNumber);
    }
  }, [location, appState.gridFilters]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      filterSerialNumbers();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [serialNumber, itemNumber]);

  const onRowDoubleClicked = (e) => {
    appDispatch({ type: "filter", value: { serialNumber, itemNumber } });
    history.push(`${AppUrls.serial_numbers}/${e.serialNo}`);
  };

  const clearForm = () => {
    setSerialNumber("");
    setItemNumber("");
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Serial Numbers</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <div className="d-flex mb-4">
              <div className="align-items-center d-flex">
                <label className="col-xs-2 label no-gutters me-3">
                  Serial Number
                </label>
                <div className="me-3">
                  <input
                    name="itemDetail"
                    onChange={(e) => setSerialNumber(e.target.value)}
                    value={serialNumber}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="align-items-center d-flex">
                <label className="col-xs-2 label no-gutters me-3">
                  Item Number
                </label>
                <div className="me-3">
                  <input
                    name="itemDetail"
                    onChange={(e) => setItemNumber(e.target.value)}
                    value={itemNumber}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline"
                  onClick={clearForm}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              fetchMethod={dataMutation}
              data={dataGrid}
              setDataGrid={setDataGrid}
              filters={filters}
              setrowClicked={onRowDoubleClicked}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              fileName="Serials List Report"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerialNumbers;
