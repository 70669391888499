import { DropDownOption } from "components/Common.models";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { ErrorResponse } from "components/data/Errors.model";
import Notes from "components/note/Notes";
import { Note } from "components/note/note.model";
import * as AppUrls from "constants/AppUrls";
import {
  CREATE_SUCCESS_MSG,
  REQUIRED_FIELDS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import Lookup from "elements/Lookup";
import {
  checkIfAllowed,
  dateFormmater,
  formatInput,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { getLeafs, getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import { getBillPaymentsList } from "pages/Invoices/InvoicesServices/InvoicesServices";
import { getCurrency } from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import { useContext, useEffect, useState } from "react";
import { Badge, Form, Tab, Tabs } from "react-bootstrap";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { billsTypeEnum } from "../VendorEnum";
import {
  VendorInvoiceBill,
  VendorModelDTO,
  VendorTypeModel,
} from "../Vendors.models";
import {
  createVendor,
  getVendorById,
  getVendorDebit,
  getVendorInvoiceBills,
  getVendorTypes,
  updateVendor,
} from "../Vendors.services";
import BillPayment from "./BillPayment";
import DebitCredit from "./DebitCredit";
import GeneralInfoTab from "./GeneralInfoTab";
import InvoiceBills from "./InvoiceBills";
import PurchaseOrdersTab from "./PurchaseOrdersTab";
import VendorModal from "./VendorModal";
import { getCurrencyByCode } from "global/helpers";
import { getBaseCurrency } from "components/currency";
const VendorDetails = ({ match }) => {
  const history = useHistory();
  const [notes, setNotes] = useState<Note[]>([]);
  const [vendorData, setVendorData] = useState<VendorModelDTO>(null);
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showTypeModal, setShowTypeModal] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isAccountDisabled, setIsAccountDisabled] = useState<boolean>(true);
  const [vendTypesOpts, setVendTypesOpts] = useState([]);
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();

  const {
    error: errorLoadingVendorTypes,
    data: vendorTypes,
    refetch,
  } = useQuery<VendorTypeModel[], ErrorResponse>(
    "get_vendorTypes",
    getVendorTypes
  );

  const { data: leafs } = useQuery("leafs", () =>
    getLeafs({ ledgerType: LedgerTypeEnum.AccountsPayable })
  );

  const { data: paymentAccounts } = useQuery("expenses", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Equity })
  );

  const receiveInvoices = useMutation(getBillPaymentsList);

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    if (vendorTypes) {
      setVendTypesOpts(
        vendorTypes
          .filter((v) => v.isActive)
          .map((vt) => {
            return { label: vt.name, value: vt.vendorTypeId };
          })
      );
    }
  }, [vendorTypes]);

  useEffect(() => {
    if (errorLoadingVendorTypes?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingVendorTypes.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingVendorTypes, appDispatch]);

  const methods = useForm<VendorModelDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { ...vendorData, isActive: true },
  });
  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    handleSubmit,
    reset,
    register,
    setValue,
  } = methods;
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);

  const getVendorDetails = useMutation(getVendorById, {
    async onSuccess(result) {
      if (result) {
        setNotes(result.notes);
        setVendorData(result);
        reset(result);
      }
    },
  });

  const { data: debitCredit } = useQuery([id], getVendorDebit, {
    enabled: !!id,
  });

  useQuery("currencies", getCurrency, {
    select(data) {
      return data.map((currency) => {
        const detailedCurrency = getCurrencyByCode(currency.currencyCode);
        return { label: detailedCurrency.name, value: currency.currencyCode };
      });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: getVendorDetails.isLoading });
  }, [getVendorDetails.isLoading, appDispatch]);

  useEffect(() => {
    if (id) {
      getVendorDetails.mutate(id);
      receiveInvoices.mutate({ vendorId: id });
      setIsDisabled(true);
    }
  }, [id]);

  const addVendor = useMutation(createVendor, {
    async onSuccess(result) {
      queryClient.invalidateQueries("get_vendorTypes");
      let notification = {
        variant: "success",
        msg: `Vendor ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(`${AppUrls.vendors}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding vendor"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editVendor = useMutation(updateVendor, {
    async onSuccess() {
      queryClient.invalidateQueries("get_vendorTypes");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsDisabled(true);
      getVendorDetails.mutate(id);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing vendor"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onNoteAdd = (data) => {
    let allNotes = [...notes];
    allNotes.push({
      ...data,
      createdDate: new Date(),
      id:
        allNotes.length > 0
          ? Math.max(...allNotes.map((o) => o.noteId || o.id)) + 1
          : 0,
    });
    setNotes(allNotes);
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onNoteUpdate = (data) => {
    let allNotes = [...notes];
    allNotes = allNotes.map((note) => {
      if ((note.id || note.noteId) === (data.id || data.noteId))
        return { ...data };
      return { ...note };
    });
    setNotes(allNotes);
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onNoteDelete = (data) => {
    let allNotes = [...notes];
    allNotes = allNotes.filter(
      (note) => (note.id || note.noteId) !== (data.id || data.noteId)
    );
    setNotes(allNotes);
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onSubmit = (data: VendorModelDTO) => {
    if (!id) {
      addVendor.mutate({
        ...data,
        startingBalance: data.startingBalance
          ? parseFloat(
              data.startingBalance?.toString().replace(/,/g, "")
            ).toString()
          : "0",
      });
    } else {
      editVendor.mutate({
        ...data,
        vendorId: id,
        notes,
        creditLimit: data.creditLimit
          ? parseFloat(
              data.creditLimit?.toString().replace(/,/g, "")
            ).toString()
          : null,
      });
    }
  };

  const onBackClicked = () => {
    if (isDirty) {
      setShowConfirmModal(true);
    } else {
      history.push(AppUrls.vendors);
    }
  };

  const onEditClicked = (e) => {
    e.preventDefault();
    setIsDisabled(false);
  };

  const cancelForm = () => {
    reset();
    if (id) {
      setIsDisabled(true);
    }
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: editVendor.isLoading || addVendor.isLoading,
    });
  }, [editVendor.isLoading, addVendor.isLoading]);

  const [billsType, setBillsType] = useState<DropDownOption>({
    value: billsTypeEnum["All Bills"],
    label: "All",
  });
  const [vendorBills, setVendorBills] = useState<VendorInvoiceBill[]>(null);

  const getInvoiceBills = useMutation(getVendorInvoiceBills, {
    async onSuccess(response) {
      setVendorBills(response?.data);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to edit refund amount"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    if (id && billsType) {
      getInvoiceBills.mutate({ vendorId: id, billsTypeId: billsType?.value });
    }
  }, [id, billsType]);

  const createLabel = () => <label>Add New</label>;

  const onCreateType = () => {
    setShowTypeModal(true);
  };

  const onTypeCreated = (id) => {
    setValue("vendorTypeId", id, { shouldDirty: true });
    refetch();
    setShowTypeModal(false);
  };

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("balancingAffectedAccountId", e.ledgerId, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div role="button" className="back-btn " onClick={onBackClicked}>
              <img src={backArrow} alt="back arrow" />
              {!id && "New "} Vendor {vendorData?.vendorNumber}
            </div>
            <div>
              {!isDisabled ? (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-primary no-border ${
                      !isDirty && !id && "text-muted"
                    }`}
                    onClick={cancelForm}
                    disabled={!isDirty && !id}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={!isDirty || isSubmitSuccessful}
                  >
                    {id ? "Save Changes" : "Create"}
                  </button>
                </>
              ) : (
                checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onEditClicked}
                  >
                    Edit
                  </button>
                )
              )}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="left-side-mid pe-5 ps-5 section bg-white">
                  <div className="row mb-4">
                    <div className="form-group col">
                      <label>
                        Vendor No <span className="text-danger">*</span>
                      </label>
                      {id ? (
                        <p>{vendorData?.vendorNumber}</p>
                      ) : (
                        <Form.Control
                          {...register("vendorNumber", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "The Vendor No cannot exceed 30 characters",
                            },
                          })}
                          name="vendorNumber"
                          className={
                            errors.vendorNumber
                              ? "form-control required_field"
                              : "form-control"
                          }
                          defaultValue={vendorData?.vendorNumber}
                        />
                      )}
                      {errors["vendorNumber"] && (
                        <span className="text-danger">
                          {errors.vendorNumber.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group col">
                      {isDisabled ? (
                        <div
                          className={
                            vendorData?.isActive
                              ? "active-status"
                              : "disabled-status"
                          }
                        >
                          {vendorData?.isActive ? "Active" : "Disabled"}
                        </div>
                      ) : (
                        <div className="form-group p-3">
                          <Controller
                            control={control}
                            name="isActive"
                            render={({ field: { onChange, value = true } }) => (
                              <>
                                <label
                                  className="switch"
                                  htmlFor="activeCheckbox"
                                >
                                  <input
                                    type="checkbox"
                                    id="activeCheckbox"
                                    name="isActive"
                                    onChange={() => onChange(!value)}
                                    checked={value}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                {value ? (
                                  <label className=" ps-3 ms-3 ">Active</label>
                                ) : (
                                  <label className=" ps-3 ms-3">
                                    {" "}
                                    Disabled
                                  </label>
                                )}
                              </>
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label>
                        Vendor Type <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="vendorTypeId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <CreatableSelect
                            onChange={(selectedOption: any) =>
                              onChange(selectedOption?.value)
                            }
                            isClearable
                            value={
                              vendTypesOpts.find(
                                (opt) => opt?.value === value
                              ) || null
                            }
                            name="vendorType"
                            isDisabled={isDisabled}
                            options={vendTypesOpts}
                            isValidNewOption={() => true}
                            formatCreateLabel={createLabel}
                            createOptionPosition="first"
                            onCreateOption={onCreateType}
                            className={errors.vendorTypeId && "required_field"}
                          />
                        )}
                      />
                      {errors["vendorTypeId"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="form-group col-sm-6">
                      <label>
                        Account Category <span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="accountingCategoryId"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(selectedOption: any) =>
                              onChange(selectedOption?.value)
                            }
                            isClearable
                            value={
                              leafs?.find((opt) => opt?.categoryId === value) ||
                              null
                            }
                            isDisabled={isDisabled}
                            options={leafs?.map((item) => {
                              return {
                                label: item.name,
                                value: item.categoryId,
                              };
                            })}
                            className={
                              errors.accountingCategoryId && "required_field"
                            }
                          />
                        )}
                      />
                      {errors["accountingCategoryId"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                  </div>
                  {!id && (
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Starting balance</label>
                          <input
                            {...register("startingBalance")}
                            className={"form-control"}
                            onChange={(e) =>
                              Number(e.target.value) > 0
                                ? setIsAccountDisabled(false)
                                : setIsAccountDisabled(true)
                            }
                            onBlur={(e) =>
                              setValue(
                                "startingBalance",
                                formatInput(e.target.value)
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Balancing affected account</label>
                          <Lookup
                            onButtonClicked={() => setOpenSelectionLedger(true)}
                            inputName="balancingAffectedAccountId"
                            initialData={paymentAccounts?.data?.map((item) => ({
                              ...item,
                              id: item.ledgerId,
                              name: item.accountName,
                            }))}
                            onSelection={onLedgerSelection}
                            inputValue={selectedLedger?.accountName}
                            isDisabled={isAccountDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row pt-4">
                    <div className="form-group col">
                      <label>Approved?</label>
                      {isDisabled ? (
                        <div
                          className={
                            vendorData?.isApproved
                              ? "active-status"
                              : "disabled-status"
                          }
                        >
                          {vendorData?.isApproved ? "Yes" : "No"}
                        </div>
                      ) : (
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="isApproved"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <label className="checkbox-inline">
                                  <input
                                    id="apvYes"
                                    name="approved"
                                    onChange={() => onChange(true)}
                                    checked={value}
                                    type="radio"
                                  />
                                  <label
                                    className="text-black ms-2"
                                    htmlFor="activeE"
                                  >
                                    Yes
                                  </label>
                                </label>
                                <label className="checkbox-inline ms-3">
                                  <input
                                    id="apvNo"
                                    name="approved"
                                    onChange={() => onChange(false)}
                                    checked={!value}
                                    type="radio"
                                  />
                                  <label
                                    className="text-black ms-2"
                                    htmlFor="disabledE"
                                  >
                                    No
                                  </label>
                                </label>
                              </>
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-group col">
                      <label>Preffered?</label>
                      {isDisabled ? (
                        <div
                          className={
                            vendorData?.isPreferred
                              ? "active-status"
                              : "disabled-status"
                          }
                        >
                          {vendorData?.isPreferred ? "Yes" : "No"}
                        </div>
                      ) : (
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="isPreferred"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <label className="checkbox-inline">
                                  <input
                                    id="prefYes"
                                    name="preferred"
                                    onChange={() => onChange(true)}
                                    disabled={isDisabled}
                                    checked={value}
                                    type="radio"
                                  />
                                  <label
                                    className="text-black ms-2"
                                    htmlFor="activeE"
                                  >
                                    Yes
                                  </label>
                                </label>
                                <label className="checkbox-inline ms-3">
                                  <input
                                    id="prefNo"
                                    name="preferred"
                                    onChange={() => onChange(false)}
                                    disabled={isDisabled}
                                    checked={!value}
                                    type="radio"
                                  />
                                  <label
                                    className="text-black ms-2"
                                    htmlFor="disabledE"
                                  >
                                    No
                                  </label>
                                </label>
                              </>
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-side-mid section ">
                  <div className="row pe-3 ps-3">
                    <div className="col-lg-6 col-sm-12">
                      <div className="form-group">
                        <label>Credit Limit</label>
                        <Form.Control
                          {...register("creditLimit")}
                          className="form-control vendor-txt"
                          name="creditLimit"
                          defaultValue={vendorData?.creditLimit}
                          disabled={isDisabled}
                          onBlur={(e) =>
                            setValue("creditLimit", formatInput(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    {id && (
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label>Date Added</label>
                          <p>{dateFormmater(vendorData?.dateAdded)}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row mb-4 pe-3 ps-3">
                    <div className="col-lg-6 col-sm-12">
                      <div className="border rounded p-3 bg-white">
                        <div>Balance</div>
                        <div className="text-success">
                          {formatNumber(
                            vendorData?.balance !== undefined
                              ? vendorData?.balance
                              : 0,
                            baseCurrency
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <div className="border rounded p-3 bg-white">
                        <div>Open PO Amount</div>
                        <div className="text-success">
                          {formatNumber(
                            vendorData?.onPO !== undefined
                              ? vendorData?.onPO
                              : 0,
                            baseCurrency
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <Tabs defaultActiveKey="GeneralInfo">
                  <Tab
                    eventKey="GeneralInfo"
                    title={<div className="tab-inner-title">General Info</div>}
                  >
                    <GeneralInfoTab
                      vendorData={vendorData}
                      isDisabled={isDisabled}
                    />
                  </Tab>
                  {id && (
                    <Tab
                      eventKey="PurchaseOrders"
                      title={
                        <div className="tab-inner-title">Purchase Orders</div>
                      }
                    >
                      <PurchaseOrdersTab vendorId={id} />
                    </Tab>
                  )}
                  {id && (
                    <Tab
                      eventKey="customerNotes"
                      title={
                        <div className="tab-inner-title">
                          Notes
                          <Badge pill className="text-secondary ms-2">
                            {notes.length}
                          </Badge>
                        </div>
                      }
                    >
                      <Notes
                        notes={notes}
                        onNoteDelete={onNoteDelete}
                        onNoteUpdate={onNoteUpdate}
                        onNoteAdd={onNoteAdd}
                        isDisabled={isDisabled}
                      />
                    </Tab>
                  )}
                  {id && (
                    <Tab
                      eventKey="InvoiceBills"
                      title={
                        <div className="tab-inner-title">
                          Invoice/Bills
                          <Badge pill className="text-secondary ms-2">
                            {vendorBills?.length}
                          </Badge>
                        </div>
                      }
                    >
                      <InvoiceBills
                        vendorId={id}
                        billsType={billsType}
                        setBillsType={setBillsType}
                        vendorBills={vendorBills}
                      />
                    </Tab>
                  )}
                  {id && (
                    <Tab
                      eventKey="DebitCredit"
                      title={
                        <div className="tab-inner-title">
                          Debit/Credit
                          <Badge pill className="text-secondary ms-2">
                            {debitCredit?.length}
                          </Badge>
                        </div>
                      }
                    >
                      <DebitCredit vendorDebit={debitCredit} />
                    </Tab>
                  )}
                  {id && (
                    <Tab
                      eventKey="Payment"
                      title={
                        <div className="tab-inner-title">
                          Payment
                          <Badge pill className="text-secondary ms-2">
                            {receiveInvoices?.data?.data?.length}
                          </Badge>
                        </div>
                      }
                    >
                      <BillPayment
                        vendorPayment={receiveInvoices?.data?.data}
                      />
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          history.push(AppUrls.vendors);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
      <VendorModal
        showModal={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        onConfirm={onTypeCreated}
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
    </>
  );
};

export default VendorDetails;
