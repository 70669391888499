import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StateContext from "context/StateContext";
import { Link, useLocation } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import logoimg from "img/BlueSeed-logo.png";
import { useQuery } from "react-query";
import { getNotifications } from "pages/EcommerceAdmin/Services/adminServices";
import { getUserProfile } from "pages/User/userServices";

const Header = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const appState = useContext(StateContext);
  const history = useHistory();
  const location = useLocation();

  const changeRoute = (url) => {
    history.push(url);
    setSearchTerm("");
  };

  const { data: userProfile } = useQuery("userProfile", getUserProfile);
  const { data: notifications, refetch } = useQuery(
    "notificationList",
    getNotifications,
    {
      select(data) {
        return data?.data?.filter((notification) => !notification.isSeen)
          .length;
      },
    }
  );

  useEffect(() => {
    if (location) refetch();
  }, [location, refetch]);

  let searchResults = appState.allowedUrls.filter((url) =>
    url.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="header">
      <div className="header-logo d-flex align-items-end">
        <img src={logoimg} alt="Blue Seed" />
        <span className="text-white ms-3">
          {userProfile?.data?.currentCompany}
        </span>
      </div>
      <div className="header-search">
        <div className="col-md-10 mx-auto">
          <div className="input-group">
            <span className="header-search-icon">
              <i className="fa fa-search"></i>
            </span>
            <input
              className="header-search-input ps-5"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              onBlur={() =>
                setTimeout(() => {
                  setSearchTerm("");
                }, 250)
              }
              value={searchTerm}
            />
            {searchTerm.length > 0 && (
              <>
                <button
                  className="header-close-icon btn bg-white"
                  onClick={() => setSearchTerm("")}
                >
                  <i className="fa fa-times"></i>
                </button>
                <div className="position-absolute top-100 ms-0 mt-1 bg-white w-100 p-1 border">
                  {searchResults.length > 0 ? (
                    searchResults.slice(0, 4).map((component) => (
                      <div key={component.url}>
                        <button
                          className="btn w-100 bg-white text-start route-btn ms-0"
                          onClick={() => changeRoute(component.url)}
                        >
                          {component.label}
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="p-2">No Results Found </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="header-user">
        <div className="header-user-content">
          {process.env.REACT_APP_ECOMMERCE_URL && (
            <a
              href={process.env.REACT_APP_ECOMMERCE_URL}
              target={"_blank"}
              rel="noreferrer"
              className="me-4"
            >
              <i className="fal fa-shopping-cart text-white"></i>
            </a>
          )}
          <Link
            to={AppUrls.ecommerce_updates}
            className="position-relative me-4"
          >
            {notifications && notifications > 0 ? (
              <span
                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger px-2"
                style={{ fontSize: "12px" }}
              >
                {notifications}
              </span>
            ) : (
              <></>
            )}
            <i className="fal fa-bell text-white"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
