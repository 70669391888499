//General  --- maxWidth 
export const smWidth=100 
export const sm_mWidth=125 
export const mWidth=150 
export const m_lgWidth=175 
export const lgWidth=200 
export const xlgWidth=250 

//Attributes --- maxWidth 
export const dateWidth=95
export const booleanWidth=110
export const smallIntWidth=120
export const balanceWidth=140
export const detailedDateWidth=250

//atributes based width
export const employeeName=170
export const employeeID=140
export const totalHrs=130
export const billingRate=130
export const totalTax=130
export const bankNumber=195
export const checkIn=130

//Description --- minWidth 
export const textWidth=300 

