import { getBaseCurrency } from "components/currency";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AgGrid from "elements/AgGrid";
import {
  checkIfAllowed,
  dateFormmaterNoTime,
  getCurrencyByCode,
} from "global/helpers";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getExpenses } from "./AccountingServices";
const ExpensesList = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const appState = useContext(StateContext);
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataMutation = useMutation("data", getExpenses);
  const { isLoading } = dataMutation;
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const columns = [
    {
      field: "number",
      headerName: "Expense #",
      resizable: true,
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      resizable: true,
      valueGetter: (params) => dateFormmaterNoTime(params.data.paymentDate),
    },
    {
      field: "amount",
      headerName: "Amount",
      resizable: true,
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const onRowClicked = (e) => {
    history.push(`${AppUrls.expenses}/${e.id}`);
  };

  const onAddExpense = () => {
    history.push(AppUrls.add_expenses);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(
      {},
      {
        onSuccess(result) {
          setDataGrid(result.data);
        },
      }
    );
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Expenses</p>
          <div>
            <button
              className="import-btn"
              type="button"
              onClick={exportToExcel}
              disabled={
                disabledExportBtn || isLoading || dataGrid?.length === 0
              }
            >
              <img src={exportIcon} className="me-2" alt="" />
              Export to Excel
            </button>
            {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
              <button className="btn btn-primary" onClick={onAddExpense}>
                Add Expense
              </button>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid-container">
            <AgGrid
              columns={columns}
              fetchMethod={dataMutation}
              data={dataGrid}
              setDataGrid={setDataGrid}
              setrowClicked={onRowClicked}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              fileName="Expenses List Report"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensesList;
