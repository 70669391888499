import api from "components/data/AxiosClient";

export interface Banner {
    title: string,
    subTitle: string,
    imageUrl: string,
    actionButtonLabel: string
    actionButtonUrl: string
}
export interface StandOut {
    title: string,
    imageUrl: string,
}

export const getAllBanners = async () => {
    const response = await api.get(`landing-page/all-banners`);
    return response.data;
  };
export const getAllStandOut = async () => {
    const response = await api.get(`/landing-page/all-standouts`);
    return response.data;
  };

export const createBanner = async (data: Banner) => {
    const response = await api.post<Banner>('landing-page/save-banner', data);
    return response.data;
};
export const createStandOut = async (data: StandOut) => {
    const response = await api.post<StandOut>('landing-page/save-standout', data);
    return response.data;
};
export const deleteBanner = async (id: Number) => {
    const response = await api.delete(`/landing-page/banner`, { params: { id } });
    return response.data;
  };
export const deleteStandOut = async (id: Number) => {
    const response = await api.delete(`/landing-page/standout`, { params: { id } });
    return response.data;
  };
  export const createBannerImg = async (data:File) => {
    const formData= new FormData()
    formData.append("image",data )
    const response = await api.post('/landing-page/upload-banner-image', formData);
    return response.data;
};  export const createSOImg = async (data:File) => {
  const formData= new FormData()
  formData.append("image",data )
  const response = await api.post('landing-page/upload-standout-image', formData);
  return response.data;
};