import { useContext, useEffect, useState } from "react";
// import { ReactComponent as ExcelIcon } from 'img/excel-icon.svg';
import { DropDownOption, QueryResult } from "components/Common.models";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { GridColumn } from "elements/AG.models";
import { checkIfAllowed, getCurrencyByCode } from "global/helpers";
import addIcon from "img/add-icon.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import {
  getInventoryHubsList,
  getInventoryItems,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";
import { Hub } from "../Inventory.models";
import CreateItemModal from "./CreateItemModal";

import DateGrid from "components/DateFilter/DateGrid";
import { getBaseCurrency } from "components/currency";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

let selectOptions = [
  { label: "All", value: 0 },
  { label: "Active", value: 1 },
  { label: "Disabled", value: 2 },
];

const InventoryList = ({ match }) => {
  const [selectedHub, setSelectedHub] = useState<DropDownOption>();
  const [selectedUnitType, setSelectedUnitType] = useState<DropDownOption>({
    label: "All",
    value: 1,
  });
  const [hubsOptions, setHubsOptions] = useState<DropDownOption[]>([]);
  const [gridData, setGridData] = useState(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [dateCategory, setDateCategory] = useState(null);
  const [activeOnly, setIsActiveOnly] = useState<number>(0);
  const [filter, setFilter] = useState({
    hubId: -1,
    itemType: 1,
    params: "",
  });
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const location = useLocation();
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const unitTypeOptions: DropDownOption[] = [
    {
      label: "All",
      value: 1,
    },
    {
      label: "Serialized",
      value: 2,
    },
    {
      label: "Non Serialized",
      value: 3,
    },
    {
      label: "Non Tangible",
      value: 4,
    },
  ];

  const columns: GridColumn[] = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
      minWidth: 260,
    },
    {
      headerName: "Status",
      field: "isActive",
      minWidth: 100,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRendererFramework: (params) =>
        params.value === "Active" ? (
          <div className="active-status">active</div>
        ) : (
          <div className="disabled-status">disabled</div>
        ),
    },
    {
      field: "quantityAtHand",
      headerName: "Qty/Hand",
    },
    {
      field: "quantityAvailable",
      headerName: "Qty Avail.",
    },
    {
      field: "price1",
      headerName: "Price 1",
      valueGetter: (params) => {
        return `${currencySymbol}${params.data?.price1?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "price2",
      headerName: "Price 2",
      valueGetter: (params) => {
        return `${currencySymbol}${params.data?.price2?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "price3",
      headerName: "Price 3",
      valueGetter: (params) => {
        return `${currencySymbol}${params.data?.price3?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "productLine",
      headerName: "Product Line",
    },
    {
      field: "itemClass",
      headerName: "Item Class",
    },
    {
      field: "itemSubClass",
      headerName: "Sub-Class",
    },
    {
      field: "category",
      headerName: "Category",
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
    },
    {
      field: "condition",
      headerName: "Condition",
    },
    {
      field: "color",
      headerName: "Color",
    },
    {
      field: "miscInfo",
      headerName: "Misc Info",
    },
  ];

  const onRowDoubleClicked = (e) => {
    let url;
    if (e.isSerialized === true)
      url = `${AppUrls.new_inventory_serialized}/${e.itemId}`;
    else if (e.isSerialized === false && e.nonTangible === false)
      url = `${AppUrls.new_inventory_nonserialized}/${e.itemId}`;
    else if (e.nonTangible === true) {
      url = `${AppUrls.non_tangible_item}/${e.itemId}`;
    }
    appDispatch({ type: "filter", value: { selectedHub, selectedUnitType } });
    history.push(url);
  };

  const getItems = useMutation(getInventoryItems, {
    async onSuccess(response) {
      setGridData(response?.data);
    },
  });

  const { isSuccess: isSuccessHubs, data: hubs } = useQuery<QueryResult<Hub>>(
    "hubsList",
    getInventoryHubsList
  );

  const onHubChanged = (e) => {
    setSelectedHub(e);
  };

  const onUnitTypeChanged = (e) => {
    setSelectedUnitType(e);
  };

  useEffect(() => {
    if (isSuccessHubs) {
      let modifiedHubs = [
        { label: "All", value: -1 },
        ...hubs?.data?.map((element) => {
          return { label: element.hubId, value: element.locationId };
        }),
      ];
      setHubsOptions(modifiedHubs);
      if (modifiedHubs.length > 0) setSelectedHub(modifiedHubs[0]);
    }
  }, [isSuccessHubs, hubs]);

  useEffect(() => {
    if (location.state && appState.gridFilters) {
      if (appState.gridFilters.selectedHub)
        setSelectedHub(appState.gridFilters.selectedHub);
      if (appState.gridFilters.selectedUnitType)
        setSelectedUnitType(appState.gridFilters.selectedUnitType);
    }
  }, [location, appState.gridFilters]);

  const displayFilter = () => {
    let isActive = null;
    if (activeOnly === 1) isActive = true;
    else if (activeOnly === 2) isActive = false;
    let params = "";
    if (startDate) params += `&startDate=${startDate.toISOString()}`;
    if (endDate) params += `&endDate=${endDate.toISOString()}`;
    if (isActive !== null) params += `&activeOnly=${isActive}`;
    params += `&searchQuery=${searchTerm}`;
    setFilter({
      hubId: selectedHub?.value,
      itemType: selectedUnitType?.value,
      params: params === "?" ? "" : params,
    });
    setGridData(null);
  };

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const clearFilters = () => {
    setSelectedUnitType({ label: "All", value: 1 });
    setSelectedHub({ label: "All", value: -1 });
    setStartDate(null);
    setEndDate(null);
    setSearchTerm("");
    setDateCategory(null);
    setIsActiveOnly(0);
    setFilter({ hubId: -1, itemType: 1, params: "" });
    setGridData(null);
  };

  const onSelectChange = (e) => {
    const value = Number(e.value);
    setIsActiveOnly(value);
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Inventory List</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || gridData?.length === 0 || !gridData}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          {/*<button className="import-btn import-btn-blue">
                        <ExcelIcon className='me-2' /> Import Serialized Items
                    </button>
                    <button className="import-btn import-btn-blue">
                        <ExcelIcon className='me-2' /> Import Tangible Items
                    </button>*/}
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <button
              onClick={() => setIsCreating(true)}
              className="btn btn-primary"
            >
              <img src={addIcon} className="me-2" alt="" /> Add New Item
            </button>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <div className="row mb-3 align-items-end">
              <div className="col-lg-3">
                <div className="page-search">
                  <label className="label me-3">Search</label>
                  <div className="input-group">
                    <span className="page-search-icon">
                      <i className="fa fa-search"></i>
                    </span>
                    <input
                      className="page-search-input ps-5"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <label className="label me-3">Show Disabled</label>
                <Select
                  options={selectOptions}
                  isSearchable={true}
                  value={selectOptions.find(
                    (option) => option.value === activeOnly
                  )}
                  onChange={onSelectChange}
                  className="flex-grow-1"
                />
              </div>
              <div className="col-lg-3">
                <label className="label me-3">Hubs</label>
                <Select
                  options={hubsOptions}
                  isSearchable={true}
                  value={selectedHub}
                  onChange={onHubChanged}
                  className="flex-grow-1"
                />
              </div>
              <div className="col-lg-3">
                <label className="label me-3">Unit Type</label>
                <Select
                  options={unitTypeOptions}
                  isSearchable={true}
                  value={selectedUnitType}
                  onChange={onUnitTypeChanged}
                  className="flex-grow-1"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <DateGrid
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  dateCategory={dateCategory}
                  setDateCategory={setDateCategory}
                />
              </div>

              <div
                className="col-lg-2 d-flex align-items-end"
                style={{ marginLeft: "-50%", marginTop: "5px" }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={displayFilter}
                >
                  Display
                </button>
                <button
                  type="button"
                  onClick={clearFilters}
                  className="btn btn-outline-primary custom-outline"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              data={gridData}
              fetchMethod={getItems}
              refreshCounter={refreshCounter}
              filters={filter}
              setDataGrid={setGridData}
              setrowClicked={onRowDoubleClicked}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              fileName="Inventory List List Report"
            />
          </div>
        </div>
      </div>
      <CreateItemModal
        showModal={isCreating}
        onExit={() => setIsCreating(false)}
        hubs={hubs?.data}
      />
    </div>
  );
};

export default InventoryList;
