import { useState, useEffect, useContext } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap';
import Select from "react-select";
import DispatchContext from 'context/DispatchContext';
import AG from 'elements/AG';
import { useMutation } from "react-query";
import { DropDownOption, GridColumns } from 'components/Common.models';
import { SalesOrder, SalesOrderItem } from '../../SalesOrder.model';
import { statusEnum } from "../../StatusEnum";
import {
    getFilteredSalesOrders,
    getSalesOrder
} from "../SalesOrderServices/SalesOrderServices";
import close from 'img/close.svg';
import './SalesOrderModel.scss';

type Props = {
    title: string,
    isOpen: boolean,
    setIsOpen: any,
    setSalesOrder?: Function,
    componentTitle: string,
    disallowPickup?: boolean
    RemoveOpenAndVoided?: boolean,
    showOnlyFulfilled?: boolean
};

const SalesOrderModel = (props: Props) => {
    const appDispatch = useContext(DispatchContext)
    const [salesOrderData, setSalesOrderData] = useState<SalesOrder[]>([])
    const [itemOrders, setItemOrders] = useState<SalesOrderItem[]>([])

    const getSalesOrders = useMutation(getFilteredSalesOrders, {
        async onSuccess(response) {
            setSalesOrderData(response)
        }
    });

    const getSalesOrderItem = useMutation(getSalesOrder, {
        async onSuccess(response) {
            setItemOrders(response.data?.itemOrders)
            if (response.data?.status === 1 || response.data?.status === 6) {
                props.setSalesOrder(null)
            } else {
                props.setSalesOrder(response.data)
            }
        }
    });

    const sales_order_options: DropDownOption[] =
        props.componentTitle === 'Apply Tracking Info' ? [
            {
                label: "All Orders",
                value: 0
            },
            {
                label: "Fullfilled",
                value: 2
            },
            {
                label: "Partially Fulfilled",
                value: 3
            },
            {
                label: "Shipped",
                value: 4
            },
            {
                label: "Partially Shipped",
                value: 5, 
            }
        ] : props.componentTitle === 'Pickup Sales Order' ? [
            {
                label: "All Orders",
                value: 0
            },
            {
                label: "Fullfilled",
                value: 2
            },
            {
                label: "Partially Fulfilled",
                value: 3
            },
        ] : []

    const order_listing_columns: GridColumns[] = [
        {
            field: "orderNumber",
            headerName: "Order No.",           


        },
        {
            field: "accountNumber",
            headerName: "Account No.",
        },
        {
            field: "company",
            headerName: "Company",
        },
        {
            field: "projectNumber",
            headerName: "Project No.",
        },
        {
            field: "customerPO",
            headerName: "Customer PO#",
        },
        {
            field: "hubKey",
            headerName: "Hub ID",
        },
        {
            field: "orderDate",
            headerName: "Order Date",
            cellRenderer: (params) => params.value ? (`${new Date(params.value).toLocaleDateString()} - ${new Date(params.value).toLocaleTimeString()}`) : '',
        },
        {
            field: "orderAmount",
            headerName: "Order Amount",
        },
        {
            field: "shipAmount",
            headerName: "Ship Amount",
        },
        {
            field: "printed",
            headerName: "Printed",
            cellRenderer: (params) => params.value === true ? 'Yes' : 'No',
        },
        {
            field: "status",
            headerName: "Status",
            cellRenderer: (params) => params.value ? statusEnum[params.value] : '',
        },
        {
            field: "origin",
            headerName: "Origin",
        },
        {
            field: "shipVia",
            headerName: "Ship Via",
        },
    ]

    const sales_order_details_columns: GridColumns[] = [
        {
            field: "itemNumber",
            headerName: "Item",
            editable: false,
        },
        {
            field: "description",
            headerName: "Item Description",
            editable: false,
        },
        {
            field: "quantity",
            headerName: "Odr Qty",
            editable: false,
        },
        {
            field: "price",
            headerName: "Price",
            editable: false,
        },
        {
            field: "tax",
            headerName: "Taxable",
            editable: false,
        },
        {
            field: "subTotal",
            headerName: "Sub-Total",
            cellRendererFramework: (params) => `$ ${params.data?.quantity * params.data.price}`,
            editable: false,
        },
        {
            field: "shipQuantity",
            headerName: "Ship Qty",
            editable: false,
        },
        {
            field: "hubKey",
            headerName: "Hub ID",
            editable: false,
        },
        {
            field: "warrantyDays",
            headerName: "Warrantly",
            editable: false,
        },
    ]

    const fullfilled_detail_columns: GridColumns[] = [
        {
            field: "itemNumber",
            headerName: "Item",
            editable: false,
        },
        {
            field: "serial",
            headerName: "Serial",
            editable: false,
        },
        {
            field: "quantity",
            headerName: "Qty",
            editable: false,
        },
        {
            field: "soNumber",
            headerName: "Sono",
            editable: false,
        },
        {
            field: "addUser",
            headerName: "Adduser",
            editable: false,
        },
        {
            field: "addedDate",
            headerName: "Adddate",
            editable: false,
        },
    ]

    const [key, setKey] = useState<string>("salesOrderDetail");

    const setrowSingleClicked = (e) => {
        getSalesOrderItem.mutate(e.salesOrderId)
    }

    const onSalesOrderRowClicked = (e) => {
        if (e.status === 1 || e.status === 6) {
            let notification = {
                variant: "danger",
                msg: 'Open and void sales order can not be selected'
            };
            appDispatch({ type: "notification", value: notification })
        } else if (props.disallowPickup && e.isPickup) {
            let notification = {
                variant: "danger",
                msg: 'Sales order to be picked up can not be selected'
            };
            appDispatch({ type: "notification", value: notification })
        } else {
            props.setIsOpen(false);
        }
    }

    const [selectedOrderStatus, setSelectedOrderStatus] = useState<Object>({ label: "All Orders", value: 0 })

    const onOrderStatusChange = (e) => {
        setSelectedOrderStatus(e)
    }

    useEffect(() => {
        if (selectedOrderStatus) {
            setItemOrders([])
            getSalesOrders.mutate(selectedOrderStatus['value'])
        }
    }, [selectedOrderStatus])

    useEffect(() => {
        appDispatch({ type: "loading", value: getSalesOrders.isLoading })
    }, [getSalesOrders.isLoading])

    return (
        <Modal
            className='multiag-modal'
            size="lg"
            dialogClassName="modal-80w"
            show={props.isOpen}
            onHide={props.setIsOpen}
            centered
        >
            <div className="d-flex justify-content-between align-items-center mx-4 my-3">
                <div className='multiag-modal-title'>{props.title}</div>
                <img
                    src={close}
                    alt=''
                    className='multiag-modal-close'
                    onClick={(e) => {
                        e.preventDefault();
                        props.setIsOpen(false);
                    }}
                />
            </div>
            {!props.RemoveOpenAndVoided && (
            <div className='ms-auto mx-4 mt-0 mb-3'>
            <Select
                isClearable={false}
                options={sales_order_options}
                isSearchable={true}
                className="multiag-select"
                value={selectedOrderStatus}
                onChange={onOrderStatusChange}
            />
        </div>
            )}
            <div className="mx-4">
                <Modal.Body className='px-0 pb-0 mb-0 pt-0'>
                    <div className="mb-2 h-250">
                        <AG
                            data={props.RemoveOpenAndVoided ? salesOrderData.filter(o => o.status != 1 && o.status != 6) : props.showOnlyFulfilled ? salesOrderData.filter(o => o.status === 2 || o.status === 3) : salesOrderData}
                            columns={order_listing_columns}
                            setrowClicked={onSalesOrderRowClicked}
                            setrowSingleClicked={setrowSingleClicked}
                            autoColumns={true}
                        />
                    </div>
                    <div>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab
                                eventKey="salesOrderDetail"
                                title={<div className='tab-inner-title'>Sales Order Detail</div>}
                            >
                                <div className='h-250'>
                                    <AG
                                        data={itemOrders}
                                        columns={sales_order_details_columns}
                                    />
                                </div>
                            </Tab>
                            <Tab
                                eventKey="fullfilledDetail"
                                title={<div className='tab-inner-title'>Fullfilled Detail</div>}
                            >

                                <div className='h-250'>
                                    <AG
                                        data={itemOrders}
                                        columns={fullfilled_detail_columns}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer className="ms-auto my-0">
                    <button
                        className='btn btn-outline-primary custom-outline'
                        onClick={(e) => {
                            e.preventDefault();
                            props.setIsOpen(false);
                        }}
                    >Exit</button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default SalesOrderModel;
