import { useQuery } from "react-query";
import { getBanks } from "./AccountingServices";
import * as AppUrls from "constants/AppUrls";
import { useHistory } from "react-router-dom";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { useContext, useRef, useState } from "react";
import ExcelIcon from "img/excel_icon.png";
import * as XLSX from "xlsx";
import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import AG from "elements/AG";
import { formatNumber, getCurrencyByCode } from "global/helpers";
import moment from "moment";
import { getBaseCurrency } from "components/currency";

const StatementList = () => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [importedData, setImportedData] = useState([]);
  const importedBank = useRef(null);

  const [id, setId] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const refButton = useRef();
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const { data: paymentAccounts } = useQuery("accounts", getBanks);

  const columns = [
    {
      field: "accountName",
      headerName: "Bank",
      resizable: true,
    },
    {
      field: "bankAccountNumber",
      headerName: "Bank Account Number",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      valueGetter: (params) => {
        return currencySymbol + `${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "canReconcile",
      headerName: "Reconcile",
      cellRendererFramework: (params) => {
        return params.data.partialReconcileId ? (
          <button
            className="btn btn-link text-decoration-none p-0 m-0"
            onClick={() =>
              resumeReconciliation(
                params.data.partialReconcileId,
                params.data?.bankId
              )
            }
          >
            Resume Reconciliation
          </button>
        ) : params.value ? (
          <div className="position-relative h-100 d-flex align-items-end">
            <label
              htmlFor="import-excel"
              className="import-btn"
              onClick={() => {
                setId(params.data?.bankId);
                setEndDate(params.data?.lastReconcileDate);
              }}
            >
              <img src={ExcelIcon} className="me-2" alt="" /> Import
            </label>
            {/* <input type="file" id="import-excel" className="invisible position-absolute start-0" onClick={(e) => { importRef.current = e; idRef.current =  params.data?.bankId; importClicked() } } /> */}
            <button
              className="invisible position-absolute start-0"
              onClick={() => {
                importClicked();
              }}
            >
              Import
            </button>
          </div>
        ) : (
          <div>Already reconciled</div>
        );
      },
    },
    {
      field: "nonReconciledCount",
      headerName: "Remaining Transactions",
    },
  ];

  const importClicked = () => {
    //@ts-ignore
    refButton.current.click();
  };

  //

  const excelColumns = [
    {
      field: "Reference",
      headerName: "Ref No",
      resizable: true,
      minWidth: 250,
    },
    {
      field: "Account Name",
      headerName: "Account",
      resizable: true,
      minWidth: 300,
    },
    {
      field: "Amount",
      headerName: "Payment",
      cellRenderer: (params) => formatNumber(params.value),
      resizable: true,
      minWidth: 200,
    },
    {
      field: "Post Date",
      headerName: "Date",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      resizable: true,
      cellRendererFramework: (params) => (
        <span className="text-danger">{params.value}</span>
      ),
      minWidth: 100,
    },
  ];

  const getExtension = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return ["xlsx", "xls", "csv"].includes(extension);
  };

  const importExcel = (e, bankId) => {
    importedBank.current = bankId;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary", cellDates: true });
      const workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const headers = fileData[0];
      fileData.splice(0, 1);
      const rows = [];
      fileData.forEach((row: string[]) => {
        let rowData = {};
        row.forEach((element, index) => {
          if (typeof element === "object") {
            if (new Date(endDate) > new Date(element))
              rowData["status"] =
                "The transaction date belongs to the previous reconciliation";
            rowData[headers[index]] = moment(element).format("DD/MM/yyyy");
          } else rowData[headers[index]] = element;
        });
        rows.push(rowData);
      });
      setImportedData(rows);
      setShowModal(true);
    };
    if (file && getExtension(file)) reader.readAsBinaryString(file);
    else {
      let notification = {
        variant: "danger",
        msg: "Please import a valid file",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const applyChanges = () => {
    setShowModal(false);
    history.push({
      pathname: AppUrls.add_bank_reconciliation,
      search: `?bank=${importedBank.current}`,
      state: importedData.filter((obj) => !obj.status),
    });
  };

  const resumeReconciliation = (id, bankId) => {
    history.push({
      pathname: AppUrls.add_bank_reconciliation,
      search: `?resumeId=${id}&bank=${bankId}`,
    });
  };

  return (
    <>
      <div className="grid-page">
        <div className="page-title page-title-editable">
          <p>Bank Reconciliation Statements</p>
        </div>
        <div className="page-content-wrapper">
          <div className="grid-container">
            <input
              ref={refButton}
              type="file"
              id="import-excel"
              className="invisible position-absolute start-0"
              onChange={(e) => {
                importExcel(e, id);
              }}
            />
            <AgGrid columns={columns} data={paymentAccounts?.data} />
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={setShowModal}
        centered
        size="lg"
        dialogClassName="modal-80w"
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <img
            src={Close}
            alt="close icon"
            className="ms-auto"
            role="button"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className="h-450">
            <AG data={importedData} pagination={true} columns={excelColumns} />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ms-auto border-top-0">
          <button
            type="button"
            className="btn btn-primary"
            onClick={applyChanges}
            disabled={importedData.every((obj) => obj.status)}
          >
            Apply Changes
          </button>
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => {
              setShowModal(false);
              setImportedData([]);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatementList;
