import { Modal } from 'react-bootstrap';
import Close from 'img/close.svg';
import Expenses from './Expenses';

type Props = {
    showModal: boolean,
    onExit: () => void,
    onCreation: (n: number) => void,
    bankId: number,
    payment: number,
    expenseType: number,
    expenseDate: Date
}

const ExpensesModal = (props: Props) => {

    return (
        <>
            <Modal show={props.showModal} centered size="lg" dialogClassName="modal-80w">
                <Modal.Header>
                    <Modal.Title>
                        <div className='mb-4'>
                            {props.expenseType === 2 ? "Add Fixed Asset" : "Add Expense"}
                        </div>
                    </Modal.Title>
                    <img
                        src={Close}
                        alt="close icon"
                        className=""
                        role="button"
                        onClick={props.onExit}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Expenses hideTitle={true} expenseDate={props.expenseDate} onCreation={props.onCreation} bankId={props.bankId} payment={props.payment} isAsset={props.expenseType === 2} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ExpensesModal;