import { useContext, useEffect } from "react";
import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { formatNumber } from "global/helpers";
import { useQuery } from "react-query";
import DispatchContext from 'context/DispatchContext';
import { useHistory, useParams } from "react-router-dom";
import AG from "elements/AG";
import { getVendorCmDetails } from "../Vendors.services";

const VendorCmDetails = () => {

    const history = useHistory()
    const appDispatch = useContext(DispatchContext)
    const { id } = useParams();


    const {
        isLoading: isLoadingInvoice,
        data: invoiceData,
    } = useQuery([id], getVendorCmDetails);



    const onBackClick = () => {
        history.push(AppUrls.vendor_credit_memo)
    }

    useEffect(() => {
        appDispatch({ type: "loading", value: isLoadingInvoice })
    }, [appDispatch, isLoadingInvoice])


    const columns = [
        {
            field: "itemNumber",
            headerName: "Item Number",
        },
        {
            field: "itemDescription",
            headerName: "Item Description",
        },
        {
            field: "quantity",
            headerName: "QTY",
            editable: true,
        },
        {
            field: "cost",
            headerName: "Cost",
            cellRenderer: (params) => formatNumber(params.value)
        },
        {
            field: "subtotal",
            headerName: "Sub Total",
            valueGetter: (params) => formatNumber(params.getValue("quantity") * params.getValue("cost")), 
        },
    ]

    return (
        <div className="static-page">
            <div className="page-title page-title-editable">
                <div className="back-btn " onClick={onBackClick}>
                    <img src={backArrow} alt="back arrow" />
                    {invoiceData?.number}
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Account No.</label>
                                    <div>{invoiceData?.accountNumber}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>CM No.</label>
                                    <div>{invoiceData?.number}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>CM Date</label>
                                    <div>{invoiceData && new Date(invoiceData?.date).toLocaleDateString()}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Company</label>
                                    <div>{invoiceData?.company}</div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Status</label>
                                    <div>{invoiceData?.status}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-2 col-sm-12">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex justify-content-between">
                                        <div>CM amount</div>
                                        <label>{formatNumber(invoiceData?.amount)}</label>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>Paid amount</div>
                                        <label>{formatNumber(invoiceData?.paidAmount)}</label>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div>CM Balance</div>
                                        <label>{formatNumber(invoiceData?.balance)}</label>
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-3 ms-3 me-2" />
                        </div>
                    </div>
                    <div className="h-450 mt-4">
                        <AG data={invoiceData?.items} columns={columns} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorCmDetails;