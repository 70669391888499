import { useContext, useEffect, useRef, useState } from "react";

import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { AccountStatementReportAPI } from "./services/ReportServices";

import { useMutation, useQuery } from "react-query";

import DateGrid from "components/DateFilter/DateGrid";
import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { dateFormmaterNoTime, formatNumber } from "global/helpers";
import { useReactToPrint } from "react-to-print";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";

const AccountStatementsReport = () => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const location = useLocation();
  const [generate, setGenerate] = useState(false);
  const { id } = useParams();
  const pageTitle = "Account Statements report";
  const backUrl = AppUrls.ledgers;
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [dateCategory, setDateCategory] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const currency = baseCurrency?.symbol;

  const mutate = useMutation(AccountStatementReportAPI, {
    onSuccess(data) {
      setAccountName(data[0].accountName);

      setData(data);
    },
  });
  const handleGen = () => {
    if (id || startDate || endDate)
      mutate.mutate({ id: id, startDate: startDate, endDate: endDate });
    setGenerate(true);
  };
  useEffect(() => {
    if (id) {
      mutate.mutate({ id: id, startDate: startDate, endDate: endDate });
    }
  }, [id]);
  const columns = [
    {
      field: "date",
      headerName: "Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params?.data?.date);
      },
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "accountName",
      headerName: "Account",
    },
    {
      field: "operationNumber",
      headerName: "Operation No",
    },
    {
      field: "journalNumber",
      headerName: "Journal No",
    },
    {
      field: "amount",
      headerName: "Credit",
      valueGetter: (params) => {
        return params?.data?.isDebit
          ? ""
          : formatNumber(params.data?.amount, currency);
      },
    },
    {
      field: "amount",
      headerName: "Debit",
      valueGetter: (params) => {
        return params?.data?.isDebit
          ? formatNumber(params.data?.amount, currency)
          : "";
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return formatNumber(params.data?.balance, currency);
      },
    },
  ];

  const onBackClick = () => {
    if (location?.state?.isLedger) history.push(AppUrls.ledgers);
    else history.push(backUrl);
  };

  const [forPrinting, setForPrinting] = useState(false);
  const reportPage = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    setForPrinting(true);
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setForPrinting(false);
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };
  return (
    <form>
      <div className="page-title  page-title-editable">
        <div className="w-auto">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {pageTitle}
          </div>
        </div>
      </div>
      <div className="page-content-wrapper p-0">
        <div className="page-content">
          <div className="grid-container">
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <DateGrid
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateCategory={dateCategory}
                    setDateCategory={setDateCategory}
                    isFullWidth={true}
                  />
                  <div>
                    <div className="btn btn-primary" onClick={handleGen}>
                      Generate
                    </div>
                  </div>
                </div>
                <div className="col-4 pt-4 mt-1 text-end">
                  <button
                    type="button"
                    className="btn btn-success  d-inline"
                    onClick={printDocument}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    Print report to PDF
                  </button>
                </div>
              </div>
            </div>
            <div className="report-page container" ref={reportPage}>
              <div className="report-header d-flex justify-content-between">
                <div>
                  <h3>
                    <b>{pageTitle}</b>
                  </h3>
                  <h5>
                    <b>Account Name : {accountName} </b>
                  </h5>
                  <br />
                  <p>
                    <b>Report Date: </b>
                    {dateFormmaterNoTime(data?.reportDate) ??
                      dateFormmaterNoTime(new Date())}
                  </p>
                  {generate && (
                    <div>
                      {dateFormmaterNoTime(startDate) && (
                        <p className="text-muted">
                          <b>Start Date: </b>
                          {dateFormmaterNoTime(startDate) ??
                            dateFormmaterNoTime(new Date())}
                          <b> End Date: </b>
                          {dateFormmaterNoTime(endDate) ??
                            dateFormmaterNoTime(new Date())}
                        </p>
                      )}
                    </div>
                  )}

                  {/* {dateFormmaterNoTime(startDate) && (
                    <p className="text-muted">
                      <b>Start Date: </b>
                      {dateFormmaterNoTime(startDate) ??
                        dateFormmaterNoTime(new Date())}
                      <b> End Date: </b>
                      {dateFormmaterNoTime(endDate) ??
                        dateFormmaterNoTime(new Date())}
                    </p>
                  )} */}
                </div>
                <div>
                  <img
                    alt=""
                    src={data?.imageLogo ?? BlueSeedLogo}
                    className="report-logo"
                  ></img>
                </div>
              </div>
              <div className="grid-wrapper">
                <div className="position-relative">
                  <div
                    className="position-relative"
                    style={{ height: "auto", width: "100%", margin: "auto" }}
                  >
                    {forPrinting ? (
                      <AgGrid
                        columns={columns}
                        data={data}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        autoSize={true}
                      />
                    ) : (
                      <AgGrid
                        columns={columns}
                        data={data}
                        pagination={false}
                        autoHight={true}
                        autoSize={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountStatementsReport;
