import { useContext } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import DispatchContext from "context/DispatchContext";
import { useMutation, useQuery } from "react-query";
import { getTaxSettings, postTaxSettings } from "./PayrollServices/payrollServices";
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import { notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";

const AddTaxSettings = () => {

    const history = useHistory();
    const appDispatch = useContext(DispatchContext)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            taxSettings: [{ fromIncome: "", toIncome: "", taxRate: "", payrollTaxSettingId: null }]
        }
    })

    const { handleSubmit, register, formState: { errors, isDirty }, setValue, control } = methods

    const {
        fields,
        append,
    } = useFieldArray({
        name: "taxSettings",
        control
    });

    const {data} = useQuery('taxsettings', getTaxSettings, {
        onSuccess(data) {
            setValue("taxSettings", data?.data);
        },
    })

    const addMutation = useMutation(postTaxSettings, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Tax Settings ${CREATE_SUCCESS_MSG}`
            };
            appDispatch({ type: "notification", value: notification });
            history.push(`${AppUrls.tax_settings}`);
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, "problem adding tax settings"),
            };
            appDispatch({ type: "notification", value: notification });
        },
    });


    const onSubmit = (values) => {
        addMutation.mutate(values.taxSettings.map(setting => {
            if (setting.payrollTaxSettingId)
                return setting
            return {
                "fromIncome": setting.fromIncome,
                "toIncome": setting.toIncome,
                "taxRate": setting.taxRate
            }
        }))
    }

    const onBackClick = () => {
        history.push(AppUrls.tax_settings)
    }

    const cancelClicked = () => {
        setValue("taxSettings", data?.data);
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="page-title page-title-editable">
                        <div className="back-btn " onClick={onBackClick}>
                            <img src={backArrow} alt="back arrow" />
                            Tax Settings
                        </div>
                        <div>
                            <button
                                type="button"
                                className={`btn btn-outline-primary no-border ${!isDirty && 'text-muted'}`}
                                onClick={cancelClicked}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-success">
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        <div className="page-content">
                            {fields.map((item, index) => {
                                return (
                                    <div key={item.id} className="row mt-2">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>From Income <span className="text-danger">*</span></label>
                                                <input type="number" {...register(`taxSettings.${index}.fromIncome`, { required: true })} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>To Income <span className="text-danger">*</span></label>
                                                <input type="number" {...register(`taxSettings.${index}.toIncome`, { required: true })} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label>Tax Rate % <span className="text-danger">*</span></label>
                                                <input type="number" {...register(`taxSettings.${index}.taxRate`, { required: true })} className="form-control" />
                                            </div>
                                        </div>
                                        <input {...register(`taxSettings.${index}.payrollTaxSettingId`)} className="d-none" />
                                    </div>
                                );
                            })}
                            {errors?.taxSettings &&
                                <div className="row">
                                    <div className="text-danger">Please fill all the required fields</div>
                                </div>
                            }
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                        append({ fromIncome: "", toIncome: "" });
                                    }}
                                >
                                    Add Bracket
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default AddTaxSettings;
