import api from "components/data/AxiosClient"

export const ValidateDataM = async (params) => {
    const response = await api.post(params.url, params.data)
    return response
}

export const handleImport = async (params) => {
    const response = await api.post(params.url, params.data)
    return response
}