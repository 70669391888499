import { Modal } from 'react-bootstrap';
import close from 'img/close.svg';
import { useMutation } from 'react-query';
import DispatchContext from 'context/DispatchContext'
import { useContext } from 'react';
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import { notificationMessage } from 'global/helpers';
import { Controller, useForm } from 'react-hook-form';
import { createCategory, createItemClass, createSubClass } from '../InventorySetup/InventorySetup.services';
import Select from "react-select";
import { DropDownOption } from 'components/Common.models';

type Props = {
    onConfirm: (id: number) => void,
    onClose: () => void,
    showModal: boolean,
    modalType: number,
    itemClassOptions: DropDownOption[]
};

const ClassesModal = (props: Props) => {

    const appDispatch = useContext(DispatchContext);

    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();

    const onCloseClicked = () => {
        props.onClose();
    }

    const addClass = useMutation(createItemClass, {
        async onSuccess(data) {
            let notification = {
                variant: "success",
                msg: `Item class ${CREATE_SUCCESS_MSG}`
            };
            appDispatch({ type: "notification", value: notification });
            reset();
            props.onConfirm(data.itemClassId)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, 'problem adding item class')
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const addSubClass = useMutation(createSubClass, {
        async onSuccess(data) {
            let notification = {
                variant: "success",
                msg: `Item sub class ${CREATE_SUCCESS_MSG}`
            };
            appDispatch({ type: "notification", value: notification });
            reset();
            props.onConfirm(data.itemSubClassId)

        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, "problem adding item sub class"),
            };
            appDispatch({ type: "notification", value: notification })
        },
    });

    const addCategory = useMutation(createCategory, {
        async onSuccess(data) {
            let notification = {
                variant: "success",
                msg: `Item Category ${CREATE_SUCCESS_MSG}`
            };
            appDispatch({ type: "notification", value: notification });
            reset();
            props.onConfirm(data.itemCategoryId)
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, "problem adding item Category"),
            };
            appDispatch({ type: "notification", value: notification })
        },
    });


    const onSubmit = (data) => {
        if (props.modalType === 1)
            addClass.mutate({ ...data, className: data.name })
        else if (props.modalType === 2)
            addSubClass.mutate({ ...data, subClass: data.name })
        else if (props.modalType === 3)
            addCategory.mutate({ ...data, category: data.name })
    }

    let label = ''
    if (props.modalType === 1)
        label = 'Class'
    else if (props.modalType === 2)
        label = 'Sub Class'
    else if (props.modalType === 3)
        label = 'Category'

    return (
        <Modal className="confirmation-modal" show={props.showModal} onHide={() => props.onClose()} centered>
            <div className="d-flex justify-content-between align-items-center mx-4 my-3">
                <div className='confirmation-modal-title'>Add {label}</div>
                <img
                    src={close}
                    alt=''
                    className='confirmation-modal-close'
                    onClick={onCloseClicked}
                />
            </div>
            <div className="mx-4">
                <Modal.Body className='px-0 py-0'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {props.modalType === 2 &&
                            <div className="form-group mb-4">
                                <label className="label">Item Class</label>
                                <Controller
                                    control={control}
                                    name="itemClassId"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Select
                                            menuPlacement="auto"
                                            options={props.itemClassOptions}
                                            isSearchable={true}
                                            value={props.itemClassOptions?.find(opt => opt?.value === value) || null}
                                            onChange={(val: DropDownOption) => onChange(val?.value)}
                                            isClearable={true}
                                        />
                                    )}
                                />
                                {errors.classId && <p className="text-danger">This field is required</p>}
                            </div>
                        }
                        <div className="form-group mb-4">
                            <label>
                                {label}
                            </label>
                            <input className='form-control' {...register("name", { required: true })} />
                        </div>
                        {errors["name"] && (
                            <p className="text-danger">This field is required</p>
                        )}
                        <div className="form-group">
                            <label>
                                Description
                            </label>
                            <input className='form-control' {...register("description")} />
                        </div>
                        <div className='d-flex justify-content-between mt-3'>
                            <button className='btn btn-outline-primary ms-0' type='button' onClick={() => props.onClose()}>
                                Cancel
                            </button>
                            <button
                                className={`btn btn-primary`}
                                type="submit"
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    );
}

export default ClassesModal;