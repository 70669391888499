import { useContext } from "react";
import Alert from "react-bootstrap/Alert";
import DispatchContext from "../context/DispatchContext";
import "./Notification.scss";

type Props = {
  notification: NotificationProps;
};

type NotificationProps = {
  variant: string;
  msg: string;
};

const Notification = ({ notification }: Props) => {
  const appDispatch = useContext(DispatchContext);

  const onClose = () => {
    appDispatch({ type: "notification", value: null });
  };

  return (
    <Alert
      variant={notification.variant}
      onClose={onClose}
      dismissible
      className="notification"
    >
      <p>
        <i className="fal fa-exclamation-circle me-3"></i>
        {notification.msg}
      </p>
    </Alert>
  );
};

export default Notification;
