import { useState, useEffect, useContext } from "react";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "../../../styles/toggle.css";

import SectionTitle from "elements/SectionTitle";
import { Tabs, Tab } from "react-bootstrap";
import Permissions from "./Permissions";
import { Controller, useForm } from "react-hook-form";
import Members from "./Members";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { useQuery, useMutation } from "react-query";
import {
  createUserRole,
  editUserRole,
  getUserPermissions,
  getUserRole,
} from "../UserManagementServices/UserManagementServices";
import "./UserRoleDetails.scss";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

const UserRoleDetails = ({ match }) => {
  const history = useHistory();
  const { id } = useParams();

  const [allPermissions, setAllPermissions] = useState([]);
  const [checked, setChecked] = useState([]);
  const [summaryOptions, setSummaryOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [activeKey, setActiveKey] = useState(
    id ? "PermissionsSummary" : "Permissions"
  );
  const [permissionsListing, setPermissionsListing] = useState([]);

  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    handleSubmit,
    reset,
    register,
    getValues,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isActive: true,
      roleId: null,
      description: null,
      permissions: [],
    },
  });

  const {
    isLoading: isLoadingPermissions,
    data: userPermissions,
    isSuccess: isSuccessPermissions,
  } = useQuery("userPermissionsList", getUserPermissions);

  const {
    isLoading: isLoadingRole,
    isSuccess: isSuccessRole,
    data: currentRole,
  } = useQuery([id], getUserRole, {
    enabled: id !== undefined ? true : false,
  });

  const addUserRole = useMutation(createUserRole, {
    async onSuccess(response) {
      let notification = {
        variant: "success",
        msg: `User Role ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });

      setSubmitted(true);
      history.push(`${AppUrls.users_roles}/${response.data.roleId}`);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding user role"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateUserRole = useMutation(editUserRole, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `User Role ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });

      setSubmitted(true);
      setIsDisabled(true);
      setTimeout(() => {
        if (document.querySelector('[id*="tab-PermissionsSummary"]'))
          document.querySelector('[id*="tab-PermissionsSummary"]').click();
      }, 1000);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing user role"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingPermissions || isLoadingRole || updateUserRole.isLoading,
    });
  }, [isLoadingPermissions, isLoadingRole, updateUserRole.isLoading]);

  useEffect(() => {
    if (isSuccessPermissions) {
      let flatPermissions = [];
      const changePermmissionsLabels = (permissions) => {
        if (permissions && permissions.length > 0)
          for (let permission of permissions) {
            permission.value = permission.componentId;
            permission.label = permission.name;
            if (permission.subPermissions.length > 0) {
              permission.children = permission.subPermissions;
              changePermmissionsLabels(permission.children);
            }
            flatPermissions.push(permission);
          }
      };
      let modifiedPermissions = userPermissions.data;
      changePermmissionsLabels(modifiedPermissions);
      setAllPermissions(modifiedPermissions);
      setPermissionsListing(flatPermissions);
      setIsFetching(true);
    }
  }, [isSuccessPermissions]);

  useEffect(() => {
    if (isSuccessRole && isFetching) {
      let checkedPermissions = [];
      let clickedPermissions = [];
      if (currentRole?.data?.permissions.length > 0)
        for (let permission of currentRole?.data?.permissions) {
          if (permission.isGranted) {
            clickedPermissions.push(permission.componentActionId);
            if (
              checkedPermissions.indexOf(permission.componentId.toString()) ===
              -1
            )
              checkedPermissions.push(permission.componentId.toString());
          }
        }
      reset({
        roleId: currentRole?.data?.roleKey,
        isActive: currentRole?.data?.isActive,
        description: currentRole?.data?.description,
        permissions: checkedPermissions,
      });
      setChecked(checkedPermissions);
      let updatedTree = permissionsListing.slice();
      for (let element of updatedTree) {
        for (let action of element.actions)
          if (clickedPermissions.includes(action.actionId)) {
            action.isGranted = true;
          }
      }
      setPermissionsListing(updatedTree);
      setTimeout(() => {
        if (document.querySelector('[id*="tab-PermissionsSummary"]'))
          document.querySelector('[id*="tab-PermissionsSummary"]').click();
      }, 1000);
    }
  }, [isSuccessRole, isFetching]);

  useEffect(() => {
    if (id) setIsDisabled(true);
  }, [id]);

  useEffect(() => {
    if (checked) setValue("permissions", checked);
  }, [checked]);

  const onBackClick = () => {
    if (isDirty && !submitted) setOpenModal(true);
    else history.push(AppUrls.users_roles);
  };

  const checkIfChecked = (option) => {
    if (checked.includes(option.value.toString())) return true;
    else if (option.children)
      for (let child of option.children) if (checkIfChecked(child)) return true;
    return false;
  };

  const onTabChanged = (e) => {
    setActiveKey(e);
    if (e == "PermissionsSummary") {
      let summary = [];
      if (allPermissions && allPermissions.length > 0)
        for (let option of allPermissions) {
          if (checkIfChecked(option)) summary.push(option);
        }
      setSummaryOptions(summary);
    }
  };

  const resetClicked = () => {
    let clickedPermissions = [];
    let checkedPermissions = [];
    if (id)
      for (let permission of currentRole.data?.permissions) {
        checkedPermissions.push(permission.componentId);
        clickedPermissions.push(permission.componentActionId);
      }
    setChecked(checkedPermissions);
    let updatedTree = permissionsListing.slice();
    for (let element of updatedTree) {
      for (let action of element.actions)
        if (!clickedPermissions.includes(action.actionId))
          delete action.isGranted;
    }
    setPermissionsListing(updatedTree);
  };

  const cancelForm = () => {
    reset();
    resetClicked();
    if (id) {
      setIsDisabled(true);
      setActiveKey("PermissionsSummary");
    }
  };

  const onEditClick = () => {
    setIsDisabled(false);
    setSubmitted(false);
    setActiveKey("PermissionsSummary");
  };

  const onSubmit = (values) => {
    let chosenPermissions = [];
    for (let permission of permissionsListing)
      for (let action of permission.actions) {
        action.componentActionId = action.actionId;
        chosenPermissions.push(action);
      }
    let newData = {
      description: values.description,
      isActive: values.isActive,
      permissions: chosenPermissions,
      roleKey: values.roleId,
    };
    if (id) {
      newData.roleId = currentRole.data.roleId;
      updateUserRole.mutate(newData);
    } else addUserRole.mutate(newData);
  };

  const checkSubmitDisabled = () => {
    if (id) {
      if (!isDirty && currentRole?.data?.permissions?.length === checked.length)
        return true;
      return false;
    } else {
      if (!isDirty && checked.length == 0) return true;
      return false;
    }
  };

  const checkCancelDisabled = () => {
    if (id) return false;
    else {
      if (!isDirty && checked.length == 0) return true;
      return false;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div className="back-btn" onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {isDisabled
              ? getValues("roleId")
              : id
                ? "Editing"
                : "Create User Role"}
          </div>
          <div className="d-flex justify-content-between ">
            {isDisabled ? (
              checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onEditClick}
                >
                  Edit
                </button>
              )
            ) : (
              <>
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border ${checkCancelDisabled() && "text-muted"
                    }`}
                  disabled={checkCancelDisabled()}
                  onClick={cancelForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={checkSubmitDisabled()}
                >
                  {id ? "Save Changes" : "Create"}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <SectionTitle editable={false} title="User Role Info" />
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <label>
                      Role ID <span className="text-danger">*</span>
                    </label>
                    <input
                      name="roleId"
                      type="text"
                      className={`form-control ${errors.roleId && " required_field"
                        }`}
                      placeholder="Role ID"
                      {...register("roleId", { required: true })}
                      disabled={isDisabled}
                    />
                    {errors["roleId"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    <label>
                      Description <span className="text-danger">*</span>
                    </label>
                    <input
                      name="description"
                      type="text"
                      className={`form-control ${errors.description && " required_field"
                        }`}
                      {...register("description", { required: true })}
                      disabled={isDisabled}
                    />
                    {errors["description"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column justify-content-end custom-col">
                {getValues("isActive") ? (
                  <label className="label mb-2">Active</label>
                ) : (
                  <label className="label mb-2">Disabled</label>
                )}
                {isDisabled ? (
                  getValues("isActive") ? (
                    <div className="active-status">True</div>
                  ) : (
                    <div className="disabled-status">False</div>
                  )
                ) : (
                  <div className="form-group">
                    <Controller
                      control={control}
                      name="isActive"
                      render={({ field: { onChange, value } }) => (
                        <>
                          <label className="switch" htmlFor="activeCheckbox">
                            <input
                              type="checkbox"
                              id="activeCheckbox"
                              name="active"
                              onChange={() => onChange(!value)}
                              checked={value}
                            />
                            <span className="slider round"></span>
                          </label>
                        </>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            {errors["permissions"] && (
              <p className="text-danger">Please select permissions</p>
            )}
            <Tabs activeKey={activeKey} onSelect={onTabChanged}>
              {!isDisabled && (
                <Tab
                  eventKey="Permissions"
                  title={<div className="tab-inner-title">Permissions</div>}
                >
                  <Permissions
                    checked={checked}
                    setChecked={setChecked}
                    treeOptions={allPermissions}
                    permissionsListing={permissionsListing}
                    setPermissionsListing={setPermissionsListing}
                    disabled={isDisabled}
                  />
                </Tab>
              )}
              <Tab
                eventKey="PermissionsSummary"
                title={
                  <div className="tab-inner-title">Permissions Summary</div>
                }
              >
                <Permissions
                  checked={checked}
                  setChecked={setChecked}
                  treeOptions={summaryOptions}
                  disabled={true}
                  permissionsListing={permissionsListing}
                  setPermissionsListing={setPermissionsListing}
                />
              </Tab>
              {isDisabled && (
                <Tab
                  eventKey="Members"
                  title={<div className="tab-inner-title">Members</div>}
                >
                  <Members id={id} />
                </Tab>
              )}
            </Tabs>
            <input
              type="text"
              {...register("permissions", { required: true })}
              className="d-none"
            />
          </div>
        </div>
      </form>
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={() => {
          setOpenModal(false);
          history.push(AppUrls.users_roles);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default UserRoleDetails;
