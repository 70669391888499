import "./FilterPopup.scss"

const FilterPopup = ({ show, setShow, children }) => {


    const onBackgroundClick = (e) => {
        if (e.target.classList.contains("filter-popup"))
            setShow(false)
    }

    return (
        <div className={`filter-popup ${show ? '' : 'hide-popup'}`} onClick={onBackgroundClick}>
            <div>
                {children}
            </div>
        </div>
    )
}

export default FilterPopup