import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useState } from "react";

const DateFilter = ({
  dateCategory = null,
  setDateCategory = null,
  options = [],
  isRequiredStar = false,
}) => {
  const { control, setValue, getValues } = useFormContext();
  const [, setRefreshCounter] = useState(0);

  const onDateChange = (e) => {
    let startDate = null;
    let endDate = null;

    setDateCategory(e);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    if (e?.value === 1) {
      startDate = new Date(currentYear - 1, 0, 1);
      endDate = new Date(currentYear - 1, 11, 31);
    } else if (e.value === 2) {
      const quarter = getQuarter(currentDate);
      startDate = new Date(currentYear, quarter * 3 - 6, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
    } else if (e.value === 3) {
      startDate = new Date(currentYear, currentMonth - 1, 1);
      endDate = new Date(currentYear, currentMonth, 0);
    } else if (e.value === 4) {
      const lastWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 7
      );
      startDate = getFirstDayOfWeek(lastWeek);
      const lastDay = new Date(startDate);
      endDate = new Date(lastDay.setDate(lastDay.getDate() + 6));
    } else if (e.value === 5) {
      startDate = new Date(currentYear, 0, 1);
      endDate = new Date(currentYear, 11, 31);
    } else if (e.value === 6) {
      const quarter = getQuarter(currentDate);
      startDate = new Date(currentYear, quarter * 3 - 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
    } else if (e.value === 7) {
      startDate = new Date(currentYear, currentMonth, 1);
      endDate = new Date(currentYear, currentMonth + 1, 0);
    } else if (e.value === 8) {
      startDate = getFirstDayOfWeek(currentDate);
      const lastDay = new Date(startDate);
      endDate = new Date(lastDay.setDate(lastDay.getDate() + 6));
    } else if (e.value === 9) {
      startDate = new Date(new Date().setFullYear(currentYear - 1));
      endDate = currentDate;
    }

    setValue("startDate", startDate);
    setValue("endDate", endDate);
  };

  const getFirstDayOfWeek = (d) => {
    const date = new Date(d);
    const day = date.getDay();

    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  };

  const getQuarter = (date) => {
    return Math.floor(date.getMonth() / 3 + 1);
  };

  return (
    <div className="row px-0">
      <div className="col-sm-2 form-group">
        <label>
          Date {isRequiredStar && <span className="text-danger">*</span>}
        </label>
        <Select
          options={options}
          isSearchable={true}
          value={dateCategory}
          onChange={onDateChange}
        />
      </div>
      <div className="form-group col-sm-2">
        <label>Start Date</label> <br />
        <Controller
          control={control}
          name="startDate"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              onChange={(e) => {
                onChange(e);
                setRefreshCounter((counter) => counter + 1);
              }}
              selected={value ? value : null}
              disabled={dateCategory?.value !== 10}
              maxDate={getValues("endDate")}
            />
          )}
        />
      </div>
      <div className="form-group col-sm-3">
        <label>End Date</label> <br />
        <Controller
          control={control}
          name="endDate"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              showYearDropdown
              dateFormatCalendar="MMMM"
              yearDropdownItemNumber={15}
              scrollableYearDropdown
              onChange={(e) => {
                onChange(e);
                setRefreshCounter((counter) => counter + 1);
              }}
              selected={value ? value : null}
              disabled={dateCategory?.value !== 10}
              minDate={getValues("startDate")}
            />
          )}
        />
      </div>
    </div>
  );
};

export default DateFilter;