import { GridColumns, QueryResult } from "components/Common.models";
import ItemsModal from "components/ItemOrderModal/ItemsModal";
import Lookup from "elements/Lookup";
import { dateFormmaterNoTime } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { HistoryForm, Hub, InventoryItemHub } from "../Inventory.models";
import {
  getInventoryHubsList,
  getInventoryItems,
  getItemHistory,
} from "../Inventory/InventoryServices/Inventory.services";
// import { ReactComponent as FilterIcon } from 'img/filter.svg';
// import { ReactComponent as CaretUp } from 'img/caretup.svg';
import FilterPopup from "components/FilterPopup/FilterPopup";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { ErrorResponse } from "components/data/Errors.model";
import DispatchContext from "context/DispatchContext";
import { HubInfo } from "pages/Invoices/Invoices.model";

import DateGrid from "components/DateFilter/DateGrid";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

const ItemHistory = () => {
  const appDispatch = useContext(DispatchContext);

  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<InventoryItemHub>(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState<HubInfo>(null);
  const [selectedType, setSelectedType] = useState(null);
  const [dateCategory, setDateCategory] = useState(null);

  const typesOptions = [
    { value: null, label: "All" },
    { value: 2, label: "Serialized" },
    { value: 3, label: "Non Serialized" },
    { value: 4, label: "Non Tangible" },
  ];

  const methods = useForm<HistoryForm>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { data: inventoryHubs } = useQuery<QueryResult<Hub>, ErrorResponse>(
    "inventoryHubsList",
    getInventoryHubsList
  );

  const getItems = useMutation(getInventoryItems);

  const getHistory = useMutation(getItemHistory);

  const sales_history_columns: GridColumns[] = [
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "representativeName",
      headerName: "Rep ID",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No",
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.invoiceDate);
      },
    },
    {
      field: "quantity",
      headerName: "Qty",
    },
    {
      field: "price",
      headerName: "Item Price",
      valueGetter: (params) => {
        return `$${params.data?.price?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "subTotal",
      headerName: "Subtotal",
      valueGetter: (params) => {
        return `$${params.data?.subTotal?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const receipt_history_columns: GridColumns[] = [
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "purchaseOrderNumber",
      headerName: "PO Number",
    },
    {
      field: "purchaseOrderDate",
      headerName: "PO Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "quantityReceived",
      headerName: "Qty Received",
    },
    {
      field: "cost",
      headerName: "PO Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "locationId",
      headerName: "Location ID",
    },
  ];

  const transfer_history_columns: GridColumns[] = [
    {
      field: "transactionQuantity",
      headerName: "Transfer Qty",
    },
    {
      field: "productLine",
      headerName: "Product Line",
    },
    {
      field: "fromLocationId",
      headerName: "From Loc ID",
    },
    {
      field: "toLocationId",
      headerName: "To Loc ID",
    },
    {
      field: "transferNumber",
      headerName: "Tansfer No",
    },
    {
      field: "transferDate",
      headerName: "Tansfer Date",
      cellRenderer: (params) =>
        params.value ? dateFormmaterNoTime(params.value) : "",
    },
  ];

  /*   const pending_po_columns: GridColumns[] = [
    {
      field: "locationId",
      headerName: "Location ID",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "purchaseOrderNumber",
      headerName: "PO Number",
    },
    {
      field: "purchaseOrderDate",
      headerName: "PO Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "openQuantity",
      headerName: "Open Qty",
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      valueGetter: (params) => {
        return `$${params.data?.totalCost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "requestDate",
      headerName: "Request Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
  ]; */

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  useEffect(() => {
    getItems.mutate({ hubId: -1, itemType: 1 });
  }, []);

  const displayFilter = () => {
    if (selectedItem) {
      let queryParams = "";
      if (startDate)
        queryParams += `&start=${new Date(startDate).toLocaleDateString()}`;
      if (endDate)
        queryParams += `&end=${new Date(endDate).toLocaleDateString()}`;

      getHistory.mutate({ itemId: selectedItem.itemId, queryParams });
    }
  };

  const onItemSelected = (item) => {
    setSelectedItem(item);
    setShowItemModal(false);
  };

  const onHubSelection = (hub) => {
    setSelectedHub(hub);
    setOpenSelectionHubs(false);
  };

  const onTypeChange = (e) => {
    setSelectedType(e);
  };

  const clearFilters = () => {
    setStartDate(null);
    setDateCategory(null);
    setEndDate(null);
    getHistory.mutate({ itemId: selectedItem.itemId });
  };

  const filterResults = () => {
    if (selectedItem) {
      let queryParams = "";
      if (startDate)
        queryParams += `&start=${new Date(startDate).toLocaleDateString()}`;
      if (endDate)
        queryParams += `&end=${new Date(endDate).toLocaleDateString()}`;
      if (selectedHub) queryParams += `&hubId=${selectedHub.locationId}`;
      if (selectedType && selectedType?.value)
        queryParams += `&inventoryItemType=${selectedType.value}`;

      getHistory.mutate({ itemId: selectedItem.itemId, queryParams });
    } else {
      let notification = {
        variant: "danger",
        msg: "Please Select Item First",
      };
      appDispatch({ type: "notification", value: notification });
    }
    setShowFilter(false);
  };

  const [exportToExcelClickedSales, setExportToExcelClickedSales] =
    useState<boolean>(false);
  const [exportToExcelClickedReceipt, setExportToExcelClickedReceipt] =
    useState<boolean>(false);
  const [exportToExcelClickedTransfer, setExportToExcelClickedTransfer] =
    useState<boolean>(false);
  const [exportToExcelClickedPendingPO, setExportToExcelClickedPendingPO] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = (tab) => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    if (tab === 1) {
      setExportToExcelClickedSales(true);
    } else if (tab === 2) {
      setExportToExcelClickedReceipt(true);
    } else if (tab === 3) {
      setExportToExcelClickedTransfer(true);
    } else if (tab === 4) {
      setExportToExcelClickedPendingPO(true);
    }
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <div className="page-title page-title-editable">
            Item History
            {/* <button className="btn btn-outline-primary custom-filter-btn" type="button" onClick={togglePopup}>
                            <FilterIcon />
                            Advanced
                            <CaretUp className="caret-icon" />
                        </button> */}
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="section py-3">
                <div className="row">
                  <div className="col-sm-3">
                    <label className="mb-0 me-3">Item</label>
                    <Lookup
                      onButtonClicked={() => setShowItemModal(true)}
                      inputName="itemId"
                      isRequired={false}
                      initialData={getItems.data?.data?.map((item) => ({
                        ...item,
                        id: item.itemId,
                        name: item.itemNumber,
                      }))}
                      onSelection={onItemSelected}
                      inputValue={selectedItem?.itemNumber}
                    />
                  </div>
                  <div className="col-sm-2 d-flex align-items-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={displayFilter}
                    >
                      Display
                    </button>
                    <button
                      type="button"
                      onClick={clearFilters}
                      className="btn btn-outline-primary custom-outline"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <DateGrid
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  dateCategory={dateCategory}
                  setDateCategory={setDateCategory}
                />
              </div>
              <div className="mt-4">
                <Tabs
                  defaultActiveKey="salesHistory"
                  className="left-side ps-3 pe-3"
                >
                  <Tab
                    eventKey="salesHistory"
                    title={<div className="tab-inner-title">Sales History</div>}
                  >
                    <div className="text-end position-relative">
                      <button
                        className="import-btn"
                        style={{
                          position: "absolute",
                          top: "-70px",
                          right: "25px",
                        }}
                        type="button"
                        onClick={() => exportToExcel(1)}
                        disabled={
                          disabledExportBtn ||
                          !selectedItem ||
                          getHistory.data?.data?.salesOrders?.length === 0
                        }
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export to Excel
                      </button>
                    </div>
                    <div className="min-h-350">
                      <AgGrid
                        columns={sales_history_columns}
                        data={getHistory.data?.data?.salesOrders ?? []}
                        exportToExcel={exportToExcelClickedSales}
                        setExportToExcelClicked={setExportToExcelClickedSales}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        fileName="Sales History List Report"
                      />
                    </div>
                  </Tab>
                  <Tab
                    eventKey="receiptHistory"
                    title={
                      <div className="tab-inner-title">Receipt History</div>
                    }
                  >
                    <div className="text-end position-relative">
                      <button
                        className="import-btn"
                        style={{
                          position: "absolute",
                          top: "-70px",
                          right: "25px",
                        }}
                        type="button"
                        onClick={() => exportToExcel(2)}
                        disabled={
                          disabledExportBtn ||
                          getHistory.data?.data?.purchaseOrders?.length === 0
                        }
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export to Excel
                      </button>
                    </div>
                    <div className="min-h-350">
                      <AgGrid
                        columns={receipt_history_columns}
                        data={getHistory.data?.data?.purchaseOrders ?? []}
                        exportToExcel={exportToExcelClickedReceipt}
                        setExportToExcelClicked={setExportToExcelClickedReceipt}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        fileName="Receipt History List Report"
                      />
                    </div>
                  </Tab>
                  <Tab
                    eventKey="transferHistory"
                    title={
                      <div className="tab-inner-title">Transfer History</div>
                    }
                  >
                    <div className="text-end position-relative">
                      <button
                        className="import-btn"
                        style={{
                          position: "absolute",
                          top: "-70px",
                          right: "25px",
                        }}
                        type="button"
                        onClick={() => exportToExcel(3)}
                        disabled={
                          disabledExportBtn ||
                          !selectedItem ||
                          getHistory.data?.data?.transfers?.length === 0
                        }
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export to Excel
                      </button>
                    </div>
                    <div className="min-h-350">
                      <AgGrid
                        columns={transfer_history_columns}
                        data={getHistory.data?.data?.transfers ?? []}
                        exportToExcel={exportToExcelClickedTransfer}
                        setExportToExcelClicked={
                          setExportToExcelClickedTransfer
                        }
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        fileName="Transfer History List Report"
                      />
                    </div>
                  </Tab>
                  <Tab
                    eventKey="pendingPO"
                    title={<div className="tab-inner-title">Pending PO</div>}
                  >
                    <div className="text-end position-relative">
                      <button
                        className="import-btn"
                        style={{
                          position: "absolute",
                          top: "-70px",
                          right: "25px",
                        }}
                        type="button"
                        onClick={() => exportToExcel(4)}
                        disabled={
                          disabledExportBtn ||
                          !selectedItem ||
                          getHistory.data?.data?.pendingPurchaseOrders
                            ?.length === 0
                        }
                      >
                        <img src={exportIcon} className="me-2" alt="" />
                        Export to Excel
                      </button>
                    </div>
                    <div className="min-h-350">
                      <AgGrid
                        columns={transfer_history_columns}
                        data={
                          getHistory.data?.data?.pendingPurchaseOrders ?? []
                        }
                        exportToExcel={exportToExcelClickedPendingPO}
                        setExportToExcelClicked={
                          setExportToExcelClickedPendingPO
                        }
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                      />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </form>
        <FilterPopup show={showFilter} setShow={setShowFilter}>
          <div className="d-flex align-items-center">
            <label className="me-2">Select Hub</label>
            <Lookup
              onButtonClicked={() => setOpenSelectionHubs(true)}
              inputName="hubId"
              initialData={inventoryHubs?.data?.map((item) => ({
                ...item,
                id: item.locationId,
                name: item.hubId,
              }))}
              onSelection={setSelectedHub}
              inputValue={selectedHub?.hubId}
              isDisabled={false}
            />
            <label className="ms-3 me-2">Item Type</label>
            <Select
              options={typesOptions}
              isSearchable={true}
              value={selectedType}
              onChange={onTypeChange}
              className="w-200"
            />
            <button className="btn btn-outline-primary" onClick={filterResults}>
              Filter
            </button>
          </div>
        </FilterPopup>
      </FormProvider>
      <ItemsModal
        showModal={showItemModal}
        setShowModal={setShowItemModal}
        setRowClicked={onItemSelected}
        selectedHub={-1}
        searchHubs={true}
      />
      <SelectionModal
        modal={"Hub"}
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={inventoryHubs?.data}
        columns={hubsColumns}
        setRowClicked={onHubSelection}
      />
    </>
  );
};

export default ItemHistory;
