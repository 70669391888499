import { useState, useContext, useEffect } from "react";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import { FormProvider, useForm } from "react-hook-form";
import Lookup from "elements/Lookup";
import { HubInfo, InvoiceItem } from "pages/Invoices/Invoices.model";
import { ErrorResponse } from "components/data/Errors.model";
import {
  getInventoryHubsList,
  SerialTransferAPI,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { useMutation, useQuery } from "react-query";
import { Hub } from "../Inventory.models";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumns, QueryResult } from "components/Common.models";
import { addedSerials } from "../../Invoices/Invoices.model";
import InvoiceItems from "pages/Invoices/Invoice Tabs/InvoiceItems";
import "react-datepicker/dist/react-datepicker.css";

const SerialTransfer = () => {
  const appDispatch = useContext(DispatchContext);
  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);
  const [selectedHub, setSelectedHub] = useState<HubInfo>(null);
  const [addedItems, setAddedItems] = useState<Array<InvoiceItem>>([]);
  const [addedSerials, setAddedSerials] = useState<addedSerials[]>([]);
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const hubsColumns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location Description",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No.",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No.",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default?",
      editable: false,
    },
    {
      field: "isActive",
      headerName: "Status",
      editable: false,
    },
  ];

  const { isLoading: isLoadingInventory, data: inventoryHubs } = useQuery<
    QueryResult<Hub>,
    ErrorResponse
  >("inventoryHubsList", getInventoryHubsList);

  useEffect(() => {
    if (inventoryHubs && inventoryHubs?.data?.length === 1) {
      setSelectedHub(inventoryHubs?.data[0]);
    }
  }, [inventoryHubs]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingInventory });
  }, [isLoadingInventory]);

  useEffect(() => {
    if (selectedHub) {
      setValue("hubId", selectedHub.hubId, { shouldDirty: true });
      setOpenSelectionHubs(false);
    }
  }, [selectedHub]);

  const {
    formState: { isDirty },
    handleSubmit,
    setValue,
    reset,
  } = methods;

  const onCancel = () => {
    reset();
    setSelectedHub(null);
    setAddedItems([]);
    setAddedSerials([]);
  };

  const SerialTransferM = useMutation(SerialTransferAPI, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Transfer success`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem with Submission"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = () => {
    let serialsToSend = [];
    addedItems.map((item) => {
      addedSerials
        ?.filter((s) => s.itemId === item.itemId)
        ?.map((serial) => {
          serialsToSend.push({
            newItemId: item.itemId,
            addCost: Number(item.invoicePrice),
            serialId: serial.serial,
          });
        });
    });
    let data = {
      hubId: selectedHub.locationId,
      serialTransfers: serialsToSend,
    };
    SerialTransferM.mutate(data);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>Item Serial Transfer</div>
            <div>
              <button
                type="button"
                className={`btn btn-outline-primary no-border ${
                  !isDirty && "text-muted"
                }`}
                disabled={!isDirty}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="left-side-mid" style={{ marginLeft: "10px" }}>
                  <div className="form-group w-50">
                    <label>
                      Hub ID <span className="text-danger">*</span>
                    </label>
                    <Lookup
                      onButtonClicked={() => setOpenSelectionHubs(true)}
                      inputName="hubId"
                      isRequired={true}
                      initialData={inventoryHubs?.data?.map((item) => ({
                        ...item,
                        id: item.locationId,
                        name: item.hubId,
                      }))}
                      onSelection={setSelectedHub}
                      inputValue={selectedHub?.hubId}
                      isDisabled={false}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <InvoiceItems
                  isEditing={true}
                  addedItems={addedItems}
                  setAddedItems={setAddedItems}
                  hubId={selectedHub?.locationId}
                  isCreating={true}
                  customer={null}
                  addedSerials={addedSerials}
                  setAddedSerials={setAddedSerials}
                  isDisabled={!!selectedHub?.locationId}
                  isTrans={true}
                  qtyReq={false}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Hub ID"}
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={inventoryHubs?.data}
        columns={hubsColumns}
        setRowClicked={setSelectedHub}
      />
    </>
  );
};

export default SerialTransfer;
