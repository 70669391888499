import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import {
  checkIfAllowed,
  getCurrencyByCode,
  notificationMessage,
} from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { ShippingMethod } from "./SystemSetup.models";
import {
  createShipping,
  editShipping,
  getShippingList,
} from "./SystemSetupServices/SystemSetupServices";

import { getBaseCurrency } from "components/currency";
import * as AppUrls from "constants/AppUrls";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";
import { Link, useHistory } from "react-router-dom";
import addIcon from "../../img/add-icon.svg";

const ShippingMethods = ({ match }) => {
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const appState = useContext(StateContext);

  const appDispatch = useContext(DispatchContext);
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dataMutation = useMutation("data", getShippingList);
  const { isLoading } = dataMutation;
  const methods = useForm<ShippingMethod>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: true },
  });
  const { reset } = methods;

  const addShipping = useMutation(createShipping, {
    async onSuccess() {
      setRefreshCounter((prev) => prev + 1);
      let notification = {
        variant: "success",
        msg: `Shipping Method ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      resetForm();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding Shipping Method"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateShipping = useMutation(editShipping, {
    async onSuccess() {
      setRefreshCounter((prev) => prev + 1);
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      resetForm();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing Shipping Method"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addShipping?.isLoading || updateShipping?.isLoading,
    });
  }, [addShipping?.isLoading, updateShipping?.isLoading, appDispatch]);

  const columns = [
    {
      field: "name",
      headerName: "Shipping method",
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return currencySymbol + params.data.cost.toFixed(2);
      },
      cellRenderer: (params) => `${params.value}`,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
  ];

  const resetForm = () => {
    reset();
  };

  const history = useHistory();

  const onRowDoubleClicked = (e: ShippingMethod) => {
    const url = `${AppUrls.shipping_methods}/${e.shippingMethodId}`;
    history.push(url);
    // setSelectedId(e.shippingMethodId);
    // setValue("name", e.name, { shouldValidate: true, shouldTouch: true });
    // setValue("isActive", e.isActive, {
    //   shouldValidate: true,
    //   shouldTouch: true,
    // });
    // setValue("cost", e.cost, { shouldValidate: true, shouldTouch: true });
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    dataMutation.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Shipping Methods</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || isLoading || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_shipping_methods} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Shipping Method
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row h-100">
          <div className=" grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                fetchMethod={dataMutation}
                data={dataGrid}
                setDataGrid={setDataGrid}
                filters={filter}
                setrowClicked={onRowDoubleClicked}
                exportToExcel={exportToExcelClicked}
                setExportToExcelClicked={setExportToExcelClicked}
                setDisabledExportBtn={setDisabledExportBtn}
                setIsLoadingGridExport={setIsLoadingGridExport}
                refreshCounter={refreshCounter}
                fileName="Shipping Methods List Report"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingMethods;
