import { useContext, useEffect, useState } from "react";

import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory, useParams } from "react-router-dom";

import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import SetupForm from "pages/SystemSetup/SetupForm";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserTypeModel } from "./UserManagement.models";
import {
  createUserType,
  editUserType,
  getUserTypes,
} from "./UserManagement.services";

export default function NewUserTypeSetup() {
  const { id } = useParams();
  const history = useHistory();

  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();
  const [selectedId, setSelectedId] = useState<number>(null);
  const { data: userTypes } = useQuery<UserTypeModel[], ErrorResponse>(
    "get_user_types",
    getUserTypes
  );
  useEffect(() => {
    if (id && userTypes && userTypes.length > 0) {
      let idNum = Number(id);
      let data = userTypes?.find((item) => item?.userTypeId === idNum);
      setSelectedId(idNum);
      setValue("name", data.name);
      setValue("isActive", data.isActive);
    }
  }, [userTypes, id]);

  const methods = useForm<UserTypeModel>({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { handleSubmit, reset, setValue } = methods;

  const addUserType = useMutation(createUserType, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `User Type ${CREATE_SUCCESS_MSG}`,
      };
      queryClient.invalidateQueries("get_user_types");
      appDispatch({ type: "notification", value: notification });
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding user type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateUserType = useMutation(editUserType, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      queryClient.invalidateQueries("get_user_types");
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.users_type);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing user type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: addUserType.isLoading });
  }, [addUserType.isLoading]);

  const onSubmit = (values: UserTypeModel) => {
    if (!selectedId) {
      addUserType.mutate(values);
    } else {
      updateUserType.mutate({ ...values, userTypeId: selectedId });
    }
  };

  const resetForm = () => {
    setSelectedId(null);
    reset();
    history.push(AppUrls.users_type);
  };

  return (
    <div className="static-page">
      <div className="  page-title page-title-editable">
        <div className="d-flex flex-row">
          <div className="back-btn mt-2 ">
            <img
              src={backArrow}
              alt="back arrow"
              onClick={() => history.push(AppUrls.users_type)}
            />
          </div>
          <div className="p-2">
            <p className="">User Type</p>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper">
        <div>
          <div className="">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <SetupForm
                  name={"User Type Name"}
                  btn={!selectedId ? "Add User Type" : "Save Changes"}
                  resetData={resetForm}
                  active={!!selectedId}
                />
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
