export default function AccessDenied() {

    return (
        <div className="d-flex align-items-center justify-content-center h-100 flex-column">
            <h1>Access Denied</h1>
            <h4>You dont have permission to view this page.</h4>
            <h4>🚫🚫🚫🚫</h4>
        </div>
    );
}
