import api from "components/data/AxiosClient";
import { Console } from "console";

export const getSalesOrders = async (params) => {
  if (params) {
    const response = await api.get(
      `sales-order?PageSize=${params.PageSize}&PageStart=${isNaN(params.PageStart) ? 0 : params.PageStart}&states=8${params.searchQuery != undefined ? `&searchQuery=${params?.searchQuery}` : ""}`
    );
    return response.data;
  } else {
    const response = await api.get(
      `sales-order${params.searchQuery != undefined ? `?searchQuery=${params?.searchQuery}` : ""}`
    );
    return response.data;
  }
};

export const getRmaList = async (params) => {
  if (params) {
    const response = await api.get(
      `rma?PageSize=${params.PageSize}&PageStart=${isNaN(params.PageStart) ? 0 : params.PageStart}${params.searchQuery != undefined ? `&searchQuery=${params?.searchQuery}` : ""}`
    );
    return response.data;
  } else {
    const response = await api.get(
      `rma${params.searchQuery != undefined ? `&searchQuery=${params?.searchQuery}` : ""}`
    );
    return response.data;
  }
};

export const getRmaDetails = async (id) => {
  const response = await api.get(`rma/${id}`);
  return response.data;
};

export const rejectRma = async (id) => {
  const response = await api.post(`rma/${id}/reject`);
  return response.data;
};

export const approveRma = async (id) => {
  const response = await api.post(`rma/${id}/approve`);
  return response.data;
};

export const receiveRma = async (id) => {
  const response = await api.post(`rma/${id}/mark-received`);
  return response.data;
};

export const replaceRma = async (data) => {
  const response = await api.post(`rma/${data.id}/replace`, data);
  return response.data;
};

export const completeRma = async (id) => {
  const response = await api.post(`rma/${id}/mark-complete`);
  return response.data;
};

export const estimateSo = async (data) => {
  const response = await api.post(`sales-order/estimate/${data.id}`, data);
  return response.data;
};

export const ackEstimate = async (id) => {
  const response = await api.post(`sales-order/estimate/${id}/acknowledge`);
  return response.data;
};

export const getNotifications = async () => {
  const response = await api.get(`customers/portal/admin/notifications`);
  return response.data;
};

export const notificationSeen = async (ids: number[]) => {
  const response = await api.post(
    `customers/portal/admin/notifications/seen`,
    ids
  );
  return response.data;
};

export const getReasons = async () => {
  const response = await api.get(`portal-admin/rma/return-reasons`);
  return response.data;
};

export const createReason = async (data) => {
  const response = await api.post(`portal-admin/rma/return-reasons`, data);
  return response.data;
};

export const editReason = async (data) => {
  const response = await api.put(
    `portal-admin/rma/return-reasons/${data.id}`,
    data
  );
  return response.data;
};

export const deleteReason = async (id) => {
  const response = await api.delete(`portal-admin/rma/return-reasons/${id}`);
  return response.data;
};

export const getAdminCreditMemos = async (params) => {
  const response = await api.get(
    `portal-admin/credit-memo?PageSize=${params.PageSize}&PageStart=${isNaN(params.PageStart) ? 0 : params.PageStart}${params.searchQuery != undefined ? `&searchQuery=${params?.searchQuery}` : ""}`
  );
  return response.data;
};
