import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import { dateFormmaterNoTime, notificationMessage } from "global/helpers";

import backArrow from "img/back-arrow.svg";

import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getBillPaymentById, voidBillPayment } from "./InvoicesServices/InvoicesServices";
const ReceivePaymentDetail = ({ match }) => {

    const { id } = useParams();
    const history = useHistory()
    const appDispatch = useContext(DispatchContext);

    const {
        data: data
    } = useQuery([id], getBillPaymentById);

    const voidMutation = useMutation(voidBillPayment, {
        async onSuccess() {
            let notification = {
                variant: "success",
                msg: `Payment voided successfully`
            };
            appDispatch({ type: "notification", value: notification });
            history.push(`${AppUrls.receive_invoice_payment_list}`);
        },
        onError(error) {
            let notification = {
                variant: "danger",
                msg: notificationMessage(error, "problem voiding payment"),
            };
            appDispatch({ type: "notification", value: notification });
        },
    });

    const onBackClick = () => {
        history.push(AppUrls.receive_invoice_payment_list)
    }

    const onVoid = () => {
        voidMutation.mutate(id)
    }

    return (
        <form>
            <div className="page-title page-title-editable">
                <div className="back-btn " onClick={onBackClick}>
                    <img src={backArrow} alt="back arrow" />
                    Receipt {data?.receiptNumber}
                </div>
                <button type="button" className="btn btn-danger" onClick={onVoid}>
                    Void
                </button>
            </div>
            <div className="page-content-wrapper">
                <div className="page-content">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Payment Amount</label>
                                        <p>${data?.paymentAmount.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label>Payment Date</label>
                                        <p>{dateFormmaterNoTime(data?.paymentDate)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col section py-3">
                            <h5><b>Vendor Info</b></h5>
                            <hr />
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Vendor No    </label>
                                        <p>{data?.receiveInvoice?.vendor?.vendorNumber ?? <span className="fst-italic text-muted">Name</span>}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label>Company</label>
                                        <p>{data?.receiveInvoice?.vendor?.company ?? <span className="fst-italic text-muted">Company</span>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ReceivePaymentDetail