import { useState, useEffect, useContext } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Dropdown, Tabs, Tab } from "react-bootstrap";
import { useParams, Link, useHistory } from "react-router-dom";
import { ThreeDots } from "react-bootstrap-icons";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Edit from "img/edit.svg";
import Delete from "img/delete.svg";
import backArrow from "img/back-arrow.svg";
import { states } from "components/data/States";
import { useMutation } from "react-query";
import DeleteRepresentative from "./DeleteRepresentative";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import "./RepresentativeDetail.scss";
import * as AppUrls from "constants/AppUrls";
import AG from "elements/AG";
import { RepresentativeModelDTO } from "./Representative.models";
import {
  getRepresentativeById,
  createRepresentative,
  editRepresentative,
} from "./Representative.services";
import { emailRegEx, zipCodeRegEx } from "global/regExp/regularExpressions";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import SectionTitle from "elements/SectionTitle";

const RepresentativeDetails = ({ match }) => {
  const { id } = useParams();
  const isNewRep: boolean = match.url === AppUrls.new_representatives;
  // Flag - Page is active - User can edit fields
  const [active, setActive] = useState<boolean>(false);
  // Data fetched from database for edit OR data fetched from inputs if creating
  const [data, setData] = useState<RepresentativeModelDTO>(null);
  // Flag - used to delete popup
  const [showDelete, setShowDelete] = useState(false);
  let history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  //const queryClient = useQueryClient();

  // Used for Form Validation
  const methods = useForm<RepresentativeModelDTO>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: data,
  });
  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    handleSubmit,
    reset,
    register,
  } = methods;

  const RepTypesOptions: any[] = [
    { value: 1, label: "support" },
    { value: 2, label: "sales" },
    { value: 3, label: "iso" },
  ];

  //const { isSuccess: isSuccessRep, isLoading: isLoadingRep, data: currentRepresentative } = useQuery<RepresentativeModelDTO, ErrorResponse>(["get_representative", id], () => getRepresentativeById(id), { enabled: isNewRep });

  const getRepDetails = useMutation(getRepresentativeById, {
    async onSuccess(result) {
      if (result) {
        setData(result);
        reset(result);
      }
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: getRepDetails.isLoading });
  }, [getRepDetails.isLoading, appDispatch]);

  useEffect(() => {
    if (isNewRep) {
      // If not, page is new, set active to true and isNew flag to true to allow user input
      setActive(true);
    } else {
      getRepDetails.mutate(id);
    }
  }, [isNewRep]);

  useEffect(() => {
    if (!isNewRep && data) {
      reset(data);
    }
  }, [data, isNewRep]);

  const cancelForm = () => {
    reset();
    if (!isNewRep) setActive(false);
  };

  const addRepresentative = useMutation(createRepresentative, {
    async onSuccess() {
      const notification = {
        variant: "success",
        msg: `Representative ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(`${AppUrls.representatives}`);
      setActive(false);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding Representative"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateRepresentative = useMutation(editRepresentative, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem editing represantative"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = async (data: RepresentativeModelDTO) => {
    if (isNewRep) {
      addRepresentative.mutate(data);
    } else {
      if (data?.hasCustomer && !data.isActive) {
        let notification = {
          variant: "danger",
          msg: "You cannot disable a representative representing customers.",
        };
        appDispatch({ type: "notification", value: notification });
      } else {
        updateRepresentative.mutate(data);
      }
    }
  };

  let actions = <></>;
  if (isNewRep) {
    actions = (
      <>
        <button
          type="button"
          className={`btn btn-outline-primary no-border ${
            !isDirty && "text-muted"
          }`}
          disabled={!isDirty}
          onClick={cancelForm}
        >
          Reset
        </button>
        <button
          type="submit"
          className="btn btn-success"
          disabled={!isDirty || isSubmitSuccessful}
        >
          Create
        </button>
      </>
    );
  } else if (active) {
    actions = (
      <>
        <button
          type="button"
          className="btn btn-outline-primary no-border"
          onClick={cancelForm}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-success" disabled={!isDirty}>
          Save Changes
        </button>
      </>
    );
  } else {
    actions = (checkIfAllowed(appState.allowedUrls, "edit", match.path) ||
      checkIfAllowed(appState.allowedUrls, "delete", match.path)) && (
      <Dropdown>
        <Dropdown.Toggle variant="primary" className="dropdown-btn">
          <ThreeDots />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
            <Dropdown.Item className="mb-1" onClick={() => setActive(true)}>
              <span>
                <img src={Edit} alt="edit" className="dp-icon me-3" />
              </span>
              Edit
            </Dropdown.Item>
          )}
          {checkIfAllowed(appState.allowedUrls, "delete", match.path) && (
            <Dropdown.Item className="mb-1" onClick={() => setShowDelete(true)}>
              <span>
                <img src={Delete} alt="delete" className="dp-icon me-3" />
              </span>
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addRepresentative.isLoading || updateRepresentative.isLoading,
    });
  }, [addRepresentative.isLoading, updateRepresentative.isLoading]);

  const content = (
    <div className="row">
      <div className="left-side">
        <SectionTitle editable={false} title="Representative info" />
        <div className="row ">
          <div className="left-side-mid">
            <div className="form-group">
              <label>
                Representative Name <span className="text-danger">*</span>
              </label>
              <input
                {...register("repName", { required: true })}
                className={`form-control ${errors.repName && "required_field"}`}
                disabled={isNewRep || active ? false : true}
              />
              {errors["repName"] && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
          </div>
          <div className="right-side-mid">
            <div className="form-group">
              <label>
                Type <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                name="repType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="repType"
                    isDisabled={isNewRep || active ? false : true}
                    options={RepTypesOptions}
                    isSearchable={true}
                    value={
                      RepTypesOptions.find((opt) => opt?.value === value) ||
                      null
                    }
                    onChange={(selectedOption: any) =>
                      onChange(selectedOption?.value)
                    }
                    className={errors.repType && "required_field"}
                  />
                )}
              />
              {errors["repType"] && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-lg-6">
            <div className="mt-3">
              {isNewRep || active ? (
                <Controller
                  name="isActive"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <div className="form-group mt-2">
                        {value ? (
                          <label className="row p-2 ">Active</label>
                        ) : (
                          <label className="row p-2"> Disabled</label>
                        )}
                        <label className="switch" htmlFor="activeCheckbox">
                          <input
                            type="checkbox"
                            id="activeCheckbox"
                            name="active"
                            onChange={() => onChange(!value)}
                            checked={value}
                          />
                          <span className="slider round"></span>
                        </label>
                        {errors.isActive && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </>
                  )}
                />
              ) : (
                <div
                  className={
                    data?.isActive ? "active-status" : "disabled-status"
                  }
                >
                  {data?.isActive ? "Active" : "Disabled"}
                </div>
              )}
            </div>
          </div>
        </div>
        <SectionTitle editable={false} title="Contact Details" />
        <div className="row ">
          <div className="left-side-mid">
            <div className="form-group">
              <label>
                Contact <span className="text-danger">*</span>
              </label>
              <input
                {...register("contact", { required: true })}
                className={`form-control ${errors.contact && "required_field"}`}
                disabled={isNewRep || active ? false : true}
              />
              {errors["contact"] && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
          </div>
          <div className="right-side-mid">
            <div className="form-group">
              <label>
                Email <span className="text-danger">*</span>
              </label>
              <input
                {...register("email", {
                  required: true,
                  pattern: {
                    value: emailRegEx,
                    message: "Entered value does not match email format.",
                  },
                })}
                className={
                  errors["email"]
                    ? "form-control required_field"
                    : "form-control"
                }
                disabled={isNewRep || active ? false : true}
              />
              {errors["email"] && (
                <p className="text-danger">This field is required</p>
              )}
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="left-side-mid">
            <div className="form-group">
              <label>Work No.</label>
              <Controller
                name="workNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    country={"us"}
                    disabled={!active}
                  />
                )}
              />
            </div>
          </div>
          <div className="right-side-mid">
            <div className="form-group">
              <label>Mobile No.</label>
              <Controller
                name="mobileNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    country={"us"}
                    disabled={!active}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-side section p-3">
        <h4>Address</h4>
        <div className="form-group">
          <label>Address</label>
          <input
            {...register("address")}
            className="form-control"
            disabled={isNewRep || active ? false : true}
          />
        </div>
        <div className="form-group">
          <label>Address 1</label>
          <input
            {...register("address1")}
            className="form-control"
            disabled={isNewRep || active ? false : true}
          />
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>City</label>
              <input
                {...register("city")}
                className="form-control"
                disabled={!active}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>State</label>
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled={!active}
                    options={states}
                    isSearchable={true}
                    value={states.find((opt) => opt?.value === value) || null}
                    onChange={(selectedOption: any) =>
                      onChange(selectedOption?.value)
                    }
                    isClearable
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="left-side-mid">
            <div className="form-group">
              <label>Zip Code</label>
              <input
                {...register("zipCode", {
                  pattern: {
                    value: zipCodeRegEx,
                    message: "Entered value does not match zipCode format.",
                  },
                })}
                className="form-control"
                disabled={!active}
              />
              {errors.zipCode && (
                <p className="text-danger">{errors.zipCode?.message}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const customers_columns = [
    {
      field: "accountNumber",
      headerName: "Account Number",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "HIB ID",
    },
    {
      field: "city",
      headerName: "City",
    },
    {
      field: "state",
      headerName: "State",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
  ];
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <Link to={AppUrls.representatives} className="back-btn ">
              <img src={backArrow} alt="back arrow" />
              {isNewRep ? (
                <>New Representative</>
              ) : (
                <>Representative {data?.repName}</>
              )}
            </Link>
            <div>{actions}</div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              {isNewRep ? (
                content
              ) : (
                <Tabs
                  className="repTabs left-side ps-3 pe-3"
                  defaultActiveKey="RepDetailsTab"
                >
                  <Tab
                    eventKey="RepDetailsTab"
                    title={<div className="tab-inner-title">Rep Info</div>}
                  >
                    {content}
                  </Tab>
                  <Tab
                    eventKey="accountsTab"
                    title={
                      <div className="tab-inner-title">
                        Represententing Accounts{" "}
                        <span className="badge text-dark badge-pill ms-2">
                          {data?.customers.length}
                        </span>
                      </div>
                    }
                  >
                    <div className="ag-container-representative">
                      <AG columns={customers_columns} data={data?.customers} />
                    </div>
                  </Tab>
                </Tabs>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      <DeleteRepresentative
        showDelete={showDelete}
        onExit={() => setShowDelete(false)}
        repId={data?.repId}
        attached={data?.hasCustomer}
      />
    </>
  );
};

export default RepresentativeDetails;
