import { useState, useContext, useEffect, useRef } from "react";
import Lookup from "../../elements/Lookup";
import {
  getCustomerById,
  getCustomers,
} from "../SalesMarketing/Customers/Customers.services";
import { useForm, FormProvider, Controller } from "react-hook-form";
import Select from "react-select";
import { useMutation, useQuery } from "react-query";
import DispatchContext from "../../context/DispatchContext";
import {
  ApplyPaymentsForm,
  CustomerAccount,
  InvoiceEntry,
  PaymentMethod,
} from "./Invoices.model";
import SelectionModal from "../../components/SelectionModal/SelectionModal";
import { GridColumns } from "../../components/Common.models";
import DatePicker from "react-datepicker";
import {
  applyPayments,
  applyUnappliedPayments,
  autoApplyCredits,
  getInvoice,
  getInvoicesByCustomer,
  getPaymentMethods,
  getUnappliedPayments,
  manualApplyCredits,
} from "./InvoicesServices/InvoicesServices";
import AG from "../../elements/AG";
import { CustomerPayment } from "../SalesMarketing/Customers/Customers.models";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  cloneObj,
  formatInput,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import { ReactComponent as AlertIcon } from "img/alert.svg";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import { getLedgers } from "pages/Accounting/AccountingServices";

const ApplyCreditMemo = () => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [openMemos, setOpenMemos] = useState<boolean>(false);
  const [openUnapplied, setOpenUnapplied] = useState<boolean>(false);
  const [selectedUnapplied, setSelectedUnapplied] = useState<CustomerPayment>();
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerAccount>();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceEntry>();
  const [openInvoices, setOpenInvoices] = useState<InvoiceEntry[]>([]);
  const [initialOpenInvoices, setInitialOpenInvoices] = useState<
    InvoiceEntry[]
  >([]);
  const [uneditedInvoices, setUneditedInvoices] = useState<InvoiceEntry[]>([]);
  const [creditMemos, setCreditMemos] = useState<InvoiceEntry[]>();
  const [unappliedPayments, setUnappliedPayments] =
    useState<CustomerPayment[]>();
  const [showManualConfirmation, setShowManualConfirmation] =
    useState<boolean>();
  const [showAutoConfirmation, setShowAutoConfirmation] = useState<boolean>();
  const [showCreditsConfirmation, setShowCreditsConfirmation] =
    useState<boolean>();
  const [showPaymentConfirmation, setShowPaymentConfirmation] =
    useState<boolean>();
  const [selectedMethod, setSelectedMethod] = useState<number>(1);
  const [applyType, setApplyType] = useState<number>(null);
  const [customIsDirty, setCutsomIsDirty] = useState<boolean>(false);
  const [gridRowChecked, setGridRowChecked] = useState<InvoiceEntry[]>([]);
  const allowSubmit = useRef<boolean>(false);
  const selectedType = useRef(LedgerTypeEnum.Asset);

  const appDispatch = useContext(DispatchContext);

  const accountNumberColumns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "hubId",
      headerName: "Hub Key",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "state",
      headerName: "State",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const creditColumns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "date",
      headerName: "Inv. Date",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "taxAmount",
      headerName: "Tax Amount",
      valueGetter: (params) => {
        return `$${params.data?.taxAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      valueGetter: (params) => {
        return `$${params.data?.paidAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "invoiceBalance",
      headerName: "Inv. Balance",
      cellRenderer: (params) =>
        formatNumber(params.data.amount - params.data.paidAmount),
    },
    {
      field: "aging",
      headerName: "Aging",
    },
  ];

  const unappliedColumns: GridColumns[] = [
    {
      field: "receiptNumber",
      headerName: "Receipt No.",
    },
    {
      field: "checkNumber",
      headerName: "Check Number",
    },
    {
      field: "refNumber",
      headerName: "Ref No.",
    },
    {
      field: "remark",
      headerName: "Remark",
    },
    {
      field: "paymentMethod",
      headerName: "Pay type",
    },
    {
      field: "date",
      headerName: "Date Paid",
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "",
      headerName: "Payment Amount",
      cellRenderer: (params) =>
        formatNumber(params.data.payment + params.data.unapplied),
    },
    {
      field: "payment",
      headerName: "Applied Amount",
      valueGetter: (params) => {
        return `$${params.data?.payment?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "unapplied",
      headerName: "Unapplied Amount",
      valueGetter: (params) => {
        return `$${params.data?.unapplied?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const gridColumns: GridColumns[] = [
    {
      headerName: "",
      field: "RowSelect",
      checkboxSelection: true,
      floatingFilter: false,
      filter: false,
      width: "45",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    {
      field: "payment",
      headerName: "Payment",
      editable: applyType === 1,
      cellStyle: { color: "black", "background-color": "#e6f7ff" },
    },
    {
      field: "company",
      headerName: "Company",
    },
    {
      field: "date",
      headerName: "Inv. Date",
      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "taxAmount",
      headerName: "Taxes",
      valueGetter: (params) => {
        return `$${params.data?.taxAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
      valueGetter: (params) => {
        return `$${params.data?.paidAmount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "invoiceBalance",
      headerName: "Inv. Balance",
      cellRenderer: (params) =>
        formatNumber(params.data.amount - params.data.paidAmount),
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
    },
    // {
    //     field: "invoiceType",
    //     headerName: "Inv. Type",
    // },
    {
      field: "aging",
      headerName: "Days Old",
    },
    {
      field: "newBalance",
      headerName: "New Balance",
      valueGetter: (params) => {
        return formatNumber(
          params.data.amount -
            params.data.paidAmount -
            (params.getValue("payment") ? params.getValue("payment") : 0)
        );
      },
    },
  ];

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const { data: paymentAccounts, refetch } = useQuery(
    "expenses",
    () => getLedgers({ ledgerType: selectedType.current }),
    {
      onSuccess(data) {
        if (data.count === 0)
          if (selectedType.current === LedgerTypeEnum.Cash)
            setValue("paymentAccountId", "0");
          else if (selectedType.current === LedgerTypeEnum.Bank)
            setValue("paymentAccountId", "-1");
      },
    }
  );

  const [selectedLedger, setSelectedLedger] = useState(null);
  const [openSelectionLedger, setOpenSelectionLedger] =
    useState<boolean>(false);

  const onLedgerSelection = (e) => {
    setSelectedLedger(e);
    setOpenSelectionLedger(false);
    setValue("paymentAccountId", e.ledgerId);
  };

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    "customersList",
    getCustomers
  );

  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );

  const getInvoicesList = useMutation(getInvoicesByCustomer, {
    async onSuccess(response) {
      setOpenInvoices(
        cloneObj(response?.data?.filter((invoice) => invoice.invoiceType === 1))
      );
      setInitialOpenInvoices(
        cloneObj(response?.data?.filter((invoice) => invoice.invoiceType === 1))
      );
      setUneditedInvoices(
        cloneObj(response?.data?.filter((invoice) => invoice.invoiceType === 1))
      );
      setCreditMemos(
        response?.data?.filter((invoice) => invoice.invoiceType === 2)
      );
    },
  });

  const getUnappliedList = useMutation(getUnappliedPayments, {
    async onSuccess(response) {
      setUnappliedPayments(response?.data);
    },
  });

  const submitManual = useMutation(manualApplyCredits, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Credits applied successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Faild to apply credits"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitAuto = useMutation(autoApplyCredits, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Credits applied successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Faild to apply credits"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitApply = useMutation(applyPayments, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Payment applied successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Faild to apply payment"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const submitUnapplied = useMutation(applyUnappliedPayments, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Payment applied successfully",
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Faild to apply payment"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const getDetails = useMutation(getCustomerById, {
    async onSuccess(response) {
      setCustomerDetails(response);
      if (response.openCredits < 0) {
        setShowCreditsConfirmation(true);
        setSelectedMethod(1);
      }
    },
  });

  const getCreditMemo = useMutation(getInvoice);

  const methods = useForm<ApplyPaymentsForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      paymentDate: new Date(),
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
    register,
    reset,
    getValues,
    control,
  } = methods;

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingCustomers });
  }, [isLoadingCustomers]);

  useEffect(() => {
    if (applyType === 2) {
      setOpenInvoices([]);
      let invoices = cloneObj(openInvoices);
      let credits = 0;
      if (selectedMethod === 1) credits = getOpenCredit();
      else if (selectedMethod === 2)
        credits = parseFloat(
          getValues("paymentAmount")?.toString().replace(/,/g, "")
        );
      else if (selectedMethod === 3 && selectedUnapplied)
        credits = selectedUnapplied.unapplied;
      for (let invoice of invoices)
        if (credits > 0)
          if (invoice.amount - invoice.paidAmount >= credits) {
            invoice.payment = credits.toString();
            credits = 0;
          } else if (credits > invoice.amount - invoice.paidAmount) {
            invoice.payment = (invoice.amount - invoice.paidAmount).toString();
            credits = credits - (invoice.amount - invoice.paidAmount);
          }
      allowSubmit.current = true;
      setOpenInvoices(invoices);
    }
  }, [applyType]);

  const onCustomerSelected = (customer) => {
    setValue("customerAccountId", customer.customerAccountId, {
      shouldDirty: true,
    });
    setSelectedCustomer(customer);
    getDetails.mutate(customer["customerAccountId"]);
    getInvoicesList.mutate(customer.customerAccountId);
    getUnappliedList.mutate(customer.customerAccountId);
    setOpenSelection(false);
    setSelectedInvoice(null);
    setCutsomIsDirty(true);
  };

  const onInvoiceSelected = (invoice) => {
    setValue("invoiceId", invoice.invoiceId, { shouldDirty: true });
    setSelectedInvoice(invoice);
    getCreditMemo.mutate({ queryKey: [invoice["invoiceId"]] });
    setOpenMemos(false);
    setApplyType(null);
  };

  const onUnappliedSelected = (payment) => {
    setSelectedUnapplied(payment);
    setOpenUnapplied(false);
  };

  const onCancel = () => {
    reset();
    setSelectedCustomer(null);
    setSelectedInvoice(null);
    setCustomerDetails(null);
    setApplyType(null);
    setSelectedMethod(1);
    setOpenInvoices([]);
    setGridRowChecked([]);
    setCutsomIsDirty(false);
    allowSubmit.current = false;
  };

  const onSubmit = (values) => {
    if (selectedMethod === 1) {
      if (applyType === 1) {
        if (getRemainingCredit() < 0) {
          let notification = {
            variant: "danger",
            msg: "No remaining credits, please edit applied payments",
          };
          appDispatch({ type: "notification", value: notification });
        } else {
          let credits = openInvoices
            .filter((invoice) => invoice.payment)
            .map((credit) => ({
              invoiceId: credit.invoiceId,
              amount: credit.payment,
            }));
          submitManual.mutate({
            creditId: values.invoiceId,
            credits: credits,
            remark: values.remarks,
          });
        }
      } else if (applyType === 2) {
        submitAuto.mutate({ id: values.invoiceId, remark: values.remarks });
      }
    } else if (selectedMethod === 2) {
      let payments = 0;
      if (openInvoices) {
        for (let invoice of openInvoices)
          if (invoice.payment) payments += Number(invoice.payment);
      }
      if (
        payments >
        parseFloat(getValues("paymentAmount")?.toString().replace(/,/g, ""))
      ) {
        let notification = {
          variant: "danger",
          msg: "Applied payments surpass the payment amount",
        };
        appDispatch({ type: "notification", value: notification });
      } else {
        let credits = openInvoices
          .filter((invoice) => invoice.payment)
          .map((credit) => ({
            invoiceId: credit.invoiceId,
            payment: credit.payment,
          }));
        let data = {
          paymentAmount: parseFloat(
            values.paymentAmount?.toString().replace(/,/g, "")
          ),
          paymentMethodId: values.paymentMethodId,
          checkNumber: values.checkNumber,
          paymentDate: values.paymentDate,
          invoicePayments: credits,
          remarks: values.remarks,
          depositTo: selectedLedger?.ledgerId,
        };
        submitApply.mutate(data);
      }
    } else if (selectedMethod === 3 && selectedUnapplied) {
      let payments = 0;
      if (openInvoices) {
        for (let invoice of openInvoices)
          if (invoice.payment) payments += Number(invoice.payment);
      }
      if (payments > selectedUnapplied?.unapplied) {
        let notification = {
          variant: "danger",
          msg: "Applied payments surpass the payment amount",
        };
        appDispatch({ type: "notification", value: notification });
      } else if (payments < selectedUnapplied?.unapplied) {
        setShowPaymentConfirmation(true);
      } else {
        let credits = openInvoices
          .filter((invoice) => invoice.payment)
          .map((credit) => ({
            invoiceId: credit.invoiceId,
            payment: credit.payment,
            remarks: values.remarks,
          }));
        let data = {
          receiptId: selectedUnapplied.paymentReceiptId,
          paidDate: values.paymentDate ? values.paymentDate.toISOString() : "",
          payments: credits,
        };
        submitUnapplied.mutate(data);
      }
    }
  };

  const getOpenCredit = () => {
    if (getCreditMemo.data && selectedInvoice)
      return getCreditMemo.data.data.total - getCreditMemo.data.data.paidAmount;
    return null;
  };

  const getRemainingCredit = () => {
    let payments = 0;
    if (openInvoices) {
      for (let invoice of openInvoices)
        if (invoice.payment) payments += Number(invoice.payment);
    }
    if (getCreditMemo.data && selectedInvoice)
      return (
        getCreditMemo.data.data.total -
        getCreditMemo.data.data.paidAmount -
        payments
      );
    return null;
  };

  const onPaymentValueChanged = (e) => {
    if (isNaN(e.data.payment)) {
      let notification = {
        variant: "danger",
        msg: "Invalid value. Please enter a number",
      };
      appDispatch({ type: "notification", value: notification });
      setOpenInvoices([]);
      setOpenInvoices(initialOpenInvoices);
    } else if (Number(e.data.payment) > e.data.amount - e.data.paidAmount) {
      let notification = {
        variant: "danger",
        msg: "Entered value is greater than the invoice balance",
      };
      appDispatch({ type: "notification", value: notification });
      setOpenInvoices([]);
      setOpenInvoices(initialOpenInvoices);
    } else {
      let updatedInvoices = openInvoices.map((invoice) => {
        if (invoice.invoiceId === e.data.invoiceId) return { ...e.data };
        return { ...invoice };
      });
      setOpenInvoices(updatedInvoices);
      setInitialOpenInvoices(updatedInvoices);
      setCutsomIsDirty(true);
    }
  };

  const onRadioChange = (e) => {
    setOpenInvoices(cloneObj(uneditedInvoices));
    setApplyType(null);
    setSelectedMethod(parseInt(e.target.value));
  };

  const onPaymentConfirmation = () => {
    setShowPaymentConfirmation(false);
    let values = getValues();
    let credits = openInvoices
      .filter((invoice) => invoice.payment)
      .map((credit) => ({
        invoiceId: credit.invoiceId,
        payment: credit.payment,
      }));
    let data = {
      receiptId: selectedUnapplied.paymentReceiptId,
      paidDate: values.paymentDate ? values.paymentDate.toISOString() : "",
      payments: credits,
      remarks: values.remarks,
    };
    submitUnapplied.mutate(data);
  };

  useEffect(() => {
    if (selectedMethod !== 1) {
      setSelectedInvoice(null);
      // setCustomerDetails(null)
    }
    if (selectedMethod !== 2) {
      setValue("paymentAmount", undefined);
      setValue("paymentMethodId", null);
      setValue("checkNumber", null);
      setValue("paymentDate", new Date());
    }
    if (selectedMethod !== 3) {
      setSelectedUnapplied(null);
    }
  }, [selectedMethod]);

  let options = [];
  if (paymentMethods)
    options = paymentMethods.map((method) => ({
      label: method.name,
      value: method.paymentMethodId,
    }));

  const applyManualPaymentToGrid = () => {
    if (gridRowChecked.length > 0) {
      setOpenInvoices([]);
      let invoices = cloneObj(openInvoices);
      let credits = 0;
      if (selectedMethod === 1) credits = getOpenCredit();
      else if (selectedMethod === 2)
        credits = parseFloat(
          getValues("paymentAmount")?.toString().replace(/,/g, "")
        );
      else if (selectedMethod === 3 && selectedUnapplied)
        credits = selectedUnapplied.unapplied;
      for (let invoice of invoices)
        if (gridRowChecked.find((i) => i.invoiceId === invoice.invoiceId))
          if (credits > 0)
            if (invoice.amount - invoice.paidAmount >= credits) {
              invoice.payment = credits.toString();
              credits = 0;
            } else if (credits > invoice.amount - invoice.paidAmount) {
              invoice.payment = (
                invoice.amount - invoice.paidAmount
              ).toString();
              credits = credits - (invoice.amount - invoice.paidAmount);
            }
      setOpenInvoices(invoices);
      setCutsomIsDirty(true);
      setGridRowChecked([]);
      allowSubmit.current = true;
    }
  };

  const onMethodChange = (e) => {
    setSelectedLedger(null);
    let deposit = LedgerTypeEnum.Asset;
    if (e === "Cash") deposit = LedgerTypeEnum.Cash;
    else if (e === "Credit Card" || e === "Check")
      deposit = LedgerTypeEnum.Bank;
    selectedType.current = deposit;
    refetch();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>Apply Payments to Invoices</div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                onClick={onCancel}
                disabled={!customIsDirty}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!allowSubmit.current}
              >
                Save Changes
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-8 col-sm-12">
                  <div className="col-sm-12">
                    <div className="row gx-5">
                      <div className="ps-0 col-lg-4 col-sm-12">
                        <div className="form-group">
                          <label>
                            Customer No. <span className="text-danger">*</span>
                          </label>
                          <Lookup
                            onButtonClicked={() => setOpenSelection(true)}
                            inputName="customerAccountId"
                            isRequired={true}
                            initialData={customers?.data?.map((item) => ({
                              ...item,
                              id: item.customerAccountId,
                              name: item.accountNumber,
                            }))}
                            onSelection={onCustomerSelected}
                            inputValue={selectedCustomer?.accountNumber}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Company</label>
                          <div>
                            {selectedCustomer ? (
                              selectedCustomer.company
                            ) : (
                              <span className="text-muted fst-italic">
                                Company
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 my-4">
                    <div className="form-group mt-2">
                      <label className="checkbox-inline">
                        <input
                          id="creditMemoE"
                          type="radio"
                          value={1}
                          onChange={onRadioChange}
                          checked={selectedMethod === 1}
                        />
                        <label
                          className={`${
                            !customIsDirty ? "text-secondary" : "text-black"
                          } ms-2`}
                          htmlFor="creditMemoE"
                        >
                          Credit Memo
                        </label>
                      </label>
                      <label className="checkbox-inline ms-3">
                        <input
                          id="paymentE"
                          type="radio"
                          value={2}
                          onChange={onRadioChange}
                          checked={selectedMethod === 2}
                        />
                        <label
                          className={`${
                            !customIsDirty ? "text-secondary" : "text-black"
                          } ms-2`}
                          htmlFor="paymentE"
                        >
                          Payment
                        </label>
                      </label>
                      <label className="checkbox-inline ms-3">
                        <input
                          id="unappliedE"
                          type="radio"
                          value={3}
                          onChange={onRadioChange}
                          checked={selectedMethod === 3}
                        />
                        <label
                          className={`${
                            !customIsDirty ? "text-secondary" : "text-black"
                          } ms-2`}
                          htmlFor="unappliedE"
                        >
                          Unapplied Payments
                        </label>
                      </label>
                    </div>
                  </div>
                  {selectedMethod === 1 && (
                    <div className="col-sm-12 my-4">
                      <div className="row gx-5">
                        <div className="ps-0 col-sm-12 col-lg-4">
                          <div className="form-group">
                            <label>
                              Credit Memo <span className="text-danger">*</span>
                            </label>
                            <Lookup
                              onButtonClicked={() => setOpenMemos(true)}
                              inputName="invoiceId"
                              isRequired={true}
                              initialData={creditMemos?.map((item) => ({
                                ...item,
                                id: item.invoiceId,
                                name: item.invoiceNumber,
                              }))}
                              onSelection={onInvoiceSelected}
                              inputValue={selectedInvoice?.invoiceNumber}
                              isDisabled={
                                getValues("customerAccountId") ? false : true
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Open Credit Amount</label>
                            <div>
                              {getOpenCredit() ? (
                                `$ ${getOpenCredit()}`
                              ) : (
                                <span className="text-muted fst-italic">
                                  Open Credit Amount
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Remaining Credit Amount</label>
                            <div>
                              {getRemainingCredit() === null ? (
                                <span className="text-muted fst-italic">
                                  Remaining Credit Amount
                                </span>
                              ) : (
                                `$ ${getRemainingCredit()}`
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedMethod === 2 && (
                    <div className="col-sm-12 my-4">
                      <div className="row gx-5">
                        <div className="ps-0 pe-3 col-sm-3">
                          <div className="form-group">
                            <label>
                              Payment Amount{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              {...register("paymentAmount", { min: 1 })}
                              className="form-control"
                              onBlur={(e) =>
                                setValue(
                                  "paymentAmount",
                                  formatInput(e.target.value)
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="px-0 pe-3 col-sm-3">
                          <div className="form-group">
                            <label>
                              Payment Method{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              control={control}
                              name="paymentMethodId"
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={options}
                                  isClearable
                                  isSearchable={true}
                                  value={
                                    options.find(
                                      (opt) => opt?.value === value
                                    ) || null
                                  }
                                  onChange={(selectedOption: any) => {
                                    onChange(selectedOption?.value);
                                    onMethodChange(selectedOption?.label);
                                  }}
                                  placeholder="Choose"
                                  menuPlacement="auto"
                                  className={`${
                                    errors["paymentMethodId"] &&
                                    "required_field"
                                  }`}
                                />
                              )}
                            />
                            {errors.paymentMethodId && (
                              <p className="text-danger">
                                This field is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="px-0 pe-3 col-sm-3">
                          <div className="form-group">
                            <label>Check No.</label>
                            <input
                              type="text"
                              {...register("checkNumber")}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="px-0 pe-3 col-sm-3">
                          <div className="form-group">
                            <label>Payment Date</label>
                            <Controller
                              control={control}
                              name="paymentDate"
                              render={({ field: { onChange, value } }) => (
                                <DatePicker
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                  onChange={onChange}
                                  selected={value ? value : null}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="ps-0 pe-3 col-sm-3">
                          <label>
                            Use Account <span className="text-danger">*</span>
                          </label>
                          <Lookup
                            onButtonClicked={() => setOpenSelectionLedger(true)}
                            inputName="paymentAccountId"
                            isRequired={true}
                            initialData={paymentAccounts?.data?.map((item) => ({
                              ...item,
                              id: item.ledgerId,
                              name: item.accountName,
                            }))}
                            onSelection={onLedgerSelection}
                            inputValue={selectedLedger?.accountName}
                            isDisabled={
                              !getValues("paymentMethodId") ||
                              getValues("paymentAccountId") === "-1" ||
                              getValues("paymentAccountId") === "0"
                            }
                            hasError={errors["paymentAccountId"] ? true : false}
                          />
                          {errors["paymentAccountId"] && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedMethod === 3 && (
                    <div className="col-sm-12 my-4">
                      <div className="row gx-5">
                        <div className="ps-0 col-sm-12 col-lg-4">
                          <div className="form-group">
                            <label>
                              Unapplied Receipt Payments{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Lookup
                              onButtonClicked={() => setOpenUnapplied(true)}
                              inputName="paymentReceiptId"
                              initialData={unappliedPayments?.map((item) => ({
                                ...item,
                                id: item.paymentReceiptId,
                                name: item.receiptNumber,
                              }))}
                              onSelection={onUnappliedSelected}
                              inputValue={selectedUnapplied?.receiptNumber}
                              isDisabled={
                                getValues("customerAccountId") ? false : true
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label>Unapplied Amount</label>
                            <div>
                              {selectedUnapplied ? (
                                `$ ${selectedUnapplied.unapplied}`
                              ) : (
                                <span className="text-muted fst-italic">
                                  Unapplied Amount
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="px-0 pe-3 col-sm-3">
                          <div className="form-group">
                            <label>Payment Date</label>
                            <Controller
                              control={control}
                              name="paymentDate"
                              render={({ field: { onChange, value } }) => (
                                <DatePicker
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                  onChange={onChange}
                                  selected={value ? value : null}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-12">
                    <div className="row gx-4 m-0">
                      <div className="ps-0 col-lg-4 col-sm-12">
                        <div
                          className={`border p-3 ${
                            !customIsDirty && "bg-silver-light text-secondary"
                          }`}
                        >
                          <div>Balance</div>
                          <div>
                            {customerDetails &&
                            customerDetails["ledger"]?.balance ? (
                              <span
                                className={`${
                                  customerDetails["ledger"]?.balance < 0
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {formatNumber(
                                  customerDetails["ledger"]?.balance
                                )}
                              </span>
                            ) : (
                              <span
                                className={`${customIsDirty && "text-success"}`}
                              >
                                $0.00
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div
                          className={`border p-3 ${
                            !customIsDirty && "bg-silver-light text-secondary"
                          }`}
                        >
                          <div>Open Credit</div>
                          <div>
                            {customerDetails &&
                            customerDetails["openCredits"] ? (
                              <span
                                className={`${
                                  customerDetails["openCredits"] < 0
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {formatNumber(customerDetails["openCredits"])}
                              </span>
                            ) : (
                              <span
                                className={`${customIsDirty && "text-success"}`}
                              >
                                $0.00
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12">
                        <div
                          className={`border p-3 ${
                            !customIsDirty && "bg-silver-light text-secondary"
                          }`}
                        >
                          <div>Credit Limit</div>
                          <div>
                            {customerDetails &&
                            customerDetails["creditLimit"] ? (
                              <span
                                className={`${
                                  customerDetails["creditLimit"] < 0
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {formatNumber(customerDetails["creditLimit"])}
                              </span>
                            ) : (
                              <span
                                className={`${customIsDirty && "text-success"}`}
                              >
                                $0.00
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="ps-0 col-lg-4 col-sm-12 mt-4">
                        <div
                          className={`border p-3 ${
                            !customIsDirty && "bg-silver-light text-secondary"
                          }`}
                        >
                          <div>on SO</div>
                          <div>
                            {customerDetails && customerDetails["onSO"] ? (
                              <span
                                className={`${
                                  customerDetails["onSO"] < 0
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {formatNumber(customerDetails["onSO"])}
                              </span>
                            ) : (
                              <span
                                className={`${customIsDirty && "text-success"}`}
                              >
                                $0.00
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-12 mt-4">
                        <div className="col">
                          <div
                            className={`border p-3 ${
                              !customIsDirty && "bg-silver-light text-secondary"
                            }`}
                          >
                            <div>Available</div>
                            <div>
                              {customerDetails && customerDetails["balance"] ? (
                                <span
                                  className={`${
                                    customerDetails["balance"] < 0
                                      ? "text-danger"
                                      : "text-success"
                                  }`}
                                >
                                  {formatNumber(customerDetails["balance"])}
                                </span>
                              ) : (
                                <span
                                  className={`${
                                    customIsDirty && "text-success"
                                  }`}
                                >
                                  $0.00
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="section py-4">
                    <label>Remarks</label>
                    <textarea
                      className="form-control min-h-200"
                      placeholder="Write your remarks"
                      {...register("remarks")}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-sm-12">
                  <div className="section p-3">
                    <div className="w-100 mb-4 mt-3">
                      <span className="alert alert-danger ms-3">
                        <AlertIcon className="me-3" />
                        Applying payment affects the payment column in the table
                        below
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={
                        getValues("customerAccountId")
                          ? gridRowChecked.length > 0
                            ? false
                            : true
                          : true
                      }
                      onClick={() => setShowManualConfirmation(true)}
                    >
                      Manual Apply
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={
                        gridRowChecked.length > 0
                          ? true
                          : getValues("customerAccountId")
                          ? false
                          : true
                      }
                      onClick={() => setShowAutoConfirmation(true)}
                    >
                      Auto Apply
                    </button>
                  </div>
                </div>
                <div className="min-h-350">
                  <AG
                    data={openInvoices}
                    columns={gridColumns}
                    autoColumns={true}
                    onCellValueChanged={onPaymentValueChanged}
                    setRowChecked={setGridRowChecked}
                    rowSelection="multiple"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={customers?.data}
        columns={accountNumberColumns}
        setRowClicked={onCustomerSelected}
      />
      <SelectionModal
        modal={"Credit Memo"}
        showModal={openMemos}
        setShowModal={setOpenMemos}
        data={creditMemos}
        columns={creditColumns}
        setRowClicked={onInvoiceSelected}
      />
      <SelectionModal
        modal={"Unapplied Payment Receipts"}
        showModal={openUnapplied}
        setShowModal={setOpenUnapplied}
        data={unappliedPayments}
        columns={unappliedColumns}
        setRowClicked={onUnappliedSelected}
      />
      <SelectionModal
        modal={"Ledger"}
        showModal={openSelectionLedger}
        setShowModal={setOpenSelectionLedger}
        data={paymentAccounts?.data}
        columns={ledgersColumns}
        setRowClicked={onLedgerSelection}
      />
      <ConfirmationModal
        title="Apply Credit Memo"
        message="Are you sure you want to manually apply receipt toward selected invoice?"
        onClose={() => setShowManualConfirmation(false)}
        onConfirm={() => {
          setApplyType(1);
          applyManualPaymentToGrid();
          setShowManualConfirmation(false);
        }}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showManualConfirmation}
      />
      <ConfirmationModal
        title="Apply Credit Memo"
        message="This process will automatically apply payment amounts toward open invoices from oldest to newest. Do you want to continue with auto apply?"
        onClose={() => setShowAutoConfirmation(false)}
        onConfirm={() => {
          setApplyType(2);
          setShowAutoConfirmation(false);
          setCutsomIsDirty(true);
        }}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showAutoConfirmation}
      />
      <ConfirmationModal
        title="Customer Payment"
        message="Account has open credits. Make sure you apply open credits before making payments."
        onClose={() => setShowCreditsConfirmation(false)}
        onConfirm={() => setShowCreditsConfirmation(false)}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="OK"
        type="confirmation-primary"
        showModal={showCreditsConfirmation}
      />
      <ConfirmationModal
        title="Payment Confirmation"
        message="You are paying more than the balance due, Overpayment will stay as unapplied. Do you wish to continue?"
        onClose={() => setShowPaymentConfirmation(false)}
        onConfirm={() => onPaymentConfirmation()}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-primary"
        showModal={showPaymentConfirmation}
      />
    </>
  );
};

export default ApplyCreditMemo;
