import { useState, useEffect, useContext } from "react";
import DispatchContext from "context/DispatchContext";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";

import {
  getMarkets,
  createMarket,
  editMarket,
} from "./SystemSetupServices/SystemSetupServices";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import StateContext from "context/StateContext";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Market } from "./SystemSetup.models";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";

const NewMarketSetup = ({ match }) => {
  const history = useHistory();

  const [selectedId, setSelectedId] = useState<number>(null);
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [marketSetupDetails, setMarketSetupDetails] = useState<Market>(null);

  const dataMutation = useMutation("data", getMarkets);
  const { id } = useParams();
  useEffect(() => {
    if (id !== "new") {
      dataMutation.mutate(id, {
        onSuccess(result) {
          let idNum = Number(id);
          let data = result.data.find((item) => item.marketId === idNum);
          setMarketSetupDetails(data);
          setSelectedId(idNum);
          setValue("name", data?.name);
          setValue("isActive", data?.isActive, {});
        },
      });
    }
  }, [id]);

  const methods = useForm<Market>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { isActive: marketSetupDetails?.isActive || true },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isDirty },
  } = methods;

  const addMarket = useMutation(createMarket, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Market ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.market_setup);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding Market"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateMarket = useMutation(editMarket, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.market_setup);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing market"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: updateMarket?.isLoading || addMarket?.isLoading,
    });
  }, [updateMarket?.isLoading, addMarket?.isLoading, appDispatch]);

  const onSubmit = (data: Market) => {
    if (!selectedId) {
      addMarket.mutate(data);
    } else {
      updateMarket.mutate({ ...data, marketId: selectedId });
    }
  };

  const resetForm = () => {
    reset({ name: "", isActive: true });
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (dataMutation.isSuccess) {
        setIsLoadingGridExport(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, dataMutation.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <div className="static-page">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="  page-title page-title-editable">
            <div className="d-flex flex-row">
              <div className="back-btn">
                <img
                  src={backArrow}
                  alt="back arrow"
                  onClick={() => history.push(AppUrls.market_setup)}
                />
              </div>
              <div className="">
                <p className="">Market Setup</p>
              </div>
            </div>
            <div className="d-flex" style={{ marginLeft: "-15px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={resetForm}
              >
                Reset
              </button>
              <button
                type="submit"
                disabled={!isDirty}
                className="btn btn-primary"
              >
                {!selectedId ? "Add Market" : "Save Changes" || "Submit"}
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div>
              {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
                <div className="">
                  <div className="row ">
                    <div className="col-4">
                      <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <>
                            <label>
                              Market Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              {...field}
                              name="name"
                              className={`form-control ${
                                errors["name"] && "required_field"
                              }`}
                              type="text"
                              required
                            />
                          </>
                        )}
                      />
                      {errors["name"] && (
                        <p className="text-danger">This field is required</p>
                      )}
                    </div>
                    <div className="col-1">
                      <label>Active</label>
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="isActive"
                          render={({ field: { onChange, value } }) => (
                            <>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="activeCheckbox"
                                  name="isActive"
                                  onChange={() => onChange(!value)}
                                  checked={value}
                                />
                                <span className="slider round"></span>
                              </label>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewMarketSetup;
