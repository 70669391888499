import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  notificationMessage,
  checkIfAllowed,
  fixTimezoneOffset,
} from "global/helpers";
import {
  createOp,
  deleteOp,
  editOp,
  getCostingMethod,
  getFiscalYear,
  getLedgers,
  getSystemOp,
  updateCostingMethod,
  updateFiscalYear,
} from "./AccountingServices";
import Select from "react-select";
import StateContext from "context/StateContext";
import SectionTitle from "elements/SectionTitle";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Delete from "img/delete.svg";
import { DropDownOption } from "components/Common.models";
import DispatchContext from "context/DispatchContext";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DatePicker from "react-datepicker";
import AgGrid from "elements/AgGrid";

enum BasicOperationEnum {
  "Customer Shipping" = 1,
  "Shipping Expenses" = 2,
  "Discount" = 3,
  "Tax Payable" = 4,
  "Stock Received Not Invoiced" = 5,
  "Fraud" = 6,
  "Reconciliation Difference Account" = 7,
  "Bank Expense" = 8,
  "Cash In Transit" = 9,
  "Income From Investment" = 10,
  "Undeposited Funds" = 11,
  "DebitPayrollExpense" = 12,
  "TaxWithHolding" = 13,
  "WriteOffAccount" = 14,
  "Reconciliation Difference ExpenseAccount" = 15,
}

const operationOpts = Object.entries(BasicOperationEnum)
  .filter((e) => !isNaN(e[0] as any))
  .map((e) => ({ label: e[1], value: Number(e[0]) }));

const OpConfiguration = ({ match }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const queryClient = useQueryClient();

  const [selectedId, setSelectedId] = useState<number>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const [fiscalYear, setFiscalYear] = useState();

  const options = [
    { value: 1, label: "FIFO" },
    { value: 2, label: "LIFO" },
    { value: 3, label: "AVERAGE" },
  ];

  const { data: operations } = useQuery("systemop", getSystemOp);

  const { data: ledgers } = useQuery("ledgers", getLedgers);

  useQuery("costingMethod", getCostingMethod, {
    onSuccess(data) {
      setSelectedMethod(
        options.find((option) => option.value === data.costingCategory)
      );
    },
  });

  useQuery("fiscalYear", getFiscalYear, {
    onSuccess(data) {
      setFiscalYear(data.fiscalYear);
    },
  });

  const frmMethods = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = frmMethods;

  const columns = [
    {
      field: "basicOperation",
      headerName: "Operation",
      cellRenderer: (params) => BasicOperationEnum[params.value],
    },
    {
      field: "ledger",
      headerName: "Ledger",
      cellRenderer: (params) => params.value?.accountName,
    },
    {
      field: "action",
      headerName: "Action",
      floatingFilter: false,
      filter: false,
      sortable: false,
      hide: !checkIfAllowed(appState.allowedUrls, "delete", match.path),
      onCellClicked: (params) => {
        onDeleteItemClicked(params.data);
      },
      cellRenderer: () => {
        return `
            <div class="btn text-danger">
              <img src=${Delete} alt="Delete Icon" />
              Delete 
            </div>
      `;
      },
    },
  ];

  const updateMethod = useMutation(updateCostingMethod, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Method ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing method"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateFY = useMutation(updateFiscalYear, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Fiscal Year ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem editing Fescal Year"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const addOp = useMutation(createOp, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Configuration ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("systemop");
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding configuration"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateOp = useMutation(editOp, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Configuration ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("systemop");
      setSelectedId(null);
      reset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing configuration"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeOp = useMutation(deleteOp, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Configuration ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("systemop");
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem removing configuration"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onRowDoubleClicked = (e) => {
    setSelectedId(e.systemOperationId);
    setValue("basicOperation", e.basicOperation);
    setValue("affectedLedgerId", e.ledger?.value);
  };

  const handleDeleteItem = () => {
    removeOp.mutate(selectedId);
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const onDeleteItemClicked = (data) => {
    setSelectedId(data.systemOperationId);
    setShowDeleteModal(true);
  };

  const resetForm = () => {
    setSelectedId(null);
    reset();
  };

  const onSubmit = (values) => {
    if (!selectedId) {
      addOp.mutate({ ...values });
    } else {
      updateOp.mutate({ ...values, id: selectedId });
    }
  };

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExpirt, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExpirt });
  }, [appDispatch, isLoadingGridExpirt]);

  const onMethodChange = (e) => {
    setSelectedMethod(e);
  };

  const onMethodSubmit = () => {
    updateMethod.mutate({ costingCategory: selectedMethod.value });
  };

  const onFiscalYearMethodChange = () => {
    updateFY.mutate({
      accountingConfigurationId: 0,
      fiscalYear: fixTimezoneOffset(fiscalYear),
    });
  };

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <p>Operations Configuration</p>
          <div>
            {/* <button className="import-btn" type="button" onClick={exportToExcel} disabled={disabledExportBtn}>
                        <img src={exportIcon} className="me-2" alt="" /> 
                        Export to Excel
                    </button> */}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row h-100">
              <div className="left-side-mid grid-container">
                <div className="grid">
                  <AgGrid
                    columns={columns}
                    data={operations}
                    setrowClicked={onRowDoubleClicked}
                    exportToExcel={exportToExcelClicked}
                    setExportToExcelClicked={setExportToExcelClicked}
                    setDisabledExportBtn={setDisabledExportBtn}
                    setIsLoadingGridExport={setIsLoadingGridExport}
                  />
                </div>
              </div>
              {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                <div className="right-side-mid">
                  <SectionTitle
                    active={!!selectedId}
                    editable={false}
                    title="Configuration"
                  />
                  <div className="">
                    <FormProvider {...frmMethods}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label>
                                Operation <span className="text-danger">*</span>
                              </label>
                              <Controller
                                control={control}
                                name="basicOperation"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    options={operationOpts}
                                    isSearchable={true}
                                    value={
                                      operationOpts?.find(
                                        (opt) => opt?.value === value
                                      ) || null
                                    }
                                    onChange={(val: DropDownOption) =>
                                      onChange(val?.value)
                                    }
                                    isClearable={true}
                                    className={`${
                                      errors["basicOperation"] &&
                                      "required_field"
                                    }`}
                                  />
                                )}
                              />
                            </div>
                            {errors["basicOperation"] && (
                              <p className="text-danger">
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label>
                                Ledger <span className="text-danger">*</span>
                              </label>
                              <Controller
                                control={control}
                                name="affectedLedgerId"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    options={ledgers?.data.map((ledger) => ({
                                      ...ledger,
                                      label: ledger.accountName,
                                    }))}
                                    isSearchable={true}
                                    value={
                                      ledgers?.data
                                        .map((ledger) => ({
                                          ...ledger,
                                          label: ledger.accountName,
                                        }))
                                        ?.find((opt) => opt?.value === value) ||
                                      null
                                    }
                                    onChange={(val: DropDownOption) =>
                                      onChange(val?.value)
                                    }
                                    isClearable={true}
                                    className={`${
                                      errors["affectedLedgerId"] &&
                                      "required_field"
                                    }`}
                                  />
                                )}
                              />
                              {errors["affectedLedgerId"] && (
                                <p className="text-danger">
                                  This field is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            type="button"
                            className="btn btn-outline-primary custom-outline custom-outline"
                            onClick={resetForm}
                          >
                            Reset{" "}
                          </button>
                          <button type="submit" className="btn btn-primary ">
                            {selectedId ? "Save Changes" : "Add Configuration"}
                          </button>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                  <div className="mt-5">
                    <SectionTitle editable={false} title="Fiscal Year" />
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Start of Fiscal Year{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            onChange={(date) => setFiscalYear(date)}
                            selected={fiscalYear}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            className={`${
                              (fiscalYear === "" || fiscalYear === null) &&
                              "required_field"
                            }`}
                          />
                          {(fiscalYear === "" || fiscalYear === null) && (
                            <p className="text-danger">
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={onFiscalYearMethodChange}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 mb-5">
                    <SectionTitle editable={false} title="Costing Method" />
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>
                            Method <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={options}
                            isSearchable={true}
                            value={selectedMethod}
                            onChange={onMethodChange}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={onMethodSubmit}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Configuration"
        message="Are you sure you want to delete the item?"
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteItem}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
    </>
  );
};

export default OpConfiguration;
