import { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { ReactComponent as AlertIcon } from "img/alert.svg";
import "../SalesOrders.scss";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { useFormContext, Controller } from "react-hook-form";
import DispatchContext from "context/DispatchContext";
import {
  getCustomers,
  getCustomerById,
} from "pages/SalesMarketing/Customers/Customers.services";
import { getRepresentatives } from "pages/SalesMarketing/Representative/Representative.services";
import {
  accountNumberColumns,
  hubsColumns,
  repsColumns,
  shipColumns,
} from "components/ItemOrderModal/modalColumns";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import {
  getExchangeRate,
  getLatestRate,
  getShippingList,
} from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";
import {
  getSalesOrder,
  searchSalesOrders,
} from "../SalesOrderServices/SalesOrderServices";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import SalesOrdersModal from "../SalesOrderModal";
import Lookup from "elements/Lookup";
import {
  formatNumber,
  getCurrenciesListFromExchangeRateList,
  getCurrencyByCode,
} from "global/helpers";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { getBaseCurrency } from "components/currency";

const SoInfo = ({
  shippingDetails,
  setShippingDetails,
  isDisabled,
  isEdit,
  setIsDisabled,
  setAddedItems,
  setOrderId,
  orderDate,
  setOrderDate,
  setHubId,
  addedItems,
  customerDetails,
  setCustomerDetails,
  pickupSo,
  setPickupSo,
  costDetails,
  methodsCounter,
  setShowShippingModal,
  allowVoid,
  setAllowVoid,
}) => {
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [selectedModal, setSelectedModal] = useState<any>({
    modal: "",
    data: [],
    columns: [],
    type: 0,
  });
  const [selectedModalType, setSelectedModalType] = useState<string>();
  const [modalRowClicked, setModalRowClicked] = useState<any>();
  const [shippingOptions, setShippingOptions] = useState<Array<any>>([]);
  const [changedHub, setChangedHub] = useState();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [openOrdersModal, setOpenOrdersModal] = useState<boolean>(false);

  const [modalTypes, setModalTypes] = useState({
    accountNumber: {
      modal: "Account",
      data: [],
      columns: accountNumberColumns,
      type: 1,
    },
    repId: { modal: "Representative", data: [], columns: repsColumns, type: 2 },
    hubId: { modal: "Hub", data: [], columns: hubsColumns, type: 3 },
    shipTo: { modal: "Address", data: [], columns: shipColumns, type: 4 },
    orderNumber: { type: 5 },
  });

  const location = useLocation();
  const appDispatch = useContext(DispatchContext);
  const [, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const {
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useFormContext();

  const getSalesOrders = useMutation(searchSalesOrders);

  const { data: currencies } = useQuery("currencies", getExchangeRate, {
    select(data) {
      const currencyCodes = data?.map((currency) => ({
        currencyCode: currency?.currencyCode,
      }));
      var currencies = getCurrenciesListFromExchangeRateList(currencyCodes);
      return currencies;
    },
  });

  const {
    isLoading: isLoadingCustomers,
    isSuccess: isSuccessCustomers,
    data: customers,
  } = useQuery("customersList", getCustomers, {
    enabled: !isEdit,
  });

  const {
    isLoading: isLoadingHubs,
    isSuccess: isSuccessHubs,
    data: hubsList,
  } = useQuery("hubs", getInventoryHubsList);

  useEffect(() => {
    if (hubsList?.data?.length === 1) {
      setValue("fromHub", hubsList?.data[0]?.hubId);
    }
  }, [hubsList]);

  const {
    isLoading: isLoadingRepresentatives,
    isSuccess: isSuccessRepresentatives,
    data: representatives,
  } = useQuery("representatives", getRepresentatives);

  const {
    isLoading: isLoadingShipping,
    isSuccess: isSuccessShipping,
    data: shippingMethods,
    refetch,
  } = useQuery("shippingList", getShippingList);

  useEffect(() => {
    if (methodsCounter > 0) refetch();
  }, [methodsCounter]);

  const getDetails = useMutation(getCustomerById, {
    async onSuccess(response) {
      setCustomerDetails(response);
      if (isEdit) {
        let info = response.shippingInfos.find(
          (info) => info.shippingInfoId === getValues("shippingInfoId")
        );
        setShippingDetails(info);
        setValue("shipTo", info?.company);
      } else {
        let shippingInfo = response.shippingInfos.find(
          (info) => info.isDefault === true
        );
        setShippingDetails(shippingInfo);
        setValue("shipTo", shippingInfo["company"]);
        setValue("shippingInfoId", shippingInfo["shippingInfoId"]);
        setValue("warrantyLevel", response.warrantyLevel);
        setValue("fromHub", response.defaultHub.hubKey);
        setValue("hubId", response.defaultHubId);
        setHubId(response.defaultHubId);
        setValue("representative", response.rep?.repName);
        setValue("repId", response.repId);
        setValue(
          "requestDate",
          response.requestDate ? new Date(response.requestDate) : null
        );
      }
      setModalTypes((prevState) => ({
        ...prevState,
        shipTo: { ...prevState.shipTo, data: response.shippingInfos },
      }));
    },
  });

  const getOrderDetails = useMutation(getSalesOrder, {
    async onSuccess(response) {
      const { discount, shipCost } = response.data;
      costDetails.current = { discount, shipCost };
      setValue("orderNumber", response.data.orderNumber, { shouldDirty: true });
      setValue("accountNumber", response.data.accountNumber);
      setValue("onHold", response.data.onHold);
      setPickupSo(response.data.toPick);
      if (response.data.onHold) {
        let notification = {
          variant: "danger",
          msg: "Sales order is on hold",
        };
        appDispatch({ type: "notification", value: notification });
      }
      setValue("projectNumber", response.data.projectNumber);
      setValue("customerPO", response.data.customerPO);
      setValue("warrantyLevel", response.data.warrantyLevel);
      if (response.data.fmrDate)
        setValue("fmrDate", new Date(response.data.fmrDate));
      if (response.data.requestDate)
        setValue("date", new Date(response.data.requestDate));
      setValue("repId", response.data.repId);
      setValue("shippingInfoId", response.data.shippingInfoId);
      setValue("remarks", response.data.remarks);
      setValue("hubId", response.data.hubId);
      setHubId(response.data.hubId);
      setValue("requestDate", new Date(response.data.requestDate));
      setValue("currencyCode", response.data.currency?.currencyCode);
      setValue("currencyExchangeRate", response.data.rate);
      if (hubsList)
        setValue(
          "fromHub",
          hubsList?.data?.find((hub) => hub.locationId === response.data.hubId)
            .hubId
        );
      if (representatives)
        setValue(
          "representative",
          representatives.data.find((rep) => rep.repId === response.data.repId)
            ?.repName
        );
      if (shippingOptions) {
        setValue(
          "shippingMethodId",
          shippingOptions.find(
            (method) => method["value"] === response.data.shippingMethodId
          )
        );
      }
      getDetails.mutate(response.data.targetId);
      setOrderId(response.data.salesOrderId);
      setOrderDate(response.data.orderDate);
      if (response?.data.status === 4) setIsDisabled(true);
      else setIsDisabled(false);
      let index =
        addedItems.length > 0
          ? Math.max(...addedItems.map((o) => o.index)) + 1
          : 0;
      setAddedItems(
        response.data.itemOrders.map((item) => ({
          ...item,
          index: index,
          itemHubId: item.itemHubId,
          inventoyItemHub: item.itemHubId,
          orderQty: item.quantity,
          orderPrice: item.price,
          orderWarranty: item.warrantyDays,
          itemDescription: item.description,
        }))
      );
    },
  });

  const getRate = useMutation(getLatestRate, {
    async onSuccess(data) {
      if (data) setValue("currencyExchangeRate", data);
      else setValue("currencyExchangeRate", null);
    },
  });

  useEffect(() => {
    if (location.state?.soId) getOrderDetails.mutate(location.state.soId);
  }, [location]);

  useEffect(() => {
    let isLoading =
      isLoadingCustomers ||
      isLoadingHubs ||
      isLoadingRepresentatives ||
      isLoadingShipping ||
      getDetails.isLoading ||
      getOrderDetails.isLoading;
    appDispatch({ type: "loading", value: isLoading });
  }, [
    isLoadingCustomers,
    isLoadingHubs,
    isLoadingRepresentatives,
    isLoadingShipping,
    getDetails.isLoading,
    getOrderDetails.isLoading,
  ]);

  useEffect(() => {
    if (isEdit) getSalesOrders.mutate("?states=1");
  }, [isEdit]);

  useEffect(() => {
    if (modalRowClicked) {
      if (selectedModal["type"] === 1) {
        getDetails.mutate(modalRowClicked["customerAccountId"]);
        setValue("accountNumber", modalRowClicked["accountNumber"], {
          shouldDirty: true,
        });
        setValue("targetId", modalRowClicked["customerAccountId"]);
      } else if (selectedModal["type"] === 2) {
        setValue("representative", modalRowClicked["repName"], {
          shouldDirty: true,
        });
        setValue("repId", modalRowClicked["repId"]);
      } else if (selectedModal["type"] === 3) {
        if (modalRowClicked["locationId"] !== getValues("hubId"))
          if (addedItems.length === 0) {
            setValue("fromHub", modalRowClicked["hubId"], {
              shouldDirty: true,
            });
            setValue("hubId", modalRowClicked["locationId"]);
            setHubId(modalRowClicked["locationId"]);
          } else {
            setChangedHub(modalRowClicked);
          }
      } else if (selectedModal["type"] === 4) {
        setValue("shipTo", modalRowClicked["company"], { shouldDirty: true });
        setValue("shippingInfoId", modalRowClicked["shippingInfoId"]);
        setShippingDetails(modalRowClicked);
      } else if (selectedModal["type"] === 5) {
        if (
          modalRowClicked.status === 1 ||
          modalRowClicked.status === 2 ||
          modalRowClicked.status === 4
        ) {
          allowVoid(modalRowClicked.status !== 4);
          getOrderDetails.mutate(modalRowClicked["salesOrderId"]);
          setOpenOrdersModal(false);
          appDispatch({ type: "notification", value: null });
        } else {
          let notification = {
            variant: "danger",
            msg: "Only Open orders can be selected",
          };
          appDispatch({ type: "notification", value: notification });
        }
      }
      setModalRowClicked(null);
      setOpenSelection(false);
    }
  }, [modalRowClicked]);

  useEffect(() => {
    if (isSuccessCustomers) {
      let activeCustomers = customers.data.filter(
        (customer) => customer.isActive === true
      );
      setModalTypes((prevState) => ({
        ...prevState,
        accountNumber: { ...prevState.accountNumber, data: activeCustomers },
      }));
    }
  }, [isSuccessCustomers, customers]);

  useEffect(() => {
    if (isSuccessHubs) {
      let activeHubs = hubsList?.data?.filter((hub) => hub.isActive === true);
      setModalTypes((prevState) => ({
        ...prevState,
        hubId: { ...prevState.hubId, data: activeHubs },
      }));
    }
  }, [isSuccessHubs, hubsList]);

  useEffect(() => {
    if (isSuccessShipping) {
      let activeMethods = shippingMethods.data.filter(
        (method) => method.isActive === true
      );
      setShippingOptions(
        activeMethods.map((method) => ({
          value: method.shippingMethodId,
          label: method.name,
        }))
      );
    }
  }, [isSuccessShipping, shippingMethods]);

  useEffect(() => {
    if (shippingOptions && getOrderDetails.data) {
      setValue(
        "shippingMethodId",
        shippingOptions.find(
          (method) =>
            method["value"] === getOrderDetails.data.data.shippingMethodId
        )
      );
    }
  }, [shippingOptions, getOrderDetails.data]);

  useEffect(() => {
    if (isSuccessRepresentatives) {
      let activeReps = representatives.data?.filter(
        (hub) => hub.isActive === true
      );
      setModalTypes((prevState) => ({
        ...prevState,
        repId: { ...prevState.repId, data: activeReps },
      }));
    }
  }, [isSuccessRepresentatives, representatives]);

  useEffect(() => {
    if (changedHub) setShowConfirmation(true);
  }, [changedHub]);

  const openModal = (type) => {
    setSelectedModal(modalTypes[type]);
    setSelectedModalType(type);
    setOpenSelection(true);
  };

  useEffect(() => {
    if (showConfirmation === false) setChangedHub(null);
  }, [showConfirmation]);

  const changeHubs = () => {
    setShowConfirmation(false);
    if (changedHub) {
      setValue("fromHub", changedHub["hubId"], { shouldDirty: true });
      setValue("hubId", changedHub["locationId"]);
      setHubId(changedHub["locationId"]);
      setAddedItems([]);
    }
  };

  const getAvailableAmount = () => {
    if (customerDetails)
      return customerDetails?.onSO + customerDetails?.ledger?.balance;
    return 0;
  };
  const [previousShippingDetails, setPreviousShippingDetails] = useState(null);

  const onPickupChange = () => {
    setPickupSo((prev) => !prev);
    setValue("shipTo", null);
    setValue("shippingInfoId", null);
    if (!pickupSo) {
      setPreviousShippingDetails(shippingDetails);
      setShippingDetails(null);
    } else {
      setShippingDetails(previousShippingDetails);
    }
  };

  const createLabel = () => <label>Add New</label>;

  const onCreateType = () => {
    setShowShippingModal(true);
  };

  const onCurrencyChange = (e) => {
    if (e) getRate.mutate(e);
    else setValue("currencyExchangeRate", null);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <div className="row gx-5">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>
                  Order No. {isEdit && <span className="text-danger">*</span>}
                </label>
                {isEdit ? (
                  <Lookup
                    onButtonClicked={() => {
                      setSelectedModal(modalTypes["orderNumber"]);
                      setOpenOrdersModal(true);
                    }}
                    inputName="orderNumber"
                    isRequired={true}
                    initialData={getSalesOrders.data?.data?.data?.map(
                      (item) => ({
                        ...item,
                        id: item.salesOrderId,
                        name: item.orderNumber,
                      })
                    )}
                    onSelection={(e) => {
                      setSelectedModal(modalTypes["orderNumber"]);
                      setModalRowClicked(e);
                    }}
                    inputValue={getValues("orderNumber")}
                  />
                ) : (
                  <p className="fst-italic text-muted">Order No.</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Order Date</label>
                <div>
                  {isEdit
                    ? orderDate
                      ? `${new Date(
                          orderDate
                        ).toLocaleDateString()} - ${new Date(
                          orderDate
                        ).toLocaleTimeString()}`
                      : "//"
                    : new Date().toLocaleDateString()}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>
                  Customer No.{" "}
                  {!isEdit && <span className="text-danger">*</span>}
                </label>
                {isEdit ? (
                  <div>
                    {getValues("accountNumber") || (
                      <span className="fst-italic text-muted">Customer No</span>
                    )}
                  </div>
                ) : (
                  <Lookup
                    onButtonClicked={() => {
                      openModal("accountNumber");
                    }}
                    inputName="targetId"
                    isRequired={true}
                    initialData={customers?.data.map((item) => ({
                      ...item,
                      id: item.customerAccountId,
                      name: item.accountNumber,
                    }))}
                    onSelection={(e) => {
                      setSelectedModal(modalTypes["accountNumber"]);
                      setSelectedModalType("accountNumber");
                      setModalRowClicked(e);
                    }}
                    inputValue={getValues("accountNumber")}
                    hasError={errors["accountNumber"] ? true : false}
                  />
                )}
                {errors["accountNumber"] && !getValues("accountNumber") && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Rep ID.</label>
                <Lookup
                  onButtonClicked={() => {
                    openModal("repId");
                  }}
                  inputName="repId"
                  initialData={representatives?.data?.map((item) => ({
                    ...item,
                    id: item.repId,
                    name: item.repName,
                  }))}
                  onSelection={(e) => {
                    setSelectedModal(modalTypes["repId"]);
                    setSelectedModalType("repId");
                    setModalRowClicked(e);
                  }}
                  isDisabled={isEdit && isDisabled}
                  inputValue={getValues("representative")}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Req. Date</label> <br />
                <Controller
                  control={control}
                  name="requestDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      onChange={onChange}
                      selected={value ? value : null}
                      disabled={isEdit && isDisabled}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <div className="form-check mt-4 pt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="soHold"
                    {...register("onHold")}
                    disabled={isEdit && isDisabled}
                  />
                  <label
                    className="form-check-label text-black"
                    htmlFor="soHold"
                  >
                    SO on hold?
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="row gx-5">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>
                  From Hub {!isEdit && <span className="text-danger">*</span>}
                </label>
                {isEdit ? (
                  <div>
                    {getValues("fromHub") || (
                      <span className="fst-italic text-muted">From Hub</span>
                    )}
                  </div>
                ) : (
                  <Lookup
                    onButtonClicked={() => {
                      openModal("hubId");
                    }}
                    inputName="hubId"
                    isRequired={true}
                    initialData={hubsList?.data?.map((item) => ({
                      ...item,
                      id: item.locationId,
                      name: item.hubId,
                    }))}
                    onSelection={(e) => {
                      setSelectedModal(modalTypes["hubId"]);
                      setSelectedModalType("hubId");
                      setModalRowClicked(e);
                    }}
                    isDisabled={isEdit && isDisabled}
                    inputValue={getValues("fromHub")}
                    hasError={
                      errors["fromHub"] && !getValues("fromHub") ? true : false
                    }
                  />
                )}
                {errors["fromHub"] && !getValues("fromHub") && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Ship Method</label>
                <Controller
                  control={control}
                  name="shippingMethodId"
                  render={({ field: { onChange, value } }) => (
                    <CreatableSelect
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={onChange}
                      value={value || null}
                      options={shippingOptions}
                      isValidNewOption={() => true}
                      formatCreateLabel={createLabel}
                      createOptionPosition="first"
                      onCreateOption={onCreateType}
                      isDisabled={isEdit && isDisabled}
                      className={`${
                        errors["shippingMethodId"] && "border-danger border"
                      }`}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Project No.</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("projectNumber")}
                  disabled={isEdit && isDisabled}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Cust. PO</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("customerPO")}
                  disabled={isEdit && isDisabled}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Warranty Level</label>
                <input
                  type="number"
                  className="form-control w-50"
                  {...register("warrantyLevel")}
                  disabled={isEdit && isDisabled}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>FMR Ends</label>
                <Controller
                  control={control}
                  name="fmrDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      onChange={onChange}
                      selected={value ? value : null}
                      disabled={isEdit && isDisabled}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Currency</label>
                <Controller
                  control={control}
                  name="currencyCode"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption?.value);
                        onCurrencyChange(selectedOption?.value);
                      }}
                      value={
                        currencies?.find((opt) => opt?.value === value) || null
                      }
                      isClearable={true}
                      options={currencies}
                      isDisabled={isEdit && isDisabled}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Exchange Rate</label>
                <input
                  className="form-control w-50"
                  {...register("currencyExchangeRate")}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="section p-3 mt-4">
            <div className="row gx-4 m-0">
              <div className="col-lg-4 col-sm-12">
                <div className="border rounded p-3 bg-white">
                  <div>Balance</div>
                  <div
                    className={` ${
                      customerDetails?.ledger?.balance < 0
                        ? "text-danger"
                        : "text-success"
                    }`}
                  >
                    {formatNumber(
                      customerDetails?.ledger?.balance ?? 0.0,

                      customerDetails && customerDetails.primaryCurrencyCode
                        ? customerDetails.primaryCurrencyCode
                        : baseCurrency
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="border rounded p-3 bg-white">
                  <div>Open Credit</div>
                  <div className="text-success">
                    {formatNumber(
                      customerDetails?.openCredits ?? 0.0,

                      customerDetails && customerDetails.primaryCurrencyCode
                        ? customerDetails.primaryCurrencyCode
                        : baseCurrency
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="border rounded p-3 bg-white">
                  <div>Credit Limit</div>
                  <div className="text-success">
                    {formatNumber(
                      customerDetails?.creditLimit ?? 0.0,

                      customerDetails && customerDetails.primaryCurrencyCode
                        ? customerDetails.primaryCurrencyCode
                        : baseCurrency
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mt-4">
                <div className="border rounded p-3 bg-white">
                  <div>On Order</div>
                  <div className="text-success">
                    {formatNumber(
                      customerDetails?.onSO ?? 0.0,
                      customerDetails && customerDetails.primaryCurrencyCode
                        ? customerDetails.primaryCurrencyCode
                        : baseCurrency
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mt-4">
                <div className="border rounded p-3 bg-white">
                  <div>Available</div>
                  <div className="text-success">
                    {formatNumber(
                      getAvailableAmount(),
                      customerDetails && customerDetails.primaryCurrencyCode
                        ? customerDetails.primaryCurrencyCode
                        : baseCurrency
                    )}
                  </div>
                </div>
              </div>
              {customerDetails?.creditLimit &&
                customerDetails?.creditLimit < 0 && (
                  <div className="col-lg-4 col-sm-12 mt-4">
                    <div className="d-flex align-items-center">
                      <AlertIcon className="me-3" />
                      <div className="text-danger">
                        Customer has unapplied open credits on account
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div className="section pt-1 pb-3">
            <div className="row gx-5">
              <label className="checkbox-inline mb-4">
                <input
                  id="isPickup"
                  type="checkbox"
                  onChange={onPickupChange}
                  checked={pickupSo}
                  disabled={!getValues("accountNumber")}
                />
                <label
                  className={`${
                    !getValues("accountNumber") ? "text-muted" : "text-black"
                  } ms-2`}
                  htmlFor="isPickup"
                >
                  Pick up sales order?
                </label>
              </label>
              <div className="col-sm-12">
                <label>Ship to</label>
                <Lookup
                  onButtonClicked={() => {
                    openModal("shipTo");
                  }}
                  inputName="shippingInfoId"
                  isRequired={!pickupSo}
                  isDisabled={
                    !getValues("accountNumber") || pickupSo || isDisabled
                  }
                  initialData={modalTypes["shipTo"]?.data?.map((item) => ({
                    ...item,
                    id: item.shippingInfoId,
                    name: item.company,
                  }))}
                  onSelection={(e) => {
                    setSelectedModal(modalTypes["shipTo"]);
                    setSelectedModalType("shipTo");
                    setModalRowClicked(e);
                  }}
                  inputValue={getValues("shipTo")}
                />
                {errors["shippingInfoId"] && !getValues("shipTo") && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="col-sm-12 mt-3">
                <label>Company Name</label>
                {shippingDetails ? (
                  <div>
                    {shippingDetails["company"] || (
                      <span className="fst-italic text-muted">
                        Company Name
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic ">
                    Company Name
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-sm-12 mt-3">
                <label>Work Phone</label>
                {shippingDetails ? (
                  <div>
                    {shippingDetails["address"]["workNumber"] || (
                      <span className="fst-italic text-muted">Work Phone</span>
                    )}{" "}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic ">
                    + (111) 222-3333
                  </div>
                )}
              </div>
              <div className="col-sm-12 mt-3">
                <label>Address</label>
                {shippingDetails ? (
                  <div>
                    {shippingDetails["address"]["address"] || (
                      <span className="fst-italic text-muted">Address</span>
                    )}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic ">
                    Address
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12 mt-3">
                <label>City</label>
                {shippingDetails ? (
                  <div>
                    {shippingDetails["address"]["city"] || (
                      <span className="fst-italic text-muted">City</span>
                    )}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic ">
                    City
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12 mt-3">
                <label>State</label>
                {shippingDetails ? (
                  <div>
                    {shippingDetails["address"]["state"] || (
                      <span className="fst-italic text-muted">State</span>
                    )}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic ">
                    State
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12 mt-3">
                <label>Country</label>
                {shippingDetails ? (
                  <div>
                    {shippingDetails["address"]["country"] || (
                      <span className="fst-italic text-muted">Country</span>
                    )}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic">
                    Country
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-12 mt-3">
                <label className="text-nowrap">Zip Code</label>
                {shippingDetails ? (
                  <div className="">
                    {shippingDetails["address"]["zipCode"] || (
                      <span className="fst-italic text-muted">Zip Code</span>
                    )}
                  </div>
                ) : (
                  <div className="text-secondary fw-normal fst-italic ">
                    Zip Code
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="section p-4 pt-2 mt-4">
            <label>Remarks</label>
            <textarea
              className="form-control"
              placeholder="Write your remarks"
              {...register("remarks")}
              disabled={isEdit && isDisabled}
            />
          </div>
        </div>
      </div>
      <SelectionModal
        modal={selectedModal["modal"]}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={modalTypes[selectedModalType]?.data}
        columns={selectedModal["columns"]}
        setRowClicked={setModalRowClicked}
      />
      {isEdit && (
        <SalesOrdersModal
          showModal={openOrdersModal}
          setShowModal={setOpenOrdersModal}
          setRowClicked={setModalRowClicked}
          preselectedStatus={{ label: "Open", value: 1 }}
        />
      )}
      <ConfirmationModal
        title="Changing Hubs"
        message="Are you sure you want to change hubs? Your selected items will be cleared"
        showModal={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={changeHubs}
        cancelBtnTitle="No"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default SoInfo;
