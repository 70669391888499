import { DropDownOption } from "components/Common.models";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import { PurchaseOrderStates } from "pages/Purchasing/PurchaseEnum";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { VendorPurchaseOrder } from "../Vendors.models";
import { getVendorPurchaseOrders } from "../Vendors.services";

type Props = {
  vendorId: number;
};

const PurchaseOrdersTab = (props: Props) => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const [purchaseOrders, setPurchaseOrders] = useState<VendorPurchaseOrder[]>(
    []
  );

  const getPOs = useMutation(getVendorPurchaseOrders, {
    async onSuccess(response) {
      setPurchaseOrders(response?.data);
    },
  });

  useEffect(() => {
    if (props.vendorId)
      getPOs.mutate({ vendorId: props.vendorId, poStatus: 5, poType: 1 });
  }, [props.vendorId]);

  useEffect(() => {
    appDispatch({ type: "loading", value: getPOs.isLoading });
  }, [getPOs.isLoading, appDispatch]);

  const purchaseOrdersColumns = [
    {
      field: "purchaseOrderNumber",
      headerName: "PO Number",
    },
    {
      field: "poDate",
      headerName: "PO Date",
      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
    },
    {
      field: "vendorPO",
      headerName: "Vendor PO/Inv No",
    },
    {
      field: "amount",
      headerName: "PO Amount",
      valueGetter: (params) => {
        return `$${params.data?.amount?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "received",
      headerName: "Received Amount",
      valueGetter: (params) => {
        return `$${params.data?.received?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "poState",
      headerName: "State",
      cellRenderer: (params) =>
        params.value ? PurchaseOrderStates[params.value] : "",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "recDate",
      headerName: "Received Date",
      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
    },
    {
      field: "recBy",
      headerName: "Received By",
    },
    {
      field: "voidDate",
      headerName: "Void Date",
    },
    {
      field: "voidedBy",
      headerName: "Void By",
    },
  ];

  const [selectedOrderStates, setSelectedOrderStates] =
    useState<DropDownOption>({ label: "All", value: 5 });

  const onOrderStatesChange = (e) => {
    setSelectedOrderStates(e);
    getPOs.mutate({ vendorId: props.vendorId, poStatus: e.value, poType: 1 });
  };

  const onRowDoubleClick = (e) => {
    history.push(`${AppUrls.purchase_order_list}/${e.purchaseOrderId}`);
  };

  return (
    <>
      <div className="row mb-3 mt-4">
        <div className="col-lg-3">
          <Select
            options={Object.entries(PurchaseOrderStates).map(([k, v]) => ({
              label: v,
              value: k,
            }))}
            isClearable={false}
            isSearchable={true}
            value={selectedOrderStates}
            onChange={onOrderStatesChange}
            className="flex-grow-1"
          />
        </div>
      </div>
      <div className="min-h-350">
        <AgGrid
          data={purchaseOrders}
          columns={purchaseOrdersColumns}
          setrowClicked={onRowDoubleClick}
          autoSize={false}
        />
      </div>
    </>
  );
};

export default PurchaseOrdersTab;
