import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import DispatchContext from "context/DispatchContext";
import Lookup from "elements/Lookup";
import { dateFormmater, notificationMessage } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { SalesOrder } from "../SalesOrder.model";
import SalesOrderModel from "./SalesOrderModel/SalesOrderModel";
import {
  getSalesOrder,
  pickUpSalesOrder,
  searchSalesOrders,
} from "./SalesOrderServices/SalesOrderServices";
import "./SalesOrders.scss";

const SalesOrderPickup = () => {
  const appDispatch = useContext(DispatchContext);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { register, reset, getValues, setValue } = methods;

  const [showSalesOrderModel, setShowSalesOrderModel] =
    useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [salesOrder, setSalesOrder] = useState<SalesOrder>(null);
  const [isPrinting, setIsPrinting] = useState<Boolean>(false);

  const handleOnPrint = (e) => {
    if (e.target.value === "false") {
      setValue("copiesToPrint", 0);
      setIsPrinting(false);
    } else {
      setValue("copiesToPrint", 1);
      setIsPrinting(true);
    }
  };

  useEffect(() => {
    if (salesOrder) {
      reset({
        orderNo: salesOrder?.orderNumber,
        printInvoice: "true",
        copiesToPrint: 1,
      });
      setIsPrinting(true);
    }
  }, [salesOrder]);

  const getSalesOrders = useMutation(searchSalesOrders);

  const getSalesOrderItem = useMutation(getSalesOrder, {
    async onSuccess(response) {
      if (response.data?.status === 1 || response.data?.status === 6) {
        let notification = {
          variant: "danger",
          msg: "Open and void sales order can not be selected",
        };
        appDispatch({ type: "notification", value: notification });
      } else {
        setSalesOrder(response.data);
      }
    },
  });

  useEffect(() => {
    getSalesOrders.mutate("?states=0");
  }, []);

  const handleApply = useMutation(pickUpSalesOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `SO. ${salesOrder?.orderNumber} picked up successfully`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(
          error,
          `SO. ${salesOrder?.orderNumber} picked up failed`
        ),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: handleApply.isLoading });
  }, [handleApply.isLoading]);

  const clearPickupSalesOrderFrm = () => {
    setValue("orderNo", "");
    setValue("copiesToPrint", "");
    setValue("printInvoice", "");
    setSalesOrder(null);
    setIsPrinting(false);
  };

  const handleSubmitPickup = () => {
    setShowConfirmModal(false);
    if (salesOrder?.toPick) {
      handleApply.mutate({ soId: salesOrder?.salesOrderId });
      setShowConfirmModal(false);
    } else {
      let notification = {
        variant: "danger",
        msg: "Order not designated to be picked up.",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <div className="page-title page-title-editable">
            <div className="back-btn ">Pickup Sales Order</div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary me-3"
                onClick={() => clearPickupSalesOrderFrm()}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setShowConfirmModal(true);
                }}
                disabled={!getValues("orderNo")}
              >
                Pickup
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row d-flex justify-content-between mt-2 mb-3">
                <div className="col">
                  <label>
                    Order No. <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setShowSalesOrderModel(true)}
                    inputName="orderNo"
                    isRequired={true}
                    initialData={getSalesOrders?.data?.data?.data?.map(
                      (item) => ({
                        ...item,
                        id: item.salesOrderId,
                        name: item.orderNumber,
                      })
                    )}
                    onSelection={(e) =>
                      getSalesOrderItem.mutate(e.salesOrderId)
                    }
                    inputValue={getValues("orderNo")}
                  />
                </div>
                <div className="col">
                  <label>Receive Date</label>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {dateFormmater(Date.now())}
                  </div>
                </div>
                <div className="col">
                  <label>Print invoice after pickup?</label>
                  <div className="col">
                    <div className="form-group d-flex mt-2">
                      <label className="checkbox-inline">
                        <input
                          {...register("printInvoice")}
                          id="printYes"
                          defaultValue="true"
                          type="radio"
                          disabled={!getValues("orderNo")}
                          onChange={(e) => handleOnPrint(e)}
                        />
                        <label className="text-black ms-2" htmlFor="printYes">
                          Yes
                        </label>
                      </label>
                      <label className="checkbox-inline ms-3">
                        <input
                          {...register("printInvoice")}
                          id="printNo"
                          defaultValue="false"
                          type="radio"
                          disabled={!getValues("orderNo")}
                          onChange={(e) => handleOnPrint(e)}
                        />
                        <label className="text-black ms-2" htmlFor="printNo">
                          No
                        </label>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <label>Copies to Print</label>
                  <Form.Control
                    {...register("copiesToPrint")}
                    className="w-50"
                    type="number"
                    disabled={!isPrinting}
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-between my-3">
                <div className="col">
                  <label>Account</label>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {salesOrder?.accountNumber || (
                      <span className="fst-italic text-muted">Account</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label>Order Date</label>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {salesOrder?.orderDate?.toLocaleString() || (
                      <span className="fst-italic text-muted">Order Date</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label>Order by</label>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {salesOrder?.itemOrders.addedBy || (
                      <span className="fst-italic text-muted">Order By</span>
                    )}
                  </div>
                </div>
                <div className="col"></div>
              </div>
              <div className="row d-flex justify-content-between my-3">
                <div className="col">
                  <label>Company Name</label>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {salesOrder?.billingInfo?.company || (
                      <span className="fst-italic text-muted">Company</span>
                    )}
                  </div>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {salesOrder?.billingInfo?.address?.address || (
                      <span className="fst-italic text-muted">Address</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label>Ship Via</label>
                  <div className={`${!salesOrder && "text-muted"} ms-auto`}>
                    {salesOrder?.shippingMethod?.name || (
                      <span className="fst-italic text-muted">Ship via</span>
                    )}
                  </div>
                </div>
                <div className="col">
                  <label>Order Origin</label>
                  <div className={`${!salesOrder && "text-muted"}`}>
                    {salesOrder?.origin || (
                      <span className="fst-italic text-muted">
                        Order Origin
                      </span>
                    )}
                  </div>
                </div>
                <div className="col"></div>
              </div>
              <div className="section p-3 my-3 mx-2">
                <div className="col">
                  <label>Order Notes</label>
                  <div>
                    {salesOrder ? (
                      salesOrder?.remarks === "" ? (
                        "--"
                      ) : (
                        salesOrder?.remarks
                      )
                    ) : (
                      <span className="fst-italic text-muted">Order Notes</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SalesOrderModel
        isOpen={showSalesOrderModel}
        setIsOpen={setShowSalesOrderModel}
        title="Sales Order Listing/Search"
        setSalesOrder={setSalesOrder}
        componentTitle="Pickup Sales Order"
        // RemoveOpenAndVoided={true}
        showOnlyFulfilled={true}
      />
      <ConfirmationModal
        title="Pickup Sales Order"
        message="Are you sure?"
        showModal={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => handleSubmitPickup()}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Yes, pickup"
        type="confirmation-saving"
      />
    </>
  );
};

export default SalesOrderPickup;
