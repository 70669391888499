import { DropDownOption } from "components/Common.models";
import { ErrorResponse } from "components/data/Errors.model";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { ItemModel } from "./InventorySetup.model";
import {
  createItemModel,
  editItemModel,
  getManufacturers,
} from "./InventorySetup.services";

import * as AppUrls from "constants/AppUrls";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";

const NewItemModel = ({ match }) => {
  const [selectedId, setSelectedId] = useState<number>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { id, name, description, itemManufacturerId } = location.state;

      setSelectedId(id);
      setValue("model", name);
      setValue("itemManufacturerId", parseInt(itemManufacturerId));
      setValue("description", description);
    }
  }, [location.state]);

  const formMethods = useForm<ItemModel>({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    register,
    control,
  } = formMethods;

  const addItemModel = useMutation(createItemModel, {
    async onSuccess() {
      queryClient.invalidateQueries("get_models");
      let notification = {
        variant: "success",
        msg: `Item model ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_models);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding item model"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateItemModel = useMutation(editItemModel, {
    async onSuccess() {
      queryClient.invalidateQueries("get_models");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.item_models);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing item model"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const history = useHistory();

  const onSubmit = (values: ItemModel) => {
    if (!selectedId) {
      addItemModel.mutate(values);
    } else {
      updateItemModel.mutate({ ...values, itemModelId: selectedId });
    }
  };

  const resetForm = () => {
    reset();
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addItemModel.isLoading || updateItemModel.isLoading,
    });
  }, [addItemModel.isLoading, updateItemModel.isLoading, appDispatch]);

  const { error: errorLoadingManufacturer, data: manufacturersList } = useQuery<
    any,
    ErrorResponse
  >("get_manufacturers", getManufacturers);

  let manufacturersOpts: DropDownOption[] = manufacturersList?.data?.map(
    (obj) => {
      return {
        value: obj.manufacturerId,
        label: obj.name,
      };
    }
  );

  useEffect(() => {
    if (errorLoadingManufacturer?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingManufacturer.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingManufacturer, appDispatch]);

  return (
    <>
      <div className="static-page">
        <FormProvider {...formMethods}>
          <form id="new-method-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="  page-title page-title-editable">
              <div className="d-flex flex-row">
                <div className="back-btn">
                  <img
                    src={backArrow}
                    alt="back arrow"
                    onClick={() => history.push(AppUrls.item_models)}
                  />
                </div>
                <div>
                  <p className="">Item Model</p>
                </div>
              </div>
              <div className="d-flex" style={{ marginLeft: "10px" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary custom-outline custom-outline"
                  onClick={resetForm}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isDirty}
                >
                  {!selectedId ? "Add Model" : "Save Changes"}
                </button>
              </div>
            </div>
            <div className="page-content-wrapper">
              <div className="row h-100">
                {(checkIfAllowed(appState.allowedUrls, "add", match.path) ||
                  checkIfAllowed(appState.allowedUrls, "edit", match.path)) && (
                  <div className="">
                    <div className="editable-info">
                      <div className="form-group">
                        <label className="label">
                          {selectedId ? "Item Model" : "New Item Model"}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("model", {
                            required: true,
                            maxLength: 15,
                          })}
                          className={
                            errors.model
                              ? "form-control required_field"
                              : "form-control"
                          }
                        />
                        {errors.model && errors.model.type === "maxLength" && (
                          <p className="text-danger">
                            Max length for name value must not exceed 15
                            characters.
                          </p>
                        )}
                        {errors.model && errors.model.type === "required" && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="form-group w-50">
                        <label>
                          Manufacturer <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="itemManufacturerId"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={manufacturersOpts}
                              isClearable
                              isSearchable={true}
                              value={
                                manufacturersOpts?.find(
                                  (opt) => opt?.value === value
                                ) || null
                              }
                              onChange={(selectedOption: any) =>
                                onChange(selectedOption?.value)
                              }
                              placeholder="Choose"
                              menuPlacement="auto"
                              className={
                                errors.itemManufacturerId && "required_field"
                              }
                            />
                          )}
                        />
                        {errors.itemManufacturerId && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="label">
                          Description <span className="text-danger">*</span>
                        </label>
                        <input
                          {...register("description", { required: true })}
                          className={
                            errors.description
                              ? "form-control required_field"
                              : "form-control"
                          }
                        />
                        {errors.description && (
                          <p className="text-danger">This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default NewItemModel;
