import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useMutation } from "react-query";
import AG from "elements/AG";
import * as AppUrls from "constants/AppUrls";
import { dateFormmaterNoTime } from "global/helpers";
import { getReceiveInvoice } from './Vendors.services';
import { GridColumn } from 'elements/AG.models';
import { ReactComponent as RefreshIcon } from 'img/refresh.svg'

const ReceiptInvoicePayment = () => {

    const history = useHistory();
    const [refreshCounter, setRefreshCounter] = useState(0)

    const dataMutation = useMutation('data', getReceiveInvoice);

    const onRowDoubleClick = (e) => {
        const url = `${AppUrls.receipt_invoice_payment}/${e.receiveInvoicePaymentId}`;
        history.push(url);
    }

    const columns: GridColumn[] = [
        {
            field: "receiptNumber",
            headerName: "Receipt No",
        },
        {
            field: "paymentAmount",
            headerName: "Payment Amount",
            cellRenderer: (params) => `$ ${params.value}`,
        },
        {
            field: "paymentDate",
            headerName: "Payment Date",
            cellRenderer: (params) => dateFormmaterNoTime(params.value),
        },
        {
            field: "createdBy",
            headerName: "Created By",
        }
    ];

    const onRefresh = () => {
        setRefreshCounter(prev => prev + 1)
    }

    return (
        <div className="grid-page">
            <div className="page-title page-title-editable">
                <p>Receipt Invoice Payment</p>
                <div>
                    <button onClick={onRefresh} className="import-btn import-btn-blue">
                        <RefreshIcon className="me-2" />
                        Refresh
                    </button>
                </div>
            </div>
            <div className="page-content-wrapper">
                <div className="grid-container">
                    <AG
                        columns={columns}
                        setrowClicked={onRowDoubleClick}
                        pagination={true}
                        fetchMethod={dataMutation}
                        refreshCounter={refreshCounter}
                    />
                </div>
            </div>
        </div>
    );
}

export default ReceiptInvoicePayment

