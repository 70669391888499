import { useContext, useState } from 'react'
import Select from "react-select";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DateFilter from 'components/DateFilter/DateFilter';
import { useMutation } from 'react-query';
import { PurgeEntitiesAPI } from './SystemSetupServices/SystemSetupServices';
import DispatchContext from 'context/DispatchContext';
import { fixTimezoneOffset } from 'global/helpers';

const Purging = () => {

    const appDispatch = useContext(DispatchContext)

    const Dateoptions = [
        { value: 5, label: "This Year" },
        // { value: 6, label: "This Quarter" },
        { value: 7, label: "This Month" },
        { value: 8, label: "This Week" },
        { value: 1, label: "Last Year" },
        { value: 2, label: "Last Quarter" },
        { value: 3, label: "Last Month" },
        { value: 4, label: "Last Week" },
        { value: 9, label: "Year To Date" },
        { value: 10, label: "Custom" }
    ]

    const [dateCategory, setDateCategory] = useState(null)

    const methods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const { formState: {isDirty},  handleSubmit, control, reset, getValues } = methods

    let options = [
        {
            value: 0,
            label: "All"
        },
        {
            value: 1,
            label: "Ledgers"
        },
        {
            value: 2,
            label: "Customers"
        },
        {
            value: 3,
            label: "Vendors"
        },
        {
            value: 4,
            label: "Inventory"
        },
        {
            value: 5,
            label: "Users"
        }
    ]

    const onCancel = () => {
        reset()
        setDateCategory(null)
    }

    const purgeElements = useMutation(PurgeEntitiesAPI, {
        onSuccess() {
            let notification = {
                variant: "success",
                msg: "Selected entities purged successfully",
              };
              appDispatch({ type: "notification", value: notification })
              onCancel()
        }
    });

    const onSubmit = () => {
        const values = getValues()
        let obj = {
            Option: values.purgingEntity,
            StartDate: fixTimezoneOffset(values.startDate),
            EndDate: fixTimezoneOffset(values.endDate),
        }
        purgeElements.mutate(obj)
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="page-title page-title-editable">
                    <div className="back-btn ">
                        Purging
                    </div>
                    <div>
                        <button
                            className="btn btn-success"
                            disabled={!isDirty || dateCategory === null}
                        >
                            Purge
                        </button>
                    </div>
                </div>
                <div className="page-content-wrapper">
                    <div className="page-content">
                        <div className='row'>
                            <div className='col-4'>
                                <div style={{height: "40px"}}></div>
                                <div className='form-group'>
                                    <label className="label me-3">Perging entity <span className='text-danger'>*</span></label>
                                    <Controller
                                        control={control}
                                        name="purgingEntity"
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                options={options}
                                                isSearchable={true}
                                                menuPlacement="auto"
                                                value={options?.find(opt => opt?.value === value) || null}
                                                onChange={(val: any) => { onChange(val?.value) }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='col-8'>
                                <p className='text-danger ms-2'><b>Select Period when no  transaction activity was recorded:</b></p>
                                <FormProvider {...methods}>
                                    <DateFilter dateCategory={dateCategory} setDateCategory={setDateCategory} options={Dateoptions} isRequiredStar={true} />  
                                </FormProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Purging