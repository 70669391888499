import { useContext, useState, useEffect } from "react";
import SideAccordion from "components/submenu/SideAccordion";
import * as AppUrls from "../constants/AppUrls";
import StateContext from "../context/StateContext";
import { Link } from "react-router-dom";
import DispatchContext from "../context/DispatchContext";
import { useMutation, useQuery } from "react-query";
import { getUserComponents } from "pages/UserManagement/UserManagementServices/UserManagementServices";
import { getUserProfile } from "pages/User/userServices";
import { logoutApi } from "pages/SalesMarketing/SalesMarketing.services";

const SideMenu = () => {
  const appState = useContext(StateContext);

  const appDispatch = useContext(DispatchContext);

  const [permissions, setPermissions] = useState([]);
  const [menuEntries, setMenuEntries] = useState([]);

  const mainMenus = [
    "SystemSetup",
    "UserManagement",
    "SalesMarketing",
    "Vendors",
    "Inventories",
    "InventorySetup",
    "InventoryItems",
    "SalesOrderMain",
    "Invoices",
    "Purchasing",
    "ItemHistory",
    "Portal",
    "CustomerRMA",
    "VendorRMA",
    "Payroll",
    "Payments",
    "Currency",
  ];

  const { data: components } = useQuery("componentsList", getUserComponents);

  const { isSuccess: isSuccessPermissions, data: permissionsList } = useQuery(
    "permissionsList",
    getUserProfile
  );

  const logoutMutation = useMutation(logoutApi, {
    onSettled() {
      localStorage.removeItem("blueseedTokens");
      localStorage.removeItem("company");
      localStorage.removeItem("companyId");
      appDispatch({ type: "logout" });
      appDispatch({ type: "urls", value: [] });
    },
  });

  useEffect(() => {
    if (isSuccessPermissions) {
      appDispatch({
        type: "user",
        value: {
          firstName: permissionsList.data.firstName,
          lastName: permissionsList.data.lastName,
          forcePasswordChange: permissionsList.data.forcePasswordChange,
        },
      });
      setPermissions(
        permissionsList.data.userActions
          .filter((action) => action.isGranted === true)
          .map((action) => action.actionIndex)
      );
    }
  }, [isSuccessPermissions, permissionsList, appDispatch]);

  useEffect(() => {
    let menuEntries = [];
    let allowedUrls = [];
    // filter menu entries by permmissions
    const checkIfGranted = (array) => {
      for (let element of array) {
        let allowedActions = [];
        for (let action of element.actions)
          if (permissions.includes(action.index)) {
            element.isGranted = true;
            element.to = AppUrls.entriesUrls[element.key];
            element.value = element.name;
            allowedActions.push(action);
          }
        if (!mainMenus.includes(element.key) && allowedActions.length > 0)
          allowedUrls.push({
            url: AppUrls.entriesUrls[element.key],
            actions: allowedActions,
            label: element.name,
          });
        if (element.children.length > 0) checkIfGranted(element.children);
      }
    };
    // construct array to send to main menu
    const addDropDownItems = (menuEntry) => {
      menuEntry.dropDownItems = [];
      for (let child of menuEntry.children) {
        if (child.isGranted) {
          menuEntry.dropDownItems.push(child);
          if (child.to === "!#" && child.children.length > 0) {
            child.sublinks = [];
            for (let element of child.children)
              if (element.isGranted) child.sublinks.push(element);
          }
        }
      }
      return menuEntry;
    };
    if (components?.data.length > 0 && permissions.length > 0) {
      checkIfGranted(components.data);
      for (let component of components.data)
        if (component.isGranted) {
          let menuEntry = { ...component };
          if (menuEntry.children.length > 0) {
            menuEntry = addDropDownItems(menuEntry);
          } else {
            menuEntry.dropDownItems = [];
          }
          menuEntries.push(menuEntry);
        }
      appDispatch({ type: "urls", value: allowedUrls });
      setMenuEntries(menuEntries);
    }
  }, [components, permissions]);

  useEffect(() => {
    if (menuEntries.length === 0) appDispatch({ type: "loading", value: true });
    else appDispatch({ type: "loading", value: false });
  }, [menuEntries, appDispatch]);

  const onLogout = () => {
    logoutMutation.mutate();
  };

  return (
    <>
      <div className="user-name border-bottom">
        <div className="header-user-initials">
          {appState.userData.firstName[0]}
          {appState.userData.lastName[0]}
        </div>
        <h3 className="m-0">
          {appState.userData.firstName} {appState.userData.lastName}
          <div className="fw-normal fs-6">{permissionsList?.data?.email}</div>
        </h3>
      </div>
      <div className="sidemenu-container">
        <SideAccordion entries={menuEntries} />
      </div>
      <div className="side-footer-links border-top">
        <div>
          <Link to={AppUrls.user_profile}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person"
              viewBox="0 0 16 16"
            >
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
            </svg>
            <span>Profile</span>
          </Link>
        </div>
        <div onClick={onLogout} className=" footer-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-box-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
            />
            <path
              fillRule="evenodd"
              d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
            />
          </svg>
          <span>Sign Out</span>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
