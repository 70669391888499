import { useState, useEffect, useContext } from "react";
import StateContext from "context/StateContext";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { notificationMessage, checkIfAllowed } from "global/helpers";
import { AccountTypeDTO } from "./AccountType.models";
import { ErrorResponse } from "components/data/Errors.model";
import { Link, useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import addIcon from "../../../img/add-icon.svg";
import {
  getAccountTypes,
  createAccountType,
  editAccountType,
  deleteAccountType,
} from "../SalesMarketing.services";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";

const AccountTypes = ({ match }) => {
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<AccountTypeDTO>(null);
  const appDispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const [filter, setFilter] = useState({
    params: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { data: accountTypes } = useQuery<AccountTypeDTO[], ErrorResponse>(
    ["get_account_types", filter],
    getAccountTypes
  );
  const [dataGrid, setDataGrid] = useState(null);
  const columns = [
    {
      field: "name",
      headerName: "Customer Type",
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) => {
        return params.data.isActive ? "Active" : "Disabled";
      },
      cellRenderer: (params) => {
        let status = params.data.isActive;
        return `
        <div class="${status ? "active-status" : "disabled-status"}">
          ${status ? "Active" : "Disabled"}
        </div>
      `;
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   floatingFilter: false,
    //   filter: false,
    //   sortable: false,
    //   hide: !checkIfAllowed(appState.allowedUrls, "delete", match.path),
    //   onCellClicked: (params) => {
    //     onDeleteItemClicked(params.data);
    //   },
    //   cellRenderer: () => {
    //     return `
    //         <div class="btn text-danger">
    //           <img src=${Delete} alt="Delete Icon" />
    //           Delete
    //         </div>
    //   `;
    //   },
    // },
  ];

  const addAccType = useMutation(createAccountType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_account_types");
      let notification = {
        variant: "success",
        msg: `Customer type ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding customer type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateAccType = useMutation(editAccountType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_account_types");
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing customer type"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeAccType = useMutation(deleteAccountType, {
    async onSuccess() {
      queryClient.invalidateQueries("get_account_types");
      let notification = {
        variant: "success",
        msg: `Information ${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
    },
  });
  const history = useHistory();

  const onRowDoubleClicked = (e: AccountTypeDTO) => {
    const url = `${AppUrls.account_types}/${e.accTypeId}`;
    history.push(url);
  };

  const handleDeleteItem = (itemId) => {
    setShowDelete(false);
    removeAccType.mutate(itemId);
    setSelectedItem(null);
  };

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addAccType.isLoading || updateAccType.isLoading,
    });
  }, [addAccType.isLoading, updateAccType.isLoading]);

  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
    setDataGrid(null);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Customer Types</p>
        <div>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.new_account_types} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New Customer Type
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="filters">
            <div className="col-lg-3">
              <div className="page-search">
                <label className="label me-3">Search</label>
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row h-100">
            <div className="">
              <div className="h-100 w-100">
                <AgGrid
                  columns={columns}
                  data={accountTypes}
                  setDataGrid={setDataGrid}
                  filters={filter}
                  setrowClicked={onRowDoubleClicked}
                  setIsLoadingGridExport={setIsLoadingGridExport}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Delete Account Type"
        message={`Are you sure you want to delete the account type "${selectedItem?.name}"?`}
        showModal={showDelete}
        onClose={() => setShowDelete(false)}
        onConfirm={() => {
          handleDeleteItem(selectedItem.accTypeId);
        }}
        cancelBtnTitle="Keep"
        confirmBtnTitle="Delete"
        type="confirmation-danger"
      />
    </div>
  );
};

export default AccountTypes;
