import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import addIcon from "img/add-icon.svg";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import { useQuery, useQueryClient } from "react-query";
import * as AppUrls from "constants/AppUrls";
import { getQpayList } from "./SystemSetupServices/SystemSetupServices";
import { checkIfAllowed } from "global/helpers";
import StateContext from "context/StateContext";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import exportIcon from "img/export.svg";

const QpayCredentials = ({ match }) => {
  const appState = useContext(StateContext);
  const queryClient = useQueryClient();
  const appDispatch = useContext(DispatchContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data: qpay } = useQuery(["qpayList", filter], getQpayList);

  let history = useHistory();

  const [rowClicked, setrowClicked] = useState(null);

  useEffect(() => {
    if (rowClicked) {
      //@ts-ignore
      const id = rowClicked?.qPayId;
      history.push(`/system-setup/qpay-credentials/${id}`);
    }
  }, [rowClicked]);

  const columns = [
    {
      field: "hub.hubKey",
      headerName: "Hub ID",
    },
    {
      field: "username",
      headerName: "Username",
    },
    {
      field: "branchId",
      headerName: "Branch ID",
    },
  ];

  const onRefresh = () => {
    queryClient.invalidateQueries(["qpayList"]);
    let notification = {
      variant: "success",
      msg: "Table is refreshing...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  const [exportToExcelClicked, setExportToExcelClicked] = useState(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState(false);
  const [isLoadingGridExport, setIsLoadingGridExport] = useState(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);
  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Qpay Credentials</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || qpay?.data?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link
              to={AppUrls.create_qpay_credentials}
              className="btn btn-primary"
            >
              <img src={addIcon} className="me-2" alt="" /> New Credentials
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <AgGrid
            columns={columns}
            data={qpay?.data}
            setrowClicked={setrowClicked}
            exportToExcel={exportToExcelClicked}
            setExportToExcelClicked={setExportToExcelClicked}
            setDisabledExportBtn={setDisabledExportBtn}
            setIsLoadingGridExport={setIsLoadingGridExport}
            autoSize={true}
            fileName="QPay Credentials List Report"
          />
        </div>
      </div>
    </div>
  );
};

export default QpayCredentials;
