import { GridColumns, QueryResult } from "components/Common.models";
import DateGrid from "components/DateFilter/DateGrid";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import SelectionModal from "components/SelectionModal/SelectionModal";
import DispatchContext from "context/DispatchContext";
import AG from "elements/AG";
import Lookup from "elements/Lookup";
import {
  fixTimezoneOffset,
  formatNumber,
  notificationMessage,
} from "global/helpers";
import SearchIcon from "img/search-group.png";
import { Hub } from "pages/Inventory/Inventory.models";
import { getInventoryHubsList } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { PaymentMethod } from "pages/Invoices/Invoices.model";
import { getPaymentMethods } from "pages/Invoices/InvoicesServices/InvoicesServices";
import { getCustomers } from "pages/SalesMarketing/Customers/Customers.services";
import { useContext, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { eodCashOut, generateEod, getAllEod, getEodById } from "./EodServices";

const EodCash = () => {
  const appDispatch = useContext(DispatchContext);
  // const [showReport, setShowReport] = useState<boolean>(false)
  const [openBatchSelection, setOpenBatchSelection] = useState<boolean>(false);
  const [openHubsSelection, setOpenHubsSelection] = useState<boolean>(false);
  const [openCustomerSelection, setOpenCustomerSelection] =
    useState<boolean>(false);
  const [selectedHubs, setSelectedHubs] = useState<number[]>([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [, setRefreshCounter] = useState<number>(0);
  const [payments, setPayments] = useState([]);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  // const viewerRef = useRef();
  const [dateCategory, setDateCategory] = useState(null);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit } = methods;

  const { data: allEod } = useQuery("allEod", getAllEod);
  const { data: hubs } = useQuery<QueryResult<Hub>>(
    "hubsList",
    getInventoryHubsList
  );
  const { data: paymentMethods } = useQuery<PaymentMethod[]>(
    "paymentMethods",
    getPaymentMethods
  );
  const { data: customers } = useQuery("customersList", getCustomers);

  const handleGenerate = useMutation(generateEod, {
    async onSuccess(data) {
      let dataValues = Object.values(data);
      let dataKeys = Object.keys(data);
      setPayments(
        paymentMethods.map((method) => {
          let index = dataKeys.indexOf(method.paymentMethodId.toString());
          if (index > -1)
            return {
              ...method,
              systemTotal: dataValues[index]["payment"],
              total: 0,
              paymentReceiptIds: dataValues[index]["paymentReceiptIds"],
            };
          else return { ...method, systemTotal: 0, total: 0 };
        })
      );
    },
  });

  const handleCashOut = useMutation(eodCashOut, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Batch created successfully",
      };
      appDispatch({ type: "notification", value: notification });
      setSelectedCustomer(null);
      setSelectedHubs([]);
      setPayments([]);
      setStartDate(null);
      setEndDate(null);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Failed to create batch"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const getBatch = useMutation(getEodById, {
    async onSuccess(data) {
      setEndDate(data.endDate);
      setStartDate(data.startDate);
      setPayments(
        data.paymentRecords.map((record) => {
          let foundMethod = paymentMethods.find(
            (method) => method.paymentMethodId === record.paymentMethodId
          );
          if (foundMethod) return { ...record, name: foundMethod.name };
          return { ...record };
        })
      );
    },
  });

  const batchColumns: GridColumns[] = [
    {
      field: "batchNumber",
      headerName: "Batch No",
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      editable: false,
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "endDate",
      headerName: "End Date",
      editable: false,
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "createdBy",
      headerName: "Added By",
      editable: false,
    },
    {
      field: "createdDate",
      headerName: "Added Date",
      editable: false,
      cellRenderer: (params) =>
        params.value ? `${new Date(params.value).toLocaleDateString()}` : "",
    },
    {
      field: "deposit",
      headerName: "Deposit",
      editable: false,
      valueGetter: (params) => {
        return `$${params.data?.deposit?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const customerColumns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
      valueGetter: (params) => {
        return `$${params.data?.balance?.toFixed(2) ?? "0.00"}`;
      },
    },
  ];

  const paymentColumns: GridColumns[] = [
    {
      field: "name",
      headerName: "Payment Type",
    },
    {
      field: "total",
      headerName: "Your Total",
      editable: !selectedBatch,
      cellRenderer: (params) =>
        isNaN(params.value) ? `$0.00` : formatNumber(params.value),
      onCellValueChanged: () => setRefreshCounter((prev) => prev + 1),
    },
    {
      field: "systemTotal",
      headerName: "System Total",
      cellRenderer: (params) =>
        isNaN(params.value) ? `$0.00` : formatNumber(params.value),
    },
    {
      field: "",
      headerName: "Difference",
      valueGetter: (params) =>
        formatNumber(
          params.getValue("systemTotal") -
            (isNaN(params.getValue("total")) ? 0 : params.getValue("total"))
        ),
    },
  ];

  const onBatchSelected = (batch) => {
    setSelectedBatch(batch);
    setOpenBatchSelection(false);
    setSelectedHubs([]);
    setSelectedCustomer(null);
    getBatch.mutate(batch.eodId);
  };

  const onHubSelected = (items) => {
    const selectedHubs = items.map((i) => i.id);
    setSelectedHubs(selectedHubs);
    setSelectedBatch(null);
    setPayments([]);
    setOpenHubsSelection(false);
  };

  const onCustomerSelected = (customer) => {
    setSelectedCustomer(customer);
    setOpenCustomerSelection(false);
  };

  const onGenerateClick = () => {
    setPayments([]);
    setSelectedBatch(null);
    if (startDate && endDate) {
      if (selectedHubs.length > 0) {
        let params = {
          startDate: fixTimezoneOffset(startDate),
          endDate: fixTimezoneOffset(endDate),
          hubs: selectedHubs,
          forUser: null,
        };
        if (selectedCustomer)
          params.forUser = selectedCustomer.customerAccountId;
        handleGenerate.mutate(params);
      } else {
        let notification = {
          variant: "danger",
          msg: "Please select hubs",
        };
        appDispatch({ type: "notification", value: notification });
      }
    } else {
      let notification = {
        variant: "danger",
        msg: "Please select start date, end date",
      };
      appDispatch({ type: "notification", value: notification });
    }
  };

  const clearFilters = () => {
    setSelectedCustomer(null);
    setSelectedHubs([]);
    setPayments([]);
    setStartDate(null);
    setEndDate(null);
    setDateCategory(null);
  };

  const onSubmit = () => {
    let data = {
      remark: inputRef.current.value,
      startDate: fixTimezoneOffset(startDate),
      endDate: fixTimezoneOffset(endDate),
      paymentRecords: payments,
    };
    handleCashOut.mutate(data);
  };

  const getDeposit = () => {
    let deposit = 0;
    for (let payment of payments) deposit += payment.systemTotal;
    return deposit;
  };

  const getTotal = () => {
    let total = 0;
    for (let payment of payments) total += Number(payment.total);
    return total;
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div>EOD Cash</div>
            <div>
              <button
                type="submit"
                className="btn btn-success"
                disabled={selectedBatch || payments.length === 0}
              >
                Save
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div style={{ display: "flex", marginLeft: "10px", marginBottom: "10px" }}>
                <div className="col-sm-2" style={{ marginRight: "10px" }}>
                  <div className="form-group">
                    <label>Batch No</label>
                    <Lookup
                      onButtonClicked={() => setOpenBatchSelection(true)}
                      inputName="batchNumber"
                      initialData={allEod?.map((item) => ({
                        ...item,
                        id: item.eodId,
                        name: item.batchNumber,
                      }))}
                      onSelection={onBatchSelected}
                      inputValue={selectedBatch?.batchNumber}
                    />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <DateGrid
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateCategory={dateCategory}
                    setDateCategory={setDateCategory}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2" style={{width: "210px"}}>
                  <div className="form-group eod-form">
                    <label>Hub Id</label>
                    <InputGroup>
                      <div className="selected-container">
                        {hubs?.data
                          ?.filter((hub) =>
                            selectedHubs?.includes(hub.locationId)
                          )
                          .map((hub) => {
                            return (
                              <span
                                key={hub.locationId}
                                className="selected-item"
                              >
                                {hub.hubId}
                              </span>
                            );
                          })}
                      </div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setOpenHubsSelection(true);
                        }}
                        className="input-group-prepend"
                      >
                        <img src={SearchIcon} alt="" />
                      </Button>
                    </InputGroup>
                  </div>
                </div>
                <div className="col-sm-2" style={{width: "210px"}}>
                  <div className="form-group position-relative eod-form">
                    <label>Customer No.</label>
                    <Lookup
                      onButtonClicked={() => {
                        setOpenCustomerSelection(true);
                      }}
                      inputName="targetId"
                      initialData={customers?.data.map((item) => ({
                        ...item,
                        id: item.customerAccountId,
                        name: item.accountNumber,
                      }))}
                      onSelection={onCustomerSelected}
                      inputValue={selectedCustomer?.accountNumber}
                    />
                    {selectedCustomer && (
                      <div
                        className="clear-button"
                        onClick={() => setSelectedCustomer(null)}
                      >
                        &#10005;
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="d-flex align-items-center h-100 mt-1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={onGenerateClick}
                    >
                      Display
                    </button>
                    <button
                      type="button"
                      onClick={clearFilters}
                      className="btn btn-outline-primary custom-outline"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div
                  className={`col-sm-8 mb-2 alert alert-warning fw-bold ${
                    (selectedBatch || payments.length === 0) && "invisible"
                  }`}
                >
                  Edit "Your Total" column to make changes
                </div>
                <div className="col-sm-8 h-450">
                  <AG data={payments} columns={paymentColumns} />
                </div>
                <div className="col-sm-4">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="fs-5">TOTAL</div>
                    <div className="text-success">
                      {formatNumber(getTotal())}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fs-5">DEPOSIT</div>
                    <div>{formatNumber(getDeposit())}</div>
                  </div>
                  <hr className="my-3" />
                  <div className="section mt-3 p-3">
                    <label>Remarks</label>
                    <textarea
                      ref={inputRef}
                      className="form-control"
                      placeholder="Write your remarks"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Batch Number"}
        showModal={openBatchSelection}
        setShowModal={setOpenBatchSelection}
        data={allEod}
        columns={batchColumns}
        setRowClicked={onBatchSelected}
      />
      {openHubsSelection && (
        <ListSelectionModal
          showModal={openHubsSelection}
          title="Hub ID List"
          data={hubs?.data.map((hub) => ({
            ...hub,
            id: hub.locationId,
            title: hub.hubId,
          }))}
          selectedValues={selectedHubs}
          onSelectData={onHubSelected}
          onExit={() => setOpenHubsSelection(false)}
        />
      )}
      <SelectionModal
        modal={"Account"}
        showModal={openCustomerSelection}
        setShowModal={setOpenCustomerSelection}
        data={customers?.data}
        columns={customerColumns}
        setRowClicked={onCustomerSelected}
      />
    </>
  );
};

export default EodCash;
