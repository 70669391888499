import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { getSalesDetailReportAPI } from "./services/ReportServices";
import "./style/common.scss";

import backArrow from "img/back-arrow.svg";

import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import {
  dateFormmaterNoTime,
  fetchReportParamsQuery,
  fixTimezoneOffset,
} from "global/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";

import { getBaseCurrency } from "components/currency";
import exportIcon from "img/export.svg";
import Filter from "./components/Filter";

const options = [
  { value: 1, label: "None" },
  { value: 2, label: "Customer" },
  { value: 3, label: "Hub" },
];

export default function AccountSalesDetailReport() {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const { search } = useLocation();

  const queryParams = fetchReportParamsQuery(search);

  const customizedComponentId = queryParams[1];
  const isCustomizedReport = customizedComponentId != null;

  const [reportName, setReportName] = useState("Account Sales Detail Report");
  const headers = ["Transactions"];
  const childFnRef = useRef(null);
  const childFnRef1 = useRef(null);
  const [, setRefreshCounter] = useState(0);

  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(getSalesDetailReportAPI, {
    async onSuccess(data) {
      let currentData = { ...data };
      setReportData(currentData);
    },
  });

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, getValues, setValue } = methods;

  const onSubmit = () => {
    const values = getValues();
    let params = {
      groupingElement: values.groupingElement?.value,
      startDate: null,
      endDate: null,
      customerId: null,
      accountTypeId: null,
      type: null,
      hubs: [],
      repId: null,
      insideRep: false,
      itemId: null,
      classId: null,
      subClassId: null,
      productLineId: null,
      supplierId: null,
      categoryId: null,
      manufacturerId: null,
      isSerialized: null,
      isRetail: null,
    };

    if (values.startDate)
      params.startDate = fixTimezoneOffset(values.startDate);
    if (values.endDate) params.endDate = fixTimezoneOffset(values.endDate);
    if (values.HubsId) params.hubs = values.HubsId.map((e) => e.value);
    if (values.ClassId) params.classId = values.ClassId?.value;
    if (values.SubClassId) params.subClassId = values.SubClassId?.value;
    if (values.ProductLineId)
      params.productLineId = values.ProductLineId?.value;
    if (values.CategoryId) params.categoryId = values.CategoryId?.value;
    if (values.ManufacturerId)
      params.manufacturerId = values.ManufacturerId?.value;
    if (values.IsSerialized) params.isSerialized = values.IsSerialized?.value;
    if (values.CustomerId) params.customerId = values.CustomerId?.value;
    if (values.CustomerTypeId)
      params.accountTypeId = values.CustomerTypeId?.value;
    if (values.invoiceType) params.type = values.invoiceType?.value;
    if (values.IsRetail) params.isRetail = values.IsRetail?.value;
    if (values.SupplierId) params.supplierId = values.SupplierId?.value;
    if (values.RepresentativeId) params.repId = values.RepresentativeId?.value;
    if (values.IsInsideRep) params.insideRep = values.IsInsideRep?.value;

    generateReport.mutate(params);
  };
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency);
  const currency = baseCurrency?.symbol;
  const columns = [
    [
      {
        field: "elementName",
        cellRenderer: "agGroupCellRenderer",
        headerName: reportData?.groupTitle ?? "",
      },
      {
        field: "total_Cost",
        headerName: "Total Cost",
        valueGetter: (params) => {
          return currency + params.data.total_Cost.toFixed(2);
        },
      },
      {
        field: "total_Price",
        headerName: "Total Price",
        valueGetter: (params) => {
          return currency + params.data.total_Price.toFixed(2);
        },
      },
      {
        field: "total_Quantity",
        headerName: "Total Quantity",
      },
      {
        field: "total_Subtotal",
        headerName: "Subtotal",
        valueGetter: (params) => {
          return currency + params.data.total_Subtotal.toFixed(2);
        },
      },
    ],
    [
      {
        field: "invoice",
        headerName: "Invoice No",
      },
      {
        field: "account",
        headerName: "Account",
      },
      {
        field: "itemNumber",
        headerName: "Item No",
      },
      {
        field: "serialNumber",
        headerName: "Serial",
      },
      {
        field: "price",
        headerName: "Price",
        valueGetter: (params) => {
          return "$" + params.data.price.toFixed(2);
        },
      },
      {
        field: "cost",
        headerName: "Cost",
        valueGetter: (params) => {
          return "$" + params.data.cost?.toFixed(2);
        },
      },
      {
        field: "quantity",
        headerName: "QTY",
      },
      {
        field: "subTotal",
        headerName: "Sub Total",
        valueGetter: (params) => {
          return "$" + params.data.subTotal.toFixed(2);
        },
      },
      {
        field: "invoiceDate",
        headerName: "Date",
        valueGetter: (params) => {
          return dateFormmaterNoTime(params.data.invoiceDate);
        },
      },
    ],
  ];

  const [gridColumns, setGridColumns] = useState(
    isCustomizedReport
      ? []
      : columns.map((column) => {
          return column.map((c) => ({ ...c }));
        })
  );

  const detailCellRenderer = ({ data }) => {
    return (
      <div className={`py-4`}>
        <AgGrid
          data={data?.salesDetailsItems}
          columns={gridColumns[1]}
          pagination={false}
          autoHight={true}
          forPrinting={true}
          ref={childFnRef1}
          autoSize={true}
        />
      </div>
    );
  };

  const onBackToReportsClick = () => {
    history.push("/reports");
  };
  const [forPrinting, setForPrinting] = useState(false);
  const reportPage = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    setForPrinting(true);
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setForPrinting(false);
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };
  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  useEffect(() => {
    if (!isCustomizedReport) {
      setValue("groupingElement", { value: 1, label: "None" });
      generateReport.mutate({});
    }
  }, [isCustomizedReport]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {reportName}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <Filter
                filters={[
                  0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18,
                ]}
                onSubmit={onSubmit}
                gridColumns={gridColumns}
                setGridColumns={setGridColumns}
                setReportName={setReportName}
                headers={headers}
                childFnRef={childFnRef.current}
                childFnRef1={childFnRef1.current}
                setRefreshCounter={setRefreshCounter}
                groupingElements={options}
              />
              <>
                <div className="container text-end mb-3">
                  <button
                    className="import-btn"
                    type="button"
                    onClick={exportToExcel}
                    disabled={reportData?.salesDetailsItems?.length === 0}
                  >
                    <img src={exportIcon} className="me-2" alt="" />
                    Export grid to Excel
                  </button>
                  <button
                    type="button"
                    className="btn btn-success ms-5"
                    onClick={printDocument}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-printer me-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                      <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                    </svg>
                    Print report to PDF
                  </button>
                </div>
                <div className="report-page" ref={reportPage}>
                  <div className="report-header d-flex justify-content-between">
                    <div>
                      <h3>
                        <b>{reportName}</b>
                      </h3>{" "}
                      <br />
                      <p>
                        <b>Report Date: </b>
                        {dateFormmaterNoTime(reportData?.reportDate)}
                      </p>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={reportData?.imageLogo ?? BlueSeedLogo}
                        className="report-logo"
                      ></img>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    {gridColumns.length > 0 && (
                      <AgGrid
                        data={reportData?.groups}
                        columns={gridColumns[0]}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        fileName="Account Sales Detail Report"
                        ref={childFnRef}
                        subTable={gridColumns[1]}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
