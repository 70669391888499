import { useState, useEffect, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AG.scss";
import { GridProps } from "./AG.models";


const BluesAgGrid = (props: GridProps) => {
    const [gridApi, setGridApi] = useState(null);
    //const gridRef = useRef<any>();

    useEffect(() => {
        if (gridApi && props.deSelectItems && props.deSelectItems > 0)
            gridApi.deselectAll();
    }, [props.deSelectItems, gridApi]);

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        if (props.autoSizeColumns) {
            params.columnApi.autoSizeAllColumns();
        }
    };

    const onGridSizeChanged = (params: any) => {
        if (!props.autoColumns) {
            params.api.sizeColumnsToFit();
        }
        else {
            params.columnApi.autoSizeAllColumns();
        }
    }

    const onRowDoubleClicked = () => {
        if (!props.isDisabled) {
            let selectedRows = gridApi.getSelectedRows();
            if (props.onRowDoubleClicked) props.onRowDoubleClicked(selectedRows[0]);
        }
    };

    const OnRowClicked = () => {
        if (!props.isDisabled) {
            let selectedRows = gridApi.getSelectedRows();
            if (props.onRowClicked) props.onRowClicked(selectedRows[0]);
        }
    };

    const onSelectionChanged = (event) => {
        if (!props.isDisabled) {
            const selectedItem = event.api.getSelectedRows();
            if (props.onSelectionChanged) props.onSelectionChanged(selectedItem);
        }
    };

    const onFirstDataRendered = useCallback((params) => {
        if (!props.autoColumns)
            params.api.sizeColumnsToFit();
        else {
            params.columnApi.autoSizeAllColumns();
        }
    }, []);


    //   useEffect(() => {
    //     if (gridApi && props.selectItem)
    //       setTimeout(() => {
    //         gridApi.forEachNode(function (node) {
    //           node.setSelected(node.data.purchaseOrderItemId === props.selectItem.purchaseOrderItemId);
    //         });
    //       });
    //   }, [props.selectItem, gridApi])


    return (
        <div className="ag-grid-container ag-theme-alpine">
            <AgGridReact
                defaultColDef={{
                    editable: false,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    sortable: true,
                    resizable: true,
                }}
                rowHeight={40}
                suppressDragLeaveHidesColumns={true}
                rowSelection={props.rowSelection ? props.rowSelection : "single"}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                onRowDoubleClicked={onRowDoubleClicked}
                onSelectionChanged={onSelectionChanged}
                rowData={props.data}
                columnDefs={props.columns}
                onRowClicked={OnRowClicked}
                onFirstDataRendered={onFirstDataRendered}
                domLayout={props.autoHeight ? 'autoHeight' : 'normal'}
                onCellValueChanged={props.onCellValueChanged}
                stopEditingWhenGridLosesFocus={true}

            ></AgGridReact>
        </div>
    );
};

export default BluesAgGrid;