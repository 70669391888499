import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext, Controller } from "react-hook-form";

import SelectionModal from "components/SelectionModal/SelectionModal";

import {
  vendorsColumns,
  hubsColumns,
} from "components/ItemOrderModal/modalColumns";

import Select from "react-select";
import { states } from "components/data/States";
import Lookup from "elements/Lookup";
import { statusEnum } from "pages/SalesOrder/StatusEnum";
import { DropDownOption } from "components/Common.models";
import {
  formatInput,
  getCurrenciesListFromExchangeRateList,
} from "global/helpers";
import CreatableSelect from "react-select/creatable";
import { useMutation, useQuery } from "react-query";
import {
  getCurrency,
  getExchangeRate,
  getLatestRate,
} from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";

const PoInfo = ({
  data,
  purchaseOrderInit,
  vendorSelected,
  setVendorSelected,
  isDisabled,
  hubSelected,
  setHubSelected,
  setShowShippingModal,
}) => {
  const { id } = useParams();

  const [openSelectionVendor, setOpenSelectionVendor] =
    useState<boolean>(false);

  const [openSelectionHubs, setOpenSelectionHubs] = useState<boolean>(false);

  const creditTermItems: DropDownOption[] = purchaseOrderInit?.creditTerms?.map(
    (c) => {
      const opt: DropDownOption = {
        label: `${c.term}: ${c.description}`,
        value: c.creditTermId,
      };
      return opt;
    }
  );

  const getRate = useMutation(getLatestRate, {
    async onSuccess(data) {
      if (data) setValue("currencyExchangeRate", data);
      else setValue("currencyExchangeRate", null);
    },
  });

  const shippingMethods: DropDownOption[] =
    purchaseOrderInit?.shippingMethods?.map((c) => {
      const opt: DropDownOption = { label: c.name, value: c.shippingMethodId };
      return opt;
    });

  const { data: currencies } = useQuery("currencies", getExchangeRate, {
    select(data) {
      const currencyCodes = data?.map((currency) => ({
        currencyCode: currency?.currencyCode,
      }));
      var currencies = getCurrenciesListFromExchangeRateList(currencyCodes);
      return currencies;
    },
  });

  useEffect(() => {
    if (hubSelected) {
      setOpenSelectionHubs(false);
      setValue(
        "hubId",
        hubSelected.locationId,
        !id && { shouldValidate: true, shouldTouch: true }
      );
    }
  }, [hubSelected]);

  useEffect(() => {
    if (purchaseOrderInit?.hubs?.length === 1) {
      setHubSelected(purchaseOrderInit?.hubs[0]);
    }
  }, [purchaseOrderInit?.hubs]);

  useEffect(() => {
    if (vendorSelected) {
      setOpenSelectionVendor(false);
      setValue("targetId", vendorSelected.vendorId, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [vendorSelected]);

  let modal = <></>;
  if (openSelectionHubs) {
    modal = (
      <SelectionModal
        modal="Hubs"
        showModal={openSelectionHubs}
        setShowModal={setOpenSelectionHubs}
        data={purchaseOrderInit?.hubs}
        columns={hubsColumns}
        setRowClicked={setHubSelected}
      />
    );
  } else if (openSelectionVendor) {
    modal = (
      <SelectionModal
        modal="Vendor"
        showModal={openSelectionVendor}
        setShowModal={setOpenSelectionVendor}
        data={purchaseOrderInit?.vendors}
        columns={vendorsColumns}
        setRowClicked={setVendorSelected}
      />
    );
  }

  const {
    formState: { errors },
    register,
    control,
    setValue,
  } = useFormContext();

  const createLabel = () => <label>Add New</label>;

  const onCreateType = () => {
    setShowShippingModal(true);
  };

  const onCurrencyChange = (e) => {
    if (e) getRate.mutate(e);
    else setValue("currencyExchangeRate", null);
  };

  return (
    <>
      <div className="row">
        <div className="left-side pb-0">
          <div className="row mb-2">
            <div className="left-side-mid">
              <div className="form-group">
                <label className="mb-3">
                  Vendor <span className="text-danger">*</span>
                </label>
                {!id ? (
                  <Lookup
                    onButtonClicked={() => setOpenSelectionVendor(true)}
                    inputName="targetId"
                    isRequired={true}
                    initialData={purchaseOrderInit?.vendors?.map((item) => ({
                      ...item,
                      id: item.vendorId,
                      name: item.vendorNumber,
                    }))}
                    onSelection={setVendorSelected}
                    inputValue={vendorSelected?.vendorNumber}
                    hasError={errors["targetId"] ? true : false}
                  />
                ) : (
                  <p>{data?.vendorNumber}</p>
                )}
                {errors["targetId"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group mb-4">
                <label className="mb-3">
                  Hub <span className="text-danger">*</span>
                </label>
                {!id ? (
                  <Lookup
                    onButtonClicked={() => setOpenSelectionHubs(true)}
                    inputName="hubId"
                    isRequired={true}
                    initialData={purchaseOrderInit?.hubs?.map((item) => ({
                      ...item,
                      id: item.locationId,
                      name: item.hubId,
                    }))}
                    onSelection={setHubSelected}
                    inputValue={hubSelected?.hubId}
                    hasError={errors["hubId"] ? true : false}
                  />
                ) : (
                  <p>{data?.hubKey}</p>
                )}
                {errors["hubId"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="left-side-mid">
              <div className="form-group w-75 mb-4">
                <label>
                  Request Date <span className="text-danger">*</span>
                </label>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="requestDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      onChange={onChange}
                      selected={value ? value : null}
                      className={` ${errors["poDate"] && "required_field"}`}
                      minDate={new Date()}
                    />
                  )}
                />
                {errors["requestDate"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group w-50 mb-4">
                <label>Discount</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text dollar-icon bg-white">
                      $
                    </span>
                  </div>
                  <input
                    {...register("discount")}
                    className="form-control border-start-0"
                    onBlur={(e) =>
                      setValue("discount", formatInput(e.target.value))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="left-side-mid">
              <div className="form-group w-75 mb-4">
                <label>
                  PO Date<span className="text-danger">*</span>
                </label>{" "}
                <br />
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="poDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      onChange={onChange}
                      selected={value ? value : null}
                      className={` ${errors["poDate"] && "required_field"}`}
                      minDate={new Date()}
                    />
                  )}
                />
                {errors["poDate"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group w-50 mb-4">
                <label>Shipping Cost</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text dollar-icon bg-white">
                      $
                    </span>
                  </div>
                  <input
                    {...register("shippingCost")}
                    className="form-control border-start-0"
                    onBlur={(e) =>
                      setValue("shippingCost", formatInput(e.target.value))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="left-side-mid">
              <div className="form-group w-75 mb-4">
                <label>Expected Ship Date</label>
                <Controller
                  control={control}
                  name="expShipDate"
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      onChange={onChange}
                      selected={value ? value : null}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
            </div>
            <div className="right-side-mid">
              {id && (
                <div className="form-group">
                  <label>Status</label>
                  <p>{statusEnum[data?.poState]}</p>
                </div>
              )}
            </div>
          </div>
          <div className="row mb-4">
            <div className="left-side-mid">
              <div className="form-group mb-4">
                <label>Vendor Invoice/PO</label>
                <input {...register("vendorPO")} className="form-control" />
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group mb-4">
                <label>Term</label>
                <Controller
                  name="creditTermId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={creditTermItems}
                      isClearable
                      isSearchable={true}
                      menuPlacement="auto"
                      value={
                        creditTermItems?.find((opt) => opt?.value === value) ||
                        creditTermItems?.find(
                          (opt) => opt?.value === data?.termId
                        ) ||
                        creditTermItems?.find(
                          (opt) => opt?.value === data?.termId
                        ) ||
                        null
                      }
                      onChange={(val: DropDownOption) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="left-side-mid">
              <div className="form-group mb-4">
                <label>
                  Shipping Method <span className="text-danger">*</span>
                </label>
                <Controller
                  name="ShippingMethod"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CreatableSelect
                      options={shippingMethods}
                      isClearable
                      isSearchable={true}
                      menuPlacement="auto"
                      value={value}
                      onChange={onChange}
                      isValidNewOption={() => true}
                      formatCreateLabel={createLabel}
                      createOptionPosition="first"
                      onCreateOption={onCreateType}
                      className={`${
                        errors["ShippingMethod"] && "required_field"
                      }`}
                    />
                  )}
                />
                {errors["ShippingMethod"] && (
                  <p className="text-danger">The Shipping Method is required</p>
                )}
              </div>
            </div>
            <div className="right-side-mid">
              <div className="form-group mb-4">
                <label>Market Code</label>
                <input
                  {...register("marketCode", { maxLength: 2 })}
                  className="form-control"
                />
                {errors["marketCode"] && (
                  <p className="text-danger">The max length should be 2</p>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Currency</label>
                <Controller
                  control={control}
                  name="currencyCode"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption?.value);
                        onCurrencyChange(selectedOption?.value);
                      }}
                      value={
                        currencies?.find((opt) => opt?.value === value) || null
                      }
                      options={currencies}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <label>Exchange Rate</label>
                <input
                  className="form-control"
                  {...register("currencyExchangeRate")}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="form-group mb-2">
              <label>Remarks</label>
              <textarea
                {...register("remarks")}
                className={` ${
                  errors["soDisclaimer"] && "required_field"
                } form-control`}
              />
            </div>
          </div>
        </div>
        <div className="right-side pb-0">
          <div className="section py-2 ">
            <div className="form-group mb-2">
              <label>Company</label>
              <p>
                {id ? (
                  data?.company ?? (
                    <span className="fst-italic text-muted">Company</span>
                  )
                ) : vendorSelected ? (
                  vendorSelected?.company
                ) : (
                  <span className="text-muted fst-italic">Company</span>
                )}
              </p>
            </div>
            <div className="form-group mb-2">
              <label>Contact</label>
              <p>
                {id ? (
                  data?.contact ?? (
                    <span className="fst-italic text-muted">Contact</span>
                  )
                ) : vendorSelected ? (
                  vendorSelected?.email != null ? (
                    vendorSelected?.email
                  ) : (
                    <span className="text-muted fst-italic">Contact</span>
                  )
                ) : (
                  <span className="text-muted fst-italic">Contact</span>
                )}
              </p>
            </div>
            <div className="form-group mb-2">
              <label>Address</label>
              <p>
                {id ? (
                  data?.address ?? (
                    <span className="fst-italic text-muted">Address</span>
                  )
                ) : vendorSelected ? (
                  vendorSelected?.address
                ) : (
                  <span className="text-muted fst-italic">Address</span>
                )}
              </p>
            </div>
            <div className="form-group mb-2">
              <label>Address1</label>
              <p>
                {id ? (
                  data?.address1 ?? (
                    <span className="fst-italic text-muted">Address 1</span>
                  )
                ) : vendorSelected ? (
                  vendorSelected?.address1 != "" ? (
                    vendorSelected?.address1
                  ) : (
                    <span className="text-muted fst-italic">Address 1</span>
                  )
                ) : (
                  <span className="text-muted fst-italic">Address 1</span>
                )}
              </p>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="left-side-mid ps-0">
                  <label>Country</label>
                  <p className="mb-0">
                    {id ? (
                      data?.country ?? (
                        <span className="fst-italic text-muted">Country</span>
                      )
                    ) : vendorSelected ? (
                      vendorSelected?.country
                    ) : (
                      <span className="text-muted fst-italic">Country</span>
                    )}
                  </p>
                </div>
                <div className="right-side-mid">
                  <label>Zip Code</label>
                  <p className="mb-0">
                    {id ? (
                      data?.zipCode ?? (
                        <span className="fst-italic text-muted">Zip Code</span>
                      )
                    ) : vendorSelected ? (
                      vendorSelected?.zipCode
                    ) : (
                      <span className="text-muted fst-italic">Zip Code</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="form-group mb-2">
              <div className="row">
                <div className="left-side-mid ps-0">
                  <label>City</label>
                  <p>
                    {id ? (
                      data?.city ?? (
                        <span className="fst-italic text-muted">City</span>
                      )
                    ) : vendorSelected ? (
                      vendorSelected?.city
                    ) : (
                      <span className="text-muted fst-italic">City</span>
                    )}
                  </p>
                </div>
                <div className="right-side-mid">
                  <label>State</label>
                  <p>
                    {id
                      ? states.find((value) =>
                          value.value === id ? (
                            data?.state
                          ) : vendorSelected ? (
                            vendorSelected?.state
                          ) : (
                            <span className="text-muted fst-italic">State</span>
                          )
                        )?.label
                      : states.find((value) =>
                          value.value === id ? (
                            data?.state
                          ) : vendorSelected ? (
                            vendorSelected?.state
                          ) : (
                            <span className="text-muted fst-italic">State</span>
                          )
                        )?.label}
                  </p>
                </div>
              </div>
            </div>
            <div className="form-group mb-2">
              <label>Work No.</label>
              <p>
                {id ? (
                  data?.workNumber ?? (
                    <span className="fst-italic text-muted">Work No.</span>
                  )
                ) : vendorSelected ? (
                  vendorSelected?.workNumber
                ) : (
                  <span className="text-muted fst-italic">Work No.</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      {modal}
    </>
  );
};

export default PoInfo;
