import axios from "axios";
import api from "components/data/AxiosClient";

export const getBaseCurrency = async () => {
  const response = await api.get(`system-setup/currency-base`);
  return response.data;
};
export const getAltCurrency = async () => {
  const response = await api.get(`system-setup/currency?withBase=false`);
  return response.data;
};

export const getCurrencyCode = (currencySymbol) => {
  switch (currencySymbol) {
    case "USD":
    case "$":
      return "USD";
    case "EUR":
    case "€":
      return "EUR";
    case "JPY":
    case "¥":
      return "JPY";
    case "GBP":
    case "£":
      return "GBP";
    case "AUD":
    case "A$":
      return "AUD";
    case "CAD":
    case "C$":
      return "CAD";
    case "CHF":
      return "CHF";
    case "CNY":
    case "CN¥":
      return "CNY";
    case "HKD":
    case "HK$":
      return "HKD";
    case "NZD":
    case "NZ$":
      return "NZD";
    case "SEK":
      return "SEK";
    case "KRW":
    case "₩":
      return "KRW";
    case "SGD":
    case "S$":
      return "SGD";
    case "NOK":
      return "NOK";
    case "MXN":
    case "MX$":
      return "MXN";
    case "INR":
    case "₹":
      return "INR";
    case "RUB":
      return "RUB";
    case "ZAR":
      return "ZAR";
    case "TRY":
      return "TRY";
    case "BRL":
      return "BRL";
    case "TWD":
    case "NT$":
      return "TWD";
    case "DKK":
      return "DKK";
    case "PLN":
      return "PLN";
    case "THB":
    case "฿":
      return "THB";
    case "IDR":
    case "Rp":
      return "IDR";
    case "HUF":
      return "HUF";
    default:
      return null;
  }
};
