import { useContext, useEffect, useRef, useState } from "react";
import * as AppUrls from "constants/AppUrls";
import addIcon from "img/add-icon.svg";
import {
  checkIfAllowed,
  dateFormmaterNoTime,
  fixTimezoneOffset,
  getCurrencyByCode,
} from "global/helpers";
import StateContext from "context/StateContext";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { GridColumns } from "components/Common.models";
import { getBillPaymentsList } from "./InvoicesServices/InvoicesServices";
import { useReactToPrint } from "react-to-print";
import { ReactComponent as RefreshIcon } from "img/refresh.svg";
import exportIcon from "img/export.svg";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { Controller, FormProvider, useForm } from "react-hook-form";
import DateFilter from "components/DateFilter/DateFilter";
import Select from "react-select";
import { getVendors } from "pages/Vendors/Vendors.services";
import { getBaseCurrency } from "components/currency";

const Dateoptions = [
  { value: 5, label: "This Year" },
  // { value: 6, label: "This Quarter" },
  { value: 7, label: "This Month" },
  { value: 8, label: "This Week" },
  { value: 1, label: "Last Year" },
  { value: 2, label: "Last Quarter" },
  { value: 3, label: "Last Month" },
  { value: 4, label: "Last Week" },
  { value: 9, label: "Year To Date" },
  { value: 10, label: "Custom" },
];

const ReceivePaymentList = ({ match }) => {
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const appState = useContext(StateContext);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    vendorId: null,
  });
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const [dateCategory, setDateCategory] = useState(null);

  const { data: vendorsList } = useQuery("vendorsList", getVendors);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { handleSubmit, control, reset, getValues } = methods;

  const columns: GridColumns[] = [
    {
      field: "receiptNumber",
      headerName: "Receipt No",
    },
    {
      field: "receiveInvoice.invoiceNumber",
      headerName: "Bill No.",
    },
    {
      field: "paymentAmount",
      headerName: "Payment Amount",
      valueGetter: (params) => {
        return `${currencySymbol}${
          params.data?.paymentAmount?.toFixed(2) ?? "0.00"
        }`;
      },
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params?.data?.paymentDate);
      },
    },
  ];

  const receiveInvoices = useMutation(getBillPaymentsList);

  const onRowDoubleClicked = (e) => {
    let url = `/vendors/receive-invoice-payment/${e.receiveInvoicePaymentId}`;
    history.push(url);
  };

  const onRefresh = () => {
    setRefreshCounter((prev) => prev + 1);
  };

  const [dataGrid, setDataGrid] = useState(null);
  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    receiveInvoices.mutate(null, {
      onSuccess(result) {
        setDataGrid(result.data);
      },
    });
  };

  const reportPage = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };

  useEffect(() => {
    if (dataGrid && dataGrid.length > 0) {
      if (receiveInvoices.isSuccess) {
        setIsLoadingGridExport(true);
        setDisabledExportBtn(true);
        setExportToExcelClicked(true);
        let notification = {
          variant: "success",
          msg: "Preparing grid for export...",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  }, [dataGrid, receiveInvoices.isSuccess]);

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  const onSubmit = () => {
    const { startDate, endDate, vendorId } = getValues();
    let newFilters = {
      startDate: startDate ? fixTimezoneOffset(startDate) : null,
      endDate: endDate ? fixTimezoneOffset(endDate) : null,
      vendorId: vendorId ?? null,
    };
    setFilters(newFilters);
  };

  const clearFilters = () => {
    reset();
    setDateCategory(null);
    onSubmit();
  };

  let options = vendorsList?.data.map((vendor) => ({
    label: vendor.vendorNumber,
    value: vendor.vendorId,
  }));

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>Bill Payments list</p>
        <div>
          <button
            className="import-btn"
            type="button"
            onClick={exportToExcel}
            disabled={disabledExportBtn || dataGrid?.length === 0}
          >
            <img src={exportIcon} className="me-2" alt="" />
            Export to Excel
          </button>
          <button
            type="button"
            className="btn btn-success ms-5"
            onClick={printDocument}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-printer me-3"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            Print report to PDF
          </button>
          <button onClick={onRefresh} className="import-btn import-btn-blue">
            <RefreshIcon className="me-2" />
            Refresh
          </button>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link
              to={AppUrls.receive_invoice_payment}
              className="btn btn-primary"
            >
              <img src={addIcon} className="me-2" alt="" /> New Bill Payment
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="filters">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DateFilter
                  dateCategory={dateCategory}
                  setDateCategory={setDateCategory}
                  options={Dateoptions}
                />
                <div className="row">
                  <div className="form-group col-sm-3">
                    <label>User</label>
                    <Controller
                      control={control}
                      name="vendorId"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={options}
                          isClearable
                          isSearchable={true}
                          menuPlacement="auto"
                          value={
                            options?.find((opt) => opt?.value === value) || null
                          }
                          onChange={(val: any) => {
                            onChange(val?.value);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm-3 d-flex align-items-center">
                    <button type="submit" className="btn btn-primary">
                      Display
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={clearFilters}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
          <div ref={reportPage} className="grid">
            <AgGrid
              columns={columns}
              fetchMethod={receiveInvoices}
              data={dataGrid}
              setDataGrid={setDataGrid}
              setrowClicked={onRowDoubleClicked}
              exportToExcel={exportToExcelClicked}
              setExportToExcelClicked={setExportToExcelClicked}
              setDisabledExportBtn={setDisabledExportBtn}
              setIsLoadingGridExport={setIsLoadingGridExport}
              refreshCounter={refreshCounter}
              fileName="Receive payment List Report"
              filters={filters}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivePaymentList;
