import { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import SearchIcon from "img/search-group.png";
import { LookupItem } from "components/Common.models";

type Props = {
    onButtonClicked: () => void,
    inputValue: string,
    initialData: LookupItem[],
    onSelection: Function,
    isDisabled?: boolean
};

const LedgerSelection = ({ onButtonClicked, initialData, onSelection, inputValue, isDisabled }: Props) => {

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [showOptions, setShowOptions] = useState<boolean>(false)

    useEffect(() => {
        if (showOptions === false)
            if (inputValue) {
                setSearchTerm(inputValue)
            } else {
                setSearchTerm("")
            }
    }, [inputValue, showOptions])

    const onOptionClicked = (component) => {
        setShowOptions(false)
        onSelection(component)
    }

    const onSearching = (e) => {
        setSearchTerm(e)
        setShowOptions(true)
    }

    const onBlur = () => {
        setTimeout(() => {
            setShowOptions(false)
        }, 250);
    }


    let searchResults = []

    if (initialData)
        searchResults = initialData.filter(element => element.name?.toLowerCase().startsWith(searchTerm.toLowerCase()))

    return (
        <InputGroup className={`input-lookup`}>
            <input
                className="form-control"
                placeholder="Choose"
                onChange={(e) => onSearching(e.target.value)}
                onBlur={onBlur}
                value={searchTerm}
                disabled={isDisabled}
            />
            <Button
                variant="primary"
                className="input-group-prepend"
                onClick={onButtonClicked}
                disabled={isDisabled}
            >
                <img src={SearchIcon} alt="" />
            </Button>
            {showOptions &&
                <div className='position-absolute top-100 ms-0 mt-1 bg-white w-100 p-1 border lookup-options-container'>
                    {searchResults.length > 0 ?
                        searchResults.slice(0, 5).map(component => (
                            <div key={component.id}>
                                <button type="button" className='btn w-100 bg-white text-start btn-option ms-0' onClick={() => onOptionClicked(component)}>{`${component.name}-${component?.accountName}`}</button>
                            </div>
                        ))
                        :
                        <div className='p-2'>No Results Found </div>
                    }
                </div>
            }
        </InputGroup>
    );
};

export default LedgerSelection;