import { Modal } from "react-bootstrap";
import Close from "img/close.svg";
import { useForm } from "react-hook-form";
import { useContext, useEffect } from "react";
import { addCategory } from "./AccountingServices";
import { useMutation } from "react-query";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";

type Props = {
  showModal: boolean;
  onExit: () => void;
  formValues;
};

const CategoryModal = (props: Props) => {
  const appDispatch = useContext(DispatchContext);

  useEffect(() => {
    if (props.formValues) {
      const { label } = props.formValues;
      setValue("parent", label);
    }
  }, [props.formValues]);

  const addMutation = useMutation(addCategory, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Category added successfully",
      };
      appDispatch({ type: "notification", value: notification });
      closeAndReset();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding category"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      parent: null,
      name: null,
    },
  });

  const closeAndReset = () => {
    reset();
    props.onExit();
  };

  const onSubmit = (data) => {
    addMutation.mutate({ ...data, parentId: props.formValues.categoryId });
  };

  return (
    <Modal show={props.showModal} centered size="lg">
      <Modal.Header>
        <Modal.Title>Add Sub Category</Modal.Title>
        <img
          src={Close}
          alt="close icon"
          className=""
          role="button"
          onClick={closeAndReset}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-11 offset-sm-1">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="h-100 d-flex flex-column"
            >
              <div className="row gy-4 mb-5">
                <div className="col-sm-5">
                  <div className="form-group">
                    <label>Parent Acc</label>
                    <input
                      {...register("parent")}
                      className={"form-control"}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group">
                    <label>
                      Category Name<span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("name", { required: true })}
                      className={
                        errors.name
                          ? "form-control required_field"
                          : "form-control"
                      }
                    />
                  </div>
                  {errors["name"] && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="mt-auto">
                <button type="submit" className="btn btn-primary ms-0">
                  Add Sub Category
                </button>
                <button
                  type="button"
                  onClick={closeAndReset}
                  className="btn btn-outline-primary"
                >
                  {" "}
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CategoryModal;
