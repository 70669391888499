import { useState, useContext, useEffect, useRef } from "react";
import backArrow from "img/back-arrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import * as AppUrls from "constants/AppUrls";
import SoInfo from "./SalesOrdersTabs/SoInfo";
import Badge from "react-bootstrap/Badge";
import { useForm, FormProvider } from "react-hook-form";
import DispatchContext from "context/DispatchContext";
import { useMutation, useQuery } from "react-query";
import {
  createSalesOrder,
  editSalesOrder,
  getSalesOrder,
  voidSalesOrder,
} from "./SalesOrderServices/SalesOrderServices";
import { notificationMessage } from "global/helpers";
import { SalesOrderItem, ShippingDetails } from "../SalesOrder.model";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  CREATE_SUCCESS_MSG,
  LOAD_FAIL_MSG,
  REQUIRED_FIELDS_MSG,
} from "constants/NotificationMsgs";
import SoItemsModel from "./ItemsModel/SoItemsModel";
import ShippingMethodModal from "pages/SystemSetup/ShippingMethodModal";

const CreateSalesOrder = ({ match }) => {
  const isEdit = match.path === AppUrls.change_sales_order;

  const history = useHistory();
  const location = useLocation();
  const appDispatch = useContext(DispatchContext);

  const [addedItems, setAddedItems] = useState<Array<SalesOrderItem>>([]);
  const [addedExpenses, setAddedExpenses] = useState([]);
  const [shippingDetails, setShippingDetails] = useState<ShippingDetails>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [voidConfirmation, setVoidConfirmation] = useState<boolean>(false);
  const [showDiscard, setShowDiscard] = useState<boolean>(false);
  const [orderId, setOrderId] = useState<number>();
  const [hubId, setHubId] = useState<number>();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [orderDate, setOrderDate] = useState<string>();
  const [activeKey, setActiveKey] = useState("SoInfo");
  const [pickupSo, setPickupSo] = useState<boolean>(false);
  const [methodsCounter, setMethodsCounter] = useState<number>(0);
  const [showShippingModal, setShowShippingModal] = useState<boolean>(false);
  const costDetails = useRef();
  const [allowVoid, setAllowVoid] = useState(true);

  useEffect(() => {
    if (location.state) setAllowVoid(location.state?.allowVoid);
  }, [location]);

  const onBackClick = () => {
    if (isDirty) setShowDiscard(true);
    else history.push(AppUrls.sales_order_list);
  };

  const soId = location.state?.soId;
  const {
    data: currentSO,
    isSuccess: isSuccessSO,
    error: errorLoadingSO,
  } = useQuery(["salesOrder", soId], () => getSalesOrder(soId), {
    enabled: !!soId,
    onSuccess: (data) => {
      setAddedExpenses(data.data.salesOrderExpenses);
    },
  });

  useEffect(() => {
    if (errorLoadingSO) {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} Sales Order's data.`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.sales_order_list);
    }
  }, [errorLoadingSO]);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = methods;

  const addSalesOrder = useMutation(createSalesOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Sales Order ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      appDispatch({ type: "loading", value: false });
      history.push(AppUrls.sales_order_list);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding sales order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const updateSalesOrder = useMutation(editSalesOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: "Changes saved successfully",
      };
      appDispatch({ type: "notification", value: notification });
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing sales order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const removeSalesOrder = useMutation(voidSalesOrder, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `SO. ${getValues("orderNumber")} voided successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      onCancel();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem voiding sales order"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: addSalesOrder.isLoading || updateSalesOrder.isLoading,
    });
  }, [addSalesOrder.isLoading, updateSalesOrder.isLoading]);

  useEffect(() => {
    onCancel();
  }, [isEdit]);

  const onCancel = () => {
    reset();
    setShippingDetails(null);
    setOrderDate(null);
    setCustomerDetails(null);
    setAddedItems([]);
    setAddedExpenses([]);
    setActiveKey("SoInfo");
    setValue("accountNumber", "");
    setValue("orderNumber", "");
    setValue("representative", "");
    setValue("shipTo", "");
    setValue("onHold", false);
    setValue("projectNumber", "");
    setValue("customerPO", "");
    setValue("warrantyLevel", "");
    setValue("remarks", "");
    setValue("requestDate", null);
    setValue("shippingMethodId", "");
    setValue("fmrDate", null);
    setPickupSo(false);
  };

  const onVoid = () => {
    setVoidConfirmation(true);
  };

  const submitVoid = () => {
    removeSalesOrder.mutate(orderId);
    setVoidConfirmation(false);
  };

  const onSubmit = (values) => {
    if (addedItems.length === 0) {
      let notification = {
        variant: "danger",
        msg: "Please add items to create sales order",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      let addedExpensesForDto = addedExpenses.map((expense) => ({
        expenseItemId: expense.expenseItemId,
        amount: expense.amount,
      }));
      let newData = {
        ...values,
        origin: 1,
        warrantyLevel:
          values?.warrantyLevel === "" ? null : values?.warrantyLevel,
        shippingMethodId: values?.shippingMethodId?.value,
        toPick: pickupSo,
        salesOrderExpenses: addedExpensesForDto,
        itemOrders: addedItems.map((item) => {
          let object;
          object = {
            itemHubId: item["itemHubId"],
            itemId: item["itemId"], // Include ItemId
            description: item["itemDescription"],
            quantity: item["orderQty"],
            price: parseFloat(item.orderPrice?.toString().replace(/,/g, "")),
          };
          if (item["orderWarranty"])
            object = { ...object, warrantyDays: item["orderWarranty"] };
          return object;
        }),
      };
      if (isEdit) {
        let data = { ...newData, id: orderId, date: orderDate };
        updateSalesOrder.mutate(data);
      } else {
        newData = {
          ...newData,
          date: new Date(),
        };
        addSalesOrder.mutate(newData);
      }
    }
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const dirtyForm = () => {
    setValue("dirtyFlag", "1", { shouldDirty: true });
  };

  const onTypeCreated = (method) => {
    setValue("shippingMethodId", method, { shouldDirty: true });
    setShowShippingModal(false);
    setMethodsCounter((counter) => counter + 1);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            {isEdit ? (
              <div>Change/Void Sales Order</div>
            ) : (
              <div className="back-btn " onClick={onBackClick}>
                <img src={backArrow} alt="back arrow" />
                Creating Sales Order
              </div>
            )}
            <div className="d-flex justify-content-between ">
              {!isEdit && (
                <button
                  type="button"
                  className={`btn btn-outline-primary no-border ${
                    !isDirty && "text-muted"
                  }`}
                  onClick={onCancel}
                  disabled={!isDirty}
                >
                  Reset
                </button>
              )}
              {isEdit && allowVoid && (
                <button
                  type="button"
                  className={`btn btn-danger`}
                  onClick={onVoid}
                  disabled={!isDirty}
                >
                  Void
                </button>
              )}
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty}
              >
                {isEdit ? "Save Changes" : "Create"}
              </button>
            </div>
          </div>

          <div className="page-content-wrapper">
            <div className="page-content">
              <Tabs activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
                <Tab
                  eventKey="SoInfo"
                  title={<div className="tab-inner-title">SO Info</div>}
                >
                  <SoInfo
                    shippingDetails={shippingDetails}
                    setShippingDetails={setShippingDetails}
                    isDisabled={isDisabled}
                    isEdit={isEdit}
                    setIsDisabled={setIsDisabled}
                    setAddedItems={setAddedItems}
                    setOrderId={setOrderId}
                    orderDate={orderDate}
                    setOrderDate={setOrderDate}
                    setHubId={setHubId}
                    addedItems={addedItems}
                    customerDetails={customerDetails}
                    setCustomerDetails={setCustomerDetails}
                    pickupSo={pickupSo}
                    setPickupSo={setPickupSo}
                    costDetails={costDetails}
                    methodsCounter={methodsCounter}
                    setShowShippingModal={setShowShippingModal}
                    allowVoid={setAllowVoid}
                    setAllowVoid={setAllowVoid}
                  />
                </Tab>
                <Tab
                  eventKey="SoItems"
                  title={
                    <div className="tab-inner-title">
                      SO items
                      <Badge pill className="text-secondary ms-2">
                        {addedItems?.length}
                      </Badge>
                    </div>
                  }
                >
                  <SoItemsModel
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    hubId={hubId}
                    canEdit={true}
                    isSo={true}
                    dirtyForm={dirtyForm}
                    customerDetails={customerDetails}
                    costDetails={costDetails}
                    addedExpenses={addedExpenses}
                    setAddedExpenses={setAddedExpenses}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <ShippingMethodModal
        showModal={showShippingModal}
        onClose={() => setShowShippingModal(false)}
        onConfirm={onTypeCreated}
      />
      <ConfirmationModal
        title="Void Sales Order"
        message={
          <>
            Void sales order <strong>{getValues("orderNumber")}</strong>? <br />
            Once you void a Sales Order, you cannot unvoid it.
          </>
        }
        showModal={voidConfirmation}
        onClose={() => setVoidConfirmation(false)}
        onConfirm={() => submitVoid()}
        cancelBtnTitle="Cancel"
        confirmBtnTitle="Void"
        type="confirmation-danger"
      />
      <ConfirmationModal
        title="Discarding"
        message="Are you sure you want to leave?"
        showModal={showDiscard}
        onClose={() => setShowDiscard(false)}
        onConfirm={() => {
          setShowDiscard(false);
          history.push(AppUrls.sales_order_list);
        }}
        cancelBtnTitle="No, stay"
        confirmBtnTitle="Yes"
        type="confirmation-danger"
      />
    </>
  );
};

export default CreateSalesOrder;
