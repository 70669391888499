import { useContext, useState } from "react";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { formatNumber, notificationMessage } from "global/helpers";

import {
  addBillRma,
  approveVendorRma,
  creditVendorRma,
  getVendorRmaDetails,
  refundVendorRma,
  rejectRma,
  voidRma,
} from "./RmaServices/RmaServices";
import AgGrid from "elements/AgGrid";
import DispatchContext from "context/DispatchContext";
import { getReceiveInvoices } from "pages/Invoices/InvoicesServices/InvoicesServices";
import DepositModal from "./DepositModal";

enum RMAStatus {
  Open = 1,
  Voided = 2,
  "Partially Shipped" = 3,
  Shipped = 4,
  Credited = 5,
  Rejected = 6,
  Approved = 7,
  Refunded = 8,
}

const ViewVendorRma = () => {
  const history = useHistory();
  const { id } = useParams();
  const appDispatch = useContext(DispatchContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onBackClick = () => {
    history.push(AppUrls.vendor_rma);
  };

  const { data } = useQuery([id], getVendorRmaDetails);

  const dataMutation = useMutation(getReceiveInvoices, {
    onSuccess(res) {
      let found = res.data?.find(
        (invoice) => invoice.invoiceNumber === data?.purchaseOrderNumber
      );
      if (found)
        invoiceMutation.mutate({
          rmaId: data?.rmaId,
          billId: found?.receiveInvoiceId,
        });
      else {
        let notification = {
          variant: "danger",
          msg: "No bill found for this RMA",
        };
        appDispatch({ type: "notification", value: notification });
      }
    },
  });

  const approveMutation = useMutation(approveVendorRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA approved successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem appproving rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const invoiceMutation = useMutation(addBillRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Invoice added successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding invoice"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const rejectMutation = useMutation(rejectRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA rejected successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem rejecting rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const voidMutation = useMutation(voidRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA voided successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem voiding rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const refundMutation = useMutation(refundVendorRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA refunded successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem refunding rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const creditMutation = useMutation(creditVendorRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA crredited successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.vendor_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem crediting rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const columns = [
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "itemDescription",
      headerName: "Item Description",
    },
    {
      field: "quantity",
      headerName: "RMA QTY",
    },
    {
      field: "cost",
      headerName: "Cost",
      valueGetter: (params) => {
        return `$${params.data?.cost?.toFixed(2) ?? "0.00"}`;
      },
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      cellRendererFramework: (params) =>
        formatNumber(params.data.quantity * params.data.cost),
    },
  ];

  const onApprove = () => {
    approveMutation.mutate(id);
  };

  const onReject = () => {
    rejectMutation.mutate(id);
  };

  const onVoid = () => {
    voidMutation.mutate(id);
  };

  const onRefund = () => {
    setShowModal(true);
  };

  const onRefundConfirm = (refundId: number) => {
    setShowModal(false);
    refundMutation.mutate({ rmaId: id, refundTo: refundId });
  };

  const onCredit = () => {
    creditMutation.mutate(id);
    onShipRma();
  };

  const onAddInvoice = () => {
    dataMutation.mutate({});
  };

  const onShipRma = () => {
    history.push({
      pathname: AppUrls.ship_vendor_rma,
      search: `?rmaId=${id}`,
    });
  };

  return (
    <>
      <div className="static-page">
        <div className="page-title page-title-editable">
          <div className="back-btn " onClick={onBackClick}>
            <img src={backArrow} alt="back arrow" />
            {data?.vendorRmaNumber}
          </div>
          <div>
            {data?.status === 1 && (
              <>
                <button className="btn btn-danger" onClick={onReject}>
                  Reject
                </button>
                <button className="btn btn-success" onClick={onApprove}>
                  Approve
                </button>
              </>
            )}
            {(data?.status === 7 || data?.status === 4) && (
              <button className="btn btn-danger" onClick={onVoid}>
                Void
              </button>
            )}
            {data?.status === 7 && (
              <button className="btn btn-success" onClick={onShipRma}>
                Ship Items
              </button>
            )}
            {data?.status === 4 &&
              (data?.billNumber === null ? (
                <button className="btn btn-primary" onClick={onAddInvoice}>
                  Add Bill
                </button>
              ) : (
                <>
                  <button className="btn btn-primary" onClick={onRefund}>
                    Refund
                  </button>
                  <button className="btn btn-primary" onClick={onCredit}>
                    Credit
                  </button>
                </>
              ))}
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row  gx-5">
              <div className="col-lg-8 col-sm-12">
                <div className="row gx-5 gy-4">
                  <div className="col-lg-6 col-sm-12">
                    <label>RMA No.</label>
                    <div>{data?.vendorRmaNumber}</div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label>RMA Date</label>
                    <div>
                      {data?.vendorRMADate &&
                        new Date(data?.vendorRMADate).toLocaleString()}
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Purchase Order No.</label>
                    <div>{data?.purchaseOrderNumber}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Bill No.</label>
                    <div>{data?.billNumber}</div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label>Hub ID</label>
                    <div>{data?.hubKey}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Vendor No.</label>
                    <div>{data?.vendorNumber}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Company</label>
                    <div>{data?.shippingInfo?.company}</div>
                  </div>
                  <div className="col-lg-3 col-sm-12">
                    <label>Status</label>
                    <div>{RMAStatus[data?.status]}</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="row mt-4">
                  <div className="col-lg-6 col-sm-12 px-4">
                    <div className="d-flex">
                      <div className="text-secondary">TOTAL QTY</div>
                      <div className="flex-grow-1 text-center">
                        {data?.totalQuantity}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="d-flex">
                      <div className="text-secondary">TOTAL ITEMS</div>
                      <div className="flex-grow-1 text-center">
                        {data?.totalItems}
                      </div>
                    </div>
                  </div>
                  <div className="px-4">
                    <hr />
                  </div>
                  <div className="col-sm-12">
                    <div className="section mt-3 p-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="fs-5">RMA TOTAL</div>
                        <div className="text-success fs-4">
                          {formatNumber(data?.amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-450 mt-5">
              <AgGrid data={data?.items} columns={columns} />
            </div>
          </div>
        </div>
      </div>
      <DepositModal
        showModal={showModal}
        onExit={() => setShowModal(false)}
        onCreation={onRefundConfirm}
      />
    </>
  );
};

export default ViewVendorRma;
