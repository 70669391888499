import React from "react";
import { useFormContext } from "react-hook-form";

type Props = {
  nameTitle: string;
  btnTitle: string;
  cancelBtnTitle: string;
  isColorInput?: boolean;
  resetData: () => void;
};

const BaseInvSetupItem = (props: Props) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <>
      <div className="row m-3">
        <div className="col-4">
          <label>
            {props.nameTitle} <span className="text-danger">*</span>
          </label>
          <input
            {...register("name", { required: true, maxLength: 15 })}
            type={props.isColorInput ? "color" : "text"}
            className={`form-control ${errors["name"] && "required_field"} ${
              props.isColorInput && "w-25"
            }`}
          />
          {errors.name && errors.name.type === "maxLength" && (
            <p className="text-danger">
              Max length for name value must not exceed 15 characters.
            </p>
          )}
          {errors.name && errors.name.type === "required" && (
            <p className="text-danger">This field is required</p>
          )}
        </div>
        <div className="col-4">
          <label>
            Description <span className="text-danger">*</span>
          </label>
          <input
            {...register("description", { required: true })}
            className={
              errors["description"]
                ? "form-control required_field"
                : "form-control"
            }
          />
          {errors.description && errors.description.type === "required" && (
            <p className="text-danger">This field is required</p>
          )}
        </div>
      </div>
    </>
  );
};

export default BaseInvSetupItem;
