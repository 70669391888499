import { useState, useContext, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";

import { useMutation, useQuery } from "react-query";
import addIcon from "img/add-icon.svg";
import Select from "react-select";
import * as AppUrls from "constants/AppUrls";
import {
  checkIfAllowed,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { dateFormmaterNoTime } from "global/helpers";
import StateContext from "context/StateContext";
import { DropDownOption } from "components/Common.models";
import AgGrid from "elements/AgGrid";
import { getRmaList } from "./RmaServices/RmaServices";
import { getBaseCurrency } from "components/currency";

const statusOptions = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Approved" },
  { value: 3, label: "Rejected" },
  { value: 4, label: "Voided" },
  { value: 5, label: "Closed" },
  { value: 6, label: "Received" },
  { value: 7, label: "Credited" },
  { value: 8, label: "Refunded" },
  { value: 9, label: "Partially Received" },
];

const selectOriginOptions = [
  { label: "All", value: 0 },
  { label: "System", value: 1 },
  { label: "Web", value: 2 },
];

enum RMAStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Voided = 4,
  Closed = 5,
  Received = 6,
  Credited = 7,
  Refunded = 8,
  "Partially Received" = 9,
}

enum Origin {
  System = 1,
  Web = 2,
}

const CustomerRma = ({ match }) => {
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const appState = useContext(StateContext);

  const [status, setStatus] = useState<DropDownOption>(null);
  const [selectedOrigin, setSelectedOrigin] = useState<number>(0);
  const [filters, setFilters] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  let history = useHistory();

  const cancelForm = () => {
    setStatus(null);
    setSelectedOrigin(0);
    setFilters(null);
  };

  const dataMutation = useMutation(getRmaList);

  const onRowClick = (e) => {
    let url = `${AppUrls.customer_rma}/${e.rmaId}`;
    history.push(url);
  };

  const columns = [
    {
      field: "rmaNumber",
      headerName: "RMA No.",
    },
    {
      field: "customerNumber",
      headerName: "Customer No.",
    },
    {
      field: "orderNumber",
      headerName: "So No.",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => RMAStatus[params.value],
    },
    {
      field: "rmaDate",
      headerName: "RMA Date",
      cellRenderer: (params) => dateFormmaterNoTime(params.value),
    },
    {
      field: "hubKey",
      headerName: "Hub ID",
    },
    {
      field: "amount",
      headerName: "RMA Amount",
      cellRenderer: (params) => formatNumber(params.value, currencySymbol),
    },
    {
      field: "origin",
      headerName: "Origin",
      cellRenderer: (params) => Origin[params.value],
    },
  ];

  const onStatusChange = (e) => {
    setStatus(e);
    setFilters((filters) => ({ ...filters, status: e?.value ?? null }));
  };

  const onSelectChange = (e) => {
    let value = Number(e.value);
    setSelectedOrigin(value);
    if (value && value > 0) {
      setFilters((filters) => ({ ...filters, origin: value }));
    } else {
      setFilters((filters) => ({ ...filters, origin: null }));
    }
  };

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilters((filters) => ({
      ...filters,
      params: params,
    }));
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="grid-page">
      <div className="page-title page-title-editable">
        <p>List of RMA</p>
        <div>
          {checkIfAllowed(appState.allowedUrls, "add", match.path) && (
            <Link to={AppUrls.create_customer_rma} className="btn btn-primary">
              <img src={addIcon} className="me-2" alt="" /> New RMA
            </Link>
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="grid-container has-filters">
          <div className="d-flex align-items-start">
            <div className="form-group">
              <label className="me-2">Search</label>
              <div className="page-search">
                <div className="input-group">
                  <span className="page-search-icon">
                    <i className="fa fa-search"></i>
                  </span>
                  <input
                    className="page-search-input ps-5 me-4 w-200"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </div>
              </div>
            </div>

            <form>
              <div className="row mb-4" style={{ width: "1000px" }}>
                <div className="col-sm-2">
                  <label>Origin</label>
                  <Select
                    value={selectOriginOptions.find(
                      (option) => option.value === selectedOrigin
                    )}
                    onChange={onSelectChange}
                    options={selectOriginOptions}
                  />
                </div>
                <div className="col-sm-2">
                  <label className="label me-3">Status</label>
                  <Select
                    options={statusOptions}
                    isSearchable={true}
                    menuPlacement="auto"
                    value={status}
                    onChange={onStatusChange}
                    isClearable
                  />
                </div>
                <div className="col pt-4 mt-1">
                  <button
                    type="button"
                    className="btn btn-outline-primary custom-outline"
                    onClick={cancelForm}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="grid">
            <AgGrid
              columns={columns}
              fetchMethod={dataMutation}
              filters={filters}
              setrowClicked={onRowClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerRma;
