import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { BalanceSheetAPI, getCategories } from "./AccountingServices";
import "./Ledgers.scss";

import { useReactToPrint } from "react-to-print";

import Accordion from "react-bootstrap/Accordion";
import { dateFormmaterNoTime, getCurrencyByCode } from "global/helpers";

import { useHistory } from "react-router-dom";
import backArrow from "img/back-arrow.svg";
import { getBaseCurrency } from "components/currency";

const BalanceSheetReport = () => {
  const history = useHistory();

  const [data, setData] = useState([]);

  const MutationMethod = useMutation(BalanceSheetAPI, {
    onSuccess(data) {
      setData(data);
    },
  });

  useEffect(() => {
    MutationMethod.mutate();
  }, []);

  const accordionDiv = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => accordionDiv.current,
  });

  const PrintReport = () => {
    handlePrint();
  };
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const { data: baseCurrency } = useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const displayAccordion = (category) => {
    if (category.children === null) return;
    return (
      <>
        <Accordion
          defaultActiveKey={data?.length > 0 && data[0].categoryId}
          flush
        >
          <Accordion.Item eventKey={category.categoryId}>
            {category.children.length > 0 ? (
              <>
                <Accordion.Header>
                  <div className="w-100 d-flex justify-content-between pe-3 ps-4">
                    <p>{category.name}</p>
                    <p className="balance">
                      {" "}
                      {category.balance} {currencySymbol}{" "}
                    </p>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {category.children.map((categoryChild) =>
                    displayAccordion(categoryChild)
                  )}
                  {category.children.length > 0 && (
                    <div className="w-100 d-flex justify-content-between pe-4 ps-4   pt-2 border-top">
                      <p>
                        <b>Total {category.name}</b>
                      </p>
                      <p>
                        <b>
                          {category.balance} {currencySymbol}
                        </b>
                      </p>
                    </div>
                  )}
                </Accordion.Body>
              </>
            ) : (
              <>
                <Accordion.Header className="custom-header">
                  <div className="w-100 d-flex justify-content-between pe-3 ps-4">
                    <p>{category.name}</p>
                    <p>
                      {category.parentId &&
                        `${category.balance} ` + currencySymbol}
                    </p>
                  </div>
                </Accordion.Header>
              </>
            )}
          </Accordion.Item>
        </Accordion>
      </>
    );
  };

  const onBackClick = () => {
    history.push("/reports");
  };

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <div className="back-btn " onClick={onBackClick}>
          <img src={backArrow} alt="back arrow" />
          Balance Sheet Report
        </div>
        <div>
          <button
            type="button"
            className="btn btn-success ms-0"
            onClick={() => PrintReport()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-printer me-3"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            Print report to PDF
          </button>
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="container w-75 report-paper custom-report">
            <div className="container w-100 pt-5" ref={accordionDiv}>
              <div className="text-center">
                <h3 className="mt-0">
                  Balance Sheet <br /> as of {dateFormmaterNoTime(new Date())}
                </h3>
              </div>
              <Accordion.Header className="custom-header">
                <div
                  className="w-100 border-bottom px-0 py-2"
                  style={{ display: "grid", gridTemplateColumns: `120fr 1fr` }}
                >
                  <p></p>
                  <p>
                    <b>TOTAL</b>
                  </p>
                </div>
              </Accordion.Header>
              {data?.map((category) => {
                return displayAccordion(category);
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheetReport;
