import AddNote from "./AddNote";
import noteLight from "img/ph_note-light.svg";
import NoteItem from "./NoteItem";
import "./notes.scss";

const Notes = (props) => {
  return (
    <div className="row h-100">
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <div className="scrollable-div">
          {props.notes?.length !== 0 ? (
            props.notes?.map((item) => {
              return (
                <div key={item.noteId || item.id}>
                  <NoteItem
                    note={item}
                    onNoteDelete={props.onNoteDelete}
                    onNoteUpdate={props.onNoteUpdate}
                    isDisabled={props.isDisabled}
                  />
                </div>
              );
            })
          ) : (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div className="d-block text-center">
                <img src={noteLight} alt="" />
                <div className="mt-1 paragraph-txt">
                  This account does not have notes.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <AddNote onNoteAdd={props.onNoteAdd} isDisabled={props.isDisabled} />
      </div>
    </div>
  );
};

export default Notes;
