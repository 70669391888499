import api from "components/data/AxiosClient";

// Operations

export const getOperations = async (params) => {
  const response = await api.post(
    `accounting/operations${
      params.PageSize
        ? `?PageSize=${params.PageSize}&PageStart=${
            isNaN(params.PageStart) ? 0 : params.PageStart
          }`
        : ""
    }&&searchQuery=${params.searchQuery ?? ""}`,
    params
  );
  return response.data;
};

export const getJournals = async (params) => {
  const response = await api.post(
    `accounting/journals${
      params.PageSize
        ? `?PageSize=${params.PageSize}&PageStart=${
            isNaN(params.PageStart) ? 0 : params.PageStart
          }`
        : ""
    }`,
    params
  );
  return response.data;
};

export const getOperationDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`accounting/operations/${queryId}`);
  return response.data;
};

export const addOperation = async (data) => {
  const response = await api.post("accounting/operations/new", data);
  return response.data;
};

export const editOperation = async (data) => {
  const response = await api.put(`accounting/operations/${data.id}`, data);
  return response.data;
};

export const getCostCenters = async () => {
  const response = await api.get("accounting/journals/cost-center");
  return response.data;
};

export const postOp = async (id) => {
  const response = await api.post(`accounting/operations/${id}/post`);
  return response.data;
};

export const unpostOp = async (id) => {
  const response = await api.post(`accounting/operations/${id}/unpost`);
  return response.data;
};

// Ledgers

export const getLedgers = async (data = null) => {
  const response = await api.get(
    `accounting/ledgers${
      data.ledgerType ? `?ledgerType=${data.ledgerType}` : ""
    }`
  );
  return response.data;
};

export const addLedger = async (data) => {
  const response = await api.post("accounting/ledgers", data);
  return response.data;
};

export const editLedger = async (data) => {
  const response = await api.put(
    `accounting/ledgers/${data.ledgerId}?ledgerId=${data.ledgerId}`,
    data
  );
  return response.data;
};

export const getCategories = async () => {
  const response = await api.get("accounting/categories");
  return response.data;
};

export const splitCategories = async (data) => {
  const response = await api.post("accounting/categories/split", data);
  return response.data;
};

export const importCategories = async (data) => {
  const response = await api.post("accounting/categories/import", data);
  return response.data;
};

export const BalanceSheetAPI = async () => {
  const response = await api.get("accounting/balance-sheet");
  return response.data;
};

export const CompBalanceSheetAPI = async (params) => {
  const response = await api.get(
    `accounting/comparative-balance-sheet?endDate=${params.endDate}`
  );
  return response.data;
};

export const TrailBalanceAPI = async (params) => {
  const response = await api.get(
    `accounting/trial-balance?startDate=${params.startDate}&endDate=${
      params.endDate
    }&groupBy=${params.groupBy ?? 1}`
  );
  return response.data;
};

export const addCategory = async (params) => {
  const response = await api.post("accounting/categories", params);
  return response.data;
};

export const removeCategory = async (id) => {
  const response = await api.delete(`accounting/categories/${id}`);
  return response.data;
};

export const getLeafs = async (data = null) => {
  const response = await api.get(
    `accounting/categories/leaf${
      data.ledgerType ? `?ledgerType=${data.ledgerType}` : ""
    }`
  );
  return response.data;
};

export const getProfitAndLoss = async (params) => {
  let extra = "";
  if (params.customerId) extra += `&customerId=${params.customerId}`;
  if (params.hubId) extra += `&hubId=${params.hubId}`;
  if (params.percent) extra += `&withPercent=${params.percent}`;
  const response = await api.get(
    `accounting/profit-loss?startDate=${params.startDate}&endDate=${params.endDate}${extra}`
  );
  return response.data;
};

export const getProfitAndLossGrouped = async (params) => {
  let extra = "";
  if (params.percent) extra += `&withPercent=${params.percent}`;
  const response = await api.get(
    `accounting/profit-loss-grouped/${params.groupingElement}?startDate=${params.startDate}&endDate=${params.endDate}${extra}`
  );
  return response.data;
};

export const getCompProfitAndLoss = async (params) => {
  const response = await api.get(
    `accounting/comparative-profit-loss?startDate=${params.startDate}&endDate=${params.endDate}`
  );
  return response.data;
};

export const getJournalsReport = async (params) => {
  const response = await api.post("Report/accounting/journals", params);
  return response.data;
};
// System Operations

export const getSystemOp = async () => {
  const response = await api.get("accounting/system-operations");
  return response.data;
};

export const createOp = async (data) => {
  const response = await api.post(`accounting/system-operations`, data);
  return response.data;
};

export const editOp = async (data) => {
  const response = await api.put(
    `accounting/system-operations/${data.id}`,
    data
  );
  return response.data;
};

export const deleteOp = async (id) => {
  const response = await api.delete(`accounting/system-operations/${id}`);
  return response.data;
};

// Expenses

export const getExpenses = async (params) => {
  const url = `accounting/expenses${
    params.PageSize
      ? `?PageSize=${params.PageSize}&PageStart=${
          isNaN(params.PageStart) ? 0 : params.PageStart
        }`
      : ""
  }${params.params && params.params !== "" ? `&${params.params}` : ""}`;

  const response = await api.get(url);
  return response.data;
};

export const getExpenseDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`accounting/expenses/${queryId}`);
  return response.data;
};

export const addExpenses = async (data) => {
  const response = await api.post("accounting/expenses", data);
  return response.data;
};

export const addAssets = async (data) => {
  const response = await api.post("accounting/fixed-assets", data);
  return response.data;
};

export const addIncome = async (data) => {
  const response = await api.post("accounting/interest-income", data);
  return response.data;
};

export const editExpenses = async (data) => {
  const response = await api.put(`accounting/expenses/${data.id}`, data);
  return response.data;
};

export const addCheque = async (data) => {
  const response = await api.post("accounting/cheques", data);
  return response.data;
};

export const editCheque = async (data) => {
  const response = await api.put(`accounting/cheques/${data.id}`, data);
  return response.data;
};

export const getCheques = async (params) => {
  const response = await api.get(
    `accounting/cheques${
      params.PageSize
        ? `?PageSize=${params.PageSize}&PageStart=${
            isNaN(params.PageStart) ? 0 : params.PageStart
          }`
        : ""
    }${params.params && params.params !== "" ? `&${params.params}` : ""}`
  );
  return response.data;
};

export const getChequeDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`accounting/cheques/${queryId}`);
  return response.data;
};

export const getItemExpenses = async (params) => {
  const response = await api.get(
    `accounting/item-expenses${
      params.PageSize
        ? `?PageSize=${params.PageSize}&PageStart=${
            isNaN(params.PageStart) ? 0 : params.PageStart
          }`
        : ""
    }${params.params && params.params !== "" ? `&${params.params}` : ""}`
  );
  return response.data;
};

export const addItemExpense = async (data) => {
  const response = await api.post("accounting/item-expenses", data);
  return response.data;
};

export const editItemExpense = async (data) => {
  const response = await api.put(`accounting/item-expenses/${data.id}`, data.body);
  return response.data;
};

export const getItemExpenseDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`accounting/item-expenses/${queryId}`);
  return response.data;
};

export const deleteItemExpense = async (id: number) => {
  const response = await api.delete(`accounting/item-expenses/${id}`);
  return response.data;
};

export const getCostingMethod = async () => {
  const response = await api.get("accounting/costing-method-category");
  return response.data;
};

export const updateCostingMethod = async (data) => {
  const response = await api.post("accounting/costing-method-category", data);
  return response.data;
};

export const getFiscalYear = async () => {
  const response = await api.get(`accounting/config`);
  return response.data;
};

export const updateFiscalYear = async (data) => {
  const response = await api.post("accounting/config", data);
  return response.data;
};

// Banks

export const getBanksTypes = async () => {
  const response = await api.get(`accounting/bank-type`);
  return response.data;
};

export const createBankType = async (data) => {
  const response = await api.post(`accounting/bank-type`, data);
  return response.data;
};

export const editBankType = async (data) => {
  const response = await api.put(
    `accounting/bank-type/${data.bankTypeId}`,
    data
  );
  return response.data;
};

export const getBanks = async (params) => {
  const response = await api.get(
    `accounting/bank${
      params.PageSize
        ? `?PageSize=${params.PageSize}&PageStart=${
            isNaN(params.PageStart) ? 0 : params.PageStart
          }`
        : ""
    }${params.params && params.params !== "" ? `&${params.params}` : ""}`
  );
  return response.data;
};

export const addBank = async (params) => {
  const response = await api.post(`accounting/bank`, params);
  return response.data;
};

export const editBank = async (data) => {
  const response = await api.put(`accounting/bank/${data.bankId}`, data);
  return response.data;
};

export const getBankDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`accounting/bank/${queryId}`);
  return response.data;
};

export const getReconciliation = async (params) => {
  const response = await api.get(
    `accounting/bank-reconcilation/${params.bankId}?endingDate=${params.endingDate}`
  );
  return response.data;
};

export const getJournalId = async (id) => {
  const response = await api.get(`accounting/fixed-asset-journal/${id}`);
  return response.data;
};

export const postReconciliation = async (params) => {
  const response = await api.post(`accounting/bank-reconcilation`, params);
  return response.data;
};

export const partialReconciliation = async (params) => {
  const response = await api.post(`accounting/partial-reconcile`, params);
  return response.data;
};

export const getPartialReconciliation = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(`accounting/partial-reconcile/${queryId}`);
  return response.data;
};

export const getLogs = async () => {
  const response = await api.get(`accounting/bank-reconcilation/logs`);
  return response.data;
};

export const getLogDetails = async (id) => {
  let queryId = id.queryKey[0];
  const response = await api.get(
    `accounting/bank-reconcilation/logs/${queryId}`
  );
  return response.data;
};

export const getExpensesReport = async (params) => {
  const response = await api.get(
    `accounting/expense-report${
      params.groupingElement ? `/${params.groupingElement}` : ""
    }${params.extraParams}`
  );
  return response.data;
};

// Transactions

export const getTransactions = async (params) => {
  const response = await api.get(`accounting/recurring-transaction${params}`);
  return response.data;
};

export const addTransaction = async (params) => {
  const response = await api.post(`accounting/recurring-transaction`, params);
  return response.data;
};

export const fixedAssetsAPI = async (params) => {
  const response = await api.get(
    `accounting/fixed-assets${
      params.PageSize
        ? `?pageSize=${params.PageSize}&pageStart=${
            isNaN(params.PageStart) ? 0 : params.PageStart
          }`
        : ""
    }${params.params && params.params !== "" ? `&${params.params}` : ""}`
  );
  return response.data;
};

export const AddfixedAssetsAPI = async (data) => {
  const response = await api.post(`accounting/fixed-assets`, data);
  return response.data;
};
