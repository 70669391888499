import { StateModel, states } from "components/data/States";
import SectionTitle from "elements/SectionTitle";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { VendorModelDTO } from "../Vendors.models";
import CountrySelect from "components/CountrySelect/CountrySelect";

type Props = {
  vendorData: VendorModelDTO;
  isDisabled: boolean;
};

const GeneralInfoTab = (props: Props) => {
  const [copyRemitAddress, setCopyRemitAddress] = useState<boolean>(false);

  useEffect(() => {
    if (props.isDisabled) {
      setCopyRemitAddress(false);
    }
  }, [props.isDisabled]);

  const vendorData: VendorModelDTO = props.vendorData;
  const statesOptions: StateModel[] = states;
  const [selectedVendorCountry, setSelectedVendorCountry] = useState(
    vendorData?.country || ""
  );
  const [selectedRemitCountry, setSelectedRemitCountry] = useState(
    vendorData?.country || ""
  );
  const {
    formState: { errors },
    control,
    register,
    setValue,
    getValues,
  } = useFormContext<VendorModelDTO>();

  const onSameInfoChecked = (e) => {
    setCopyRemitAddress(e.target.checked);
    if (e.target.checked) {
      copyAddress(true);
    }
  };
  const copyAddress = (copy: boolean) => {
    if (copy) {
      setValue("remitContact", getValues("contact"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitWorkNumber", getValues("workNumber"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitEmail", getValues("email"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitAddress", getValues("address"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitAddress1", getValues("address1"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitCity", getValues("city"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitState", getValues("state"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitZipCode", getValues("zipCode"), {
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("remitCountry", getValues("country"), {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="section bg-white px-0">
            <SectionTitle editable={false} title="Vendor Information" />
            <div className="row mb-4">
              <div className="form-group col-md-6 px-0">
                <label>
                  Company Name <span className="text-danger">*</span>
                </label>
                <Form.Control
                  {...register("company", { required: true })}
                  className={
                    errors.company
                      ? "form-control required_field"
                      : "form-control"
                  }
                  name="company"
                  disabled={props.isDisabled}
                />
                {errors["company"] && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
              <div className="form-group col">
                <label>Contact</label>
                <Form.Control
                  {...register("contact")}
                  className="form-control vendor-txt"
                  name="contact"
                  defaultValue={vendorData?.contact}
                  disabled={props.isDisabled}
                  onBlur={() => {
                    copyAddress(copyRemitAddress);
                  }}
                />
              </div>
            </div>
            <div className="form-group col-md-6 my-4">
              <label>Mobile No.</label>
              <Controller
                name="mobileNumber"
                defaultValue=""
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    onChange={onChange}
                    country={"us"}
                    value={value}
                    disabled={props.isDisabled}
                  />
                )}
              />
              <div className="text-danger">{errors.mobileNumber?.message}</div>
            </div>
            <div className="form-group col-md-6 my-4">
              <label>Work No.</label>
              <Controller
                control={control}
                name="workNumber"
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    onChange={onChange}
                    country={"us"}
                    value={value}
                    disabled={props.isDisabled}
                    onBlur={() => {
                      copyAddress(copyRemitAddress);
                    }}
                  />
                )}
              />
              <div className="text-danger">{errors.workNumber?.message}</div>
            </div>
          </div>
          <hr className="mb-2" />
          <div className="section pt-4 bg-white px-0">
            <div className="form-group  mb-4">
              <label>Address 1</label>
              <Form.Control
                {...register("address")}
                className="form-control vendor-txt"
                name="address"
                defaultValue={vendorData?.address}
                disabled={props.isDisabled}
                onBlur={() => {
                  copyAddress(copyRemitAddress);
                }}
              />
            </div>
            <div className="form-group  mb-4">
              <label>Address 2</label>
              <Form.Control
                {...register("address1")}
                className="form-control vendor-txt"
                name="address1"
                defaultValue={vendorData?.address1}
                disabled={props.isDisabled}
                onBlur={() => {
                  copyAddress(copyRemitAddress);
                }}
              />
            </div>
            <div className="row">
              <div className="col ps-0">
                <div className="form-group">
                  <label>City</label>
                  <Form.Control
                    {...register("city")}
                    className="form-control vendor-txt"
                    name="city"
                    defaultValue={vendorData?.city}
                    disabled={props.isDisabled}
                    onBlur={() => {
                      copyAddress(copyRemitAddress);
                    }}
                  />
                </div>
              </div>
              <div className="col pe-0">
                <div className="form-group">
                  <label>State</label>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(selectedOption: any) => {
                          onChange(selectedOption?.value);
                          copyAddress(copyRemitAddress);
                        }}
                        isClearable
                        value={
                          states.find((opt) => opt?.value === value) || null
                        }
                        isDisabled={
                          selectedVendorCountry !== "US" || props.isDisabled
                        }
                        options={statesOptions}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col ps-0">
                <div className="form-group mb-4">
                  <label>Country</label>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field }) => (
                      <CountrySelect
                        value={field.value}
                        isDisabled={props.isDisabled}
                        onChange={(countryCode) => {
                          field.onChange(countryCode);
                          setSelectedVendorCountry(countryCode);
                          if (countryCode !== "US") {
                            setValue("state", null);
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col pe-0">
                <div className="form-group mb-4">
                  <label>Zip Code</label>
                  <Form.Control
                    {...register("zipCode", {
                      pattern: {
                        value: /^\d{5}(?:[-\s]\d{4})?$/,
                        message: "Entered value does not match zipCode format.",
                      },
                    })}
                    className={
                      !errors.zipCode
                        ? "form-control"
                        : "form-control error_field"
                    }
                    name="zipCode"
                    defaultValue={vendorData?.zipCode}
                    disabled={props.isDisabled}
                    onBlur={() => {
                      copyAddress(copyRemitAddress);
                    }}
                  />
                  <div className="text-danger">{errors.zipCode?.message}</div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-2" />
          <div className="section bg-white px-0">
            <div className="col-lg-10 ps-0">
              <div className="row mb-4 ps-0">
                <div className="form-group col-6 ps-0">
                  <label>Customer ID</label>
                  <Form.Control
                    {...register("accountId")}
                    className="form-control"
                    name="accountId"
                    defaultValue={vendorData?.accountId}
                    disabled={props.isDisabled}
                  />
                </div>
                <div className="form-group col-6">
                  <label>EIN/Tax ID</label>
                  <Form.Control
                    {...register("taxId")}
                    className="form-control"
                    name="taxId"
                    defaultValue={vendorData?.taxId}
                    disabled={props.isDisabled}
                  />
                </div>
              </div>
              <div className="form-group mb-4">
                <label>Terms</label>
                <Form.Control
                  {...register("terms")}
                  className="form-control"
                  name="terms"
                  defaultValue={vendorData?.terms}
                  disabled={props.isDisabled}
                />
              </div>
            </div>
          </div>
          <hr className="mb-2" />
          <div className="section pt-4 bg-white px-0">
            <div className="d-flex justify-content-between mb-2">
              <div className="form-group col-md-6">
                <label>Website</label>
                <Form.Control
                  {...register("webSite", {
                    pattern: {
                      value:
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                      message: "Entered value does not match website format.",
                    },
                  })}
                  className={
                    !errors.webSite
                      ? "form-control"
                      : "form-control error_field"
                  }
                  name="webSite"
                  defaultValue={vendorData?.webSite}
                  disabled={props.isDisabled}
                />
                <div className="text-danger">{errors.webSite?.message}</div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <Form.Control
                  {...register("email", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format.",
                    },
                  })}
                  className={
                    !errors.email ? "form-control" : "form-control error_field"
                  }
                  name="email"
                  defaultValue={vendorData?.email}
                  disabled={props.isDisabled}
                />
                <div className="text-danger">{errors.email?.message}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
          <div className="right_section">
            <div className="section-title d-flex justify-content-between">
              <span>Remit to Address</span>
              <div className="d-flex justify-content-between">
                <Form.Label className="me-2 pt-2 fw-normal text-secondary">
                  Same as Vendor Info
                </Form.Label>
                <Form.Check
                  name="sameVInfo"
                  id="sameVInfo"
                  type="checkbox"
                  className="my-auto vendor-info-check-lbl mx-2"
                  onChange={onSameInfoChecked}
                  checked={copyRemitAddress}
                  disabled={props.isDisabled}
                />
              </div>
            </div>
            <div className="form-group mb-4">
              <label>Contact</label>
              <Form.Control
                {...register("remitContact")}
                className="form-control vendor-txt"
                name="remitContact"
                defaultValue={vendorData?.remitContact}
                disabled={props.isDisabled || copyRemitAddress}
              />
            </div>
            <div className="form-group col-md-8 mb-4">
              <label>Work Phone</label>
              <Controller
                control={control}
                name="remitWorkNumber"
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    onChange={onChange}
                    country={"us"}
                    value={value}
                    disabled={props.isDisabled || copyRemitAddress}
                  />
                )}
              />
              <div className="text-danger">
                {errors.remitWorkNumber?.message}
              </div>
            </div>
            <div className="form-group col-md-8 mb-4">
              <label>Email</label>
              <Controller
                control={control}
                name="remitEmail"
                render={({ field: { onChange, value } }) => (
                  <input
                    type={"emai"}
                    className={
                      !errors.remitEmail
                        ? "form-control"
                        : "form-control error_field"
                    }
                    onChange={onChange}
                    value={value}
                    disabled={props.isDisabled || copyRemitAddress}
                  />
                )}
              />
              <div className="text-danger">{errors.remitEmail?.message}</div>
            </div>
            <div className="form-group mb-4">
              <label>Address 1</label>
              <Form.Control
                {...register("remitAddress")}
                className="form-control"
                name="remitAddress"
                defaultValue={vendorData?.remitAddress}
                disabled={props.isDisabled || copyRemitAddress}
              />
            </div>
            <div className="form-group mb-4">
              <label>Address 2</label>
              <Form.Control
                {...register("remitAddress1")}
                className="form-control"
                name="remitAddress1"
                defaultValue={vendorData?.remitAddress1}
                disabled={props.isDisabled || copyRemitAddress}
              />
            </div>
            <div className="row mb-4">
              <div className="form-group col-6 ps-0">
                <label>City</label>
                <Form.Control
                  {...register("remitCity")}
                  className="form-control"
                  name="remitCity"
                  defaultValue={vendorData?.remitCity}
                  disabled={props.isDisabled || copyRemitAddress}
                />
              </div>
              <div className="form-group col-6 pe-0">
                <label>State</label>
                <Controller
                  control={control}
                  name="remitState"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(selectedOption: any) => {
                        onChange(selectedOption?.value);
                      }}
                      isClearable
                      value={states.find((opt) => opt?.value === value) || null}
                      isDisabled={
                        props.isDisabled ||
                        copyRemitAddress ||
                        selectedRemitCountry !== "US"
                      }
                      options={statesOptions}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="form-group col-6 ps-0">
                <label>Country</label>
                <Controller
                  control={control}
                  name="remitCountry"
                  render={({ field }) => (
                    <CountrySelect
                      value={field?.value}
                      isDisabled={props.isDisabled || copyRemitAddress}
                      onChange={(countryCode) => {
                        field.onChange(countryCode);
                        setSelectedRemitCountry(countryCode);
                        if (countryCode !== "US") {
                          setValue("remitState", null);
                        }
                      }}
                    />
                  )}
                />
              </div>
              <div className="form-group col-6 pe-0">
                <label>Zip Code</label>
                <Form.Control
                  {...register("remitZipCode", {
                    pattern: {
                      value: /^\d{5}(?:[-\s]\d{4})?$/,
                      message: "Entered value does not match zipCode format.",
                    },
                  })}
                  className={`form-control ${
                    errors.remitZipCode && "border-danger"
                  }`}
                  name="remitZipCode"
                  defaultValue={vendorData?.remitZipCode}
                  disabled={props.isDisabled || copyRemitAddress}
                />
                <div className="text-danger">
                  {errors.remitZipCode?.message}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInfoTab;
