import { useState, useContext, useRef } from "react";
import Lookup from "elements/Lookup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumns, QueryResult } from "components/Common.models";
import DispatchContext from "context/DispatchContext";
import { formatNumber, notificationMessage } from "global/helpers";
import { InvoiceEntry } from "pages/Invoices/Invoices.model";
import {
  getInvoice,
  getInvoices,
} from "pages/Invoices/InvoicesServices/InvoicesServices";
import {
  getSalesOrder,
  searchSalesOrders,
} from "pages/SalesOrder/SalesOrders/SalesOrderServices/SalesOrderServices";
import SalesOrdersModal from "pages/SalesOrder/SalesOrders/SalesOrderModal";
import DatePicker from "react-datepicker";
import AgGrid from "elements/AgGrid";
import ListSelectionModal from "components/SelectionListModal/ListSelectionModal";
import { createRma } from "./RmaServices/RmaServices";
import { CREATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import { getReasons } from "pages/EcommerceAdmin/Services/adminServices";
import ReasonsModal from "./ReasonsModal";
import * as colDefs from "../../constants/colDefs";

const CreateRma = () => {
  const history = useHistory();
  const [openSelectionOrder, setOpenSelectionOrder] = useState<boolean>(false);
  const [openSelectionInvoice, setOpenSelectionInvoice] =
    useState<boolean>(false);
  const [showSerialSelection, setShowSerialSelection] =
    useState<boolean>(false);
  const [showReasonModal, setShowReasonModal] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceEntry>();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [rowChecked, setRowChecked] = useState([]);
  const selectedItem = useRef(null);
  const gridItems = useRef([]);
  const reasonIndex = useRef(null);

  const { data: reasons, refetch } = useQuery("reasons", getReasons);

  const appDispatch = useContext(DispatchContext);

  const { data: invoices } = useQuery<QueryResult<InvoiceEntry>>(
    "openInvoices",
    getInvoices
  );

  const getSalesOrders = useMutation(searchSalesOrders);
  const getInvoiceDetails = useMutation(getInvoice, {
    onSuccess(res) {
      const { data } = res;
      gridItems.current = data.items;
      let details = {
        customer: data.customerNumber,
        company: data.shippingInfo?.company,
        hub: data.hubKey,
        count: data.items.length,
        total: data.total,
      };
      setSelectedDetails(details);
    },
  });

  const getOrderDetails = useMutation(getSalesOrder, {
    async onSuccess(res) {
      const { data } = res;
      gridItems.current = data.itemOrders;
      let details = {
        customerId: data.customerId,
        customer: data.accountNumber,
        company: data.company,
        hub: data.hubKey,
        count: data.totalItems,
        total: data.total,
      };
      setSelectedDetails(details);
    },
  });

  const addRma = useMutation(createRma, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `RMA ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      history.push(AppUrls.customer_rma);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem adding rma"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const columns: GridColumns[] = [
    {
      field: "invoiceNumber",
      headerName: "Invoice No.",
      minWidth: colDefs.sm_mWidth,
    },
    {
      field: "accountNumber",
      headerName: "Customer No.",
      minWidth: colDefs.mWidth,
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: colDefs.mWidth,
    },
    {
      field: "date",
      headerName: "Inv. Date",
      minWidth: colDefs.lgWidth,

      cellRenderer: (params) =>
        params.value
          ? `${new Date(params.value).toLocaleDateString()} - ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : "",
    },
    {
      field: "amount",
      headerName: "Inv. Amount",
    },
    {
      field: "paidAmount",
      headerName: "Paid Amount",
    },
    {
      field: "aging",
      headerName: "Aging",
    },
    {
      field: "batchNumber",
      headerName: "Batch No",
    },
  ];

  const gridColumns = [
    {
      headerName: "",
      field: "RowSelect",
      checkboxSelection: true,
      filter: false,
      width: 50,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
      width: 200,
    },
    {
      field: "description",
      headerName: "Item Description",
      width: 200,
    },
    {
      field: "return",
      headerName: "Return QTY",
      editable: true,
      width: 150,
    },
    {
      field: "returnReason",
      headerName: "Return Reason",
      editable: true,
      width: 250,
      cellEditorSelector: () => {
        let customReasons = reasons?.map((d) => {
          return d.reason;
        });
        customReasons.unshift("Add New");
        return {
          component: "agRichSelectCellEditor",
          params: { values: customReasons },
        };
      },
      onCellValueChanged: (params) => {
        if (params?.newValue === "Add New") {
          reasonIndex.current = params?.data?.invoiceItemId;
          setShowReasonModal(true);
        }
      },
    },
    {
      field: "quantity",
      headerName: "QTY",
      width: 100,
    },
    {
      field: "price",
      headerName: "Cost",
      cellRenderer: (params) => formatNumber(params.value),
      width: 100,
    },
    {
      field: "subTotal",
      headerName: "Subtotal",
      cellRenderer: (params) =>
        formatNumber(params.data.quantity * params.data.price),
      width: 120,
    },
    {
      field: "returnTotal",
      headerName: "Return Total",
      valueGetter: (params) =>
        formatNumber(params.data.return * params.data.price),
      width: 200,
    },
    {
      field: "",
      headerName: "",
      cellRendererFramework: (params) => {
        return params?.data && params?.data?.serials.length > 0 ? (
          <button
            type="button"
            className="btn btn-link text-decoration-none ms-0 ps-0"
            onClick={() => addSerial(params.data)}
          >
            Add Serial
          </button>
        ) : (
          "-"
        );
      },
    },
  ];

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    reset,
    control,
    setValue,
  } = methods;

  const onBackClick = () => {
    history.push(AppUrls.customer_rma);
  };

  const onInvoiceSelected = (invoice) => {
    setValue("invoiceId", invoice.invoiceId, { shouldDirty: true });
    setValue("salesOrderId", null);
    setSelectedInvoice(invoice);
    setSelectedOrder(null);
    setOpenSelectionInvoice(false);
    getInvoiceDetails.mutate({ queryKey: [invoice.invoiceId] });
  };

  const onOrderSelected = (order) => {
    setValue("salesOrderId", order.salesOrderId, { shouldDirty: true });
    setValue("invoiceId", null);
    setSelectedOrder(order);
    setSelectedInvoice(null);
    setOpenSelectionOrder(false);
    getOrderDetails.mutate(order.salesOrderId);
  };

  const addSerial = (values) => {
    selectedItem.current = values.invoiceItemId || values.itemOrderId;
    setShowSerialSelection(true);
  };

  const selectSerial = (items) => {
    gridItems.current = gridItems.current.map((item) => {
      if (
        item.invoiceItemId === selectedItem.current ||
        item.itemOrderId === selectedItem.current
      )
        return { ...item, selectedSerials: items.map((i) => i.id) };
      return { ...item };
    });
    setShowSerialSelection(false);
  };

  const onReasonAdded = (reason) => {
    refetch();
    gridItems.current = gridItems.current.map((item) => {
      if (item.invoiceItemId === reasonIndex.current)
        return { ...item, returnReason: reason };
      return { ...item };
    });
    setShowReasonModal(false);
  };

  const onCancelReason = () => {
    gridItems.current = gridItems.current.map((item) => {
      if (item.invoiceItemId === reasonIndex.current)
        return { ...item, returnReason: null };
      return { ...item };
    });
    setShowReasonModal(false);
  };

  const onCancel = () => {
    reset();
    setSelectedOrder(null);
    setSelectedInvoice(null);
    setSelectedDetails(null);
  };

  const onSubmit = (values) => {
    let checkedItems = rowChecked.filter((n) =>
      rowChecked.some((n2) =>
        n.itemOrderId
          ? n.itemOrderId === n2.itemOrderId
          : n.invoiceItemId === n2.invoiceItemId
      )
    );

    if (checkedItems.length === 0) {
      let notification = {
        variant: "danger",
        msg: "Please select items to return from the table",
      };

      appDispatch({ type: "notification", value: notification });
    } else if (checkedItems.some((item) => !item.return)) {
      let notification = {
        variant: "danger",
        msg: "Please choose item quantity to return",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      checkedItems.some(
        (item) =>
          isNaN(item.return) ||
          Number(item.return) > item.quantity ||
          Number(item.return) <= 0
      )
    ) {
      let notification = {
        variant: "danger",
        msg: "Invalid quantity to return entered",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (
      checkedItems.some(
        (item) =>
          item?.selectedSerials?.length > 0 &&
          Number(item?.return) !== item?.selectedSerials?.length
      )
    ) {
      let notification = {
        variant: "danger",
        msg: "Please add serials to selected items",
      };
      appDispatch({ type: "notification", value: notification });
    } else if (checkedItems.some((item) => !item.returnReason)) {
      let notification = {
        variant: "danger",
        msg: "Please select the reason for item return",
      };
      appDispatch({ type: "notification", value: notification });
    } else {
      let data = {
        ...values,
        returnMethod: 1,
        origin: 1,
        customerId: selectedDetails.customerId,
        items: checkedItems.map((item) => ({
          itemHubId: item.itemHubId,
          price: item.price,
          quantity: item.return,
          serials: item.selectedSerials ?? [],
          reason: reasons?.find((a) => a.reason === item.returnReason)?.id,
        })),
      };
      addRma.mutate(data);
    }
  };

  let itemSerials = gridItems.current.find(
    (item) =>
      item.invoiceItemId === selectedItem.current ||
      item.itemOrderId === selectedItem.current
  );

  return (
    <>
      <style>
        {`.ag-theme-material.ag-popup .ag-virtual-list-container .ag-virtual-list-item[aria-posinset="1"] .ag-rich-select-row {
                        background-color: #347AE6;
                        margin-right:  25%;
                        border-radius: 4px;
                        color: white;
                        cursor: pointer
                    }
                    .ag-theme-material.ag-popup .ag-virtual-list-container  {
                      align-text:center;
                      margin-left:25%;

                  }`}
      </style>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackClick}>
              <img src={backArrow} alt="back arrow" />
              Creating RMA
            </div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                disabled={!isDirty}
                onClick={onCancel}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty || isSubmitSuccessful}
              >
                Create
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-sm-3">
                  <label>
                    Invoice No. <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionInvoice(true)}
                    inputName="invoiceId"
                    initialData={invoices?.data
                      ?.filter((item) => item.status !== 3)
                      .map((item) => ({
                        ...item,
                        id: item.invoiceId,
                        name: item.invoiceNumber,
                      }))}
                    onSelection={onInvoiceSelected}
                    inputValue={selectedInvoice?.invoiceNumber}
                    isDisabled={selectedOrder ? true : false}
                  />
                </div>
                <div className="col-sm-3">
                  <label>
                    Order No. <span className="text-danger">*</span>
                  </label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionOrder(true)}
                    inputName="salesOrderId"
                    initialData={getSalesOrders?.data?.data?.data
                      ?.filter((item) => item.status === 4 || item.status === 7)
                      .map((item) => ({
                        ...item,
                        id: item.salesOrderId,
                        name: item.orderNumber,
                      }))}
                    onSelection={onOrderSelected}
                    inputValue={selectedOrder?.orderNumber}
                    isDisabled={selectedInvoice ? false : true}
                  />
                </div>
                <div className="col-sm-3 offset-sm-1">
                  <div className="form-group">
                    <label>
                      RMA Date <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="orderDate"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          yearDropdownItemNumber={15}
                          scrollableYearDropdown
                          onChange={(e) => {
                            onChange(e);
                          }}
                          selected={value ? value : null}
                          minDate={new Date()}
                        />
                      )}
                    />
                    {errors.orderDate && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-2 col-sm-12">
                  <label>Customer No.</label>
                  <div>
                    {selectedDetails?.customer ?? (
                      <span className="text-muted fst-italic">
                        Customer No.
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label>Company Name</label>
                  <div>
                    {selectedDetails?.company ?? (
                      <span className="text-muted fst-italic">
                        Company Name
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label>Hub Id</label>
                  <div>
                    {selectedDetails?.hub ?? (
                      <span className="text-muted fst-italic">Hub Id</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label>Item Count</label>
                  <div>
                    {selectedDetails?.count ?? (
                      <span className="text-muted fst-italic">Item Count</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 col-sm-12">
                  <label>Total</label>
                  <div>
                    {selectedDetails?.total ? (
                      formatNumber(selectedDetails?.total)
                    ) : (
                      <span className="text-muted fst-italic">Total</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="h-450 mt-5">
                <AgGrid
                  columns={gridColumns}
                  data={gridItems.current}
                  multipleSelection={true}
                  setRowChecked={setRowChecked}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal="Invoice"
        showModal={openSelectionInvoice}
        setShowModal={setOpenSelectionInvoice}
        data={invoices?.data?.filter((item) => item.status !== 3)}
        columns={columns}
        setRowClicked={onInvoiceSelected}
      />
      <SalesOrdersModal
        showModal={openSelectionOrder}
        setShowModal={setOpenSelectionOrder}
        setRowClicked={onOrderSelected}
        preselectedStatus={{ label: "Shipped", value: 4 }}
      />
      <ListSelectionModal
        showModal={showSerialSelection}
        title="Serials"
        data={
          selectedItem.current
            ? itemSerials?.serials.map((serial) => {
                return { title: serial.serialNo, id: serial.serialId };
              })
            : []
        }
        selectedValues={
          selectedItem.current ? itemSerials?.selectedSerials : []
        }
        onSelectData={selectSerial}
        onExit={() => setShowSerialSelection(false)}
        selectValue={itemSerials?.selectedSerials?.length}
        isInvoice={true}
        maxValue={itemSerials?.serials?.length ?? 0}
      />
      <ReasonsModal
        onConfirm={onReasonAdded}
        onClose={onCancelReason}
        showModal={showReasonModal}
      />
    </>
  );
};

export default CreateRma;
