import { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import SearchIcon from "img/search-group.png";
import Badge from "react-bootstrap/Badge";
import { Tabs, Tab } from "react-bootstrap";
import InvoiceInfo from "./Invoice Tabs/InvoiceInfo";
import InvoiceItems from "./Invoice Tabs/InvoiceItems";
import { Controller, FormProvider, useForm } from "react-hook-form";
import SelectionModal from "components/SelectionModal/SelectionModal";
import { GridColumns } from "components/Common.models";
import DispatchContext from "context/DispatchContext";
import {
  getCustomerById,
  getCustomers,
} from "pages/SalesMarketing/Customers/Customers.services";
import { CustomerAccount, InvoiceItem } from "./Invoices.model";
import { createInvoice } from "./InvoicesServices/InvoicesServices";
import {
  formatInput,
  formatNumber,
  getCurrenciesListFromExchangeRateList,
  notificationMessage,
} from "global/helpers";
import { CustomerInfoDTO } from "pages/SalesMarketing/Customers/Customers.models";
import {
  CREATE_SUCCESS_MSG,
  REQUIRED_FIELDS_MSG,
} from "constants/NotificationMsgs";
import backArrow from "img/back-arrow.svg";
import { useHistory } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";
import Lookup from "elements/Lookup";
import { getLedgers } from "pages/Accounting/AccountingServices";
import { LedgerTypeEnum } from "pages/Accounting/enum/AccountEnum";
import Select from "react-select";
import {
  getCurrency,
  getExchangeRate,
  getLatestRate,
} from "pages/SystemSetup/SystemSetupServices/SystemSetupServices";

const CreateCreditMemo = () => {
  const history = useHistory();

  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] = useState<CustomerInfoDTO>();
  const [hubId, setHubId] = useState<number>();
  const [addedItems, setAddedItems] = useState<InvoiceItem[]>([]);
  const [addedSerials, setAddedSerials] = useState([]);
  const [activeKey, setActiveKey] = useState<string>("InvoiceInfo");
  const [valueInput, setValueInput] = useState(null);
  const [openExpenseAccountId, setOpenExpenseAccountId] =
    useState<boolean>(false);
  const [selectedExpenseAccountId, setSelectedExpenseAccountId] =
    useState(null);
  const appDispatch = useContext(DispatchContext);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    setValue,
    reset,
    control,
  } = methods;

  const { isLoading: isLoadingCustomers, data: customers } = useQuery(
    "customersList",
    getCustomers
  );

  const { data: currencies } = useQuery("currencies", getExchangeRate, {
    select(data) {
      const currencyCodes = data?.map((currency) => ({
        currencyCode: currency?.currencyCode,
      }));
      var currencies = getCurrenciesListFromExchangeRateList(currencyCodes);
      return currencies;
    },
  });

  const getDetails = useMutation(getCustomerById, {
    async onSuccess(response) {
      setCustomerDetails(response);
      setHubId(response.defaultHubId);
    },
  });

  const resetFrm = () => {
    reset();
    setCustomerDetails(null);
    setAddedItems([]);
    setHubId(null);
    setActiveKey("InvoiceInfo");
  };

  const addCreditMemo = useMutation(createInvoice, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Credit Memo ${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      resetFrm();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem creating credit memo"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const getRate = useMutation(getLatestRate, {
    async onSuccess(data) {
      if (data) setValue("currencyExchangeRate", data);
      else setValue("currencyExchangeRate", null);
    },
  });

  useEffect(() => {
    appDispatch({
      type: "loading",
      value:
        isLoadingCustomers || getDetails.isLoading || addCreditMemo.isLoading,
    });
  }, [isLoadingCustomers, getDetails.isLoading, addCreditMemo.isLoading]);

  const accountNumberColumns: GridColumns[] = [
    {
      field: "accountNumber",
      headerName: "Account No.",
    },
    {
      field: "company",
      headerName: "Company Name",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "hubId",
      headerName: "Hub ID",
    },
    {
      field: "state",
      headerName: "Location",
    },
    {
      field: "accountType",
      headerName: "Account Type",
    },
    {
      field: "balance",
      headerName: "Balance",
    },
  ];

  const onExpenseAccountIdSelection = (e) => {
    setSelectedExpenseAccountId(e);
    setOpenExpenseAccountId(false);
    setValue("expenseAccountId", e.ledgerId, { shouldValidate: true });
  };

  const ledgersColumns = [
    {
      field: "accountNumber",
      headerName: "Account",
      resizable: true,
    },
    {
      field: "accountName",
      headerName: "Account name",
      resizable: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      resizable: true,
    },
    {
      field: "isDebit",
      headerName: "Debit",
      resizable: true,
      cellRenderer: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const { data: paymentAccountsEpense } = useQuery("expenses", () =>
    getLedgers({ ledgerType: LedgerTypeEnum.Expenses })
  );

  const onCustomerSelected = (customer: CustomerAccount) => {
    setValue("accountNumber", customer["accountNumber"], { shouldDirty: true });
    setValue("targetId", customer["customerAccountId"]);
    getDetails.mutate(customer.customerAccountId);
    setOpenSelection(false);
  };

  const onCancel = () => {
    resetFrm();
  };

  const onSubmit = (values) => {
    if (valueInput) {
      let newData = {
        ...values,
        invoiceType: 2,
        hubId: hubId,
        orderDate: new Date(),
        items: [],
        shippingMethodId: values.shippingMethodId?.value ?? null,
        creditAmount: values.creditAmount,
        creditCostofSalesAccountId: selectedExpenseAccountId?.ledgerId,
      };
      addCreditMemo.mutate(newData);
    } else {
      if (addedItems.length > 0) {
        let newData = {
          ...values,
          invoiceType: 2,
          hubId: hubId,
          orderDate: new Date(),
          shippingMethodId: values.shippingMethodId?.value ?? null,
          items: addedItems.map((item) => {
            let serialsToSend = [];
            addedSerials
              ?.filter((s) => s.itemId === item.itemId)
              ?.map((serial) => {
                serialsToSend.push(serial.serial);
              });
            return {
              itemId: item.itemId,
              quantity: Number(item.invoiceQty),
              price: Number(item.invoicePrice),
              serials: serialsToSend,
            };
          }),
        };
        addCreditMemo.mutate(newData);
      } else {
        let notification = {
          variant: "danger",
          msg: "Please add items to create credit memo",
        };
        appDispatch({ type: "notification", value: notification });
      }
    }
  };

  const onError = () => {
    let notification = {
      variant: "danger",
      msg: `${REQUIRED_FIELDS_MSG}`,
    };
    appDispatch({ type: "notification", value: notification });
  };

  const onCurrencyChange = (e) => {
    if (e) getRate.mutate(e);
    else setValue("currencyExchangeRate", null);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="page-title page-title-editable">
            <div
              className="back-btn "
              onClick={() => history.push(AppUrls.credit_memo)}
            >
              <img src={backArrow} alt="back arrow" />
              Credit Memo
            </div>
            <div className="d-flex justify-content-between ">
              <button
                type="button"
                className="btn btn-outline-primary no-border"
                onClick={onCancel}
              >
                Reset
              </button>
              <button type="submit" className="btn btn-success">
                Save Changes
              </button>
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="row gx-5">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Customer No.</label>{" "}
                        <span className="text-danger">*</span>
                        <InputGroup>
                          <input
                            className="form-control bg-white"
                            placeholder="Choose"
                            readOnly
                            {...register("accountNumber", { required: true })}
                          />
                          <Button
                            variant="primary"
                            className="input-group-prepend"
                            onClick={() => setOpenSelection(true)}
                          >
                            <img src={SearchIcon} alt="" />
                          </Button>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Amount</label>
                        <span className="text-danger">*</span>
                        <input
                          {...register("creditAmount")}
                          className="form-control"
                          onChange={(e) => {
                            setValueInput(e.target.value);
                            if (e.target.value == "") {
                              setValueInput(null);
                              setSelectedExpenseAccountId(null);
                            }
                          }}
                          onBlur={(e) => {
                            setValue(
                              "creditAmount",
                              formatInput(e.target.value)
                            );
                            setActiveKey("InvoiceInfo");
                          }}
                          disabled={addedItems?.length > 0}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <label>Expense Account</label>{" "}
                      <span className="text-danger">*</span>
                      <Lookup
                        onButtonClicked={() => setOpenExpenseAccountId(true)}
                        inputName="expenseAccountId"
                        isRequired={addedItems?.length > 0 ? false : true}
                        initialData={paymentAccountsEpense?.data?.map(
                          (item) => ({
                            ...item,
                            id: item.ledgerId,
                            name: item.accountName,
                          })
                        )}
                        onSelection={onExpenseAccountIdSelection}
                        inputValue={selectedExpenseAccountId?.accountName}
                        isDisabled={valueInput === null}
                      />
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Memo No.</label>
                        <div>
                          <span className="text-muted fst-italic">
                            Memo No.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Memo Date</label>
                        <div>{new Date().toLocaleDateString()}</div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Currency</label>
                        <Controller
                          control={control}
                          name="currencyId"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isSearchable={true}
                              menuPlacement="auto"
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption?.value);
                                onCurrencyChange(selectedOption?.value);
                              }}
                              value={
                                currencies?.find(
                                  (opt) => opt?.value === value
                                ) || null
                              }
                              options={currencies}
                              isClearable
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>Exchange Rate</label>
                        <input
                          className="form-control w-50"
                          {...register("currencyExchangeRate")}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="row my-5">
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>Balance</div>
                        <div>
                          {customerDetails &&
                          customerDetails["ledger"]["balance"] ? (
                            <span
                              className={`${
                                customerDetails["ledger"]["balance"] < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(
                                customerDetails["ledger"]["balance"]
                              )}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>Credit Limit</div>
                        <div>
                          {customerDetails && customerDetails["creditLimit"] ? (
                            <span
                              className={`${
                                Number(customerDetails["creditLimit"]) < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(customerDetails["creditLimit"])}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>On Order</div>
                        <div>
                          {customerDetails && customerDetails["onSO"] ? (
                            <span
                              className={`${
                                customerDetails["onSO"] < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(customerDetails["onSO"])}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div
                        className={`border p-3 ${
                          !isDirty && "bg-silver-light text-secondary"
                        }`}
                      >
                        <div>Available</div>
                        <div>
                          {customerDetails &&
                          customerDetails["ledger"]["balance"] ? (
                            <span
                              className={`${
                                customerDetails["ledger"]["balance"] < 0
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {formatNumber(
                                customerDetails["ledger"]["balance"]
                              )}
                            </span>
                          ) : (
                            <span className={`${isDirty && "text-success"}`}>
                              $0.00
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
                <Tab
                  eventKey="InvoiceInfo"
                  title={
                    <div className="tab-inner-title">Credit Memo Info</div>
                  }
                >
                  <InvoiceInfo
                    isEditing={false}
                    isCreating={true}
                    customerDetails={customerDetails}
                    isCredit={true}
                    setHubId={setHubId}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                  />
                </Tab>
                <Tab
                  eventKey="InvoiceItems"
                  disabled={valueInput != null}
                  title={
                    <div className="tab-inner-title">
                      Credit Memo Items
                      <Badge pill className="text-secondary ms-2">
                        {addedItems.length}
                      </Badge>
                    </div>
                  }
                >
                  <InvoiceItems
                    isCreating={true}
                    isEditing={false}
                    hubId={hubId}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    setAddedSerials={setAddedSerials}
                    addedSerials={addedSerials}
                    isCredit={true}
                    showUnavailable={true}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </form>
      </FormProvider>
      <SelectionModal
        modal={"Account"}
        showModal={openSelection}
        setShowModal={setOpenSelection}
        data={customers?.data}
        columns={accountNumberColumns}
        setRowClicked={onCustomerSelected}
      />
      <SelectionModal
        modal="account"
        showModal={openExpenseAccountId}
        setShowModal={setOpenExpenseAccountId}
        data={paymentAccountsEpense?.data}
        columns={ledgersColumns}
        setRowClicked={onExpenseAccountIdSelection}
      />
    </>
  );
};

export default CreateCreditMemo;
