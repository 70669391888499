import { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useQuery, useMutation } from "react-query";
import {
  getExchangeRateById,
  addExchangeRate,
  editExchangeRate,
  getCurrency,
} from "./SystemSetupServices/SystemSetupServices";
import {
  fixTimezoneOffset,
  getCurrenciesDropdownList,
  notificationMessage,
} from "global/helpers";
import backArrow from "img/back-arrow.svg";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import {
  CREATE_SUCCESS_MSG,
  UPDATE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import { Modal } from "react-bootstrap";

const EditExchangeRate = () => {
  const { id } = useParams();
  const isEdit = !!id;
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(isEdit);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const { data: exchangeRateData, isLoading } = useQuery(
    ["exchangeRate", id],
    () => getExchangeRateById(id),
    {
      enabled: isEdit,
    }
  );
  const { data: usedCurrencies } = useQuery("usedCurrencies", getCurrency);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      currencyCode: "",
      rate: "",
      date: new Date(),
    },
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    control,
    register,
    setValue,
    reset,
  } = methods;

  useEffect(() => {
    if (isEdit && exchangeRateData) {
      const { currencyCode, rate, createdDate } = exchangeRateData;
      setValue("currencyCode", currencyCode);
      setValue("rate", rate);
      setValue("date", new Date(createdDate));
    }
  }, [exchangeRateData, isEdit, setValue]);

  const mutation = useMutation(
    "exchangeRate",
    isEdit ? editExchangeRate : addExchangeRate,
    {
      onSuccess: () => {
        let notification = {
          variant: "success",
          msg: `Exchange Rate ${
            isEdit ? UPDATE_SUCCESS_MSG : CREATE_SUCCESS_MSG
          }`,
        };
        appDispatch({ type: "notification", value: notification });
        history.push(`${AppUrls.exchange_rate}`);
      },
      onError: (error) => {
        let notification = {
          variant: "danger",
          msg: notificationMessage(
            error,
            `problem ${isEdit ? "editing" : "adding"} exchange rate`
          ),
        };
        appDispatch({ type: "notification", value: notification });
      },
    }
  );

  const onSubmit = (values) => {
    const payload = { ...values, date: fixTimezoneOffset(values?.date) };
    if (isEdit) {
      payload.id = id;
    }
    mutation.mutate(payload);
  };

  const onBackClick = () => {
    if (isDirty) setOpenConfirmModal(true);
    else history.push(AppUrls.exchange_rate);
  };

  const cancelForm = () => {
    reset();

    if (id) {
      setIsDisabled(true);
    }
  };

  const onEditClick = () => {
    setIsDisabled(false);
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoading });
  }, [isLoading, appDispatch]);

  const availableCurrencies = getCurrenciesDropdownList().filter(
    (dropdownCurrency) =>
      !usedCurrencies?.some(
        (usedCurrency) => usedCurrency.currencyCode === dropdownCurrency.value
      )
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="page-title page-title-editable">
          <div className="back-btn ">
            <img src={backArrow} alt="back arrow" onClick={onBackClick} />
            {isEdit ? `Edit ExchangeRate` : "Create ExchangeRate"}
          </div>
          <div>
            <div>
              {id && isDisabled && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onEditClick}
                >
                  Edit
                </button>
              )}
              {(!id || (id && !isDisabled)) && (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-primary no-border ${
                      !isDirty && !id && "text-muted"
                    }`}
                    disabled={!isDirty && !id}
                    onClick={cancelForm}
                  >
                    Reset{" "}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={!isDirty}
                  >
                    {id ? "Save Changes" : "Create"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row">
              <div className="col-sm-2">
                <div className="form-group">
                  <label>
                    Currency <span className="text-danger">*</span>
                  </label>
                  <Controller
                    control={control}
                    name="currencyCode"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        onChange={(selectedOption: any) =>
                          onChange(selectedOption?.value)
                        }
                        isDisabled={isDisabled || isEdit}
                        isClearable
                        value={
                          (isEdit
                            ? getCurrenciesDropdownList()
                            : availableCurrencies
                          )?.find((opt) => opt?.value === value) || null
                        }
                        options={
                          isEdit
                            ? getCurrenciesDropdownList()
                            : availableCurrencies
                        }
                      />
                    )}
                  />
                  {errors?.currencyCode && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label className="label me-3">
                    Exchange Rate <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={isDisabled}
                    type="number"
                    {...register("rate", {
                      required: true,
                      valueAsNumber: true,
                    })}
                    className="form-control"
                  />
                  {errors?.rate && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>
                    Date<span className="text-danger">*</span>
                  </label>{" "}
                  <br />
                  <Controller
                    control={control}
                    name="date"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        onChange={onChange}
                        selected={value ? value : null}
                        disabled={isDisabled}
                      />
                    )}
                  />
                  {errors?.date && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={openConfirmModal}
          onHide={setOpenConfirmModal}
          centered
          className="discard-modal"
        >
          <Modal.Header>
            <Modal.Title>Discarding</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to leave?</p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <button
              className="btn btn-outline-primary"
              onClick={() => setOpenConfirmModal(false)}
            >
              No, stay
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                history.push(AppUrls.exchange_rate);
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </form>
    </>
  );
};

export default EditExchangeRate;
