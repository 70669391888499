import { DropDownOption } from "components/Common.models";
import { StateModel, states } from "components/data/States";
import * as AppUrls from "constants/AppUrls";
import DispatchContext from "context/DispatchContext";
import { notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  addTax,
  editTax,
  getTaxRates,
} from "./SystemSetupServices/SystemSetupServices";

import { useLocation } from "react-router-dom";

const NewTaxSetup = () => {
  const queryClient = useQueryClient();
  const appDispatch = useContext(DispatchContext);
  const statesOptions: StateModel[] = states;
  const [selectedId, setSelectedId] = useState<number>(null);

  useQuery("taxes", getTaxRates);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const { id, name, rate } = location.state;
      setSelectedId(id);
      setValue("state", name);
      setValue("stateTaxRate", rate);
    }
  }, [location.state]);
  const addMutation = useMutation(addTax, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Tax rate added successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("taxes");
      history.push(AppUrls.tax_setup);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding tax"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editMutation = useMutation(editTax, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Tax rate edited successfully`,
      };
      appDispatch({ type: "notification", value: notification });
      queryClient.invalidateQueries("taxes");
      history.push(AppUrls.tax_setup);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing tax"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const {
    formState: { isDirty, errors },
    handleSubmit,
    reset,
    control,
    register,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const history = useHistory();

  const resetForm = () => {
    setValue("stateTaxRate", null);
    reset();
  };

  const onSubmit = (data) => {
    if (selectedId) editMutation.mutate({ ...data, id: selectedId });
    else addMutation.mutate(data);
  };

  return (
    <div className="static-page">
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="  page-title page-title-editable">
          <div className="d-flex flex-row">
            <div className="back-btn">
              <img
                src={backArrow}
                alt="back arrow"
                onClick={() => history.push(AppUrls.tax_setup)}
              />
            </div>
            <div>
              <p className="">Tax Rate</p>
            </div>
          </div>{" "}
          <div className="d-flex" style={{ marginLeft: "-15px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={resetForm}
              disabled={!!selectedId}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={!isDirty}
              className="btn btn-primary"
            >
              {!selectedId ? "Add Tax Rate" : "Save Changes"}
            </button>
          </div>
        </div>
        <div className="page-content-wrapper">
          <div className="row h-100">
            <div>
              <div className="">
                <div className="row mb-3">
                  <div className="col-4 ">
                    <label>
                      State <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="state"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={statesOptions}
                          isSearchable={true}
                          value={
                            statesOptions?.find(
                              (opt) => opt?.value === value
                            ) || null
                          }
                          onChange={(val: DropDownOption) =>
                            onChange(val?.value)
                          }
                          menuPlacement="auto"
                          isClearable={true}
                          isDisabled={!!selectedId}
                          className={errors["state"] && "required_field"}
                        />
                      )}
                    />
                    {errors.state && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                  <div className="col-4">
                    <label>
                      Tax Rate <span className="text-danger">*</span>
                    </label>
                    <input
                      {...register("stateTaxRate", {
                        valueAsNumber: true,
                        required: true,
                      })}
                      type="number"
                      min={0}
                      step="any"
                      className={`form-control ${
                        errors.stateTaxRate ? "required_field" : ""
                      }`}
                      style={{ height: "38px" }}
                    />
                    {errors.stateTaxRate && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewTaxSetup;
