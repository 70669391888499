import { useEffect, useState } from "react";
import AG from "elements/AG";
import { GridColumns } from "components/Common.models";
import { formatNumber } from "global/helpers";

const InvoiceExpenses = ({ expenses, currencySymbol }) => {
  const [formattedExpenses, setFormattedExpenses] = useState([]);

  useEffect(() => {
    if (expenses) {
      setFormattedExpenses(
        expenses.map((expense, index) => ({
          ...expense,
          expenseIndex: index + 1,
        }))
      );
    }
  }, [expenses]);

  const columns: GridColumns[] = [
    {
      field: "expenseItemId",
      headerName: "Expense Id",
    },
    {
      field: "expenseItemName",
      headerName: "Expense Item Name",
    },
    {
      field: "amount",
      headerName: "Amount",
      cellRenderer: (params) => formatNumber(params.value, currencySymbol),
    },
  ];

  return (
    <div className="grid mt-3">
      <div className="row section px-0 ps-2 py-3 pb-0 ">
        <div className="ps-0">
          <h4>
            <b>Expenses</b>
          </h4>
        </div>
      </div>
      <div className="h-350">
        <AG columns={columns} data={formattedExpenses} autoColumns={true} />
      </div>
    </div>
  );
};

export default InvoiceExpenses;
