import api from "components/data/AxiosClient";

export const getAudit = async (params) => {
    let extraParams = '?'
    if(params.startDate) 
        extraParams += `&startDate=${params.startDate}`
    if(params.endDate)
        extraParams += `&endDate=${params.endDate}`
    if (params.userId)
        extraParams += `&userId=${params.userId}`
    if (params.actionType)
        extraParams += `&actionType=${params.actionType}`
    const response = await api.get(`audits${extraParams}`);
    return response.data;
};