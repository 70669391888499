import { useHistory, Link } from "react-router-dom";
import * as AppUrls from "constants/AppUrls";

import AgGrid from "elements/AgGrid";
import { useQuery } from "react-query";
import { getExchangeRate } from "./SystemSetupServices/SystemSetupServices";
import { dateFormmaterNoTime, getCurrencyByCode } from "global/helpers";
import addIcon from "../../img/add-icon.svg";
import { useEffect, useState } from "react";

const ExchangeRate = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState({
    params: "",
  });
  const { data } = useQuery(["exchangeRates", filter], getExchangeRate);

  const columns = [
    {
      headerName: "Name",
      editable: false,
      cellRenderer: (params) =>
        getCurrencyByCode(params?.data?.currencyCode)?.name,
    },
    {
      field: "currencyCode",
      headerName: "Code",
    },
    {
      field: "rate",
      headerName: "Exchange Rate",
    },
    {
      field: "date",
      headerName: "Date",
      cellRenderer: (params) => dateFormmaterNoTime(params?.value),
    },
  ];

  const displayFilter = () => {
    const params = searchTerm ? `searchQuery=${searchTerm}` : "";
    setFilter({
      params: params,
    });
  };

  const onRowDoubleClicked = (e) => {
    const url = `${AppUrls.exchange_rate}/${e.exchangeRateId}`;
    history.push(url);
  };

  useEffect(() => {
    displayFilter();
  }, [searchTerm]);

  return (
    <div className="static-page">
      <div className="page-title page-title-editable">
        <p>Exchange Rate</p>
        <Link to={AppUrls.new_exchange_rate} className="btn btn-primary">
          <img src={addIcon} className="me-2" alt="" /> New Exchange Rate
        </Link>
      </div>
      <div className="page-content-wrapper">
        <div className="filters">
          <div className="col-lg-3">
            <div className="page-search">
              <label className="label me-3">Search</label>
              <div className="input-group">
                <span className="page-search-icon">
                  <i className="fa fa-search"></i>
                </span>
                <input
                  className="page-search-input ps-5"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row h-100">
          <div className=" grid-container">
            <div className="grid">
              <AgGrid
                columns={columns}
                data={data}
                filters={filter}
                setrowClicked={onRowDoubleClicked}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeRate;
