type Props = {
   editable: boolean, 
   title: string,
   active?: boolean, 
   isclicked?: any, 
   activeOthers?: any
};

const SectionTitle = ({ editable, title, active, isclicked, activeOthers } : Props) => {
  
  const editbtnclicked = () => {
    isclicked(true);
    typeof activeOthers !== "undefined" ? (
      activeOthers.map((other) => {
        other(false);
        return true;
      })
    ) : (
      <></>
    );
  };
  
  return (
    <div className={`section-title ${ active ? 'active' : ''}`}>
      <span>{title}</span>
      {editable && !active ? (
        <button className="btn btn-primary" onClick={editbtnclicked}>
          Edit
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default SectionTitle;
