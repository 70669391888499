import React, { useState, useEffect, useContext } from "react";
import EcomSetupForm from "./EcomSetupForm";
import { notificationMessage } from "global/helpers";
import * as AppUrls from "constants/AppUrls";
import { createBanner, deleteBanner } from "../Portal.services";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  CREATE_SUCCESS_MSG,
  DELETE_SUCCESS_MSG,
} from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import { useMutation } from "react-query";

export default function EcomSLideShow() {
  const [defaultdata, setDefaultData] = useState({
    id: "",
    title: "",
    subTitle: "",
    imageUrl: "",
    actionButtonUrl: "",
    actionButtonLabel: "",
  });
  const location = useLocation();
  const id = location.state && location.state.id;
  const title = location.state && location.state.title;
  const subTitle = location.state && location.state.subTitle;
  const imageUrl = location.state && location.state.imageUrl;
  const actionButtonUrl = location.state && location.state.actionButtonUrl;
  const actionButtonLabel = location.state && location.state.actionButtonLabel;

  useEffect(() => {
    setDefaultData({
      id: id,
      title: title,
      subTitle: subTitle,
      imageUrl: imageUrl,
      actionButtonUrl: actionButtonUrl,
      actionButtonLabel: actionButtonLabel,
    });
  }, [id, title, subTitle, imageUrl, actionButtonUrl, actionButtonLabel]);

  const deleteBannerMutation = useMutation(deleteBanner, {
    onSuccess: (dataID) => {
      let notification = {
        variant: "success",
        msg: `${DELETE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      const url = `${AppUrls.Content_Management_System}`;

      history.push({
        pathname: url,
      });
    },
    onError: (error) => {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem deleting section"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const handleFormDelete = () => {
    deleteBannerMutation.mutate(Number(defaultdata.id));
  };
  const appDispatch = useContext(DispatchContext);

  const createBannerMutation = useMutation(createBanner, {
    onSuccess: (bannerData) => {
      let notification = {
        variant: "success",
        msg: `${CREATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      const url = `${AppUrls.Content_Management_System}`;

      history.push({
        pathname: url,
      });
    },
    onError: (error) => {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem adding banner"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const handleCreateBanner = (bannerData) => {
    createBannerMutation.mutate(bannerData);
  };

  const history = useHistory();

  const handleFormSubmit = (data) => {
    handleCreateBanner(data);
  };

  const inputs = [
    {
      name: "title",
      type: "text",
      label: "Title",
      required: true,
      maxLength: 50,
      default: defaultdata.title,
    },
    {
      name: "subTitle",
      type: "text",
      label: "Subtitle",
      required: false,
      maxLength: 100,
      default: defaultdata.subTitle,
    },
    {
      name: "actionButtonUrl",
      type: "text",
      label: "Button ",
      required: true,
      default: defaultdata.actionButtonUrl,
    },
    {
      name: "actionButtonLabel",
      type: "text",
      label: "Button Label ",
      required: true,
      maxLength: 40,

      default: defaultdata.actionButtonLabel,
    },
  ];

  return (
    <>
      <div>
        <EcomSetupForm
          title="Slide Show"
          section={"SO"}
          imageUrl={defaultdata.imageUrl}
          inputs={inputs}
          sbmtData={handleFormSubmit}
          deleteData={handleFormDelete}
          btn="Save"
          {...(defaultdata.id ? { deleteBtn: "Delete" } : {})}
        />
      </div>
    </>
  );
}
