import { useEffect } from "react";
import { useMutation } from "react-query";
import Close from "img/close.svg";
import { Modal } from "react-bootstrap";
import AG from "elements/AG";
import { getInvoiceHist, getItemHistory } from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import { GridColumns } from "components/Common.models";
import { dateFormmaterNoTime } from "global/helpers";

const HistoryModal = ({ showModal, setShowModal, selectedItem, customerDetails, isSoHistory = false, isInvoice = false }) => {

    const item_history_columns: GridColumns[] = [
        {
            field: "itemNumber",
            headerName: "Item Number",
        },
        {
            field: "itemDescription",
            headerName: "Item Description",
        },
        {
            field: "invoiceNumber",
            headerName: "Invoice Number",
        },
        {
            field: "invoiceDate",
            headerName: "Item Date",
            cellRenderer: (params) => dateFormmaterNoTime(params.value),
        },
        {
            field: "quantity",
            headerName: "Quantity",
        },
        {
            field: "price",
            headerName: "Price",
        },
        {
            field: "subTotal",
            headerName: "Sub Total",
        },
    ]

    const soColumns: GridColumns[] = [
        {
            field: "invoiceNumber",
            headerName: "Invoice No.",
        },
        {
            field: "quantity",
            headerName: "QTY",
        },
        {
            field: "price",
            headerName: "Item Price",
            cellRenderer: (params) => `$ ${params.value}`,
        },
        {
            field: "subTotal",
            headerName: "Subtotal",
            cellRenderer: (params) => `$ ${params.value}`,
        },
        {
            field: "representativeName",
            headerName: "Rep ID",
        },
        {
            field: "invoiceDate",
            headerName: "Invoice Date",
            cellRenderer: (params) => params.value ? (`${new Date(params.value).toLocaleDateString()}`) : '',
        }
    ];

    const item_po_history = [
        {
            field: "purchaseOrderNumber",
            headerName: "PO No.",
        },
        {
            field: "locationId",
            headerName: "Hub",
        },
        {
            field: "quantityReceived",
            headerName: "QTY Received",
        },
        {
            field: "cost",
            headerName: "Cost",
            cellRenderer: (params) => `$ ${params.value}`,
        },
        {
            field: "vendorName",
            headerName: "Vendor",
        },
        {
            field: "purchaseOrderDate",
            headerName: "PO Date",
            cellRenderer: (params) => params.value ? (`${dateFormmaterNoTime(params.value)}`) : '',
        }  
    ]

    const getHistory = useMutation(getItemHistory)
    const getInvoiceHistory = useMutation(getInvoiceHist)

    useEffect(() => {
        if(showModal && selectedItem && isInvoice) {
            if(customerDetails?.customerAccountId != undefined)
                getInvoiceHistory.mutate({ itemId: selectedItem?.itemId, customerId: customerDetails?.customerAccountId })
            else
                getInvoiceHistory.mutate({ itemId: selectedItem?.itemId })
        } else if (showModal && selectedItem) {
            getHistory.mutate({ itemId: selectedItem.itemId, queryParams: '' })
        }
    }, [showModal, selectedItem, isInvoice])

    return (
        <Modal show={showModal} onHide={setShowModal} id="item-history-modal" centered size="lg" dialogClassName="modal-80w">
            <Modal.Header className="d-flex justify-content-between border-bottom-0">
                <Modal.Title className="copy-items-title ">
                    Item {isInvoice ? 'Invoice' : 'Sales'} History
                </Modal.Title>
                <img
                    src={Close}
                    alt="close icon"
                    className=""
                    role="button"
                    onClick={() => {
                        setShowModal(false);
                    }}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="row px-3 mb-4">
                    <div className="px-2">
                        <hr className="mt-0" />
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex">
                        <div className="me-5">
                            {isSoHistory || isInvoice ?
                                <>
                                    <label className="text-primary mb-3">Account No.</label>
                                    <div>{customerDetails?.accountNumber ?? <span className="text-muted fst-italic">Account No.</span>}</div>
                                </>
                                :
                                <>
                                    <label className="text-primary mb-3">Vendor No.</label>
                                    <div>{customerDetails?.vendorNumber ?? <span className="text-muted fst-italic">Vendor No.</span>}</div>
                                </>
                            }
                        </div>
                        <div>
                            <label className="text-primary mb-3">Company</label>
                            <div>{isSoHistory ? (customerDetails?.billingInfo?.company ?? <span className="text-muted fst-italic">Company</span>) : (customerDetails?.company ?? <span className="text-muted fst-italic">Company</span>)}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex">
                        <div className="me-5">
                            <label className="text-primary mb-3">Item Number</label>
                            <div>{selectedItem?.itemNumber ?? <span className="text-muted fst-italic">Item Number</span>}</div>
                        </div>
                        <div>
                            <label className="text-primary mb-3">Item Description</label>
                            <div>{selectedItem?.itemDescription ?? <span className="text-muted fst-italic">Item Description</span>}</div>
                        </div>
                    </div>
                </div>
                <AG
                    data={isSoHistory ? getHistory.data?.data?.salesOrders : isInvoice ? getInvoiceHistory.data?.data : getHistory.data?.data?.purchaseOrders}
                    columns={isSoHistory ? soColumns : isInvoice ? item_history_columns : item_po_history}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex  border-top-0">
                <button
                    type="button"
                    className="btn btn-outline-primary custom-outline"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default HistoryModal