import { useState, useEffect, useContext } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import Select from "react-select";
import { useMutation } from "react-query";
import InputGroup from "react-bootstrap/InputGroup";
import "react-datepicker/dist/react-datepicker.css";
import DispatchContext from "context/DispatchContext";
import SelectionModal from "components/SelectionModal/SelectionModal";
import {
  getItemHubsInitData,
  getHubPricing,
  updateHubPricing,
} from "pages/Inventory/Inventory/InventoryServices/Inventory.services";
import * as AppUrls from "constants/AppUrls";
import { formatInput, formatNumber, notificationMessage } from "global/helpers";
import { DropDownOption, GridColumns } from "components/Common.models";
import { ItemHubsInitData } from "../Inventory.models";
import rightArrow from "img/right-arrow.png";
import { LOAD_FAIL_MSG, UPDATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import "./Inventory.scss";
import SectionTitle from "elements/SectionTitle";
import Lookup from "elements/Lookup";

const ItemHubs = (props) => {
  const history = useHistory();
  const appDispatch = useContext(DispatchContext);

  const { id } = useParams();

  const isSerialized: boolean =
    props.match.path === AppUrls.inventory_serialized_hubs;

  const [openSelectionHub, setOpenSelectionHub] = useState<boolean>(false);
  const [openSelectionSupplier, setOpenSelectionSupplier] =
    useState<boolean>(false);

  const [initData, setInitData] = useState<ItemHubsInitData>(null);

  const getInitData = useMutation(getItemHubsInitData, {
    async onSuccess(response) {
      if (response) setInitData(response);
    },
    onError() {
      let notification = {
        variant: "danger",
        msg: `${LOAD_FAIL_MSG} item hub data`,
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    if (id) getInitData.mutate(id);
  }, [id]);

  useEffect(() => {
    if (getInitData.isLoading)
      appDispatch({ type: "loading", value: getInitData.isLoading });
    else appDispatch({ type: "loading", value: false });
  }, [getInitData.isLoading, appDispatch]);

  let shelvesOpts: DropDownOption[] = initData?.Shelves?.map((shelve) => {
    return {
      value: shelve.itemShelveId,
      label: shelve.shelve,
    };
  });

  let binsOpts: DropDownOption[] = initData?.Bins?.map((bin) => {
    return {
      value: bin.itemBinId,
      label: bin.binId,
    };
  });

  const hub_columns: GridColumns[] = [
    {
      field: "hubId",
      headerName: "Hub ID",
      editable: false,
    },
    {
      field: "description",
      headerName: "Location",
      editable: false,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work No",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax No",
      editable: false,
    },
    {
      field: "isDefault",
      headerName: "Default",
      editable: false,
    },
  ];

  const suppliers_columns: GridColumns[] = [
    {
      field: "vendorNumber",
      headerName: "Vendor Number",
      editable: false,
    },
    {
      field: "company",
      headerName: "Company",
      editable: false,
    },
    {
      field: "contact",
      headerName: "Contact",
      editable: false,
    },
    {
      field: "workNumber",
      headerName: "Work Phone",
      editable: false,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile No",
      editable: false,
    },
    {
      field: "faxNumber",
      headerName: "Fax Phone",
      editable: false,
    },
    {
      field: "vendorNumber",
      headerName: "Acct W/Vendor",
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      editable: false,
    },
    {
      field: "vendorTypeName",
      headerName: "Type",
      editable: false,
    },
    {
      field: "isApproved",
      headerName: "Approved?",
      editable: false,
    },
    {
      field: "isPreferred",
      headerName: "Preferred",
      editable: false,
    },
  ];

  const onBackClick = () => {
    if (isSerialized) {
      history.push(`${AppUrls.new_inventory_serialized}/${id}`);
    } else {
      history.push(`${AppUrls.new_inventory_nonserialized}/${id}`);
    }
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    register,
    setValue,
    handleSubmit,
    reset,
    getValues,
  } = methods;

  const cancelForm = () => {
    setSelectedHub(null);
    setSelectedSupplier(null);
    reset({
      itemPrice: null,
      lastPrice: null,
      handling: null,
      capacity: null,
      avgPrice: null,
      isOnline: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
    });
    setDbBin(null);
    setDbShelve(null);
  };

  const [selectedHub, setSelectedHub] = useState(null);

  const onHubSelected = (e) => {
    setOpenSelectionHub(false);
    setSelectedHub(e);
    setValue("flag", 1, { shouldDirty: true });
    setHubPricing.mutate({ hubId: e.locationId, itemId: parseInt(id) });
  };

  useEffect(() => {
    if (initData?.Hubs && initData?.Hubs?.length === 1) {
      setSelectedHub(initData?.Hubs[0]);
      setValue("flag", 1, { shouldDirty: true });
      setHubPricing.mutate({
        hubId: initData?.Hubs[0].locationId,
        itemId: parseInt(id),
      });
    }
  }, [initData?.Hubs]);

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const onSupplierSelected = (e) => {
    setOpenSelectionSupplier(false);
    setSelectedSupplier(e);
  };

  let modal = <></>;

  if (openSelectionHub) {
    modal = (
      <SelectionModal
        modal={"Hub"}
        showModal={openSelectionHub}
        setShowModal={setOpenSelectionHub}
        data={initData?.Hubs}
        columns={hub_columns}
        setRowClicked={onHubSelected}
      />
    );
  } else if (openSelectionSupplier) {
    modal = (
      <SelectionModal
        modal={"Supplier"}
        showModal={openSelectionSupplier}
        setShowModal={setOpenSelectionSupplier}
        data={initData?.Suppliers}
        columns={suppliers_columns}
        setRowClicked={onSupplierSelected}
      />
    );
  }

  const [dbShelve, setDbShelve] = useState<DropDownOption>(null);
  const [dbBin, setDbBin] = useState<DropDownOption>(null);

  const setHubPricing = useMutation(getHubPricing, {
    async onSuccess(response) {
      if (response) {
        setValue("itemPrice", formatInput(response.itemPrice?.toString()));
        setValue("lastPrice", response.lastPrice);
        setValue("handling", response.handling);
        setValue("capacity", response.capacity);
        setValue("avgPrice", response.avgPrice);
        setValue("lastBuyingPrice", response.lastBuyingPrice);
        setValue("lastSellingPrice", response.lastSellingPrice);
        setValue("onHand", response.onHand);
        setValue("onPO", response.onPO);
        setValue("onSO", response.onSO);
        setValue("allowRMA", response.allowRMA);
        setValue("isOnline", response.isOnline);
        setValue("reorder", response.reOrder);
        response.priceLevels.forEach((lvl) => {
          setValue(
            lvl.priceLevel.toString(),
            formatInput(lvl.price?.toString())
          );
        });
        let supplier = initData?.Suppliers.find(
          (supp) => supp.vendorId === response.supplierId
        );
        if (supplier) {
          setSelectedSupplier(supplier);
          setValue("supplierName", supplier.vendorNumber);
          setValue("supplierId", supplier.vendorId);
        }
        let shelve = initData?.Shelves?.find(
          (shelv) => shelv.itemShelveId === response.shelveId
        );
        let bin = initData?.Bins?.find(
          (bin) => bin.itemBinId === response.binId
        );
        setDbBin({ value: bin?.itemBinId, label: bin?.binId });
        setDbShelve({
          value: shelve?.itemShelveId,
          label: shelve?.description,
        });
      }
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem setting hub pricing data"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const editHubPricing = useMutation(updateHubPricing, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Information ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      cancelForm();
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "Problem editing hub pricing"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  useEffect(() => {
    appDispatch({ type: "loading", value: editHubPricing.isLoading });
  }, [editHubPricing.isLoading, appDispatch]);

  const onSubmit = (values) => {
    let priceLevelsArray = [];
    let keys = Object.keys(values);
    keys.forEach((key) => {
      if (Number(key) && values[key]) {
        priceLevelsArray.push({
          priceLevel: Number(key),
          price: parseFloat(values[key]?.toString().replace(/,/g, "")),
        });
      }
    });
    let json_data = {
      itemId: Number(id),
      hubId: selectedHub?.locationId,
      handling: Number(values.handling) ?? null,
      capacity: Number(values.capacity) ?? null,
      minRetailPrice: Number(values.minRetailPrice) ?? null,
      itemPrice: parseFloat(values.itemPrice?.toString().replace(/,/g, "")),
      supplierId: selectedSupplier?.vendorId,
      shelveId: dbShelve?.value,
      binId: dbBin.value,
      priceLevels: priceLevelsArray,
      allowRMA: values.allowRMA,
      isOnline: values.isOnline,
      reOrder: Number(values.reorder),
    };
    editHubPricing.mutate(json_data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input {...register("flag")} type="hidden" />
      {modal}
      <div className="page-title page-title-editable">
        <nav aria-label="breadcrumb" className="first d-md-flex">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a onClick={() => history.push(`${AppUrls.inventory_list}`)}>
                <span>Inventory List</span>
              </a>
              <img
                alt=""
                src={rightArrow}
                className="ms-3 me-2"
                width="10"
                height="10"
              />
            </li>
            <li className="breadcrumb-item">
              <a onClick={onBackClick}>
                <span>
                  {props.location.state
                    ? props.location.state?.itemNumber
                    : initData
                    ? initData?.itemNumber
                    : "—"}
                </span>
              </a>
              <img
                alt=""
                src={rightArrow}
                className="ms-3 me-2"
                width="10"
                height="10"
              />
            </li>
            <li className="breadcrumb-item">
              <span>Hubs</span>
            </li>
          </ol>
        </nav>
        <div>
          <button
            type="button"
            className={`btn btn-outline-primary no-border ${
              !isDirty && !id && "text-muted"
            }`}
            disabled={!isDirty}
            onClick={cancelForm}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-success"
            disabled={!isDirty || isSubmitSuccessful}
          >
            Save
          </button>
        </div>
      </div>
      <FormProvider {...methods}>
        <div className="page-content-wrapper">
          <div className="page-content">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="form-group mb-4 w-50">
                  <label>Hub ID</label>
                  <Lookup
                    onButtonClicked={() => setOpenSelectionHub(true)}
                    inputName="itemHubId"
                    initialData={initData?.Hubs?.map((item) => ({
                      ...item,
                      id: item.locationId,
                      name: item.hubId,
                    }))}
                    onSelection={onHubSelected}
                    inputValue={selectedHub?.hubId}
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="row section py-3">
                  <div className="col-lg-6">
                    <InputGroup className="align-items-center">
                      <label className="label mt-0">Qty on Hand</label>
                      <input
                        {...register("onHand")}
                        className="form-control border-0 bg-transparent text-end px-3"
                        disabled
                      />
                      {/* <Button
                                            variant="primary"
                                        >
                                            <img src={SearchIcon} alt="" />
                                        </Button> */}
                    </InputGroup>
                  </div>
                  <div className="col-lg-6">
                    <InputGroup className="align-items-center">
                      <label className="label mt-0">On Sales Orders</label>
                      <input
                        {...register("onSO")}
                        className="form-control border-0 bg-transparent text-end px-3"
                        disabled
                      />
                      {/* <Button
                                            variant="primary"
                                        >
                                            <img src={SearchIcon} alt="" />
                                        </Button> */}
                    </InputGroup>
                  </div>
                  <div className="col-lg-6 mt-2">
                    <InputGroup className="align-items-center">
                      <label className="label mt-0">Pending PO</label>
                      <input
                        {...register("onPO")}
                        className="form-control border-0 bg-transparent text-end px-3"
                        disabled
                      />
                      {/* <Button
                                            variant="primary"
                                        >
                                            <img src={SearchIcon} alt="" />
                                        </Button> */}
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="mt-4">
                  <SectionTitle title="Item Cost/Price" editable={false} />
                </div>
                <div className="row mt-4">
                  <div className="left-side">
                    <div className="row">
                      {initData &&
                        initData.priceLevels?.map((priceLevel) => {
                          return (
                            <div
                              className="col-6"
                              key={priceLevel.priceLevelId}
                            >
                              <div className="form-group w-75">
                                <label>{priceLevel.title}</label>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span
                                      className={`input-group-text dollar-icon ${
                                        !id
                                          ? "bg-white"
                                          : isDirty
                                          ? "bg-white"
                                          : "bg-disabled"
                                      }`}
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    {...register(
                                      priceLevel.priceLevelId.toString()
                                    )}
                                    className="form-control border-start-0"
                                    disabled={!isDirty}
                                    onBlur={(e) =>
                                      setValue(
                                        priceLevel.priceLevelId.toString(),
                                        formatInput(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="right-side">
                    <div className="form-group border p-2 d-flex justify-content-between">
                      <label>Last Selling Price</label>
                      <span>
                        {formatNumber(getValues("lastSellingPrice") ?? 0)}
                      </span>
                    </div>
                    <div className="form-group border p-2 d-flex justify-content-between">
                      <label>Last Buying Price</label>
                      <span>
                        {formatNumber(getValues("lastBuyingPrice") ?? 0)}
                      </span>
                    </div>
                    <div className="form-group border p-2 d-flex justify-content-between">
                      <label>Average Price</label>
                      <span>{formatNumber(getValues("avgPrice") ?? 0)}</span>
                    </div>
                    <div className="form-group w-75">
                      <label>
                        Item Price <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className={`input-group-text dollar-icon ${
                              !id
                                ? "bg-white"
                                : isDirty
                                ? "bg-white"
                                : "bg-disabled"
                            }`}
                          >
                            $
                          </span>
                        </div>
                        <input
                          {...register("itemPrice", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                          className="form-control border-start-0"
                          disabled={!isDirty}
                          onBlur={(e) =>
                            setValue("itemPrice", formatInput(e.target.value))
                          }
                        />
                      </div>
                      {errors["itemPrice"] && (
                        <span className="text-danger">
                          {errors.itemPrice.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group w-75">
                      <label>Handling</label>
                      <input
                        {...register("handling")}
                        type="number"
                        className="form-control"
                        disabled={!isDirty}
                      />
                    </div>
                    <div className="form-group w-75">
                      <label>Capacity</label>
                      <input
                        {...register("capacity")}
                        type="number"
                        className="form-control"
                        disabled={!isDirty}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="mt-4">
                  <SectionTitle title="Item Info" editable={false} />
                </div>
                <div className="mt-4">
                  <div className="form-group w-50">
                    <div className="form-group mb-4">
                      <label>Supplier</label>
                      <Lookup
                        onButtonClicked={() => setOpenSelectionSupplier(true)}
                        inputName="supplierId"
                        initialData={initData?.Suppliers?.map((supplier) => ({
                          ...supplier,
                          id: supplier.vendorId,
                          name: supplier.vendorNumber,
                        }))}
                        onSelection={onSupplierSelected}
                        isDisabled={!isDirty}
                        inputValue={selectedSupplier?.vendorNumber}
                      />
                    </div>
                  </div>
                  <div className="form-group w-50">
                    <label>Shelve</label>
                    <Controller
                      control={control}
                      name="shelveId"
                      render={() => (
                        <Select
                          onChange={(value) => setDbShelve(value)}
                          isClearable
                          value={dbShelve}
                          options={shelvesOpts}
                          isDisabled={!isDirty}
                          menuPlacement="auto"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group w-50">
                    <label>Bin</label>
                    <Controller
                      control={control}
                      name="binId"
                      render={() => (
                        <Select
                          onChange={(value) => setDbBin(value)}
                          isClearable
                          value={dbBin}
                          options={binsOpts}
                          isDisabled={!isDirty}
                          menuPlacement="auto"
                        />
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <label>Visible Online</label>
                    <div className="mt-3">
                      <Controller
                        control={control}
                        name="isOnline"
                        render={({ field: { onChange, value } }) => (
                          <div className="form-group">
                            <label className="checkbox-inline">
                              <input
                                id="onlineYes"
                                onChange={() => onChange(true)}
                                checked={value}
                                type="radio"
                                disabled={!isDirty}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="onlineYes"
                              >
                                Yes
                              </label>
                            </label>
                            <label className="checkbox-inline ms-3">
                              <input
                                id="onlineNo"
                                onChange={() => onChange(false)}
                                checked={!value}
                                type="radio"
                                disabled={!isDirty}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="onlineNo"
                              >
                                No
                              </label>
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Allow RMA</label>
                    <div className="mt-3">
                      <Controller
                        control={control}
                        name="allowRMA"
                        render={({ field: { onChange, value } }) => (
                          <div className="form-group">
                            <label className="checkbox-inline">
                              <input
                                id="rmaYes"
                                onChange={() => onChange(true)}
                                checked={value}
                                type="radio"
                                disabled={!isDirty}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="rmaYes"
                              >
                                Yes
                              </label>
                            </label>
                            <label className="checkbox-inline ms-3">
                              <input
                                id="rmaNo"
                                onChange={() => onChange(false)}
                                checked={!value}
                                type="radio"
                                disabled={!isDirty}
                              />
                              <label
                                className="text-black ms-2"
                                htmlFor="rmaNo"
                              >
                                No
                              </label>
                            </label>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Re-Order</label>
                    <input
                      {...register("reorder", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        valueAsNumber: true,
                      })}
                      type="number"
                      className="form-control w-50"
                      disabled={!isDirty}
                    />
                    {errors["reorder"] && (
                      <p className="text-danger">{errors["reorder"].message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export default ItemHubs;
