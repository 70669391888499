import { useFormContext } from "react-hook-form";

import ImageUploadComponent from "components/ImageUpload/ImageUploadComponent";
import "./Inventory.scss";

const PortalSettings = ({
  item,
  isDisabled,
  setImgThumbnail,
  setCustomIsDirty,
}) => {
  const { register } = useFormContext();

  return (
    <>
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="col-12 ps-2 mb-3">
            <label>Item Thumbnail</label>
            <ImageUploadComponent
              active={!isDisabled}
              initialImg={item?.item?.imageUrl}
              setCustomIsDirty={setCustomIsDirty}
              setImageData={setImgThumbnail}
            />
          </div>
          <div className="form-group mt-4 mb-4 ps-2">
            <label className="checkbox-inline">
              <input
                {...register("portalSettings.hotItem")}
                id="hotItemE"
                type="checkbox"
                disabled={isDisabled}
              />
              <label className="text-black ms-2" htmlFor="hotItemE">
                List as Hot item
              </label>
            </label>
          </div>
          <div className="form-group mb-4 ps-2">
            <label className="checkbox-inline">
              <input
                {...register("portalSettings.newArrival")}
                id="newArrivalE"
                type="checkbox"
                disabled={isDisabled}
              />
              <label className="text-black ms-2" htmlFor="newArrivalE">
                List as New Arrival
              </label>
            </label>
          </div>
          <div className="form-group mb-4 ps-2">
            <label className="checkbox-inline">
              <input
                {...register("portalSettings.clearance")}
                id="clearanceE"
                type="checkbox"
                disabled={isDisabled}
              />
              <label className="text-black ms-2" htmlFor="clearanceE">
                List as Clearance
              </label>
            </label>
          </div>
          <div className="form-group mb-4 ps-2">
            <p>New items automatically revert after 14 days</p>
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-sm-12 ps-5">
          <div className="col-12 mb-3">
            <label>Additional item description online</label>
          </div>
          <div className="form-group mb-4 mw-100">
            <textarea
              {...register("portalSettings.onlineDescription")}
              className="form-control min-h-350"
              placeholder="Item description"
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalSettings;
