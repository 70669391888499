import { getBaseCurrency } from "components/currency";
import DispatchContext from "context/DispatchContext";
import AgGrid from "elements/AgGrid";
import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import backArrow from "img/back-arrow.svg";
import exportIcon from "img/export.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BlueSeedLogo from "../img/BlueSeed-logo-blue.png";
import { getInventoryValuation } from "./services/ReportServices";
import "./style/common.scss";
import "./style/report.scss";

export default function InventoryValuation() {
  const history = useHistory();

  const appDispatch = useContext(DispatchContext);

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [showReport, setShowReport] = useState<boolean>(false);
  const [reportData, setReportData] = useState(null);

  const generateReport = useMutation(
    "getInventoryValuation",
    getInventoryValuation,
    {
      async onSuccess(data) {
        let currentData = { ...data };
        setShowReport(true);
        setReportData(currentData);
      },
    }
  );

  // const onSubmit = () => {
  //     let params = { startDate: null, endDate: null }
  //     if (startDate)
  //         params.startDate = fixTimezoneOffset(startDate)
  //     if (endDate)
  //         params.endDate = fixTimezoneOffset(endDate)
  //     else {
  //         params.endDate = fixTimezoneOffset(new Date())
  //         setEndDate(new Date())
  //     }

  //     generateReport.mutate(params)
  // }

  useEffect(() => {
    generateReport.mutate();
  }, []);

  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc3", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });
  const hubs_columns = [
    {
      field: "itemNumber",
      headerName: "Item No",
      cellRenderer: "agGroupCellRenderer",
      filter: false,
      floatingFilter: false,
      editable: false,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      field: "",
      headerName: "",
      filter: false,
      floatingFilter: false,
      editable: false,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      field: "assetValue",
      headerName: "Asset Value",
      valueGetter: (params) => {
        return formatNumber(params.data.assetValue, currencySymbol);
      },
      filter: false,
      floatingFilter: false,
      editable: false,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      field: "totalFIFOCost",
      headerName: "Total FIFO Cost",
      valueGetter: (params) => {
        return formatNumber(params.data.totalFIFOCost, currencySymbol);
      },
      filter: false,
      floatingFilter: false,
      editable: false,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      field: "totalQuantity",
      headerName: "Total QTY",
      filter: false,
      floatingFilter: false,
      editable: false,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
    {
      field: "totalQuantityOnHand",
      headerName: "Total QTY On Hand",
      filter: false,
      floatingFilter: false,
      editable: false,
      resizable: false,
      sortable: false,
      suppressMenu: true,
    },
  ];

  const TransactionTypeEnumArray = [
    "Bill",
    "Manual Inventory Receipt",
    "Manual Inventory Issue",
    "Credit Memo",
    "Invoice",
    "Purchase Order",
  ];

  const items_columns = [
    {
      field: "date",
      headerName: "Date",
      valueGetter: (params) => {
        return dateFormmaterNoTime(params.data.date);
      },
    },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      valueGetter: (params) => {
        return TransactionTypeEnumArray[params.data.transactionType - 1] ?? "";
      },
    },
    {
      field: "number",
      headerName: "No",
    },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "quantity",
      headerName: "QTY",
    },
    {
      field: "rate",
      headerName: "Rate",
      valueGetter: (params) => {
        return params?.data?.rate?.toFixed(2);
      },
    },
    {
      field: "fifoCost",
      headerName: "FIFO Cost",
      valueGetter: (params) => {
        return formatNumber(params.data.fifoCost, currencySymbol);
      },
    },
    {
      field: "quantityOnHand",
      headerName: "QTY On Hand",
    },
    {
      field: "assetValue",
      headerName: "Asset Value",
      valueGetter: (params) => {
        return formatNumber(params.data.assetValue, currencySymbol);
      },
    },
  ];

  const detailCellRenderer = ({ data }) => {
    return (
      <div className="py-4">
        <AgGrid
          data={data?.transactions}
          columns={items_columns}
          pagination={false}
          autoHight={true}
          autoSize={true}
          forPrinting={true}
        />
      </div>
    );
  };

  const pageTitle = "Inventory Valuation Report";

  const reportPage = useRef(null);

  const onBackToReportsClick = () => {
    history.push("/reports");
  };

  const handlePrint = useReactToPrint({
    content: () => reportPage.current,
  });

  const printDocument = () => {
    appDispatch({ type: "loading", value: true });
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      appDispatch({ type: "loading", value: false });
    }, 5000);
  };
  useEffect(() => {
    appDispatch({ type: "loading", value: generateReport.isLoading });
  }, [appDispatch, generateReport.isLoading]);

  const [exportToExcelClicked, setExportToExcelClicked] =
    useState<boolean>(false);
  const [disabledExportBtn, setDisabledExportBtn] = useState<boolean>(false);
  const [isLoadingGridExport, setIsLoadingGridExport] =
    useState<boolean>(false);

  const exportToExcel = () => {
    setIsLoadingGridExport(true);
    setDisabledExportBtn(true);
    setExportToExcelClicked(true);
    let notification = {
      variant: "success",
      msg: "Preparing grid for export...",
    };
    appDispatch({ type: "notification", value: notification });
  };

  useEffect(() => {
    appDispatch({ type: "loading", value: isLoadingGridExport });
  }, [appDispatch, isLoadingGridExport]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <div className="page-title page-title-editable">
            <div className="back-btn " onClick={onBackToReportsClick}>
              <img src={backArrow} alt="back arrow" />
              {pageTitle}
            </div>
          </div>
          <div className="page-content-wrapper">
            <div className="page-content">
              {/* <Accordion defaultActiveKey="1" className="mb-4 reports-card reports-filters-card">
                                <Accordion.Item eventKey={"1"}>
                                    <Accordion.Header>
                                        <b>Filters</b>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className='col-lg-4'>
                                                <label className='me-2'>Start Date</label>
                                                <DatePicker
                                                    onChange={(date) => setStartDate(date)}
                                                    selected={startDate}
                                                    className="form-control"
                                                    showYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                    yearDropdownItemNumber={15}
                                                    scrollableYearDropdown
                                                />
                                            </div>
                                            <div className='col-lg-4'>
                                                <label className='me-2'>End Date</label>
                                                <DatePicker
                                                    onChange={(date) => setEndDate(date)}
                                                    selected={endDate}
                                                    className="form-control"
                                                    showYearDropdown
                                                    dateFormatCalendar="MMMM"
                                                    yearDropdownItemNumber={15}
                                                    scrollableYearDropdown
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4 text-end">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary no-border me-3"
                                                onClick={onCancelClick}
                                            >
                                                Clear filters
                                            </button>
                                            <button type="submit" className="btn btn-outline-primary">
                                                Generate Report
                                            </button>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> */}
              {showReport && (
                <>
                  <div className="container report-filters-div text-end mb-3">
                    <button
                      className="import-btn"
                      type="button"
                      onClick={exportToExcel}
                      disabled={
                        disabledExportBtn || reportData?.hubs?.length === 0
                      }
                    >
                      <img src={exportIcon} className="me-2" alt="" />
                      Export grid to Excel
                    </button>
                    <button
                      type="button"
                      className="btn btn-success ms-5"
                      onClick={printDocument}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-printer me-3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                      </svg>
                      Print report to PDF
                    </button>
                    {/* <button className="btn btn-danger ms-5" onClick={() => { setShowReport(false) }}>
                                            Cancel
                                        </button> */}
                  </div>
                  <div className="report-page" ref={reportPage}>
                    <div className="report-header d-flex justify-content-between">
                      <div>
                        <h3>
                          <b>{pageTitle}</b>
                        </h3>{" "}
                        <br />
                        <p>
                          <b>Report Date: </b>
                          {dateFormmaterNoTime(reportData?.reportDate)}
                        </p>
                      </div>
                      <div>
                        <img
                          alt=""
                          src={reportData?.imageLogo ?? BlueSeedLogo}
                          className="report-logo"
                        ></img>
                      </div>
                    </div>
                    <div className="grid-wrapper">
                      <AgGrid
                        data={reportData?.items}
                        columns={hubs_columns}
                        pagination={false}
                        forPrinting={true}
                        autoHight={true}
                        autoSize={true}
                        masterDetail={true}
                        detailCellRenderer={detailCellRenderer}
                        exportToExcel={exportToExcelClicked}
                        setExportToExcelClicked={setExportToExcelClicked}
                        setDisabledExportBtn={setDisabledExportBtn}
                        setIsLoadingGridExport={setIsLoadingGridExport}
                        subTable={items_columns}
                        subTableObj={"transactions"}
                        fileName="Inventory Valuation"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
