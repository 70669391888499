import { GridColumns } from "components/Common.models";
import AG from "elements/AG";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getItemsByPos } from "../InvoicesServices/InvoicesServices";
import Close from "img/close.svg";
import { useMutation, useQuery } from "react-query";

import {
  dateFormmaterNoTime,
  formatNumber,
  getCurrencyByCode,
} from "global/helpers";
import { getBaseCurrency } from "components/currency";

const ReceiveInvoiceItemsModal = ({
  addedItems = [],
  setAddedItems = (val) => {},
  selectedPos = [],
  isEdit = false,
  tax,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showSerials, setSHowSerials] = useState<boolean>(false);
  const [serialsList, setSerialsList] = useState([]);

  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  useQuery("bc", getBaseCurrency, {
    onSuccess: (data) => {
      setCurrencySymbol(getCurrencyByCode(data).symbol);
    },
  });

  let fetchItems = useMutation(getItemsByPos, {
    onSuccess(response) {
      setAddedItems(response);
    },
  });

  useEffect(() => {
    if (selectedPos.length > 0) {
      fetchItems.mutate(selectedPos);
    } else {
      setSelectedItem("");
      setSerialsList([]);
      setAddedItems([]);
    }
  }, [selectedPos]);

  useEffect(() => {
    setSerialsList(selectedItem?.serials);
  }, [selectedItem]);

  const itemsColumns: GridColumns[] = [
    {
      field: "purchaseOrderNumber",
      headerName: "PO No.",
      hide: isEdit,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
    },
    {
      field: "description",
      headerName: "Item Description",
    },
    {
      field: "serials",
      headerName: "Serials",
      cellRendererFramework: (params) => {
        let serials = params.value;
        return (
          <>
            {serials?.length > 0 ? (
              <span
                className="btn btn-link text-decoration-none ms-0 ps-0"
                onClick={() => {
                  setSHowSerials(true);
                  setSelectedItem(params.data);
                }}
              >
                Serials
              </span>
            ) : (
              <span className="text-muted fst-italic">Serials</span>
            )}
          </>
        );
      },
    },
    {
      field: "cost",
      headerName: "Cost",
      editable: true,
      cellRenderer: (params) => formatNumber(params.value, currencySymbol),
    },
    {
      field: "quantityReceived",
      headerName: "Qty",
    },
    {
      field: "vendorPartNum",
      headerName: "Vendor Part Num",
      hide: isEdit,
    },
    {
      field: "warranty",
      headerName: "Warranty",
      hide: isEdit,
    },
  ];

  const serialsColumns: GridColumns[] = [
    {
      field: "serialNo",
      headerName: "Serial Number",
    },
    {
      field: "scanDate",
      headerName: "Scan Date",
      cellRenderer: (params) => {
        return dateFormmaterNoTime(params.value);
      },
    },
    {
      field: "isAvailable",
      headerName: "Available",
      cellRenderer: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
  ];

  const getQty = () => {
    let total = 0;
    if (addedItems.length > 0 && addedItems.length > 0)
      for (let item of addedItems) total += parseInt(item?.quantityReceived);
    return total;
  };

  const getSubTotal = () => {
    let subTotal = 0;
    if (addedItems.length > 0 && addedItems.length > 0)
      for (let item of addedItems)
        subTotal += parseInt(item?.quantityReceived) * parseFloat(item?.cost);
    return subTotal;
  };

  return (
    <>
      <div className="mt-3 pb-5">
        <div className="row mt-3 mb-3">
          <div className="left-side-mid"></div>
          <div className="right-side-mid">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex">
                  <div className="text-secondary">TOTAL QTY</div>
                  <div className="flex-grow-1 text-center">{getQty()}</div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="d-flex">
                  <div className="text-secondary">TOTAL ITEMS</div>
                  <div className="flex-grow-1 text-center">
                    {addedItems?.length}
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-3">
                <hr />
              </div>
              <div className="col-sm-12 d-flex justify-content-between">
                <div className="text-secondary">SUB-TOTAL</div>
                <div>{formatNumber(getSubTotal(), currencySymbol)}</div>
              </div>
              <div className="col-sm-12 d-flex justify-content-between mt-3">
                <div className="text-secondary">TAX AMOUNT</div>
                <div>{(formatNumber(tax), currencySymbol)}</div>
              </div>
              <div className="col-sm-12">
                <div className="section mt-3 p-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fs-5">ORDER TOTAL</div>
                    <div className="text-success fs-4">
                      {formatNumber(getSubTotal() + tax, currencySymbol)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row section px-0 ps-2 py-3 pb-0 ">
          <div className="ps-0">
            <h4>
              <b>Items list</b>
            </h4>
          </div>
        </div>
        <div className="h-350">
          <AG columns={itemsColumns} data={addedItems} autoColumns={true} />
        </div>
      </div>
      <Modal
        show={showSerials}
        onHide={setSHowSerials}
        centered
        size="lg"
        dialogClassName="modal"
      >
        <Modal.Header className="d-flex justify-content-between border-bottom-0">
          <Modal.Title>
            Serials List for Item {selectedItem?.itemNumber}
          </Modal.Title>
          <img
            src={Close}
            alt="close icon"
            className="ms-auto"
            role="button"
            onClick={() => {
              setSHowSerials(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="px-0">
          <div className="h-250">
            <AG data={serialsList} columns={serialsColumns} />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ms-auto border-top-0">
          <button
            type="button"
            className="btn btn-outline-primary custom-outline"
            onClick={() => {
              setSHowSerials(false);
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReceiveInvoiceItemsModal;
