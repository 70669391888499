import { DropDownOption, GridColumns } from "components/Common.models";
import { ErrorResponse } from "components/data/Errors.model";
import * as AppUrls from "constants/AppUrls";
import { UPDATE_SUCCESS_MSG } from "constants/NotificationMsgs";
import DispatchContext from "context/DispatchContext";
import StateContext from "context/StateContext";
import AG from "elements/AG";
import SectionTitle from "elements/SectionTitle";
import { checkIfAllowed, notificationMessage } from "global/helpers";
import backArrow from "img/back-arrow.svg";
import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { PortalUser } from "../SalesMarketing.models";
import {
  editPortal,
  getPortal,
  getSameCompanyList,
} from "../SalesMarketingServices/salesMarketingServices";

const PortalUserDetails = (props) => {
  const { match } = props;
  const { id } = useParams();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const {
    formState: { errors, isDirty, isSubmitSuccessful },

    control,
    reset,
    getValues,
    register,
    handleSubmit,
  } = useForm<PortalUser>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      isActive: true,
    },
  });

  const {
    error: errorLoadingSameCompanyList,
    isLoading: isLoadingSameCompanyList,
    data: sameCompanyDetails,
  } = useQuery<PortalUser[], ErrorResponse>(
    ["getCompanyList", id],
    getSameCompanyList,
    {
      enabled: id !== undefined ? true : false,
    }
  );

  useEffect(() => {
    if (errorLoadingSameCompanyList?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingSameCompanyList.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingSameCompanyList, appDispatch]);

  const {
    data: currentPortal,
    isLoading: isLoadingCurrentPortal,
    error: errorLoadingCurrentPortal,
  } = useQuery<PortalUser, ErrorResponse>([id], getPortal, {
    enabled: id !== undefined ? true : false,
  });

  useEffect(() => {
    if (currentPortal !== undefined) {
      reset(currentPortal);
    }
  }, [currentPortal]);

  useEffect(() => {
    appDispatch({
      type: "loading",
      value: isLoadingCurrentPortal || isLoadingSameCompanyList,
    });
  }, [isLoadingCurrentPortal, isLoadingSameCompanyList]);

  useEffect(() => {
    if (errorLoadingCurrentPortal?.errorMessage) {
      let notification = {
        variant: "danger",
        msg: errorLoadingCurrentPortal.errorMessage,
      };
      appDispatch({ type: "notification", value: notification });
    }
  }, [errorLoadingCurrentPortal, appDispatch]);

  const statusCellRenderer = (params) => {
    let style = params.value === true ? "active-status" : "disabled-status";
    return `<div class=${style}>${
      params.value === true ? "Active" : "Disabled"
    }</div>`;
  };

  const sameCompanyColumns: GridColumns[] = [
    {
      field: "username",
      headerName: "User ID",
    },
    {
      field: "fullname",
      headerName: "Full Name",
    },
    {
      field: "userLevel",
      headerName: "Level",
    },
    {
      field: "isActive",
      headerName: "Status",
      cellRenderer: statusCellRenderer,
    },
  ];

  const userLevels: DropDownOption[] = [
    {
      label: "Employee",
      value: 1,
    },
    {
      label: "Operator",
      value: 2,
    },
  ];

  const onEditClicked = () => {
    setIsDisabled(false);
  };

  const cancelForm = (e) => {
    e.preventDefault();
    reset();
    if (id) {
      setIsDisabled(true);
    }
  };

  const updatePortalUser = useMutation(editPortal, {
    async onSuccess() {
      let notification = {
        variant: "success",
        msg: `Portal user ${UPDATE_SUCCESS_MSG}`,
      };
      appDispatch({ type: "notification", value: notification });
      setIsDisabled(true);
    },
    onError(error) {
      let notification = {
        variant: "danger",
        msg: notificationMessage(error, "problem editing hub"),
      };
      appDispatch({ type: "notification", value: notification });
    },
  });

  const onSubmit = (data: PortalUser) => {
    updatePortalUser.mutate({ ...data, id });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="page-title page-title-editable">
        <Link to={AppUrls.portal_users} className="back-btn ">
          <img src={backArrow} alt="back arrow" />
          <span> UserID {id}</span>
        </Link>
        <div>
          {!isDisabled ? (
            <>
              <button
                type="button"
                className={`btn btn-outline-primary no-border ${
                  !isDirty && !id && "text-muted"
                }`}
                onClick={cancelForm}
                disabled={!isDirty && !id}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={!isDirty || isSubmitSuccessful}
              >
                Save Changes
              </button>
            </>
          ) : (
            checkIfAllowed(appState.allowedUrls, "edit", match.path) && (
              <button className="btn btn-primary" onClick={onEditClicked}>
                Edit
              </button>
            )
          )}
        </div>
      </div>
      <div className="page-content-wrapper">
        <div className="page-content">
          <div className="row mt-3">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Customer No.</label>
                    <div>{currentPortal?.accountNum}</div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Company Name</label>
                    <div>{currentPortal?.companyName}</div>
                  </div>
                </div>
              </div>
              <div className="section p-0 my-4 mx-0">
                <SectionTitle editable={false} title="Customer Info" />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      User ID <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      {...register("username", { required: true })}
                      className={`form-control ${
                        errors.username && "required_field"
                      }`}
                      disabled={id ? true : isDisabled}
                    />
                    {errors["username"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="section p-0 my-4 mx-0">
                <SectionTitle editable={false} title="User Info" />
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      {...register("fullname", { required: true })}
                      className={`form-control ${
                        errors.fullname && "required_field"
                      }`}
                      disabled={isDisabled}
                    />
                    {errors["fullname"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>
                      User Level <span className="text-danger">*</span>
                    </label>
                    <Controller
                      control={control}
                      name="userLevelId"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          onChange={(selectedOption: any) =>
                            onChange(selectedOption?.value)
                          }
                          isClearable
                          value={
                            userLevels.find((opt) => opt?.value === value) ||
                            null
                          }
                          name="userLevel"
                          isDisabled={isDisabled}
                          isSearchable={false}
                          options={userLevels}
                          className={errors.userLevelId && "required_field"}
                        />
                      )}
                    />
                    {errors["userLevelId"] && (
                      <p className="text-danger">This field is required</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>
                      User Email <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      {...register("email", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format.",
                        },
                      })}
                      className={`form-control ${
                        errors.email && "required_field"
                      }`}
                      disabled={id ? true : isDisabled}
                    />
                    {errors["email"] && (
                      <p className="text-danger">{errors["email"].message}</p>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="right-side-mid">
                    <label>Status</label>
                    {id && !isDisabled ? (
                      <>
                        <div className="form-group">
                          <Controller
                            control={control}
                            name="isActive"
                            render={({ field: { onChange, value } }) => (
                              <>
                                <label className="checkbox-inline">
                                  <input
                                    id="activeE"
                                    name="active"
                                    onChange={() => onChange(true)}
                                    checked={value}
                                    type="radio"
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="text-black ms-2"
                                    htmlFor="activeE"
                                  >
                                    Yes
                                  </label>
                                </label>
                                <label className="checkbox-inline ms-3">
                                  <input
                                    id="disabledE"
                                    name="active"
                                    onChange={() => onChange(false)}
                                    checked={!value}
                                    type="radio"
                                    disabled={isDisabled}
                                  />
                                  <label
                                    className="text-black ms-2"
                                    htmlFor="disabledE"
                                  >
                                    No
                                  </label>
                                </label>
                              </>
                            )}
                          />
                          {errors["isActive"] && (
                            <p className="text-danger">
                              This status is required
                            </p>
                          )}
                        </div>
                      </>
                    ) : id && isDisabled ? (
                      <div
                        className={`${
                          getValues("isActive") === true
                            ? "active-status"
                            : "disabled-status"
                        } ms-0`}
                      >
                        {getValues("isActive") === true ? "Active" : "Disabled"}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col items-page">
              <SectionTitle
                title="Same Company Portal Users"
                editable={false}
              />
              <AG data={sameCompanyDetails} columns={sameCompanyColumns} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PortalUserDetails;
